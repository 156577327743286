//React
import React, { Component } from 'react';
import { compose } from 'recompose';

//Material UI
import Button from '@material-ui/core/Button';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';

//Icons
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';

//Constants
import * as styleConstants from "../../../../constants/styleConstants";

//react-router-dom
import {
    Link
} from "react-router-dom";

//Services
import { GetAllBlogs } from '../../../../services/blogServices';

//Animations
import Fade from 'react-reveal/Fade';

//Others
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import MediaQuery from 'react-responsive';
const axios = require('axios').default;

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%'
    },
    rootContainer: {
        maxWidth: '75%'

    },
    rootCard: {
        maxWidth: "75%",
        width: '75%',
        margin: 10,
    },
    gridContainer: {
        maxWidth: '90%'
    },
    margin: {
        margin: 100
    },
    marginTop: {
        marginTop: 100
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginTop: theme.spacing(5)
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    header: {
        maxWidth: "80%"
    },
    body: {
        maxWidth: "80%",
        marginTop: 30
    },
    divider: {
        maxWidth: "100%"
    },
    image: {
        margin: 'auto',
        display: 'block',
        margin: 10
    },
    commentTextField: {
        width: '100%',
        maxWidth: '100%'
    },
    commentGridItem: {
        maxWidth: "80%",
        marginTop: 30,
        marginBottom: 100
    }
});

const theme = createMuiTheme({
    typography: {
        fontFamily: [
            'Prompt'
        ].join(','),
    },
});


class PinnedBlogsSectionMobile extends Component {
    constructor() {
        super();
        this.state = {
        };
    }

    componentDidMount = async () => {
        const blogObj = await GetAllBlogs();
        console.log(blogObj);

        if (blogObj.success) {
            this.setState({
                blogObj: blogObj.res
            })
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    render() {
        const { classes } = this.props;

        return (
            <MediaQuery minWidth={"768px"}>
                {(matches) =>
                    matches ? <Grid item xs={12} style={{ maxWidth: window.innerWidth, padding: "20px" }}>
                        <Grid container>
                            <Grid item align="center" xs={12}>
                                <Grid container>
                                    <Grid item align="left" xs={12}>
                                        <Typography variant="h3" style={{ fontFamily: styleConstants.fontStyle, fontWeight: styleConstants.fontWeight.bold, color: "#991514" }} >
                                            BLOGS
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} style={{ marginTop: '10%' }}>
                                        <Grid container spacing={4}>
                                            <Grid item align="left" sm={6}>
                                                <Typography variant="h6" style={{ fontFamily: styleConstants.fontStyle, fontWeight: styleConstants.fontWeight.medium, color: "#6b6b6b", textAlign: 'justify' }} >
                                                    {this.state.blogObj ? this.state.blogObj?.pinnedBlogs[0]?.heading : <Skeleton />}
                                                </Typography>
                                                {this.state.blogObj !== undefined ? this.state.blogObj?.pinnedBlogs[0].body.slice(0, 500).split("\\n").map((i, index, row) => {
                                                    if (index + 1 === row.length) {
                                                        return <Typography variant="subtitle2" style={{ fontFamily: styleConstants.fontStyle, fontWeight: styleConstants.fontWeight.thin, color: "#6b6b6b", textAlign: 'justify', marginTop: '2%', marginBottom: '5%' }}>
                                                            {i}...
                                                        </Typography>
                                                    } else {
                                                        return <Typography variant="subtitle2" style={{ fontFamily: styleConstants.fontStyle, fontWeight: styleConstants.fontWeight.thin, color: "#6b6b6b", textAlign: 'justify', marginTop: '2%', marginBottom: '2%' }}>
                                                            {i}
                                                        </Typography>
                                                    }
                                                }) : <Typography variant="subtitle2" style={{ fontFamily: styleConstants.fontStyle, fontWeight: styleConstants.fontWeight.thin, color: "#6b6b6b", textAlign: 'justify', marginTop: '5%', marginBottom: '5%' }} >
                                                    <Skeleton />
                                                </Typography>}
                                                {this.state.blogObj &&
                                                    <Fade bottom>
                                                        <Link to="/blogs" style={{ textDecoration: 'none', color: '#0f0f0f' }}>
                                                            <Button variant="contained" size="large" endIcon={<ArrowForwardRoundedIcon />} style={{ backgroundColor: "white" }}>
                                                                <Typography variant="body2" style={{ fontFamily: styleConstants.fontStyle, fontWeight: 600, }}>
                                                                    See more
                                                                </Typography>
                                                            </Button>
                                                        </Link>
                                                    </Fade>
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid> : <Grid item xs={12} style={{ maxWidth: window.innerWidth, padding: "20px" }}>
                        <Grid container>
                            <Grid item align="center" xs={12}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Grid container spacing={4}>
                                            <Grid item align="center" sm={6}>
                                                <Typography variant="h6" style={{ fontFamily: styleConstants.fontStyle, fontWeight: styleConstants.fontWeight.medium, color: "#6b6b6b", textAlign: 'justify' }} >
                                                    {this.state.blogObj ? this.state.blogObj?.pinnedBlogs[0]?.heading : <Skeleton />}
                                                </Typography>
                                                {this.state.blogObj !== undefined ? this.state.blogObj?.pinnedBlogs[0].body.slice(0, 500).split("\\n").map((i, index, row) => {
                                                    if (index + 1 === row.length) {
                                                        return <Typography variant="subtitle2" style={{ fontFamily: styleConstants.fontStyle, fontWeight: styleConstants.fontWeight.thin, color: "#6b6b6b", textAlign: 'justify', marginTop: '2%', marginBottom: '5%' }}>
                                                            <div dangerouslySetInnerHTML={{ __html: `${i}...` }} />
                                                        </Typography>
                                                    } else {
                                                        return <Typography variant="subtitle2" style={{ fontFamily: styleConstants.fontStyle, fontWeight: styleConstants.fontWeight.thin, color: "#6b6b6b", textAlign: 'justify', marginTop: '2%', marginBottom: '2%' }}>
                                                            <div dangerouslySetInnerHTML={{ __html: i }} />
                                                        </Typography>
                                                    }
                                                }) : <Typography variant="subtitle2" style={{ fontFamily: styleConstants.fontStyle, fontWeight: styleConstants.fontWeight.thin, color: "#6b6b6b", textAlign: 'justify', marginTop: '5%', marginBottom: '5%' }} >
                                                    <Skeleton />
                                                </Typography>}
                                                {this.state.blogObj &&
                                                    <Fade bottom>
                                                        <Link to="/blogs" style={{ textDecoration: 'none', color: '#0f0f0f' }}>
                                                            <Button variant="contained" size="large" endIcon={<ArrowForwardRoundedIcon />} style={{ backgroundColor: "white" }}>
                                                                <Typography variant="body2" style={{ fontFamily: styleConstants.fontStyle, fontWeight: 600, }}>
                                                                    See more
                                                                </Typography>
                                                            </Button>
                                                        </Link>
                                                    </Fade>
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </MediaQuery>
        )
    }
}

export default compose(
    withStyles(useStyles)
)(PinnedBlogsSectionMobile);
