//React
import React, { Component } from "react";
import { compose } from "recompose";

//Material UI
import Button from "@material-ui/core/Button";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import { withStyles } from "@material-ui/core/styles";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";

//Icons
import ArrowForwardRoundedIcon from "@material-ui/icons/ArrowForwardRounded";
import PlayArrowRoundedIcon from "@material-ui/icons/PlayArrowRounded";
import CheckBoxOutlinedIcon from "@material-ui/icons/CheckBoxOutlined";

//Constants
import * as styleConstants from "../../../constants/styleConstants";

//react-router-dom
import { Link } from "react-router-dom";

//Animations
import Fade from "react-reveal/Fade";

//Others
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { GetAllBlogs } from "../../../services/blogServices";
const axios = require("axios").default;

const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  rootContainer: {
    maxWidth: "75%",
  },
  rootCard: {
    maxWidth: "75%",
    width: "75%",
    margin: 10,
  },
  gridContainer: {
    maxWidth: "90%",
  },
  margin: {
    margin: 100,
  },
  marginTop: {
    marginTop: 100,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(5),
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  header: {
    maxWidth: "80%",
  },
  body: {
    maxWidth: "80%",
    marginTop: 30,
  },
  divider: {
    maxWidth: "100%",
  },
  image: {
    margin: "auto",
    display: "block",
    margin: 10,
  },
  commentTextField: {
    width: "100%",
    maxWidth: "100%",
  },
  commentGridItem: {
    maxWidth: "80%",
    marginTop: 30,
    marginBottom: 100,
  },
});

const theme = createMuiTheme({
  typography: {
    fontFamily: ["Prompt"].join(","),
  },
});

class PodcastSection extends Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() { }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
    this.getBlogs();
  }

  render() {
    const { classes } = this.props;

    return (
      <Grid container>
        <Grid item align="center" xs={12}>
          <Grid container>
            <Grid item align="left" xs={12}>
              <Typography
                variant="h3"
                style={{
                  fontFamily: styleConstants.fontStyle,
                  fontWeight: styleConstants.fontWeight.bold,
                  color: "#991514",
                }}
              >
                PODCAST
              </Typography>
            </Grid>
            <Grid item xs={12} align="center" style={{ marginTop: "10%", zIndex: 1 }}>
              <iframe
                id="player_iframe"
                src="https://www.buzzsprout.com/1865725?client_source=large_player&iframe=true&referrer=https%3A%2F%2Fwww.buzzsprout.com%2F1865725%2Fpodcast%2Fembed"
                loading="lazy"
                width="100%"
                height="375"
                frameborder="0"
                scrolling="no"
                title="That’s What Happened !"
              ></iframe>
            </Grid>
            <Grid item xs={12} align="center" style={{ marginTop: "15%" }}>
              <Fade bottom>
                <Link
                  to="/free-course"
                  style={{ textDecoration: "none", color: "#0f0f0f" }}
                >
                  <Button
                    variant="contained"
                    size="large"
                    endIcon={<ArrowForwardRoundedIcon />}
                    style={{ backgroundColor: "white" }}
                  >
                    <Typography
                      variant="h6"
                      style={{ fontFamily: styleConstants.fontStyle, fontWeight: styleConstants.fontWeight.bold, }}
                    >
                      See more
                    </Typography>
                  </Button>
                </Link>
              </Fade>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default compose(withStyles(useStyles))(PodcastSection);
