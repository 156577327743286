//React
import { Component } from "react";
import { compose } from "recompose";

//Material UI
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FilterListRoundedIcon from "@material-ui/icons/FilterListRounded";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import Skeleton from "@material-ui/lab/Skeleton";

//react-router-dom
import { Link } from "react-router-dom";

//Others
import moment from 'moment';
import { GetAllSpeakingLog } from "../../../../services/testServices";

const axios = require("axios").default;
const FileDownload = require("js-file-download");

const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
});

class AllTests extends Component {
  constructor() {
    super();
    this.state = {
      numberOfTest: 0,
      filterType: "all",
      scoreArr: [],
      isFullLoading: true
    };
  }

  componentDidMount = async () => {
    this.setState({
      isFullLoading: true
    });

    await this.handleGetAllSubmittedTests();
    await this.handleGetAllSpeakingLog();

    this.setState({
      isFullLoading: false
    });
  };

  handleChange = (event) => {
    const name = event.target.name;

    this.setState({
      ...this.state,
      [name]: event.target.value,
    });
  };

  handleGetAllSubmittedTests = async () => {
    try {
      const res = await axios({
        method: "get",
        url: "https://chatter-funky-busby.glitch.me/tests",
      });

      const resData = res.data;
      resData.tests = resData.tests.filter((test) => { return test.submitterUsername ? (!["bellebelle", "117337903114766421177"].includes(test.submitterUsername) && !["bellebelle", "117337903114766421177", "113282161277766108210"].includes(test.teacherUsername)) : false });

      this.setState({
        tests: resData.tests,
        numberOfTest: resData.tests.length <= 10 ? resData.tests.length : 10,
      });
    } catch (err) {
      console.log(err);
    }
  };

  handleGetAllSpeakingLog = async () => {
    const res = await GetAllSpeakingLog();

    if (res.success) {
      await res.res.data.filter((test) => { return test.user_email ? !["b.billybillo@gmail.com", "jirayu.promsongwong@hotmail.com"].includes(test.user_email) : false }).map((obj, index) => {
        this.state.tests.push({
          id: obj.id,
          submitter: obj.user_name,
          createdAt: obj.created_at,
          testCategory: "IELTS",
          testType: "Speaking",
          assignTo: obj.teacher_name,
          teacherUsername: obj.teacher_username,
          score: obj.score,
          timeLeft: obj.time_left,
          tags: ["IELTS", "Speaking", "Full"],
        });
      });

      this.setState({
        tests: this.state.tests,
      });
    }
  };

  handleDownloadTest = async (fileName) => {
    try {
      const res = await axios({
        method: "get",
        url: "https://chatter-funky-busby.glitch.me/tests/file/" + fileName,
        responseType: "blob",
      });

      await FileDownload(res.data, fileName);
    } catch (err) {
      console.log(err);
    }
  };

  handleSeeMoreTest = () => {
    if (this.state.numberOfTest + 10 >= this.state.tests.length) {
      this.setState({
        numberOfTest: this.state.tests.length,
      });

      return;
    }

    this.setState({
      numberOfTest: this.state.numberOfTest + 10,
    });
  };

  handleSeeLessTest = () => {
    if (this.state.numberOfTest - 10 <= 10) {
      this.setState({
        numberOfTest: 10,
      });

      return;
    }

    this.setState({
      numberOfTest: this.state.numberOfTest - 10,
    });
  };

  //================== FILTER RELATED ======================
  handleChipClick = (type) => {
    this.setState({
      filterType: type,
    });
  };

  handleSelectFilterTestStatus = (event) => {
    if (event === null) {
      this.setState({
        filteredTestStatus: "all",
      });
    } else if (event !== null) {
      this.setState({
        filteredTestStatus: event.value,
      });
    }
  };
  //================== FILTER RELATED ======================

  handleColorChip = (tag) => {
    if (tag === "Full") {
      return (
        <Chip
          label={tag}
          style={{ margin: "5px", backgroundColor: "#76323F", color: "white" }}
        />
      );
    } else if (tag === "IELTS") {
      return (
        <Chip
          label={tag}
          style={{ margin: "5px", backgroundColor: "#141951", color: "white" }}
        />
      );
    } else {
      return <Chip label={tag} style={{ margin: "5px" }} />;
    }
  };

  handleAddLeadingZero = (num) => {
    if (num < 10) {
      return "0" + num;
    } else if (num >= 10) {
      return "" + num;
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <Grid item xs={12} sm={9}>
        <Grid container>
          <Grid item xs={12}>
            <Typography align="left" variant="h5" gutterBottom>
              <Box fontWeight="fontWeightBold" display="inline">
                All Tests
              </Box>
            </Typography>
            <Grid item align="left" xs={12}>
              <Grid container>
                <Grid item xs>
                  <Chip
                    avatar={
                      <FilterListRoundedIcon
                        style={{
                          color: this.state.filterType === "all" ? "white" : "",
                        }}
                      />
                    }
                    label="All"
                    onClick={() => {
                      this.handleChipClick("all");
                    }}
                    style={{
                      margin: "5px",
                      backgroundColor:
                        this.state.filterType === "all" ? "#76323F" : "",
                      color: this.state.filterType === "all" ? "white" : "",
                    }}
                  />
                  <Chip
                    avatar={
                      <FilterListRoundedIcon
                        style={{
                          color:
                            this.state.filterType === "Free" ? "white" : "",
                        }}
                      />
                    }
                    label="Free test"
                    onClick={() => {
                      this.handleChipClick("Free");
                    }}
                    style={{
                      margin: "5px",
                      backgroundColor:
                        this.state.filterType === "Free" ? "#76323F" : "",
                      color: this.state.filterType === "Free" ? "white" : "",
                    }}
                  />
                  <Chip
                    avatar={
                      <FilterListRoundedIcon
                        style={{
                          color:
                            this.state.filterType === "Full" ? "white" : "",
                        }}
                      />
                    }
                    label="Full test"
                    onClick={() => {
                      this.handleChipClick("Full");
                    }}
                    style={{
                      margin: "5px",
                      backgroundColor:
                        this.state.filterType === "Full" ? "#76323F" : "",
                      color: this.state.filterType === "Full" ? "white" : "",
                    }}
                  />
                  <Chip
                    avatar={
                      <FilterListRoundedIcon
                        style={{
                          color:
                            this.state.filterType === "Writing" ? "white" : "",
                        }}
                      />
                    }
                    label="Writing"
                    onClick={() => {
                      this.handleChipClick("Writing");
                    }}
                    style={{
                      margin: "5px",
                      backgroundColor:
                        this.state.filterType === "Writing" ? "#76323F" : "",
                      color: this.state.filterType === "Writing" ? "white" : "",
                    }}
                  />
                  <Chip
                    avatar={
                      <FilterListRoundedIcon
                        style={{
                          color:
                            this.state.filterType === "Speaking" ? "white" : "",
                        }}
                      />
                    }
                    label="Speaking"
                    onClick={() => {
                      this.handleChipClick("Speaking");
                    }}
                    style={{
                      margin: "5px",
                      backgroundColor:
                        this.state.filterType === "Speaking" ? "#76323F" : "",
                      color:
                        this.state.filterType === "Speaking" ? "white" : "",
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Paper
              variant="outlined"
              style={{ overflowY: "auto", maxHeight: "800px" }}
            >
              {(this.state.tests !== undefined && !this.state.isFullLoading) ? (
                this.state.tests
                  .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                  .filter((test) =>
                    this.state.filterType !== "all"
                      ? test.tags.some((tag) => tag === this.state.filterType)
                      : true
                  )
                  .map((test, index) => (
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Grid container spacing={0}>
                          <Grid align="left" item xs={12} sm>
                            <Typography>
                              {test.submitter}'s {test.testType.toLowerCase()}{" "}
                              test
                            </Typography>
                            <Typography variant="caption" color="textSecondary">
                              {moment(test.createdAt).format('MMMM Do YYYY, h:mm:ss A')}
                            </Typography>
                          </Grid>
                          <Grid align="right" item xs={12} sm>
                            {test.tags.map((tag) => this.handleColorChip(tag))}
                            {test.assignTo === "Unassigned" && (
                              <Chip
                                label={test.assignTo}
                                style={{
                                  margin: "5px",
                                  backgroundColor: "orange",
                                  color: "white",
                                }}
                              />
                            )}
                          </Grid>
                        </Grid>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={2}>
                          <Grid align="right" item xs={6} sm={3}>
                            <Typography>
                              <Box fontWeight="fontWeightBold" display="inline">
                                Tester:
                              </Box>
                            </Typography>
                            <Typography>
                              <Box fontWeight="fontWeightBold" display="inline">
                                Submit date:
                              </Box>
                            </Typography>
                            <Typography>
                              <Box fontWeight="fontWeightBold" display="inline">
                                Test category:
                              </Box>
                            </Typography>
                            <Typography>
                              <Box fontWeight="fontWeightBold" display="inline">
                                Type:
                              </Box>
                            </Typography>
                            <Typography>
                              <Box fontWeight="fontWeightBold" display="inline">
                                Assign to:
                              </Box>
                            </Typography>
                          </Grid>
                          <Grid align="left" item xs={6} sm={9}>
                            <Typography>{test.submitter}</Typography>
                            <Typography>
                              {new Date(test.createdAt).toDateString()}
                            </Typography>
                            <Typography>{test.testCategory}</Typography>
                            <Typography>{test.testType}</Typography>
                            <Typography>{test.assignTo}</Typography>
                          </Grid>
                          {test.testType.toLowerCase() === "speaking" && (
                            <Grid align="right" item xs={12}>
                              <Link
                                to={`/test/speaking/scoring?testid=&tester=${test?.teacherUsername
                                  }&comments=${test?.score?.comment !== null
                                    ? test.score?.comment
                                    : ""
                                  }&id=${test?.id?.split("_")[0]}&password=${test?.id?.split("_")[1]
                                  }`}
                                target="_blank"
                                style={{ textDecoration: "none" }}
                              >
                                <Button
                                  disableElevation
                                  size="small"
                                  variant="contained"
                                  style={{
                                    marginRight: "10px",
                                    backgroundColor: "#76323F",
                                    color: "white",
                                  }}
                                >
                                  Submit Score
                                </Button>
                              </Link>
                            </Grid>
                          )}

                          {test.testType.toLowerCase() !== "speaking" && (
                            <Grid align="right" item xs={12}>
                              <Button
                                disableElevation
                                size="small"
                                variant="contained"
                                startIcon={
                                  <GetAppRoundedIcon
                                    style={{ color: "white" }}
                                  />
                                }
                                style={{
                                  marginRight: "10px",
                                  backgroundColor: "#76323F",
                                  color: "white",
                                }}
                                onClick={() =>
                                  this.handleDownloadTest(test.fileName)
                                }
                              >
                                Download
                              </Button>
                            </Grid>
                          )}
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  ))
              ) : (
                <Grid>
                  <Skeleton variant="rect" width="100%" height={80} />
                </Grid>
              )}
            </Paper>
          </Grid>
          {/*<Grid item xs={12}>
                        {this.state.tests !== undefined && !(this.state.numberOfTest === this.state.tests.length) && 
                            <Button
                                variant="contained"
                                style={{ width: '100%', marginTop: '20px', backgroundColor: '#76323F', color: 'white' }}
                                onClick={this.handleSeeMoreTest}
                            >
                                See More (+10)
                            </Button>
                        }
                        {this.state.tests !== undefined && (this.state.numberOfTest === this.state.tests.length) && 
                            <Button
                                variant="contained"
                                style={{ width: '100%', marginTop: '20px', backgroundColor: '#76323F', color: 'white' }}
                                onClick={this.handleSeeLessTest}
                            >
                                See Less (-10)
                            </Button>
                        }
                    </Grid>*/}
        </Grid>
      </Grid>
    );
  }
}

export default compose(withStyles(useStyles))(AllTests);
