//React
import { Component } from 'react';
import { compose } from 'recompose';

//Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

//Icon
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';

//react-router-dom

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%'
    }
});

class UserInfoPage extends Component {
    constructor() {
        super();
    }

    render() {
        return (
            <Grid container justify="center">
                <Paper variant="outlined" style={{
                    padding: '50px',
                    backgroundImage: `url("/U-Test-Logo-new-bw.png")`,
                    backgroundSize: '120px',
                    backgroundRepeat: 'space'
                }}>
                    <Grid item align='left' xs={12}>
                        <Typography variant="h6" gutterBottom>
                            <Box fontWeight="fontWeightBold" display='inline'> Confirm your information </Box>
                        </Typography>
                    </Grid>
                    <Grid item align='left' xs={12}>
                        <Typography variant="subtitle2" gutterBottom>
                            Name: {this.props.user.name}
                        </Typography>
                        <Typography variant="subtitle2" gutterBottom>
                            Email: {this.props.user.username}
                        </Typography>
                        <Typography variant="subtitle2" gutterBottom>
                            School: {JSON.parse(localStorage.getItem('user')).school?.length === 0 ? "-" : JSON.parse(localStorage.getItem('user')).school}
                        </Typography>
                    </Grid>
                    <Grid item align='left' xs={12} style={{ marginTop: '20px' }}>
                        <Typography variant="subtitle2" gutterBottom>
                            <InfoRoundedIcon fontSize="small" /> If your details are not correct, please contact us.
                        </Typography>
                    </Grid>
                    <Grid item align='center' xs={12} style={{ marginTop: '20px' }}>
                        <Button size="small" variant="contained" onClick={this.props.handleUserInfoChecked} style={{
                            color: 'white',
                            backgroundColor: '#25333e'
                        }}>
                            <Box fontWeight="fontWeightBold" display='inline'> My details are correct </Box>
                        </Button>
                    </Grid>
                    <Grid item align='right' xs={12}>
                        <Typography
                            variant="subtitle2"
                            style={{ color: "#c8c8c8", fontWeight: "bold" }}
                        >
                            {btoa(this.props.user.username)}
                        </Typography>
                    </Grid>
                </Paper>
            </Grid>
        )
    }
}

export default compose(
    withStyles(useStyles)
)(UserInfoPage);