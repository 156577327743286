//React
import { Component } from 'react';
import { compose } from 'recompose';

//Material UI
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@material-ui/icons/KeyboardArrowUpRounded';

//react-router-dom
import {
    Link
} from "react-router-dom";

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%'
    },
    gridContainer: {
        marginTop: 100
    },
    questionDescriptionButton: {
        width: '100%',
        height: '100px'
    },
    questionButton: {
        width: '100%',
        height: '50px'
    },
    question: {
        paddingTop: 10,
        paddingLeft: 100
    },
    addSectionButton: {
        width: '100%',
        height: '100px'
    },
    margin: {
        margin: 10
    },
    padding: {
        paddig: 100
    },
    paddingTop: {
        paddingTop: 10
    }
});

class TestModification extends Component {
    constructor() {
        super();
        this.state = {
            sections: [
                {
                    name: 'Section 1',
                    order: 1,
                    questions: [
                        {
                            question: 'Boss is my name',
                            order: 1,
                            answer: "TRUE",
                            type: "t/f/ng"
                        }
                    ]
                }
            ],
            addNewQuestion: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.addNewSection = this.addNewSection.bind(this);
        this.upSection = this.upSection.bind(this);
        this.downSection = this.downSection.bind(this);
        this.handleCloseAddNewQuestion = this.handleCloseAddNewQuestion.bind(this);
        this.handleAddNewQuestion = this.handleAddNewQuestion.bind(this);
        this.addNewQuestion = this.addNewQuestion.bind(this);
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        })
        console.log(this.state)
    }

    async upSection(selectedSectionOrder) {
        if (selectedSectionOrder === 1) {
            return;
        }

        let filterSection = this.state.sections.filter(function (section) {
            return section.order !== selectedSectionOrder && section.order !== selectedSectionOrder - 1;
        })

        let selectedSection = this.state.sections.filter(function (section) {
            return section.order === selectedSectionOrder;
        })

        let upperSection = this.state.sections.filter(function (section) {
            return section.order === selectedSectionOrder - 1;
        })

        selectedSection[0].order = await selectedSection[0].order - 1
        upperSection[0].order = await upperSection[0].order + 1

        filterSection.push(selectedSection[0]);
        filterSection.push(upperSection[0]);

        this.setState({
            sections: filterSection
        });

    }

    async downSection(selectedSectionOrder) {
        let max = await Math.max.apply(Math, this.state.sections.map(function (section) { return section.order; }))
        if (selectedSectionOrder === max) {
            return;
        }

        let filterSection = this.state.sections.filter(function (section) {
            return section.order !== selectedSectionOrder && section.order !== selectedSectionOrder + 1;
        })

        let selectedSection = this.state.sections.filter(function (section) {
            return section.order === selectedSectionOrder;
        })

        let lowerSection = this.state.sections.filter(function (section) {
            return section.order === selectedSectionOrder + 1;
        })

        selectedSection[0].order = await selectedSection[0].order + 1
        lowerSection[0].order = await lowerSection[0].order - 1

        filterSection.push(selectedSection[0]);
        filterSection.push(lowerSection[0]);

        this.setState({
            sections: filterSection
        });
    }

    async addNewSection() {
        let max = await Math.max.apply(Math, this.state.sections.map(function (section) { return section.order; }))
        let newSectionOrder = max + 1;
        let addedObject = {
            name: 'Section ' + newSectionOrder,
            order: newSectionOrder,
            questions: [
            ]
        };
        this.state.sections.push(addedObject);
        this.setState({
            section: this.state.sections
        });

    }

    //======================================= BEGIN ADD NEW QUESTION ==============================================

    handleCloseAddNewQuestion() {
        this.setState({
            addNewQuestion: false
        })
    }

    handleAddNewQuestion(sectionName) {
        this.setState({
            addNewQuestion: true,
            selectedSection: sectionName
        })
    }

    async addNewQuestion(question, answer, type, section) {
        let max = await Math.max.apply(Math, this.state.sections.map(function (section) { return section.order; }))
        const selectedSectionIndex = this.state.sections.findIndex(element => element.name == section)
        let addedObject = {
            question: question,
            order: max + 1,
            answer: answer,
            type: type
        }

        this.state.sections[selectedSectionIndex].questions.push(addedObject);

        this.setState({
            addNewQuestion: false
        })
    }

    //======================================= END ADD NEW QUESTION ==============================================

    render() {
        const { classes } = this.props;

        const tfng = (
            <div>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Question"
                    variant="outlined"
                    name="newQuestionName"
                    onChange={this.handleChange}
                    fullWidth
                />
                <FormControl component="fieldset" className={classes.padding}>
                    <RadioGroup aria-label="answer" name="answer" value={this.state.answer} onChange={this.handleChange}>
                        <FormControlLabel value="true" control={<Radio />} label="TRUE" />
                        <FormControlLabel value="false" control={<Radio />} label="FALSE" />
                        <FormControlLabel value="not given" control={<Radio />} label="NOT GIVEN" />
                    </RadioGroup>
                </FormControl>
            </div>
        )

        const multiChoices = (
            <div>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Question"
                    variant="outlined"
                    name="newQuestionName"
                    onChange={this.handleChange}
                    fullWidth
                />
                <FormControl component="fieldset" className={classes.padding}>
                    <RadioGroup aria-label="answer" name="answer" value={this.state.answer} onChange={this.handleChange}>
                        <FormControlLabel value={this.state.A} control={<Radio />} label={<TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="A"
                            variant="outlined"
                            name="A"
                            onChange={this.handleChange}
                            fullWidth
                        />} />
                        <FormControlLabel value={this.state.B} control={<Radio />} label={<TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="B"
                            variant="outlined"
                            name="B"
                            onChange={this.handleChange}
                            fullWidth
                        />} />
                        <FormControlLabel value={this.state.C} control={<Radio />} label={<TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="C"
                            variant="outlined"
                            name="C"
                            onChange={this.handleChange}
                            fullWidth
                        />} />
                        <FormControlLabel value={this.state.D} control={<Radio />} label={<TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="D"
                            variant="outlined"
                            name="D"
                            onChange={this.handleChange}
                            fullWidth
                        />} />
                    </RadioGroup>
                </FormControl>
            </div>
        )

        const addNewQuestion = (
            <Dialog open={this.state.addNewQuestion} onClose={this.handleCloseAddNewQuestion} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title" >
                    Please complete all required task to add new question
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Adding new question.
                    </DialogContentText>
                    <FormControl margin="dense" variant="outlined" fullWidth>
                        <InputLabel htmlFor="outlined-age-native-simple">Type</InputLabel>
                        <Select
                            native
                            value={this.state.questionType}
                            onChange={this.handleChange}
                            label="questionType"
                            name="questionType"
                            inputProps={{
                                id: 'outlined-age-native-simple',
                            }}
                        >
                            <option aria-label="None" value="" />
                            <option value={"t/f/ng"}>TRUE/FALSE/NOT GIVEN</option>
                            <option value={"multiplechoices"}>Multiple Choices</option>
                            <option value={"notecompletion"}>Note Completion</option>
                            <option value={"listofheadings"}>List of Headings</option>
                        </Select>
                    </FormControl>
                    {this.state.questionType === "t/f/ng" && tfng}
                    {this.state.questionType === "multiplechoices" && multiChoices}
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCloseAddNewQuestion} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => { this.addNewQuestion(this.state.newQuestionName, this.state.answer, this.state.questionType, this.state.selectedSection) }} color="primary">
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
        )

        return (
            <div className={classes.root}>
                <Container maxWidth="lg">
                    <Grid container alignItems='center' className={classes.gridContainer}>
                        <Grid item xs={12}>
                            <Grid container spacing={0}>
                                <Grid item align='left' xs={1}>
                                    <Link to="/test/management" color="inherit" underline="none" style={{ textDecoration: 'none', color: '#0f0f0f' }}>
                                        <IconButton color="inherit">
                                            <ArrowBackIosRoundedIcon />
                                        </IconButton>
                                    </Link>
                                </Grid>
                                <Grid item xs={11} align='left' style={{ marginTop: 3 }}>
                                    <Typography align='left' variant="h4">
                                        {this.props.match.params.id}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        {this.state.sections.sort((a, b) => a.order > b.order ? 1 : -1).map((section) => (
                            <Grid container alignItems='center'>
                                <Grid item xs={10}>
                                    <Button variant="outlined" className={classes.questionDescriptionButton}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Typography align='left' variant="button" gutterBottom>
                                                    {section.name}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Button>
                                </Grid>
                                <Grid item xs={2}>
                                    <Grid container>
                                        <Grid item xs={12} className={classes.margin}>
                                            <Button variant="outlined" onClick={() => { this.handleAddNewQuestion(section.name) }}>
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <AddRoundedIcon />
                                                    </Grid>
                                                </Grid>
                                            </Button>
                                        </Grid>
                                        {section.order !== 1 &&
                                            <Grid item xs={12} className={classes.margin}>
                                                <Button variant="outlined" onClick={() => { this.upSection(section.order) }}>
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <KeyboardArrowUpRoundedIcon />
                                                        </Grid>
                                                    </Grid>
                                                </Button>
                                            </Grid>
                                        }
                                        {section.order !== Math.max.apply(Math, this.state.sections.map(function (section) { return section.order; })) &&
                                            <Grid item xs={12} className={classes.margin}>
                                                <Button variant="outlined" onClick={() => { this.downSection(section.order) }}>
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <KeyboardArrowDownRoundedIcon />
                                                        </Grid>
                                                    </Grid>
                                                </Button>
                                            </Grid>
                                        }
                                        <Grid item xs={12} className={classes.margin}>
                                            <Divider variant="middle" />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {section.questions.sort((a, b) => a.order > b.order ? 1 : -1).map((question) => (
                                    <Grid item xs={10} className={classes.question}>
                                        <Button variant="contained" color="primary" className={classes.questionButton}>
                                            <Grid container align='left'>
                                                <Grid item xs={12}>
                                                    <Typography align='left' variant="subtitle2">
                                                        Question: {question.question}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography align='left' variant="subtitle2">
                                                        Answer: {question.answer}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Button>
                                    </Grid>
                                ))}
                            </Grid>
                        ))}
                        <Grid item xs={10} align='center' className={classes.paddingTop}>
                            <Button variant="outlined" onClick={this.addNewSection} className={classes.addSectionButton}>
                                <Grid container justify='center'>
                                    <Grid item xs={12}>
                                        <AddBoxRoundedIcon />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography align='left' variant="button" gutterBottom>
                                            ADD SECTION
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Button>
                        </Grid>
                    </Grid>
                    {addNewQuestion}
                </Container>
            </div>
        )
    }
}

export default compose(
    withStyles(useStyles)
)(TestModification);