import React from 'react';

//Material UI
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import MuiAlert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

//Others
import { withStyles } from '@material-ui/core/styles';
import { Component } from 'react';
import { compose } from 'recompose';
import Select from 'react-select'

const useStyles = theme => ({
    root: {
        margin: 15,
        marginTop: 50
    },
    margin: {
        margin: theme.spacing(1),
    },
    buttonColor: {
        backgroundColor: '#76323F',
        color: 'white'
    }
});

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class CompleteTheNotes extends Component {
    constructor() {
        super()
        this.state = {
            tempAnswer: [1],
            answerCount: 1
        };
    }

    componentDidMount = async () => {

    }

    handleEditVoiceUrl = async (event) => {
        this.props.questionObj.voice = event.target.value;
    }

    handleEditQuestion = async (event) => {
        this.props.questionObj.question = event.target.value;
    }

    handleEditContext = async (event) => {
        this.props.questionObj.text = event.target.value;
    }

    handleEditQuestionNumber = async (event) => {
        this.props.questionObj.no = `${event.target.value}-${Number(event.target.value) + 9}`;
        this.setState({
            beginQuestion: Number(event.target.value)
        });

        console.log(this.props.questionObj)
    }

    handleEditAnswer = async (event, index) => {
        this.props.questionObj.answer[index] = event.target.value;
    }

    handleAddAnswer = () => {
        this.state.tempAnswer.push(this.state.answerCount + 1);

        this.setState({
            tempAnswer: this.state.tempAnswer,
            answerCount: this.state.answerCount + 1
        });
    }

    render() {
        const { classes } = this.props;

        return (
            <Grid item xs={12}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                                            Voice Url
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="outlined-size-small"
                                            variant="outlined"
                                            size="small"
                                            onChange={this.handleEditVoiceUrl}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                                            Question
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="outlined-size-small"
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                                            Context
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="outlined-size-small"
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            multiline
                                            maxRows={6}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                                            Answer
                                        </Typography>
                                    </Grid>
                                    {this.state.tempAnswer.map((answer, ansIdx) =>
                                        <Grid key={`answer-${ansIdx}`} item xs={12}>
                                            <Grid container spacing={1}>
                                                <Grid item align='center' xs={1}>
                                                    <TextField
                                                        id="outlined-size-small"
                                                        variant="outlined"
                                                        size="small"
                                                        label="No."
                                                        value={(this.state.beginQuestion === undefined || this.state.beginQuestion === 0) ? "" : this.state.beginQuestion + ansIdx}
                                                        onChange={this.handleEditQuestionNumber}
                                                        disabled={ansIdx !== 0}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={11}>
                                                    <TextField
                                                        id="outlined-size-small"
                                                        variant="outlined"
                                                        size="small"
                                                        label="Answer"
                                                        onChange={(event) => { this.handleEditAnswer(event, ansIdx) }}
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )}
                                    <Grid item align="center" xs={12}>
                                        <IconButton aria-label="add" onClick={this.handleAddAnswer} style={{ margin: 1 }}>
                                            <AddCircleRoundedIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}
export default compose(
    withStyles(useStyles)
)(CompleteTheNotes);