import React from 'react';

import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import MuiAlert from '@material-ui/lab/Alert';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { Container } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Component } from 'react';
import { compose } from 'recompose';

//Icon
import InboxIcon from '@material-ui/icons/Inbox';
import DraftsIcon from '@material-ui/icons/Drafts';

//Others
const axios = require('axios').default;
const FormData = require('form-data');
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

const useStyles = theme => ({
    root: {
        margin: 15,
        marginTop: 50
    },
    large: {
      width: theme.spacing(20),
      height: theme.spacing(20)
    }
});

class Menus extends Component {
    constructor() {
        super()
        this.state = {
            breakPoints: [
                { width: 1, itemsToShow: 1 },
                { width: 600, itemsToShow: 2 },
                { width: 900, itemsToShow: 3 },
                { width: 1200, itemsToShow: 4 },
            ],
            window: {
                height: window.innerHeight,
                width: window.innerWidth
            }
        };
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    handleResize = (event) => {
        this.setState({
            window: {
                height: window.innerHeight,
                width: window.innerWidth
            }
        });
    };

    render() {
        const { classes } = this.props;

        return (
            <Paper style={{ padding: '40px' }}>
                <Grid container>
                    <Grid item align="center" xs={12}>
                        <Avatar alt={JSON.parse(localStorage.getItem('user')).name} src={JSON.parse(localStorage.getItem('user')).picture} className={classes.large} />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '20px' }}>
                        <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold' }}>
                            {JSON.parse(localStorage.getItem('user')).name}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '20px' }}>
                        <Divider variant="middle" />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '20px' }}>
                        <List component="nav" aria-label="main mailbox folders">
                            <ListItem selected={this.props.selectedMenu === 'allTests' || this.props.selectedMenu === 'editTest'} button onClick={() => {this.props.handleSelectMenu('allTests')}}>
                                <ListItemText primary="All tests" />
                            </ListItem>
                            <ListItem selected={this.props.selectedMenu === 'allParts' || this.props.selectedMenu === 'editParts'} button onClick={() => {this.props.handleSelectMenu('allParts')}}>
                                <ListItemText primary="All sections" />
                            </ListItem>
                            {/*<ListItem selected={this.props.selectedMenu === 'allQuestions' || this.props.selectedMenu === 'editQuestions'} button onClick={() => {this.props.handleSelectMenu('allQuestions')}}>
                                <ListItemText primary="All questions" />
                            </ListItem>*/}
                            <ListItem selected={this.props.selectedMenu === 'allStrategy' || this.props.selectedMenu === 'editStrategy'} button onClick={() => {this.props.handleSelectMenu('allStrategy')}}>
                                <ListItemText primary="All strategy" />
                            </ListItem>
                            {/*<ListItem selected={this.props.selectedMenu === 'createNewPart'} button onClick={() => {this.props.handleSelectMenu('createNewPart')}}>
                                <ListItemText primary="Create new section" />
                            </ListItem>
                            <ListItem selected={this.props.selectedMenu === 'addNewSection'} button onClick={() => {this.props.handleSelectMenu('addNewSection')}}>
                                <ListItemText primary="Create new question" />
                            </ListItem>
                            <ListItem selected={this.props.selectedMenu === 'createNewStrategy'} button onClick={() => {this.props.handleSelectMenu('createNewStrategy')}}>
                                <ListItemText primary="Create new strategy" />
                            </ListItem>*/}
                        </List>
                    </Grid>
                </Grid>
            </Paper>
        )
    }
}
export default compose(
    withStyles(useStyles)
)(Menus);