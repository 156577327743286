//React
import { Component } from 'react';
import { compose } from 'recompose';

//Material UI
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

//import components
import reactStringReplace from 'react-string-replace';
import { DragDropContainer, DropTarget } from 'react-drag-drop-container';
import { Button, Paper } from '@material-ui/core';

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%'
    },
    paperRoot: {
        padding: theme.spacing(2),
        textAlign: 'left'
    },
    paperItem: {
        padding: theme.spacing(2),
        textAlign: 'left',
        height: '810px',
        maxHeight: '810px',
        overflow: 'auto',
    },
    testAlignLeft: {
        padding: theme.spacing(2),
    },
    gridContainerRoot: {
        marginTop: 80
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    marginLeft: {
        marginLeft: 20
    },
    marginTop: {
        marginTop: 20
    },
    margin: {
        margin: 5
    }
});

class NoteCompletion extends Component {
    constructor() {
        super();

        this.state = {
            draggedItem: '',
            draggedItemToShow: '',
            dropItems: [],
            dropItemsToShow: [],
            answerArr: [],
            sizeMapHeader: {
                'sm': 'subtitle2',
                'md': 'body2',
                'lg': 'body1',
                'xl': 'h6',
            },
            sizeMapBody: {
                'sm': 0.6,
                'md': 0.7,
                'lg': 1,
                'xl': 1.2,
            },
            sizeMapCaption: {
                'sm': 'subtitle2',
                'md': 'body2',
                'lg': 'body1',
                'xl': 'h6',
            }
        };
    }

    componentDidMount = async () => {
        console.log(this.props.sectionInfo.questions)
        const filteredAnsObj = await this.props.answerObj?.parts[this.props.currentPart - 1].sections.find((element) => {
            return element.section_id === this.props.sectionInfo.section_id;
        })
        console.log(filteredAnsObj);
        this.setState({
            answerObj: {
                section_id: this.props.sectionInfo.section_id,
                question_type: this.props.sectionInfo.question_type,
                answers: filteredAnsObj.answers.length === 0 ? {} : filteredAnsObj.answers
            }
        })

        if (filteredAnsObj !== undefined) {
            const objectArray = Object.entries(filteredAnsObj.answers);
            await objectArray.forEach(([key, value]) => {
                this.state.answerArr[key] = value
            });

            this.setState({
                answerArr: this.state.answerArr
            });
        }
    }

    handleAnswerQuestion = (event, questionNo) => {
        const questionKey = '' + questionNo //Convert integer to string
        this.state.answerArr[questionNo] = event.target.value;
        this.state.answerObj.answers[questionKey] = event.target.value;
        this.props.handleAnswerObj(this.state.answerObj);
        this.props.handleAnsweredQuestionNumber(questionNo);
    }

    onDragged = (index) => {
        if (this.state.draggedItem !== undefined) {
            console.log(index)
            this.state.dropItems[index] = this.state.draggedItem
            this.state.dropItemsToShow[index] = this.state.draggedItemToShow
            // this.state.answerObj.answers[this.props.sectionInfo.questions[0].no] = this.state.dropItems
            // this.props.handleAnswerObj(this.state.answerObj);
            this.props.handleAnsweredQuestionNumber(Number(this.props.sectionInfo.questions[0].no) + index);
            this.setState({
                dropItemsToShow: this.state.dropItemsToShow,
                draggedItem: undefined
            });
        }
    }

    onDragStart = (item) => {
        this.setState({
            draggedItem: item,
            draggedItemToShow: item
        });
    }

    onClickAnswer = (index) => {
        this.state.dropItemsToShow[index] = undefined;
        this.props.handleCancelQuestionNumber(Number(this.props.sectionInfo.questions[0].no) + index);
        this.setState({
            dropItemsToShow: this.state.dropItemsToShow
        });

        this.props.onFocusQuestion(Number(this.props.sectionInfo.questions[0].no) + index);
    }

    render() {
        const { classes } = this.props;
        let count = 0;

        return (
            this.props.sectionInfo.questions[0].type === "sentence" ? (<Grid container style={{ marginTop: '20px' }}>
                {/* =================================== SECTION 2 =================================== */}
                <Grid item align="left" xs={12}>
                    <Typography variant={this.state.sizeMapHeader[`${this.props.fontSizeMap}`]} gutterBottom>
                        <Box fontWeight="fontWeightBold" display='inline'>{this.props.sectionInfo.section}</Box>
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant={this.state.sizeMapBody[`${this.props.fontSizeMap}`]} style={{ textAlign: "justify", lineHeight: 2 }}>
                        {reactStringReplace(this.props.sectionInfo.questions[0].text, '_', (match, i, offset) => {
                            count++
                            const idx = count;
                            return (<DropTarget
                                targetKey="MatchingSentenceEnding"
                                onChange={this.props.handleChange}
                                onHit={() => {
                                    this.onDragged((idx - 1));
                                }}
                            >
                                <Button
                                    size="small"
                                    id="outlined-basic"
                                    variant="outlined"
                                    style={{ width: '20%', fontWeight: 'bold', backgroundColor: this.state.dropItemsToShow[(idx - 1)] === undefined ? 'white' : '#dbe9ee' }}
                                    onClick={() => { this.onClickAnswer((idx - 1)) }}
                                >
                                    <Typography style={{ fontSize: `${this.state.sizeMapBody[`${this.props.fontSizeMap}`]}rem`, fontWeight: "bold" }}>
                                        {this.state.dropItemsToShow[(idx - 1)] === undefined ? (Number(this.props.sectionInfo.questions[0].no) + (idx - 1)) : this.state.dropItemsToShow[(idx - 1)].length <= 1 ? this.state.dropItemsToShow[(idx - 1)] : (this.state.dropItemsToShow[(idx - 1)])}
                                    </Typography>
                                </Button>
                            </DropTarget>
                        )})}
                    </Typography>
                </Grid>
                <Grid item xs={12} style={{ marginTop: '10px', zoom: this.props.isRetina ? 1.2 : 1 }}>
                    <Grid container align='left' spacing={1}>
                        {this.props.sectionInfo.questions[0].choice.map((q, index) =>
                            !this.state.dropItemsToShow.includes(q) && <Grid item xs={12}>
                                <DragDropContainer targetKey="MatchingSentenceEnding" onDragStart={() => { this.onDragStart(q) }} >
                                    <Paper style={{ padding: '5px' }}>
                                        <Typography style={{ fontWeight: 'bold', fontSize: `${this.state.sizeMapBody[`${this.props.fontSizeMap}`] - (this.props.isRetina ? 0.2 : 0)}rem` }}>
                                            {q}
                                        </Typography>
                                    </Paper>
                                </DragDropContainer>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>) : (<Grid container style={{ marginTop: '20px' }}>
                {/* =================================== SECTION 2 =================================== */}
                <Grid item align="left" xs={12}>
                    <Typography variant={this.state.sizeMapHeader[`${this.props.fontSizeMap}`]} gutterBottom>
                        <Box fontWeight="fontWeightBold" display='inline'>{this.props.sectionInfo.section}</Box>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    {this.props.sectionInfo.questions.map(q =>
                        <Typography align='left' className={classes.testAlignLeft} style={{ fontSize: `${this.state.sizeMapBody[`${this.props.fontSizeMap}`]}rem` }}>
                            {q.text.split("_")[0]}  <TextField size="small" id="outlined-basic" variant="outlined" placeholder={Number(q.no)} value={this.state.answerArr[Number(q.no)]} onFocus={() => { this.props.onFocusQuestion(Number(q.no)) }} onChange={(event) => { this.handleAnswerQuestion(event, q.no) }} inputProps={{ style: { textAlign: 'center', height: '3px' } }} />
                            {q.text.split("_")[1]}
                        </Typography>
                    )}
                </Grid>
            </Grid>)
        )
    }
}

export default compose(
    withStyles(useStyles)
)(NoteCompletion);