//React
import { Component } from 'react';
import { compose } from 'recompose';

//Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';

//Others libraries
import Chart from "react-google-charts";
//react-router-dom
import {
    Link
} from "react-router-dom";

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%'
    }
});

class TestReportPage extends Component {
    constructor() {
        super();
        this.state = {
            testReportObj: {},
            score: [
                ['Question', 'Score']
            ],
            question: [
                ['Question', 'Number']
            ]
        };
    }

    componentDidMount = async () => {
        document.body.style.overflow = "auto";
        /*if (this.props.testReportObj.length === 0) {
            const filteredTestReportObj = await this.props.testReportObj.parts.filter(part => part.part_type === 'LISTENING');
            console.log(filteredTestReportObj);
            this.setState({
                testReportObj: filteredTestReportObj
            })
        }*/
    }

    handleAddData = (sectionObj, index) => {
        this.state.question.push([sectionObj.question_type.toUpperCase(), sectionObj.question])
        this.state.score.push([sectionObj.question_type.toUpperCase(), sectionObj.score])
    }

    render() {
        const { classes } = this.props;
        return (
            <Grid container>
                <Grid item xs={12} align='center'>
                    <Typography variant="h4" gutterBottom style={{ fontSize: '3rem' }}>
                        <Box fontWeight="fontWeightBold" display='inline'>Result</Box>
                    </Typography>
                </Grid>
                <Grid item xs={12} align='center' style={{ marginBottom: '50px' }}>
                    <Typography variant="h4" gutterBottom style={{ fontSize: '2rem' }}>
                        Listening
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container>
                        {this.props.testReportObj !== undefined && this.props.testReportObj.parts.filter(part => part.part_type === 'LISTENING').map((part, index) =>
                            <Grid item xs={6}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography variant="h4" gutterBottom align='left' style={{ fontSize: '1.5rem' }}>
                                            <Box fontWeight="fontWeightBold" display='inline'>{'Part: ' + part.part}</Box>
                                        </Typography>
                                    </Grid>
                                    {part.section.map((section) =>
                                        <Grid item xs={12} sm={6}>
                                            <Chart
                                                width={'500px'}
                                                height={'300px'}
                                                chartType="ColumnChart"
                                                loader={<div>Loading Chart</div>}
                                                data={[
                                                    ['Name', 'Score', { role: 'annotation' }, { role: 'style' }],
                                                    ['Full Score', section.question === undefined ? 0 : section.question, section.question === undefined ? 0 : section.question, '#e5e4e2'],
                                                    ['My Score', section.score === undefined ? 0 : section.score, section.score === undefined ? 0 : section.score, '#76323F']
                                                ]}
                                                options={{
                                                    title: 'Question type: ' + section.question_type.toUpperCase(),
                                                    legend: { position: 'top' },
                                                    bar: { groupWidth: '75%' },
                                                    legend: { position: "none" }
                                                }}
                                                rootProps={{ 'data-testid': '4' }}
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <Grid item xs={12} align='center'>
                    <Link to="/" style={{ textDecoration: 'none', color: '#0f0f0f' }}>
                        <Button size="small" startIcon={<HomeRoundedIcon style={{ color: 'grey' }} />} onClick={this.props.handleEndTest}>
                            <Box fontWeight="fontWeightBold" display='inline' style={{ color: 'grey' }}> Home </Box>
                        </Button>
                    </Link>
                </Grid>
            </Grid>
        )
    }
}

export default compose(
    withStyles(useStyles)
)(TestReportPage);