//React
import { Component } from 'react';
import { compose } from 'recompose';
import { Prompt } from 'react-router-dom';

//Material UI
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';

import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';

//import components
import VerificationPage from '../../verification/VerificationPage';
import ReadingRender from './tests/ReadingRender';
import WritingRender from './tests/WritingRender';

//Dialogs
import RegistrationFormDialog from "../../dialogs/RegistrationFormDialog";
import LeaveFullScreenDialog from "../../dialogs/LeaveFullScreenDialog";

import { CreateTestSurveyLog } from '../../../../services/testServices';
import { CreateNewNotification } from '../../../../services/notificationServices';
import MediaQuery from 'react-responsive';
import ListeningRetina from './tests/ListeningRetina';
import TestListenting from './tests/TestListenting';
import { Button } from '@material-ui/core';

const axios = require('axios').default;
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

//FONT RELATED
const theme = createMuiTheme({
    typography: {
        fontFamily: [
            'Noto Sans'
        ].join(','),
    },
});

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%'
    }
});

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class DummyTestMain extends Component {
    constructor() {
        super();
        this.state = {
            window: {
                height: window.innerHeight,
                width: window.innerWidth
            },
            readingAnwerObj: {},
            listeningAnwerObj: {},
            isLoading: true,
            isOpenDialog: true,
            isFreeTest: true,
            isChecked: true,
            isDoneReport: false,
            answerObj: {
                parts: []
            },
            isFullScreen: true,
            isTestDone: false,
            isContinueTablet: false
        };
    }

    componentDidMount = async () => {
        document.body.style.backgroundColor = "#efefef";
        document.addEventListener('contextmenu', (e) => {
            e.preventDefault();
        });
        this.props.handleStartTest();
        // localStorage.setItem('readingSession', null);
        // localStorage.setItem('listeningSession', null);
        // localStorage.setItem('writingSession', null);
        // localStorage.setItem('testSession', null);
        await this.checkSession();
        this.setState({
            startTestTime: new Date()
        });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    checkSession = async () => {
        // if (JSON.parse(localStorage.getItem("testSession")) !== null) {
        //     await this.setState(JSON.parse(localStorage.getItem("testSession")));
        // } else {
        //     await this.handleGetPurchasedCourse();
        //     await this.handleCheckCourseId();
        // }

        // if (this.state.regitrationFormResult === undefined) {
        //     this.setState({
        //         regitrationFormResult: {},
        //         isOpenDialog: true
        //     });
        // }

        // await this.handleEndTest()
        await this.handleGetPurchasedCourse();
        await this.handleCheckCourseId();
    }

    DoFullScreen = () => {
        this.setState({
            isTestDone: false
        });

        document.getElementById('root').requestFullscreen().then(() => {
            console.log("Enter full-screen mode");
            document.getElementById("root").addEventListener("fullscreenchange", (event) => { this.onFullScreenChange(event) });

        }, () => {
            console.log("Can't access to full-screen mode");
            this.setState({
                isFullScreen: false
            })
        });
    }

    ExitFullScreen = () => {
        this.setState({
            isTestDone: true
        });

        document
            .exitFullscreen()
            .then(() => console.log("Exited from Full screen mode"))
            .catch((err) => console.error(err));

    }

    onFullScreenChange = (event) => {
        if (document.fullscreenElement) {
            this.setState({
                isFullScreen: true
            })
        } else {
            this.setState({
                isFullScreen: false
            })
        }
    };

    handleResize = (event) => {
        this.setState({
            window: {
                height: window.innerHeight,
                width: window.innerWidth
            }
        });
    };

    handleGetPurchasedCourse = async () => {
        try {
            this.setState({
                purchasedCourse: [],
                startTestTime: new Date()
            });

        } catch (error) {
            console.error(error);
            this.setState({
                purchasedCourse: []
            });
        }
    }

    handleCheckCourseId = async () => {
        const query = new URLSearchParams(this.props.location.search);
        const testType = query.get('test_type');

        this.setState({
            purchasedTestId: '',
            currentTest: testType.toUpperCase(),
            isLoading: false
        })
    }

    keepCurrentSession = (session) => {
        if (this.state.currentTest === "LISTENING") {
            localStorage.setItem('listeningSession', JSON.stringify(session));
            localStorage.setItem('testSession', JSON.stringify(this.state));
        } if (this.state.currentTest === "READING") {
            localStorage.setItem('readingSession', JSON.stringify(session));
            localStorage.setItem('testSession', JSON.stringify(this.state));
        } if (this.state.currentTest === "WRITING") {
            localStorage.setItem('writingSession', JSON.stringify(session));
            localStorage.setItem('testSession', JSON.stringify(this.state));
        }

        localStorage.setItem('partSession', JSON.stringify(session));
        localStorage.setItem('testSession', JSON.stringify(this.state));
    }

    handleUpdateAnswerObj = (obj) => {
        if (this.state.answerObj.test_id === undefined) {
            this.state.answerObj['test_id'] = obj.test_id
            obj.parts.map(answer => {
                this.state.answerObj.parts.push(answer);
            })
        }

        else if (this.state.answerObj.test_id !== undefined) {
            obj.parts.map(answer => {
                this.state.answerObj.parts.push(answer);
            });
        }
    }

    handleSubmitResult = async (type) => {
        try {
            const res = await axios({
                method: 'post',
                url: `${api_base_url}/api/test/report?part=${type}`,
                headers: {
                    Authorization: `bearer ${JSON.parse(localStorage.getItem('user')).token}`
                },
                data: this.state.answerObj
            });

            return res.data
            //this.handleCreateStudentReport(res.data);
        } catch (err) {
            console.log(err);
        }
    }

    handleCreateStudentReport = async (obj) => {
        try {
            const { history } = this.props;
            const query = new URLSearchParams(this.props.location.search);
            const testType = query.get('test_type').toUpperCase();
            const reportObj = await this.handleSubmitResult(testType);

            reportObj.total_question = 20;
            reportObj["test_started"] = this.state.startTestTime;
            reportObj["test_survey"] = this.state.regitrationFormResult;

            const resResult = await axios({
                method: 'post',
                url: `${api_base_url}/api/test/result`,
                headers: {
                    Authorization: `bearer ${JSON.parse(localStorage.getItem('user')).token}`
                },
                data: {
                    test_type: `IELTS ${testType} FREE`,
                    test_result: reportObj,
                    test_id: reportObj.test_id,
                    username: JSON.parse(localStorage.getItem('user')).username
                }
            });

            CreateTestSurveyLog({
                user_email: JSON.parse(localStorage.getItem('user')).username,
                user_name: `${JSON.parse(localStorage.getItem('user'))?.firstname} ${JSON.parse(localStorage.getItem('user'))?.lastname}`,
                survey_result: resResult.data["test_survey"],
                test_type: `IELTS ${testType} FREE`,
                test_id: resResult.data.test_id,
                result_id: resResult.data.result_id
            });

            this.setState({
                testReportObj: resResult.data,
                isDoneReport: true
            });

            clearInterval(this.state.myInterval);

            await CreateNewNotification(JSON.parse(localStorage.getItem('user')).username, "free_report", { result_id: resResult.data.result_id, message: "Your report is ready! Click here to check it out!" })

            this.props.history.push(`/test/evaluation?test-type=IELTS ${testType} FREE&test-id=${resResult.data.test_id}&report-id=${resResult.data.result_id}`);

            localStorage.setItem('readingSession', null);
            localStorage.setItem('listeningSession', null);
            localStorage.setItem('writingSession', null);
            localStorage.setItem('testSession', null);
            this.handleCloseLoading();

            return resResult.data;
        } catch (err) {
            console.log(err);
            return false;
        }
    }

    handleEndTest = async () => {
        try {
            const res = await axios({
                method: 'put',
                url: api_base_url + '/api/test/test-purchase-status?test_id=' + this.state.purchasedTestId + '&test_status=true',
                headers: {
                    Authorization: "bearer " + JSON.parse(localStorage.getItem('user')).token
                }
            });
        } catch (err) {
            console.log(err);
        }
    }

    handleStartTestType = (type) => {
        this.setState({
            currentTest: type
        });
    }

    handleCloseLoading = () => {
        this.setState({
            isLoading: false
        })
    }

    handleOpenLoading = () => {
        this.setState({
            isLoading: true
        })
    }

    //================================================================= CREATE TEST REPORT =================================================================
    handleGetTestReport = async () => {
        try {
            this.handleLoading();

            const testResult = await axios({
                method: 'post',
                url: api_base_url + "/api/test/report",
                headers: {
                    Authorization: "bearer " + JSON.parse(localStorage.getItem('user')).token
                },
                data: this.state.answerObj
            })

            this.setState({
                testReportObj: testResult.data
            });

            this.handleCloseLoading();

        } catch (error) {
            this.handleCloseLoading();
            console.error(error);
        }
    }

    handleGetStudentReport = async () => {
        try {
            this.handleLoading();

            const studentReport = await axios({
                method: 'post',
                url: api_base_url + "/api/test/report/student",
                headers: {
                    Authorization: "bearer " + JSON.parse(localStorage.getItem('user')).token
                },
                data: this.state.testReportObj
            })

            this.setState({
                studentReport: studentReport.data
            });

            this.handleCloseLoading();

        } catch (error) {
            this.handleCloseLoading();
            console.error(error);
        }
    }

    handleCreateReportResult = async () => {
        try {
            this.handleLoading();

            const bodyObj = {
                test_type: "IELTS",
                test_result: this.state.testReportObj,
                test_id: this.state.testReportObj.test_id,
                username: JSON.parse(localStorage.getItem('user')).username
            }

            const studentReport = await axios({
                method: 'post',
                url: api_base_url + "/api/test/result",
                headers: {
                    Authorization: "bearer " + JSON.parse(localStorage.getItem('user')).token
                },
                data: bodyObj
            })

            this.handleCloseLoading();

        } catch (error) {
            this.handleCloseLoading();
            console.error(error);
        }
    }

    // ======== Registration form =========
    onSubmitRegitrationFormResult = (result) => {
        this.setState({
            regitrationFormResult: result,
            isOpenDialog: false
        });
    }

    onClickContinueOnTablet = () => {
        this.setState({
            isContinueTablet: true
        });
    }

    onClickBackToHome = () => {
        const { history } = this.props;
        history.push("/freetests");
    }

    render() {
        const { classes } = this.props;

        return (
            <ThemeProvider theme={theme}>
                <Container
                    maxWidth={"xl"}
                    // maxWidth={false}
                    onPaste={(e) => {
                        e.preventDefault()
                        return false;
                    }}
                    onCopy={(e) => {
                        e.preventDefault()
                        return false;
                    }}
                    style={{ flex: 1, width: '100%' }}
                >
                    {!this.state.isDoneReport && <Prompt
                        when={true}
                        message="Leaving now? Your tests will be erased, and no refunds. Choose 'Cancel' to return or 'OK' to proceed."
                    />}

                    {this.state.isLoading &&
                        <Grid container>
                            <Grid item xs={12}>
                                <CircularProgress style={{ marginTop: '25%', marginBottom: '35%' }} />
                            </Grid>
                        </Grid>
                    }

                    <MediaQuery maxWidth="1224px">
                        {(matches) =>
                            <Dialog
                                open={!this.state.isContinueTablet && matches}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">Are you intending to take the test on a tablet device?</DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        We want to let you know that certain functionalities,text highlighting and note-taking, may be unavailable in tablet mode. For the optimal testing experience, we recommend using a desktop or laptop computer.
                                        <p />
                                        <p>If you wish to proceed with tablet mode, please click 'Continue'</p>
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={this.onClickBackToHome} variant="contained" style={{ backgroundColor: "#a6a6a6", color: "white", textTransform: "none" }}>
                                        Back
                                    </Button>
                                    <Button onClick={this.onClickContinueOnTablet} variant="contained" style={{ backgroundColor: "#25333e", color: "white", textTransform: "none" }} autoFocus>
                                        Continue
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        }
                    </MediaQuery>

                    <MediaQuery maxWidth="1224px">
                        {(matches) =>
                            (this.state.isChecked && (this.state.isContinueTablet || !matches)) && <RegistrationFormDialog isOpenDialog={this.state.isOpenDialog} onSubmitRegitrationFormResult={this.onSubmitRegitrationFormResult} />
                        }
                    </MediaQuery>

                    {/* {!this.state.isTestDone && <LeaveFullScreenDialog isOpenDialog={!this.state.isFullScreen} DoFullScreen={this.DoFullScreen} />} */}

                    {!this.state.isChecked && !this.state.isLoading &&
                        <Grid container>
                            <Grid item xs={12} style={{ marginTop: '6%', marginBottom: '80%' }}>
                                <Alert severity="error">Invalid course. Please make sure that you pick an availabke course.</Alert>
                            </Grid>
                        </Grid>
                    }

                    {this.state.isChecked && this.state.currentTest === 'VERIFICATION' &&
                        <VerificationPage
                            user={this.props.user}
                            userSignup={this.props.userSignup}
                            userSignedin={this.props.userSignedin}
                            handleEndTest={this.props.handleEndTest}
                            handleOpenLoading={this.handleOpenLoading}
                            handleCloseLoading={this.handleCloseLoading}
                            handleStartTest={this.props.handleStartTest}
                            handleStartTestType={this.handleStartTestType}
                            handleUpdateAnswerObj={this.handleUpdateAnswerObj}
                        />
                    }

                    {this.state.isChecked && this.state.currentTest === 'READING' &&
                        <ReadingRender
                            session={JSON.parse(localStorage.getItem("readingSession"))}
                            user={this.props.user}
                            userSignup={this.props.userSignup}
                            userSignedin={this.props.userSignedin}
                            handleEndTest={this.props.handleEndTest}
                            DoFullScreen={this.DoFullScreen}
                            ExitFullScreen={this.ExitFullScreen}
                            keepCurrentSession={this.keepCurrentSession}
                            handleOpenLoading={this.handleOpenLoading}
                            handleCloseLoading={this.handleCloseLoading}
                            handleStartTest={this.props.handleStartTest}
                            handleStartTestType={this.handleStartTestType}
                            handleUpdateAnswerObj={this.handleUpdateAnswerObj}
                            isDummyTest={true}
                            handleCreateStudentReport={this.handleCreateStudentReport}
                        />
                    }
                    {this.state.isChecked && this.state.currentTest === 'LISTENING' &&
                        <MediaQuery minResolution={"2dppx"}>
                            {(matches) =>
                                matches ? <ListeningRetina
                                    isDummyTest={true}
                                    session={JSON.parse(localStorage.getItem("listeningSession"))}
                                    handleCreateStudentReport={this.handleCreateStudentReport}
                                    isLoading={this.state.isLoading}
                                    user={this.props.user}
                                    userSignup={this.props.userSignup}
                                    userSignedin={this.props.userSignedin}
                                    handleEndTest={this.props.handleEndTest}
                                    handleEndTestSession={this.handleEndTest}
                                    keepCurrentSession={this.keepCurrentSession}
                                    handleStartPurchasedTest={this.handleStartPurchasedTest}
                                    handleOpenLoading={this.handleOpenLoading}
                                    handleCloseLoading={this.handleCloseLoading}
                                    handleStartTest={this.props.handleStartTest}
                                    handleStartTestType={this.handleStartTestType}
                                    handleUpdateAnswerObj={this.handleUpdateAnswerObj}
                                    history={this.props.history}
                                /> : <TestListenting
                                    isDummyTest={true}
                                    session={JSON.parse(localStorage.getItem("listeningSession"))}
                                    handleCreateStudentReport={this.handleCreateStudentReport}
                                    isLoading={this.state.isLoading}
                                    user={this.props.user}
                                    userSignup={this.props.userSignup}
                                    userSignedin={this.props.userSignedin}
                                    handleEndTest={this.props.handleEndTest}
                                    handleEndTestSession={this.handleEndTest}
                                    keepCurrentSession={this.keepCurrentSession}
                                    handleOpenLoading={this.handleOpenLoading}
                                    handleCloseLoading={this.handleCloseLoading}
                                    handleStartPurchasedTest={this.handleStartPurchasedTest}
                                    handleStartTest={this.props.handleStartTest}
                                    handleStartTestType={this.handleStartTestType}
                                    handleUpdateAnswerObj={this.handleUpdateAnswerObj}
                                    history={this.props.history}
                                />
                            }
                        </MediaQuery>
                    }

                    {this.state.isChecked && this.state.currentTest === 'WRITING' &&
                        <WritingRender
                            session={JSON.parse(localStorage.getItem("writingSession"))}
                            purchasedTestId={this.state.purchasedTestId}
                            user={this.props.user}
                            userSignup={this.props.userSignup}
                            userSignedin={this.props.userSignedin}
                            handleEndTest={this.props.handleEndTest}
                            keepCurrentSession={this.keepCurrentSession}
                            handleOpenLoading={this.handleOpenLoading}
                            handleCloseLoading={this.handleCloseLoading}
                            handleStartTest={this.props.handleStartTest}
                            handleStartTestType={this.handleStartTestType}
                            handleUpdateAnswerObj={this.handleUpdateAnswerObj}
                            handleSubmitResult={this.handleSubmitResult}
                            handleCreateStudentReport={this.handleCreateStudentReport}
                            history={this.props.history}
                            isDummyTest={true}
                        />
                    }
                </Container>
            </ThemeProvider>
        )
    }
}

export default compose(
    withStyles(useStyles)
)(DummyTestMain);
