import React, { useEffect } from 'react';

//MUI
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';

import { ThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';

//Font
import 'typeface-glacial-indifference';

//Services
import { GetTestReport } from '../../../../../services/testReportServices';

//Others
import moment from 'moment';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";

const axios = require('axios').default;
const FormData = require('form-data');
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

const useStyles = theme => ({
    root: {
        padding: theme.spacing(12, 4),
    }
});

const theme = createMuiTheme({
    typography: {
        fontFamily: [
            'Roboto'
        ].join(','),
    },
});

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1500 },
        items: 5
    },
    tablet: {
        breakpoint: { max: 1500, min: 464 },
        items: 3
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1
    }
};

const MyReport = () => {
    //User
    const [filter, setFilter] = React.useState("all");
    const [filterArr, setFilterArr] = React.useState(["IELTS", "IELTS LISTENING FREE", "IELTS READING FREE", "BMAT", "BMAT FREE"]);
    const [testReportArr, setTestReportArr] = React.useState([]);

    const [isLoading, setIsLoading] = React.useState(false);

    useEffect(async () => {
        const user = await JSON.parse(localStorage.getItem('user'));
        await getTestReports(user.token);
    }, []);

    const getTestReports = async (token) => {
        setIsLoading(true);

        const testReports = await GetTestReport(token);

        if (testReports.success) {
            console.log(testReports.res);
            setTestReportArr(testReports.res);
        }

        setIsLoading(false);
    }

    const selectFilter = (filter) => {
        setFilter(filter);

        if (filter === 'all') {
            setFilterArr(["IELTS", "IELTS LISTENING FREE", "IELTS READING FREE", "BMAT", "BMAT FREE"])
        } if (filter === 'fullielts') {
            setFilterArr(["IELTS"])
        } if (filter === 'freeielts') {
            setFilterArr(["IELTS LISTENING FREE", "IELTS READING FREE"])
        } if (filter === 'fullbmat') {
            setFilterArr(["BMAT"])
        } if (filter === 'freebmat') {
            setFilterArr(["BMAT"])
        }

        return;
    }

    const checkFullBMAT = (report) => {
        if (["all", "fullielts", "freeielts"].includes(filter)) {
            return true
        }  if (filter === "fullbmat") {
            return report.test_result.total_question === 16 ? false : true
        } if (filter === "freebmat") {
            return report.test_result.total_question === 16 ? true : false
        }
    }

    const mobileUI = (<Grid item xs={12} style={{ maxWidth: window.innerWidth, marginTop: "10%" }}>
        <Grid container spacing={5}>
            <Grid item align="left" xs={12} style={{ paddingLeft: "10%" }}>
                <Grid container alignItems='center' spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant="h4" style={{ fontWeight: "bold", color: "#880000" }}>
                            My Reports
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body2" style={{ color: "#a6a6a6" }}>
                            Filter by:
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Button
                            size='small'
                            variant="outlined"
                            style={{
                                textTransform: 'none',
                                borderColor: '#880000',
                                color: filter === 'all' ? 'white' : '#880000',
                                backgroundColor: filter === 'all' ? '#880000' : ''
                            }}
                            onClick={() => { selectFilter('all') }}
                        >
                            All
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            disabled={testReportArr.filter((test) => ["IELTS"].includes(test.test_type)).length === 0}
                            size='small'
                            variant={testReportArr.filter((test) => ["IELTS"].includes(test.test_type)).length > 0 ? "outlined" : "contained"}
                            style={{
                                textTransform: 'none',
                                borderColor: '#880000',
                                color: filter === 'fullielts' ? 'white' : (testReportArr.filter((test) => ["IELTS"].includes(test.test_type)).length === 0 ? '' : '#880000'),
                                backgroundColor: filter === 'fullielts' ? '#880000' : ''
                            }}
                            onClick={() => { selectFilter('fullielts') }}
                        >
                            IELTS(Full)
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            disabled={testReportArr.filter((test) => ["IELTS LISTENING FREE", "IELTS READING FREE"].includes(test.test_type)).length === 0}
                            size='small'
                            variant={testReportArr.filter((test) => ["IELTS LISTENING FREE", "IELTS READING FREE"].includes(test.test_type)).length > 0 ? "outlined" : "contained"}
                            style={{
                                textTransform: 'none',
                                borderColor: '#880000',
                                color: filter === 'freeielts' ? 'white' : (testReportArr.filter((test) => ["IELTS LISTENING FREE", "IELTS READING FREE"].includes(test.test_type)).length === 0 ? '' : '#880000'),
                                backgroundColor: filter === 'freeielts' ? '#880000' : ''
                            }}
                            onClick={() => { selectFilter('freeielts') }}
                        >
                            IELTS(Free)
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            disabled={testReportArr.filter((test) => ["BMAT"].includes(test.test_type)).length === 0}
                            size='small'
                            variant={testReportArr.filter((test) => ["BMAT"].includes(test.test_type)).length > 0 ? "outlined" : "contained"}
                            style={{
                                textTransform: 'none',
                                borderColor: '#880000',
                                color: filter === 'fullbmat' ? 'white' : (testReportArr.filter((test) => ["BMAT"].includes(test.test_type)).length === 0 ? '' : '#880000'),
                                backgroundColor: filter === 'fullbmat' ? '#880000' : ''
                            }}
                            onClick={() => { selectFilter('fullbmat') }}
                        >
                            BMAT(Full)
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            disabled={testReportArr.filter((test) => ["BMAT FREE"].includes(test.test_type)).length === 0}
                            size='small'
                            variant={testReportArr.filter((test) => ["BMAT FREE"].includes(test.test_type)).length > 0 ? "outlined" : "contained"}
                            style={{
                                textTransform: 'none',
                                borderColor: '#880000',
                                color: filter === 'freebmat' ? 'white' : (testReportArr.filter((test) => ["BMAT FREE"].includes(test.test_type)).length === 0 ? '' : '#880000'),
                                backgroundColor: filter === 'freebmat' ? '#880000' : ''
                            }}
                            onClick={() => { selectFilter('freebmat') }}
                        >
                            BMAT(Free)
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item align="center" xs={12}>
                <Carousel
                    responsive={responsive}
                    swipeable={true}
                    showDots={testReportArr.filter((test) => filterArr.length === 0 ? true : filterArr.includes(test.test_type)).length > 5}
                    autoPlay={false}
                    autoPlaySpeed={100000}
                >
                    {testReportArr
                        .filter((test) => filterArr.length === 0 ? true : filterArr.includes(test.test_type))?.sort((a, b) => new Date(b.test_result.test_started ? b.test_result.test_started : b.date_created).getTime() - new Date(a.test_result.test_started ? a.test_result.test_started : a.date_created).getTime()).map((report, index) =>
                            checkFullBMAT(report) && <div style={{ padding: "6%" }}>
                                <Card style={{ backgroundColor: "#a6a6a6", borderRadius: "25px", padding: "5%" }}>
                                    <CardContent>
                                        <Typography variant="body1" style={{ color: "white" }} gutterBottom>
                                            {["IELTS"].includes(report.test_type) ? "Full Report" : "Mini Report"}
                                        </Typography>
                                        <Typography variant="h3" style={{ color: "white", fontWeight: "bold" }}>
                                            {report.test_type.includes("BMAT") ? "BMAT" : "IELTS"}
                                        </Typography>
                                        {report.test_type.includes("IELTS") && (report.test_type === "IELTS"
                                            ? <Typography variant="body2" style={{ color: "white" }} gutterBottom>
                                                FULL IELTS
                                        </Typography>
                                            : <Typography variant="body2" style={{ color: "white" }} gutterBottom>
                                                {report.test_type === "IELTS LISTENING FREE" ? "LISTENING" : "READING"}
                                            </Typography>)
                                        }

                                        {report.test_type.includes("BMAT") && (report.test_result.total_question === 16
                                            ? <Typography variant="body2" style={{ color: "white" }} gutterBottom>
                                                FREE
                                        </Typography>
                                            : <Typography variant="body2" style={{ color: "white" }} gutterBottom>
                                                FULL
                                        </Typography>)
                                        }
                                        <Typography variant="subtitle2" style={{ color: "white" }}>
                                            {report.test_result.test_started ? moment(report.test_result.test_started).format('dddd Do MMM YYYY') : moment(report.test_result.date_created).format('dddd Do MMM YYYY')}
                                        </Typography>
                                        <Typography variant="subtitle2" style={{ color: "white" }}>
                                            {report.test_result.test_started ? moment(report.test_result.test_started).format('hh:mm:ss A') : moment(report.test_result.date_created).format('hh:mm:ss A')}
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        <Grid item xs={12}>
                                            {report.test_type.includes("IELTS") ? <Link
                                                to={report.test_type === "IELTS" ? `/full-report?result_id=${report.result_id}` : `/report?result_id=${report.result_id}`}
                                                style={{ textDecoration: 'none', color: '#565656' }}
                                            >
                                                <Button
                                                    variant="contained"
                                                    size="large"
                                                    style={{ backgroundColor: "#880000", color: "white", borderRadius: "10px", textTransform: "none" }}
                                                    disableElevation
                                                    fullWidth
                                                >
                                                    View
                                            </Button>
                                            </Link> : <Link
                                                to={`/full-bmat-report?result_id=${report.result_id}`}
                                                style={{ textDecoration: 'none', color: '#565656' }}
                                            >
                                                    <Button
                                                        variant="contained"
                                                        size="large"
                                                        style={{ backgroundColor: "#880000", color: "white", borderRadius: "10px", textTransform: "none" }}
                                                        disableElevation
                                                        fullWidth
                                                    >
                                                        View
                                            </Button>
                                                </Link>}
                                        </Grid>
                                    </CardActions>
                                </Card>
                            </div>
                        )}
                </Carousel>
            </Grid>

            {isLoading &&
                <Grid align="left" xs={12}>
                    <Grid item style={{ padding: "10%" }}>
                        <Card style={{ backgroundColor: "#a6a6a6", borderRadius: "25px", padding: "5%" }}>
                            <CardContent>
                                <Typography align="center" variant="body1" style={{ color: "white" }} gutterBottom>
                                    <Skeleton />
                                </Typography>
                                <Typography align="center" variant="h3" style={{ color: "white", fontWeight: "bold" }}>
                                    <Skeleton />
                                </Typography>
                                <Typography align="center" variant="subtitle2" style={{ color: "white" }} gutterBottom>
                                    <Skeleton />
                                </Typography>
                                <Typography align="center" variant="subtitle2" style={{ color: "white" }}>
                                    <Skeleton />
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Grid item xs={12}>
                                    <Link
                                        disabled={isLoading}
                                        to={`/freetests`}
                                        style={{ textDecoration: 'none', color: '#565656' }}
                                    >
                                        <Button
                                            variant="contained"
                                            size="large"
                                            style={{ backgroundColor: "#880000", color: "white", borderRadius: "10px", textTransform: "none" }}
                                            disableElevation
                                            fullWidth
                                        >
                                            Try Now
                                        </Button>
                                    </Link>
                                </Grid>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>}

            {(testReportArr?.length === 0 || (testReportArr.filter((test) => filterArr.length === 0 ? true : filterArr.includes(test.test_type)).length === 0)) && !isLoading &&
                <Grid align="left" xs={12}>
                    <Grid item style={{ padding: "10%" }}>
                        <Card style={{ backgroundColor: "#a6a6a6", borderRadius: "25px", padding: "5%" }}>
                            <CardContent>
                                <Typography align="center" variant="body1" style={{ color: "white" }} gutterBottom>
                                    Test
                                </Typography>
                                <Typography align="center" variant="h3" style={{ color: "white", fontWeight: "bold" }}>
                                    Empty
                                </Typography>
                                <Typography align="center" variant="subtitle2" style={{ color: "white" }} gutterBottom>
                                    -
                                </Typography>
                                <Typography align="center" variant="subtitle2" style={{ color: "white" }}>
                                    -
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Grid item xs={12}>
                                    <Link
                                        disabled={isLoading}
                                        to={`/freetests`}
                                        style={{ textDecoration: 'none', color: '#565656' }}
                                    >
                                        <Button
                                            variant="contained"
                                            size="large"
                                            style={{ backgroundColor: "#880000", color: "white", borderRadius: "10px", textTransform: "none" }}
                                            disableElevation
                                            fullWidth
                                        >
                                            Try Now
                                        </Button>
                                    </Link>
                                </Grid>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
            }
        </Grid>
    </Grid>)

    // ==================================================================== DESKTOP ==========================================================================
    const webUI = (<Grid container spacing={5} style={{ maxWidth: window.innerWidth }}>
        <Grid item align="left" xs={12} style={{ paddingLeft: "10%" }}>
            <Grid container alignItems='center' spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h5" style={{ fontWeight: "bold", color: "#880000" }}>
                        My Reports
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body2" style={{ color: "#a6a6a6" }}>
                        Filter by:
                    </Typography>
                </Grid>
                <Grid item>
                    <Button
                        size='small'
                        variant="outlined"
                        style={{
                            textTransform: 'none',
                            borderColor: '#880000',
                            color: filter === 'all' ? 'white' : '#880000',
                            backgroundColor: filter === 'all' ? '#880000' : ''
                        }}
                        onClick={() => { selectFilter('all') }}
                    >
                        All
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        disabled={testReportArr.filter((test) => ["IELTS"].includes(test.test_type)).length === 0}
                        size='small'
                        variant={testReportArr.filter((test) => ["IELTS"].includes(test.test_type)).length > 0 ? "outlined" : "contained"}
                        style={{
                            textTransform: 'none',
                            borderColor: '#880000',
                            color: filter === 'fullielts' ? 'white' : (testReportArr.filter((test) => ["IELTS"].includes(test.test_type)).length === 0 ? '' : '#880000'),
                            backgroundColor: filter === 'fullielts' ? '#880000' : ''
                        }}
                        onClick={() => { selectFilter('fullielts') }}
                    >
                        IELTS(Full)
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        disabled={testReportArr.filter((test) => ["IELTS LISTENING FREE", "IELTS READING FREE"].includes(test.test_type)).length === 0}
                        size='small'
                        variant={testReportArr.filter((test) => ["IELTS LISTENING FREE", "IELTS READING FREE"].includes(test.test_type)).length > 0 ? "outlined" : "contained"}
                        style={{
                            textTransform: 'none',
                            borderColor: '#880000',
                            color: filter === 'freeielts' ? 'white' : (testReportArr.filter((test) => ["IELTS LISTENING FREE", "IELTS READING FREE"].includes(test.test_type)).length === 0 ? '' : '#880000'),
                            backgroundColor: filter === 'freeielts' ? '#880000' : ''
                        }}
                        onClick={() => { selectFilter('freeielts') }}
                    >
                        IELTS(Free)
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        disabled={testReportArr.filter((test) => ["BMAT"].includes(test.test_type)).length === 0}
                        size='small'
                        variant={testReportArr.filter((test) => ["BMAT"].includes(test.test_type)).length > 0 ? "outlined" : "contained"}
                        style={{
                            textTransform: 'none',
                            borderColor: '#880000',
                            color: filter === 'fullbmat' ? 'white' : (testReportArr.filter((test) => ["BMAT"].includes(test.test_type)).length === 0 ? '' : '#880000'),
                            backgroundColor: filter === 'fullbmat' ? '#880000' : ''
                        }}
                        onClick={() => { selectFilter('fullbmat') }}
                    >
                        BMAT(Full)
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        disabled={testReportArr.filter((test) => ["BMAT FREE"].includes(test.test_type)).length === 0}
                        size='small'
                        variant={testReportArr.filter((test) => ["BMAT FREE"].includes(test.test_type)).length > 0 ? "outlined" : "contained"}
                        style={{
                            textTransform: 'none',
                            borderColor: '#880000',
                            color: filter === 'freebmat' ? 'white' : (testReportArr.filter((test) => ["BMAT FREE"].includes(test.test_type)).length === 0 ? '' : '#880000'),
                            backgroundColor: filter === 'freebmat' ? '#880000' : ''
                        }}
                        onClick={() => { selectFilter('freebmat') }}
                    >
                        BMAT(Free)
                    </Button>
                </Grid>
            </Grid>
        </Grid>
        <Grid item align="center" xs={11} style={{ paddingLeft: "10%" }}>
            <Carousel
                responsive={responsive}
                swipeable={true}
                showDots={testReportArr.filter((test) => filterArr.length === 0 ? true : filterArr.includes(test.test_type)).length > 5}
                autoPlay={false}
                autoPlaySpeed={100000}
            >
                {testReportArr
                    .filter((test) => filterArr.length === 0 ? true : filterArr.includes(test.test_type))?.sort((a, b) => new Date(b.test_result.test_started ? b.test_result.test_started : b.date_created).getTime() - new Date(a.test_result.test_started ? a.test_result.test_started : a.date_created).getTime()).map((report, index) =>
                        checkFullBMAT(report) && <div style={{ paddingBottom: "50px" }}>
                            <Card style={{ backgroundColor: "#a6a6a6", borderRadius: "25px", padding: "10px", minWidth: "250px", maxWidth: "250px" }}>
                                <CardContent>
                                    <Typography variant="body1" style={{ color: "white" }} gutterBottom>
                                        {(["IELTS"].includes(report.test_type) || (["BMAT"].includes(report.test_type) && report.test_result.total_question > 16)) ? "Full Report" : "Mini Report"}
                                    </Typography>
                                    <Typography variant="h3" style={{ color: "white", fontWeight: "bold" }}>
                                        {report.test_type.includes("BMAT") ? "BMAT" : "IELTS"}
                                    </Typography>
                                    {report.test_type.includes("IELTS") && (report.test_type === "IELTS"
                                        ? <Typography variant="body2" style={{ color: "white" }} gutterBottom>
                                            FULL IELTS
                                    </Typography>
                                        : <Typography variant="body2" style={{ color: "white" }} gutterBottom>
                                            {report.test_type === "IELTS LISTENING FREE" ? "LISTENING" : "READING"}
                                        </Typography>)
                                    }

                                    {report.test_type.includes("BMAT") && (report.test_result.total_question === 16
                                        ? <Typography variant="body2" style={{ color: "white" }} gutterBottom>
                                            FREE
                                    </Typography>
                                        : <Typography variant="body2" style={{ color: "white" }} gutterBottom>
                                            FULL
                                    </Typography>)
                                    }
                                    {report.test_type === "BMAT" ? <Typography variant="subtitle2" style={{ color: "white" }}>
                                        {moment.utc(report.date_created).format('dddd Do MMM YYYY')}
                                    </Typography> : <Typography variant="subtitle2" style={{ color: "white" }}>
                                            {report.test_result.test_started ? moment(report.test_result.test_started).format('dddd Do MMM YYYY') : moment(report.test_result.date_created).format('dddd Do MMM YYYY')}
                                        </Typography>}
                                    {report.test_type === "BMAT" ? <Typography variant="subtitle2" style={{ color: "white" }}>
                                        {moment.utc(report.date_created).format('hh:mm:ss A')}
                                    </Typography> : <Typography variant="subtitle2" style={{ color: "white" }}>
                                            {report.test_result.test_started ? moment(report.test_result.test_started).format('hh:mm:ss A') : moment(report.test_result.date_created).format('hh:mm:ss A')}
                                        </Typography>}
                                </CardContent>
                                <CardActions>
                                    <Grid item xs={12}>
                                        {report.test_type.includes("IELTS") ? <Link
                                            to={report.test_type === "IELTS" ? `/full-report?result_id=${report.result_id}` : `/report?result_id=${report.result_id}`}
                                            style={{ textDecoration: 'none', color: '#565656' }}
                                        >
                                            <Button
                                                variant="contained"
                                                size="large"
                                                style={{ backgroundColor: "#880000", color: "white", borderRadius: "10px", textTransform: "none" }}
                                                disableElevation
                                                fullWidth
                                            >
                                                View
                                            </Button>
                                        </Link> : <Link
                                            to={`/full-bmat-report?result_id=${report.result_id}`}
                                            style={{ textDecoration: 'none', color: '#565656' }}
                                        >
                                                <Button
                                                    variant="contained"
                                                    size="large"
                                                    style={{ backgroundColor: "#880000", color: "white", borderRadius: "10px", textTransform: "none" }}
                                                    disableElevation
                                                    fullWidth
                                                >
                                                    View
                                            </Button>
                                            </Link>}
                                    </Grid>
                                </CardActions>
                            </Card>
                        </div>
                    )}
            </Carousel>
        </Grid>

        {isLoading &&
            <Grid align="left" xs={11} style={{ paddingLeft: "10%" }}>
                <Grid item>
                    <Card style={{ backgroundColor: "#a6a6a6", borderRadius: "25px", padding: "10px", width: "250px", maxWidth: "250px" }}>
                        <CardContent>
                            <Typography align="center" variant="body1" style={{ color: "white" }} gutterBottom>
                                <Skeleton />
                            </Typography>
                            <Typography align="center" variant="h3" style={{ color: "white", fontWeight: "bold" }}>
                                <Skeleton />
                            </Typography>
                            <Typography align="center" variant="subtitle2" style={{ color: "white" }} gutterBottom>
                                <Skeleton />
                            </Typography>
                            <Typography align="center" variant="subtitle2" style={{ color: "white" }}>
                                <Skeleton />
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Grid item xs={12}>
                                <Link
                                    disabled={isLoading}
                                    to={`/freetests`}
                                    style={{ textDecoration: 'none', color: '#565656' }}
                                >
                                    <Button
                                        variant="contained"
                                        size="large"
                                        style={{ backgroundColor: "#880000", color: "white", borderRadius: "10px", textTransform: "none" }}
                                        disableElevation
                                        fullWidth
                                    >
                                        Try Now
                                    </Button>
                                </Link>
                            </Grid>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>}

        {(testReportArr?.length === 0 || (testReportArr.filter((test) => filterArr.length === 0 ? true : filterArr.includes(test.test_type)).length === 0)) && !isLoading &&
            <Grid align="left" xs={11} style={{ paddingLeft: "10%" }}>
                <Grid item>
                    <Card style={{ backgroundColor: "#a6a6a6", borderRadius: "25px", padding: "10px", width: "250px", maxWidth: "250px" }}>
                        <CardContent>
                            <Typography align="center" variant="body1" style={{ color: "white" }} gutterBottom>
                                Test
                            </Typography>
                            <Typography align="center" variant="h3" style={{ color: "white", fontWeight: "bold" }}>
                                Empty
                            </Typography>
                            <Typography align="center" variant="subtitle2" style={{ color: "white" }} gutterBottom>
                                -
                            </Typography>
                            <Typography align="center" variant="subtitle2" style={{ color: "white" }}>
                                -
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Grid item xs={12}>
                                <Link
                                    disabled={isLoading}
                                    to={`/freetests`}
                                    style={{ textDecoration: 'none', color: '#565656' }}
                                >
                                    <Button
                                        variant="contained"
                                        size="large"
                                        style={{ backgroundColor: "#880000", color: "white", borderRadius: "10px", textTransform: "none" }}
                                        disableElevation
                                        fullWidth
                                    >
                                        Try Now
                                    </Button>
                                </Link>
                            </Grid>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
        }
    </Grid>)

    return <ThemeProvider theme={theme}>
        <Hidden mdUp>
            {mobileUI}
        </Hidden>
        <Hidden smDown>
            {webUI}
        </Hidden>
    </ThemeProvider>
};

export default compose(
    withStyles(useStyles)
)(MyReport);
