//Material UI
import { Button, Grid, Paper, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import { ThemeProvider, createMuiTheme, makeStyles, withStyles } from '@material-ui/core/styles';
import DraftsRoundedIcon from '@material-ui/icons/DraftsRounded';
import { compose } from 'recompose';

//Others
import { Link, useHistory } from "react-router-dom";

//React
import { Component } from 'react';

const useStyles = makeStyles((theme) => ({
    input: {
        background: "white",
        fontSize: "1rem"
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    }
}));

const theme = createMuiTheme({
    root: {
        flexGrow: 1,
    },
    typography: {
        fontFamily: [
            'Roboto'
        ].join(','),
    },
});

class ValidatingEmailPage extends Component {
    componentDidMount = () => {
        document.body.style.backgroundColor = "#efefef";
        document.querySelector("body").scrollTo(0, 0);
    }
    
    render() {
        const { classes } = this.props;

        return (
            <ThemeProvider theme={theme}>
                <Grid container style={{ maxWidth: '100%' }}>
                    <Paper style={{ padding: '50px', backgroundColor: "#a6a6a6", borderRadius: "25px", padding: '50px' }}>
                        <Grid item xs={12}>
                            <Paper elevation={0} style={{ width: '20%', padding: '10px', backgroundColor: '#f7c9c8' }}>
                                <DraftsRoundedIcon color="primary" fontSize="large" style={{ color: "#991514" }} />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: '50px' }}>
                            <Typography variant="h4" gutterBottom>
                                <Box fontWeight="fontWeightBold" display='inline' style={{ color: "white" }}>Please check your inbox</Box>
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography color='textSecondary' variant="subtitle1" gutterBottom>
                                We have sent a password recover instructions to your email.
                        </Typography>
                        </Grid>
                        <Grid item xs={12} style={{ margin: '50px' }}>
                            <CheckCircleRoundedIcon style={{ color: "#47ee01", fontSize: 80 }} />
                        </Grid>
                        <Grid item xs={12}>
                            <Link
                                to={`/newlogin`}
                                style={{ textDecoration: 'none' }}
                            >
                                <Button style={{ color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1rem" }}>
                                    <Box fontWeight="fontWeightBold" display='inline'>Back to sign-in</Box>
                                </Button>
                            </Link>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography color='textSecondary' variant="subtitle2" gutterBottom>
                                Did not receive the email? Check your spam filter.
                        </Typography>
                            <Typography color='textSecondary' variant="subtitle2" gutterBottom>
                                Or try <Box fontWeight="fontWeightBold" display='inline' onClick={this.props.handleExitValidating}>another email address</Box>
                            </Typography>
                        </Grid>
                    </Paper>
                </Grid>
            </ThemeProvider>
        )
    }
}
export default compose(
    withStyles(useStyles)
)(ValidatingEmailPage);