//React
import { Component } from 'react';
import { compose } from 'recompose';

//Material UI
import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

//import components


const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%'
    }
});

class MultipleChoice extends Component {
    constructor() {
        super();

        this.state = {
            answerObj: {
                answers: {}
            },
            choiceArr: ["A", "B", "C", "D"],
            sizeMapHeader: {
                'sm': 'body2',
                'md': 'body1',
                'lg': 'h6',
            },
            sizeMapBody: {
                'sm': 'caption',
                'md': 'body2',
                'lg': 'body1',
            },
            sizeMapCaption: {
                'sm': 'caption',
                'md': 'body2',
                'lg': 'h6',
            },
        };
    }

    componentDidMount = async () => {
        const filteredAnsObj = await this.props.answerObj.parts[this.props.currentPart].sections.find((element) => {
            return element.section_id === this.props.currentTestObject.section_id;
        })

        const halfQ = this.props.currentTestObject.questions.length / 2;
        const firstHalfQ = this.props.currentTestObject.questions.slice(0, halfQ);
        const secondHalffQ = this.props.currentTestObject.questions.slice(halfQ, (halfQ + halfQ));

        this.setState({
            firstHalfQ: firstHalfQ,
            secondHalffQ: secondHalffQ,
            answerObj: {
                section_id: this.props.currentTestObject.section_id,
                question_type: this.props.currentTestObject.question_type,
                answers: (filteredAnsObj.answers === undefined || Object.keys(filteredAnsObj.answers).length === 0) ? {} : filteredAnsObj.answers
            }
        })
    }

    handleAnswerQuestion = (event, questionNo, choice) => {
        console.log(choice);
        const questionKey = '' + questionNo //Convert integer to string
        this.state.answerObj.answers[questionKey] = choice;
        this.props.handleAnswerObj(this.state.answerObj)
        this.props.handleAnsweredQuestionNumber(questionNo);
        this.props.onFocusQuestion(questionNo);
        console.log(this.state.answerObj.answers);
    }

    render() {
        const { classes } = this.props;

        return (
            <Grid container style={{ maxHeight: '100px' }}>
                <Grid item xs={12} style={{ padding: '30px', paddingTop: '0px' }}>
                    <Grid container>
                        <Grid item xs={6}>
                            <Grid container>
                                {this.state.firstHalfQ?.map((q, idx) =>
                                    <Grid item align='left' xs={12} onFocus={() => { this.props.onFocusQuestion(Number(q.no)) }} style={{ height: "200px", maxHeight: "200px" }}>
                                        <Typography gutterBottom align='left' style={{ marginTop: '30px', fontSize: `${this.props.fontSize}rem` }}>
                                            <Box fontWeight="fontWeightBold" display='inline' m={1}>{q.no}</Box> {q.question}
                                        </Typography>
                                        <RadioGroup
                                            aria-label="q1"
                                            name={(this.props.currentSection + 1) + '_' + q.no}
                                            value={this.state.answerObj.answers.hasOwnProperty(Number(q.no)) ? this.state.answerObj.answers[Number(q.no)] : ''}
                                            //onChange={(event) => { this.handleAnswerQuestion(event, q.no) }}
                                            placeholder={(this.props.currentSection + 1).toString()}
                                            style={{ marginLeft: '20px' }}
                                        >
                                            {q.choice.map((choice, choiceIdx) =>
                                                <FormControlLabel
                                                    value={choice}
                                                    control={<div style={{ display: 'table-cell', width: "50px" }}><Radio size="small" color="default" checked={this.state.answerObj.answers[Number(q.no)] === choice} /></div>}
                                                    label={<Typography style={{ fontSize: `${this.props.fontSize}rem` }}>{choice}</Typography>}
                                                    style={{ display: 'table' }}
                                                    onClick={(event) => { this.handleAnswerQuestion(event, q.no, choice) }}
                                                />
                                            )}
                                        </RadioGroup>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container>
                                {this.state.secondHalffQ?.map((q, idx) =>
                                    <Grid item align='left' xs={12} onFocus={() => { this.props.onFocusQuestion(Number(q.no)) }} style={{ height: "200px", maxHeight: "200px" }}>
                                        <Typography align='left' style={{ marginTop: '30px', fontSize: `${this.props.fontSize}rem` }}>
                                            <Box fontWeight="fontWeightBold" display='inline' m={1}>{q.no}</Box> {q.question}
                                        </Typography>
                                        <RadioGroup
                                            aria-label="q1"
                                            name={(this.props.currentSection + 1) + '_' + q.no}
                                            value={this.state.answerObj.answers.hasOwnProperty(Number(q.no)) ? this.state.answerObj.answers[Number(q.no)] : ''}
                                            //onChange={(event) => { this.handleAnswerQuestion(event, q.no) }}
                                            placeholder={(this.props.currentSection + 1).toString()}
                                            style={{ marginLeft: '20px', textAlign: "left" }}
                                        >
                                            {q.choice.map((choice, choiceIdx) =>
                                                <FormControlLabel
                                                    value={choice}
                                                    control={<div style={{ display: 'table-cell', width: "50px" }}><Radio size="small" color="default" checked={this.state.answerObj.answers[Number(q.no)] === choice} /></div>}
                                                    label={<Typography style={{ fontSize: `${this.props.fontSize}rem` }}>{choice}</Typography>}
                                                    style={{ display: 'table' }}
                                                    onClick={(event) => { this.handleAnswerQuestion(event, q.no, choice) }}
                                                />
                                            )}
                                        </RadioGroup>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

export default compose(
    withStyles(useStyles)
)(MultipleChoice);