import React, { useEffect } from 'react';

//MUI
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Skeleton from '@material-ui/lab/Skeleton';

import { ThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';

//Functions
import {
    RoundBand,
    MapBandScoreDescription
} from "../../../../../functions/testReportFuntions";

//Font
import 'typeface-glacial-indifference';

//Others
const axios = require('axios').default;
const FormData = require('form-data');
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

const useStyles = theme => ({
    root: {
        padding: theme.spacing(12, 4),
    }
});

const theme = createMuiTheme({
    typography: {
        fontFamily: [
            'Roboto'
        ].join(','),
    },
});

const BandScoreDescription = (props) => {
    useEffect(async () => {
        console.log(props.bandScoreObj);
    }, []);

    const calculateOverallBand = () => {
        const listeningScore = props.reportObj?.test_result?.score?.listening?.score;
        const readingScore = props.reportObj?.test_result?.score?.reading?.score;
        const speakingScore = props.isGotSpeakingResult ? props.speakingNotesObj?.note?.score : 0;
        const writingScore = props.isGotWritingResult ? props.writingNotesObj?.note?.score : 0;
        const overallBand = RoundBand((Number(listeningScore) + Number(readingScore) + Number(writingScore) + Number(speakingScore)) / (2 + (props.isGotSpeakingResult ? 1 : 0) + (props.isGotWritingResult ? 1 : 0)));

        return overallBand;
    }

    const mobileUI = (<Accordion defaultExpanded style={{ borderRadius: "15px" }} >
        <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            style={{
                backgroundColor: "#25333e",
                color: "white",
                borderTopLeftRadius: "15px",
                borderTopRightRadius: "15px"
            }}
        >
            <Typography variant="h6" style={{ fontWeight: "bold" }}>
                Band Score Description
            </Typography>
        </AccordionSummary>
        <AccordionDetails style={{
            backgroundColor: "#dbecf6",
            borderBottomLeftRadius: "15px",
            borderBottomRightRadius: "15px"
        }}>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Typography variant="body1" align="left" style={{ padding: "20px 0 20px", whiteSpace: 'pre-line' }}>
                        {props.isLoading ? <Skeleton /> : MapBandScoreDescription(calculateOverallBand())}
                    </Typography>
                </Grid>
            </Grid>
        </AccordionDetails>
    </Accordion>)

    const webUI = (
        <Grid container spacing={4}>
            <Grid item xs={12}>
                <Accordion defaultExpanded expanded={true} style={{ borderRadius: "15px" }} >
                    <AccordionSummary
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                        style={{
                            backgroundColor: "#25333e",
                            color: "white",
                            borderTopLeftRadius: "15px",
                            borderTopRightRadius: "15px"
                        }}
                    >
                        <Typography variant="body1" style={{ fontWeight: "bold" }}>
                            Overall Band Score Description
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{
                        backgroundColor: "#dbecf6",
                        borderBottomLeftRadius: "15px",
                        borderBottomRightRadius: "15px"
                    }}>
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <Typography variant="body1" align="left" style={{ padding: "20px 0 20px", whiteSpace: 'pre-line' }}>
                                    {props.isLoading ? <Skeleton /> : MapBandScoreDescription(calculateOverallBand())}
                                </Typography>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Grid>

            {/*props.selectedTestType === "writing" &&
                <Grid item xs={12}>
                    <Accordion defaultExpanded style={{ borderRadius: "15px" }} >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            style={{
                                backgroundColor: "#25333e",
                                color: "white",
                                borderTopLeftRadius: "15px",
                                borderTopRightRadius: "15px"
                            }}
                        >
                            <Typography variant="body1" style={{ fontWeight: "bold" }}>
                                Teacher Comments
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{
                            backgroundColor: "#dbecf6",
                            borderBottomLeftRadius: "15px",
                            borderBottomRightRadius: "15px"
                        }}>
                            <Grid container spacing={4}>
                                <Grid item xs={12}>
                                    <Typography variant="body1" align="left" style={{ padding: "20px 0 20px" }}>
                                        {props.isLoading ? <Skeleton /> : props.writingNotesObj?.note?.comment}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            */}
        </Grid>
    )

    return <ThemeProvider theme={theme}>
        <Hidden mdUp>
            {mobileUI}
        </Hidden>
        <Hidden smDown>
            {webUI}
        </Hidden>
    </ThemeProvider>
};

export default compose(
    withStyles(useStyles)
)(BandScoreDescription);
