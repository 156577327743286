import React from 'react';
import ReactDOM from 'react-dom';

//Material UI
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import MuiAlert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

//Components
import LoadingBackdrop from '../../public/components/LoadingBackdrop';

//Question type reading
import YesNoNG from './questionTypes/reading/YesNoNG';
import TrueFalseNG from './questionTypes/reading/TrueFalseNG';
import ListOfHeading from './questionTypes/reading/ListOfHeading';
import MultipleChoice from './questionTypes/shared/MultipleChoice';
import TextEditor from './questionTypes/reading/TextEditor';

//Question type listening
import Map from './questionTypes/listening/Map';
import Choose2Letters from './questionTypes/listening/Choose2Letters';
import CompleteTheTables from './questionTypes/listening/CompleteTheTables';

//Question type writing
import WritingBaseQuestion from './questionTypes/writing/WritingBaseQuestion';

//Question type speaking
import SpeakingBaseQuestion from './questionTypes/speaking/SpeakingBaseQuestion';

//Question type shared
import NoteCompletion from './questionTypes/shared/NoteCompletion';
import MatchingSentenceEndings from './questionTypes/shared/MatchingSentenceEndings';

//Icons
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

//Contants
import * as apiConstants from '../../../../constants/apiConstants';

//Others
import { withStyles } from '@material-ui/core/styles';
import { Component } from 'react';
import { compose } from 'recompose';
import ReactAudioPlayer from 'react-audio-player';
import { Link } from "react-router-dom";

const axios = require('axios').default;
const FormData = require('form-data');
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

const useStyles = theme => ({
    root: {
        margin: 15,
        marginTop: 50
    },
    margin: {
        margin: theme.spacing(1),
    },
    buttonColor: {
        backgroundColor: '#76323F',
        color: 'white'
    },
    heading: {
        fontWeight: 'bold'
    },
});

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class EditTest extends Component {
    constructor() {
        super()
        this.state = {
            addedQuestionArr: [],
            isEdit: false,
            isLoading: false,
            isLoadingTest: false,
            isFree: false,
            selectedSectionId: '',
            selectedParagraph: -1
        };
        this.onChange = editorState => this.setState({ editorState });
    }

    componentDidMount = async () => {
        await this.handleGetTests();
        document.querySelector("body").scrollTo(0, 0);
    }

    //==================================== PREPARATION =======================================
    handleGetTests = async () => {
        if (this.props.match.params.test_id !== undefined) {
            try {
                this.handleLoadingTest();

                const res = await axios({
                    method: 'get',
                    url: `${api_base_url}/api/test/?test_id=${this.props.match.params.test_id}`,
                    headers: {
                        "Authorization": "Bearer " + JSON.parse(localStorage.getItem('user')).token
                    }
                });

                const resData = res.data[0];

                if (resData.title.includes('Free')) {
                    this.setState({
                        isFree: true
                    });
                } else if (!(resData.title.includes('Free'))) {
                    this.setState({
                        isFree: false,
                        testWritingObj: resData.parts.filter(part => part.part_type === 'WRITING'),
                        testSpeakingObj: resData.parts.filter(part => part.part_type === 'SPEAKING')
                    });
                }

                this.setState({
                    testObj: resData,
                    testReadingObj: resData.parts.filter(part => part.part_type === 'READING'),
                    testListeningObj: resData.parts.filter(part => part.part_type === 'LISTENING')
                });

                console.log(this.state)

                this.handleEndLoadingTest();
            } catch (err) {
                console.log(err);
                this.handleEndLoadingTest();
            }
        }
    }

    handleLoadingTest = () => {
        this.setState({
            isLoadingTest: true
        });
    }

    handleEndLoadingTest = () => {
        this.setState({
            isLoadingTest: false
        });
    }
    //==================================== PREPARATION =======================================

    handleAddQuestionUI = (sectionObj) => {
        //================================== READING ==================================
        if (sectionObj.question_type === 'yesNoNG') {
            return (<YesNoNG key={sectionObj.section_id} test_id={this.state.testObj?.test_id} selectedPart={this.state.selectedPart} selectedSectionId={this.state.selectedSectionId} sectionId={sectionObj.section_id} handleEndEditSection={this.handleEndEditSection} handleCreateEditTestHistory={this.handleCreateEditTestHistory} />)
        }

        if (sectionObj.question_type === 'trueFalseNG') {
            return (<TrueFalseNG key={sectionObj.section_id} test_id={this.state.testObj?.test_id} selectedPart={this.state.selectedPart} selectedSectionId={this.state.selectedSectionId} sectionId={sectionObj.section_id} handleEndEditSection={this.handleEndEditSection} handleCreateEditTestHistory={this.handleCreateEditTestHistory} />)
        }

        if (sectionObj.question_type === 'multipleChoice') {
            return (<MultipleChoice key={sectionObj.section_id} test_id={this.state.testObj?.test_id} selectedPart={this.state.selectedPart} selectedSectionId={this.state.selectedSectionId} sectionId={sectionObj.section_id} handleEndEditSection={this.handleEndEditSection} handleCreateEditTestHistory={this.handleCreateEditTestHistory} />)
        }

        if (sectionObj.question_type === 'matchingSentenceEndings' || sectionObj.question_type === 'matching' || sectionObj.question_type === 'matchingFeatures') {
            return (<MatchingSentenceEndings key={sectionObj.section_id} test_id={this.state.testObj?.test_id} selectedPart={this.state.selectedPart} selectedSectionId={this.state.selectedSectionId} sectionId={sectionObj.section_id} handleEndEditSection={this.handleEndEditSection} handleCreateEditTestHistory={this.handleCreateEditTestHistory} />)
        }

        if (sectionObj.question_type === 'listOfHeading') {
            return (<ListOfHeading key={sectionObj.section_id} test_id={this.state.testObj?.test_id} selectedPart={this.state.selectedPart} selectedSectionId={this.state.selectedSectionId} sectionId={sectionObj.section_id} handleEndEditSection={this.handleEndEditSection} handleCreateEditTestHistory={this.handleCreateEditTestHistory} />)
        }

        if (sectionObj.question_type === 'completeTheNotes' || sectionObj.question_type === 'noteCompletion') {
            return (<NoteCompletion key={sectionObj.section_id} test_id={this.state.testObj?.test_id} selectedPart={this.state.selectedPart} selectedSectionId={this.state.selectedSectionId} sectionId={sectionObj.section_id} handleEndEditSection={this.handleEndEditSection} handleCreateEditTestHistory={this.handleCreateEditTestHistory} />)
        }
        //================================== READING ==================================

        //================================== LISTENING ==================================
        if (sectionObj.question_type === 'completeTheTables') {
            return (<CompleteTheTables key={sectionObj.section_id} test_id={this.state.testObj?.test_id} selectedPart={this.state.selectedPart} selectedSectionId={this.state.selectedSectionId} sectionId={sectionObj.section_id} handleEndEditSection={this.handleEndEditSection} handleCreateEditTestHistory={this.handleCreateEditTestHistory} />)
        }

        if (sectionObj.question_type === 'choose2Letters') {
            return (<Choose2Letters key={sectionObj.section_id} test_id={this.state.testObj?.test_id} selectedPart={this.state.selectedPart} selectedSectionId={this.state.selectedSectionId} sectionId={sectionObj.section_id} handleEndEditSection={this.handleEndEditSection} handleCreateEditTestHistory={this.handleCreateEditTestHistory} />)
        }

        if (sectionObj.question_type === 'map') {
            return (<Map key={sectionObj.section_id} test_id={this.state.testObj?.test_id} selectedPart={this.state.selectedPart} selectedSectionId={this.state.selectedSectionId} sectionId={sectionObj.section_id} handleEndEditSection={this.handleEndEditSection} handleCreateEditTestHistory={this.handleCreateEditTestHistory} />)
        }
        //================================== LISTENING ==================================

        //================================== WRITING ==================================
        if (sectionObj.question_type === 'writingBaseQuestion') {
            return (<WritingBaseQuestion key={sectionObj.section_id} test_id={this.state.testObj?.test_id} selectedPart={this.state.selectedPart} selectedSectionId={this.state.selectedSectionId} sectionId={sectionObj.section_id} handleEndEditSection={this.handleEndEditSection} handleCreateEditTestHistory={this.handleCreateEditTestHistory} />)
        }
        //================================== WRITING ==================================
        if (sectionObj.question_type === 'speakingBaseQuestion') {
            return (<SpeakingBaseQuestion key={sectionObj.section_id} test_id={this.state.testObj?.test_id} selectedPart={this.state.selectedPart} selectedSectionId={this.state.selectedSectionId} sectionId={sectionObj.section_id} handleEndEditSection={this.handleEndEditSection} handleCreateEditTestHistory={this.handleCreateEditTestHistory} />)
        }

    }

    //=========================== EDIT TEST HISTORY ======================================
    handleCreateEditTestHistory = async (oldObj, newObj, sectionId) => {
        try {
            const res = await axios({
                method: 'post',
                url: apiConstants.TEST_MANAGEMENT_API_BASE_URL + '/test/edit/history',
                data: {
                    test_id: this.state.testObj.test_id,
                    part_id: this.state.selectedPart,
                    section_id: sectionId,
                    updateFrom: oldObj,
                    updateTo: newObj,
                    updateBy: {
                        name: JSON.parse(localStorage.getItem('user')).name,
                        username: JSON.parse(localStorage.getItem('user')).username
                    }
                }
            });

        } catch (err) {
            console.log(err);
        }
    }
    //=========================== EDIT TEST HISTORY ======================================

    //=========================== EDIT PARAGRAPH =========================================
    handleUpdateParagraph = async (obj) => {
        try {
            this.handleLoadingTest();

            const tempSectionArr = []
            await obj.sections.map((section) =>
                tempSectionArr.push(section.section_id)
            )

            await axios({
                method: 'put',
                url: `${api_base_url}/api/test/part`,
                headers: {
                    "Authorization": "Bearer " + JSON.parse(localStorage.getItem('user')).token
                },
                data: {
                    _id: obj.id,
                    title: obj.title,
                    description: obj.description,
                    sections: tempSectionArr,
                    part: obj.part,
                    part_type: obj.part_type,
                    total_question: obj.part_type,
                    version: obj.version,
                }
            });

            this.handleEndEditParagraph();
            this.handleEndLoadingTest();
        } catch (err) {
            console.log(err);
            this.handleEndLoadingTest();
        }
    }

    handleIsEdit = () => {
        this.setState({
            isEdit: true
        });
    }

    handleEndEdit = () => {
        this.setState({
            isEdit: false
        });
    }

    handleClickEditSection = (sectionId, part) => {
        this.setState({
            selectedSectionId: sectionId,
            selectedPart: part
        });
    }

    handleEndEditSection = () => {
        this.setState({
            selectedSectionId: ''
        });
    }

    handleClickEditParagraph = (index) => {
        this.setState({
            selectedParagraph: index
        });
    }

    handleEndEditParagraph = (index) => {
        this.setState({
            selectedParagraph: -1
        });
    }

    handleChangeEditorState = (event) => {
        console.log(event.target.value);

        this.setState({
            editorState: event.target.value
        });
    }

    render() {
        const { classes } = this.props;

        return (
            <Grid item xs={12}>
                <LoadingBackdrop isLoading={this.state.isLoadingTest} />
                <Grid container spacing={2} style={{ padding: '100px' }}>
                    <Grid item align='left' xs={12}>
                        <Typography variant="h4" style={{ fontWeight: 'bold' }} gutterBottom>
                            Edit test
                            {/*!this.state.isEdit &&
                                    <IconButton aria-label="edit" onClick={this.handleIsEdit} style={{ marginLeft: '10px' }}>
                                        <EditRoundedIcon fontSize="small" />
                                    </IconButton>
                                */}
                        </Typography>
                    </Grid>

                    {/* =========================================== INFORMATION =========================================== */}
                    <Grid item align='left' xs={12}>
                        <Typography variant="h5" style={{ fontWeight: 'bold' }} gutterBottom>
                            Test ID
                        </Typography>
                        <TextField
                            disabled={!this.state.isEdit}
                            id="outlined-basic-title"
                            variant="outlined"
                            size="small"
                            value={this.state.testObj === undefined ? '' : this.state.testObj.test_id}
                            fullWidth
                        />
                    </Grid>
                    <Grid item align='left' xs={12}>
                        <Typography variant="h5" style={{ fontWeight: 'bold' }} gutterBottom>
                            Title
                        </Typography>
                        <TextField
                            disabled={!this.state.isEdit}
                            id="outlined-basic-title"
                            variant="outlined"
                            size="small"
                            value={this.state.testObj === undefined ? '' : this.state.testObj.title}
                            fullWidth
                        />
                    </Grid>
                    <Grid item align='left' xs={12}>
                        <Typography variant="h5" style={{ fontWeight: 'bold' }} gutterBottom>
                            Description
                        </Typography>
                        <TextField
                            disabled={!this.state.isEdit}
                            id="outlined-basic-title"
                            variant="outlined"
                            size="small"
                            value={this.state.testObj === undefined ? '' : this.state.testObj.description}
                            fullWidth
                        />
                    </Grid>
                    <Grid item align='left' xs={12}>
                        <Typography variant="h5" style={{ fontWeight: 'bold' }} gutterBottom>
                            Type
                        </Typography>
                        <TextField
                            disabled={!this.state.isEdit}
                            id="outlined-basic-title"
                            variant="outlined"
                            size="small"
                            value={this.state.testObj === undefined ? '' : this.state.testObj.type}
                            fullWidth
                        />
                    </Grid>
                    {/* =========================================== INFORMATION =========================================== */}

                    {/* =========================================== READING =========================================== */}
                    <Grid item align='left' xs={12}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography variant="h5" style={{ fontWeight: 'bold' }} gutterBottom>
                                    Reading
                                </Typography>
                            </Grid>
                        </Grid>
                        {this.state.testReadingObj && this.state.testReadingObj.map((part, index) =>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id={"panel1a-header-" + part.part}
                                    style={{ borderTop: '5px solid rgb(241,234,235)' }}
                                >
                                    <Typography paragraph className={classes.heading}>Part {part.part} {part.title}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Grid item align='right' xs={12}>
                                                <Link to={`/dashboard/test-admin/tests/${this.props.match.params.test_id}/parts/${part.id}/preview`} style={{ textDecoration: 'none', color: '#0f0f0f' }}>
                                                    <Button
                                                        variant="outlined"
                                                        size="medium"
                                                        color="primary"
                                                        style={{ marginBottom: '10px', borderColor: '#76323F', color: '#76323F', fontWeight: 'bold' }}
                                                    >
                                                        Preview this part
                                                    </Button>
                                                </Link>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    disabled={!this.state.isEdit}
                                                    id="outlined-basic-title"
                                                    variant="outlined"
                                                    size="small"
                                                    label="Part Id"
                                                    value={part.id}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    disabled={!this.state.isEdit}
                                                    id="outlined-basic-title"
                                                    variant="outlined"
                                                    size="small"
                                                    label="Part version"
                                                    value={part.version}
                                                    style={{ marginTop: '10px' }}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} style={{ marginTop: '20px' }}>
                                                <Typography className={classes.heading} gutterBottom>Paragraph</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item align='right' xs={12}>
                                                        <Button
                                                            disabled={(this.state.selectedParagraph === index)}
                                                            variant="contained"
                                                            size="medium"
                                                            color="primary"
                                                            style={{ marginBottom: '10px', backgroundColor: (this.state.selectedParagraph === index) ? '' : '#76323F' }}
                                                            onClick={() => { this.handleClickEditParagraph(index) }}
                                                        >
                                                            Edit
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextEditor selectedParagraph={this.state.selectedParagraph} paragraphIndex={index} description={part.description} partObj={part} />
                                                    </Grid>
                                                    {this.state.selectedParagraph === index &&
                                                        <Grid item align='right' xs={12} style={{ marginTop: '10px' }}>
                                                            <Button
                                                                //disabled={true}
                                                                variant="contained"
                                                                size="medium"
                                                                color="primary"
                                                                style={{ marginBottom: '10px', backgroundColor: '#76323F' }}
                                                                onClick={() => { this.handleUpdateParagraph(part) }}
                                                            >
                                                                Update
                                                            </Button>
                                                            <Button
                                                                variant="contained"
                                                                size="medium"
                                                                color="primary"
                                                                style={{ marginBottom: '10px', marginLeft: '5px', backgroundColor: 'grey' }}
                                                                onClick={this.handleEndEditParagraph}
                                                            >
                                                                Cancel
                                                            </Button>
                                                        </Grid>
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} style={{ marginTop: '20px' }}>
                                            <Typography className={classes.heading} gutterBottom>Sections</Typography>
                                        </Grid>
                                        {part.sections.map(section =>
                                            <Grid key={section.section_id} item xs={12}>
                                                <Accordion>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon style={{ color: '#76323f' }} />}
                                                        aria-controls="panel1a-content"
                                                        id={"panel1a-header-" + part.part}
                                                        style={{ borderTop: '5px solid rgb(118,50,63)', color: '#76323f' }}
                                                    >
                                                        <Typography className={classes.heading}>{section.section}</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Grid container spacing={2}>
                                                            <Grid item align='right' xs={12}>
                                                                <Link to={`/dashboard/test-admin/tests/${this.props.match.params.test_id}/parts/${part.id}/sections/${section.section_id}/history`} style={{ textDecoration: 'none', color: '#0f0f0f' }}>
                                                                    <Button
                                                                        variant="contained"
                                                                        size="medium"
                                                                        color="primary"
                                                                        style={{ marginBottom: '10px', backgroundColor: '#76323F' }}
                                                                    >
                                                                        History
                                                                    </Button>
                                                                </Link>
                                                                <Button
                                                                    disabled={(this.state.selectedSectionId === section.section_id)}
                                                                    variant="contained"
                                                                    size="medium"
                                                                    color="primary"
                                                                    style={{ marginLeft: '5px', marginBottom: '10px', backgroundColor: (this.state.selectedSectionId === section.section_id) ? '' : '#76323F' }}
                                                                    onClick={() => { this.handleClickEditSection(section.section_id, part.part) }}
                                                                >
                                                                    Edit
                                                                </Button>
                                                            </Grid>
                                                            <Grid item align='left' xs={12}>
                                                                <Typography variant="subtitle2" style={{ fontWeight: 'bold' }} gutterBottom>
                                                                    Section id
                                                                </Typography>
                                                                <TextField
                                                                    disabled
                                                                    id="outlined-basic-title"
                                                                    variant="outlined"
                                                                    size="small"
                                                                    value={this.state.testObj === undefined ? '' : section.section_id}
                                                                    fullWidth
                                                                />
                                                            </Grid>
                                                            {this.handleAddQuestionUI(section)}
                                                        </Grid>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </Grid>
                                        )}
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        )}
                    </Grid>
                    {/* =========================================== READING =========================================== */}

                    {/* =========================================== LISTENING =========================================== */}
                    <Grid item align='left' xs={12}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography variant="h5" style={{ fontWeight: 'bold' }} gutterBottom>
                                    Listening
                                </Typography>
                            </Grid>
                        </Grid>
                        {this.state.testListeningObj && this.state.testListeningObj.map((part, index) =>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id={"panel1a-header-" + part.part}
                                    style={{ borderTop: '5px solid rgb(241,234,235)' }}
                                >
                                    <Typography className={classes.heading}>Part {part.part} {part.title}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={2}>
                                        <Grid item align='right' xs={12}>
                                            <Link to={`/dashboard/test-admin/tests/${this.props.match.params.test_id}/parts/${part.id}/preview`} style={{ textDecoration: 'none', color: '#0f0f0f' }}>
                                                <Button
                                                    variant="outlined"
                                                    size="medium"
                                                    color="primary"
                                                    style={{ marginBottom: '10px', borderColor: '#76323F', color: '#76323F', fontWeight: 'bold' }}
                                                >
                                                    Preview this part
                                                </Button>
                                            </Link>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                disabled
                                                id="outlined-basic-title"
                                                variant="outlined"
                                                size="small"
                                                label="Part description"
                                                value={part.description}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }} gutterBottom>
                                                Voice
                                            </Typography>
                                            <ReactAudioPlayer
                                                src={part.sections[0].questions[0].voice}
                                                controls
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }} gutterBottom>
                                                Sections
                                            </Typography>
                                        </Grid>
                                        {part.sections.map(section =>
                                            <Grid key={section.section_id} item xs={12}>
                                                <Accordion>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon style={{ color: '#76323f' }} />}
                                                        aria-controls="panel1a-content"
                                                        id={"panel1a-header-" + part.part}
                                                        style={{ borderTop: '5px solid rgb(118,50,63)', color: '#76323f' }}
                                                    >
                                                        <Typography className={classes.heading}>{section.section}</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Grid container spacing={2}>
                                                            <Grid item align='right' xs={12}>
                                                                <Link to={`/dashboard/test-admin/tests/${this.props.match.params.test_id}/parts/${part.id}/sections/${section.section_id}/history`} style={{ textDecoration: 'none', color: '#0f0f0f' }}>
                                                                    <Button
                                                                        variant="contained"
                                                                        size="medium"
                                                                        color="primary"
                                                                        style={{ marginBottom: '10px', backgroundColor: '#76323F' }}
                                                                    >
                                                                        History
                                                                    </Button>
                                                                </Link>
                                                                <Button
                                                                    disabled={(this.state.selectedSectionId === section.section_id)}
                                                                    variant="contained"
                                                                    size="medium"
                                                                    color="primary"
                                                                    style={{ marginLeft: '5px', marginBottom: '10px', backgroundColor: (this.state.selectedSectionId === section.section_id) ? '' : '#76323F' }}
                                                                    onClick={() => { this.handleClickEditSection(section.section_id, part.part) }}
                                                                >
                                                                    Edit
                                                                </Button>
                                                            </Grid>
                                                            <Grid item align='left' xs={12}>
                                                                <Typography variant="subtitle2" style={{ fontWeight: 'bold' }} gutterBottom>
                                                                    Section id
                                                                </Typography>
                                                                <TextField
                                                                    disabled
                                                                    id="outlined-basic-title"
                                                                    variant="outlined"
                                                                    size="small"
                                                                    value={this.state.testObj === undefined ? '' : section.section_id}
                                                                    fullWidth
                                                                />
                                                            </Grid>
                                                            {this.handleAddQuestionUI(section)}
                                                        </Grid>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </Grid>
                                        )}
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        )}
                    </Grid>
                    {/* =========================================== LISTENING =========================================== */}

                    {/* =========================================== WRITING =========================================== */}
                    {!this.state.isFree &&
                        <Grid item align='left' xs={12}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography variant="h5" style={{ fontWeight: 'bold' }} gutterBottom>
                                        Writing
                                    </Typography>
                                </Grid>
                            </Grid>
                            {this.state.testWritingObj && this.state.testWritingObj.map((part, index) =>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id={"panel1a-header-" + part.part}
                                        style={{ borderTop: '5px solid rgb(241,234,235)' }}
                                    >
                                        <Typography className={classes.heading}>Part {part.part} {part.title}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    disabled
                                                    id="outlined-basic-title"
                                                    variant="outlined"
                                                    size="small"
                                                    label="Part description"
                                                    value={part.description}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="subtitle1" style={{ fontWeight: 'bold' }} gutterBottom>
                                                    Sections
                                                </Typography>
                                            </Grid>
                                            {part.sections.map(section =>
                                                <Grid key={section.section_id} item xs={12}>
                                                    <Accordion>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon style={{ color: '#76323f' }} />}
                                                            aria-controls="panel1a-content"
                                                            id={"panel1a-header-" + part.part}
                                                            style={{ borderTop: '5px solid rgb(118,50,63)', color: '#76323f' }}
                                                        >
                                                            <Typography className={classes.heading}>{section.section}</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <Grid container spacing={2}>
                                                                <Grid item align='right' xs={12}>
                                                                    <Link to={`/dashboard/test-admin/tests/${this.props.match.params.test_id}/parts/${part.id}/sections/${section.section_id}/history`} style={{ textDecoration: 'none', color: '#0f0f0f' }}>
                                                                        <Button
                                                                            variant="contained"
                                                                            size="medium"
                                                                            color="primary"
                                                                            style={{ marginLeft: '5px', marginBottom: '10px', backgroundColor: '#76323F' }}
                                                                        >
                                                                            History
                                                                        </Button>
                                                                    </Link>
                                                                    <Button
                                                                        disabled={(this.state.selectedSectionId === section.section_id)}
                                                                        variant="contained"
                                                                        size="medium"
                                                                        color="primary"
                                                                        style={{ marginLeft: '5px', marginBottom: '10px', backgroundColor: (this.state.selectedSectionId === section.section_id) ? '' : '#76323F' }}
                                                                        onClick={() => { this.handleClickEditSection(section.section_id, part.part) }}
                                                                    >
                                                                        Edit
                                                                    </Button>
                                                                </Grid>
                                                                <Grid item align='left' xs={12}>
                                                                    <Typography variant="subtitle2" style={{ fontWeight: 'bold' }} gutterBottom>
                                                                        Section id
                                                                    </Typography>
                                                                    <TextField
                                                                        disabled
                                                                        id="outlined-basic-title"
                                                                        variant="outlined"
                                                                        size="small"
                                                                        value={this.state.testObj === undefined ? '' : section.section_id}
                                                                        fullWidth
                                                                    />
                                                                </Grid>
                                                                {this.handleAddQuestionUI(section)}
                                                            </Grid>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            )}
                        </Grid>
                    }
                    {/* =========================================== WRITING =========================================== */}

                    {/* =========================================== SPEAKING =========================================== */}
                    {!this.state.isFree &&
                        <Grid item align='left' xs={12}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography variant="h5" style={{ fontWeight: 'bold' }} gutterBottom>
                                        Speaking
                                    </Typography>
                                </Grid>
                            </Grid>
                            {this.state.testSpeakingObj && this.state.testSpeakingObj.map((part, index) =>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id={"panel1a-header-" + part.part}
                                        style={{ borderTop: '5px solid rgb(241,234,235)' }}
                                    >
                                        <Typography className={classes.heading}>Part {part.part} {part.title}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    disabled
                                                    id="outlined-basic-title"
                                                    variant="outlined"
                                                    size="small"
                                                    label="Part description"
                                                    value={part.description}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="subtitle1" style={{ fontWeight: 'bold' }} gutterBottom>
                                                    Sections
                                                </Typography>
                                            </Grid>
                                            {part.sections.map(section =>
                                                <Grid key={section.section_id} item xs={12}>
                                                    <Accordion>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon style={{ color: '#76323f' }} />}
                                                            aria-controls="panel1a-content"
                                                            id={"panel1a-header-" + part.part}
                                                            style={{ borderTop: '5px solid rgb(118,50,63)', color: '#76323f' }}
                                                        >
                                                            <Typography className={classes.heading}>{section.section}</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <Grid container spacing={2}>
                                                                <Grid item align='right' xs={12}>
                                                                    <Link to={`/dashboard/test-admin/tests/${this.props.match.params.test_id}/parts/${part.id}/sections/${section.section_id}/history`} style={{ textDecoration: 'none', color: '#0f0f0f' }}>
                                                                        <Button
                                                                            variant="contained"
                                                                            size="medium"
                                                                            color="primary"
                                                                            style={{ marginLeft: '5px', marginBottom: '10px', backgroundColor: '#76323F' }}
                                                                        >
                                                                            History
                                                                        </Button>
                                                                    </Link>
                                                                    <Button
                                                                        disabled={(this.state.selectedSectionId === section.section_id)}
                                                                        variant="contained"
                                                                        size="medium"
                                                                        color="primary"
                                                                        style={{ marginLeft: '5px', marginBottom: '10px', backgroundColor: (this.state.selectedSectionId === section.section_id) ? '' : '#76323F' }}
                                                                        onClick={() => { this.handleClickEditSection(section.section_id, part.part) }}
                                                                    >
                                                                        Edit
                                                                    </Button>
                                                                </Grid>
                                                                <Grid item align='left' xs={12}>
                                                                    <Typography variant="subtitle2" style={{ fontWeight: 'bold' }} gutterBottom>
                                                                        Section id
                                                                    </Typography>
                                                                    <TextField
                                                                        disabled
                                                                        id="outlined-basic-title"
                                                                        variant="outlined"
                                                                        size="small"
                                                                        value={this.state.testObj === undefined ? '' : section.section_id}
                                                                        fullWidth
                                                                    />
                                                                </Grid>
                                                                {this.handleAddQuestionUI(section)}
                                                            </Grid>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            )}
                        </Grid>
                    }
                    {/* =========================================== SPEAKING =========================================== */}

                    {this.state.isEdit &&
                        <Grid item align='left' xs={12} style={{ marginTop: '50px' }}>
                            <Button variant="contained" size="medium" color="primary" style={{ marginRight: '5px', backgroundColor: '#76323F' }}>
                                Confirm
                            </Button>
                            <Button variant="contained" size="medium" color="default" onClick={this.handleEndEdit} style={{ marginLeft: '5px', color: 'grey' }}>
                                Cancel
                            </Button>
                        </Grid>
                    }

                    {!this.state.isEdit &&
                        <Grid item align='center' xs={12} style={{ marginTop: '50px' }}>
                            <Link to={`/dashboard/test-admin?from=test`} style={{ textDecoration: 'none', color: '#0f0f0f' }}>
                                <Button variant="contained" size="medium" color="default" style={{ marginLeft: '5px', color: 'grey' }}>
                                    Back
                                </Button>
                            </Link>
                        </Grid>
                    }
                </Grid>
            </Grid >
        )
    }
}
export default compose(
    withStyles(useStyles)
)(EditTest);