//React
import { Component } from 'react';
import { compose } from 'recompose';

//Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const axios = require('axios').default;
const FormData = require('form-data');
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%'
    }
});

class Instructions extends Component {
    constructor() {
        super();
        this.state = {

        };
    }

    componentDidMount = () => {

    }


    render() {
        const { classes } = this.props;

        return (
            <Grid item align="center" xs={12}>
                <Grid container spacing={3} style={{ maxWidth: "70%" }}>
                    <Grid item xs={12}>
                        <Grid container justify="center" >
                            <Grid item>
                                <Box
                                    component="img"
                                    style={{ width: "60px" }}
                                    alt="Logo"
                                    src="/U Test Logo (new) square.png"
                                />

                            </Grid>
                            <Grid item>
                                <Box
                                    component="img"
                                    style={{ width: "100px" }}
                                    alt="Logo"
                                    src="/U Test Logo (new) text.png"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper elevation={0} style={{ padding: "30px" }}>
                            <Grid container>
                                <Grid item align="left" xs={12}>
                                    <Typography variant="h5" gutterBottom style={{ fontWeight: "bold" }}>
                                        🔒 Important Verification Notice 🔒
                                    </Typography>
                                </Grid>
                                <Grid item align="left" xs={12}>
                                    <Typography variant="body1">
                                        Hello valued user,
                                    </Typography>
                                    <Typography variant="body1">
                                        To continue experiencing the simulation test, we kindly request your participation in our quick verification process. Rest assured, your ID will NOT be stored after verification.
                                    </Typography>
                                    <Typography variant="body1" style={{ marginTop: "20px", fontWeight: "bold" }}>
                                        Why Verify Your Identity?
                                    </Typography>
                                    <Typography variant="body1">
                                        Verifying your identity adds an extra layer of protection, preventing unauthorized access and ensuring a secure online experience for you.
                                    </Typography>
                                    <Typography variant="body1" style={{ marginTop: "20px", fontWeight: "bold" }}>
                                        How it works:
                                    </Typography>
                                    <Typography variant="body1" style={{ paddingLeft: "10px" }}>
                                        1. Click “OK” to go to the next page.
                                    </Typography>
                                    <Typography variant="body1" style={{ paddingLeft: "10px" }}>
                                        2. Follow the simple on-screen instructions.
                                    </Typography>
                                    <Typography variant="body1" style={{ paddingLeft: "10px" }}>
                                        3. Your ID will be used solely for verification purposes and will not be retained.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item align="left" xs style={{ paddingLeft: "30px" }}>
                        <Typography variant="body1">
                            * If you have any privacy concern, please contact us via contact@utestavenue.com
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Button variant="contained" onClick={this.props.onClickConsent} disableElevation>
                            OK
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

export default compose(
    withStyles(useStyles)
)(Instructions);
