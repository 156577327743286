//React
import { Component } from 'react';
import { compose } from 'recompose';

//Material UI
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

//others

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%'
    },
    large: {
        width: theme.spacing(50),
        height: theme.spacing(50),
    },
});

class MultipleChoices extends Component {
    constructor() {
        super();

        this.state = {
            answerObj: {
                answers: {}
            },
            selectedChoice: -1
        };
    }

    componentDidMount = async () => {

    }

    handleChange = (event) => {
        this.setState({
            value: event.target.value
        })
    }

    handleOnClickChoice = (index, qNum, answer) => {
        this.setState({
            selectedChoice: index
        });

        this.props.handleTrackingAnswer(Number(qNum), answer);
    }

    render() {
        const { classes } = this.props;

        return (
            <Grid container spacing={5}>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item align='left' xs={12}>
                            <Typography variant="body1" style={{ textAlign: 'justify' }} gutterBottom>
                                {this.props.questionObj.description}
                            </Typography>
                        </Grid>
                        <Grid item align='center' xs={12} style={{ padding: '20px' }}>
                            {this.props.questionObj.picture.length !== 0 &&
                                <CardMedia
                                    component="img"
                                    alt="Contemplative Reptile"
                                    src={this.props.questionObj.picture[0]}
                                    title="Contemplative Reptile"
                                    style={{ width: '500px' }}
                                />
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item align='left' xs={12}>
                            <Typography variant="body1" style={{ fontWeight: 'bold' }} gutterBottom>
                                {this.props.questionObj.no}. {this.props.questionObj.question}
                            </Typography>
                        </Grid>

                        {this.props.questionObj.choice[0].includes("https://") ?
                            (
                                <Grid itex xs={12}>
                                    <Grid container spacing={2}>
                                        {this.props.questionObj.choice.map((choice, index) =>
                                            <Grid item xs={6}>
                                                <Button
                                                    size="large"
                                                    variant="outlined"
                                                    onClick={() => { this.handleOnClickChoice(index, this.props.questionObj.no, choice) }}
                                                    style={{
                                                        textTransform: 'none',
                                                        backgroundColor: this.props.answeredQuestion[Number(this.props.questionObj.no)] === choice ? '#214b4b' : '',
                                                        color: this.props.answeredQuestion[Number(this.props.questionObj.no)] === choice ? 'white' : 'black',
                                                        borderColor: '#214b4b',
                                                        minHeight: this.props.questionObj._id === '61c7585ee1868916783772d9' ? '300px' : '150px',
                                                    }}
                                                    fullWidth
                                                >
                                                    <CardMedia
                                                        component="img"
                                                        alt="Contemplative Reptile"
                                                        src={choice.split('. ')[1]}
                                                        title="Contemplative Reptile"
                                                        style={{ width: this.props.questionObj._id === '61c7585ee1868916783772d9' ? '150px' : '200px' }}
                                                    />
                                                </Button>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            ) : (
                                <Grid item xs={12} style={{ marginTop: '10px' }}>
                                    <Grid container spacing={2}>
                                        {this.props.questionObj.choice.length > 7 ? (this.props.questionObj.choice.map((choice, index) =>
                                            <Grid item xs={12}>
                                                <Button
                                                    variant="outlined"
                                                    onClick={() => { this.handleOnClickChoice(index, this.props.questionObj.no, choice) }}
                                                    style={{
                                                        textTransform: 'none',
                                                        backgroundColor: this.props.answeredQuestion[Number(this.props.questionObj.no)] === choice ? '#214b4b' : '',
                                                        color: this.props.answeredQuestion[Number(this.props.questionObj.no)] === choice ? 'white' : 'black',
                                                        borderColor: '#214b4b',
                                                        minHeight: '30px'
                                                    }}
                                                    fullWidth
                                                >
                                                    <Grid container alignItems='center' spacing={5}>
                                                        <Grid item xs={1}>
                                                            <Avatar
                                                                style={{
                                                                    backgroundColor: this.props.answeredQuestion[Number(this.props.questionObj.no)] === choice ? 'white' : '#214b4b',
                                                                    color: this.props.answeredQuestion[Number(this.props.questionObj.no)] === choice ? '#214b4b' : 'white',
                                                                    width: "35px",
                                                                    height: "35px"
                                                                }}
                                                            >
                                                                {choice.split('.')[0]}
                                                            </Avatar>
                                                        </Grid>
                                                        <Grid item xs={11}>
                                                            <Typography variant="body1" align="left">
                                                                {choice.split('. ').slice(1)}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Button>
                                            </Grid>
                                        )) : (this.props.questionObj.choice.map((choice, index) =>
                                            <Grid item xs={12}>
                                                <Button
                                                    size="large"
                                                    variant="outlined"
                                                    onClick={() => { this.handleOnClickChoice(index, this.props.questionObj.no, choice) }}
                                                    style={{
                                                        textTransform: 'none',
                                                        backgroundColor: this.props.answeredQuestion[Number(this.props.questionObj.no)] === choice ? '#214b4b' : '',
                                                        color: this.props.answeredQuestion[Number(this.props.questionObj.no)] === choice ? 'white' : 'black',
                                                        borderColor: '#214b4b',
                                                        minHeight: '50px'
                                                    }}
                                                    fullWidth
                                                >
                                                    <Grid container alignItems='center' spacing={5}>
                                                        <Grid item xs={1}>
                                                            <Avatar
                                                                style={{
                                                                    backgroundColor: this.props.answeredQuestion[Number(this.props.questionObj.no)] === choice ? 'white' : '#214b4b',
                                                                    color: this.props.answeredQuestion[Number(this.props.questionObj.no)] === choice ? '#214b4b' : 'white'
                                                                }}
                                                            >
                                                                {choice.split('.')[0]}
                                                            </Avatar>
                                                        </Grid>
                                                        <Grid item xs={11}>
                                                            <Typography variant="body1" align="left">
                                                                {choice.split('. ').slice(1)}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Button>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                            )
                        }

                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

export default compose(
    withStyles(useStyles)
)(MultipleChoices);
