//Material UI
import { Button, Grid, Paper, TextField, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { ThemeProvider, createMuiTheme, makeStyles, withStyles } from '@material-ui/core/styles';
import LockOpenRoundedIcon from '@material-ui/icons/LockOpenRounded';
import { compose } from 'recompose';

//Import components
import PageBackdrop from '../../main/PageBackdrop';
import ValidatingEmailPage from './ValidatingEmailPage';

//React
import { Component } from 'react';

//Other
import { Link, useHistory } from "react-router-dom";

const axios = require('axios').default;
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

const useStyles = makeStyles((theme) => ({
    input: {
        background: "white",
        fontSize: "1rem"
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    }
}));

const theme = createMuiTheme({
    root: {
        flexGrow: 1,
    },
    typography: {
        fontFamily: [
            'Roboto'
        ].join(','),
    },
});

class ForgotPasswordPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isValidating: false,
            isvalalidated: false,
            isLoading: false,
            invalidEmail: false
        };
    }

    componentDidMount = () => {
        document.body.style.backgroundColor = "#efefef";
        document.querySelector("body").scrollTo(0, 0);
    }

    checkExistingEmail = async (email) => {
        this.handleLoading();
        const signinSession_url = api_base_url + "/api/oauth/login";
        const checkExist_url = api_base_url + "/api/user/exist";
        try {
            const response = await axios.post(signinSession_url, {}, {
                auth: {
                    username: "phisonkha",
                    password: "1234"
                }
            });
            let adminToken = response.data.token.token;

            let config_email = {
                headers: {
                    "Authorization": "bearer " + adminToken
                },
                params: {
                    email: email
                }
            }
            const responseEmail = await axios.get(checkExist_url, config_email);
            if (!responseEmail.data.status) {
                return false;
            }

            return true;

        } catch (error) {
            this.handleCloseLoading();
            console.error(error);
            return false
        }
    }

    handleResetPassword = async (email) => {
        let isExistingEmail = await this.checkExistingEmail(email);
        console.log(isExistingEmail);
        if (!isExistingEmail) {
            this.setState({
                invalidEmail: true
            });
            this.handleCloseLoading();

            return;
        }

        this.setState({
            invalidEmail: false
        });

        try {
            const sendEmailvalidation_url = api_base_url + "/api/user/reset-password";
            let configEmailValidation_config = {
                params: {
                    email: email
                }
            }
            const responseEmailValidation = await axios.get(sendEmailvalidation_url, configEmailValidation_config);

        } catch (error) {
            this.handleCloseLoading();
            console.error(error);
            return false
        }

        this.handleCloseLoading();
        this.handleValidating();
    }

    //======================== BEGIN BACKDROP CONTROL ==============================
    handleCloseLoading = () => {
        this.setState({
            isLoading: false
        });
    }

    handleLoading = () => {
        this.setState({
            isLoading: true
        });
    }
    //======================== END BACKDROP CONTROL ===============================

    //========================== BEGIN PAGE CHANGE HANDLER ============================
    handleValidating = () => {
        this.setState({
            isValidating: true,
        });
    }

    handleExitValidating = () => {
        this.setState({
            isValidating: false,
            isvalalidated: false
        });
    }

    handleValidated = () => {
        this.setState({
            isValidating: false,
            isvalalidated: true
        });
    }

    handleToSigninPage = () => {
        this.setState({
            isValidating: false,
            isvalalidated: false
        });

        this.props.handleToSigninPage();
    }
    //========================== END PAGE CHANGE HANDLER ============================

    handleChange = (event) => {
        const name = event.target.name;
        this.setState({
            ...this.state,
            [name]: event.target.value,
        });
    };

    render() {
        const { classes } = this.props;

        return (
            <ThemeProvider theme={theme}>
                <PageBackdrop isLoading={this.state.isLoading} />

                <Grid container style={{ marginTop: "4%", marginBottom: "20%", maxWidth: window.innerWidth }}>
                    <Grid item align='center' xs={12}>
                        <Grid container justify='center' spacing={0}>
                            <Grid item>
                                {!this.state.isValidating && !this.state.isvalalidated &&
                                    <Grid container>
                                        <Paper elevation={0} style={{ backgroundColor: "#a6a6a6", borderRadius: "25px", padding: '50px' }}>
                                            <Grid item align='center' xs={12} style={{ marginBottom: '50px' }}>
                                                <LockOpenRoundedIcon color="primary" fontSize="large" style={{ color: "#991514" }} />
                                            </Grid>
                                            <Grid item align='left' xs={12}>
                                                <Typography variant="h4" style={{ color: "white" }} gutterBottom>
                                                    <Box fontWeight="fontWeightBold" display='inline'>Forgot Your Password?</Box>
                                                </Typography>
                                            </Grid>
                                            <Grid item align='center' xs={12}>
                                                <Typography variant="body1" align="left" style={{ fontWeight: 'bold' ,marginTop: '20px', color: "white" }} gutterBottom>
                                                    Email:
                                                </Typography>
                                                <TextField
                                                    error={this.state.invalidEmail}
                                                    name="email"
                                                    id="outlined-basic"
                                                    variant="outlined"
                                                    type="input"
                                                    onChange={this.handleChange}
                                                    style={{ width: '100%', background: "white", fontSize: "1rem" }}
                                                />
                                            </Grid>
                                            <Grid item align='center' xs={12}>
                                                <Button
                                                    disableElevation
                                                    variant="contained"
                                                    color="primary"
                                                    style={{ height: '40px', marginTop: '20px', backgroundColor: "#991514", color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1rem" }}
                                                    onClick={() => { this.handleResetPassword(this.state.email) }}
                                                >
                                                    <Box fontWeight="fontWeightBold" display='inline'>Reset Password</Box>
                                                </Button>
                                            </Grid>
                                            <Grid item align='center' xs={12}>
                                                <Link
                                                    to={`/newlogin`}
                                                    style={{ textDecoration: 'none' }}
                                                >
                                                    <Button style={{ color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1rem" }}>
                                                        Back to signin
                                                    </Button>
                                                </Link>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                }
                                {this.state.isValidating &&
                                    <ValidatingEmailPage
                                        handleExitValidating={this.handleExitValidating}
                                        handleToSigninPage={this.handleToSigninPage}
                                        handleValidated={this.handleValidated}
                                    />
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </ThemeProvider>
        )
    }
}
export default compose(
    withStyles(useStyles)
)(ForgotPasswordPage);