import React, { useEffect } from "react";

//MUI
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";

import { ThemeProvider, createMuiTheme, withStyles } from "@material-ui/core/styles";
import { compose } from "recompose";

//Font
import "typeface-glacial-indifference";

//Components
import OverallScore from "./components/fullBmatReportPage/OverallScore";
import UserProfile from "./components/fullReportPage/UserProfile";
//BMAT
import SpectrumChart from "./components/fullBmatReportPage/SpectrumChart";

//Funtions
import { capitalize } from '../../../models/publicFuntions';
import { AgeCalculation } from '../../../functions/userFunctions';

//PDF File
import pdfMake from "pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

//Services
import {
  GetTestReportByResultId,
  GetUserInfoByUsername,
  GetWritingNotes,
} from "../../../services/testReportServices";

//Others
import moment from 'moment';
import { Link } from "react-router-dom";
import { BmatScoreMappingSectionOne, BmatScoreMappingSectionTwo } from "../../../functions/testReportFuntions";

const htmlToPdfmake = require("html-to-pdfmake");

const useStyles = (theme) => ({
  root: {
    padding: theme.spacing(12, 4),
  },
});

const theme = createMuiTheme({
  typography: {
    fontFamily: ["Roboto"].join(","),
  },
});

const NewFullBMATReportPage = (props) => {
  const [userObj, setUserObj] = React.useState({});
  const [reportObj, setReportObj] = React.useState({});
  const [bandScoreObj, setBandScoreObj] = React.useState({});
  const [writingNotesObj, setWritingNotesObj] = React.useState({});
  const [isGotWritingResult, setIsGotWritingResult] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(async () => {
    document.body.style.overflow = "auto";
    document.body.style.backgroundColor = "#efefef";
    document.querySelector("body").scrollTo(0, 0);

    getTestReportById();
  }, []);

  const getTestReportById = async () => {
    setIsLoading(true);

    const query = new URLSearchParams(props.location.search);
    const res = await GetTestReportByResultId(
      JSON.parse(localStorage.getItem("user")).token,
      query.get("result_id")
    );

    if (res.success) {
      setReportObj(res.res);
      await getUserInfo(res.res.username);
      await getWritingNotes(query.get("result_id"), res.res.username);
    }

    setIsLoading(false);
  };

  const getUserInfo = async (username) => {
    const res = await GetUserInfoByUsername(JSON.parse(localStorage.getItem('user')).token, username);
    const userObj = res.res;
    const age = await AgeCalculation(res.res.birthDate)
    const firstname = await capitalize(res.res.firstname);
    const lastname = await capitalize(res.res.lastname);
    userObj["name"] = `${firstname} ${lastname}`
    userObj["initial"] = `${res.res.firstname[0]}${res.res.lastname[0]}`;
    userObj["age"] = age

    setUserObj(userObj);
  }

  const getWritingNotes = async (test_id, username) => {
    const res = await GetWritingNotes(
      JSON.parse(localStorage.getItem("user")).token,
      username,
      test_id
    );

    if (res.success) {
      await setWritingNotesObj(res.res.note[0]);
      await setIsGotWritingResult(true);
    }
    if (!res.success) {
      await setIsGotWritingResult(false);
    }
  };

  const exportPdf = () => {
    const documentDefinition = {
      content: [
        {
          margin: [0, 0, 0, -70],
          image: 'image1',
          width: 100,
          height: 100,
          alignment: 'right',
        },
        {
          text: 'BMAT',
          margin: [5, 0, 0, 0],
          fontSize: 60,
          bold: true,
          alignment: 'left',
        },
        {
          text: 'SIMULATION TEST BY UTEST AVENUE',
          margin: [0, 0, 0, 0],
          fontSize: 10,
          alignment: 'left',
          color: "#949290",
        },
        {
          margin: [6, 15, 0, 0],
          table: {
            body: [
              [
                {
                  margin: [6, 6, 6, 6],
                  text: 'Statement of Results',
                  fillColor: '#03b950',
                  color: '#ffffff',
                  fontSize: 18,
                }
              ]
            ]
          },
          layout: 'noBorders'
        },
        {
          text: 'This is to verify that',
          margin: [12, 20, 0, 0],
          fontSize: 13,
          alignment: 'left'
        },
        {
          text: userObj.name,
          margin: [12, 25, 0, 0],
          fontSize: 13,
          alignment: 'left'
        },
        {
          text: `Date of Birth: ${moment.utc(userObj.birthDate).format('DD MMMM YYYY')}`,
          margin: [12, 25, 0, 0],
          fontSize: 13,
          alignment: 'left'
        },
        {
          text: 'Sat the BMAT Simulation Test on',
          margin: [12, 25, 0, 0],
          fontSize: 13,
          alignment: 'left'
        },
        {
          text: `${moment.utc(reportObj.date_created).format('dddd Do MMM YYYY')} and scored:`,
          margin: [12, 5, 0, 0],
          fontSize: 13,
          alignment: 'left'
        },
        {
          margin: [50, 30, 50, 0],
          table: {
            widths: ['20%', '*', '35%'],
            body: [
              [
                {
                  text: 'Section',
                  bold: true,
                  fontSize: 10,
                },
                {
                  text: 'Title',
                  bold: true,
                  fontSize: 10,
                },
                {
                  text: 'Result',
                  bold: true,
                  fontSize: 10,
                  alignment: 'right'
                }
              ]
            ]
          },
          layout: 'noBorders'
        },
        {
          margin: [50, 10, 50, 0],
          table: {
            widths: ['20%', '*', '35%'],
            body: [
              [
                {
                  text: 'Section 1',
                  fontSize: 10,
                },
                {
                  text: 'Thinking Skills',
                  fontSize: 10,
                },
                {
                  text: BmatScoreMappingSectionOne(reportObj.test_result.parts[0].total_score),
                  fontSize: 10,
                  alignment: 'right'
                }
              ]
            ]
          },
          layout: 'noBorders'
        },
        {
          margin: [50, 5, 50, 0],
          table: {
            widths: ['20%', '*', '35%'],
            body: [
              [
                {
                  text: 'Section 2',
                  fontSize: 10,
                },
                {
                  text: 'Scientific Knowledge and Applications',
                  fontSize: 10,
                },
                {
                  text: BmatScoreMappingSectionTwo(reportObj.test_result.parts[1].total_score),
                  fontSize: 10,
                  alignment: 'right'
                }
              ]
            ]
          },
          layout: 'noBorders'
        },
        isGotWritingResult ? {
          margin: [50, 5, 50, 0],
          table: {
            widths: ['20%', '*', '35%'],
            body: [
              [
                {
                  text: 'Section 3',
                  fontSize: 10,
                },
                {
                  text: 'Writing Task',
                  fontSize: 10,
                },
                {
                  text: `${writingNotesObj?.note?.quality_of_content}${writingNotesObj?.note?.quality_of_english}`,
                  fontSize: 10,
                  alignment: 'right'
                }
              ]
            ]
          },
          layout: 'noBorders'
        } : {},
        {
          margin: [50, 5, 50, 0],
          table: {
            widths: ['20%', '*', '35%'],
            body: [
              [
                {
                  text: 'Overall',
                  fontSize: 10,
                },
                {
                  text: '',
                  fontSize: 10,
                },
                {
                  text: isGotWritingResult ? `${BmatScoreMappingSectionOne(reportObj.test_result.parts[0].total_score) + BmatScoreMappingSectionTwo(reportObj.test_result.parts[1].total_score) + writingNotesObj?.note?.quality_of_content}${writingNotesObj?.note?.quality_of_english}` : BmatScoreMappingSectionOne(reportObj.test_result.parts[0].total_score) + BmatScoreMappingSectionTwo(reportObj.test_result.parts[1].total_score),
                  fontSize: 10,
                  alignment: 'right'
                }
              ]
            ]
          },
          layout: 'noBorders'
        },
        {
          margin: [12, 50, 0, 0],
          canvas: [{ type: 'line', x1: 0, y1: 0, x2: 500, y2: 0, lineWidth: 5, color: "#03b950" }]
        },
        {
          text: 'Areas of Improvement',
          margin: [12, 20, 0, 20],
          fontSize: 15,
          bold: true,
          alignment: 'left',
          pageBreak: 'before'
        },
      ],
      defaultStyle: {
        font: "Roboto", // Any already loaded font
      },
      images: {
        image1: "https://i.ibb.co/xH8B9Xv/U-Test-logo-with-text.png",
      },
      styles: {
        image: {
          alignment: 'right',
          margin: [0, 0, 0, 0]
        },
        table: {
          margin: [0, 5, 0, 15]
        },
        overallScoreTable: {
          margin: [0, 5, 0, 5]
        },
        infoText: {
          margin: [5, 5, 0, 5]
        },
        'html-p': {
          margin: [0, 0, 0, 0]
        }
      }
    };

    reportObj?.test_result?.parts?.map((part) => {
      part.result?.map((result, rIdx) => {
        let htmlContent = htmlToPdfmake(result.improvement);
        const resetStyle = htmlContent.filter((obj) => { return typeof obj.text !== "string" })

        documentDefinition.content.push({
          style: 'table',
          table: {
            widths: ['*'],
            heights: ['auto'],
            body: [
              [{ text: `${part.part_type}: ${result.type}`, bold: true }],
              [resetStyle]
            ]
          },
        });
        return result;
      });

      return part;
    });

    pdfMake.fonts = {
      // download default Roboto font from cdnjs.com
      Roboto: {
        normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
      },
      Arial: {
        normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
      }
    }

    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
    pdfDocGenerator.open();
  }

  const mobileUI = (
    <Grid
      container
      style={{
        marginTop: "5%",
        marginBottom: "20%",
        maxWidth: window.innerWidth,
      }}
    >
      <Grid item align="right" xs={12} style={{ padding: "10px", paddingBottom: "0px", paddingTop: "0px" }}>
        {!isLoading &&
          <Button
            variant="contained"
            size="small"
            style={{
              backgroundColor: "#a00506",
              color: "white",
              textTransform: "none",
              fontSize: "1rem",
            }}
            onClick={exportPdf}
            disableElevation
          >
            PDF
          </Button>
        }
      </Grid>
      <Grid item align="center" xs={12}>
        <Box
          component="img"
          style={{ width: "50px" }}
          alt="Logo"
          src="/U Test Logo (new).png"
        />
        <Typography
          variant="h6"
          style={{ color: "#26343f", fontWeight: "bold" }}
          gutterBottom
        >
          BMAT Simulation Test Report
        </Typography>
      </Grid>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <UserProfile
              userObj={userObj}
              reportObj={reportObj}
              bandScoreObj={bandScoreObj}
              isLoading={isLoading}
            />
          </Grid>
          <Grid item xs={12}>
            {<OverallScore
              isGotWritingResult={isGotWritingResult}
              writingNotesObj={writingNotesObj}
              reportObj={reportObj}
              bandScoreObj={bandScoreObj}
              isLoading={isLoading}
            />}
          </Grid>
          <Grid item xs={12}>
            <SpectrumChart
              isGotWritingResult={isGotWritingResult}
              writingNotesObj={writingNotesObj}
              reportObj={reportObj}
              bandScoreObj={bandScoreObj}
              isLoading={isLoading}
            />
          </Grid>
          <Grid item xs={12}>
            <Link
              to={`/profile`}
              style={{ textDecoration: "none", color: "#565656" }}
            >
              <Button
                variant="contained"
                size="large"
                style={{
                  backgroundColor: "#a6a6a6",
                  color: "white",
                  borderRadius: "10px",
                  textTransform: "none",
                  fontSize: "1rem",
                }}
                disableElevation
              >
                Back
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );

  const webUI = (
    <Grid
      container
      style={{
        marginTop: "30px",
        marginBottom: "20%",
        maxWidth: window.innerWidth,
      }}
    >
      <Grid item align="right" xs={12} style={{ padding: "10px", paddingBottom: "0px", paddingTop: "0px", paddingRight: "100px" }}>
        {!isLoading &&
          <Button
            variant="contained"
            size="small"
            style={{
              backgroundColor: "#a00506",
              color: "white",
              textTransform: "none",
              fontSize: "1rem",
            }}
            onClick={exportPdf}
            disableElevation
          >
            PDF
          </Button>
        }
      </Grid>
      <Grid item align="center" xs={12} style={{ marginBottom: "50px" }}>
        <Grid container>
          <Grid item align="center" xs={12}>
            <Grid container justify="center" >
              <Grid item>
                <Box
                  component="img"
                  style={{ width: "60px" }}
                  alt="Logo"
                  src="/U Test Logo (new) square.png"
                />

              </Grid>
              <Grid item>
                <Box
                  component="img"
                  style={{ width: "100px" }}
                  alt="Logo"
                  src="/U Test Logo (new) text.png"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="h5"
              style={{ color: "#26343f", fontWeight: "bold" }}
              gutterBottom
            >
              BMAT Simulation Test Report
              </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <UserProfile
              userObj={userObj}
              reportObj={reportObj}
              bandScoreObj={bandScoreObj}
              isLoading={isLoading}
            />
          </Grid>
          <Grid item xs={4}>
            {<OverallScore
              isGotWritingResult={isGotWritingResult}
              writingNotesObj={writingNotesObj}
              reportObj={reportObj}
              bandScoreObj={bandScoreObj}
              isLoading={isLoading}
            />}
          </Grid>
          <Grid item xs={12}>
            <SpectrumChart
              reportObj={reportObj}
              bandScoreObj={bandScoreObj}
              isLoading={isLoading}
              isGotWritingResult={isGotWritingResult}
              writingNotesObj={writingNotesObj}
            />
          </Grid>
          <Grid item xs={12}>
            <Link
              to={`/profile`}
              style={{ textDecoration: "none", color: "#565656" }}
            >
              <Button
                variant="contained"
                size="large"
                style={{
                  backgroundColor: "#a6a6a6",
                  color: "white",
                  borderRadius: "10px",
                  textTransform: "none",
                  fontSize: "1rem",
                }}
                disableElevation
              >
                Back
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );

  return (
    <ThemeProvider theme={theme}>
      <Hidden mdUp>{mobileUI}</Hidden>
      <Hidden smDown>{webUI}</Hidden>
    </ThemeProvider>
  );
};

export default compose(withStyles(useStyles))(NewFullBMATReportPage);
