//React
import { Component } from 'react';
import { compose } from 'recompose';

//Material UI
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

//import components


const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%'
    }
});

class ChooseTwoLetters extends Component {
    constructor() {
        super();

        this.state = {
            answerArr: [],
            answerArrToCheck: [],
            isError: false,
            sizeMapHeader: {
                'sm': 'body2',
                'md': 'body1',
                'lg': 'h6',
            },
            sizeMapBody: {
                'sm': 'caption',
                'md': 'body2',
                'lg': 'body1',
            },
            sizeMapCaption: {
                'sm': 'caption',
                'md': 'body2',
                'lg': 'h6',
            },
        };
    }

    componentDidMount = async (e) => {
        const filteredAnsObj = await this.props.answerObj.parts[this.props.currentPart].sections.filter(section => section.section_id === this.props.currentTestObject.section_id);
        await this.setState({
            answerObj: {
                section_id: this.props.currentTestObject.section_id,
                question_type: this.props.currentTestObject.question_type,
                answers: filteredAnsObj[0].answers.length === 0 ? {
                    [this.props.currentTestObject.questions[0].no]: []
                } : filteredAnsObj[0].answers
            },
            answerArr: filteredAnsObj[0].answers.length === 0 ? [] : filteredAnsObj[0].answers[this.props.currentTestObject.questions[0].no],
        })
        
        this.setState({
            isError: this.state.answerArr.length === 2
        });
    }

    handleAnswerQuestion = (event) => {
        const questionNumber = this.props.currentTestObject.questions[0].no.split('-')

        if (event.target.checked) {
            if (this.state.answerArr.find(ans => ans === event.target.name.split(' ')[0])) {
                return;
            }

            this.state.answerArr.push(event.target.name.split(' ')[0]);
            this.state.answerArrToCheck.push(event.target.name);
        }

        else if (!event.target.checked) {
            const index = this.state.answerArr.indexOf(event.target.name.split(' ')[0]);
            this.state.answerArr.splice(index, 1);
            this.state.answerArrToCheck.splice(index, 1);
        }

        if (this.state.answerArrToCheck.length === 2) {
            this.props.handleAnsweredQuestionNumber(questionNumber[0]);
            this.props.handleAnsweredQuestionNumber(questionNumber[1]);
        }

        this.setState({
            isError: this.state.answerArr.length === 2
        });

        this.state.answerObj.answers[this.props.currentTestObject.questions[0].no] = this.state.answerArr
        this.props.handleAnswerObj(this.state.answerObj);
        this.props.onFocusQuestion([questionNumber[0], questionNumber[1]]);
    }

    render() {
        const { classes } = this.props;

        return (
            <Grid container>
                <Grid item xs={12} style={{ padding: '30px' }}>
                    <Grid container>
                        {this.props.currentTestObject.questions.map(q =>
                            <Grid item xs={12} align='left'>
                                <Typography align='left' style={{ marginTop: '20px', fontSize: `${this.props.fontSize}rem` }}>
                                    {q.question}
                                </Typography>
                                <FormControl required component="fieldset" style={{ marginTop: '20px', paddingLeft: '20px' }}>
                                    <FormGroup>
                                        {q.choice.map(choice =>
                                            <FormControlLabel
                                                onFocus={() => { this.props.onFocusQuestion(Number(q.question)) }}
                                                disabled={this.state.isError && !this.state.answerArr.find(ans => ans === choice.split(' ')[0])}
                                                control={<Checkbox checked={!!this.state.answerArr.find(ans => ans === choice.split(' ')[0])} onChange={(event) => { this.handleAnswerQuestion(event) }} name={choice} style={{ color: '#25333e' }} />}
                                                label={<Typography style={{ fontSize: `${this.props.fontSize}rem` }} gutterBottom>{choice.split(". ")[1]}</Typography>}
                                            />
                                        )}
                                    </FormGroup>
                                </FormControl>

                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

export default compose(
    withStyles(useStyles)
)(ChooseTwoLetters);