//React
import { Component } from "react";

//MUI

//Import Footer
import Footer from "./components/footer/Footer";

//Import NavBarComponents

//Import Main Components
import HomePage from "./components/homepage/HomePage";
import Blog from "./components/main/Blog";
import Blogs from "./components/main/Blogs";
import Courses from "./components/main/Courses";
import FreeCourse from "./components/main/FreeCourses";
import LoginPage from "./components/main/LoginPage";
import MyProfile from "./components/main/MyProfile";
import Admin from "./components/management/admin/Admin";
import FullReport from "./components/myReport/BMAT/FullReport";
import MyReportPage from "./components/myReport/MyReportPage";
import ConfirmPaymentPage from "./components/payment/ConfirmPaymentPage";
import ForgotPasswordPage from "./components/updateUserInfo/ForgotPassword/ForgotPasswordPage";
import CreateNewPasswordPage from "./components/updateUserInfo/ForgotPassword/CreateNewPasswordPage";

//Import Blog components
import AddNewBlogPage from "./components/blog/AddNewBlogPage";
import EditBlogPage from "./components/blog/EditBlogPage";

//Import Test Components
import TestSchedulerPage from "./components/Scheduler/TestSchedulerPage";
import MainBMATTestPage from "./components/test/BMAT/MainBMATTestPage";
import MiniReportPage from "./components/test/IELTS/freeTest/report/MiniReportPage";
import FullTestMain from "./components/test/IELTS/fullTest/FullTestMain";
import FreeTestMain from "./components/test/IELTS/fullTest/FreeTestMain";
import TestWriting from "./components/test/IELTS/fullTest/tests/TestWriting";
import ScoringPage from "./components/test/IELTS/speaking/ScoringPage";
import TestEvaluationPage from "./components/test/TestEvaluationPage";
import TestListening from "./components/test/TestListenting";
import TestReading from "./components/test/TestReading";
import TestSpeaking from "./components/test/TestSpeaking";
import VerificationPage from "./components/test/verification/VerificationPage";

//Import Management Components
import TestManagement from "./components/management/TestManagement";
import TestModification from "./components/management/TestModification";
import TeacherDashboard from "./components/management/teacher/TeacherDashboard";

//Import dashboard components
import HeadTeacherDashboardPage from "./components/dashboard/headTeacher/HeadTeacherDashboardPage";
import SuperAdminDashboardPage from "./components/dashboard/superAdmin/SuperAdminDashboardPage";
import TeacherDashboardPage from "./components/dashboard/teacher/TeacherDashboardPage";
import TestAdminDashboardPage from "./components/dashboard/testAdmin/TestAdminDashboardPage";
import EditPart from "./components/dashboard/testAdmin/components/EditPart";
import EditSectionHistory from "./components/dashboard/testAdmin/components/EditSectionHistory";
import EditStrategy from "./components/dashboard/testAdmin/components/EditStrategy";
import EditTest from "./components/dashboard/testAdmin/components/EditTest";
import PreviewTest from "./components/dashboard/testAdmin/components/PreviewTest";

//New UI
import AboutUsPage from "./components/main/new/AboutUsPage";
import AdminReportPage from "./components/main/new/AdminReportPage";
import BlogsPage from "./components/main/new/BlogsPage";
import Copyrights from "./components/help/Copyrights";
import Dashboard from "./components/main/new/Dashboard";
import FAQ from "./components/help/FAQ";
import RefundPolicy from "./components/help/RefundPolicy";
import FinancialPage from "./components/main/new/FinancialPage";
import NewFullReportPage from "./components/main/new/NewFullReportPage";
import NewLoginPage from "./components/main/new/NewLoginPage";
import NewNavBar from "./components/main/new/NewNavBar";
import NewReportPage from "./components/main/new/NewReportPage";
import Privacy from "./components/help/Privacy";
import ProductPage from "./components/main/new/ProductPage";
import ProfilePage from "./components/main/new/ProfilePage";
import RegistrationPage from "./components/main/new/RegistrationPage";
import SocialRegistrationPage from "./components/main/new/SocialRegistrationPage";
import TryNowPage from "./components/main/new/TryNowPage";
import UsersPage from "./components/main/new/UsersPage";
import IELTSListeningNew from "./components/test/IELTSListeningNew";
import IELTSReadingNew from "./components/test/IELTSReadingNew";

//.css
import "./App.css";
//import router
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";

import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { GetUserDetail } from "./services/userServices";
import NewFullBMATReportPage from "./components/main/new/NewFullBMATReportPage";
import PageNotFound from "./components/notfound/PageNotFound";
import DoneVerifyPage from "./components/payment/DoneVerifyPage";
import DummyTestMain from "./components/test/IELTS/fullTest/DummyTestMain";
import PromptPayPage from "./components/payment/PromptPayPage";

//react-messenger-customer-chat

const theme = createMuiTheme({
  typography: {
    fontFamily: ["Roboto"].join(","),
  },
});

class App extends Component {
  constructor() {
    super();

    this.state = {
      currentPage: null,
      userSignedin: false,
      userSignup: false,
      user: {
        picture: {
          length: 0,
        },
        email: "guest@hotmail.com",
        name: "Guest",
      },
      isTest: false,
      joinMeeting: false,
      currentPage: "main",
    };

    this.handleSignin = this.handleSignin.bind(this);
    this.handleSignout = this.handleSignout.bind(this);
    this.handleSignup = this.handleSignup.bind(this);
    this.handleEndTest = this.handleEndTest.bind(this);
    this.handleStartTest = this.handleStartTest.bind(this);
  }

  componentDidMount() {
    window.addEventListener("beforeunload", function (event) {
      event.stopImmediatePropagation();
    });

    if (JSON.parse(localStorage.getItem("user")) !== null) {
      this.handleSignin(JSON.parse(localStorage.getItem("user")));
    } else {
      this.handleFalseLogin();
    }

    if (window.location.pathname == "/test") {
      window.location.href = "/freetests";
    }
    document.body.style.overflow = "auto";
  }

  async handleSignin(data) {
    const res = await GetUserDetail(
      JSON.parse(localStorage.getItem("user")).token
    );
    if (res.res.email) {
      await localStorage.setItem("user", JSON.stringify(data));

      this.setState({
        userSignedin: true,
        user: data,
      });

      return;
    } else {
      this.handleFalseLogin();

      return;
    }
  }

  handleFalseLogin = () => {
    if (
      window.location.pathname !== "/" &&
      window.location.pathname !== "/user/resetpassword" &&
      window.location.pathname !== "/freetests" &&
      window.location.pathname !== "/ourtests" &&
      window.location.pathname !== "/social-signup" &&
      window.location.pathname !== "/newlogin" &&
      window.location.pathname !== "/register" &&
      window.location.pathname !== "/user/forgotpassword" &&
      window.location.pathname !== "/aboutus" &&
      window.location.pathname !== "/blogs" &&
      window.location.pathname !== "/faq" &&
      window.location.pathname !== "/refund-policy" &&
      window.location.pathname !== "/copyrights" &&
      window.location.pathname !== "/privacy"
    ) {
      window.location.href = "/";
    }

    localStorage.setItem("user", null);
    localStorage.setItem("userSignedIn", false);

    return;
  };

  handleSignup(data) {
    this.setState({
      userSignedin: false,
      userSignup: true,
      user: data,
    });
  }

  handleSignout() {
    this.setState({
      userSignedin: false,
      userSignup: false,
      user: {},
    });
    localStorage.setItem("user", null);
    localStorage.setItem("userSignedIn", false);
  }

  handleStartTest() {
    document.body.style.overflow = "hidden";
    this.setState({
      isTest: true,
    });
  }

  handleEndTest() {
    document.body.style.overflow = "auto";
    localStorage.setItem("bmatSessionObj", null);

    this.setState({
      isTest: false,
    });
  }

  handleCurrentPage = async (page) => {
    await this.setState({
      currentPage: page,
    });

    if (page === "main") {
      this.setState({
        fontFamily: "Glacial Indifference",
      });
    } else if (page === "blog") {
      this.setState({
        fontFamily: "Glacial Indifference",
      });
    } else if (page === "test") {
      this.setState({
        fontFamily: "Glacial Indifference",
      });
    }
  };

  handleChangeMenu = (page) => {
    this.setState({
      currentPage: page,
    });
  };

  render() {
    return (
      <ThemeProvider theme={theme}>
        <div className="App">
          <Router>
            {/*!this.state.isTest && <NavigationBar
              handleSignout={this.handleSignout}
              handleEndTest={this.handleEndTest}
              handleStartTest={this.handleStartTest}
              userSignedin={this.state.userSignedin}
              user={this.state.user}
              isTest={this.state.isTest}
              userSignup={this.state.userSignup}
              handleChangeMenu={this.handleChangeMenu}
              currentPage={this.state.currentPage}
            />*/}
            <NewNavBar
              handleSignout={this.handleSignout}
              handleEndTest={this.handleEndTest}
              handleStartTest={this.handleStartTest}
              userSignedin={this.state.userSignedin}
              user={this.state.user}
              isTest={this.state.isTest}
              userSignup={this.state.userSignup}
              handleChangeMenu={this.handleChangeMenu}
              currentPage={this.state.currentPage}
            />
            {/*<Route exact
              path='/'
              render={props => (
                <Home {...props}
                  handleSignin={this.handleSignin}
                  handleSignout={this.handleSignout}
                  handleSignup={this.handleSignup}
                  handleEndTest={this.handleEndTest}
                  handleStartTest={this.handleStartTest}
                  userSignedin={this.state.userSignedin}
                  userSignup={this.state.userSignup}
                  handleChangeMenu={this.handleChangeMenu}
                  currentPage={this.state.currentPage}
                />
              )} />*/}

            {/* ====================================================== NEW UI ====================================================== */}
            <Switch>
              <Route
                exact
                path="/"
                render={(props) =>
                  ["SUPER_ADMIN", "ADMIN", "HEAD_TEACHER"].includes(
                    JSON.parse(localStorage.getItem("user"))?.role
                  ) ? (
                    <HomePage
                      {...props}
                      handleSignin={this.handleSignin}
                      handleSignout={this.handleSignout}
                      handleSignup={this.handleSignup}
                      handleEndTest={this.handleEndTest}
                      handleStartTest={this.handleStartTest}
                      userSignedin={this.state.userSignedin}
                      userSignup={this.state.userSignup}
                      handleChangeMenu={this.handleChangeMenu}
                      currentPage={this.state.currentPage}
                    />
                  ) : (
                    <HomePage
                      {...props}
                      handleSignin={this.handleSignin}
                      handleSignout={this.handleSignout}
                      handleSignup={this.handleSignup}
                      handleEndTest={this.handleEndTest}
                      handleStartTest={this.handleStartTest}
                      userSignedin={this.state.userSignedin}
                      userSignup={this.state.userSignup}
                      handleChangeMenu={this.handleChangeMenu}
                      currentPage={this.state.currentPage}
                    />
                  )
                }
              />

              <Route
                exact
                path="/newlogin"
                render={(props) => (
                  <NewLoginPage
                    {...props}
                    handleSignin={this.handleSignin}
                    handleSignout={this.handleSignout}
                    handleSignup={this.handleSignup}
                    handleEndTest={this.handleEndTest}
                    handleStartTest={this.handleStartTest}
                    userSignedin={this.state.userSignedin}
                    userSignup={this.state.userSignup}
                    handleChangeMenu={this.handleChangeMenu}
                    currentPage={this.state.currentPage}
                  />
                )}
              />

              <Route
                exact
                path="/test/payment/success"
                render={(props) => (
                  <DoneVerifyPage
                    {...props}
                    handleSignin={this.handleSignin}
                    handleSignout={this.handleSignout}
                    handleSignup={this.handleSignup}
                    handleEndTest={this.handleEndTest}
                    handleStartTest={this.handleStartTest}
                    userSignedin={this.state.userSignedin}
                    userSignup={this.state.userSignup}
                    handleChangeMenu={this.handleChangeMenu}
                    currentPage={this.state.currentPage}
                  />
                )}
              />

              <Route
                exact
                path="/register"
                render={(props) => (
                  <RegistrationPage
                    {...props}
                    handleSignin={this.handleSignin}
                    handleSignout={this.handleSignout}
                    handleSignup={this.handleSignup}
                    handleEndTest={this.handleEndTest}
                    handleStartTest={this.handleStartTest}
                    userSignedin={this.state.userSignedin}
                    userSignup={this.state.userSignup}
                    handleChangeMenu={this.handleChangeMenu}
                    currentPage={this.state.currentPage}
                  />
                )}
              />

              <Route
                exact
                path="/social-signup"
                render={(props) => (
                  <SocialRegistrationPage
                    {...props}
                    handleSignin={this.handleSignin}
                    handleSignout={this.handleSignout}
                    handleSignup={this.handleSignup}
                    handleEndTest={this.handleEndTest}
                    handleStartTest={this.handleStartTest}
                    userSignedin={this.state.userSignedin}
                    userSignup={this.state.userSignup}
                    handleChangeMenu={this.handleChangeMenu}
                    currentPage={this.state.currentPage}
                  />
                )}
              />

              <Route
                exact
                path="/profile"
                render={(props) => (
                  <ProfilePage
                    {...props}
                    handleSignin={this.handleSignin}
                    handleSignout={this.handleSignout}
                    handleSignup={this.handleSignup}
                    handleEndTest={this.handleEndTest}
                    handleStartTest={this.handleStartTest}
                    userSignedin={this.state.userSignedin}
                    userSignup={this.state.userSignup}
                    handleChangeMenu={this.handleChangeMenu}
                    currentPage={this.state.currentPage}
                  />
                )}
              />

              <Route
                exact
                path="/freetests"
                render={(props) => (
                  <ProductPage
                    {...props}
                    handleSignin={this.handleSignin}
                    handleSignout={this.handleSignout}
                    handleSignup={this.handleSignup}
                    handleEndTest={this.handleEndTest}
                    handleStartTest={this.handleStartTest}
                    userSignedin={this.state.userSignedin}
                    userSignup={this.state.userSignup}
                    handleChangeMenu={this.handleChangeMenu}
                    currentPage={this.state.currentPage}
                  />
                )}
              />

              {/* ============ HELP ============== */}
              <Route
                exact
                path="/faq"
                render={(props) => (
                  <FAQ
                    {...props}
                    handleSignin={this.handleSignin}
                    handleSignout={this.handleSignout}
                    handleSignup={this.handleSignup}
                    handleEndTest={this.handleEndTest}
                    handleStartTest={this.handleStartTest}
                    userSignedin={this.state.userSignedin}
                    userSignup={this.state.userSignup}
                    handleChangeMenu={this.handleChangeMenu}
                    currentPage={this.state.currentPage}
                  />
                )}
              />
              <Route
                exact
                path="/refund-policy"
                render={(props) => (
                  <RefundPolicy
                    {...props}
                    handleSignin={this.handleSignin}
                    handleSignout={this.handleSignout}
                    handleSignup={this.handleSignup}
                    handleEndTest={this.handleEndTest}
                    handleStartTest={this.handleStartTest}
                    userSignedin={this.state.userSignedin}
                    userSignup={this.state.userSignup}
                    handleChangeMenu={this.handleChangeMenu}
                    currentPage={this.state.currentPage}
                  />
                )}
              />
              <Route
                exact
                path="/copyrights"
                render={(props) => (
                  <Copyrights
                    {...props}
                    handleSignin={this.handleSignin}
                    handleSignout={this.handleSignout}
                    handleSignup={this.handleSignup}
                    handleEndTest={this.handleEndTest}
                    handleStartTest={this.handleStartTest}
                    userSignedin={this.state.userSignedin}
                    userSignup={this.state.userSignup}
                    handleChangeMenu={this.handleChangeMenu}
                    currentPage={this.state.currentPage}
                  />
                )}
              />
              <Route
                exact
                path="/privacy"
                render={(props) => (
                  <Privacy
                    {...props}
                    handleSignin={this.handleSignin}
                    handleSignout={this.handleSignout}
                    handleSignup={this.handleSignup}
                    handleEndTest={this.handleEndTest}
                    handleStartTest={this.handleStartTest}
                    userSignedin={this.state.userSignedin}
                    userSignup={this.state.userSignup}
                    handleChangeMenu={this.handleChangeMenu}
                    currentPage={this.state.currentPage}
                  />
                )}
              />
              {/* ============ HELP ============== */}

              <Route
                exact
                path="/ourtests"
                render={(props) => (
                  <TryNowPage
                    {...props}
                    handleSignin={this.handleSignin}
                    handleSignout={this.handleSignout}
                    handleSignup={this.handleSignup}
                    handleEndTest={this.handleEndTest}
                    handleStartTest={this.handleStartTest}
                    userSignedin={this.state.userSignedin}
                    userSignup={this.state.userSignup}
                    handleChangeMenu={this.handleChangeMenu}
                    currentPage={this.state.currentPage}
                  />
                )}
              />

              <Route
                exact
                path="/blogs"
                render={(props) => (
                  <BlogsPage
                    {...props}
                    userSignedin={this.state.userSignedin}
                    user={this.state.user}
                    userSignup={this.state.userSignup}
                    handleChangeMenu={this.handleChangeMenu}
                    currentPage={this.state.currentPage}
                    handleEndTest={this.handleEndTest}
                    handleStartTest={this.handleStartTest}
                  />
                )}
              />

              <Route
                exact
                path="/testpage"
                render={(props) => (
                  <IELTSReadingNew
                    {...props}
                    handleSignin={this.handleSignin}
                    handleSignout={this.handleSignout}
                    handleSignup={this.handleSignup}
                    handleEndTest={this.handleEndTest}
                    handleStartTest={this.handleStartTest}
                    userSignedin={this.state.userSignedin}
                    userSignup={this.state.userSignup}
                    handleChangeMenu={this.handleChangeMenu}
                    currentPage={this.state.currentPage}
                    user={this.state.user}
                    isTest={this.state.isTest}
                  />
                )}
              />
              <Route
                exact
                path="/listening-test-page"
                render={(props) => (
                  <IELTSListeningNew
                    {...props}
                    handleSignin={this.handleSignin}
                    handleSignout={this.handleSignout}
                    handleSignup={this.handleSignup}
                    handleEndTest={this.handleEndTest}
                    handleStartTest={this.handleStartTest}
                    userSignedin={this.state.userSignedin}
                    userSignup={this.state.userSignup}
                    handleChangeMenu={this.handleChangeMenu}
                    currentPage={this.state.currentPage}
                    user={this.state.user}
                    isTest={this.state.isTest}
                  />
                )}
              />

              <Route
                exact
                path="/report"
                render={(props) => (
                  <NewReportPage
                    {...props}
                    handleSignin={this.handleSignin}
                    handleSignout={this.handleSignout}
                    handleSignup={this.handleSignup}
                    handleEndTest={this.handleEndTest}
                    handleStartTest={this.handleStartTest}
                    userSignedin={this.state.userSignedin}
                    userSignup={this.state.userSignup}
                    handleChangeMenu={this.handleChangeMenu}
                    currentPage={this.state.currentPage}
                  />
                )}
              />
              <Route
                exact
                path="/full-bmat-report"
                render={(props) => (
                  <NewFullBMATReportPage
                    {...props}
                    handleSignin={this.handleSignin}
                    handleSignout={this.handleSignout}
                    handleSignup={this.handleSignup}
                    handleEndTest={this.handleEndTest}
                    handleStartTest={this.handleStartTest}
                    userSignedin={this.state.userSignedin}
                    userSignup={this.state.userSignup}
                    handleChangeMenu={this.handleChangeMenu}
                    currentPage={this.state.currentPage}
                  />
                )}
              />
              <Route
                exact
                path="/full-report"
                render={(props) => (
                  <NewFullReportPage
                    {...props}
                    handleSignin={this.handleSignin}
                    handleSignout={this.handleSignout}
                    handleSignup={this.handleSignup}
                    handleEndTest={this.handleEndTest}
                    handleStartTest={this.handleStartTest}
                    userSignedin={this.state.userSignedin}
                    userSignup={this.state.userSignup}
                    handleChangeMenu={this.handleChangeMenu}
                    currentPage={this.state.currentPage}
                  />
                )}
              />

              <Route
                exact
                path="/users"
                render={(props) => (
                  <UsersPage
                    {...props}
                    handleSignin={this.handleSignin}
                    handleSignout={this.handleSignout}
                    handleSignup={this.handleSignup}
                    handleEndTest={this.handleEndTest}
                    handleStartTest={this.handleStartTest}
                    userSignedin={this.state.userSignedin}
                    userSignup={this.state.userSignup}
                    handleChangeMenu={this.handleChangeMenu}
                    currentPage={this.state.currentPage}
                  />
                )}
              />
              <Route
                exact
                path="/dashboard"
                render={(props) => (
                  <Dashboard
                    {...props}
                    handleSignin={this.handleSignin}
                    handleSignout={this.handleSignout}
                    handleSignup={this.handleSignup}
                    handleEndTest={this.handleEndTest}
                    handleStartTest={this.handleStartTest}
                    userSignedin={this.state.userSignedin}
                    userSignup={this.state.userSignup}
                    handleChangeMenu={this.handleChangeMenu}
                    currentPage={this.state.currentPage}
                  />
                )}
              />
              <Route
                exact
                path="/adminreport"
                render={(props) => (
                  <AdminReportPage
                    {...props}
                    handleSignin={this.handleSignin}
                    handleSignout={this.handleSignout}
                    handleSignup={this.handleSignup}
                    handleEndTest={this.handleEndTest}
                    handleStartTest={this.handleStartTest}
                    userSignedin={this.state.userSignedin}
                    userSignup={this.state.userSignup}
                    handleChangeMenu={this.handleChangeMenu}
                    currentPage={this.state.currentPage}
                  />
                )}
              />

              <Route
                exact
                path="/aboutus"
                render={(props) => (
                  <AboutUsPage
                    {...props}
                    handleSignin={this.handleSignin}
                    handleSignout={this.handleSignout}
                    handleSignup={this.handleSignup}
                    handleEndTest={this.handleEndTest}
                    handleStartTest={this.handleStartTest}
                    userSignedin={this.state.userSignedin}
                    userSignup={this.state.userSignup}
                    handleChangeMenu={this.handleChangeMenu}
                    currentPage={this.state.currentPage}
                  />
                )}
              />

              {/* ============ FINANCIAL ============== */}
              <Route
                exact
                path="/financial"
                render={(props) => (
                  <FinancialPage
                    {...props}
                    handleSignin={this.handleSignin}
                    handleSignout={this.handleSignout}
                    handleSignup={this.handleSignup}
                    userSignedin={this.state.userSignedin}
                    userSignup={this.state.userSignup}
                    handleChangeMenu={this.handleChangeMenu}
                    currentPage={this.state.currentPage}
                  />
                )}
              />
              {/* ============ FINANCIAL ============== */}

              {/* ====================================================== END NEW UI ====================================================== */}
              <Route
                exact
                path="/newhomepage"
                render={(props) => (
                  <HomePage
                    {...props}
                    handleSignin={this.handleSignin}
                    handleSignout={this.handleSignout}
                    handleSignup={this.handleSignup}
                    handleEndTest={this.handleEndTest}
                    handleStartTest={this.handleStartTest}
                    userSignedin={this.state.userSignedin}
                    userSignup={this.state.userSignup}
                    handleChangeMenu={this.handleChangeMenu}
                    currentPage={this.state.currentPage}
                  />
                )}
              />
              <Route
                exact
                path="/login"
                render={(props) => (
                  <LoginPage
                    {...props}
                    handleSignin={this.handleSignin}
                    handleSignout={this.handleSignout}
                    handleSignup={this.handleSignup}
                    handleEndTest={this.handleEndTest}
                    handleStartTest={this.handleStartTest}
                    userSignedin={this.state.userSignedin}
                    userSignup={this.state.userSignup}
                    handleChangeMenu={this.handleChangeMenu}
                    currentPage={this.state.currentPage}
                  />
                )}
              />

              {/* =================== FORGOT PASSWORD =================== */}
              <Route
                exact
                path="/user/forgotpassword"
                render={(props) => <ForgotPasswordPage />}
              />

              <Route
                exact
                path="/user/resetpassword"
                render={(props) => (
                  <CreateNewPasswordPage
                    {...props}
                    handleSignout={this.handleSignout}
                  />
                )}
              />
              {/* =================== FORGOT PASSWORD =================== */}

              <Route
                exact
                path="/myprofile"
                render={(props) => (
                  <MyProfile
                    {...props}
                    userSignedin={this.state.userSignedin}
                    user={this.state.user}
                    userSignup={this.state.userSignup}
                    handleChangeMenu={this.handleChangeMenu}
                    currentPage={this.state.currentPage}
                    handleEndTest={this.handleEndTest}
                    handleStartTest={this.handleStartTest}
                  />
                )}
              />
              <Route
                exact
                path="/myprofile/report"
                render={(props) => (
                  <MyReportPage
                    {...props}
                    userSignedin={this.state.userSignedin}
                    user={this.state.user}
                    userSignup={this.state.userSignup}
                    handleChangeMenu={this.handleChangeMenu}
                    currentPage={this.state.currentPage}
                    handleEndTest={this.handleEndTest}
                    handleStartTest={this.handleStartTest}
                  />
                )}
              />
              <Route
                exact
                path="/myprofile/report/bmat"
                render={(props) => (
                  <FullReport
                    {...props}
                    userSignedin={this.state.userSignedin}
                    user={this.state.user}
                    userSignup={this.state.userSignup}
                    handleChangeMenu={this.handleChangeMenu}
                    currentPage={this.state.currentPage}
                    handleEndTest={this.handleEndTest}
                    handleStartTest={this.handleStartTest}
                  />
                )}
              />
              <Route
                exact
                path="/courses"
                render={(props) => (
                  <Courses
                    {...props}
                    userSignedin={this.state.userSignedin}
                    user={this.state.user}
                    userSignup={this.state.userSignup}
                    handleChangeMenu={this.handleChangeMenu}
                    currentPage={this.state.currentPage}
                    handleEndTest={this.handleEndTest}
                    handleStartTest={this.handleStartTest}
                  />
                )}
              />
              <Route
                exact
                path="/free-course"
                render={(props) => (
                  <FreeCourse
                    {...props}
                    userSignedin={this.state.userSignedin}
                    user={this.state.user}
                    userSignup={this.state.userSignup}
                    handleChangeMenu={this.handleChangeMenu}
                    currentPage={this.state.currentPage}
                    handleEndTest={this.handleEndTest}
                    handleStartTest={this.handleStartTest}
                  />
                )}
              />
              <Route
                exact
                path="/admin"
                render={(props) => (
                  <Admin
                    {...props}
                    userSignedin={this.state.userSignedin}
                    user={this.state.user}
                    userSignup={this.state.userSignup}
                    handleChangeMenu={this.handleChangeMenu}
                    currentPage={this.state.currentPage}
                    handleEndTest={this.handleEndTest}
                    handleStartTest={this.handleStartTest}
                  />
                )}
              />
              {/* ====================================================== BMAT ====================================================== */}
              <Route
                exact
                path="/test/bmat"
                render={(props) => (
                  <MainBMATTestPage
                    {...props}
                    userSignedin={this.state.userSignedin}
                    user={this.state.user}
                    userSignup={this.state.userSignup}
                    handleChangeMenu={this.handleChangeMenu}
                    currentPage={this.state.currentPage}
                    handleEndTest={this.handleEndTest}
                    handleStartTest={this.handleStartTest}
                    isTest={this.state.isTest}
                  />
                )}
              />
              {/* ====================================================== BMAT ====================================================== */}

              {/* ====================================================== IELTS ====================================================== */}
              <Route
                exact
                path="/test/verification"
                render={(props) => (
                  <VerificationPage
                    {...props}
                    userSignedin={this.state.userSignedin}
                    user={this.state.user}
                    userSignup={this.state.userSignup}
                    handleChangeMenu={this.handleChangeMenu}
                    currentPage={this.state.currentPage}
                    handleEndTest={this.handleEndTest}
                    handleStartTest={this.handleStartTest}
                  />
                )}
              />
              <Route
                exact
                path="/test/full-test"
                render={(props) => (
                  <FullTestMain
                    {...props}
                    userSignedin={this.state.userSignedin}
                    user={this.state.user}
                    userSignup={this.state.userSignup}
                    handleChangeMenu={this.handleChangeMenu}
                    currentPage={this.state.currentPage}
                    handleEndTest={this.handleEndTest}
                    handleStartTest={this.handleStartTest}
                  />
                )}
              />

              <Route
                exact
                path="/freetests/test"
                render={(props) => (
                  <FreeTestMain
                    {...props}
                    userSignedin={this.state.userSignedin}
                    user={this.state.user}
                    userSignup={this.state.userSignup}
                    handleChangeMenu={this.handleChangeMenu}
                    currentPage={this.state.currentPage}
                    handleEndTest={this.handleEndTest}
                    handleStartTest={this.handleStartTest}
                  />
                )}
              />
              <Route
                exact
                path="/dummytests/test"
                render={(props) => (
                  <DummyTestMain
                    {...props}
                    userSignedin={this.state.userSignedin}
                    user={this.state.user}
                    userSignup={this.state.userSignup}
                    handleChangeMenu={this.handleChangeMenu}
                    currentPage={this.state.currentPage}
                    handleEndTest={this.handleEndTest}
                    handleStartTest={this.handleStartTest}
                  />
                )}
              />

              <Route
                exact
                path="/test/reading"
                render={(props) => (
                  <TestReading
                    {...props}
                    userSignedin={this.state.userSignedin}
                    user={this.state.user}
                    userSignup={this.state.userSignup}
                    handleChangeMenu={this.handleChangeMenu}
                    currentPage={this.state.currentPage}
                    handleEndTest={this.handleEndTest}
                    handleStartTest={this.handleStartTest}
                    isTest={this.state.isTest}
                  />
                )}
              />
              <Route
                exact
                path="/test/listening"
                render={(props) => (
                  <TestListening
                    {...props}
                    userSignedin={this.state.userSignedin}
                    user={this.state.user}
                    userSignup={this.state.userSignup}
                    handleChangeMenu={this.handleChangeMenu}
                    currentPage={this.state.currentPage}
                    handleEndTest={this.handleEndTest}
                    handleStartTest={this.handleStartTest}
                    isTest={this.state.isTest}
                  />
                )}
              />
              <Route
                exact
                path="/test/writing"
                render={(props) => (
                  <TestWriting
                    {...props}
                    userSignedin={this.state.userSignedin}
                    user={this.state.user}
                    userSignup={this.state.userSignup}
                    handleChangeMenu={this.handleChangeMenu}
                    currentPage={this.state.currentPage}
                    handleEndTest={this.handleEndTest}
                    handleStartTest={this.handleStartTest}
                  />
                )}
              />
              <Route
                exact
                path="/test/speaking/scoring"
                render={(props) => (
                  <ScoringPage
                    {...props}
                    userSignedin={this.state.userSignedin}
                    user={this.state.user}
                    userSignup={this.state.userSignup}
                    handleChangeMenu={this.handleChangeMenu}
                    currentPage={this.state.currentPage}
                    handleEndTest={this.handleEndTest}
                    handleStartTest={this.handleStartTest}
                  />
                )}
              />
              <Route
                exact
                path="/test/speaking/meeting-id/:id/password/:password/role/:role"
                render={(props) => (
                  <TestSpeaking
                    {...props}
                    joinMeeting={this.state.joinMeeting}
                    userSignedin={this.state.userSignedin}
                    user={this.state.user}
                    userSignup={this.state.userSignup}
                    handleChangeMenu={this.handleChangeMenu}
                    currentPage={this.state.currentPage}
                    handleEndTest={this.handleEndTest}
                    handleStartTest={this.handleStartTest}
                  />
                )}
              />
              <Route
                exact
                path="/test/scheduler"
                render={(props) => (
                  <TestSchedulerPage
                    {...props}
                    userSignedin={this.state.userSignedin}
                    user={this.state.user}
                    userSignup={this.state.userSignup}
                    handleChangeMenu={this.handleChangeMenu}
                    currentPage={this.state.currentPage}
                    handleEndTest={this.handleEndTest}
                    handleStartTest={this.handleStartTest}
                  />
                )}
              />
              <Route
                exact
                path="/test/free/report"
                render={(props) => (
                  <MiniReportPage
                    {...props}
                    userSignedin={this.state.userSignedin}
                    user={this.state.user}
                    userSignup={this.state.userSignup}
                    handleChangeMenu={this.handleChangeMenu}
                    currentPage={this.state.currentPage}
                    handleEndTest={this.handleEndTest}
                    handleStartTest={this.handleStartTest}
                  />
                )}
              />

              {/* ====================================================== IELTS ====================================================== */}

              {/* ====================================================== BLOG ====================================================== */}
              <Route
                exact
                path="/blogs/:id"
                render={(props) => (
                  <Blog
                    {...props}
                    userSignedin={this.state.userSignedin}
                    user={this.state.user}
                    userSignup={this.state.userSignup}
                    handleChangeMenu={this.handleChangeMenu}
                    currentPage={this.state.currentPage}
                    handleEndTest={this.handleEndTest}
                    handleStartTest={this.handleStartTest}
                  />
                )}
              />
              <Route
                exact
                path="/blogs/:id/edit"
                render={(props) => (
                  <EditBlogPage
                    {...props}
                    userSignedin={this.state.userSignedin}
                    user={this.state.user}
                    userSignup={this.state.userSignup}
                    handleChangeMenu={this.handleChangeMenu}
                    currentPage={this.state.currentPage}
                    handleEndTest={this.handleEndTest}
                    handleStartTest={this.handleStartTest}
                  />
                )}
              />
              <Route
                exact
                path="/blogs"
                render={(props) => (
                  <Blogs
                    {...props}
                    userSignedin={this.state.userSignedin}
                    user={this.state.user}
                    userSignup={this.state.userSignup}
                    handleChangeMenu={this.handleChangeMenu}
                    currentPage={this.state.currentPage}
                    handleEndTest={this.handleEndTest}
                    handleStartTest={this.handleStartTest}
                  />
                )}
              />
              <Route
                exact
                path="/newblog"
                render={(props) => (
                  <AddNewBlogPage
                    {...props}
                    userSignedin={this.state.userSignedin}
                    user={this.state.user}
                    userSignup={this.state.userSignup}
                    handleChangeMenu={this.handleChangeMenu}
                    currentPage={this.state.currentPage}
                    handleEndTest={this.handleEndTest}
                    handleStartTest={this.handleStartTest}
                  />
                )}
              />
              {/* ====================================================== BLOG ====================================================== */}

              <Route
                exact
                path="/test/management"
                render={(props) => (
                  <TestManagement
                    {...props}
                    userSignedin={this.state.userSignedin}
                    user={this.state.user}
                    userSignup={this.state.userSignup}
                    handleChangeMenu={this.handleChangeMenu}
                    currentPage={this.state.currentPage}
                    handleEndTest={this.handleEndTest}
                    handleStartTest={this.handleStartTest}
                  />
                )}
              />
              <Route
                exact
                path="/test/management/:id"
                render={(props) => (
                  <TestModification
                    {...props}
                    userSignedin={this.state.userSignedin}
                    user={this.state.user}
                    userSignup={this.state.userSignup}
                    handleChangeMenu={this.handleChangeMenu}
                    currentPage={this.state.currentPage}
                    handleEndTest={this.handleEndTest}
                    handleStartTest={this.handleStartTest}
                  />
                )}
              />
              <Route
                exact
                path="/test/ielts/reading/report"
                render={(props) => (
                  <MiniReportPage
                    {...props}
                    userSignedin={this.state.userSignedin}
                    user={this.state.user}
                    userSignup={this.state.userSignup}
                    handleChangeMenu={this.handleChangeMenu}
                    currentPage={this.state.currentPage}
                    handleEndTest={this.handleEndTest}
                    handleStartTest={this.handleStartTest}
                  />
                )}
              />
              {/* ====================================================== IELTS ====================================================== */}

              {/*===================================================== EVALUATION ===============================================*/}
              <Route
                exact
                path="/test/evaluation"
                render={(props) => (
                  <TestEvaluationPage
                    {...props}
                    userSignedin={this.state.userSignedin}
                    user={this.state.user}
                    userSignup={this.state.userSignup}
                    handleChangeMenu={this.handleChangeMenu}
                    currentPage={this.state.currentPage}
                    handleEndTest={this.handleEndTest}
                    handleStartTest={this.handleStartTest}
                  />
                )}
              />
              {/*===================================================== EVALUATION ===============================================*/}

              {/*===================================================== TEACHER ===============================================*/}
              <Route
                exact
                path="/teacher/dashboard"
                render={(props) => (
                  <TeacherDashboard
                    {...props}
                    userSignedin={this.state.userSignedin}
                    user={this.state.user}
                    userSignup={this.state.userSignup}
                    handleChangeMenu={this.handleChangeMenu}
                    currentPage={this.state.currentPage}
                    handleEndTest={this.handleEndTest}
                    handleStartTest={this.handleStartTest}
                  />
                )}
              />
              {/*===================================================== TEACHER ===============================================*/}

              {/*===================================================== DASHBOARDS ===============================================*/}
              <Route
                exact
                path="/dashboard/teacher"
                render={(props) => (
                  <TeacherDashboardPage
                    {...props}
                    userSignedin={this.state.userSignedin}
                    user={this.state.user}
                    userSignup={this.state.userSignup}
                    handleChangeMenu={this.handleChangeMenu}
                    currentPage={this.state.currentPage}
                    handleEndTest={this.handleEndTest}
                    handleStartTest={this.handleStartTest}
                  />
                )}
              />

              <Route
                exact
                path="/dashboard/head-teacher"
                render={(props) => (
                  <HeadTeacherDashboardPage
                    {...props}
                    userSignedin={this.state.userSignedin}
                    user={this.state.user}
                    userSignup={this.state.userSignup}
                    handleChangeMenu={this.handleChangeMenu}
                    currentPage={this.state.currentPage}
                    handleEndTest={this.handleEndTest}
                    handleStartTest={this.handleStartTest}
                  />
                )}
              />

              <Route
                exact
                path="/dashboard/super-admin"
                render={(props) => (
                  <SuperAdminDashboardPage
                    {...props}
                    userSignedin={this.state.userSignedin}
                    user={this.state.user}
                    userSignup={this.state.userSignup}
                    handleChangeMenu={this.handleChangeMenu}
                    currentPage={this.state.currentPage}
                    handleEndTest={this.handleEndTest}
                    handleStartTest={this.handleStartTest}
                  />
                )}
              />

              <Route
                exact
                path="/dashboard/test-admin"
                render={(props) => (
                  <TestAdminDashboardPage
                    {...props}
                    userSignedin={this.state.userSignedin}
                    user={this.state.user}
                    userSignup={this.state.userSignup}
                    handleChangeMenu={this.handleChangeMenu}
                    currentPage={this.state.currentPage}
                    handleEndTest={this.handleEndTest}
                    handleStartTest={this.handleStartTest}
                  />
                )}
              />

              <Route
                exact
                path="/dashboard/test-admin/tests/:test_id/edit"
                render={(props) => (
                  <EditTest
                    {...props}
                    userSignedin={this.state.userSignedin}
                    user={this.state.user}
                    userSignup={this.state.userSignup}
                    handleChangeMenu={this.handleChangeMenu}
                    currentPage={this.state.currentPage}
                    handleEndTest={this.handleEndTest}
                    handleStartTest={this.handleStartTest}
                  />
                )}
              />

              <Route
                exact
                path="/dashboard/test-admin/tests/:test_id/parts/:part_id/preview"
                render={(props) => (
                  <PreviewTest
                    {...props}
                    userSignedin={this.state.userSignedin}
                    user={this.state.user}
                    userSignup={this.state.userSignup}
                    handleChangeMenu={this.handleChangeMenu}
                    currentPage={this.state.currentPage}
                    handleEndTest={this.handleEndTest}
                    handleStartTest={this.handleStartTest}
                  />
                )}
              />

              <Route
                exact
                path="/dashboard/test-admin/parts/:part_id/edit"
                render={(props) => (
                  <EditPart
                    {...props}
                    userSignedin={this.state.userSignedin}
                    user={this.state.user}
                    userSignup={this.state.userSignup}
                    handleChangeMenu={this.handleChangeMenu}
                    currentPage={this.state.currentPage}
                    handleEndTest={this.handleEndTest}
                    handleStartTest={this.handleStartTest}
                  />
                )}
              />

              <Route
                exact
                path="/dashboard/test-admin/tests/:test_id/parts/:part_id/sections/:section_id/history"
                render={(props) => (
                  <EditSectionHistory
                    {...props}
                    userSignedin={this.state.userSignedin}
                    user={this.state.user}
                    userSignup={this.state.userSignup}
                    handleChangeMenu={this.handleChangeMenu}
                    currentPage={this.state.currentPage}
                    handleEndTest={this.handleEndTest}
                    handleStartTest={this.handleStartTest}
                  />
                )}
              />

              <Route
                exact
                path="/dashboard/test-admin/strategy/:strategy_id/edit"
                render={(props) => (
                  <EditStrategy
                    {...props}
                    userSignedin={this.state.userSignedin}
                    user={this.state.user}
                    userSignup={this.state.userSignup}
                    handleChangeMenu={this.handleChangeMenu}
                    currentPage={this.state.currentPage}
                    handleEndTest={this.handleEndTest}
                    handleStartTest={this.handleStartTest}
                  />
                )}
              />

              {/*===================================================== DASHBOARDS ===============================================*/}

              {/*===================================================== VERIFICATION ===============================================*/}
              <Route
                exact
                path="/test/user/verification"
                render={(props) => (
                  <VerificationPage
                    {...props}
                    userSignedin={this.state.userSignedin}
                    user={this.state.user}
                    userSignup={this.state.userSignup}
                    handleChangeMenu={this.handleChangeMenu}
                    currentPage={this.state.currentPage}
                    handleEndTest={this.handleEndTest}
                    handleStartTest={this.handleStartTest}
                  />
                )}
              />
              {/*===================================================== VERIFICATION ===============================================*/}

              {/*===================================================== PAYMENT ===============================================*/}
              <Route
                exact
                path="/test/payment"
                render={(props) => (
                  <ConfirmPaymentPage
                    {...props}
                    userSignedin={this.state.userSignedin}
                    user={this.state.user}
                    userSignup={this.state.userSignup}
                    handleChangeMenu={this.handleChangeMenu}
                    currentPage={this.state.currentPage}
                    handleEndTest={this.handleEndTest}
                    handleStartTest={this.handleStartTest}
                  />
                )}
              />

              <Route
                exact
                path="/test/payment/promptpay"
                render={(props) => (
                  <PromptPayPage
                    {...props}
                    userSignedin={this.state.userSignedin}
                    user={this.state.user}
                    userSignup={this.state.userSignup}
                    handleChangeMenu={this.handleChangeMenu}
                    currentPage={this.state.currentPage}
                    handleEndTest={this.handleEndTest}
                    handleStartTest={this.handleStartTest}
                  />
                )}
              />

              <Route render={(props) => <PageNotFound />} />
            </Switch>
            {/*===================================================== PAYMENT ===============================================*/}
            {!this.state.isTest && <Footer />}
          </Router>
          {/*!this.state.isTest &&
            <MessengerCustomerChat
              pageId="133454780590828"
              appId="1235660373518321"
            />
              */}
        </div>
      </ThemeProvider>
    );
  }
}

export default App;
