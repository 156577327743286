import React from 'react';
import ReactDOM from 'react-dom';

//Material UI
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import LinearProgress from '@material-ui/core/LinearProgress';
import MuiAlert from '@material-ui/lab/Alert';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

//Components
import LoadingBackdrop from '../../public/components/LoadingBackdrop';

//Import Question type
//import MultipleChoice from '../../../test/IELTS/listening/questions/MultipleChoice';
import Matching from '../components/previewTest/listening/questions/Matching';
import CompleteTheTables from '../components/previewTest/listening/questions/CompleteTheTables';
import CompleteTheNotes from '../components/previewTest/listening/questions/CompleteTheNotes';
import ChooseTwoLetters from '../components/previewTest/listening/questions/ChooseTwoLetters';
import Map from '../components/previewTest/listening/questions/Map';

import TrueFalseNG from '../components/previewTest/reading/questions/TrueFalseNG';
import YesNoNG from '../components/previewTest/reading/questions/YesNoNG';
import NoteCompletion from '../components/previewTest/reading/questions/NoteCompletion';
import ListOfHeadings from '../components/previewTest/reading/questions/ListOfHeadings';
import MatchingSentenceEndings from '../components/previewTest/reading/questions/MatchingSentenceEndings';
import SentenceCompletion from '../components/previewTest/reading/questions/SentenceCompletion';
import MultipleChoice from '../components/previewTest/reading/questions/MultipleChoice';
import DisplayParagraph from './previewTest/reading/paragraph/DisplayParagraph';

//Contants
import * as apiConstants from '../../../../constants/apiConstants';

//Others
import { withStyles } from '@material-ui/core/styles';
import { Component } from 'react';
import { compose } from 'recompose';
import { Link } from "react-router-dom";
import ReactAudioPlayer from 'react-audio-player';

const axios = require('axios').default;
const FormData = require('form-data');
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

const useStyles = theme => ({
    root: {
        margin: 15,
        marginTop: 50
    },
    margin: {
        margin: theme.spacing(1),
    },
    buttonColor: {
        backgroundColor: '#76323F',
        color: 'white'
    },
    heading: {
        fontWeight: 'bold'
    },
});

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class PreviewTest extends Component {
    constructor() {
        super()
        this.state = {
            addedQuestionArr: [],
            currentSection: 0,
            isEdit: false,
            isLoading: false,
            isLoadingTest: false,
            isFree: false,
            isPrepared: false,
            selectedSectionId: '',
            selectedParagraph: -1,
            window: {
                height: window.innerHeight,
                width: window.innerWidth
            }
        };
    }

    componentDidMount = async () => {
        this.handleLoadingTest();

        if (this.props.match.params.test_id === 'editPart') {
            await this.handleGetPart();
        } if (this.props.match.params.test_id !== 'editPart') {
            await this.handleGetTests();
        }

        this.handleEndLoadingTest();
        console.log(window.innerHeight);
        document.querySelector("body").scrollTo(0, 0);
        window.removeEventListener("resize", this.handleResize);
    }

    handleResize = (event) => {
        this.setState({
            window: {
                height: window.innerHeight,
                width: window.innerWidth
            }
        });
    };

    //==================================== PREPARATION =======================================
    handleGetTests = async () => {
        if (this.props.match.params.test_id !== undefined && this.props.match.params.part_id !== undefined) {
            try {
                const res = await axios({
                    method: 'get',
                    url: `${api_base_url}/api/test/?test_id=${this.props.match.params.test_id}`,
                    headers: {
                        "Authorization": "Bearer " + JSON.parse(localStorage.getItem('user')).token
                    }
                });

                const resData = res.data[0];
                const filteredTestObj = await resData.parts.filter((part) => part.id === this.props.match.params.part_id)

                this.setState({
                    partObj: filteredTestObj[0],
                    sectionArr: filteredTestObj[0].sections
                });

                console.log(this.state.partObj);
            } catch (err) {
                console.log(err);
            }
        }
    }

    handleGetPart = async () => {
        if (this.props.match.params.part_id !== undefined) {
            try {
                let secArr = [];

                const res = await axios({
                    method: 'get',
                    url: `${api_base_url}/api/test/part/id/${this.props.match.params.part_id}`,
                    headers: {
                        "Authorization": "Bearer " + JSON.parse(localStorage.getItem('user')).token
                    }
                });

                const resData = res.data.part;

                await resData.sections.map(async (section) => {
                    const secObj = await axios({
                        method: 'get',
                        url: `${api_base_url}/api/test/section/id/${section}`,
                        headers: {
                            "Authorization": "Bearer " + JSON.parse(localStorage.getItem('user')).token
                        }
                    });

                    secArr.push(secObj.data.section);

                    this.setState({
                        sectionArr: secArr
                    });
                });

                this.setState({
                    partObj: resData
                });

                console.log(this.state);

            } catch (err) {
                console.log(err);
            }
        }
    }

    handleLoadingTest = () => {
        this.setState({
            isLoadingTest: true
        });
    }

    handleEndLoadingTest = () => {
        this.setState({
            isLoadingTest: false
        });
    }
    //==================================== PREPARATION =======================================

    onFocusQuestion = (questionNumber) => {
        console.log(this.state.focusedQuestion);
    }

    //==================================== READING QUESTIONS =======================================
    handleSelectReadingQuestionType = (section) => {
        if (section.question_type === 'trueFalseNG') {
            return <TrueFalseNG
                key={section.section_id}
                sectionInfo={section}
                answer={this.state.answer}
                answerObj={this.state.answerObj}
                handleChange={this.handleChange}
                currentPart={this.state.currentPart}
                handleAnswerObj={this.handleAnswerObj}
                onFocusQuestion={this.onFocusQuestion}
                handleAnsweredQuestionNumber={this.handleAnsweredQuestionNumber}
                isPreview={true}
            />
        }

        else if (section.question_type === 'yesNoNG') {
            return <YesNoNG
                key={section.section_id}
                sectionInfo={section}
                answer={this.state.answer}
                answerObj={this.state.answerObj}
                handleChange={this.handleChange}
                currentPart={this.state.currentPart}
                handleAnswerObj={this.handleAnswerObj}
                onFocusQuestion={this.onFocusQuestion}
                handleAnsweredQuestionNumber={this.handleAnsweredQuestionNumber}
                isPreview={true}
            />
        }

        else if (section.question_type === 'noteCompletion') {
            return <NoteCompletion
                key={section.section_id}
                sectionInfo={section}
                answer={this.state.answer}
                answerObj={this.state.answerObj}
                currentPart={this.state.currentPart}
                handleChange={this.handleChange}
                handleAnswerObj={this.handleAnswerObj}
                onFocusQuestion={this.onFocusQuestion}
                handleAnsweredQuestionNumber={this.handleAnsweredQuestionNumber}
                isPreview={true}
            />
        }

        else if (section.question_type === 'listOfHeading') {
            return <ListOfHeadings
                key={section.section_id}
                sectionInfo={section}
                answer={this.state.answer}
                answerObj={this.state.answerObj}
                handleChange={this.handleChange}
                currentQuestion={this.state.currentQuestion}
                currentPart={this.state.currentPart}
                dropItems={this.state.dropItems}
                onDragStart={this.onDragStart}
                handleAnswerObj={this.handleAnswerObj}
                onFocusQuestion={this.onFocusQuestion}
                handleAnsweredQuestionNumber={this.handleAnsweredQuestionNumber}
                isPreview={true}
            />
        }

        else if (section.question_type === 'matchingSentenceEndings' || section.question_type === 'matchingFeatures') {
            return <MatchingSentenceEndings
                key={section.section_id}
                sectionInfo={section}
                answer={this.state.answer}
                answerObj={this.state.answerObj}
                handleChange={this.handleChange}
                currentQuestion={this.state.currentQuestion}
                currentPart={this.state.currentPart}
                onDragStart={this.onDragStart}
                handleAnswer={this.handleAnswer}
                handleAnswerObj={this.handleAnswerObj}
                onFocusQuestion={this.onFocusQuestion}
                handleCancelQuestionNumber={this.handleCancelQuestionNumber}
                handleAnsweredQuestionNumber={this.handleAnsweredQuestionNumber}
                isPreview={true}
            />
        }

        else if (section.question_type === 'sentenceCompletion') {
            return <SentenceCompletion
                key={section.section_id}
                sectionInfo={section}
                answer={this.state.answer}
                answerObj={this.state.answerObj}
                handleChange={this.handleChange}
                currentQuestion={this.state.currentQuestion}
                currentPart={this.state.currentPart}
                handleAnswerObj={this.handleAnswerObj}
                onFocusQuestion={this.onFocusQuestion}
                handleAnsweredQuestionNumber={this.handleAnsweredQuestionNumber}
                isPreview={true}
            />
        }

        else if (section.question_type === 'multipleChoice') {
            return <MultipleChoice
                key={section.section_id}
                sectionInfo={section}
                answer={this.state.answer}
                answerObj={this.state.answerObj}
                handleChange={this.handleChange}
                currentQuestion={this.state.currentQuestion}
                currentPart={this.state.currentPart}
                handleAnswerObj={this.handleAnswerObj}
                onFocusQuestion={this.onFocusQuestion}
                handleAnsweredQuestionNumber={this.handleAnsweredQuestionNumber}
                isPreview={true}
            />
        }
    }
    //==================================== READING QUESTIONS =======================================

    //==================================== LISTENING QUESTIONS =======================================
    handleSelectListeningQuestionType = (section) => {
        console.log(section);

        if (section.question_type === "multipleChoice") {
            return <MultipleChoice
                key={section.section_id}
                sectionInfo={section}
                answer={this.state.answer}
                answerObj={this.state.answerObj}
                handleChange={this.handleChange}
                currentQuestion={this.state.currentQuestion}
                currentPart={this.state.currentPart}
                handleAnswerObj={this.handleAnswerObj}
                onFocusQuestion={this.onFocusQuestion}
                handleAnsweredQuestionNumber={this.handleAnsweredQuestionNumber}
                isPreview={true}
            />
        }

        else if (section.question_type === "matching") {
            return <Matching
                key={section.section_id}
                currentTestObject={section}
                answer={this.state.answer}
                handleAnswer={this.handleAnswer}
                playingSound={this.state.playingSound}
                volume={this.state.volume}
                currentSection={this.state.currentSection - 1}
                currentPart={this.state.currentPart - 1}
                handleAnswerObj={this.handleAnswerObj}
                answerObj={this.state.answerObj}
                handleCancelQuestionNumber={this.handleCancelQuestionNumber}
                handleAnsweredQuestionNumber={this.handleAnsweredQuestionNumber}
                onFocusQuestion={this.onFocusQuestion}
            />
        }

        else if (section.question_type === "completeTheTables") {
            // return <CompleteTheTables
            //     key={section.section_id}
            //     currentTestObject={section}
            //     answer={this.state.answer}
            //     handleAnswer={this.handleAnswer}
            //     playingSound={this.state.playingSound}
            //     volume={this.state.volume}
            //     currentSection={this.state.currentSection - 1}
            //     currentPart={this.state.currentPart - 1}
            //     handleAnswerObj={this.handleAnswerObj}
            //     answerObj={this.state.answerObj}
            //     handleAnsweredQuestionNumber={this.handleAnsweredQuestionNumber}
            //     onFocusQuestion={this.onFocusQuestion}
            // />
            return <CompleteTheNotes
                key={section.section_id}
                currentTestObject={section}
                answer={this.state.answer}
                handleAnswer={this.handleAnswer}
                playingSound={this.state.playingSound}
                volume={this.state.volume}
                currentSection={this.state.currentSection - 1}
                currentPart={this.state.currentPart - 1}
                handleAnswerObj={this.handleAnswerObj}
                answerObj={this.state.answerObj}
                handleAnsweredQuestionNumber={this.handleAnsweredQuestionNumber}
                onFocusQuestion={this.onFocusQuestion}
            />
        }

        else if (section.question_type === "completeTheNotes") {
            return <CompleteTheNotes
                key={section.section_id}
                currentTestObject={section}
                answer={this.state.answer}
                handleAnswer={this.handleAnswer}
                playingSound={this.state.playingSound}
                volume={this.state.volume}
                currentSection={this.state.currentSection - 1}
                currentPart={this.state.currentPart - 1}
                handleAnswerObj={this.handleAnswerObj}
                answerObj={this.state.answerObj}
                handleAnsweredQuestionNumber={this.handleAnsweredQuestionNumber}
                onFocusQuestion={this.onFocusQuestion}
            />
        }

        else if (section.question_type === "choose2Letters") {
            return <ChooseTwoLetters
                key={section.section_id}
                currentTestObject={section}
                answer={this.state.answer}
                handleAnswer={this.handleAnswer}
                playingSound={this.state.playingSound}
                volume={this.state.volume}
                currentSection={this.state.currentSection - 1}
                currentPart={this.state.currentPart - 1}
                handleAnswerObj={this.handleAnswerObj}
                answerObj={this.state.answerObj}
                handleAnsweredQuestionNumber={this.handleAnsweredQuestionNumber}
                onFocusQuestion={this.onFocusQuestion}
            />
        }

        else if (section.question_type === "map") {
            return <Map
                key={section.section_id}
                currentTestObject={section}
                answer={this.state.answer}
                handleAnswer={this.handleAnswer}
                playingSound={this.state.playingSound}
                volume={this.state.volume}
                currentSection={this.state.currentSection - 1}
                currentPart={this.state.currentPart - 1}
                handleAnswerObj={this.handleAnswerObj}
                answerObj={this.state.answerObj}
                handleAnsweredQuestionNumber={this.handleAnsweredQuestionNumber}
                onFocusQuestion={this.onFocusQuestion}
            />
        }
    }
    //==================================== LISTENING QUESTIONS =======================================

    //======================= LIST OF HEADING ====================================
    onDropped = async (questionNo) => {
        if (this.state.draggedItem !== undefined) {
            this.state.dropItems['' + questionNo] = this.state.draggedItem

            this.setState({
                draggedItem: undefined
            });
        }
    }

    onDragStart = (item) => {
        this.setState({
            draggedItem: item
        });
    }

    onClickAnswer = async (question) => {
        this.state.dropItems['' + question] = undefined;
        this.setState({
            dropItems: this.state.dropItems
        });

        this.onFocusQuestion(question);
    }
    //======================= LIST OF HEADING ====================================

    //======================= SECTIONS CONTROL ===================================
    handleClickNextSection = () => {
        if ((this.state.currentSection + 1) < this.state.partObj.sections.length) {
            this.setState({
                currentSection: this.state.currentSection + 1
            })
        }
    }

    handleClickPreviousSection = () => {
        if (this.state.currentSection >= 0) {
            this.setState({
                currentSection: this.state.currentSection - 1
            })
        }
    }
    //======================= SECTIONS CONTROL ===================================

    render() {
        const { classes } = this.props;

        return (
            <Container maxWidth={false} style={{ marginTop: "100px", marginBottom: '200px' }}>
                <Grid item xs={12}>
                    <LoadingBackdrop isLoading={this.state.isLoadingTest} />

                    <Paper variant="outlined">
                        <Grid container>
                            <Grid item align="left" xs={12} style={{ padding: "10px", backgroundColor: "#76323f", color: "white" }}>
                                <Typography variant="body1" style={{ fontWeight: 'bold' }} gutterBottom>
                                    Part {this.state.partObj?.part}
                                </Typography>
                                <Typography variant="subtitle1" gutterBottom>
                                    {this.state.partObj?.title}
                                </Typography>
                            </Grid>

                            {this.state.partObj?.part_type === "READING" &&
                                <Grid item xs={6} style={{
                                    marginBottom: '20px',
                                    padding: '15px',
                                    maxHeight: this.state.window.height - 300,
                                    overflow: 'auto'
                                }}>
                                    <DisplayParagraph
                                        currentTestObject={this.state.partObj}
                                        draggedItem={this.state.draggedItem}
                                        dropItems={this.state.dropItems}
                                        onDropped={this.onDropped}
                                        onClickAnswer={this.onClickAnswer}
                                    />
                                </Grid>
                            }

                            {this.state.partObj?.part_type === "READING" &&
                                <Grid item xs={6} style={{
                                    marginBottom: '20px',
                                    padding: '15px',
                                    height: this.state.window.height - 300,
                                    maxHeight: this.state.window.height - 300,
                                    overflow: 'auto'
                                }}>
                                    {this.state.sectionArr?.map((section) =>
                                        this.handleSelectReadingQuestionType(section)
                                    )}
                                </Grid>
                            }

                            {this.state.partObj?.part_type === "LISTENING" && this.state.sectionArr &&
                                <Grid item xs={12} style={{
                                    padding: '30px',
                                    height: this.state.window.height - 300,
                                    maxHeight: this.state.window.height - 300,
                                    overflow: 'auto'
                                }}>
                                    {this.handleSelectListeningQuestionType(this.state.sectionArr[this.state.currentSection])}
                                </Grid>
                            }

                            {this.state.partObj?.part_type === "LISTENING" &&
                                <Grid item align='right' xs={12} style={{ marginBottom: '20px' }}>
                                    {(this.state.currentSection > 0) &&
                                        <Button
                                            variant="outlined"
                                            size="medium"
                                            color="default"
                                            onClick={this.handleClickPreviousSection}
                                            style={{ margin: '20px', color: '#76323f', borderColor: '#76323f' }}
                                        >
                                            Previous
                                        </Button>
                                    }
                                    {((this.state.currentSection + 1) < this.state.sectionArr?.length) &&
                                        <Button
                                            variant="contained"
                                            size="medium"
                                            color="default"
                                            onClick={this.handleClickNextSection}
                                            style={{ margin: '20px', backgroundColor: '#76323f', color: 'white' }}>
                                            Next
                                        </Button>
                                    }
                                    <ReactAudioPlayer
                                        src={this.state.sectionArr !== undefined && this.state.sectionArr[0].questions[0].voice}
                                        volume={0.6}
                                        autoPlay={true}
                                    />
                                </Grid>}
                        </Grid>
                    </Paper>

                    <Grid item align='center' xs={12} style={{ marginTop: '30px' }}>
                        {this.props.match.params.test_id === 'editPart' ?
                            <Link to={`/dashboard/test-admin/parts/${this.props.match.params.part_id}/edit`} style={{ textDecoration: 'none', color: '#0f0f0f' }}>
                                <Button variant="contained" size="medium" color="default" style={{ marginLeft: '5px', color: 'grey' }}>
                                    Back
                                </Button>
                            </Link> :
                            <Link to={`/dashboard/test-admin/tests/${this.props.match.params.test_id}/edit`} style={{ textDecoration: 'none', color: '#0f0f0f' }}>
                                <Button variant="contained" size="medium" color="default" style={{ marginLeft: '5px', color: 'grey' }}>
                                    Back
                                </Button>
                            </Link>
                        }
                    </Grid>
                </Grid >
            </Container>
        )
    }
}
export default compose(
    withStyles(useStyles)
)(PreviewTest);