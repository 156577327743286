import React, { useEffect } from 'react';

//MUI
import Container from '@material-ui/core/Container';

import { ThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';


//Font
import 'typeface-glacial-indifference';
import { Grid, Hidden, Typography } from '@material-ui/core';

const useStyles = theme => ({
    root: {
        padding: theme.spacing(12, 4),
    }
});

const theme = createMuiTheme({
    typography: {
        fontFamily: [
            'Roboto'
        ].join(','),
    },
});

const Privacy = () => {
    useEffect(() => {
        document.body.style.overflow = "auto";
        document.body.style.backgroundColor = "#efefef";
        document.querySelector("body").scrollTo(0, 0);
    }, []);

    const mobileUI = (
        <Grid item xs={12} style={{ maxWidth: window.innerWidth, zIndex: 1, padding: "20px" }}>
            <Grid container spacing={2} style={{ marginTop: "10%", marginBottom: "10%" }}>
                {/* ==================================== TERMS AND CONDITIONS ==================================== */}
                <Grid item xs={12}>
                    <Typography variant="h4" style={{ fontWeight: "bold" }}>
                        PRIVACY POLICY
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold" }}>
                        1. Introduction
                    </Typography>
                    <Typography variant="body1">
                        Welcome to <span style={{ fontWeight: "bold" }}>UTest Avenue</span>.
                    </Typography>
                    <Typography variant="body1">
                        <span style={{ fontWeight: "bold" }}>UTest Avenue</span> (“us”, “we”, or “our”) operates <span style={{ fontWeight: "bold" }}>www.utestavenue.com</span> (hereinafter referred to as “Service”).
                    </Typography>
                    <Typography variant="body1">
                        Our Privacy Policy governs your visit to <span style={{ fontWeight: "bold" }}>www.utestavenue.com</span>, and explains how we collect, safeguard and disclose information that results from your use of our Service.
                    </Typography>
                    <Typography variant="body1">
                        We use your data to provide and improve Service. By using Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, the terms used in this Privacy Policy have the same meanings as in our Terms and Conditions.
                    </Typography>
                    <Typography variant="body1">
                        Our Terms and Conditions (“<span style={{ fontWeight: "bold" }}>Terms</span>”) govern all use of our Service and together with the Privacy Policy constitutes your agreement with us (<span style={{ fontWeight: "bold" }}>agreement</span>).
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold" }}>
                        1. Definitions
                    </Typography>
                    <Typography variant="body1">
                        <span style={{ fontWeight: "bold" }}>SERVICE</span> means the www.utestavenue.com website operated by UTest Avenue, Inside Out English Coach Co., Ltd
                    </Typography>
                    <Typography variant="body1">
                        <span style={{ fontWeight: "bold" }}>PERSONAL DATA</span> means data about a living individual who can be identified from those data (or from those and other information either in our possession or likely to come into our possession).
                    </Typography>
                    <Typography variant="body1">
                        <span style={{ fontWeight: "bold" }}>USAGE DATA</span> is data collected automatically either generated by the use of Service or from Service infrastructure itself (for example, the duration of a page visit).
                    </Typography>
                    <Typography variant="body1">
                        <span style={{ fontWeight: "bold" }}>COOKIES</span> are small files stored on your device (computer or mobile device).
                    </Typography>
                    <Typography variant="body1">
                        <span style={{ fontWeight: "bold" }}>DATA CONTROLLER</span> means a natural or legal person who (either alone or jointly or in common with other persons) determines the purposes for which and the manner in which any personal data are, or are to be, processed. For the purpose of this Privacy Policy, we are a Data Controller of your data.
                    </Typography>
                    <Typography variant="body1">
                        <span style={{ fontWeight: "bold" }}>DATA PROCESSORS (OR SERVICE PROVIDERS)</span> means any natural or legal person who processes the data on behalf of the Data Controller. We may use the services of various Service Providers in order to process your data more effectively.
                    </Typography>
                    <Typography variant="body1">
                        <span style={{ fontWeight: "bold" }}>DATA SUBJECT</span> is any living individual who is the subject of Personal Data.
                    </Typography>
                    <Typography variant="body1">
                        <span style={{ fontWeight: "bold" }}>THE USER</span> is the individual using our Service. The User corresponds to the Data Subject, who is the subject of Personal Data.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold" }}>
                        3. Information Collection and Use
                    </Typography>
                    <Typography variant="body1">
                        We collect several different types of information for various purposes to provide and improve our Service to you.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold" }}>
                        3. Types of Data Collected
                    </Typography>
                    <Typography variant="body1" style={{ fontWeight: "bold" }}>
                        Personal Data
                    </Typography>
                    <Typography variant="body1">
                        While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you (“<span style={{ fontWeight: "bold" }}>Personal Data</span>”). Personally identifiable information may include, but is not limited to:
                    </Typography>
                    <Typography variant="body1">
                        0.1. Email address
                    </Typography>
                    <Typography variant="body1">
                        0.2. First name and last name
                    </Typography>
                    <Typography variant="body1">
                        0.3. Phone number
                    </Typography>
                    <Typography variant="body1">
                        0.4. Address, Country, State, Province, ZIP/Postal code, City
                    </Typography>
                    <Typography variant="body1">
                        0.5. Cookies and Usage Data
                    </Typography>
                    <Typography variant="body1">
                        We may use your Personal Data to contact you with newsletters, marketing or promotional materials and other information that may be of interest to you. You may opt out of receiving any, or all, of these communications from us by following the unsubscribe link.
                    </Typography>
                    <Typography variant="body1" style={{ fontWeight: "bold" }}>
                        Usage Data
                    </Typography>
                    <Typography variant="body1">
                        We may also collect information that your browser sends whenever you visit our Service or when you access Service by or through any device (“<span style={{ fontWeight: "bold" }}>Usage Data</span>”).
                    </Typography>
                    <Typography variant="body1">
                        This Usage Data may include information such as your computer’s Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.
                    </Typography>
                    <Typography variant="body1">
                        When you access Service with a device, this Usage Data may include information such as the type of device you use, your device unique ID, the IP address of your device, your device operating system, the type of Internet browser you use, unique device identifiers and other diagnostic data.
                    </Typography>
                    <Typography variant="body1" style={{ fontWeight: "bold" }}>
                        Location Data
                    </Typography>
                    <Typography variant="body1">
                        We may use and store information about your location if you give us permission to do so (“<span style={{ fontWeight: "bold" }}>Location Data</span>”). We use this data to provide features of our Service, to improve and customize our Service. You can enable or disable location services when you use our Service at any time by way of your device settings.
                    </Typography>
                    <Typography variant="body1" style={{ fontWeight: "bold" }}>
                        Tracking Cookies Data
                    </Typography>
                    <Typography variant="body1">
                        We use cookies and similar tracking technologies to track the activity on our Service and we hold certain information.
                    </Typography>
                    <Typography variant="body1">
                        Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Other tracking technologies are also used such as beacons, tags and scripts to collect and track information and to improve and analyze our Service.
                    </Typography>
                    <Typography variant="body1">
                        You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.
                    </Typography>
                    <Typography variant="body1">
                        Examples of Cookies we use:
                    </Typography>
                    <Typography variant="body1">
                        0.1. <span style={{ fontWeight: "bold" }}>Session Cookies</span>: We use Session Cookies to operate our Service.
                    </Typography>
                    <Typography variant="body1">
                        0.2. <span style={{ fontWeight: "bold" }}>Preference Cookies:</span>: We use Preference Cookies to remember your preferences and various settings.
                    </Typography>
                    <Typography variant="body1">
                        0.3. <span style={{ fontWeight: "bold" }}>Security Cookies</span>: We use Security Cookies for security purposes.
                    </Typography>
                    <Typography variant="body1">
                        0.4. <span style={{ fontWeight: "bold" }}>Advertising Cookies</span>: Advertising Cookies are used to serve you with advertisements that may be relevant to you and your interests.
                    </Typography>
                    <Typography variant="body1" style={{ fontWeight: "bold" }}>
                        Other Data
                    </Typography>
                    <Typography variant="body1">
                        While using our Service, we may also collect the following information: sex, age, date of birth, place of birth, passport details, citizenship, registration at place of residence and actual address, telephone number (work, mobile), details of documents on education, qualification, professional training, employment agreements, NDA agreements, social security (or other identification) number, office location and other data.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold" }}>
                        5. Use of Data
                    </Typography>
                    <Typography variant="body1">
                        UTest Avenue uses the collected data for various purposes:
                    </Typography>
                    <Typography variant="body1">
                        0.1. to provide and maintain our Service;
                    </Typography>
                    <Typography variant="body1">
                        0.2. to notify you about changes to our Service;
                    </Typography>
                    <Typography variant="body1">
                        0.3. to allow you to participate in interactive features of our Service when you choose to do so;
                    </Typography>
                    <Typography variant="body1">
                        0.4. to provide customer support;
                    </Typography>
                    <Typography variant="body1">
                        0.5. to gather analysis or valuable information so that we can improve our Service;
                    </Typography>
                    <Typography variant="body1">
                        0.6. to monitor the usage of our Service;
                    </Typography>
                    <Typography variant="body1">
                        0.7. to detect, prevent and address technical issues;
                    </Typography>
                    <Typography variant="body1">
                        0.8. to fulfil any other purpose for which you provide it;
                    </Typography>
                    <Typography variant="body1">
                        0.9. to carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection;
                    </Typography>
                    <Typography variant="body1">
                        0.10. to provide you with notices about your account and/or purchase of products, including expiration and renewal notices, email-instructions, etc.;
                    </Typography>
                    <Typography variant="body1">
                        0.11. to provide you with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless you have opted not to receive such information;
                    </Typography>
                    <Typography variant="body1">
                        0.12. in any other way we may describe when you provide the information;
                    </Typography>
                    <Typography variant="body1">
                        0.13. for any other purpose with your consent.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold" }}>
                        6. Retention of Data
                    </Typography>
                    <Typography variant="body1">
                        We will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.
                    </Typography>
                    <Typography variant="body1">
                        We will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period, except when this data is used to strengthen the security or to improve the functionality of our Service, or we are legally obligated to retain this data for longer time periods.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold" }}>
                        7. Transfer of Data
                    </Typography>
                    <Typography variant="body1">
                        Your information, including Personal Data, may be transferred to – and maintained on – computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction.
                    </Typography>
                    <Typography variant="body1">
                        If you are located outside Thailand and choose to provide information to us, please note that we transfer the data, including Personal Data, to Thailand and process it there.
                    </Typography>
                    <Typography variant="body1">
                        Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.
                    </Typography>
                    <Typography variant="body1">
                        UTest Avenue will take all the steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organisation or a country unless there are adequate controls in place including the security of your data and other personal information.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold" }}>
                        7. Disclosure of Data
                    </Typography>
                    <Typography variant="body1">
                        We may disclose personal information that we collect, or you provide:
                    </Typography>
                    <Typography variant="body1" style={{ fontWeight: "bold" }}>
                        0.1. Disclosure for Law Enforcement.
                    </Typography>
                    <Typography variant="body1">
                        Under certain circumstances, we may be required to disclose your Personal Data if required to do so by law or in response to valid requests by public authorities.
                    </Typography>
                    <Typography variant="body1" style={{ fontWeight: "bold" }}>
                        0.2. Business Transaction.
                    </Typography>
                    <Typography variant="body1">
                        If we or our subsidiaries are involved in a merger, acquisition or asset sale, your Personal Data may be transferred.
                    </Typography>
                    <Typography variant="body1" style={{ fontWeight: "bold" }}>
                        0.3. Other cases. We may disclose your information also:
                    </Typography>
                    <Typography variant="body1">
                        0.3.1. to our subsidiaries and affiliates;
                    </Typography>
                    <Typography variant="body1">
                        0.3.2. to contractors, service providers, and other third parties we use to support our business;
                    </Typography>
                    <Typography variant="body1">
                        0.3.3. to fulfil the purpose for which you provide it;
                    </Typography>
                    <Typography variant="body1">
                        0.3.4. for the purpose of including your company’s logo on our website;
                    </Typography>
                    <Typography variant="body1">
                        0.3.5. for any other purpose disclosed by us when you provide the information;
                    </Typography>
                    <Typography variant="body1">
                        0.3.6. with your consent in any other cases;
                    </Typography>
                    <Typography variant="body1">
                        0.3.7. if we believe disclosure is necessary or appropriate to protect the rights, property, or safety of the Company, our customers, or others.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold" }}>
                        9. Security of Data
                    </Typography>
                    <Typography variant="body1">
                        The security of your data is important to us but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold" }}>
                        10. Your Data Protection Rights Under General Data Protection Regulation (GDPR)
                    </Typography>
                    <Typography variant="body1">
                        If you are a resident of the European Union (EU) and European Economic Area (EEA), you have certain data protection rights, covered by GDPR.
                    </Typography>
                    <Typography variant="body1">
                        We aim to take reasonable steps to allow you to correct, amend, delete, or limit the use of your Personal Data.
                    </Typography>
                    <Typography variant="body1">
                        If you wish to be informed what Personal Data we hold about you and if you want it to be removed from our systems, please email us at contact@utestavenue.com.
                    </Typography>
                    <Typography variant="body1">
                        In certain circumstances, you have the following data protection rights:
                    </Typography>
                    <Typography variant="body1">
                        0.1. the right to access, update or to delete the information we have on you;
                    </Typography>
                    <Typography variant="body1">
                        0.2. the right of rectification. You have the right to have your information rectified if that information is inaccurate or incomplete;
                    </Typography>
                    <Typography variant="body1">
                        0.3. the right to object. You have the right to object to our processing of your Personal Data;
                    </Typography>
                    <Typography variant="body1">
                        0.4. the right of restriction. You have the right to request that we restrict the processing of your personal information;
                    </Typography>
                    <Typography variant="body1">
                        0.5. the right to data portability. You have the right to be provided with a copy of your Personal Data in a structured, machine-readable and commonly used format;
                    </Typography>
                    <Typography variant="body1">
                        0.6. the right to withdraw consent. You also have the right to withdraw your consent at any time where we rely on your consent to process your personal information;
                    </Typography>
                    <Typography variant="body1">
                        Please note that we may ask you to verify your identity before responding to such requests. Please note, we may not be able to provide Service without some necessary data.
                    </Typography>
                    <Typography variant="body1">
                        You have the right to complain to a Data Protection Authority about our collection and use of your Personal Data. For more information, please contact your local data protection authority in the European Economic Area (EEA).
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold" }}>
                        11. Service Providers
                    </Typography>
                    <Typography variant="body1">
                        We may employ third party companies and individuals to facilitate our Service (“<span style={{ fontWeight: "bold" }}>Service Providers</span>”), provide Service on our behalf, perform Service-related services or assist us in analysing how our Service is used.
                    </Typography>
                    <Typography variant="body1">
                        These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold" }}>
                        14. Analytics
                    </Typography>
                    <Typography variant="body1">
                        We may use third-party Service Providers to monitor and analyze the use of our Service.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold" }}>
                        15. CI/CD tools
                    </Typography>
                    <Typography variant="body1">
                        We may use third-party Service Providers to automate the development process of our Service.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold" }}>
                        16. Behavioral Remarketing
                    </Typography>
                    <Typography variant="body1">
                        We may use remarketing services to advertise on third party websites to you after you visited our Service. We and our third-party vendors use cookies to inform, optimise and serve ads based on your past visits to our Service.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold" }}>
                        17. Payments
                    </Typography>
                    <Typography variant="body1">
                        We may provide paid products and/or services within Service. In that case, we use third-party services for payment processing (e.g. payment processors).
                        We will not store or collect your payment card details. That information is provided directly to our third-party payment processors whose use of your personal information is governed by their Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, Mastercard, American Express and Discover. PCI-DSS requirements help ensure the secure handling of payment information.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold" }}>
                        18. Links to Other Sites
                    </Typography>
                    <Typography variant="body1">
                        Our Service may contain links to other sites that are not operated by us. If you click a third party link, you will be directed to that third party’s site. We strongly advise you to review the Privacy Policy of every site you visit.
                    </Typography>
                    <Typography variant="body1">
                        We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold" }}>
                        19. Changes to This Privacy Policy
                    </Typography>
                    <Typography variant="body1">
                        We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
                    </Typography>
                    <Typography variant="body1">
                        We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update “effective date” at the top of this Privacy Policy.
                    </Typography>
                    <Typography variant="body1">
                        You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
                    </Typography>
                </Grid>
                {/* ==================================== TERMS AND CONDITIONS ==================================== */}
            </Grid>
        </Grid>
    )

    const webUI = (
        <Container>
            <Grid container spacing={2} style={{ marginTop: "10%", marginBottom: "10%" }}>
                {/* ==================================== TERMS AND CONDITIONS ==================================== */}
                <Grid item xs={12}>
                    <Typography variant="h4" style={{ fontWeight: "bold" }}>
                        PRIVACY POLICY
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold" }}>
                        1. Introduction
                    </Typography>
                    <Typography variant="body1">
                        Welcome to <span style={{ fontWeight: "bold" }}>UTest Avenue</span>.
                    </Typography>
                    <Typography variant="body1">
                        <span style={{ fontWeight: "bold" }}>UTest Avenue</span> (“us”, “we”, or “our”) operates <span style={{ fontWeight: "bold" }}>www.utestavenue.com</span> (hereinafter referred to as “Service”).
                    </Typography>
                    <Typography variant="body1">
                        Our Privacy Policy governs your visit to <span style={{ fontWeight: "bold" }}>www.utestavenue.com</span>, and explains how we collect, safeguard and disclose information that results from your use of our Service.
                    </Typography>
                    <Typography variant="body1">
                        We use your data to provide and improve Service. By using Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, the terms used in this Privacy Policy have the same meanings as in our Terms and Conditions.
                    </Typography>
                    <Typography variant="body1">
                        Our Terms and Conditions (“<span style={{ fontWeight: "bold" }}>Terms</span>”) govern all use of our Service and together with the Privacy Policy constitutes your agreement with us (<span style={{ fontWeight: "bold" }}>agreement</span>).
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold" }}>
                        1. Definitions
                    </Typography>
                    <Typography variant="body1">
                        <span style={{ fontWeight: "bold" }}>SERVICE</span> means the www.utestavenue.com website operated by UTest Avenue, Inside Out English Coach Co., Ltd
                    </Typography>
                    <Typography variant="body1">
                        <span style={{ fontWeight: "bold" }}>PERSONAL DATA</span> means data about a living individual who can be identified from those data (or from those and other information either in our possession or likely to come into our possession).
                    </Typography>
                    <Typography variant="body1">
                        <span style={{ fontWeight: "bold" }}>USAGE DATA</span> is data collected automatically either generated by the use of Service or from Service infrastructure itself (for example, the duration of a page visit).
                    </Typography>
                    <Typography variant="body1">
                        <span style={{ fontWeight: "bold" }}>COOKIES</span> are small files stored on your device (computer or mobile device).
                    </Typography>
                    <Typography variant="body1">
                        <span style={{ fontWeight: "bold" }}>DATA CONTROLLER</span> means a natural or legal person who (either alone or jointly or in common with other persons) determines the purposes for which and the manner in which any personal data are, or are to be, processed. For the purpose of this Privacy Policy, we are a Data Controller of your data.
                    </Typography>
                    <Typography variant="body1">
                        <span style={{ fontWeight: "bold" }}>DATA PROCESSORS (OR SERVICE PROVIDERS)</span> means any natural or legal person who processes the data on behalf of the Data Controller. We may use the services of various Service Providers in order to process your data more effectively.
                    </Typography>
                    <Typography variant="body1">
                        <span style={{ fontWeight: "bold" }}>DATA SUBJECT</span> is any living individual who is the subject of Personal Data.
                    </Typography>
                    <Typography variant="body1">
                        <span style={{ fontWeight: "bold" }}>THE USER</span> is the individual using our Service. The User corresponds to the Data Subject, who is the subject of Personal Data.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold" }}>
                        3. Information Collection and Use
                    </Typography>
                    <Typography variant="body1">
                        We collect several different types of information for various purposes to provide and improve our Service to you.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold" }}>
                        3. Types of Data Collected
                    </Typography>
                    <Typography variant="body1" style={{ fontWeight: "bold" }}>
                        Personal Data
                    </Typography>
                    <Typography variant="body1">
                        While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you (“<span style={{ fontWeight: "bold" }}>Personal Data</span>”). Personally identifiable information may include, but is not limited to:
                    </Typography>
                    <Typography variant="body1">
                        0.1. Email address
                    </Typography>
                    <Typography variant="body1">
                        0.2. First name and last name
                    </Typography>
                    <Typography variant="body1">
                        0.3. Phone number
                    </Typography>
                    <Typography variant="body1">
                        0.4. Address, Country, State, Province, ZIP/Postal code, City
                    </Typography>
                    <Typography variant="body1">
                        0.5. Cookies and Usage Data
                    </Typography>
                    <Typography variant="body1">
                        We may use your Personal Data to contact you with newsletters, marketing or promotional materials and other information that may be of interest to you. You may opt out of receiving any, or all, of these communications from us by following the unsubscribe link.
                    </Typography>
                    <Typography variant="body1" style={{ fontWeight: "bold" }}>
                        Usage Data
                    </Typography>
                    <Typography variant="body1">
                        We may also collect information that your browser sends whenever you visit our Service or when you access Service by or through any device (“<span style={{ fontWeight: "bold" }}>Usage Data</span>”).
                    </Typography>
                    <Typography variant="body1">
                        This Usage Data may include information such as your computer’s Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.
                    </Typography>
                    <Typography variant="body1">
                        When you access Service with a device, this Usage Data may include information such as the type of device you use, your device unique ID, the IP address of your device, your device operating system, the type of Internet browser you use, unique device identifiers and other diagnostic data.
                    </Typography>
                    <Typography variant="body1" style={{ fontWeight: "bold" }}>
                        Location Data
                    </Typography>
                    <Typography variant="body1">
                        We may use and store information about your location if you give us permission to do so (“<span style={{ fontWeight: "bold" }}>Location Data</span>”). We use this data to provide features of our Service, to improve and customize our Service. You can enable or disable location services when you use our Service at any time by way of your device settings.
                    </Typography>
                    <Typography variant="body1" style={{ fontWeight: "bold" }}>
                        Tracking Cookies Data
                    </Typography>
                    <Typography variant="body1">
                        We use cookies and similar tracking technologies to track the activity on our Service and we hold certain information.
                    </Typography>
                    <Typography variant="body1">
                        Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Other tracking technologies are also used such as beacons, tags and scripts to collect and track information and to improve and analyze our Service.
                    </Typography>
                    <Typography variant="body1">
                        You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.
                    </Typography>
                    <Typography variant="body1">
                        Examples of Cookies we use:
                    </Typography>
                    <Typography variant="body1">
                        0.1. <span style={{ fontWeight: "bold" }}>Session Cookies</span>: We use Session Cookies to operate our Service.
                    </Typography>
                    <Typography variant="body1">
                        0.2. <span style={{ fontWeight: "bold" }}>Preference Cookies:</span>: We use Preference Cookies to remember your preferences and various settings.
                    </Typography>
                    <Typography variant="body1">
                        0.3. <span style={{ fontWeight: "bold" }}>Security Cookies</span>: We use Security Cookies for security purposes.
                    </Typography>
                    <Typography variant="body1">
                        0.4. <span style={{ fontWeight: "bold" }}>Advertising Cookies</span>: Advertising Cookies are used to serve you with advertisements that may be relevant to you and your interests.
                    </Typography>
                    <Typography variant="body1" style={{ fontWeight: "bold" }}>
                        Other Data
                    </Typography>
                    <Typography variant="body1">
                        While using our Service, we may also collect the following information: sex, age, date of birth, place of birth, passport details, citizenship, registration at place of residence and actual address, telephone number (work, mobile), details of documents on education, qualification, professional training, employment agreements, NDA agreements, social security (or other identification) number, office location and other data.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold" }}>
                        5. Use of Data
                    </Typography>
                    <Typography variant="body1">
                        UTest Avenue uses the collected data for various purposes:
                    </Typography>
                    <Typography variant="body1">
                        0.1. to provide and maintain our Service;
                    </Typography>
                    <Typography variant="body1">
                        0.2. to notify you about changes to our Service;
                    </Typography>
                    <Typography variant="body1">
                        0.3. to allow you to participate in interactive features of our Service when you choose to do so;
                    </Typography>
                    <Typography variant="body1">
                        0.4. to provide customer support;
                    </Typography>
                    <Typography variant="body1">
                        0.5. to gather analysis or valuable information so that we can improve our Service;
                    </Typography>
                    <Typography variant="body1">
                        0.6. to monitor the usage of our Service;
                    </Typography>
                    <Typography variant="body1">
                        0.7. to detect, prevent and address technical issues;
                    </Typography>
                    <Typography variant="body1">
                        0.8. to fulfil any other purpose for which you provide it;
                    </Typography>
                    <Typography variant="body1">
                        0.9. to carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection;
                    </Typography>
                    <Typography variant="body1">
                        0.10. to provide you with notices about your account and/or purchase of products, including expiration and renewal notices, email-instructions, etc.;
                    </Typography>
                    <Typography variant="body1">
                        0.11. to provide you with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless you have opted not to receive such information;
                    </Typography>
                    <Typography variant="body1">
                        0.12. in any other way we may describe when you provide the information;
                    </Typography>
                    <Typography variant="body1">
                        0.13. for any other purpose with your consent.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold" }}>
                        6. Retention of Data
                    </Typography>
                    <Typography variant="body1">
                        We will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.
                    </Typography>
                    <Typography variant="body1">
                        We will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period, except when this data is used to strengthen the security or to improve the functionality of our Service, or we are legally obligated to retain this data for longer time periods.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold" }}>
                        7. Transfer of Data
                    </Typography>
                    <Typography variant="body1">
                        Your information, including Personal Data, may be transferred to – and maintained on – computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction.
                    </Typography>
                    <Typography variant="body1">
                        If you are located outside Thailand and choose to provide information to us, please note that we transfer the data, including Personal Data, to Thailand and process it there.
                    </Typography>
                    <Typography variant="body1">
                        Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.
                    </Typography>
                    <Typography variant="body1">
                        UTest Avenue will take all the steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organisation or a country unless there are adequate controls in place including the security of your data and other personal information.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold" }}>
                        7. Disclosure of Data
                    </Typography>
                    <Typography variant="body1">
                        We may disclose personal information that we collect, or you provide:
                    </Typography>
                    <Typography variant="body1" style={{ fontWeight: "bold" }}>
                        0.1. Disclosure for Law Enforcement.
                    </Typography>
                    <Typography variant="body1">
                        Under certain circumstances, we may be required to disclose your Personal Data if required to do so by law or in response to valid requests by public authorities.
                    </Typography>
                    <Typography variant="body1" style={{ fontWeight: "bold" }}>
                        0.2. Business Transaction.
                    </Typography>
                    <Typography variant="body1">
                        If we or our subsidiaries are involved in a merger, acquisition or asset sale, your Personal Data may be transferred.
                    </Typography>
                    <Typography variant="body1" style={{ fontWeight: "bold" }}>
                        0.3. Other cases. We may disclose your information also:
                    </Typography>
                    <Typography variant="body1">
                        0.3.1. to our subsidiaries and affiliates;
                    </Typography>
                    <Typography variant="body1">
                        0.3.2. to contractors, service providers, and other third parties we use to support our business;
                    </Typography>
                    <Typography variant="body1">
                        0.3.3. to fulfil the purpose for which you provide it;
                    </Typography>
                    <Typography variant="body1">
                        0.3.4. for the purpose of including your company’s logo on our website;
                    </Typography>
                    <Typography variant="body1">
                        0.3.5. for any other purpose disclosed by us when you provide the information;
                    </Typography>
                    <Typography variant="body1">
                        0.3.6. with your consent in any other cases;
                    </Typography>
                    <Typography variant="body1">
                        0.3.7. if we believe disclosure is necessary or appropriate to protect the rights, property, or safety of the Company, our customers, or others.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold" }}>
                        9. Security of Data
                    </Typography>
                    <Typography variant="body1">
                        The security of your data is important to us but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold" }}>
                        10. Your Data Protection Rights Under General Data Protection Regulation (GDPR)
                    </Typography>
                    <Typography variant="body1">
                        If you are a resident of the European Union (EU) and European Economic Area (EEA), you have certain data protection rights, covered by GDPR.
                    </Typography>
                    <Typography variant="body1">
                        We aim to take reasonable steps to allow you to correct, amend, delete, or limit the use of your Personal Data.
                    </Typography>
                    <Typography variant="body1">
                        If you wish to be informed what Personal Data we hold about you and if you want it to be removed from our systems, please email us at contact@utestavenue.com.
                    </Typography>
                    <Typography variant="body1">
                        In certain circumstances, you have the following data protection rights:
                    </Typography>
                    <Typography variant="body1">
                        0.1. the right to access, update or to delete the information we have on you;
                    </Typography>
                    <Typography variant="body1">
                        0.2. the right of rectification. You have the right to have your information rectified if that information is inaccurate or incomplete;
                    </Typography>
                    <Typography variant="body1">
                        0.3. the right to object. You have the right to object to our processing of your Personal Data;
                    </Typography>
                    <Typography variant="body1">
                        0.4. the right of restriction. You have the right to request that we restrict the processing of your personal information;
                    </Typography>
                    <Typography variant="body1">
                        0.5. the right to data portability. You have the right to be provided with a copy of your Personal Data in a structured, machine-readable and commonly used format;
                    </Typography>
                    <Typography variant="body1">
                        0.6. the right to withdraw consent. You also have the right to withdraw your consent at any time where we rely on your consent to process your personal information;
                    </Typography>
                    <Typography variant="body1">
                        Please note that we may ask you to verify your identity before responding to such requests. Please note, we may not be able to provide Service without some necessary data.
                    </Typography>
                    <Typography variant="body1">
                        You have the right to complain to a Data Protection Authority about our collection and use of your Personal Data. For more information, please contact your local data protection authority in the European Economic Area (EEA).
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold" }}>
                        11. Service Providers
                    </Typography>
                    <Typography variant="body1">
                        We may employ third party companies and individuals to facilitate our Service (“<span style={{ fontWeight: "bold" }}>Service Providers</span>”), provide Service on our behalf, perform Service-related services or assist us in analysing how our Service is used.
                    </Typography>
                    <Typography variant="body1">
                        These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold" }}>
                        14. Analytics
                    </Typography>
                    <Typography variant="body1">
                        We may use third-party Service Providers to monitor and analyze the use of our Service.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold" }}>
                        15. CI/CD tools
                    </Typography>
                    <Typography variant="body1">
                        We may use third-party Service Providers to automate the development process of our Service.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold" }}>
                        16. Behavioral Remarketing
                    </Typography>
                    <Typography variant="body1">
                        We may use remarketing services to advertise on third party websites to you after you visited our Service. We and our third-party vendors use cookies to inform, optimise and serve ads based on your past visits to our Service.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold" }}>
                        17. Payments
                    </Typography>
                    <Typography variant="body1">
                        We may provide paid products and/or services within Service. In that case, we use third-party services for payment processing (e.g. payment processors).
                        We will not store or collect your payment card details. That information is provided directly to our third-party payment processors whose use of your personal information is governed by their Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, Mastercard, American Express and Discover. PCI-DSS requirements help ensure the secure handling of payment information.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold" }}>
                        18. Links to Other Sites
                    </Typography>
                    <Typography variant="body1">
                        Our Service may contain links to other sites that are not operated by us. If you click a third party link, you will be directed to that third party’s site. We strongly advise you to review the Privacy Policy of every site you visit.
                    </Typography>
                    <Typography variant="body1">
                        We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold" }}>
                        19. Changes to This Privacy Policy
                    </Typography>
                    <Typography variant="body1">
                        We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
                    </Typography>
                    <Typography variant="body1">
                        We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update “effective date” at the top of this Privacy Policy.
                    </Typography>
                    <Typography variant="body1">
                        You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
                    </Typography>
                </Grid>
                {/* ==================================== TERMS AND CONDITIONS ==================================== */}
            </Grid>
        </Container>
    )

    return (
        <ThemeProvider theme={theme}>
            <Hidden mdUp>{mobileUI}</Hidden>
            <Hidden smDown>{webUI}</Hidden>
        </ThemeProvider>
    )
};

export default compose(
    withStyles(useStyles)
)(Privacy);
