//React
import { Component } from 'react';
import { compose } from 'recompose';

//Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

//Icon
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';

//react-router-dom

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%'
    }
});

class InstructionPage extends Component {
    constructor() {
        super();
    }

    componentDidMount = () => {
        console.log(this.props.isFreeTest);
        console.log(this.props.isFreetest);
    };

    render() {
        return (
            <Grid container justify="center" style={{ marginTop: '20px' }}>
                <Paper variant="outlined" style={{
                    padding: '20px',
                    backgroundImage: `url("/U-Test-Logo-new-bw.png")`,
                    backgroundSize: '120px',
                    backgroundRepeat: 'space'
                }}>
                    <Grid item align='left' xs={12}>
                        <Typography variant="h6" gutterBottom>
                            <Box fontWeight="fontWeightBold" display='inline'> IELTS Academic Listening Simulation Test </Box>
                        </Typography>
                    </Grid>
                    <Grid item align='left' xs={12}>
                        {!this.props.isFreeTest && <Typography variant="subtitle2" gutterBottom>
                            Time: Approximately 35 - 40 minutes
                        </Typography>}
                        {this.props.isFreeTest && <Typography variant="subtitle2" gutterBottom>
                            Time: Approximately 17 - 20 minutes
                        </Typography>}
                    </Grid>
                    <Grid item align='left' xs={12}>
                        <Typography variant="h6" gutterBottom>
                            <Box fontWeight="fontWeightBold" display='inline'> INSTRUCTIONS TO CANDIDATES </Box>
                        </Typography>
                    </Grid>
                    <Grid item align='left' xs={12}>
                        <Typography variant="subtitle2" gutterBottom>
                            <Box fontWeight="fontWeightBold" display='inline' m={1}>-</Box> Answer <Box fontWeight="fontWeightBold" display='inline'> all </Box> the questions.
                        </Typography>
                        <Typography variant="subtitle2" gutterBottom>
                            <Box fontWeight="fontWeightBold" display='inline' m={1}>-</Box> You can change your answer at any time during the test.
                        </Typography>
                    </Grid>
                    <Grid item align='left' xs={12}>
                        <Typography variant="h6" gutterBottom>
                            <Box fontWeight="fontWeightBold" display='inline'> INFORMATION FOR CANDIDATE </Box>
                        </Typography>
                    </Grid>
                    <Grid item align='left' xs={12}>
                        <Typography variant="subtitle2" gutterBottom>
                            <Box fontWeight="fontWeightBold" display='inline' m={1}>-</Box> The test consists of {this.props.isFreeTest ? "20" : "40"} questions.
                        </Typography>
                        <Typography variant="subtitle2" gutterBottom>
                            <Box fontWeight="fontWeightBold" display='inline' m={1}>-</Box> Each question is equivalent to one mark.
                        </Typography>
                        <Typography variant="subtitle2" gutterBottom>
                            <Box fontWeight="fontWeightBold" display='inline' m={1}>-</Box> There are four parts to the listening test.
                        </Typography>
                        <Typography variant="subtitle2" gutterBottom>
                            <Box fontWeight="fontWeightBold" display='inline' m={1}>-</Box> Please note you will hear each part once only.
                        </Typography>
                        <Typography variant="subtitle2" gutterBottom>
                            <Box fontWeight="fontWeightBold" display='inline' m={1}>-</Box> There will be time for you to look through the questions before the audio starts and time for you to check your answers after each part ends.
                        </Typography>
                    </Grid>
                    <Grid item align='center' xs={12} style={{ marginTop: '20px' }}>
                        <Typography variant="subtitle2" gutterBottom>
                            <InfoRoundedIcon fontSize="small" /> Please make sure you are ready before click <Box fontWeight="fontWeightBold" display='inline'> 'Start test' </Box>.
                        </Typography>
                        <Button size="small" variant="contained" onClick={this.props.handleDoneReadInstruction} style={{ color: 'white', backgroundColor: '#25333e' }}>
                            <Box fontWeight="fontWeightBold" display='inline'> Start test </Box>
                        </Button>
                    </Grid>
                    <Grid item align='center' xs={12} style={{ marginTop: '2px' }}>
                        <Button size="small" onClick={this.props.handleUserInfoChecked}>
                            <Box fontWeight="fontWeightBold" display='inline' style={{ color: 'grey' }}> Back </Box>
                        </Button>
                    </Grid>
                    <Grid item align='right' xs={12}>
                        <Typography
                            variant="subtitle2"
                            style={{ color: "#c8c8c8", fontWeight: "bold" }}
                        >
                            {btoa(this.props.user.username)}
                        </Typography>
                    </Grid>
                </Paper>
            </Grid>
        )
    }
}

export default compose(
    withStyles(useStyles)
)(InstructionPage);
