import * as apiConstants from '../constants/apiConstants';

const axios = require('axios').default;

export async function GetPurchasedTests() {
    try {
        const res = await axios({
            method: 'get',
            url: `${apiConstants.PAYMENT_API_BASE_URL}/charges/history`,
        });

        return {
            success: true,
            res: res.data.charges
        }

    } catch (err) {
        console.log(err);

        return {
            success: false,
            res: err
        }
    }
}

export async function GetPurchasedTestsByUsername(username) {
    try {
        const res = await axios({
            method: 'get',
            url: `${apiConstants.PAYMENT_API_BASE_URL}/charges/history?purchasedUsername=${username}`,
        });

        console.log(res.data);

        return {
            success: true,
            res: res.data.charges
        }

    } catch (err) {
        console.log(err);

        return {
            success: false,
            res: err
        }
    }
}
