import React, { useEffect, useState } from 'react';

//MUI
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';

import { ThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';

//Constats
import * as styleConstants from '../../../../../constants/styleConstants';

//Components

//Font
import 'typeface-glacial-indifference';

//Others
import Fade from 'react-reveal/Fade';
import VisibilitySensor from 'react-visibility-sensor';

const useStyles = theme => ({
    root: {
        padding: theme.spacing(12, 4),
    }
});

const theme = createMuiTheme({
    typography: {
        fontFamily: [
            styleConstants.fontStyle
        ].join(','),
    },
});

const HowItWorks = () => {
    const [isVisibleA, setIsVisibleA] = useState(false);
    const [isVisibleB, setIsVisibleB] = useState(false);
    const [isVisibleC, setIsVisibleC] = useState(false);

    useEffect(() => {
        document.body.style.overflow = "auto";
        document.body.style.backgroundColor = "#efefef";
        document.querySelector("body").scrollTo(0, 0);
    }, []);

    const mobileUI = (<Grid id="howitwork" container alignContent='center' alignItems='center' style={{ marginBottom: "100px", marginTop: "100px", maxWidth: window.innerWidth, padding: "20px" }}>
        <Grid item align='left' xs={12} style={{ marginBottom: '50px', zIndex: 1 }}>
            <Typography variant="h3" style={{ fontWeight: styleConstants.fontWeight.bold, color: '#991514' }}>
                HOW UTEST AVENUE WORKS
            </Typography>
        </Grid>
        <Grid item xs={12} style={{ marginBottom: "2%", zIndex: 1 }}>
            <Grid container alignItems='center'>
                <Grid item xs={12} align='center'>
                    <Box
                        component="img"
                        style={{ width: '100%' }}
                        alt="The house from the offer."
                        src="/Our team.png"
                    />
                </Grid>
                <Grid item align='left' xs={12}>
                    <Typography variant="h6" style={{ fontWeight: styleConstants.fontWeight.medium, textAlign: 'left', color: '#a00505' }}>
                        <span style={{ color: '#414042' }}>Our</span> teams of educators
                    </Typography>
                    <Typography gutterBottom variant="h6" style={{ fontWeight: styleConstants.fontWeight.medium, textAlign: 'left', color: '#a00505' }}>
                        <span style={{ color: '#414042' }}>and</span> software developers
                    </Typography>
                    <Typography variant="body2" style={{ fontWeight: styleConstants.fontWeight.thin, textAlign: 'left', color: '#414042' }}>
                        work collaboratively to design most similar experience to taking the actual tests.
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs={12} style={{ marginBottom: "2%", zIndex: 1 }}>
            <Grid container alignItems='center' spacing={10}>
                <Grid item xs={12} align='center'>
                    <Box
                        component="img"
                        style={{ width: '100%' }}
                        alt="The house from the offer."
                        src="/Our tests.png"
                    />
                </Grid>
                <Grid item align='left' xs={12}>
                    <Typography variant="h6" style={{ fontWeight: styleConstants.fontWeight.medium, textAlign: 'left', color: '#414042' }}>
                        Computer-Based
                    </Typography>
                    <Typography gutterBottom variant="h6" style={{ fontWeight: styleConstants.fontWeight.medium, textAlign: 'left', color: '#a00505' }}>
                        Simulation Tests
                    </Typography>
                    <Typography variant="body2" style={{ fontWeight: styleConstants.fontWeight.thin, textAlign: 'left', color: '#414042' }}>
                        with the easy-to-use system, our users can take complete computer-based simulation tests from anywhere at any time.
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs={12} style={{ marginBottom: "2%", zIndex: 1 }}>
            <Grid container alignItems='center' spacing={10}>
                <Grid item xs={12} align='center'>
                    <Box
                        component="img"
                        style={{ width: '100%' }}
                        alt="The house from the offer."
                        src="/Machine Learning.png"
                    />
                </Grid>
                <Grid item align='left' xs={12}>
                    <Typography variant="h6" style={{ fontWeight: styleConstants.fontWeight.medium, textAlign: 'left', color: '#414042' }}>
                        Our intelligent
                    </Typography>
                    <Typography gutterBottom variant="h6" style={{ fontWeight: styleConstants.fontWeight.medium, textAlign: 'left', color: '#a00505' }}>
                        Machine-Learning
                    </Typography>
                    <Typography variant="body2" style={{ fontWeight: styleConstants.fontWeight.thin, textAlign: 'left', color: '#414042' }}>
                        analyses the skills to generate a report for personal improvement.
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    </Grid>)

    const webUI = (<Grid id="howitwork" container alignContent='center' alignItems='center' style={{ marginBottom: "100px", marginTop: "100px", maxWidth: window.innerWidth, padding: "5%" }}>
        <VisibilitySensor onChange={(isVisible) => { if (isVisible) { setIsVisibleA(isVisible) } }}>
            <Grid item align='left' xs={12} style={{ marginBottom: '50px', zIndex: 1 }}>
                    <Typography variant="h3" style={{ fontWeight: styleConstants.fontWeight.bold, color: '#991514' }}>
                        HOW UTEST AVENUE WORKS
                    </Typography>
            </Grid>
        </VisibilitySensor>
        <VisibilitySensor onChange={(isVisible) => { if (!isVisible) { setIsVisibleA(isVisible) } }}>
            <Grid item xs={12} style={{ marginBottom: "10px", zIndex: 1 }}>
                <Grid container alignItems='center' spacing={10}>
                    <Grid item xs={6} align='center'>
                        <Box
                            component="img"
                            style={{ width: '100%' }}
                            alt="The house from the offer."
                            src="/Our team.png"
                        />
                    </Grid>
                    <Grid item align='left' xs={6}>
                        <Fade right when={isVisibleA}>
                            <Typography variant="h3" style={{ fontWeight: styleConstants.fontWeight.medium, textAlign: 'right', color: '#a00505' }}>
                                <span style={{ color: '#414042' }}>Our</span> teams of educators
                            </Typography>
                            <Typography gutterBottom variant="h3" style={{ fontWeight: styleConstants.fontWeight.medium, textAlign: 'right', color: '#a00505' }}>
                                <span style={{ color: '#414042' }}>and</span> software developers
                            </Typography>
                            <Typography variant="h6" style={{ fontWeight: styleConstants.fontWeight.thin, textAlign: 'right', color: '#414042' }}>
                                work collaboratively to design most similar experience to taking the actual tests.
                            </Typography>
                        </Fade>
                    </Grid>
                </Grid>
            </Grid>
        </VisibilitySensor>
        <VisibilitySensor onChange={(isVisible) => { setIsVisibleB(isVisible) }}>
            <Grid item xs={12} style={{ marginBottom: "10px", zIndex: 1 }}>
                <Grid container alignItems='center' spacing={10}>
                    <Grid item align='left' xs={6}>
                        <Fade left when={isVisibleB}>
                            <Typography variant="h3" style={{ fontWeight: styleConstants.fontWeight.medium, textAlign: 'left', color: '#414042' }}>
                                Computer-Based
                            </Typography>
                            <Typography gutterBottom variant="h3" style={{ fontWeight: styleConstants.fontWeight.medium, textAlign: 'left', color: '#a00505' }}>
                                Simulation Tests
                            </Typography>
                            <Typography variant="h6" style={{ fontWeight: styleConstants.fontWeight.thin, textAlign: 'left', color: '#414042' }}>
                                with the easy-to-use system, our users can take complete computer-based simulation tests from anywhere at any time.
                            </Typography>
                        </Fade>
                    </Grid>
                    <Grid item xs={6} align='center'>
                        <Box
                            component="img"
                            style={{ width: '100%' }}
                            alt="The house from the offer."
                            src="/Our tests.png"
                        />
                    </Grid>
                </Grid>
            </Grid>
        </VisibilitySensor>
        <VisibilitySensor onChange={(isVisible) => { setIsVisibleC(isVisible) }}>
            <Grid item xs={12} style={{ marginBottom: "10px", zIndex: 1 }}>
                <Grid container alignItems='center' spacing={10}>
                    <Grid item xs={6} align='center'>
                        <Box
                            component="img"
                            style={{ width: '100%' }}
                            alt="The house from the offer."
                            src="/Machine Learning.png"
                        />
                    </Grid>
                    <Grid item align='left' xs={6}>
                        <Fade right when={isVisibleC}>
                            <Typography variant="h3" style={{ fontWeight: styleConstants.fontWeight.medium, textAlign: 'left', color: '#414042' }}>
                                Our intelligent
                            </Typography>
                            <Typography gutterBottom variant="h3" style={{ fontWeight: styleConstants.fontWeight.medium, textAlign: 'left', color: '#a00505' }}>
                                Machine-Learning
                            </Typography>
                            <Typography variant="h6" style={{ fontWeight: styleConstants.fontWeight.thin, textAlign: 'left', color: '#414042' }}>
                                analyses the skills to generate a report for personal improvement.
                            </Typography>
                        </Fade>
                    </Grid>
                </Grid>
            </Grid>
        </VisibilitySensor>
    </Grid>)

    return <ThemeProvider theme={theme}>
        <Hidden mdUp>
            {mobileUI}
        </Hidden>
        <Hidden smDown>
            {webUI}
        </Hidden>
    </ThemeProvider>
};

export default compose(
    withStyles(useStyles)
)(HowItWorks);
