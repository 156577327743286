import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import MuiAlert from '@material-ui/lab/Alert';

import { Container } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Component } from 'react';
import { compose } from 'recompose';

//Icon

import "react-multi-carousel/lib/styles.css";

//Import component
import AccountInfo from '../myprofile/AccountInfo';
import MyReportHistory from '../myprofile/MyReportHistory';
import MySchedule from '../myprofile/MySchedule';
import PurchasedCourses from '../myprofile/PurchasedCourses';
import ReportProblemDialog from '../myprofile/ReportProblemDialog';
import TestHistory from '../myprofile/TestHistory';
import UserMenuTab from '../myprofile/UserMenuTab';

//import { deepOrange } from '@material-ui/core/colors';

//Others
const axios = require('axios').default;
const FormData = require('form-data');
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

const useStyles = theme => ({
    root: {
        margin: 15,
        marginTop: 50
    },
    flexRoot: {
        margin: 15,
        display: 'flex'
    },
    paperRoot: {
        margin: 15,
        width: theme.spacing(40),
        height: theme.spacing(60),
        padding: 30
    },
    infoPaper: {
        margin: 15,
        padding: 30
    },
    listRoot: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    cover: {
        width: 151,
    },
    margin: {
        margin: 15,
    },
    marginLarge: {
        margin: 30,
    },
    marginTop: {
        marginTop: 15,
    },
    large: {
        width: theme.spacing(40),
        height: theme.spacing(40),
    },
    small: {
        width: theme.spacing(20),
        height: theme.spacing(20),
    },
    orange: {
        //color: theme.palette.getContrastText(deepOrange[500]),
        //backgroundColor: deepOrange[500],
        width: theme.spacing(20),
        height: theme.spacing(20),
    },
    textField: {
        width: '250px',
        margin: theme.spacing(1),
    },
    textFieldSmall: {
        width: theme.spacing(10),
        margin: theme.spacing(1),
    },
    textFieldWithSmall: {
        width: theme.spacing(80),
        margin: theme.spacing(1),
    },
    courseProgressRoot: {
        width: '100%',
        margin: 15,
        marginTop: 0
    }
});

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function LinearProgressWithLabel(props) {
    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

class MyProfile extends Component {
    constructor() {
        super()
        this.state = {
            courseProgress: 50,
            numSchedules: 0,
            courses: [
                {
                    image: 'https://i.ytimg.com/vi/gT2yPwlcofA/maxresdefault.jpg',
                    name: 'BMAT',
                    progress: 40,
                    description: 'About BMAT It tests your ability to apply scientific and mathematical knowledge, as well as problem solving, critical thinking and written communication skills that are essential to university-level study.'
                },
                {
                    image: 'https://miro.medium.com/max/1200/1*i_9lBdBCorF4tXS1YWLLCg.jpeg',
                    name: 'TOEIC',
                    progress: 38,
                    description: 'The Test of English for International Communication is an international standardized test of English language proficiency for non-native speakers. It is intentionally designed to measure the everyday English skills of people working in an international environment.'
                },
                {
                    image: 'https://www.britishcouncil.or.th/sites/default/files/ielts_red_-_new_ielts_landing_page_preview_image.png',
                    name: 'IELTS',
                    progress: 87,
                    description: 'The International English Language Testing System, or IELTS, is an international standardized test of English language proficiency for non-native English language speakers'
                },
                {
                    image: 'https://cache-igetweb-v2.mt108.info/uploads/images-cache/3246/product/d6e3f6ab7b4cec34544dfffc3eb3c9bc_full.png',
                    name: 'CUTEP',
                    progress: 15,
                    description: 'Chulalongkorn University Test of English Proficiency which was modeled on the TOEFL test. CU-Tep was established, and is run by theChulalongkorn University Language Institute.'
                },
            ],
            breakPoints: [
                { width: 1, itemsToShow: 1 },
                { width: 600, itemsToShow: 2 },
                { width: 900, itemsToShow: 3 },
                { width: 1200, itemsToShow: 4 },
            ],
            window: {
                height: window.innerHeight,
                width: window.innerWidth
            },
            currentTab: 'information',
            reportProblemType: '',
            reportProblemTypeDescription: '',
            reportProblemTestObj: {},
            isOpenDialog: false
        };
    }

    componentDidMount = () => {
        document.querySelector("body").scrollTo(0,0);
        this.handleGetTestHistory();
        this.handleGetPurchasedCourse();
        this.handleGetTestReportHistory();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    handleResize = (event) => {
        this.setState({
            window: {
                height: window.innerHeight,
                width: window.innerWidth
            }
        });
    };

    handleChangeTab = (tab) => {
        this.setState({
            currentTab: tab
        });
    }

    handleNumChange = (type, num) => {
        this.setState({
            [type]: num
        });
    }

    handleGetTestReportHistory = async () => {
        try {
            const testReportHistory = await axios({
                method: 'get',
                url: api_base_url + "/api/test/result",
                headers: {
                    Authorization: "bearer " + JSON.parse(localStorage.getItem('user')).token
                }
            })

            this.setState({
                testReportHistory: testReportHistory.data
            });

            console.log(testReportHistory.data)

        } catch (error) {
            console.error(error);
        }
    }

    handleGetTestHistory = async () => {
        try {
            const testHistory = await axios({
                method: 'get',
                url: "https://chatter-funky-busby.glitch.me/test/status/logs?username=" + JSON.parse(localStorage.getItem('user')).username
            })

            console.log(testHistory.data.logs);

            this.setState({
                testHistory: testHistory.data.logs
            });

        } catch (error) {
            console.error(error);
            this.setState({
                testHistory: []
            });
        }
    }

    handleGetPurchasedCourse = async () => {
        try {
            const purchasedCourse = await axios({
                method: 'get',
                url: api_base_url + "/api/test/test-purchase-status?user_name=" + JSON.parse(localStorage.getItem('user')).username,
                headers: {
                    Authorization: "bearer " + JSON.parse(localStorage.getItem('user')).token
                }
            })

            console.log(purchasedCourse.data);

            this.setState({
                purchasedCourse: purchasedCourse.data
            });

        } catch (error) {
            console.error(error);
            this.setState({
                purchasedCourse: []
            });
        }
    }

    handleSelectReportProblem = (id, type) => {
        this.setState({
            reportProblemTestId: id,
            reportProblemTypeFor: type,
            isOpenDialog: true
        });
    }

    handleCloseDialog = () => {
        this.setState({
            isOpenDialog: false
        });
    }

    handleOpenDialog = () => {
        this.setState({
            isOpenDialog: true
        });
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root} style={{ maxWidth: this.state.window.width }}>
                <ReportProblemDialog
                    isOpenDialog={this.state.isOpenDialog}
                    reportProblemType={this.state.reportProblemType}
                    reportProblemTestId={this.state.reportProblemTestId}
                    reportProblemTypeFor={this.state.reportProblemTypeFor}
                    reportProblemTypeDescription={this.state.reportProblemTypeDescription}
                    handleChangeReportProblemType={this.handleChangeReportProblemType}
                    handleChangeInProblemReport={this.handleChangeInProblemReport}
                    handleCloseDialog={this.handleCloseDialog}
                    handleOpenDialog={this.handleOpenDialog}
                />
                <Container>
                    {this.props.userSignedin &&
                        <Grid container style={{ marginTop: '200px', marginBottom: '200px' }}>
                            <Grid item xs={12} sm={4}>
                                <UserMenuTab
                                    userInfo={this.props.user}
                                    userSignedin={this.props.userSignedin}
                                    testHistory={this.state.testHistory === undefined ? {} : this.state.testHistory}
                                    numSchedules={this.state.numSchedules === undefined ? 0 : this.state.numSchedules}
                                    numPosts={this.state.numPosts === undefined ? 0 : this.state.numPosts}
                                    numCourses={this.state.numCourses === undefined ? 0 : this.state.numCourses}
                                    numPurchaseHistory={this.state.numPurchaseHistory === undefined ? 0 : this.state.numPurchaseHistory}
                                    testReportHistory={this.state.testReportHistory}
                                    purchasedCourse={this.state.purchasedCourse}
                                />
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <AccountInfo
                                    userInfo={this.props.user}
                                    userSignedin={this.props.userSignedin}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TestHistory
                                    userInfo={this.props.user}
                                    userSignedin={this.props.userSignedin}
                                    testHistory={this.state.testHistory}
                                    handleOpenDialog={this.handleOpenDialog}
                                    handleSelectReportProblem={this.handleSelectReportProblem}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <MyReportHistory
                                    userInfo={this.props.user}
                                    userSignedin={this.props.userSignedin}
                                    testHistory={this.state.testHistory}
                                    testReportHistory={this.state.testReportHistory}
                                    handleOpenDialog={this.handleOpenDialog}
                                    handleSelectReportProblem={this.handleSelectReportProblem}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <PurchasedCourses
                                    userInfo={this.props.user}
                                    userSignedin={this.props.userSignedin}
                                    purchasedCourse={this.state.purchasedCourse}
                                    handleOpenDialog={this.handleOpenDialog}
                                    handleSelectReportProblem={this.handleSelectReportProblem}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                {(this.props.user.role === "STUDENT" || this.props.user.role === "SUPER_ADMIN") &&
                                    <MySchedule
                                        userInfo={this.props.user}
                                        handleNumChange={this.handleNumChange}
                                    />
                                }
                            </Grid>
                        </Grid>
                    }
                    {!this.props.userSignedin &&
                        <Alert severity="info">
                            Please Sign in or Create an account to use this feature.
                        </Alert>
                    }
                </Container>
            </div >
        )
    }
}
export default compose(
    withStyles(useStyles)
)(MyProfile);