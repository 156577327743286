import * as apiConstants from '../constants/apiConstants';

const axios = require('axios').default;

export async function GetTestReport(token) {
    try {
        const res = await axios({
            method: 'get',
            url: `${apiConstants.E_LEARNING_API_BASE_URL}/api/test/result`,
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });

        console.log(res);

        return {
            success: true,
            res: res.data
        }

    } catch (err) {
        console.log(err);
        return {
            success: false,
            res: err
        }
    }
}

export async function GetTestReportById(token, result_id) {
    try {
        const res = await axios({
            method: 'get',
            url: `${apiConstants.E_LEARNING_API_BASE_URL}/api/test/result`,
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });

        console.log(res);

        const resData = res.data.filter(report => report.result_id === result_id);

        console.log(resData);

        return {
            success: true,
            res: resData[0]
        }

    } catch (err) {
        console.log(err);
        return {
            success: false,
            res: err
        }
    }
}

export async function GetTestReportByResultId(token, result_id) {
    try {
        const res = await axios({
            method: 'get',
            url: `${apiConstants.TEST_MANAGEMENT_API_BASE_URL}/test-reports/${result_id}`,
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });

        return {
            success: true,
            res: res.data
        }

    } catch (err) {
        console.log(err);
        return {
            success: false,
            res: err
        }
    }
}

export async function GetAllTestReportByUsername(token, username) {
    try {
        const res = await axios({
            method: 'get',
            url: `${apiConstants.TEST_MANAGEMENT_API_BASE_URL}/users/${username}/test-reports`,
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });

        return {
            success: true,
            res: res.data
        }

    } catch (err) {
        console.log(err);
        return {
            success: false,
            res: err
        }
    }
}

export async function GetUserInfoByUsername(token, username) {
    try {
        const res = await axios({
            method: 'get',
            url: `${apiConstants.TEST_MANAGEMENT_API_BASE_URL}/users/${username}`,
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });

        return {
            success: true,
            res: res.data
        }

    } catch (err) {
        console.log(err);
        return {
            success: false,
            res: err
        }
    }
}

export async function GetAllTestReportById(token, id) {
    try {
        const res = await axios({
            method: 'get',
            url: `${apiConstants.TEST_MANAGEMENT_API_BASE_URL}/test-reports/${id}`,
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });

        return {
            success: true,
            res: res.data
        }

    } catch (err) {
        console.log(err);
        return {
            success: false,
            res: err
        }
    }
}

export async function GetAllTestReport() {
    try {
        const res = await axios({
            method: 'get',
            url: `${apiConstants.TEST_MANAGEMENT_API_BASE_URL}/test-reports`,
            headers: {
                "Authorization": `Bearer ${JSON.parse(localStorage.getItem('user')).token}`
            }
        });

        return {
            success: true,
            res: res.data
        }

    } catch (err) {
        console.log(err);
        return {
            success: false,
            res: err
        }
    }
}

export async function GetDetailedReport(token, testObj) {
    try {
        console.log(testObj);

        testObj["writing"] = {};
        testObj["speaking"] = {};

        console.log(testObj);

        const res = await axios({
            method: 'post',
            url: `${apiConstants.E_LEARNING_API_BASE_URL}/api/test/report/student`,
            headers: {
                "Authorization": `Bearer ${token}`
            },
            data: testObj
        });

        console.log(res);

        return {
            success: true,
            res: res.data
        }

    } catch (err) {
        console.log(err);
        return {
            success: false,
            res: err
        }
    }
}

export async function GetWritingNotes(token, username, testId) {
    try {
        const res = await axios({
            method: 'get',
            url: `${apiConstants.E_LEARNING_API_BASE_URL}/api/test/test-writing-note?user_name=${username}&test_id=${testId}`,
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });

        return {
            success: true,
            res: res.data
        }

    } catch (err) {
        console.log(err);
        return {
            success: false,
            res: err
        }
    }
}

export async function GetWritingNotesByUsername(username) {
    try {
        const res = await axios({
            method: 'get',
            url: `${apiConstants.SPEAKING_API_BASE_URL}/writing/score/username/${username}`
        });

        return {
            success: true,
            res: res.data
        }

    } catch (err) {
        console.log(err);
        return {
            success: false,
            res: err
        }
    }
}

export async function DownloadWritingFile(resultId, type, name) {
    const FileDownload = require("js-file-download");

    try {
        const res = await axios({
            method: 'get',
            url: `${apiConstants.TEST_MANAGEMENT_API_BASE_URL}/tests/result_id/${resultId}`
        });

        const file = await axios({
            method: "get",
            url: `${apiConstants.TEST_MANAGEMENT_API_BASE_URL}/tests/file/${type === 'student' ? res.data.fileName : res.data.teacherFile}`,
            responseType: "blob",
        });

        return await FileDownload(
            file.data,
            name ? name : res.data.fileName
        );

    } catch (err) {
        console.log(err);
        return {
            success: false,
            res: err
        }
    }
}

export async function GetSpeakingNotes(token, purchaseId) {
    try {
        const meetingInfo = await axios({
            method: 'get',
            url: `${apiConstants.SPEAKING_API_BASE_URL}/meetings/${purchaseId}`
        });

        const speakingInfo = await axios({
            method: 'get',
            url: `${apiConstants.SPEAKING_API_BASE_URL}/speaking/score/${meetingInfo.data.data.schedule.id}_${meetingInfo.data.data.schedule.password}`
        });

        return {
            success: true,
            res: speakingInfo.data
        }
    } catch (err) {
        return {
            success: false,
            res: err
        }
    }
}

export async function GetSpeakingNotesByUsername(username) {
    try {
        const res = await axios({
            method: 'get',
            url: `${apiConstants.SPEAKING_API_BASE_URL}/speaking/score/username/${username}`
        });

        return {
            success: true,
            res: res.data
        }
    } catch (err) {
        return {
            success: false,
            res: err
        }
    }
}
