//React
import { Component } from "react";
import { compose } from "recompose";

//Material UI
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FilterListRoundedIcon from "@material-ui/icons/FilterListRounded";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import Skeleton from "@material-ui/lab/Skeleton";

//import components
import PageBackdrop from "../../../main/PageBackdrop";
import WritingScoring from "./WritingScoring";

//Others
import moment from 'moment';
import { Link } from "react-router-dom";
import Select from "react-select";
import { GetAllSpeakingLog } from "../../../../services/testServices";
import WritingScoreDisplay from "./head-teacher/components/WritingScoreDisplay";
import { CreateNewNotification } from "../../../../services/notificationServices";
import SpeakingScoreDisplay from "./head-teacher/components/SpeakingScoreDisplay";
const axios = require("axios").default;
const FormData = require("form-data");
const FileDownload = require("js-file-download");

const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
});

class AssignedTests extends Component {
  constructor() {
    super();
    this.state = {
      numberOfTest: 0,
      selectedMenu: "assignedTest",
      filterType: "all",
      filteredTestStatus: "all",
      today: new Date(Date.now()),
      isScoring: false,
      isOpenDisplayDialog: false,
      selectedScore: {},
      isEdit: false,
      isFullLoading: true
    };
  }

  componentDidMount = async () => {
    this.setState({
      isFullLoading: true
    });
    await this.handleGetAllSubmittedTests();
    await this.handleGetAllSpeakingLog();
    this.setState({
      isFullLoading: false
    });
    this.handleGetAllTeachers();
  };

  handleGetAllTeachers = async () => {
    try {
      let tempArr = [];
      const res = await axios({
        method: 'get',
        url: 'https://chatter-funky-busby.glitch.me/teachers',
      });

      const resData = res.data.teachers;
      resData.map((obj) => {
        tempArr.push({ value: obj.username, label: `${obj.firstname} ${obj.lastname}` })
        return obj
      })

      this.setState({
        teachers: res.data.teachers,
        teachersSelect: tempArr,
        isLoading: false
      });

    } catch (err) {
      console.log(err);
    }
  }

  onClickSaveEditTeacher = async (testObj) => {
    try {
      this.handleLoading();

      const res = await axios({
        method: 'put',
        url: 'https://chatter-funky-busby.glitch.me/teachers/assign/update',
        data: {
          id: testObj._id,
          assignTo: this.state.selectedTeacher.label,
          teacherUsername: this.state.selectedTeacher.value
        }
      });

      if (res.status === 200) {
        CreateNewNotification(
          this.state.selectedTeacher.value,
          "teacher_writing",
          {
            message: `${testObj.submitter.split(' ')[0]}'s ${testObj.testCategory} ${testObj.testType} test has been re-assigned to you. Please check via assigned tests section in the dashboard.`,
          }
        );
      }

      await this.handleGetAllSubmittedTests();
      await this.handleGetAllSpeakingLog();

      this.onCloseEdit();
      this.handleEndLoading();
    } catch (err) {
      console.log(err);
    }
  }

  onSelectTeacher = (event) => {
    if (event === null) {
      this.setState({
        selectedTeacher: {}
      });
      console.log(event);
    } else if (event !== null) {
      this.setState({
        selectedTeacher: event
      });
    }
  }

  handleGetAllSubmittedTests = async () => {
    try {
      const submittedTestRes = await axios({
        method: 'get',
        url: 'https://chatter-funky-busby.glitch.me/test/head-teacher/submitted-tests',
      });

      const res = await axios({
        method: "get",
        url: "https://chatter-funky-busby.glitch.me/tests",
      });

      const resData = res.data;
      const assignedTests = resData.tests.filter((test) => test.assignTo !== "Unassigned" && test.createdAt);
      submittedTestRes.data.tests.map((test) => {
        const foundIndex = assignedTests.findIndex((obj) => { return obj._id === test.submitted_test_id });

        if (assignedTests[foundIndex] !== undefined) {
          assignedTests[foundIndex]["submitStatus"] = test;
        }
      })

      this.setState({
        tests: assignedTests.filter((test) => { return test.submitterUsername ? (!["bellebelle", "117337903114766421177"].includes(test.submitterUsername) && !["bellebelle", "117337903114766421177", "113282161277766108210"].includes(test.teacherUsername)) : false }),
        approvals: submittedTestRes.data.tests,
        numberOfTest:
          resData.tests.filter((test) => test.assignTo !== "Unassigned")
            .length <= 10
            ? resData.tests.length
            : 10,
      });
    } catch (err) {
      console.log(err);
    }
  };

  handleGetAllSpeakingLog = async () => {
    const res = await GetAllSpeakingLog();

    if (res.success) {
      if (!this.state.tests) {
        return this.setState({
          tests: [],
        });
      }
      await res.res.data.filter((test) => test.created_at !== undefined).filter((test) => { return test.user_email ? (!["b.billybillo@gmail.com", "jirayu.promsongwong@hotmail.com"].includes(test.user_email)) : false }).map((obj, index) => {
        const filterdObj = this.state.approvals.filter((spk) => { return spk.note.test_id === obj.id })
        this.state.tests.push({
          id: obj.id,
          submitter: obj.user_name,
          createdAt: obj.created_at,
          testCategory: "IELTS",
          testType: "Speaking",
          assignTo: obj.teacher_name,
          teacherUsername: obj.teacher_username,
          score: obj.score,
          timeLeft: obj.time_left,
          speakingStatus: filterdObj.length > 0 ? filterdObj[filterdObj.length - 1] : "empty",
          tags: ["IELTS", "Speaking", "Full"],
        });
      });

      this.setState({
        tests: this.state.tests,
      });
    }
  };

  handleDownloadTest = async (fileName, submitter, createdAt, type) => {
    try {
      this.handleLoading();

      const res = await axios({
        method: "get",
        url: "https://chatter-funky-busby.glitch.me/tests/file/" + fileName,
        responseType: "blob",
      });

      await FileDownload(
        res.data,
        `${submitter}_${createdAt.toLocaleDateString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })}_${type}file.pdf`
      );

      this.handleEndLoading();
    } catch (err) {
      console.log(err);
    }
  };

  handleUploadTest = async (id) => {
    this.setState({
      id: id,
    });
  };

  handleFileChange = async (event) => {
    try {
      event.preventDefault();

      this.handleLoading();

      let formData = new FormData();
      await formData.append("id", this.state.id);
      await formData.append("isUpdate", "true");
      await formData.append("submittedFile", event.target.files[0]);

      for (var pair of formData.entries()) {
        console.log(pair[0] + ', ' + pair[1]);
      }

      const res = await axios({
        method: "put",
        url: "https://chatter-funky-busby.glitch.me/tests/submit",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      });

      const resData = res.data;
      await this.handleGetAllSubmittedTests();
      this.handleEndLoading();
    } catch (err) {
      console.log(err);
    }
  };

  handleChange = (event) => {
    const name = event.target.name;

    this.setState({
      ...this.state,
      [name]: event.target.value,
    });
  };

  handleSeeMoreTest = () => {
    if (this.state.numberOfTest + 10 >= this.state.tests.length) {
      this.setState({
        numberOfTest: this.state.tests.length,
      });

      return;
    }

    this.setState({
      numberOfTest: this.state.numberOfTest + 10,
    });
  };

  handleSeeLessTest = () => {
    if (this.state.numberOfTest - 10 <= 10) {
      this.setState({
        numberOfTest: 10,
      });

      return;
    }

    this.setState({
      numberOfTest: this.state.numberOfTest - 10,
    });
  };

  handleCloseScoringDialog = () => {
    this.setState({
      isScoring: false,
    });
  };

  handleOpenScoringDialog = (
    test,
    tester,
    submitDate,
    assignTo,
    submitterUsername,
    submissionId,
    test_id
  ) => {
    this.setState({
      test: test,
      test_id: test_id,
      isScoring: true,
      tester: tester,
      submitDate: submitDate,
      assignTo: assignTo,
      submitterUsername: submitterUsername,
      teacherUsername: this.props.userInfo.username,
      submissionId: submissionId,
    });
  };

  //================== FILTER RELATED ======================
  handleChipClick = (type) => {
    this.setState({
      filterType: type,
    });
  };

  handleSelectFilterTestStatus = (event) => {
    if (event === null) {
      this.setState({
        filteredTestStatus: "all",
      });
    } else if (event !== null) {
      this.setState({
        filteredTestStatus: event.value,
      });
    }
  };
  //================== FILTER RELATED ======================

  handleColorChip = (tag) => {
    if (tag === "Full") {
      return (
        <Chip
          label={tag}
          style={{ margin: "5px", backgroundColor: "#76323F", color: "white" }}
        />
      );
    } else if (tag === "IELTS") {
      return (
        <Chip
          label={tag}
          style={{ margin: "5px", backgroundColor: "#141951", color: "white" }}
        />
      );
    } else if (tag === "Scored") {
      return (
        <Chip
          label={tag}
          style={{
            margin: "5px",
            backgroundColor: "white",
            color: "#008000",
            border: "1px solid #008000",
          }}
        />
      );
    } else {
      return <Chip label={tag} style={{ margin: "5px" }} />;
    }
  };

  handleAddLeadingZero = (num) => {
    if (num < 10) {
      return "0" + num;
    } else if (num >= 10) {
      return "" + num;
    }
  };

  handleLoading = () => {
    this.setState({
      isLoading: true,
    });
  };

  handleEndLoading = () => {
    this.setState({
      isLoading: false,
    });
  };

  onCloseDisplayDialog = () => {
    this.setState({
      isOpenDisplayDialog: false
    });
  }

  onOpenDisplayDialog = () => {
    this.setState({
      isOpenDisplayDialog: true
    });
  }

  handleOpenScoreDisplay = (obj) => {
    this.setState({
      selectedScore: obj
    })

    this.onOpenDisplayDialog()
  }

  onOpenEdit = () => {
    this.setState({
      isEdit: true
    });
  }

  onCloseEdit = () => {
    this.setState({
      isEdit: false
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <Grid item xs={12} sm={9}>
        <PageBackdrop isLoading={this.state.isLoading} />

        <WritingScoring
          test={this.state.test}
          test_id={this.state.test_id}
          isScoring={this.state.isScoring}
          tester={this.state.tester}
          submitDate={this.state.submitDate}
          assignTo={this.state.assignTo}
          submitterUsername={this.state.submitterUsername}
          submissionId={this.state.submissionId}
          teacherUsername={this.state.teacherUsername}
          token={this.props.userInfo.token}
          handleCloseScoringDialog={this.handleCloseScoringDialog}
          handleGetAllSubmittedTests={this.handleGetAllSubmittedTests}
        />

        {this.state.selectedScore &&
          (this.state.selectedScore.testType === "Speaking" ? <SpeakingScoreDisplay
            test={this.state.test}
            isOpenDialog={this.state.isOpenDisplayDialog}
            scoreObj={this.state.selectedScore.speakingStatus}
            onCloseScoreDialog={this.onCloseDisplayDialog}
            handleGetAllSubmittedTests={this.handleGetAllSubmittedTests}
          /> : <WritingScoreDisplay
            test={this.state.test}
            isOpenDialog={this.state.isOpenDisplayDialog}
            scoreObj={this.state.selectedScore.submitStatus}
            onCloseScoreDialog={this.onCloseDisplayDialog}
            handleGetAllSubmittedTests={this.handleGetAllSubmittedTests}
          />)
        }

        <Grid container>
          <Grid item xs={12}>
            <Typography align="left" variant="h5" gutterBottom>
              <Box fontWeight="fontWeightBold" display="inline">
                Assigned Tests
              </Box>
            </Typography>
            <Grid item align="left" xs={12}>
              <Grid container>
                <Grid item xs={8}>
                  <Chip
                    avatar={
                      <FilterListRoundedIcon
                        style={{
                          color: this.state.filterType === "all" ? "white" : "",
                        }}
                      />
                    }
                    label="All"
                    onClick={() => {
                      this.handleChipClick("all");
                    }}
                    style={{
                      margin: "5px",
                      backgroundColor:
                        this.state.filterType === "all" ? "#76323F" : "",
                      color: this.state.filterType === "all" ? "white" : "",
                    }}
                  />
                  <Chip
                    avatar={
                      <FilterListRoundedIcon
                        style={{
                          color:
                            this.state.filterType === "Free" ? "white" : "",
                        }}
                      />
                    }
                    label="Free test"
                    onClick={() => {
                      this.handleChipClick("Free");
                    }}
                    style={{
                      margin: "5px",
                      backgroundColor:
                        this.state.filterType === "Free" ? "#76323F" : "",
                      color: this.state.filterType === "Free" ? "white" : "",
                    }}
                  />
                  <Chip
                    avatar={
                      <FilterListRoundedIcon
                        style={{
                          color:
                            this.state.filterType === "Full" ? "white" : "",
                        }}
                      />
                    }
                    label="Full test"
                    onClick={() => {
                      this.handleChipClick("Full");
                    }}
                    style={{
                      margin: "5px",
                      backgroundColor:
                        this.state.filterType === "Full" ? "#76323F" : "",
                      color: this.state.filterType === "Full" ? "white" : "",
                    }}
                  />
                  <Chip
                    avatar={
                      <FilterListRoundedIcon
                        style={{
                          color:
                            this.state.filterType === "Writing" ? "white" : "",
                        }}
                      />
                    }
                    label="Writing"
                    onClick={() => {
                      this.handleChipClick("Writing");
                    }}
                    style={{
                      margin: "5px",
                      backgroundColor:
                        this.state.filterType === "Writing" ? "#76323F" : "",
                      color: this.state.filterType === "Writing" ? "white" : "",
                    }}
                  />
                  <Chip
                    avatar={
                      <FilterListRoundedIcon
                        style={{
                          color:
                            this.state.filterType === "Speaking" ? "white" : "",
                        }}
                      />
                    }
                    label="Speaking"
                    onClick={() => {
                      this.handleChipClick("Speaking");
                    }}
                    style={{
                      margin: "5px",
                      backgroundColor:
                        this.state.filterType === "Speaking" ? "#76323F" : "",
                      color:
                        this.state.filterType === "Speaking" ? "white" : "",
                    }}
                  />
                </Grid>
                <Grid item xs={1} style={{ padding: "5px" }}>
                  <Typography align="right" variant="subtitle1">
                    <Box fontWeight="fontWeightBold" display="inline">
                      Status:{" "}
                    </Box>
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Select
                    name="Status"
                    isSearchable={false}
                    isClearable={true}
                    options={[
                      { value: "Checked", label: "Checked" },
                      { value: "Unchecked", label: "Unchecked" },
                    ]}
                    onChange={this.handleSelectFilterTestStatus}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Paper
              variant="outlined"
              style={{ overflowY: "auto", maxHeight: "800px" }}
            >
              {(this.state.tests !== undefined && !this.state.isFullLoading) ? (
                this.state.tests
                  .sort((a, b) =>
                    new Date(a.createdAt) < new Date(b.createdAt) ? 1 : -1
                  )
                  .filter((test) =>
                    this.state.filterType !== "all"
                      ? test.tags.some((tag) => tag === this.state.filterType)
                      : true
                  )
                  .filter((test) =>
                    this.state.filteredTestStatus !== "all" &&
                      this.state.filteredTestStatus === "Checked"
                      ? test.teacherFile !== "Unassigned"
                      : true
                  )
                  .filter((test) =>
                    this.state.filteredTestStatus !== "all" &&
                      this.state.filteredTestStatus === "Unchecked"
                      ? test.teacherFile === "Unassigned"
                      : true
                  )
                  .map((test, index) => (
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Grid key={`id-${test._id}`} container spacing={0}>
                          <Grid align="left" item xs={12} sm>
                            <Typography>
                              {test.submitter}'s {test.testType.toLowerCase()} test
                            </Typography>
                            <Typography variant="caption" color="textSecondary">
                              {moment(test.createdAt).format('MMMM Do YYYY, h:mm:ss A')}
                            </Typography>
                          </Grid>
                          <Grid align="right" item xs={12} sm>
                            {test.tags.map((tag) => this.handleColorChip(tag))}
                            {test.submitStatus && <Chip
                              label={test.submitStatus.isChecked ? test.submitStatus.status.toUpperCase() : 'Wait for approval'}
                              style={{ margin: "5px", backgroundColor: test.submitStatus.isChecked ? (test.submitStatus.status === 'approved' ? 'green' : '#be323e') : 'orange', color: "white" }}
                            />
                            }
                            {(test.testType === "Speaking" && test.speakingStatus !== 'empty') && <Chip
                              label={test.speakingStatus.status !== 'waiting' ? test.speakingStatus.status.toUpperCase() : 'Wait for approval'}
                              style={{ margin: "5px", backgroundColor: test.speakingStatus.status !== 'waiting' ? (test.speakingStatus.status === 'approved' ? 'green' : '#be323e') : 'orange', color: "white" }}
                            />
                            }
                          </Grid>
                          {!test.tags.includes("Scored") &&
                            test.testType.toLowerCase() !== "speaking" &&
                            test.teacherFile === "Unassigned" && (
                              <Chip
                                label={
                                  test.teacherFile === "Unassigned"
                                    ? "Unchecked"
                                    : "Checked"
                                }
                                style={{
                                  margin: "5px",
                                  backgroundColor:
                                    test.teacherFile === "Unassigned"
                                      ? "orange"
                                      : "green",
                                  color: "white",
                                }}
                              />
                            )}
                            {(test.testType === "Speaking") && (
                              <Chip
                                label={
                                  test.speakingStatus === 'empty'
                                    ? "Unchecked"
                                    : "Checked"
                                }
                                style={{
                                  margin: "5px",
                                  backgroundColor:
                                  test.speakingStatus === 'empty'
                                      ? "orange"
                                      : "green",
                                  color: "white",
                                }}
                              />
                            )}
                        </Grid>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid item xs={12} style={{ paddingLeft: "15%" }}>
                          <Grid container spacing={1}>
                            <Grid item align="right" xs={12}>
                              {!this.state.isEdit && <Button
                                disableElevation
                                size="small"
                                variant="contained"
                                onClick={this.onOpenEdit}
                              >
                                Edit
                              </Button>}
                              {this.state.isEdit && <Button
                                disableElevation
                                size="small"
                                variant="contained"
                                onClick={() => { this.onClickSaveEditTeacher(test) }}
                                style={{ backgroundColor: "#76323F", color: "white" }}
                              >
                                Save
                              </Button>}
                              {this.state.isEdit && <Button
                                disableElevation
                                size="small"
                                variant="contained"
                                onClick={this.onCloseEdit}
                                style={{ marginLeft: "5px" }}
                              >
                                Cancel
                              </Button>}
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container spacing={1}>
                                <Grid item align="right" xs={2}>
                                  <Typography style={{ fontWeight: "bold" }}>
                                    Tester:
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Typography>
                                    {test.submitter}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container spacing={1}>
                                <Grid item align="right" xs={2}>
                                  <Typography style={{ fontWeight: "bold" }}>
                                    Submit date:
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Typography>
                                    {moment(test.createdAt).format('MMMM Do YYYY, h:mm:ss A')}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container spacing={1}>
                                <Grid item align="right" xs={2}>
                                  <Typography style={{ fontWeight: "bold" }}>
                                    Test id:
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Typography>
                                    {test.testType.toLowerCase() === "speaking"
                                      ? test.id
                                      : test.test_id}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container spacing={1}>
                                <Grid item align="right" xs={2}>
                                  <Typography style={{ fontWeight: "bold" }}>
                                    Test category:
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Typography>
                                    {test.testCategory}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container spacing={1}>
                                <Grid item align="right" xs={2}>
                                  <Typography style={{ fontWeight: "bold" }}>
                                    Test type:
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Typography>
                                    {test.testType}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container alignItems="center" spacing={1}>
                                <Grid item align="right" xs={2}>
                                  <Typography style={{ fontWeight: "bold" }}>
                                    Assign to:
                                  </Typography>
                                </Grid>
                                {this.state.isEdit ? <Grid item>
                                  <div style={{ width: "400px" }}>
                                    <Select
                                      name="AssignTo"
                                      isLoading={!this.state.teachersSelect}
                                      isSearchable={false}
                                      isClearable={true}
                                      defaultValue={{ value: test.teacherUsername, label: test.assignTo }}
                                      options={this.state.teachersSelect}
                                      onChange={this.onSelectTeacher}
                                    />
                                  </div>
                                </Grid> : <Grid item>
                                  <Typography>
                                    {test.assignTo}
                                  </Typography>
                                </Grid>}
                              </Grid>
                            </Grid>
                            <Grid item xs={12} style={{ marginBottom: "20px" }}>
                              <Grid container spacing={1}>
                                <Grid item align="right" xs={2}>
                                  <Typography style={{ fontWeight: "bold" }}>
                                    Status:
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  {test.testType.toLowerCase() !== "speaking" ? (
                                    <Typography>
                                      {test.teacherFile === "Unassigned"
                                        ? "Unchecked"
                                        : "Checked"}
                                    </Typography>
                                  ) : <Typography>
                                    {test.speakingStatus === "empty"
                                      ? "Unchecked"
                                      : "Waiting for approval"}
                                  </Typography>}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          {test.testType.toLowerCase() !== "speaking" && (
                            <Grid align="right" item xs={12}>
                              <Button
                                disableElevation
                                size="small"
                                variant="contained"
                                startIcon={<GetAppRoundedIcon />}
                                style={{ marginRight: "10px" }}
                                onClick={() =>
                                  this.handleDownloadTest(
                                    test.fileName,
                                    test.submitter,
                                    new Date(test.createdAt),
                                    "student"
                                  )
                                }
                              >
                                Download Student File
                              </Button>
                              {test.teacherFile !== "Unassigned" && (
                                <Button
                                  disableElevation
                                  size="small"
                                  variant="contained"
                                  startIcon={<GetAppRoundedIcon />}
                                  style={{ marginRight: "10px" }}
                                  onClick={() =>
                                    this.handleDownloadTest(
                                      test.teacherFile,
                                      test.assignTo,
                                      new Date(test.createdAt),
                                      "teacher"
                                    )
                                  }
                                >
                                  Download Teacher File
                                </Button>
                              )}
                              {test.submitStatus &&
                                <Button
                                  disableElevation
                                  size="small"
                                  variant="contained"
                                  style={{
                                    marginRight: "10px",
                                    backgroundColor: "#76323F",
                                    color: "white",
                                  }}
                                  onClick={() => {
                                    this.handleOpenScoreDisplay(test);
                                  }}
                                >
                                  Review Score
                                </Button>
                              }
                              {test.teacherFile !== "Unassigned" &&
                                test.submitterUsername !== undefined &&
                                (test.submitStatus && (["approved", "waiting"].includes(test.submitStatus?.status)) ? (
                                  <Button
                                    disabled
                                    disableElevation
                                    size="small"
                                    variant="contained"
                                    style={{ marginRight: "10px" }}
                                  >
                                    Submitted
                                  </Button>
                                ) : (
                                  <Button
                                    disableElevation
                                    size="small"
                                    variant="contained"
                                    style={{
                                      marginRight: "10px",
                                      backgroundColor: "#76323F",
                                      color: "white",
                                    }}
                                    onClick={() => {
                                      this.handleOpenScoringDialog(
                                        test,
                                        test.submitter,
                                        test.createdAt,
                                        test.assignTo,
                                        test.submitterUsername,
                                        test._id,
                                        (test.result_id === undefined || test.result_id === "undefined")
                                          ? test.test_id
                                          : test.result_id
                                      );
                                    }}
                                  >
                                    Submit Score
                                  </Button>
                                ))}
                              {(test.submitStatus && (["approved", "waiting"].includes(test.submitStatus?.status)) ? (
                                <Button
                                  disabled
                                  disableElevation
                                  size="small"
                                  variant="contained"
                                >
                                  Uploaded
                                </Button>
                              ) : (<>
                                <input
                                  accept="application/pdf"
                                  id={`teacher-file-input-id-${test._id}`}
                                  type="file"
                                  onChange={this.handleFileChange}
                                  style={{ display: "none" }}
                                />
                                <label htmlFor={`teacher-file-input-id-${test._id}`}>
                                  <Button
                                    disableElevation
                                    size="small"
                                    variant="contained"
                                    component="span"
                                    onClick={() => {
                                      this.handleUploadTest(test._id);
                                    }}
                                  >
                                    Upload
                                  </Button>
                                </label>
                              </>
                              ))}
                            </Grid>
                          )}
                          {test.testType.toLowerCase() === "speaking" && (
                            <Grid align="right" item xs={12}>
                              {test.speakingStatus !== "empty" &&
                                <Button
                                  disableElevation
                                  size="small"
                                  variant="contained"
                                  style={{
                                    marginRight: "10px",
                                    backgroundColor: "#76323F",
                                    color: "white",
                                  }}
                                  onClick={() => {
                                    this.handleOpenScoreDisplay(test);
                                  }}
                                >
                                  Review Score
                                </Button>
                              }
                              {(test.speakingStatus !== "empty" && (test.speakingStatus.status === "waiting" || test.speakingStatus.status === "approved")) ? <Button
                                disabled
                                disableElevation
                                size="small"
                                variant="contained"
                                style={{
                                  marginRight: "10px",
                                  color: "white",
                                }}
                              >
                                Submitted
                              </Button> : <Link
                                to={`/test/speaking/scoring?testid=&tester=${test.teacherUsername
                                  }&comments=${test?.score?.comment ? test?.score?.comment : ""
                                  }&id=${test?.id?.split("_")[0]}&password=${test?.id?.split("_")[1]
                                  }`}
                                target="_blank"
                                style={{ textDecoration: "none" }}
                              >
                                <Button
                                  disableElevation
                                  size="small"
                                  variant="contained"
                                  style={{
                                    marginRight: "10px",
                                    backgroundColor: "#76323F",
                                    color: "white",
                                  }}
                                >
                                  Submit Score
                                </Button>
                              </Link>}
                            </Grid>
                          )}
                        </Grid>
                        {/* <Grid container spacing={2}>
                          <Grid align="right" item xs={6} sm={3}>
                            <Typography>
                              <Box fontWeight="fontWeightBold" display="inline">
                                Tester:
                              </Box>
                            </Typography>
                            <Typography>
                              <Box fontWeight="fontWeightBold" display="inline">
                                Submit date:
                              </Box>
                            </Typography>
                            <Typography>
                              <Box fontWeight="fontWeightBold" display="inline">
                                Test id:
                              </Box>
                            </Typography>
                            <Typography>
                              <Box fontWeight="fontWeightBold" display="inline">
                                Test category:
                              </Box>
                            </Typography>
                            <Typography>
                              <Box fontWeight="fontWeightBold" display="inline">
                                Type:
                              </Box>
                            </Typography>
                            <Typography>
                              <Box fontWeight="fontWeightBold" display="inline">
                                Assign to:
                              </Box>
                            </Typography>
                            {test.testType.toLowerCase() !== "speaking" && (
                              <Typography>
                                <Box
                                  fontWeight="fontWeightBold"
                                  display="inline"
                                >
                                  Status:
                                </Box>
                              </Typography>
                            )}
                          </Grid>
                          <Grid align="left" item xs={6} sm={9}>
                            <Typography>{test.submitter}</Typography>
                            <Typography>
                              {new Date(test.createdAt).toDateString()}
                            </Typography>
                            <Typography>
                              {test.testType.toLowerCase() === "speaking"
                                ? test.id
                                : test.test_id}
                            </Typography>
                            <Typography>{test.testCategory}</Typography>
                            <Typography>{test.testType}</Typography>
                            <Typography>
                              {test.assignTo} <Button size="small" variant="contained" disableElevation style={{ marginLeft: "10px" }}>
                                Edit
                              </Button>
                            </Typography>
                            <div style={{ width: "200px" }}>
                              <Select
                                name="Status"
                                isSearchable={false}
                                isClearable={true}
                                options={[
                                  { value: "Checked", label: "Checked" },
                                  { value: "Unchecked", label: "Unchecked" },
                                ]}
                                onChange={this.handleSelectFilterTestStatus}
                              />
                            </div>
                            {test.testType.toLowerCase() !== "speaking" && (
                              <Typography>
                                {test.teacherFile === "Unassigned"
                                  ? "Unchecked"
                                  : "Checked"}
                              </Typography>
                            )}
                          </Grid> 
                        </Grid> */}
                      </AccordionDetails>
                    </Accordion>
                  ))
              ) : (
                <Grid>
                  <Skeleton variant="rect" width="100%" height={80} />
                </Grid>
              )}
            </Paper>
          </Grid>
          {/*<Grid item xs={12}>
                        {this.state.tests !== undefined && !(this.state.numberOfTest >= this.state.tests.length) &&
                            <Button
                                variant="contained"
                                style={{ width: '100%', marginTop: '20px', backgroundColor: '#76323F', color: 'white' }}
                                onClick={this.handleSeeMoreTest}
                            >
                                See More (+10)
                            </Button>
                        }
                        {this.state.tests !== undefined && (this.state.numberOfTest === this.state.tests.length) &&
                            <Button
                                variant="contained"
                                style={{ width: '100%', marginTop: '20px', backgroundColor: '#76323F', color: 'white' }}
                                onClick={this.handleSeeLessTest}
                            >
                                See Less (-10)
                            </Button>
                        }
                    </Grid>*/}
        </Grid>
      </Grid>
    );
  }
}

export default compose(withStyles(useStyles))(AssignedTests);
