import React from 'react';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import MuiAlert from '@material-ui/lab/Alert';

//Components

//Icons

//Others
import { withStyles } from '@material-ui/core/styles';
import { Component } from 'react';
import Select from 'react-select';
import { compose } from 'recompose';
import { FormControlLabel } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';

const axios = require('axios').default;
const FormData = require('form-data');
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

const useStyles = theme => ({
    root: {
        margin: 15,
        marginTop: 50
    },
    buttonColor: {
        backgroundColor: '#76323F',
        color: 'white'
    }
});

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class CreateDiscountCode extends Component {
    constructor() {
        super()
        this.state = {
            isLoading: false,
            reqObj: {
                discountCode: "",
                discount: "",
                useFor: "",
                createdBy: "",
                useCount: 1,
                expiredAt: new Date(Date.now()),
                selectedDate: new Date(Date.now()),
                isForTesting: false 
            }
        };
    }

    handleChangeDiscountCode = (event) => {
        this.state.reqObj.discountCode = event.target.value.toUpperCase();

        this.setState({
            reqObj: this.state.reqObj
        });
    }

    handleChangeDiscount = async (event) => {
        this.state.reqObj.discount = event.target.value;

        this.setState({
            reqObj: this.state.reqObj
        });
    }

    handleChangeUseCount = (event) => {
        console.log(event.target.value);

        this.state.reqObj.useCount = Number(event.target.value);

        this.setState({
            reqObj: this.state.reqObj
        });
    }

    handleChangeUserFor = (event) => {
        if (event === null) {
            this.state.reqObj.useFor = "";
        } else if (event !== null) {
            this.state.reqObj.useFor = event.value;
        }

        this.setState({
            reqObj: this.state.reqObj
        });
    }

    handleChangeExpireDate = async (event) => {
        this.state.reqObj.expiredAt = new Date(event.target.value).toISOString();

        this.setState({
            reqObj: this.state.reqObj,
            selectedDate: event.target.value
        });
    }

    handleCreateNewDiscountCode = async () => {
        try {
            this.state.reqObj.createdBy = JSON.parse(localStorage.getItem('user')).username;

            this.setState({
                isLoading: true
            });

            await axios({
                method: 'post',
                url: 'https://erratic-noiseless-wasabi.glitch.me/utestavenue-system-api/api/v1/discounts/create',
                data: this.state.reqObj
            });

            this.props.handleSelectMenu('discountCode')
        } catch (err) {
            console.log(err);
        }
    }

    handleChangeCheckBox = (event) => {
        this.state.reqObj.isForTesting = event.target.checked;
        this.setState({
            isForTesting: event.target.checked,
            reqObj: this.state.reqObj
        });

        console.log(this.state.reqObj)
    }

    render() {
        const { classes } = this.props;

        return (
            <Grid item xs={12}>
                <Paper>
                    <Grid container spacing={2} style={{ padding: '20px' }}>
                        <Grid item align='left' xs={12}>
                            <Typography variant="h4" style={{ fontWeight: 'bold' }} gutterBottom>
                                Create new discount code
                            </Typography>
                        </Grid>

                        <Grid item align='left' xs={12}>
                            <Typography variant="h5" style={{ fontWeight: 'bold' }} gutterBottom>
                                Discount code
                            </Typography>
                            <TextField
                                disabled={this.state.isLoading}
                                type="input"
                                id="outlined-basic-title"
                                variant="outlined"
                                size="small"
                                value={this.state.reqObj.discountCode}
                                onChange={this.handleChangeDiscountCode}
                            />
                        </Grid>

                        <Grid item align='left' xs={12}>
                            <Typography variant="h5" style={{ fontWeight: 'bold' }} gutterBottom>
                                Discount
                            </Typography>
                            <TextField
                                disabled={this.state.isLoading}
                                type="input"
                                id="outlined-basic-title"
                                variant="outlined"
                                size="small"
                                value={this.state.reqObj.discount}
                                onChange={this.handleChangeDiscount}
                            />
                        </Grid>

                        <Grid item align='left' xs={12}>
                            <Typography variant="h5" style={{ fontWeight: 'bold' }} gutterBottom>
                                Used for
                            </Typography>
                        </Grid>
                        <Grid item align='left' xs={6}>
                            <Select
                                name="testType"
                                isSearchable={true}
                                isClearable={true}
                                isDisabled={this.state.isLoading}
                                options={[
                                    { value: 'IELTS', label: 'IELTS' },
                                    { value: 'BMAT', label: 'BMAT' }
                                ]}
                                onChange={this.handleChangeUserFor}
                            />
                        </Grid>

                        <Grid item align='left' xs={12}>
                            <Typography variant="h5" style={{ fontWeight: 'bold' }} gutterBottom>
                                Usage limits
                            </Typography>
                        </Grid>
                        <Grid item align='left' xs={6}>
                            <TextField
                                disabled={this.state.isLoading}
                                type="number"
                                id="outlined-basic-title"
                                variant="outlined"
                                size="small"
                                value={this.state.reqObj.useCount}
                                onChange={this.handleChangeUseCount}
                            />
                        </Grid>

                        <Grid item align='left' xs={12}>
                            <Typography variant="h5" style={{ fontWeight: 'bold' }} gutterBottom>
                                Expired at
                            </Typography>
                        </Grid>
                        <Grid item align='left' xs={12}>
                            <TextField
                                disabled={this.state.isLoading}
                                id="date"
                                type="date"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={this.handleChangeExpireDate}
                            />
                        </Grid>

                        <Grid item align='left' xs={12} style={{ marginTop: '15px' }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.isForTesting}
                                        onChange={this.handleChangeCheckBox}
                                        size="small"
                                        name="isForTesting"
                                        color="default"
                                    />
                                }
                                label="Check this box if this code is for testing purpose only."
                            />
                        </Grid>

                        <Grid item align='left' xs={12} style={{ marginTop: '50px' }}>
                            <Button
                                disableElevation
                                disabled={this.state.isLoading}
                                variant="contained"
                                className={classes.buttonColor}
                                onClick={this.handleCreateNewDiscountCode}
                            >
                                {this.state.isLoading ? <CircularProgress size={30} style={{ color: 'white' }} /> : 'Create'}
                            </Button>
                        </Grid>
                    </Grid>
                </Paper >
            </Grid >
        )
    }
}
export default compose(
    withStyles(useStyles)
)(CreateDiscountCode);
