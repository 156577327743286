import React from 'react';

import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import LinearProgress from '@material-ui/core/LinearProgress';
import MuiAlert from '@material-ui/lab/Alert';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

//Others
import { withStyles } from '@material-ui/core/styles';
import { Component } from 'react';
import { compose } from 'recompose';
import MaterialTable from 'material-table'

//Icons
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

//Others
const axios = require('axios').default;
const FormData = require('form-data');
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

const useStyles = theme => ({
    root: {
        margin: 15,
        marginTop: 50
    }
});

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class AssignedWorks extends Component {
    constructor() {
        super()
        this.state = {
            testsToShow: []
        };
    }

    componentDidMount = () => {
        this.handleGetAllSubmittedTests();
    }

    handleGetAllSubmittedTests = async () => {
        try {
            const testsToShow = [];
            const res = await axios({
                method: 'get',
                url: 'https://chatter-funky-busby.glitch.me/tests?teacherUsername=103455356512977070747' /*+ JSON.parse(localStorage.getItem('user')).username*/
            });

            const resData = res.data;
            await resData.tests.map((test) => {
                testsToShow.push({
                    testCategory: test.testCategory,
                    testType: test.testType,
                    status: test.teacherFile === 'Unassigned' ? 'Waiting' : 'Checked',
                    assignTo: test.assignTo,
                    createdAt: new Date(test.createdAt).toDateString()
                })
            })
            console.log()
            this.setState({
                testsToShow: testsToShow
            });

        } catch (err) {
            console.log(err);
        }
    }

    render() {
        const { classes } = this.props;

        return (
            <Grid item xs={12}>
                <MaterialTable
                    data={this.state.testsToShow}
                    columns={[
                        {
                            title: 'Category',
                            field: 'testCategory',
                            editable: 'never'
                        },
                        {
                            title: 'Type',
                            field: 'testType',
                            editable: 'never'
                        },
                        {
                            title: 'Assign to',
                            field: 'assignTo',
                            editable: 'never'
                        },
                        {
                            title: 'Created',
                            field: 'createdAt',
                            editable: 'never',
                            defaultSort: 'desc',
                            customSort: (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
                        },
                        {
                            title: 'Status',
                            field: 'status',
                            editable: 'never'
                        }
                    ]}
                    options={{
                        actionsColumnIndex: -1,
                        exportAllData: true,
                        exportButton: true,
                        grouping: true
                    }}
                    title='My assigned test'
                />
            </Grid>
        )
    }
}
export default compose(
    withStyles(useStyles)
)(AssignedWorks);