import * as apiConstants from '../constants/apiConstants';

const axios = require('axios').default;

//=========================== GET ALL SECTIONS ======================================
export async function GetAllSections(token) {
    try {
        const res = await axios({
            method: 'get',
            url: `${apiConstants.E_LEARNING_API_BASE_URL}/api/test/section`,
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });

        return {
            success: true,
            res: res.data
        }
    } catch (err) {
        console.log(err);
        return {
            success: false,
            res: err
        }
    }
}
//=========================== GET ALL SECTIONS ======================================

//=========================== GET ALL PARTS ======================================
export async function GetAllParts(token) {
    try {
        const res = await axios({
            method: 'get',
            url: `${apiConstants.E_LEARNING_API_BASE_URL}/api/test/part/all`,
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });

        return {
            success: true,
            res: res.data
        }
    } catch (err) {
        console.log(err);
        return {
            success: false,
            res: err
        }
    }
}
//=========================== GET ALL PARTS ======================================

//=========================== CREATE NEW SECTION ======================================
export async function CreateNewSection(token, partObj) {
    try {
        const res = await axios({
            method: 'post',
            url: `${apiConstants.E_LEARNING_API_BASE_URL}/api/test/part`,
            headers: {
                "Authorization": `Bearer ${token}`
            },
            data: {
                part: [
                    {
                        part: Number(partObj.part),
                        part_type: partObj.part_type,
                        version: partObj.version,
                        title: partObj.title,
                        description: partObj.description,
                        sections: partObj.sections
                    }
                ]
            }
        });

        console.log(res);

        return {
            success: true,
            message: "CREATED"
        }
    } catch (err) {
        console.log(err);
        return {
            success: false,
            message: err
        }
    }
}
//=========================== CREATE NEW SECTION ======================================