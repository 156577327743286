//React
import { Component } from 'react';
import { compose } from 'recompose';

//Material UI
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Skeleton from '@material-ui/lab/Skeleton';

//react-router-dom
import {
    Link
} from "react-router-dom";

//Others
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/react";
import { GetAllTeachers } from '../../../../services/teacherServices';

const axios = require('axios').default;
const FileDownload = require('js-file-download');

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%'
    }
});

class AvailableSchedules extends Component {
    constructor() {
        super();
        this.state = {
            clickedAssign: false,
            numberOfTest: 0,
            events: []
        };
    }

    componentDidMount() {
        this.handleGetAllTeacher();
        this.handleGetMyMeeting();
    }

    capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    handleGetAllTeacher = async () => {
        const res = await GetAllTeachers();

        if (res.success) {
            console.log(res.res);
        }
    }

    handleGetMyMeeting = async () => {
        try {
            const res = await axios({
                method: 'get',
                url: 'https://steep-legend-midnight.glitch.me/meetings'
            });

            const resData = res.data.data.filter((schedule) => new Date(schedule.start) > new Date());

            let scheduleArr = [];
            await resData.map(schedule => {
                scheduleArr.push({
                    id: schedule._id,
                    title: schedule.schedule.topic,
                    start: schedule.schedule.start_time,
                    end: new Date(schedule.schedule.start_time) + (30 * 60000),
                    overlap: false,
                    editable: false
                })
            })

            this.setState({
                schedules: resData,
                numberOfTest: resData.length <= 10 ? resData.length : 10,
                events: scheduleArr
            });

            console.log(this.state.events);

        } catch (err) {
            console.log(err);
        }
    }

    handleSeeMoreTest = () => {
        if (this.state.numberOfTest + 10 >= this.state.schedules.length) {
            this.setState({
                numberOfTest: this.state.schedules.length
            });

            return;
        }

        this.setState({
            numberOfTest: this.state.numberOfTest + 10
        });
    }

    handleSeeLessTest = () => {
        if (this.state.numberOfTest - 10 <= 10) {
            this.setState({
                numberOfTest: 10
            });

            return;
        }

        this.setState({
            numberOfTest: this.state.numberOfTest - 10
        });
    }

    render() {
        const { classes } = this.props;

        return (
            <Grid item xs={12} sm={9}>
                <Grid container>
                    <Grid item xs={12}>
                        <FullCalendar
                            plugins={[dayGridPlugin, interactionPlugin]}
                            defaultView="dayGridMonth"
                            timeZone="Asia/Bangkok"
                            rerenderDelay={10}
                            eventDurationEditable={false}
                            editable={false}
                            droppable={true}
                            events={this.state.events}
                        />
                    </Grid>
                    <Grid item align='left' xs={12} style={{ marginTop: '50px' }}>
                        <Typography variant="h5" gutterBottom>
                            <Box fontWeight="fontWeightBold" display='inline'>Teacher schedules</Box>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ overflowY: 'auto', maxHeight: '500px' }}>
                        <Paper variant="outlined">
                            {this.state.schedules !== undefined && (this.state.schedules?.length === 0 &&
                                <Typography>
                                    No available schedules.
                                </Typography>
                            )}

                            {this.state.schedules !== undefined ? (this.state.schedules.sort((a, b) => new Date(a.createdAt) < new Date(b.schedule.start_time) ? 1 : -1).map((schedule, index) =>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Grid container spacing={0}>
                                            <Grid align='left' item xs={12} sm>
                                                <Typography>
                                                    {schedule.schedule.topic}
                                                </Typography>
                                            </Grid>
                                            <Grid align='right' item xs={12} sm>
                                                <Chip
                                                    label={new Date(schedule.schedule.start_time).toDateString()}
                                                    style={{ margin: '5px' }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container spacing={2}>
                                            <Grid align='right' item xs={6} sm={3}>
                                                <Typography>
                                                    <Box fontWeight="fontWeightBold" display='inline'>Tester:</Box>
                                                </Typography>
                                                <Typography>
                                                    <Box fontWeight="fontWeightBold" display='inline'>Schedule at:</Box>
                                                </Typography>
                                                <Typography>
                                                    <Box fontWeight="fontWeightBold" display='inline'>Duration:</Box>
                                                </Typography>
                                                <Typography>
                                                    <Box fontWeight="fontWeightBold" display='inline'>Test category:</Box>
                                                </Typography>
                                                <Typography>
                                                    <Box fontWeight="fontWeightBold" display='inline'>Type:</Box>
                                                </Typography>
                                                <Typography>
                                                    <Box fontWeight="fontWeightBold" display='inline'>Teacher:</Box>
                                                </Typography>
                                            </Grid>
                                            <Grid align='left' item xs={6} sm={9}>
                                                <Typography>
                                                    {schedule.submitter}
                                                </Typography>
                                                <Typography>
                                                    {new Date(schedule.schedule.start_time).toString()}
                                                </Typography>
                                                <Typography>
                                                    {schedule.schedule.duration} minutes
                                                </Typography>
                                                <Typography>
                                                    {schedule.testCategory}
                                                </Typography>
                                                <Typography>
                                                    {schedule.testType}
                                                </Typography>
                                                <Typography>
                                                    {this.props.userInfo.name}
                                                </Typography>
                                            </Grid>
                                            <Grid align='right' item xs={12}>
                                                <Grid container spacing={2} justify='right'>
                                                    <Grid item xs={12}>
                                                        <Link
                                                            to={"/test/speaking/meeting-id/" + schedule.schedule.id + "/password/" + schedule.schedule.password + "/role/1"}
                                                            underline="none"
                                                            style={{ textDecoration: 'none', color: '#880000' }}
                                                        >
                                                            <Button
                                                                color="primary"
                                                                variant="contained"
                                                            >
                                                                Start Meeting
                                                            </Button>
                                                        </Link>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            )) : (
                                <Grid>
                                    <Skeleton variant="rect" width="100%" height={80} />
                                </Grid>
                            )}
                        </Paper>
                    </Grid>
                    {/* {<Grid item xs={12}>
                        {this.state.schedules !== undefined && !(this.state.numberOfTest === this.state.schedules.length) &&
                            <Button
                                variant="contained"
                                style={{ width: '100%', marginTop: '20px', backgroundColor: '#76323F', color: 'white' }}
                                onClick={this.handleSeeMoreTest}
                            >
                                See More (+10)
                            </Button>
                        }
                        {this.state.schedules !== undefined && (this.state.numberOfTest === this.state.schedules.length) &&
                            <Button
                                variant="contained"
                                style={{ width: '100%', marginTop: '20px', backgroundColor: '#76323F', color: 'white' }}
                                onClick={this.handleSeeLessTest}
                            >
                                See Less (-10)
                            </Button>
                        }
                    </Grid>} */}
                </Grid>
            </Grid>
        )
    }
}

export default compose(
    withStyles(useStyles)
)(AvailableSchedules);