export const purchasePopup = `<html xmlns="http://www.w3.org/1999/xhtml" xml:lang="en" lang="en" style="
max-height: 300px;
">
<head>
  <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
  <title>
    Pop texts after purchase (7-day speaking and 90-day expiration policies)
    [May 25, 2023]
  </title>
  <style type="text/css">
    * {
      margin: 0;
      padding: 0;
      text-indent: 0;
    }
    .s1 {
      color: black;
      font-family: Arial, sans-serif;
      font-style: normal;
      font-weight: bold;
      text-decoration: none;
      font-size: 11pt;
    }
    .s2 {
      color: black;
      font-family: "Segoe UI Symbol", sans-serif;
      font-style: normal;
      font-weight: normal;
      text-decoration: none;
      font-size: 13pt;
    }
    .p,
    p {
      color: black;
      font-family: Arial, sans-serif;
      font-style: normal;
      font-weight: normal;
      text-decoration: none;
      font-size: 11pt;
      margin: 0pt;
    }
    table td,
    table th {
      padding: 0;
    }
    .c2 {
      border-right-style: solid;
      padding: 5pt 5pt 5pt 5pt;
      border-bottom-color: #ffffff;
      border-top-width: 1pt;
      border-right-width: 1pt;
      border-left-color: #ffffff;
      vertical-align: top;
      border-right-color: #ffffff;
      border-left-width: 1pt;
      border-top-style: solid;
      border-left-style: solid;
      border-bottom-width: 1pt;
      width: 451.4pt;
      border-top-color: #ffffff;
      border-bottom-style: solid;
    }
    .c6 {
      border-spacing: 0;
      border-collapse: collapse;
      margin-right: auto;
    }
    .c4 {
      height: 0pt;
    }
  </style>
</head>
<body>
  <table class="c6">
    <tr class="c4">
      <td class="c2" colspan="1" rowspan="1">
        <p
          style="
            padding-top: 9pt;
            padding-left: 4pt;
            text-indent: 0pt;
            text-align: left;
          "
        >
          <span class="s2">🗓 </span>Remember, if you have purchased
          <u><b>FULL IELTS with SPK</b></u
          >, please schedule your speaking test
          <u><b>within 7 days of payment to secure your spot.</b></u>
        </p>
        <p
          style="
            padding-top: 9pt;
            padding-left: 4pt;
            text-indent: 0pt;
            text-align: left;
          "
        >
          <span class="s2">⏰ </span>For all other test purchases,
          <u><b>make sure to complete your test within 90 days</b></u
          >.
        </p>
        <p
          class="s2"
          style="
            padding-top: 9pt;
            padding-left: 4pt;
            text-indent: 0pt;
            text-align: left;
          "
        >
          🚫
          <span class="p"
            >Please note that failing to adhere to our policies will result in
            the expiration of your test and the inability to issue
            refunds.</span
          >
        </p>
        <p style="padding-left: 5pt; text-indent: 0pt; text-align: left" />
      </td>
    </tr>
  </table>
</body>
</html>
`;
