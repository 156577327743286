//React
import { Component } from 'react';
import { compose } from 'recompose';

//CSS
import './Paragraph.css';

//Material UI
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

//Other libraries
import { DropTarget } from 'react-drag-drop-container';
import { Markup } from 'interweave';

const axios = require('axios').default;
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%'
    },
    paperRoot: {
        padding: theme.spacing(2),
        textAlign: 'left'
    },
    paperItem: {
        padding: theme.spacing(2),
        textAlign: 'left',
        height: '810px',
        maxHeight: '810px',
        overflow: 'auto',
    },
    testAlignLeft: {
        padding: theme.spacing(2),
    },
    gridContainerRoot: {
        marginTop: 80
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    marginLeft: {
        marginLeft: 20
    },
    marginTop: {
        marginTop: 20
    },
    margin: {
        margin: '0'
    }
});

class DisplayParagraph extends Component {
    constructor() {
        super();
        this.state = {
            textArr: [],
            anchorEl: null,
            anchorElNote: null,
            isOpenPoper: false,
            isOpenNotePoper: false,
            isOpenContextMenu: false,
            selectedText: '',
            selectedTextPos: {},
            buttonText: 'Highlight',
            window: {
                height: window.innerHeight,
                width: window.innerWidth
            }
        };
    }

    componentDidMount = async () => {
        console.log(this.props.currentTestObject);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    handleResize = (event) => {
        this.setState({
            window: {
                height: window.innerHeight,
                width: window.innerWidth
            }
        });
    };

    render() {
        const { classes } = this.props;

        return (
            <Grid container>
                <Grid item xs={12}>
                    {this.props.currentTestObject?.sections[0].question_type !== 'listOfHeading' &&
                        <Grid container>
                            <Grid item xs={12} align='left'>
                                <Typography
                                    style={{ fontSize: this.props.fontSize + 'rem', textAlign: 'justify', fontWeight: 'bold' }}
                                >
                                    {this.props.currentTestObject?.description_title}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} align='left'>
                                <Typography
                                    style={{ fontSize: this.props.fontSize + 'rem', textAlign: 'justify' }}
                                >
                                    <Markup content={this.props.currentTestObject?.description} />
                                </Typography>
                            </Grid>
                        </Grid>
                    }

                    {this.props.currentTestObject?.sections[0].question_type === 'listOfHeading' &&
                        <Grid container>
                            <Grid item xs={12} align='left' style={{ marginBottom: '20px' }}>
                                <Typography
                                    style={{ fontSize: this.props.fontSize + 'rem', textAlign: 'justify', fontWeight: 'bold' }}
                                >
                                     {this.props.currentTestObject?.description_title}
                                </Typography>
                            </Grid>
                            {this.props.currentTestObject?.sections.filter(section => section.question_type === 'listOfHeading')[0].questions.map((question, index) =>
                                <Grid item>
                                    <Typography
                                        style={{ fontSize: this.props.fontSize + 'rem', textAlign: 'justify' }}
                                    >
                                        <Markup content={question.paragraph} />
                                    </Typography>
                                    <DropTarget
                                        targetKey="foo"
                                        onHit={() => {
                                            this.props.onDropped(question.no);
                                        }}
                                    >
                                        <List component="nav" aria-label="main mailbox folders">
                                            <Paper variant="outlined">
                                                <ListItem
                                                    button
                                                    onClick={() => { this.props.onClickAnswer(question.no) }}
                                                >
                                                    <ListItemText align='center' primary={
                                                        <Typography variant="body1">
                                                            {this.props.dropItems === undefined || this.props.dropItems['' + question.no] === undefined ? '' + question.no : this.props.dropItems['' + question.no]}
                                                        </Typography>
                                                    }
                                                    />
                                                </ListItem>
                                            </Paper>
                                        </List>
                                    </DropTarget>
                                </Grid>
                            )}
                        </Grid>
                    }
                </Grid>

            </Grid>
        )
    }
}

export default compose(
    withStyles(useStyles)
)(DisplayParagraph);