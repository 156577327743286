//React
import { Component } from 'react';
import { compose } from 'recompose';

//Material UI
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%'
    }
});

class MultipleChoice extends Component {
    constructor() {
        super();

        this.state = {
            alphabetArr: [
                'A',
                'B',
                'C',
                'D',
                'E',
                'F',
                'G'
            ],
            tempChoices: [
                'A',
                'B',
                'C',
                'D'
            ],
            tempAnsArr: []
        };
    }

    componentDidMount = async () => {

    }

    handleEditQuestionNumber = (event) => {
        this.props.questionObj.no = event.target.value
    }

    handleEditQuestion = (event) => {
        this.props.questionObj.question = event.target.value
    }

    handleEditAnswer = async (event) => {
        this.props.questionObj.answer = await event.target.value.split("-")[1];
        await this.setState({
            answerIdx: Number(event.target.value.split("-")[0])
        });

        console.log(this.props.questionObj, this.state.answerIdx);
    }

    handleEditChoice = (event, index) => {
        this.props.questionObj.choice[index] = event.target.value;
        this.props.questionObj.choice = this.props.questionObj.choice;
    }

    render() {
        const { classes } = this.props;

        return (
            <Grid item xs={12}>
                <Grid container spacing={1}>
                    <Grid item align='left' xs={12} style={{ marginTop: '10px' }}>
                        <Grid container spacing={1}>
                            <Grid item xs={1}>
                                <TextField
                                    label="No."
                                    id="outlined-size-small"
                                    variant="outlined"
                                    size="small"
                                    onChange={this.handleEditQuestionNumber}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={11}>
                                <TextField
                                    label="Question"
                                    id="outlined-size-small"
                                    variant="outlined"
                                    size="small"
                                    onChange={this.handleEditQuestion}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <RadioGroup
                                    onChange={this.handleEditAnswer}
                                    style={{ marginLeft: '20px', marginTop: '10px' }}
                                >
                                    {this.state.tempChoices.map((choice, choiceIdx) =>
                                        <FormControlLabel
                                            control={<Radio selected={this.state.answerIdx ? this.state.answerIdx === choiceIdx : false} color="default" />}
                                            value={`${choiceIdx}-${this.props.questionObj?.choice[choiceIdx]}`}
                                            label={
                                                <Grid container spacing={2}>
                                                    <Grid item align='right' xs style={{ paddingTop: '1.2%' }}>
                                                        <Typography variant="h6" align='left'>
                                                            {choice}.
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs>
                                                        <TextField
                                                            id="outlined-size-small"
                                                            variant="outlined"
                                                            size="small"
                                                            style={{ width: '800px' }}
                                                            onChange={(event) => {this.handleEditChoice(event, choiceIdx)}}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            }
                                        />
                                    )}
                                </RadioGroup>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

export default compose(
    withStyles(useStyles)
)(MultipleChoice);