//React
import React, { Component } from 'react';
import { compose } from 'recompose';

//Material UI
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';

//Constants
import * as styleConstants from "../../../../constants/styleConstants";
import MediaQuery from 'react-responsive';

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%'
    },
    rootContainer: {
        maxWidth: '75%'

    },
    rootCard: {
        maxWidth: "75%",
        width: '75%',
        margin: 10,
    },
    gridContainer: {
        maxWidth: '90%'
    },
    margin: {
        margin: 100
    },
    marginTop: {
        marginTop: 100
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginTop: theme.spacing(5)
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    header: {
        maxWidth: "80%"
    },
    body: {
        maxWidth: "80%",
        marginTop: 30
    },
    divider: {
        maxWidth: "100%"
    },
    image: {
        margin: 'auto',
        display: 'block',
        margin: 10
    },
    commentTextField: {
        width: '100%',
        maxWidth: '100%'
    },
    commentGridItem: {
        maxWidth: "80%",
        marginTop: 30,
        marginBottom: 100
    }
});

const theme = createMuiTheme({
    typography: {
        fontFamily: [
            'Prompt'
        ].join(','),
    },
});


class ContactUsSectionMobile extends Component {
    constructor() {
        super();
        this.state = {
        };
    }

    componentDidMount() {
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    render() {
        const { classes } = this.props;

        return (
            <MediaQuery minWidth={"768px"}>
                {(matches) =>
                    matches ? <Grid item xs={12} style={{ maxWidth: window.innerWidth, zIndex: 1, paddingLeft: "8%", paddingRight: "8%" }}>
                        <Grid id="contactus" container spacing={5}>
                            <Grid item align="center" xs style={{ zIndex: 1 }}>
                                <Grid container>
                                    <Grid item align="left" xs={12}>
                                        <Typography variant="h3" style={{ fontFamily: styleConstants.fontStyle, fontWeight: styleConstants.fontWeight.bold, color: "#991514" }} >
                                            CONTACT US
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} align="left" style={{ marginTop: '5%', zIndex: 1 }}>
                                        <Typography variant="body1" gutterBottom style={{ fontFamily: styleConstants.fontStyle, fontWeight: styleConstants.fontWeight.bold, }}>
                                            Feel like contacting us?
                                        </Typography>
                                        <Typography variant="body1" style={{ fontFamily: styleConstants.fontStyle, fontWeight: styleConstants.fontWeight.thin, }}>
                                            Submit your queries here and we will get back to you as soon as possible.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item align="center" xs={8} style={{ zIndex: 1 }}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <TextField type="input" id="firstname" label="First Name" variant="filled" onChange={this.props.handleChange} fullWidth />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField type="input" id="lastname" label="Last Name" variant="filled" onChange={this.props.handleChange} fullWidth />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField type="input" id="email" label="Email" variant="filled" onChange={this.props.handleChange} fullWidth />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            onChange={this.props.handleChange}
                                            type="input"
                                            id="message"
                                            label="Message"
                                            multiline
                                            fullWidth
                                            rows={6}
                                            variant="filled"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button disabled={this.props.isLoading} disableElevation variant="contained" onClick={this.props.handleSubmit} style={{ backgroundColor: "#991514", color: "white" }}>
                                            Submit
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid> : <Grid item xs={12} style={{ maxWidth: window.innerWidth, zIndex: 1, paddingLeft: "5%", paddingRight: "5%" }}>
                        <Grid id="contactus" container spacing={5}>
                            <Grid item align="center" xs={12} style={{ zIndex: 1 }}>
                                <Grid container>
                                    <Grid item align="center" xs={12}>
                                        <Typography variant="h4" style={{ fontFamily: styleConstants.fontStyle, fontWeight: styleConstants.fontWeight.bold, color: "#991514" }} >
                                            CONTACT US
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} align="center" style={{ marginTop: '5%', zIndex: 1 }}>
                                        <Typography variant="body1" style={{ fontFamily: styleConstants.fontStyle, fontWeight: styleConstants.fontWeight.thin, }}>
                                            Feel like contacting us? Submit your queries here and we will get back to you as soon as possible.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item align="center" xs={12} style={{ zIndex: 1 }}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <TextField type="input" id="firstname" label="First Name" variant="filled" onChange={this.props.handleChange} fullWidth />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField type="input" id="lastname" label="Last Name" variant="filled" onChange={this.props.handleChange} fullWidth />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField type="input" id="email" label="Email" variant="filled" onChange={this.props.handleChange} fullWidth />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            onChange={this.props.handleChange}
                                            type="input"
                                            id="message"
                                            label="Message"
                                            multiline
                                            fullWidth
                                            rows={6}
                                            variant="filled"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button disabled={this.props.isLoading} disableElevation variant="contained" onClick={this.props.handleSubmit} style={{ backgroundColor: "#991514", color: "white" }}>
                                            Submit
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </MediaQuery>
        )
    }
}

export default compose(
    withStyles(useStyles)
)(ContactUsSectionMobile);
