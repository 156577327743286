//React
import { Component } from 'react';
import { compose } from 'recompose';

//Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

//Icon

//react-router-dom

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%'
    }
});

class SectionTwoInstructions extends Component {
    constructor() {
        super();
    }

    render() {
        return (
            <Grid container justify="center" style={{ padding: "20%", paddingTop: '20px', paddingBottom: '0px' }}>
                <Paper variant="outlined" style={{
                    padding: '50px',
                    backgroundImage: `url("/U-Test-Logo-new-bw.png")`,
                    backgroundSize: '120px',
                    backgroundRepeat: 'space'
                }}>
                    <Grid container>
                        <Grid item align='right' xs={12}>
                            <CardMedia
                                component="img"
                                alt="Contemplative Reptile"
                                src="/U Test Logo (new).png"
                                title="Contemplative Reptile"
                                style={{ width: '70px' }}
                            />
                            <Typography variant="h6">
                                <Box fontWeight="fontWeightBold" display='inline'> 15 minutes </Box>
                            </Typography>
                            <Typography variant="h6" gutterBottom>
                                <Box fontWeight="fontWeightBold" display='inline'> Scientific Knowledge and Applications </Box>
                            </Typography>
                        </Grid>
                        <Grid item align='left' xs={12}>
                        <Typography variant="h5" gutterBottom>
                                <Box fontWeight="fontWeightBold" display='inline'> BioMedical Admissions Simulation Test </Box>
                            </Typography>
                            <Typography variant="body1" style={{ fontWeight: 'bold' }} gutterBottom>
                                SECTION 2
                            </Typography>
                            <Typography variant="body1" style={{ fontWeight: 'bold' }} gutterBottom>
                                INSTRUCTIONS TO CANDIDATES
                            </Typography>
                            <Typography variant="body1" style={{ fontWeight: 'bold' }} gutterBottom>
                                Please read this page carefully; once you click ‘start’, the timer will begin.
                            </Typography>
                        </Grid>
                        <Grid item align='left' xs={12} style={{ marginTop: '20px' }}>
                            <Typography variant="body1" gutterBottom>
                                Speed as well as accuracy is important in this section. <span style={{ fontWeight: 'bold' }}>Work quickly, or you might not finish the section.</span>
                                There are no penalties for incorrect answers, only marks for correct answers. Each question is worth one mark.
                                So you should attempt all 8 questions.
                            </Typography>
                        </Grid>
                        <Grid item align='left' xs={12} style={{ marginTop: '20px' }}>
                            <Typography variant="body1" gutterBottom>
                                Questions ask you to answer by clicking on one of the choices.
                                If you make a mistake, you may click on another choice to change to answer
                            </Typography>
                        </Grid>
                        <Grid item align='left' xs={12} style={{ marginTop: '20px' }}>
                            <Typography variant="body1" gutterBottom>
                                You <span style={{ fontWeight: 'bold' }}>must</span> complete the section within the time limit.
                            </Typography>
                        </Grid>
                        <Grid item align='left' xs={12} style={{ marginTop: '20px' }}>
                            <Typography variant="body1" gutterBottom>
                                You can use the question paper for rough working or notes, but Calculators are NOT permitted.
                            </Typography>
                        </Grid>
                        <Grid item align='left' xs={12} style={{ marginTop: '20px' }}>
                            <Typography variant="body1" gutterBottom>
                                Copyright &reg; UTEST AVENUE 2020
                            </Typography>
                        </Grid>
                        <Grid item align='right' xs={12} style={{ marginTop: '20px' }}>
                            <Button size="small" variant="contained" onClick={this.props.handleCloseSectionTwoInfoPage} style={{ color: 'white', backgroundColor: '#214b4b' }}>
                                <Box fontWeight="fontWeightBold" display='inline'> Start </Box>
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        )
    }
}

export default compose(
    withStyles(useStyles)
)(SectionTwoInstructions);
