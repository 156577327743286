import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import React from 'react';

class TextEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            updatedObj: {}
        };
    }

    render() {
        return (
            <CKEditor
                disabled
                editor={ClassicEditor}
                data={this.props.notes}
                config={{
                    toolbar: []
                }}
                onReady={editor => {
                    // You can store the "editor" and use when it is needed.
                    console.log('Editor is ready to use!', editor);
                    editor.editing.view.change((writer) => {
                        writer.setStyle(
                            "height",
                            "300px",
                            editor.editing.view.document.getRoot()
                        );
                    });
                }}
                onChange={(event, editor) => {
                    const data = editor.getData();
                    console.log({ event, editor, data });
                }}
            />
        );
    }
}

export default TextEditor;