import React, { useEffect } from 'react';

//MUI
import Container from '@material-ui/core/Container';
import Hidden from "@material-ui/core/Hidden";
import { ThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';


//Font
import 'typeface-glacial-indifference';
import { Grid, Typography } from '@material-ui/core';

const useStyles = theme => ({
    root: {
        padding: theme.spacing(12, 4),
    }
});

const theme = createMuiTheme({
    typography: {
        fontFamily: [
            'Roboto'
        ].join(','),
    },
});

const RefundPolicy = () => {
    useEffect(() => {
        document.body.style.overflow = "auto";
        document.body.style.backgroundColor = "#efefef";
        document.querySelector("body").scrollTo(0, 0);
    }, []);

    const mobileUI = (
        <Grid item xs={12} style={{ maxWidth: window.innerWidth, zIndex: 1, padding: "20px" }}>
            <Grid container spacing={2} style={{ marginTop: "10%", marginBottom: "10%" }}>
                {/* ==================================== Refund Policy ==================================== */}
                <Grid item xs={12}>
                    <Typography variant="h4" style={{ fontWeight: "bold" }}>
                        REFUND POLICY
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="body1">
                        We have a 30-day return policy, which means you have 30 days after you have purchased and have not started or completed the products and/or services to request a refund.
                    </Typography>
                    <Typography variant="body1">
                        To be eligible for a return, the products and/or services must not be started or completed. You will also need the receipt or proof of purchase.
                    </Typography>
                    <Typography variant="body1">
                        To request a refund, you can contact us at contact@utestavenue.com.
                    </Typography>
                    <Typography variant="body1">
                        If your refund is accepted, we will send you a confirmation email, as well as instructions on how and where to receive your refund.
                    </Typography>
                    <Typography variant="body1">
                        You can always contact us for any return questions at contact@utestavenue.com.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold", paddingLeft: "30px" }}>
                        1. Started and partially completed products or services
                    </Typography>
                    <Typography variant="body1">
                        Please note that once you start the simulation tests, you agree to comply with our terms and conditions. Hence, started but incomplete or partially complete simulation tests cannot be refunded.
                    </Typography>
                    <Typography variant="body1">
                        We also do not provide a refund to the users who fail to show up on their self-selected speaking schedule. However, please get in touch if you have questions or concerns about your specific conditions.
                    </Typography>
                    <Typography variant="body1">
                        Unfortunately, gift cards or rewards for free access to the Full Simulation Tests cannot be exchanged for money.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold", paddingLeft: "30px" }}>
                        2. Exchanges
                    </Typography>
                    <Typography variant="body1">
                        Within 30 days of your purchase, you have the right to cancel or request a refund. You will also need the receipt or proof of purchase.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold", paddingLeft: "30px" }}>
                        3. Refunds
                    </Typography>
                    <Typography variant="body1">
                        We will notify you once we have received your request and let you know if the refund was approved or not. If approved, you will be automatically refunded on your original payment method within 10 business days. However, please remember that it can take time for your bank or credit card company to process and post the refund.
                    </Typography>
                    <Typography variant="body1">
                        If more than 15 business days have passed since we approved your return, please contact us at contact@utestavenue.com.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold", paddingLeft: "30px" }}>
                        4. Contact Us
                    </Typography>
                    <Typography variant="body1">
                        If you have any questions, please contact us by email: contact@utestavenue.com.
                    </Typography>
                </Grid>
                {/* ==================================== Refund Policy ==================================== */}
            </Grid>
        </Grid>
    )

    const webUI = (
        <Container>
            <Grid container spacing={2} style={{ marginTop: "10%", marginBottom: "10%" }}>
                {/* ==================================== Refund Policy ==================================== */}
                <Grid item xs={12}>
                    <Typography variant="h4" style={{ fontWeight: "bold" }}>
                        REFUND POLICY
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="body1">
                        We have a 30-day return policy, which means you have 30 days after you have purchased and have not started or completed the products and/or services to request a refund.
                    </Typography>
                    <Typography variant="body1">
                        To be eligible for a return, the products and/or services must not be started or completed. You will also need the receipt or proof of purchase.
                    </Typography>
                    <Typography variant="body1">
                        To request a refund, you can contact us at contact@utestavenue.com.
                    </Typography>
                    <Typography variant="body1">
                        If your refund is accepted, we will send you a confirmation email, as well as instructions on how and where to receive your refund.
                    </Typography>
                    <Typography variant="body1">
                        You can always contact us for any return questions at contact@utestavenue.com.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold", paddingLeft: "30px" }}>
                        1. Started and partially completed products or services
                    </Typography>
                    <Typography variant="body1">
                        Please note that once you start the simulation tests, you agree to comply with our terms and conditions. Hence, started but incomplete or partially complete simulation tests cannot be refunded.
                    </Typography>
                    <Typography variant="body1">
                        We also do not provide a refund to the users who fail to show up on their self-selected speaking schedule. However, please get in touch if you have questions or concerns about your specific conditions.
                    </Typography>
                    <Typography variant="body1">
                        Unfortunately, gift cards or rewards for free access to the Full Simulation Tests cannot be exchanged for money.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold", paddingLeft: "30px" }}>
                        2. Exchanges
                    </Typography>
                    <Typography variant="body1">
                        Within 30 days of your purchase, you have the right to cancel or request a refund. You will also need the receipt or proof of purchase.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold", paddingLeft: "30px" }}>
                        3. Refunds
                    </Typography>
                    <Typography variant="body1">
                        We will notify you once we have received your request and let you know if the refund was approved or not. If approved, you will be automatically refunded on your original payment method within 10 business days. However, please remember that it can take time for your bank or credit card company to process and post the refund.
                    </Typography>
                    <Typography variant="body1">
                        If more than 15 business days have passed since we approved your return, please contact us at contact@utestavenue.com.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold", paddingLeft: "30px" }}>
                        4. Contact Us
                    </Typography>
                    <Typography variant="body1">
                        If you have any questions, please contact us by email: contact@utestavenue.com.
                    </Typography>
                </Grid>
                {/* ==================================== Refund Policy ==================================== */}
            </Grid>
        </Container>
    )

    return (
        <ThemeProvider theme={theme}>
            <Hidden mdUp>{mobileUI}</Hidden>
            <Hidden smDown>{webUI}</Hidden>
        </ThemeProvider>
    )
};

export default compose(
    withStyles(useStyles)
)(RefundPolicy);
