//React
import React, { Component } from 'react';
import { compose } from 'recompose';

//Material UI
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import FavoriteRoundedIcon from '@material-ui/icons/FavoriteRounded';
import ShareRoundedIcon from '@material-ui/icons/ShareRounded';
import Hidden from '@material-ui/core/Hidden';

import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';

//react-router-dom
import {
    Link
} from "react-router-dom";
import { Paper } from '@material-ui/core';

//Others
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import LoadingAnimation from './new/components/blogsPage/Pulse-1s-200px.svg';
import { ThreeSixtyOutlined } from '@material-ui/icons';
const axios = require('axios').default;

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%'
    },
    rootContainer: {
        maxWidth: '75%'

    },
    rootCard: {
        maxWidth: "75%",
        width: '75%',
        margin: 10,
    },
    gridContainer: {
        maxWidth: '90%'
    },
    margin: {
        margin: 100
    },
    marginTop: {
        marginTop: 50
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginTop: theme.spacing(5)
    },
    media: {
        height: 0,
        paddingTop: '100%', // 16:9
    },
    header: {
        maxWidth: "80%"
    },
    body: {
        maxWidth: "80%",
        marginTop: 30
    },
    divider: {
        maxWidth: "100%"
    },
    image: {
        margin: 'auto',
        display: 'block',
        margin: 10
    },
    commentTextField: {
        width: '100%',
        maxWidth: '100%'
    },
    commentGridItem: {
        maxWidth: "80%",
        marginTop: 30,
        marginBottom: 100
    }
});

const theme = createMuiTheme({
    typography: {
        fontFamily: [
            'Roboto'
        ].join(','),
    },
});


class Blog extends Component {
    constructor() {
        super();
        this.state = {
            isSearch: false,
            isSubmitting: false
        };
        this.handleSearch = this.handleSearch.bind(this);
    }

    componentDidMount() {
        this.handleGetBlogById();
        this.handleGetBlogCommentsById();
        document.body.style.overflow = "auto";
        document.body.style.backgroundColor = "#efefef";
        document.querySelector("body").scrollTo(0, 0);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    handleResize = (event) => {
        this.setState({
            window: {
                height: window.innerHeight,
                width: window.innerWidth
            }
        });
    };

    handleSearch() {
        this.setState({
            isSearch: true
        });
    }

    handleGetBlogById = async () => {
        try {
            const res = await axios({
                method: 'get',
                url: 'https://erratic-noiseless-wasabi.glitch.me/utestavenue-system-api/api/v1/blogs/id/' + this.props.match.params.id,
            });

            console.log(res);

            this.setState({
                blog: res.data
            });

        } catch (err) {
            console.log(err);
        }
    }

    handleGetBlogCommentsById = async () => {
        try {
            const res = await axios({
                method: 'get',
                url: 'https://erratic-noiseless-wasabi.glitch.me/utestavenue-system-api/api/v1/blogs/id/' + this.props.match.params.id + '/comments',
            });

            this.setState({
                comments: res.data.comments
            });

        } catch (err) {
            console.log(err);
        }
    }

    handlePostBlogCommentsById = async () => {
        this.setState({
            isSubmitting: true
        });

        if (this.state.inputComment === undefined || this.state.inputComment.length === 0) {
            return;
        }

        try {
            await axios({
                method: 'post',
                url: 'https://erratic-noiseless-wasabi.glitch.me/utestavenue-system-api/api/v1/blogs/id/' + this.props.match.params.id + '/comments/submit',
                data: {
                    submitterUsername: this.props.user.username,
                    submitter: this.props.user.name,
                    submitterImg: this.props.user.picture,
                    comment: this.state.inputComment
                }
            });

            await this.handleGetBlogCommentsById();

            this.setState({
                inputComment: '',
                isSubmitting: false
            });
        } catch (err) {
            console.log(err);
        }
    }

    handleInputComment = (event) => {
        this.setState({
            inputComment: event.target.value
        })
    }

    render() {
        const { classes } = this.props;

        const mobileUI = (
            <Grid item xs={12} style={{ maxWidth: window.innerWidth, zIndex: 1, marginBottom: "10%" }}>
                <Grid container justify='center'>
                    <Grid item align='center' xs={12} className={classes.marginTop}>
                        <Grid container justify='center' alignItems="center" className={classes.gridContainer}>
                            <Grid item xs={12} align='left'>
                                <Link
                                    to="/blogs"
                                    className={classes.title}
                                    underline="none"
                                    style={{ textDecoration: 'none', color: '#565656' }}
                                    onClick={this.handleDrawerClose}
                                    display='inline'
                                >
                                    <Typography variant="subtitle1" gutterBottom>
                                        All blogs
                                    </Typography>
                                </Link>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} align='center' className={classes.marginTop}>
                        {this.state.blog !== undefined &&
                            <Grid item xs={12} align='left' className={classes.header}>
                                <Typography variant="overline" color='textSecondary' display="block" gutterBottom>
                                    Create at {new Date(this.state.blog.createdAt).toDateString()} - by {this.state.blog.writerName}
                                </Typography>
                            </Grid>
                        }
                        {/*===================== TOPIC =========================*/}
                        {this.state.blog !== undefined &&
                            <Grid item xs={12} align='left' className={classes.header}>
                                <Typography variant="h3" gutterBottom>
                                    {this.state.blog.heading}
                                </Typography>
                                <Typography variant="subtitle1" gutterBottom>
                                    {this.state.blog.subheading}
                                </Typography>
                            </Grid>
                        }
                        {/*===================== HEADER IMAGE =========================*/}
                        {this.state.blog !== undefined &&
                            <Grid item xs={12} align='center'>
                                <Card className={classes.rootCard}>
                                    <CardMedia
                                        className={classes.media}
                                        image={this.state.blog.bigPic}
                                        title="Contemplative Reptile"
                                    />
                                </Card>
                            </Grid>
                        }
                        {/*===================== BODY =========================*/}
                        {this.state.blog !== undefined &&
                            <Grid item xs={12} align='left' className={classes.body}>
                                <Typography variant="body1" gutterBottom>
                                    <Box fontWeight="fontWeightBold" display='inline'>{this.state.blog.bodyHeading}</Box>
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    {this.state.blog.body.split("\\n").map((i, key) => {
                                        return <p key={key}>{i}</p>;
                                    })}
                                </Typography>
                            </Grid>
                        }

                        {this.state.blog === undefined && <CircularProgress size={100} style={{ padding: '200px', color: '#76323F' }} />}

                        <Grid item xs={12} align='center' className={classes.body}>
                            <Divider className={classes.divider} />
                        </Grid>
                        <Grid item xs={12} align='left' className={classes.body}>
                            <IconButton aria-label="add to favorites">
                                <FavoriteRoundedIcon />
                            </IconButton>
                            <IconButton aria-label="share">
                                <ShareRoundedIcon />
                            </IconButton>
                        </Grid>
                        <Grid item xs={12} align='left' className={classes.body}>
                            <Grid container justify='center'>
                                <Grid item xs={12}>
                                    <Typography variant="h6" gutterBottom>
                                        Comments
                                    </Typography>
                                </Grid>
                                <Grid item align='center' xs={12}>
                                    <Divider className={classes.divider} />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} className={classes.commentGridItem} style={{ marginBottom: 50 }}>
                            <Grid container justify='center' spacing={1} alignItems="flex-end">
                                <Grid item xs>
                                    {(this.props.userSignedin && this.props.user.picture.length !== 0) &&
                                        <Avatar alt="Remy Sharp" src={this.props.user.picture} className={classes.image} />
                                    }
                                    {(this.props.userSignedin && this.props.user.picture.length === 0) &&
                                        <Avatar style={{ margin: 10 }}>
                                            {this.props.user.firstNameChar}
                                        </Avatar>
                                    }
                                    {(!this.props.userSignedin) &&
                                        <Avatar style={{ margin: 10 }}>
                                        </Avatar>
                                    }
                                </Grid>
                                <Grid item xs={9}>
                                    <TextField
                                        id="outlined-multiline-flexible"
                                        label="Write a comment.."
                                        variant="outlined"
                                        multiline
                                        disabled={this.state.isSubmitting}
                                        rowsMax={5}
                                        value={this.state.inputComment}
                                        onChange={this.handleInputComment}
                                        className={classes.commentTextField}
                                    />
                                </Grid>
                                <Grid item xs={12} align="right">
                                    {!this.state.isSubmitting ?
                                        (<Button disableElevation variant="contained" onClick={this.handlePostBlogCommentsById} style={{ textTransform: 'none', height: '30px', backgroundColor: '#76323F', color: 'white' }}>Post</Button>) :
                                        (<CircularProgress style={{ color: '#76323F' }} />)
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                        <Divider variant="middle" />
                        {this.state.comments !== undefined && this.state.comments.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).map((comment) =>
                            <Grid item xs={12} style={{ maxWidth: "80%", marginTop: 30 }}>
                                <Grid container justify='center' spacing={1} alignItems="flex-end">
                                    <Grid item xs>
                                        {(comment.submitterImg.length !== 0) &&
                                            <Avatar style={{ margin: 10 }}>
                                                {comment.submitter[0]}
                                            </Avatar>
                                        }
                                        {(comment.submitterImg.length === 0) &&
                                            <Avatar style={{ margin: 10 }}>
                                                {comment.submitter[0]}
                                            </Avatar>
                                        }
                                    </Grid>
                                    <Grid item align='left' xs={9}>
                                        <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                                            {comment.comment}
                                        </Typography>
                                        <Typography variant="caption" display="block">
                                            {comment.submitter} - {new Date(comment.createdAt).toDateString()}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        )

        const webUI = (
            <Container align='center' style={{ marginBottom: '200px', marginTop: '50px' }}>
                <Grid item align="left" xs={12} style={{ marginBottom: "30px" }}>
                    <Link to={"/blogs"} style={{ textDecoration: 'none', color: '#0f0f0f' }}>
                        <Button startIcon={<ArrowBackIcon />} style={{ textTransform: "none" }}>
                            All Blogs
                        </Button>
                    </Link>
                </Grid>
                {this.state.blog !== undefined ? <Grid container spacing={3}>
                    <Grid item xs={9}>
                        <Grid container>
                            <Grid item align="left" xs={12}>
                                <Typography variant="h4" style={{ fontWeight: 500 }}>
                                    {this.state.blog.heading}
                                </Typography>
                            </Grid>
                            <Grid item align="left" xs={12}>
                                <Typography variant="body1" style={{ fontWeight: 500 }}>
                                    {this.state.blog.subheading}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} style={{ marginTop: "20px", marginBottom: "20px" }}>
                                <Card style={{ maxWidth: "70%", width: '70%', margin: 10 }}>
                                    <CardMedia
                                        image={this.state.blog.bigPic}
                                        title="Image"
                                        style={{ height: 0, paddingTop: '100%', }}
                                    />
                                </Card>
                            </Grid>
                            <Grid item align="left" xs={12}>
                                <Typography variant="h6" style={{ fontWeight: 500, marginBottom: "30px" }}>
                                    {this.state.blog.bodyHeading}
                                </Typography>
                                {this.state.blog.type === 'Blogcast' ? <Typography variant="body1" style={{ marginBottom: "15px" }}>
                                    <div dangerouslySetInnerHTML={{ __html: this.state.blog.body }} style={{ lineHeight: 1.4 }} />
                                </Typography> : this.state.blog.body.split("\\n").map((i, key) => {
                                    return <Typography variant="body1" style={{ marginBottom: "15px", textAlign: "justify" }}>{i}</Typography>;
                                })}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={3}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Paper style={{ padding: "20px" }}>
                                    <Grid container spacing={2}>
                                        <Grid item align="left" xs={12}>
                                            <Typography variant="body2" style={{ fontWeight: 500 }}>
                                                {this.state.blog.heading}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Card>
                                                <CardMedia
                                                    image={this.state.blog.smallPic}
                                                    title="Image"
                                                    style={{ height: 0, paddingTop: '100%', }}
                                                />
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <AudioPlayer
                                                autoPlay={false}
                                                src={this.state.blog.type === 'Blogcast' ? this.state.blog.sound : "http://example.com/audio.mp3"}
                                                volume={0.5}
                                            />
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: "30px" }}>
                        <Divider />
                    </Grid>
                    <Grid item align="left" xs={12}>
                        <Typography variant="h5" style={{ fontWeight: 600 }}>
                            Comments
                        </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ paddingLeft: "30px" }}>
                        {this.state.comments !== undefined && this.state.comments.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).map((comment) =>
                            <Grid item xs={12}>
                                <Grid container alignItems='center' spacing={3}>
                                    <Grid item align="left">
                                        {(comment.submitterImg.length !== 0) &&
                                            <Avatar >
                                                {comment.submitter[0]}
                                            </Avatar>
                                        }
                                        {(comment.submitterImg.length === 0) &&
                                            <Avatar>
                                                {comment.submitter[0]}
                                            </Avatar>
                                        }
                                    </Grid>
                                    <Grid item align='left' xs={10}>
                                        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                                            {comment.comment}
                                        </Typography>
                                        <Typography variant="caption" display="block" style={{ color: "grey" }}>
                                            {comment.submitter} - {new Date(comment.createdAt).toDateString()}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                    <Grid item xs={8} style={{ paddingLeft: "30px" }}>
                        <Grid container spacing={3} alignItems='center'>
                            <Grid item >
                                <Avatar>
                                    {this.props.user.name[0]}
                                </Avatar>
                            </Grid>
                            <Grid item xs>
                                <TextField fullWidth size="small" type="input" variant="outlined" value={this.state.inputComment} onChange={this.handleInputComment} style={{ backgroundColor: "white", borderRadius: "10px" }} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item align="right" xs={8}>
                        <Button disableElevation disabled={this.state.isSubmitting} variant="outlined" onClick={this.handlePostBlogCommentsById} style={{ textTransform: "none" }}>
                            <Typography variant="body1" style={{ color: "grey" }}>
                                {this.state.isSubmitting ? <CircularProgress size={15} style={{ color: "grey" }} /> : "Comment"}
                            </Typography>
                        </Button>
                    </Grid>
                </Grid> : <img src={LoadingAnimation} alt="Loading..." style={{ width: "100px" }} />}
            </Container>
        )

        // const webUI = (
        //     <Container align='center' maxWidth={false} style={{ marginBottom: '300px' }}>
        //         <Grid container justify='center' className={classes.rootContainer}>
        //             <Grid item align='center' xs={12} className={classes.marginTop}>
        //                 <Grid container justify='center' className={classes.gridContainer}>
        //                     <Grid item xs={6} align='left'>
        //                         <Link
        //                             to="/blogs"
        //                             className={classes.title}
        //                             underline="none"
        //                             style={{ textDecoration: 'none', color: '#565656' }}
        //                             onClick={this.handleDrawerClose}
        //                             display='inline'
        //                         >
        //                             <Typography variant="subtitle1" gutterBottom>
        //                                 All blogs
        //                                 </Typography>
        //                         </Link>
        //                     </Grid>
        //                     <Grid item xs={6} align='right'>
        //                         <Input placeholder="search" inputProps={{ 'aria-label': 'description', style: { textAlign: 'left' } }} />
        //                     </Grid>
        //                 </Grid>
        //             </Grid>
        //             <Grid item xs={12} align='center' className={classes.marginTop}>
        //                 {this.state.blog !== undefined &&
        //                     <Grid item xs={12} align='left' className={classes.header}>
        //                         <Typography variant="overline" color='textSecondary' display="block" gutterBottom>
        //                             Create at {new Date(this.state.blog.createdAt).toDateString()} - by {this.state.blog.writerName}
        //                         </Typography>
        //                     </Grid>
        //                 }
        //                 {/*===================== TOPIC =========================*/}
        //                 {this.state.blog !== undefined &&
        //                     <Grid item xs={12} align='left' className={classes.header}>
        //                         <Typography variant="h3" gutterBottom>
        //                             {this.state.blog.heading}
        //                         </Typography>
        //                         <Typography variant="subtitle1" gutterBottom>
        //                             {this.state.blog.subheading}
        //                         </Typography>
        //                     </Grid>
        //                 }
        //                 {/*===================== HEADER IMAGE =========================*/}
        //                 {this.state.blog !== undefined &&
        //                     <Grid item xs={12} align='center'>
        //                         <Card className={classes.rootCard}>
        //                             <CardMedia
        //                                 className={classes.media}
        //                                 image={this.state.blog.bigPic}
        //                                 title="Contemplative Reptile"
        //                             />
        //                         </Card>
        //                     </Grid>
        //                 }
        //                 {/*===================== BODY =========================*/}
        //                 {this.state.blog !== undefined &&
        //                     <Grid item xs={12} align='left' className={classes.body}>
        //                         <Typography variant="body1" gutterBottom>
        //                             <Box fontWeight="fontWeightBold" display='inline'>{this.state.blog.bodyHeading}</Box>
        //                         </Typography>
        //                         <Typography variant="body1" gutterBottom>
        //                             {this.state.blog.body.split("\\n").map((i, key) => {
        //                                 return <p key={key}>{i}</p>;
        //                             })}
        //                         </Typography>
        //                     </Grid>
        //                 }

        //                 {this.state.blog === undefined && <CircularProgress size={100} style={{ padding: '200px', color: '#76323F' }} />}

        //                 <Grid item xs={12} align='center' className={classes.body}>
        //                     <Divider className={classes.divider} />
        //                 </Grid>
        //                 <Grid item xs={12} align='left' className={classes.body}>
        //                     <IconButton aria-label="add to favorites">
        //                         <FavoriteRoundedIcon />
        //                     </IconButton>
        //                     <IconButton aria-label="share">
        //                         <ShareRoundedIcon />
        //                     </IconButton>
        //                 </Grid>
        //                 <Grid item xs={12} align='left' className={classes.body}>
        //                     <Grid container justify='center'>
        //                         <Grid item xs={12}>
        //                             <Typography variant="h6" gutterBottom>
        //                                 Comments
        //                                 </Typography>
        //                         </Grid>
        //                         <Grid item align='center' xs={12}>
        //                             <Divider className={classes.divider} />
        //                         </Grid>
        //                     </Grid>
        //                 </Grid>

        //                 <Grid item xs={12} className={classes.commentGridItem}>
        //                     <Grid container justify='center' spacing={1} alignItems="flex-end">
        //                         <Grid item xs>
        //                             {(this.props.userSignedin && this.props.user.picture.length !== 0) &&
        //                                 <Avatar alt="Remy Sharp" src={this.props.user.picture} className={classes.image} />
        //                             }
        //                             {(this.props.userSignedin && this.props.user.picture.length === 0) &&
        //                                 <Avatar style={{ margin: 10 }}>
        //                                     {this.props.user.firstNameChar}
        //                                 </Avatar>
        //                             }
        //                             {(!this.props.userSignedin) &&
        //                                 <Avatar style={{ margin: 10 }}>
        //                                 </Avatar>
        //                             }
        //                         </Grid>
        //                         <Grid item xs={9}>
        //                             <TextField
        //                                 id="outlined-multiline-flexible"
        //                                 label="Write a comment.."
        //                                 variant="outlined"
        //                                 multiline
        //                                 disabled={this.state.isSubmitting}
        //                                 rowsMax={5}
        //                                 value={this.state.inputComment}
        //                                 onChange={this.handleInputComment}
        //                                 className={classes.commentTextField}
        //                             />
        //                         </Grid>
        //                         <Grid item xs={1}>
        //                             {!this.state.isSubmitting ?
        //                                 (<Button variant="contained" onClick={this.handlePostBlogCommentsById} style={{ textTransform: 'none', height: '60px', backgroundColor: '#76323F', color: 'white' }}>Post</Button>) :
        //                                 (<CircularProgress style={{ color: '#76323F' }} />)
        //                             }
        //                         </Grid>
        //                     </Grid>
        //                 </Grid>
        //                 <Divider variant="middle" />
        //                 {this.state.comments !== undefined && this.state.comments.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).map((comment) =>
        //                     <Grid item xs={12} style={{ maxWidth: "80%", marginTop: 30 }}>
        //                         <Grid container justify='center' spacing={1} alignItems="flex-end">
        //                             <Grid item xs>
        //                                 {(comment.submitterImg.length !== 0) &&
        //                                     <Avatar style={{ margin: 10 }}>
        //                                         {comment.submitter[0]}
        //                                     </Avatar>
        //                                 }
        //                                 {(comment.submitterImg.length === 0) &&
        //                                     <Avatar style={{ margin: 10 }}>
        //                                         {comment.submitter[0]}
        //                                     </Avatar>
        //                                 }
        //                             </Grid>
        //                             <Grid item align='left' xs={10}>
        //                                 <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
        //                                     {comment.comment}
        //                                 </Typography>
        //                                 <Typography variant="caption" display="block">
        //                                     {comment.submitter} - {new Date(comment.createdAt).toDateString()}
        //                                 </Typography>
        //                             </Grid>
        //                         </Grid>
        //                     </Grid>
        //                 )}
        //             </Grid>
        //         </Grid>
        //     </Container>
        // )

        return (
            <ThemeProvider theme={theme}>
                <Hidden mdUp>{mobileUI}</Hidden>
                <Hidden smDown>{webUI}</Hidden>
            </ThemeProvider>
        );
    }
}

export default compose(
    withStyles(useStyles)
)(Blog);
