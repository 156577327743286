//React
import { Component } from 'react';
import { compose } from 'recompose';

//Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import HeadsetMicRoundedIcon from '@material-ui/icons/HeadsetMicRounded';

//Icon

//react-router-dom

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%'
    }
});

class TestSoundPage extends Component {
    constructor() {
        super();
    }

    render() {
        return (
            <Grid container justify="center" style={{ marginTop: '20px' }}>
                <Grid item>
                    <Paper variant="outlined" style={{
                        padding: '50px',
                        backgroundImage: `url("/U-Test-Logo-new-bw.png")`,
                        backgroundSize: '120px',
                        backgroundRepeat: 'space'
                    }}>
                        <Grid item xs={12} align='center'>
                            <Typography variant="h6" gutterBottom style={{ fontWeight: "bold" }}>
                                <HeadsetMicRoundedIcon /> Test sound
                            </Typography>
                        </Grid>
                        <Grid item xs={12} align='center' style={{ paddingTop: '10px' }}>
                            <Typography variant="body2">
                                Put on your headphones and click on the <Box fontWeight="fontWeightBold" display='inline'>Play sound</Box> button to
                                play a sample sound.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{ paddingTop: '5px' }}>
                            {!this.props.isPlayTestSound &&
                                <Button size="small" variant="contained" onClick={() => this.props.handleSoundControl('play')} style={{ textTransform: "none", color: 'white', backgroundColor: '#25333e', fontWeight: 'bold' }}>
                                    Play sound
                                </Button>
                            }
                            {this.props.isPlayTestSound &&
                                <Button size="small" variant="contained" onClick={() => this.props.handleSoundControl('pause')} style={{ textTransform: "none", color: 'white', backgroundColor: '#25333e', fontWeight: 'bold' }}>
                                    <Typography variant="body1">
                                        Stop sound
                                    </Typography>
                                </Button>
                            }
                        </Grid>
                        <Grid item xs={12} align='center' style={{ marginTop: '20px' }}>
                            <Grid container spacing={1} alignItems='center' justify="center">
                                <Grid item>
                                    <ErrorRoundedIcon style={{ color: 'red' }} />
                                </Grid>
                                <Grid item>
                                    <Typography variant="body2">
                                        Please make sure that you can hear the sound clearly.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{ paddingTop: '5px' }}>
                            <Button size="small" variant="outlined" onClick={this.props.handleDoneSoundTest} style={{ textTransform: "none", color: 'white', backgroundColor: '#25333e', fontWeight: 'bold' }}>
                                Continue
                            </Button>
                        </Grid>
                        <Grid item align='center' xs={12}>
                            <Button size="small" onClick={this.props.handleUserInfoPage}>
                                <Box fontWeight="fontWeightBold" display='inline' style={{ textTransform: "none", color: 'grey', fontWeight: 'bold' }}> Back </Box>
                            </Button>
                        </Grid>
                        <Grid item align='right' xs={12}>
                            <Typography
                                variant="subtitle2"
                                style={{ color: "#c8c8c8", fontWeight: "bold" }}
                            >
                                {btoa(this.props.user.username)}
                            </Typography>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        )
    }
}

export default compose(
    withStyles(useStyles)
)(TestSoundPage);