import React from 'react';

//MUI
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

import { ThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';

//Functions

//Font
import 'typeface-glacial-indifference';

//Others
const axios = require('axios').default;
const FormData = require('form-data');
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

const useStyles = theme => ({
    root: {
        padding: theme.spacing(12, 4),
    }
});

const theme = createMuiTheme({
    typography: {
        fontFamily: [
            'Roboto'
        ].join(','),
    },
});

const SelectTestType = (props) => {
    const mobileUI = (<Grid container>
        <Grid item xs={12}>
            <ButtonGroup fullWidth>
                <Button
                    onClick={() => { props.setSelectedTestType("listening") }}
                    style={{
                        borderColor: "#880000",
                        color: props.selectedTestType === "listening" ? "white" : "#880000",
                        backgroundColor: props.selectedTestType === "listening" ? "#880000" : "",
                        textTransform: "none",
                    }}
                >
                    Listening
                </Button>
                <Button
                    onClick={() => { props.setSelectedTestType("reading") }}
                    style={{
                        borderColor: "#880000",
                        color: props.selectedTestType === "reading" ? "white" : "#880000",
                        backgroundColor: props.selectedTestType === "reading" ? "#880000" : "",
                        textTransform: "none"
                    }}
                >
                    Reading
                </Button>
                <Button
                    disabled={!props.isGotWritingResult}
                    onClick={() => { props.setSelectedTestType("writing") }}
                    style={{
                        borderColor: props.isGotWritingResult ? "#880000" : "#a6a6a6",
                        borderLeftColor: "#880000",
                        color: props.isGotWritingResult ? (props.selectedTestType === "writing" ? "white" : "#880000") : "#a6a6a6",
                        backgroundColor: props.selectedTestType === "writing" ? "#880000" : "",
                        textTransform: "none"
                    }}
                >
                    Writing
                </Button>
                <Button
                    disabled={props.reportObj?.test_result?.speakingStatus !== "done"}
                    onClick={() => { props.setSelectedTestType("speaking") }}
                    style={{
                        borderColor: props.reportObj?.test_result?.speakingStatus === "done" ? "#880000" : "#a6a6a6",
                        color: props.reportObj?.test_result?.speakingStatus === "done" ? (props.selectedTestType === "writing" ? "white" : "#880000") : "#a6a6a6",
                        backgroundColor: props.selectedTestType === "speaking" ? "#880000" : "",
                        textTransform: "none"
                    }}
                >
                    Speaking
                </Button>
            </ButtonGroup>
        </Grid>
    </Grid>)

    const webUI = (<Grid container>
        <Grid item xs={12}>
            <ButtonGroup fullWidth>
                <Button
                    onClick={() => { props.setSelectedTestType("listening") }}
                    style={{
                        borderColor: "#880000",
                        color: props.selectedTestType === "listening" ? "white" : "#880000",
                        backgroundColor: props.selectedTestType === "listening" ? "#880000" : "",
                        textTransform: "none",
                    }}
                >
                    Listening
                </Button>
                <Button
                    onClick={() => { props.setSelectedTestType("reading") }}
                    style={{
                        borderColor: "#880000",
                        color: props.selectedTestType === "reading" ? "white" : "#880000",
                        backgroundColor: props.selectedTestType === "reading" ? "#880000" : "",
                        textTransform: "none"
                    }}
                >
                    Reading
                </Button>
                <Button
                    disabled={!props.isGotWritingResult}
                    onClick={() => { props.setSelectedTestType("writing") }}
                    style={{
                        borderColor: props.isGotWritingResult ? "#880000" : "#a6a6a6",
                        borderLeftColor: "#880000",
                        color: props.isGotWritingResult ? (props.selectedTestType === "writing" ? "white" : "#880000") : "#a6a6a6",
                        backgroundColor: props.selectedTestType === "writing" ? "#880000" : "",
                        textTransform: "none"
                    }}
                >
                    Writing
                </Button>
                <Button
                    disabled={props.reportObj?.test_result?.speakingStatus !== "done"}
                    onClick={() => { props.setSelectedTestType("speaking") }}
                    style={{
                        borderColor: props.reportObj?.test_result?.speakingStatus === "done" ? "#880000" : "#a6a6a6",
                        color: props.reportObj?.test_result?.speakingStatus === "done" ? (props.selectedTestType === "writing" ? "white" : "#880000") : "#a6a6a6",
                        backgroundColor: props.selectedTestType === "speaking" ? "#880000" : "",
                        textTransform: "none"
                    }}
                >
                    Speaking
                </Button>
            </ButtonGroup>
        </Grid>
    </Grid>)

    return <ThemeProvider theme={theme}>
        <Hidden mdUp>
            {mobileUI}
        </Hidden>
        <Hidden smDown>
            {webUI}
        </Hidden>
    </ThemeProvider>
};

export default compose(
    withStyles(useStyles)
)(SelectTestType);