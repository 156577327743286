import React from 'react';

import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import MuiAlert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';

//Others
import { withStyles } from '@material-ui/core/styles';
import { Component } from 'react';
import { compose } from 'recompose';
import MaterialTable from 'material-table'

//Icons
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

//Models
import { GetAllSections } from '../../../../models/addPart';
import { ThreeSixtySharp } from '@material-ui/icons';

//Others
const axios = require('axios').default;
const FormData = require('form-data');
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

const useStyles = theme => ({
    root: {
        margin: 15,
        marginTop: 50
    }
});

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class AllQuestions extends Component {
    constructor() {
        super()
        this.state = {
            selectedPartType: 'reading',
            testsToShow: [],
            isLoadingParts: false,
            isConfirmDeletion: false
        };
    }

    componentDidMount = () => {
        this.handleGetAllQuestions();
        document.querySelector("body").scrollTo(0, 0);
    }

    handleGetAllQuestions = async () => {
        let questionsToShow = []
        const res = await GetAllSections(JSON.parse(localStorage.getItem('user')).token);
        await res.res.section?.filter((section) => section.question_type !== "multipleChoiceBMAT" && section.question_type !== "writingBaseBMAT").map((section) => {
            questionsToShow.push({
                id: section.section_id,
                description: section.section,
                question_count: section.questions.length,
                type: this.handleConvertQuestionType(section.question_type)
            });
        });

        this.setState({
            questionsToShow: questionsToShow
        });
    }

    handleDeleteQuestion = async () => {
        await axios({
            method: 'delete',
            url: `${api_base_url}/api/test/section/id/${this.state.selectedObj.id}`,
            headers: {
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem('user')).token
            }
        });

        this.handleGetAllQuestions();
        this.handleCloseConfirmDeletion();
    }

    handleOpenConfirmDeletion = (selectedObj) => {
        this.setState({
            isConfirmDeletion: true,
            selectedObj: selectedObj
        });
    }

    handleCloseConfirmDeletion = () => {
        this.setState({
            isConfirmDeletion: false
        });
    }

    //==================================== QUESTION TEXT FORMAT ====================================
    handleConvertQuestionType = (question) => {
        if (question === "trueFalseNG") {
            return "True/False/Not given"
        } if (question === "yesNoNG") {
            return "Yes/No/Not given"
        } if (question === "choose2Letters") {
            return "Choose 2 Letters"
        } if (question === undefined) {
            return "-"
        }

        const result = question.replace(/([A-Z])/g, " $1");
        const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
        return finalResult
    }
    //==================================== QUESTION TEXT FORMAT ====================================

    handleSelectTestType = (type) => {
        this.setState({
            selectedPartType: type
        });
    }

    render() {
        const { classes } = this.props;

        return (
            <Grid item xs={12} style={{ paddingLeft: '8px' }}>
                <Dialog
                    open={this.state.isConfirmDeletion}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{`Are you sure to remove question ${this.state.selectedObj?.id}?`}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Please click <span style={{ fontWeight: 'bold' }}>"Confirm"</span> to remove question {this.state.selectedObj?.id}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseConfirmDeletion} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.handleDeleteQuestion} color="primary" autoFocus>
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>

                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <MaterialTable
                            data={this.state.questionsToShow}
                            columns={[
                                {
                                    title: 'Question id',
                                    field: 'id',
                                    editable: 'never'
                                },
                                {
                                    title: 'Description',
                                    field: 'description',
                                    editable: 'never'
                                },
                                {
                                    title: 'Type',
                                    field: 'type',
                                    editable: 'never',
                                    defaultSort: 'asc'
                                },
                                {
                                    title: 'Question Count',
                                    field: 'question_count',
                                    editable: 'never'
                                }
                            ]}
                            options={{
                                actionsColumnIndex: -1,
                                exportAllData: true,
                                exportButton: true,
                                grouping: true,
                                sorting: true,
                                pageSize: 5
                            }}
                            actions={[
                                {
                                    icon: 'delete',
                                    tooltip: 'Delete Test',
                                    onClick: (event, rowData) => this.handleOpenConfirmDeletion(rowData)
                                }

                            ]}
                            isLoading={!this.state.questionsToShow}
                            title='Questions'
                        />
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}
export default compose(
    withStyles(useStyles)
)(AllQuestions);