import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { compose } from 'recompose';

//import other library
import axios from 'axios';
import 'react-calendar/dist/Calendar.css';

//react-router-dom
import {
    Link
} from "react-router-dom";

//import components
import ConfirmPaymentPage from '../payment/ConfirmPaymentPage';
import ChooseTestType from '../test/ChooseTestType';
import SelectTestType from '../testRegistration/SelectTestType';

const useStyles = theme => ({
    root: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    }
});


class Courses extends Component {
    constructor() {
        super();
        this.state = {
            selectedTest: '',
            isNewDesign: true,
            avalibleCoursesArr: [
                {
                    name: 'IELTS',
                    data: [
                        {
                            name: "IELTS",
                            type: "Reading, Listening, Writing, Speaking",
                            description: "Include Reading, Listening, Writing and Speaking",
                            amount: 159900,
                            duration: 120,
                            teacher: 'Jirayu Promsongwong',
                            available: true
                        },
                        {
                            name: "IELTS (No Speaking)",
                            type: "Reading, Listening, Writing",
                            description: "Include Reading, Listening, Writing",
                            amount: 129900,
                            duration: 120,
                            teacher: 'Jirayu Promsongwong',
                            available: true
                        }
                    ]
                },
                {
                    name: 'OTHERS',
                    data: [
                        {
                            name: "BMAT",
                            type: "Full test",
                            description: "This is full test.",
                            amount: 159900,
                            duration: 180,
                            teacher: 'Jirayu Promsongwong',
                            available: true
                        },
                        {
                            name: "TOEIC",
                            type: "Reading, Listening, Writing",
                            description: "Include Reading, Listening, Writing",
                            amount: 99900,
                            duration: 60,
                            teacher: 'Boss',
                            available: false
                        }
                    ]
                }
            ],
            checkout: false,
            charge: undefined,
            selectedCourse: {
                name: "IELTS",
                type: "Reading, Listening, Writing",
                description: "Include Reading, Listening, Writing",
                amount: 14000,
            },
            isPurchase: false,
            isSelectPlan: false,
            isChooseFreeTest: false,
            window: {
                height: window.innerHeight,
                width: window.innerWidth
            }
        }
        this.handleCheckout = this.handleCheckout.bind(this);
    }

    componentDidMount() {
        this.props.handleChangeMenu('packages');
        document.querySelector("body").scrollTo(0, 0);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    handleResize = (event) => {
        this.setState({
            window: {
                height: window.innerHeight,
                width: window.innerWidth
            }
        });
    };

    handleCheckout() {
        this.setState({
            checkout: true
        });
    }

    //============================= BEGIN PAY BY CREDIT/DEBIT CARD ================================
    createCreditCardCharge = async (email, name, amount, token) => {
        try {
            const res = await axios({
                method: 'post',
                url: 'http://localhost:80/checkout-credit-card',
                data: {
                    email,
                    name,
                    amount,
                    token
                },
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            const resData = res.data;
            this.setState({
                charge: resData
            });

        } catch (err) {
            console.log(err);
        }
    }
    //============================= END PAY BY CREDIT/DEBIT CARD ================================

    handleCloseConfirmPayment = () => {
        this.setState({
            isPurchase: false
        });
        this.handleSelectPlan();
    }

    handleOpenConfirmPayment = () => {
        this.handleCloseSelectPlan();
        this.setState({
            isPurchase: true
        });
    }

    handlePlanChosen = (name, type, description, amount) => {
        let selectedCourse = {
            name: name,
            type: type,
            description: description,
            amount: amount
        }
        this.setState({
            selectedCourse: selectedCourse
        });

        this.handleOpenConfirmPayment();
    }

    handleToggleSwitch = () => {
        this.setState({
            isNewDesign: !this.state.isNewDesign
        })
    }

    handleSelectPlan = () => {
        this.setState({
            isSelectPlan: true
        });
    }

    handleCloseSelectPlan = () => {
        this.setState({
            isSelectPlan: false
        });
    }

    handleBacktoAllTests = () => {
        this.setState({
            isSelectPlan: false,
            isPurchase: false
        });
    }

    handleChooseFreeTest = () => {
        this.setState({
            isChooseFreeTest: true
        });
    }

    handleCloseChooseFreeTest = () => {
        this.setState({
            isChooseFreeTest: false
        });
    }



    render() {
        const { classes } = this.props;

        return (
            <Container>
                <Grid container justify='center' style={{ marginTop: '100px', marginBottom: '100px', padding: '20px', maxWidth: this.state.window.width, flexDirection: 'row' }}>
                    {this.state.isSelectPlan &&
                        <SelectTestType
                            handlePlanChosen={this.handlePlanChosen}
                            handleBacktoAllTests={this.handleBacktoAllTests}
                            handleOpenConfirmPayment={this.handleOpenConfirmPayment}
                        />
                    }
                    {!this.state.isSelectPlan && !this.state.isPurchase &&
                        <Grid item xs={8}>
                            <Typography variant="h2" gutterBottom>
                                <Box fontWeight="fontWeightBold" display='inline' style={{ color: '#76323F' }}>Book a Simulation Test</Box>
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom>
                                You can book and start taking the computer-based simulation tests now from anywhere.
                            </Typography>
                            <Typography variant="h5" gutterBottom style={{ marginTop: '30px' }}>
                                <Box fontWeight="fontWeightBold" display='inline' style={{ color: '#76323F' }}>Start a test today</Box>
                            </Typography>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        {this.state.avalibleCoursesArr.map((course, index) =>
                            <Grid container>
                                <Grid item align='left' xs={12} >
                                    <Typography variant="h5" style={{ color: '#76323F' }} gutterBottom>
                                        <Box fontWeight="fontWeightBold" display='inline' style={{ color: '#76323F' }}>{course.name}</Box>
                                    </Typography>
                                </Grid>
                                {course.data.map((type, index) =>
                                    <Grid item xs={2} style={{ margin: '10px' }}>
                                        <Card>
                                            <CardMedia
                                                component="img"
                                                height="140"
                                                image="https://images.unsplash.com/photo-1515378791036-0648a3ef77b2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
                                                alt="green iguana"
                                            />
                                            <CardContent style={{ height: 120, maxHeight: 120 }}>
                                                <Typography style={{ fontSize: '1rem' }} color="text.secondary" gutterBottom>
                                                    Type
                                                </Typography>
                                                <Typography variant="h5" component="div" style={{ fontSize: '1.5rem' }}>
                                                    <Box fontWeight="fontWeightBold" display='inline' style={{ color: '#76323F' }}>{type.name}</Box>
                                                </Typography>
                                                <Typography style={{ fontSize: '0.7rem' }} color="text.secondary">
                                                    {type.type}
                                                </Typography>
                                                <Typography variant="caption" display="block" color="text.secondary">
                                                    {type.duration} mins
                                                </Typography>
                                            </CardContent>
                                            <CardActions>
                                                <Grid container>
                                                    <Grid item align='center' xs={12} sm={8}>
                                                        {type.available && type.amount === 0 &&
                                                            <Button
                                                                size="small"
                                                                onClick={this.handleChooseFreeTest}
                                                                style={{
                                                                    backgroundColor: '#76323F',
                                                                    color: 'white',
                                                                    padding: '5px',
                                                                    width: '100%'
                                                                }}
                                                            >
                                                                Try Now
                                                            </Button>
                                                        }
                                                        {type.available && type.amount !== 0 &&
                                                            <Link
                                                                to={"/test/payment?course_name=" + type.name + '&description=' + type.description + '&amount=' + type.amount}
                                                                color="inherit"
                                                                underline="none"
                                                                style={{ textDecoration: 'none', color: '#565656' }}
                                                            >
                                                                <Button
                                                                    size="small"
                                                                    onClick={this.handleChooseFreeTest}
                                                                    style={{
                                                                        backgroundColor: '#76323F',
                                                                        color: 'white',
                                                                        padding: '5px',
                                                                        width: '100%'
                                                                    }}
                                                                >
                                                                    Buy Now
                                                                </Button>
                                                            </Link>
                                                        }
                                                        {!type.available &&
                                                            <Button
                                                                size="small"
                                                                variant="contained"
                                                                onClick={this.handleSelectPlan}
                                                                style={{
                                                                    padding: '5px',
                                                                    width: '100%'
                                                                }}
                                                                disabled
                                                            >
                                                                Coming Soon
                                                            </Button>
                                                        }
                                                    </Grid>
                                                    <Grid item align='center' xs={4}>
                                                        {type.available ? <Typography variant="body1" component="div" style={{ color: '#76323F', padding: '5px' }}>
                                                            {type.amount === 0 ? 'FREE' : type.amount / 100 + '฿'}
                                                        </Typography> : <Typography variant="body1" component="div" style={{ color: '#76323F', padding: '5px' }}>
                                                            -
                                                        </Typography>}
                                                    </Grid>
                                                </Grid>
                                            </CardActions>
                                        </Card>
                                    </Grid>
                                )}
                                {this.state.avalibleCoursesArr.length - 1 !== index &&
                                    <Grid item align='center' xs={12} style={{ marginTop: '50px', marginBottom: '50px' }}>
                                        <Divider style={{ height: '5px', backgroundColor: '#76323F' }} />
                                    </Grid>
                                }
                            </Grid>
                        )}
                    </Grid>
                    <ChooseTestType
                        userInfo={this.props.user}
                        isChooseFreeTest={this.state.isChooseFreeTest}
                        handleCloseChooseFreeTest={this.handleCloseChooseFreeTest}
                    />
                    {this.state.isPurchase &&
                        <Grid item align='center' xs={12}>
                            <ConfirmPaymentPage
                                user={this.props.user}
                                course={this.state.selectedCourse}
                                isConfirmPayment={this.state.isConfirmPayment}
                                handleCloseConfirmPayment={this.handleCloseConfirmPayment}
                                handleOpenConfirmPayment={this.handleOpenConfirmPayment}
                                createCreditCardCharge={this.createCreditCardCharge}
                            />
                        </Grid>
                    }
                </Grid>
            </Container >
        )
    }
}
export default compose(
    withStyles(useStyles)
)(Courses);