import React, { PureComponent } from 'react';

import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import LinearProgress from '@material-ui/core/LinearProgress';
import MuiAlert from '@material-ui/lab/Alert';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import { withStyles } from '@material-ui/core/styles';
import { Component } from 'react';
import { compose } from 'recompose';

//Others libraries
import Chart from "react-google-charts";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

//Others
const axios = require('axios').default;
const FormData = require('form-data');
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

const useStyles = theme => ({
    root: {
        margin: 15,
        marginTop: 50
    }
});

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class AssignedWorksChart extends Component {
    constructor() {
        super()
        this.state = {
            testGroup: [],
            isBarChartView: false
        };
    }

    componentDidMount = () => {
        this.handleGetAllSubmittedTests();
    }

    handleGetAllSubmittedTests = async () => {
        try {
            const res = await axios({
                method: 'get',
                url: 'https://chatter-funky-busby.glitch.me/tests',
            });

            const resData = res.data;
            console.log(resData);
            await this.handleRemoveTime(resData.tests);
            this.setState({
                tests: resData.tests,
                numberOfTest: resData.tests.length <= 10 ? resData.tests.length : 10
            });

        } catch (err) {
            console.log(err);
        }
    }

    handleRemoveTime = async (testsArr) => {
        let groups = [];
        let testGroupToShowLine = [];
        let testGroupToShowBar = [
            ['Date', 'Assigned tests']
        ];
        await testsArr.map(async (test, index) => {
            const date = test.updatedAt.split('T')[0];
            if (!groups[date]) {
                groups[date] = [];

                testGroupToShowLine.push({
                    date: date,
                    count: 0
                })
            }

            let foundIndex = await testGroupToShowLine.findIndex(x => x.date === date);
            groups[date].push(test);
            testGroupToShowLine[foundIndex].count = groups[date].length
        })



        testGroupToShowLine.map((testGroup) => {
            testGroupToShowBar.push([
                testGroup.date,
                testGroup.count
            ]);
        })

        console.log(testGroupToShowBar);

        this.setState({
            testGroup: groups,
            testGroupToShowLine: testGroupToShowLine,
            testGroupToShowBar: testGroupToShowBar
        });
    }

    handleToggleBarChartView = () => {
        this.setState({
            isBarChartView: !this.state.isBarChartView
        })
    }

    render() {
        const { classes } = this.props;

        return (
            <Paper style={{ padding: '35px' }} >
                <Grid container>
                    <Grid item align='left' xs={12}>
                        <Typography variant="h5" gutterBottom>
                            Assigned tests
                        </Typography>
                    </Grid>
                    <Grid item align='right' xs={12}>
                        <FormControlLabel
                            control={<Switch color="primary" checked={this.state.isBarChartView} onChange={this.handleToggleBarChartView} />}
                            label="Bar chart view"
                        />
                    </Grid>
                    {!this.state.isBarChartView &&
                        <Grid item align='left' xs={12}>
                            <AreaChart
                                width={1000}
                                height={500}
                                data={this.state.testGroupToShowLine}
                                margin={{
                                    top: 10,
                                    right: 30,
                                    left: 0,
                                    bottom: 0,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="date" />
                                <YAxis />
                                <Tooltip />
                                <Area type="monotone" dataKey="count" stroke="#8884d8" fill="#8884d8" />
                            </AreaChart>
                        </Grid>
                    }
                    {this.state.isBarChartView &&
                        <Grid item align='left' xs={12}>
                            <Chart
                                width={1000}
                                height={500}
                                chartType="Bar"
                                loader={<div>Loading Chart</div>}
                                data={this.state.testGroupToShowBar}
                                options={{
                                    // Material design options
                                    chart: {
                                        title: 'Assigned tests',
                                        subtitle: 'Overall assigned tests based on number of test assigned.',
                                    },
                                }}
                                margin={{
                                    top: 10,
                                    right: 30,
                                    left: 0,
                                    bottom: 0,
                                }}
                                // For tests
                                rootProps={{ 'data-testid': '2' }}
                            />
                        </Grid>
                    }
                </Grid>
            </Paper >
        )
    }
}
export default compose(
    withStyles(useStyles)
)(AssignedWorksChart);