//React
import React, { Component } from "react";
import { compose } from "recompose";

import { withStyles } from "@material-ui/core/styles";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";

//Constants
import * as styleConstants from "../../../constants/styleConstants";

//Others
import { Button, Grid, Hidden, IconButton } from "@material-ui/core";
import PodcastSectionMobile from "./mobile/PodcastSectionMobile";
import PinnedBlogsSectionMobile from "./mobile/PinnedBlogsSectionMobile";
import PodcastSectionWeb from "./web/PodcastSectionWeb";
import PinnedBlogsSectionWeb from "./web/PinnedBlogsSectionWeb";
import MediaQuery from "react-responsive";
import { GetAllBlogs } from "../../../services/blogServices";
import { ArrowLeft, ArrowRight } from "@material-ui/icons";
import moment from 'moment';

const useStyles = (theme) => ({
    root: {
        flexGrow: 1,
        width: "100%",
    },
    rootContainer: {
        maxWidth: "75%",
    },
    rootCard: {
        maxWidth: "75%",
        width: "75%",
        margin: 10,
    },
    gridContainer: {
        maxWidth: "90%",
    },
    margin: {
        margin: 100,
    },
    marginTop: {
        marginTop: 100,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
        marginTop: theme.spacing(5),
    },
    media: {
        height: 0,
        paddingTop: "56.25%", // 16:9
    },
    header: {
        maxWidth: "80%",
    },
    body: {
        maxWidth: "80%",
        marginTop: 30,
    },
    divider: {
        maxWidth: "100%",
    },
    image: {
        margin: "auto",
        display: "block",
        margin: 10,
    },
    commentTextField: {
        width: "100%",
        maxWidth: "100%",
    },
    commentGridItem: {
        maxWidth: "80%",
        marginTop: 30,
        marginBottom: 100,
    },
});

const theme = createMuiTheme({
    typography: {
        fontFamily: [`${styleConstants.fontStyle}`].join(","),
    },
});

class ContentSection extends Component {
    constructor() {
        super();
        this.state = {
            currentBlog: 0
        };
    }

    componentDidMount() {
        this.getBlogs();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    getBlogs = async () => {
        const blogObj = await GetAllBlogs();

        if (blogObj.success) {
            this.setState({
                blogObj: blogObj.res,
                // sortedBlogObj: blogObj.res.blogs.sort((blog) => { return blog.pinned ? -1 : 1 })
                sortedBlogObj: blogObj.res.blogs.sort((a, b) => {
                    if (a.pinned && !b.pinned) return -1;
                    if (!a.pinned && b.pinned) return 1;
                    return moment(b.createdAt, 'YYYY-MM-DD').diff(moment(a.createdAt, 'YYYY-MM-DD'));
                  })
            })
        }
    }

    onClickNext = () => {
        if (this.state.currentBlog + 1 <= this.state.blogObj.blogs.length - 1) {
            this.setState({
                currentBlog: this.state.currentBlog + 1
            });
        }

        return;
    }

    onClickBack = () => {
        if (this.state.currentBlog > 0) {
            this.setState({
                currentBlog: this.state.currentBlog - 1
            });
        }

        return;
    }

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Hidden lgUp>
                    <MediaQuery minWidth={"768px"}>
                        {(matches) =>
                            matches ?
                                <Grid container style={{ paddingLeft: "7%", paddingRight: "7%" }}>
                                    <Grid item xs={12} style={{ zIndex: 1 }}>
                                        <PinnedBlogsSectionMobile />
                                    </Grid>
                                </Grid> :
                                <Grid container spacing={1}>
                                    <Grid item xs={12} style={{ zIndex: 1 }}>
                                        <PodcastSectionMobile blogs={this.state.blogObj} />
                                    </Grid>
                                    <Grid item xs={12} style={{ zIndex: 1 }}>
                                        <PinnedBlogsSectionMobile />
                                    </Grid>
                                </Grid>
                        }
                    </MediaQuery>
                </Hidden>
                <Hidden mdDown>
                    <Grid container alignItems="center" spacing={5} style={{ paddingLeft: "5%", paddingRight: "5%" }}>
                        <Grid item style={{ zIndex: 1 }}>
                            <IconButton disabled={this.state.currentBlog === 0} onClick={this.onClickBack} aria-label="next">
                                <ArrowLeft style={{ fontSize: 40 }} />
                            </IconButton>
                        </Grid>
                        <Grid item xs={4} style={{ zIndex: 1 }}>
                            <PodcastSectionWeb currentBlog={this.state.currentBlog} blogs={this.state.sortedBlogObj}  />
                        </Grid>
                        <Grid item xs={6} style={{ zIndex: 1 }}>
                            <PinnedBlogsSectionWeb currentBlog={this.state.currentBlog} blogs={this.state.sortedBlogObj}  />
                        </Grid>
                        <Grid item style={{ zIndex: 1 }}>
                            <IconButton disabled={(this.state.currentBlog === (this.state.blogObj?.blogs?.length - 1))} onClick={this.onClickNext} aria-label="next">
                                <ArrowRight style={{ fontSize: 40 }} />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Hidden>
            </ThemeProvider>
        );
    }
}

export default compose(withStyles(useStyles))(ContentSection);

