//React
import { Component } from 'react';
import { compose } from 'recompose';

//Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

//import components
import { DragDropContainer, DropTarget } from 'react-drag-drop-container';

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%'
    }
});

class Matching extends Component {
    constructor() {
        super();
        this.state = {
            draggedItem: '',
            draggedItemToShow: '',
            dropItems: [],

        };
    }

    componentDidMount = async () => {

    }

    onDragged = (index) => {
        this.state.dropItems[index] = this.state.draggedItem
        this.setState({
            dropItemsToShow: this.state.dropItemsToShow
        });
    }

    onDragStart = (item) => {
        this.setState({
            draggedItem: item
        });
    }

    onClickAnswer = (index, qNum) => {
        this.state.dropItems[index] = undefined;
        this.setState({
            dropItems: this.state.dropItems
        });
    }

    render() {
        const { classes } = this.props;

        return (
            <Grid container>
                <Grid align='left' item xs={12} style={{ marginBottom: '50px' }}>
                    <Typography variant="h4" gutterBottom>
                        <Box fontWeight="fontWeightBold" display='inline'> Questions {this.props.currentTestObject?.questions[0].no} </Box>
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        {this.props.currentTestObject?.questions[0].question}
                    </Typography>
                </Grid>
                <Grid item align='left' xs={6}>
                    <Grid container spacing={2} align='left'>
                        {this.props.currentTestObject?.questions[0].match_question_l.map((q, index) =>
                            <Grid item xs={12}>
                                <Typography variant="body1" style={{ fontSize: '1.2rem' }}>
                                    {q} <DropTarget
                                        targetKey="foo"
                                        onHit={() => {
                                            this.onDragged(index);
                                        }}
                                    >
                                        <Button
                                            size="small"
                                            id="outlined-basic"
                                            variant="outlined"
                                            style={{ width: '50%', marginLeft: '10px' }}
                                            onClick={() => {this.onClickAnswer(index, Number(q.split('.')[0]))}}
                                        >
                                            {this.state.dropItems[index] === undefined || this.state.dropItems[index] === null ? q.split('.')[0] : this.state.dropItems[index]}
                                        </Button>
                                    </DropTarget>
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <Grid item align='left' xs={6}>
                    <Grid container spacing={2}>
                        {this.props.currentTestObject.questions[0].match_question_r.map((q, index) =>
                            (!this.state.dropItems.includes(q)) && <Grid item xs={12}>
                                <DragDropContainer onDragStart={() => { this.onDragStart(q) }} targetKey="foo" >
                                    <Paper style={{ padding: '5px' }}>
                                        <Typography variant="subtitle2" gutterBottom style={{ fontSize: '1.2rem' }}>
                                            {q}
                                        </Typography>
                                    </Paper>
                                </DragDropContainer>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

export default compose(
    withStyles(useStyles)
)(Matching);