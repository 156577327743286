import React, { useRef, useEffect } from 'react';

//MUI
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Skeleton from '@material-ui/lab/Skeleton';

import { ThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';

//Icons
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecordRounded';

//Components
import AreaOfImprovement from './AreaOfImprovement';

//Functions
import { SplitPascalCaseToString, MapReadingBandScore, CheckDisplaySection } from '../../../../../functions/testReportFuntions';

//Service
import { DownloadWritingFile } from '../../../../../services/testReportServices';

//Font
import 'typeface-glacial-indifference';

//Others
import Chart from "react-google-charts";
import PageBackdrop from '../../../PageBackdrop';
const axios = require('axios').default;
const FormData = require('form-data');
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

const useStyles = theme => ({
    root: {
        padding: theme.spacing(12, 4),
    }
});

const theme = createMuiTheme({
    typography: {
        fontFamily: [
            'Roboto'
        ].join(','),
    },
});

const ScoreVisualization = (props) => {
    const [selectedTab, setSelectedTab] = React.useState("Overall Band Score");
    const [listeningObj, setListeningObj] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);

    useEffect(() => {
        console.log(props.speakingNotesObj);
        getTestById();
    }, []);

    const getTestById = async () => {
        console.log(props.reportObj)
        const res = await axios({
            method: 'get',
            url: `${api_base_url}/api/test/?test_id=${props.reportObj.test_id}`,
            headers: {
                "Authorization": `Bearer ${JSON.parse(localStorage.getItem('user')).token}`
            }
        });

        const resData = res.data[0];
        const listeningParts = resData.parts.filter((part) => { return part.part_type === "LISTENING" });

        setListeningObj(listeningParts);
    }

    const selectTab = (tab) => {
        setSelectedTab(tab);
    };

    const downloadWritingFile = async () => {
        setIsLoading(true);
        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });

        await DownloadWritingFile(params.result_id, 'teacher');
        setIsLoading(false);
    }

    const checkReadingPercentage = (questions, score) => {
        let totalQuestion = 0;

        questions.map((question) => {
            const questionsArr = question.split(' ')[1].split('-');
            const questionCount = Number(questionsArr[1]) - Number(questionsArr[0]);

            totalQuestion += questionCount;
        })

        return Math.round((score / totalQuestion) * 100);
    }

    const checkListeningPercentage = (section, score) => {
        return Math.round((score / listeningObj[section.no - 1].total_question) * 100);
    }

    const mobileUI = (<Grid container>
        <Grid item>
            <Button
                onClick={() => { selectTab("Overall Band Score") }}
                style={{ borderBottom: selectedTab === "Overall Band Score" ? "5px solid #43637c" : "5px solid #c8c8c8", borderRadius: "0px", fontSize: "1rem", textTransform: "none" }}>
                Overall Band Score
            </Button>
        </Grid>
        <Grid item>
            <Button
                onClick={() => { selectTab("Skill Analysis Section") }}
                style={{ borderBottom: selectedTab === "Skill Analysis Section" ? "5px solid #43637c" : "5px solid #c8c8c8", borderRadius: "0px", fontSize: "1rem", textTransform: "none" }}>
                Skill Analysis Section
            </Button>
        </Grid>
        <Grid item xs={12}>
            <Divider />
        </Grid>

        {selectedTab === "Overall Band Score" &&
            <Grid item align='center' xs={12}>
                <Grid container>
                    <Grid item xs={12} style={{ paddingTop: '50px' }}>
                        <Grid container spacing={2} justify="center">
                            <Grid item>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'wrap'
                                }}>
                                    <FiberManualRecordRoundedIcon style={{ color: "#636363", marginRight: '5px' }} />
                                    <Typography variant="subtitle1" style={{ color: "black" }}>
                                        AVG Users
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'wrap'
                                }}>
                                    <FiberManualRecordRoundedIcon style={{ color: "#a6a6a6", marginRight: '5px' }} />
                                    <Typography variant="subtitle1" style={{ color: "black" }}>
                                        AVG Test takers
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'wrap'
                                }}>
                                    <FiberManualRecordRoundedIcon style={{ color: "#880000", marginRight: '5px' }} />
                                    <Typography variant="subtitle1" style={{ color: "black" }}>
                                        You
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Chart
                        chartType="ColumnChart"
                        width="100%"
                        height="400px"
                        data={[
                            ['Category', 'Avg Users', 'Avg Test Takers', 'You'],
                            ['Listening',
                                6.5,
                                7,
                                props.reportObj?.test_result?.score?.listening?.score],
                            ['Reading',
                                props.reportObj?.test_result?.score?.reading?.avg_user_score,
                                props.reportObj?.test_result?.score?.reading?.avg_tester_score,
                                props.reportObj?.test_result?.score?.reading?.score],
                            ['Writing',
                                props.reportObj?.test_result?.score?.writing?.avg_user_score,
                                props.reportObj?.test_result?.score?.writing?.avg_tester_score,
                                props.writingNotesObj?.note?.score],
                            ['Speaking',
                                props.reportObj?.test_result?.score?.speaking?.avg_user_score,
                                props.reportObj?.test_result?.score?.speaking?.avg_tester_score,
                                props.speakingNotesObj?.note?.score]
                        ]}
                        options={{
                            // Material design options
                            chartArea: { width: '96%' },
                            colors: ['#636363', '#a6a6a6', '#880000'],
                            title: 'Overall Band Score Comparison',
                            height: 400,
                            backgroundColor: "#efefef"
                        }}
                    />
                </Grid>
            </Grid>
        }

        {selectedTab === "Skill Analysis Section" &&
            <Grid item align='center' xs={12} style={{ paddingTop: "30px" }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <ButtonGroup fullWidth>
                            <Button
                                onClick={() => { props.setSelectedTestType("listening") }}
                                style={{
                                    borderColor: "#880000",
                                    color: props.selectedTestType === "listening" ? "white" : "#880000",
                                    backgroundColor: props.selectedTestType === "listening" ? "#880000" : "",
                                    textTransform: "none",
                                }}
                            >
                                Listening
                            </Button>
                            <Button
                                onClick={() => { props.setSelectedTestType("reading") }}
                                style={{
                                    borderColor: "#880000",
                                    color: props.selectedTestType === "reading" ? "white" : "#880000",
                                    backgroundColor: props.selectedTestType === "reading" ? "#880000" : "",
                                    textTransform: "none"
                                }}
                            >
                                Reading
                            </Button>
                            <Button
                                disabled={!props.isGotWritingResult}
                                onClick={() => { props.setSelectedTestType("writing") }}
                                style={{
                                    borderColor: props.isGotWritingResult ? "#880000" : "#a6a6a6",
                                    borderLeftColor: "#880000",
                                    color: props.isGotWritingResult ? (props.selectedTestType === "writing" ? "white" : "#880000") : "#a6a6a6",
                                    backgroundColor: props.selectedTestType === "writing" ? "#880000" : "",
                                    textTransform: "none"
                                }}
                            >
                                Writing
                            </Button>
                            <Button
                                disabled={!props.isGotSpeakingResult}
                                onClick={() => { props.setSelectedTestType("speaking") }}
                                style={{
                                    borderColor: props.isGotSpeakingResult ? "#880000" : "#a6a6a6",
                                    color: props.isGotSpeakingResult ? (props.selectedTestType === "speaking" ? "white" : "#880000") : "#a6a6a6",
                                    backgroundColor: props.selectedTestType === "speaking" ? "#880000" : "",
                                    textTransform: "none"
                                }}
                            >
                                Speaking
                            </Button>
                        </ButtonGroup>
                    </Grid>

                    {props.selectedTestType !== "writing" && <Grid item xs={12}>
                        <Accordion defaultExpanded style={{ borderRadius: "15px" }} >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                style={{
                                    backgroundColor: "#25333e",
                                    color: "white",
                                    borderTopLeftRadius: "15px",
                                    borderTopRightRadius: "15px"
                                }}
                            >
                                <Typography variant="body1" style={{ fontWeight: "bold" }}>
                                    Introduction
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails style={{
                                backgroundColor: "#dbecf6",
                                borderBottomLeftRadius: "15px",
                                borderBottomRightRadius: "15px"
                            }}>
                                <Grid container spacing={4}>
                                    {/* <Grid item xs={2} style={{ backgroundColor: "#42647d", borderBottomLeftRadius: "15px" }}>
                                            <Typography variant="h5" style={{ color: "white", padding: "50px 0 50px" }}>
                                                Band {props.isLoading ? <Skeleton /> : props.reportObj?.test_result?.score[props.selectedTestType]?.score}
                                            </Typography>
                                        </Grid> */}
                                    <Grid item xs={12}>
                                        <Typography variant="body1" align="left" style={{ padding: "20px 0 20px" }}>
                                            {props.isLoading ? <Skeleton /> : props.reportObj?.test_result?.score[props.selectedTestType]?.score_introduction}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>}

                    {props.selectedTestType === "writing" &&
                        <Grid container spacing={2} style={{ marginTop: "10px" }}>
                            <Grid item align="right" xs={12}>
                                <Button
                                    disableElevation
                                    variant="contained"
                                    color="default"
                                    startIcon={<GetAppRoundedIcon />}
                                    onClick={downloadWritingFile}
                                    style={{ backgroundColor: '#25333e', color: 'white', textTransform: 'none' }}
                                >
                                    Download Teacher's Comment
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Accordion defaultExpanded style={{ borderRadius: "15px" }} >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        style={{
                                            backgroundColor: "#25333e",
                                            color: "white",
                                            borderTopLeftRadius: "15px",
                                            borderTopRightRadius: "15px"
                                        }}
                                    >
                                        <Typography variant="body1" style={{ fontWeight: "bold" }}>
                                            Introduction
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails style={{
                                        backgroundColor: "#dbecf6",
                                        borderBottomLeftRadius: "15px",
                                        borderBottomRightRadius: "15px"
                                    }}>
                                        <Typography variant="body1" align="left">
                                            <p>IELTS consists of two tasks: Report and Academic Essay. Task 1 tests the candidate&#39;s ability to recognise the given information&#39;s key features and write a 150-word report,&nbsp; using appropriate vocabulary and correct grammar.</p>

                                            <p>However, Task 2 assesses the candidates&#39; skills in interpreting, forming ideas, and writing one academic essay with a minimum of 250 words, using a wide range of vocabulary and grammar.</p>

                                            <p>Although Writing for IELTS does not reflect a complete set of skills needed for actual academic writing, it frames a comprehensive evaluation of writing skills in the English language.</p>

                                            <p>This section of the IELTS test is evaluated manually by two examiners. Both writing tasks are evaluated separately using 4 writing criteria: task achievement, cohesion and coherence, lexical resource, and grammatical range and accuracy.</p>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid item xs={12}>
                                <Accordion defaultExpanded style={{ borderRadius: "15px" }} >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        style={{
                                            backgroundColor: "#25333e",
                                            color: "white",
                                            borderTopLeftRadius: "15px",
                                            borderTopRightRadius: "15px"
                                        }}
                                    >
                                        <Typography variant="body1" style={{ fontWeight: "bold" }}>
                                            Examiner's Comment:
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails style={{
                                        backgroundColor: "#dbecf6",
                                        borderBottomLeftRadius: "15px",
                                        borderBottomRightRadius: "15px"
                                    }}>
                                        <Grid container spacing={4}>
                                            <Grid item xs={12}>
                                                <Typography variant="body1" align="left" style={{ padding: "20px 0 20px", whiteSpace: 'pre-line' }}>
                                                    {props.isLoading ? <Skeleton /> : props.writingNotesObj?.note?.comment}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        </Grid>
                    }

                    {props.selectedTestType === "reading" &&
                        <Grid item xs={12}>
                            <AreaOfImprovement reportObj={props.reportObj} />
                        </Grid>
                    }

                    {props.reportObj?.test_result?.score[props.selectedTestType]?.section?.map((section, index) => {
                        return CheckDisplaySection(section, props.selectedTestType) && (<Grid key={section.id} item xs={12} style={{ paddingTop: '20px' }}>
                            <Accordion defaultExpanded style={{ borderRadius: "15px" }} >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    style={{
                                        backgroundColor: "#6C0000",
                                        color: "white",
                                        borderTopLeftRadius: "15px",
                                        borderTopRightRadius: "15px"
                                    }}
                                >
                                    <Typography variant="body1" style={{ fontWeight: "bold" }}>
                                        Section: {props.isLoading ? <Skeleton /> : props.selectedTestType === "reading" ? (SplitPascalCaseToString(section.no)) : section.no}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails style={{
                                    backgroundColor: "#FFE4E4",
                                    borderBottomLeftRadius: "15px",
                                    borderBottomRightRadius: "15px"
                                }}>
                                    <Grid container spacing={4}>
                                        <Grid item xs={4} style={{ backgroundColor: "#880000", borderBottomLeftRadius: "15px" }}>
                                            <Typography variant="h6" style={{ color: "white", padding: "50px 0 50px" }}>
                                                Your score is {props.selectedTestType === "reading" ? `${checkReadingPercentage(section.question, section.raw_score)}%` : `${checkListeningPercentage(section, section.raw_score)}%`}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Typography variant="body1" align="left" style={{ padding: "20px 0 20px" }}>
                                                {props.isLoading ? <Skeleton /> : MapReadingBandScore(section, props.selectedTestType, section.description)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>)
                    }
                    )}
                </Grid>
            </Grid>
        }

        {(selectedTab === "Skill Analysis Section" && props.selectedTestType === "speaking") &&
            <Grid container spacing={2}>
                <Grid item xs={12} style={{ paddingTop: '30px' }}>
                    <Accordion defaultExpanded style={{ borderRadius: "15px" }} >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            style={{
                                backgroundColor: "#25333e",
                                color: "white",
                                borderTopLeftRadius: "15px",
                                borderTopRightRadius: "15px"
                            }}
                        >
                            <Typography variant="body1" style={{ fontWeight: "bold" }}>
                                Examiner's Comment:
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{
                            backgroundColor: "#dbecf6",
                            borderBottomLeftRadius: "15px",
                            borderBottomRightRadius: "15px"
                        }}>
                            <Grid container spacing={4}>
                                <Grid item xs={12}>
                                    <Typography variant="body1" align="left" style={{ padding: "20px 0 20px", whiteSpace: 'pre-line' }}>
                                        {props.isLoading ? <Skeleton /> : props.speakingNotesObj?.note?.comment}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item xs={12} style={{ paddingTop: '50px' }}>
                    <Typography align="left" variant="h6" style={{ fontWeight: "bold" }}>
                        {props.isLoading ? <Skeleton /> : "Speaking Criteria"}
                    </Typography>
                    <Chart
                        chartType="BarChart"
                        width="100%"
                        height="400px"
                        data={[
                            ["Task", "Score", { role: 'annotation' }],
                            ["Fluency and Coherence", parseFloat(props.speakingNotesObj?.note?.fluency_and_coherence), parseFloat(props.speakingNotesObj?.note?.fluency_and_coherence)],
                            ["Lexical Resource", parseFloat(props.speakingNotesObj?.note?.lexical_resource), parseFloat(props.speakingNotesObj?.note?.lexical_resource)],
                            ["Grammatical Range and Accuracy", parseFloat(props.speakingNotesObj?.note?.gramatical_range_and_accuracy), parseFloat(props.speakingNotesObj?.note?.gramatical_range_and_accuracy)],
                            ["Pronunciation", parseFloat(props.speakingNotesObj?.note?.pronunciation), parseFloat(props.speakingNotesObj?.note?.pronunciation)],
                        ]}
                        options={{
                            colors: ['#880000'],
                            chartArea: { width: '60%' },
                            legend: { position: "none" },
                            height: 400,
                            backgroundColor: '#efefef',
                            bars: 'horizontal', // Set chart type to horizontal bar
                            hAxis: {
                                title: null,
                                minValue: 0,
                                viewWindow: {
                                    min: 0, // set the minimum value of y-axis
                                    max: 9, // set the maximum value of y-axis
                                }
                            },
                            vAxis: {
                                title: null,
                            },
                            axes: {
                                y: {
                                    0: { side: 'left' },
                                },
                            },
                            annotations: {
                                alwaysOutside: true,
                                textStyle: {
                                    fontSize: 16,
                                    auraColor: 'none',
                                    color: 'black',
                                    bold: true,
                                },
                            }
                        }}
                    />
                </Grid>

            </Grid>
        }

        {(selectedTab === "Skill Analysis Section" && props.selectedTestType === "writing") &&
            <Grid container spacing={2}>
                <Grid item xs={12} style={{ paddingTop: '50px' }}>
                    <Typography align="left" variant="h6" style={{ fontWeight: "bold" }}>
                        {props.isLoading ? <Skeleton /> : "Task 1"}
                    </Typography>
                    <Chart
                        chartType="BarChart"
                        width="100%"
                        height="400px"
                        data={[
                            ["Task", "Score", { role: 'annotation' }],
                            ["Task Achievement", parseFloat(props.writingNotesObj?.note?.task_1?.task_achievement), parseFloat(props.writingNotesObj?.note?.task_1?.task_achievement)],
                            ["Cohesion and Coherence", parseFloat(props.writingNotesObj?.note?.task_1?.cohesion_and_coherence), parseFloat(props.writingNotesObj?.note?.task_1?.cohesion_and_coherence)],
                            ["Lexical Resource", parseFloat(props.writingNotesObj?.note?.task_1?.lexical_resource), parseFloat(props.writingNotesObj?.note?.task_1?.lexical_resource)],
                            ["Grammatical Range and Accuracy", parseFloat(props.writingNotesObj?.note?.task_1?.grammatical_range_and_accuracy), parseFloat(props.writingNotesObj?.note?.task_1?.grammatical_range_and_accuracy)],
                        ]}
                        options={{
                            colors: ['#880000'],
                            chartArea: { width: '60%' },
                            legend: { position: "none" },
                            height: 400,
                            backgroundColor: '#efefef',
                            bars: 'horizontal', // Set chart type to horizontal bar
                            hAxis: {
                                title: null,
                                minValue: 0,
                                viewWindow: {
                                    min: 0, // set the minimum value of y-axis
                                    max: 9, // set the maximum value of y-axis
                                }
                            },
                            vAxis: {
                                title: null,
                            },
                            axes: {
                                y: {
                                    0: { side: 'left' },
                                },
                            },
                            annotations: {
                                alwaysOutside: true,
                                textStyle: {
                                    fontSize: 16,
                                    auraColor: 'none',
                                    color: 'black',
                                    bold: true,
                                },
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography align="left" variant="h6" style={{ fontWeight: "bold" }}>
                        {props.isLoading ? <Skeleton /> : "Task 2"}
                    </Typography>
                    <Chart
                        chartType="BarChart"
                        width="100%"
                        height="400px"
                        data={[
                            ["Task", "Score", { role: 'annotation' }],
                            ["Task Achievement", parseFloat(props.writingNotesObj?.note?.task_2?.task_achievement), parseFloat(props.writingNotesObj?.note?.task_2?.task_achievement)],
                            ["Cohesion and Coherence", parseFloat(props.writingNotesObj?.note?.task_2?.cohesion_and_coherence), parseFloat(props.writingNotesObj?.note?.task_2?.cohesion_and_coherence)],
                            ["Lexical Resource", parseFloat(props.writingNotesObj?.note?.task_2?.lexical_resource), parseFloat(props.writingNotesObj?.note?.task_2?.lexical_resource)],
                            ["Grammatical Range and Accuracy", parseFloat(props.writingNotesObj?.note?.task_2?.grammatical_range_and_accuracy), parseFloat(props.writingNotesObj?.note?.task_2?.grammatical_range_and_accuracy)],
                        ]}
                        options={{
                            colors: ['#880000'],
                            chartArea: { width: '60%' },
                            legend: { position: "none" },
                            height: 400,
                            backgroundColor: '#efefef',
                            bars: 'horizontal', // Set chart type to horizontal bar
                            hAxis: {
                                title: null,
                                minValue: 0,
                                viewWindow: {
                                    min: 0, // set the minimum value of y-axis
                                    max: 9, // set the maximum value of y-axis
                                }
                            },
                            vAxis: {
                                title: null,
                            },
                            axes: {
                                y: {
                                    0: { side: 'left' },
                                },
                            },
                            annotations: {
                                alwaysOutside: true,
                                textStyle: {
                                    fontSize: 16,
                                    auraColor: 'none',
                                    color: 'black',
                                    bold: true,
                                },
                            }
                        }}
                    />
                </Grid>
            </Grid>
        }
    </Grid>)

    const webUI = (
        <Grid container>
            <Grid item>
                <Button
                    onClick={() => { selectTab("Overall Band Score") }}
                    style={{ borderBottom: selectedTab === "Overall Band Score" ? "5px solid #43637c" : "5px solid #c8c8c8", borderRadius: "0px", fontSize: "1rem", textTransform: "none" }}>
                    Overall Band Score
                </Button>
            </Grid>
            <Grid item>
                <Button
                    onClick={() => { selectTab("Skill Analysis Section") }}
                    style={{ borderBottom: selectedTab === "Skill Analysis Section" ? "5px solid #43637c" : "5px solid #c8c8c8", borderRadius: "0px", fontSize: "1rem", textTransform: "none" }}>
                    Skill Analysis Section
                </Button>
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>

            {selectedTab === "Overall Band Score" &&
                <Grid item align='center' xs={12}>
                    <Grid container>
                        <Grid item xs={12} style={{ paddingTop: '50px' }}>
                            <Grid container spacing={2} justify="center">
                                <Grid item>
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexWrap: 'wrap'
                                    }}>
                                        <FiberManualRecordRoundedIcon style={{ color: "#636363", marginRight: '5px' }} />
                                        <Typography variant="subtitle1" style={{ color: "black" }}>
                                            AVG Users
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item>
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexWrap: 'wrap'
                                    }}>
                                        <FiberManualRecordRoundedIcon style={{ color: "#a6a6a6", marginRight: '5px' }} />
                                        <Typography variant="subtitle1" style={{ color: "black" }}>
                                            AVG Test takers
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item>
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexWrap: 'wrap'
                                    }}>
                                        <FiberManualRecordRoundedIcon style={{ color: "#880000", marginRight: '5px' }} />
                                        <Typography variant="subtitle1" style={{ color: "black" }}>
                                            You
                                        </Typography>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Chart
                            chartType="ColumnChart"
                            width="100%"
                            height="400px"
                            data={[
                                ['Category', 'Avg Users', 'Avg Test Takers', 'You'],
                                ['Listening',
                                    6.5,
                                    7,
                                    props.reportObj?.test_result?.score?.listening?.score],
                                ['Reading',
                                    props.reportObj?.test_result?.score?.reading?.avg_user_score,
                                    props.reportObj?.test_result?.score?.reading?.avg_tester_score,
                                    props.reportObj?.test_result?.score?.reading?.score],
                                ['Writing',
                                    props.reportObj?.test_result?.score?.writing?.avg_user_score,
                                    props.reportObj?.test_result?.score?.writing?.avg_tester_score,
                                    props.writingNotesObj?.note?.score],
                                ['Speaking',
                                    props.reportObj?.test_result?.score?.speaking?.avg_user_score,
                                    props.reportObj?.test_result?.score?.speaking?.avg_tester_score,
                                    props.speakingNotesObj?.note?.score]
                            ]}
                            options={{
                                // Material design options
                                chartArea: { width: '96%' },
                                colors: ['#636363', '#a6a6a6', '#880000'],
                                title: 'Overall Band Score Comparison',
                                height: 400,
                                backgroundColor: "#efefef"
                            }}
                        />
                    </Grid>
                </Grid>
            }

            {selectedTab === "Skill Analysis Section" &&
                <Grid item align='center' xs={12} style={{ paddingTop: "30px" }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <ButtonGroup fullWidth>
                                <Button
                                    onClick={() => { props.setSelectedTestType("listening") }}
                                    style={{
                                        borderColor: "#880000",
                                        color: props.selectedTestType === "listening" ? "white" : "#880000",
                                        backgroundColor: props.selectedTestType === "listening" ? "#880000" : "",
                                        textTransform: "none",
                                    }}
                                >
                                    Listening
                                </Button>
                                <Button
                                    onClick={() => { props.setSelectedTestType("reading") }}
                                    style={{
                                        borderColor: "#880000",
                                        color: props.selectedTestType === "reading" ? "white" : "#880000",
                                        backgroundColor: props.selectedTestType === "reading" ? "#880000" : "",
                                        textTransform: "none"
                                    }}
                                >
                                    Reading
                                </Button>
                                <Button
                                    disabled={!props.isGotWritingResult}
                                    onClick={() => { props.setSelectedTestType("writing") }}
                                    style={{
                                        borderColor: props.isGotWritingResult ? "#880000" : "#a6a6a6",
                                        borderLeftColor: "#880000",
                                        color: props.isGotWritingResult ? (props.selectedTestType === "writing" ? "white" : "#880000") : "#a6a6a6",
                                        backgroundColor: props.selectedTestType === "writing" ? "#880000" : "",
                                        textTransform: "none"
                                    }}
                                >
                                    Writing
                                </Button>
                                <Button
                                    disabled={!props.isGotSpeakingResult}
                                    onClick={() => { props.setSelectedTestType("speaking") }}
                                    style={{
                                        borderColor: props.isGotSpeakingResult ? "#880000" : "#a6a6a6",
                                        color: props.isGotSpeakingResult ? (props.selectedTestType === "speaking" ? "white" : "#880000") : "#a6a6a6",
                                        backgroundColor: props.selectedTestType === "speaking" ? "#880000" : "",
                                        textTransform: "none"
                                    }}
                                >
                                    Speaking
                                </Button>
                            </ButtonGroup>
                        </Grid>

                        {props.selectedTestType !== "writing" && <Grid item xs={12}>
                            <Accordion defaultExpanded style={{ borderRadius: "15px" }} >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    style={{
                                        backgroundColor: "#25333e",
                                        color: "white",
                                        borderTopLeftRadius: "15px",
                                        borderTopRightRadius: "15px"
                                    }}
                                >
                                    <Typography variant="body1" style={{ fontWeight: "bold" }}>
                                        Introduction
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails style={{
                                    backgroundColor: "#dbecf6",
                                    borderBottomLeftRadius: "15px",
                                    borderBottomRightRadius: "15px"
                                }}>
                                    <Grid container spacing={4}>
                                        {/* <Grid item xs={2} style={{ backgroundColor: "#42647d", borderBottomLeftRadius: "15px" }}>
                                                <Typography variant="h5" style={{ color: "white", padding: "50px 0 50px" }}>
                                                    Band {props.isLoading ? <Skeleton /> : props.reportObj?.test_result?.score[props.selectedTestType]?.score}
                                                </Typography>
                                            </Grid> */}
                                        <Grid item xs={12}>
                                            <Typography variant="body1" align="left" style={{ padding: "20px 0 20px" }}>
                                                {props.isLoading ? <Skeleton /> : props.reportObj?.test_result?.score[props.selectedTestType]?.score_introduction}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>}

                        {props.selectedTestType === "writing" &&
                            <Grid container spacing={2}>
                                <Grid item align="right" xs={12}>
                                    <Button
                                        disableElevation
                                        variant="contained"
                                        color="default"
                                        startIcon={<GetAppRoundedIcon />}
                                        onClick={downloadWritingFile}
                                        style={{ backgroundColor: '#25333e', color: 'white', textTransform: 'none' }}
                                    >
                                        Download Teacher's Comment
                                    </Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <Accordion defaultExpanded style={{ borderRadius: "15px" }} >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                            style={{
                                                backgroundColor: "#25333e",
                                                color: "white",
                                                borderTopLeftRadius: "15px",
                                                borderTopRightRadius: "15px"
                                            }}
                                        >
                                            <Typography variant="body1" style={{ fontWeight: "bold" }}>
                                                Introduction
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails style={{
                                            backgroundColor: "#dbecf6",
                                            borderBottomLeftRadius: "15px",
                                            borderBottomRightRadius: "15px"
                                        }}>
                                            <Typography variant="body1" align="left" style={{ padding: "20px 0 20px" }}>
                                                <p>IELTS consists of two tasks: Report and Academic Essay. Task 1 tests the candidate&#39;s ability to recognise the given information&#39;s key features and write a 150-word report,&nbsp; using appropriate vocabulary and correct grammar.</p>

                                                <p>However, Task 2 assesses the candidates&#39; skills in interpreting, forming ideas, and writing one academic essay with a minimum of 250 words, using a wide range of vocabulary and grammar.</p>

                                                <p>Although Writing for IELTS does not reflect a complete set of skills needed for actual academic writing, it frames a comprehensive evaluation of writing skills in the English language.</p>

                                                <p>This section of the IELTS test is evaluated manually by two examiners. Both writing tasks are evaluated separately using 4 writing criteria: task achievement, cohesion and coherence, lexical resource, and grammatical range and accuracy.</p>
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                                <Grid item xs={12}>
                                    <Accordion defaultExpanded style={{ borderRadius: "15px" }} >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                            style={{
                                                backgroundColor: "#25333e",
                                                color: "white",
                                                borderTopLeftRadius: "15px",
                                                borderTopRightRadius: "15px"
                                            }}
                                        >
                                            <Typography variant="body1" style={{ fontWeight: "bold" }}>
                                                Examiner's Comment:
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails style={{
                                            backgroundColor: "#dbecf6",
                                            borderBottomLeftRadius: "15px",
                                            borderBottomRightRadius: "15px"
                                        }}>
                                            <Grid container spacing={4}>
                                                <Grid item xs={12}>
                                                    <Typography variant="body1" align="left" style={{ padding: "20px 0 20px", whiteSpace: 'pre-line' }}>
                                                        {props.isLoading ? <Skeleton /> : props.writingNotesObj?.note?.comment}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            </Grid>
                        }

                        {props.selectedTestType === "reading" &&
                            <Grid item xs={12}>
                                <AreaOfImprovement reportObj={props.reportObj} />
                            </Grid>
                        }

                        {props.reportObj?.test_result?.score[props.selectedTestType]?.section?.map((section, index) => {
                            return CheckDisplaySection(section, props.selectedTestType) && (<Grid key={section.id} item xs={12} style={{ paddingTop: '50px' }}>
                                <Accordion defaultExpanded style={{ borderRadius: "15px" }} >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        style={{
                                            backgroundColor: "#6C0000",
                                            color: "white",
                                            borderTopLeftRadius: "15px",
                                            borderTopRightRadius: "15px"
                                        }}
                                    >
                                        <Typography variant="body1" style={{ fontWeight: "bold" }}>
                                            {/* Section: {props.isLoading ? <Skeleton /> : props.selectedTestType === "reading" ? (SplitPascalCaseToString(section.no)) : `${section.no} (${section.question.map((text, index) => { return `${index === 0 ? '' : ' '}${SplitPascalCaseToString(text)}` })})`} */}
                                            Section: {props.isLoading ? <Skeleton /> : props.selectedTestType === "reading" ? (SplitPascalCaseToString(section.no)) : `${section.no}`}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails style={{
                                        backgroundColor: "#FFE4E4",
                                        borderBottomLeftRadius: "15px",
                                        borderBottomRightRadius: "15px"
                                    }}>
                                        <Grid container spacing={4}>
                                            <Grid item xs={2} style={{ backgroundColor: "#880000", borderBottomLeftRadius: "15px" }}>
                                                <Typography variant="h6" style={{ color: "white", padding: "50px 0 50px" }}>
                                                    Your score is {props.selectedTestType === "reading" ? `${checkReadingPercentage(section.question, section.raw_score)}%` : `${checkListeningPercentage(section, section.raw_score)}%`}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={10}>
                                                <Typography variant="body1" align="left" style={{ padding: "20px 0 20px" }}>
                                                    {props.isLoading ? <Skeleton /> : MapReadingBandScore(section, props.selectedTestType, section.description)}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>)
                        }
                        )}
                    </Grid>
                </Grid>
            }

            {(selectedTab === "Skill Analysis Section" && props.selectedTestType === "speaking") &&
                <Grid container spacing={2}>
                    <Grid item xs={12} style={{ paddingTop: '30px' }}>
                        <Accordion defaultExpanded style={{ borderRadius: "15px" }} >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                style={{
                                    backgroundColor: "#25333e",
                                    color: "white",
                                    borderTopLeftRadius: "15px",
                                    borderTopRightRadius: "15px"
                                }}
                            >
                                <Typography variant="body1" style={{ fontWeight: "bold" }}>
                                    Examiner's Comment:
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails style={{
                                backgroundColor: "#dbecf6",
                                borderBottomLeftRadius: "15px",
                                borderBottomRightRadius: "15px"
                            }}>
                                <Grid container spacing={4}>
                                    {/* <Grid item xs={2} style={{ backgroundColor: "#42647d", borderBottomLeftRadius: "15px" }}>
                                                <Typography variant="h5" style={{ color: "white", padding: "50px 0 50px" }}>
                                                    Band {props.isLoading ? <Skeleton /> : props.reportObj?.test_result?.score[props.selectedTestType]?.score}
                                                </Typography>
                                            </Grid> */}
                                    <Grid item xs={12}>
                                        <Typography variant="body1" align="left" style={{ padding: "20px 0 20px", whiteSpace: 'pre-line' }}>
                                            {props.isLoading ? <Skeleton /> : props.speakingNotesObj?.note?.comment}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                    <Grid item xs={12} style={{ paddingTop: '50px' }}>
                        <Typography align="left" variant="h6" style={{ fontWeight: "bold" }}>
                            {props.isLoading ? <Skeleton /> : "Speaking Criteria"}
                        </Typography>
                        <Chart
                            chartType="BarChart"
                            width="100%"
                            height="400px"
                            data={[
                                ["Task", "Score", { role: 'annotation' }],
                                ["Fluency and Coherence", parseFloat(props.speakingNotesObj?.note?.fluency_and_coherence), parseFloat(props.speakingNotesObj?.note?.fluency_and_coherence)],
                                ["Lexical Resource", parseFloat(props.speakingNotesObj?.note?.lexical_resource), parseFloat(props.speakingNotesObj?.note?.lexical_resource)],
                                ["Grammatical Range and Accuracy", parseFloat(props.speakingNotesObj?.note?.gramatical_range_and_accuracy), parseFloat(props.speakingNotesObj?.note?.gramatical_range_and_accuracy)],
                                ["Pronunciation", parseFloat(props.speakingNotesObj?.note?.pronunciation), parseFloat(props.speakingNotesObj?.note?.pronunciation)],
                            ]}
                            options={{
                                colors: ['#880000'],
                                chartArea: { width: '60%' },
                                legend: { position: "none" },
                                height: 400,
                                backgroundColor: '#efefef',
                                bars: 'horizontal', // Set chart type to horizontal bar
                                hAxis: {
                                    title: null,
                                    minValue: 0,
                                    viewWindow: {
                                        min: 0, // set the minimum value of y-axis
                                        max: 9, // set the maximum value of y-axis
                                    }
                                },
                                vAxis: {
                                    title: null,
                                },
                                axes: {
                                    y: {
                                        0: { side: 'left' },
                                    },
                                },
                                annotations: {
                                    alwaysOutside: true,
                                    textStyle: {
                                        fontSize: 16,
                                        auraColor: 'none',
                                        color: 'black',
                                        bold: true,
                                    },
                                }
                            }}
                        />
                    </Grid>

                </Grid>
            }

            {(selectedTab === "Skill Analysis Section" && props.selectedTestType === "writing") &&
                <Grid container spacing={2}>
                    <Grid item xs={12} style={{ paddingTop: '50px' }}>
                        <Typography align="left" variant="h6" style={{ fontWeight: "bold" }}>
                            {props.isLoading ? <Skeleton /> : "Task 1"}
                        </Typography>
                        <Chart
                            chartType="BarChart"
                            width="100%"
                            height="400px"
                            data={[
                                ["Task", "Score", { role: 'annotation' }],
                                ["Task Achievement", parseFloat(props.writingNotesObj?.note?.task_1?.task_achievement), parseFloat(props.writingNotesObj?.note?.task_1?.task_achievement)],
                                ["Cohesion and Coherence", parseFloat(props.writingNotesObj?.note?.task_1?.cohesion_and_coherence), parseFloat(props.writingNotesObj?.note?.task_1?.cohesion_and_coherence)],
                                ["Lexical Resource", parseFloat(props.writingNotesObj?.note?.task_1?.lexical_resource), parseFloat(props.writingNotesObj?.note?.task_1?.lexical_resource)],
                                ["Grammatical Range and Accuracy", parseFloat(props.writingNotesObj?.note?.task_1?.grammatical_range_and_accuracy), parseFloat(props.writingNotesObj?.note?.task_1?.grammatical_range_and_accuracy)],
                            ]}
                            options={{
                                colors: ['#880000'],
                                chartArea: { width: '60%' },
                                legend: { position: "none" },
                                height: 400,
                                backgroundColor: '#efefef',
                                bars: 'horizontal', // Set chart type to horizontal bar
                                hAxis: {
                                    title: null,
                                    minValue: 0,
                                    viewWindow: {
                                        min: 0, // set the minimum value of y-axis
                                        max: 9, // set the maximum value of y-axis
                                    }
                                },
                                vAxis: {
                                    title: null,
                                },
                                axes: {
                                    y: {
                                        0: { side: 'left' },
                                    },
                                },
                                annotations: {
                                    alwaysOutside: true,
                                    textStyle: {
                                        fontSize: 16,
                                        auraColor: 'none',
                                        color: 'black',
                                        bold: true,
                                    },
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography align="left" variant="h6" style={{ fontWeight: "bold" }}>
                            {props.isLoading ? <Skeleton /> : "Task 2"}
                        </Typography>
                        <Chart
                            chartType="BarChart"
                            width="100%"
                            height="400px"
                            data={[
                                ["Task", "Score", { role: 'annotation' }],
                                ["Task Achievement", parseFloat(props.writingNotesObj?.note?.task_2?.task_achievement), parseFloat(props.writingNotesObj?.note?.task_2?.task_achievement)],
                                ["Cohesion and Coherence", parseFloat(props.writingNotesObj?.note?.task_2?.cohesion_and_coherence), parseFloat(props.writingNotesObj?.note?.task_2?.cohesion_and_coherence)],
                                ["Lexical Resource", parseFloat(props.writingNotesObj?.note?.task_2?.lexical_resource), parseFloat(props.writingNotesObj?.note?.task_2?.lexical_resource)],
                                ["Grammatical Range and Accuracy", parseFloat(props.writingNotesObj?.note?.task_2?.grammatical_range_and_accuracy), parseFloat(props.writingNotesObj?.note?.task_2?.grammatical_range_and_accuracy)],
                            ]}
                            options={{
                                colors: ['#880000'],
                                chartArea: { width: '60%' },
                                legend: { position: "none" },
                                height: 400,
                                backgroundColor: '#efefef',
                                bars: 'horizontal', // Set chart type to horizontal bar
                                hAxis: {
                                    title: null,
                                    minValue: 0,
                                    viewWindow: {
                                        min: 0, // set the minimum value of y-axis
                                        max: 9, // set the maximum value of y-axis
                                    }
                                },
                                vAxis: {
                                    title: null,
                                },
                                axes: {
                                    y: {
                                        0: { side: 'left' },
                                    },
                                },
                                annotations: {
                                    alwaysOutside: true,
                                    textStyle: {
                                        fontSize: 16,
                                        auraColor: 'none',
                                        color: 'black',
                                        bold: true,
                                    },
                                }
                            }}
                        />
                    </Grid>
                </Grid>
            }

            {/*(selectedTab === "Skill Analysis Section" && props.selectedTestType === "writing") &&
                <Grid container spacing={2}>
                    <Grid item align='center' xs={6}>
                        <Grid container>
                            <Grid item xs={12} style={{ paddingTop: '50px' }}>
                                <Accordion defaultExpanded style={{ borderRadius: "15px" }} >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        style={{
                                            backgroundColor: "#6C0000",
                                            color: "white",
                                            borderTopLeftRadius: "15px",
                                            borderTopRightRadius: "15px"
                                        }}
                                    >
                                        <Typography variant="body1" style={{ fontWeight: "bold" }}>
                                            {props.isLoading ? <Skeleton /> : "Task 1"}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails style={{
                                        backgroundColor: "#FFE4E4",
                                        borderBottomLeftRadius: "15px",
                                        borderBottomRightRadius: "15px"
                                    }}>
                                        <Grid container spacing={4}>
                                            <Grid item xs={3} style={{ backgroundColor: "#880000", borderBottomLeftRadius: "15px" }}>
                                                <Typography variant="h6" style={{ color: "white", padding: "50px 0 50px" }}>
                                                    Cohesion and Coherence
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <Typography variant="body1" align="left" style={{ padding: "20px 0 20px" }}>
                                                    {props.isLoading ? <Skeleton /> : props.writingNotesObj?.note?.task_1?.cohesion_and_coherence}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>

                            <Grid item xs={12} style={{ paddingTop: '50px' }}>
                                <Accordion defaultExpanded style={{ borderRadius: "15px" }} >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        style={{
                                            backgroundColor: "#6C0000",
                                            color: "white",
                                            borderTopLeftRadius: "15px",
                                            borderTopRightRadius: "15px"
                                        }}
                                    >
                                        <Typography variant="body1" style={{ fontWeight: "bold" }}>
                                            {props.isLoading ? <Skeleton /> : "Task 1"}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails style={{
                                        backgroundColor: "#FFE4E4",
                                        borderBottomLeftRadius: "15px",
                                        borderBottomRightRadius: "15px"
                                    }}>
                                        <Grid container spacing={4}>
                                            <Grid item xs={3} style={{ backgroundColor: "#880000", borderBottomLeftRadius: "15px" }}>
                                                <Typography variant="h6" style={{ color: "white", padding: "50px 0 50px" }}>
                                                    Grammatical Range and Accuracy
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <Typography variant="body1" align="left" style={{ padding: "20px 0 20px" }}>
                                                    {props.isLoading ? <Skeleton /> : props.writingNotesObj?.note?.task_1?.grammatical_range_and_accuracy}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>

                            <Grid item xs={12} style={{ paddingTop: '50px' }}>
                                <Accordion defaultExpanded style={{ borderRadius: "15px" }} >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        style={{
                                            backgroundColor: "#6C0000",
                                            color: "white",
                                            borderTopLeftRadius: "15px",
                                            borderTopRightRadius: "15px"
                                        }}
                                    >
                                        <Typography variant="body1" style={{ fontWeight: "bold" }}>
                                            {props.isLoading ? <Skeleton /> : "Task 1"}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails style={{
                                        backgroundColor: "#FFE4E4",
                                        borderBottomLeftRadius: "15px",
                                        borderBottomRightRadius: "15px"
                                    }}>
                                        <Grid container spacing={4}>
                                            <Grid item xs={3} style={{ backgroundColor: "#880000", borderBottomLeftRadius: "15px" }}>
                                                <Typography variant="h6" style={{ color: "white", padding: "50px 0 50px" }}>
                                                    Task Achievement
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <Typography variant="body1" align="left" style={{ padding: "20px 0 20px" }}>
                                                    {props.isLoading ? <Skeleton /> : props.writingNotesObj?.note?.task_1?.task_achievement}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>

                            <Grid item xs={12} style={{ paddingTop: '50px' }}>
                                <Accordion defaultExpanded style={{ borderRadius: "15px" }} >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        style={{
                                            backgroundColor: "#6C0000",
                                            color: "white",
                                            borderTopLeftRadius: "15px",
                                            borderTopRightRadius: "15px"
                                        }}
                                    >
                                        <Typography variant="body1" style={{ fontWeight: "bold" }}>
                                            {props.isLoading ? <Skeleton /> : "Task 1"}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails style={{
                                        backgroundColor: "#FFE4E4",
                                        borderBottomLeftRadius: "15px",
                                        borderBottomRightRadius: "15px"
                                    }}>
                                        <Grid container spacing={4}>
                                            <Grid item xs={3} style={{ backgroundColor: "#880000", borderBottomLeftRadius: "15px" }}>
                                                <Typography variant="h6" style={{ color: "white", padding: "50px 0 50px" }}>
                                                    Lexical Resource
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <Typography variant="body1" align="left" style={{ padding: "20px 0 20px" }}>
                                                    {props.isLoading ? <Skeleton /> : props.writingNotesObj?.note?.task_1?.lexical_resource}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item align='center' xs={6}>
                        <Grid container>
                            <Grid item xs={12} style={{ paddingTop: '50px' }}>
                                <Accordion defaultExpanded style={{ borderRadius: "15px" }} >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        style={{
                                            backgroundColor: "#6C0000",
                                            color: "white",
                                            borderTopLeftRadius: "15px",
                                            borderTopRightRadius: "15px"
                                        }}
                                    >
                                        <Typography variant="body1" style={{ fontWeight: "bold" }}>
                                            {props.isLoading ? <Skeleton /> : "Task 2"}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails style={{
                                        backgroundColor: "#FFE4E4",
                                        borderBottomLeftRadius: "15px",
                                        borderBottomRightRadius: "15px"
                                    }}>
                                        <Grid container spacing={4}>
                                            <Grid item xs={3} style={{ backgroundColor: "#880000", borderBottomLeftRadius: "15px" }}>
                                                <Typography variant="h6" style={{ color: "white", padding: "50px 0 50px" }}>
                                                    Cohesion and Coherence
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <Typography variant="body1" align="left" style={{ padding: "20px 0 20px" }}>
                                                    {props.isLoading ? <Skeleton /> : props.writingNotesObj?.note?.task_2?.cohesion_and_coherence}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>

                            <Grid item xs={12} style={{ paddingTop: '50px' }}>
                                <Accordion defaultExpanded style={{ borderRadius: "15px" }} >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        style={{
                                            backgroundColor: "#6C0000",
                                            color: "white",
                                            borderTopLeftRadius: "15px",
                                            borderTopRightRadius: "15px"
                                        }}
                                    >
                                        <Typography variant="body1" style={{ fontWeight: "bold" }}>
                                            {props.isLoading ? <Skeleton /> : "Task 2"}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails style={{
                                        backgroundColor: "#FFE4E4",
                                        borderBottomLeftRadius: "15px",
                                        borderBottomRightRadius: "15px"
                                    }}>
                                        <Grid container spacing={4}>
                                            <Grid item xs={3} style={{ backgroundColor: "#880000", borderBottomLeftRadius: "15px" }}>
                                                <Typography variant="h6" style={{ color: "white", padding: "50px 0 50px" }}>
                                                    Grammatical Range and Accuracy
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <Typography variant="body1" align="left" style={{ padding: "20px 0 20px" }}>
                                                    {props.isLoading ? <Skeleton /> : props.writingNotesObj?.note?.task_2?.grammatical_range_and_accuracy}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>

                            <Grid item xs={12} style={{ paddingTop: '50px' }}>
                                <Accordion defaultExpanded style={{ borderRadius: "15px" }} >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        style={{
                                            backgroundColor: "#6C0000",
                                            color: "white",
                                            borderTopLeftRadius: "15px",
                                            borderTopRightRadius: "15px"
                                        }}
                                    >
                                        <Typography variant="body1" style={{ fontWeight: "bold" }}>
                                            {props.isLoading ? <Skeleton /> : "Task 2"}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails style={{
                                        backgroundColor: "#FFE4E4",
                                        borderBottomLeftRadius: "15px",
                                        borderBottomRightRadius: "15px"
                                    }}>
                                        <Grid container spacing={4}>
                                            <Grid item xs={3} style={{ backgroundColor: "#880000", borderBottomLeftRadius: "15px" }}>
                                                <Typography variant="h6" style={{ color: "white", padding: "50px 0 50px" }}>
                                                    Task Achievement
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <Typography variant="body1" align="left" style={{ padding: "20px 0 20px" }}>
                                                    {props.isLoading ? <Skeleton /> : props.writingNotesObj?.note?.task_2?.task_achievement}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>

                            <Grid item xs={12} style={{ paddingTop: '50px' }}>
                                <Accordion defaultExpanded style={{ borderRadius: "15px" }} >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        style={{
                                            backgroundColor: "#6C0000",
                                            color: "white",
                                            borderTopLeftRadius: "15px",
                                            borderTopRightRadius: "15px"
                                        }}
                                    >
                                        <Typography variant="body1" style={{ fontWeight: "bold" }}>
                                            {props.isLoading ? <Skeleton /> : "Task 2"}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails style={{
                                        backgroundColor: "#FFE4E4",
                                        borderBottomLeftRadius: "15px",
                                        borderBottomRightRadius: "15px"
                                    }}>
                                        <Grid container spacing={4}>
                                            <Grid item xs={3} style={{ backgroundColor: "#880000", borderBottomLeftRadius: "15px" }}>
                                                <Typography variant="h6" style={{ color: "white", padding: "50px 0 50px" }}>
                                                    Lexical Resource
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <Typography variant="body1" align="left" style={{ padding: "20px 0 20px" }}>
                                                    {props.isLoading ? <Skeleton /> : props.writingNotesObj?.note?.task_2?.lexical_resource}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                                */}
        </Grid>
    )

    return <ThemeProvider theme={theme}>
        <PageBackdrop isLoading={isLoading} />
        <Hidden mdUp>
            {mobileUI}
        </Hidden>
        <Hidden smDown>
            {webUI}
        </Hidden>
    </ThemeProvider>
};

export default compose(
    withStyles(useStyles)
)(ScoreVisualization);
