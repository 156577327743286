//React
import { Component } from 'react';
import { compose } from 'recompose';

//Material UI
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%'
    },
    paperRoot: {
        padding: theme.spacing(2),
        textAlign: 'left'
    },
    paperItem: {
        padding: theme.spacing(2),
        textAlign: 'left',
        height: '810px',
        maxHeight: '810px',
        overflow: 'auto',
    },
    testAlignLeft: {
        padding: theme.spacing(2),
    },
    gridContainerRoot: {
        marginTop: 80
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    marginLeft: {
        marginLeft: 20
    },
    marginTop: {
        marginTop: 20
    },
    margin: {
        margin: 5
    }
});

class CompleteTheTables extends Component {
    constructor() {
        super();
        this.state = {
            answerArr: [
            ],
            answerArrToRequest: [
            ]
        };
    }

    componentDidMount = async () => {

    }

    handleOnFocusTextField = (event, number) => {
        if (!this.state.answerArr[event.target.value]) {
            this.state.answerArr[event.target.value] = '';
            this.setState({
                answerArr: this.state.answerArr
            });
        }
    }

    handleAnswerQuestion = (event, index) => {
        this.state.answerArr[index] = event.target.value;
        this.state.answerArrToRequest[index - 1] = event.target.value;
        console.log(this.state.answerArr);
        this.state.answerObj.answers[this.props.currentTestObject.questions[0].no] = this.state.answerArr;
        this.state.answerObjToRequest.answers[this.props.currentTestObject.questions[0].no] = this.state.answerArrToRequest;
        console.log(this.state.answerObjToRequest);
    }

    onGetAnswer = (event, questionNo, index) => {
        //this.state.answerObj.[this.props.currentTestObject.questions[0].no][index] = event.target.value;
        console.log(this.state.answerObj);
        console.log(event.target.value);
    }

    render() {
        const { classes } = this.props;
        return (
            <Grid container>
                <Grid item align='left' xs={12}>
                    <Typography variant="h4" gutterBottom  >
                        <Box fontWeight="fontWeightBold" display='inline'>Question {this.props.currentTestObject.questions[0].no}</Box>
                    </Typography>
                    <Typography variant="h6" gutterBottom >
                        {this.props.currentTestObject.section}
                    </Typography>
                </Grid>
                <Grid item align='left' xs={12} style={{ marginTop: '50px' }}>
                    <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold' }} >
                        {this.props.currentTestObject.questions[0].table_title}
                    </Typography>
                </Grid>
                <Grid item align='left' xs={12} style={{ marginTop: '20px' }}>
                    <Typography variant="subtitle1" gutterBottom >
                        <Box fontWeight="fontWeightBold" display='inline'>Items</Box>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12}>
                            {this.props.currentTestObject.questions[0].columnL.map((q, index) =>
                                <Grid container>
                                    <Grid item xs={6} align='left'>
                                        <Grid item xs={12} align='left' style={{ margin: '22px' }}>
                                            <Typography variant="body1" align='left' style={{ fontSize: '1.2rem' }}>
                                                {this.props.currentTestObject.questions[0].columnL[index]}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6} align='left'>
                                        <Grid item xs={12} align='left' style={{ margin: '20px' }}>
                                            <Typography variant="body1" align='left' style={{ fontSize: '1.2rem' }}>
                                                {this.props.currentTestObject.questions[0].columnR[index].split('_').map((text, textIndex) => (
                                                    <span>
                                                        {text}
                                                        {(textIndex + 1 < this.props.currentTestObject.questions[0].columnR[index].split('_').length) &&
                                                            <TextField
                                                                disabled
                                                                size="small"
                                                                defaultValue={Number(this.props.currentTestObject.questions[0].no.split('-')[0]) + index + textIndex + (index !== 0 ? this.props.currentTestObject.questions[0].columnR[index - 1].split("_").length - 2 : 0) - (index !== 0 && this.props.currentTestObject.questions[0].columnR[index - 1].split("_").length === 1 ? 1 : 0)}
                                                                id="outlined-basic"
                                                                variant="outlined"
                                                                autoComplete="new-password"
                                                                onChange={(event) => {
                                                                    this.handleAnswerQuestion(event, Number(this.props.currentTestObject.questions[0].no.split('-')[0]) + index + textIndex + (index !== 0 ? this.props.currentTestObject.questions[0].columnR[index - 1].split("_").length - 2 : 0) - (index !== 0 && this.props.currentTestObject.questions[0].columnR[index - 1].split("_").length === 1 ? 1 : 0))
                                                                }}
                                                                onFocus={(event) => { this.handleOnFocusTextField(event, Number(this.props.currentTestObject.questions[0].no.split('-')[0]) + index + textIndex + (index !== 0 ? this.props.currentTestObject.questions[0].columnR[index - 1].split("_").length - 2 : 0) - (index !== 0 && this.props.currentTestObject.questions[0].columnR[index - 1].split("_").length === 1 ? 1 : 0)) }}
                                                                inputProps={{ style: { textAlign: 'center', height: '5px' } }} />}
                                                    </span>
                                                ))}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid >
        )
    }
}

export default compose(
    withStyles(useStyles)
)(CompleteTheTables);