import React, { useEffect } from "react";

//MUI
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";

import { ThemeProvider, createMuiTheme, withStyles } from "@material-ui/core/styles";
import { compose } from "recompose";

//Font
import "typeface-glacial-indifference";

//Components
import BandScoreDescription from "./components/reportPage/BandScoreDescription";
import OverallScore from "./components/reportPage/OverallScore";
import ScoreVisualization from "./components/reportPage/ScoreVisualization";
import UserProfile from "./components/reportPage/UserProfile";
//BMAT
import SpectrumChart from "./components/reportPage/BMAT/SpectrumChart";

//Funtions
import { BandScoreCalculation } from "../../../functions/testReportFuntions";
import {
  GetTestReportById
} from "../../../services/testReportServices";

//Others
import { Link } from "react-router-dom";

const axios = require("axios").default;
const FormData = require("form-data");
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

const useStyles = (theme) => ({
  root: {
    padding: theme.spacing(12, 4),
  },
});

const theme = createMuiTheme({
  typography: {
    fontFamily: ["Roboto"].join(","),
  },
});

const NewReportPage = (props) => {
  const [reportObj, setReportObj] = React.useState({});
  const [bandScoreObj, setBandScoreObj] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);

  useEffect(async () => {
    document.body.style.overflow = "auto";
    document.body.style.backgroundColor = "#efefef";
    document.querySelector("body").scrollTo(0, 0);

    getTestReportById();
  }, []);

  const getTestReportById = async () => {
    setIsLoading(true);

    const query = new URLSearchParams(props.location.search);
    const res = await GetTestReportById(
      JSON.parse(localStorage.getItem("user")).token,
      query.get("result_id")
    );
    const bandScore = await BandScoreCalculation(res.res);
    setBandScoreObj(bandScore);

    if (res.success) {
      console.log(res.res);
      setReportObj(res.res);
    }

    setIsLoading(false);
  };

  const mobileUI = (
    <Grid
      container
      style={{
        marginTop: "5%",
        marginBottom: "20%",
        maxWidth: window.innerWidth,
      }}
    >
      <Grid item align="center" xs={12}>
        <Box
          component="img"
          style={{ width: "50px" }}
          alt="Logo"
          src="/U Test Logo (new).png"
        />
        <Typography
          variant="h6"
          style={{ color: "#26343f", fontWeight: "bold" }}
          gutterBottom
        >
          {["IELTS", "IELTS LISTENING FREE", "IELTS READING FREE"].includes(
            reportObj.test_type
          )
            ? "IELTS"
            : "BMAT"}{" "}
          Simulation Test Report
        </Typography>
      </Grid>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <UserProfile
              reportObj={reportObj}
              bandScoreObj={bandScoreObj}
              isLoading={isLoading}
            />
          </Grid>
          <Grid item xs={12}>
            <OverallScore
              reportObj={reportObj}
              bandScoreObj={bandScoreObj}
              isLoading={isLoading}
            />
          </Grid>
          <Grid item xs={12}>
            {["IELTS", "IELTS LISTENING FREE", "IELTS READING FREE"].includes(
              reportObj.test_type
            ) && (
                <BandScoreDescription
                  reportObj={reportObj}
                  bandScoreObj={bandScoreObj}
                  isLoading={isLoading}
                />
              )}
          </Grid>
          <Grid item xs={12}>
            {["IELTS", "IELTS LISTENING FREE", "IELTS READING FREE"].includes(
              reportObj.test_type
            ) ? (
                <ScoreVisualization
                  reportObj={reportObj}
                  bandScoreObj={bandScoreObj}
                  isLoading={isLoading}
                />
              ) : (
                <SpectrumChart
                  reportObj={reportObj}
                  bandScoreObj={bandScoreObj}
                  isLoading={isLoading}
                />
              )}
          </Grid>
          <Grid item xs={12}>
            <Link
              to={`/profile`}
              style={{ textDecoration: "none", color: "#565656" }}
            >
              <Button
                variant="contained"
                size="large"
                style={{
                  backgroundColor: "#a6a6a6",
                  color: "white",
                  borderRadius: "10px",
                  textTransform: "none",
                  fontSize: "1rem",
                }}
                disableElevation
              >
                Back
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );

  const webUI = (
    <Grid
      container
      style={{
        marginTop: "5%",
        marginBottom: "20%",
        maxWidth: window.innerWidth,
      }}
    >
      <Grid item align="center" xs={12}>
        <Box
          component="img"
          style={{ width: "100px" }}
          alt="Logo"
          src="/U Test Logo (new).png"
        />
        <Typography
          variant="h5"
          style={{ color: "#26343f", fontWeight: "bold" }}
          gutterBottom
        >
          {["IELTS", "IELTS LISTENING FREE", "IELTS READING FREE"].includes(
            reportObj.test_type
          )
            ? "IELTS"
            : "BMAT"}{" "}
          Simulation Test Report
        </Typography>
      </Grid>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <UserProfile
              reportObj={reportObj}
              bandScoreObj={bandScoreObj}
              isLoading={isLoading}
            />
          </Grid>
          <Grid item xs={4}>
            <OverallScore
              reportObj={reportObj}
              bandScoreObj={bandScoreObj}
              isLoading={isLoading}
            />
          </Grid>
          <Grid item xs={12}>
            {["IELTS", "IELTS LISTENING FREE", "IELTS READING FREE"].includes(
              reportObj.test_type
            ) && (
                <BandScoreDescription
                  reportObj={reportObj}
                  bandScoreObj={bandScoreObj}
                  isLoading={isLoading}
                />
              )}
          </Grid>
          <Grid item xs={12}>
            {["IELTS", "IELTS LISTENING FREE", "IELTS READING FREE"].includes(
              reportObj.test_type
            ) ? (
                <ScoreVisualization
                  reportObj={reportObj}
                  bandScoreObj={bandScoreObj}
                  isLoading={isLoading}
                />
              ) : (
                <SpectrumChart
                  reportObj={reportObj}
                  bandScoreObj={bandScoreObj}
                  isLoading={isLoading}
                />
              )}
          </Grid>
          <Grid item xs={12}>
            <Link
              to={`/profile`}
              style={{ textDecoration: "none", color: "#565656" }}
            >
              <Button
                variant="contained"
                size="large"
                style={{
                  backgroundColor: "#a6a6a6",
                  color: "white",
                  borderRadius: "10px",
                  textTransform: "none",
                  fontSize: "1rem",
                }}
                disableElevation
              >
                Back
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );

  return (
    <ThemeProvider theme={theme}>
      <Hidden mdUp>{mobileUI}</Hidden>
      <Hidden smDown>{webUI}</Hidden>
    </ThemeProvider>
  );
};

export default compose(withStyles(useStyles))(NewReportPage);
