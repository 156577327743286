//React
import { Component } from "react";
import { compose } from "recompose";

//Material UI
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

//Others libraries
//react-router-dom

const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
});

class LoadingDialog extends Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount = async () => {};

  render() {
    const { classes } = this.props;
    return (
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        aria-labelledby="customized-dialog-title"
        open={this.props.open}
      >
        <DialogContent dividers>
          <Grid container spacing={1} style={{ padding: "50px" }}>
            <Grid align="center" item xs={12}>
              <Box position="relative" display="inline-flex">
                <CircularProgress
                  variant="determinate"
                  size={100}
                  value={this.props.percentage}
                  style={{ color: "red" }}
                />
                <Box
                  top={0}
                  left={0}
                  bottom={0}
                  right={0}
                  position="absolute"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography
                    variant="h6"
                    component="div"
                    color="textSecondary"
                  >{`${Math.round(this.props.percentage)}%`}</Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid align="center" item xs={12}>
            <Typography gutterBottom>
              {this.props.statusText}
            </Typography>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}

export default compose(withStyles(useStyles))(LoadingDialog);
