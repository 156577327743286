import React, { useEffect } from 'react';

//MUI
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { ThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';

//Functions
import {
    CheckDisplaySection,
} from "../../../../../functions/testReportFuntions";

//Font
import 'typeface-glacial-indifference';

//Others
const axios = require('axios').default;
const FormData = require('form-data');
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

const useStyles = theme => ({
    root: {
        padding: theme.spacing(12, 4),
    }
});

const theme = createMuiTheme({
    typography: {
        fontFamily: [
            'Roboto'
        ].join(','),
    },
});

const AreaOfImprovement = (props) => {
    const readingTestType = [
        {
            name: "Information Identification",
            id: "InformationIdentification"
        },
        {
            name: "List of Headings",
            id: "ListOfHeadings"
        },
        {
            name: "Note and Summary Completion",
            id: "NoteAndSummaryCompletion"
        },
        {
            name: "Matching Sentence Endings",
            id: "MatchingSentenceEndings"
        },
        {
            name: "Matching Features",
            id: "MatchingFeatures"
        },
        {
            name: "Multiple Choices",
            id: "MultipleChoices"
        },
    ]

    const validateExistingType = (type) => {
        const filteredObj = props.reportObj?.test_result?.score?.reading?.section?.filter((section) => { return section.no === type.id });
        if (filteredObj.length > 0) {
            return CheckDisplaySection(filteredObj[0], 'reading');
        } else {
            return false
        }
    }

    const mobileUI = (<Grid container spacing={2} style={{ paddingLeft: "10px", paddingTop: "20px" }}>
        <Grid item align="left" xs={12}>
            <Typography variant="h6" style={{ fontWeight: "bold" }}>
                Areas of Improvement
            </Typography>
        </Grid>
        <Grid item align="left" xs={12} style={{ paddingLeft: "50px" }}>
            <FormControl component="fieldset">
                <FormGroup>
                    {readingTestType.map((type) => {
                        return (validateExistingType(type) &&
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        disabled
                                        checked={validateExistingType(type)}
                                        name="checkedB"
                                        style={{ color: "#880000" }}
                                    />
                                }
                                label={type.name}
                            />
                        )
                    })}
                </FormGroup>
            </FormControl>
        </Grid>
    </Grid>)

    const webUI = (
        <Grid container spacing={2} style={{ paddingLeft: "30px" }}>
            <Grid item align="left" xs={12}>
                <Typography variant="h6" style={{ fontWeight: "bold" }}>
                    Areas of Improvement
                </Typography>
            </Grid>
            <Grid item align="left" xs={12} style={{ paddingLeft: "60px" }}>
                <FormControl component="fieldset">
                    <FormGroup>
                        {readingTestType.map((type) => {
                            return (validateExistingType(type) &&
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            disabled
                                            checked={validateExistingType(type)}
                                            name="checkedB"
                                            style={{ color: "#880000" }}
                                        />
                                    }
                                    label={<Typography style={{ color: "black" }}>{type.name}</Typography>}
                                />
                            )
                        })}
                    </FormGroup>
                </FormControl>
            </Grid>
        </Grid>
    )

    return <ThemeProvider theme={theme}>
        <Hidden mdUp>
            {mobileUI}
        </Hidden>
        <Hidden smDown>
            {webUI}
        </Hidden>
    </ThemeProvider>
};

export default compose(
    withStyles(useStyles)
)(AreaOfImprovement);
