import React from 'react';

//Material UI
import Grid from '@material-ui/core/Grid';
import MuiAlert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';


//Others
import { withStyles } from '@material-ui/core/styles';
import { Component } from 'react';
import { compose } from 'recompose';

const axios = require('axios').default;
const FormData = require('form-data');
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

const useStyles = theme => ({
    root: {
        margin: 15,
        marginTop: 50
    },
    margin: {
        margin: theme.spacing(1),
    },
    buttonColor: {
        backgroundColor: '#76323F',
        color: 'white'
    }
});

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class WritingBaseQuestion extends Component {
    constructor() {
        super()
        this.state = {
            tempQuestionCount: [1, 2]
        };
    }

    componentDidMount = async () => {

    }

    handleEditQuestionNumber = async (event) => {
        this.props.questionObj.no = event.target.value;
    }

    handleEditPassage = async (event) => {
        this.props.questionObj.passage = event.target.value;
    }

    handleEditQuestion = async (event) => {
        this.props.questionObj.question = event.target.value;
    }

    render() {
        const { classes } = this.props;

        return (
            <Grid item xs={12}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                                    No.
                                </Typography>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    onChange={this.handleEditQuestionNumber}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                                    Passage/Image url
                                </Typography>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    onChange={this.handleEditPassage}
                                    fullWidth
                                    multiline
                                    maxRows={4}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                                    Question
                                </Typography>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    onChange={this.handleEditQuestion}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}
export default compose(
    withStyles(useStyles)
)(WritingBaseQuestion);