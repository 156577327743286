import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing(1),
    },
}));

export default function LeaveFullScreenDialog(props) {
    return (
        <React.Fragment>
            <Dialog
                fullWidth={false}
                maxWidth={"sm"}
                open={props.isOpenDialog}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogTitle id="max-width-dialog-title">
                    <Typography variant="h5" style={{ fontWeight: "bold" }}>
                        You are exit from the full-screen mode
                    </Typography>
                </DialogTitle>
                <DialogContent divißers>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="body1" gutterBottom>
                                Please remain in full-screen mode until the end of the test session. 
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                Click <span style={{ fontWeight: "bold" }}>Back to full-screen</span> to enter full-screen mode.
                            </Typography>
                        </Grid>
                        <Grid item align="center" xs={12} style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                            <Button disableElevation variant="contained" onClick={props.DoFullScreen} style={{ textTransform: "none", color: 'white', backgroundColor: '#991514', fontWeight: "bold" }}>Back to full-screen</Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}
