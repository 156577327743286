import React from 'react';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import { withStyles } from '@material-ui/core/styles';
import { Component } from 'react';
import { compose } from 'recompose';

const useStyles = theme => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    }
});

class LoadingBackdrop extends Component {
    render() {
        const { classes } = this.props;

        return (
            <Backdrop className={classes.backdrop} open={this.props.isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }
}
export default compose(
    withStyles(useStyles)
)(LoadingBackdrop);