//Contants
import * as apiConstants from '../constants/apiConstants';

const axios = require('axios').default;

//=========================== GET TEST HISTORY ======================================
export async function GetAllStrategy(token) {
    try {
        const res = await axios({
            method: 'get',
            url: `${apiConstants.E_LEARNING_API_BASE_URL}/api/test/strategy`,
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });

        return res;
    } catch (err) {
        console.log(err);
        return err;
    }
}
//=========================== GET TEST HISTORY ======================================

export async function CreateStrategy(token, strategyObj) {
    try {
        const res = await axios({
            method: 'post',
            url: `${apiConstants.E_LEARNING_API_BASE_URL}/api/test/strategy`,
            headers: {
                "Authorization": `Bearer ${token}`
            },
            data: {
                strategy: [
                    strategyObj
                ]
            }
        });

        return res;
    } catch (err) {
        console.log(err);
        return err;
    }
}

export async function UpdateStrategy(token, strategyObj) {
    try {
        const res = await axios({
            method: 'put',
            url: `${apiConstants.E_LEARNING_API_BASE_URL}/api/test/strategy/${strategyObj._id}`,
            headers: {
                "Authorization": `Bearer ${token}`
            },
            data: {
                strategy: [
                    {
                        test_type: strategyObj.test_type,
                        part_type: strategyObj.part_type,
                        is_free: strategyObj.is_free,
                        strategy_color: strategyObj.strategy_color,
                        strategy: strategyObj.strategy
                    }
                ]
            }
        });

        return true;
    } catch (err) {
        console.log(err);
        return false;
    }
}