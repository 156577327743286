import React, { useEffect, useState } from 'react';

//MUI
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';

import { ThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';

//Constats
import * as styleConstants from '../../../../../constants/styleConstants';

//Components

//Font
import 'typeface-glacial-indifference';

//Others
import { Player, ControlBar, PlayToggle, VolumeMenuButton } from "video-react";
import Fade from 'react-reveal/Fade';
import VisibilitySensor from 'react-visibility-sensor';
import "video-react/dist/video-react.css";
import MediaQuery from 'react-responsive';
const axios = require('axios').default;
const FormData = require('form-data');
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

const useStyles = theme => ({
    root: {
        padding: theme.spacing(12, 4),
    }
});

const theme = createMuiTheme({
    typography: {
        fontFamily: [
            'Roboto'
        ].join(','),
    },
});

const OurStory = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        document.body.style.overflow = "auto";
        document.body.style.backgroundColor = "#efefef";
        document.querySelector("body").scrollTo(0, 0);
    }, []);

    const mobileUI = (<Grid container alignContent='center' alignItems='center' style={{ marginTop: "30px", maxWidth: window.innerWidth, padding: "20px" }}>
        <Grid item align='left' xs={12} style={{ marginBottom: '50px', zIndex: 1 }}>
            <Typography variant="h4" style={{ fontWeight: styleConstants.fontWeight.bold, color: '#991514' }}>
                OUR STORY
            </Typography>
        </Grid>
        <Grid item xs={12} style={{ marginBottom: "2%", zIndex: 1 }}>
            <Grid container alignItems='center'>
                <Grid item align='left' xs={12}>
                    <Typography variant="body2" style={{ fontWeight: styleConstants.fontWeight.thin, textAlign: 'justify' }}>
                        UTest Avenue first began when our team of educators recognised the existing gap in traditional preparations for proficiency and standardised tests. Over ten years in academic scenes, we have assisted hundreds and hundreds of students in their educational journeys. However, with the growing demand for our academic support, we searched for a way to maintain our values for personalised education in a more scalable and consistent approach.
                        We corporate our years of teaching experiences with innovative machine learning to offer a new way of personalised test preparations. Our platform aims to help students and users understand their existing skills and areas of improvement.
                        We take pride in our proficiently designed test materials and intelligent skill analysis system. We are also thrilled and pleased to offer our support to a broader group of users regardless of where they are.
                    </Typography>
                </Grid>
                <Grid item xs={12} align='center' style={{ marginTop: "50px" }}>
                    <Player
                        autoPlay={false}
                        preload="auto"
                        poster="/video-thumbnail.png"
                        src="/UTEST AVENUE (MAIN PAGE VID).mp4"
                        fluid={false}
                        width={300}
                    >
                        <ControlBar autoHide={false} disableDefaultControls={true}>
                            <PlayToggle />
                            <VolumeMenuButton />
                        </ControlBar>
                    </Player>
                </Grid>
            </Grid>
        </Grid>
    </Grid>)

    const webUI = (
        <MediaQuery minWidth={"768px"}>
            {(matches) =>
                matches ? <Grid container alignContent='center' alignItems='center' style={{ marginTop: "100px", maxWidth: window.innerWidth, paddingLeft: "5%", paddingRight: "5%" }}>
                    <Grid item align='left' xs={12} style={{ marginBottom: '50px', zIndex: 1 }}>
                        <VisibilitySensor onChange={(isVisible) => {
                            setIsVisible(isVisible);
                        }}>
                            <Fade top when={isVisible}>
                                <Typography variant="h3" style={{ fontWeight: styleConstants.fontWeight.bold, color: '#991514' }}>
                                    OUR STORY
                                </Typography>
                            </Fade>
                        </VisibilitySensor>
                    </Grid>
                    <Grid item xs={12} style={{ marginBottom: "2%", zIndex: 1 }}>
                        <Grid container alignItems='center' spacing={10}>
                            <Grid item align='left' xs>
                                <Fade top when={isVisible}>
                                    <Typography variant="body1" style={{ fontWeight: styleConstants.fontWeight.thin, textAlign: 'justify' }}>
                                        UTest Avenue first began when our team of educators recognised the existing gap in traditional preparations for proficiency and standardised tests. Over ten years in academic scenes, we have assisted hundreds and hundreds of students in their educational journeys. However, with the growing demand for our academic support, we searched for a way to maintain our values for personalised education in a more scalable and consistent approach.
                                        We corporate our years of teaching experiences with innovative machine learning to offer a new way of personalised test preparations. Our platform aims to help students and users understand their existing skills and areas of improvement.
                                        We take pride in our proficiently designed test materials and intelligent skill analysis system. We are also thrilled and pleased to offer our support to a broader group of users regardless of where they are.
                                    </Typography>
                                </Fade>
                            </Grid>
                            <Grid item xs={7} align='center'>
                                <Fade right when={isVisible}>
                                    <Player
                                        autoPlay={false}
                                        preload="auto"
                                        poster="/video-thumbnail.png"
                                        src="/UTEST AVENUE (MAIN PAGE VID).mp4"
                                        fluid={false}
                                        width={550}
                                    >
                                        <ControlBar autoHide={false} disableDefaultControls={true}>
                                            <PlayToggle />
                                            <VolumeMenuButton />
                                        </ControlBar>
                                    </Player>
                                </Fade>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid> : <Grid container alignContent='center' alignItems='center' style={{ marginTop: "100px", maxWidth: window.innerWidth, paddingLeft: "10%", paddingRight: "10%" }}>
                    <Grid item align='left' xs={12} style={{ marginBottom: '50px', zIndex: 1 }}>
                        <VisibilitySensor onChange={(isVisible) => {
                            setIsVisible(isVisible);
                        }}>
                            <Typography variant="h2" style={{ fontWeight: styleConstants.fontWeight.bold, color: '#991514' }}>
                                OUR STORY
                            </Typography>
                        </VisibilitySensor>
                    </Grid>
                    <Grid item xs={12} style={{ marginBottom: "2%", zIndex: 1 }}>
                        <Grid container alignItems='center' spacing={10}>
                            <Grid item align='left' xs={6}>
                                <Fade left when={isVisible}>
                                    <Typography variant="h6" style={{ fontWeight: styleConstants.fontWeight.thin, textAlign: 'justify' }}>
                                        UTest Avenue first began when our team of educators recognised the existing gap in traditional preparations for proficiency and standardised tests. Over ten years in academic scenes, we have assisted hundreds and hundreds of students in their educational journeys. However, with the growing demand for our academic support, we searched for a way to maintain our values for personalised education in a more scalable and consistent approach.
                                        We corporate our years of teaching experiences with innovative machine learning to offer a new way of personalised test preparations. Our platform aims to help students and users understand their existing skills and areas of improvement.
                                        We take pride in our proficiently designed test materials and intelligent skill analysis system. We are also thrilled and pleased to offer our support to a broader group of users regardless of where they are.
                                    </Typography>
                                </Fade>
                            </Grid>
                            <Grid item xs={6} align='center'>
                                <Fade right when={isVisible}>
                                    <Player
                                        autoPlay={false}
                                        preload="auto"
                                        poster="/video-thumbnail.png"
                                        src="/UTEST AVENUE (MAIN PAGE VID).mp4"
                                        fluid={false}
                                        width={600}
                                    >
                                        <ControlBar autoHide={false} disableDefaultControls={true}>
                                            <PlayToggle />
                                            <VolumeMenuButton />
                                        </ControlBar>
                                    </Player>
                                </Fade>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }
        </MediaQuery>)

    return <ThemeProvider theme={theme}>
        <Hidden mdUp>
            {mobileUI}
        </Hidden>
        <Hidden smDown>
            {webUI}
        </Hidden>
    </ThemeProvider>
};

export default compose(
    withStyles(useStyles)
)(OurStory);
