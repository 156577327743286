import React, { useEffect } from 'react';

//MUI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { ThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';

//Components

//Font
import 'typeface-glacial-indifference';

//Others
import { Link } from "react-router-dom";

const axios = require('axios').default;
const FormData = require('form-data');
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

const useStyles = theme => ({
    root: {
        padding: theme.spacing(12, 4),
    }
});

const theme = createMuiTheme({
    typography: {
        fontFamily: [
            'Roboto'
        ].join(','),
    },
});

const ProductPage = (props) => {
    useEffect(() => {
        document.body.style.overflow = "auto";
        document.body.style.backgroundColor = "#efefef";
        document.querySelector("body").scrollTo(0, 0);
        localStorage.setItem('readingSession', null);
        localStorage.setItem('listeningSession', null);
        localStorage.setItem('writingSession', null);
        localStorage.setItem('testSession', null);

        props.handleEndTest();
    }, []);

    const mobileUI = (<Grid container style={{ marginTop: "5%", marginBottom: "20%", maxWidth: window.innerWidth }}>
        <Grid item xs={12} style={{ marginBottom: "2%" }}>
            <Button
                disabled
                variant="contained"
                size="large"
                style={{ backgroundColor: "#991514", color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1.3rem" }}
                disableElevation
            >
                Free Test
            </Button>
        </Grid>
        <Grid item xs={12} style={{ paddingLeft: "10%", paddingRight: "10%", marginTop: "-8%" }}>
            <Paper elevation={0} style={{ backgroundColor: "#a6a6a6", borderRadius: "25px", padding: "10%" }}>
                <Grid item align="center" xs={12}>
                    <Box
                        component="img"
                        style={{ width: '200px' }}
                        alt="The house from the offer."
                        src="/Book_designed_by_Benny_Forsberg_from_the_Noun_Project_-_with_lines.png"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h3" style={{ fontWeight: "bold", color: "white" }} gutterBottom>
                        Type
                    </Typography>
                    <Typography variant="h4" style={{ color: "white" }} gutterBottom>
                        Reading
                    </Typography>
                    <Typography variant="body1" style={{ color: "white" }} >
                        IELTS Reading Test
                    </Typography>
                    <Typography variant="subtitle1" style={{ color: "white" }} gutterBottom>
                        30 mins
                    </Typography>
                </Grid>
            </Paper>
        </Grid>
        <Grid item xs={12} style={{ marginTop: "-5%" }}>
            <Button
                disabled
                variant="contained"
                size="large"
                style={{ backgroundColor: "#636363", color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1.3rem" }}
                disableElevation
            >
                Unavailable for mobile.
            </Button>
        </Grid>
        <Grid item xs={12} style={{ marginTop: "2%" }}>
            <Link
                to={`/`}
                style={{ textDecoration: 'none' }}
            >
                <Button
                    variant="contained"
                    size="large"
                    style={{ backgroundColor: "#a6a6a6", color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1rem" }}
                    disableElevation
                >
                    Back
                </Button>
            </Link>
        </Grid>

        <Grid item xs={12} style={{ marginBottom: "2%", marginTop: "50px" }}>
            <Button
                disabled
                variant="contained"
                size="large"
                style={{ backgroundColor: "#991514", color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1.3rem" }}
                disableElevation
            >
                Free Test
            </Button>
        </Grid>
        <Grid item xs={12} style={{ paddingLeft: "10%", paddingRight: "10%", marginTop: "-8%" }}>
            <Paper elevation={0} style={{ backgroundColor: "#a6a6a6", borderRadius: "25px", padding: "10%" }}>
                <Grid item align="center" xs={12}>
                    <Box
                        component="img"
                        style={{ width: '200px' }}
                        alt="The house from the offer."
                        src="/listen-icon-png-23.jpg"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h3" style={{ fontWeight: "bold", color: "white" }} gutterBottom>
                        Type
                    </Typography>
                    <Typography variant="h4" style={{ color: "white" }} gutterBottom>
                        Listening
                    </Typography>
                    <Typography variant="body1" style={{ color: "white" }} >
                        IELTS Listening Test
                    </Typography>
                    <Typography variant="subtitle1" style={{ color: "white" }} gutterBottom>
                        30 mins
                    </Typography>
                </Grid>
            </Paper>
        </Grid>
        <Grid item xs={12} style={{ marginTop: "-5%" }}>
            <Button
                disabled
                variant="contained"
                size="large"
                style={{ backgroundColor: "#636363", color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1.3rem" }}
                disableElevation
            >
                Unavailable for mobile.
            </Button>
        </Grid>
        <Grid item xs={12} style={{ marginTop: "2%" }}>
            <Link
                to={`/`}
                style={{ textDecoration: 'none' }}
            >
                <Button
                    variant="contained"
                    size="large"
                    style={{ backgroundColor: "#a6a6a6", color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1rem" }}
                    disableElevation
                >
                    Back
                </Button>
            </Link>
        </Grid>

        <Grid item xs={12} style={{ marginBottom: "2%", marginTop: "50px" }}>
            <Button
                disabled
                variant="contained"
                size="large"
                style={{ backgroundColor: "#991514", color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1.3rem" }}
                disableElevation
            >
                Free Test
            </Button>
        </Grid>
        <Grid item xs={12} style={{ paddingLeft: "10%", paddingRight: "10%", marginTop: "-8%" }}>
            <Paper elevation={0} style={{ backgroundColor: "#a6a6a6", borderRadius: "25px", padding: "10%" }}>
                <Grid item align="center" xs={12}>
                    <Box
                        component="img"
                        style={{ width: '200px', filter: 'grayscale(1)' }}
                        alt="The house from the offer."
                        src="/logo512.png"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h3" style={{ fontWeight: "bold", color: "white" }} gutterBottom>
                        Type
                    </Typography>
                    <Typography variant="h4" style={{ color: "white" }} gutterBottom>
                    TBAT
                    </Typography>
                    <Typography variant="body1" style={{ color: "white" }} >
                    TBAT FREE TEST
                    </Typography>
                    <Typography variant="subtitle1" style={{ color: "white" }} gutterBottom>
                        30 mins
                    </Typography>
                </Grid>
            </Paper>
        </Grid>
        <Grid item xs={12} style={{ marginTop: "-5%" }}>
            <Button
                disabled
                variant="contained"
                size="large"
                style={{ backgroundColor: "#636363", color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1.3rem" }}
                disableElevation
            >
                Unavailable for mobile.
            </Button>
        </Grid>
        <Grid item xs={12} style={{ marginTop: "2%" }}>
            <Link
                to={`/`}
                style={{ textDecoration: 'none' }}
            >
                <Button
                    variant="contained"
                    size="large"
                    style={{ backgroundColor: "#a6a6a6", color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1rem" }}
                    disableElevation
                >
                    Back
                </Button>
            </Link>
        </Grid>
    </Grid>)

    const webUI = (<Grid container style={{ marginTop: "5%", marginBottom: "20%", maxWidth: window.innerWidth }}>
        {/* {JSON.parse(localStorage.getItem('userSignedIn'))
            ? !(["ADMIN", "SUPER_ADMIN"].includes(JSON.parse(localStorage.getItem('user')).role)) &&
            <Grid item xs={12} style={{ marginBottom: "2%", paddingLeft: "40%", paddingRight: "40%" }}>
                <Button
                    disabled
                    variant="contained"
                    size="large"
                    style={{ backgroundColor: "#991514", color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1.3rem" }}
                    disableElevation
                >
                    Free Test
                </Button>
            </Grid>
            : <Grid item xs={12} style={{ marginBottom: "2%", paddingLeft: "40%", paddingRight: "40%" }}>
                <Button
                    disabled
                    variant="contained"
                    size="large"
                    style={{ backgroundColor: "#991514", color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1.3rem" }}
                    disableElevation
                >
                    Free Test
                </Button>
            </Grid>
        }
        {JSON.parse(localStorage.getItem('userSignedIn'))
            ? !(["ADMIN", "SUPER_ADMIN"].includes(JSON.parse(localStorage.getItem('user')).role)) &&
            <Grid item align="center" xs={12} style={{ marginTop: "-50px" }}>
                <Paper elevation={0} style={{ maxWidth: "300px", backgroundColor: "#a6a6a6", borderRadius: "25px", padding: "50px" }}>
                    <Grid item align="center" xs={12}>
                        <Box
                            component="img"
                            style={{ width: '200px' }}
                            alt="The house from the offer."
                            src="/Book_designed_by_Benny_Forsberg_from_the_Noun_Project_-_with_lines.png"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h3" style={{ fontWeight: "bold", color: "white" }} gutterBottom>
                            Type
                        </Typography>
                        <Typography variant="h4" style={{ color: "white" }} gutterBottom>
                            Reading
                        </Typography>
                        <Typography variant="body1" style={{ color: "white" }} >
                            IELTS Reading Test
                        </Typography>
                        <Typography variant="subtitle1" style={{ color: "white" }} gutterBottom>
                            30 mins
                        </Typography>
                    </Grid>
                </Paper>
            </Grid>
            : <Grid item align="center" xs={12} style={{ marginTop: "-50px" }}>
                <Paper elevation={0} style={{ maxWidth: "300px", backgroundColor: "#a6a6a6", borderRadius: "25px", padding: "50px" }}>
                    <Grid item align="center" xs={12}>
                        <Box
                            component="img"
                            style={{ width: '200px' }}
                            alt="The house from the offer."
                            src="/Book_designed_by_Benny_Forsberg_from_the_Noun_Project_-_with_lines.png"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h3" style={{ fontWeight: "bold", color: "white" }} gutterBottom>
                            Type
                        </Typography>
                        <Typography variant="h4" style={{ color: "white" }} gutterBottom>
                            Reading
                        </Typography>
                        <Typography variant="body1" style={{ color: "white" }} >
                            IELTS Reading Test
                        </Typography>
                        <Typography variant="subtitle1" style={{ color: "white" }} gutterBottom>
                            30 mins
                        </Typography>
                    </Grid>
                </Paper>
            </Grid>
        }
        {JSON.parse(localStorage.getItem('userSignedIn'))
            ? !(["ADMIN", "SUPER_ADMIN"].includes(JSON.parse(localStorage.getItem('user')).role)) &&
            <Grid item xs={12} style={{ marginTop: "-30px" }}>
                <Link
                    to="/testpage"
                    style={{ textDecoration: 'none', color: '#565656' }}
                >
                    <Button
                        variant="contained"
                        size="large"
                        style={{ backgroundColor: "#991514", color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1.3rem" }}
                        disableElevation
                    >
                        Try Now
                    </Button>
                </Link>
            </Grid>
            : <Grid item xs={12} style={{ marginTop: "-1%" }}>
                <Link
                    to="/newlogin"
                    style={{ textDecoration: 'none', color: '#565656' }}
                >
                    <Button
                        variant="contained"
                        size="large"
                        style={{ backgroundColor: "#991514", color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1.3rem" }}
                        disableElevation
                    >
                        Log in
                    </Button>
                </Link>
            </Grid>
        }
        {JSON.parse(localStorage.getItem('userSignedIn'))
            ? !(["ADMIN", "SUPER_ADMIN"].includes(JSON.parse(localStorage.getItem('user'))?.role)) &&
            <Grid item xs={12} style={{ marginTop: "0.5%" }}>
                <Link
                    to={`/`}
                    style={{ textDecoration: 'none' }}
                >
                    <Button
                        variant="contained"
                        size="large"
                        style={{ backgroundColor: "#a6a6a6", color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1rem" }}
                        disableElevation
                    >
                        Back
                    </Button>
                </Link>
            </Grid>
            : <Grid item xs={12} style={{ marginTop: "0.5%" }}>
                <Link
                    to={`/`}
                    style={{ textDecoration: 'none' }}
                >
                    <Button
                        variant="contained"
                        size="large"
                        style={{ backgroundColor: "#a6a6a6", color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1rem" }}
                        disableElevation
                    >
                        Back
                    </Button>
                </Link>
            </Grid>
        } */}

        {/*====================================== ADMIN VIEW ==============================================================================*/}
        {JSON.parse(localStorage.getItem('userSignedIn')) && <Grid container>
            <Grid item xs={12} sm>
                <Grid item xs={12} style={{ marginBottom: "2%", paddingLeft: "30%", paddingRight: "30%" }}>
                    <Button
                        disabled
                        variant="contained"
                        size="large"
                        style={{ backgroundColor: "#991514", color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1.3rem" }}
                        disableElevation
                    >
                        Free Test
                    </Button>
                </Grid>
                <Grid item align="center" xs={12} style={{ marginTop: "-40px" }}>
                    <Paper elevation={0} style={{ height: "400px", maxWidth: "300px", backgroundColor: "#a6a6a6", borderRadius: "25px", padding: "50px" }}>
                        <Grid item align="center" xs={12}>
                            <Box
                                component="img"
                                style={{ width: '200px' }}
                                alt="The house from the offer."
                                src="/Book_designed_by_Benny_Forsberg_from_the_Noun_Project_-_with_lines.png"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h4" style={{ fontWeight: "bold", color: "white" }} gutterBottom>
                                Type
                            </Typography>
                            <Typography variant="h5" style={{ color: "white" }} gutterBottom>
                                Reading
                            </Typography>
                            <Typography variant="body2" style={{ color: "white" }} >
                                IELTS Reading Test
                            </Typography>
                            <Typography variant="subtitle2" style={{ color: "white" }} gutterBottom>
                                30 mins
                            </Typography>
                        </Grid>
                    </Paper>
                </Grid>
                {JSON.parse(localStorage.getItem('userSignedIn')) ?
                    <Grid item xs={12} style={{ marginTop: "-30px" }}>
                        <Link
                            to="/freetests/test?test_type=READING"
                            style={{ textDecoration: 'none', color: '#565656' }}
                        >
                            <Button
                                variant="contained"
                                size="large"
                                style={{ backgroundColor: "#991514", color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1.3rem" }}
                                disableElevation
                            >
                                Try Now
                            </Button>
                        </Link>
                    </Grid> : <Grid item xs={12} style={{ marginTop: "-1%" }}>
                        <Link
                            to="/newlogin"
                            style={{ textDecoration: 'none', color: '#565656' }}
                        >
                            <Button
                                variant="contained"
                                size="large"
                                style={{ backgroundColor: "#991514", color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1.3rem" }}
                                disableElevation
                            >
                                Log in
                            </Button>
                        </Link>
                    </Grid>
                }

                {JSON.parse(localStorage.getItem('userSignedIn')) && ["SUPER_ADMIN"].includes(JSON.parse(localStorage.getItem("user"))?.role) &&
                    <Grid item xs={12} style={{ marginTop: "0.5%" }}>
                        <Link
                            to="/dummytests/test?test_type=READING"
                            style={{ textDecoration: 'none', color: '#565656' }}
                        >
                            <Button
                                variant="contained"
                                size="large"
                                style={{ backgroundColor: "#991514", color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1.3rem" }}
                                disableElevation
                            >
                                Try Dummy
                            </Button>
                        </Link>
                    </Grid>
                }

                <Grid item xs={12} style={{ marginTop: "0.5%" }}>
                    <Link
                        to={`/`}
                        style={{ textDecoration: 'none' }}
                    >
                        <Button
                            variant="contained"
                            size="large"
                            style={{ backgroundColor: "#a6a6a6", color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1rem" }}
                            disableElevation
                        >
                            Back
                        </Button>
                    </Link>
                </Grid>
            </Grid>

            <Grid item xs={12} sm>
                <Grid item xs={12} style={{ marginBottom: "2%", paddingLeft: "30%", paddingRight: "30%" }}>
                    <Button
                        disabled
                        variant="contained"
                        size="large"
                        style={{ backgroundColor: "#991514", color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1.3rem" }}
                        disableElevation
                    >
                        Free Test
                    </Button>
                </Grid>
                <Grid item align="center" xs={12} style={{ marginTop: "-40px" }}>
                    <Paper elevation={0} style={{ height: "400px", maxWidth: "300px", backgroundColor: "#a6a6a6", borderRadius: "25px", padding: "50px" }}>
                        <Grid item align="center" xs={12}>
                            <Box
                                component="img"
                                style={{ width: '150px' }}
                                alt="The house from the offer."
                                src="/listen-icon-png-23.jpg"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h4" style={{ fontWeight: "bold", color: "white" }} gutterBottom>
                                Type
                            </Typography>
                            <Typography variant="h5" style={{ color: "white" }} gutterBottom>
                                Listening
                            </Typography>
                            <Typography variant="body2" style={{ color: "white" }} >
                                IELTS Listening Test
                            </Typography>
                            <Typography variant="subtitle2" style={{ color: "white" }} gutterBottom>
                                30 mins
                            </Typography>
                        </Grid>
                    </Paper>
                </Grid>
                {JSON.parse(localStorage.getItem('userSignedIn')) ?
                    <Grid item xs={12} style={{ marginTop: "-30px" }}>
                        <Link
                            to="/freetests/test?test_type=LISTENING"
                            style={{ textDecoration: 'none', color: '#565656' }}
                        >
                            <Button
                                variant="contained"
                                size="large"
                                style={{ backgroundColor: "#991514", color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1.3rem" }}
                                disableElevation
                            >
                                Try Now
                            </Button>
                        </Link>
                    </Grid> : <Grid item xs={12} style={{ marginTop: "-1%" }}>
                        <Link
                            to="/newlogin"
                            style={{ textDecoration: 'none', color: '#565656' }}
                        >
                            <Button
                                variant="contained"
                                size="large"
                                style={{ backgroundColor: "#991514", color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1.3rem" }}
                                disableElevation
                            >
                                Log in
                            </Button>
                        </Link>
                    </Grid>
                }
                {JSON.parse(localStorage.getItem('userSignedIn')) && ["SUPER_ADMIN"].includes(JSON.parse(localStorage.getItem("user"))?.role) &&
                    <Grid item xs={12} style={{ marginTop: "0.5%" }}>
                        <Link
                            to="/dummytests/test?test_type=LISTENING"
                            style={{ textDecoration: 'none', color: '#565656' }}
                        >
                            <Button
                                variant="contained"
                                size="large"
                                style={{ backgroundColor: "#991514", color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1.3rem" }}
                                disableElevation
                            >
                                Try Dummy
                            </Button>
                        </Link>
                    </Grid>
                }
                <Grid item xs={12} style={{ marginTop: "0.5%" }}>
                    <Link
                        to={`/`}
                        style={{ textDecoration: 'none' }}
                    >
                        <Button
                            variant="contained"
                            size="large"
                            style={{ backgroundColor: "#a6a6a6", color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1rem" }}
                            disableElevation
                        >
                            Back
                        </Button>
                    </Link>
                </Grid>
            </Grid>

            <Grid item xs={12} sm>
                <Grid item xs={12} style={{ marginBottom: "2%", paddingLeft: "30%", paddingRight: "30%" }}>
                    <Button
                        disabled
                        variant="contained"
                        size="large"
                        style={{ backgroundColor: "#991514", color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1.3rem" }}
                        disableElevation
                    >
                        Free Test
                    </Button>
                </Grid>
                <Grid item align="center" xs={12} style={{ marginTop: "-40px" }}>
                    <Paper elevation={0} style={{ height: "400px", maxWidth: "300px", backgroundColor: "#a6a6a6", borderRadius: "25px", padding: "50px" }}>
                        <Grid item align="center" xs={12}>
                            <Box
                                component="img"
                                style={{ width: '150px', filter: 'grayscale(1)' }}
                                alt="The house from the offer."
                                src="/logo512.png"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h4" style={{ fontWeight: "bold", color: "white" }} gutterBottom>
                                Type
                            </Typography>
                            <Typography variant="h5" style={{ color: "white" }} gutterBottom>
                            TBAT
                            </Typography>
                            <Typography variant="body2" style={{ color: "white" }} >
                            TBAT FREE TEST
                            </Typography>
                            <Typography variant="subtitle2" style={{ color: "white" }} gutterBottom>
                                30 mins
                            </Typography>
                        </Grid>
                    </Paper>
                </Grid>
                {JSON.parse(localStorage.getItem('userSignedIn')) ?
                    <Grid item xs={12} style={{ marginTop: "-30px" }}>
                        <Link
                            to="/test/bmat?is_free=true"
                            style={{ textDecoration: 'none', color: '#565656' }}
                        >
                            <Button
                                variant="contained"
                                size="large"
                                style={{ backgroundColor: "#991514", color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1.3rem" }}
                                disableElevation
                            >
                                Try Now
                            </Button>
                        </Link>
                        {/* <Button
                            disabled
                            variant="contained"
                            size="large"
                            style={{ backgroundColor: "#d4d4d4", color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1.3rem" }}
                            disableElevation
                        >
                            Coming Soon!
                        </Button> */}
                    </Grid> : <Grid item xs={12} style={{ marginTop: "-1%" }}>
                        <Link
                            to="/newlogin"
                            style={{ textDecoration: 'none', color: '#565656' }}
                        >
                            <Button
                                variant="contained"
                                size="large"
                                style={{ backgroundColor: "#991514", color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1.3rem" }}
                                disableElevation
                            >
                                Log in
                            </Button>
                        </Link>
                    </Grid>
                }
                <Grid item xs={12} style={{ marginTop: "0.5%" }}>
                    <Link
                        to={`/`}
                        style={{ textDecoration: 'none' }}
                    >
                        <Button
                            variant="contained"
                            size="large"
                            style={{ backgroundColor: "#a6a6a6", color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1rem" }}
                            disableElevation
                        >
                            Back
                        </Button>
                    </Link>
                </Grid>
            </Grid>
        </Grid>
        }

        {!JSON.parse(localStorage.getItem('userSignedIn')) && <Grid container>
            <Grid item xs={12} sm>
                <Grid item xs={12} style={{ marginBottom: "2%", paddingLeft: "30%", paddingRight: "30%" }}>
                    <Button
                        disabled
                        variant="contained"
                        size="large"
                        style={{ backgroundColor: "#991514", color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1.3rem" }}
                        disableElevation
                    >
                        Free Test
                    </Button>
                </Grid>
                <Grid item align="center" xs={12} style={{ marginTop: "-40px" }}>
                    <Paper elevation={0} style={{ height: "400px", maxWidth: "300px", backgroundColor: "#a6a6a6", borderRadius: "25px", padding: "50px" }}>
                        <Grid item align="center" xs={12}>
                            <Box
                                component="img"
                                style={{ width: '200px' }}
                                alt="The house from the offer."
                                src="/Book_designed_by_Benny_Forsberg_from_the_Noun_Project_-_with_lines.png"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h4" style={{ fontWeight: "bold", color: "white" }} gutterBottom>
                                Type
                            </Typography>
                            <Typography variant="h5" style={{ color: "white" }} gutterBottom>
                                Reading
                            </Typography>
                            <Typography variant="body2" style={{ color: "white" }} >
                                IELTS Reading Test
                            </Typography>
                            <Typography variant="subtitle2" style={{ color: "white" }} gutterBottom>
                                30 mins
                            </Typography>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} style={{ marginTop: "-5%" }}>
                    <Link
                        to="/newlogin"
                        style={{ textDecoration: 'none', color: '#565656' }}
                    >
                        <Button
                            variant="contained"
                            size="large"
                            style={{ backgroundColor: "#991514", color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1.3rem" }}
                            disableElevation
                        >
                            Log in
                        </Button>
                    </Link>
                </Grid>
                <Grid item xs={12} style={{ marginTop: "0.5%" }}>
                    <Link
                        to={`/`}
                        style={{ textDecoration: 'none' }}
                    >
                        <Button
                            variant="contained"
                            size="large"
                            style={{ backgroundColor: "#a6a6a6", color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1rem" }}
                            disableElevation
                        >
                            Back
                        </Button>
                    </Link>
                </Grid>
            </Grid>

            <Grid item xs={12} sm>
                <Grid item xs={12} style={{ marginBottom: "2%", paddingLeft: "30%", paddingRight: "30%" }}>
                    <Button
                        disabled
                        variant="contained"
                        size="large"
                        style={{ backgroundColor: "#991514", color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1.3rem" }}
                        disableElevation
                    >
                        Free Test
                    </Button>
                </Grid>
                <Grid item align="center" xs={12} style={{ marginTop: "-40px" }}>
                    <Paper elevation={0} style={{ height: "400px", maxWidth: "300px", backgroundColor: "#a6a6a6", borderRadius: "25px", padding: "50px" }}>
                        <Grid item align="center" xs={12}>
                            <Box
                                component="img"
                                style={{ width: '150px' }}
                                alt="The house from the offer."
                                src="/listen-icon-png-23.jpg"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h4" style={{ fontWeight: "bold", color: "white" }} gutterBottom>
                                Type
                            </Typography>
                            <Typography variant="h5" style={{ color: "white" }} gutterBottom>
                                Listening
                            </Typography>
                            <Typography variant="body2" style={{ color: "white" }} >
                                IELTS Listening Test
                            </Typography>
                            <Typography variant="subtitle2" style={{ color: "white" }} gutterBottom>
                                30 mins
                            </Typography>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} style={{ marginTop: "-5%" }}>
                    <Link
                        to="/newlogin"
                        style={{ textDecoration: 'none', color: '#565656' }}
                    >
                        <Button
                            variant="contained"
                            size="large"
                            style={{ backgroundColor: "#991514", color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1.3rem" }}
                            disableElevation
                        >
                            Log in
                        </Button>
                    </Link>
                </Grid>
                <Grid item xs={12} style={{ marginTop: "0.5%" }}>
                    <Link
                        to={`/`}
                        style={{ textDecoration: 'none' }}
                    >
                        <Button
                            variant="contained"
                            size="large"
                            style={{ backgroundColor: "#a6a6a6", color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1rem" }}
                            disableElevation
                        >
                            Back
                        </Button>
                    </Link>
                </Grid>
            </Grid>

            <Grid item xs={12} sm>
                <Grid item xs={12} style={{ marginBottom: "2%", paddingLeft: "30%", paddingRight: "30%" }}>
                    <Button
                        disabled
                        variant="contained"
                        size="large"
                        style={{ backgroundColor: "#991514", color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1.3rem" }}
                        disableElevation
                    >
                        Free Test
                    </Button>
                </Grid>
                <Grid item align="center" xs={12} style={{ marginTop: "-40px" }}>
                    <Paper elevation={0} style={{ height: "400px", maxWidth: "300px", backgroundColor: "#a6a6a6", borderRadius: "25px", padding: "50px" }}>
                        <Grid item align="center" xs={12}>
                            <Box
                                component="img"
                                style={{ width: '150px', filter: 'grayscale(1)' }}
                                alt="The house from the offer."
                                src="/logo512.png"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h4" style={{ fontWeight: "bold", color: "white" }} gutterBottom>
                                Type
                            </Typography>
                            <Typography variant="h5" style={{ color: "white" }} gutterBottom>
                            TBAT
                            </Typography>
                            <Typography variant="body2" style={{ color: "white" }} >
                            TBAT FREE TEST
                            </Typography>
                            <Typography variant="subtitle2" style={{ color: "white" }} gutterBottom>
                                30 mins
                            </Typography>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} style={{ marginTop: "-5%" }}>
                    <Link
                        to="/newlogin"
                        style={{ textDecoration: 'none', color: '#565656' }}
                    >
                        <Button
                            variant="contained"
                            size="large"
                            style={{ backgroundColor: "#991514", color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1.3rem" }}
                            disableElevation
                        >
                            Log in
                        </Button>
                    </Link>
                </Grid>
                <Grid item xs={12} style={{ marginTop: "0.5%" }}>
                    <Link
                        to={`/`}
                        style={{ textDecoration: 'none' }}
                    >
                        <Button
                            variant="contained"
                            size="large"
                            style={{ backgroundColor: "#a6a6a6", color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1rem" }}
                            disableElevation
                        >
                            Back
                        </Button>
                    </Link>
                </Grid>
            </Grid>
        </Grid>
        }
    </Grid>)

    return <ThemeProvider theme={theme}>
        <Hidden mdUp>
            {mobileUI}
        </Hidden>
        <Hidden smDown>
            {webUI}
        </Hidden>
    </ThemeProvider>
};

export default compose(
    withStyles(useStyles)
)(ProductPage);
