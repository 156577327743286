import React, { useEffect } from 'react';

//MUI
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';

//Components
import WritingRetina from './WritingRetina';
import TestWriting from './TestWriting';

//Others
import MediaQuery from 'react-responsive';

const useStyles = theme => ({
    root: {
        padding: theme.spacing(12, 4),
    }
});

const WritingRender = (props) => {
    useEffect(() => {
        document.querySelector("body").scrollTo(0, 0);
    }, []);

    return <MediaQuery minResolution={"2dppx"}>
        {(matches) =>
            matches ? <WritingRetina
                session={JSON.parse(localStorage.getItem("writingSession"))}
                purchasedTestId={props.purchasedTestId}
                purchaseId={props.purchaseId}
                user={props.user}
                userSignup={props.userSignup}
                userSignedin={props.userSignedin}
                handleEndTest={props.handleEndTest}
                keepCurrentSession={props.keepCurrentSession}
                handleOpenLoading={props.handleOpenLoading}
                handleCloseLoading={props.handleCloseLoading}
                handleStartTest={props.handleStartTest}
                handleStartTestType={props.handleStartTestType}
                handleUpdateAnswerObj={props.handleUpdateAnswerObj}
                handleSubmitResult={props.handleSubmitResult}
                handleCreateStudentReport={props.handleCreateStudentReport}
                history={props.history}
            /> : <TestWriting
                    session={JSON.parse(localStorage.getItem("writingSession"))}
                    purchasedTestId={props.purchasedTestId}
                    purchaseId={props.purchaseId}
                    user={props.user}
                    userSignup={props.userSignup}
                    userSignedin={props.userSignedin}
                    handleEndTest={props.handleEndTest}
                    keepCurrentSession={props.keepCurrentSession}
                    handleOpenLoading={props.handleOpenLoading}
                    handleCloseLoading={props.handleCloseLoading}
                    handleStartTest={props.handleStartTest}
                    handleStartTestType={props.handleStartTestType}
                    handleUpdateAnswerObj={props.handleUpdateAnswerObj}
                    handleSubmitResult={props.handleSubmitResult}
                    handleCreateStudentReport={props.handleCreateStudentReport}
                    history={props.history}
                />
        }
    </MediaQuery>
};

export default compose(
    withStyles(useStyles)
)(WritingRender);
