//React
import { Component } from 'react';
import { compose } from 'recompose';

//Material UI
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CircularProgress from '@material-ui/core/CircularProgress';
import CardMedia from '@material-ui/core/CardMedia';
import Divider from '@material-ui/core/Divider';
import FavoriteRoundedIcon from '@material-ui/icons/FavoriteRounded';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import ShareRoundedIcon from '@material-ui/icons/ShareRounded';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

//react-router-dom
import {
    Link
} from "react-router-dom";

//import axios
const axios = require('axios').default;

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%'
    },
    rootContainer: {
        maxWidth: '100%'
    },
    rootCard: {
        maxWidth: "75%",
        width: '75%',
        margin: 10,
    },
    gridContainer: {
        maxWidth: '90%'
    },
    margin: {
        margin: 100
    },
    marginTop: {
        marginTop: 100
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginTop: theme.spacing(5)
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    header: {
        maxWidth: "80%"
    },
    body: {
        maxWidth: "80%",
        marginTop: 30
    },
    divider: {
        maxWidth: "100%"
    },
    image: {
        margin: 'auto',
        display: 'block',
        margin: 10
    }
});

class PreviewPage extends Component {
    constructor() {
        super();
        this.state = {

        };
    }

    handleResize = (event) => {
        this.setState({
            window: {
                height: window.innerHeight,
                width: window.innerWidth
            }
        });
    };

    render() {
        const { classes } = this.props;

        return (
            <Container maxWidth="lg" align="center">
                <Paper>
                    <Grid container justify='center' className={classes.rootContainer} style={{ marginTop: '200px', marginBottom: '315px' }}>
                        <Grid item xs={12} align='center' className={classes.marginTop}>
                            {/*===================== TOPIC =========================*/}
                            <Grid item xs={12} align='left' className={classes.header}>
                                <Typography variant="h3" gutterBottom>
                                    {this.props.heading ? this.props.heading : 'Heading'}
                                </Typography>
                                <Typography variant="subtitle1" gutterBottom>
                                    {this.props.subHeading ? this.props.subHeading : 'Sub heading'}
                                </Typography>
                            </Grid>
                            {/*===================== HEADER IMAGE =========================*/}
                            <Grid item xs={12} align='center'>
                                <Card className={classes.rootCard}>
                                    <CardMedia
                                        className={classes.media}
                                        image={this.props.image}
                                        title="Contemplative Reptile"
                                    />
                                </Card>
                            </Grid>
                            {/*===================== BODY =========================*/}
                            <Grid item xs={12} align='left' className={classes.body}>
                                <Typography variant="body1" gutterBottom>
                                    <Box fontWeight="fontWeightBold" display='inline'>{this.props.bodyHeading ? this.props.bodyHeading : 'Body heading'}</Box>
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    { this.props.body ? this.props.body.split("\\n").map((i, key) => {
                                        return <p key={key}>{i}</p>;
                                    }) : 'Body'}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} style={{ padding: '20px' }}>
                                <Grid container justify='flex-end' spacing={2}>
                                    {!this.props.isEdit &&
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                component="span"
                                                style={{ backgroundColor: '#76323F', color: 'white' }}
                                                onClick={this.props.handleCreateBlog}
                                            >
                                                Create
                                            </Button>
                                        </Grid>
                                    }
                                    {this.props.isEdit &&
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                component="span"
                                                style={{ backgroundColor: '#76323F', color: 'white' }}
                                                onClick={this.props.handleUpdateBlog}
                                            >
                                                Update
                                            </Button>
                                        </Grid>
                                    }
                                    <Grid item>
                                        <Button
                                            variant="text"
                                            component="span"
                                            onClick={this.props.handleCancelPreviewBlog}
                                            variant="contained"
                                            component="span"
                                            style={{ backgroundColor: '#aaaaaa', color: 'white' }}
                                        >
                                            Back
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        )
    }
}

export default compose(
    withStyles(useStyles)
)(PreviewPage);