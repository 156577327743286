import * as apiConstants from '../constants/apiConstants';

const axios = require('axios').default;

export async function GetUserNotifications(username) {
    try {
        const res = await axios({
            method: 'get',
            url: `${apiConstants.SPEAKING_API_BASE_URL}/notifications/username/${username}`,
        });

        return {
            success: true,
            res: res.data.data
        }

    } catch (err) {
        console.log(err);

        return {
            success: false,
            res: err
        }
    }
}

export async function CreateNewNotification(username, type, content) {
    try {
        const res = await axios({
            method: 'post',
            url: `${apiConstants.SPEAKING_API_BASE_URL}/notifications`,
            data: {
                username: username,
                type: type,
                content: content
            }
        });

        return {
            success: true,
            res: res.data
        }

    } catch (err) {
        console.log(err);

        return {
            success: false,
            res: err
        }
    }
}

export async function ReadNotification(id) {
    try {
        const res = await axios({
            method: 'patch',
            url: `${apiConstants.SPEAKING_API_BASE_URL}/notifications/id/${id}/read`
        });

        return {
            success: true,
            res: res.data
        }

    } catch (err) {
        console.log(err);

        return {
            success: false,
            res: err
        }
    }
}

export async function ClearAllNotification(username) {
    try {
        const res = await axios({
            method: 'delete',
            url: `${apiConstants.SPEAKING_API_BASE_URL}/notifications/username/${username}`
        });

        return {
            success: true,
            res: res.data
        }

    } catch (err) {
        console.log(err);

        return {
            success: false,
            res: err
        }
    }
}
