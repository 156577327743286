//Contants
import * as apiConstants from '../constants/apiConstants';

const axios = require('axios').default;

//=========================== CREATE TEST HISTORY ======================================
export async function CreateSectionHistory(oldObj, newObj, sectionId, test_id, part_id) {
    try {
        await axios({
            method: 'post',
            url: `${apiConstants.TEST_MANAGEMENT_API_BASE_URL}/test/edit/history`,
            data: {
                test_id: test_id,
                part_id: part_id,
                section_id: sectionId,
                updateFrom: oldObj,
                updateTo: newObj,
                updateBy: {
                    name: JSON.parse(localStorage.getItem('user')).name,
                    username: JSON.parse(localStorage.getItem('user')).username
                }
            }
        });

        return true
    } catch (err) {
        console.log(err);
        return err
    }
}
//=========================== CREATE TEST HISTORY ======================================

//=========================== GET TEST HISTORY ======================================
export async function GetSectionHistory(section_id) {
    try {
        const res = await axios({
            method: 'get',
            url: `${apiConstants.TEST_MANAGEMENT_API_BASE_URL}/test/edit/history?section_id=${section_id}`,
            headers: {
                "Authorization": `Bearer ${JSON.parse(localStorage.getItem('user')).token}`
            }
        });

        return res;
    } catch (err) {
        console.log(err);
        return err;
    }
}
//=========================== GET TEST HISTORY ======================================