import MaterialTable from 'material-table';

import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { compose } from 'recompose';

//import other library
import 'react-calendar/dist/Calendar.css';

//react-router-dom

const axios = require('axios').default;
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

const useStyles = theme => ({
    root: {
        minWidth: 275,
    }
});


class UserTable extends Component {
    constructor() {
        super();
        this.state = {
        }
    }

    componentDidMount() {
        this.handleGetallUsers();
    }

    //============================== GET ALL USERS =============================
    async handleGetallUsers() {
        const getAllUsers = api_base_url + "/api/user/all";
        try {
            let authentication = {
                headers: {
                    "Authorization": "Bearer " + JSON.parse(localStorage.getItem('user')).token
                }
            };
            const response = await axios.get(getAllUsers, authentication);
            let teacherArr = [];
            await response.data.map((teacher) => {
                teacherArr.push({
                    id: btoa(teacher.username),
                    username: teacher.username,
                    name: this.capitalize(teacher.firstname) + ' ' + this.capitalize(teacher.lastname),
                    email: teacher.email,
                    role: teacher.role,
                    school: teacher.school
                })
            })
            
            this.setState({
                teachers: teacherArr,
                isConfirmDeletion: false
            })

        } catch (error) {
            console.error(error);
        }
    }

    capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    //============================== GET ALL USERS =============================

    render() {
        const { classes } = this.props;

        return (
            <MaterialTable
                isLoading={!this.state.teachers}
                data={this.state.teachers}
                columns={[
                    { title: 'Id', field: 'id', editable: 'never' },
                    { title: 'Name', field: 'name', editable: 'never' },
                    { title: 'Email', field: 'email', editable: 'never' },
                    { title: 'Username', field: 'username', editable: 'never' },
                    { title: 'School', field: 'school', editable: 'never' },
                    { title: 'Role', field: 'role', lookup: { SUPER_ADMIN: 'Super admin', TEST_ADMIN: 'Test admin', ADMIN: 'Admin', HEAD_TEACHER: 'Head teacher', TEACHER: 'Teacher', STUDENT: 'Student' } },
                ]}
                actions={[
                    {
                        icon: 'delete',
                        tooltip: 'Delete user',
                        onClick: (event, rowData) => {
                            this.props.handleConfirmDeletion(rowData.username)
                        }
                    }
                ]}
                options={{
                    actionsColumnIndex: -1,
                    exportAllData: true,
                    exportButton: true,
                    pageSize: 10
                }}
                editable={{
                    onRowUpdate: (newData, oldData) =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                this.props.handleChangeUserRole(newData.username, newData.role)
                                resolve();
                            }, 1000)
                        }),
                }}
                title='Users'
            />
        )
    }
}
export default compose(
    withStyles(useStyles)
)(UserTable);