import React, { useEffect } from 'react';

//MUI
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';

import { ThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';

//Components

//Font
import 'typeface-glacial-indifference';
import { BmatScoreMappingSectionOne, BmatScoreMappingSectionTwo } from '../../../../../functions/testReportFuntions';

//Others
const axios = require('axios').default;
const FormData = require('form-data');
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

const useStyles = theme => ({
    root: {
        padding: theme.spacing(12, 4),
    }
});

const theme = createMuiTheme({
    typography: {
        fontFamily: [
            'Roboto'
        ].join(','),
    },
});

const OverallScore = (props) => {
    useEffect(() => {
        console.log("test")
    }, []);

    const mobileUI = (<Grid container>
        <Grid item xs={12} style={{
            backgroundColor: "#42647d",
            borderTopLeftRadius: "15px",
            borderTopRightRadius: "15px",
            padding: '5px'
        }}>
            <Typography variant="h5" style={{ color: "white" }}>
                Overall score
            </Typography>
        </Grid>
        <Grid item xs={12} style={{
            padding: "5%",
            border: "1px solid #42647d"
        }}>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={12} style={{
                            backgroundColor: "#42647d",
                            borderTopLeftRadius: "5px",
                            borderTopRightRadius: "5px"
                        }}>
                            <Typography variant="subtitle2" style={{ color: "white" }}>
                                Thinking
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{
                            padding: "5%",
                            border: "1px solid #42647d",
                            borderBottomLeftRadius: "5px",
                            borderBottomRightRadius: "5px"
                        }}>
                            <Typography variant="subtitle2" style={{ color: "#25333e", fontWeight: "bold" }}>
                                {props.isLoading ? <Skeleton /> : BmatScoreMappingSectionOne(props.reportObj.test_result.parts[0].total_score)}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={12} style={{
                            backgroundColor: "#42647d",
                            borderTopLeftRadius: "5px",
                            borderTopRightRadius: "5px"
                        }}>
                            <Typography variant="subtitle2" style={{ color: "white" }}>
                                Scientific
                                </Typography>
                        </Grid>
                        <Grid item xs={12} style={{
                            padding: "5%",
                            border: "1px solid #42647d",
                            borderBottomLeftRadius: "5px",
                            borderBottomRightRadius: "5px"
                        }}>
                            <Typography variant="subtitle2" style={{ color: "#25333e", fontWeight: "bold" }}>
                                {props.isLoading ? <Skeleton /> : BmatScoreMappingSectionTwo(props.reportObj.test_result.parts[1].total_score)}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12} style={{
                            backgroundColor: "#42647d",
                            borderTopLeftRadius: "5px",
                            borderTopRightRadius: "5px"
                        }}>
                            <Typography variant="subtitle2" style={{ color: "white" }}>
                                Writing
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{
                            padding: "2%",
                            border: "1px solid #42647d",
                            borderBottomLeftRadius: "5px",
                            borderBottomRightRadius: "5px"
                        }}>
                            <Typography variant="subtitle2" style={{ color: "#25333e", fontWeight: "bold" }}>
                                {props.isLoading ? <Skeleton /> : (props.isGotWritingResult ? `${props.writingNotesObj?.note?.quality_of_content}${props.writingNotesObj?.note?.quality_of_english}` : "-")}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs={12}>
            <Grid container>
                <Grid item xs={4} style={{
                    backgroundColor: "#42647d",
                    borderBottomLeftRadius: "15px",
                    padding: "15px 0 15px"
                }}>
                    <Typography variant="subtitle1" style={{ color: "white" }}>
                        Overall Score
                    </Typography>
                </Grid>
                <Grid item xs={8} style={{
                    borderBottom: "1px solid #42647d",
                    borderRight: "1px solid #42647d",
                    borderBottomRightRadius: "15px"
                }}>
                    <Typography variant="h3" style={{
                        color: "#25333e"
                    }}>
                        {props.isLoading ? <Skeleton />
                            : `${props.isGotWritingResult ? `${(BmatScoreMappingSectionOne(props.reportObj.test_result.parts[0].total_score) + BmatScoreMappingSectionTwo(props.reportObj.test_result.parts[1].total_score) + props.writingNotesObj?.note?.quality_of_content)}${props.writingNotesObj?.note?.quality_of_english}` : `${BmatScoreMappingSectionOne(props.reportObj.test_result.parts[0].total_score) + BmatScoreMappingSectionTwo(props.reportObj.test_result.parts[1].total_score)}`}`}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    </Grid>)

    const webUI = (<Grid container>
        <Grid item xs={12} style={{
            backgroundColor: "#42647d",
            borderTopLeftRadius: "15px",
            borderTopRightRadius: "15px",
            padding: '5px'
        }}>
            <Typography variant="h5" style={{ color: "white" }}>
                Overall score
            </Typography>
        </Grid>
        <Grid item xs={12} style={{
            padding: "5%",
            border: "1px solid #42647d"
        }}>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={12} style={{
                            backgroundColor: "#42647d",
                            borderTopLeftRadius: "5px",
                            borderTopRightRadius: "5px"
                        }}>
                            <Typography variant="subtitle2" style={{ color: "white" }}>
                                Thinking
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{
                            padding: "5%",
                            border: "1px solid #42647d",
                            borderBottomLeftRadius: "5px",
                            borderBottomRightRadius: "5px"
                        }}>
                            <Typography variant="subtitle2" style={{ color: "#25333e", fontWeight: "bold" }}>
                                {props.isLoading ? <Skeleton /> : BmatScoreMappingSectionOne(props.reportObj.test_result.parts[0].total_score)}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={12} style={{
                            backgroundColor: "#42647d",
                            borderTopLeftRadius: "5px",
                            borderTopRightRadius: "5px"
                        }}>
                            <Typography variant="subtitle2" style={{ color: "white" }}>
                                Scientific
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{
                            padding: "5%",
                            border: "1px solid #42647d",
                            borderBottomLeftRadius: "5px",
                            borderBottomRightRadius: "5px"
                        }}>
                            <Typography variant="subtitle2" style={{ color: "#25333e", fontWeight: "bold" }}>
                                {props.isLoading ? <Skeleton /> : BmatScoreMappingSectionTwo(props.reportObj.test_result.parts[1].total_score)}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12} style={{
                            backgroundColor: "#42647d",
                            borderTopLeftRadius: "5px",
                            borderTopRightRadius: "5px"
                        }}>
                            <Typography variant="subtitle2" style={{ color: "white" }}>
                                Writing
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{
                            padding: "2%",
                            border: "1px solid #42647d",
                            borderBottomLeftRadius: "5px",
                            borderBottomRightRadius: "5px"
                        }}>
                            <Typography variant="subtitle2" style={{ color: "#25333e", fontWeight: "bold" }}>
                                {props.isLoading ? <Skeleton /> : (props.isGotWritingResult ? `${props.writingNotesObj?.note?.quality_of_content}${props.writingNotesObj?.note?.quality_of_english}` : "-")}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs={12}>
            <Grid container>
                <Grid item xs={4} style={{
                    backgroundColor: "#42647d",
                    borderBottomLeftRadius: "15px",
                    padding: "15px 0 15px"
                }}>
                    <Typography variant="subtitle1" style={{ color: "white" }}>
                        Overall Score
                    </Typography>
                </Grid>
                <Grid item xs={8} style={{
                    borderBottom: "1px solid #42647d",
                    borderRight: "1px solid #42647d",
                    borderBottomRightRadius: "15px"
                }}>
                    <Typography variant="h3" style={{
                        color: "#25333e"
                    }}>
                        {props.isLoading ? <Skeleton />
                            : `${props.isGotWritingResult ? `${(BmatScoreMappingSectionOne(props.reportObj.test_result.parts[0].total_score) + BmatScoreMappingSectionTwo(props.reportObj.test_result.parts[1].total_score) + props.writingNotesObj?.note?.quality_of_content)}${props.writingNotesObj?.note?.quality_of_english}` : `${BmatScoreMappingSectionOne(props.reportObj.test_result.parts[0].total_score) + BmatScoreMappingSectionTwo(props.reportObj.test_result.parts[1].total_score)}`}`}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    </Grid>)

    return <ThemeProvider theme={theme}>
        <Hidden mdUp>
            {mobileUI}
        </Hidden>
        <Hidden smDown>
            {webUI}
        </Hidden>
    </ThemeProvider>
};

export default compose(
    withStyles(useStyles)
)(OverallScore);
