import React from 'react';

//Material UI
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import LinearProgress from '@material-ui/core/LinearProgress';
import MuiAlert from '@material-ui/lab/Alert';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

//Icons
import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

//Models
import { CreateSectionHistory } from '../../../../../../models/sectionHistory.js';

//Others
import { withStyles } from '@material-ui/core/styles';
import { Component } from 'react';
import { compose } from 'recompose';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

const axios = require('axios').default;
const FormData = require('form-data');
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

const useStyles = theme => ({
    root: {
        margin: 15,
        marginTop: 50
    },
    margin: {
        margin: theme.spacing(1),
    },
    buttonColor: {
        backgroundColor: '#76323F',
        color: 'white'
    }
});

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class CompleteTheTables extends Component {
    constructor() {
        super()
        this.state = {
        };
    }

    componentDidMount = async () => {
        if (this.props.sectionId !== undefined) {
            await this.handleGetSectionObj();
        }
    }

    handleGetSectionObj = async () => {
        if (this.props.sectionId !== undefined && this.props.sectionObj === undefined) {
            try {
                const res = await axios({
                    method: 'get',
                    url: api_base_url + '/api/test/section/id/' + this.props.sectionId,
                    headers: {
                        "Authorization": "Bearer " + JSON.parse(localStorage.getItem('user')).token
                    }
                });
                console.log(res);

                const resData = res.data.section;
                let resDataTemp = JSON.parse(JSON.stringify(resData));

                await this.setState({
                    sectionObj: resData,
                    tempSectionObj: resDataTemp
                });
            } catch (err) {
                console.log(err);
            }
        } else if (this.props.sectionObj !== undefined) {
            await this.setState({
                sectionObj: this.props.sectionObj,
                tempSectionObj: this.props.sectionObj,
                tempAnsArr: []
            });
        }
    }

    // =============================== EDIT SECTION ==================================
    handleEditTableTitle = async (event) => {
        this.state.sectionObj.questions[0].table_title = event.target.value;
        this.setState({
            sectionObj: this.state.sectionObj
        });
    }

    handleEditQuestion = async (event) => {
        this.state.sectionObj.questions[0].question = event.target.value;
        this.setState({
            sectionObj: this.state.sectionObj
        });
    }

    handleEditColumnL = async (event, index) => {
        this.state.sectionObj.questions[0].columnL[index] = event.target.value;
        this.setState({
            sectionObj: this.state.sectionObj
        });
    }

    handleEditColumnR = async (event, index) => {
        this.state.sectionObj.questions[0].columnR[index] = event.target.value;
        this.setState({
            sectionObj: this.state.sectionObj
        });
    }

    handleEditAnswer = async (event, index) => {
        console.log(event.target.value.split(', '));
        this.state.sectionObj.questions[0].answer[index] = event.target.value.split(',');
        this.setState({
            sectionObj: this.state.sectionObj
        });
    }

    handlePutEditSection = async () => {
        if (this.props.sectionId !== undefined) {
            try {
                const res = await axios({
                    method: 'put',
                    url: api_base_url + '/api/test/section',
                    headers: {
                        "Authorization": "Bearer " + JSON.parse(localStorage.getItem('user')).token
                    },
                    data: this.state.sectionObj
                });

                await CreateSectionHistory(this.state.tempSectionObj, this.state.sectionObj, this.props.sectionId, this.props.test_id, this.props.selectedPart);
                await this.handleGetSectionObj();
                this.props.handleEndEditSection();
            } catch (err) {
                console.log(err);
            }
        }
    }
    // =============================== EDIT SECTION ==================================

    render() {
        const { classes } = this.props;

        return (
            <Grid item xs={12}>
                {this.state.sectionObj === undefined ?
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={2}>
                            <TextField
                                label="No."
                                id="outlined-size-small"
                                variant="outlined"
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <TextField
                                label="Question"
                                id="outlined-size-small"
                                variant="outlined"
                                size="small"
                                fullWidth
                            />
                        </Grid>
                    </Grid> : <Grid container spacing={1}>
                        {this.state.sectionObj.questions.map((question, index) =>
                            <Grid item key={'question' + index} xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                                                    Table title
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    disabled={!(this.props.selectedSectionId === this.state.sectionObj.section_id)}
                                                    key="outlined-size-small-table-title"
                                                    id="outlined-size-small-table-title"
                                                    variant="outlined"
                                                    size="small"
                                                    value={question.table_title}
                                                    onChange={(event) => { this.handleEditTableTitle(event) }}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                                                    Question
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    disabled={!(this.props.selectedSectionId === this.state.sectionObj.section_id)}
                                                    key="outlined-size-small-question"
                                                    id="outlined-size-small-question"
                                                    variant="outlined"
                                                    size="small"
                                                    value={question.question}
                                                    onChange={(event) => { this.handleEditQuestion(event) }}
                                                    fullWidth
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={1}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                                                    No.
                                                </Typography>
                                            </Grid>
                                            {question.columnL.map((text, Idx) =>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        disabled
                                                        id="outlined-size-small"
                                                        variant="outlined"
                                                        size="small"
                                                        value={Number(question.no.split('-')[0]) + Idx}
                                                        fullWidth
                                                    />
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                                                    Context
                                                </Typography>
                                            </Grid>
                                            {question.columnL.map((text, columnLIdx) =>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        disabled={!(this.props.selectedSectionId === this.state.sectionObj.section_id)}
                                                        id="outlined-size-small"
                                                        variant="outlined"
                                                        size="small"
                                                        value={text}
                                                        onChange={(event) => { this.handleEditColumnL(event, columnLIdx) }}
                                                        fullWidth
                                                    />
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                                                    Question
                                                </Typography>
                                            </Grid>
                                            {question.columnR.map((text, columnRIdx) =>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        disabled={!(this.props.selectedSectionId === this.state.sectionObj.section_id)}
                                                        id="outlined-size-small"
                                                        variant="outlined"
                                                        size="small"
                                                        value={text}
                                                        onChange={(event) => { this.handleEditColumnR(event, columnRIdx) }}
                                                        fullWidth
                                                    />
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={2}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                                                    Answer
                                                </Typography>
                                            </Grid>
                                            {question.answer.map((text, ansIdx) =>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        disabled={!(this.props.selectedSectionId === this.state.sectionObj.section_id)}
                                                        id="outlined-size-small"
                                                        variant="outlined"
                                                        size="small"
                                                        value={text}
                                                        onChange={(event) => { this.handleEditAnswer(event, ansIdx) }}
                                                        fullWidth
                                                    />
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}

                        {this.state.sectionObj !== undefined && (this.props.selectedSectionId === this.state.sectionObj.section_id) &&
                            <Grid item align='right' xs={12}>
                                <Button variant="contained" size="medium" color="primary" onClick={this.handlePutEditSection} style={{ marginRight: '5px', backgroundColor: '#76323F' }}>
                                    Confirm
                                </Button>
                                <Button variant="contained" size="medium" color="default" onClick={this.props.handleEndEditSection} style={{ marginLeft: '5px', color: 'grey' }}>
                                    Cancel
                                </Button>
                            </Grid>
                        }
                    </Grid>
                }
            </Grid>
        )
    }
}
export default compose(
    withStyles(useStyles)
)(CompleteTheTables);