//React
import { Component } from 'react';
import { compose } from 'recompose';

//Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

//Icons
import NavigateNextRoundedIcon from '@material-ui/icons/NavigateNextRounded';
import NavigateBeforeRoundedIcon from '@material-ui/icons/NavigateBeforeRounded';
import { GetBMATWriting } from '../../../../../services/testServices';
import PageBackdrop from '../../../../main/PageBackdrop';

//react-router-dom
import {
    Link
} from "react-router-dom";

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%'
    }
});

class FullWritingSelection extends Component {
    constructor() {
        super();
        this.state = {
            testObj: {},
            currentTestIndex: 0,
            isSelected: false,
            answer: "",
            isLoading: true,
            isConfirmSubmitTest: false
        };
    }

    componentDidMount = () => {
        if (JSON.parse(localStorage.getItem('bmatWritingSessionObj')) !== null) {
            this.setState(JSON.parse(localStorage.getItem('bmatWritingSessionObj')));
        } else {
            this.getWritingTest();
        }
    }

    getWritingTest = async () => {
        const res = await GetBMATWriting();

        if (res.success) {
            this.setState({
                testObj: res.res,
                isLoading: false
            });

            this.updateSession();
            this.props.handleStartWriting();
        }
    }

    handleSubmitTest = async () => {
        try {
            this.handleCloseDialog();
            this.setState({
                isLoading: true
            });
            const resultObj = await this.props.handleSubmitTestWithWriting();
            this.props.handleSubmitWritingTest(this.state.testObj, this.state.currentTestIndex, this.state.answer, resultObj.data);
        } catch (err) {
            console.log(err);
        }
    };

    updateSession = () => {
        localStorage.setItem('bmatWritingSessionObj', JSON.stringify(this.state));
    }

    handleChange = (event) => {
        this.setState({
            answer: event.target.value,
        });

        this.updateSession();
    };

    onClickNext = () => {
        if (this.state.currentTestIndex + 1 < this.state.testObj.length) {
            this.setState(prevState => {
                return { currentTestIndex: prevState.currentTestIndex + 1 }
            });

            this.updateSession();
        }
    }

    onClickBack = () => {
        if (this.state.currentTestIndex > 0) {
            this.setState(prevState => {
                return { currentTestIndex: prevState.currentTestIndex - 1 }
            })

            this.updateSession();
        }
    }

    onClickSelectTest = () => {
        this.setState({
            isSelected: true
        });

        this.updateSession();
    }

    handleOpenConfirmSubmitTest = () => {
        this.setState({
            isConfirmSubmitTest: true
        });

        this.updateSession();
    }

    handleCloseDialog = () => {
        this.setState({
            isConfirmSubmitTest: false
        });

        this.updateSession();
    }

    render() {
        return (
            <Grid item xs={12} style={{ padding: "5%", paddingTop: "10px" }}>
                <PageBackdrop isLoading={this.state.isLoading} />

                <Dialog
                    open={this.state.isConfirmSubmitTest}
                    onClose={this.handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Are you sure to submit the test?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure to submit a test?
                                Please click <Box fontWeight="fontWeightBold" display='inline'>Submit</Box> to submit a test.
                            </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseDialog} color="primary" style={{ backgroundColor: "#a6a6a6", color: "white", textTransform: "none" }}>
                            Cancel
                        </Button>
                        <Link
                            color="inherit"
                            underline="none"
                            style={{ textDecoration: 'none', color: '#565656' }}
                        >
                            <Button onClick={this.handleSubmitTest} color="primary" style={{ backgroundColor: "#214b4b", color: "white", textTransform: "none" }} autoFocus>
                                Submit
                            </Button>
                        </Link>
                    </DialogActions>
                </Dialog>

                <Grid container spacing={5}>
                    <Grid item>
                        {this.state.currentTestIndex > 0 && !this.state.isSelected &&
                            <Button
                                disableElevation
                                variant="contained"
                                color="primary"
                                onClick={this.onClickBack}
                                style={{
                                    backgroundColor: "#214b4b",
                                    color: "white",
                                    marginTop: window.innerWidth * 0.2
                                }}
                            >
                                <NavigateBeforeRoundedIcon fontSize="large" />
                            </Button>
                        }
                    </Grid>
                    <Grid item align="left" xs>
                        <Typography variant="h6" style={{ fontWeight: "bold" }}>
                            {this.state.testObj[this.state.currentTestIndex]?.versions?.context}
                        </Typography>
                        <Typography variant="body1" style={{ marginTop: "30px" }}>
                            {this.state.testObj[this.state.currentTestIndex]?.versions?.question}
                        </Typography>
                    </Grid>
                    <Grid item xs>
                        <textarea
                            disabled={!this.state.isSelected}
                            value={this.state.answer}
                            className="form-control"
                            data-gramm="false"
                            data-gramm_editor="false"
                            data-enable-grammarly="false"
                            spellcheck="false"
                            placeholder="Write your answer here..."
                            onChange={this.handleChange}
                            style={{
                                fontSize: '1rem',
                                width: "100%",
                                height: window.innerWidth * 0.42,
                                background: this.state.isSelected ? "" : "rgba(212,212,212, 0.3)"
                            }}
                        />
                        {
                            !this.state.isSelected &&
                            <Button disableElevation size="large" variant="contained" style={{ textTransform: "none", marginTop: "-100%", backgroundColor: "#214b4b", color: "white" }}>
                                <Typography variant="h5" onClick={this.onClickSelectTest} >
                                    Select
                                </Typography>
                            </Button>
                        }
                    </Grid>
                    <Grid item>
                        {(this.state.currentTestIndex + 1 < this.state.testObj.length) && !this.state.isSelected &&
                            <Button
                                disableElevation
                                variant="contained"
                                color="primary"
                                onClick={this.onClickNext}
                                style={{
                                    backgroundColor: "#214b4b",
                                    color: "white",
                                    marginTop: window.innerWidth * 0.2
                                }}
                            >
                                <NavigateNextRoundedIcon fontSize="large" />
                            </Button>
                        }
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{ position: 'absolute', bottom: 0, width: '100%' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={10} align='center' style={{ paddingLeft: '20px' }}>
                            <Paper variant="outlined">
                                <Button
                                    size="small"
                                    variant={!this.state.answer ? "outlined" : "contained"}
                                    style={{
                                        backgroundColor: !this.state.answer ? "" : "#214b4b",
                                        color: !this.state.answer ? "#214b4b" : "white",
                                        maxWidth: '25px', maxHeight: '25px', minWidth: '25px', minHeight: '25px', margin: '0.3%',
                                        borderColor: '#214b4b'
                                    }}
                                >
                                    1
                                </Button>
                            </Paper>
                        </Grid>
                        <Grid item xs={2} align='left' style={{ width: '100%' }}>
                            <Grid container>
                                <Grid item xs={12} style={{ padding: '1px' }}>
                                    <ButtonGroup size="large" variant="text" aria-label="outlined primary button group" >
                                        <Button onClick={this.handleOpenConfirmSubmitTest} style={{ color: 'white', backgroundColor: '#214b4b', borderColor: '#214b4b' }}>Submit</Button>
                                    </ButtonGroup>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

export default compose(
    withStyles(useStyles)
)(FullWritingSelection);
