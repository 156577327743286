//React
import { Component } from 'react';
import { compose } from 'recompose';

//Material UI
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccountBoxRoundedIcon from '@material-ui/icons/AccountBoxRounded';
import AccessAlarmRoundedIcon from '@material-ui/icons/AccessAlarmRounded';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Input from '@material-ui/core/Input';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

//import components

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%'
    },
    paperRoot: {
        padding: theme.spacing(2),
        textAlign: 'left'
    },
    paperItem: {
        padding: theme.spacing(2),
        textAlign: 'left',
        height: '810px',
        maxHeight: '810px',
        overflow: 'auto',
    },
    testAlignLeft: {
        padding: theme.spacing(2),
    },
    gridContainerRoot: {
        marginTop: 80
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    marginLeft: {
        marginLeft: 20
    },
    marginTop: {
        marginTop: 20
    },
    margin: {
        margin: 5
    }
});

class CompleteTheNotes extends Component {
    constructor() {
        super();
        this.state = {
            startQuestionNumber: 0
        };
    }

    componentDidMount = async () => {
        this.prepareTest();
    }

    handleAnswerQuestion = (event, index) => {
        this.state.answerArr[index] = event.target.value;
        this.state.answerObj.answers[this.props.currentTestObject?.questions[0].no] = this.state.answerArr;
        console.log(this.props.currentTestObject?.questions[0].no)
    }

    prepareTest = async () => {
        let count = 0;
        const questionNo = Number(this.props.currentTestObject.questions[0].no.split('-')[0])

        const final = await this.props.currentTestObject.questions[0].text.replace(/_/g, function (x) { count++; return `<input id="index-${count}" placeholder="${questionNo + (count - 1)}" type="text" style="text-align: center" />` })

        await this.setState({
            testContext: final
        });

        [...Array(5).keys()].map((value, index) => {
            const el = document.getElementById(`index-${index + 1}`);
            el.addEventListener('change', (event) => {this.handleAnswerQuestion(event, Number(event.target.id.split("-")[1]) - 1)} );
        })
    }

    render() {
        const { classes } = this.props;

        return (
            <Grid container>
                <Grid item align='left' xs={12}>
                    <Typography variant="h6" gutterBottom style={{ fontWeight: "bold" }}>
                        Question {this.props.currentTestObject.questions[0].no}
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                        Complete the notes below.
                    </Typography>
                    <Typography variant="h6">
                        Write <span style={{ fontWeight: 'bold' }}>NO MORE THAN TWO WORDS</span> for each anwer.
                    </Typography>
                </Grid>
                <Grid item style={{ marginTop: '20px' }}>
                    <Paper
                        variant="outlined"
                        style={{
                            padding: '20px',
                            paddingTop: '80px',
                            paddingBottom: '100px',
                            backgroundImage: `url("/U-Test-Logo-new-bw.png")`,
                            backgroundSize: '120px',
                            backgroundRepeat: 'space'
                        }}>
                        {/*this.props.currentTestObject.questions[0].text.replace("\n", "(br)").split("_").map((sentence, index) =>
                            sentence.split('(br)').map((brText, brTextIdx) =>
                                <Typography variant="body1" align='left' style={{ marginTop: '10px', textAlign: 'justify', fontSize: '1.2rem' }}>
                                    <Markup content={brText} /> <span>{brTextIdx !== index && index + 1 !== this.props.currentTestObject.questions[0].text.split("_").length &&
                                        Number(this.props.currentTestObject.questions[0].no.split('-')[0]) + index !== 41 &&
                                        <TextField
                                            size="small"
                                            autoComplete="new-password"
                                            id={index}
                                            value={this.state.answerArr && (this.state.answerArr[index] === undefined ?
                                                Number(this.props.currentTestObject.questions[0].no.split('-')[0]) + index :
                                                this.state.answerArr[index])}
                                            variant="outlined"
                                            onChange={(event) => { this.handleAnswerQuestion(event, index) }}
                                            onFocus={(event) => { this.handleOnFocusTextField(event, index) }}
                                            inputProps={{ style: { textAlign: 'center', height: '5px' } }}
                                        />
                                    }</span>
                                </Typography>
                            )
                                )*/}
                        <Typography variant="body1" align='left' style={{ marginTop: '10px', textAlign: 'justify', fontSize: '1.2rem' }}>
                            <div dangerouslySetInnerHTML={{ __html: this.state.testContext ? this.state.testContext : "" }} />
                        </Typography>
                        {/*<Typography variant="body1" align='left' style={{ marginTop: '10px', textAlign: 'justify', fontSize: '1.2rem' }}>
                            {this.props.currentTestObject.questions[0].text.split("_").map((sentence, index) =>
                                <span>
                                    <Markup content={sentence} /> {index + 1 !== this.props.currentTestObject.questions[0].text.split("_").length &&
                                        Number(this.props.currentTestObject.questions[0].no.split('-')[0]) + index !== 41 &&
                                        <TextField
                                            size="small"
                                            autoComplete="new-password"
                                            id={index}
                                            value={this.state.answerArr && (this.state.answerArr[index] === undefined ?
                                                Number(this.props.currentTestObject.questions[0].no.split('-')[0]) + index :
                                                this.state.answerArr[index])}
                                            variant="outlined"
                                            onChange={(event) => { this.handleAnswerQuestion(event, index) }}
                                            onFocus={(event) => { this.handleOnFocusTextField(event, index) }}
                                            inputProps={{ style: { textAlign: 'center', height: '5px' } }}
                                        />
                                    }
                                </span>
                            )}
                        </Typography>*/}
                    </Paper>
                </Grid>
            </Grid >
        )
    }
}

export default compose(
    withStyles(useStyles)
)(CompleteTheNotes);