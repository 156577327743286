//React
import { Component } from 'react';
import { compose } from 'recompose';

//Material UI
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

//react-router-dom

//Services
import { CreateNewNotification } from '../../../../services/notificationServices';

//Others

const axios = require('axios').default;
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%'
    }
});


class WritingScoring extends Component {
    constructor() {
        super();
        this.state = {
            numberOfTest: 0,
            submitObj: {},
            isLoading: false,
            bandScoreResult: 0
        };
    }

    componentDidMount = () => {
        if (this.props.test?.testCategory === "BMAT") {
            this.setState({
                submitObj: {
                    "quality_of_content": "none",
                    "quality_of_english": "none",
                }
            });
        } else {
            this.setState({
                submitObj: {
                    "score": 0,
                    "task_1": {
                        "task_achievement": "none",
                        "cohesion_and_coherence": "none",
                        "lexical_resource": "none",
                        "grammatical_range_and_accuracy": "none"
                    },
                    "task_2": {
                        "task_achievement": "none",
                        "cohesion_and_coherence": "none",
                        "lexical_resource": "none",
                        "grammatical_range_and_accuracy": "none"
                    },
                    "comment": "none"
                }
            });
        }
    }

    handleScoring = async () => {
        try {
            this.handleLoading();

            /*const submitScore = await axios({
                method: 'post',
                url: api_base_url + "/api/test/test-writing-note",
                headers: {
                    Authorization: "bearer " + this.props.token
                },
                data: {
                    note: [{
                        test_id: this.props.test_id,
                        tester: this.props.submitterUsername,
                        teacher: this.props.teacherUsername,
                        note: this.state.submitObj
                    }]
                }
            })

            CreateNewNotification(
                this.props.submitterUsername,
                "writing_score",
                {
                    test_id: this.props.test_id,
                    message: "Your writing score is out now! Let's go and check at your report.",
                }
            );
            */

            await this.handleAskForHeadTeacherApproval();

            this.props.handleCloseScoringDialog();
            this.handleEndLoading();
        } catch (error) {
            console.log(error);
        }
    }

    handleAskForHeadTeacherApproval = async () => {
        try {
            await axios({
                method: 'post',
                url: "https://chatter-funky-busby.glitch.me/test/head-teacher/submit",
                data: {
                    note: {
                        test_id: this.props.test_id,
                        tester: this.props.submitterUsername,
                        teacher: this.props.teacherUsername,
                        note: this.state.submitObj
                    },
                    submitted_test_id: this.props.submissionId,
                    createdAt: new Date(Date.now())
                }
            });

            await this.props.handleGetAllSubmittedTests();
        } catch (error) {
            console.log(error);
        }
    }

    handleChange = (event) => {
        this.state.submitObj[event.target.name.split('-')[0]][event.target.name.split('-')[1]] = event.target.value;
        this.calculateBandScore();
    }

    handleChangeBMAT = (event) => {
        this.state.submitObj[event.target.name] = event.target.value;
        console.log(this.state.submitObj);
    }

    calculateBandScore = () => {
        const sec1 = [
            this.state.submitObj.task_1.cohesion_and_coherence === "none" ? 0 : Number(this.state.submitObj.task_1.cohesion_and_coherence),
            this.state.submitObj.task_1.grammatical_range_and_accuracy === "none" ? 0 : Number(this.state.submitObj.task_1.grammatical_range_and_accuracy),
            this.state.submitObj.task_1.lexical_resource === "none" ? 0 : Number(this.state.submitObj.task_1.lexical_resource),
            this.state.submitObj.task_1.task_achievement === "none" ? 0 : Number(this.state.submitObj.task_1.task_achievement),
        ].reduce((accumulator, currentValue) => accumulator + currentValue);
        const sec2 = [
            this.state.submitObj.task_2.cohesion_and_coherence === "none" ? 0 : Number(this.state.submitObj.task_2.cohesion_and_coherence),
            this.state.submitObj.task_2.grammatical_range_and_accuracy === "none" ? 0 : Number(this.state.submitObj.task_2.grammatical_range_and_accuracy),
            this.state.submitObj.task_2.lexical_resource === "none" ? 0 : Number(this.state.submitObj.task_2.lexical_resource),
            this.state.submitObj.task_2.task_achievement === "none" ? 0 : Number(this.state.submitObj.task_2.task_achievement),
        ].reduce((accumulator, currentValue) => accumulator + currentValue);

        const bandScore = (this.roundedResult(sec1 / 4) * 0.3333) + (this.roundedResult(sec2 / 4) * 0.6666);
        const finalScore = this.roundedResult(bandScore);
        this.state.submitObj.score = finalScore;
        this.setState({
            bandScoreResult: finalScore
        });
    }

    roundedResult = (result) => {
        let roundedResult;

        if (result % 1 < 0.25) {
            console.log('1')
            roundedResult = Math.floor(result);
        } else if (result % 1 >= 0.25 && result % 1 < 0.75) {
            roundedResult = Math.floor(result) + 0.5;
        } else {
            roundedResult = Math.ceil(result);
        }

        return parseFloat(roundedResult);
    }

    handleChangeScore = (event) => {
        this.state.submitObj.score = event.target.value
    }

    handleChangeComment = (event) => {
        this.state.submitObj.comment = event.target.value
    }

    handleLoading = () => {
        this.setState({
            isLoading: true,
        });
    }

    handleEndLoading = () => {
        this.setState({
            isLoading: false,
        });
    }

    render() {
        const { classes } = this.props;

        const ieltsDialog = (<Grid item xs={12} sm={9}>
            <Dialog maxWidth='lg' open={this.props.isScoring} onClose={this.props.handleCloseScoringDialog} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">
                    Submit IELTS Writing Score
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Grid container>
                            <Grid item xs={12}>
                                <Box fontWeight="fontWeightBold" display='inline' style={{ fontSize: '1.5rem', marginBottom: '10%' }}>Information</Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box fontWeight="fontWeightBold" display='inline'>Submission id:</Box> {this.props.submissionId}
                            </Grid>
                            <Grid item xs={12}>
                                <Box fontWeight="fontWeightBold" display='inline'>Test id:</Box> {this.props.test_id}
                            </Grid>
                            <Grid item xs={12}>
                                <Box fontWeight="fontWeightBold" display='inline'>Tester:</Box> {this.props.tester}
                            </Grid>
                            <Grid item xs={12}>
                                <Box fontWeight="fontWeightBold" display='inline'>Assigned teacher:</Box> {this.props.assignTo}
                            </Grid>
                            <Grid item xs={12}>
                                <Box fontWeight="fontWeightBold" display='inline'>Submit date:</Box> {new Date(this.props.submitDate).toDateString()}
                            </Grid>
                        </Grid>
                    </DialogContentText>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>
                                <Box fontWeight="fontWeightBold" display='inline'>Section 1</Box>
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography>
                                        Task achievement
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        type="number"
                                        disabled={this.state.isLoading}
                                        name="task_1-task_achievement"
                                        margin="dense"
                                        variant="outlined"
                                        autoComplete="new-password"
                                        inputProps={{ min: 0 }}
                                        fullWidth
                                        onChange={this.handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography>
                                        Cohesion and coherence
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        type="number"
                                        disabled={this.state.isLoading}
                                        name="task_1-cohesion_and_coherence"
                                        margin="dense"
                                        variant="outlined"
                                        autoComplete="new-password"
                                        inputProps={{ min: 0 }}
                                        fullWidth
                                        onChange={this.handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography>
                                        Lexical resource
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        type="number"
                                        disabled={this.state.isLoading}
                                        name="task_1-lexical_resource"
                                        margin="dense"
                                        variant="outlined"
                                        autoComplete="new-password"
                                        inputProps={{ min: 0 }}
                                        fullWidth
                                        onChange={this.handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography>
                                        Grammatical range and accuracy
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        type="number"
                                        disabled={this.state.isLoading}
                                        name="task_1-grammatical_range_and_accuracy"
                                        margin="dense"
                                        variant="outlined"
                                        autoComplete="new-password"
                                        inputProps={{ min: 0 }}
                                        fullWidth
                                        onChange={this.handleChange}
                                    />
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>
                                <Box fontWeight="fontWeightBold" display='inline'>Section 2</Box>
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography>
                                        Task achievement
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        type="number"
                                        disabled={this.state.isLoading}
                                        name="task_2-task_achievement"
                                        margin="dense"
                                        variant="outlined"
                                        autoComplete="new-password"
                                        inputProps={{ min: 0 }}
                                        fullWidth
                                        onChange={this.handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography>
                                        Cohesion and coherence
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        type="number"
                                        disabled={this.state.isLoading}
                                        name="task_2-cohesion_and_coherence"
                                        margin="dense"
                                        variant="outlined"
                                        autoComplete="new-password"
                                        inputProps={{ min: 0 }}
                                        fullWidth
                                        onChange={this.handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography>
                                        Lexical resource
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        type="number"
                                        disabled={this.state.isLoading}
                                        name="task_2-lexical_resource"
                                        margin="dense"
                                        variant="outlined"
                                        autoComplete="new-password"
                                        inputProps={{ min: 0 }}
                                        fullWidth
                                        onChange={this.handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography>
                                        Grammatical range and accuracy
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        type="number"
                                        disabled={this.state.isLoading}
                                        name="task_2-grammatical_range_and_accuracy"
                                        margin="dense"
                                        variant="outlined"
                                        autoComplete="new-password"
                                        inputProps={{ min: 0 }}
                                        fullWidth
                                        onChange={this.handleChange}
                                    />
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Typography style={{ marginTop: '10%' }}>
                        Band score
                    </Typography>
                    <TextField
                        value={this.state.bandScoreResult}
                        disabled
                        name="task_1-score"
                        margin="dense"
                        variant="outlined"
                        autoComplete="new-password"
                        fullWidth
                        onChange={this.handleChangeScore}
                    />
                    <Typography>
                        Comments
                    </Typography>
                    <TextField
                        disabled={this.state.isLoading}
                        name="comment"
                        margin="dense"
                        variant="outlined"
                        autoComplete="new-password"
                        fullWidth
                        multiline
                        rows={4}
                        onChange={this.handleChangeComment}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.handleCloseScoringDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={this.handleScoring} variant="contained" style={{ backgroundColor: '#76323F', color: 'white' }}>
                        {this.state.isLoading
                            ? <CircularProgress size={20} style={{ color: 'white' }} />
                            : "Submit"
                        }
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>);

        const bmatDialog = (<Grid item xs={12} sm={9}>
            <Dialog maxWidth='lg' open={this.props.isScoring} onClose={this.props.handleCloseScoringDialog} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">
                    Submit BMAT Writing Score
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Grid container>
                            <Grid item xs={12}>
                                <Box fontWeight="fontWeightBold" display='inline' style={{ fontSize: '1.5rem', marginBottom: '10%' }}>Information</Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box fontWeight="fontWeightBold" display='inline'>Submission id:</Box> {this.props.submissionId}
                            </Grid>
                            <Grid item xs={12}>
                                <Box fontWeight="fontWeightBold" display='inline'>Test id:</Box> {this.props.test_id}
                            </Grid>
                            <Grid item xs={12}>
                                <Box fontWeight="fontWeightBold" display='inline'>Tester:</Box> {this.props.tester}
                            </Grid>
                            <Grid item xs={12}>
                                <Box fontWeight="fontWeightBold" display='inline'>Assigned teacher:</Box> {this.props.assignTo}
                            </Grid>
                            <Grid item xs={12}>
                                <Box fontWeight="fontWeightBold" display='inline'>Submit date:</Box> {new Date(this.props.submitDate).toDateString()}
                            </Grid>
                        </Grid>
                    </DialogContentText>
                    <Typography style={{ marginTop: '20px' }}>
                        Quality of Content
                    </Typography>
                    <Select
                        name="quality_of_content"
                        margin="dense"
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null
                        }}
                        fullWidth
                        variant="outlined"
                        onChange={this.handleChangeBMAT}
                    >
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                    </Select>
                    <Typography style={{ marginTop: '20px' }}>
                        Quality of English
                    </Typography>
                    <Select
                        name="quality_of_english"
                        margin="dense"
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null
                        }}
                        fullWidth
                        variant="outlined"
                        onChange={this.handleChangeBMAT}
                    >
                        <MenuItem value={'A'}>A</MenuItem>
                        <MenuItem value={'B'}>B</MenuItem>
                        <MenuItem value={'C'}>C</MenuItem>
                        <MenuItem value={'D'}>D</MenuItem>
                        <MenuItem value={'E'}>E</MenuItem>
                    </Select>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.handleCloseScoringDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={this.handleScoring} variant="contained" style={{ backgroundColor: '#76323F', color: 'white' }}>
                        {this.state.isLoading
                            ? <CircularProgress size={20} style={{ color: 'white' }} />
                            : "Submit"
                        }
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>);

        return (
            <div>
                {this.props.test?.testCategory === "BMAT" ? bmatDialog : ieltsDialog}
            </div>
        )
    }
}

export default compose(
    withStyles(useStyles)
)(WritingScoring);
