import React, { useEffect, useState } from 'react';

//MUI

import { ThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';

//Font
import 'typeface-glacial-indifference';

//Others
import { Box, Button, CardMedia, CircularProgress, Divider, FormControl, FormControlLabel, Grid, InputLabel, Paper, Select, Switch, Typography } from '@material-ui/core';
import { GetAllTestReport } from '../../../../services/testReportServices';
import Chart from "react-google-charts";
import moment from 'moment';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';

const useStyles = theme => ({
    root: {
        padding: theme.spacing(12, 4),
    }
});

const theme = createMuiTheme({
    typography: {
        fontFamily: [
            'Roboto'
        ].join(','),
    },
});

const UserEngagement = () => {
    const years = [2022, 2023, 2024];
    const [tests, setTests] = useState({});
    const [year, setYear] = useState(2024);
    const [type, setType] = useState("All");
    const [category, setCategory] = useState("All");
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        document.body.style.overflow = "auto";
        document.querySelector("body").scrollTo(0, 0);

        getAllTests();
    }, []);

    const getAllTests = async () => {
        setIsLoading(true);

        const res = await GetAllTestReport();
        if (res.success) {
            let tempObj = {};
            console.log(res.res.filter((t) => !["bellebelle", "117337903114766421177", "phisonkha"].includes(t.username)));
            res.res.filter((t) => !["bellebelle", "117337903114766421177", "phisonkha"].includes(t.username)).map((test) => {
                if (tempObj[`${moment(test.date_created).year()}-${moment(test.date_created).month() + 1}`]) {
                    tempObj[`${moment(test.date_created).year()}-${moment(test.date_created).month() + 1}`].push(test);
                } else {
                    tempObj[`${moment(test.date_created).year()}-${moment(test.date_created).month() + 1}`] = [test];
                }
            })

            setTests(tempObj);
            setIsLoading(false);
        } else {
            console.error("Could not get tests.")
            setIsLoading(false);
        }
    }

    const onClickYear = (year) => {
        setYear(year);
    }

    const onClickType = (type) => {
        setType(type);
    }

    const onClickCategory = (category) => {
        setCategory(category);
    }

    const onChangeYear = (event) => {
        setYear(event.target.value);
    }

    return <ThemeProvider theme={theme}>
        <Grid item xs={12}>
            <Grid container style={{ paddingBottom: "20px" }}>
                <Grid item align="left" xs>
                    <Typography variant="h5" style={{ fontWeight: "bold" }}>
                        User engagement
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Grid container spacing={1} justify="flex-end" alignItems='center'>
                        <Grid item>
                            <Typography style={{ fontWeight: "bold" }}>
                                Type:
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Button disabled={category === "All"} disableElevation variant={category === "All" ? 'contained' : 'outlined'} onClick={() => { onClickCategory("All") }}>
                                All
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button disabled={category === "Free"} disableElevation variant={category === "Free" ? 'contained' : 'outlined'} onClick={() => { onClickCategory("Free") }}>
                                Free
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button disabled={category === "Paid"} disableElevation variant={category === "Paid" ? 'contained' : 'outlined'} onClick={() => { onClickCategory("Paid") }}>
                                Paid
                            </Button>
                        </Grid>
                        <Grid item>
                            <Divider style={{ width: "10px", color: "black" }} />
                        </Grid>
                        <Grid item>
                            <Button disabled={type === "All"} disableElevation variant={type === "All" ? 'contained' : 'outlined'} onClick={() => { onClickType("All") }}>
                                All
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button disabled={type === "IELTS"} disableElevation variant={type === "IELTS" ? 'contained' : 'outlined'} onClick={() => { onClickType("IELTS") }}>
                                IELTS
                            </Button>
                        </Grid>
                        <Grid item style={{ marginRight: "20px" }}>
                            <Button disabled={type === "BMAT"} disableElevation variant={type === "BMAT" ? 'contained' : 'outlined'} onClick={() => { onClickType("BMAT") }}>
                                BMAT
                            </Button>
                        </Grid>
                        <Grid item>
                            <Typography style={{ fontWeight: "bold" }}>
                                Year:
                            </Typography>
                        </Grid>
                        {/* <Grid item>
                            <Button disabled={year === 2022} disableElevation variant={year === 2022 ? 'contained' : 'outlined'} onClick={() => { onClickYear(2022) }}>
                                2022
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button disabled={year === 2023} disableElevation variant={year === 2023 ? 'contained' : 'outlined'} onClick={() => { onClickYear(2023) }}>
                                2023
                            </Button>
                        </Grid> */}
                        <Grid item>
                            <FormControl size="small" variant="outlined">
                                <Select
                                    id="demo-simple-select-required"
                                    value={year}
                                    onChange={onChangeYear}
                                >
                                    {years.map((year) => {
                                        return <MenuItem disabled={year > 2024} value={year}>{year}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Paper>
                <Grid container>
                    <Grid item align="center" xs={12} style={{ padding: "30px", paddingTop: isLoading ? 200 : 30, paddingBottom: isLoading ? 200 : 30 }}>
                        {isLoading ? <CircularProgress size={100} /> : <Chart
                            width={1000}
                            height={450}
                            chartType="Bar"
                            loader={<div>Loading Chart</div>}
                            data={[['Month', 'Test count'],
                            ['Jan', tests[`${year}-1`]?.filter((obj) => { return type === "All" ? true : obj.test_type.includes(type) })?.filter((obj) => { return category === "All" ? true : category === "Free" ? obj.test_type.toLowerCase().includes("free") : !obj.test_type.toLowerCase().includes("free") })?.length],
                            ['Feb', tests[`${year}-2`]?.filter((obj) => { return type === "All" ? true : obj.test_type.includes(type) })?.filter((obj) => { return category === "All" ? true : category === "Free" ? obj.test_type.toLowerCase().includes("free") : !obj.test_type.toLowerCase().includes("free") })?.length],
                            ['Mar', tests[`${year}-3`]?.filter((obj) => { return type === "All" ? true : obj.test_type.includes(type) })?.filter((obj) => { return category === "All" ? true : category === "Free" ? obj.test_type.toLowerCase().includes("free") : !obj.test_type.toLowerCase().includes("free") })?.length],
                            ['Apr', tests[`${year}-4`]?.filter((obj) => { return type === "All" ? true : obj.test_type.includes(type) })?.filter((obj) => { return category === "All" ? true : category === "Free" ? obj.test_type.toLowerCase().includes("free") : !obj.test_type.toLowerCase().includes("free") })?.length],
                            ['May', tests[`${year}-5`]?.filter((obj) => { return type === "All" ? true : obj.test_type.includes(type) })?.filter((obj) => { return category === "All" ? true : category === "Free" ? obj.test_type.toLowerCase().includes("free") : !obj.test_type.toLowerCase().includes("free") })?.length],
                            ['Jun', tests[`${year}-6`]?.filter((obj) => { return type === "All" ? true : obj.test_type.includes(type) })?.filter((obj) => { return category === "All" ? true : category === "Free" ? obj.test_type.toLowerCase().includes("free") : !obj.test_type.toLowerCase().includes("free") })?.length],
                            ['Jul', tests[`${year}-7`]?.filter((obj) => { return type === "All" ? true : obj.test_type.includes(type) })?.filter((obj) => { return category === "All" ? true : category === "Free" ? obj.test_type.toLowerCase().includes("free") : !obj.test_type.toLowerCase().includes("free") })?.length],
                            ['Aug', tests[`${year}-8`]?.filter((obj) => { return type === "All" ? true : obj.test_type.includes(type) })?.filter((obj) => { return category === "All" ? true : category === "Free" ? obj.test_type.toLowerCase().includes("free") : !obj.test_type.toLowerCase().includes("free") })?.length],
                            ['Sep', tests[`${year}-9`]?.filter((obj) => { return type === "All" ? true : obj.test_type.includes(type) })?.filter((obj) => { return category === "All" ? true : category === "Free" ? obj.test_type.toLowerCase().includes("free") : !obj.test_type.toLowerCase().includes("free") })?.length],
                            ['Oct', tests[`${year}-10`]?.filter((obj) => { return type === "All" ? true : obj.test_type.includes(type) })?.filter((obj) => { return category === "All" ? true : category === "Free" ? obj.test_type.toLowerCase().includes("free") : !obj.test_type.toLowerCase().includes("free") })?.length],
                            ['Nov', tests[`${year}-11`]?.filter((obj) => { return type === "All" ? true : obj.test_type.includes(type) })?.filter((obj) => { return category === "All" ? true : category === "Free" ? obj.test_type.toLowerCase().includes("free") : !obj.test_type.toLowerCase().includes("free") })?.length],
                            ['Dec', tests[`${year}-12`]?.filter((obj) => { return type === "All" ? true : obj.test_type.includes(type) })?.filter((obj) => { return category === "All" ? true : category === "Free" ? obj.test_type.toLowerCase().includes("free") : !obj.test_type.toLowerCase().includes("free") })?.length]]}
                            options={{
                                // Material design options
                                chart: {
                                    title: `Number of ${type} tests`,
                                    subtitle: `Number of completed ${type} tests in the system.`,
                                },
                            }}
                            margin={{
                                top: 10,
                                right: 30,
                                left: 0,
                                bottom: 0,
                            }}
                            // For tests
                            rootProps={{ 'data-testid': '2' }}
                        />}
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    </ThemeProvider>
};

export default compose(
    withStyles(useStyles)
)(UserEngagement);
