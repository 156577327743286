//React
import React, { Component } from 'react';
import { compose } from 'recompose';

//Material UI
import Button from '@material-ui/core/Button';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';

//Icons
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';

//Constants
import * as styleConstants from "../../../../constants/styleConstants";

//react-router-dom
import {
    Link
} from "react-router-dom";

//Animations
import Fade from 'react-reveal/Fade';

//Others
import VisibilitySensor from 'react-visibility-sensor';

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%'
    },
    rootContainer: {
        maxWidth: '75%'

    },
    rootCard: {
        maxWidth: "75%",
        width: '75%',
        margin: 10,
    },
    gridContainer: {
        maxWidth: '90%'
    },
    margin: {
        margin: 100
    },
    marginTop: {
        marginTop: 100
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginTop: theme.spacing(5)
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    header: {
        maxWidth: "80%"
    },
    body: {
        maxWidth: "80%",
        marginTop: 30
    },
    divider: {
        maxWidth: "100%"
    },
    image: {
        margin: 'auto',
        display: 'block',
        margin: 10
    },
    commentTextField: {
        width: '100%',
        maxWidth: '100%'
    },
    commentGridItem: {
        maxWidth: "80%",
        marginTop: 30,
        marginBottom: 100
    }
});

const theme = createMuiTheme({
    typography: {
        fontFamily: [
            'Prompt'
        ].join(','),
    },
});


class OurTestsSection extends Component {
    constructor() {
        super();
        this.state = {
            isPaperAppear: false,
            isImageAppear: false,
            isTextAppear: false,
            isVisible: false
        };
    }

    componentDidMount() {
    }

    timeout = (delay) => {
        return new Promise(res => setTimeout(res, delay));
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    handlehandleShowAnimation = async (isVisible) => {
        if (isVisible) {
            this.setState({
                isPaperAppear: true,
                isVisible: isVisible
            });
            await this.timeout(1000);
            this.setState({
                isImageAppear: true
            });
            await this.timeout(1000);
            this.setState({
                isTextAppear: true
            });
        } if (!isVisible) {
            this.setState({
                isVisible: isVisible,
                isTextAppear: false
            });
            await this.timeout(1000);
            this.setState({
                isImageAppear: false
            });
            await this.timeout(1000);
            this.setState({
                isPaperAppear: false
            });
        }
    }

    render() {
        const { classes } = this.props;

        return (
            <Grid container style={{ paddingLeft: "7%", paddingRight: "7%" }}>
                <Grid item align="center" xs={12} sm={6} style={{ padding: '10%' }}>
                    <Grid container>
                        <Fade top cascade when={this.state.isVisible}>
                            <Grid item align="left" xs={12}>
                                <VisibilitySensor onChange={(isVisible) => {
                                    if (isVisible) {
                                        this.handlehandleShowAnimation(isVisible);
                                    }
                                }}>
                                    <Typography variant="h3" style={{ fontFamily: styleConstants.fontStyle, fontWeight: styleConstants.fontWeight.bold, color: "#991514" }} >
                                        OUR TESTS
                                    </Typography>
                                </VisibilitySensor>
                            </Grid>
                            <Grid item xs={12} align="left" style={{ marginTop: '5%' }}>
                                <Typography variant="h5" style={{ fontFamily: styleConstants.fontStyle, fontWeight: styleConstants.fontWeight.thin, }}>
                                    <CheckBoxOutlinedIcon /> Computer-based tests.
                                </Typography>
                                <Typography variant="h5" style={{ ffontFamily: styleConstants.fontStyle, fontWeight: styleConstants.fontWeight.thin, }}>
                                    <CheckBoxOutlinedIcon /> Original materials
                                </Typography>
                                <Typography variant="h5" style={{ fontFamily: styleConstants.fontStyle, fontWeight: styleConstants.fontWeight.thin, marginBottom: "40px" }}>
                                    <CheckBoxOutlinedIcon /> Intelligent skill analysis
                                </Typography>
                                <Link to="/ourtests" style={{ textDecoration: 'none', color: '#0f0f0f' }}>
                                    <Button variant="contained" size="large" style={{ backgroundColor: "#3f5e75", color: "white" }}>
                                        <Typography variant="h6" style={{ fontWeight: styleConstants.fontWeight.semiBold, fontFamily: styleConstants.fontStyle }}>
                                            Our tests
                                        </Typography>
                                    </Button>
                                </Link>
                            </Grid>
                        </Fade>
                    </Grid>
                </Grid>
                <Grid item align="center" xs={12} sm={6} style={{ zIndex: 1, paddingTop: "30px" }}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Grid container spacing={5}>
                                <Grid item align="center" xs={6} style={{ height: "350px" }}>
                                    <Fade cascade delay={1000} when={this.state.isVisible}> <Paper style={{
                                        padding: "16px",
                                        paddingBottom: "0px",
                                        height: "172px",
                                        borderRadius: "25px",
                                        background: "#97a7b2",
                                        position: "relative",
                                    }} >
                                        <Fade cascade delay={2000} when={this.state.isVisible}>
                                            <Link to="/ourtests" style={{ textDecoration: 'none', color: '#0f0f0f' }}>
                                                <CardMedia
                                                    component="img"
                                                    style={{
                                                        position: "absolute",
                                                        bottom: 0,
                                                        left: 0,
                                                        right: 0,
                                                        top: 0,
                                                        marginTop: "-22.5%",
                                                        height: "330px",
                                                        width: "100%",
                                                        backgroundSize: "cover",
                                                    }}
                                                    src="graphics/IELTS (Academic).png"
                                                />
                                            </Link>
                                        </Fade>
                                    </Paper>
                                    </Fade>
                                    <Fade cascade delay={3000} when={this.state.isVisible}>
                                        <Link to="/ourtests" style={{ textDecoration: 'none', color: '#0f0f0f' }}>
                                            <Typography variant="h5" style={{ fontWeight: styleConstants.fontWeight.semiBold, fontFamily: styleConstants.fontStyle, marginTop: "10px" }}>
                                                IELTS (ACADEMIC)
                                            </Typography>
                                        </Link>
                                    </Fade>
                                </Grid>
                                <Grid item align="center" xs={6} style={{ height: "350px" }}>
                                    <Fade cascade delay={1000} when={this.state.isVisible}> <Paper style={{
                                        padding: "16px",
                                        paddingBottom: "0px",
                                        height: "172px",
                                        borderRadius: "25px",
                                        background: "#97a7b2",
                                        position: "relative",
                                    }} >
                                        <Fade cascade delay={2000} when={this.state.isVisible}> <Link to="/ourtests" style={{ textDecoration: 'none', color: '#0f0f0f' }}>
                                            <CardMedia
                                                component="img"
                                                style={{
                                                    position: "absolute",
                                                    bottom: 0,
                                                    left: 0,
                                                    right: 0,
                                                    top: 0,
                                                    marginTop: "-22%",
                                                    height: "330px",
                                                    width: "100%",
                                                    backgroundSize: "cover",
                                                }}
                                                src="graphics/BMAT.png"
                                            />
                                        </Link>
                                        </Fade>
                                    </Paper>
                                    </Fade>
                                    <Fade cascade delay={3000} when={this.state.isVisible}>
                                        <Link to="/ourtests" style={{ textDecoration: 'none', color: '#0f0f0f' }}>
                                            <Typography variant="h5" style={{ fontWeight: styleConstants.fontWeight.semiBold, fontFamily: styleConstants.fontStyle, marginTop: "10px" }}>
                                                TBAT
                                            </Typography>
                                        </Link>
                                    </Fade>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={5}>
                                <Grid item align="center" xs={6} style={{ height: "350px" }}>
                                    <Fade cascade delay={1000} when={this.state.isVisible}> <Paper style={{
                                        padding: "16px",
                                        paddingBottom: "0px",
                                        height: "172px",
                                        borderRadius: "25px",
                                        background: "#97a7b2",
                                        position: "relative",
                                    }} >
                                        <Fade cascade delay={2000} when={this.state.isVisible}>
                                            <Link to="/ourtests" style={{ textDecoration: 'none', color: '#0f0f0f' }}>
                                                <CardMedia
                                                    component="img"
                                                    style={{
                                                        position: "absolute",
                                                        bottom: 0,
                                                        left: 0,
                                                        right: 0,
                                                        top: 0,
                                                        marginTop: "-22%",
                                                        height: "330px",
                                                        width: "100%",
                                                        backgroundSize: "cover",
                                                    }}
                                                    src="graphics/CU-TEP.png"
                                                />
                                            </Link>
                                        </Fade>
                                    </Paper>
                                    </Fade>
                                    <Fade cascade delay={3000} when={this.state.isVisible}>
                                        <Link to="/ourtests" style={{ textDecoration: 'none', color: '#0f0f0f' }}>
                                            <Typography variant="h5" style={{ fontWeight: styleConstants.fontWeight.semiBold, fontFamily: styleConstants.fontStyle, marginTop: "10px" }}>
                                                CU-TEP
                                            </Typography>
                                        </Link>
                                    </Fade>
                                </Grid>
                                <Grid item align="center" xs={6} style={{ height: "350px" }}>
                                    <VisibilitySensor onChange={(isVisible) => {
                                        this.handlehandleShowAnimation(isVisible);
                                    }}>
                                        <Fade cascade delay={1000} when={this.state.isVisible}> <Paper style={{
                                            padding: "16px",
                                            paddingBottom: "0px",
                                            height: "172px",
                                            borderRadius: "25px",
                                            background: "#97a7b2",
                                            position: "relative",
                                        }} >
                                            <Fade cascade delay={2000} when={this.state.isVisible}> <Link to="/ourtests" style={{ textDecoration: 'none', color: '#0f0f0f' }}>
                                                <CardMedia
                                                    component="img"
                                                    style={{
                                                        position: "absolute",
                                                        bottom: 0,
                                                        left: 0,
                                                        right: 0,
                                                        top: 0,
                                                        marginTop: "-23%",
                                                        height: "330px",
                                                        width: "100%",
                                                        backgroundSize: "cover",
                                                    }}
                                                    src="graphics/TOEIC.png"
                                                />
                                            </Link>
                                            </Fade>
                                        </Paper>
                                        </Fade>
                                    </VisibilitySensor>
                                    <Fade cascade delay={3000} when={this.state.isVisible}>
                                        <Link to="/ourtests" style={{ textDecoration: 'none', color: '#0f0f0f' }}>
                                            <Typography variant="h5" style={{ fontWeight: styleConstants.fontWeight.semiBold, fontFamily: styleConstants.fontStyle, marginTop: "10px" }}>
                                                TOEIC
                                            </Typography>
                                        </Link>
                                    </Fade>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

export default compose(
    withStyles(useStyles)
)(OurTestsSection);
