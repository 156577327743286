import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import BorderColorRoundedIcon from '@material-ui/icons/BorderColorRounded';
import HearingRoundedIcon from '@material-ui/icons/HearingRounded';
import MenuBookRoundedIcon from '@material-ui/icons/MenuBookRounded';
import React, { Component } from 'react';
import { compose } from 'recompose';

//Icon

//import other library
import 'react-calendar/dist/Calendar.css';

//react-router-dom
import {
    Link
} from "react-router-dom";

const useStyles = theme => ({
    root: {
        minWidth: 275,
    }
});


class ChooseTestType extends Component {
    constructor() {
        super();
        this.state = {
            avalibleCourses: [
                {
                    name: "Reading",
                    type: "IELTS",
                    amount: 0,
                    duration: 60,
                    teacher: 'Jirayu Promsongwong'
                },
                {
                    name: "Writing",
                    type: "IELTS",
                    amount: 0,
                    duration: 60,
                    teacher: 'Jirayu Promsongwong'
                },
                {
                    name: "Listening",
                    type: "IELTS",
                    amount: 0,
                    duration: 30,
                    teacher: 'Jirayu Promsongwong'
                }
            ],
            isSelectPlan: false,
            selectedTest: 'Listening'
        }
    }

    render() {
        const { classes } = this.props;

        return (
            <Grid>
            { this.props.userInfo.name !== "Guest" ? (<Dialog
                open={this.props.isChooseFreeTest}
                onClose={this.props.handleCloseChooseFreeTest}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Please select your test"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {this.state.avalibleCourses.filter(test => test.name === this.state.selectedTest).map(testObj => (
                            <Paper variant="outlined" style={{ padding: '100px' }}>
                                <Typography align='center' variant="h3" style={{ width: '100%' }}>
                                    <Box fontWeight="fontWeightBold" display='inline'>{testObj.name}</Box>
                                </Typography>
                                <Typography align='center' variant="subtitle1" gutterBottom>
                                    {testObj.amount === 0 ? 'FREE' : testObj.amount + ' Baht'}
                                </Typography>
                                <Typography align='center' variant="subtitle1" gutterBottom>
                                    <Box fontWeight="fontWeightBold" display='inline'>Type: </Box> {testObj.type}
                                </Typography>

                                <Typography align='center' variant="subtitle1" gutterBottom>
                                    <Box fontWeight="fontWeightBold" display='inline'>Duration: </Box>{testObj.duration + ' Minutes'}
                                </Typography>
                                <Typography align='center' variant="subtitle1" gutterBottom>
                                    <Box fontWeight="fontWeightBold" display='inline'>Duration: </Box>{this.props.userInfo.name}
                                </Typography>
                            </Paper>
                        ))}
                        <BottomNavigation
                            value={this.state.selectedTest}
                            onChange={(event, newValue) => {
                                console.log(newValue);
                                this.setState({
                                    selectedTest: newValue
                                })
                            }}
                            showLabels
                            style={{ marginTop: '20px' }}
                        >
                            <BottomNavigationAction label="Listening" value='Listening' icon={<HearingRoundedIcon />} />
                            <BottomNavigationAction label="Reading" value='Reading' icon={<MenuBookRoundedIcon />} />
                            <BottomNavigationAction label="Writing" value='Writing' icon={<BorderColorRoundedIcon />} />
                        </BottomNavigation>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.handleCloseChooseFreeTest} color="primary">
                        Cancel
                    </Button>
                    <Link
                        //to={"/test/user/verification?redirectPath=/test/" + this.state.selectedTest.toLowerCase()}
                        to={"/test/" + this.state.selectedTest.toLowerCase()}
                        color="inherit"
                        underline="none"
                        style={{ textDecoration: 'none', color: '#565656' }}
                    >
                        <Button onClick={this.handleDoneTestDialog} color="primary" autoFocus>
                            Confirm
                        </Button>
                    </Link>
                </DialogActions>
            </Dialog>) : <Dialog
                open={this.props.isChooseFreeTest}
                onClose={this.props.handleCloseChooseFreeTest}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Authentication required"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {"Please login first to access this test section"}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.handleCloseChooseFreeTest} color="primary">
                        Cancel
                    </Button>
                    <Link
                        to={"/login"}
                        color="inherit"
                        underline="none"
                        style={{ textDecoration: 'none', color: '#565656' }}
                    >
                        <Button onClick={this.handleDoneTestDialog} color="primary" autoFocus>
                            Login
                        </Button>
                    </Link>
                </DialogActions>
            </Dialog>
            }
            </Grid>
        )
    }
}
export default compose(
    withStyles(useStyles)
)(ChooseTestType);