import MaterialTable from 'material-table';

import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { compose } from 'recompose';

//import other library
import 'react-calendar/dist/Calendar.css';

//react-router-dom

const axios = require('axios').default;
const useStyles = theme => ({
    root: {
        minWidth: 275,
    }
});


class UserFeedbackTable extends Component {
    constructor() {
        super();
        this.state = {
            feedbacksArr: []
        }
    }

    componentDidMount = () => {
        this.handleGetAllFeedbacks();
    }

    handleGetAllFeedbacks = async () => {
        try {
            const feedbacksArr = [];

            const res = await axios({
                method: 'get',
                url: 'https://chatter-funky-busby.glitch.me/test/evaluations'
            });

            const feedbacks = res.data.evaluations;
            console.log(feedbacks);

            await feedbacks.map((feedback, index) => {
                feedbacksArr.push({
                    submitterName: feedback.submitterName,
                    Q1: feedback.evaluationObj[0].rating,
                    Q2: feedback.evaluationObj[1].rating,
                    Q3: feedback.evaluationObj[2].rating,
                    createdAt: new Date(feedback.createdAt).toDateString(),
                    testType: feedback.testType,
                    additionalComment: feedback.additionalComment,
                })
            })

            this.setState({
                feedbacksArr: feedbacksArr
            });

        } catch (err) {
            console.log(err);
        }
    }

    render() {
        const { classes } = this.props;

        return (
            <Grid item xs={12} sm={9}>
                <MaterialTable
                    data={this.state.feedbacksArr}
                    columns={[
                        { title: 'Submitter', field: 'submitterName', editable: 'never' },
                        { title: 'How satisfied were you with your experience on overall test system today?', field: 'Q1', editable: 'never' },
                        { title: 'How much would you rate difficulty of this test?', field: 'Q2', editable: 'never' },
                        { title: 'Do you want to recommend this test to your friends?', field: 'Q3', editable: 'never' },
                        { title: 'Comments', field: 'additionalComment', editable: 'never' },
                        { title: 'Type', field: 'testType', editable: 'never' },
                        { title: 'Created', field: 'createdAt', editable: 'never' }
                    ]}
                    options={{
                        actionsColumnIndex: -1,
                        exportButton: true,
                        grouping: true
                    }}
                    title='User feedbacks'
                />
            </Grid>
        )
    }
}
export default compose(
    withStyles(useStyles)
)(UserFeedbackTable);