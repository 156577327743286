import React, { useEffect, useState } from 'react';

//MUI
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';

import { ThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';

//Icons
import VisibilityRoundedIcon from '@material-ui/icons/VisibilityRounded';
import { FiTarget } from 'react-icons/fi';

//Constats
import * as styleConstants from '../../../../../constants/styleConstants';


//Font
import 'typeface-glacial-indifference';

//Others
import Fade from 'react-reveal/Fade';
import VisibilitySensor from 'react-visibility-sensor';


const useStyles = theme => ({
    root: {
        padding: theme.spacing(12, 4),
    }
});

const theme = createMuiTheme({
    typography: {
        fontFamily: [
            styleConstants.fontStyle
        ].join(','),
    },
});

const Objectives = () => {
    const [isVisible, setIsVisible] = useState(false);
    const [isVisibleB, setIsVisibleB] = useState(false);

    useEffect(() => {
        document.body.style.overflow = "auto";
        document.body.style.backgroundColor = "#efefef";
        document.querySelector("body").scrollTo(0, 0);
    }, []);

    const mobileUI = (<Grid container spacing={1} alignContent='center' alignItems='center' style={{ marginTop: "100px", maxWidth: window.innerWidth, padding: "20px" }}>
        <Grid item xs={12} style={{ marginBottom: "2%", zIndex: 1 }}>
            <Grid container alignItems='center' spacing={2}>
                <Grid item align='left' xs={4}>
                    <Button disabled fullWidth variant='contained' style={{ backgroundColor: '#a00505', color: 'white', height: '150px', borderRadius: '15px' }}>
                        <VisibilityRoundedIcon style={{ fontSize: '90px' }} />
                    </Button>
                </Grid>
                <Grid item xs align='center'>
                    <Typography gutterBottom variant="h6" style={{ fontWeight: 'bold', textAlign: 'justify', color: '#6b6b6b' }}>
                        Vision
                    </Typography>
                    <Typography variant="body1" style={{ fontWeight: styleConstants.fontWeight.thin, textAlign: 'left', color: '#6b6b6b' }}>
                        To make simulation tests and skill analysis easy, accessible, and accurate
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs={12} style={{ marginBottom: "2%", zIndex: 1 }}>
            <Grid container alignItems='center' spacing={2}>
                <Grid item align='left' xs={4}>
                    <Button disabled fullWidth variant='contained' style={{ backgroundColor: '#a00505', color: 'white', height: '150px', borderRadius: '15px' }}>
                        <FiTarget style={{ fontSize: '90px' }} />
                    </Button>
                </Grid>
                <Grid item xs align='center'>
                    <Typography gutterBottom variant="h6" style={{ fontWeight: 'bold', textAlign: 'justify', color: '#6b6b6b' }}>
                        Mission
                    </Typography>
                    <Typography variant="body1" style={{ fontWeight: styleConstants.fontWeight.thin, textAlign: 'left', color: '#6b6b6b' }}>
                        To offer well-designed simulation tests and intelligent skill analysis with personalised tests preparing experience
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    </Grid>)

    const webUI = (
        <Grid container spacing={10} alignContent='center' alignItems='center' style={{ marginTop: "100px", maxWidth: window.innerWidth, paddingLeft: "10%", paddingRight: "10%" }}>
            <VisibilitySensor onChange={(isVisible) => {
                setIsVisible(isVisible);
            }}>
                <Grid item xs={12} style={{ marginBottom: "2%", zIndex: 1 }}>
                    <Grid container alignItems='center' spacing={6}>
                        <Grid item align='left' xs={2}>
                            <Fade left when={isVisible}>
                                <Button disabled fullWidth variant='contained' style={{ backgroundColor: '#a00505', color: 'white', height: '150px', borderRadius: '15px' }}>
                                    <VisibilityRoundedIcon style={{ fontSize: '90px' }} />
                                </Button>
                            </Fade>
                        </Grid>
                        <Grid item xs={6} align='center'>
                            <Fade right when={isVisible}>
                                <Typography gutterBottom variant="h5" style={{ fontWeight: 'bold', textAlign: 'justify', color: '#6b6b6b' }}>
                                    Vision
                                </Typography>
                                <Typography variant="h5" style={{ fontWeight: styleConstants.fontWeight.thin, textAlign: 'left', color: '#6b6b6b' }}>
                                    To make simulation tests and skill analysis easy, accessible, and accurate
                                </Typography>
                            </Fade>
                        </Grid>
                    </Grid>
                </Grid>
            </VisibilitySensor>
            <VisibilitySensor onChange={(isVisible) => {
                setIsVisibleB(isVisible);
            }}>
                <Grid item xs={12} style={{ marginBottom: "2%", zIndex: 1 }}>
                    <Grid container alignItems='center' spacing={6}>
                        <Grid item align='left' xs={2}>
                            <Fade left when={isVisibleB}>
                                <Button disabled fullWidth variant='contained' style={{ backgroundColor: '#a00505', color: 'white', height: '150px', borderRadius: '15px' }}>
                                    <FiTarget style={{ fontSize: '90px' }} />
                                </Button>
                            </Fade>
                        </Grid>
                        <Grid item xs={6} align='center'>
                            <Fade right when={isVisibleB}>
                                <Typography gutterBottom variant="h5" style={{ fontWeight: 'bold', textAlign: 'justify', color: '#6b6b6b' }}>
                                    Mission
                                </Typography>
                                <Typography variant="h5" style={{ fontWeight: styleConstants.fontWeight.thin, textAlign: 'left', color: '#6b6b6b' }}>
                                    To offer well-designed simulation tests and intelligent skill analysis with personalised tests preparing experience
                                </Typography>
                            </Fade>
                        </Grid>
                    </Grid>
                </Grid>
            </VisibilitySensor>
        </Grid>)

    return <ThemeProvider theme={theme}>
        <Hidden mdUp>
            {mobileUI}
        </Hidden>
        <Hidden smDown>
            {webUI}
        </Hidden>
    </ThemeProvider>
};

export default compose(
    withStyles(useStyles)
)(Objectives);
