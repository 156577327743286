import React, { useEffect } from 'react';

//MUI
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CakeRoundedIcon from '@material-ui/icons/CakeRounded';
import EmailRoundedIcon from '@material-ui/icons/EmailRounded';
import LocalPhoneRoundedIcon from '@material-ui/icons/LocalPhoneRounded';
import SchoolRoundedIcon from '@material-ui/icons/SchoolRounded';
import Skeleton from '@material-ui/lab/Skeleton';

import { ThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';

//Icons

//Font
import 'typeface-glacial-indifference';

//CSS
import './css/profilePage.css';

//Services
import { GetAllTestsResult, GetAllTestsTaken } from '../../../services/managementServices';
import { GetAdminToken } from '../../../services/token';
import { GetAllUsers } from '../../../services/userServices';

//Functions

//Others
import { JsonToExcel } from "react-json-to-excel";
import Carousel from "react-multi-carousel";
import { Link } from "react-router-dom";
import Select from 'react-select';
import Switch from "react-switch";
import { Axis, Bar, Chart, Line, Point, Tooltip } from 'viser-react';

import "react-multi-carousel/lib/styles.css";

const axios = require('axios').default;
const FormData = require('form-data');
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

const useStyles = theme => ({
    root: {
        padding: theme.spacing(12, 4),
    }
});

const theme = createMuiTheme({
    typography: {
        fontFamily: [
            'Roboto'
        ].join(','),
    },
});

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1500 },
        items: 5
    },
    tablet: {
        breakpoint: { max: 1500, min: 464 },
        items: 3
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1
    }
};

const AdminReportPage = () => {
    //User
    const [userObj, setUserObj] = React.useState({});
    const [allUserArr, setAllUserArr] = React.useState([]);
    const [allResultArr, setAllResultArr] = React.useState([]);
    const [toShowResultArr, setToShowResultArr] = React.useState([]);
    const [resultArr, setResultArr] = React.useState([]);
    const [testHistoryArr, setTestHistoryArr] = React.useState([]);
    const [testReportArr, setTestReportArr] = React.useState([]);
    const [excelArr, setExcelArr] = React.useState([]);
    const [pageCountArr, setPageCountArr] = React.useState([]);
    const [tempUsersArr, setTempUsersArr] = React.useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);
    const [testCountArr, setTestCountArr] = React.useState([]);
    const [scatterArr, setScatterArr] = React.useState([]);
    const [schoolArr, setSchoolArr] = React.useState([]);
    const [schoolAvgArr, setSchoolAvgArr] = React.useState([]);
    const [initial, setInitial] = React.useState("");
    const [filterSchool, setFilterSchool] = React.useState("");
    const [schoolAvg, setSchoolAvg] = React.useState(null);
    const [isLineView, setIsLineView] = React.useState(false);
    const [isProblemReport, setIsProblemReport] = React.useState(true);

    const [isLoading, setIsLoading] = React.useState(false);


    const barScale = [{
        dataKey: 'count',
        tickInterval: 50,
    }];

    const lineScale = [{
        dataKey: 'count',
        min: 0,
    }, {
        dataKey: 'month',
        min: 0,
        max: 1,
    }];

    const scatterScale = [{
        dataKey: 'score',
        min: 0
    }];

    const scatterAvgScale = [{
        dataKey: 'average',
        min: 0
    }];

    useEffect(async () => {
        document.body.style.overflow = "auto";
        document.body.style.backgroundColor = "#efefef";
        document.querySelector("body").scrollTo(0, 0);

        const user = await JSON.parse(localStorage.getItem('user'));
        setUserObj(user);
        setInitial(`${user.firstname[0]}${user.lastname[0]}`);

        await getAllTests();
        await getAllResult(user.token);
    }, []);

    const getAllTests = async () => {
        setIsLoading(true);
        const res = await GetAllTestsTaken("2022-01-22", "2023-12-22");
        const reportObj = res.res.logs;
        const reportArr = await [
            {
                month: "Jan",
                count: reportObj["2023-01"] ? reportObj["2023-01"].length : null
            }, {
                month: "Feb",
                count: reportObj["2023-02"] ? reportObj["2023-02"].length : null
            }, {
                month: "Mar",
                count: reportObj["2023-03"] ? reportObj["2023-03"].length : null
            }, {
                month: "Apr",
                count: reportObj["2023-04"] ? reportObj["2023-04"].length : null
            }, {
                month: "May",
                count: reportObj["2023-05"] ? reportObj["2023-05"].length : null
            }, {
                month: "Jun",
                count: reportObj["2023-06"] ? reportObj["2023-06"].length : null
            }, {
                month: "Jul",
                count: reportObj["2023-07"] ? reportObj["2023-07"].length : null
            }, {
                month: "Aug",
                count: reportObj["2023-08"] ? reportObj["2023-08"].length : null
            }, {
                month: "Sep",
                count: reportObj["2023-09"] ? reportObj["2023-09"].length : null
            }, {
                month: "Oct",
                count: reportObj["2023-10"] ? reportObj["2023-10"].length : null
            }, {
                month: "Nov",
                count: reportObj["2023-11"] ? reportObj["2023-11"].length : null
            }, {
                month: "Dec",
                count: reportObj["2023-12"] ? reportObj["2023-12"].length : null
            },
        ]

        setTestCountArr(reportArr);
        setIsLoading(false);
    }

    const getAllResult = async (token) => {
        setIsLoading(true);
        const res = await GetAllTestsResult(token);
        const resultObj = res.res.resultsOrderedByMonths;
        const resultArr = await [
            {
                month: "Jan",
                count: resultObj["2023-01"] ? resultObj["2023-01"].length : null
            }, {
                month: "Feb",
                count: resultObj["2023-02"] ? resultObj["2023-02"].length : null
            }, {
                month: "Mar",
                count: resultObj["2023-03"] ? resultObj["2023-03"].length : null
            }, {
                month: "Apr",
                count: resultObj["2023-04"] ? resultObj["2023-04"].length : null
            }, {
                month: "May",
                count: resultObj["2023-05"] ? resultObj["2023-05"].length : null
            }, {
                month: "Jun",
                count: resultObj["2023-06"] ? resultObj["2023-06"].length : null
            }, {
                month: "Jul",
                count: resultObj["2023-07"] ? resultObj["2023-07"].length : null
            }, {
                month: "Aug",
                count: resultObj["2023-08"] ? resultObj["2023-08"].length : null
            }, {
                month: "Sep",
                count: resultObj["2023-09"] ? resultObj["2023-09"].length : null
            }, {
                month: "Oct",
                count: resultObj["2023-10"] ? resultObj["2023-10"].length : null
            }, {
                month: "Nov",
                count: resultObj["2023-11"] ? resultObj["2023-11"].length : null
            }, {
                month: "Dec",
                count: resultObj["2023-12"] ? resultObj["2023-12"].length : null
            },
        ]

        setToShowResultArr(resultArr);
        setResultArr(res.res.results);
        listAllSchool(res.res.results);
        prepareExcelFile(res.res.results);
        setIsLoading(false);
    }

    const prepareExcelFile = (resultArr) => {
        let finalTempArr = [];
        resultArr.map((result) => {
            finalTempArr.push({
                ...result.test_result.test_result,
                ...result.user_obj
            });
        })

        setExcelArr(finalTempArr);
    }

    const calculateBandScore = (score) => {
        if (score === 20) {
            return 9
        } if (score === 19) {
            return 8.5
        } if (score === 18) {
            return 8
        } if (score === 17) {
            return 7.5
        } if (score === 16 || score === 15) {
            return 7
        } if (score === 14) {
            return 6.5
        } if (score === 13 || score === 12) {
            return 6
        } if (score === 11 || score === 10) {
            return 5.5
        } if (score === 9 || score === 8) {
            return 5
        } if (score === 7 || score === 6) {
            return 4.5
        } if (score === 5) {
            return 4
        } if (score === 4) {
            return 3.5
        } if (score === 3) {
            return 3
        } if (score === 2) {
            return 2.5
        } if (score < 2) {
            return 0
        }
    }

    const listAllSchool = async (results) => {
        let schoolArrTemp = [];
        let schoolAvgArrTemp = [];
        let finalArr = [];
        results.map((result, index) => {
            if (result.user_obj.school !== undefined && result.user_obj.school.length !== 0) {
                schoolArrTemp.push(result.user_obj.school);
            }
        })

        const uniqueSchool = [...new Set(schoolArrTemp)];

        uniqueSchool.map(async (school) => {
            const avg = await calculateAverageFromSchoolWithResult(school, results);

            schoolAvgArrTemp.push({
                school: school,
                average: avg
            });

            finalArr.push({
                value: school,
                label: school
            });
        })

        setSchoolArr(finalArr);
        setSchoolAvgArr(schoolAvgArrTemp);
    };

    const handleChangeSchool = async (event) => {
        if (event === null) {
            setFilterSchool("");
            setSchoolAvg(null);
        } if (event !== null) {
            setFilterSchool(event.value);
            calculateAverageFromSchool(event.value);
        }
    }

    const calculateAverageFromSchoolWithResult = async (school, result) => {
        let tempArr = [];
        const filteredArr = await result.filter((result) => result.user_obj.school === school);

        await filteredArr.map((obj) => {
            tempArr.push(calculateBandScore(obj.test_result.test_result.total_score));
        })

        const average = tempArr.reduce((a, b) => a + b, 0) / tempArr.length;
        return average;
    }

    const calculateAverageFromSchool = async (school) => {
        let tempScatterArr = [];
        const filteredArr = await resultArr.filter((result) => result.user_obj.school === school);

        await filteredArr.map((obj) => {
            tempScatterArr.push({
                name: `${obj.user_obj?.firstname} ${obj.user_obj?.lastname}`,
                score: calculateBandScore(obj.test_result.test_result.total_score)
            });
        })

        const average = schoolAvgArr.find(element => element.school === school);
        setSchoolAvg(average.average);
        setScatterArr(tempScatterArr);

        return average;
    }

    const handleSwitchIsLineView = (checked) => {
        setIsLineView(checked);
    };

    const onClickProblemReport = () => {
        setIsProblemReport(true);
    };

    const onClickUserReport = () => {
        setIsProblemReport(false);
    };

    const getAllUsers = async () => {
        const tokenObj = await GetAdminToken();
        const res = await GetAllUsers(tokenObj.res.token.token);

        setAllUserArr(res.res);
        const pageArr = await preparePageCount(Math.floor(res.res.length / 10), res.res);
        setPageCountArr(pageArr);
    }

    const preparePageCount = async (pageCount, userArr) => {
        let tempPageCount = [];
        for (let count = 0; count <= pageCount; count++) {
            let start = (count) * 10;
            let end = (count * 10) + 9

            await tempPageCount.push({
                page: count + 1,
                arr: userArr.slice(start, end)
            });
        }

        return tempPageCount
    }

    const mobileUI = (<Grid container style={{ marginTop: "5%", marginBottom: "20%", maxWidth: window.innerWidth }}>
        <Grid item align="left" xs={12} style={{ padding: "5%" }}>
            <Typography variant="h3" style={{ fontWeight: "bold", color: "#880000" }}>
                Profile
            </Typography>
        </Grid>
        <Grid item align="center" xs={12} style={{ padding: "5%" }}>
            <Paper elevation={0} style={{ backgroundColor: "#a6a6a6", borderRadius: "25px", padding: "10%" }}>
                <Grid container>
                    <Grid item xs={12}>
                        <Avatar style={{
                            width: "200px",
                            height: "200px",
                            fontSize: "80px",
                            fontWeight: "bold",
                            backgroundColor: "#880000"
                        }}
                        >
                            {initial}
                        </Avatar>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item align="left" xs={12} style={{ marginTop: "10px" }}>
                                <Typography variant="h4" style={{ color: "white", fontWeight: "bold" }}>
                                    {`${userObj?.firstname} ${userObj?.lastname}`}
                                </Typography>
                            </Grid>
                            <Grid item align="left" xs={12}>
                                <Typography variant="subtitle1" style={{ color: "white" }}>
                                    {userObj?.role}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
        <Grid item xs={12} style={{ padding: "5%" }}>
            <Paper elevation={0} style={{ backgroundColor: "#a6a6a6", borderRadius: "25px", padding: "10%" }}>
                <Grid container>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item align="left">
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'wrap',
                                }}>
                                    <EmailRoundedIcon style={{ color: "white", marginRight: '5px' }} />
                                    <Typography variant="subtitle1" style={{ color: "white" }}>
                                        {userObj?.email}
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item align="left" xs={12}>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'wrap',
                                }}>
                                    <LocalPhoneRoundedIcon style={{ color: "white", marginRight: '5px' }} />
                                    <Typography variant="subtitle1" style={{ color: "white" }}>
                                        {userObj?.phone?.length === 0 ? "-" : userObj.phone}
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item align="left" xs={12}>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'wrap',
                                }}>
                                    <SchoolRoundedIcon style={{ color: "white", marginRight: '5px' }} />
                                    <Typography variant="subtitle1" style={{ color: "white" }}>
                                        {userObj?.school?.length === 0 ? "-" : userObj.school}
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item align="left" xs={12}>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'wrap',
                                }}>
                                    <CakeRoundedIcon style={{ color: "white", marginRight: '5px' }} />
                                    <Typography variant="subtitle1" style={{ color: "white" }}>
                                        {userObj?.birthdate}
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
        <Grid item align="left" xs={12} style={{ padding: "5%" }}>
            <Typography variant="h4" style={{ fontWeight: "bold", color: "#880000" }}>
                History
            </Typography>
        </Grid>
        {testHistoryArr && testHistoryArr?.length > 0 && <Grid align="center" xs={12}>
            <Carousel
                responsive={responsive}
                swipeable={true}
                showDots={true}
                autoPlay={false}
                autoPlaySpeed={100000}
            >
                {testHistoryArr.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()).map((history, index) =>
                    <Grid item xs={12} style={{ padding: "5%" }}>
                        <Card style={{ backgroundColor: "#a6a6a6", borderRadius: "25px", padding: "5%" }}>
                            <CardContent>
                                <Typography variant="h6" style={{ color: "white" }} gutterBottom>
                                    Test
                                </Typography>
                                <Typography variant="h2" style={{ color: "white", fontWeight: "bold" }}>
                                    {history.test_type}
                                </Typography>
                                <Typography variant="body1" style={{ color: "white" }} gutterBottom>
                                    {history.test_part}
                                </Typography>
                                <Typography variant="subtitle1" style={{ color: "white" }}>
                                    {new Date(history.createdAt).toDateString()}
                                </Typography>
                                <Typography variant="subtitle1" style={{ color: "white" }}>
                                    {new Date(history.createdAt).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Grid item xs={12}>
                                    {history.isDone ? <Button
                                        disabled
                                        variant="contained"
                                        size="large"
                                        style={{ backgroundColor: "#636363", color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1.5rem" }}
                                        disableElevation
                                        fullWidth
                                    >
                                        Done
                                    </Button> : <Button
                                            disabled
                                            variant="contained"
                                            size="large"
                                            style={{ backgroundColor: "#636363", color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1.5rem" }}
                                            disableElevation
                                            fullWidth
                                        >
                                            Unavailable in mobile
                                    </Button>
                                    }
                                </Grid>
                            </CardActions>
                        </Card>
                    </Grid>
                )}
            </Carousel>
        </Grid>
        }

        {isLoading && <Grid align="left" xs={12} style={{ padding: "5%" }}>
            <Grid item align="center" xs={12}>
                <Card style={{ backgroundColor: "#a6a6a6", borderRadius: "25px", padding: "10%" }}>
                    <CardContent>
                        <Typography variant="h6" style={{ color: "white" }} gutterBottom>
                            <Skeleton />
                        </Typography>
                        <Typography variant="h2" style={{ color: "white", fontWeight: "bold" }}>
                            <Skeleton />
                        </Typography>
                        <Typography variant="subtitle1" style={{ color: "white" }} gutterBottom>
                            <Skeleton />
                        </Typography>
                        <Typography variant="subtitle1" style={{ color: "white" }}>
                            <Skeleton />
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Grid item xs={12}>
                            <Button
                                disabled
                                variant="contained"
                                size="large"
                                style={{ backgroundColor: "#636363", color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1rem" }}
                                disableElevation
                                fullWidth
                            >
                                Unavailable in mobile
                            </Button>
                        </Grid>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
        }

        {testHistoryArr?.length === 0 && !isLoading && <Grid align="left" xs={12} style={{ padding: "5%" }}>
            <Grid item align="center" xs={12}>
                <Card style={{ backgroundColor: "#a6a6a6", borderRadius: "25px", padding: "10%" }}>
                    <CardContent>
                        <Typography variant="h6" style={{ color: "white" }} gutterBottom>
                            Test
                        </Typography>
                        <Typography variant="h2" style={{ color: "white", fontWeight: "bold" }}>
                            Empty
                        </Typography>
                        <Typography variant="subtitle1" style={{ color: "white" }} gutterBottom>
                            -
                        </Typography>
                        <Typography variant="subtitle1" style={{ color: "white" }}>
                            -
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Grid item xs={12}>
                            <Button
                                disabled
                                variant="contained"
                                size="large"
                                style={{ backgroundColor: "#636363", color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1rem" }}
                                disableElevation
                                fullWidth
                            >
                                Unavailable in mobile
                            </Button>
                        </Grid>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
        }

        <Grid item align="left" xs={12} style={{ padding: "5%" }}>
            <Typography variant="h4" style={{ fontWeight: "bold", color: "#880000" }}>
                My Report
            </Typography>
        </Grid>
        <Grid align="center" xs={12}>
            <Carousel
                responsive={responsive}
                swipeable={true}
                showDots={true}
                autoPlay={false}
                partialVisible={false}
                autoPlaySpeed={100000}
            >
                {testReportArr.sort((a, b) => new Date(b.test_result.test_started ? b.test_result.test_started : b.date_created).getTime() - new Date(a.test_result.test_started ? a.test_result.test_started : a.date_created).getTime()).map((report, index) =>
                    <Grid item xs={12} style={{ padding: "5%" }}>
                        <Card style={{ backgroundColor: "#a6a6a6", borderRadius: "25px", padding: "5%" }}>
                            <CardContent>
                                <Typography variant="h6" style={{ color: "white" }} gutterBottom>
                                    Mini Report
                                </Typography>
                                <Typography variant="h2" style={{ color: "white", fontWeight: "bold" }}>
                                    IELTS
                                </Typography>
                                <Typography variant="body1" style={{ color: "white" }} gutterBottom>
                                    READING
                                </Typography>
                                <Typography variant="subtitle1" style={{ color: "white" }}>
                                    {report.test_result.test_started ? new Date(report.test_result.test_started).toDateString() : new Date(report.date_created).toDateString()}
                                </Typography>
                                <Typography variant="subtitle1" style={{ color: "white" }}>
                                    {report.test_result.test_started ? new Date(report.test_result.test_started).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : new Date(report.date_created).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Grid item xs={12}>
                                    <Link
                                        to={`/report?result_id=${report.result_id}`}
                                        style={{ textDecoration: 'none', color: '#565656' }}
                                    >
                                        <Button
                                            variant="contained"
                                            size="large"
                                            style={{ backgroundColor: "#880000", color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1.5rem" }}
                                            disableElevation
                                            fullWidth
                                        >
                                            View
                                        </Button>
                                    </Link>
                                </Grid>
                            </CardActions>
                        </Card>
                    </Grid>
                )}
            </Carousel>
        </Grid>

        {isLoading && <Grid align="left" xs={12} style={{ padding: "5%" }}>
            <Grid item align="center" xs={12}>
                <Card style={{ backgroundColor: "#a6a6a6", borderRadius: "25px", padding: "10%" }}>
                    <CardContent>
                        <Typography variant="h6" style={{ color: "white" }} gutterBottom>
                            <Skeleton />
                        </Typography>
                        <Typography variant="h2" style={{ color: "white", fontWeight: "bold" }}>
                            <Skeleton />
                        </Typography>
                        <Typography variant="subtitle1" style={{ color: "white" }} gutterBottom>
                            <Skeleton />
                        </Typography>
                        <Typography variant="subtitle1" style={{ color: "white" }}>
                            <Skeleton />
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Grid item xs={12}>
                            <Button
                                disabled
                                variant="contained"
                                size="large"
                                style={{ backgroundColor: "#636363", color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1rem" }}
                                disableElevation
                                fullWidth
                            >
                                Unavailable in mobile
                            </Button>
                        </Grid>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
        }

        {testReportArr?.length === 0 && !isLoading && <Grid align="left" xs={12} style={{ padding: "5%" }}>
            <Grid item align="center" xs={12}>
                <Card style={{ backgroundColor: "#a6a6a6", borderRadius: "25px", padding: "10%" }}>
                    <CardContent>
                        <Typography variant="h6" style={{ color: "white" }} gutterBottom>
                            Mini Report
                        </Typography>
                        <Typography variant="h2" style={{ color: "white", fontWeight: "bold" }}>
                            Empty
                        </Typography>
                        <Typography variant="subtitle1" style={{ color: "white" }} gutterBottom>
                            -
                        </Typography>
                        <Typography variant="subtitle1" style={{ color: "white" }}>
                            -
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Grid item xs={12}>
                            <Button
                                disabled
                                variant="contained"
                                size="large"
                                style={{ backgroundColor: "#636363", color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1rem" }}
                                disableElevation
                                fullWidth
                            >
                                Unavailable in mobile
                            </Button>
                        </Grid>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
        }
    </Grid>)

    // ============================================================================================================================================= 

    const webUI = (<Grid container spacing={5} style={{ marginTop: "5%", marginBottom: "20%", maxWidth: window.innerWidth }}>
        <Grid item xs={12} style={{ paddingLeft: "10%", paddingRight: '10%' }}>
            <Paper elevation={0} style={{ backgroundColor: "#a6a6a6", borderRadius: "15px", padding: "5px" }}>
                <Grid container>
                    <Grid item xs={6}>
                        <Grid container spacing={2} justify="flex-start" alignItems='center'>
                            <Grid item>
                                <Button variant='text' onClick={onClickProblemReport} fullWidth>
                                    <Typography variant="h6" style={{ color: "white", fontWeight: "bold" }}>
                                        Tests
                                    </Typography>
                                </Button>
                            </Grid>
                            <Divider orientation="vertical" flexItem style={{ backgroundColor: "white", marginTop: "3px" }} />
                            <Grid item>
                                <Button variant='text' onClick={onClickUserReport} fullWidth>
                                    <Typography variant="h6" style={{ color: "white", fontWeight: "bold" }}>
                                        Users
                                    </Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container spacing={2} justify="flex-end" alignItems='center'>
                            <Grid item align="right">
                                <Typography variant="body1" style={{ color: "white", fontWeight: "bold" }}>
                                    {`${userObj?.firstname} ${userObj?.lastname}`}
                                </Typography>
                                <Typography variant="caption" style={{ color: "white" }}>
                                    {userObj?.role}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Avatar style={{
                                    width: "40px",
                                    height: "40px",
                                    fontSize: "15px",
                                    fontWeight: "bold",
                                    backgroundColor: "#880000"
                                }}
                                >
                                    {initial}
                                </Avatar>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
        <Grid item xs={12} align="center" style={{ paddingLeft: "10%", paddingRight: "10%" }}>
            <Paper elevation={0} style={{ backgroundColor: "#f4f3f3", borderRadius: "25px", padding: "5%" }}>
                <Grid container>
                    {!isProblemReport &&
                        <Grid item align='right' xs={12}>
                            <JsonToExcel
                                title="Export as Excel"
                                data={excelArr}
                                fileName={`result-report(${new Date().toDateString()})`}
                            />
                        </Grid>
                    }

                    {!isProblemReport && <Grid item align="right" xs={12} style={{ marginBottom: "30px" }}>
                        <Grid item xs={3}>
                            <Typography align="left" variant="h6" style={{ color: "#595959", fontWeight: "bold" }}>
                                School:
                            </Typography>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                isLoading={isLoading}
                                isClearable={true}
                                isSearchable={true}
                                name="School"
                                options={schoolArr}
                                style={{ maxWidth: "200px" }}
                                onChange={handleChangeSchool}
                            />
                            {schoolAvg !== null &&
                                <Typography align="left" variant="subtitle1" style={{ color: "#595959", fontWeight: "bold" }}>
                                    School Avg: {schoolAvg}
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                    }
                    {!isProblemReport && <Grid item xs={12} align="center">
                        <Grid container>
                            <Grid item xs>
                                <Typography align="left" variant="h6" style={{ color: "#595959", fontWeight: "bold" }}>
                                    School
                                </Typography>
                            </Grid>
                            <Grid item xs>
                                <Typography align="left" variant="h6" style={{ color: "#595959", fontWeight: "bold" }}>
                                    Name
                                </Typography>
                            </Grid>
                            <Grid item xs>
                                <Typography align="left" variant="h6" style={{ color: "#595959", fontWeight: "bold" }}>
                                    Date
                                </Typography>
                            </Grid>
                            <Grid item xs>
                                <Typography align="left" variant="h6" style={{ color: "#595959", fontWeight: "bold" }}>
                                    Subject
                                </Typography>
                            </Grid>
                            <Grid item xs>
                                <Typography variant="h6" style={{ color: "#595959", fontWeight: "bold" }}>
                                    Overall
                                </Typography>
                            </Grid>
                            <Grid item xs>
                                <Typography variant="h6" style={{ color: "#595959", fontWeight: "bold" }}>
                                    Reading
                                </Typography>
                            </Grid>
                            <Grid item xs>
                                <Typography variant="h6" style={{ color: "#595959", fontWeight: "bold" }}>
                                    Listening
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    }

                    {!isProblemReport && <Grid item xs={12}>
                        <Grid container style={{ minHeight: "500px", maxHeight: "500px", overflowY: "auto" }}>
                            {resultArr?.filter((result) => filterSchool.length === 0 ? true : result.user_obj.school === filterSchool).map((obj) =>
                                <Grid item xs={12} align="left" style={{ marginTop: "10px" }}>
                                    <Grid container spacing={1}>
                                        <Grid item xs>
                                            <Typography align="left" variant="subtitle2" style={{ color: "#595959" }}>
                                                {obj.user_obj?.school?.length === 0 ? "-" : obj.user_obj.school}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs>
                                            <Typography align="left" variant="subtitle2" style={{ color: "#595959" }}>
                                                {`${obj.user_obj?.firstname} ${obj.user_obj?.lastname}`}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs>
                                            <Typography align="left" variant="subtitle2" style={{ color: "#595959" }}>
                                                {obj.test_result.test_started ? new Date(obj.test_result.test_started).toDateString() : new Date(obj.test_result.date_created).toDateString()}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs>
                                            <Typography align="left" variant="subtitle2" style={{ color: "#595959" }}>
                                                {obj.test_result.test_type}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs>
                                            <Typography align="center" variant="subtitle2" style={{ color: "#595959" }}>
                                                {calculateBandScore(obj.test_result.test_result.total_score)}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs>
                                            <Typography align="center" variant="subtitle2" style={{ color: "#595959" }}>
                                                {obj.test_result.test_type === "IELTS READING FREE" ? calculateBandScore(obj.test_result.test_result.total_score) : "-"}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs>
                                            <Typography align="center" variant="subtitle2" style={{ color: "#595959" }}>
                                                {obj.test_result.test_type === "IELTS READING FREE" ? "-" : calculateBandScore(obj.test_result.test_result.total_score)}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    }

                    {isProblemReport &&
                        <Grid item align="right" xs={12} style={{ marginTop: isProblemReport ? "" : "50px" }}>
                            <Grid container justify="flex-end" spacing={2}>
                                <Grid item>
                                    <Switch
                                        onChange={handleSwitchIsLineView}
                                        checked={isLineView}
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography variant="h6" style={{ fontWeight: "bold" }}>
                                        Line View
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    }

                    {isLoading && <Skeleton variant="rect" width="100%" height={400} style={{ marginTop: "20px", borderRadius: "15px" }} />}

                    {(!isLoading && isProblemReport) && (isLineView ? <Grid item xs={12}>
                        <Chart forceFit height={400} data={testCountArr} scale={lineScale}>
                            <Tooltip />
                            <Axis />
                            <Line position="month*count" color="#880000" />
                            <Point position="month*count" shape="circle" color="#880000" />
                        </Chart>
                    </Grid> : <Grid item xs={12}>
                            <Chart forceFit height={400} data={testCountArr} scale={barScale}>
                                <Tooltip />
                                <Axis />
                                <Bar position="month*count" color="#880000" />
                            </Chart>
                        </Grid>
                    )}

                    {(!isLoading && !isProblemReport) &&
                        <Grid item xs={12} style={{ marginTop: "50px" }}>
                            <Chart forceFit height={400} data={schoolAvgArr} scale={scatterAvgScale}>
                                <Tooltip
                                    showTitle={false}
                                    crosshairs={{ type: 'cross' }}
                                    itemTpl={
                                        `<li data-index={index} style="margin-bottom:4px;">
                                <span style="background-color:{color};" class="g2-tooltip-marker"></span>
                                {name}<br />{value}
                            </li>`
                                    }
                                />
                                <Axis dataKey="average" label={{
                                    textStyle: {
                                        fill: '#aaaaaa'
                                    }
                                }} />
                                <Axis dataKey="school" label={null} />
                                <Point
                                    color="#880000"
                                    position="average*school"
                                    size={4}
                                    opacity={0.65}
                                    tooltip={['average*school', (school, average) => {
                                        return {
                                            name: average,
                                            value: school
                                        };
                                    }]}
                                    label={[
                                        'school',
                                        {
                                            type: 'scatter',
                                            offset: 0,
                                            textStyle: {
                                                fill: 'rgba(0, 0, 0, 0.65)',
                                                stroke: '#fff',
                                                lineWidth: 2
                                            }
                                        }
                                    ]}
                                    shape="circle"
                                />
                            </Chart>
                        </Grid>
                    }
                </Grid>

                {(schoolAvg !== null && !isProblemReport) && <Grid item xs={12} style={{ marginTop: "50px" }}>
                    <Chart forceFit height={400} data={scatterArr} scale={scatterScale}>
                        <Tooltip
                            showTitle={false}
                            crosshairs={{ type: 'cross' }}
                            itemTpl={
                                `<li data-index={index} style="margin-bottom:4px;">
                                <span style="background-color:{color};" class="g2-tooltip-marker"></span>
                                {name}<br />{value}
                            </li>`
                            }
                        />
                        <Axis />
                        <Point
                            color="#880000"
                            position="name*score"
                            size={4}
                            opacity={0.65}
                            tooltip={['name*score', (name, score) => {
                                return {
                                    name: name,
                                    value: `Band ${score}`
                                };
                            }]}
                            shape="circle"
                        />
                    </Chart>
                </Grid>
                }
            </Paper>
        </Grid>
    </Grid>)

    return <ThemeProvider theme={theme}>
        <Hidden mdUp>
            {mobileUI}
        </Hidden>
        <Hidden smDown>
            {webUI}
        </Hidden>
    </ThemeProvider>
};

export default compose(
    withStyles(useStyles)
)(AdminReportPage);
