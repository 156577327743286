//React
import React, { Component } from "react";
import { compose } from "recompose";

//Material UI
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Checkbox from "@material-ui/core/Checkbox";
import Container from "@material-ui/core/Container";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import {
  ThemeProvider,
  createMuiTheme,
  withStyles,
} from "@material-ui/core/styles";
import AccessAlarmRoundedIcon from "@material-ui/icons/AccessAlarmRounded";
import AccountBoxRoundedIcon from "@material-ui/icons/AccountBoxRounded";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded";
import ArrowForwardRoundedIcon from "@material-ui/icons/ArrowForwardRounded";
import RemoveRoundedIcon from "@material-ui/icons/RemoveRounded";

//import components
import { DropTarget } from "react-drag-drop-container";
import PageBackdrop from "../../../../main/PageBackdrop";
import UserInfoPage from "../../reading/UserInfoPage";
import DisplayParagraph from "../../reading/paragraph/DisplayParagraph";
import InstructionsPage from "../instructionPage/reading/InstructionsPage";

//import question type
import ListOfHeadings from "../../reading/questions/ListOfHeadings";
import MatchingSentenceEndings from "../../reading/questions/MatchingSentenceEndings";
import MultipleChoice from "../../reading/questions/MultipleChoice";
import NoteCompletion from "../../reading/questions/NoteCompletion";
import SentenceCompletion from "../../reading/questions/SentenceCompletion";
import TrueFalseNG from "../../reading/questions/TrueFalseNG";
import YesNoNG from "../../reading/questions/YesNoNG";

//models
import {
  PrepareAnsObj,
  PrepareAnsObjBeforeSubmit,
} from "../../../../../models/testSubmittion";

//other libraries
import { Link } from "react-router-dom";
import MediaQuery from "react-responsive";
import { Avatar } from "@material-ui/core";
import { isSafari } from "react-device-detect";

const axios = require("axios").default;
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  paperRoot: {
    padding: theme.spacing(2),
    textAlign: "left",
  },
  paperItem: {
    padding: theme.spacing(2),
    textAlign: "left",
    height: "810px",
    maxHeight: "810px",
    overflow: "auto",
  },
  testAlignLeft: {
    padding: theme.spacing(2),
  },
  gridContainerRoot: {
    marginTop: 80,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  marginLeft: {
    marginLeft: 20,
  },
  marginTop: {
    marginTop: 20,
  },
  margin: {
    margin: "0",
  },
  questionNumber: {
    maxWidth: "20px",
    maxHeight: "20px",
    minWidth: "20px",
    minHeight: "20px",
    margin: "0.5%",
  },
});

//FONT RELATED
const theme = createMuiTheme({
  typography: {
    fontFamily: ["Noto Sans"].join(","),
  },
});

class TestReading extends Component {
  constructor() {
    super();
    this.myRef = React.createRef();
    this.myRef2 = React.createRef();
    this.myRef3 = React.createRef();
    this.myRef4 = React.createRef();
    this.state = {
      questionNo: "",
      answers: [],
      answeredQuestion: [],
      highlightArr: [],
      highlightDisplayTextArr: [],
      reviewedQuestion: [],
      questionNumArr: [],
      reviewed: false,
      isLoading: false,
      currentTestObject: null,
      currentTotalQuestion: null,
      totalScore: {},
      isDone: false,
      isConfirmDone: false,
      currentQuestion: 1,
      currentPart: 1,
      questionCount: 40,
      freeQuestionCount: 20,
      timer: 3600,
      timerSeconds: 0,
      window: {
        height: window.innerHeight,
        width: window.innerWidth,
      },
      isStartTest: false,
      isCheckUserInfo: false,
      isReadInstruction: true,
      dropItems: {},
      testText: "Read the text below and answer question 1 - 13.",
      fontSize: 1,
      fontSizeMap: "lg",
      //New
      questionSeparation: [],
      isNotAllQuestion: false,
    };
    this.handleChangeReviewed = this.handleChangeReviewed.bind(this);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = (event) => {
    this.setState({
      window: {
        height: window.innerHeight,
        width: window.innerWidth,
      },
    });
  };

  componentDidMount = async () => {
    window.addEventListener("resize", this.handleResize);
    this.checkSession();
  };

  checkSession = async () => {
    if (this.props.isFreeTest) {
      // await this.createQuestionArr();
      // this.props.keepCurrentSession(this.state);
      this.setState({
        timer: 1800,
      });
      // this.props.handleCloseLoading();

      if (this.props.session !== null) {
        // this.props.DoFullScreen();
        await this.setState(this.props.session);
        await this.props.keepCurrentSession(this.state);
        await this.createQuestionArr();
        clearInterval(this.myInterval);
        this.myInterval = setInterval(() => {
          if (this.state.timer > 0) {
            if (this.state.timerSeconds === 0) {
              this.setState({
                timerSeconds: 60,
              });
            }

            this.setState((prevState) => ({
              timer: prevState.timer - 1,
              timerSeconds: this.state.timerSeconds - 1,
            }));
          }
        }, 1000);

        this.props.handleCloseLoading();
        this.setState({
          isLoading: false,
        });
      } else {
        await this.createQuestionArr();
        this.props.keepCurrentSession(this.state);
        //this.props.handleStartTest();
        this.props.handleCloseLoading();
        this.setState({
          isLoading: false,
        });
      }
    }
    if (this.props.isDummyTest) {
      await this.createQuestionArr();
      this.props.keepCurrentSession(this.state);
      this.props.handleCloseLoading();
    } else {
      if (this.props.session !== null) {
        await this.setState(this.props.session);
        await this.props.keepCurrentSession(this.state);
        await this.createQuestionArr();
        clearInterval(this.myInterval);
        this.myInterval = setInterval(() => {
          if (this.state.timer > 0) {
            if (this.state.timerSeconds === 0) {
              this.setState({
                timerSeconds: 60,
              });
            }

            this.setState((prevState) => ({
              timer: prevState.timer - 1,
              timerSeconds: this.state.timerSeconds - 1,
            }));
          }
        }, 1000);

        this.props.handleCloseLoading();
      } else {
        await this.createQuestionArr();
        this.props.keepCurrentSession(this.state);
        this.props.handleCloseLoading();
      }
    }
  };

  onClickFontSize = (size) => {
    if (size === "sm") {
      this.setState({
        fontSizeMap: "md",
      });
    }
    if (size === "md") {
      this.setState({
        fontSizeMap: "lg",
      });
    }
    if (size === "lg") {
      this.setState({
        fontSizeMap: "xl",
      });
    }

    return;
  };

  handleAnswerObj = (answerObj) => {
    const selectedPart = this.state.answerObj.parts.find(
      (part) => part.part === this.state.currentPart
    );
    const selectedSection = selectedPart.sections.find(
      (section) => section.section_id === answerObj.section_id
    );

    this.handleCheckQuestion(answerObj);

    if (selectedSection) {
      selectedSection.answers = answerObj.answers;
      this.props.keepCurrentSession(this.state);
      return;
    }

    selectedPart.sections.push(answerObj);
    this.props.keepCurrentSession(this.state);

    /*const selectedPart = this.state.answerObj.parts.find(part => part.part === this.state.currentPart)
        const selectedSection = selectedPart.sections.find(section => section.section_id === answerObj.section_id)
        if (selectedSection) {
            return;
        }
        selectedPart.sections.push(answerObj);*/
  };

  handleCheckQuestion = (answerObj) => {
    if (["matchingSentenceEndings"].includes(answerObj.question_type)) {
      const questionCount = Object.keys(answerObj.answers)[0];
      const firstQuestion = questionCount.split("-")[0];
      answerObj.answers[questionCount].map((question, index) => {
        this.state.answeredQuestion[Number(firstQuestion) + index] = true;
      });
    } else {
      const qNumArr = Object.keys(answerObj.answers);

      qNumArr.map((num, index) => {
        this.state.answeredQuestion[num] = true;
      });
    }
  };

  handleEndTest = async () => {
    try {
      const res = await axios({
        method: "put",
        url:
          api_base_url +
          "/api/test/test-purchase-status?test_id=" +
          this.state.purchasedTestId +
          "&test_status=true",
        headers: {
          Authorization:
            "bearer " + JSON.parse(localStorage.getItem("user")).token,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  getTest = async () => {
    let getTest_url;
    if (this.props.isFreeTest) {
      getTest_url = `${api_base_url}/api/test/random?test_type=IELTS&is_free=true`;
    } else if (this.props.isDummyTest) {
      getTest_url = `https://steep-legend-midnight.glitch.me/test/dummy`;
    } else {
      getTest_url = `${api_base_url}/api/test?test_id=${this.props.centralTestId}`;
    }

    try {
      this.handleLoading();

      const testObj = await axios.get(getTest_url, {
        headers: {
          Authorization:
            "bearer " + JSON.parse(localStorage.getItem("user")).token,
        },
      });

      console.log(testObj);

      if (!this.props.isFreeTest && !this.props.isDummyTest) {
        testObj.data["test"] = testObj.data[0];
      }
      if (this.props.isDummyTest) {
        testObj.data["test"] = testObj.data;
      }

      let filteredTest = testObj.data.test.parts.filter(
        (part) => part.part_type === "READING"
      );
      let listOfHeadingObj = testObj.data.test.parts.filter((part) =>
        part.sections.some((e) => e.question_type === "listOfHeading")
      );

      const answerObj = await PrepareAnsObj(
        filteredTest,
        testObj.data.test.test_id
      );
      this.prepareTestQuestion(filteredTest);

      await this.setState({
        answerObj: answerObj,
        test_id: testObj.data.test.test_id,
        log_id: testObj.data.log_id,
        currentTestObject: filteredTest.filter((part) => part.part === 1)[0],
        currentTotalQuestion: filteredTest.filter((part) => part.part === 1)[0]
          .total_question,
        testObject: filteredTest,
        listOfHeadingObj: listOfHeadingObj,
      });

      this.handleCloseLoading();
    } catch (error) {
      this.handleCloseLoading();
      console.error(error);
    }
  };

  createQuestionArr = async () => {
    for (
      let i = 1;
      i <
      (this.props.isFreeTest
        ? this.state.freeQuestionCount
        : this.state.questionCount) +
        1;
      i++
    ) {
      this.state.questionNumArr[i] = i;
    }

    this.setState({
      questionNumArr: this.state.questionNumArr,
    });
  };

  prepareTestQuestion = (testObj) => {
    let tempArr = [];
    let count = 0;
    testObj.map((part) => {
      tempArr.push({
        part: part.part,
        total_question: part.total_question + count,
      });

      count += part.total_question;

      return tempArr;
    });

    this.setState({
      questionSeparation: tempArr,
    });
  };

  onClickQuestionBox = (num) => {
    if (!isSafari) {
      this.handleLoading();
    }

    const filteredParts = this.state.questionSeparation.filter((part) => {
      return part.total_question >= num;
    });

    this.setState({
      focusedQuestion: num,
      currentPart: filteredParts[0].part,
      currentTestObject: this.state.testObject[filteredParts[0].part - 1],
    });

    this.myRef.current.scrollTo(0, 0);
    this.myRef2.current.scrollTo(0, 0);
    this.myRef3.current.scrollTo(0, 0);
    this.myRef4.current.scrollTo(0, 0);
    this.props.keepCurrentSession(this.state);

    if (!isSafari) {
      this.handleCloseLoading();
    }
  };

  handleGetResult = async () => {
    try {
      this.handleLoading();

      const finalAnsObj = await PrepareAnsObjBeforeSubmit(this.state.answerObj);
      this.props.handleUpdateAnswerObj(finalAnsObj);

      if (this.props.isFreeTest) {
        await this.props.handleCreateStudentReport(finalAnsObj);
      } else {
        this.props.handleStartTestType("WRITING");
      }
      this.handleCloseLoading();
    } catch (error) {
      this.handleCloseLoading();
      console.error(error);
    }
  };

  handleCheckScore = async () => {
    let ansArr = this.state.answers;
    let partIndex = this.state.testObject.parts.findIndex(
      (obj) => obj.part === "READING"
    );
    let index;
    let scoreSection1 = 0;
    let scoreSection2 = 0;

    for (index = 0; index < ansArr.length; index++) {
      let answerObj = ansArr[index];

      let sectionIndex = await this.state.testObject.parts[
        partIndex
      ].sections.findIndex((obj) => obj.section_id === answerObj.section);
      let questionIndex = await this.state.testObject.parts[partIndex].sections[
        sectionIndex
      ].questions.findIndex((obj) => obj.no === answerObj.question);
      let key = await this.state.testObject.parts[partIndex].sections[
        sectionIndex
      ].questions[questionIndex].answer;

      if (key === answerObj.answer && answerObj.section === "1") {
        scoreSection1 = scoreSection1 + 1;
      } else if (key === answerObj.answer && answerObj.section === "2") {
        scoreSection2 = scoreSection2 + 1;
      }
    }

    this.setState({
      totalScore: {
        section1: scoreSection1,
        section2: scoreSection2,
        duration: 60 - this.state.timer,
      },
    });
  };

  handleSelectQuestionType = (section) => {
    if (section.question_type === "trueFalseNG") {
      return (
        <TrueFalseNG
          sectionInfo={section}
          answer={this.state.answer}
          fontSizeMap={this.state.fontSizeMap}
          answerObj={this.state.answerObj}
          handleChange={this.handleChange}
          currentPart={this.state.currentPart}
          handleAnswerObj={this.handleAnswerObj}
          onFocusQuestion={this.onFocusQuestion}
          handleAnsweredQuestionNumber={this.handleAnsweredQuestionNumber}
        />
      );
    } else if (section.question_type === "yesNoNG") {
      return (
        <YesNoNG
          sectionInfo={section}
          answer={this.state.answer}
          fontSizeMap={this.state.fontSizeMap}
          answerObj={this.state.answerObj}
          handleChange={this.handleChange}
          currentPart={this.state.currentPart}
          handleAnswerObj={this.handleAnswerObj}
          onFocusQuestion={this.onFocusQuestion}
          handleAnsweredQuestionNumber={this.handleAnsweredQuestionNumber}
        />
      );
    } else if (section.question_type === "noteCompletion") {
      return (
        <NoteCompletion
          sectionInfo={section}
          answer={this.state.answer}
          fontSizeMap={this.state.fontSizeMap}
          answerObj={this.state.answerObj}
          currentPart={this.state.currentPart}
          handleChange={this.handleChange}
          handleAnswerObj={this.handleAnswerObj}
          onFocusQuestion={this.onFocusQuestion}
          handleCancelQuestionNumber={this.handleCancelQuestionNumber}
          handleAnsweredQuestionNumber={this.handleAnsweredQuestionNumber}
        />
      );
    } else if (section.question_type === "listOfHeading") {
      return (
        <ListOfHeadings
          sectionInfo={section}
          answer={this.state.answer}
          fontSizeMap={this.state.fontSizeMap}
          answerObj={this.state.answerObj}
          handleChange={this.handleChange}
          currentQuestion={this.state.currentQuestion}
          currentPart={this.state.currentPart}
          dropItems={this.state.dropItems}
          onDragStart={this.onDragStart}
          handleAnswerObj={this.handleAnswerObj}
          onFocusQuestion={this.onFocusQuestion}
          handleAnsweredQuestionNumber={this.handleAnsweredQuestionNumber}
        />
      );
    } else if (
      section.question_type === "matchingSentenceEndings" ||
      section.question_type === "matchingFeatures"
    ) {
      return (
        <MatchingSentenceEndings
          sectionInfo={section}
          answer={this.state.answer}
          fontSizeMap={this.state.fontSizeMap}
          answerObj={this.state.answerObj}
          handleChange={this.handleChange}
          currentQuestion={this.state.currentQuestion}
          currentPart={this.state.currentPart}
          onDragStart={this.onDragStart}
          handleAnswer={this.handleAnswer}
          handleAnswerObj={this.handleAnswerObj}
          onFocusQuestion={this.onFocusQuestion}
          handleCancelQuestionNumber={this.handleCancelQuestionNumber}
          handleAnsweredQuestionNumber={this.handleAnsweredQuestionNumber}
        />
      );
    } else if (section.question_type === "sentenceCompletion") {
      return (
        <SentenceCompletion
          sectionInfo={section}
          answer={this.state.answer}
          fontSizeMap={this.state.fontSizeMap}
          answerObj={this.state.answerObj}
          handleChange={this.handleChange}
          currentQuestion={this.state.currentQuestion}
          currentPart={this.state.currentPart}
          handleAnswerObj={this.handleAnswerObj}
          onFocusQuestion={this.onFocusQuestion}
          handleAnsweredQuestionNumber={this.handleAnsweredQuestionNumber}
        />
      );
    } else if (section.question_type === "multipleChoice") {
      return (
        <MultipleChoice
          sectionInfo={section}
          answer={this.state.answer}
          fontSizeMap={this.state.fontSizeMap}
          answerObj={this.state.answerObj}
          handleChange={this.handleChange}
          currentQuestion={this.state.currentQuestion}
          currentPart={this.state.currentPart}
          handleAnswerObj={this.handleAnswerObj}
          onFocusQuestion={this.onFocusQuestion}
          handleAnsweredQuestionNumber={this.handleAnsweredQuestionNumber}
        />
      );
    }
  };

  handleChangePart = async (type) => {
    if (!isSafari) {
      this.handleLoading();
    }

    if (type === "next") {
      if (this.state.currentPart === 3) {
        await this.setState({
          currentPart: this.state.currentPart,
        });
      } else {
        await this.setState({
          currentPart: this.state.currentPart + 1,
        });
      }
    } else if (type === "back") {
      if (this.state.currentPart === 1) {
        return;
      }

      await this.setState({
        currentPart: this.state.currentPart - 1,
      });
    }

    this.setState({
      currentTestObject: this.state.testObject[this.state.currentPart - 1],
    });

    this.myRef.current.scrollTo(0, 0);
    this.myRef2.current.scrollTo(0, 0);
    this.myRef3.current.scrollTo(0, 0);
    this.myRef4.current.scrollTo(0, 0);
    this.props.keepCurrentSession(this.state);

    if (!isSafari) {
      this.handleCloseLoading();
    }
  };

  handleReviewQuestion = (event) => {
    this.state.reviewedQuestion[Number(this.state.focusedQuestion)] =
      event.target.checked;
    this.setState({
      reviewedQuestion: this.state.reviewedQuestion,
    });
  };

  //======================== BEGIN BACKDROP CONTROL ==============================
  handleCloseLoading = () => {
    this.setState({
      isLoading: false,
    });
  };

  handleLoading = () => {
    this.setState({
      isLoading: true,
    });
  };
  //======================== END BACKDROP CONTROL ===============================

  handleAnsweredQuestionNumber = (questionNo) => {
    this.state.answeredQuestion[questionNo] = true;
    this.setState({
      answeredQuestion: this.state.answeredQuestion,
    });
  };

  handleCancelQuestionNumber = (questionNo) => {
    this.state.answeredQuestion[questionNo] = null;
    this.setState({
      answeredQuestion: this.state.answeredQuestion,
    });
  };

  handleAnswer = (question) => {
    const isAnswered = "q" + question.split("_")[1];
    this.setState({
      ...this.state,
      [isAnswered]: true,
    });
  };

  onDropped = async (questionNo, sectionId) => {
    if (this.state.draggedItem !== undefined) {
      this.state.dropItems["" + questionNo] = this.state.draggedItem;

      this.setState({
        draggedItem: undefined,
      });
    }

    this.handleAnsweredQuestionNumber(questionNo);
  };
  onDragStart = (item) => {
    this.setState({
      draggedItem: item,
    });
  };

  handleChangeReviewed = () => {
    this.setState({
      reviewed: !this.state.reviewed,
    });
  };

  handleConfirmDoneTestDialog = async () => {
    if (this.props.isFreeTest) {
      // this.props.ExitFullScreen();
    }

    const answerCountArr = await this.state.answeredQuestion.filter(
      (answer) => answer
    );

    if (answerCountArr.length != this.state.questionCount) {
      this.setState({
        isNotAllQuestion: true,
      });
    } else if (answerCountArr.length == this.state.questionCount) {
      this.setState({
        isNotAllQuestion: false,
      });
    }

    this.setState({
      isConfirmDone: true,
    });
  };

  handleCloseConfirmDoneTestDialog = () => {
    if (this.props.isFreeTest) {
      // this.props.DoFullScreen();
    }

    this.setState({
      isConfirmDone: false,
      isErrorConfirmDone: false,
    });
  };

  handleDoneTestDialog = async () => {
    this.setState({
      isErrorConfirmDone: false,
      isConfirmDone: false,
    });

    await this.handleGetResult();

    this.setState({
      isDone: true,
      isStartTest: false,
    });
  };

  //================================= BEGIN PRE-TEST ================================
  handleUserInfoPage = () => {
    this.setState({
      isStartTest: false,
      isCheckUserInfo: true,
      isReadInstruction: false,
    });
  };

  handleUserInfoChecked = () => {
    this.setState({
      isStartTest: false,
      isCheckUserInfo: false,
      isReadInstruction: true,
    });
  };

  handleDoneReadInstruction = async () => {
    this.setState({
      isStartTest: true,
      isCheckUserInfo: false,
      isReadInstruction: false,
    });

    await this.getTest();

    if (this.props.isFreeTest) {
      // this.props.DoFullScreen();
    }

    clearInterval(this.myInterval);
    this.myInterval = setInterval(() => {
      if (this.state.timer > 0) {
        if (this.state.timerSeconds === 0) {
          this.setState({
            timerSeconds: 60,
          });
        }

        this.setState((prevState) => ({
          timer: prevState.timer - 1,
          timerSeconds: this.state.timerSeconds - 1,
        }));
      }
    }, 1000);
  };

  handleAddLeadingZero = (num) => {
    if (num < 10) {
      return "0" + num;
    } else if (num >= 10) {
      return "" + num;
    }
  };

  //================================= END PRE-TEST =================================

  handleFullScreen = () => {
    this.setState({
      fullScreenMode: !this.state.fullScreenMode,
    });
  };

  handleFontSize = (type) => {
    if (type === "plus") {
      this.setState({
        fontSize: this.state.fontSize + 0.1,
      });
    } else if (type === "minus") {
      this.setState({
        fontSize: this.state.fontSize - 0.1,
      });
    }
  };

  //========================= HIGHLIGHT TEXT ====================================
  handleGetSelectedText = async () => {
    try {
      const text = window.getSelection().toString();

      if (this.state.highlightArr.includes(text)) {
        const foundIndex = this.state.highlightArr.indexOf(text);
        this.state.highlightArr.splice(foundIndex, 1);
        return;
      }

      this.state.highlightArr.push(text);
      this.setState({
        highlightArr: this.state.highlightArr,
      });
    } catch (err) {
      console.log(err);
    }
  };

  handleGetSelectedDisplayText = async () => {
    try {
      const text = window.getSelection().toString();

      if (this.state.highlightDisplayTextArr.includes(text)) {
        const foundIndex = this.state.highlightDisplayTextArr.indexOf(text);
        this.state.highlightDisplayTextArr.splice(foundIndex, 1);
        return;
      }

      this.state.highlightDisplayTextArr.push(text);
      this.setState({
        highlightDisplayTextArr: this.state.highlightDisplayTextArr,
      });
    } catch (err) {
      console.log(err);
    }
  };
  //========================= HIGHLIGHT TEXT ====================================

  onFocusQuestion = (questionNumber) => {
    this.setState({
      focusedQuestion: Number(questionNumber),
    });
  };

  onClickAnswer = async (question) => {
    this.state.dropItems["" + question] = undefined;
    this.setState({
      dropItems: this.state.dropItems,
    });

    this.onFocusQuestion(question);
  };

  handleAlarm = () => {
    console.log("ALERT!!!");
  };

  handleRestTextSize = () => {
    this.setState({
      fontSize: 1,
    });
  };

  render() {
    const { classes } = this.props;

    const timesUp = (
      <Dialog
        open={true}
        onClose={this.handleCloseConfirmDoneTestDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Time's up!"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please click on{" "}
            <Box fontWeight="fontWeightBold" display="inline">
              Confirm
            </Box>
            , to continue on writing test.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleDoneTestDialog} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    );

    return (
      <ThemeProvider theme={theme}>
        <Container
          maxWidth={false}
          style={{ flex: 1, width: "100%", zoom: "100%" }}
          onPaste={(e) => {
            e.preventDefault();
            return false;
          }}
          onCopy={(e) => {
            e.preventDefault();
            return false;
          }}
        >
          <PageBackdrop isLoading={this.state.isLoading} />

          <Dialog
            open={this.state.isConfirmDone}
            onClose={this.handleCloseConfirmDoneTestDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {this.state.isNotAllQuestion
                ? "There's still some time remaining."
                : "There's still some time remaining."}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Please take a moment to ensure you've answered all the questions
                before submitting. Are you sure you want to submit the test? If
                so, please click{" "}
                <Box fontWeight="fontWeightBold" display="inline">
                  Confirm
                </Box>
                .
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={this.handleCloseConfirmDoneTestDialog}
                variant="contained"
                style={{
                  backgroundColor: "#a6a6a6",
                  color: "white",
                  textTransform: "none",
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={this.handleDoneTestDialog}
                variant="contained"
                style={{
                  backgroundColor: "#25333e",
                  color: "white",
                  textTransform: "none",
                }}
                autoFocus
              >
                Confirm
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={this.state.isErrorConfirmDone}
            onClose={this.handleCloseConfirmDoneTestDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"There's still some time remaining."}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Please finished all questions before submit the test.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={this.handleCloseConfirmDoneTestDialog}
                variant="contained"
                style={{
                  backgroundColor: "#a6a6a6",
                  color: "white",
                  textTransform: "none",
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={this.state.isExpiredTest}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Please confirm that There's still some time remaining."}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                This test session is already{" "}
                <Box fontWeight="fontWeightBold" display="inline">
                  expired
                </Box>{" "}
                please start a new one.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Link
                to="/myprofile"
                style={{ textDecoration: "none", color: "#0f0f0f" }}
              >
                <Button onClick={this.handleExpiredTest} color="primary">
                  back
                </Button>
              </Link>
            </DialogActions>
          </Dialog>

          <MediaQuery orientation="portrait">
            <Dialog
              open={true}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"You are in a portrait mode"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  This test is not available in portrait mode. Please use
                  landscape mode to continue the test.
                </DialogContentText>
              </DialogContent>
            </Dialog>
          </MediaQuery>

          <Grid
            container
            justify="center"
            style={{ maxHeight: this.state.window.height, padding: "20px" }}
          >
            {this.state.isCheckUserInfo && (
              <Grid item align="center" xs={12}>
                <UserInfoPage
                  user={this.props.user}
                  isFreeTest={this.props.isFreeTest}
                  handleUserInfoChecked={this.handleUserInfoChecked}
                  handleEndTest={this.props.handleEndTest}
                />
              </Grid>
            )}
            {this.state.isReadInstruction && (
              <Grid item align="center" xs={12}>
                <InstructionsPage
                  user={this.props.user}
                  isFreeTest={this.props.isFreeTest}
                  handleDoneReadInstruction={this.handleDoneReadInstruction}
                  handleEndTest={this.props.handleEndTest}
                  handleUserInfoPage={this.handleUserInfoPage}
                />
              </Grid>
            )}
            {!this.state.isDone &&
              this.state.isStartTest &&
              this.state.currentTestObject !== null && (
                <Grid
                  item
                  xs={12}
                  style={{ maxHeight: this.state.window.height }}
                >
                  <Grid container justify="center">
                    <Grid item xs={12} style={{ padding: "10px" }}>
                      <Grid container>
                        <Grid item xs={4}>
                          <Grid container spacing={4}>
                            <Grid item>
                              <Avatar
                                variant="square"
                                src="/U Test Logo (new).png"
                                style={{ width: "30px", height: "50px" }}
                              />
                            </Grid>
                            <Grid item>
                              <Button
                                disableElevation
                                disabled
                                variant="contained"
                                style={{
                                  backgroundColor: "#25333e",
                                  color: "white",
                                  fontWeight: "bold",
                                  textTransform: "none",
                                }}
                              >
                                {"IELTS Reading"}
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={4}>
                          <Grid
                            container
                            justify="center"
                            alignItems="center"
                            spacing={1}
                          >
                            <Grid item>
                              <AccessAlarmRoundedIcon
                                style={{
                                  color:
                                    (this.state.timer >= 590 &&
                                      this.state.timer <= 600) ||
                                    (this.state.timer >= 290 &&
                                      this.state.timer <= 300)
                                      ? "#c91e24"
                                      : "",
                                }}
                              />
                            </Grid>
                            <Grid item>
                              {this.handleAddLeadingZero(
                                Math.floor(this.state.timer / 3600)
                              )}{" "}
                              :{" "}
                              {this.state.timer === 3600
                                ? "00"
                                : this.handleAddLeadingZero(
                                    Math.floor(this.state.timer / 60)
                                  )}{" "}
                              :{" "}
                              {this.handleAddLeadingZero(this.state.timer % 60)}{" "}
                              minutes left
                            </Grid>
                          </Grid>
                          {this.state.timer === 0 && timesUp}
                        </Grid>
                        <Grid item xs={4}>
                          <Grid
                            container
                            justify="center"
                            alignItems="center"
                            spacing={1}
                          >
                            <Grid item>
                              <AccountBoxRoundedIcon />
                            </Grid>
                            <Grid item>
                              <Typography variant="body2">
                                {this.props.user.name}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Paper
                        variant="outlined"
                        style={{
                          padding: "10px",
                          backgroundColor: "#25333e",
                          color: "white",
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          gutterBottom
                          align="left"
                        >
                          <Box fontWeight="fontWeightBold" display="inline">
                            Section {this.state.currentPart}
                          </Box>
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          gutterBottom
                          align="left"
                        >
                          {this.state.currentTestObject !== null &&
                            this.state.currentTestObject.title}{" "}
                          <span style={{ fontSize: 6, color: "#DDDDDD" }}>
                            {" "}
                            {btoa(this.props.user.username)}
                          </span>
                        </Typography>
                      </Paper>
                    </Grid>
                    <Grid item xs={12}>
                      <Paper
                        variant="outlined"
                        style={{
                          backgroundImage: `url("/U-Test-Logo-new-bw.png")`,
                          backgroundSize: "120px",
                          backgroundRepeat: "space",
                        }}
                      >
                        <Grid container>
                          <Grid
                            item
                            xs={6}
                            align="left"
                            style={{
                              maxHeight: this.state.window.height,
                              padding: "20px",
                            }}
                          >
                            <Grid container>
                              {this.state.currentTestObject !== null &&
                                [
                                  "matchingSentenceEndings",
                                  "listOfHeading",
                                ].includes(
                                  this.state.currentTestObject.sections[0]
                                    .question_type
                                ) && (
                                  <Grid
                                    item
                                    align="right"
                                    xs={12}
                                    style={{ marginBottom: "10px" }}
                                  >
                                    <ButtonGroup
                                      size="small"
                                      variant="contained"
                                      aria-label="contained primary button group"
                                    >
                                      <Button
                                        size="small"
                                        onClick={() => {
                                          this.handleFontSize("plus");
                                        }}
                                        style={{
                                          color: "white",
                                          backgroundColor: "#42647d",
                                        }}
                                      >
                                        <AddRoundedIcon />
                                      </Button>
                                      <Button
                                        size="small"
                                        onClick={() => {
                                          this.handleFontSize("minus");
                                        }}
                                        style={{
                                          color: "white",
                                          backgroundColor: "#42647d",
                                        }}
                                      >
                                        <RemoveRoundedIcon />
                                      </Button>
                                      <Button
                                        size="small"
                                        onClick={this.handleRestTextSize}
                                        style={{
                                          color: "white",
                                          backgroundColor: "#42647d",
                                        }}
                                      >
                                        Reset
                                      </Button>
                                    </ButtonGroup>
                                  </Grid>
                                )}
                              {this.state.currentTestObject !== null &&
                                [
                                  "matchingSentenceEndings",
                                  "listOfHeading",
                                ].includes(
                                  this.state.currentTestObject.sections[0]
                                    .question_type
                                ) && (
                                  <Grid
                                    item
                                    align="center"
                                    xs={12}
                                    style={{ marginBottom: "10px" }}
                                  >
                                    {this.state.currentTestObject?.description_title
                                      .split("\\n")
                                      .map((text, index) => (
                                        <Typography
                                          variant="h5"
                                          gutterBottom
                                          style={{ fontSize: `${this.state.fontSize}rem` }}
                                        >
                                          <Box
                                            fontWeight="fontWeightBold"
                                            display="inline"
                                          >
                                            {text}
                                          </Box>
                                        </Typography>
                                      ))}
                                  </Grid>
                                )}
                              <Grid item xs={12}>
                                <Grid
                                  container
                                  ref={this.myRef2}
                                  style={{
                                    marginBottom: "20px",
                                    paddingRight: "15px",
                                    maxHeight: this.state.window.height * 0.68,
                                    overflow: "auto",
                                    overflowY: "auto",
                                    overflowX: "hidden",
                                    WebkitOverflowScrolling: "touch",
                                    display:
                                      this.state.currentTestObject !== null &&
                                      [
                                        "matchingSentenceEndings",
                                        "listOfHeading",
                                      ].includes(
                                        this.state.currentTestObject.sections[0]
                                          .question_type
                                      )
                                        ? ""
                                        : "none",
                                  }}
                                >
                                  {this.state.listOfHeadingObj[0]?.sections
                                    ?.filter(
                                      (section) =>
                                        section.question_type ===
                                        "listOfHeading"
                                    )[0]
                                    ?.questions?.map((question, index) => (
                                      <Grid item>
                                        <DropTarget
                                          targetKey="foo"
                                          onHit={() => {
                                            this.onDropped(
                                              question.no,
                                              question
                                            );
                                          }}
                                          onFocus={() => {
                                            this.onFocusQuestion(
                                              Number(question.no)
                                            );
                                          }}
                                        >
                                          <List
                                            component="nav"
                                            aria-label="main mailbox folders"
                                          >
                                            <Paper
                                              variant="outlined"
                                              style={{
                                                backgroundColor:
                                                  this.state.dropItems[
                                                    "" + question.no
                                                  ] === undefined
                                                    ? "white"
                                                    : "#dbe9ee",
                                              }}
                                            >
                                              <ListItem
                                                button
                                                //selected={selectedIndex === 0}
                                                onClick={() => {
                                                  this.onClickAnswer(
                                                    question.no
                                                  );
                                                }}
                                              >
                                                <ListItemText
                                                  align="center"
                                                  primary={
                                                    <Typography
                                                      variant="body1"
                                                      style={{
                                                        fontWeight: "bold",
                                                      }}
                                                    >
                                                      {this.state.dropItems[
                                                        "" + question.no
                                                      ] === undefined
                                                        ? "" + question.no
                                                        : this.state.dropItems[
                                                            "" + question.no
                                                          ].toUpperCase()}
                                                    </Typography>
                                                  }
                                                />
                                              </ListItem>
                                            </Paper>
                                          </List>
                                        </DropTarget>
                                        <Typography
                                          paragraph
                                          style={{
                                            fontSize:
                                              `${this.state.fontSize}rem`,
                                            textAlign: "justify",
                                          }}
                                        >
                                          <DisplayParagraph
                                            handleGetSelectedDisplayText={
                                              this.handleGetSelectedDisplayText
                                            }
                                            handleNoteSelectedText={
                                              this.handleNoteSelectedText
                                            }
                                            fontSize={this.state.fontSize}
                                            currentTestObject={{
                                              description: question.paragraph,
                                            }}
                                            // currentTestObject={this.state.testObject[1]}
                                            highlightDisplayTextArr={
                                              this.state.highlightDisplayTextArr
                                            }
                                          />
                                          {/*<Highlighter
                                                                                    highlightClassName="YourHighlightClass"
                                                                                    searchWords={this.state.highlightArr}
                                                                                    autoEscape={true}
                                                                                    textToHighlight={question.paragraph}
                                                                                />*/}
                                        </Typography>
                                      </Grid>
                                    ))}
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid
                              container
                              style={{
                                display:
                                  this.state.currentTestObject !== null &&
                                  [
                                    "matchingSentenceEndings",
                                    "listOfHeading",
                                  ].includes(
                                    this.state.currentTestObject.sections[0]
                                      .question_type
                                  )
                                    ? "none"
                                    : "",
                              }}
                            >
                              <Grid
                                item
                                align="right"
                                xs={12}
                                style={{ marginBottom: "10px" }}
                              >
                                <ButtonGroup
                                  size="small"
                                  variant="contained"
                                  aria-label="contained primary button group"
                                >
                                  <Button
                                    onClick={() => {
                                      this.handleFontSize("plus");
                                    }}
                                    style={{
                                      color: "white",
                                      backgroundColor: "#42647d",
                                    }}
                                  >
                                    <AddRoundedIcon />
                                  </Button>
                                  <Button
                                    onClick={() => {
                                      this.handleFontSize("minus");
                                    }}
                                    style={{
                                      color: "white",
                                      backgroundColor: "#42647d",
                                    }}
                                  >
                                    <RemoveRoundedIcon />
                                  </Button>
                                  <Button
                                    onClick={this.handleRestTextSize}
                                    style={{
                                      color: "white",
                                      backgroundColor: "#42647d",
                                    }}
                                  >
                                    Reset
                                  </Button>
                                </ButtonGroup>
                              </Grid>
                              <Grid
                                item
                                align="center"
                                xs={12}
                                style={{ marginBottom: "10px" }}
                              >
                                <Typography
                                  variant="h5"
                                  gutterBottom
                                  style={{ fontSize: `${this.state.fontSize}rem` }}
                                >
                                  <Box
                                    fontWeight="fontWeightBold"
                                    display="inline"
                                  >
                                    {
                                      this.state.currentTestObject
                                        ?.description_title
                                    }
                                  </Box>
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                align="left"
                                ref={this.myRef3}
                                style={{
                                  marginBottom: "20px",
                                  paddingRight: "15px",
                                  maxHeight: this.state.window.height * 0.68,
                                  overflow: "auto",
                                  overflowY: "auto",
                                  overflowX: "hidden",
                                  WebkitOverflowScrolling: "touch",
                                  display:
                                    this.state.currentPart === 1 ? "" : "none",
                                }}
                              >
                                <DisplayParagraph
                                  handleGetSelectedDisplayText={
                                    this.handleGetSelectedDisplayText
                                  }
                                  handleNoteSelectedText={
                                    this.handleNoteSelectedText
                                  }
                                  fontSize={this.state.fontSize}
                                  currentTestObject={this.state.testObject[0]}
                                  highlightDisplayTextArr={
                                    this.state.highlightDisplayTextArr
                                  }
                                />
                              </Grid>
                              <Grid
                                ref={this.myRef4}
                                item
                                xs={12}
                                align="left"
                                style={{
                                  marginBottom: "20px",
                                  paddingRight: "15px",
                                  maxHeight: this.state.window.height * 0.68,
                                  overflow: "auto",
                                  overflowY: "auto",
                                  overflowX: "hidden",
                                  WebkitOverflowScrolling: "touch",
                                  display:
                                    this.state.currentPart === 3 ? "" : "none",
                                }}
                              >
                                <DisplayParagraph
                                  handleGetSelectedDisplayText={
                                    this.handleGetSelectedDisplayText
                                  }
                                  handleNoteSelectedText={
                                    this.handleNoteSelectedText
                                  }
                                  fontSize={this.state.fontSize}
                                  currentTestObject={this.state.testObject[2]}
                                  highlightDisplayTextArr={
                                    this.state.highlightDisplayTextArr
                                  }
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={6}>
                            <Grid container spacing={1}>
                              <Grid item xs={12} style={{ padding: "10px" }}>
                                <Grid
                                  item
                                  align="right"
                                  xs={12}
                                  style={{
                                    marginBottom: "10px",
                                    marginTop: "10px",
                                  }}
                                >
                                  <Grid
                                    container
                                    spacing={1}
                                    justify="flex-end"
                                    alignItems="center"
                                  >
                                    <Grid item>
                                      <Typography
                                        variant="h5"
                                        style={{
                                          fontSize: "1rem",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Text size:
                                      </Typography>
                                    </Grid>
                                    <Grid item>
                                      <ButtonGroup
                                        size="small"
                                        variant="contained"
                                        aria-label="contained primary button group"
                                      >
                                        <Button
                                          onClick={() => {
                                            this.onClickFontSize("sm");
                                          }}
                                          style={{
                                            color: "white",
                                            backgroundColor: "#42647d",
                                          }}
                                        >
                                          Small
                                        </Button>
                                        <Button
                                          onClick={() => {
                                            this.onClickFontSize("md");
                                          }}
                                          style={{
                                            color: "white",
                                            backgroundColor: "#42647d",
                                          }}
                                        >
                                          Medium
                                        </Button>
                                        <Button
                                          onClick={() => {
                                            this.onClickFontSize("lg");
                                          }}
                                          style={{
                                            color: "white",
                                            backgroundColor: "#42647d",
                                          }}
                                        >
                                          Large
                                        </Button>
                                      </ButtonGroup>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                ref={this.myRef}
                                style={{
                                  overflow: "auto",
                                  overflowY: "auto",
                                  overflowX: "hidden",
                                  WebkitOverflowScrolling: "touch",
                                  maxHeight: this.state.window.height * 0.7,
                                  padding: "10px",
                                }}
                              >
                                {this.state.currentTestObject !== null &&
                                  this.state.currentTestObject.sections
                                    .sort((a, b) => {
                                      return (
                                        Number(a.section.split(" - ")[1]) -
                                        Number(b.section.split(" - ")[1])
                                      );
                                    })
                                    .map((section) =>
                                      this.handleSelectQuestionType(section)
                                    )}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            {!this.state.isDone && this.state.isStartTest && (
              <Grid item align="right" xs={12}>
                <Typography
                  variant="subtitle2"
                  style={{ color: "#c8c8c8", fontWeight: "bold" }}
                >
                  {btoa(this.props.user.username)}
                </Typography>
              </Grid>
            )}
            {!this.state.isDone &&
              this.state.isStartTest &&
              this.state.currentTestObject !== null && (
                <Grid
                  item
                  xs={12}
                  style={{ position: "absolute", bottom: 0, width: "100%" }}
                >
                  <Container maxWidth={"xl"}>
                    <Grid container justify="center">
                      <Grid item xs={1}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                this.state.reviewedQuestion[
                                  this.state.focusedQuestion
                                ]
                                  ? this.state.reviewedQuestion[
                                      this.state.focusedQuestion
                                    ]
                                  : false
                              }
                              onChange={this.handleReviewQuestion}
                              name="reviewed"
                              color="primary"
                            />
                          }
                          label="Review"
                        />
                      </Grid>
                      <Grid item xs={10} align="left">
                        <Paper variant="outlined">
                          {this.state.questionNumArr.map((num, index) => {
                            return (
                              index !== 0 && (
                                <Button
                                  key={num}
                                  size="small"
                                  style={{
                                    borderRadius: this.state.reviewedQuestion[
                                      num
                                    ]
                                      ? 75
                                      : 3,
                                    color:
                                      this.state.answeredQuestion[num] ===
                                        undefined ||
                                      this.state.answeredQuestion[num] === null
                                        ? "#25333e"
                                        : "",
                                    backgroundColor:
                                      this.state.answeredQuestion[num] ===
                                        undefined ||
                                      this.state.answeredQuestion[num] === null
                                        ? this.state.focusedQuestion === num
                                          ? "#dbecf6"
                                          : ""
                                        : "#25333e",
                                    borderColor:
                                      this.state.answeredQuestion[num] ===
                                        undefined ||
                                      this.state.answeredQuestion[num] === null
                                        ? "#25333e"
                                        : "",
                                  }}
                                  variant={
                                    this.state.answeredQuestion[num] ===
                                      undefined ||
                                    this.state.answeredQuestion[num] === null
                                      ? "outlined"
                                      : "contained"
                                  }
                                  color="primary"
                                  onClick={() => {
                                    this.onClickQuestionBox(num);
                                  }}
                                  className={classes.questionNumber}
                                >
                                  {num}
                                </Button>
                              )
                            );
                          })}
                        </Paper>
                      </Grid>
                      <Grid item xs={1} align="right">
                        <Grid container>
                          <Grid item xs={6}>
                            {!(this.state.currentPart === 1) && (
                              <IconButton
                                aria-label="add to favorites"
                                onClick={() => {
                                  this.handleChangePart("back");
                                }}
                              >
                                <ArrowBackRoundedIcon />
                              </IconButton>
                            )}
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            style={{
                              marginTop: !(
                                this.state.currentPart ===
                                (this.props.isFreeTest ? 2 : 3)
                              )
                                ? "0%"
                                : "5%",
                            }}
                          >
                            {!(
                              this.state.currentPart ===
                              (this.props.isFreeTest ? 2 : 3)
                            ) ? (
                              <IconButton
                                aria-label="add to favorites"
                                onClick={() => {
                                  this.handleChangePart("next");
                                }}
                              >
                                <ArrowForwardRoundedIcon />
                              </IconButton>
                            ) : (
                              <Button
                                size="small"
                                variant="contained"
                                onClick={this.handleConfirmDoneTestDialog}
                                style={{
                                  backgroundColor: "#25333e",
                                  color: "white",
                                }}
                              >
                                Done
                              </Button>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Container>
                </Grid>
              )}
          </Grid>
        </Container>
      </ThemeProvider>
    );
  }
}

export default compose(withStyles(useStyles))(TestReading);
