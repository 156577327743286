//React
import { Component } from 'react';
import { compose } from 'recompose';

//Material UI
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import Skeleton from '@material-ui/lab/Skeleton';

//react-router-dom

//Others
import moment from 'moment';
import Select from 'react-select';

const axios = require('axios').default;
const FileDownload = require('js-file-download');

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%'
    }
});

class UnassignedTests extends Component {
    constructor() {
        super();
        this.state = {
            numberOfTest: 0,
            clickedAssign: false,
            isLoading: false,
            teachers: []
        };
    }

    componentDidMount() {
        this.handleGetAllSubmittedTests();
        this.handleGetAllTeachers();
    }

    capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    handleChange = (event) => {
        const name = event.target.name;

        this.setState({
            ...this.state,
            [name]: event.target.value
        });
    };

    handleTeacherChange = (event) => {
        console.log(event.value);
        this.setState({
            selectedTeacher: event.value
        });
    }

    handleGetAllTeachers = async () => {
        try {
            const res = await axios({
                method: 'get',
                url: 'https://chatter-funky-busby.glitch.me/teachers',
            });

            const resData = res.data;
            resData.teachers.map((teacher) => {
                this.state.teachers.push({
                    value: teacher,
                    label: this.capitalize(teacher.firstname) + ' ' + this.capitalize(teacher.lastname)
                })
            });

        } catch (err) {
            console.log(err);
        }
    }

    handleAssignTeacher = async (id, teacher) => {
        try {
            this.setState({
                isLoading: true
            });

            const res = await axios({
                method: 'put',
                url: 'https://chatter-funky-busby.glitch.me/teachers/assign',
                headers: {
                    "Content-Type": "application/json"
                },
                data: {
                    id: id,
                    assignTo: this.capitalize(teacher.firstname) + ' ' + this.capitalize(teacher.lastname),
                    teacherUsername: teacher.username,
                    createdAt: moment().format("DD/MM/YYYY HH:mm:ss")
                }
            });

            const resData = res.data;
            await this.handleGetAllSubmittedTests();
            this.setState({
                isLoading: false
            });

        } catch (err) {
            console.log(err);
        }
    }

    handleGetAllSubmittedTests = async () => {
        try {
            const res = await axios({
                method: 'get',
                url: 'https://chatter-funky-busby.glitch.me/tests',
            });

            const resData = res.data;
            this.setState({
                tests: resData.tests.filter(test => test.assignTo === 'Unassigned'),
                numberOfTest: resData.tests.filter(test => test.assignTo === 'Unassigned').length <= 10 ? resData.tests.length : 10
            });
        } catch (err) {
            console.log(err);
        }
    }

    handleDownloadTest = async (fileName) => {
        try {
            const res = await axios({
                method: 'get',
                url: 'https://chatter-funky-busby.glitch.me/tests/' + fileName,
                responseType: "blob"
            });

            await FileDownload(res.data, fileName);

        } catch (err) {
            console.log(err);
        }
    }

    handleSeeMoreTest = () => {
        if (this.state.numberOfTest + 10 >= this.state.tests.length) {
            this.setState({
                numberOfTest: this.state.tests.length
            });

            return;
        }

        this.setState({
            numberOfTest: this.state.numberOfTest + 10
        });
    }

    handleSeeLessTest = () => {
        if (this.state.numberOfTest - 10 <= 10) {
            this.setState({
                numberOfTest: 10
            });

            return;
        }

        this.setState({
            numberOfTest: this.state.numberOfTest - 10
        });
    }

    handleColorChip = (tag) => {
        if (tag === 'Full') {
            return <Chip label={tag} style={{ margin: '5px', backgroundColor: '#76323F', color: 'white' }} />;
        } else if (tag === 'IELTS') {
            return <Chip label={tag} style={{ margin: '5px', backgroundColor: '#141951', color: 'white' }} />
        } else {
            return <Chip label={tag} style={{ margin: '5px' }} />
        }
    }

    render() {
        const { classes } = this.props;

        return (
            <Grid item xs={12} sm={9}>
                <Grid container>
                    <Grid item xs={12} style={{ overflowY: 'auto', maxHeight: '800px' }}>
                        <Typography align='left' variant="h5" gutterBottom>
                            <Box fontWeight="fontWeightBold" display='inline'>Unassigned Tests</Box>
                        </Typography>
                        <Paper variant="outlined">
                            {this.state.tests !== undefined ? (this.state.tests.sort((a, b) => new Date(a.createdAt) < new Date(b.createdAt) ? 1 : -1).slice(0, this.state.numberOfTest).map((test, index) =>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Grid container spacing={0}>
                                            <Grid align='left' item xs={12} sm>
                                                <Typography>{test.submitter}'s {test.testType.toLowerCase()} test</Typography>
                                                <Typography variant="caption" color="textSecondary">
                                                    {moment(test.createdAt).format('MMMM Do YYYY, h:mm:ss A')}
                                                </Typography>
                                            </Grid>
                                            <Grid align='right' item xs={12} sm>
                                                {test.tags.map((tag) =>
                                                    this.handleColorChip(tag)
                                                )}
                                                {test.assignTo === 'Unassigned' && <Chip label={test.assignTo} style={{ margin: '5px', backgroundColor: 'orange', color: 'white' }} />}
                                            </Grid>
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container spacing={2}>
                                            <Grid align='right' item xs={6} sm={3}>
                                                <Typography>
                                                    <Box fontWeight="fontWeightBold" display='inline'>Tester:</Box>
                                                </Typography>
                                                <Typography>
                                                    <Box fontWeight="fontWeightBold" display='inline'>Submit date:</Box>
                                                </Typography>
                                                <Typography>
                                                    <Box fontWeight="fontWeightBold" display='inline'>Test category:</Box>
                                                </Typography>
                                                <Typography>
                                                    <Box fontWeight="fontWeightBold" display='inline'>Type:</Box>
                                                </Typography>
                                                <Typography>
                                                    <Box fontWeight="fontWeightBold" display='inline'>Assign to:</Box>
                                                </Typography>
                                            </Grid>
                                            <Grid align='left' item xs={6} sm={9}>
                                                <Typography>
                                                    {test.submitter}
                                                </Typography>
                                                <Typography>
                                                    {new Date(test.createdAt).toDateString()}
                                                </Typography>
                                                <Typography>
                                                    {test.testCategory}
                                                </Typography>
                                                <Typography>
                                                    {test.testType}
                                                </Typography>
                                                <Typography>
                                                    {test.assignTo}
                                                </Typography>
                                            </Grid>
                                            <Grid align='right' item xs={12}>
                                                <Grid container spacing={2} justify='right'>
                                                    <Grid item xs={12} sm={4} />
                                                    <Grid item xs={12} sm={6}>
                                                        <Select
                                                            options={this.state.teachers}
                                                            isSearchable={true}
                                                            isClearable={true}
                                                            onChange={this.handleTeacherChange}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={1}>
                                                        <Button
                                                            disabled={this.state.isLoading}
                                                            variant="contained"
                                                            onClick={async () => {
                                                                await this.handleAssignTeacher(test._id, this.state.selectedTeacher);
                                                                this.handleGetAllSubmittedTests();
                                                            }}
                                                            style={{ color: 'white', backgroundColor: '#880000' }}
                                                            startIcon={this.state.isLoading && <CircularProgress size={25} style={{ color: 'white' }} />}
                                                        >
                                                            {this.state.isLoading ? '' : 'Assign'}
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Button
                                                            variant="contained"
                                                            startIcon={<GetAppRoundedIcon style={{ color: 'white' }} />}
                                                            onClick={() => this.handleDownloadTest(test.fileName)}
                                                            style={{ color: 'white', backgroundColor: '#880000' }}
                                                        >
                                                            Download
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            )) : (<Skeleton variant="rect" width="100%" height={80} />)}
                        </Paper>
                    </Grid>
                    {/* {<Grid item xs={12}>
                        {this.state.tests !== undefined && !(this.state.numberOfTest >= this.state.tests.length) &&
                            <Button
                                variant="contained"
                                style={{ width: '100%', marginTop: '20px', backgroundColor: '#76323F', color: 'white' }}
                                onClick={this.handleSeeMoreTest}
                            >
                                See More (+10)
                            </Button>
                        }
                        {this.state.tests !== undefined && (this.state.numberOfTest === this.state.tests.length) &&
                            <Button
                                variant="contained"
                                style={{ width: '100%', marginTop: '20px', backgroundColor: '#76323F', color: 'white' }}
                                onClick={this.handleSeeLessTest}
                            >
                                See Less (-10)
                            </Button>
                        }
                    </Grid>} */}
                </Grid>
            </Grid>
        )
    }
}

export default compose(
    withStyles(useStyles)
)(UnassignedTests);