import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { ThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import React, { Component } from 'react';
import { compose } from 'recompose';

//Icon
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import TodayRoundedIcon from '@material-ui/icons/TodayRounded';
import AccessAlarmsRoundedIcon from '@material-ui/icons/AccessAlarmsRounded';
import BlockRoundedIcon from '@material-ui/icons/BlockRounded';

//import other library
import 'react-calendar/dist/Calendar.css';
import {
    Link
} from "react-router-dom";

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '80%'
    }
});

const theme = createMuiTheme({
    typography: {
        fontFamily: [
            'Roboto'
        ].join(','),
    },
  });

export class PaymentSuccessfulPage extends Component {
    constructor() {
        super();
        this.state = {

        };
    }

    componentDidMount = () => {
        document.querySelector("body").scrollTo(0, 0);
    }

    render() {
        const { classes } = this.props;

        const mobileUI = (
            <Container maxWidth="md" style={{ paddingLeft: '5%', paddingRight: '5%', paddingBottom: '15%' }}>
                <Paper style={{ padding: '5%' }}>
                    <Grid container justify='center'>
                        <Grid item align='center' xs={12}>
                            <CheckCircleRoundedIcon style={{ color: 'green', fontSize: '5rem' }} />
                            <Typography variant="body1" color='primary' gutterBottom style={{ color: 'green', fontSize: '1.2rem' }}>
                                <Box fontWeight="fontWeightBold" display='inline'>Congratulations on your purchase from UTest Avenue!</Box>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: '10%' }}>
                            <Grid container>
                                <Grid item align='left' xs={6}>
                                    <Typography variant="body1" gutterBottom style={{ fontSize: '1rem' }}>
                                        Test type
                                    </Typography>
                                </Grid>
                                <Grid item align='right' xs={6}>
                                    <Typography variant="body1" gutterBottom style={{ fontSize: '1rem' }}>
                                        {this.props.testType}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item align='left' xs={6}>
                                    <Typography variant="body1" gutterBottom style={{ fontSize: '1rem' }}>
                                        Name
                                    </Typography>
                                </Grid>
                                <Grid item align='right' xs={6}>
                                    <Typography variant="body1" gutterBottom style={{ fontSize: '1rem' }}>
                                        {this.props.name}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item align='left' xs={6}>
                                    <Typography variant="body1" gutterBottom style={{ fontSize: '1rem' }}>
                                        Mobile
                                    </Typography>
                                </Grid>
                                <Grid item align='right' xs={6}>
                                    <Typography variant="body1" gutterBottom style={{ fontSize: '1rem' }}>
                                        {this.props.phone}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item align='left'>
                                    <Typography variant="body1" gutterBottom>
                                        Email
                                    </Typography>
                                </Grid>
                                <Grid item align='right' xs>
                                    <Typography variant="body2" gutterBottom style={{ fontSize: '1rem' }}>
                                        {this.props.email}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: '3%', marginBottom: '3%' }}>
                            <Grid container>
                                <Grid item align='left' xs={6}>
                                    <Typography variant="body1" gutterBottom style={{ fontSize: '1.2rem' }}>
                                        <Box fontWeight="fontWeightBold" display='inline'>Amount paid</Box>
                                    </Typography>
                                </Grid>
                                <Grid item align='right' xs={6}>
                                    <Typography variant="body1" gutterBottom style={{ fontSize: '1.2rem' }}>
                                        <Box fontWeight="fontWeightBold" display='inline'>{this.props.amount < 0 ? 0 : this.props.amount} Baht</Box>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item align='left'>
                                    <Typography variant="body2" gutterBottom>
                                        Transaction id
                                    </Typography>
                                </Grid>
                                <Grid item align='right' xs>
                                    <Typography variant="body2" gutterBottom>
                                        {this.props.omiseToken}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: '10%' }}>
                            <Grid container justify='center' spacing={2}>
                                <Grid item align='left' xs={3}>
                                    <Link to={"/"} color="inherit" underline="none" style={{ textDecoration: 'none', color: '#565656' }}>
                                        <Button
                                            onClick={this.handleChooseFreeTest}
                                            style={{
                                                backgroundColor: '#76323F',
                                                color: 'white',
                                                padding: '5px',
                                                width: '100%'
                                            }}
                                        >
                                            Home
                                        </Button>
                                    </Link>
                                </Grid>
                                <Grid item align='right' xs>
                                    <Link to={"/profile"} color="inherit" underline="none" style={{ textDecoration: 'none', color: '#565656' }}>
                                        <Button
                                            onClick={this.handleChooseFreeTest}
                                            style={{
                                                backgroundColor: '#76323F',
                                                color: 'white',
                                                padding: '5px',
                                                width: '100%'
                                            }}
                                        >
                                            View purchased test
                                        </Button>
                                    </Link>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} style={{ marginTop: '7%' }}>
                            <Grid container justify="center" alignItems="center" spacing={1}>
                                <Grid item align='left'>
                                    <TodayRoundedIcon style={{ color: "#6a6a6a" }} />
                                </Grid>
                                <Grid item align='left' xs>
                                    <Typography variant="caption" gutterBottom style={{ color: "#6a6a6a" }}>
                                        Remember, if you have purchased <span style={{ fontWeight: 'bold', textDecoration: "underline" }}>FULL IELTS with SPK</span>, please schedule your
                                        speaking test <span style={{ fontWeight: 'bold', textDecoration: "underline" }}>within 7 days of payment to secure your spot.</span>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container justify="center" alignItems="center" spacing={1}>
                                <Grid item align='left'>
                                    <AccessAlarmsRoundedIcon style={{ color: "#6a6a6a" }} />
                                </Grid>
                                <Grid item align='left' xs>
                                    <Typography variant="caption" gutterBottom style={{ color: "#6a6a6a" }}>
                                        For all other test purchases, <span style={{ fontWeight: 'bold', textDecoration: "underline" }}>make sure to complete your test within 90 days.</span>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container justify="center" alignItems="center" spacing={1}>
                                <Grid item align='left'>
                                    <BlockRoundedIcon style={{ color: "#f44335" }} />
                                </Grid>
                                <Grid item align='left' xs>
                                    <Typography variant="caption" gutterBottom style={{ color: "#6a6a6a" }}>
                                        Please note that failing to adhere to our policies will result in the expiration of your test and the inability to issue refunds.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        )

        const webUI = (
            <Container maxWidth="md" style={{ paddingLeft: '15%', paddingRight: '15%', paddingBottom: '15%' }}>
                <Paper style={{ padding: '5%' }}>
                    <Grid container justify='center'>
                        <Grid item align='center' xs={12}>
                            <CheckCircleRoundedIcon style={{ color: 'green', fontSize: '5rem' }} />
                            <Typography variant="body1" color='primary' gutterBottom style={{ color: 'green', fontSize: '1.2rem' }}>
                                <Box fontWeight="fontWeightBold" display='inline'>Congratulations on your purchase from UTest Avenue!</Box>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: '10%' }}>
                            <Grid container>
                                <Grid item align='left' xs={6}>
                                    <Typography variant="body1" gutterBottom style={{ fontSize: '1rem' }}>
                                        Test type
                                    </Typography>
                                </Grid>
                                <Grid item align='right' xs={6}>
                                    <Typography variant="body1" gutterBottom style={{ fontSize: '1rem' }}>
                                        {this.props.testType}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item align='left' xs={6}>
                                    <Typography variant="body1" gutterBottom style={{ fontSize: '1rem' }}>
                                        Name
                                    </Typography>
                                </Grid>
                                <Grid item align='right' xs={6}>
                                    <Typography variant="body1" gutterBottom style={{ fontSize: '1rem' }}>
                                        {this.props.name}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item align='left' xs={6}>
                                    <Typography variant="body1" gutterBottom style={{ fontSize: '1rem' }}>
                                        Mobile
                                    </Typography>
                                </Grid>
                                <Grid item align='right' xs={6}>
                                    <Typography variant="body1" gutterBottom style={{ fontSize: '1rem' }}>
                                        {this.props.phone}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item align='left' xs={6}>
                                    <Typography variant="body1" gutterBottom style={{ fontSize: '1rem' }}>
                                        Email
                                    </Typography>
                                </Grid>
                                <Grid item align='right' xs={6}>
                                    <Typography variant="body1" gutterBottom style={{ fontSize: '1rem' }}>
                                        {this.props.email}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: '3%', marginBottom: '3%' }}>
                            <Grid container>
                                <Grid item align='left' xs={6}>
                                    <Typography variant="body1" gutterBottom style={{ fontSize: '1.2rem' }}>
                                        <Box fontWeight="fontWeightBold" display='inline'>Amount paid</Box>
                                    </Typography>
                                </Grid>
                                <Grid item align='right' xs={6}>
                                    <Typography variant="body1" gutterBottom style={{ fontSize: '1.2rem' }}>
                                        <Box fontWeight="fontWeightBold" display='inline'>{this.props.amount < 0 ? 0 : this.props.amount} Baht</Box>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item align='left' xs={6}>
                                    <Typography variant="body1" gutterBottom style={{ fontSize: '1rem' }}>
                                        Transaction id
                                    </Typography>
                                </Grid>
                                <Grid item align='right' xs={6}>
                                    <Typography variant="body1" gutterBottom style={{ fontSize: '1rem' }}>
                                        {this.props.omiseToken}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: '10%' }}>
                            <Grid container justify='center' spacing={2}>
                                <Grid item align='left' xs={3}>
                                    <Link to={"/"} color="inherit" underline="none" style={{ textDecoration: 'none', color: '#565656' }}>
                                        <Button
                                            size="large"
                                            onClick={this.handleChooseFreeTest}
                                            style={{
                                                backgroundColor: '#76323F',
                                                color: 'white',
                                                padding: '5px',
                                                width: '100%'
                                            }}
                                        >
                                            Home
                                        </Button>
                                    </Link>
                                </Grid>
                                <Grid item align='right' xs={6}>
                                    <Link to={"/profile"} color="inherit" underline="none" style={{ textDecoration: 'none', color: '#565656' }}>
                                        <Button
                                            size="large"
                                            onClick={this.handleChooseFreeTest}
                                            style={{
                                                backgroundColor: '#76323F',
                                                color: 'white',
                                                padding: '5px',
                                                width: '100%'
                                            }}
                                        >
                                            View purchased test
                                        </Button>
                                    </Link>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} style={{ marginTop: '7%' }}>
                            <Grid container justify="center" alignItems="center" spacing={1}>
                                <Grid item align='left'>
                                    <TodayRoundedIcon style={{ color: "#6a6a6a" }} />
                                </Grid>
                                <Grid item align='left' xs>
                                    <Typography variant="caption" gutterBottom style={{ color: "#6a6a6a" }}>
                                        Remember, if you have purchased <span style={{ fontWeight: 'bold', textDecoration: "underline" }}>FULL IELTS with SPK</span>, please schedule your
                                        speaking test <span style={{ fontWeight: 'bold', textDecoration: "underline" }}>within 7 days of payment to secure your spot.</span>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container justify="center" alignItems="center" spacing={1}>
                                <Grid item align='left'>
                                    <AccessAlarmsRoundedIcon style={{ color: "#6a6a6a" }} />
                                </Grid>
                                <Grid item align='left' xs>
                                    <Typography variant="caption" gutterBottom style={{ color: "#6a6a6a" }}>
                                        For all other test purchases, <span style={{ fontWeight: 'bold', textDecoration: "underline" }}>make sure to complete your test within 90 days.</span>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container justify="center" alignItems="center" spacing={1}>
                                <Grid item align='left'>
                                    <BlockRoundedIcon style={{ color: "#f44335" }} />
                                </Grid>
                                <Grid item align='left' xs>
                                    <Typography variant="caption" gutterBottom style={{ color: "#6a6a6a" }}>
                                        Please note that failing to adhere to our policies will result in the expiration of your test and the inability to issue refunds.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        )

        return (
            <ThemeProvider theme={theme}>
                <Hidden mdUp>{mobileUI}</Hidden>
                <Hidden smDown>{webUI}</Hidden>
            </ThemeProvider>
        );
    }
}

export default compose(
    withStyles(useStyles)
)(PaymentSuccessfulPage);
