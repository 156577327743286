//React
import { Component } from 'react';
import { compose } from 'recompose';

//Material UI
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

//import other library
import Image from 'material-ui-image';

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%'
    }
});

class Map extends Component {
    constructor() {
        super();

        this.state = {
            testObject: {
                "section_id": "9302f708-6631-44d1-a8e6-8f6c323e856a",
                "section": "Questions 11 - 16 The map has eight labels (A - H) Choose the correct label for different parts of the campuses",
                "question_type": "map",
                "questions": [
                    {
                        "voice": "https://storage.googleapis.com/e-learning-audio/IELTS LISTENING (2B).wav",
                        "no": "11",
                        "question": "The map has eight labels (A - H) Choose the correct label for different parts of the campuses",
                        "image": "https://storage.googleapis.com/e-learning-audio/IELTS LISTENING (2B) MAP.PNG",
                        "sub_question": [
                            "11. Gardens"
                        ],
                        "choice": [
                            "A",
                            "B",
                            "C",
                            "D",
                            "E",
                            "F",
                            "G",
                            "H"
                        ]
                    },
                    {
                        "voice": "https://storage.googleapis.com/e-learning-audio/IELTS LISTENING (2B).wav",
                        "no": "12",
                        "question": "The map has eight labels (A - H) Choose the correct label for different parts of the campuses",
                        "image": "https://storage.googleapis.com/e-learning-audio/IELTS LISTENING (2B) MAP.PNG",
                        "sub_question": [
                            "12. Meeting Room"
                        ],
                        "choice": [
                            "A",
                            "B",
                            "C",
                            "D",
                            "E",
                            "F",
                            "G",
                            "H"
                        ]
                    },
                    {
                        "voice": "https://storage.googleapis.com/e-learning-audio/IELTS LISTENING (2B).wav",
                        "no": "13",
                        "question": "The map has eight labels (A - H) Choose the correct label for different parts of the campuses",
                        "image": "https://storage.googleapis.com/e-learning-audio/IELTS LISTENING (2B) MAP.PNG",
                        "sub_question": [
                            "13. English Class"
                        ],
                        "choice": [
                            "A",
                            "B",
                            "C",
                            "D",
                            "E",
                            "F",
                            "G",
                            "H"
                        ]
                    },
                    {
                        "voice": "https://storage.googleapis.com/e-learning-audio/IELTS LISTENING (2B).wav",
                        "no": "14",
                        "question": "The map has eight labels (A - H) Choose the correct label for different parts of the campuses",
                        "image": "https://storage.googleapis.com/e-learning-audio/IELTS LISTENING (2B) MAP.PNG",
                        "sub_question": [
                            "14. Library"
                        ],
                        "choice": [
                            "A",
                            "B",
                            "C",
                            "D",
                            "E",
                            "F",
                            "G",
                            "H"
                        ]
                    },
                    {
                        "voice": "https://storage.googleapis.com/e-learning-audio/IELTS LISTENING (2B).wav",
                        "no": "15",
                        "question": "The map has eight labels (A - H) Choose the correct label for different parts of the campuses",
                        "image": "https://storage.googleapis.com/e-learning-audio/IELTS LISTENING (2B) MAP.PNG",
                        "sub_question": [
                            "15. Computer Room"
                        ],
                        "choice": [
                            "A",
                            "B",
                            "C",
                            "D",
                            "E",
                            "F",
                            "G",
                            "H"
                        ]
                    },
                    {
                        "voice": "https://storage.googleapis.com/e-learning-audio/IELTS LISTENING (2B).wav",
                        "no": "16",
                        "question": "The map has eight labels (A - H) Choose the correct label for different parts of the campuses",
                        "image": "https://storage.googleapis.com/e-learning-audio/IELTS LISTENING (2B) MAP.PNG",
                        "sub_question": [
                            "16. Gym"
                        ],
                        "choice": [
                            "A",
                            "B",
                            "C",
                            "D",
                            "E",
                            "F",
                            "G",
                            "H"
                        ]
                    }
                ],
                "total_question": 6
            },
            answerObj: {
                answers: {}
            },
            answerArr: {},
            sizeMapHeader: {
                'sm': 'body2',
                'md': 'body1',
                'lg': 'h6',
            },
            sizeMapBody: {
                'sm': 'caption',
                'md': 'body2',
                'lg': 'body1',
            },
            sizeMapCaption: {
                'sm': 'caption',
                'md': 'body2',
                'lg': 'h6',
            },
        };
    }

    componentDidMount = async () => {
        const filteredAnsObj = await this.props.answerObj.parts[this.props.currentPart].sections.find((element) => {
            return element.section_id === this.props.currentTestObject.section_id;
        })

        this.setState({
            answerObj: {
                section_id: this.props.currentTestObject.section_id,
                question_type: this.props.currentTestObject.question_type,
                answers: filteredAnsObj.answers.length === 0 ? {} : filteredAnsObj.answers
            },
        })

    }

    handleSelectAnswer = (event, index, no) => {
        const questionKey = '' + no //Convert integer to string
        this.state.answerObj.answers[questionKey] = event.target.value;
        this.state.answerArr[no] = event.target.value;
        this.props.handleAnswerObj(this.state.answerObj)
        this.props.handleAnsweredQuestionNumber(no);
        this.props.onFocusQuestion(no);

    }

    render() {
        const { classes } = this.props;

        return (
            <Grid container>
                <Grid item xs={12} style={{ padding: '30px' }}>
                    <Grid container>
                        <Grid item xs={12} sm={4} style={{ marginTop: "-50px" }}>
                            <Image
                                src={this.props.currentTestObject?.questions[0].image}
                            />
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <TableContainer component={Paper} style={{ width: '100%' }}>
                                <Table size="small" aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell></TableCell>
                                            {this.props.currentTestObject?.questions[0].choice.map((choice) => (
                                                <TableCell align="center">
                                                    <Typography style={{ fontSize: `${this.props.fontSize}rem` }}>
                                                        {choice}
                                                    </Typography>
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.props.currentTestObject?.questions.map((row, index) => (
                                            <TableRow key={row.name}>
                                                <TableCell component="th" scope="row">
                                                    <Typography style={{ fontSize: `${this.props.fontSize}rem` }}>
                                                        {row.sub_question[0]}
                                                    </Typography>
                                                </TableCell>
                                                {row.choice.map((choice) => (
                                                    <TableCell align="center" style={{ padding: '0px' }}>
                                                        <Button
                                                            variant={this.state.answerObj.answers[row.no] === choice ? "contained" : "outlined"}
                                                            value={choice}
                                                            onClick={(event) => { this.handleSelectAnswer(event, index, row.no) }}
                                                            style={{ width: '40px', height: '40px', margin: "5px", backgroundColor: this.state.answerObj.answers[row.no] === choice ? "#25333e" : "white" }}
                                                        />
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

export default compose(
    withStyles(useStyles)
)(Map);
