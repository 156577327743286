//React
import { Component } from 'react';
import { compose } from 'recompose';

//Material UI
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import Skeleton from '@material-ui/lab/Skeleton';
import WritingScoreDisplay from './components/WritingScoreDisplay';
import { CreateNewNotification } from '../../../../../services/notificationServices';
import moment from 'moment';
import SpeakingScoreDisplay from './components/SpeakingScoreDisplay';

//Others
const axios = require("axios").default;
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";
const FormData = require('form-data');
const FileDownload = require('js-file-download');

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%'
    }
});

class AssignedTestsHT extends Component {
    constructor() {
        super();
        this.state = {
            numberOfTest: 0,
            selectedMenu: 'assignedTest',
            filterType: 'all',
            filteredTestStatus: 'all',
            today: new Date(Date.now()),
            isApproving: false,
            isOpenDialog: false,
            selectedScore: {}
        };
    }

    componentDidMount() {
        this.handleGetAllSubmittedTests();
    }

    handleGetAllSubmittedTests = async () => {
        try {
            const res = await axios({
                method: 'get',
                url: 'https://chatter-funky-busby.glitch.me/test/head-teacher/submitted-tests' /*+ this.props.userInfo.username*/,
            });

            const resData = res.data;
            this.setState({
                tests: resData.tests
            });

        } catch (err) {
            console.log(err);
        }
    }

    handleDownloadTest = async (testId) => {
        try {
            const testObj = await axios({
                method: 'get',
                url: 'https://chatter-funky-busby.glitch.me/tests/' + testId
            });

            const res = await axios({
                method: 'get',
                url: 'https://chatter-funky-busby.glitch.me/tests/file/' + testObj.data.teacherFile,
                responseType: "blob"
            });

            //Create a Blob from the PDF Stream
            const file = new Blob([res.data], { type: "application/pdf" });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            const pdfWindow = window.open();
            pdfWindow.location.href = fileURL;

            //await FileDownload(res.data, testObj.data.fileName);

        } catch (err) {
            console.log(err);
        }
    }

    handleApproveTest = async (id, status, obj) => {
        try {
            this.setState({
                isApproving: true
            });

            if (status === "approved") {
                if (obj.submitted_test_id === "speaking") {
                    await axios({
                        method: 'post',
                        url: `${api_base_url}/api/test/test-speaking-note`,
                        headers: {
                            Authorization: `Bearer ${JSON.parse(localStorage.getItem('user')).token}`
                        },
                        data: {
                            note: [obj.note]
                        }
                    })
                } else {
                    await axios({
                        method: 'post',
                        url: `${api_base_url}/api/test/test-writing-note`,
                        headers: {
                            Authorization: `Bearer ${JSON.parse(localStorage.getItem('user')).token}`
                        },
                        data: {
                            note: [obj.note]
                        }
                    });
                }
            }

            await axios({
                method: 'patch',
                url: 'https://chatter-funky-busby.glitch.me/test/head-teacher/submitted-tests/' + id,
                data: {
                    isChecked: true,
                    status: status
                }
            });

            await this.handleGetAllSubmittedTests();


            if (obj.note.note.quality_of_content !== undefined) {
                CreateNewNotification(
                    obj.note.tester,
                    "bmat_writing_score",
                    {
                        test_id: obj.note.test_id,
                        message: "Your writing score is out now! Let's go and check at your report.",
                    }
                );
            } else {
                CreateNewNotification(
                    obj.note.tester,
                    "writing_score",
                    {
                        test_id: obj.note.test_id,
                        message: `Your ${obj.submitted_test_id === "speaking" ? "speaking" : "writing"} score is out now! Let's go and check at your report.`,
                    }
                );
            }

            this.setState({
                isApproving: false
            });

        } catch (err) {
            console.log(err);
        }
    }

    onClickReviewScore = (obj) => {
        console.log(obj);
        this.setState({
            selectedScore: obj
        });
        this.onOpenScoreDialog();
    }

    onOpenScoreDialog = () => {
        this.setState({
            isOpenDialog: true
        });
    }

    onCloseScoreDialog = () => {
        this.setState({
            isOpenDialog: false
        });
    }

    //================== FILTER RELATED ======================
    handleChipClick = (type) => {
        this.setState({
            filterType: type
        });
    }

    handleSelectFilterTestStatus = (event) => {
        if (event === null) {
            this.setState({
                filteredTestStatus: 'all'
            });
        } else if (event !== null) {
            this.setState({
                filteredTestStatus: event.value
            });
        }
    }
    //================== FILTER RELATED ======================

    handleColorChip = (tag) => {
        if (tag === 'Full') {
            return <Chip label={tag} style={{ margin: '5px', backgroundColor: '#76323F', color: 'white' }} />;
        } else if (tag === 'IELTS') {
            return <Chip label={tag} style={{ margin: '5px', backgroundColor: '#141951', color: 'white' }} />
        } else {
            return <Chip label={tag} style={{ margin: '5px' }} />
        }
    }

    handleCalculateExpectedReportDate = (date) => {
        const tempDate = new Date(date);
        const numberOfDaysToAdd = 3;
        const expectedReportDate = tempDate.setDate(tempDate.getDate() + numberOfDaysToAdd);
        return new Date(expectedReportDate).toDateString()
    }

    render() {
        const { classes } = this.props;

        return (
            <Grid item xs={12} sm={9}>
                {this.state.selectedScore !== {} &&
                    (this.state.selectedScore.submitted_test_id === "speaking" ? <SpeakingScoreDisplay
                        test={this.state.test}
                        isOpenDialog={this.state.isOpenDialog}
                        scoreObj={this.state.selectedScore}
                        onCloseScoreDialog={this.onCloseScoreDialog}
                    /> : <WritingScoreDisplay
                        test={this.state.test}
                        isOpenDialog={this.state.isOpenDialog}
                        scoreObj={this.state.selectedScore}
                        onCloseScoreDialog={this.onCloseScoreDialog}
                    />)
                }
                <Grid container>
                    <Grid item xs={12}>
                        <Typography align='left' variant="h5" gutterBottom>
                            <Box fontWeight="fontWeightBold" display='inline'>Head Teacher Approval</Box>
                        </Typography>
                        <Paper variant="outlined" style={{ overflowY: 'auto', maxHeight: '800px' }}>
                            {this.state.tests !== undefined ?
                                (this.state.tests.sort((a, b) => new Date(a.createdAt) < new Date(b.createdAt) ? 1 : -1)
                                    .map((test, index) =>
                                        <Accordion>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Grid container spacing={0}>
                                                    <Grid align='left' item xs={12} sm>
                                                        <Typography>{test.submitted_test_id === "speaking" ? "Speaking test" : "Writing test"}</Typography>
                                                        <Typography variant="caption" color="textSecondary">
                                                            {moment(test.createdAt).format('MMMM Do YYYY, h:mm:ss A')}
                                                        </Typography>
                                                    </Grid>
                                                    <Chip
                                                        label={test.submitted_test_id === "speaking" ? "Speaking" : 'Writing'}
                                                        style={{
                                                            margin: '5px',
                                                        }}
                                                    />
                                                    <Chip
                                                        label={test.status ? test.status.toUpperCase() : 'Waiting'}
                                                        style={{
                                                            margin: '5px',
                                                            backgroundColor: test.isChecked ? (test.status === 'approved' ? 'green' : '#be323e') : 'orange',
                                                            color: 'white'
                                                        }}
                                                    />
                                                </Grid>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid container spacing={2}>
                                                    <Grid align='left' item xs={12} style={{ paddingLeft: '50px' }}>
                                                        <Typography gutterBottom>
                                                            <Box fontWeight="fontWeightBold" display='inline'>Submission id:</Box> {test.submitted_test_id === "speaking" ? test.note.tester : test.submitted_test_id}
                                                        </Typography>
                                                        <Typography gutterBottom>
                                                            <Box fontWeight="fontWeightBold" display='inline'>Head teacher:</Box> {test.headTeacherName}
                                                        </Typography>
                                                        <Typography gutterBottom>
                                                            <Box fontWeight="fontWeightBold" display='inline'>Submitted at:</Box> {new Date(test.createdAt).toDateString()}
                                                        </Typography>
                                                        <Typography gutterBottom>
                                                            <Box fontWeight="fontWeightBold" display='inline'>Expected report date:</Box> {this.handleCalculateExpectedReportDate(test.createdAt)}
                                                        </Typography>
                                                        <Typography gutterBottom>
                                                            <Box fontWeight="fontWeightBold" display='inline'>Status:</Box> {test.status ? test.status : 'Waiting'}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid align='right' item xs={12}>
                                                        <Button
                                                            variant="contained"
                                                            style={{ marginRight: '10px', backgroundColor: '#76323F', color: 'white' }}
                                                            onClick={() => this.onClickReviewScore(test)}
                                                        >
                                                            Review Score
                                                        </Button>
                                                        {test.submitted_test_id !== "speaking" && <Button
                                                            variant="contained"
                                                            startIcon={<GetAppRoundedIcon />}
                                                            style={{ marginRight: '10px', backgroundColor: '#76323F', color: 'white' }}
                                                            onClick={() => this.handleDownloadTest(test.submitted_test_id)}
                                                        >
                                                            Download Teacher File
                                                        </Button>}
                                                        {!test.isChecked &&
                                                            <Button
                                                                variant="contained"
                                                                disabled={this.state.isApproving}
                                                                startIcon={this.state.isApproving ? '' : <CheckCircleOutlineRoundedIcon style={{ color: 'white' }} />}
                                                                onClick={() => this.handleApproveTest(test._id, 'approved', test)}
                                                                style={{ marginRight: '10px', backgroundColor: 'green', color: 'white' }}
                                                            >
                                                                {this.state.isApproving ? <CircularProgress size={25} style={{ color: 'white' }} /> : "Approve"}
                                                            </Button>
                                                        }

                                                        {!test.isChecked &&
                                                            <Button
                                                                variant="contained"
                                                                disabled={this.state.isApproving}
                                                                startIcon={this.state.isApproving ? '' : <HighlightOffRoundedIcon style={{ color: 'white' }} />}
                                                                onClick={() => this.handleApproveTest(test._id, 'rejected', test)}
                                                                style={{ backgroundColor: '#be323e', color: 'white' }}
                                                            >
                                                                {this.state.isApproving ? <CircularProgress size={25} style={{ color: 'white' }} /> : "Rejected"}
                                                            </Button>
                                                        }

                                                        {test.isChecked &&
                                                            <Button
                                                                variant="contained"
                                                                disabled
                                                            >
                                                                Checked
                                                            </Button>
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    )) : (
                                    <Grid>
                                        <Skeleton variant="rect" width="100%" height={80} />
                                    </Grid>
                                )}
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

export default compose(
    withStyles(useStyles)
)(AssignedTestsHT);
