//React
import { Component } from 'react';
import { compose } from 'recompose';

//Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';

//Others libraries
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

//react-router-dom
import {
    Link
} from "react-router-dom";

const axios = require('axios').default;
const FormData = require('form-data');
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%'
    }
});

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 5
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 4
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 3
    }
};

class TestHistory extends Component {
    constructor() {
        super();
        this.state = {
        };
    }

    handleTestOperation = (operation) => {
        if (operation === 'GOT_TEST') {
            return 'Test received'
        }

        else if (operation === 'EXAMINED_TEST') {
            return 'Examined'
        }
    }

    handleFilterExpiredTest = (date) => {
        const oneHour = 60 * 60 * 1000;

        if (((new Date()) - new Date(date)) > oneHour) {
            return false;
        } else {
            return true;
        }
    }

    render() {
        const { classes } = this.props;

        return (
            <Grid container style={{ padding: '20px' }}>
                <Grid item align="left" xs={12}>
                    <Typography variant="h4" gutterBottom>
                        <Box fontWeight="fontWeightBold" display='inline' style={{ color: '#76323F' }}>My history</Box>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Carousel
                        responsive={responsive}
                        swipeable={true}
                        showDots={true}
                        containerClass="carousel-container"
                        itemClass="carousel-item"
                    >
                        {this.props.testHistory !== undefined &&
                            this.props.testHistory.length !== 0 &&
                            this.props.testHistory
                                .filter(test => !(test.isDone) && this.handleFilterExpiredTest(test.createdAt)).sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()).map((test, index) =>
                                    <Grid item style={{ margin: '5px' }}>
                                        <Card style={{ width: '220px', maxWidth: '220px', height: '300px', maxHeight: '300px' }}>
                                            <CardContent>
                                                <Grid container style={{ padding: '10px', paddingBottom: '0%' }}>
                                                    <Grid item xs={12}>
                                                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                                            Type
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography variant="h5" component="div">
                                                            <Box fontWeight="fontWeightBold" display='inline' style={{ color: '#76323F' }}>{test.test_type}</Box>
                                                        </Typography>
                                                        <Typography variant="subtitle2" component="div" style={{ color: '#76323F' }}>
                                                            {test.test_part}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography variant="caption" display="block" color="text.secondary">
                                                            {new Date(test.createdAt).toDateString()}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item align='center' xs={12} style={{ marginTop: '5%' }}>
                                                        <Paper elevation={0} style={{ backgroundColor: test.isDone ? 'green' : 'orange', padding: '5%', width: '50%' }}>
                                                            <Typography variant="caption" display="block" style={{ color: 'white' }}>
                                                                {test.isDone ? 'Examined' : 'In progress'}
                                                            </Typography>
                                                        </Paper>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                            <CardActions>
                                                <Grid container style={{ padding: '10px', paddingTop: '0%' }}>
                                                    <Grid item align='center' xs={12}>
                                                        <Link to={"/test/" + test.test_part.toLowerCase() + "?is_free=true&log_id=" + test.log_id} color="inherit" underline="none" style={{ textDecoration: 'none', color: '#565656' }}>
                                                            <Button
                                                                size="large"
                                                                onClick={this.handleChooseFreeTest}
                                                                disabled={test.isDone}
                                                                variant="contained"
                                                                style={{
                                                                    backgroundColor: test.isDone ? '' : '#76323F',
                                                                    color: test.isDone ? '' : 'white',
                                                                    padding: '5px',
                                                                    width: '100%'
                                                                }}
                                                            >
                                                                {test.isDone ? 'Done' : 'Continue'}
                                                            </Button>
                                                        </Link>
                                                    </Grid>
                                                    {test.test_operation === 'EXAMINED_TEST' &&
                                                        <Grid item align='center' xs={12} style={{ marginTop: '2%' }}>
                                                            <Link to={"/myprofile/report?test_id=" + test.test_id} color="inherit" underline="none" style={{ textDecoration: 'none', color: '#565656' }}>
                                                                <Button
                                                                    size="small"
                                                                    style={{
                                                                        backgroundColor: '#76323F',
                                                                        color: 'white',
                                                                        padding: '5px',
                                                                        width: '80%'
                                                                    }}
                                                                >
                                                                    My report
                                                                </Button>
                                                            </Link>
                                                        </Grid>
                                                    }
                                                    <Grid item align='center' xs={12}>
                                                        <Button
                                                            size="small"
                                                            onClick={() => { this.props.handleSelectReportProblem(test._id, 'testHistory') }}
                                                            style={{
                                                                padding: '5px',
                                                                width: '100%'
                                                            }}
                                                        >
                                                            <Typography variant="caption" display="block" color="text.secondary">
                                                                Report a problem
                                                            </Typography>
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </CardActions>
                                        </Card>
                                    </Grid>
                                )}
                    </Carousel>

                    {this.props.testHistory?.filter(test => !(test.isDone) && this.handleFilterExpiredTest(test.createdAt))
                        .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())?.length === 0 &&
                        <Grid item style={{ margin: '10px', maxWidth: '200px' }}>
                            <Card style={{ width: '200px', maxWidth: '200px', height: '250px', maxHeight: '250px' }}>
                                <CardContent>
                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom style={{ color: 'grey', paddingTop: '45%' }}>
                                        You don't have any test history
                                    </Typography>
                                    <Link to={"/free-course"} color="inherit" underline="none" style={{ textDecoration: 'none', color: '#565656' }}>
                                        <Button
                                            size="large"
                                            onClick={this.handleChooseFreeTest}
                                            style={{
                                                backgroundColor: '#76323F',
                                                color: 'white',
                                                padding: '5px',
                                                width: '100%'
                                            }}
                                        >
                                            Try Free Test
                                        </Button>
                                    </Link>
                                </CardContent>
                            </Card>
                        </Grid>
                    }

                    {this.props.testHistory === undefined &&
                        <Grid item style={{ width: '200px', maxWidth: '200px', height: '200px', maxHeight: '200px', marginRight: '10px' }}>
                            <Card style={{ width: '200px', maxWidth: '200px', height: '250px', maxHeight: '250px' }}>
                                <CardContent>
                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom style={{ marginTop: '10%' }}>
                                        <Skeleton />
                                    </Typography>
                                    <Typography variant="h5" component="div">
                                        <Skeleton />
                                    </Typography>
                                    <Typography variant="caption" display="block" color="text.secondary">
                                        <Skeleton />
                                    </Typography>
                                    <Skeleton variant="rect" height={40} style={{ marginTop: '15%' }} />
                                    <Skeleton variant="rect" height={20} style={{ marginTop: '5%' }} />
                                </CardContent>
                            </Card>
                        </Grid>
                    }
                </Grid>
            </Grid>
        )
    }
}

export default compose(
    withStyles(useStyles)
)(TestHistory);