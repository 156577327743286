import React from 'react';

import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import MuiAlert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';

//Others
import { withStyles } from '@material-ui/core/styles';
import { Component } from 'react';
import { compose } from 'recompose';
import MaterialTable from 'material-table'

//Icons
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

//Others
const axios = require('axios').default;
const FormData = require('form-data');
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

const useStyles = theme => ({
    root: {
        margin: 15,
        marginTop: 50
    }
});

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class AllParts extends Component {
    constructor() {
        super()
        this.state = {
            selectedPartType: 'reading',
            testsToShow: [],
            isLoadingParts: false,
            isConfirmDeletion: false
        };
    }

    componentDidMount = () => {
        this.handleGetAllSubmittedTests();
        document.querySelector("body").scrollTo(0, 0);
    }

    handleGetAllSubmittedTests = async () => {
        try {
            const readingToshow = [];
            const listeningToshow = [];
            const writingToshow = [];
            const bmatToshow = [];

            this.setState({
                isLoadingParts: true
            });

            const res = await axios({
                method: 'get',
                url: `${api_base_url}/api/test/part/all`,
                headers: {
                    "Authorization": "Bearer " + JSON.parse(localStorage.getItem('user')).token
                }
            });

            const resData = res.data;

            const readingArr = await resData.data.filter((part) => part.part_type === "READING");
            const listeningArr = await resData.data.filter((part) => part.part_type === "LISTENING");
            const writingArr = await resData.data.filter((part) => part.part_type === "WRITING");
            const bmatArr = await resData.data.filter((part) => part.part_type !== "READING" && part.part_type !== "LISTENING" && part.part_type !== "WRITING");

            await readingArr.map((part) => {
                readingToshow.push({
                    _id: part._id,
                    part: part.part,
                    part_type: part.part_type,
                    version: part.version
                });
            });

            await listeningArr.map((part) => {
                listeningToshow.push({
                    _id: part._id,
                    part: part.part,
                    part_type: part.part_type,
                    version: part.version
                });
            });

            await writingArr.map((part) => {
                writingToshow.push({
                    _id: part._id,
                    part: part.part,
                    part_type: part.part_type,
                    version: part.version
                });
            });

            await bmatArr.map((part) => {
                bmatToshow.push({
                    _id: part._id,
                    part: part.part,
                    part_type: part.part_type,
                    version: part.version
                });
            });

            this.setState({
                readingToshow: readingToshow,
                listeningToshow: listeningToshow,
                writingToshow: writingToshow,
                bmatToshow: bmatToshow,
                partInfo: resData,
                isLoadingParts: false
            });
        } catch (err) {
            console.log(err);
            this.setState({
                isLoadingParts: false
            });
        }
    }

    handleDeletePart = async () => {
        await axios({
            method: 'delete',
            url: `${api_base_url}/api/test/part/id/${this.state.selectedObj._id}`,
            headers: {
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem('user')).token
            }
        });

        this.handleGetAllSubmittedTests();
        this.handleCloseConfirmDeletion();
    }

    handleOpenConfirmDeletion = (selectedObj) => {
        this.setState({
            isConfirmDeletion: true,
            selectedObj: selectedObj
        });
    }

    handleCloseConfirmDeletion = () => {
        this.setState({
            isConfirmDeletion: false
        });
    }

    handleSelectTestType = (type) => {
        this.setState({
            selectedPartType: type
        })
    }

    render() {
        const { classes } = this.props;

        return (
            <Grid item xs={12} style={{ paddingLeft: '8px' }}>
                <Dialog
                    open={this.state.isConfirmDeletion}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{`Are you sure to remove section ${this.state.selectedObj?.version}?`}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Please click <span style={{ fontWeight: 'bold' }}>"Confirm"</span> to remove section <span style={{ fontWeight: 'bold' }}>{this.state.selectedObj?.version}</span> 
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseConfirmDeletion} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.handleDeletePart} color="primary" autoFocus>
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>

                <Grid container spacing={2}>
                    <Grid item align='left'>
                        <Typography variant="h6" style={{ padding: '2px', fontWeight: 'bold', paddingLeft: '10px' }}>
                            Select Section
                        </Typography>
                    </Grid>
                    <Grid item align='left'>
                        <Divider orientation='vertical' />
                    </Grid>
                    <Grid item align='left'>
                        <Button
                            variant={this.state.selectedPartType === 'reading' ? "contained" : "outlined"}
                            style={{
                                borderColor: this.state.selectedPartType === 'reading' ? '' : '#76323F',
                                color: this.state.selectedPartType === 'reading' ? 'white' : '#76323F',
                                backgroundColor: this.state.selectedPartType === 'reading' ? '#76323F' : ''
                            }}
                            onClick={() => { this.handleSelectTestType('reading') }}
                        >
                            READING
                        </Button>
                        <Button
                            variant={this.state.selectedPartType === 'listening' ? "contained" : "outlined"}
                            style={{
                                marginLeft: '10px',
                                borderColor: this.state.selectedPartType === 'listening' ? '' : '#76323F',
                                color: this.state.selectedPartType === 'listening' ? 'white' : '#76323F',
                                backgroundColor: this.state.selectedPartType === 'listening' ? '#76323F' : ''
                            }}
                            onClick={() => { this.handleSelectTestType('listening') }}
                        >
                            LISTENING
                        </Button>
                        <Button
                            variant={this.state.selectedPartType === 'writing' ? "contained" : "outlined"}
                            style={{
                                marginLeft: '10px',
                                borderColor: this.state.selectedPartType === 'writing' ? '' : '#76323F',
                                color: this.state.selectedPartType === 'writing' ? 'white' : '#76323F',
                                backgroundColor: this.state.selectedPartType === 'writing' ? '#76323F' : ''
                            }}
                            onClick={() => { this.handleSelectTestType('writing') }}
                        >
                            WRITING
                        </Button>
                        <Button
                            variant={this.state.selectedPartType === 'bmat' ? "contained" : "outlined"}
                            style={{
                                marginLeft: '10px',
                                borderColor: this.state.selectedPartType === 'bmat' ? '' : '#76323F',
                                color: this.state.selectedPartType === 'bmat' ? 'white' : '#76323F',
                                backgroundColor: this.state.selectedPartType === 'bmat' ? '#76323F' : ''
                            }}
                            onClick={() => { this.handleSelectTestType('bmat') }}
                        >
                            BMAT
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider style={{ height: '2px', backgroundColor: '#76323F' }} />
                    </Grid>
                    <Grid item xs={12}>
                        <MaterialTable
                            data={this.state[`${this.state.selectedPartType}Toshow`]}
                            columns={[
                                {
                                    title: 'Section id',
                                    field: '_id',
                                    editable: 'never'
                                },
                                {
                                    title: 'Section',
                                    field: 'part',
                                    editable: 'never'
                                },
                                {
                                    title: 'Type',
                                    field: 'part_type',
                                    editable: 'never'
                                },
                                {
                                    title: 'Version',
                                    field: 'version',
                                    editable: 'never',
                                    defaultSort: 'asc'
                                }
                            ]}
                            options={{
                                actionsColumnIndex: -1,
                                exportAllData: true,
                                exportButton: true,
                                grouping: true,
                                sorting: true,
                                pageSize: 10
                            }}
                            actions={this.state.selectedPartType === 'reading' || this.state.selectedPartType === 'listening' ? [
                                {
                                    icon: 'edit',
                                    tooltip: 'Edit Test',
                                    onClick: (event, rowData) => this.props.handleSelectPart(rowData._id)
                                },
                                {
                                    icon: 'delete',
                                    tooltip: 'Delete Test',
                                    onClick: (event, rowData) => this.handleOpenConfirmDeletion(rowData)
                                }

                            ] : false}
                            isLoading={this.state.isLoadingParts}
                            title='Sections'
                        />
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}
export default compose(
    withStyles(useStyles)
)(AllParts);