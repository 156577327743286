import React from 'react';

//Material UI
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import MuiAlert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

//Others
import { withStyles } from '@material-ui/core/styles';
import { Component } from 'react';
import { compose } from 'recompose';
import Select from 'react-select'

const useStyles = theme => ({
    root: {
        margin: 15,
        marginTop: 50
    },
    margin: {
        margin: theme.spacing(1),
    },
    buttonColor: {
        backgroundColor: '#76323F',
        color: 'white'
    }
});

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class SentenceCompletion extends Component {
    constructor() {
        super()
        this.state = {
            tempQuestionCount: [
                1
            ],
            questionCount: 1
        };
    }

    componentDidMount = async () => {

    }

    handleEditQuestionNumber = (event, index) => {
        this.props.questionObj.no = event.target.value
    }

    handleEditTitle = (event, index) => {
        this.props.questionObj.question = event.target.value

        console.log(this.props);
    }

    handleEditText = (event, index) => {
        this.props.questionObj.text = event.target.value
    }

    handleEditAnswer = (event, index) => {
        this.props.questionObj.answer = event.target.value
    }

    render() {
        const { classes } = this.props;

        return (
            <Grid item xs={12}>
                <Grid container spacing={1}>
                    {this.state.tempQuestionCount.map((question, index) =>
                        <Grid item key={'question' + index} xs={12}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                                                Question number
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                id="outlined-size-small"
                                                variant="outlined"
                                                size="small"
                                                onChange={(event) => { this.handleEditQuestionNumber(event, index) }}
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                                                Title
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                id="outlined-size-small"
                                                variant="outlined"
                                                size="small"
                                                onChange={(event) => { this.handleEditTitle(event, index) }}
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                                                Context
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                id="outlined-size-small"
                                                variant="outlined"
                                                size="small"
                                                onChange={(event) => { this.handleEditText(event, index) }}
                                                fullWidth
                                                multiline
                                                maxRows={6}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                                                Answer
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                id="outlined-size-small"
                                                variant="outlined"
                                                size="small"
                                                onChange={(event) => { this.handleEditAnswer(event, index) }}
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} style={{ marginTop: '10px' }}>
                                    <Divider style={{ backgroundColor: "#76323F", height: '5px' }} />
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        )
    }
}
export default compose(
    withStyles(useStyles)
)(SentenceCompletion);