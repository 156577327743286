import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

//MUI
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import {
  ThemeProvider,
  createMuiTheme,
  withStyles,
} from "@material-ui/core/styles";
import { compose } from "recompose";
import { GetCharge } from "./services/omise";
import { Button, DialogActions, Grid, Typography } from "@material-ui/core";

const useStyles = (theme) => ({
  root: {
    padding: theme.spacing(12, 4),
  },
});

const theme = createMuiTheme({
  typography: {
    fontFamily: ["Roboto"].join(","),
  },
});

const PromptPayPage = (props) => {
  useEffect(() => {
    document.querySelector("body").scrollTo(0, 0);
    checkChargeStatus(props.chargeId);
  }, []);

  const checkChargeStatus = (id) => {
    const intervalTime = 5000; // Time in milliseconds (5000ms = 5 seconds)

    const intervalId = setInterval(async () => {
      const res = await GetCharge(id);
      if (!res.success) {
        clearInterval(intervalId);
      }
      if (res.res.status === "successful") {
        props.handleOpenLoading();
        props.donePromPayCharge(res.res);
        clearInterval(intervalId);
      }
    }, intervalTime);
  };

  const [timeLeft, setTimeLeft] = useState(15 * 60); // 15 minutes in seconds

  useEffect(() => {
    if (timeLeft <= 0) return;

    const intervalId = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        maxWidth="md"
        open={props.isOpenQrCode}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle
          id="max-width-dialog-title"
        >
          <Grid container alignItems="center">
            <Grid item align="left" xs>
              Scan to pay
            </Grid>
            <Grid item align="right" xs>
              <IconButton
                aria-label="close"
                onClick={props.onClosePromptPay}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Scan the QR code with your payment app to make quick and secure
            payments instantly!
          </DialogContentText>
          <Grid
            xs={12}
            align="center"
            style={{ paddingBottom: 20, color: "silver" }}
          >
            {timeLeft === 0 ? (
              <>
                <Typography variant="body1">
                  Your payment session has expired.
                </Typography>
                <Typography variant="body1">
                  Please return to the payment page to continue.
                </Typography>
              </>
            ) : (
              <img
                id="myImage"
                src={props.qrImage}
                alt="Base64 Image"
                width="400"
              />
            )}
          </Grid>
          {timeLeft != 0 && (
            <Grid item align="center" xs={12} style={{ paddingBottom: 20 }}>
              <div>
                <Typography variant="h6">
                  Please complete your payment within 15 minutes
                </Typography>
                <Typography variant="h4">{formatTime(timeLeft)}</Typography>
              </div>
            </Grid>
          )}
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
};

export default compose(withStyles(useStyles))(PromptPayPage);
