//React
import { Component } from 'react';
import { compose } from 'recompose';

//Material UI
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccountBoxRoundedIcon from '@material-ui/icons/AccountBoxRounded';
import AccessAlarmRoundedIcon from '@material-ui/icons/AccessAlarmRounded';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Input from '@material-ui/core/Input';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

//import components


const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%'
    }
});

class ChooseTwoLetters extends Component {
    constructor() {
        super();

        this.state = {
            answerArr: [],
            answerArrToCheck: [],
            isError: false
        };
    }

    render() {
        const { classes } = this.props;

        return (
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h4" gutterBottom align='left'>
                        <Box fontWeight="fontWeightBold" display='inline'>{this.props.currentTestObject.section}</Box>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle2" align='left'>
                        Choose <Box fontWeight="fontWeightBold" display='inline'>TWO</Box> correct answers.
                    </Typography>
                </Grid>
                {this.props.currentTestObject.questions.map(q =>
                    <Grid item xs={12} align='left'>
                        <Typography variant="subtitle1" align='left' style={{ marginTop: '20px', fontSize: '1.2rem' }}>
                            {q.question}
                        </Typography>
                        <FormControl required component="fieldset" >
                            <FormGroup>
                                {q.choice.map(choice =>
                                    <FormControlLabel
                                        disabled
                                        control={<Checkbox checked={!!this.state.answerArr.find(ans => ans === choice.split(' ')[0])} name={choice} />}
                                        label={<Typography variant="subtitle2" gutterBottom>{choice}</Typography>}
                                    />
                                )}
                            </FormGroup>
                        </FormControl>

                    </Grid>
                )}
            </Grid>
        )
    }
}

export default compose(
    withStyles(useStyles)
)(ChooseTwoLetters);