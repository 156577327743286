import React from 'react';
import ReactDOM from 'react-dom';

//Material UI
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import MuiAlert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

//Components
import LoadingBackdrop from '../../public/components/LoadingBackdrop';

//Question type reading
import YesNoNG from './questionTypes/reading/YesNoNG';
import TrueFalseNG from './questionTypes/reading/TrueFalseNG';
import ListOfHeading from './questionTypes/reading/ListOfHeading';
import MultipleChoice from './questionTypes/shared/MultipleChoice';
import TextEditor from './questionTypes/reading/TextEditor';

//Question type listening
import Map from './questionTypes/listening/Map';
import Choose2Letters from './questionTypes/listening/Choose2Letters';
import CompleteTheTables from './questionTypes/listening/CompleteTheTables';

//Question type writing
import WritingBaseQuestion from './questionTypes/writing/WritingBaseQuestion';

//Question type shared
import NoteCompletion from './questionTypes/shared/NoteCompletion';
import MatchingSentenceEndings from './questionTypes/shared/MatchingSentenceEndings';

//Icons
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

//Contants
import * as apiConstants from '../../../../constants/apiConstants';

//Others
import { withStyles } from '@material-ui/core/styles';
import { Component } from 'react';
import { compose } from 'recompose';
import ReactAudioPlayer from 'react-audio-player';
import { Link } from "react-router-dom";

const axios = require('axios').default;
const FormData = require('form-data');
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

const useStyles = theme => ({
    root: {
        margin: 15,
        marginTop: 50
    },
    margin: {
        margin: theme.spacing(1),
    },
    buttonColor: {
        backgroundColor: '#76323F',
        color: 'white'
    },
    heading: {
        fontWeight: 'bold'
    },
});

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class EditPart extends Component {
    constructor() {
        super()
        this.state = {
            addedQuestionArr: [],
            sectionArr: [],
            isEdit: false,
            isLoading: false,
            isLoadingPart: false,
            selectedParagraph: false,
            isFree: false,
            selectedSectionId: ''
        };
        this.onChange = editorState => this.setState({ editorState });
    }

    componentDidMount = async () => {
        await this.handleGetPart();
        document.querySelector("body").scrollTo(0, 0);
    }

    //==================================== PREPARATION =======================================
    handleGetPart = async () => {
        if (this.props.match.params.part_id !== undefined) {
            try {
                this.handleLoadingTest();

                const res = await axios({
                    method: 'get',
                    url: `${api_base_url}/api/test/part/id/${this.props.match.params.part_id}`,
                    headers: {
                        "Authorization": "Bearer " + JSON.parse(localStorage.getItem('user')).token
                    }
                });

                const resData = res.data.part;

                console.log(resData);

                resData.sections.map((section) => {
                    this.handleGetSections(section);
                })

                this.setState({
                    partObj: resData
                });

                this.handleEndLoadingTest();
            } catch (err) {
                console.log(err);
                this.handleEndLoadingTest();
            }
        }
    }

    handleGetSections = async (sectionId) => {
        try {
            const secObj = await axios({
                method: 'get',
                url: `${api_base_url}/api/test/section/id/${sectionId}`,
                headers: {
                    'content-type': 'application/json',
                    "Authorization": "Bearer " + JSON.parse(localStorage.getItem('user')).token
                }
            });

            this.state.sectionArr.push(secObj.data.section);

            this.setState({
                sectionArr: this.state.sectionArr
            });
        } catch (err) {
            console.log(err)
        }
    }

    handleLoadingTest = () => {
        this.setState({
            isLoadingPart: true
        });
    }

    handleEndLoadingTest = () => {
        this.setState({
            isLoadingPart: false
        });
    }
    //==================================== PREPARATION =======================================

    handleAddQuestionUI = (sectionObj) => {
        //================================== READING ==================================
        if (sectionObj.question_type === 'yesNoNG') {
            return (<YesNoNG key={sectionObj.section_id} selectedSectionId={this.state.selectedSectionId} sectionId={sectionObj.section_id} handleEndEditSection={this.handleEndEditSection} handleCreateEditTestHistory={this.handleCreateEditTestHistory} />)
        }

        else if (sectionObj.question_type === 'trueFalseNG') {
            return (<TrueFalseNG key={sectionObj.section_id} selectedSectionId={this.state.selectedSectionId} sectionId={sectionObj.section_id} handleEndEditSection={this.handleEndEditSection} handleCreateEditTestHistory={this.handleCreateEditTestHistory} />)
        }

        else if (sectionObj.question_type === 'multipleChoice') {
            return (<MultipleChoice key={sectionObj.section_id} selectedSectionId={this.state.selectedSectionId} sectionId={sectionObj.section_id} handleEndEditSection={this.handleEndEditSection} handleCreateEditTestHistory={this.handleCreateEditTestHistory} />)
        }

        else if (sectionObj.question_type === 'matchingSentenceEndings' || sectionObj.question_type === 'matching' || sectionObj.question_type === 'matchingFeatures') {
            return (<MatchingSentenceEndings key={sectionObj.section_id} selectedSectionId={this.state.selectedSectionId} sectionId={sectionObj.section_id} handleEndEditSection={this.handleEndEditSection} handleCreateEditTestHistory={this.handleCreateEditTestHistory} />)
        }

        else if (sectionObj.question_type === 'listOfHeading') {
            return (<ListOfHeading key={sectionObj.section_id} selectedSectionId={this.state.selectedSectionId} sectionId={sectionObj.section_id} handleEndEditSection={this.handleEndEditSection} handleCreateEditTestHistory={this.handleCreateEditTestHistory} />)
        }

        else if (sectionObj.question_type === 'completeTheNotes' || sectionObj.question_type === 'noteCompletion') {
            return (<NoteCompletion key={sectionObj.section_id} selectedSectionId={this.state.selectedSectionId} sectionId={sectionObj.section_id} handleEndEditSection={this.handleEndEditSection} handleCreateEditTestHistory={this.handleCreateEditTestHistory} />)
        }
        //================================== READING ==================================

        //================================== LISTENING ==================================
        else if (sectionObj.question_type === 'completeTheTables') {
            return (<NoteCompletion key={sectionObj.section_id} selectedSectionId={this.state.selectedSectionId} sectionId={sectionObj.section_id} handleEndEditSection={this.handleEndEditSection} handleCreateEditTestHistory={this.handleCreateEditTestHistory} />)
        }

        else if (sectionObj.question_type === 'choose2Letters') {
            return (<Choose2Letters key={sectionObj.section_id} selectedSectionId={this.state.selectedSectionId} sectionId={sectionObj.section_id} handleEndEditSection={this.handleEndEditSection} handleCreateEditTestHistory={this.handleCreateEditTestHistory} />)
        }

        else if (sectionObj.question_type === 'map') {
            return (<Map key={sectionObj.section_id} selectedSectionId={this.state.selectedSectionId} sectionId={sectionObj.section_id} handleEndEditSection={this.handleEndEditSection} handleCreateEditTestHistory={this.handleCreateEditTestHistory} />)
        }
        //================================== LISTENING ==================================

        //================================== WRITING ==================================
        else if (sectionObj.question_type === 'writingBaseQuestion') {
            return (<WritingBaseQuestion key={sectionObj.section_id} selectedSectionId={this.state.selectedSectionId} sectionId={sectionObj.section_id} handleEndEditSection={this.handleEndEditSection} handleCreateEditTestHistory={this.handleCreateEditTestHistory} />)
        }
        //================================== WRITING ==================================

    }

    //=========================== EDIT TEST HISTORY ======================================
    handleCreateEditTestHistory = async (oldObj, newObj, sectionId) => {
        try {
            const res = await axios({
                method: 'post',
                url: apiConstants.TEST_MANAGEMENT_API_BASE_URL + '/test/edit/history',
                data: {
                    test_id: this.state.testObj.test_id,
                    part_id: this.state.selectedPart,
                    section_id: sectionId,
                    updateFrom: oldObj,
                    updateTo: newObj,
                    updateBy: {
                        name: JSON.parse(localStorage.getItem('user')).name,
                        username: JSON.parse(localStorage.getItem('user')).username
                    }
                }
            });

        } catch (err) {
            console.log(err);
        }
    }
    //=========================== EDIT TEST HISTORY ======================================

    //=========================== EDIT PARAGRAPH =========================================
    handleUpdateParagraph = async () => {
        try {
            this.handleLoadingTest();

            console.log(this.state.sectionArr);

            await axios({
                method: 'put',
                url: `${api_base_url}/api/test/part`,
                headers: {
                    "Authorization": "Bearer " + JSON.parse(localStorage.getItem('user')).token
                },
                data: {
                    _id: this.state.partObj._id,
                    title: this.state.partObj.title,
                    description: this.state.partObj.description,
                    sections: this.state.partObj.sections,
                    part: this.state.partObj.part,
                    part_type: this.state.partObj.part_type,
                    version: this.state.partObj.version,
                }
            });

            this.handleEndEditParagraph();
            this.handleEndLoadingTest();
        } catch (err) {
            console.log(err);
            this.handleEndLoadingTest();
        }
    }

    handleIsEdit = () => {
        this.setState({
            isEdit: true
        });
    }

    handleEndEdit = () => {
        this.setState({
            isEdit: false
        });
    }

    handleClickEditSection = (sectionId, part) => {
        this.setState({
            selectedSectionId: sectionId,
            selectedPart: part
        });
    }

    handleEndEditSection = () => {
        this.setState({
            selectedSectionId: ''
        });
    }

    handleClickEditParagraph = () => {
        this.setState({
            selectedParagraph: true
        });
    }

    handleEndEditParagraph = () => {
        this.setState({
            selectedParagraph: false
        });
    }

    handleChangeEditorState = (event) => {
        console.log(event.target.value);

        this.setState({
            editorState: event.target.value
        });
    }

    render() {
        const { classes } = this.props;

        return (
            <Grid item xs={12}>
                <LoadingBackdrop isLoading={this.state.isLoadingPart} />
                
                <Grid container spacing={2} style={{ padding: '100px' }}>
                    <Grid item align='left' xs={12}>
                        <Typography variant="h4" style={{ fontWeight: 'bold' }} gutterBottom>
                            Edit section
                        </Typography>
                    </Grid>
                    <Grid item align='right' xs={12}>
                        <Link to={`/dashboard/test-admin/tests/editPart/parts/${this.state.partObj?._id}/preview`} style={{ textDecoration: 'none', color: '#0f0f0f' }}>
                            <Button
                                variant="outlined"
                                size="medium"
                                color="primary"
                                style={{ marginBottom: '10px', borderColor: '#76323F', color: '#76323F', fontWeight: 'bold' }}
                            >
                                Preview this section
                            </Button>
                        </Link>
                    </Grid>

                    {/* =========================================== INFORMATION =========================================== */}
                    <Grid item align='left' xs={12}>
                        <Typography variant="h5" style={{ fontWeight: 'bold' }} gutterBottom>
                            Section ID
                        </Typography>
                        <TextField
                            disabled={!this.state.isEdit}
                            id="outlined-basic-title"
                            variant="outlined"
                            size="small"
                            value={this.state.partObj === undefined ? '' : this.state.partObj._id}
                            fullWidth
                        />
                    </Grid>
                    <Grid item align='left' xs={12}>
                        <Typography variant="h5" style={{ fontWeight: 'bold' }} gutterBottom>
                            Title
                        </Typography>
                        <TextField
                            disabled={!this.state.isEdit}
                            id="outlined-basic-title"
                            variant="outlined"
                            size="small"
                            value={this.state.partObj === undefined ? '' : this.state.partObj.title}
                            fullWidth
                        />
                    </Grid>
                    <Grid item align='left' xs={12}>
                        <Typography variant="h5" style={{ fontWeight: 'bold' }} gutterBottom>
                            Type
                        </Typography>
                        <TextField
                            disabled={!this.state.isEdit}
                            id="outlined-basic-title"
                            variant="outlined"
                            size="small"
                            value={this.state.partObj === undefined ? '' : this.state.partObj.part_type}
                            fullWidth
                        />
                    </Grid>
                    <Grid item align='left' xs={12}>
                        <Typography variant="h5" style={{ fontWeight: 'bold' }} gutterBottom>
                            Version
                        </Typography>
                        <TextField
                            disabled={!this.state.isEdit}
                            id="outlined-basic-title"
                            variant="outlined"
                            size="small"
                            value={this.state.partObj === undefined ? '' : this.state.partObj.version}
                            fullWidth
                        />
                    </Grid>
                    {/* =========================================== INFORMATION =========================================== */}

                    {/* =========================================== READING =========================================== */}
                    {this.state.partObj?.part_type === 'READING' &&
                        <Grid item align='left' xs={12}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography variant="h5" style={{ fontWeight: 'bold' }} gutterBottom>
                                        Reading
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Grid item xs={12} style={{ marginTop: '20px' }}>
                                        <Typography className={classes.heading} gutterBottom>Paragraph</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item align='right' xs={12}>
                                                <Button
                                                    disabled={this.state.selectedParagraph}
                                                    variant="contained"
                                                    size="medium"
                                                    color="primary"
                                                    style={{ marginBottom: '10px', backgroundColor: this.state.selectedParagraph ? '' : '#76323F' }}
                                                    onClick={this.handleClickEditParagraph}
                                                >
                                                    Edit
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextEditor selectedParagraph={this.state.selectedParagraph} paragraphIndex={true} description={this.state.partObj.description} partObj={this.state.partObj} />
                                            </Grid>
                                            {this.state.selectedParagraph &&
                                                <Grid item align='right' xs={12} style={{ marginTop: '10px' }}>
                                                    <Button
                                                        //disabled={true}
                                                        variant="contained"
                                                        size="medium"
                                                        color="primary"
                                                        style={{ marginBottom: '10px', backgroundColor: '#76323F' }}
                                                        onClick={this.handleUpdateParagraph}
                                                    >
                                                        Update
                                                    </Button>
                                                    <Button
                                                        variant="contained"
                                                        size="medium"
                                                        color="primary"
                                                        style={{ marginBottom: '10px', marginLeft: '5px', backgroundColor: 'grey' }}
                                                        onClick={this.handleEndEditParagraph}
                                                    >
                                                        Cancel
                                                    </Button>
                                                </Grid>
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} style={{ marginTop: '20px' }}>
                                    <Typography className={classes.heading} gutterBottom>Sections</Typography>
                                </Grid>

                                {this.state.sectionArr && this.state.sectionArr.map(section =>
                                    <Grid key={section.section_id} item xs={12}>
                                        <Accordion>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon style={{ color: '#76323f' }} />}
                                                aria-controls="panel1a-content"
                                                id={"panel1a-header-" + section.section_id}
                                                style={{ borderTop: '5px solid rgb(118,50,63)', color: '#76323f' }}
                                            >
                                                <Typography className={classes.heading}>{section.section}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid container spacing={2}>
                                                    <Grid item align='right' xs={12}>
                                                        <Link to={`/dashboard/test-admin/tests/editPart/parts/${this.state.partObj?._id}/sections/${section.section_id}/history`} style={{ textDecoration: 'none', color: '#0f0f0f' }}>
                                                            <Button
                                                                variant="contained"
                                                                size="medium"
                                                                color="primary"
                                                                style={{ marginBottom: '10px', backgroundColor: '#76323F' }}
                                                            >
                                                                History
                                                            </Button>
                                                        </Link>
                                                        <Button
                                                            disabled={(this.state.selectedSectionId === section.section_id)}
                                                            variant="contained"
                                                            size="medium"
                                                            color="primary"
                                                            style={{ marginLeft: '5px', marginBottom: '10px', backgroundColor: (this.state.selectedSectionId === section.section_id) ? '' : '#76323F' }}
                                                            onClick={() => { this.handleClickEditSection(section.section_id, this.state.partObj.part) }}
                                                        >
                                                            Edit
                                                        </Button>
                                                    </Grid>
                                                    <Grid item align='left' xs={12}>
                                                        <Typography variant="subtitle2" style={{ fontWeight: 'bold' }} gutterBottom>
                                                            Section id
                                                        </Typography>
                                                        <TextField
                                                            disabled
                                                            id="outlined-basic-title"
                                                            variant="outlined"
                                                            size="small"
                                                            value={section.section_id}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    {this.handleAddQuestionUI(section)}
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    }
                    {/* =========================================== READING =========================================== */}

                    {/* =========================================== LISTENING =========================================== */}
                    {this.state.partObj?.part_type === 'LISTENING' &&
                        <Grid item align='left' xs={12}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography variant="h5" style={{ fontWeight: 'bold' }} gutterBottom>
                                        Listening
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        disabled
                                        id="outlined-basic-title"
                                        variant="outlined"
                                        size="small"
                                        label="Part description"
                                        value={this.state.partObj.description}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle1" style={{ fontWeight: 'bold' }} gutterBottom>
                                        Voice
                                    </Typography>
                                    <ReactAudioPlayer
                                        src={this.state.sectionArr[0]?.questions[0]?.voice}
                                        controls
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle1" style={{ fontWeight: 'bold' }} gutterBottom>
                                        Sections
                                    </Typography>
                                </Grid>
                                {this.state.sectionArr && this.state.sectionArr.map(section =>
                                    <Grid key={section.section_id} item xs={12}>
                                        <Accordion>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon style={{ color: '#76323f' }} />}
                                                aria-controls="panel1a-content"
                                                id={"panel1a-header-" + section.section_id}
                                                style={{ borderTop: '5px solid rgb(118,50,63)', color: '#76323f' }}
                                            >
                                                <Typography className={classes.heading}>{section.section}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid container spacing={2}>
                                                    <Grid item align='right' xs={12}>
                                                        <Link to={`/dashboard/test-admin/tests/editPart/parts/${this.state.partObj?._id}/sections/${section.section_id}/history`} style={{ textDecoration: 'none', color: '#0f0f0f' }}>
                                                            <Button
                                                                variant="contained"
                                                                size="medium"
                                                                color="primary"
                                                                style={{ marginBottom: '10px', backgroundColor: '#76323F' }}
                                                                onClick={() => { this.props.handleSelectSectionHistory(section.section_id) }}
                                                            >
                                                                History
                                                            </Button>
                                                        </Link>
                                                        <Button
                                                            disabled={(this.state.selectedSectionId === section.section_id)}
                                                            variant="contained"
                                                            size="medium"
                                                            color="primary"
                                                            style={{ marginLeft: '5px', marginBottom: '10px', backgroundColor: (this.state.selectedSectionId === section.section_id) ? '' : '#76323F' }}
                                                            onClick={() => { this.handleClickEditSection(section.section_id, this.state.partObj.part) }}
                                                        >
                                                            Edit
                                                        </Button>
                                                    </Grid>
                                                    <Grid item align='left' xs={12}>
                                                        <Typography variant="subtitle2" style={{ fontWeight: 'bold' }} gutterBottom>
                                                            Section id
                                                        </Typography>
                                                        <TextField
                                                            disabled
                                                            id="outlined-basic-title"
                                                            variant="outlined"
                                                            size="small"
                                                            value={section.section_id}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    {this.handleAddQuestionUI(section)}
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    }
                    {/* =========================================== LISTENING =========================================== */}

                    {this.state.isEdit &&
                        <Grid item align='left' xs={12} style={{ marginTop: '50px' }}>
                            <Button variant="contained" size="medium" color="primary" style={{ marginRight: '5px', backgroundColor: '#76323F' }}>
                                Confirm
                            </Button>
                            <Button variant="contained" size="medium" color="default" onClick={this.handleEndEdit} style={{ marginLeft: '5px', color: 'grey' }}>
                                Cancel
                            </Button>
                        </Grid>
                    }

                    {!this.state.isEdit &&
                        <Grid item align='center' xs={12} style={{ marginTop: '50px' }}>
                            <Link to={`/dashboard/test-admin?from=part`} style={{ textDecoration: 'none', color: '#0f0f0f' }}>
                                <Button variant="contained" size="medium" color="default" style={{ marginLeft: '5px', color: 'grey' }}>
                                    Back
                                </Button>
                            </Link>
                        </Grid>
                    }
                </Grid>
            </Grid >
        )
    }
}
export default compose(
    withStyles(useStyles)
)(EditPart);