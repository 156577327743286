//React
import { Component } from 'react';
import { compose } from 'recompose';

//Material UI
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import Skeleton from '@material-ui/lab/Skeleton';

import moment from 'moment';

//Others
const axios = require('axios').default;
const FormData = require('form-data');
const FileDownload = require('js-file-download');

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%'
    }
});

class Refunds extends Component {
    constructor() {
        super();
        this.state = {
            numberOfTest: 0,
            selectedMenu: 'assignedTest',
            filterType: 'all',
            filteredTestStatus: 'all',
            today: new Date(Date.now()),
            isApproving: false

        };
    }

    componentDidMount() {
        this.handleGetAllRefunds();
        this.handleGetAllRetest();
    }

    handleGetAllRefunds = async () => {
        try {
            const res = await axios({
                method: 'get',
                url: 'https://steep-legend-midnight.glitch.me/refunds',
            });

            const resData = res.data;
            this.setState({
                refunds: resData.data
            });

        } catch (err) {
            console.log(err);
            this.setState({
                refunds: []
            });
        }
    }

    handleGetAllRetest = async () => {
        try {
            const res = await axios({
                method: 'get',
                url: 'https://steep-legend-midnight.glitch.me/reactivate-test',
            });

            const resData = res.data;
            this.setState({
                retests: resData.data
            });

        } catch (err) {
            console.log(err);
            this.setState({
                retests: []
            });
        }
    }

    handleUpdateReTestStatus = async (id, status, obj) => {
        this.setState({
            isApproving: true
        });

        await axios({
            method: 'patch',
            url: `https://steep-legend-midnight.glitch.me/reactivate-test/${id}`,
            data: {
                status: status,
            }
        });

        if (status === "Approved") {
            const res = await axios({
                method: 'put',
                url: `https://e-learning-be-ybcs6wa7da-as.a.run.app/api/test/test-purchase-status?test_id=${obj.test_obj._id}&test_status=false`,
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem('user')).token}`
                }
            });
        }

        await this.handleGetAllRetest();

        this.setState({
            isApproving: false
        });
    }

    handleUpdateStatus = async (id, amount, chargeId, status) => {
        try {
            this.setState({
                isApproving: true
            });

            await axios({
                method: 'patch',
                url: 'https://almond-magnetic-sale.glitch.me/charges/refunds/' + id + '/status',
                data: {
                    status: status,
                    amount: amount,
                    chargeId: chargeId,
                    approverName: JSON.parse(localStorage.getItem('user')).name,
                    approverUsername: JSON.parse(localStorage.getItem('user')).username,
                }
            });

            await this.handleGetAllRefunds();

            this.setState({
                isApproving: false
            });

        } catch (err) {
            console.log(err);
        }
    }

    //================== FILTER RELATED ======================
    handleChipClick = (type) => {
        this.setState({
            filterType: type
        });
    }

    handleSelectFilterTestStatus = (event) => {
        if (event === null) {
            this.setState({
                filteredTestStatus: 'all'
            });
        } else if (event !== null) {
            this.setState({
                filteredTestStatus: event.value
            });
        }
    }
    //================== FILTER RELATED ======================

    handleColorChip = (tag) => {
        if (tag === 'Full') {
            return <Chip label={tag} style={{ margin: '5px', backgroundColor: '#76323F', color: 'white' }} />;
        } else if (tag === 'IELTS') {
            return <Chip label={tag} style={{ margin: '5px', backgroundColor: '#141951', color: 'white' }} />
        } else {
            return <Chip label={tag} style={{ margin: '5px' }} />
        }
    }

    render() {
        const { classes } = this.props;

        return (
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography align='left' variant="h5" gutterBottom>
                            <Box fontWeight="fontWeightBold" display='inline'>Refunds</Box>
                        </Typography>
                        <Paper variant="outlined" style={{ overflowY: 'auto', maxHeight: '800px' }}>
                            {this.state.refunds !== undefined ?
                                (this.state.refunds.length === 0 ? <Typography variant="body1" style={{ padding: "20px" }}>
                                    Empty
                                    </Typography> : this.state.refunds.sort((a, b) => new Date(a.createdAt) < new Date(b.createdAt) ? 1 : -1)
                                        .map((refunds, index) =>
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Grid container spacing={0}>
                                                        <Grid align='left' item xs={12} sm>
                                                            <Typography>{refunds.purchasedId}</Typography>
                                                        </Grid>
                                                        <Chip
                                                            label={refunds.isChecked ? 'Checked' : 'Waiting'}
                                                            style={{
                                                                margin: '5px',
                                                                backgroundColor: refunds.isChecked ? '#d4d4d4' : 'orange',
                                                                color: 'white'
                                                            }}
                                                        />
                                                        {refunds.status === "Rejected" && <Chip
                                                            label="Rejected"
                                                            style={{
                                                                margin: '5px',
                                                                backgroundColor: '#be323e',
                                                                color: 'white'
                                                            }}
                                                        />
                                                        }
                                                        {refunds.status === "Approved" && <Chip
                                                            label="Approved"
                                                            style={{
                                                                margin: '5px',
                                                                backgroundColor: 'green',
                                                                color: 'white'
                                                            }}
                                                        />
                                                        }
                                                    </Grid>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Grid container spacing={2}>
                                                        <Grid align='left' item xs={12} style={{ paddingLeft: '50px' }}>
                                                            <Typography gutterBottom>
                                                                <Box fontWeight="fontWeightBold" display='inline'>Purchased id:</Box> {refunds.purchasedId}
                                                            </Typography>
                                                            <Typography gutterBottom>
                                                                <Box fontWeight="fontWeightBold" display='inline'>Charge id:</Box> {refunds.purchasedChargeId}
                                                            </Typography>
                                                            <Typography gutterBottom>
                                                                <Box fontWeight="fontWeightBold" display='inline'>Requester username:</Box> {refunds.purchasedUsername}
                                                            </Typography>
                                                            <Typography gutterBottom>
                                                                <Box fontWeight="fontWeightBold" display='inline'>Reason:</Box> {refunds.reason}
                                                            </Typography>
                                                            <Typography gutterBottom>
                                                                <Box fontWeight="fontWeightBold" display='inline'>Amount:</Box> {refunds.purchasedChargeAmount}
                                                            </Typography>
                                                            <Typography gutterBottom>
                                                                <Box fontWeight="fontWeightBold" display='inline'>Status:</Box> {refunds.status}
                                                            </Typography>
                                                            <Typography gutterBottom>
                                                                <Box fontWeight="fontWeightBold" display='inline'>Created at:</Box> {refunds.createdAt}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid align='right' item xs={12}>
                                                            {!refunds.isChecked &&
                                                                <Button
                                                                    variant="contained"
                                                                    disabled={this.state.isApproving}
                                                                    startIcon={this.state.isApproving ? '' : <CheckCircleOutlineRoundedIcon style={{ color: 'white' }} />}
                                                                    onClick={() => this.handleUpdateStatus(refunds._id, refunds.purchasedChargeAmount, refunds.purchasedChargeId, 'Approved')}
                                                                    style={{ backgroundColor: 'green', color: 'white' }}
                                                                >
                                                                    {this.state.isApproving ? <CircularProgress size={25} style={{ color: 'white' }} /> : "Approve"}
                                                                </Button>
                                                            }
                                                            {!refunds.isChecked &&
                                                                <Button
                                                                    variant="contained"
                                                                    disabled={this.state.isApproving}
                                                                    startIcon={this.state.isApproving ? '' : <HighlightOffRoundedIcon style={{ color: 'white' }} />}
                                                                    onClick={() => this.handleUpdateStatus(refunds._id, refunds.purchasedChargeAmount, refunds.purchasedChargeId, 'Rejected')}
                                                                    style={{ backgroundColor: '#be323e', color: 'white', marginLeft: '10px' }}
                                                                >
                                                                    {this.state.isApproving ? <CircularProgress size={25} style={{ color: 'white' }} /> : "Reject"}
                                                                </Button>
                                                            }

                                                            {refunds.status === 'Approved' &&
                                                                <Button
                                                                    variant="contained"
                                                                    disabled
                                                                >
                                                                    Approved
                                                            </Button>
                                                            }

                                                            {refunds.status === 'Rejected' &&
                                                                <Button
                                                                    variant="contained"
                                                                    disabled
                                                                >
                                                                    Rejected
                                                            </Button>
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        )) : (
                                    <Grid>
                                        <Skeleton variant="rect" width="100%" height={80} />
                                    </Grid>
                                )}
                        </Paper>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: "30px" }}>
                        <Typography align='left' variant="h5" gutterBottom>
                            <Box fontWeight="fontWeightBold" display='inline'>Reactivate requests</Box>
                        </Typography>
                        <Paper variant="outlined" style={{ overflowY: 'auto', maxHeight: '800px' }}>
                            {this.state.retests ?
                                (this.state.retests.length === 0 ? <Typography variant="body1" style={{ padding: "20px" }}>
                                    Empty
                                    </Typography> : this.state.retests?.sort((a, b) => new Date(a.createdAt) < new Date(b.createdAt) ? 1 : -1)
                                        .map((retest, index) =>
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Grid container spacing={0}>
                                                        <Grid align='left' item xs={12} sm>
                                                            <Typography>{retest.test_obj.test_type} ({retest.test_obj._id})</Typography>
                                                        </Grid>
                                                        <Chip
                                                            label={retest.isChecked ? 'Checked' : 'Waiting'}
                                                            style={{
                                                                margin: '5px',
                                                                backgroundColor: retest.isChecked ? '#d4d4d4' : 'orange',
                                                                color: 'white'
                                                            }}
                                                        />
                                                        {retest.status === "Rejected" && <Chip
                                                            label="Rejected"
                                                            style={{
                                                                margin: '5px',
                                                                backgroundColor: '#be323e',
                                                                color: 'white'
                                                            }}
                                                        />
                                                        }
                                                        {retest.status === "Approved" && <Chip
                                                            label="Approved"
                                                            style={{
                                                                margin: '5px',
                                                                backgroundColor: 'green',
                                                                color: 'white'
                                                            }}
                                                        />
                                                        }
                                                    </Grid>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Grid container spacing={2}>
                                                        <Grid align='left' item xs={12} style={{ paddingLeft: '50px' }}>
                                                            <Typography gutterBottom>
                                                                <Box fontWeight="fontWeightBold" display='inline'>Purchased id:</Box> {retest.test_obj._id}
                                                            </Typography>
                                                            <Typography gutterBottom>
                                                                <Box fontWeight="fontWeightBold" display='inline'>Test type:</Box> {retest.test_obj.test_type}
                                                            </Typography>
                                                            <Typography gutterBottom>
                                                                <Box fontWeight="fontWeightBold" display='inline'>Status:</Box> {retest.test_obj.tested ? "Tested" : "Purchased"}
                                                            </Typography>
                                                            <Typography gutterBottom>
                                                                <Box fontWeight="fontWeightBold" display='inline'>Reason:</Box> {retest.reason}
                                                            </Typography>
                                                            <Typography gutterBottom>
                                                                <Box fontWeight="fontWeightBold" display='inline'>Submitter:</Box> {retest.submitter}
                                                            </Typography>
                                                            <Typography gutterBottom>
                                                                <Box fontWeight="fontWeightBold" display='inline'>Status:</Box> {retest.status}
                                                            </Typography>
                                                            <Typography gutterBottom>
                                                                <Box fontWeight="fontWeightBold" display='inline'>Created at:</Box> {moment.utc(retest.updatedAt).format('MMMM Do YYYY, h:mm:ss A')}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid align='right' item xs={12}>
                                                            {!retest.isChecked &&
                                                                <Button
                                                                    variant="contained"
                                                                    disabled={this.state.isApproving}
                                                                    startIcon={this.state.isApproving ? '' : <CheckCircleOutlineRoundedIcon style={{ color: 'white' }} />}
                                                                    onClick={() => this.handleUpdateReTestStatus(retest._id, 'Approved', retest)}
                                                                    style={{ backgroundColor: 'green', color: 'white' }}
                                                                >
                                                                    {this.state.isApproving ? <CircularProgress size={25} style={{ color: 'white' }} /> : "Approve"}
                                                                </Button>
                                                            }
                                                            {!retest.isChecked &&
                                                                <Button
                                                                    variant="contained"
                                                                    disabled={this.state.isApproving}
                                                                    startIcon={this.state.isApproving ? '' : <HighlightOffRoundedIcon style={{ color: 'white' }} />}
                                                                    onClick={() => this.handleUpdateReTestStatus(retest._id, 'Rejected', retest)}
                                                                    style={{ backgroundColor: '#be323e', color: 'white', marginLeft: '10px' }}
                                                                >
                                                                    {this.state.isApproving ? <CircularProgress size={25} style={{ color: 'white' }} /> : "Reject"}
                                                                </Button>
                                                            }

                                                            {retest.status === 'Approved' &&
                                                                <Button
                                                                    variant="contained"
                                                                    disabled
                                                                >
                                                                    Approved
                                                            </Button>
                                                            }

                                                            {retest.status === 'Rejected' &&
                                                                <Button
                                                                    variant="contained"
                                                                    disabled
                                                                >
                                                                    Rejected
                                                            </Button>
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        )) : (
                                    <Grid>
                                        <Skeleton variant="rect" width="100%" height={80} />
                                    </Grid>
                                )}
                        </Paper>
                    </Grid>
                </Grid>
            </Grid >
        )
    }
}

export default compose(
    withStyles(useStyles)
)(Refunds);
