import Avatar from '@material-ui/core/Avatar';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { deepOrange } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import Skeleton from '@material-ui/lab/Skeleton';
import React, { Component } from 'react';
import { compose } from 'recompose';

import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import 'react-calendar/dist/Calendar.css';

//Icon
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import BookmarkBorderRoundedIcon from '@material-ui/icons/BookmarkBorderRounded';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';

//react-router-dom
import BookmarkRounded from '@material-ui/icons/BookmarkRounded';
import {
    Link
} from "react-router-dom";

//import axios
const axios = require('axios').default;

const theme = createMuiTheme({
    typography: {
        fontFamily: [
            'Roboto San 3'
        ].join(','),
    },
});

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        margin: 15,
        paddingTop: '10%',
        marginBottom: '10%'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    itemRoot: {
        marginTop: '50px',
        display: 'block',
        maxWidth: '80%'
    },
    paperRoot: {
        display: 'block',
        maxWidth: '80%'
    },
    margin: {
        margin: 10
    },
    square: {
        color: theme.palette.getContrastText(deepOrange[500]),
        backgroundColor: deepOrange[500],
        width: theme.spacing(15),
        height: theme.spacing(15),
    },
    marginTop: {
        marginTop: '100px'
    },
    paddingTop: {
        paddingTop: '100px'
    },
    serchIcon: {
        marginTop: '5px'
    },
    modifyButton: {
        margin: '5px'
    }
});


class Blogs extends Component {
    constructor() {
        super();
        this.state = {
            window: {
                height: window.innerHeight,
                width: window.innerWidth
            },
            blogs: [],
            pinnedBlogs: [],
            isManageBlog: false,
            isLoading: false
        };
    };

    componentDidMount() {
        this.handleGetBlogs();
        this.props.handleChangeMenu('blogs');
        document.querySelector("body").scrollTo(0, 0);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    handleGetBlogs = async () => {
        try {
            this.handleLoading();
            const res = await axios({
                method: 'get',
                url: 'https://erratic-noiseless-wasabi.glitch.me/utestavenue-system-api/api/v1/blogs',
            });

            const pinnedBlogs = res.data.blogs.filter(blog => blog.pinned);
            this.setState({
                blogs: res.data.blogs,
                pinnedBlogs: pinnedBlogs
            });
            this.handleCloseLoading();

        } catch (err) {
            console.log(err);
            this.handleCloseLoading();
        }
    }

    handlePinnedBlog = async (id) => {
        try {
            this.handleLoading();
            await axios({
                method: 'get',
                url: `https://erratic-noiseless-wasabi.glitch.me/utestavenue-system-api/api/v1/blogs/id/${id}/pinned`,
            });

            this.handleCloseLoading();
            this.handleGetBlogs();
        } catch (err) {
            console.log(err);
            this.handleCloseLoading();
        }
    }

    handleUnpinnedBlog = async (id) => {
        try {
            this.handleLoading();
            await axios({
                method: 'get',
                url: `https://erratic-noiseless-wasabi.glitch.me/utestavenue-system-api/api/v1/blogs/id/${id}/unpinned`,
            });

            this.handleCloseLoading();
            this.handleGetBlogs();
        } catch (err) {
            console.log(err);
            this.handleCloseLoading();
        }
    }

    handleConfirmDeletion = (id) => {
        this.setState({
            isConfirmDeletion: true,
            selectedBlogId: id
        });
    }

    handleCancelConfirmDeletion = () => {
        this.setState({
            isConfirmDeletion: false
        });
    }

    handleDeleteBlog = async () => {
        try {
            this.handleLoading();
            await axios({
                method: 'delete',
                url: `https://erratic-noiseless-wasabi.glitch.me/utestavenue-system-api/api/v1/blogs/id/${this.state.selectedBlogId}`,
            });

            this.handleCloseLoading();
            this.handleGetBlogs();
            this.handleCancelConfirmDeletion();
        } catch (err) {
            console.log(err);
            this.handleCloseLoading();
        }
    }

    handleManageBlog = () => {
        this.setState({
            isManageBlog: true
        });
    }

    handleCancelManageBlog = () => {
        this.setState({
            isManageBlog: false
        });
    }

    handleResize = (event) => {
        this.setState({
            window: {
                height: window.innerHeight,
                width: window.innerWidth
            }
        });
    };

    handleLoading = () => {
        this.setState({
            isLoading: true
        });
    }

    handleCloseLoading = () => {
        this.setState({
            isLoading: false
        });
    }

    handleChange = (event, newValue) => {
        this.setState({
            eventValue: newValue,
        });
    }

    render() {
        const { classes } = this.props;
        const recommendedBlogs = (
            <Grid container justify='center'>
                <Grid item xs={12} align='left' className={classes.itemRoot}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Typography align='left' gutterBottom variant="h5" component="h5">
                                Recommended
                            </Typography>
                        </Grid>
                        {!this.state.isManageBlog &&
                            <Grid item align='right' xs={12} sm={2}>
                                <Button
                                    variant="contained"
                                    component="span"
                                    style={{ backgroundColor: '#76323F', color: 'white' }}
                                    onClick={this.handleManageBlog}
                                >
                                    Manage
                                </Button>
                            </Grid>
                        }
                        {this.state.isManageBlog &&
                            <Grid item align='right' xs={12} sm={2}>
                                <Button
                                    variant="outlined"
                                    component="span"
                                    style={{ borderColor: '#76323F', color: '#76323F' }}
                                    onClick={this.handleCancelManageBlog}
                                >
                                    Done
                                </Button>
                            </Grid>
                        }
                        <Grid item xs={12} sm={2}>
                            <Link to={"/newblog"} style={{ textDecoration: 'none', color: '#0f0f0f' }}>
                                <Button
                                    variant="contained"
                                    component="span"
                                    startIcon={<AddCircleOutlineRoundedIcon />}
                                    style={{ backgroundColor: '#76323F', color: 'white' }}
                                >
                                    Create new
                                </Button>
                            </Link>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Grid container>
                                <Grid item xs={2} className={classes.serchIcon}>
                                    <SearchIcon />
                                </Grid>
                                <Grid item xs={10}>
                                    <Input placeholder="search" inputProps={{ 'aria-label': 'description', style: { textAlign: 'left' } }} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} align='left' className={classes.paperRoot}>
                    {this.state.pinnedBlogs.map((blog) =>
                        <Grid container>
                            {this.state.isManageBlog &&
                                <Grid item align='right' xs={12}>
                                    <Button
                                        variant="contained"
                                        size="small"
                                        style={{ backgroundColor: '#76323F' }}
                                        onClick={() => { this.handleUnpinnedBlog(blog._id) }}
                                        className={classes.modifyButton} >
                                        <BookmarkRounded style={{ color: 'white' }} />
                                    </Button>
                                    <Link to={`/blogs/${blog._id}/edit`} style={{ textDecoration: 'none', color: '#0f0f0f' }}>
                                        <Button
                                            variant="contained"
                                            size="small"
                                            style={{ backgroundColor: '#76323F' }}
                                            className={classes.modifyButton} >
                                            <EditRoundedIcon style={{ color: 'white' }} />
                                        </Button>
                                    </Link>
                                    <Button
                                        variant="contained"
                                        size="small"
                                        style={{ backgroundColor: '#76323F' }}
                                        onClick={() => { this.handleConfirmDeletion(blog._id) }}
                                        className={classes.modifyButton} >
                                        <DeleteForeverRoundedIcon style={{ color: 'white' }} />
                                    </Button>
                                </Grid>
                            }
                            <Grid item xs={12}>
                                <Paper variant="outlined" className={classes.margin}>
                                    <Link to={"/blogs/" + blog._id} style={{ textDecoration: 'none', color: '#0f0f0f' }}>
                                        <ListItem alignItems="flex-start" button>
                                            <ListItemAvatar style={{ marginTop: 0 }}>
                                                <Avatar
                                                    variant="square"
                                                    src={blog.smallPic}
                                                    className={classes.square}
                                                    style={{ margin: '10px' }}
                                                />
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={
                                                    <Typography variant="h6">
                                                        {blog.heading}
                                                    </Typography>
                                                }
                                                secondary={
                                                    <React.Fragment>
                                                        <Grid container>
                                                            <Grid item xs={12}>
                                                                <Typography
                                                                    component="span"
                                                                    variant="body2"
                                                                    className={classes.inline}
                                                                    color="textPrimary"
                                                                >
                                                                    {blog.writerName}
                                                                </Typography>
                                                                {" — " + blog.subheading}
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Typography
                                                                    variant="caption"
                                                                    className={classes.inline}
                                                                    color="textSecondary"
                                                                >
                                                                    Posted at {new Date(blog.createdAt).toDateString()}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </React.Fragment>
                                                }
                                                className={classes.margin}
                                            />
                                        </ListItem>
                                    </Link>
                                </Paper>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        );

        const mostRecentBlogs = (
            <Grid container justify='center'>
                <Grid item xs={12} align='left' className={classes.itemRoot}>
                    <Grid container>
                        <Grid item xs={10}>
                            <Typography align='left' gutterBottom variant="h5" component="h5">
                                Most Recent
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} align='left' className={classes.paperRoot}>
                    {this.state.blogs.map((blog) =>
                        <Grid container>
                            {this.state.isManageBlog &&
                                <Grid item xs={12} align='right'>
                                    {blog.pinned ? <Button
                                        variant="contained"
                                        size="small"
                                        style={{ backgroundColor: '#76323F' }}
                                        onClick={() => { this.handleUnpinnedBlog(blog._id) }}
                                        className={classes.modifyButton} >
                                        <BookmarkRounded style={{ color: 'white' }} />
                                    </Button> : <Button
                                        variant="outlined"
                                        size="small"
                                        style={{ borderColor: '#76323F' }}
                                        onClick={() => { this.handlePinnedBlog(blog._id) }}
                                        className={classes.modifyButton} >
                                        <BookmarkBorderRoundedIcon style={{ color: '#76323F' }} />
                                    </Button>
                                    }
                                    <Link to={`/blogs/${blog._id}/edit`} style={{ textDecoration: 'none', color: '#0f0f0f' }}>
                                        <Button
                                            variant="contained"
                                            size="small"
                                            style={{ backgroundColor: '#76323F' }}
                                            className={classes.modifyButton} >
                                            <EditRoundedIcon style={{ color: 'white' }} />
                                        </Button>
                                    </Link>
                                    <Button
                                        variant="contained"
                                        size="small"
                                        style={{ backgroundColor: '#76323F' }}
                                        onClick={() => { this.handleConfirmDeletion(blog._id) }}
                                        className={classes.modifyButton} >
                                        <DeleteForeverRoundedIcon style={{ color: 'white' }} />
                                    </Button>
                                </Grid>
                            }
                            <Paper variant="outlined" style={{ width: '100%' }} className={classes.margin}>
                                <Grid item xs={12}>
                                    <Link to={"/blogs/" + blog._id} style={{ textDecoration: 'none', color: '#0f0f0f' }}>
                                        <ListItem alignItems="flex-start" button>
                                            <ListItemAvatar style={{ marginTop: 0 }}>
                                                <Avatar
                                                    variant="square"
                                                    src={blog.smallPic}
                                                    className={classes.square}
                                                    style={{ margin: '10px' }}
                                                />
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={
                                                    <Typography variant="h6">
                                                        {blog.heading}
                                                    </Typography>
                                                }
                                                secondary={
                                                    <React.Fragment>
                                                        <Grid container>
                                                            <Grid item xs={12}>
                                                                <Typography
                                                                    component="span"
                                                                    variant="body2"
                                                                    className={classes.inline}
                                                                    color="textPrimary"
                                                                >
                                                                    {blog.writerName}
                                                                </Typography>
                                                                {" — " + blog.subheading}
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Typography
                                                                    variant="caption"
                                                                    className={classes.inline}
                                                                    color="textSecondary"
                                                                >
                                                                    Posted at {new Date(blog.createdAt).toDateString()}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </React.Fragment>
                                                }
                                                className={classes.margin}
                                            />
                                        </ListItem>
                                    </Link>
                                </Grid>
                            </Paper>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        );

        const confirmDeletion = (
            <Dialog
                open={this.state.isConfirmDeletion}
                onClose={this.handleCancelConfirmDeletion}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Are you sure to delete this blog?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You're about to delete blog {this.state.selectedBlogId}.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleDeleteBlog} color="primary">
                        Delete
                    </Button>
                    <Button onClick={this.handleCancelConfirmDeletion} color="primary" autoFocus>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        );

        const backdrop = (
            <Backdrop className={classes.backdrop} open={this.state.isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        )

        return (
            <ThemeProvider theme={theme}>
                <Container maxWidth="lg" style={{ marginBottom: '10%', marginTop: '10%', flexDirection: 'row' }}>
                    <Grid container justify='center' spacing={0} style={{ maxWidth: this.state.window.width }}>
                        <Grid item align='center' xs={12}>
                            {recommendedBlogs}
                            {this.state.pinnedBlogs.length === 0 && <Skeleton variant="rect" width={'80%'} height={150} style={{ marginTop: '10px' }} />}
                        </Grid>
                        <Grid item align='center' xs={12}>
                            {mostRecentBlogs}
                            {this.state.blogs.length === 0 && <Skeleton variant="rect" width={'80%'} height={150} />}
                            {this.state.blogs.length === 0 && <Skeleton variant="rect" width={'80%'} height={150} style={{ marginTop: '20px' }} />}
                            {this.state.blogs.length === 0 && <Skeleton variant="rect" width={'80%'} height={150} style={{ marginTop: '20px' }} />}
                            {this.state.blogs.length === 0 && <Skeleton variant="rect" width={'80%'} height={150} style={{ marginTop: '20px' }} />}
                        </Grid>
                    </Grid>
                    {confirmDeletion}
                    {backdrop}
                </Container>
            </ThemeProvider>
        )
    }
}
export default compose(
    withStyles(useStyles)
)(Blogs);