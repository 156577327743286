//React
import { Component } from 'react';
import { compose } from 'recompose';

//Material UI
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccountBoxRoundedIcon from '@material-ui/icons/AccountBoxRounded';
import AccessAlarmRoundedIcon from '@material-ui/icons/AccessAlarmRounded';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Input from '@material-ui/core/Input';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

//import components
import { DragDropContainer, DropTarget } from 'react-drag-drop-container';

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%'
    }
});

class MatchingSentenceEndings extends Component {
    constructor() {
        super();
        this.state = {
            draggedItem: '',
            draggedItemToShow: '',
            dropItems: [],
            dropItemsToShow: []
        };
    }

    componentDidMount = async () => {

    }

    onDragged = (index) => {
        if (this.state.draggedItem !== undefined) {
            this.state.dropItems[index] = this.state.draggedItem
            this.state.dropItemsToShow[index] = this.state.draggedItemToShow

            this.setState({
                dropItemsToShow: this.state.dropItemsToShow,
                draggedItem: undefined
            });
        }
    }

    onDragStart = (item) => {
        this.setState({
            draggedItem: item.split(' ')[0],
            draggedItemToShow: item
        });
    }

    onClickAnswer = (index, q) => {
        this.state.dropItemsToShow[index] = undefined;

        this.setState({
            dropItemsToShow: this.state.dropItemsToShow
        });
    }

    render() {
        const { classes } = this.props;

        return (
            <Grid container>
                <Grid item xs={12} style={{ marginBottom: '20px' }}>
                    <Typography variant="h5" gutterBottom align='left' style={{ fontSize: '1rem' }}>
                        <Box fontWeight="fontWeightBold" display='inline'>{this.props.sectionInfo.section}</Box>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2} align='left'>
                        {this.props.sectionInfo.questions[0].match_question_l.map((q, index) =>
                            <Grid item xs={12}>
                                <Typography variant="body1" style={{ fontSize: '1rem' }}>
                                    {q} <DropTarget
                                        targetKey="foo"
                                        onChange={this.props.handleChange}
                                        onHit={() => {
                                            this.onDragged(index);
                                        }}
                                    >
                                        <Button
                                            size="small"
                                            id="outlined-basic"
                                            variant="outlined"
                                            style={{ width: '50%' }}
                                            onClick={() => { this.onClickAnswer(index, q) }}
                                        >
                                            {this.state.dropItemsToShow[index] === undefined ? q.split('.')[0] : this.state.dropItemsToShow[index]}
                                        </Button>
                                    </DropTarget>
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{ marginTop: '50px' }}>
                    <Grid item xs={12} style={{ marginBottom: '20px' }}>
                        <Typography variant="h5" gutterBottom align='left' style={{ fontSize: '1rem' }}>
                            <Box fontWeight="fontWeightBold" display='inline'>{this.props.sectionInfo.questions[0].question}</Box>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ marginBottom: '50px' }}>
                        <Grid container align='left' spacing={1}>
                            {this.props.sectionInfo.questions[0].match_question_r.map((q, index) =>
                                !this.state.dropItemsToShow.includes(q) && <Grid item xs={12}>
                                    <DragDropContainer targetKey="foo" onDragStart={() => { this.onDragStart(q) }} >
                                        <Paper style={{ padding: '10px' }}>
                                            <Typography variant="subtitle2" style={{ fontSize: '1rem' }}>
                                                {q}
                                            </Typography>
                                        </Paper>
                                    </DragDropContainer>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

export default compose(
    withStyles(useStyles)
)(MatchingSentenceEndings);