import React from 'react';

import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import LinearProgress from '@material-ui/core/LinearProgress';
import MuiAlert from '@material-ui/lab/Alert';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

//Others
import { withStyles } from '@material-ui/core/styles';
import { Component } from 'react';
import { compose } from 'recompose';
import MaterialTable from 'material-table'
import { GetPurchasedTests } from '../../../../services/paymentServices';

//Icons
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

//Components
import LoadingBackdrop from '../../public/components/LoadingBackdrop';
import PageBackdrop from '../../../main/PageBackdrop';

//Others
const axios = require('axios').default;
const FormData = require('form-data');
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

const useStyles = theme => ({
    root: {
        margin: 15,
        marginTop: 50
    }
});

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class IncomeTable extends Component {
    constructor() {
        super()
        this.state = {
            chargesToShow: [],
            discountCodeUsage: [],
            isLoading: false
        };
    }

    componentDidMount = async () => {
        this.setState({
            isLoading: true
        });

        await this.handleGetAllDiscountCodeUsage();
        await this.handleGetAllCharges();

        this.setState({
            isLoading: false
        });
    }

    handleGetAllDiscountCodeUsage = async () => {
        try {
            const resDC = await axios({
                method: 'get',
                url: 'https://erratic-noiseless-wasabi.glitch.me/utestavenue-system-api/api/v1/discounts',
            });

            const resDCData = resDC.data.discounts.filter((dc) => !["117337903114766421177", "phisonkha"].includes(dc.createdBy) && dc.discountCode !== "JPROMSO");

            const resDCU = await axios({
                method: 'get',
                url: 'https://erratic-noiseless-wasabi.glitch.me/utestavenue-system-api/api/v1/discountsUsage/used',
            });

            const resDCUData = resDCU.data.discountsUsage.filter((dcu) => !["bellebelle", "117337903114766421177", "phisonkha", "jirayu.promsongwong@hotmail.com"].includes(dcu.usedBy) && dcu.discountCode !== "JPROMSO");

            resDCUData.map((dcu) => {
                if (resDCData.find(dc => dc.discountCode === dcu.discountCode)) {
                    dcu.amount = resDCData.find(dc => dc.discountCode === dcu.discountCode).discount;
                }
            });

            this.setState({
                discountCodeUsage: resDCUData
            });

        } catch (err) {
            console.log(err);
        }
    }

    handleGetAllCharges = async () => {
        try {
            const chargesToShow = [];
            const res = await axios({
                method: 'get',
                url: 'https://almond-magnetic-sale.glitch.me/charges',
            });

            const resData = res.data;
            await resData.list.data.filter((c) => !["failed", "expired"].includes(c.status)).map((charge) => {
                chargesToShow.push({
                    cardHolder: charge.card.name,
                    transactionId: charge.transaction,
                    amount: charge.amount / 100,
                    status: charge.status,
                    reason: charge.failure_message === null ? 'Paid' : charge.failure_message,
                    paidAt: new Date(charge.paid_at).toDateString()
                })
            })

            // const purchaseArr = await GetPurchasedTests();

            // purchaseArr.res.map((obj) => {
            //     if (Number(obj.purchasedChargeAmount) === 0) {
            //         chargesToShow.push({
            //             cardHolder: "-",
            //             transactionId: "-",
            //             amount: 0,
            //             status: "successful",
            //             reason: 'Paid',
            //             paidAt: new Date(obj.createdAt).toDateString()
            //         })
            //     }

            //     return obj;
            // })

            this.state.discountCodeUsage.map((dcu) => {
                if (dcu.amount) {
                    chargesToShow.push({
                        cardHolder: "-",
                        transactionId: "-",
                        amount: Number(dcu.amount),
                        status: "successful",
                        reason: `Code ${dcu.discountCode}`,
                        paidAt: new Date(dcu.createdAt).toDateString()
                    })
                }
            });

            this.setState({
                chargesToShow: chargesToShow
            });

        } catch (err) {
            console.log(err);
        }
    }

    render() {
        const { classes } = this.props;

        return (
            <Grid item xs={12}>
                {/* <PageBackdrop isLoading={this.state.isLoading} /> */}
                <MaterialTable
                    data={this.state.chargesToShow}
                    columns={[
                        {
                            title: 'Transaction id',
                            field: 'transactionId',
                            editable: 'never'
                        },
                        {
                            title: 'Cardholder name',
                            field: 'cardHolder',
                            editable: 'never'
                        },
                        {
                            title: 'Amount',
                            field: 'amount',
                            editable: 'never'
                        },
                        {
                            title: 'Paid at',
                            field: 'paidAt',
                            editable: 'never',
                            defaultSort: 'desc',
                            customSort: (a, b) => new Date(a.paidAt) - new Date(b.paidAt)
                        },
                        {
                            title: 'Status',
                            field: 'status',
                            editable: 'never'
                        },
                        {
                            title: 'Reason',
                            field: 'reason',
                            editable: 'never'
                        }
                    ]}
                    options={{
                        actionsColumnIndex: -1,
                        exportAllData: true,
                        exportButton: true,
                        grouping: true
                    }}
                    title='Charges'
                />
            </Grid>
        )
    }
}
export default compose(
    withStyles(useStyles)
)(IncomeTable);
