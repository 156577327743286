import React, { useEffect } from 'react';

//MUI
import Container from '@material-ui/core/Container';

import { ThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';


//Font
import 'typeface-glacial-indifference';
import { Grid, Hidden, Typography } from '@material-ui/core';

const useStyles = theme => ({
    root: {
        padding: theme.spacing(12, 4),
    }
});

const theme = createMuiTheme({
    typography: {
        fontFamily: [
            'Roboto'
        ].join(','),
    },
});

const FAQ = () => {
    useEffect(() => {
        document.body.style.overflow = "auto";
        document.body.style.backgroundColor = "#efefef";
        document.querySelector("body").scrollTo(0, 0);
    }, []);

    const mobileUI = (
        <Grid item xs={12} style={{ maxWidth: window.innerWidth, zIndex: 1, padding: "20px" }}>
            <Grid container spacing={2} style={{ marginTop: "10%", marginBottom: "10%" }}>
                {/* ==================================== FAQs ==================================== */}
                <Grid item xs={12}>
                    <Typography variant="h4" style={{ fontWeight: "bold" }} gutterBottom>
                        Frequently asked questions (FAQs)
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h5" style={{ fontWeight: "bold" }} gutterBottom>
                        About UTest Avenue
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold", paddingLeft: "30px" }}>
                        1. What is UTest Avenue?
                    </Typography>
                    <Typography variant="body1">
                        UTest Avenue is a platform that offers IELTS simulation and other English proficiency tests combined with machine-learning technology to provide personalised reports to its users. It is designed to help users prepare for the IELTS and other English proficiency exams and improve their language skills.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold", paddingLeft: "30px" }}>
                        2. How does UTest Avenue's IELTS simulation tests work?
                    </Typography>
                    <Typography variant="body1">
                        UTest Avenue's IELTS simulation tests mimic the real IELTS exam and provide users with a realistic experience. The tests are designed to evaluate the user's language skills and provide a score and a personalised report on areas of improvement.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold", paddingLeft: "30px" }}>
                        3. Are all the materials on UTest Avenue original and unique?
                    </Typography>
                    <Typography variant="body1">
                        Yes, all the materials provided on UTest Avenue are originally written and designed by our educators. Our team of experts works hard to develop and create high-quality IELTS and other English proficiency simulation tests that are designed to help users prepare effectively for their exams. The material is regularly updated to ensure that it is current and accurate, and all test questions are created from scratch to avoid any instances of plagiarism or copyright infringement.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold", paddingLeft: "30px" }}>
                        4. Is the IELTS speaking test from UTest Avenue evaluated by a person?
                    </Typography>
                    <Typography variant="body1">
                        The IELTS speaking test on UTest Avenue is evaluated by an in-house examiner. Our  examiner evaluates the task response, cohesion and coherence, grammatical range and accuracy, and lexical resources of the speaking test.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold", paddingLeft: "30px" }}>
                        5. Is the IELTS writing test from UTest Avenue evaluated by a person?
                    </Typography>
                    <Typography variant="body1">
                        The IELTS writing test on UTest Avenue is evaluated by both a person and a machine. Our machine-learning algorithms grade the grammatical range and accuracy and lexical resources, while the tasks response and cohesion & coherence are graded by experienced in-house examiners at UTest Avenue. This combination of technology and human expertise provides a comprehensive and accurate evaluation of the user's writing skills.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold", paddingLeft: "30px" }}>
                        6. Are the IELTS and other  English proficiency exams simulation tests on UTest Avenue the actual tests from the official organisations?
                    </Typography>
                    <Typography variant="body1">
                        No, the IELTS and other simulation tests on UTest Avenue are not the actual tests from official organisations. They are designed to mimic the format and structure of the IELTS and other English proficiency exams, but the content and questions are created by UTest Avenue's team of experts. The aim of the simulation tests is to provide users with a realistic experience and help them prepare for the actual IELTS and  English proficiency exams.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold", paddingLeft: "30px" }}>
                        7. Why is machine-learning used at UTest Avenue?
                    </Typography>
                    <Typography variant="body1">
                        Machine-learning is used at UTest Avenue to enhance the user's IELTS and other  English proficiency exams preparation experience. By analysing the user's performance on the IELTS and other English proficiency simulation tests, the platform's machine-learning algorithms generate a personalised report that highlights areas of improvement and inhouse-examiners’ feedback. This allows users to focus their preparation efforts on the areas where they need the most help, providing a more efficient and effective preparation method. The use of machine-learning technology also allows UTest Avenue to provide its users with accurate and up-to-date IELTS simulation tests with the aim to help them and their educators recognize the users’ individual strengths and weaknesses.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold", paddingLeft: "30px" }}>
                        8. Who designs the test materials for UTest Avenue?
                    </Typography>
                    <Typography variant="body1">
                        The test materials for UTest Avenue are designed by a team of experts who are dedicated to providing high-quality IELTS and  other English proficiency simulation tests. Our team includes language experts, educational professionals, and machine-learning specialists who work together to create tests that accurately evaluate language skills and provide personalised reports to users. The material is regularly updated to ensure that it aligns with the latest IELTS exam format and provides users with an accurate and up-to-date preparation experience.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold", paddingLeft: "30px" }}>
                        9. What is the advantage of using UTest Avenue over other IELTS preparation methods?
                    </Typography>
                    <Typography variant="body1">
                        UTest Avenue's combination of simulation tests and machine-learning technology provides users with a personalised experience that is tailored to their strengths and weaknesses. The platform also offers up-to-date and accurate IELTS tests, making it a reliable and effective preparation method.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold", paddingLeft: "30px" }}>
                        10. How does the personal report feature work on UTest Avenue?
                    </Typography>
                    <Typography variant="body1">
                        After completing the IELTS simulation tests on UTest Avenue, users receive an instant personalised report that details their strengths and weaknesses in Listening and Reading skills and in-house examiner’s feedback within 48 - 72 hours for Writing and Speaking skills.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold", paddingLeft: "30px" }}>
                        11. How can users access their personal report on UTest Avenue?
                    </Typography>
                    <Typography variant="body1">
                        Users can access their personal report on UTest Avenue by logging into their account and accessing the "Reports" section of the platform.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold", paddingLeft: "30px" }}>
                        12. What are the system requirements for using UTest Avenue?
                    </Typography>
                    <Typography variant="body1">
                        To use UTest Avenue, users need a device with an internet connection and a modern web browser. The specific requirements for the device may vary, so it is recommended to check the platform's website for more information.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold", paddingLeft: "30px" }}>
                        13. What kind of support does UTest Avenue offer to its users?
                    </Typography>
                    <Typography variant="body1">
                        UTest Avenue offers support to its users through various channels, such as email and live chat. The platform's website also provides a comprehensive FAQ section and other resources to help users with any questions or issues they may have.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold", paddingLeft: "30px" }}>
                        14. What are the privacy policies of UTest Avenue?
                    </Typography>
                    <Typography variant="body1">
                        UTest Avenue takes user privacy seriously and has measures in place to protect user data and information. The platform's privacy policy can be found on the website and outlines how user data is collected, used, and protected.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold", paddingLeft: "30px" }}>
                        15. How long do I have to complete my test if I purchased any other test?
                    </Typography>
                    <Typography variant="body1">
                        For all other test purchases, you have 90 days from the date of payment to complete your test. Make sure to allocate sufficient time within this period to avoid expiration.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold", paddingLeft: "30px" }}>
                        16. What happens if I fail to schedule or complete my test within the specified timeframes?
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        If you don't schedule your speaking test within 7 days (for FULL IELTS with SPK) or fail to complete your test within 90 days (for other tests), your test may expire. In such cases, refunds will not be possible as per our policies.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        We recommend being proactive in scheduling and completing your tests to make the most of your purchase. Should you have any further questions or concerns, please don't hesitate to reach out to our support team.
                    </Typography>
                </Grid>
                {/* ==================================== FAQs ==================================== */}

                {/* ==================================== Payment and Refund Policy ==================================== */}
                <Grid item xs={12} style={{ marginTop: "50px" }} gutterBottom>
                    <Typography variant="h4" style={{ fontWeight: "bold" }}>
                        Payment and Refund Policy
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold", paddingLeft: "30px" }}>
                        1. How much does UTest Avenue cost?
                    </Typography>
                    <Typography variant="body1">
                        The cost of using UTest Avenue may vary and can be found on the platform's website. Users may have the option of purchasing  packages, bundles, or individual tests.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold", paddingLeft: "30px" }}>
                        2. What payment methods does UTest Avenue accept?
                    </Typography>
                    <Typography variant="body1">
                        UTest Avenue accepts multiple payment methods, including credit and debit cards, PayPal, and other secure online payment options.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold", paddingLeft: "30px" }}>
                        3. Is it possible to request a refund if I am not satisfied with my purchase?
                    </Typography>
                    <Typography variant="body1">
                        Yes, UTest Avenue offers a refund policy for users who are not satisfied with their purchase. The specific conditions and time frame for requesting are within 30 days after your purchase, so please refer to UTest Avenue's refund policy for more information.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold", paddingLeft: "30px" }}>
                        4. How can I receive a receipt for my purchase on UTest Avenue?
                    </Typography>
                    <Typography variant="body1">
                        After making a purchase on UTest Avenue, a receipt will be automatically generated and sent to the email associated with your account. You can also log into your account and view your purchase history and receipts.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold", paddingLeft: "30px" }}>
                        5. Are there any promotions or discounts available for UTest Avenue?
                    </Typography>
                    <Typography variant="body1">
                        UTest Avenue may offer promotions or discounts from time to time. To stay up to date on current promotions and discounts, please sign up for UTest Avenue's email newsletter or follow them on social media.
                    </Typography>
                </Grid>
                {/* ==================================== Payment and Refund Policy ==================================== */}
            </Grid>
        </Grid>
    )

    const webUI = (
        <Container>
            <Grid container spacing={2} style={{ marginTop: "10%", marginBottom: "10%" }}>
                {/* ==================================== FAQs ==================================== */}
                <Grid item xs={12}>
                    <Typography variant="h4" style={{ fontWeight: "bold" }} gutterBottom>
                        Frequently asked questions (FAQs)
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h5" style={{ fontWeight: "bold" }} gutterBottom>
                        About UTest Avenue
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold", paddingLeft: "30px" }}>
                        1. What is UTest Avenue?
                    </Typography>
                    <Typography variant="body1">
                        UTest Avenue is a platform that offers IELTS simulation and other English proficiency tests combined with machine-learning technology to provide personalised reports to its users. It is designed to help users prepare for the IELTS and other English proficiency exams and improve their language skills.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold", paddingLeft: "30px" }}>
                        2. How does UTest Avenue's IELTS simulation tests work?
                    </Typography>
                    <Typography variant="body1">
                        UTest Avenue's IELTS simulation tests mimic the real IELTS exam and provide users with a realistic experience. The tests are designed to evaluate the user's language skills and provide a score and a personalised report on areas of improvement.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold", paddingLeft: "30px" }}>
                        3. Are all the materials on UTest Avenue original and unique?
                    </Typography>
                    <Typography variant="body1">
                        Yes, all the materials provided on UTest Avenue are originally written and designed by our educators. Our team of experts works hard to develop and create high-quality IELTS and other English proficiency simulation tests that are designed to help users prepare effectively for their exams. The material is regularly updated to ensure that it is current and accurate, and all test questions are created from scratch to avoid any instances of plagiarism or copyright infringement.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold", paddingLeft: "30px" }}>
                        4. Is the IELTS speaking test from UTest Avenue evaluated by a person?
                    </Typography>
                    <Typography variant="body1">
                        The IELTS speaking test on UTest Avenue is evaluated by an in-house examiner. Our  examiner evaluates the task response, cohesion and coherence, grammatical range and accuracy, and lexical resources of the speaking test.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold", paddingLeft: "30px" }}>
                        5. Is the IELTS writing test from UTest Avenue evaluated by a person?
                    </Typography>
                    <Typography variant="body1">
                        The IELTS writing test on UTest Avenue is evaluated by both a person and a machine. Our machine-learning algorithms grade the grammatical range and accuracy and lexical resources, while the tasks response and cohesion & coherence are graded by experienced in-house examiners at UTest Avenue. This combination of technology and human expertise provides a comprehensive and accurate evaluation of the user's writing skills.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold", paddingLeft: "30px" }}>
                        6. Are the IELTS and other  English proficiency exams simulation tests on UTest Avenue the actual tests from the official organisations?
                    </Typography>
                    <Typography variant="body1">
                        No, the IELTS and other simulation tests on UTest Avenue are not the actual tests from official organisations. They are designed to mimic the format and structure of the IELTS and other English proficiency exams, but the content and questions are created by UTest Avenue's team of experts. The aim of the simulation tests is to provide users with a realistic experience and help them prepare for the actual IELTS and  English proficiency exams.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold", paddingLeft: "30px" }}>
                        7. Why is machine-learning used at UTest Avenue?
                    </Typography>
                    <Typography variant="body1">
                        Machine-learning is used at UTest Avenue to enhance the user's IELTS and other  English proficiency exams preparation experience. By analysing the user's performance on the IELTS and other English proficiency simulation tests, the platform's machine-learning algorithms generate a personalised report that highlights areas of improvement and inhouse-examiners’ feedback. This allows users to focus their preparation efforts on the areas where they need the most help, providing a more efficient and effective preparation method. The use of machine-learning technology also allows UTest Avenue to provide its users with accurate and up-to-date IELTS simulation tests with the aim to help them and their educators recognize the users’ individual strengths and weaknesses.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold", paddingLeft: "30px" }}>
                        8. Who designs the test materials for UTest Avenue?
                    </Typography>
                    <Typography variant="body1">
                        The test materials for UTest Avenue are designed by a team of experts who are dedicated to providing high-quality IELTS and  other English proficiency simulation tests. Our team includes language experts, educational professionals, and machine-learning specialists who work together to create tests that accurately evaluate language skills and provide personalised reports to users. The material is regularly updated to ensure that it aligns with the latest IELTS exam format and provides users with an accurate and up-to-date preparation experience.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold", paddingLeft: "30px" }}>
                        9. What is the advantage of using UTest Avenue over other IELTS preparation methods?
                    </Typography>
                    <Typography variant="body1">
                        UTest Avenue's combination of simulation tests and machine-learning technology provides users with a personalised experience that is tailored to their strengths and weaknesses. The platform also offers up-to-date and accurate IELTS tests, making it a reliable and effective preparation method.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold", paddingLeft: "30px" }}>
                        10. How does the personal report feature work on UTest Avenue?
                    </Typography>
                    <Typography variant="body1">
                        After completing the IELTS simulation tests on UTest Avenue, users receive an instant personalised report that details their strengths and weaknesses in Listening and Reading skills and in-house examiner’s feedback within 48 - 72 hours for Writing and Speaking skills.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold", paddingLeft: "30px" }}>
                        11. How can users access their personal report on UTest Avenue?
                    </Typography>
                    <Typography variant="body1">
                        Users can access their personal report on UTest Avenue by logging into their account and accessing the "Reports" section of the platform.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold", paddingLeft: "30px" }}>
                        12. What are the system requirements for using UTest Avenue?
                    </Typography>
                    <Typography variant="body1">
                        To use UTest Avenue, users need a device with an internet connection and a modern web browser. The specific requirements for the device may vary, so it is recommended to check the platform's website for more information.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold", paddingLeft: "30px" }}>
                        13. What kind of support does UTest Avenue offer to its users?
                    </Typography>
                    <Typography variant="body1">
                        UTest Avenue offers support to its users through various channels, such as email and live chat. The platform's website also provides a comprehensive FAQ section and other resources to help users with any questions or issues they may have.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold", paddingLeft: "30px" }}>
                        14. What are the privacy policies of UTest Avenue?
                    </Typography>
                    <Typography variant="body1">
                        UTest Avenue takes user privacy seriously and has measures in place to protect user data and information. The platform's privacy policy can be found on the website and outlines how user data is collected, used, and protected.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold", paddingLeft: "30px" }}>
                        15. How long do I have to complete my test if I purchased any other test?
                    </Typography>
                    <Typography variant="body1">
                        For all other test purchases, you have 90 days from the date of payment to complete your test. Make sure to allocate sufficient time within this period to avoid expiration.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold", paddingLeft: "30px" }}>
                        16. What happens if I fail to schedule or complete my test within the specified timeframes?
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        If you don't schedule your speaking test within 7 days (for FULL IELTS with SPK) or fail to complete your test within 90 days (for other tests), your test may expire. In such cases, refunds will not be possible as per our policies.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        We recommend being proactive in scheduling and completing your tests to make the most of your purchase. Should you have any further questions or concerns, please don't hesitate to reach out to our support team.
                    </Typography>
                </Grid>
                {/* ==================================== FAQs ==================================== */}

                {/* ==================================== Payment and Refund Policy ==================================== */}
                <Grid item xs={12} style={{ marginTop: "50px" }} gutterBottom>
                    <Typography variant="h4" style={{ fontWeight: "bold" }}>
                        Payment and Refund Policy
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold", paddingLeft: "30px" }}>
                        1. How much does UTest Avenue cost?
                    </Typography>
                    <Typography variant="body1">
                        The cost of using UTest Avenue may vary and can be found on the platform's website. Users may have the option of purchasing  packages, bundles, or individual tests.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold", paddingLeft: "30px" }}>
                        2. What payment methods does UTest Avenue accept?
                    </Typography>
                    <Typography variant="body1">
                        UTest Avenue accepts multiple payment methods, including credit and debit cards, PayPal, and other secure online payment options.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold", paddingLeft: "30px" }}>
                        3. Is it possible to request a refund if I am not satisfied with my purchase?
                    </Typography>
                    <Typography variant="body1">
                        Yes, UTest Avenue offers a refund policy for users who are not satisfied with their purchase. The specific conditions and time frame for requesting are within 30 days after your purchase, so please refer to UTest Avenue's refund policy for more information.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold", paddingLeft: "30px" }}>
                        4. How can I receive a receipt for my purchase on UTest Avenue?
                    </Typography>
                    <Typography variant="body1">
                        After making a purchase on UTest Avenue, a receipt will be automatically generated and sent to the email associated with your account. You can also log into your account and view your purchase history and receipts.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold", paddingLeft: "30px" }}>
                        5. Are there any promotions or discounts available for UTest Avenue?
                    </Typography>
                    <Typography variant="body1">
                        UTest Avenue may offer promotions or discounts from time to time. To stay up to date on current promotions and discounts, please sign up for UTest Avenue's email newsletter or follow them on social media.
                    </Typography>
                </Grid>
                {/* ==================================== Payment and Refund Policy ==================================== */}
            </Grid>
        </Container>
    )

    return (
        <ThemeProvider theme={theme}>
            <Hidden mdUp>{mobileUI}</Hidden>
            <Hidden smDown>{webUI}</Hidden>
        </ThemeProvider>
    )
};

export default compose(
    withStyles(useStyles)
)(FAQ);
