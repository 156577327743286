//React
import { Component } from "react";
import { compose } from "recompose";

//Material UI
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = (theme) => ({
    root: {
        flexGrow: 1,
        width: "100%",
    },
});

class SpeakingScoreDisplay extends Component {
    componentDidMount() {
        console.log(this.props);
    }

    render() {
        const { classes } = this.props;

        return (
            <Grid item xs={12} sm={9}>
                <Dialog
                    maxWidth="lg"
                    open={this.props.isOpenDialog}
                    onClose={this.props.onCloseScoreDialog}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">Speaking Score</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Box
                                        fontWeight="fontWeightBold"
                                        display="inline"
                                        style={{ fontSize: "1.5rem", marginBottom: "10%" }}
                                    >
                                        Information
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box fontWeight="fontWeightBold" display="inline">
                                        Submission id:
                                    </Box>{" "}
                                    {this.props.scoreObj?.submitted_test_id}
                                </Grid>
                                <Grid item xs={12}>
                                    <Box fontWeight="fontWeightBold" display="inline">
                                        Test id:
                                    </Box>{" "}
                                    {this.props.scoreObj?.note?.test_id}
                                </Grid>
                                <Grid item xs={12}>
                                    <Box fontWeight="fontWeightBold" display="inline">
                                        Tester:
                                    </Box>{" "}
                                    {this.props.scoreObj?.note?.tester}
                                </Grid>
                                <Grid item xs={12}>
                                    <Box fontWeight="fontWeightBold" display="inline">
                                        Assigned teacher:
                                    </Box>{" "}
                                    {this.props.scoreObj?.note?.teacher}
                                </Grid>
                            </Grid>
                        </DialogContentText>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography>Fluency and coherence</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    disabled
                                    value={
                                        this.props.scoreObj?.note?.note?.fluency_and_coherence
                                    }
                                    name="task_2-task_achievement"
                                    margin="dense"
                                    variant="outlined"
                                    autoComplete="new-password"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>Lexical resource</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    disabled
                                    value={
                                        this.props.scoreObj?.note?.note?.lexical_resource
                                    }
                                    name="task_2-cohesion_and_coherence"
                                    margin="dense"
                                    variant="outlined"
                                    autoComplete="new-password"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>Gramatical range and accuracy</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    disabled
                                    value={
                                        this.props.scoreObj?.note?.note?.gramatical_range_and_accuracy
                                    }
                                    name="task_2-lexical_resource"
                                    margin="dense"
                                    variant="outlined"
                                    autoComplete="new-password"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>Pronunciation</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    disabled
                                    value={
                                        this.props.scoreObj?.note?.note?.pronunciation
                                    }
                                    name="task_2-grammatical_range_and_accuracy"
                                    margin="dense"
                                    variant="outlined"
                                    autoComplete="new-password"
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        <Typography style={{ marginTop: "5%" }}>Band score</Typography>
                        <TextField
                            disabled
                            value={this.props.scoreObj?.note?.note?.score}
                            name="task_1-score"
                            margin="dense"
                            variant="outlined"
                            autoComplete="new-password"
                            fullWidth
                        />
                        <Typography>Comments</Typography>
                        <TextField
                            disabled
                            value={this.props.scoreObj?.note?.note?.comment}
                            name="comment"
                            margin="dense"
                            variant="outlined"
                            autoComplete="new-password"
                            fullWidth
                            multiline
                            rows={12}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.onCloseScoreDialog} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        );
    }
}

export default compose(withStyles(useStyles))(SpeakingScoreDisplay);
