//React
import { Component } from 'react';
import { compose } from 'recompose';

//Material UI
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

//import components

//Others

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%'
    },
    paperRoot: {
        padding: theme.spacing(2),
        textAlign: 'left'
    },
    paperItem: {
        padding: theme.spacing(2),
        textAlign: 'left',
        height: '810px',
        maxHeight: '810px',
        overflow: 'auto',
    },
    testAlignLeft: {
        padding: theme.spacing(2),
    },
    gridContainerRoot: {
        marginTop: 80
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    marginLeft: {
        marginLeft: 20
    },
    marginTop: {
        marginTop: 20
    },
    margin: {
        margin: 5
    }
});

class CompleteTheTables extends Component {
    constructor() {
        super();
        this.state = {
            startQuestionNumber: 0,
            sizeMapHeader: {
                'sm': 'body2',
                'md': 'body1',
                'lg': 'h6',
            },
            sizeMapBody: {
                'sm': 'caption',
                'md': 'body2',
                'lg': 'body1',
            },
            sizeMapCaption: {
                'sm': 'caption',
                'md': 'body2',
                'lg': 'h6',
            },
        };
    }

    componentDidMount = async () => {
        const filteredAnsObj = await this.props.answerObj.parts[this.props.currentPart].sections.find((element) => {
            return element.section_id === this.props.currentTestObject.section_id;
        })

        await this.setState({
            answerObj: {
                section_id: this.props.currentTestObject.section_id,
                question_type: this.props.currentTestObject.question_type,
                answers: {}
            },
            answerArr: filteredAnsObj.answers.length === 0 ? [] : filteredAnsObj.answers[this.props.currentTestObject.questions[0].no],
            startQuestionNumber: this.props.currentTestObject.questions[0].no.split("-")[0]
        });

        this.prepareTest();
    }

    prepareTest = async () => {
        let count = 0;
        const questionNo = Number(this.props.currentTestObject.questions[0].no.split('-')[0]);
        const ansArr = this.state.answerArr;

        const final = await this.props.currentTestObject.questions[0].text.replace(/_/g, function (x) { count++; return `<input id="index-${count}" value="${ansArr[count - 1] ? ansArr[count - 1] : ""}" autocomplete="off" spellcheck="false" placeholder="${questionNo + (count - 1)}" type="text" style="text-align: center; height:21px; margin: 5px" />` })

        await this.setState({
            testContext: final
        });

        [...Array(this.props.currentTestObject.total_question).keys()].map((value, index) => {
            const el = document.getElementById(`index-${index + 1}`);
            el.addEventListener('change', (event) => { this.handleAnswerQuestion(event, Number(event.target.id.split("-")[1]) - 1) });
            el.addEventListener('click', (event) => { this.props.onFocusQuestion(Number(questionNo) + index) });
        })
    }

    handleOnFocusTextField = (event, index) => {
        if (this.state.answerArr[index] === undefined) {
            this.state.answerArr[index] = '';

            this.setState({
                answerArr: this.state.answerArr
            });

            this.props.onFocusQuestion(Number(this.props.currentTestObject.questions[0].no.split('-')[0]) + index);
        }
    }

    handleAnswerQuestion = (event, index) => {
        this.state.answerArr[index] = event.target.value;
        this.state.answerObj.answers[this.props.currentTestObject.questions[0].no] = this.state.answerArr;
        this.props.handleAnswerObj(this.state.answerObj)
        this.props.handleAnsweredQuestionNumber(Number(this.props.currentTestObject.questions[0].no.split('-')[0]) + index);
        this.handleOnFocusTextField(event, index);
    }

    render() {
        const { classes } = this.props;

        return (
            <Grid container style={{ zIndex: 1, position: "relative" }}>
                <Grid item style={{ padding: '30px' }}>
                    <Typography align='left' style={{ textAlign: 'justify', fontSize: `${this.props.fontSize}rem` }}>
                        <div dangerouslySetInnerHTML={{ __html: this.state.testContext ? this.state.testContext : "" }} />
                    </Typography>
                </Grid>
            </Grid >
        )
    }
}

export default compose(
    withStyles(useStyles)
)(CompleteTheTables);
