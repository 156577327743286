//React
import { Component } from 'react';
import { compose } from 'recompose';

//Material UI
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import { TextFieldsRounded } from '@material-ui/icons';

//Initial
const axios = require('axios').default;
const FormData = require('form-data');
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%'
    }
});

class Choose2Letters extends Component {
    constructor() {
        super();

        this.state = {
            alphabetArr: [
                'A',
                'B',
                'C',
                'D',
                'E',
                'F',
                'G'
            ],
            tempAnsArr: [],
            tempQuestionCount: [1, 2, 3, 4, 5],
            tempChoice: ['A', 'B', 'C', 'D', 'E']
        };
    }

    componentDidMount = async () => {

    }

    handleEditVoiceUrl = async (event) => {
        this.props.questionObj.voice = event.target.value;
    }

    handleEditQuestionNumber = (event) => {
        this.props.questionObj.no = `${event.target.value}-${Number(event.target.value) + 1}`;
        this.setState({
            beginQuestion: Number(event.target.value)
        });

        console.log(this.props.questionObj)
    }

    handleEditQuestion = (event) => {
        this.props.questionObj.question = event.target.value;
    }

    handleSelectChoice = (event, index) => {
        this.props.questionObj.answer[index] = event.target.value;
        this.state.tempAnsArr[index] = event.target.value;
        this.setState({
            tempAnsArr: this.state.tempAnsArr
        });

        console.log(this.state.tempAnsArr);
    }

    handleClearAnswer = () => {
        this.props.questionObj.answer = [];

        this.setState({
            tempAnsArr: []
        });
    }

    handleEditChoiceText = (event, index) => {
        this.props.questionObj.choice[index] = event.target.value;
    }

    render() {
        const { classes } = this.props;

        return (
            <Grid item xs={12}>
                <Grid container spacing={1}>
                    <Grid item align='left' xs={12} style={{ marginTop: '10px' }}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                                            Voice Url
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="outlined-size-small"
                                            variant="outlined"
                                            size="small"
                                            onChange={this.handleEditVoiceUrl}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                                    Question
                                </Typography>
                            </Grid>
                            <Grid item xs>
                                <TextField
                                    label="No."
                                    id="outlined-size-small"
                                    variant="outlined"
                                    size="small"
                                    onChange={this.handleEditQuestionNumber}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item>
                                <Typography variant="h6" align='center'>
                                    -
                                </Typography>
                            </Grid>
                            <Grid item xs>
                                <TextField
                                    label="No."
                                    id="outlined-size-small"
                                    variant="outlined"
                                    size="small"
                                    value={(this.state.beginQuestion === undefined || this.state.beginQuestion === 0) ? '' : this.state.beginQuestion + 1}
                                    disabled
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={10}>
                                <TextField
                                    label="Question"
                                    id="outlined-size-small"
                                    variant="outlined"
                                    size="small"
                                    onChange={this.handleEditQuestion}
                                    fullWidth
                                />
                            </Grid>
                            {(this.state.tempAnsArr.length === 2) &&
                                <Grid item align='right' xs={12}>
                                    <Button variant="contained" size="medium" color="primary" onClick={this.handleClearAnswer} style={{ marginRight: '5px', backgroundColor: '#76323F', color: 'white' }}>
                                        Clear
                                    </Button>
                                </Grid>
                            }
                            <Grid item xs={12}>
                                <RadioGroup
                                    style={{ marginLeft: '20px', marginTop: '10px' }}
                                >
                                    {this.state.tempChoice.map((choice, choiceIdx) =>
                                        <FormControlLabel
                                            key={`choice-${choiceIdx}`}
                                            value={this.props.questionObj.choice[choiceIdx]}
                                            control={<Radio disabled={this.state.tempAnsArr.length === 2} checked={this.state.tempAnsArr[choiceIdx] !== undefined} onChange={(event) => { this.handleSelectChoice(event, choiceIdx) }} color="default" />}
                                            label={
                                                <Grid container spacing={2}>
                                                    <Grid item align='right' xs={1} style={{ paddingTop: '1.2%' }}>
                                                        <Typography variant="h6" align='left'>
                                                            {choice}.
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item align='left' xs={11}>
                                                        <TextField
                                                            id="outlined-size-small"
                                                            variant="outlined"
                                                            size="small"
                                                            onChange={(event) => {this.handleEditChoiceText(event, choiceIdx)}}
                                                            style={{ width: '1000px' }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            }
                                        />
                                    )}
                                </RadioGroup>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

export default compose(
    withStyles(useStyles)
)(Choose2Letters);