import React, { useEffect } from 'react';

//MUI
import Popper from '@material-ui/core/Popper';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import Paper from '@material-ui/core/Paper';

import { ThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';

//Font
import 'typeface-glacial-indifference';

//Services
import { GetMeetingById, GetPurchasedTests, GetSpeakingLogById } from '../../../../../services/testServices';
import { GetPurchasedTestsByUsername } from '../../../../../services/paymentServices';

//Others
import moment from 'moment';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import ReTestDialog from "./ReTestDialog";

const axios = require('axios').default;
const FormData = require('form-data');
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

const useStyles = theme => ({
    root: {
        padding: theme.spacing(12, 4),
    }
});

const theme = createMuiTheme({
    typography: {
        fontFamily: [
            'Roboto'
        ].join(','),
    },
});

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1500 },
        items: 5
    },
    tablet: {
        breakpoint: { max: 1500, min: 464 },
        items: 3
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1
    }
};

const MyTest = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    //User
    const [filter, setFilter] = React.useState("all");
    const [filterArr, setFilterArr] = React.useState(["FULL IELTS", "IELTS NO SPK", "BMAT"]);
    const [purchasedTestArr, setPurchasedTestArr] = React.useState([]);
    const [reTestArr, setReTestArr] = React.useState([]);
    const [timeStampObj, setTimeStampObj] = React.useState({});
    const [isOpenDialog, setIsOpenDialog] = React.useState(false);
    const [isOpenDialogError, setIsOpenDialogError] = React.useState(true);
    const [dialogDescription, setDialogDescription] = React.useState("");
    const [dialogTitle, setDialogTitle] = React.useState("");
    const [zoomMeetingUrl, setZoomMeetingUrl] = React.useState("");
    const [selectedTest, setSelectedTest] = React.useState({});

    const [isOpenReTestDialog, setIsReTestOpenDialog] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(true);
    const [isProcessing, setIsProcessing] = React.useState(false);

    useEffect(async () => {
        const user = await JSON.parse(localStorage.getItem('user'));
        await getTestPurchasedTests()
    }, []);

    const getTestPurchasedTests = async () => {
        setIsLoading(true);
        let tempOBj = {};

        const purchaseddTests = await GetPurchasedTests(JSON.parse(localStorage.getItem('user')).token, JSON.parse(localStorage.getItem('user')).username);
        const paymentHistory = await GetPurchasedTestsByUsername(JSON.parse(localStorage.getItem('user')).username);
        const reTest = await getReTestSubmission();

        if (purchaseddTests.success && paymentHistory.success) {
            paymentHistory.res.map((history) => {
                tempOBj[`${history.purchasedId}`] = {
                    createAt: history.createdAt,
                    purchasedId: history.purchasedId
                }
            });

            setTimeStampObj(tempOBj);

            let results;
            if (reTest.success) {
                results = await Promise.all(purchaseddTests.res.map(async (obj) => {
                    if (obj.speaking_scheduled) {
                        const res = await GetMeetingById(obj._id);
                        if (res.success) {
                            obj['speakingId'] = `${res.res.data.schedule.id}_${res.res.data.schedule.password}`
                            // const spkLog = await GetSpeakingLogById(`${res.res.data.schedule.id}_${res.res.data.schedule.password}`);
                            // obj['isSpeakingTested'] = spkLog.success;
                        }
                    }

                    const rtObj = reTest.res.data.filter((rt) => { return rt.test_obj._id === obj._id });
                    obj['isSubmitted'] = rtObj.length > 0;
                    obj['isApproved'] = rtObj.some((t) => { return t.status === "Approved" });
                    obj['isRejected'] = rtObj.some((t) => { return t.status === "Rejected" });

                    return obj;
                }))
            } if (!reTest.success) {
                console.log(reTest.res.response.status)
                if (reTest.res.response.status === 404) {
                    results = await Promise.all(purchaseddTests.res.map(async (obj) => {
                        if (obj.speaking_scheduled) {
                            const res = await GetMeetingById(obj._id);
                            if (res.success) {
                                obj['speakingId'] = `${res.res.data.schedule.id}_${res.res.data.schedule.password}`
                            }
                        }
    
                        obj['isSubmitted'] = false;
                        obj['isApproved'] = false;
                        obj['isRejected'] = false;
    
                        return obj;
                    }))
                }
            }
            
            results = await Promise.all(results.map(async (obj) => {
                if (obj.speakingId) {
                    const spkLog = await GetSpeakingLogById(obj.speakingId);
                    obj['isSpeakingTested'] = spkLog.success;
                }

                return obj;
            }))

            setPurchasedTestArr(results);
        }

        setIsLoading(false);
    }

    const getReTestSubmission = async () => {
        try {
            const res = await axios({
                method: 'get',
                url: `https://steep-legend-midnight.glitch.me/reactivate-test?submitter=${JSON.parse(localStorage.getItem('user')).username}`
            });

            return {
                success: true,
                res: res.data
            };

            getTestPurchasedTests()

        } catch (error) {
            console.error(error);
            return {
                success: false,
                res: error
            };;
        }
    }

    const selectFilter = (filter) => {
        setFilter(filter);

        if (filter === 'all') {
            setFilterArr(["FULL IELTS", "IELTS NO SPK", "BMAT", "BMAT FULL"])
        } if (filter === 'fullielts') {
            setFilterArr(["FULL IELTS"])
        } if (filter === 'ieltsnospk') {
            setFilterArr(["IELTS NO SPK"])
        } if (filter === 'fullbmat') {
            setFilterArr(["BMAT", "BMAT FULL"])
        }

        return;
    }

    const getMeetingById = async (id) => {
        setIsProcessing(true);
        const res = await GetMeetingById(id);

        if (res.success) {
            let testTimeUp30 = moment(res.res.data.schedule.start_time).add(30, 'minutes');
            let testTimeDown30 = moment(res.res.data.schedule.start_time).subtract(30, 'minutes');

            if (moment().isAfter(testTimeDown30) && !(moment().isAfter(testTimeUp30))) {
                setDialogTitle("Your Speaking test is ready!");
                setDialogDescription(`Your Speaking test will start at ${moment(res.res.data.schedule.start_time).format('MMMM Do YYYY, h:mm:ss a')}. Please click on "Start" to begin the test.`);
                setIsOpenDialog(true);
                setIsOpenDialogError(false);
                setZoomMeetingUrl(`/test/speaking/meeting-id/${res.res.data.schedule.id}/password/${res.res.data.schedule.password}/role/0`);
            } if (moment().isAfter(testTimeUp30)) {
                setDialogTitle("Your Speaking test session is already ended.");
                setDialogDescription(`Your Speaking test is started at ${moment(res.res.data.schedule.start_time).format('MMMM Do YYYY, h:mm:ss a')}. Please re-schedule new Speaking test session.`);
                setIsOpenDialog(true);
            } if (moment().isBefore(testTimeDown30)) {
                setDialogTitle("Your Speaking test is not ready yet.");
                setDialogDescription(`Your Speaking test will start at ${moment(res.res.data.schedule.start_time).format('MMMM Do YYYY, h:mm:ss a')}. You are allow to join 30 minutes before a meeting`);
                setIsOpenDialog(true);
            }
        } if (!res.success) {
            setIsOpenDialog(true);
            setDialogTitle("Something went wrong.");
            setDialogDescription("Something went wrong. Please try again later.");
        }
        setIsProcessing(false);
    }

    const handleCloseDialog = () => {
        setIsOpenDialog(false);
        setIsReTestOpenDialog(false)
    }

    const onClickReTest = (obj) => {
        setIsReTestOpenDialog(true);
        setSelectedTest(obj);
    }

    const isReTestable = (test) => {
        return moment().isSameOrBefore(moment(timeStampObj[`${test._id}`] ? timeStampObj[`${test._id}`].createAt : test.time_stamp).add(7, 'days'));
    }

    const isTestable = (test) => {
        return moment().isSameOrBefore(moment(timeStampObj[`${test._id}`] ? timeStampObj[`${test._id}`].createAt : test.time_stamp).add(90, 'days'));
    }

    const reTestStatus = (obj) => {
        if (!obj.isSubmitted && !obj.isApproved && !obj.isRejected) {
            return "Request for re-test"
        } if (obj.isSubmitted && !obj.isApproved && !obj.isRejected) {
            return "Request has been submitted"
        } if (obj.isSubmitted && !obj.isApproved && obj.isRejected) {
            return "Rejected"
        } if (obj.isSubmitted && obj.isApproved && !obj.isRejected) {
            return "Request has been approved"
        }
    }

    const mobileUI = (<Grid item xs={12} style={{ maxWidth: window.innerWidth, marginTop: "20%" }}>
        <Grid container spacing={5}>
            <Popper id={id} open={open} anchorEl={anchorEl} transition>
                {({ TransitionProps }) => (
                    <Paper>
                        <Typography style={{ padding: '10px' }}>
                            Your speaking is expired, please read the <Link
                                to={'/faq'}
                            >FAQ #16</Link> for more clarification
                        </Typography>
                    </Paper>
                )}
            </Popper>
            {/*======================================== PURCHASED TESTS ========================================*/}
            {<Grid item align="left" xs={12} style={{ paddingLeft: "10%" }}>
                <Grid container alignItems='center' spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant="h4" style={{ fontWeight: "bold", color: "#880000" }}>
                            My Tests
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body2" style={{ color: "#a6a6a6" }}>
                            Filter by:
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Button
                            size='small'
                            variant="outlined"
                            style={{
                                textTransform: 'none',
                                borderColor: '#880000',
                                color: filter === 'all' ? 'white' : '#880000',
                                backgroundColor: filter === 'all' ? '#880000' : ''
                            }}
                            onClick={() => { selectFilter('all') }}
                        >
                            All
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            disabled={purchasedTestArr.filter((test) => ["FULL IELTS"].includes(test.test_type)).length === 0}
                            size='small'
                            variant={purchasedTestArr.filter((test) => ["FULL IELTS"].includes(test.test_type)).length === 0 ? "contained" : "outlined"}
                            style={{
                                textTransform: 'none',
                                borderColor: '#880000',
                                color: filter === 'fullielts' ? 'white' : (purchasedTestArr.filter((test) => ["FULL IELTS"].includes(test.test_type)).length === 0 ? '' : '#880000'),
                                backgroundColor: filter === 'fullielts' ? '#880000' : ''
                            }}
                            onClick={() => { selectFilter('fullielts') }}
                        >
                            IELTS
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            disabled={purchasedTestArr.filter((test) => ["IELTS NO SPK"].includes(test.test_type)).length === 0}
                            size='small'
                            variant={purchasedTestArr.filter((test) => ["IELTS NO SPK"].includes(test.test_type)).length === 0 ? "contained" : "outlined"}
                            style={{
                                textTransform: 'none',
                                borderColor: '#880000',
                                color: filter === 'ieltsnospk' ? 'white' : (purchasedTestArr.filter((test) => ["IELTS NO SPK"].includes(test.test_type)).length === 0 ? '' : '#880000'),
                                backgroundColor: filter === 'ieltsnospk' ? '#880000' : ''
                            }}
                            onClick={() => { selectFilter('ieltsnospk') }}
                        >
                            IELTS No SPK
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            disabled={purchasedTestArr.filter((test) => ["BMAT"].includes(test.test_type)).length === 0}
                            size='small'
                            variant={purchasedTestArr.filter((test) => ["BMAT"].includes(test.test_type)).length === 0 ? "contained" : "outlined"}
                            style={{
                                textTransform: 'none',
                                borderColor: '#880000',
                                color: filter === 'fullbmat' ? 'white' : (purchasedTestArr.filter((test) => ["BMAT"].includes(test.test_type)).length === 0 ? '' : '#880000'),
                                backgroundColor: filter === 'fullbmat' ? '#880000' : ''
                            }}
                            onClick={() => { selectFilter('fullbmat') }}
                        >
                            BMAT
                        </Button>
                    </Grid>
                </Grid>
            </Grid>}
            {!isLoading &&
                <Grid align="center" xs={12}>
                    <Carousel
                        responsive={responsive}
                        swipeable={true}
                        showDots={purchasedTestArr.filter((test) => filterArr.length === 0 ? true : filterArr.includes(test.test_type))?.length > 5}
                        autoPlay={false}
                        autoPlaySpeed={100000}
                    >
                        {purchasedTestArr.filter((test) => filterArr.length === 0 ? true : filterArr.includes(test.test_type))?.sort((a, b) => new Date(timeStampObj[`${b._id}`] ? timeStampObj[`${b._id}`].createAt : b.time_stamp).getTime() - new Date(timeStampObj[`${a._id}`] ? timeStampObj[`${a._id}`].createAt : a.time_stamp).getTime()).map((test, index) =>
                            <div style={{ padding: "10%" }}>
                                <Card style={{ backgroundColor: "#a6a6a6", borderRadius: "25px", padding: "5%" }}>
                                    <CardContent>
                                        <Typography variant="body1" style={{ color: "white" }} gutterBottom>
                                            Test
                                        </Typography>
                                        <Typography variant={test.test_type === "IELTS NO SPK" ? "h5" : "h4"} style={{ color: "white", fontWeight: "bold", height: "50px" }}>
                                            {test.test_type}
                                        </Typography>
                                        <Typography variant="body2" style={{ color: "white" }} gutterBottom>
                                            {test.tested ? "Tested" : "Available"}
                                        </Typography>
                                        <Typography variant="subtitle2" style={{ color: "white" }}>
                                            {moment(timeStampObj[`${test._id}`] ? timeStampObj[`${test._id}`].createAt : test.time_stamp).format('dddd Do MMM YYYY')}
                                        </Typography>
                                        <Typography variant="subtitle2" style={{ color: "white" }}>
                                            {moment(timeStampObj[`${test._id}`] ? timeStampObj[`${test._id}`].createAt : test.time_stamp).format('hh:mm:ss A')}
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                {(test.tested || !isTestable(test)) ?
                                                    <Button
                                                        disabled
                                                        variant="contained"
                                                        size="large"
                                                        style={{ backgroundColor: "#636363", color: "white", borderRadius: "10px", textTransform: "none" }}
                                                        disableElevation
                                                        fullWidth
                                                    >
                                                        {test.tested ? "Tested" : "Expired"}
                                                    </Button> : <Button
                                                        disabled
                                                        variant="contained"
                                                        size="large"
                                                        style={{ backgroundColor: "#636363", color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1rem" }}
                                                        disableElevation
                                                        fullWidth
                                                    >
                                                        Unavailable in mobile
                                                    </Button>
                                                }
                                            </Grid>
                                            <Grid item xs={12}>
                                                {(!test.speaking_scheduled && test.test_type === 'FULL IELTS') &&
                                                    (isReTestable(test) ? <Link
                                                        disabled={isReTestable(test)}
                                                        to={`/test/scheduler?id=${test._id}&purchaseId=${timeStampObj[`${test._id}`]?.purchasedId}`}
                                                        style={{ textDecoration: 'none', color: '#565656' }}
                                                    >
                                                        <Button
                                                            disabled
                                                            variant="contained"
                                                            size="large"
                                                            style={{ backgroundColor: "#636363", color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1rem" }}
                                                            disableElevation
                                                            fullWidth
                                                        >
                                                            Unavailable in mobile
                                                        </Button>
                                                    </Link> : <div aria-describedby={id}
                                                        onMouseEnter={handleClick}
                                                        onMouseLeave={handleClose}
                                                    >
                                                        <Button
                                                            disabled={true}
                                                            variant="contained"
                                                            style={{ backgroundColor: "#636363", color: "white", borderRadius: "10px", textTransform: "none" }}
                                                            disableElevation
                                                            fullWidth
                                                        >
                                                            Schedule expired
                                                        </Button>
                                                    </div>
                                                    )
                                                }
                                                {(test.speaking_scheduled && test.test_type === 'FULL IELTS') &&
                                                    (isTestable(test) ? <Button
                                                        disabled
                                                        variant="contained"
                                                        size="large"
                                                        style={{ backgroundColor: "#636363", color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1rem" }}
                                                        disableElevation
                                                        fullWidth
                                                    >
                                                        Unavailable in mobile
                                                    </Button> : <Button
                                                        disabled
                                                        variant="contained"
                                                        size="large"
                                                        style={{ backgroundColor: "#636363", color: "white", borderRadius: "10px", textTransform: "none" }}
                                                        disableElevation
                                                        fullWidth
                                                    >
                                                        Expired
                                                    </Button>)
                                                }
                                            </Grid>
                                            {test.tested && (isReTestable(test) || test.isSubmitted) && <Grid item xs={12} style={{ paddingTop: "0px" }}>
                                                <Button
                                                    disabled={!isReTestable(test) || test.isSubmitted}
                                                    variant="contained"
                                                    style={{ backgroundColor: (isReTestable(test) && !test.isSubmitted) ? "#880000" : "#636363", color: "white", borderRadius: "10px", textTransform: "none" }}
                                                    disableElevation
                                                    fullWidth
                                                    onClick={() => { onClickReTest(test) }}
                                                >
                                                    {reTestStatus(test)}
                                                </Button>
                                            </Grid>
                                            }
                                        </Grid>
                                    </CardActions>
                                </Card>
                            </div>
                        )}
                    </Carousel>
                </Grid>}

            {isLoading &&
                <Grid align="left" xs={12}>
                    <Grid item style={{ padding: "10%" }}>
                        <Card style={{ backgroundColor: "#a6a6a6", borderRadius: "25px", padding: "5%" }}>
                            <CardContent>
                                <Typography align="center" variant="body1" style={{ color: "white" }} gutterBottom>
                                    <Skeleton />
                                </Typography>
                                <Typography align="center" variant="h3" style={{ color: "white", fontWeight: "bold" }}>
                                    <Skeleton />
                                </Typography>
                                <Typography align="center" variant="subtitle2" style={{ color: "white" }} gutterBottom>
                                    <Skeleton />
                                </Typography>
                                <Typography align="center" variant="subtitle2" style={{ color: "white" }}>
                                    <Skeleton />
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Grid item xs={12}>
                                    <Link
                                        disabled={isLoading}
                                        to={`/freetests`}
                                        style={{ textDecoration: 'none', color: '#565656' }}
                                    >
                                        <Button
                                            variant="contained"
                                            size="large"
                                            style={{ backgroundColor: "#880000", color: "white", borderRadius: "10px", textTransform: "none" }}
                                            disableElevation
                                            fullWidth
                                        >
                                            Try Now
                                        </Button>
                                    </Link>
                                </Grid>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>}

            {(purchasedTestArr?.length === 0 || purchasedTestArr.filter((test) => filterArr.length === 0 ? true : filterArr.includes(test.test_type))?.length === 0) && !isLoading &&
                <Grid align="left" xs={11}>
                    <Grid item style={{ padding: "10%" }}>
                        <Card style={{ backgroundColor: "#a6a6a6", borderRadius: "25px", padding: "5%" }}>
                            <CardContent>
                                <Typography align="center" variant="body1" style={{ color: "white" }} gutterBottom>
                                    Test
                                </Typography>
                                <Typography align="center" variant="h3" style={{ color: "white", fontWeight: "bold" }}>
                                    Empty
                                </Typography>
                                <Typography align="center" variant="subtitle2" style={{ color: "white" }} gutterBottom>
                                    -
                                </Typography>
                                <Typography align="center" variant="subtitle2" style={{ color: "white" }}>
                                    -
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Grid item xs={12}>
                                    <Link
                                        disabled={isLoading}
                                        to={`/freetests`}
                                        style={{ textDecoration: 'none', color: '#565656' }}
                                    >
                                        <Button
                                            variant="contained"
                                            size="large"
                                            style={{ backgroundColor: "#880000", color: "white", borderRadius: "10px", textTransform: "none" }}
                                            disableElevation
                                            fullWidth
                                        >
                                            Try Now
                                        </Button>
                                    </Link>
                                </Grid>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
            }

            <ReTestDialog testObj={selectedTest} isOpen={isOpenReTestDialog} handleCloseDialog={handleCloseDialog} timeStampObj={timeStampObj} />

            <Backdrop open={isProcessing} style={{ zIndex: 1200, color: "#880000" }}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <Dialog
                open={isOpenDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {dialogDescription}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} variant='contained' style={{ color: 'white', backgroundColor: '#636363' }}>
                        Close
                    </Button>
                    {!isOpenDialogError &&
                        <Link
                            disabled={isLoading}
                            to={zoomMeetingUrl}
                            style={{ textDecoration: 'none', color: '#565656' }}
                        >
                            <Button
                                disabled
                                variant="contained"
                                size="large"
                                style={{ backgroundColor: "#636363", color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1rem" }}
                                disableElevation
                                fullWidth
                            >
                                Unavailable in mobile
                            </Button>
                        </Link>
                    }
                </DialogActions>
            </Dialog>
        </Grid>
    </Grid>)

    // ==================================================================== DESKTOP ==========================================================================
    const webUI = (<Grid container spacing={5} style={{ maxWidth: window.innerWidth }}>
        <Popper id={id} open={open} anchorEl={anchorEl} transition>
            {({ TransitionProps }) => (
                <Paper>
                    <Typography style={{ padding: '10px' }}>
                        Your speaking is expired, please read the <Link
                            to={'/faq'}
                        >FAQ #16</Link> for more clarification
                    </Typography>
                </Paper>
            )}
        </Popper>
        {/*======================================== PURCHASED TESTS ========================================*/}
        {<Grid item align="left" xs={12} style={{ paddingLeft: "10%" }}>
            <Grid container alignItems='center' spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h5" style={{ fontWeight: "bold", color: "#880000" }}>
                        My Tests
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body2" style={{ color: "#a6a6a6" }}>
                        Filter by:
                    </Typography>
                </Grid>
                <Grid item>
                    <Button
                        size='small'
                        variant="outlined"
                        style={{
                            textTransform: 'none',
                            borderColor: '#880000',
                            color: filter === 'all' ? 'white' : '#880000',
                            backgroundColor: filter === 'all' ? '#880000' : ''
                        }}
                        onClick={() => { selectFilter('all') }}
                    >
                        All
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        disabled={purchasedTestArr.filter((test) => ["FULL IELTS"].includes(test.test_type)).length === 0}
                        size='small'
                        variant={purchasedTestArr.filter((test) => ["FULL IELTS"].includes(test.test_type)).length === 0 ? "contained" : "outlined"}
                        style={{
                            textTransform: 'none',
                            borderColor: '#880000',
                            color: filter === 'fullielts' ? 'white' : (purchasedTestArr.filter((test) => ["FULL IELTS"].includes(test.test_type)).length === 0 ? '' : '#880000'),
                            backgroundColor: filter === 'fullielts' ? '#880000' : ''
                        }}
                        onClick={() => { selectFilter('fullielts') }}
                    >
                        IELTS
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        disabled={purchasedTestArr.filter((test) => ["IELTS NO SPK"].includes(test.test_type)).length === 0}
                        size='small'
                        variant={purchasedTestArr.filter((test) => ["IELTS NO SPK"].includes(test.test_type)).length === 0 ? "contained" : "outlined"}
                        style={{
                            textTransform: 'none',
                            borderColor: '#880000',
                            color: filter === 'ieltsnospk' ? 'white' : (purchasedTestArr.filter((test) => ["IELTS NO SPK"].includes(test.test_type)).length === 0 ? '' : '#880000'),
                            backgroundColor: filter === 'ieltsnospk' ? '#880000' : ''
                        }}
                        onClick={() => { selectFilter('ieltsnospk') }}
                    >
                        IELTS No SPK
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        disabled={purchasedTestArr.filter((test) => ["BMAT"].includes(test.test_type)).length === 0}
                        size='small'
                        variant={purchasedTestArr.filter((test) => ["BMAT"].includes(test.test_type)).length === 0 ? "contained" : "outlined"}
                        style={{
                            textTransform: 'none',
                            borderColor: '#880000',
                            color: filter === 'fullbmat' ? 'white' : (purchasedTestArr.filter((test) => ["BMAT"].includes(test.test_type)).length === 0 ? '' : '#880000'),
                            backgroundColor: filter === 'fullbmat' ? '#880000' : ''
                        }}
                        onClick={() => { selectFilter('fullbmat') }}
                    >
                        BMAT
                    </Button>
                </Grid>
            </Grid>
        </Grid>}
        {!isLoading &&
            <Grid align="center" xs={11} style={{ paddingLeft: "10%" }}>
                <Carousel
                    responsive={responsive}
                    swipeable={true}
                    showDots={purchasedTestArr.filter((test) => filterArr.length === 0 ? true : filterArr.includes(test.test_type))?.length > 5}
                    autoPlay={false}
                    autoPlaySpeed={100000}
                >
                    {purchasedTestArr.filter((test) => filterArr.length === 0 ? true : filterArr.includes(test.test_type))?.sort((a, b) => new Date(timeStampObj[`${b._id}`] ? timeStampObj[`${b._id}`].createAt : b.time_stamp).getTime() - new Date(timeStampObj[`${a._id}`] ? timeStampObj[`${a._id}`].createAt : a.time_stamp).getTime()).map((test, index) =>
                        <div>
                            <Card style={{ backgroundColor: "#a6a6a6", borderRadius: "25px", padding: "10px", minWidth: "250px", maxWidth: "250px" }}>
                                <CardContent>
                                    <Typography variant="body1" style={{ color: "white" }} gutterBottom>
                                        Test
                                    </Typography>
                                    <Typography variant={test.test_type === "IELTS NO SPK" ? "h5" : "h4"} style={{ color: "white", fontWeight: "bold", height: "50px" }}>
                                        {test.test_type}
                                    </Typography>
                                    <Typography variant="body2" style={{ color: "white" }} gutterBottom>
                                        {test.tested ? "Tested" : "Available"}
                                    </Typography>
                                    <Typography variant="subtitle2" style={{ color: "white" }}>
                                        {moment(timeStampObj[`${test._id}`] ? timeStampObj[`${test._id}`].createAt : test.time_stamp).format('dddd Do MMM YYYY')}
                                    </Typography>
                                    <Typography variant="subtitle2" style={{ color: "white" }}>
                                        {moment(timeStampObj[`${test._id}`] ? timeStampObj[`${test._id}`].createAt : test.time_stamp).format('hh:mm:ss A')}
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Grid container spacing={2}>
                                        {test.test_type === "BMAT" ? <Grid item xs={12}>
                                            {(test.tested || !isTestable(test)) ?
                                                <Button
                                                    disabled
                                                    variant="contained"
                                                    size="large"
                                                    style={{ backgroundColor: "#636363", color: "white", borderRadius: "10px", textTransform: "none" }}
                                                    disableElevation
                                                    fullWidth
                                                >
                                                    {test.tested ? "Tested" : "Expired"}
                                                </Button> : <Link
                                                    to={`/test/verification?redirectTo=/test/bmat?id=${test._id}&purchaseId=${timeStampObj[`${test._id}`]?.purchasedId}&is_free=false`}
                                                    style={{ textDecoration: 'none', color: '#565656' }}
                                                >
                                                    <Button
                                                        variant="contained"
                                                        size="large"
                                                        style={{ backgroundColor: "#880000", color: "white", borderRadius: "10px", textTransform: "none" }}
                                                        disableElevation
                                                        fullWidth
                                                    >
                                                        Start
                                                    </Button>
                                                </Link>
                                            }
                                        </Grid> : <Grid item xs={12}>
                                            {(test.tested || !isTestable(test)) ?
                                                <Button
                                                    disabled
                                                    variant="contained"
                                                    size="large"
                                                    style={{ backgroundColor: "#636363", color: "white", borderRadius: "10px", textTransform: "none" }}
                                                    disableElevation
                                                    fullWidth
                                                >
                                                    {test.tested ? "Tested" : "Expired"}
                                                </Button> : <Link
                                                    to={`/test/verification?redirectTo=/test/full-test?id=${test._id}&purchaseId=${timeStampObj[`${test._id}`]?.purchasedId}`}
                                                    style={{ textDecoration: 'none', color: '#565656' }}
                                                >
                                                    <Button
                                                        variant="contained"
                                                        size="large"
                                                        style={{ backgroundColor: "#880000", color: "white", borderRadius: "10px", textTransform: "none" }}
                                                        disableElevation
                                                        fullWidth
                                                    >
                                                        Start
                                                    </Button>
                                                </Link>
                                            }
                                        </Grid>
                                        }
                                        <Grid item xs={12}>
                                            {(!test.speaking_scheduled && test.test_type === 'FULL IELTS') &&
                                                (isReTestable(test) ? (test.isSpeakingTested ? <Button
                                                    disabled
                                                    variant="contained"
                                                    style={{ backgroundColor: "#636363", color: "white", borderRadius: "10px", textTransform: "none" }}
                                                    disableElevation
                                                    fullWidth
                                                >
                                                    Speaking tested
                                                </Button> : <Link
                                                    disabled={isReTestable(test)}
                                                    to={`/test/scheduler?id=${test._id}&purchaseId=${timeStampObj[`${test._id}`]?.purchasedId}`}
                                                    style={{ textDecoration: 'none', color: '#565656' }}
                                                >
                                                    <Button
                                                        variant="contained"
                                                        style={{ backgroundColor: "#880000", color: "white", borderRadius: "10px", textTransform: "none" }}
                                                        disableElevation
                                                        fullWidth
                                                    >
                                                        Schedule a speaking
                                                    </Button>
                                                </Link>) : <div aria-describedby={id}
                                                    onMouseEnter={handleClick}
                                                    onMouseLeave={handleClose}
                                                >
                                                    <Button
                                                        disabled={true}
                                                        variant="contained"
                                                        style={{ backgroundColor: "#636363", color: "white", borderRadius: "10px", textTransform: "none" }}
                                                        disableElevation
                                                        fullWidth
                                                    >
                                                        Schedule expired
                                                    </Button>
                                                </div>
                                                )
                                            }
                                            {(test.speaking_scheduled && test.test_type === 'FULL IELTS') &&
                                                (isTestable(test) ? (test.isSpeakingTested ? <Button
                                                    disabled
                                                    variant="contained"
                                                    style={{ backgroundColor: "#636363", color: "white", borderRadius: "10px", textTransform: "none" }}
                                                    disableElevation
                                                    fullWidth
                                                >
                                                    Speaking tested
                                                </Button> : <Button
                                                    variant="contained"
                                                    style={{ backgroundColor: "#880000", color: "white", borderRadius: "10px", textTransform: "none" }}
                                                    disableElevation
                                                    fullWidth
                                                    onClick={() => { getMeetingById(test._id) }}
                                                >
                                                    Start a Speaking
                                                </Button>) : <Button
                                                    disabled
                                                    variant="contained"
                                                    size="large"
                                                    style={{ backgroundColor: "#636363", color: "white", borderRadius: "10px", textTransform: "none" }}
                                                    disableElevation
                                                    fullWidth
                                                >
                                                    Expired
                                                </Button>)
                                            }
                                        </Grid>
                                        {test.tested && (isReTestable(test) || test.isSubmitted) && <Grid item xs={12} style={{ paddingTop: "0px" }}>
                                            <Button
                                                disabled={!isReTestable(test) || test.isSubmitted}
                                                variant="contained"
                                                style={{ backgroundColor: (isReTestable(test) && !test.isSubmitted) ? "#880000" : "#636363", color: "white", borderRadius: "10px", textTransform: "none" }}
                                                disableElevation
                                                fullWidth
                                                onClick={() => { onClickReTest(test) }}
                                            >
                                                {reTestStatus(test)}
                                            </Button>
                                        </Grid>
                                        }
                                    </Grid>
                                </CardActions>
                            </Card>
                        </div>
                    )}
                </Carousel>
            </Grid>}

        {isLoading &&
            <Grid align="left" xs={11} style={{ paddingLeft: "10%" }}>
                <Grid item>
                    <Card style={{ backgroundColor: "#a6a6a6", borderRadius: "25px", padding: "10px", width: "250px", maxWidth: "250px" }}>
                        <CardContent>
                            <Typography align="center" variant="body1" style={{ color: "white" }} gutterBottom>
                                <Skeleton />
                            </Typography>
                            <Typography align="center" variant="h3" style={{ color: "white", fontWeight: "bold" }}>
                                <Skeleton />
                            </Typography>
                            <Typography align="center" variant="subtitle2" style={{ color: "white" }} gutterBottom>
                                <Skeleton />
                            </Typography>
                            <Typography align="center" variant="subtitle2" style={{ color: "white" }}>
                                <Skeleton />
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Grid item xs={12}>
                                <Link
                                    disabled={isLoading}
                                    to={`/freetests`}
                                    style={{ textDecoration: 'none', color: '#565656' }}
                                >
                                    <Button
                                        variant="contained"
                                        size="large"
                                        style={{ backgroundColor: "#880000", color: "white", borderRadius: "10px", textTransform: "none" }}
                                        disableElevation
                                        fullWidth
                                    >
                                        Try Now
                                    </Button>
                                </Link>
                            </Grid>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>}

        {(purchasedTestArr?.length === 0 || purchasedTestArr.filter((test) => filterArr.length === 0 ? true : filterArr.includes(test.test_type))?.length === 0) && !isLoading &&
            <Grid align="left" xs={11} style={{ paddingLeft: "10%" }}>
                <Grid item>
                    <Card style={{ backgroundColor: "#a6a6a6", borderRadius: "25px", padding: "10px", width: "250px", maxWidth: "250px" }}>
                        <CardContent>
                            <Typography align="center" variant="body1" style={{ color: "white" }} gutterBottom>
                                Test
                            </Typography>
                            <Typography align="center" variant="h3" style={{ color: "white", fontWeight: "bold" }}>
                                Empty
                            </Typography>
                            <Typography align="center" variant="subtitle2" style={{ color: "white" }} gutterBottom>
                                -
                            </Typography>
                            <Typography align="center" variant="subtitle2" style={{ color: "white" }}>
                                -
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Grid item xs={12}>
                                <Link
                                    disabled={isLoading}
                                    to={`/freetests`}
                                    style={{ textDecoration: 'none', color: '#565656' }}
                                >
                                    <Button
                                        variant="contained"
                                        size="large"
                                        style={{ backgroundColor: "#880000", color: "white", borderRadius: "10px", textTransform: "none" }}
                                        disableElevation
                                        fullWidth
                                    >
                                        Try Now
                                    </Button>
                                </Link>
                            </Grid>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
        }

        <ReTestDialog testObj={selectedTest} isOpen={isOpenReTestDialog} handleCloseDialog={handleCloseDialog} timeStampObj={timeStampObj} getTestPurchasedTests={getTestPurchasedTests} />

        <Backdrop open={isProcessing} style={{ zIndex: 1200, color: "#880000" }}>
            <CircularProgress color="inherit" />
        </Backdrop>

        <Dialog
            open={isOpenDialog}
            onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {dialogDescription}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDialog} variant='contained' style={{ color: 'white', backgroundColor: '#636363' }}>
                    Close
                </Button>
                {!isOpenDialogError &&
                    <Link
                        disabled={isLoading}
                        to={zoomMeetingUrl}
                        style={{ textDecoration: 'none', color: '#565656' }}
                    >
                        <Button onClick={handleCloseDialog} variant='contained' autoFocus style={{ color: 'white', backgroundColor: '#880000' }}>
                            Start
                        </Button>
                    </Link>
                }
            </DialogActions>
        </Dialog>
    </Grid>)

    return <ThemeProvider theme={theme}>
        <Hidden mdUp>
            {mobileUI}
        </Hidden>
        <Hidden smDown>
            {webUI}
        </Hidden>
    </ThemeProvider>
};

export default compose(
    withStyles(useStyles)
)(MyTest);
