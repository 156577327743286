//React
import { Component } from 'react';
import { compose } from 'recompose';

//Material UI
import Avatar from '@material-ui/core/Avatar';
import Backdrop from '@material-ui/core/Backdrop';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

//Icon
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';
import ArrowDropUpRoundedIcon from '@material-ui/icons/ArrowDropUpRounded';

//Others libraries
import { StyleSheet } from '@react-pdf/renderer';

//PDF File
import htmlToPdfmake from 'html-to-pdfmake';
import jsPDF from 'jspdf';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import MiniReportPDF from './MiniReportPDF';

//react-router-dom
import {
    Link
} from "react-router-dom";

const axios = require('axios').default;
const FormData = require('form-data');
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%'
    }
});

// Create styles for PDF
const styles = StyleSheet.create({
    body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35
    },
    title: {
        fontSize: 24,
        textAlign: 'center',
        marginBottom: 12
    },
    author: {
        fontSize: 12,
        textAlign: 'center',
        marginBottom: 40,
    },
    subtitle: {
        fontSize: 12,
        margin: 12,
        marginBottom: 50,
        textAlign: 'center',
    },
    grammarErrCount: {
        margin: 12,
        fontSize: 14,
        textAlign: 'right',
        fontFamily: 'Times-Roman'
    },
    text: {
        margin: 12,
        fontSize: 14,
        textAlign: 'justify',
        fontFamily: 'Times-Roman'
    },
    image: {
        marginVertical: 15,
        marginHorizontal: 100,
    },
    header: {
        fontSize: 12,
        marginBottom: 20,
        textAlign: 'center',
        color: 'grey',
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    },
});

class MiniReportPage extends Component {
    constructor() {
        super();
        this.state = {
            isSeeMore: false,
            isLoading: false,
            window: {
                height: window.innerHeight,
                width: window.innerWidth
            },
            listeningRawScore: 0,
            readingRawScore: 0
        };
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    handleResize = (event) => {
        this.setState({
            window: {
                height: window.innerHeight,
                width: window.innerWidth
            }
        });
    };

    componentDidMount = async () => {
        const user = JSON.parse(localStorage.getItem('user'));
        const query = new URLSearchParams(this.props.location.search);

        if (user !== null) {
            await this.setState({
                userToken: user.token,
                test_id: query.get('test_id')
            })
        }

        await this.getUserTestReport();
    }

    getUserTestReport = async () => {
        this.handleOpenBackdrop();
        const query = new URLSearchParams(this.props.location.search);
        try {
            const res = await axios({
                method: 'get',
                url: api_base_url + '/api/test/result',
                headers: {
                    "Authorization": "Bearer " + JSON.parse(localStorage.getItem('user')).token
                }
            });

            console.log(res)

            const resData = await res.data.filter(history => history.test_id === this.state.test_id);

            await resData[0].test_result.parts.map((part, index) => {
                console.log(part.part_type);
                if (part.part_type === 'LISTENING') {
                    this.setState({
                        listeningRawScore: this.state.listeningRawScore + part.score
                    });
                }

                else if (part.part_type === 'READING') {
                    this.setState({
                        readingRawScore: this.state.readingRawScore + part.score
                    });
                }

                this.setState({
                    listeningBandScore: this.handleCalculateListeningBandScore(this.state.listeningRawScore),
                    readingBandScore: this.handleCalculateReadingBandScore(this.state.readingRawScore)
                })
            })

            this.setState({
                report: resData[0]
            });
            this.handleCloseBackdrop();

        } catch (err) {
            console.log(err);
            this.handleCloseBackdrop();
        }
    }

    handleConvertQuestionType = (question) => {
        if (question === "completeTheNotes") {
            return "Complete the notes";
        }

        else if (question === "matching") {
            return "Matching"
        }

        else if (question === "choose2Letters") {
            return "Choose 2 letters"
        }

        else if (question === "multipleChoice") {
            return "Multiple choice"
        }

        else if (question === "multipleChoice") {
            return "Multiple choice"
        }

        else if (question === "completeTheTables") {
            return "Complete the tables"
        }

        else if (question === "map") {
            return "Map"
        }

        else if (question === "yesNoNG") {
            return "Yes/No/Not Given"
        }

        else if (question === "listOfHeading") {
            return "List of heading"
        }

        else if (question === "matchingSentenceEndings") {
            return "Matching sentence endings"
        }
    }

    handleCalculateListeningBandScore = (rawScore) => {
        if (rawScore === 20) {
            return 9
        }

        else if (rawScore === 19) {
            return 8.5
        }

        else if (rawScore === 18) {
            return 8
        }

        else if (rawScore > 15 && rawScore <= 17) {
            return 7.5
        }

        else if (rawScore === 15) {
            return 7
        }

        else if (rawScore > 12 && rawScore <= 14) {
            return 6.5
        }

        else if (rawScore === 12) {
            return 6
        }

        else if (rawScore > 8 && rawScore <= 11) {
            return 5.5
        }

        else if (rawScore === 8) {
            return 5
        }

        else if (rawScore === 7) {
            return 4.5
        }

        else if (rawScore === 6) {
            return 4
        }

        else if (rawScore > 3 && rawScore <= 5) {
            return 3.5
        }

        else if (rawScore === 3) {
            return 3
        }

        else if (rawScore === 2) {
            return 2.5
        }

        else if (rawScore < 2) {
            return 0
        }
    }

    handleCalculateReadingBandScore = (rawScore) => {
        console.log(rawScore)
        if (rawScore === 20) {
            return 9
        }

        else if (rawScore === 19) {
            return 8.5
        }

        else if (rawScore === 18) {
            return 8
        }

        else if (rawScore === 17) {
            return 7.5
        }

        else if (rawScore > 14 && rawScore <= 16) {
            return 7
        }

        else if (rawScore === 14) {
            return 6.5
        }

        else if (rawScore > 11 && rawScore <= 13) {
            return 6
        }

        else if (rawScore > 9 && rawScore <= 11) {
            return 5.5
        }

        else if (rawScore > 7 && rawScore <= 9) {
            return 5
        }

        else if (rawScore > 5 && rawScore <= 7) {
            return 4.5
        }

        else if (rawScore === 5) {
            return 4
        }

        else if (rawScore === 4) {
            return 3.5
        }

        else if (rawScore === 3) {
            return 3
        }

        else if (rawScore === 2) {
            return 2.5
        }

        else if (rawScore < 2) {
            return 0
        }
    }


    handleSeeMore = (category) => {
        this.setState({
            isSeeMore: true,
            isSeeMoreType: category
        });
    }

    handleSeeLess = () => {
        this.setState({
            isSeeMore: false,
            isSeeMoreType: ''
        });
    }

    handleCloseBackdrop = () => {
        this.setState({
            isLoading: false
        });
    }

    handleOpenBackdrop = () => {
        this.setState({
            isLoading: true
        });
    }

    //PDF
    printDocument = () => {
        //const input = document.getElementById('divToPrint');
        const doc = new jsPDF();

        //get table html
        const pdfTable = document.getElementById('divToPrint');
        //html to pdf format
        var html = htmlToPdfmake(pdfTable.innerHTML);

        const documentDefinition = { content: html };
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
        pdfMake.createPdf(documentDefinition).open();

    }

    render() {
        const { classes } = this.props;
        const search = window.location.search;

        return (
            <Container>
                <Backdrop open={this.state.isLoading} onClick={this.handleCloseBackdrop} style={{ color: '#76323F', zIndex: 1 }}>
                    <CircularProgress color="inherit" />
                </Backdrop>

                <Grid container spacing={5} style={{ marginTop: '100px', marginBottom: '300px', maxWidth: this.state.window.width }}>
                    <Grid item align='left' xs={12}>
                        <Typography variant="h4" gutterBottom>
                            <Box fontWeight="fontWeightBold" display='inline'>My Report</Box>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Paper style={{ padding: '20px' }}>
                            <Grid container>
                                <Grid item align='center' xs={12}>
                                    <Avatar
                                        alt={this.props.user.name}
                                        src={this.props.user.picture}
                                        style={{
                                            width: '150px',
                                            height: '150px'
                                        }}
                                    />
                                </Grid>
                                <Grid item align='center' xs={12} style={{ marginTop: '10px' }}>
                                    <Typography variant="h6" gutterBottom>
                                        <Box fontWeight="fontWeightBold" display='inline'>{this.props.user.name}</Box>
                                    </Typography>
                                </Grid>
                                <Grid item align='center' xs={12}>
                                    <Divider style={{ marginBottom: '10px' }} />
                                </Grid>
                                <Grid item align='left' xs={12}>
                                    <Typography variant="body2" gutterBottom>
                                        <Box fontWeight="fontWeightBold" display='inline'>Test: </Box> {this.state.report === undefined ? '' : this.state.report.test_result.test_type}
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        <Box fontWeight="fontWeightBold" display='inline'>Test date: </Box> {this.state.report === undefined ? '' : new Date(this.state.report.date_created).toDateString()}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={9}>

                        <Grid item xs={12} style={{ display: 'none' }}>
                            <MiniReportPDF
                                report={this.state.report}
                                user={this.props.user}
                                readingBandScore={this.state.readingBandScore}
                                listeningBandScore={this.state.listeningBandScore}
                            />
                        </Grid>
                        <Grid item xs={12} align='right' style={{ marginBottom: '20px' }}>
                            <Button variant="outlined" onClick={this.printDocument} style={{ backgroundColor: '#76323F', color: 'white' }}>
                                View as PDF
                            </Button>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container spacing={5}>
                                <Grid item xs>
                                    <Paper style={{ padding: '20px' }}>
                                        <Typography variant="subtitle2" gutterBottom>
                                            Type
                                        </Typography>
                                        <Divider style={{ marginBottom: '10px' }} />
                                        <Typography variant="h6">
                                            <Box fontWeight="fontWeightBold" display='inline'>{this.state.report === undefined ? '' : this.state.report.test_type}</Box>
                                        </Typography>
                                    </Paper>
                                </Grid>
                                <Grid item xs>
                                    <Paper style={{ padding: '20px' }}>
                                        <Typography variant="subtitle2" gutterBottom>
                                            Full Score
                                        </Typography>
                                        <Divider style={{ marginBottom: '10px' }} />
                                        <Typography variant="h4" >
                                            <Box fontWeight="fontWeightBold" display='inline'>{this.state.report?.test_result?.total_question}</Box>
                                        </Typography>
                                    </Paper>
                                </Grid>
                                <Grid item xs>
                                    <Paper style={{ padding: '20px' }}>
                                        <Typography variant="subtitle2" gutterBottom>
                                            Your Score
                                        </Typography>
                                        <Divider style={{ marginBottom: '10px' }} />
                                        <Typography variant="h4">
                                            <Box
                                                fontWeight="fontWeightBold"
                                                display='inline'
                                            >
                                                {this.state.report === undefined ? '' : this.state.report.test_result.total_score}
                                            </Box> {this.state.report !== undefined && this.state.report.test_result.total_score > this.state.report.test_result.total_question ?
                                                <ArrowDropUpRoundedIcon style={{ color: '#00A300' }} /> : <ArrowDropDownRoundedIcon style={{ color: '#D10000' }} />
                                            }
                                        </Typography>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} style={{ marginTop: '5%', marginBottom: '5%' }}>
                            <Typography variant="h4">
                                Percentage = {this.state.report === undefined ? '' : Math.trunc((this.state.report.test_result.total_score / this.state.report.test_result.total_question) * 100)}%
                            </Typography>
                        </Grid>

                        {this.state.report !== undefined && this.state.report.test_type === 'IELTS LISTENING FREE' &&
                            <Grid item xs={12} style={{ marginTop: '20px' }}>
                                <Paper style={{ padding: '20px', backgroundColor: '#76323F', color: 'white' }}>
                                    <Typography variant="h4">
                                        You are likely to get band score of {this.state.listeningBandScore}.
                                    </Typography>
                                </Paper>
                            </Grid>
                        }

                        {this.state.report !== undefined && this.state.report.test_type === 'IELTS READING FREE' &&
                            <Grid item xs={12} style={{ marginTop: '20px' }}>
                                <Paper style={{ padding: '20px', backgroundColor: '#76323F', color: 'white' }}>
                                    <Typography variant="h4">
                                        You are likely to get band score of {this.state.readingBandScore}.
                                    </Typography>
                                </Paper>
                            </Grid>
                        }


                        {/*================================================= BAND SCORE ===========================================================*/}
                        {/*this.state.report !== undefined && this.state.report.test_result.parts.map((part, partIndex) =>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={12} style={{ marginTop: '20px' }}>
                                        <Paper style={{ padding: '20px', backgroundColor: '#76323F', color: 'white' }}>
                                            <Grid container>
                                                <Grid item align='left' xs={12}>
                                                    <Typography variant="subtitle2" gutterBottom>
                                                        <Box fontWeight="fontWeightBold" display='inline'>Part {partIndex + 1} score</Box>
                                                    </Typography>
                                                    <Divider style={{ marginBottom: '10px', backgroundColor: 'white' }} />
                                                </Grid>
                                                <Grid item xs style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    borderRadius: 5
                                                }}>
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle2" gutterBottom>
                                                                Raw score
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h4" gutterBottom>
                                                                <Box fontWeight="fontWeightBold" display='inline'>{this.state.report === undefined ? '' : part.score}</Box>
                                                                <Box display='inline' style={{ fontSize: '1rem' }}>{this.state.report === undefined ? '' : '/' + part.question}</Box>
                                                                <Box display='inline' style={{ fontSize: '1rem' }}>{this.state.report === undefined ? '' : ' (' + Math.trunc((part.score / part.question) * 100) + '%)'}</Box>
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                    {part.section.map((section, secIndex) =>
                                        <Grid item xs={12} style={{ marginTop: '20px' }}>
                                            <Paper style={{ padding: '20px', borderTop: '5px solid rgb(241,234,235)' }}>
                                                <Grid container>
                                                    <Grid item align='left' xs={12}>
                                                        <Typography variant="subtitle2" gutterBottom>
                                                            <Box fontWeight="fontWeightBold" display='inline'>Section {secIndex + 1}</Box>
                                                        </Typography>
                                                        <Divider style={{ marginBottom: '10px' }} />
                                                    </Grid>
                                                    <Grid item xs style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        borderRadius: 5
                                                    }}>
                                                        <Grid container>
                                                            <Grid item xs={12}>
                                                                <Typography variant="subtitle2" gutterBottom>
                                                                    Question type
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Typography variant="h5" gutterBottom>
                                                                    <Box fontWeight="fontWeightBold" display='inline'>{section === undefined ? '' : this.handleConvertQuestionType(section.question_type)}</Box>
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Divider variant="middle" orientation="vertical" flexItem />
                                                    <Grid item xs style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        borderRadius: 5
                                                    }}>
                                                        <Grid container>
                                                            <Grid item xs={12}>
                                                                <Typography variant="subtitle2" gutterBottom>
                                                                    Full score
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Typography variant="h4" gutterBottom>
                                                                    <Box fontWeight="fontWeightBold" display='inline'>{section === undefined ? '' : section.question}</Box> 
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Divider variant="middle" orientation="vertical" flexItem />
                                                    <Grid item xs style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        borderRadius: 5
                                                    }}>
                                                        <Grid container>
                                                            <Grid item xs={12}>
                                                                <Typography variant="subtitle2" gutterBottom>
                                                                    Your score
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Typography variant="h4" gutterBottom>
                                                                    <Box fontWeight="fontWeightBold" display='inline'>{section === undefined ? '' : section.score}</Box>
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Divider variant="middle" orientation="vertical" flexItem />
                                                    <Grid item xs style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        borderRadius: 5
                                                    }}>
                                                        <Grid container>
                                                            <Grid item xs={12}>
                                                                <Typography variant="subtitle2" gutterBottom>
                                                                    Percentage
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Typography variant="h4" gutterBottom>
                                                                    <Box fontWeight="fontWeightBold" display='inline'>{section === undefined ? '' : Math.trunc((section.score / section.question) * 100) + '%'}</Box>
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                                                )*/}
                        {/*================================================= BAND SCORE ===========================================================*/}

                        <Grid item xs={12} style={{ marginTop: '20px' }}>
                            <Link to="/" style={{ textDecoration: 'none', color: '#0f0f0f' }}>
                                <Button variant="outlined" >
                                    Home
                                </Button>
                            </Link>
                            <Link to="/myprofile" style={{ textDecoration: 'none', color: '#0f0f0f', marginLeft: '20px' }}>
                                <Button variant="outlined" style={{ backgroundColor: '#76323F', color: 'white' }}>
                                    Bactk to My Profile
                                </Button>
                            </Link>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        )
    }
}

export default compose(
    withStyles(useStyles)
)(MiniReportPage);