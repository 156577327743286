//React
import { Component } from 'react';
import { compose } from 'recompose';

//Material UI
import Backdrop from '@material-ui/core/Backdrop';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';

//import components
import { CreateSpeakingLog, GetSpeakingInformation, GetSpeakingLogById, GetSpeakingQuestion } from '../../services/testServices';
import ZoomSDK from './IELTS/speaking/ZoomSDK';
import Notes from './IELTS/speaking/components/Notes';
import TextEditor from './IELTS/speaking/components/TextEditor';
import "./css/TestSpeaking.css";

const axios = require('axios').default;
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%'
    }
});

class TestSpeaking extends Component {
    constructor() {
        super();
        this.state = {
            joinMeeting: false,
            isSubmitting: false,
            drawer: false,
            timer: 1800,
            timerState: "STOP",
            selectedTab: "notes",
            scoreObj: {
                score: 0,
                fluency_and_coherence: "",
                lexical_resource: "",
                gramatical_range_and_accuracy: "",
                pronunciation: "",
                comment: ""
            },
            meetingObj: {},
            isLoaded: false,
            comments: "",
            notes: ""
        };
    }

    componentDidMount = async () => {
        await this.getSpeakingQuestions();
        await this.getSpeakinginformation();
        await this.getSpeakingTestLog();
        this.setState({
            isLoaded: true
        });
    }

    getSpeakingQuestions = async () => {
        const res = await GetSpeakingQuestion();

        if (res.success) {
            this.setState({
                notes: res.res[0].questions[0].questions
            });
        }

        return;
    }

    getSpeakingTestLog = async () => {
        const res = await GetSpeakingLogById(`${this.props.match.params.id}_${this.props.match.params.password}`);

        if (res.success) {
            this.setState({
                scoreObj: res.res.score
            });
        } if (!res.success) {
            this.createSpeakingLog();
        }
    }

    getSpeakinginformation = async () => {
        const res = await GetSpeakingInformation(this.props.match.params.id, this.props.match.params.password);
        
        if (res.success) {
            this.setState({
                meetingObj: res.res
            });
        }
    }

    createSpeakingLog = async () => {
        this.setState({
            isSubmitting: true
        });

        const res = await CreateSpeakingLog({
            id: `${this.props.match.params.id}_${this.props.match.params.password}`,
            user_email: this.state.meetingObj.submitter,
            user_name: this.state.meetingObj.schedule.topic,
            teacher_username: this.state.meetingObj.assignTo,
            teacher_name: this.state.meetingObj.schedule.host_email,
            score: this.state.scoreObj,
            time_left: this.state.timer
        });

        this.setState({
            isSubmitting: false
        });

        this.getSpeakinginformation();
    }

    joinMeeting = (toggleStatus) => {
        this.setState({
            joinMeeting: toggleStatus
        });
    }

    handleToggleDrawer = async (type) => {
        await this.setState({
            selectedTab: type
        });

        this.setState({
            drawer: !this.state.drawer
        });
    }

    handleChangeComments = (event) => {
        this.setState({
            comments: event.target.value
        });
    }

    handleChangeTextField = (event) => {
        this.state.scoreObj[event.target.name] = event.target.value;
        this.setState({
            scoreObj: this.state.scoreObj
        });
    }

    handleTimerControl = (state) => {
        this.setState({
            timerState: state
        });
    }

    handleStartTimer = () => {
        this.setState({
            timerState: "STARTING"
        });

        this.timer();
    }

    timer = () => {
        this.myInterval = setInterval(() => {
            if (this.state.timer > 0 && this.state.timerState === "STARTING") {
                this.setState(prevState => ({
                    timer: prevState.timer - 1
                }))
            }
        }, 1000);
    }

    handleAddLeadingZero = (num) => {
        if (num < 10) {
            return '0' + num
        } else if (num >= 10) {
            return '' + num
        }
    }

    render() {
        const notes = (<Drawer
            variant="permanent"
            anchor="right"
        >
            <Grid container style={{ width: '500px' }}>
                <Grid item align='center' xs={6} style={{ marginTop: '50px' }}>
                    <Typography variant="h5">
                        <Box fontWeight="fontWeightBold" display='inline'>Speaking dialog</Box>
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Grid container style={{ marginTop: '20px' }}>
                        <Grid item align="center" xs={12}>
                            <Typography variant="h5" style={{ fontWeight: 'bold' }}>
                                Timer
                            </Typography>
                        </Grid>
                        <Grid item align="center" xs={12}>
                            <Typography variant="h6">
                                {this.handleAddLeadingZero(Math.floor(this.state.timer / 3600))} : {this.state.timer === 3600 ? '00' : this.handleAddLeadingZero(Math.floor(this.state.timer / 60))} : {this.handleAddLeadingZero(this.state.timer % 60)} minutes left
                            </Typography>
                        </Grid>
                        <Grid item align="center" xs={12}>
                            {this.state.timerState === "STOP" &&
                                <Button
                                    size="small"
                                    color="primary"
                                    variant="contained"
                                    align='center'
                                    onClick={this.handleStartTimer}
                                    style={{ width: '50%', textTransform: 'none', backgroundColor: '#991514' }}
                                >
                                    Start
                                </Button>
                            }
                            {this.state.timerState === "PAUSE" &&
                                <Button
                                    size="small"
                                    color="primary"
                                    variant="contained"
                                    align='center'
                                    onClick={() => { this.handleTimerControl("STARTING") }}
                                    style={{ width: '50%', textTransform: 'none', backgroundColor: '#991514' }}
                                >
                                    Resume
                                </Button>
                            }
                        </Grid>
                        <Grid item align="center" xs={12}>
                            {!(this.state.timerState === "STOP") && !(this.state.timerState === "PAUSE") &&
                                <Button
                                    size="small"
                                    color="primary"
                                    variant="contained"
                                    align='center'
                                    onClick={() => { this.handleTimerControl("PAUSE") }}
                                    style={{ width: '50%', textTransform: 'none', backgroundColor: '#991514' }}
                                >
                                    Pause
                                </Button>
                            }
                            {/*!(this.state.timerState === "STOP") && this.state.timerState === "PAUSE" &&
                            <Button
                                size="small"
                                color="primary"
                                variant="contained"
                                align='center'
                                onClick={() => { this.handleTimerControl("PAUSE") }}
                                style={{ width: '50%', textTransform: 'none' }}
                            >
                                STOP
                            </Button>
                    */}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{ paddingTop: '30px' }}>
                    <TextEditor notes={this.state.notes} />
                </Grid>
                <Grid item xs={12} style={{
                    position: 'fixed', position: 'absolute', left: '50%', top: '75%',
                    transform: 'translate(-50%, -50%)'
                }}>
                    <Typography variant="h6">
                        <Box fontWeight="fontWeightBold" display='inline'>Notes & Comments</Box>
                    </Typography>
                    <TextField
                        name="comment"
                        autoComplete='off'
                        id="outlined-multiline-static"
                        label="Notes & Comments"
                        multiline
                        value={this.state.scoreObj.comment === undefined ? "" : this.state.scoreObj.comment}
                        rows={20}
                        variant="outlined"
                        style={{
                            width: '500px'
                        }}
                        onChange={this.handleChangeTextField}
                    />
                </Grid>
                <Grid item xs={12} style={{
                    position: 'fixed', position: 'absolute', left: '50%', top: '97%',
                    transform: 'translate(-50%, -50%)'
                }}>
                    <Button
                        color="primary"
                        variant="contained"
                        align='center'
                        onClick={this.handleToggleDrawer}
                        style={{ textTransform: 'none', backgroundColor: '#991514' }}
                    >
                        Hide notes
                    </Button>
                </Grid>
            </Grid>
        </Drawer>)

        const score = (<Drawer
            variant="permanent"
            anchor="right"
        >
            <Grid container style={{ width: '500px' }}>
                <Grid item align='center' xs={6} style={{ marginTop: '50px' }}>
                    <Typography variant="h5">
                        <Box fontWeight="fontWeightBold" display='inline'>Speaking scoring</Box>
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Grid container style={{ marginTop: '20px' }}>
                        <Grid item align="center" xs={12}>
                            <Typography variant="h5" style={{ fontWeight: 'bold' }}>
                                Timer
                            </Typography>
                        </Grid>
                        <Grid item align="center" xs={12}>
                            <Typography variant="h6">
                                {this.handleAddLeadingZero(Math.floor(this.state.timer / 3600))} : {this.state.timer === 3600 ? '00' : this.handleAddLeadingZero(Math.floor(this.state.timer / 60))} : {this.handleAddLeadingZero(this.state.timer % 60)} minutes left
                            </Typography>
                        </Grid>
                        <Grid item align="center" xs={12}>
                            {this.state.timerState === "STOP" &&
                                <Button
                                    size="small"
                                    color="primary"
                                    variant="contained"
                                    align='center'
                                    onClick={this.handleStartTimer}
                                    style={{ width: '50%', textTransform: 'none', backgroundColor: '#991514' }}
                                >
                                    Start
                                </Button>
                            }
                            {this.state.timerState === "PAUSE" &&
                                <Button
                                    size="small"
                                    color="primary"
                                    variant="contained"
                                    align='center'
                                    onClick={() => { this.handleTimerControl("STARTING") }}
                                    style={{ width: '50%', textTransform: 'none', backgroundColor: '#991514' }}
                                >
                                    Resume
                                </Button>
                            }
                        </Grid>
                        <Grid item align="center" xs={12}>
                            {!(this.state.timerState === "STOP") && !(this.state.timerState === "PAUSE") &&
                                <Button
                                    size="small"
                                    color="primary"
                                    variant="contained"
                                    align='center'
                                    onClick={() => { this.handleTimerControl("PAUSE") }}
                                    style={{ width: '50%', textTransform: 'none', backgroundColor: '#991514' }}
                                >
                                    Pause
                                </Button>
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{ paddingTop: '30px', paddingLeft: '20px', paddingRight: '20px' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography align="left" variant="body1" pattern="[0-9]*" style={{ fontWeight: 'bold' }}>
                                Score
                            </Typography>
                            <TextField
                                name="score"
                                autoComplete='off'
                                size="small"
                                value={this.state.scoreObj.score === undefined ? "" : this.state.scoreObj.score}
                                type="number"
                                id="outlined-basic"
                                label="Score.."
                                variant="outlined"
                                onChange={this.handleChangeTextField}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography align="left" variant="body1" style={{ fontWeight: 'bold' }}>
                                Fluency and Coherence
                            </Typography>
                            <TextField
                                name="fluency_and_coherence"
                                autoComplete='off'
                                value={this.state.scoreObj.fluency_and_coherence === undefined ? "" : this.state.scoreObj.fluency_and_coherence}
                                size="small"
                                type="number"
                                id="outlined-basic"
                                label="Fluency and Coherence.."
                                variant="outlined"
                                onChange={this.handleChangeTextField}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography align="left" variant="body1" style={{ fontWeight: 'bold' }}>
                                Lexical Resource
                            </Typography>
                            <TextField
                                name="lexical_resource"
                                autoComplete='off'
                                value={this.state.scoreObj.lexical_resource === undefined ? "" : this.state.scoreObj.lexical_resource}
                                size="small"
                                type="number"
                                id="outlined-basic"
                                label="Lexical Resource.."
                                variant="outlined"
                                onChange={this.handleChangeTextField}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography align="left" variant="body1" style={{ fontWeight: 'bold' }}>
                                Gramatical Range and Accuracy
                            </Typography>
                            <TextField
                                name="gramatical_range_and_accuracy"
                                autoComplete='off'
                                value={this.state.scoreObj.gramatical_range_and_accuracy === undefined ? "" : this.state.scoreObj.gramatical_range_and_accuracy}
                                size="small"
                                type="number"
                                id="outlined-basic"
                                label="Gramatical Range and Accuracy.."
                                variant="outlined"
                                onChange={this.handleChangeTextField}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography align="left" variant="body1" style={{ fontWeight: 'bold' }}>
                                Pronunciation
                            </Typography>
                            <TextField
                                name="pronunciation"
                                autoComplete='off'
                                value={this.state.scoreObj.pronunciation === undefined ? "" : this.state.scoreObj.pronunciation}
                                size="small"
                                type="number"
                                id="outlined-basic"
                                label="Pronunciation.."
                                variant="outlined"
                                onChange={this.handleChangeTextField}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography align="left" variant="body1" style={{ fontWeight: 'bold' }}>
                                Comment
                            </Typography>
                            <TextField
                                name="comment"
                                autoComplete='off'
                                value={this.state.scoreObj.comment === undefined ? "" : this.state.scoreObj.comment}
                                size="small"
                                type="input"
                                id="outlined-basic"
                                label="Comment.."
                                variant="outlined"
                                fullWidth
                                onChange={this.handleChangeTextField}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container justify='center' alignItems='center' spacing={1}>
                                <Grid item>
                                    <Button
                                        disabled={this.state.isSubmitting}
                                        color="primary"
                                        variant="contained"
                                        style={{ textTransform: 'none', backgroundColor: this.state.isSubmitting ? "#d8d6d8" : '#991514' }}
                                        onClick={() => { this.createSpeakingLog() }}
                                    >
                                        Save
                                    </Button>
                                </Grid>
                                <Grid item>
                                    {this.state.isSubmitting && <CircularProgress size={30} style={{ color: "#991514" }} />}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{
                    position: 'fixed', position: 'absolute', left: '50%', top: '97%',
                    transform: 'translate(-50%, -50%)'
                }}>
                    <Button
                        disableElevation
                        color="primary"
                        variant="contained"
                        align='center'
                        onClick={this.handleToggleDrawer}
                        style={{ textTransform: 'none', backgroundColor: '#d8d6d8' }}
                    >
                        Hide {this.state.selectedTab}
                    </Button>
                </Grid>
            </Grid>
        </Drawer>)

        return (
            <Grid container style={{ backgroundColor: '#a6a6a6', paddingTop: '400px' }}>
                {this.state.drawer && this.props.match.params.role === '1' && this.state.selectedTab === 'notes' &&
                    <Notes
                        scoreObj={this.state.scoreObj}
                        notes={this.state.notes}
                        timerState={this.state.timerState}
                        timer={this.state.timer}
                        isSubmitting={this.state.isSubmitting}
                        handleAddLeadingZero={this.handleAddLeadingZero}
                        handleChangeTextField={this.handleChangeTextField}
                        handleToggleDrawer={this.handleToggleDrawer}
                        handleTimerControl={this.handleTimerControl}
                        handleStartTimer={this.handleStartTimer}
                        createSpeakingLog={this.createSpeakingLog}
                    />
                }

                {this.state.drawer && this.props.match.params.role === '1' && this.state.selectedTab === 'score' &&
                    score
                }

                {(this.props.match.params.role === '1' && !this.state.drawer) &&
                    <Button
                        disableElevation
                        color="primary"
                        variant="contained"
                        align='center'
                        style={{
                            zIndex: 1200, position: 'fixed', position: 'absolute', right: '-50px', top: '45%',
                            transform: 'translate(-50%, -50%)', textTransform: 'none', backgroundColor: '#991514'
                        }}
                        onClick={() => { this.handleToggleDrawer("score") }}
                        startIcon={<ArrowBackIosRoundedIcon />}
                    >
                        Score
                    </Button>
                }
                {(this.props.match.params.role === '1' && !this.state.drawer) &&
                    <Button
                        disableElevation
                        color="primary"
                        variant="contained"
                        align='center'
                        style={{
                            zIndex: 1200, position: 'fixed', position: 'absolute', right: '-50px', top: '50%',
                            transform: 'translate(-50%, -50%)', textTransform: 'none', backgroundColor: '#991514'
                        }}
                        onClick={() => { this.handleToggleDrawer("notes") }}
                        startIcon={<ArrowBackIosRoundedIcon />}
                    >
                        Notes
                    </Button>
                }
                <Grid item xs={12} style={{ marginTop: '300px', marginBottom: '300px' }}>
                    {/*<ZoomMeetingRoom
                        meetingNumber={this.props.match.params.id}
                        password={this.props.match.params.password}
                        role={this.props.match.params.role}
                        userEmail={this.props.user.email}
                        userName={this.props.user.name}
                        scoreObj={this.state.scoreObj}
                        meetingObj={this.state.meetingObj}
                        createSpeakingLog={this.createSpeakingLog}
            />*/}
                    {this.state.isLoaded &&
                        <ZoomSDK
                            meetingNumber={this.props.match.params.id}
                            password={this.props.match.params.password}
                            role={this.props.match.params.role}
                            userEmail={this.state.meetingObj.submitter}
                            userName={JSON.parse(localStorage.getItem('user')).name}
                            scoreObj={this.state.scoreObj}
                            meetingObj={this.state.meetingObj}
                            createSpeakingLog={this.createSpeakingLog}
                        />
                    }
                </Grid>

                <Backdrop open={!this.state.isLoaded} style={{ zIndex: 1200, color: '#991514' }}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Grid>
        )
    }
}

export default compose(
    withStyles(useStyles)
)(TestSpeaking);
