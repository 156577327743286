//React
import { Component } from 'react';
import { compose } from 'recompose';

//Material UI
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Skeleton from '@material-ui/lab/Skeleton';

//import components
import PageBackdrop from '../../../main/PageBackdrop';

//react-router-dom
import {
    Link
} from "react-router-dom";

//Others
import { GetSpeakingLogByTeacher } from '../../../../services/testServices';
const axios = require('axios').default;
const FormData = require('form-data');
const FileDownload = require('js-file-download');

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%'
    }
});

class AllSpeakingScoring extends Component {
    constructor() {
        super();
        this.state = {
            numberOfTest: 0,
            selectedMenu: 'assignedTest',
            filterType: 'all',
            filteredTestStatus: 'all',
            today: new Date(Date.now()),
            isScoring: false
        };
    }

    componentDidMount() {
        this.getAllSpeakingScoring();
    }

    getAllSpeakingScoring = async () => {
        const res = await GetSpeakingLogByTeacher(/*JSON.parse(localStorage.getItem('user')).username*/"pigboss1")

        if (res.success) {
            this.setState({
                scoreArr: res.res.data
            });
        }
    }

    handleChange = (event) => {
        const name = event.target.name;

        this.setState({
            ...this.state,
            [name]: event.target.value
        });
    };

    handleSeeMoreTest = () => {
        if (this.state.numberOfTest + 10 >= this.state.tests.length) {
            this.setState({
                numberOfTest: this.state.tests.length
            });

            return;
        }

        this.setState({
            numberOfTest: this.state.numberOfTest + 10
        });
    }

    handleSeeLessTest = () => {
        if (this.state.numberOfTest - 10 <= 10) {
            this.setState({
                numberOfTest: 10
            });

            return;
        }

        this.setState({
            numberOfTest: this.state.numberOfTest - 10
        });
    }

    handleCloseScoringDialog = () => {
        this.setState({
            isScoring: false
        });
    }

    handleLoading = () => {
        this.setState({
            isLoading: true,
        });
    }

    handleEndLoading = () => {
        this.setState({
            isLoading: false,
        });
    }

    handleAddLeadingZero = (num) => {
        if (num < 10) {
            return '0' + num
        } else if (num >= 10) {
            return '' + num
        }
    }

    render() {
        const { classes } = this.props;

        return (
            <Grid item xs={12} sm={9}>
                <PageBackdrop isLoading={this.state.isLoaing} />
                <Grid container>
                    <Grid item xs={12}>
                        <Typography align='left' variant="h5" gutterBottom>
                            <Box fontWeight="fontWeightBold" display='inline'>My Assigned Speaking Tests</Box>
                        </Typography>
                        <Paper variant="outlined" style={{ overflowY: 'auto', maxHeight: '800px' }}>
                            {this.state.scoreArr !== undefined ?
                                (this.state.scoreArr/*.sort((a, b) => new Date(a.createdAt) < new Date(b.createdAt) ? 1 : -1)*/
                                    .map((test, index) =>
                                        <Accordion>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Grid container spacing={0}>
                                                    <Grid align='left' item xs={12} sm>
                                                        <Typography>{test.user_name}'s speaking test</Typography>
                                                    </Grid>
                                                </Grid>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid container spacing={2} style={{ paddingLeft: '20px' }}>
                                                    <Grid item xs={12}>
                                                        <Grid container spacing={1}>
                                                            <Grid item>
                                                                <Typography variant='body1' style={{ fontWeight: 'bold' }}>Tester:</Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <Typography variant='body1'>{test.user_name}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Grid container spacing={1}>
                                                            <Grid item>
                                                                <Typography variant='body1' style={{ fontWeight: 'bold' }}>Tester Email:</Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <Typography variant='body1'>{test.user_email}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Grid container spacing={1}>
                                                            <Grid item>
                                                                <Typography variant='body1' style={{ fontWeight: 'bold' }}>Teacher:</Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <Typography variant='body1'>{test.teacher_name}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Grid container spacing={1}>
                                                            <Grid item>
                                                                <Typography variant='body1' style={{ fontWeight: 'bold' }}>Duration:</Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <Typography variant='body1'>{this.handleAddLeadingZero(Math.floor((1800 - Number(test.time_left)) / 3600))} : {(1800 - Number(test.time_left)) === 3600 ? '00' : this.handleAddLeadingZero(Math.floor((1800 - Number(test.time_left)) / 60))} : {this.handleAddLeadingZero((1800 - Number(test.time_left)) % 60)} minutes</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item align='right' xs={12}>
                                                        <Link
                                                            to={`/test/speaking/scoring?testid=&tester=${test.user_name}&comments=${test.score.comment}&id=${test.id.split('_')[0]}&password=${test.id.split('_')[1]}`}
                                                            target="_blank"
                                                            style={{ textDecoration: 'none' }}
                                                        >
                                                            <Button
                                                                disableElevation
                                                                size="small"
                                                                variant="contained"
                                                                style={{ marginRight: '10px', backgroundColor: '#76323F', color: 'white' }}
                                                            >
                                                                Submit Score
                                                            </Button>
                                                        </Link>
                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    )) : (
                                    <Grid>
                                        <Skeleton variant="rect" width="100%" height={40} />
                                    </Grid>
                                )}
                        </Paper>
                        {this.state.scoreArr?.length === 0 && <Paper variant="outlined" style={{ overflowY: 'auto', maxHeight: '800px', marginTop: '10px' }}>
                            <Accordion>
                                <AccordionSummary
                                    disabled
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Grid container spacing={0}>
                                        <Grid align='left' item xs={12} sm>
                                            <Typography>You have no Speaking scoring.</Typography>
                                        </Grid>
                                    </Grid>
                                </AccordionSummary>
                            </Accordion>
                        </Paper>

                        }
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

export default compose(
    withStyles(useStyles)
)(AllSpeakingScoring);