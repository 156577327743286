import * as apiConstants from '../constants/apiConstants';

const axios = require('axios').default;

export async function GetAllTeachers() {
    try {
        const res = await axios({
            method: 'get',
            url: `${apiConstants.TEST_MANAGEMENT_API_BASE_URL}/teachers`
        });

        return {
            success: true,
            res: res.data
        }
    } catch (err) {
        console.log(err);
        return {
            success: false,
            res: err
        }
    }
}