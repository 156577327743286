import React, { useEffect } from "react";

//MUI
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";

import { ThemeProvider, createMuiTheme, withStyles } from "@material-ui/core/styles";
import { compose } from "recompose";

//Font
import "typeface-glacial-indifference";

//Components
import BandScoreDescription from "./components/fullReportPage/BandScoreDescription";
import OverallScore from "./components/fullReportPage/OverallScore";
import ScoreVisualization from "./components/fullReportPage/ScoreVisualization";
import SelectTestType from "./components/fullReportPage/SelectTestType";
import UserProfile from "./components/fullReportPage/UserProfile";

//Funtions
import { capitalize } from '../../../models/publicFuntions';
import { AgeCalculation } from '../../../functions/userFunctions';
import { CheckDisplaySection, MapBandScoreDescription, MapReadingBandScore, RoundBand, SplitPascalCaseToString } from "../../../functions/testReportFuntions";

//Services
import {
  GetSpeakingNotes,
  GetTestReportByResultId,
  GetUserInfoByUsername,
  GetWritingNotes,
} from "../../../services/testReportServices";

//PDF File
import pdfMake from "pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

//Others
import moment from 'moment';
import { Link } from "react-router-dom";
import PageBackdrop from "../PageBackdrop";

const axios = require("axios").default;
const FormData = require("form-data");
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

const useStyles = (theme) => ({
  root: {
    padding: theme.spacing(12, 4),
  },
});

const theme = createMuiTheme({
  typography: {
    fontFamily: ["Roboto"].join(","),
  },
});

const NewFullReportPage = (props) => {
  const [userObj, setUserObj] = React.useState({});
  const [reportObj, setReportObj] = React.useState({});
  const [listeningObj, setListeningObj] = React.useState([]);
  const [bandScoreObj, setBandScoreObj] = React.useState({});
  const [writingNotesObj, setWritingNotesObj] = React.useState({});
  const [speakingNotesObj, setSpeakingNotesObj] = React.useState({});
  const [selectedTestType, setSelectedTestType] = React.useState("listening");
  const [isLoading, setIsLoading] = React.useState(false);
  const [isDownloading, setIsDownloading] = React.useState(false);
  const [isGotWritingResult, setIsGotWritingResult] = React.useState(false);
  const [isGotSpeakingResult, setIsGotSpeakingResult] = React.useState(false);

  useEffect(async () => {
    document.body.style.overflow = "auto";
    document.body.style.backgroundColor = "#efefef";
    document.querySelector("body").scrollTo(0, 0);

    getTestReportById();
  }, []);

  const getTestReportById = async () => {
    setIsLoading(true);

    const query = new URLSearchParams(props.location.search);
    const res = await GetTestReportByResultId(
      JSON.parse(localStorage.getItem("user")).token,
      query.get("result_id")
    );

    if (res.success) {
      await setReportObj(res.res);
      await getWritingNotes(query.get("result_id"), res.res.username);
      await getSpeakingNotes(res.res.test_result?.test_purchase_id);
      await getUserInfo(res.res.username);
      getTestById(res.res);
    }

    setIsLoading(false);
  };

  const getUserInfo = async (username) => {
    const res = await GetUserInfoByUsername(JSON.parse(localStorage.getItem('user')).token, username);
    const userObj = res.res;
    const age = await AgeCalculation(res.res.birthDate)
    const firstname = await capitalize(res.res.firstname);
    const lastname = await capitalize(res.res.lastname);
    userObj["name"] = `${firstname} ${lastname}`
    userObj["initial"] = `${res.res.firstname[0]}${res.res.lastname[0]}`;
    userObj["age"] = age

    setUserObj(userObj);
  }

  const getWritingNotes = async (test_id, username) => {
    const res = await GetWritingNotes(
      JSON.parse(localStorage.getItem("user")).token,
      username,
      test_id
    );

    if (res.success) {
      await setWritingNotesObj(res.res.note[0]);
      await setIsGotWritingResult(true);
    }
    if (!res.success) {
      await setIsGotWritingResult(false);
    }
  };

  const getSpeakingNotes = async (purchaseId) => {
    const res = await GetSpeakingNotes(JSON.parse(localStorage.getItem("user")).token, purchaseId);
    if (res.success) {
      setSpeakingNotesObj(res.res.data);
      setIsGotSpeakingResult(true);
    } else {
      setIsGotSpeakingResult(false);
      return;
    }
  };

  const calculateOverallBand = () => {
    const listeningScore = reportObj?.test_result?.score?.listening?.score;
    const readingScore = reportObj?.test_result?.score?.reading?.score;
    const speakingScore = isGotSpeakingResult ? speakingNotesObj?.note?.score : 0;
    const writingScore = isGotWritingResult ? writingNotesObj?.note?.score : 0;
    const overallBand = RoundBand((Number(listeningScore) + Number(readingScore) + Number(writingScore) + Number(speakingScore)) / (2 + (isGotSpeakingResult ? 1 : 0) + (isGotWritingResult ? 1 : 0)));

    return overallBand;
  }

  const checkReadingPercentage = (questions, score) => {
    let totalQuestion = 0;

    questions.map((question) => {
      const questionsArr = question.split(' ')[1].split('-');
      const questionCount = Number(questionsArr[1]) - Number(questionsArr[0]);

      totalQuestion += questionCount;
    })

    return Math.round((score / totalQuestion) * 100);
  }

  const validateExistingType = (type) => {
    const section = reportObj?.test_result?.score?.reading?.section.filter((section) => section.no === type)[0];
    if (section) {
      return CheckDisplaySection(section, 'reading');
    } else {
      return false;
    }


    /*reportObj?.test_result?.score?.reading?.section?.map((section) => {
      const validation = CheckDisplaySection(section, 'reading');

      if (validation) {
        return section.no === type;
      }
      return false;
    })
    return false;*/
  }

  const getTestById = async (reportObj) => {
    const res = await axios({
      method: 'get',
      url: `${api_base_url}/api/test/?test_id=${reportObj.test_id}`,
      headers: {
        "Authorization": `Bearer ${JSON.parse(localStorage.getItem('user')).token}`
      }
    });

    const resData = res.data[0];
    const listeningParts = resData.parts.filter((part) => { return part.part_type === "LISTENING" });

    setListeningObj(listeningParts);
  }

  const checkListeningPercentage = (section, score) => {
    return Math.round((score / listeningObj[section.no - 1].total_question) * 100);
  }

  const checkIfString = (input) => {
    return isNaN(parseFloat(input))
  }

  const exportPdf = () => {
    setIsDownloading(true);

    const overallScore = calculateOverallBand();

    const documentDefinition = {
      content: [
        {
          margin: [0, 0, 0, 0],
          image: 'image1',
          width: 150,
          height: 80,
          style: 'image'
        },
        {
          text: 'IELTS Simulation Test Report',
          margin: [0, 0, 0, 0],
          fontSize: 20,
          bold: true,
          alignment: 'center'
        },
        {
          style: 'table',
          table: {
            widths: [300, '*'],
            body: [
              [{ text: 'User profile', bold: true, alignment: 'center' }, { text: 'Overall band score', bold: true, alignment: 'center' }],
              [{
                ul: [
                  {
                    text: [
                      { text: 'Name: ', bold: true },
                      `${userObj?.name}`,
                    ],
                    margin: [5, 15, 0, 5],
                  },
                  {
                    text: [
                      { text: 'Email: ', bold: true },
                      `${userObj?.email}`,
                    ],
                    style: "infoText"
                  },
                  {
                    text: [
                      { text: 'School: ', bold: true },
                      `${userObj?.school}`,
                    ],
                    style: "infoText"
                  },
                  {
                    text: [
                      { text: 'Expected Band score: ', bold: true },
                      `${reportObj?.test_result?.test_survey ? reportObj.test_result.test_survey.selectedExpectedScore : "-"}`,
                    ],
                    style: "infoText"
                  },
                  {
                    text: [
                      { text: 'Test date: ', bold: true },
                      `${moment(reportObj?.test_result?.test_started ? reportObj.test_result.test_started : reportObj.date_created).format('MMMM Do YYYY, h:mm:ss a')}`,
                    ],
                    style: "infoText"
                  },
                ]
              },
              [{
                style: 'overallScoreTable',
                table: {
                  widths: ['*', '*'],
                  heights: ['auto', 20],
                  body: [
                    [{ text: 'Listening', bold: true, alignment: 'center' }, { text: 'Reading', bold: true, alignment: 'center' }],
                    [{ text: reportObj?.test_result?.score?.listening?.score, alignment: 'center' },
                    { text: reportObj?.test_result?.score?.reading?.score, alignment: 'center' }]
                  ]
                },
              }, {
                style: 'overallScoreTable',
                table: {
                  widths: ['*', '*'],
                  heights: ['auto', 20],
                  body: [
                    [{ text: 'Writing', bold: true, alignment: 'center' }, { text: 'Speaking', bold: true, alignment: 'center' }],
                    [{ text: isGotWritingResult ? writingNotesObj?.note?.score : "-", alignment: 'center' },
                    { text: isGotSpeakingResult ? speakingNotesObj?.note?.score : "-", alignment: 'center' }]
                  ]
                },
              }, {
                style: 'overallScoreTable',
                table: {
                  widths: [50, '*'],
                  body: [
                    [{ text: 'Overall', bold: true, alignment: 'center' }, { text: `${overallScore}`, alignment: 'center' }]
                  ]
                },
              }
              ]],
            ]
          }
        },
        {
          style: 'table',
          table: {
            widths: ['*'],
            heights: ['auto'],
            body: [
              [{ text: 'Overall Band Score Description', bold: true }],
              [{
                text: MapBandScoreDescription(overallScore),
                margin: [0, 10, 0, 10],
              }]
            ]
          },
        },
        {
          bold: true,
          fontSize: 18,
          margin: [0, 20, 0, 0],
          text: "Skill Analysis Section"
        },
        // LISTENING
        {
          bold: true,
          fontSize: 15,
          margin: [0, 10, 0, 0],
          text: "Listening"
        },
        {
          style: 'table',
          table: {
            widths: ['*'],
            heights: ['auto'],
            body: [
              [{ text: 'Introduction', bold: true }],
              [{
                text: reportObj?.test_result?.score?.listening?.score_introduction,
                margin: [0, 10, 0, 10],
              }]
            ]
          },
        },
        reportObj?.test_result?.score?.listening?.section?.map((section) => {
          return {
            style: 'table',
            table: {
              widths: ['*'],
              heights: ['auto'],
              body: [
                [{ text: `Section: ${section.no} (Your score: ${checkListeningPercentage(section, section.raw_score)}%)`, bold: true }],
                [{
                  fontSize: 12,
                  text: MapReadingBandScore(section, 'listening', section.description),
                  margin: [0, 10, 0, 10],
                }]
              ]
            },
          }
        }),
        // READING
        {
          bold: true,
          fontSize: 15,
          margin: [0, 10, 0, 0],
          text: "Reading"
        },
        {
          bold: true,
          fontSize: 12,
          margin: [0, 10, 0, 0],
          text: "Areas of Improvement"
        },
        {
          margin: [0, 10, 0, 10],
          ul: [
            validateExistingType('InformationIdentification') ? { text: [{ text: 'X  ', bold: true }, { text: 'Information Identification' }], listType: 'none' } : { text: '', listType: 'none' },
            validateExistingType('ListOfHeadings') ? { text: [{ text: 'X  ', bold: true }, { text: 'List Of Headings' }], listType: 'none' } : { text: '', listType: 'none' },
            validateExistingType('NoteAndSummaryCompletion') ? { text: [{ text: 'X  ', bold: true }, { text: 'Note and Summary Completion' }], listType: 'none' } : { text: '', listType: 'none' },
            validateExistingType('MatchingSentenceEndings') ? { text: [{ text: 'X  ', bold: true }, { text: 'Matching Sentence Endings' }], listType: 'none' } : { text: '', listType: 'none' },
            validateExistingType('MatchingFeatures') ? { text: [{ text: 'X  ', bold: true }, { text: 'Matching Features' }], listType: 'none' } : { text: '', listType: 'none' },
            validateExistingType('MultipleChoices') ? { text: [{ text: 'X  ', bold: true }, { text: 'Multiple Choices' }], listType: 'none' } : { text: '', listType: 'none' },
          ]
        },
        {
          style: 'table',
          table: {
            widths: ['*'],
            heights: ['auto'],
            body: [
              [{ text: 'Introduction', bold: true }],
              [{
                text: reportObj?.test_result?.score?.reading?.score_introduction,
                margin: [0, 10, 0, 10],
              }]
            ]
          },
        },
        reportObj?.test_result?.score?.reading?.section?.map((section) => {
          return {
            style: 'table',
            table: {
              widths: ['*'],
              heights: ['auto'],
              body: [
                [{ text: `Section: ${SplitPascalCaseToString(section.no)} (Your score: ${checkReadingPercentage(section.question, section.raw_score)}%)`, bold: true }],
                [{
                  fontSize: 12,
                  text: MapReadingBandScore(section, 'reading', section.description),
                  margin: [0, 10, 0, 10],
                }]
              ]
            },
          }
        })
      ],
      images: {
        image1: "https://i.ibb.co/0jnfJPJ/download-16x9.png",
        image2: "https://i.ibb.co/KKTrVyG/Band-Score-Table.png",
      },
      styles: {
        image: {
          alignment: 'center'
        },
        table: {
          margin: [0, 5, 0, 15]
        },
        overallScoreTable: {
          margin: [0, 5, 0, 5]
        },
        infoText: {
          margin: [5, 5, 0, 5]
        }
      }
    };

    if (isGotWritingResult) {
      documentDefinition.content.push({
        bold: true,
        fontSize: 15,
        margin: [0, 10, 0, 0],
        text: "Writing"
      });
      documentDefinition.content.push({
        style: 'table',
        table: {
          widths: ['*'],
          heights: ['auto'],
          body: [
            [{ text: 'Introduction', bold: true }],
            [
              {
                text: `IELTS consists of two tasks: Report and Academic Essay. Task 1 tests the candidate's ability to recognise the given information's key features and write a 150-word report,  using appropriate vocabulary and correct grammar.
                
                However, Task 2 assesses the candidates' skills in interpreting, forming ideas, and writing one academic essay with a minimum of 250 words, using a wide range of vocabulary and grammar.
                
                Although Writing for IELTS does not reflect a complete set of skills needed for actual academic writing, it frames a comprehensive evaluation of writing skills in the English language.
                
                This section of the IELTS test is evaluated manually by two examiners. Both writing tasks are evaluated separately using 4 writing criteria: task achievement, cohesion and coherence, lexical resource, and grammatical range and accuracy.`,
                margin: [0, 10, 0, 10],
              },
              // {
              //   ol: [
              //     {
              //       text: "IELTS consists of two tasks: Report and Academic Essay. Task 1 tests the candidate's ability to recognise the given information's key features and write a 150-word report,  using appropriate vocabulary and correct grammar.",
              //       margin: [0, 10, 0, 0],
              //       listType: 'none'
              //     },
              //     {
              //       text: "However, Task 2 assesses the candidates' skills in interpreting, forming ideas, and writing one academic essay with a minimum of 250 words, using a wide range of vocabulary and grammar.",
              //       margin: [0, 5, 0, 0],
              //       listType: 'none'
              //     },
              //     {
              //       text: "Although Writing for IELTS does not reflect a complete set of skills needed for actual academic writing, it frames a comprehensive evaluation of writing skills in the English language.",
              //       margin: [0, 5, 0, 0],
              //       listType: 'none'
              //     },
              //     {
              //       text: "This section of the IELTS test is evaluated manually by two examiners. Both writing tasks are evaluated separately using 4 writing criteria: task achievement, cohesion and coherence, lexical resource, and grammatical range and accuracy.",
              //       margin: [0, 5, 0, 10],
              //       listType: 'none'
              //     }
              //   ]
              // }
            ]
          ]
        },
      });
      documentDefinition.content.push({
        style: 'table',
        table: {
          widths: ['*'],
          heights: ['auto'],
          body: [
            [{ text: "Examiner's comment", bold: true }],
            [{
              text: writingNotesObj?.note?.comment,
              margin: [0, 5, 0, 10],
            }]
          ]
        },
      });
      //TASK 1
      documentDefinition.content.push({
        bold: true,
        fontSize: 13,
        margin: [0, 10, 0, 0],
        text: "Task 1"
      });
      documentDefinition.content.push({
        margin: [0, 10, 0, 0],
        columns: [
          {
            width: 170,
            fontSize: 10,
            text: 'Task Achievement'
          },
          {
            width: '*',
            canvas: [{ type: 'line', x1: 0, y1: 0, x2: 300 * (parseFloat(checkIfString(writingNotesObj?.note?.task_1?.task_achievement) ? 0 : writingNotesObj?.note?.task_1?.task_achievement) / 9), y2: 0, lineWidth: 10 }],
            margin: [0, 7, 0, 0],
          },
          {
            width: 20,
            text: parseFloat(checkIfString(writingNotesObj?.note?.task_1?.task_achievement) ? 0 : writingNotesObj?.note?.task_1?.task_achievement)
          },
        ]
      });
      documentDefinition.content.push({
        margin: [0, 5, 0, 0],
        columns: [
          {
            width: 170,
            fontSize: 10,
            text: 'Cohesion and Coherence'
          },
          {
            width: '*',
            canvas: [{ type: 'line', x1: 0, y1: 0, x2: 300 * (parseFloat(checkIfString(writingNotesObj?.note?.task_1?.cohesion_and_coherence) ? 0 : writingNotesObj?.note?.task_1?.cohesion_and_coherence) / 9), y2: 0, lineWidth: 10 }],
            margin: [0, 7, 0, 0],
          },
          {
            width: 20,
            text: parseFloat(checkIfString(writingNotesObj?.note?.task_1?.cohesion_and_coherence) ? 0 : writingNotesObj?.note?.task_1?.cohesion_and_coherence)
          },
        ]
      });
      documentDefinition.content.push({
        margin: [0, 5, 0, 0],
        columns: [
          {
            width: 170,
            fontSize: 10,
            text: 'Lexical Resource'
          },
          {
            width: '*',
            canvas: [{ type: 'line', x1: 0, y1: 0, x2: 300 * (parseFloat(checkIfString(writingNotesObj?.note?.task_1?.lexical_resource) ? 0 : writingNotesObj?.note?.task_1?.lexical_resource) / 9), y2: 0, lineWidth: 10 }],
            margin: [0, 7, 0, 0],
          },
          {
            width: 20,
            text: parseFloat(checkIfString(writingNotesObj?.note?.task_1?.lexical_resource) ? 0 : writingNotesObj?.note?.task_1?.lexical_resource)
          },
        ]
      });
      documentDefinition.content.push({
        margin: [0, 5, 0, 0],
        columns: [
          {
            width: 170,
            fontSize: 10,
            text: 'Grammatical Range and Accuracy'
          },
          {
            width: '*',
            canvas: [{ type: 'line', x1: 0, y1: 0, x2: 300 * (parseFloat(checkIfString(writingNotesObj?.note?.task_1?.grammatical_range_and_accuracy) ? 0 : writingNotesObj?.note?.task_1?.grammatical_range_and_accuracy) / 9), y2: 0, lineWidth: 10 }],
            margin: [0, 7, 0, 0],
          },
          {
            width: 20,
            text: parseFloat(checkIfString(writingNotesObj?.note?.task_1?.grammatical_range_and_accuracy) ? 0 : writingNotesObj?.note?.task_1?.grammatical_range_and_accuracy)
          },
        ]
      });
      //TASK 2
      documentDefinition.content.push({
        bold: true,
        fontSize: 13,
        margin: [0, 10, 0, 0],
        text: "Task 2"
      });
      documentDefinition.content.push({
        margin: [0, 10, 0, 0],
        columns: [
          {
            width: 170,
            fontSize: 10,
            text: 'Task Achievement'
          },
          {
            width: '*',
            canvas: [{ type: 'line', x1: 0, y1: 0, x2: 300 * (parseFloat(checkIfString(writingNotesObj?.note?.task_2?.task_achievement) ? 0 : writingNotesObj?.note?.task_2?.task_achievement) / 9), y2: 0, lineWidth: 10 }],
            margin: [0, 7, 0, 0],
          },
          {
            width: 20,
            text: parseFloat(checkIfString(writingNotesObj?.note?.task_2?.task_achievement) ? 0 : writingNotesObj?.note?.task_2?.task_achievement)
          },
        ]
      });
      documentDefinition.content.push({
        margin: [0, 5, 0, 0],
        columns: [
          {
            width: 170,
            fontSize: 10,
            text: 'Cohesion and Coherence'
          },
          {
            width: '*',
            canvas: [{ type: 'line', x1: 0, y1: 0, x2: 300 * (parseFloat(checkIfString(writingNotesObj?.note?.task_2?.cohesion_and_coherence) ? 0 : writingNotesObj?.note?.task_2?.cohesion_and_coherence) / 9), y2: 0, lineWidth: 10 }],
            margin: [0, 7, 0, 0],
          },
          {
            width: 20,
            text: parseFloat(checkIfString(writingNotesObj?.note?.task_2?.cohesion_and_coherence) ? 0 : writingNotesObj?.note?.task_2?.cohesion_and_coherence)
          },
        ]
      });
      documentDefinition.content.push({
        margin: [0, 5, 0, 0],
        columns: [
          {
            width: 170,
            fontSize: 10,
            text: 'Lexical Resource'
          },
          {
            width: '*',
            canvas: [{ type: 'line', x1: 0, y1: 0, x2: 300 * (parseFloat(checkIfString(writingNotesObj?.note?.task_2?.lexical_resource) ? 0 : writingNotesObj?.note?.task_2?.lexical_resource) / 9), y2: 0, lineWidth: 10 }],
            margin: [0, 7, 0, 0],
          },
          {
            width: 20,
            text: parseFloat(checkIfString(writingNotesObj?.note?.task_2?.lexical_resource) ? 0 : writingNotesObj?.note?.task_2?.lexical_resource)
          },
        ]
      });
      documentDefinition.content.push({
        margin: [0, 5, 0, 0],
        columns: [
          {
            width: 170,
            fontSize: 10,
            text: 'Grammatical Range and Accuracy'
          },
          {
            width: '*',
            canvas: [{ type: 'line', x1: 0, y1: 0, x2: 300 * (parseFloat(checkIfString(writingNotesObj?.note?.task_2?.grammatical_range_and_accuracy) ? 0 : writingNotesObj?.note?.task_2?.grammatical_range_and_accuracy) / 9), y2: 0, lineWidth: 10 }],
            margin: [0, 7, 0, 0],
          },
          {
            width: 20,
            text: parseFloat(checkIfString(writingNotesObj?.note?.task_2?.grammatical_range_and_accuracy) ? 0 : writingNotesObj?.note?.task_2?.grammatical_range_and_accuracy)
          },
        ]
      });
    } if (isGotSpeakingResult) {
      documentDefinition.content.push({
        bold: true,
        fontSize: 15,
        margin: [0, 10, 0, 0],
        text: "Speaking"
      });
      documentDefinition.content.push({
        style: 'table',
        table: {
          widths: ['*'],
          heights: ['auto'],
          body: [
            [{ text: 'Introduction', bold: true }],
            [
              {
                text: `The IELTS Speaking test consists of 3 parts: short interview, long turn, and discussion. The score is evaluated holistically using the 4 criteria: fluency and coherence, lexical resource, grammatical range and accuracy, and pronunciation.
                
                This section of the IELTS test is evaluated manually.`,
                margin: [0, 10, 0, 10],
              },
              // {
              //   ol: [
              //     {
              //       text: "The IELTS Speaking test consists of 3 parts: short interview, long turn, and discussion. The score is evaluated holistically using the 4 criteria: fluency and coherence, lexical resource, grammatical range and accuracy, and pronunciation.",
              //       margin: [0, 10, 0, 0],
              //       listType: 'none'
              //     },
              //     {
              //       text: "This section of the IELTS test is evaluated manually.",
              //       margin: [0, 5, 0, 10],
              //       listType: 'none'
              //     }
              //   ]
              // }
            ]
          ]
        },
      });
      documentDefinition.content.push({
        style: 'table',
        table: {
          widths: ['*'],
          heights: ['auto'],
          body: [
            [{ text: "Examiner's comment", bold: true }],
            [{
              text: speakingNotesObj?.note?.comment,
              margin: [0, 5, 0, 10],
            }]
          ]
        },
      });
      documentDefinition.content.push({
        bold: true,
        fontSize: 13,
        margin: [0, 10, 0, 0],
        text: "Speaking Criteria"
      });
      documentDefinition.content.push({
        margin: [0, 10, 0, 0],
        columns: [
          {
            width: 170,
            fontSize: 10,
            text: 'Fluency & Coherence'
          },
          {
            width: '*',
            canvas: [{ type: 'line', x1: 0, y1: 0, x2: 300 * (parseFloat(checkIfString(speakingNotesObj?.note?.fluency_and_coherence) ? 0 : speakingNotesObj?.note?.fluency_and_coherence) / 9), y2: 0, lineWidth: 10 }],
            margin: [0, 7, 0, 0],
          },
          {
            width: 20,
            text: parseFloat(checkIfString(speakingNotesObj?.note?.fluency_and_coherence) ? 0 : speakingNotesObj?.note?.fluency_and_coherence)
          },
        ]
      });
      documentDefinition.content.push({
        margin: [0, 5, 0, 0],
        columns: [
          {
            width: 170,
            fontSize: 10,
            text: 'Lexical Resource'
          },
          {
            width: '*',
            canvas: [{ type: 'line', x1: 0, y1: 0, x2: 300 * (parseFloat(checkIfString(speakingNotesObj?.note?.lexical_resource) ? 0 : speakingNotesObj?.note?.lexical_resource) / 9), y2: 0, lineWidth: 10 }],
            margin: [0, 7, 0, 0],
          },
          {
            width: 20,
            text: parseFloat(checkIfString(speakingNotesObj?.note?.lexical_resource) ? 0 : speakingNotesObj?.note?.lexical_resource)
          },
        ]
      });
      documentDefinition.content.push({
        margin: [0, 5, 0, 0],
        columns: [
          {
            width: 170,
            fontSize: 10,
            text: 'Grammatical Range & Accuracy'
          },
          {
            width: '*',
            canvas: [{ type: 'line', x1: 0, y1: 0, x2: 300 * (parseFloat(checkIfString(speakingNotesObj?.note?.gramatical_range_and_accuracy) ? 0 : speakingNotesObj?.note?.gramatical_range_and_accuracy) / 9), y2: 0, lineWidth: 10 }],
            margin: [0, 7, 0, 0],
          },
          {
            width: 20,
            text: parseFloat(checkIfString(speakingNotesObj?.note?.gramatical_range_and_accuracy) ? 0 : speakingNotesObj?.note?.gramatical_range_and_accuracy)
          },
        ]
      });
      documentDefinition.content.push({
        margin: [0, 5, 0, 0],
        columns: [
          {
            width: 170,
            fontSize: 10,
            text: 'Pronunciation'
          },
          {
            width: '*',
            canvas: [{ type: 'line', x1: 0, y1: 0, x2: 300 * (parseFloat(checkIfString(speakingNotesObj?.note?.pronunciation) ? 0 : speakingNotesObj?.note?.pronunciation) / 9), y2: 0, lineWidth: 10 }],
            margin: [0, 7, 0, 0],
          },
          {
            width: 20,
            text: parseFloat(checkIfString(speakingNotesObj?.note?.pronunciation) ? 0 : speakingNotesObj?.note?.pronunciation)
          },
        ]
      });
    }

    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
    pdfDocGenerator.open();
    setIsDownloading(false);
  }

  const mobileUI = (
    <Grid
      container
      style={{
        marginTop: "10px",
        marginBottom: "20%",
        maxWidth: window.innerWidth,
      }}
    >
      <Grid item align="right" xs={12} style={{ padding: "10px", paddingTop: "0px" }}>
        {!isLoading &&
          <Button
            variant="contained"
            size="small"
            style={{
              backgroundColor: "#a00506",
              color: "white",
              textTransform: "none",
              fontSize: "1rem",
            }}
            onClick={exportPdf}
            disableElevation
          >
            PDF
          </Button>
        }
      </Grid>
      <Grid item align="center" xs={12}>
        <Box
          component="img"
          style={{ width: "60px" }}
          alt="Logo"
          src="/U Test Logo (new).png"
        />
        <Typography
          variant="h5"
          style={{ color: "#26343f", fontWeight: "bold" }}
          gutterBottom
        >
          IELTS Simulation Test Report
        </Typography>
      </Grid>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} style={{ marginTop: "20px" }}>
            <UserProfile reportObj={reportObj} userObj={userObj} isLoading={isLoading} />
          </Grid>
          <Grid item xs={12}>
            <OverallScore
              reportObj={reportObj}
              isLoading={isLoading}
              isGotWritingResult={isGotWritingResult}
              writingNotesObj={writingNotesObj}
              isGotSpeakingResult={isGotSpeakingResult}
              speakingNotesObj={speakingNotesObj}
            />
          </Grid>
          <Grid item xs={12}>
            <BandScoreDescription
              selectedTestType={selectedTestType}
              reportObj={reportObj}
              bandScoreObj={bandScoreObj}
              isLoading={isLoading}
              isGotWritingResult={isGotWritingResult}
              writingNotesObj={writingNotesObj}
            />
          </Grid>
          <Grid item xs={12}>
            {Object.keys(reportObj).length !== 0 && <ScoreVisualization
              setSelectedTestType={setSelectedTestType}
              isLoading={isLoading}
              isGotWritingResult={isGotWritingResult}
              selectedTestType={selectedTestType}
              reportObj={reportObj}
              bandScoreObj={bandScoreObj}
              writingNotesObj={writingNotesObj}
              isGotSpeakingResult={isGotSpeakingResult}
              speakingNotesObj={speakingNotesObj}
            />
            }
          </Grid>
          <Grid item xs={12}>
            <Link
              to={`/profile`}
              style={{ textDecoration: "none", color: "#565656" }}
            >
              <Button
                variant="contained"
                size="large"
                style={{
                  backgroundColor: "#a6a6a6",
                  color: "white",
                  borderRadius: "10px",
                  textTransform: "none",
                  fontSize: "1rem",
                }}
                disableElevation
              >
                Back
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );

  const webUI = (
    <Grid
      container
      style={{
        marginTop: "2%",
        marginBottom: "20%",
        maxWidth: window.innerWidth,
      }}
    >
      <Container>
        <Grid item align="right" xs={12} style={{ padding: "10px", paddingBottom: "0px", paddingTop: "0px" }}>
          {!isLoading &&
            <Button
              variant="contained"
              size="small"
              style={{
                backgroundColor: "#a00506",
                color: "white",
                textTransform: "none",
                fontSize: "1rem",
              }}
              onClick={exportPdf}
              disableElevation
            >
              PDF
            </Button>
          }
        </Grid>
        <Grid item align="center" xs={12} style={{ marginBottom: "50px" }}>
          <Grid container>
            <Grid item align="center" xs={12}>
              <Grid container justify="center" >
                <Grid item>
                  <Box
                    component="img"
                    style={{ width: "60px" }}
                    alt="Logo"
                    src="/U Test Logo (new) square.png"
                  />

                </Grid>
                <Grid item>
                  <Box
                    component="img"
                    style={{ width: "100px" }}
                    alt="Logo"
                    src="/U Test Logo (new) text.png"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h5"
                style={{ color: "#26343f", fontWeight: "bold" }}
                gutterBottom
              >
                IELTS Simulation Test Report
              </Typography>
            </Grid>
          </Grid>
        </Grid>

      </Container>
      <Container>
        <Grid container spacing={1}>
          <Grid item xs={8}>
            <UserProfile reportObj={reportObj} userObj={userObj} isLoading={isLoading} />
          </Grid>
          <Grid item xs={4}>
            <OverallScore
              reportObj={reportObj}
              isLoading={isLoading}
              isGotWritingResult={isGotWritingResult}
              writingNotesObj={writingNotesObj}
              isGotSpeakingResult={isGotSpeakingResult}
              speakingNotesObj={speakingNotesObj}
            />
          </Grid>
          <Grid item xs={12}>
            <BandScoreDescription
              selectedTestType={selectedTestType}
              reportObj={reportObj}
              bandScoreObj={bandScoreObj}
              isLoading={isLoading}
              isGotWritingResult={isGotWritingResult}
              isGotSpeakingResult={isGotSpeakingResult}
              speakingNotesObj={speakingNotesObj}
              writingNotesObj={writingNotesObj}
            />
          </Grid>
          <Grid item xs={12}>
            {Object.keys(reportObj).length !== 0 && <ScoreVisualization
              setSelectedTestType={setSelectedTestType}
              isLoading={isLoading}
              isGotWritingResult={isGotWritingResult}
              selectedTestType={selectedTestType}
              reportObj={reportObj}
              bandScoreObj={bandScoreObj}
              writingNotesObj={writingNotesObj}
              isGotSpeakingResult={isGotSpeakingResult}
              speakingNotesObj={speakingNotesObj}
            />
            }
          </Grid>
          <Grid item xs={12}>
            <Link
              to={`/profile`}
              style={{ textDecoration: "none", color: "#565656" }}
            >
              <Button
                variant="contained"
                size="large"
                style={{
                  backgroundColor: "#a6a6a6",
                  color: "white",
                  borderRadius: "10px",
                  textTransform: "none",
                  fontSize: "1rem",
                }}
                disableElevation
              >
                Back
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );

  return (
    <ThemeProvider theme={theme}>
      <PageBackdrop isLoading={isDownloading} />
      <Hidden mdUp>{mobileUI}</Hidden>
      <Hidden smDown>{webUI}</Hidden>
    </ThemeProvider>
  );
};

export default compose(withStyles(useStyles))(NewFullReportPage);
