import React, { useEffect } from "react";

//MUI
import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Popper from "@material-ui/core/Popper";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import FiberManualRecordRoundedIcon from "@material-ui/icons/FiberManualRecordRounded";
import NotificationsRoundedIcon from "@material-ui/icons/NotificationsRounded";

import {
    ThemeProvider,
    createMuiTheme,
    withStyles,
} from "@material-ui/core/styles";
import { compose } from "recompose";

//Font
import "typeface-glacial-indifference";

//Others
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import {
    ClearAllNotification,
    GetUserNotifications,
    ReadNotification,
} from "../../../services/notificationServices";
import { HashLink } from "react-router-hash-link";
import MediaQuery from "react-responsive";

const useStyles = (theme) => ({
    root: {
        flexGrow: 1,
    },
});

const theme = createMuiTheme({
    typography: {
        fontFamily: ["Roboto"].join(","),
    },
});

const NewNavBar = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [logoutDialogStatus, setLogoutDialog] = React.useState(false);
    const [userSignedIn, setUserSignedIn] = React.useState(false);
    const [notificationArr, setNotificationArr] = React.useState([]);
    const history = useHistory();

    useEffect(() => {
        setUserSignedIn(
            JSON.parse(localStorage.getItem("userSignedIn"))
                ? JSON.parse(localStorage.getItem("userSignedIn"))
                : false
        );

        if (JSON.parse(localStorage.getItem("userSignedIn"))) {
            getUserNotifications();
        }
    }, []);

    const getUserNotifications = async () => {
        const res = await GetUserNotifications(
            JSON.parse(localStorage.getItem("user")).username
        );
        if (res.success) {
            setNotificationArr(res.res);
        }

        setInterval(async () => {
            if (
                window.location.pathname !== "/test/full-test" &&
                JSON.parse(localStorage.getItem("user")) !== null
            ) {
                const res = await GetUserNotifications(
                    JSON.parse(localStorage.getItem("user")).username
                );
                if (res.success) {
                    setNotificationArr(res.res);
                }
            }
        }, 30000);
    };

    const handleCloseLogoutDialog = () => {
        setLogoutDialog(false);
    };

    const handleOpenLogoutDialog = () => {
        setLogoutDialog(true);
    };

    const handleLogout = async () => {
        await localStorage.setItem("userSignedIn", false);
        await localStorage.setItem("user", null);
        setUserSignedIn(false);
        handleCloseLogoutDialog();
        // history.push("/");
        window.location.href = "/";
    };

    const handleClickNotification = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleCloseNotification = () => {
        setAnchorEl(null);
    };

    const handleClickNotificationList = async (type, content, id, isRead) => {
        if (type === "free_report") {
            if (!isRead) {
                await ReadNotification(id);
            }
            history.push("/report?result_id=" + content.result_id);
            handleCloseNotification();
        }
        if (type === "full_report") {
            if (!isRead) {
                await ReadNotification(id);
            }
            history.push("/full-report?result_id=" + content.result_id);
            handleCloseNotification();
        }
        if (type === "writing_score") {
            if (!isRead) {
                await ReadNotification(id);
            }
            history.push("/full-report?result_id=" + content.test_id);
            handleCloseNotification();
        }
        if (type === "teacher_writing") {
            if (!isRead) {
                await ReadNotification(id);
            }
            history.push("/teacher/dashboard");
            handleCloseNotification();
        } if (type === "bmat_writing_score") {
            if (!isRead) {
                await ReadNotification(id);
            }
            history.push("/full-bmat-report?result_id=" + content.test_id);
            handleCloseNotification();
        }

        const res = await GetUserNotifications(
            JSON.parse(localStorage.getItem("user")).username
        );
        if (res.success) {
            setNotificationArr(res.res);
        }
    };

    const handleClickClearAllNotification = async () => {
        const res = await ClearAllNotification(JSON.parse(localStorage.getItem("user")).username);
        console.log(res);

        if (res.success) {
            getUserNotifications();
        }
    }

    const classes = useStyles();

    const mobileUI = (
        <AppBar
            position="static"
            style={{
                backgroundColor: "#efefef",
                boxShadow: "0px 0px",
                borderBottom: "1px solid #a6a6a6",
                borderRadius: "0px",
                borderBottomLength: "90%",
                maxWidth: window.innerWidth,
                zIndex: 1,
            }}
        >
            <Toolbar>
                <Grid container alignItems="center">
                    {!props.isTest && (
                        <Grid item align="left" xs={2}>
                            <Link align="left" to="/" style={{ textDecoration: "none" }}>
                                <Avatar
                                    variant="square"
                                    src="/U Test Logo (new) square.png"
                                    style={{ height: "30px", width: "30px" }}
                                />
                            </Link>
                        </Grid>
                    )}

                    {props.isTest && (
                        <Grid item align="left">
                            <Avatar
                                variant="square"
                                src="/U Test Logo (new) square.png"
                                style={{ height: "30px", width: "30px" }}
                            />
                        </Grid>
                    )}
                    {props.isTest && (
                        <Grid item align="left" style={{ marginLeft: "30px" }}>
                            <Button
                                disableElevation
                                disabled
                                size="small"
                                variant="contained"
                                style={{
                                    backgroundColor: "#25333e",
                                    color: "white",
                                    fontWeight: "bold",
                                    textTransform: "none",
                                    fontSize: "1rem",
                                }}
                            >
                                IELTS Reading
                            </Button>
                        </Grid>
                    )}

                    <Grid item align="right" xs>
                        {!userSignedIn && !props.isTest && (
                            <Link
                                to={`/newlogin`}
                                style={{ textDecoration: "none", color: "#565656" }}
                            >
                                <Button
                                    size="small"
                                    color="inherit"
                                    style={{
                                        color: "#a6a6a6",
                                        fontWeight: "bold",
                                        textTransform: "none",
                                    }}
                                >
                                    Sign in/Sign up
                                </Button>
                            </Link>
                        )}
                        {userSignedIn && !props.isTest && (
                            <Link
                                to={`/profile`}
                                style={{ textDecoration: "none", color: "#565656" }}
                            >
                                <Button
                                    size="small"
                                    style={{
                                        color: "#636363",
                                        fontWeight: "bold",
                                        textTransform: "none",
                                    }}
                                >
                                    Profile
                                </Button>
                            </Link>
                        )}
                        {!props.isTest && (
                            <Link
                                to={`/ourtests`}
                                style={{ textDecoration: "none", color: "#565656" }}
                            >
                                <Button
                                    size="small"
                                    color="inherit"
                                    style={{
                                        color: "#880000",
                                        fontWeight: "bold",
                                        textTransform: "none",
                                    }}
                                >
                                    Our tests
                                </Button>
                            </Link>
                        )}
                        {!props.isTest && (
                            <Link
                                to={`/freetests`}
                                style={{ textDecoration: "none", color: "#565656" }}
                            >
                                <Button
                                    size="small"
                                    color="inherit"
                                    style={{
                                        color: "#880000",
                                        fontWeight: "bold",
                                        textTransform: "none",
                                    }}
                                >
                                    Free tests
                                </Button>
                            </Link>
                        )}
                        {userSignedIn &&
                            !props.isTest &&
                            ["ADMIN", "SUPER_ADMIN", "HEAD_TEACHER", "TEACHER"].includes(
                                JSON.parse(localStorage.getItem("user")).role
                            ) && (
                                <Link
                                    to={`/teacher/dashboard`}
                                    style={{ textDecoration: "none", color: "#565656" }}
                                >
                                    <Button
                                        size="small"
                                        color="inherit"
                                        style={{
                                            color: "#880000",
                                            fontWeight: "bold",
                                            textTransform: "none",
                                        }}
                                    >
                                        {["SUPER_ADMIN", "HEAD_TEACHER"].includes(
                                            JSON.parse(localStorage.getItem("user")).role
                                        )
                                            ? "Head teacher"
                                            : "Teacher"}
                                    </Button>
                                </Link>
                            )}
                        {userSignedIn && !props.isTest && (
                            <Button
                                onClick={handleOpenLogoutDialog}
                                size="small"
                                color="inherit"
                                style={{
                                    color: "#a6a6a6",
                                    fontWeight: "bold",
                                    textTransform: "none",
                                }}
                            >
                                Sign out
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    );

    const webUI = (
        <MediaQuery minWidth={"768px"}>
            {(matches) =>
                matches ? <ClickAwayListener onClickAway={handleCloseNotification}>
                    <AppBar
                        position="static"
                        style={{
                            backgroundColor: "#efefef",
                            boxShadow: "0px 0px",
                            borderBottom: "1px solid #a6a6a6",
                            borderRadius: "0px",
                            borderBottomLength: "90%",
                        }}
                    >
                        <Toolbar>
                            <Grid container style={{ zIndex: 1 }}>
                                {window.location.pathname.includes("/test/speaking/meeting-id") && (
                                    <Grid item align="center" xs={12} style={{ marginTop: "20px" }}>
                                        <Avatar
                                            variant="square"
                                            src="/U Test Logo (new).png"
                                            style={{ width: "40px", height: "65px" }}
                                        />
                                    </Grid>
                                )}
                                {(!props.isTest && !window.location.pathname.includes("/test/speaking/meeting-id")) && (
                                    <Grid item align="left" xs={7}>
                                        <Grid container alignItems="center">
                                            <Grid item style={{ marginRight: "30px" }}>
                                                <Link
                                                    align="left"
                                                    to="/"
                                                    style={{ textDecoration: "none" }}
                                                >
                                                    <Avatar
                                                        variant="square"
                                                        src="/U Test Logo (new).png"
                                                        style={{ width: "20px" }}
                                                    />
                                                </Link>
                                            </Grid>
                                            <Grid item>
                                                {userSignedIn &&
                                                    !props.isTest &&
                                                    ["ADMIN", "SUPER_ADMIN"].includes(
                                                        JSON.parse(localStorage.getItem("user")).role
                                                    ) && (
                                                        <Link
                                                            to={`/admin`}
                                                            style={{ textDecoration: "none", color: "#565656" }}
                                                        >
                                                            <Button
                                                                color="inherit"
                                                                style={{
                                                                    color: "#880000",
                                                                    fontWeight: "bold",
                                                                    textTransform: "none",
                                                                }}
                                                            >
                                                                Administrator
                                                            </Button>
                                                        </Link>
                                                    )}
                                            </Grid>
                                            {/* <Grid item>
                                                {userSignedIn &&
                                                    !props.isTest &&
                                                    ["ADMIN", "SUPER_ADMIN"].includes(
                                                        JSON.parse(localStorage.getItem("user")).role
                                                    ) && (
                                                        <Link
                                                            to={`/adminreport`}
                                                            style={{ textDecoration: "none", color: "#565656" }}
                                                        >
                                                            <Button
                                                                color="inherit"
                                                                style={{
                                                                    color: "#880000",
                                                                    fontWeight: "bold",
                                                                    textTransform: "none",
                                                                }}
                                                            >
                                                                Score report
                                                            </Button>
                                                        </Link>
                                                    )}
                                            </Grid> */}
                                            <Grid item>
                                                {userSignedIn &&
                                                    !props.isTest &&
                                                    ["SUPER_ADMIN"].includes(
                                                        JSON.parse(localStorage.getItem("user")).role
                                                    ) && (
                                                        <Link
                                                            to={`/dashboard/super-admin`}
                                                            style={{ textDecoration: "none", color: "#565656" }}
                                                        >
                                                            <Button
                                                                color="inherit"
                                                                style={{
                                                                    color: "#880000",
                                                                    fontWeight: "bold",
                                                                    textTransform: "none",
                                                                }}
                                                            >
                                                                Dashboard
                                                            </Button>
                                                        </Link>
                                                    )}
                                            </Grid>
                                            <Grid item>
                                                {userSignedIn &&
                                                    !props.isTest &&
                                                    ["ADMIN", "SUPER_ADMIN", "HEAD_TEACHER", "TEACHER"].includes(
                                                        JSON.parse(localStorage.getItem("user")).role
                                                    ) && (
                                                        <Link
                                                            to={`/teacher/dashboard`}
                                                            style={{ textDecoration: "none", color: "#565656" }}
                                                        >
                                                            <Button
                                                                color="inherit"
                                                                style={{
                                                                    color: "#880000",
                                                                    fontWeight: "bold",
                                                                    textTransform: "none",
                                                                }}
                                                            >
                                                                {["SUPER_ADMIN", "HEAD_TEACHER"].includes(
                                                                    JSON.parse(localStorage.getItem("user")).role
                                                                )
                                                                    ? "Head teacher"
                                                                    : "Teacher"}
                                                            </Button>
                                                        </Link>
                                                    )}
                                            </Grid>
                                            <Grid item>
                                                {userSignedIn &&
                                                    !props.isTest &&
                                                    ["ADMIN", "SUPER_ADMIN"].includes(
                                                        JSON.parse(localStorage.getItem("user")).role
                                                    ) && (
                                                        <Link
                                                            to={`/dashboard/test-admin`}
                                                            style={{ textDecoration: "none", color: "#565656" }}
                                                        >
                                                            <Button
                                                                color="inherit"
                                                                style={{
                                                                    color: "#880000",
                                                                    fontWeight: "bold",
                                                                    textTransform: "none",
                                                                }}
                                                            >
                                                                Test admin
                                                            </Button>
                                                        </Link>
                                                    )}
                                            </Grid>
                                            <Grid item>
                                                {userSignedIn &&
                                                    !props.isTest &&
                                                    ["SUPER_ADMIN"].includes(
                                                        JSON.parse(localStorage.getItem("user")).role
                                                    ) && (
                                                        <Link
                                                            to={`/financial`}
                                                            style={{ textDecoration: "none", color: "#565656" }}
                                                        >
                                                            <Button
                                                                color="inherit"
                                                                style={{
                                                                    color: "#880000",
                                                                    fontWeight: "bold",
                                                                    textTransform: "none",
                                                                }}
                                                            >
                                                                Finances
                                                            </Button>
                                                        </Link>
                                                    )}
                                            </Grid>
                                            <Grid item>
                                                {userSignedIn &&
                                                    !props.isTest && (
                                                        <Link
                                                            to={`/blogs`}
                                                            style={{ textDecoration: "none", color: "#565656" }}
                                                        >
                                                            <Button
                                                                color="inherit"
                                                                style={{
                                                                    color: "#880000",
                                                                    fontWeight: "bold",
                                                                    textTransform: "none",
                                                                }}
                                                            >
                                                                Blogcasts
                                                            </Button>
                                                        </Link>
                                                    )}
                                            </Grid>
                                            <Grid item>
                                                {!props.isTest && (
                                                    <Link
                                                        to={`/aboutus`}
                                                        style={{ textDecoration: "none", color: "#565656" }}
                                                    >
                                                        <Button
                                                            color="inherit"
                                                            style={{
                                                                color: "#880000",
                                                                fontWeight: "bold",
                                                                textTransform: "none",
                                                            }}
                                                        >
                                                            About us
                                                        </Button>
                                                    </Link>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}

                                {props.isTest && (
                                    <Grid item align="left">
                                        <Avatar
                                            variant="square"
                                            src="/U Test Logo (new).png"
                                            style={{ width: "20px" }}
                                        />
                                    </Grid>
                                )}
                                {props.isTest && (`${window.location.pathname}${window.location.search}`) === "/freetests/test?test_type=READING" && (
                                    <Grid item align="left" style={{ marginLeft: "30px" }}>
                                        <Button
                                            disableElevation
                                            disabled
                                            size="small"
                                            variant="contained"
                                            style={{
                                                backgroundColor: "#25333e",
                                                color: "white",
                                                fontWeight: "bold",
                                                textTransform: "none",
                                            }}
                                        >
                                            {"IELTS Reading"}
                                        </Button>
                                    </Grid>
                                )}
                                {props.isTest &&
                                    (`${window.location.pathname}${window.location.search}`) === "/freetests/test?test_type=LISTENING" && (
                                        <Grid item align="left" style={{ marginLeft: "30px" }}>
                                            <Button
                                                disableElevation
                                                disabled
                                                size="small"
                                                variant="contained"
                                                style={{
                                                    backgroundColor: "#25333e",
                                                    color: "white",
                                                    fontWeight: "bold",
                                                    textTransform: "none",
                                                }}
                                            >
                                                {"IELTS Listening"}
                                            </Button>
                                        </Grid>
                                    )}
                                {props.isTest && window.location.pathname === "/test/bmat" && (
                                    <Grid item align="left" style={{ marginLeft: "30px" }}>
                                        <Button
                                            disableElevation
                                            disabled
                                            size="small"
                                            variant="contained"
                                            style={{
                                                backgroundColor: "#214b4b",
                                                color: "white",
                                                fontWeight: "bold",
                                                textTransform: "none",
                                            }}
                                        >
                                            {"TBAT"}
                                        </Button>
                                    </Grid>
                                )}
                                {props.isTest && window.location.pathname === "/test/evaluation" && (
                                    <Grid item align="left" style={{ marginLeft: "30px" }}>
                                        <Button
                                            disableElevation
                                            disabled
                                            size="small"
                                            variant="contained"
                                            style={{
                                                backgroundColor: "#880000",
                                                color: "white",
                                                fontWeight: "bold",
                                                textTransform: "none",
                                            }}
                                        >
                                            {"Test Survey"}
                                        </Button>
                                    </Grid>
                                )}
                                {props.isTest &&
                                    window.location.pathname !== "/freetests/test" &&
                                    window.location.pathname !== "/test/evaluation" &&
                                    window.location.pathname !== "/test/bmat" && (
                                        <Grid item align="left" style={{ marginLeft: "30px" }}>
                                            <Button
                                                disableElevation
                                                disabled
                                                size="small"
                                                variant="contained"
                                                style={{
                                                    backgroundColor: "#25333e",
                                                    color: "white",
                                                    fontWeight: "bold",
                                                    textTransform: "none",
                                                }}
                                            >
                                                {"IELTS Full Test"}
                                            </Button>
                                        </Grid>
                                    )}

                                <Grid item align="right" xs={5}>
                                    {!userSignedIn && !props.isTest && (
                                        <Link
                                            to={`/newlogin`}
                                            style={{ textDecoration: "none", color: "#565656" }}
                                        >
                                            <Button
                                                color="inherit"
                                                style={{
                                                    color: "#a6a6a6",
                                                    fontWeight: "bold",
                                                    textTransform: "none",
                                                    marginRight: "20px",
                                                }}
                                            >
                                                Sign in/Sign up
                                            </Button>
                                        </Link>
                                    )}
                                    {(userSignedIn &&
                                        !props.isTest && !window.location.pathname.includes("/test/speaking/meeting-id")) &&
                                        (
                                            <IconButton
                                                aria-label="notification"
                                                onClick={(event) => {
                                                    handleClickNotification(event);
                                                }}
                                                style={{ marginRight: "20px" }}
                                            >
                                                <Badge
                                                    badgeContent={
                                                        notificationArr.filter((noti) => !noti.isRead).length
                                                    }
                                                    color="error"
                                                >
                                                    <NotificationsRoundedIcon style={{ color: "black" }} />
                                                </Badge>
                                            </IconButton>
                                        )}
                                    {(userSignedIn && !props.isTest && !window.location.pathname.includes("/test/speaking/meeting-id")) && (
                                        <Popper
                                            id={Boolean(anchorEl) ? "simple-popper" : undefined}
                                            open={Boolean(anchorEl)}
                                            anchorEl={anchorEl}
                                            style={{ zIndex: 1 }}
                                        >
                                            <div
                                                style={{
                                                    backgroundColor: "white",
                                                    width: "300px",
                                                    borderRadius: "10px",
                                                }}
                                            >
                                                <Grid container style={{ padding: "15px" }}>
                                                    <Grid item xs={12}>
                                                        <Grid container>
                                                            <Grid align="left" item xs>
                                                                <Typography
                                                                    variant="h6"
                                                                    style={{ color: "#030303", fontWeight: "bold" }}
                                                                    gutterBottom
                                                                >
                                                                    Notifications
                                                                </Typography>
                                                            </Grid>
                                                            <Grid align="right" item xs>
                                                                <Button
                                                                    color="inherit"
                                                                    onClick={handleClickClearAllNotification}
                                                                    style={{
                                                                        color: "#6a6a6a",
                                                                        fontWeight: "bold",
                                                                        textTransform: "none",
                                                                    }}
                                                                >
                                                                    Clear All
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <List
                                                            component="nav"
                                                            aria-label="secondary mailbox folders"
                                                            style={{ height: "400px", maxHeight: "400px", overflowY: "auto" }}
                                                        >
                                                            {notificationArr.length === 0 &&
                                                                <Typography align="center" style={{ color: "#d4d4d4", paddingTop: "170px" }}>
                                                                    No notification.
                                                                </Typography>
                                                            }
                                                            {notificationArr
                                                                .sort(
                                                                    (a, b) => moment(b.createdAt) - moment(a.createdAt)
                                                                )
                                                                .map((noti, index) => (
                                                                    <ListItem
                                                                        button
                                                                        onClick={() => {
                                                                            handleClickNotificationList(
                                                                                noti.type,
                                                                                noti.content,
                                                                                noti._id,
                                                                                noti.isRead
                                                                            );
                                                                        }}
                                                                    >
                                                                        <ListItemText
                                                                            primary={noti.content.message}
                                                                            secondary={moment(noti.createdAt).fromNow()}
                                                                        />
                                                                        {!noti.isRead && (
                                                                            <ListItemSecondaryAction>
                                                                                <FiberManualRecordRoundedIcon
                                                                                    style={{ color: "#f44336" }}
                                                                                />
                                                                            </ListItemSecondaryAction>
                                                                        )}
                                                                    </ListItem>
                                                                ))}
                                                        </List>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </Popper>
                                    )}
                                    {(userSignedIn && !props.isTest && !window.location.pathname.includes("/test/speaking/meeting-id")) && (
                                        <Link
                                            to={`/profile`}
                                            style={{ textDecoration: "none", color: "#565656" }}
                                        >
                                            <Button
                                                color="inherit"
                                                style={{
                                                    color: "#636363",
                                                    fontWeight: "bold",
                                                    textTransform: "none",
                                                    marginRight: "20px",
                                                }}
                                            >
                                                Profile
                                            </Button>
                                        </Link>
                                    )}
                                    {/* {userSignedIn && !props.isTest && (
                                        <HashLink
                                            smooth
                                            to="/profile/#mytest"
                                            style={{ textDecoration: "none", color: "#565656" }}
                                        >
                                            <Button
                                                color="inherit"
                                                style={{
                                                    color: "#636363",
                                                    fontWeight: "bold",
                                                    textTransform: "none",
                                                    marginRight: "20px",
                                                }}
                                            >
                                                My tests
                                            </Button>
                                        </HashLink>
                                    )} */}
                                    {(userSignedIn && !props.isTest && !window.location.pathname.includes("/test/speaking/meeting-id")) && (
                                        <Button
                                            onClick={handleOpenLogoutDialog}
                                            color="inherit"
                                            style={{
                                                color: "#a6a6a6",
                                                fontWeight: "bold",
                                                textTransform: "none",
                                                marginRight: "20px",
                                            }}
                                        >
                                            Sign out
                                        </Button>
                                    )}
                                    {(!props.isTest && !window.location.pathname.includes("/test/speaking/meeting-id")) && (
                                        <Link
                                            to={`/freetests`}
                                            style={{ textDecoration: "none", color: "#565656" }}
                                        >
                                            <Button
                                                color="inherit"
                                                style={{
                                                    color: "#880000",
                                                    fontWeight: "bold",
                                                    textTransform: "none",
                                                    marginRight: "20px",
                                                }}
                                            >
                                                Free tests
                                            </Button>
                                        </Link>
                                    )}
                                    {(!props.isTest && !window.location.pathname.includes("/test/speaking/meeting-id")) && (
                                        <Link
                                            to={`/ourtests`}
                                            style={{ textDecoration: "none", color: "#565656" }}
                                        >
                                            <Button
                                                color="inherit"
                                                style={{
                                                    color: "#880000",
                                                    fontWeight: "bold",
                                                    textTransform: "none",
                                                }}
                                            >
                                                Our tests
                                            </Button>
                                        </Link>
                                    )}
                                </Grid>
                            </Grid>
                        </Toolbar>
                    </AppBar>
                </ClickAwayListener> : <ClickAwayListener onClickAway={handleCloseNotification}>
                    <AppBar
                        position="static"
                        style={{
                            backgroundColor: "#efefef",
                            boxShadow: "0px 0px",
                            borderBottom: "1px solid #a6a6a6",
                            borderRadius: "0px",
                            borderBottomLength: "90%",
                        }}
                    >
                        <Toolbar>
                            <Grid container style={{ zIndex: 1 }}>
                                {!props.isTest && (
                                    <Grid item align="left" xs={7}>
                                        <Grid container alignItems="center">
                                            <Grid item style={{ marginRight: "30px" }}>
                                                <Link
                                                    align="left"
                                                    to="/"
                                                    style={{ textDecoration: "none" }}
                                                >
                                                    <Avatar
                                                        variant="square"
                                                        src="/U Test Logo (new).png"
                                                        style={{ width: "20px" }}
                                                    />
                                                </Link>
                                            </Grid>
                                            <Grid item>
                                                {userSignedIn &&
                                                    !props.isTest &&
                                                    ["ADMIN", "SUPER_ADMIN"].includes(
                                                        JSON.parse(localStorage.getItem("user")).role
                                                    ) && (
                                                        <Link
                                                            to={`/admin`}
                                                            style={{ textDecoration: "none", color: "#565656" }}
                                                        >
                                                            <Button
                                                                color="inherit"
                                                                style={{
                                                                    color: "#880000",
                                                                    fontWeight: "bold",
                                                                    textTransform: "none",
                                                                }}
                                                            >
                                                                Administrator
                                                            </Button>
                                                        </Link>
                                                    )}
                                            </Grid>
                                            {/* <Grid item>
                                                {userSignedIn &&
                                                    !props.isTest &&
                                                    ["ADMIN", "SUPER_ADMIN"].includes(
                                                        JSON.parse(localStorage.getItem("user")).role
                                                    ) && (
                                                        <Link
                                                            to={`/adminreport`}
                                                            style={{ textDecoration: "none", color: "#565656" }}
                                                        >
                                                            <Button
                                                                color="inherit"
                                                                style={{
                                                                    color: "#880000",
                                                                    fontWeight: "bold",
                                                                    textTransform: "none",
                                                                }}
                                                            >
                                                                Score report
                                                            </Button>
                                                        </Link>
                                                    )}
                                            </Grid> */}
                                            <Grid item>
                                                {userSignedIn &&
                                                    !props.isTest &&
                                                    ["ADMIN", "SUPER_ADMIN"].includes(
                                                        JSON.parse(localStorage.getItem("user")).role
                                                    ) && (
                                                        <Link
                                                            to={`/dashboard/super-admin`}
                                                            style={{ textDecoration: "none", color: "#565656" }}
                                                        >
                                                            <Button
                                                                color="inherit"
                                                                style={{
                                                                    color: "#880000",
                                                                    fontWeight: "bold",
                                                                    textTransform: "none",
                                                                }}
                                                            >
                                                                Dashboard
                                                            </Button>
                                                        </Link>
                                                    )}
                                            </Grid>
                                            <Grid item>
                                                {userSignedIn &&
                                                    !props.isTest &&
                                                    ["ADMIN", "SUPER_ADMIN", "HEAD_TEACHER", "TEACHER"].includes(
                                                        JSON.parse(localStorage.getItem("user")).role
                                                    ) && (
                                                        <Link
                                                            to={`/teacher/dashboard`}
                                                            style={{ textDecoration: "none", color: "#565656" }}
                                                        >
                                                            <Button
                                                                color="inherit"
                                                                style={{
                                                                    color: "#880000",
                                                                    fontWeight: "bold",
                                                                    textTransform: "none",
                                                                }}
                                                            >
                                                                {["SUPER_ADMIN", "HEAD_TEACHER"].includes(
                                                                    JSON.parse(localStorage.getItem("user")).role
                                                                )
                                                                    ? "Head teacher"
                                                                    : "Teacher"}
                                                            </Button>
                                                        </Link>
                                                    )}
                                            </Grid>
                                            <Grid item>
                                                {userSignedIn &&
                                                    !props.isTest &&
                                                    ["ADMIN", "SUPER_ADMIN"].includes(
                                                        JSON.parse(localStorage.getItem("user")).role
                                                    ) && (
                                                        <Link
                                                            to={`/dashboard/test-admin`}
                                                            style={{ textDecoration: "none", color: "#565656" }}
                                                        >
                                                            <Button
                                                                color="inherit"
                                                                style={{
                                                                    color: "#880000",
                                                                    fontWeight: "bold",
                                                                    textTransform: "none",
                                                                }}
                                                            >
                                                                Test admin
                                                            </Button>
                                                        </Link>
                                                    )}
                                            </Grid>
                                            <Grid item>
                                                {userSignedIn &&
                                                    !props.isTest &&
                                                    ["SUPER_ADMIN"].includes(
                                                        JSON.parse(localStorage.getItem("user")).role
                                                    ) && (
                                                        <Link
                                                            to={`/financial`}
                                                            style={{ textDecoration: "none", color: "#565656" }}
                                                        >
                                                            <Button
                                                                color="inherit"
                                                                style={{
                                                                    color: "#880000",
                                                                    fontWeight: "bold",
                                                                    textTransform: "none",
                                                                }}
                                                            >
                                                                Finances
                                                            </Button>
                                                        </Link>
                                                    )}
                                            </Grid>
                                            <Grid item>
                                                {userSignedIn &&
                                                    !props.isTest &&
                                                    ["ADMIN", "SUPER_ADMIN"].includes(
                                                        JSON.parse(localStorage.getItem("user")).role
                                                    ) && (
                                                        <Link
                                                            to={`/blogs`}
                                                            style={{ textDecoration: "none", color: "#565656" }}
                                                        >
                                                            <Button
                                                                color="inherit"
                                                                style={{
                                                                    color: "#880000",
                                                                    fontWeight: "bold",
                                                                    textTransform: "none",
                                                                }}
                                                            >
                                                                Blogcasts
                                                            </Button>
                                                        </Link>
                                                    )}
                                            </Grid>
                                            <Grid item>
                                                {!props.isTest && (
                                                    <Link
                                                        to={`/aboutus`}
                                                        style={{ textDecoration: "none", color: "#565656" }}
                                                    >
                                                        <Button
                                                            color="inherit"
                                                            style={{
                                                                color: "#880000",
                                                                fontWeight: "bold",
                                                                textTransform: "none",
                                                            }}
                                                        >
                                                            About us
                                                        </Button>
                                                    </Link>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}

                                {props.isTest && (
                                    <Grid item align="left">
                                        <Avatar
                                            variant="square"
                                            src="/U Test Logo (new).png"
                                            style={{ width: "20px" }}
                                        />
                                    </Grid>
                                )}
                                {props.isTest && (`${window.location.pathname}${window.location.search}`) === "/freetests/test?test_type=READING" && (
                                    <Grid item align="left" style={{ marginLeft: "30px" }}>
                                        <Button
                                            disableElevation
                                            disabled
                                            size="small"
                                            variant="contained"
                                            style={{
                                                backgroundColor: "#25333e",
                                                color: "white",
                                                fontWeight: "bold",
                                                textTransform: "none",
                                            }}
                                        >
                                            {"IELTS Reading"}
                                        </Button>
                                    </Grid>
                                )}
                                {props.isTest &&
                                    (`${window.location.pathname}${window.location.search}`) === "/freetests/test?test_type=LISTENING" && (
                                        <Grid item align="left" style={{ marginLeft: "30px" }}>
                                            <Button
                                                disableElevation
                                                disabled
                                                size="small"
                                                variant="contained"
                                                style={{
                                                    backgroundColor: "#25333e",
                                                    color: "white",
                                                    fontWeight: "bold",
                                                    textTransform: "none",
                                                }}
                                            >
                                                {"IELTS Listening"}
                                            </Button>
                                        </Grid>
                                    )}
                                {props.isTest && window.location.pathname === "/test/bmat" && (
                                    <Grid item align="left" style={{ marginLeft: "30px" }}>
                                        <Button
                                            disableElevation
                                            disabled
                                            size="small"
                                            variant="contained"
                                            style={{
                                                backgroundColor: "#214b4b",
                                                color: "white",
                                                fontWeight: "bold",
                                                textTransform: "none",
                                            }}
                                        >
                                            {"TBAT"}
                                        </Button>
                                    </Grid>
                                )}
                                {props.isTest && window.location.pathname === "/test/evaluation" && (
                                    <Grid item align="left" style={{ marginLeft: "30px" }}>
                                        <Button
                                            disableElevation
                                            disabled
                                            size="small"
                                            variant="contained"
                                            style={{
                                                backgroundColor: "#880000",
                                                color: "white",
                                                fontWeight: "bold",
                                                textTransform: "none",
                                            }}
                                        >
                                            {"Test Survey"}
                                        </Button>
                                    </Grid>
                                )}
                                {props.isTest &&
                                    window.location.pathname !== "/freetests/test" &&
                                    window.location.pathname !== "/test/evaluation" &&
                                    window.location.pathname !== "/test/bmat" && (
                                        <Grid item align="left" style={{ marginLeft: "30px" }}>
                                            <Button
                                                disableElevation
                                                disabled
                                                size="small"
                                                variant="contained"
                                                style={{
                                                    backgroundColor: "#25333e",
                                                    color: "white",
                                                    fontWeight: "bold",
                                                    textTransform: "none",
                                                }}
                                            >
                                                {"IELTS Full Test"}
                                            </Button>
                                        </Grid>
                                    )}

                                <Grid item align="right" xs={5}>
                                    {!userSignedIn && !props.isTest && (
                                        <Link
                                            to={`/newlogin`}
                                            style={{ textDecoration: "none", color: "#565656" }}
                                        >
                                            <Button
                                                color="inherit"
                                                style={{
                                                    color: "#a6a6a6",
                                                    fontWeight: "bold",
                                                    textTransform: "none",
                                                    marginRight: "20px",
                                                }}
                                            >
                                                Sign in/Sign up
                                            </Button>
                                        </Link>
                                    )}
                                    {userSignedIn &&
                                        !props.isTest &&
                                        (
                                            <IconButton
                                                aria-label="notification"
                                                onClick={(event) => {
                                                    handleClickNotification(event);
                                                }}
                                                style={{ marginRight: "20px" }}
                                            >
                                                <Badge
                                                    badgeContent={
                                                        notificationArr.filter((noti) => !noti.isRead).length
                                                    }
                                                    color="error"
                                                >
                                                    <NotificationsRoundedIcon style={{ color: "black" }} />
                                                </Badge>
                                            </IconButton>
                                        )}
                                    {userSignedIn && !props.isTest && (
                                        <Popper
                                            id={Boolean(anchorEl) ? "simple-popper" : undefined}
                                            open={Boolean(anchorEl)}
                                            anchorEl={anchorEl}
                                            style={{ zIndex: 1 }}
                                        >
                                            <div
                                                style={{
                                                    backgroundColor: "white",
                                                    width: "300px",
                                                    borderRadius: "10px",
                                                }}
                                            >
                                                <Grid container style={{ padding: "15px" }}>
                                                    <Grid item xs={12}>
                                                        <Grid container>
                                                            <Grid align="left" item xs>
                                                                <Typography
                                                                    variant="h6"
                                                                    style={{ color: "#030303", fontWeight: "bold" }}
                                                                    gutterBottom
                                                                >
                                                                    Notifications
                                                                </Typography>
                                                            </Grid>
                                                            <Grid align="right" item xs>
                                                                <Button
                                                                    color="inherit"
                                                                    onClick={handleClickClearAllNotification}
                                                                    style={{
                                                                        color: "#6a6a6a",
                                                                        fontWeight: "bold",
                                                                        textTransform: "none",
                                                                    }}
                                                                >
                                                                    Clear All
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <List
                                                            component="nav"
                                                            aria-label="secondary mailbox folders"
                                                            style={{ height: "400px", maxHeight: "400px", overflowY: "auto" }}
                                                        >
                                                            {notificationArr.length === 0 &&
                                                                <Typography align="center" style={{ color: "#d4d4d4", paddingTop: "170px" }}>
                                                                    No notification.
                                                                </Typography>
                                                            }
                                                            {notificationArr
                                                                .sort(
                                                                    (a, b) => moment(b.createdAt) - moment(a.createdAt)
                                                                )
                                                                .map((noti, index) => (
                                                                    <ListItem
                                                                        button
                                                                        onClick={() => {
                                                                            handleClickNotificationList(
                                                                                noti.type,
                                                                                noti.content,
                                                                                noti._id,
                                                                                noti.isRead
                                                                            );
                                                                        }}
                                                                    >
                                                                        <ListItemText
                                                                            primary={noti.content.message}
                                                                            secondary={moment(noti.createdAt).fromNow()}
                                                                        />
                                                                        {!noti.isRead && (
                                                                            <ListItemSecondaryAction>
                                                                                <FiberManualRecordRoundedIcon
                                                                                    style={{ color: "#f44336" }}
                                                                                />
                                                                            </ListItemSecondaryAction>
                                                                        )}
                                                                    </ListItem>
                                                                ))}
                                                        </List>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </Popper>
                                    )}
                                    {userSignedIn && !props.isTest && (
                                        <Link
                                            to={`/profile`}
                                            style={{ textDecoration: "none", color: "#565656" }}
                                        >
                                            <Button
                                                color="inherit"
                                                style={{
                                                    color: "#636363",
                                                    fontWeight: "bold",
                                                    textTransform: "none",
                                                    marginRight: "20px",
                                                }}
                                            >
                                                Profile
                                            </Button>
                                        </Link>
                                    )}
                                    {userSignedIn && !props.isTest && (
                                        <HashLink
                                            smooth
                                            to="/profile/#mytest"
                                            style={{ textDecoration: "none", color: "#565656" }}
                                        >
                                            <Button
                                                color="inherit"
                                                style={{
                                                    color: "#636363",
                                                    fontWeight: "bold",
                                                    textTransform: "none",
                                                    marginRight: "20px",
                                                }}
                                            >
                                                My tests
                                            </Button>
                                        </HashLink>
                                    )}
                                    {userSignedIn && !props.isTest && (
                                        <Button
                                            onClick={handleOpenLogoutDialog}
                                            color="inherit"
                                            style={{
                                                color: "#a6a6a6",
                                                fontWeight: "bold",
                                                textTransform: "none",
                                                marginRight: "20px",
                                            }}
                                        >
                                            Sign out
                                        </Button>
                                    )}
                                    {!props.isTest && (
                                        <Link
                                            to={`/freetests`}
                                            style={{ textDecoration: "none", color: "#565656" }}
                                        >
                                            <Button
                                                color="inherit"
                                                style={{
                                                    color: "#880000",
                                                    fontWeight: "bold",
                                                    textTransform: "none",
                                                    marginRight: "20px",
                                                }}
                                            >
                                                Free tests
                                            </Button>
                                        </Link>
                                    )}
                                    {!props.isTest && (
                                        <Link
                                            to={`/ourtests`}
                                            style={{ textDecoration: "none", color: "#565656" }}
                                        >
                                            <Button
                                                color="inherit"
                                                style={{
                                                    color: "#880000",
                                                    fontWeight: "bold",
                                                    textTransform: "none",
                                                }}
                                            >
                                                Our tests
                                            </Button>
                                        </Link>
                                    )}
                                </Grid>
                            </Grid>
                        </Toolbar>
                    </AppBar>
                </ClickAwayListener>
            }
        </MediaQuery>
    );

    const logoutDialog = (
        <Dialog
            open={logoutDialogStatus}
            onClose={handleCloseLogoutDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                <Typography
                    variant="h5"
                    style={{ color: "#030303", fontWeight: "bold" }}
                    gutterBottom
                >
                    Sign out
                </Typography>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <Typography variant="h5" style={{ color: "#030303" }} gutterBottom>
                        Are you sure you want to sign out?
                    </Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    onClick={handleCloseLogoutDialog}
                    style={{
                        backgroundColor: "#a6a6a6",
                        color: "white",
                        textTransform: "none",
                    }}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    onClick={handleLogout}
                    style={{
                        backgroundColor: "#880000",
                        color: "white",
                        textTransform: "none",
                    }}
                    autoFocus
                >
                    Log out
                </Button>
            </DialogActions>
        </Dialog>
    );

    return (
        <ThemeProvider theme={theme}>
            {logoutDialog}
            <Hidden mdUp>{!props.isTest && mobileUI}</Hidden>
            <Hidden smDown>{!props.isTest && webUI}</Hidden>
        </ThemeProvider>
    );
};

export default compose(withStyles(useStyles))(NewNavBar);
