import TrueFalseNG from "../components/dashboard/testAdmin/components/questionTypes/reading/TrueFalseNG";

export const footerMenus = [
    {
        header: "Help",
        isRequireLogin: false,
        subHeader: [
            {
                name: "FAQ",
                url: "/faq",
                isRequireLogin: false
            },
            {
                name: "Test reports",
                url: "/profile/#myreport",
                isRequireLogin: true
            },
            {
                name: "Report problems",
                url: "/profile",
                isRequireLogin: true
            },
            {
                name: "Refund policy",
                url: "/refund-policy",
                isRequireLogin: false
            },
            {
                name: "Copyrights",
                url: "/copyrights",
                isRequireLogin: false
            },
            {
                name: "Privacy",
                url: "/privacy",
                isRequireLogin: false
            },
        ]
    },
    {
        header: "Account",
        isRequireLogin: true,
        subHeader: [
            {
                name: "My report",
                url: "/profile/#myreport",
                isRequireLogin: true
            },
            {
                name: "Test history",
                url: "/profile/#testhistory",
                isRequireLogin: true
            },
            {
                name: "My tests",
                url: "/profile/#mytest",
                isRequireLogin: true
            },
        ]
    },
    {
        header: "Resources",
        isRequireLogin: false,
        subHeader: [
            {
                name: "Blogcasts",
                url: "/blogs",
                isRequireLogin: false
            }
        ]
    },
    {
        header: "Our Tests",
        isRequireLogin: false,
        subHeader: [
            {
                name: "IELTS (Academic)",
                url: "/ourtests/#productielts",
                isRequireLogin: false
            },
            {
                name: "TBAT",
                url: "/ourtests",
                isRequireLogin: false
            },
            {
                name: "Free tests",
                url: "/freetests",
                isRequireLogin: false
            },
            {
                name: "Test bundles",
                url: "/ourtests",
                isRequireLogin: false
            },
        ]
    },
    {
        header: "Company",
        isRequireLogin: false,
        subHeader: [
            {
                name: "About us",
                url: "/aboutus/#aboutus",
                isRequireLogin: false
            },
            {
                name: "How UTest Avenue works",
                url: "/aboutus/#howitwork",
                isRequireLogin: false
            },
            {
                name: "Contact us",
                url: "/#contactus",
                isRequireLogin: false
            },
        ]
    },
]
