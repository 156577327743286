//Material UI
import { Avatar, Button, Grid, Paper, TextField, Typography } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Snackbar from '@material-ui/core/Snackbar';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { withStyles } from '@material-ui/core/styles';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import CloseIcon from '@material-ui/icons/Close';
import MuiAlert from '@material-ui/lab/Alert';
import { compose } from 'recompose';

//import components
import ForgotPasswordPage from '../updateUserInfo/ForgotPassword/ForgotPasswordPage';

//Social login
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';

//React
import { Component } from 'react';

//react-phone-input-2
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';

//react-router-dom
import {
    Link
} from "react-router-dom";

//@material-ui/pickers
import DateFnsUtils from '@date-io/date-fns';
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider
} from '@material-ui/pickers';
;

const axios = require('axios').default;
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";
const LanguageDetect = require('languagedetect');
const lngDetector = new LanguageDetect();

const useStyles = theme => ({
    root: {
        flexGrow: 1,
    },
    stepperRoot: {
        width: '100%',
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    paper: {
        padding: theme.spacing(1),
        margin: 'auto',
        marginTop: 100,
        maxWidth: 1000,
    },
    gridContainer: {
        margin: 'auto',
        display: 'block',
    },
    gridItem: {
        margin: 10,
        display: 'block'
    },
    socialLoginButton: {
        margin: 10,
        display: 'block',
        width: 270,
        height: 50
    },
    gridTopRight: {
        marginRight: 30
    },
    image: {
        width: '150px',
        height: '150px',
        margin: 'auto',
        display: 'block',
    },
    margin: {
        margin: theme.spacing(1),
    },
    textField: {
        width: '250px',
        margin: theme.spacing(1),
    },
    genderTextField: {
        width: '100px',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    marginTopBot: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    marginTop: {
        marginTop: '100px'
    },
    emailTextField: {
        width: '520px',
        margin: theme.spacing(1)
    },
});

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class LoginPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isSignIn: true,
            isLoggedIn: false,
            isSignUp: false,
            isSocialSignup: false,
            isErrorEmail: false,
            isForgotPassord: false,
            picture: '',
            checked: false,
            steps: ['Account', 'Confirm E-mail'],
            activeStep: 0,
            allFilled: false,
            alertSnackBar: false,
            isLoading: false,
            errorAlertMessage: '',
            verifyEmail: false,
            verifyEmailStatus: 'inactive',
            firstname: '',
            lastname: '',
            username: '',
            email: '',
            password: '',
            phoneNumber: '',
            birthDate: new Date(),
            gender: '',
            window: {
                height: window.innerHeight,
                width: window.innerWidth
            }
        };

        this.handleStandardSignin = this.handleStandardSignin.bind(this);
        this.handleLoading = this.handleLoading.bind(this);
        this.handleCloseLoading = this.handleCloseLoading.bind(this);
        this.checkVerifyEmail = this.checkVerifyEmail.bind(this);
        this.handleSkip = this.handleSkip.bind(this);
        this.handleLoginGoogle = this.handleLoginGoogle.bind(this);
        this.handleLoginFacebook = this.handleLoginFacebook.bind(this);
        this.handleUpdateUserInfo = this.handleUpdateUserInfo.bind(this);
        this.handleCountryCode = this.handleCountryCode.bind(this);
    }

    componentDidMount() {
        document.querySelector("body").scrollTo(0,0);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    handleResize = (event) => {
        this.setState({
            window: {
                height: window.innerHeight,
                width: window.innerWidth
            }
        });
    };

    //Facebook login
    responseFacebook = response => {
        console.log(response);
        let data = {
            userID: response.userID,
            name: response.name,
            email: response.email,
            picture: response.picture.data.url,
            accessToken: response.accessToken,
            signInMethod: "Facebook"
        }
        this.setState(data);
        this.handleLoading();
        this.handleLoginFacebook();
    };

    async handleLoginFacebook() {
        const customLoginWithFacebook = api_base_url + "/api/oauth/login/facebook";
        try {
            let authentication = {
                headers: {
                    "access_token": this.state.accessToken,
                    "user_id": this.state.userID
                }
            };
            axios.get(customLoginWithFacebook, authentication)
                .then(res => {
                    console.log(res.data);
                    this.getUserInfo(res.data.token.token, "Sign In");
                })
        } catch (error) {
            console.error(error);
        }
    }

    //=============================Google login==============================
    getProfile = response => {
        console.log(response);
        let data = {
            userID: response.profileObj.googleId,
            name: this.capitalize(response.profileObj.givenName) + ' ' + this.capitalize(response.profileObj.familyName),
            firstname: this.capitalize(response.profileObj.givenName),
            lastname: this.capitalize(response.profileObj.familyName),
            email: response.profileObj.email,
            picture: response.profileObj.imageUrl,
            googleToken: response.tokenId,
            signInMethod: "Google"
        }
        this.setState(data);
        this.handleLoading();
        this.handleLoginGoogle();
    }

    async handleLoginGoogle() {
        const customLoginWithGoogle = api_base_url + "/api/oauth/login/google";
        try {
            let authentication = {
                headers: {
                    "id_token": this.state.googleToken
                }
            };
            axios.get(customLoginWithGoogle, authentication)
                .then(res => {
                    this.getUserInfo(res.data.token.token, "Sign In");
                })
        } catch (error) {
            console.error(error);
        }
    }

    signOut = () => {
        this.setState({
            isSignIn: true,
            isLoggedIn: false,
            isSignUp: false,
            isSocialSignup: false,
            userID: '',
            name: '',
            email: '',
            picture: '',
            gender: '',
            birthday: '',
            firstname: '',
            lastname: '',
            checked: false,
            steps: ['Account', 'Confirm E-mail'],
            activeStep: 0,
            verifyEmailStatus: 'inactive',
            skip: false
        });

        this.props.handleSignout();
    }

    wait(ms) {
        return new Promise((resolve) => { setTimeout(resolve, ms) });
    }

    async checkVerifyEmail() {
        while (this.state.verifyEmailStatus !== 'active' && !this.state.skip) {
            const checkVerifyEmail_url = api_base_url + "/api/user";
            try {
                let authentication = {
                    headers: {
                        "Authorization": "bearer " + this.state.token
                    }
                };
                const verifiedEmail = await axios.get(checkVerifyEmail_url, authentication);
                this.setState({
                    verifyEmailStatus: verifiedEmail.data.status
                });
                await this.wait(5000);
            } catch (error) {
                console.error(error);
            }
        }
    }

    async checkExisting(email) {
        const signinSession_url = api_base_url + "/api/oauth/login";
        const checkExist_url = api_base_url + "/api/user/exist";
        try {
            const response = await axios.post(signinSession_url, {}, {
                auth: {
                    username: "phisonkha",
                    password: "1234"
                }
            });
            let adminToken = response.data.token.token;

            let config_email = {
                headers: {
                    "Authorization": "bearer " + adminToken
                },
                params: {
                    email: this.state.email
                }
            }
            const responseEmail = await axios.get(checkExist_url, config_email);
            if (!responseEmail.data.status) {
                return false;
            }

            this.handleCloseLoading();
            this.handleAlertSnackBar("This username already exist.");
            return true;
        } catch (error) {
            this.handleCloseLoading();
            console.error(error);
            this.handleAlertSnackBar("This username or email already exist.");
            return true
        }
    }

    handleNext = async () => {
        if (this.state.activeStep === 0) {
            const signup_url = api_base_url + "/api/oauth/register";
            try {
                if (this.state.isErrorEmail || this.state.firstname.length === 0 || this.state.lastname.length === 0 || this.state.email.length === 0 ||
                    this.state.password.length === 0 || this.state.birthDate.length === 0 || this.state.gender.length === 0) {
                    this.handleAlertSnackBar("Please complete all required fields.")
                }

                else if (!this.state.isErrorEmail || this.state.firstname.length !== 0 || this.state.lastname.length !== 0 || this.state.email.length !== 0 ||
                    this.state.password.length !== 0 || this.state.birthDate.length !== 0 || this.state.gender.length !== 0) {

                    this.handleLoading();
                    let data = {
                        firstname_th: "",
                        lastname_th: "",
                        firstname: this.state.firstname,
                        lastname: this.state.lastname,
                        username: this.state.email,
                        email: this.state.email,
                        password: this.state.password,
                        phoneNumber: this.state.phone === undefined ? "" : this.state.phone,
                        birthDate: this.state.birthday,
                        gender: this.state.gender
                    };
                    const checkExist = await this.checkExisting(this.state.email);
                    if (!checkExist) {
                        const response = await axios.post(signup_url, data);
                        this.handleStandardSignin("Sign Up");
                        this.setState({
                            activeStep: this.state.activeStep + 1,
                            verifyEmail: true,
                            isSignUp: false
                        });
                        this.handleCloseLoading();
                        this.handleNext();
                    }
                }

            } catch (error) {
                this.handleCloseLoading();
                console.log(error);
            }
        }

        else if (this.state.activeStep === 1) {
            try {
                await this.checkVerifyEmail();
                this.setState({
                    activeStep: this.state.activeStep + 1
                });
                this.handleStandardSignin("Sign In")
            } catch (error) {
                console.error(error);
            }
        }
    };

    handleSkip() {
        this.setState({
            activeStep: this.state.activeStep + 1,
            skip: true,
            isForgotPassord: false,
            isSignUp: false
        });
    }

    handleBack = () => {
        this.setState({
            activeStep: this.state.activeStep - 1
        })
    };

    handleReset = () => {
        this.setState({
            activeStep: 0
        })
    };

    handleLoading() {
        this.setState({
            isLoading: true
        });
    }

    handleCloseLoading() {
        this.setState({
            isLoading: false
        });
    }

    handleForgotPassword = () => {
        this.setState({
            isForgotPassord: true,
            isSignUp: false,
            isSignIn: false
        })
    }

    handleToSigninPage = () => {
        this.setState({
            isForgotPassord: false,
            isSignUp: false,
            isSignIn: true
        })
    }

    capitalize(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    async getUserInfo(token, type) {
        const userDetail_url = api_base_url + "/api/user";
        const { history } = this.props;
        if (type === "Sign In") {
            try {
                this.handleLoading();
                const response = await axios.get(userDetail_url, {
                    headers: {
                        "Authorization": "bearer " + token
                    }
                });
                console.log(response.data);
                let data = {
                    userSignedin: true,
                    firstname_th: response.data.firstname_th,
                    lastname_th: response.data.lastname_th,
                    firstname: this.capitalize(response.data.firstname),
                    lastname: this.capitalize(response.data.lastname),
                    name: this.capitalize(response.data.firstname) + ' ' + this.capitalize(response.data.lastname),
                    name_th: this.capitalize(response.data.firstname_th) + ' ' + this.capitalize(response.data.lastname_th),
                    email: response.data.email,
                    birthdate: response.data.birthDate,
                    gender: response.data.gender,
                    phone: response.data.phoneNumber,
                    firstNameChar: response.data.firstname.charAt(0).toUpperCase(),
                    token: token,
                    role: response.data.role,
                    picture: response.data.picture,
                    username: response.data.username
                }
                this.setState(data);
                if (this.state.birthdate !== "Thu Jan 01 1970" || this.state.phone.length !== 0) {
                    console.log("INSIDE");
                    console.log(data);
                    await this.props.handleSignin(data);
                    this.handleCloseLoading();
                    history.push('/');
                }
                else if (this.state.birthdate === "Thu Jan 01 1970" || this.state.phone.length === 0) {
                    console.log("IN");
                    this.setState({
                        isSocialSignup: true,
                        isSignIn: false
                    });
                    this.handleCloseLoading();
                }
            } catch (error) {
                this.handleCloseLoading();
                console.error(error);
            }
        }
        else if (type === "Sign Up") {
            try {
                const response = await axios.get(userDetail_url, {
                    headers: {
                        "Authorization": "bearer " + token.token
                    }
                });
                let data = {
                    userSignedin: true,
                    firstname_th: response.data.firstname_th,
                    lastname_th: response.data.lastname_th,
                    firstname: this.capitalize(response.data.firstname),
                    lastname: this.capitalize(response.data.lastname),
                    name: this.capitalize(response.data.firstname) + ' ' + this.capitalize(response.data.lastname),
                    name_th: this.capitalize(response.data.firstname_th) + ' ' + this.capitalize(response.data.lastname_th),
                    email: response.data.email,
                    birthdate: response.data.birthDate,
                    gender: response.data.gender,
                    phone: response.data.phoneNumber,
                    firstNameChar: response.data.firstname.charAt(0).toUpperCase(),
                    token: token,
                    role: response.data.role,
                    picture: response.data.picture,
                    username: response.data.username
                }
                this.setState(data);
                this.props.handleSignin(data);
            } catch (error) {
                console.error(error);
            }
        }
    }

    async handleUpdateUserInfo() {
        const updateUser_url = api_base_url + "/api/user";
        this.handleLoading();
        try {
            const response = await axios.put(updateUser_url, {
                "firstname_th": "",
                "lastname_th": "",
                "username": this.state.email,
                "gender": this.state.gender,
                "birthDate": this.state.birthday,
                "phoneNumber": this.state.phone,
            }, {
                headers: {
                    "Authorization": "bearer " + this.state.token
                }
            });
            if (this.state.signInMethod === "Facebook") {
                this.handleLoginFacebook();
            } else if (this.state.signInMethod === "Google") {
                this.handleLoginGoogle();
            }
        } catch (error) {
            console.error(error);
        }
    }

    async handleStandardSignin(type) {
        const session_url = api_base_url + "/api/oauth/login";
        this.handleLoading();
        if (type === "Sign In") {
            try {
                const response = await axios.post(session_url, {}, {
                    auth: {
                        username: this.state.email,
                        password: this.state.password
                    }
                });
                this.getUserInfo(response.data.token.token, type);
            } catch (error) {
                console.error(error);
                this.handleCloseLoading();
                this.handleAlertSnackBar("Incorrect username or password.");
            }
        }
        else if (type === "Sign Up") {
            try {
                const response = await axios.post(session_url, {}, {
                    auth: {
                        username: this.state.email,
                        password: this.state.password
                    }
                });
                this.setState({
                    token: response.data.token.token
                });
            } catch (error) {
                console.error(error);
            }
        }
    }

    async handleSignupNewUser() {
        console.log("Register");
    }

    getStepContent = (stepIndex) => {
        switch (stepIndex) {
            case 0:
                return 'Please fill in all required information.';
            case 1:
                return 'Take a moment to confirm that we can use this address to send you e-mails.';
            case 2:
                return 'Preparing for your account.';
            default:
                return 'All steps completed.';
        }
    }

    signUp = () => {
        this.setState({
            isSignUp: true,
            isSignIn: false
        });
    }

    handleChange = (event) => {
        const name = event.target.name;
        if (name === 'firstname' || name === 'lastname') {
            let value = event.target.value;
            value = value.replace(/[^A-Za-z]/gi, "");
            this.setState({
                ...this.state,
                [name]: value,
            });
            return;
        }

        else if (name === 'firstname_th' || name === 'lastname_th') {
            let value = event.target.value;
            value = value.replace(/[^ก-๙]+$/gi, "");
            this.setState({
                ...this.state,
                [name]: value,
            });
            return;
        }

        else if (name === 'email') {
            let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            let value = event.target.value;
            if (!re.test(value)) {
                this.setState({
                    isErrorEmail: true
                });
                return;
            }

            else if (re.test(value)) {
                this.setState({
                    isErrorEmail: false,
                    [name]: value,
                });
                return;
            }
        }

        this.setState({
            ...this.state,
            [name]: event.target.value,
        });
    };

    handleDateChange = (event, date) => {
        this.setState({
            birthDate: date
        });
    }

    handleCheckbox = (event) => {
        this.setState({ ...this.state, [event.target.name]: event.target.checked });
    };

    handleAlertSnackBar(message) {
        this.setState({
            alertSnackBar: true,
            errorAlertMessage: message
        });
    };

    handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({
            alertSnackBar: false
        });
    };

    handleCountryCode(event, value) {
        console.log(value)
    }

    render() {
        const { classes } = this.props;
        //=============================== SIGN IN =================================
        const signInComponent = (
            <div>
                <Grid container justify='center'>
                    <Grid item xs={12} className={classes.gridItem}>
                        <Grid container justify='center'>
                            <Grid item xs={12} className={classes.gridItem}>
                                <FacebookLogin
                                    appId="1235660373518321"
                                    autoLoad={false}
                                    fields="birthday,email,gender,first_name,last_name,picture.type(large)"
                                    callback={this.responseFacebook}
                                    icon="fa-facebook"
                                    size="medium"
                                />
                            </Grid>
                            <Grid item xs={12} className={classes.gridItem}>
                                <GoogleLogin
                                    clientId="381958762379-v3cvipu3ncagsn76o2rfl3mv13gso2m5.apps.googleusercontent.com"
                                    buttonText="Login with Google"
                                    onSuccess={this.getProfile}
                                    onFailure={this.getProfile}
                                    cookiePolicy={'single_host_origin'}
                                />
                            </Grid>
                            <Grid item xs={12} className={classes.gridItem}>
                                <Typography variant="h6">Or</Typography>
                            </Grid>
                            <Grid item xs={12} className={classes.gridItem}>
                                <TextField
                                    id="outlined-required"
                                    name="email"
                                    label="Email"
                                    variant="outlined"
                                    onChange={this.handleChange}
                                    className={classes.textField}
                                />
                            </Grid>
                            <Grid item xs={12} className={classes.gridItem}>
                                <TextField
                                    id="outlined-password-input"
                                    name="password"
                                    label="Password"
                                    type="password"
                                    autoComplete="current-password"
                                    variant="outlined"
                                    onChange={this.handleChange}
                                    className={classes.textField}
                                />
                                <Link color="inherit" underline="none" onClick={this.handleForgotPassword} style={{ color: '#565656' }}>
                                    <Typography variant="subtitle2" gutterBottom onClick={this.handleForgotPassword}>
                                        Forgot password?
                                    </Typography>
                                </Link>
                            </Grid>
                            <Grid item xs={12} className={classes.gridItem}>
                                <Button variant="contained" color="inherit" onClick={() => { this.handleStandardSignin("Sign In") }}>Sign In</Button>
                            </Grid>
                            <Grid item xs={12} className={classes.gridItem}>
                                <Button color="inherit" onClick={this.signUp}>Sign Up</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );

        //=================Sign Up part=====================================
        const socialSignUp = (
            <div>
                <Grid item className={classes.gridTopRight} style={{ textAlign: "right" }}>
                    <label htmlFor="icon-button-file">
                        <IconButton color="primary" component="span" onClick={this.signOut}>
                            <CloseIcon />
                        </IconButton>
                    </label>
                </Grid>
                <Typography gutterBottom variant="h3" component="h2">
                    Sign up
                </Typography>
                <Grid item className={classes.gridItem}>
                    <Avatar alt="Remy Sharp" className={classes.image} src={this.state.picture} />
                </Grid>
                <Grid container>
                    <Grid container justify='center'>
                        <TextField
                            id="date"
                            name="birthday"
                            label="Birthday"
                            type="date"
                            defaultValue=""
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={this.handleChange}
                            className={classes.textField}
                        />
                        <FormControl variant="outlined" className={classes.textField}>
                            <InputLabel htmlFor="outlined-age-native-simple">Gender</InputLabel>
                            <Select
                                native
                                value={this.state.gender}
                                onChange={this.handleChange}
                                label="Gender"
                                inputProps={{
                                    name: 'gender',
                                    id: 'outlined-age-native-simple',
                                }}
                            >
                                <option aria-label="None" value="" />
                                <option value={1}>Male</option>
                                <option value={0}>Female</option>
                                <option value={3}>Others</option>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid
                        container
                        direction="column"
                        alignItems="center"
                        justify="center"
                        className={classes.marginTopBot}
                    >
                        <Grid xs={10} align='left'>
                            <PhoneInput
                                country={'th'}
                                value={this.state.phone}
                                enableSearch={true}
                                onChange={phone => this.setState(
                                    { phone }
                                )}
                                inputProps={{
                                    name: 'phone',
                                    required: true,
                                    autoFocus: true
                                }}
                                inputStyle={{
                                    width: '250px'
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.state.checked}
                                onChange={this.handleCheckbox}
                                name="checked"
                                color="primary"
                            />
                        }
                        label="I do want to receive any promotional materials via email."
                    />
                </Grid>
                <Button variant="contained" color="primary" onClick={this.handleUpdateUserInfo}>
                    Finish
                </Button>
            </div>
        )

        const signUp = (
            <Grid container>
                <Grid item xs={12} className={classes.gridTopRight} style={{ textAlign: "right" }}>
                    <label htmlFor="icon-button-file">
                        <IconButton color="primary" component="span" onClick={this.signOut}>
                            <CloseIcon />
                        </IconButton>
                    </label>
                </Grid>
                <Grid item xs={12} align='center'>
                    <Typography gutterBottom variant="h3" component="h2">
                        Sign up
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField required id="outlined-basic" value={this.state.firstname} name="firstname" label="First Name(English)" variant="outlined" className={classes.textField} onChange={this.handleChange} />
                    <TextField required id="outlined-basic" value={this.state.lastname} name="lastname" label="Last Name(English)" variant="outlined" className={classes.textField} onChange={this.handleChange} />
                </Grid>
                <Grid item xs={12}>
                    {/*<TextField
                        required
                        id="outlined-basic"
                        name="username"
                        label="Username"
                        variant="outlined"
                        className={classes.textField}
                        onChange={this.handleChange}
                    />*/}
                    <TextField
                        required
                        error={this.state.isErrorEmail}
                        helperText={this.state.isErrorEmail && "Incorrect format."}
                        id="outlined-required"
                        name="email"
                        label="Email"
                        variant="outlined"
                        className={classes.emailTextField}
                        onChange={this.handleChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        id="outlined-password-input"
                        name="password"
                        label="Password"
                        type="password"
                        autoComplete="current-password"
                        variant="outlined"
                        className={classes.textField}
                        onChange={this.handleChange}
                    />
                    <TextField
                        required
                        id="outlined-password-input"
                        name="confirmPassword"
                        label="Confirm Password"
                        type="password"
                        autoComplete="current-password"
                        variant="outlined"
                        className={classes.textField}
                        onChange={this.handleChange}
                    />
                </Grid>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid item xs={12}>
                        <KeyboardDatePicker
                            margin="normal"
                            name="birthday"
                            id="date-picker-dialog"
                            label="Birthdate"
                            format="MM/dd/yyyy"
                            inputVariant="outlined"
                            value={this.state.birthDate}
                            onChange={this.handleDateChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            className={classes.textField}
                        />
                        <FormControl variant="outlined" className={classes.textField}>
                            <InputLabel htmlFor="outlined-age-native-simple">Gender</InputLabel>
                            <Select
                                native
                                value={this.state.gender}
                                onChange={this.handleChange}
                                label="Gender"
                                inputProps={{
                                    name: 'gender',
                                    id: 'outlined-age-native-simple',
                                }}
                            >
                                <option aria-label="None" value="" />
                                <option value={1}>Male</option>
                                <option value={0}>Female</option>
                                <option value={3}>Others</option>
                            </Select>
                        </FormControl>
                    </Grid>
                </MuiPickersUtilsProvider>
                <Grid item xs={12}>
                    <Grid
                        container
                        direction="column"
                        alignItems="center"
                        justify="center"
                        className={classes.marginTopBot}
                    >
                        <Grid xs={10} align='left'>
                            <PhoneInput
                                country={'th'}
                                value={this.state.phone}
                                enableSearch={true}
                                onChange={phone => this.setState(
                                    { phone }
                                )}
                                inputProps={{
                                    name: 'phone',
                                    required: true,
                                    autoFocus: true
                                }}
                                inputStyle={{
                                    width: '250px'
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.state.checked}
                                onChange={this.handleCheckbox}
                                name="checked"
                                color="primary"
                            />
                        }
                        label="I do want to receive any promotional materials via email."
                    />
                </Grid>
            </Grid>
        );

        const confirmEmail = (
            <Grid container>
                {(this.state.verifyEmailStatus !== 'active') &&
                    <Grid item xs={12}>
                        <CircularProgress />
                        <Typography variant="h4" gutterBottom>
                            Verify your email
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                            Hi {this.state.firstname}!
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                            Please verify your email and start enjoying uTestAvenue
                        </Typography>
                    </Grid>
                }
                {(this.state.verifyEmailStatus === 'active') &&
                    <Grid item xs={12}>
                        <CheckCircleRoundedIcon />
                        <Typography variant="h4" gutterBottom>
                            Your email has been verified
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                            Welcome {this.state.firstname}!
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                            Please wait while we set things tp for you!
                        </Typography>
                    </Grid>
                }
            </Grid>
        )

        const stepperTop = (
            <div className={classes.stepperRoot}>
                <Stepper activeStep={this.state.activeStep} alternativeLabel>
                    {this.state.steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </div>
        );

        const stepperBottom = (
            <div className={classes.stepperRoot} style={{ marginBottom: '50px' }}>
                <div>
                    {this.state.activeStep === this.state.steps.length ? (
                        <div>
                            <Typography className={classes.instructions}>Welcome {this.state.firstname}!</Typography>
                            <Grid item className={classes.gridItem}>
                                <Typography variant="subtitle1" gutterBottom>
                                    Signing In...
                                </Typography>
                            </Grid>
                        </div>
                    ) : (
                        <div>
                            <Typography className={classes.instructions}>{this.getStepContent(this.state.activeStep)}</Typography>
                            <div>
                                {(this.state.activeStep === 1 && this.state.verifyEmailStatus !== 'active') &&
                                    <Button variant="contained" onClick={this.handleSkip} className={classes.backButton}>
                                        Skip
                                    </Button>
                                }
                                {(this.state.activeStep === 1 && this.state.verifyEmailStatus === 'active') &&
                                    <CircularProgress />
                                }
                                {this.state.activeStep === 0 &&
                                    <Button variant="contained" color="primary" onClick={this.handleNext} disabled={this.state.allFilled}>
                                        Next
                                    </Button>
                                }
                            </div>
                        </div>
                    )}
                </div>
            </div>
        )

        return (
            <div className={classes.root} style={{ maxWidth: this.state.window.width }}>
                <Backdrop className={classes.backdrop} open={this.state.isLoading} onClick={this.handleCloseLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Container maxWidth="lg" style={{ marginTop: '10%', marginBottom: '400px' }}>
                    {!this.state.isForgotPassord &&
                        <Paper elevation={3}>
                            <Grid container spacing={2} className={classes.gridContainer} justify="center" alignItems="center" style={{ flexDirection: 'row' }}>
                                <Grid item className={classes.gridItem}>
                                    {(this.state.isSignUp) && stepperTop}
                                </Grid>
                                {(this.state.isSignUp) &&
                                    <div>
                                        <Grid item className={classes.gridItem}>
                                            <Divider variant="middle" />
                                        </Grid>
                                    </div>
                                }
                                <Grid item>
                                    {this.state.isSocialSignup && socialSignUp}
                                    {this.state.isSignIn && signInComponent}
                                    {this.state.isSignUp && signUp}
                                    {this.state.verifyEmail && confirmEmail}
                                </Grid>
                                <Grid item className={classes.gridItem}>
                                    {(this.state.isSignUp || this.state.verifyEmail) && stepperBottom}
                                </Grid>
                            </Grid>
                        </Paper>
                    }
                    {this.state.isForgotPassord && <ForgotPasswordPage handleToSigninPage={this.handleToSigninPage} />}
                    <Snackbar open={this.state.alertSnackBar} autoHideDuration={6000} onClose={this.handleCloseSnackBar}>
                        <Alert onClose={this.handleCloseSnackBar} severity="error">
                            {this.state.errorAlertMessage}
                        </Alert>
                    </Snackbar>
                </Container>
            </div>
        )
    }
}
export default compose(
    withStyles(useStyles)
)(LoginPage);