//React
import { Component } from "react";
import { compose } from "recompose";

//Material UI
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

//import components
import { DragDropContainer, DropTarget } from "react-drag-drop-container";

const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
});

class Matching extends Component {
  constructor() {
    super();
    this.state = {
      draggedItem: "",
      draggedItemToShow: "",
      dropItems: [],
      sizeMapHeader: {
        'sm': 'body2',
        'md': 'body1',
        'lg': 'h6',
      },
      sizeMapBody: {
        'sm': 'caption',
        'md': 'body2',
        'lg': 'body1',
      },
      sizeMapCaption: {
        'sm': 'caption',
        'md': 'body2',
        'lg': 'h6',
      },
    };
  }

  componentDidMount = async () => {
    const filteredAnsObj = await this.props.answerObj.parts[
      this.props.currentPart
    ].sections.find((element) => {
      return element.section_id === this.props.currentTestObject.section_id;
    });
    this.setState({
      answerObj: {
        section_id: this.props.currentTestObject.section_id,
        question_type: this.props.currentTestObject.question_type,
        answers: {},
      },
      dropItems:
        filteredAnsObj.answers.length === 0
          ? []
          : filteredAnsObj.answers[
          this.props.currentTestObject.questions[0].no
          ],
    });

  };

  onDragged = (index, qNum) => {
    this.state.dropItems[index] = this.state.draggedItem;
    this.state.answerObj.answers[this.props.currentTestObject.questions[0].no] =
      this.state.dropItems;
    this.props.handleAnswerObj(this.state.answerObj);
    this.props.handleAnsweredQuestionNumber(
      Number(this.props.currentTestObject.questions[0].no.split("-")[0]) + index
    );
    this.setState({
      dropItemsToShow: this.state.dropItemsToShow,
    });

    this.props.onFocusQuestion(qNum);
  };

  onDragStart = (item) => {
    this.setState({
      draggedItem: item,
    });
  };

  onClickAnswer = (index, qNum) => {
    if (this.state.dropItems[index] !== undefined) {
      this.state.dropItems[index] = undefined;
      this.setState({
        dropItems: this.state.dropItems,
      });
      this.props.handleCancelQuestionNumber(qNum);
    }

    this.props.onFocusQuestion(qNum);
  };

  render() {
    const { classes } = this.props;

    return (
      <Grid container style={{}}>
        <Grid
          item
          align="left"
          xs={6}
          style={{ padding: "20px", paddingTop: "0px" }}
        >
          <Grid container spacing={2} align="left">
            {this.props.currentTestObject.questions[0].match_question_l.map(
              (q, index) => (
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={3}>
                      <Typography style={{ fontSize: `${this.props.fontSize}rem` }}>
                        {q.split(". ")[1]}
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <DropTarget
                        targetKey="foo"
                        onChange={this.props.handleChange}
                        onHit={() => {
                          this.onDragged(index, Number(q.split(".")[0]));
                        }}
                        onFocus={() => {
                          this.props.onFocusQuestion(Number(q.split(".")[0]));
                        }}
                      >
                        <Button
                          size="small"
                          id="outlined-basic"
                          variant="outlined"
                          style={{
                            width: "50%",
                            marginLeft: "10px",
                            fontWeight: "bold",
                            backgroundColor:
                              this.state.dropItems[index] === undefined
                                ? "white"
                                : "#dbe9ee"
                          }}
                          onClick={() => {
                            this.onClickAnswer(index, Number(q.split(".")[0]));
                          }}
                        >
                          <Typography style={{ fontWeight: "bold", fontSize: `${this.props.fontSize}rem` }}>
                            {this.state.dropItems[index] === undefined ||
                              this.state.dropItems[index] === null
                              ? q.split(".")[0]
                              : this.state.dropItems[index].split(". ")
                                .length === 1
                                ? this.state.dropItems[index]
                                : this.state.dropItems[index].split(". ")[1]}
                          </Typography>
                        </Button>
                      </DropTarget>
                    </Grid>
                  </Grid>
                </Grid>
              )
            )}
          </Grid>
        </Grid>
        <Grid
          item
          align="left"
          xs={6}
          style={{ zoom: "100%", padding: "10px", paddingTop: "0px" }}
        >
          <Grid container spacing={1}>
            {this.props.currentTestObject.questions[0].match_question_r.map(
              (q, index) =>
                !this.state.dropItems.includes(q) && (
                  <Grid item xs={12} style={{ zoom: this.props.isRetina ? 1.2 : 1 }}>
                    <DragDropContainer
                      onDragStart={() => {
                        this.onDragStart(q);
                      }}
                      targetKey="foo"
                    >
                      <Paper style={{ padding: "5px" }}>
                        <Typography
                          style={{ fontWeight: "bold", fontSize: `${this.props.fontSize}rem` }}
                        >
                          {q.split(". ").length === 1 ? q : q.split(". ")[1]}
                        </Typography>
                      </Paper>
                    </DragDropContainer>
                  </Grid>
                )
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default compose(withStyles(useStyles))(Matching);
