import React from 'react';
import ReactDOM from 'react-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

class TextEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            updatedObj: {}
        };
    }

    render() {
        return (
            <CKEditor
                disabled={this.props.selectedParagraph !== this.props.paragraphIndex}
                editor={ClassicEditor}
                data={this.props.description}
                onReady={editor => {
                    // You can store the "editor" and use when it is needed.
                    console.log('Editor is ready to use!', editor);
                }}
                onChange={(event, editor) => {
                    const data = editor.getData();
                    //this.props.partObj.description = data;
                    console.log({ event, editor, data });
                }}
            />
        );
    }
}

export default TextEditor;