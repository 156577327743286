import React from 'react';

//MUI
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { ThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';

//Icons
import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecordRounded';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

//Components

//Font
import 'typeface-glacial-indifference';

//Others
import Chart from "react-google-charts";
import { Button } from '@material-ui/core';
const axios = require('axios').default;
const FormData = require('form-data');
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

const useStyles = theme => ({
    root: {
        padding: theme.spacing(12, 4),
    }
});

const theme = createMuiTheme({
    typography: {
        fontFamily: [
            'Roboto'
        ].join(','),
    },
});

const scale = [
    {
        value: 0,
        label: '0%'
    },
    {
        value: 40,
        label: '40%',
    },
    {
        value: 50,
        label: '50%'
    },
    {
        value: 60,
        label: '60%',
    },
    {
        value: 80,
        label: '80%'
    },
];

function valuetext(value) {
    return `${value}%`;
}

const AreasOfImprovement = (props) => {
    const mobileUI = (<Grid container>
        <Grid item align='center' xs={12}>
            <Grid container>
                <Grid item align="left" xs={12} style={{ paddingTop: '50px' }}>
                    <Typography variant="h5" style={{ color: "black", fontWeight: 'bold' }}>
                        Area of Improvements
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item align="left" xs={12} style={{ paddingTop: '50px' }}>
                            <Typography variant="h6" style={{ color: "black", fontWeight: 'bold' }}>
                                Section 1: Aptitude Tests - Critical thinking (AC)
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{ border: "1px solid black" }}>Topic</TableCell>
                                            <TableCell align="center" style={{ border: "1px solid black" }}>Area of Improvements</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {props.reportObj?.test_result?.parts[0].result?.map((row) => (
                                            <TableRow key={row.topic}>
                                                <TableCell component="th" scope="row" style={{ border: "1px solid black" }}>
                                                    {row.topic}
                                                </TableCell>
                                                <TableCell align="right" style={{ border: "1px solid black" }}><div dangerouslySetInnerHTML={{ __html: row.improvement }} /></TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item align="left" xs={12} style={{ paddingTop: '50px' }}>
                            <Typography variant="h6" style={{ color: "black", fontWeight: 'bold' }}>
                                Section 2: Biology (B)
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{ border: "1px solid black" }}>Topic</TableCell>
                                            <TableCell align="center" style={{ border: "1px solid black" }}>Area of Improvements</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {props.reportObj?.test_result?.parts[1].result?.filter((r) => { return r.type === "Biology" }).map((row) => (
                                            <TableRow key={row.topic}>
                                                <TableCell component="th" scope="row" style={{ border: "1px solid black" }}>
                                                    {row.topic}
                                                </TableCell>
                                                <TableCell align="left" style={{ border: "1px solid black" }}><div dangerouslySetInnerHTML={{ __html: row.improvement }} /></TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item align="left" xs={12} style={{ paddingTop: '50px' }}>
                            <Typography variant="h6" style={{ color: "black", fontWeight: 'bold' }}>
                                Section 2: Physics (P)
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{ border: "1px solid black" }}>Topic</TableCell>
                                            <TableCell align="center" style={{ border: "1px solid black" }}>Area of Improvements</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {props.reportObj?.test_result?.parts[1].result?.filter((r) => { return r.type === "Physics" }).map((row) => (
                                            <TableRow key={row.topic}>
                                                <TableCell component="th" scope="row" style={{ border: "1px solid black" }}>
                                                    {row.topic}
                                                </TableCell>
                                                <TableCell align="left" style={{ border: "1px solid black" }}><div dangerouslySetInnerHTML={{ __html: row.improvement }} /></TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item align="left" xs={12} style={{ paddingTop: '50px' }}>
                            <Typography variant="h6" style={{ color: "black", fontWeight: 'bold' }}>
                                Section 2: Chemistry (C)
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{ border: "1px solid black" }}>Topic</TableCell>
                                            <TableCell align="center" style={{ border: "1px solid black" }}>Area of Improvements</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {props.reportObj?.test_result?.parts[1].result?.filter((r) => { return r.type === "Chemistry" }).map((row) => (
                                            <TableRow key={row.topic}>
                                                <TableCell component="th" scope="row" style={{ border: "1px solid black" }}>
                                                    {row.topic}
                                                </TableCell>
                                                <TableCell align="left" style={{ border: "1px solid black" }}><div dangerouslySetInnerHTML={{ __html: row.improvement }} /></TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item align="left" xs={12} style={{ paddingTop: '50px' }}>
                            <Typography variant="h6" style={{ color: "black", fontWeight: 'bold' }}>
                                Section 2: Chemistry (M)
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{ border: "1px solid black" }}>Topic</TableCell>
                                            <TableCell align="center" style={{ border: "1px solid black" }}>Area of Improvements</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {props.reportObj?.test_result?.parts[1].result?.filter((r) => { return r.type === "Mathematics" }).map((row) => (
                                            <TableRow key={row.topic}>
                                                <TableCell component="th" scope="row" style={{ border: "1px solid black" }}>
                                                    {row.topic}
                                                </TableCell>
                                                <TableCell align="left" style={{ border: "1px solid black" }}><div dangerouslySetInnerHTML={{ __html: row.improvement }} /></TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item align="left" xs={12} style={{ paddingTop: '50px' }}>
                            <Typography variant="h6" style={{ color: "black", fontWeight: 'bold' }}>
                                Section 3: Writing task
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Button disableElevation variant="contained" onClick={props.onClickDownloadTeacherFile} style={{ backgroundColor: "#214b4b", color: "white" }}>
                                Download Teacher Comments
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        </Grid>
    </Grid>)

    const webUI = (<Grid container>
        <Grid item align='center' xs={12}>
            <Grid container>
                <Grid item align="left" xs={12} style={{ paddingTop: '50px' }}>
                    <Typography variant="h5" style={{ color: "black", fontWeight: 'bold' }}>
                        Area of Improvements
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item align="left" xs={12} style={{ paddingTop: '50px' }}>
                            <Typography variant="h6" style={{ color: "black", fontWeight: 'bold' }}>
                                Section 1: Aptitude Tests - Critical thinking (AC)
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{ border: "1px solid black" }}>Topic</TableCell>
                                            <TableCell align="center" style={{ border: "1px solid black" }}>Area of Improvements</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {props.reportObj?.test_result?.parts[0].result?.map((row) => (
                                            <TableRow key={row.topic}>
                                                <TableCell component="th" scope="row" style={{ border: "1px solid black" }}>
                                                    {row.topic}
                                                </TableCell>
                                                <TableCell align="right" style={{ border: "1px solid black" }}><div dangerouslySetInnerHTML={{ __html: row.improvement }} /></TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item align="left" xs={12} style={{ paddingTop: '50px' }}>
                            <Typography variant="h6" style={{ color: "black", fontWeight: 'bold' }}>
                                Section 2: Biology (B)
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{ border: "1px solid black" }}>Topic</TableCell>
                                            <TableCell align="center" style={{ border: "1px solid black" }}>Area of Improvements</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {props.reportObj?.test_result?.parts[1].result?.filter((r) => { return r.type === "Biology" }).map((row) => (
                                            <TableRow key={row.topic}>
                                                <TableCell component="th" scope="row" style={{ border: "1px solid black" }}>
                                                    {row.topic}
                                                </TableCell>
                                                <TableCell align="left" style={{ border: "1px solid black" }}><div dangerouslySetInnerHTML={{ __html: row.improvement }} /></TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item align="left" xs={12} style={{ paddingTop: '50px' }}>
                            <Typography variant="h6" style={{ color: "black", fontWeight: 'bold' }}>
                                Section 2: Physics (P)
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{ border: "1px solid black" }}>Topic</TableCell>
                                            <TableCell align="center" style={{ border: "1px solid black" }}>Area of Improvements</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {props.reportObj?.test_result?.parts[1].result?.filter((r) => { return r.type === "Physics" }).map((row) => (
                                            <TableRow key={row.topic}>
                                                <TableCell component="th" scope="row" style={{ border: "1px solid black" }}>
                                                    {row.topic}
                                                </TableCell>
                                                <TableCell align="left" style={{ border: "1px solid black" }}><div dangerouslySetInnerHTML={{ __html: row.improvement }} /></TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item align="left" xs={12} style={{ paddingTop: '50px' }}>
                            <Typography variant="h6" style={{ color: "black", fontWeight: 'bold' }}>
                                Section 2: Chemistry (C)
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{ border: "1px solid black" }}>Topic</TableCell>
                                            <TableCell align="center" style={{ border: "1px solid black" }}>Area of Improvements</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {props.reportObj?.test_result?.parts[1].result?.filter((r) => { return r.type === "Chemistry" }).map((row) => (
                                            <TableRow key={row.topic}>
                                                <TableCell component="th" scope="row" style={{ border: "1px solid black" }}>
                                                    {row.topic}
                                                </TableCell>
                                                <TableCell align="left" style={{ border: "1px solid black" }}><div dangerouslySetInnerHTML={{ __html: row.improvement }} /></TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item align="left" xs={12} style={{ paddingTop: '50px' }}>
                            <Typography variant="h6" style={{ color: "black", fontWeight: 'bold' }}>
                                Section 2: Chemistry (M)
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{ border: "1px solid black" }}>Topic</TableCell>
                                            <TableCell align="center" style={{ border: "1px solid black" }}>Area of Improvements</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {props.reportObj?.test_result?.parts[1].result?.filter((r) => { return r.type === "Mathematics" }).map((row) => (
                                            <TableRow key={row.topic}>
                                                <TableCell component="th" scope="row" style={{ border: "1px solid black" }}>
                                                    {row.topic}
                                                </TableCell>
                                                <TableCell align="left" style={{ border: "1px solid black" }}><div dangerouslySetInnerHTML={{ __html: row.improvement }} /></TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item align="left" xs={12} style={{ paddingTop: '50px' }}>
                            <Typography variant="h6" style={{ color: "black", fontWeight: 'bold' }}>
                                Section 3: Writing task
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Button disableElevation variant="contained" onClick={props.onClickDownloadTeacherFile} style={{ backgroundColor: "#214b4b", color: "white" }}>
                                Download Teacher Comments
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        </Grid>
    </Grid>)

    return <ThemeProvider theme={theme}>
        <Hidden mdUp>
            {mobileUI}
        </Hidden>
        <Hidden smDown>
            {webUI}
        </Hidden>
    </ThemeProvider>
};

export default compose(
    withStyles(useStyles)
)(AreasOfImprovement);
