import React from 'react';

//MUI
import { ThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';


//Font
import 'typeface-glacial-indifference';
import {
    Dialog, DialogTitle, TextField, Button, DialogActions,
    Grid, Typography, DialogContent, DialogContentText
} from '@material-ui/core';

import moment from 'moment';
import axios from 'axios';

const useStyles = theme => ({
    root: {
        padding: theme.spacing(12, 4),
    }
});

const theme = createMuiTheme({
    typography: {
        fontFamily: [
            'Roboto'
        ].join(','),
    },
});

const ReTestDialog = (props) => {
    const [reason, setReason] = React.useState("");

    const handleClose = () => {
        props.handleCloseDialog();
    }

    const handleClickSubmit = async () => {
        try {
            await axios({
                method: 'post',
                url: 'https://steep-legend-midnight.glitch.me/reactivate-test',
                data: {
                    test_obj: props.testObj,
                    submitter: JSON.parse(localStorage.getItem("user")).username,
                    reason: reason,
                }
            });

            props.handleCloseDialog();
            props.getTestPurchasedTests();
        } catch (error) {
            console.error(error);
            return error;
        }
    }

    const onChangeReason = (event) => {
        setReason(event.target.value);
    }

    return <ThemeProvider theme={theme}>
        <Dialog open={props.isOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Request to re-activate test</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please provide more information:
                </DialogContentText>
                <Grid container>
                    <Grid item xs={12}>
                        <TextField
                            disabled
                            margin="dense"
                            id="purchase_id"
                            label="Purchase id"
                            variant="outlined"
                            type="input"
                            value={props.testObj?._id}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            disabled
                            margin="dense"
                            id="test_type"
                            label="Test type"
                            variant="outlined"
                            type="input"
                            value={props.testObj?.test_type}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            disabled
                            margin="dense"
                            id="reason"
                            label="Purchase date"
                            variant="outlined"
                            type="input"
                            value={props.testObj?._id !== undefined ? moment.utc(props.timeStampObj[`${props.testObj?._id}`] ? props.timeStampObj[`${props.testObj?._id}`].createAt : props.testObj?.time_stamp).format('dddd Do MMM YYYY, hh:mm:ss A') : ""}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            margin="dense"
                            id="reason"
                            label="Reason"
                            variant="outlined"
                            type="input"
                            multiline
                            rows={5}
                            fullWidth
                            onChange={onChangeReason}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleClickSubmit} color="primary">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    </ThemeProvider>
};

export default compose(
    withStyles(useStyles)
)(ReTestDialog);
