//=========================== PREPARE ANSWER OBJECT ======================================
export async function PrepareAnsObj(testObj, testId) {
    let tempAnsObj = {
        test_id: testId,
        parts: []
    };

    await testObj.map(async (part, index) => {
        const tempSecArr = SetupSections(part.sections);

        tempAnsObj.parts.push({
            id: part.id,
            part: part.part,
            part_type: part.part_type,
            sections: tempSecArr
        });

    })

    return tempAnsObj;
}

function SetupSections(sections) {
    let tempSecArr = [];
    sections.map((section) => {

        tempSecArr.push({
            question_type: section.question_type,
            section_id: section.section_id,
            total_question: section.total_question,
            answers: ReplaceEmptyAnswer(section)
        });
    });

    return tempSecArr;
}

function ReplaceEmptyAnswer(section) {
    const curlyBracketsTypeArr = ["map", "trueFalseNG", "yesNoNG", "multipleChoice", "noteCompletion", "listOfHeading", "sentenceCompletion", "matchingSentenceEndings"];
    const squareBracketsTypeArr = ["choose2Letters", "completeTheNotes", "completeTheTables", "matching"];
    if (curlyBracketsTypeArr.includes(section.question_type)) {
        return {}
    } if (squareBracketsTypeArr.includes(section.question_type)) {
        return []
    }

    return {}
}

export async function PrepareAnsObjResume(testObj, testId) {
    let tempAnsObj = {
        test_id: testId,
        parts: []
    };

    await testObj.map(async (part, index) => {
        const tempSecArr = SetupSections(part.sections);

        tempAnsObj.parts.push({
            id: part.id,
            part: part.part,
            part_type: part.part_type,
            sections: tempSecArr
        });

    })

    return tempAnsObj;
}
//=========================== PREPARE ANSWER OBJECT ======================================

//=========================== FILL EMPTY ANSWERS ======================================
export async function FillEmptyAnswers(answerObj, testObj) {
    answerObj.parts.map((part, index) => {
        FilSections(part.sections);
    });

    return answerObj
}

async function FilSections(sections) {
    const questionTypeGroupA = ["trueFalseNG", "yesNoNG"];
    let tempSectionArr = [];
    sections.map((section, index) => {
        tempSectionArr.push(section);
    })
}
//=========================== FILL EMPTY ANSWERS ======================================

export async function PrepareAnsObjBeforeSubmit(answerObj) {
    answerObj.parts.map((part, index) => {
        PrepareSections(part.sections);
    });

    return answerObj
}

async function PrepareSections(sections) {
    sections.map((section, index) => {
        if (section.answers === undefined) {
            section.answers = {}
        }
    });

}

