//React
import { Component } from 'react';
import { compose } from 'recompose';

//Material UI
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Backdrop from '@material-ui/core/Backdrop';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FilterListRoundedIcon from '@material-ui/icons/FilterListRounded';
//import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';

//react-router-dom

//Others
import Select from 'react-select';

const axios = require('axios').default;
const FileDownload = require('js-file-download');

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    }
});

class ReportedProblems extends Component {
    constructor() {
        super();
        this.state = {
            numberOfTest: 0,
            filterType: 'all',
            filterReportStatus: '',
            isUpdate: false,
            teachers: []
        };
    }

    componentDidMount() {
        this.handleGetAllSubmittedProblems();
        this.handleGetAllTeachers();
    }

    handleChange = (event) => {
        const name = event.target.name;

        this.setState({
            ...this.state,
            [name]: event.target.value
        });
    };

    handleGetAllSubmittedProblems = async () => {
        try {
            const res = await axios({
                method: 'get',
                url: 'https://chatter-funky-busby.glitch.me/test/problems',
            });

            const resData = res.data;

            this.setState({
                problems: resData.problems,
                numberOfTest: resData.problems.length <= 10 ? resData.problems.length : 10
            });

        } catch (err) {
            console.log(err);
        }
    }

    handleGetAllTeachers = async () => {
        try {
            const res = await axios({
                method: 'get',
                url: 'https://chatter-funky-busby.glitch.me/teachers',
            });

            const resData = await res.data;
            resData.teachers.map((teacher) => {
                this.state.teachers.push({
                    value: teacher,
                    label: this.capitalize(teacher.firstname) + ' ' + this.capitalize(teacher.lastname)
                })
            });

        } catch (err) {
            console.log(err);
        }
    }

    capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    handleSeeMoreTest = () => {
        if (this.state.numberOfTest + 10 >= this.state.problems.length) {
            this.setState({
                numberOfTest: this.state.problems.length
            });

            return;
        }

        this.setState({
            numberOfTest: this.state.numberOfTest + 10
        });
    }

    handleSeeLessTest = () => {
        if (this.state.numberOfTest - 10 <= 10) {
            this.setState({
                numberOfTest: 10
            });

            return;
        }

        this.setState({
            numberOfTest: this.state.numberOfTest - 10
        });
    }

    handleChipClick = (filterType) => {
        this.setState({
            filterType: filterType
        });
    }

    handleTeacherChange = (event) => {
        this.setState({
            selectedTeacher: event.value
        });
    }

    handleAssignTeacher = async (id, teacher) => {
        this.setState({
            isUpdate: true
        });

        try {
            await axios({
                method: 'put',
                url: 'https://chatter-funky-busby.glitch.me/problems/assign-teacher',
                headers: {
                    "Content-Type": "application/json"
                },
                data: {
                    id: id,
                    teacherName: this.capitalize(teacher.firstname) + ' ' + this.capitalize(teacher.lastname),
                    teacherUsername: teacher.username
                }
            });

            await this.handleGetAllSubmittedProblems();

            this.setState({
                isUpdate: false
            });

        } catch (err) {
            console.log(err);
            this.setState({
                isUpdate: false
            });
        }
    }

    handleSelectReportStatus = (event) => {
        this.setState({
            selectedReportStatus: event.value.split('-')[0],
            selectedReportId: event.value.split('-')[1]
        });
    }

    handleSelectFilterReportStatus = (event) => {
        if (event === null) {
            this.setState({
                filterReportStatus: ''
            });
        } else if (event !== null) {
            this.setState({
                filterReportStatus: event.value
            });
        }
    }

    handleChangeReportStatus = async () => {
        if (this.state.selectedReportId === undefined || this.state.selectedReportStatus === undefined) {
            return;
        }

        this.setState({
            isUpdate: true
        });

        try {
            const res = await axios({
                method: 'put',
                url: 'https://chatter-funky-busby.glitch.me/test/problems/' + this.state.selectedReportId,
                data: {
                    status: this.state.selectedReportStatus
                }
            });
            console.log(res);
            await this.handleGetAllSubmittedProblems();

            this.setState({
                isUpdate: false
            });
        } catch (err) {
            console.log(err);
            this.setState({
                isUpdate: false
            });
        }
    }

    handleDoneUpdate = () => {
        this.setState({
            isUpdate: false
        });
    }

    render() {
        const { classes } = this.props;

        return (
            <Grid item xs={12} sm={9}>
                <Backdrop className={classes.backdrop} open={this.state.isUpdate} onClick={this.handleDoneUpdate}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Grid container>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography align='left' variant="h5" gutterBottom>
                                    <Box fontWeight="fontWeightBold" display='inline'>Reported problems</Box>
                                </Typography>
                            </Grid>
                            <Grid item align='left' xs={12}>
                                <Grid container>
                                    <Grid item xs>
                                        <Chip
                                            avatar={
                                                <FilterListRoundedIcon
                                                    style={{
                                                        color: this.state.filterType === 'report' ? 'white' : '',
                                                    }}
                                                />
                                            }
                                            label="Report"
                                            onClick={() => { this.handleChipClick('report') }}
                                            style={{
                                                margin: '5px',
                                                backgroundColor: this.state.filterType === 'report' ? '#76323F' : '',
                                                color: this.state.filterType === 'report' ? 'white' : '',
                                            }}
                                        />
                                        <Chip
                                            avatar={
                                                <FilterListRoundedIcon
                                                    style={{
                                                        color: this.state.filterType === 'rescore' ? 'white' : '',
                                                    }}
                                                />
                                            }
                                            label="Re-score"
                                            onClick={() => { this.handleChipClick('rescore') }}
                                            style={{
                                                margin: '5px',
                                                backgroundColor: this.state.filterType === 'rescore' ? '#76323F' : '',
                                                color: this.state.filterType === 'rescore' ? 'white' : '',
                                            }}
                                        />
                                        <Chip
                                            avatar={
                                                <FilterListRoundedIcon
                                                    style={{
                                                        color: this.state.filterType === 'all' ? 'white' : '',
                                                    }}
                                                />
                                            }
                                            label="All"
                                            onClick={() => { this.handleChipClick('all') }}
                                            style={{
                                                margin: '5px',
                                                backgroundColor: this.state.filterType === 'all' ? '#76323F' : '',
                                                color: this.state.filterType === 'all' ? 'white' : '',
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs style={{ padding: '5px' }}>
                                        <Typography align='right' variant="subtitle1">
                                            <Box fontWeight="fontWeightBold" display='inline'>Status: </Box>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs>
                                        <Select
                                            name="Status"
                                            isSearchable={false}
                                            isClearable={true}
                                            options={[
                                                { value: 'Assigned', label: 'Assigned' },
                                                { value: 'In progress', label: 'In progress' },
                                                { value: 'Closed', label: 'Closed' },
                                            ]}
                                            onChange={this.handleSelectFilterReportStatus}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item align='left' xs={12} style={{ overflowY: 'auto', maxHeight: '900px' }}>
                            <Paper variant="outlined">
                                {this.state.problems !== undefined ?
                                    (this.state.problems.filter(problem => this.state.filterType !== 'all' ? problem.problemType === this.state.filterType : problem.problemType !== '')
                                        .filter(problem => this.state.filterReportStatus !== '' ? problem.status === this.state.filterReportStatus : problem.status !== '')
                                        .sort((a, b) => new Date(a.createdAt) < new Date(b.createdAt) ? 1 : -1)
                                        .slice(0, this.state.numberOfTest).map((problem, index) =>
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Grid container spacing={0}>
                                                        <Grid align='left' item xs={12} sm>
                                                            <Typography>{problem.topic}</Typography>
                                                            <Typography variant="subtitle2" color="textSecondary">
                                                                {new Date(problem.createdAt).toDateString()}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid align='right' item xs={12} sm>
                                                            <Chip label={problem.problemType} style={{ margin: '5px' }} />
                                                            <Chip label={problem.status} style={{ margin: '5px' }} />
                                                        </Grid>
                                                    </Grid>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Grid container style={{ paddingLeft: '50px' }}>
                                                        <Grid item xs={12}>
                                                            <Grid container spacing={2}>
                                                                <Grid item>
                                                                    <Typography>
                                                                        <Box fontWeight="fontWeightBold" display='inline'>Reference id:</Box>
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid align='center' item>
                                                                    <Typography>
                                                                        {problem._id}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Grid container spacing={2}>
                                                                <Grid align='right' item>
                                                                    <Typography>
                                                                        <Box fontWeight="fontWeightBold" display='inline'>Description:</Box>
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid align='left' item>
                                                                    <Typography>
                                                                        {problem.description}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Grid container spacing={2}>
                                                                <Grid align='right' item>
                                                                    <Typography>
                                                                        <Box fontWeight="fontWeightBold" display='inline'>Submitter:</Box>
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid align='left' item>
                                                                    <Typography spacing={2}>
                                                                        {problem.submitter}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        {(problem.teacherName !== undefined && problem.teacherName !== 'Unassigned') &&
                                                            <Grid item xs={12}>
                                                                <Grid container spacing={2}>
                                                                    <Grid align='right' item>
                                                                        <Typography>
                                                                            <Box fontWeight="fontWeightBold" display='inline'>Assign to:</Box>
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid align='left' item>
                                                                        <Typography spacing={2}>
                                                                            {problem.teacherName}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        }
                                                        <Grid item xs={12}>
                                                            <Grid container spacing={2}>
                                                                <Grid align='right' item>
                                                                    <Typography>
                                                                        <Box fontWeight="fontWeightBold" display='inline'>Create at:</Box>
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid align='left' item>
                                                                    <Typography>
                                                                        {new Date(problem.createdAt).toDateString()}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Grid container spacing={2}>
                                                                <Grid align='right' item>
                                                                    <Typography>
                                                                        <Box fontWeight="fontWeightBold" display='inline'>Status:</Box>
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid align='left' item xs={3}>
                                                                    <Select
                                                                        options={[
                                                                            { value: 'Unassigned-' + problem._id, label: 'Unassigned' },
                                                                            { value: 'In progress-' + problem._id, label: 'In progress' },
                                                                            { value: 'Closed-' + problem._id, label: 'Closed' }
                                                                        ]}
                                                                        defaultValue={{ value: problem.status + '-' + problem._id, label: problem.status }}
                                                                        onChange={this.handleSelectReportStatus}
                                                                        isSearchable={false}
                                                                        isClearable={false}
                                                                        inputProps={{
                                                                            id: 'age-native-required',
                                                                        }}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid align='right' item xs={12} style={{ marginTop: '50px' }}>
                                                            <Grid container spacing={2}>
                                                                <Grid item align='right' xs={12} sm={1} />
                                                                <Grid item align='right' xs={12} sm={6}>
                                                                    <Select
                                                                        options={this.state.teachers}
                                                                        defaultValue={{value: problem.teacherName, label: problem.teacherName}}
                                                                        isSearchable={true}
                                                                        isClearable={true}
                                                                        onChange={this.handleTeacherChange}
                                                                    />
                                                                </Grid>
                                                                <Grid item align='left' xs={12} sm={2}>
                                                                    <Button
                                                                        variant="contained"
                                                                        style={{ backgroundColor: '#76323F', color: 'white' }}
                                                                        onClick={async () => {
                                                                            await this.handleAssignTeacher(problem._id, this.state.selectedTeacher);
                                                                        }}
                                                                    >
                                                                        {(problem.teacherName !== undefined && problem.teacherName !== 'Unassigned') ? 'Re-assign' : 'Assign'}
                                                                    </Button>
                                                                </Grid>
                                                                <Grid item xs={12} sm={3}>
                                                                    <Button
                                                                        variant="contained"
                                                                        style={{ backgroundColor: '#76323F', color: 'white' }}
                                                                        onClick={this.handleChangeReportStatus}
                                                                    >
                                                                        Update status
                                                                    </Button>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        )) : (
                                        <Grid>
                                            <Skeleton variant="rect" width="100%" height={80} />
                                        </Grid>
                                    )}
                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        {this.state.problems !== undefined && (this.state.numberOfTest > this.state.problems.length) &&
                            <Button
                                variant="contained"
                                style={{ width: '100%', marginTop: '20px', backgroundColor: '#76323F', color: 'white' }}
                                onClick={this.handleSeeMoreTest}
                            >
                                See More (+10)
                            </Button>
                        }
                        {this.state.problems !== undefined && !(this.state.numberOfTest === this.state.problems.length) &&
                            <Button

                                variant="contained"
                                style={{ width: '100%', marginTop: '20px', backgroundColor: '#76323F', color: 'white' }}
                                onClick={this.handleSeeLessTest}
                            >
                                See Less (-10)
                            </Button>
                        }
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

export default compose(
    withStyles(useStyles)
)(ReportedProblems);