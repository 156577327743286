import React, { useEffect } from 'react';

//MUI
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';

//Others
import MediaQuery, {useMediaQuery} from 'react-responsive';
import TestListenting from './TestListenting';
import ListeningRetina from './ListeningRetina';

const useStyles = theme => ({
    root: {
        padding: theme.spacing(12, 4),
    }
});

const ListeningRender = (props) => {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

    useEffect(() => {
        document.querySelector("body").scrollTo(0, 0);
    }, []);

    return <MediaQuery minResolution={"2dppx"}>
        {(matches) =>
            (matches || isTabletOrMobile) ? <ListeningRetina
                session={JSON.parse(localStorage.getItem("listeningSession"))}
                user={props.user}
                userSignup={props.userSignup}
                userSignedin={props.userSignedin}
                handleEndTest={props.handleEndTest}
                handleEndTestSession={props.handleEndTestSession}
                keepCurrentSession={props.keepCurrentSession}
                handleOpenLoading={props.handleOpenLoading}
                handleCloseLoading={props.handleCloseLoading}
                handleStartTest={props.handleStartTest}
                handleStartTestType={props.handleStartTestType}
                handleUpdateAnswerObj={props.handleUpdateAnswerObj}
                isFreeTest={props.isFreeTest}
                isDummyTest={props.isDummyTest}
                history={props.history}
                handleCreateStudentReport={props.handleCreateStudentReport}
                isLoading={props.isLoading}
            /> : <TestListenting
                    session={JSON.parse(localStorage.getItem("listeningSession"))}
                    user={props.user}
                    userSignup={props.userSignup}
                    userSignedin={props.userSignedin}
                    handleEndTest={props.handleEndTest}
                    handleEndTestSession={props.handleEndTestSession}
                    keepCurrentSession={props.keepCurrentSession}
                    handleOpenLoading={props.handleOpenLoading}
                    handleCloseLoading={props.handleCloseLoading}
                    handleStartTest={props.handleStartTest}
                    handleStartTestType={props.handleStartTestType}
                    handleUpdateAnswerObj={props.handleUpdateAnswerObj}
                    isFreeTest={props.isFreeTest}
                    isDummyTest={props.isDummyTest}
                    history={props.history}
                    handleCreateStudentReport={props.handleCreateStudentReport}
                    isLoading={props.isLoading}
                />
        }
    </MediaQuery>
};

export default compose(
    withStyles(useStyles)
)(ListeningRender);
