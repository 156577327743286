import React from 'react';

import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import LinearProgress from '@material-ui/core/LinearProgress';
import MuiAlert from '@material-ui/lab/Alert';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import { Container } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Component } from 'react';
import { compose } from 'recompose';

//Icon
import BallotRoundedIcon from '@material-ui/icons/BallotRounded';
import ImportContactsRoundedIcon from '@material-ui/icons/ImportContactsRounded';
import EventRoundedIcon from '@material-ui/icons/EventRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import CardMembershipRoundedIcon from '@material-ui/icons/CardMembershipRounded';

//Components
import AllTests from './components/AllTests';
import AllParts from './components/AllParts';
import AllQuestions from './components/AllQuestions';
import AllStrategy from './components/AllStrategy';
import CreateNewStrategy from './components/CreateNewStrategy';
import EditTest from './components/EditTest';
import Menus from './components/Menus';
import CreateNewPart from './components/CreateNewPart';
import AddSections from './components/AddSections';
import EditSectionHistory from './components/EditSectionHistory';

import Carousel from 'react-elastic-carousel';
import "react-multi-carousel/lib/styles.css";

//Others
const axios = require('axios').default;
const FormData = require('form-data');
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

const useStyles = theme => ({
    root: {
        margin: 15,
        marginTop: 50
    }
});

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class TestAdminDashboardPage extends Component {
    constructor() {
        super()
        this.state = {
            breakPoints: [
                { width: 1, itemsToShow: 1 },
                { width: 600, itemsToShow: 2 },
                { width: 900, itemsToShow: 3 },
                { width: 1200, itemsToShow: 4 },
            ],
            window: {
                height: window.innerHeight,
                width: window.innerWidth
            },
            selectedMenu: '',
            selectedTestId: ''
        };
    }

    componentDidMount = () => {
        this.handleBackFrom();
        this.props.handleChangeMenu('testAdminDashboard');
        document.querySelector("body").scrollTo(0, 0);
        document.body.style.backgroundColor = "#efefef";
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    handleResize = (event) => {
        this.setState({
            window: {
                height: window.innerHeight,
                width: window.innerWidth
            }
        });
    };

    handleBackFrom = () => {
        const query = new URLSearchParams(this.props.location.search);

        if (query.get('from') === 'test' || query.get('from') === null) {
            this.setState({
                selectedMenu: 'allTests'
            })
        } if (query.get('from') === 'part') {
            this.setState({
                selectedMenu: 'allParts'
            })
        } if (query.get('from') === 'strategy') {
            this.setState({
                selectedMenu: 'allStrategy'
            })
        }
    }

    handleSelectMenu = (menu) => {
        this.setState({
            selectedMenu: menu
        })
    }

    handleSelectTest = (testId) => {
        const { history } = this.props;

        history.push(`/dashboard/test-admin/tests/${testId}/edit`);
    }

    handleSelectPart = (partId) => {
        const { history } = this.props;

        history.push(`/dashboard/test-admin/parts/${partId}/edit`);
    }

    handleSelectStrategy = (strategyId) => {
        const { history } = this.props;

        history.push(`/dashboard/test-admin/strategy/${strategyId}/edit`);
    }

    handleSelectSectionHistory = (sectionId) => {
        this.setState({
            selectedSectionId: sectionId,
            selectedMenu: 'sectionHistory'
        });
    }

    render() {
        const { classes } = this.props;

        return (
            <Container maxWidth={false} style={{ marginTop: '100px', marginBottom: '300px' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={2} style={{ padding: '0px' }}>
                        <Menus selectedMenu={this.state.selectedMenu} user={this.props.user} handleSelectMenu={this.handleSelectMenu} />
                    </Grid>

                    {this.state.selectedMenu === 'allTests' &&
                        <Grid item xs={12} sm={10} style={{ padding: '0px' }}>
                            <AllTests user={this.props.user} handleSelectTest={this.handleSelectTest} />
                        </Grid>
                    }

                    {this.state.selectedMenu === 'editTest' &&
                        <Grid item xs={12} sm={10}>
                            <EditTest user={this.props.user} selectedTestId={this.state.selectedTestId} handleSelectMenu={this.handleSelectMenu} handleSelectSectionHistory={this.handleSelectSectionHistory} />
                        </Grid>
                    }

                    {this.state.selectedMenu === 'allParts' &&
                        <Grid item xs={12} sm={10} style={{ padding: '0px' }}>
                            <AllParts user={this.props.user} handleSelectPart={this.handleSelectPart} />
                        </Grid>
                    }

                    {this.state.selectedMenu === 'allQuestions' &&
                        <Grid item xs={12} sm={10} style={{ padding: '0px' }}>
                            <AllQuestions user={this.props.user} />
                        </Grid>
                    }

                    {this.state.selectedMenu === 'allStrategy' &&
                        <Grid item xs={12} sm={10}>
                            <AllStrategy user={this.props.user} handleSelectStrategy={this.handleSelectStrategy} />
                        </Grid>
                    }

                    {this.state.selectedMenu === 'sectionHistory' &&
                        <Grid item xs={12} sm={10}>
                            <EditSectionHistory user={this.props.user} selectedSectionId={this.state.selectedSectionId} selectedTestId={this.state.selectedTestId} handleSelectTest={this.handleSelectTest} />
                        </Grid>
                    }

                    {this.state.selectedMenu === 'createNewPart' &&
                        <Grid item xs={12} sm={10}>
                            <CreateNewPart user={this.props.user} handleSelectMenu={this.handleSelectMenu} />
                        </Grid>
                    }

                    {this.state.selectedMenu === 'createNewStrategy' &&
                        <Grid item xs={12} sm={10}>
                            <CreateNewStrategy handleSelectMenu={this.handleSelectMenu} />
                        </Grid>
                    }

                    {this.state.selectedMenu === 'addNewSection' &&
                        <Grid item xs={12} sm={10}>
                            <AddSections handleSelectMenu={this.handleSelectMenu} />
                        </Grid>
                    }
                </Grid>
            </Container>
        )
    }
}
export default compose(
    withStyles(useStyles)
)(TestAdminDashboardPage);