import React, { useEffect } from 'react';

//MUI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';

import { ThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';

//Font
import 'typeface-glacial-indifference';

//Icons
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';

//Others
import { Link } from "react-router-dom";
const axios = require('axios').default;
const FormData = require('form-data');
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

const useStyles = theme => ({
    root: {
        padding: theme.spacing(12, 4),
    }
});

const theme = createMuiTheme({
    typography: {
        fontFamily: [
            'Roboto'
        ].join(','),
    },
});

const AllBlogs = (props) => {
    useEffect(() => {
        document.body.style.backgroundColor = "#efefef";
        document.querySelector("body").scrollTo(0, 0);
        console.log(props);
    }, []);

    const mobileUI = (
        <Grid container alignItems='center' spacing={5} style={{ marginTop: "50px" }}>
            {props.blogsArr?.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()).map((blog, index) =>
                <Grid item xs={12}>
                    <Paper variant="outlined" style={{ backgroundColor: '#efefef', padding: "30px", borderRadius: "25px", boxShadow: "5px 5px #919ea9", borderWidth: "2px", borderColor: "#919ea9" }}>
                        <Grid container spacing={5}>
                            <Grid item xs={12}>
                                <Box
                                    component="img"
                                    style={{ width: '100%', height: '100%' }}
                                    alt='image'
                                    src={blog.smallPic ? blog.smallPic : blog.bigPic}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item align='left' xs={12}>
                                        <Typography variant="h5" style={{ fontWeight: 'bold', color: '#7b7a7a' }}>
                                            {blog.heading}
                                        </Typography>
                                    </Grid>
                                    <Grid item align='left' xs={12}>
                                        <Typography
                                            variant="body1"
                                            color="textSecondary"
                                        >
                                            {blog.subheading}
                                        </Typography>
                                    </Grid>
                                    <Grid item align='right' xs={12} style={{ marginTop: "30px" }}>
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                        >
                                            Article by {blog.writerName}
                                        </Typography>
                                    </Grid>
                                    <Grid item align='right' xs={12}>
                                        <Typography
                                            variant="caption"
                                            color="textSecondary"
                                        >
                                            Posted at {new Date(blog.createdAt).toDateString()}
                                        </Typography>
                                    </Grid>
                                    <Grid item align='right' xs={12} style={{ marginTop: "5px" }}>
                                        <Link to={"/blogs/" + blog._id} style={{ textDecoration: 'none', color: '#0f0f0f' }}>
                                            <Button
                                                variant="contained"
                                                size="small"
                                                style={{ backgroundColor: "#f9f9f9", color: "#636363", borderRadius: "10px", textTransform: "none" }}
                                                endIcon={<ArrowForwardRoundedIcon />}
                                                disableElevation
                                            >
                                                <Typography variant="body1" style={{ padding: "5px" }}>
                                                    See more
                                            </Typography>
                                            </Button>
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            )}
        </Grid>
    )

    const webUI = (
        <Grid container alignItems='center' spacing={5} style={{ marginTop: "50px" }}>
            {props.blogsArr?.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()).map((blog, index) =>
                <Grid item xs={12}>
                    <Paper variant="outlined" style={{ backgroundColor: '#efefef', padding: "30px", borderRadius: "25px", boxShadow: "5px 5px #919ea9", borderWidth: "2px", borderColor: "#919ea9" }}>
                        <Grid container spacing={5}>
                            <Grid item xs={3}>
                                <Box
                                    component="img"
                                    style={{ width: '100%', height: '100%' }}
                                    alt='image'
                                    src={blog.smallPic ? blog.smallPic : blog.bigPic}
                                />
                            </Grid>
                            <Grid item xs={9}>
                                <Grid container>
                                    <Grid item align='left' xs={12}>
                                        <Typography variant="h5" style={{ fontWeight: 'bold', color: '#7b7a7a' }}>
                                            {blog.heading}
                                        </Typography>
                                    </Grid>
                                    <Grid item align='left' xs={12}>
                                        <Typography
                                            variant="body1"
                                            color="textSecondary"
                                        >
                                            {blog.subheading}
                                        </Typography>
                                    </Grid>
                                    <Grid item align='right' xs={12} style={{ marginTop: "30px" }}>
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                        >
                                            Article by {blog.writerName}
                                        </Typography>
                                    </Grid>
                                    <Grid item align='right' xs={12}>
                                        <Typography
                                            variant="caption"
                                            color="textSecondary"
                                        >
                                            Posted at {new Date(blog.createdAt).toDateString()}
                                        </Typography>
                                    </Grid>
                                    <Grid item align='right' xs={12} style={{ marginTop: "5px" }}>
                                        <Link to={"/blogs/" + blog._id} style={{ textDecoration: 'none', color: '#0f0f0f' }}>
                                            <Button
                                                variant="contained"
                                                size="small"
                                                style={{ backgroundColor: "#f9f9f9", color: "#636363", borderRadius: "10px", textTransform: "none" }}
                                                endIcon={<ArrowForwardRoundedIcon />}
                                                disableElevation
                                            >
                                                <Typography variant="body1" style={{ padding: "5px" }}>
                                                    See more
                                                </Typography>
                                            </Button>
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            )}
        </Grid>
    )

    return <ThemeProvider theme={theme}>
        <Hidden mdUp>
            {mobileUI}
        </Hidden>
        <Hidden smDown>
            {webUI}
        </Hidden>
    </ThemeProvider>
};

export default compose(
    withStyles(useStyles)
)(AllBlogs);
