//React
import React, { Component } from "react";
import { compose } from "recompose";

//Material UI
import Button from "@material-ui/core/Button";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import { withStyles } from "@material-ui/core/styles";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";

//react-router-dom
import { Link } from "react-router-dom";

//Constants
import * as styleConstants from "../../../constants/styleConstants";
import AdvertisementSectionMobile from "./mobile/AdvertisementSectionMobile";
import { Hidden } from "@material-ui/core";

//Animations
import Fade from "react-reveal/Fade";

//Others
import VisibilitySensor from 'react-visibility-sensor';
const axios = require("axios").default;

const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  rootContainer: {
    maxWidth: "75%",
  },
  rootCard: {
    maxWidth: "75%",
    width: "75%",
    margin: 10,
  },
  gridContainer: {
    maxWidth: "90%",
  },
  margin: {
    margin: 100,
  },
  marginTop: {
    marginTop: 100,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(5),
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  header: {
    maxWidth: "80%",
  },
  body: {
    maxWidth: "80%",
    marginTop: 30,
  },
  divider: {
    maxWidth: "100%",
  },
  image: {
    margin: "auto",
    display: "block",
    margin: 10,
  },
  commentTextField: {
    width: "100%",
    maxWidth: "100%",
  },
  commentGridItem: {
    maxWidth: "80%",
    marginTop: 30,
    marginBottom: 100,
  },
});

const theme = createMuiTheme({
  typography: {
    fontFamily: ["Roboto"].join(","),
  },
});

class AdvertisementSection extends Component {
  constructor() {
    super();
    this.state = {
      isVisible: false
    };
  }

  componentDidMount() { }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  render() {
    const { classes } = this.props;

    const webUI = (
      <Grid container spacing={5} style={{ paddingLeft: "5%", paddingRight: "5%" }}>
        <Grid
          item
          align="center"
          sm={12}
          md={6}
          style={{ paddingTop: "10%", paddingBottom: "10%" }}
        >
          <Grid container justify="center">
            <Grid item xs={12} className="w3-animate-top">
              <Fade top>
                <Typography
                  variant="h2"
                  style={{ fontFamily: styleConstants.fontStyle, fontWeight: styleConstants.fontWeight.bold, }}
                >
                  <span style={{ color: "#991514" }}>U</span>TEST AVENUE
                </Typography>
              </Fade>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ marginTop: "5%" }}
              className="w3-animate-top"
            >
              <Fade top>
                <Typography variant="h6" style={{ fontFamily: styleConstants.fontStyle, fontWeight: styleConstants.fontWeight.thin, }}>
                  A smartly-designed platform for simulation tests & intelligent
                  skill analysis
                </Typography>
              </Fade>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ marginTop: "10%" }}
              className="w3-animate-top"
            >
              <Fade top>
                <Link
                  to="/freetests"
                  style={{ textDecoration: "none", color: "#0f0f0f" }}
                >
                  <Button
                    variant="contained"
                    size="large"
                    style={{ backgroundColor: "#3f5e75", color: "white" }}
                  >
                    <Typography
                      variant="h6"
                      style={{ fontWeight: styleConstants.fontWeight.bold, fontFamily: styleConstants.fontStyle }}
                    >
                      Free test
                    </Typography>
                  </Button>
                </Link>
              </Fade>
            </Grid>
          </Grid>
        </Grid>
        <VisibilitySensor onChange={(isVisible) => {
          this.setState({ isVisible: isVisible })
        }}>
          <Grid item align="center" lg={6} md={12} style={{ padding: "5%" }}>
            <Fade>
              <CardMedia
                component="img"
                alt="Image"
                src="graphics/HomeImg.png"
                style={{ width: "100%" }}
                className="w3-animate-top"
              />
            </Fade>
          </Grid>
        </VisibilitySensor>
      </Grid>
    );

    return (
      <ThemeProvider theme={theme}>
        <Hidden lgUp>
          <AdvertisementSectionMobile />
        </Hidden>
        <Hidden mdDown>
          {webUI}
        </Hidden>
      </ThemeProvider>
    );
  }
}

export default compose(withStyles(useStyles))(AdvertisementSection);
