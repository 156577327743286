import React, { useEffect } from 'react';

//MUI
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Skeleton from '@material-ui/lab/Skeleton';

import { ThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';

//Components

//Font
import 'typeface-glacial-indifference';

//Others
const axios = require('axios').default;
const FormData = require('form-data');
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

const useStyles = theme => ({
    root: {
        padding: theme.spacing(12, 4),
    }
});

const theme = createMuiTheme({
    typography: {
        fontFamily: [
            'Roboto'
        ].join(','),
    },
});

const BandScoreDescription = (props) => {
    useEffect(async () => {
        console.log(props.bandScoreObj);
    }, []);

    const mobileUI = (<Accordion defaultExpanded style={{ borderRadius: "15px" }} >
        <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            style={{
                backgroundColor: "#25333e",
                color: "white",
                borderTopLeftRadius: "15px",
                borderTopRightRadius: "15px"
            }}
        >
            <Typography variant="body1" style={{ fontWeight: "bold" }}>
                Band Score Description
            </Typography>
        </AccordionSummary>
        <AccordionDetails style={{
            backgroundColor: "#dbecf6",
            borderBottomLeftRadius: "15px",
            borderBottomRightRadius: "15px"
        }}>
            <Grid container spacing={4}>
                <Grid item xs={4} style={{ backgroundColor: "#42647d", borderBottomLeftRadius: "15px" }}>
                    <Typography variant="h5" style={{ color: "white", padding: "50px 0 50px" }}>
                        Band {props.isLoading ? <Skeleton /> : props.bandScoreObj?.score}
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="body1" align="left" style={{ padding: "20px 0 20px" }}>
                        {props.isLoading ? <Skeleton /> : props.bandScoreObj?.description}
                    </Typography>
                </Grid>
            </Grid>
        </AccordionDetails>
    </Accordion>)

    const webUI = (<Accordion defaultExpanded style={{ borderRadius: "15px" }} >
        <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            style={{
                backgroundColor: "#25333e",
                color: "white",
                borderTopLeftRadius: "15px",
                borderTopRightRadius: "15px"
            }}
        >
            <Typography variant="body1" style={{ fontWeight: "bold" }}>
                Band Score Description
            </Typography>
        </AccordionSummary>
        <AccordionDetails style={{
            backgroundColor: "#dbecf6",
            borderBottomLeftRadius: "15px",
            borderBottomRightRadius: "15px"
        }}>
            <Grid container spacing={4}>
                <Grid item xs={2} style={{ backgroundColor: "#42647d", borderBottomLeftRadius: "15px" }}>
                    <Typography variant="h5" style={{ color: "white", padding: "50px 0 50px" }}>
                        Band {props.isLoading ? <Skeleton /> : props.bandScoreObj?.score}
                    </Typography>
                </Grid>
                <Grid item xs={10}>
                    <Typography variant="body1" align="left" style={{ padding: "20px 0 20px" }}>
                        {props.isLoading ? <Skeleton /> : props.bandScoreObj?.description}
                    </Typography>
                </Grid>
            </Grid>
        </AccordionDetails>
    </Accordion>)

    return <ThemeProvider theme={theme}>
        <Hidden mdUp>
            {mobileUI}
        </Hidden>
        <Hidden smDown>
            {webUI}
        </Hidden>
    </ThemeProvider>
};

export default compose(
    withStyles(useStyles)
)(BandScoreDescription);
