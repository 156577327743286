import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { ThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import React, { Component } from 'react';
import { compose } from 'recompose';

//Icon
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import TodayRoundedIcon from '@material-ui/icons/TodayRounded';
import AccessAlarmsRoundedIcon from '@material-ui/icons/AccessAlarmsRounded';
import BlockRoundedIcon from '@material-ui/icons/BlockRounded';

//import other library
import 'react-calendar/dist/Calendar.css';
import {
    Link
} from "react-router-dom";

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '80%'
    }
});

const theme = createMuiTheme({
    typography: {
        fontFamily: [
            'Roboto'
        ].join(','),
    },
});

export class DoneVerifyPage extends Component {
    constructor() {
        super();
        this.state = {

        };
    }

    componentDidMount = () => {
        document.body.style.backgroundColor = "#efefef";
        document.querySelector("body").scrollTo(0, 0);
    }

    render() {
        const { classes } = this.props;

        const mobileUI = (
            <Container style={{ paddingBottom: '15%' }}>
                <Grid item xs={12} style={{ maxWidth: window.innerWidth, paddingRight: "3%" }}>
                    <Paper elevation={0} style={{ padding: '5%', marginTop: '20%' }}>
                        <Grid container spacing={4} justify='center'>
                            <Grid item xs={12} align='center'>
                                <Box
                                    component="img"
                                    style={{ width: '20%' }}
                                    alt="The house from the offer."
                                    src="/U Test Logo (new).png"
                                />
                            </Grid>
                            <Grid item align='center' xs={12}>
                                <Typography variant="h3" style={{ fontWeight: "bold" }}>
                                    Thank You!
                                </Typography>
                                <Typography variant="h5" style={{ fontWeight: "bold" }}>
                                    Your payment has been received.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} style={{ marginTop: "30px" }}>
                                <Typography variant="body1">
                                    You can close this page.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Container>
        )

        const webUI = (
            <Container maxWidth="md" style={{ paddingLeft: '15%', paddingRight: '15%', paddingBottom: '15%' }}>
                <Paper elevation={0} style={{ padding: '5%', marginTop: '40%' }}>
                    <Grid container spacing={4} justify='center'>
                        <Grid item xs={12} align='center'>
                            <Box
                                component="img"
                                style={{ width: '20%' }}
                                alt="The house from the offer."
                                src="/U Test Logo (new).png"
                            />
                        </Grid>
                        <Grid item align='center' xs={12}>
                            <Typography variant="h3" style={{ fontWeight: "bold" }}>
                                Thank You!
                            </Typography>
                            <Typography variant="h5" style={{ fontWeight: "bold" }}>
                                Your payment has been received.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: "30px" }}>
                            <Typography variant="body1">
                                You can close this page.
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        )

        return (
            <ThemeProvider theme={theme}>
                <Hidden mdUp>{mobileUI}</Hidden>
                <Hidden smDown>{webUI}</Hidden>
            </ThemeProvider>
        );
    }
}

export default compose(
    withStyles(useStyles)
)(DoneVerifyPage);
