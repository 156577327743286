import React from 'react';

//MUI
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
//import Slider from '@mui/material/Slider';
import Slider from '@material-ui/core/Slider';

import { ThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';

//Functions
import { BmatScoreMappingSectionOne, BmatScoreMappingSectionTwo } from '../../../../../functions/testReportFuntions';

//Services
import { DownloadWritingFile } from '../../../../../services/testReportServices';

//Components
import AreasOfImprovement from './AreasOfImprovement';

//Font
import 'typeface-glacial-indifference';
import PageBackdrop from '../../../PageBackdrop';

const useStyles = theme => ({
    root: {
        padding: theme.spacing(12, 4),
    }
});

const theme = createMuiTheme({
    typography: {
        fontFamily: [
            'Roboto'
        ].join(','),
    },
});

const scale = [
    {
        value: 0,
        label: '0%'
    },
    {
        value: 20,
        label: '20%'
    },
    {
        value: 40,
        label: '40%',
    },
    {
        value: 50,
        label: '50%'
    },
    {
        value: 60,
        label: '60%',
    },
    {
        value: 80,
        label: '80%'
    },
];

const scaleWriting = [
    {
        value: 100,
        label: 'E'
    },
    {
        value: 80,
        label: 'D'
    },
    {
        value: 60,
        label: 'C'
    },
    {
        value: 40,
        label: 'B',
    },
    {
        value: 20,
        label: 'A'
    },
];

const writingMap = {
    'E': 100,
    'D': 80,
    'C': 60,
    'B': 40,
    'A': 20
}

function valuetext(value) {
    return `${value}%`;
}

function valuetextWriting(value) {
    return value;
}

const SpectrumChart = (props) => {
    const [currentTab, setCurrentTab] = React.useState(1);
    const [isLoading, setIsLoading] = React.useState(false);

    const onSelectTab = (tab) => {
        setCurrentTab(tab);
    };

    const onClickDownloadTeacherFile = async () => {
        setIsLoading(true);

        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });

        await DownloadWritingFile(params.result_id, 'teacher', `bmat_writing_teacher_note_testid_${params.result_id}.pdf`);

        setIsLoading(false);
    }

    const mobileUI = (<Grid container style={{ paddingBottom: '200px' }}>
        <Grid item>
            <Button onClick={() => { onSelectTab(1) }} style={{
                borderBottom: currentTab === 1 ? "5px solid #43637c" : "",
                borderRadius: currentTab === 1 ? "0px" : "",
            }}>
                Spectrum Chart
        </Button>
        </Grid>
        <Grid item>
            {props.reportObj?.test_result?.total_question === 16 ? <Button disabled>
                Areas of Improvement
        </Button> : <Button onClick={() => { onSelectTab(2) }} style={{
                    borderBottom: currentTab === 2 ? "5px solid #43637c" : "",
                    borderRadius: currentTab === 2 ? "0px" : "",
                }}>
                    Areas of Improvement
        </Button>}
        </Grid>
        <Grid item xs={12}>
            <Divider />
        </Grid>
        {currentTab === 1 && <Grid item align='center' xs={12}>
            <Grid container>
                <Grid item xs={12} style={{ paddingTop: '50px' }}>
                    <Typography variant="h6" style={{ color: "black", fontWeight: 'bold' }}>
                        Overall Performance
                </Typography>
                </Grid>
                <Grid item xs={12} style={{ paddingTop: '30px' }}>
                    <Grid container spacing={1} justify='center'>
                        <Grid item align="left" xs={12} style={{ paddingLeft: "10px" }}>
                            <Typography variant="subtitle1" style={{ color: "black", fontWeight: 'bold' }}>
                                Section 1
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Slider
                                disabled
                                aria-label="Custom marks"
                                value={Math.round(props.reportObj?.test_result === undefined ? 0 : (parseFloat(BmatScoreMappingSectionOne(props.reportObj?.test_result?.parts[0]?.total_score)) / 9) * 100,)}
                                getAriaValueText={valuetext}
                                step={1}
                                valueLabelDisplay="auto"
                                marks={scale}
                                style={{ width: "350px", color: "white", background: "linear-gradient(90deg, rgba(255,0,0,1) 0%, rgba(254,253,0,1) 50%, rgba(0,191,46,1) 100%)" }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{ paddingTop: '10px' }}>
                    <Grid container spacing={1} justify='left'>
                        <Grid item align="left" xs={12} style={{ paddingLeft: "10px" }}>
                            <Typography variant="subtitle1" style={{ color: "black", fontWeight: 'bold' }}>
                                Section 2
                        </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Slider
                                disabled
                                aria-label="Custom marks"
                                value={Math.round(props.reportObj?.test_result === undefined ? 0 : (parseFloat(BmatScoreMappingSectionTwo(props.reportObj?.test_result?.parts[1]?.total_score)) / 9) * 100,)}
                                getAriaValueText={valuetext}
                                step={1}
                                valueLabelDisplay="auto"
                                marks={scale}
                                style={{ width: "350px", color: "white", background: "linear-gradient(90deg, rgba(255,0,0,1) 0%, rgba(254,253,0,1) 50%, rgba(0,191,46,1) 100%)" }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{ paddingTop: '10px' }}>
                    <Grid container spacing={1} justify='center'>
                        <Grid item align="left" xs={12} style={{ paddingLeft: "10px" }}>
                            <Typography variant="subtitle1" style={{ color: "black", fontWeight: 'bold' }}>
                                Section 3
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Slider
                                disabled
                                aria-label="Custom marks"
                                value={props.isGotWritingResult ? writingMap[`${props.writingNotesObj?.note?.quality_of_english}`] : 0}
                                getAriaValueText={valuetextWriting}
                                step={1}
                                valueLabelDisplay="auto"
                                marks={scaleWriting}
                                style={{ width: "350px", color: "white", background: "linear-gradient(90deg, rgba(255,0,0,1) 0%, rgba(254,253,0,1) 50%, rgba(0,191,46,1) 100%)" }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>}
        {currentTab === 2 && <Grid item align='center' xs={12}>
            <AreasOfImprovement
                onClickDownloadTeacherFile={onClickDownloadTeacherFile}
                reportObj={props.reportObj}
                isGotWritingResult={props.isGotWritingResult}
                writingNotesObj={props.writingNotesObj}
            />
        </Grid>}
    </Grid>)

    const webUI = (<Grid container style={{ paddingBottom: '200px' }}>
        <Grid item>
            <Button onClick={() => { onSelectTab(1) }} style={{
                borderBottom: currentTab === 1 ? "5px solid #43637c" : "",
                borderRadius: currentTab === 1 ? "0px" : "",
                fontSize: "1rem"
            }}>
                Spectrum Chart
            </Button>
        </Grid>
        <Grid item>
            {props.reportObj?.test_result?.total_question === 16 ? <Button disabled style={{
                fontSize: "1rem"
            }}>
                Areas of Improvement
            </Button> : <Button onClick={() => { onSelectTab(2) }} style={{
                    borderBottom: currentTab === 2 ? "5px solid #43637c" : "",
                    borderRadius: currentTab === 2 ? "0px" : "",
                    fontSize: "1rem"
                }}>
                    Areas of Improvement
            </Button>}
        </Grid>
        <Grid item xs={12}>
            <Divider />
        </Grid>
        {currentTab === 1 && <Grid item align='center' xs={12}>
            <Grid container>
                <Grid item xs={12} style={{ paddingTop: '50px' }}>
                    <Typography variant="h6" style={{ color: "black", fontWeight: 'bold' }}>
                        Overall Performance
                    </Typography>
                </Grid>
                <Grid item xs={12} style={{ paddingTop: '30px' }}>
                    <Grid container spacing={4} justify='center'>
                        <Grid item>
                            <Typography variant="subtitle1" style={{ color: "black", fontWeight: 'bold' }}>
                                Section 1
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Slider
                                disabled
                                aria-label="Custom marks"
                                value={Math.round(props.reportObj?.test_result === undefined ? 0 : (parseFloat(BmatScoreMappingSectionOne(props.reportObj?.test_result?.parts[0]?.total_score)) / 9) * 100,)}
                                getAriaValueText={valuetext}
                                step={1}
                                valueLabelDisplay="auto"
                                marks={scale}
                                style={{ width: "500px", color: "white", background: "linear-gradient(90deg, rgba(255,0,0,1) 0%, rgba(254,253,0,1) 50%, rgba(0,191,46,1) 100%)" }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{ paddingTop: '10px' }}>
                    <Grid container spacing={4} justify='center'>
                        <Grid item>
                            <Typography variant="subtitle1" style={{ color: "black", fontWeight: 'bold' }}>
                                Section 2
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Slider
                                disabled
                                aria-label="Custom marks"
                                value={Math.round(props.reportObj?.test_result === undefined ? 0 : (parseFloat(BmatScoreMappingSectionTwo(props.reportObj?.test_result.parts[1]?.total_score)) / 9) * 100,)}
                                getAriaValueText={valuetext}
                                step={1}
                                valueLabelDisplay="auto"
                                marks={scale}
                                style={{ width: "500px", color: "white", background: "linear-gradient(90deg, rgba(255,0,0,1) 0%, rgba(254,253,0,1) 50%, rgba(0,191,46,1) 100%)" }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{ paddingTop: '10px' }}>
                    <Grid container spacing={4} justify='center'>
                        <Grid item>
                            <Typography variant="subtitle1" style={{ color: "black", fontWeight: 'bold' }}>
                                Section 3
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Slider
                                disabled
                                aria-label="Custom marks"
                                value={props.isGotWritingResult ? writingMap[`${props.writingNotesObj?.note?.quality_of_english}`] : 0}
                                getAriaValueText={valuetextWriting}
                                step={1}
                                valueLabelDisplay="auto"
                                marks={scaleWriting}
                                style={{ width: "500px", color: "white", background: "linear-gradient(90deg, rgba(255,0,0,1) 0%, rgba(254,253,0,1) 50%, rgba(0,191,46,1) 100%)" }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>}
        {currentTab === 2 && <Grid item align='center' xs={12}>
            <AreasOfImprovement
                onClickDownloadTeacherFile={onClickDownloadTeacherFile}
                reportObj={props.reportObj}
                isGotWritingResult={props.isGotWritingResult}
                writingNotesObj={props.writingNotesObj}
            />
        </Grid>}
    </Grid>)

    return <ThemeProvider theme={theme}>

        <PageBackdrop isLoading={isLoading} />

        <Hidden mdUp>
            {mobileUI}
        </Hidden>
        <Hidden smDown>
            {webUI}
        </Hidden>
    </ThemeProvider>
};

export default compose(
    withStyles(useStyles)
)(SpectrumChart);
