//React
import { Component } from 'react';
import { compose } from 'recompose';

//Material UI
import Backdrop from '@material-ui/core/Backdrop';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

//react-router-dom
import { CreateSpeakingLog, GetSpeakingInformation, GetSpeakingLogById } from '../../../../services/testServices';

const axios = require('axios').default;
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%'
    }
});

class ScoringPage extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
            scoreObj: {}
        };
    }

    componentDidMount = async () => {
        const query = await new URLSearchParams(this.props.location.search);

        await this.setState({
            tester: query.get('tester'),
            testid: query.get('testid'),
            id: query.get('id'),
            password: query.get('password'),
            isLoading: true
        });

        await this.getSpeakingTestLog();
        await this.getSpeakinginformation();

        this.setState({
            isLoading: false
        });
    }

    getSpeakingTestLog = async () => {
        const res = await GetSpeakingLogById(`${this.state.id}_${this.state.password}`);

        if (res.success) {
            this.setState({
                scoreObj: res.res.score,
                scoreinfoObj: res.res
            });
        }
    }

    getSpeakinginformation = async () => {
        const res = await GetSpeakingInformation(this.state.id, this.state.password);

        console.log(res);
        if (res.success) {
            this.setState({
                meetingObj: res.res
            });
        }
    }

    handleSubmitScore = async () => {
        try {
            this.setState({
                isLoading: true
            });

            await axios({
                method: 'post',
                url: "https://chatter-funky-busby.glitch.me/test/head-teacher/submit",
                data: {
                    note: {
                        test_id: `${this.state.id}_${this.state.password}`,
                        tester: this.state.scoreinfoObj.user_name,
                        teacher: this.state.scoreinfoObj.teacher_username,
                        note: this.state.scoreObj
                    },
                    submitted_test_id: "speaking",
                    createdAt: new Date(Date.now())
                }
            });

            // await axios({
            //     method: 'post',
            //     url: api_base_url + "/api/test/test-speaking-note",
            //     headers: {
            //         Authorization: `Bearer ${JSON.parse(localStorage.getItem('user')).token}`
            //     },
            //     data: {
            //         "note": [
            //             {
            //                 "test_id": `${this.state.id}_${this.state.password}`,
            //                 "tester": this.state.scoreinfoObj.user_name,
            //                 "teacher": this.state.scoreinfoObj.teacher_username,
            //                 "note": this.state.scoreObj
            //             }
            //         ]
            //     }
            // })

            const res = await CreateSpeakingLog({
                id: `${this.state.id}_${this.state.password}`,
                user_email: this.state.scoreinfoObj.user_email,
                user_name: this.state.scoreinfoObj.user_name,
                teacher_username: this.state.scoreinfoObj.teacher_username,
                teacher_name: this.state.scoreinfoObj.teacher_name,
                score: this.state.scoreObj,
                time_left: this.state.scoreinfoObj.time_left
            });

            if (res.success) {
                this.setState({
                    isLoading: false
                });

                this.props.history.push('/teacher/dashboard');

                return;
            }

            this.setState({
                isLoading: false
            });
        } catch (error) {
            console.error(error);
        }
    }

    handleSkipScoring = async () => {
        this.setState({
            isLoading: true
        });

        const res = await CreateSpeakingLog({
            id: `${this.state.id}_${this.state.password}`,
            user_email: this.state.scoreinfoObj.user_email,
            user_name: this.state.scoreinfoObj.user_name,
            teacher_username: this.state.scoreinfoObj.teacher_username,
            teacher_name: this.state.scoreinfoObj.teacher_name,
            score: this.state.scoreObj,
            time_left: this.state.scoreinfoObj.time_left
        });

        if (res.success) {
            this.setState({
                isLoading: false
            });

            this.props.history.push('/profile');

            return;
        }

        this.setState({
            isLoading: false
        });
    }

    handleChange = (event) => {
        this.state.scoreObj[event.target.name] = event.target.value
        this.setState({
            scoreObj: this.state.scoreObj
        });
    }

    render() {
        return (
            <Grid container spacing={2} style={{ marginTop: '1px', paddingBottom: '200px', padding: '5%', backgroundColor: '#efefef' }}>
                <Grid item xs={12} sm={6}>
                    <Paper style={{ padding: '5%' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h4" gutterBottom style={{ color: '#76323F', fontSize: '2rem' }}>
                                    <Box fontWeight="fontWeightBold" display='inline'>Test information</Box>
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1" gutterBottom style={{ color: '#76323F', fontSize: '1rem' }}>
                                    <Box fontWeight="fontWeightBold" display='inline'>Tester</Box>
                                </Typography>
                                <Typography variant="body1" gutterBottom style={{ color: '#76323F', fontSize: '1rem' }}>
                                    {this.state.scoreinfoObj?.user_name}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1" gutterBottom style={{ color: '#76323F', fontSize: '1rem' }}>
                                    <Box fontWeight="fontWeightBold" display='inline'>Teacher</Box>
                                </Typography>
                                <Typography variant="body1" gutterBottom style={{ color: '#76323F', fontSize: '1rem' }}>
                                    {this.state.scoreinfoObj?.teacher_name}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1" gutterBottom style={{ color: '#76323F', fontSize: '1rem' }}>
                                    <Box fontWeight="fontWeightBold" display='inline'>Test ID</Box>
                                </Typography>
                                <Typography variant="body1" gutterBottom style={{ color: '#76323F', fontSize: '1rem' }}>
                                    {this.state.scoreinfoObj?.id}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Paper style={{ padding: '5%' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h4" gutterBottom style={{ color: '#76323F', fontSize: '2rem' }}>
                                    <Box fontWeight="fontWeightBold" display='inline'>Submit Speaking Score</Box>
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6" gutterBottom style={{ color: '#76323F', fontSize: '1rem' }}>
                                    <Box fontWeight="fontWeightBold" display='inline'>Score</Box>
                                </Typography>
                                <TextField name="score" id="outlined-score" variant="outlined" type="number" value={this.state.scoreObj?.score} onChange={this.handleChange} style={{ width: '100%' }} />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6" gutterBottom style={{ color: '#76323F', fontSize: '1rem' }}>
                                    <Box fontWeight="fontWeightBold" display='inline'>Fluency and Coherence</Box>
                                </Typography>
                                <TextField name="fluency_and_coherence" id="outlined-fluency" variant="outlined" type="number" value={this.state.scoreObj?.fluency_and_coherence} onChange={this.handleChange} style={{ width: '100%' }} />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6" gutterBottom style={{ color: '#76323F', fontSize: '1rem' }}>
                                    <Box fontWeight="fontWeightBold" display='inline'>Lexical Resource</Box>
                                </Typography>
                                <TextField name="lexical_resource" id="outlined-lexical" variant="outlined" type="number" value={this.state.scoreObj?.lexical_resource} onChange={this.handleChange} style={{ width: '100%' }} />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6" gutterBottom style={{ color: '#76323F', fontSize: '1rem' }}>
                                    <Box fontWeight="fontWeightBold" display='inline'>Gramatical Range and Accuracy</Box>
                                </Typography>
                                <TextField name="gramatical_range_and_accuracy" id="outlined-grammar" variant="outlined" type="number" value={this.state.scoreObj?.gramatical_range_and_accuracy} onChange={this.handleChange} style={{ width: '100%' }} />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6" gutterBottom style={{ color: '#76323F', fontSize: '1rem' }}>
                                    <Box fontWeight="fontWeightBold" display='inline'>Pronunciation</Box>
                                </Typography>
                                <TextField name="pronunciation" id="outlined-pronunciation" variant="outlined" type="number" value={this.state.scoreObj?.pronunciation} onChange={this.handleChange} style={{ width: '100%' }} />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6" gutterBottom style={{ color: '#76323F', fontSize: '1rem' }}>
                                    <Box fontWeight="fontWeightBold" display='inline'>Comments</Box>
                                </Typography>
                                <TextField
                                    id="outlined-multiline-static"
                                    name="comment"
                                    multiline
                                    rows={4}
                                    variant="outlined"
                                    defaultValue={this.state.scoreObj?.comment}
                                    onChange={this.handleChange}
                                    style={{ width: '100%' }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    disableElevation
                                    variant="contained"
                                    style={{ backgroundColor: '#76323F', color: 'white' }}
                                    onClick={this.handleSubmitScore}
                                >
                                    Submit
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    disableElevation
                                    variant="contained"
                                    style={{ backgroundColor: '#bbb8bb', color: 'white' }}
                                    onClick={this.handleSkipScoring}
                                >
                                    Skip
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

                <Backdrop open={this.state.isLoading} style={{ zIndex: 1200, color: "#76323F" }}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Grid>
        )
    }
}

export default compose(
    withStyles(useStyles)
)(ScoringPage);
