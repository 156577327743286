//Material UI
import { TextField, Grid, Typography, Button, Avatar, Paper, Divider } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';
import Box from '@material-ui/core/Box';
import CardMedia from '@material-ui/core/CardMedia';
import Container from '@material-ui/core/Container';

//React
import { Component } from 'react';

//Constants
import * as footerConstants from '../../constants/footerConstants';

//react-router-dom
import { HashLink } from 'react-router-hash-link';

const useStyles = theme => ({
    root: {
        borderTop: '1px solid #ddd',
        height: '60px',
        lineHeight: '60px',
        backgroundColor: 'white'
    },
});

class Footer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            window: {
                height: window.innerHeight,
                width: window.innerWidth
            },
            userSignedIn: false
        };
    }

    componentDidMount = () => {
        this.setState({
            userSignedIn: JSON.parse(localStorage.getItem("userSignedIn"))
                ? JSON.parse(localStorage.getItem("userSignedIn"))
                : false
        });
    }

    componentWillUnmount = () => {
        window.removeEventListener("resize", this.handleResize);
    }

    handleResize = (event) => {
        this.setState({
            window: {
                height: window.innerHeight,
                width: window.innerWidth
            }
        });
    };

    handleChangeCalendarFormat = (event) => {
        const name = event.target.name;
        this.setState({
            ...this.state,
            [name]: event.target.checked,
        });
    };

    render() {
        const { classes } = this.props;

        return (
            <Grid container style={{
                width: '100%',
                flexDirection: 'row',
                backgroundColor: '#efefef',
                maxWidth: window.innerWidth,
                //borderTop: '2px solid #d4d4d4'
            }}>
                <Container maxWidth={false}>
                    <Grid item xs={12}>
                        <Divider variant='fullWidth' />
                    </Grid>
                    <Grid item xs={12}>
                        <Box
                            color='#efefef'
                            style={{ padding: "5%" }}
                        >
                            <Grid container>
                                <Grid item align='left' xs={12} style={{ zIndex: 1 }}>
                                    <Grid container justify='left' spacing={5} style={{ top: "-25%" }}>
                                        <Grid item align='center' xs={12} md={2}>
                                            <Avatar variant="square" src="/U Test Logo (new) square.png" />
                                        </Grid>
                                        {footerConstants.footerMenus.map((menu, index) => {
                                            return (
                                                menu.isRequireLogin ? (this.state.userSignedIn && <Grid key={menu.name} item xs={12} md>
                                                    <Typography align='left' variant="h6" style={{ color: '#1d1d1d', fontWeight: 'bold' }}>
                                                        {menu.header}
                                                    </Typography>
                                                    {
                                                        menu.subHeader.map((subHeader, index) => {
                                                            return (
                                                                !(subHeader.isRequireLogin && JSON.parse(localStorage.getItem("user")) === null) && <Box key={subHeader.name} style={{ marginTop: index === 0 ? '20px' : '' }}>
                                                                    <HashLink smooth to={subHeader.url} style={{ textDecoration: 'none', color: '#6a6a6a' }} >
                                                                        <Typography align='left' variant="body1">
                                                                            {subHeader.name}
                                                                        </Typography>
                                                                    </HashLink>
                                                                </Box>
                                                            )
                                                        })
                                                    }
                                                </Grid>) : (<Grid key={menu.name} item xs={12} md>
                                                    <Typography align='left' variant="h6" style={{ color: '#1d1d1d', fontWeight: 'bold' }}>
                                                        {menu.header}
                                                    </Typography>
                                                    {
                                                        menu.subHeader.map((subHeader, index) => {
                                                            return (
                                                                !(subHeader.isRequireLogin && JSON.parse(localStorage.getItem("user")) === null) && <Box key={subHeader.name} style={{ marginTop: index === 0 ? '20px' : '' }}>
                                                                    <HashLink smooth to={subHeader.url} style={{ textDecoration: 'none', color: '#6a6a6a' }} >
                                                                        <Typography align='left' variant="body1">
                                                                            {subHeader.name}
                                                                        </Typography>
                                                                    </HashLink>
                                                                </Box>
                                                            )
                                                        })
                                                    }
                                                </Grid>)
                                            )
                                        })}
                                    </Grid>
                                    <Grid item align='center' xs={12} style={{ paddingTop: '100px' }}>
                                        <Typography align='center' variant="subtitle1" style={{ marginTop: '20px', color: '#6a6a6a' }}>
                                            <Box textAlign='center' pb={{ xs: 5, sm: 0 }} style={{ fontSize: '0.7rem' }}>
                                                Copyright &reg; 2021 uTest Avenue
                                            </Box>
                                        </Typography>
                                        <Typography align='center' variant="subtitle1" style={{ color: '#6a6a6a' }}>
                                            <Box textAlign='center' pb={{ xs: 5, sm: 0 }} style={{ fontSize: '0.7rem' }}>
                                                Contact us at contact@utestavenue.com
                                            </Box>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Container>
            </Grid>
        )
    }
}
export default compose(
    withStyles(useStyles)
)(Footer);
