//Material UI
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';

//Social login

//React
import { Component } from 'react';

const useStyles = theme => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    root: {
        flexGrow: 1,
    },
});

class PageBackdrop extends Component {
    render() {
        const { classes } = this.props;

        return (
            <Backdrop className={classes.backdrop} open={this.props.isLoading}>
                <CircularProgress color="inherit" style={{ color: "#880000" }} />
            </Backdrop>
        )
    }
}
export default compose(
    withStyles(useStyles)
)(PageBackdrop);