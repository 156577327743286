import React from 'react';

//MUI
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';

import { ThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';

//Icons
import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecordRounded';

//Components

//Font
import 'typeface-glacial-indifference';

//Others
import Chart from "react-google-charts";
const axios = require('axios').default;
const FormData = require('form-data');
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

const useStyles = theme => ({
    root: {
        padding: theme.spacing(12, 4),
    }
});

const theme = createMuiTheme({
    typography: {
        fontFamily: [
            'Roboto'
        ].join(','),
    },
});

const ScoreVisualization = (props) => {
    const mobileUI = (<Grid container>
        <Grid item>
            <Button style={{ borderBottom: "5px solid #43637c", borderRadius: "0px", textTransform: "none" }}>
                Overall Band Score
            </Button>
        </Grid>
        <Grid item>
            <Button disabled style={{ textTransform: "none" }}>
                Skill Analysis Section
            </Button>
        </Grid>
        <Grid item xs={12}>
            <Divider />
        </Grid>
        <Grid item align='center' xs={12}>
            <Grid container>
                <Grid item xs={12} style={{ paddingTop: '50px' }}>
                    <Grid container spacing={2} justify="center">
                        <Grid item>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexWrap: 'wrap'
                            }}>
                                <FiberManualRecordRoundedIcon style={{ color: "#636363", marginRight: '5px' }} />
                                <Typography variant="body2" style={{ color: "black" }}>
                                    AVG Users
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexWrap: 'wrap'
                            }}>
                                <FiberManualRecordRoundedIcon style={{ color: "#a6a6a6", marginRight: '5px' }} />
                                <Typography variant="body2" style={{ color: "black" }}>
                                    AVG Test takers
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexWrap: 'wrap'
                            }}>
                                <FiberManualRecordRoundedIcon style={{ color: "#880000", marginRight: '5px' }} />
                                <Typography variant="body2" style={{ color: "black" }}>
                                    You
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Chart
                    chartType="ColumnChart"
                    width="100%"
                    height="400px"
                    data={[
                        ['Category', 'Avg Users', 'Avg Test Takers', 'You'],
                        ['Listening',
                            6.5,
                            7,
                            props.reportObj.test_type === "IELTS LISTENING FREE" ? props.bandScoreObj?.score : 0],
                        ['Reading',
                            6,
                            6.5,
                            props.reportObj.test_type === "IELTS LISTENING FREE" ? 0 : props.bandScoreObj?.score],
                        ['Writing',
                            5,
                            6,
                            0],
                        ['Speaking',
                            5.5,
                            6.5,
                            0]
                    ]}
                    options={{
                        // Material design options
                        chartArea: { width: '90%' },
                        colors: ['#636363', '#a6a6a6', '#880000'],
                        title: 'Overall Band Score Comparison',
                        height: 400,
                        backgroundColor: "#efefef"
                    }}
                />
            </Grid>
        </Grid>
    </Grid>)

    const webUI = (<Grid container>
        <Grid item>
            <Button style={{ borderBottom: "5px solid #43637c", borderRadius: "0px", fontSize: "1rem" }}>
                Overall Band Score
            </Button>
        </Grid>
        <Grid item>
            <Button disabled style={{ fontSize: "1rem" }}>
                Skill Analysis Section
            </Button>
        </Grid>
        <Grid item xs={12}>
            <Divider />
        </Grid>
        <Grid item align='center' xs={12}>
            <Grid container>
                <Grid item xs={12} style={{ paddingTop: '50px' }}>
                    <Grid container spacing={2} justify="center">
                        <Grid item>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexWrap: 'wrap'
                            }}>
                                <FiberManualRecordRoundedIcon style={{ color: "#636363", marginRight: '5px' }} />
                                <Typography variant="subtitle1" style={{ color: "black" }}>
                                    AVG Users
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexWrap: 'wrap'
                            }}>
                                <FiberManualRecordRoundedIcon style={{ color: "#a6a6a6", marginRight: '5px' }} />
                                <Typography variant="subtitle1" style={{ color: "black" }}>
                                    AVG Test takers
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexWrap: 'wrap'
                            }}>
                                <FiberManualRecordRoundedIcon style={{ color: "#880000", marginRight: '5px' }} />
                                <Typography variant="subtitle1" style={{ color: "black" }}>
                                    You
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Chart
                    chartType="ColumnChart"
                    width="100%"
                    height="400px"
                    data={[
                        ['Category', 'Avg Users', 'Avg Test Takers', 'You'],
                        ['Listening',
                            6.5,
                            7,
                            props.reportObj.test_type === "IELTS LISTENING FREE" ? props.bandScoreObj?.score : 0],
                        ['Reading',
                            6,
                            6.5,
                            props.reportObj.test_type === "IELTS LISTENING FREE" ? 0 : props.bandScoreObj?.score],
                        ['Writing',
                            5,
                            6,
                            0],
                        ['Speaking',
                            5.5,
                            6.5,
                            0]
                    ]}
                    options={{
                        // Material design options
                        chartArea: { width: '96%' },
                        colors: ['#636363', '#a6a6a6', '#880000'],
                        title: 'Overall Band Score Comparison',
                        height: 400,
                        backgroundColor: "#efefef"
                    }}
                />
            </Grid>
        </Grid>
    </Grid>)

    return <ThemeProvider theme={theme}>
        <Hidden mdUp>
            {mobileUI}
        </Hidden>
        <Hidden smDown>
            {webUI}
        </Hidden>
    </ThemeProvider>
};

export default compose(
    withStyles(useStyles)
)(ScoreVisualization);
