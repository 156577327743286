import React from 'react';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import MuiAlert from '@material-ui/lab/Alert';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

//Icons
import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';

//Others
import { withStyles } from '@material-ui/core/styles';
import { Component } from 'react';
import { compose } from 'recompose';
import Select from 'react-select'

//Models
import { GetAllSections, CreateNewSection } from '../../../../models/addPart';

const axios = require('axios').default;
const FormData = require('form-data');
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

const useStyles = theme => ({
    root: {
        margin: 15,
        marginTop: 50
    },
    buttonColor: {
        backgroundColor: '#76323F',
        color: 'white'
    }
});

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class CreateNewPart extends Component {
    constructor() {
        super()
        this.state = {
            selectedSection: {},
            selectedSectionArr: [],
            selectedSectionArrToShow: [],
            testsToShow: [],
            numSection: [],
            isLoading: false,
            isExistVersion: false,
            isCreatedSection: false,
            partObj: {}
        };
    }

    componentDidMount = async () => {
        document.querySelector("body").scrollTo(0, 0);
        this.state.partObj["sections"] = this.state.selectedSectionArr;
        await this.handleGetAllSections();
        await this.handleGetAllPart();
    }

    handleGetAllSections = async () => {
        let sectionsToShow = []
        const res = await GetAllSections(JSON.parse(localStorage.getItem('user')).token);
        await res.res.section?.filter((section) => section.question_type !== "multipleChoiceBMAT" && section.question_type !== "writingBaseBMAT").map((section) => {
            sectionsToShow.push({
                value: section,
                label: `${section.section_id} - ${this.handleConvertQuestionType(section.question_type)}`
            })
        })

        this.setState({
            sectionsToShow: sectionsToShow
        });
    }

    handleGetAllPart = async () => {
        try {
            const readingToshow = [];
            const listeningToshow = [];
            const writingToshow = [];
            const bmatToshow = [];

            this.setState({
                isLoadingParts: true
            });

            const res = await axios({
                method: 'get',
                url: `${api_base_url}/api/test/part/all`,
                headers: {
                    "Authorization": "Bearer " + JSON.parse(localStorage.getItem('user')).token
                }
            });

            const resData = res.data;

            const readingArr = await resData.data.filter((part) => part.part_type === "READING");
            const listeningArr = await resData.data.filter((part) => part.part_type === "LISTENING");
            const writingArr = await resData.data.filter((part) => part.part_type === "WRITING");
            const bmatArr = await resData.data.filter((part) => part.part_type !== "READING" && part.part_type !== "LISTENING" && part.part_type !== "WRITING");

            await readingArr.map((part) => {
                readingToshow.push(part.version);
            });

            await listeningArr.map((part) => {
                listeningToshow.push(part.version);
            });

            await writingArr.map((part) => {
                writingToshow.push(part.version);
            });

            await bmatArr.map((part) => {
                bmatToshow.push(part.version);
            });

            this.setState({
                readingToshow: readingToshow,
                listeningToshow: listeningToshow,
                writingToshow: writingToshow,
                bmatToshow: bmatToshow,
                partInfo: resData,
                isLoadingParts: false
            });
        } catch (err) {
            console.log(err);
            this.setState({
                isLoadingParts: false
            });
        }
    }

    handleAddNewSection = async () => {
        await this.state.numSection.push('new');
        this.setState({
            numSection: this.state.numSection
        });
    }

    handleCreateNewSection = async () => {
        console.log(this.state);
        const response = await CreateNewSection(JSON.parse(localStorage.getItem('user')).token, this.state.partObj);
        if (response.success) {
            this.setState({
                selectedSection: {},
                selectedSectionArr: [],
                testsToShow: [],
                numSection: [],
                isLoading: false,
                isExistVersion: false,
                isCreatedSection: true,
                partObj: {}
            });

            this.props.handleSelectMenu('allParts');
        }
    }

    handleSelectSection = async (event, index) => {
        if (event === null) {
            this.state.selectedSectionArr[index] = undefined;
            this.state.selectedSectionArrToShow[index] = undefined;

            this.setState({
                selectedSection: undefined,
            });
        } else if (event !== null) {
            this.state.selectedSectionArr[index] = event.value.section_id
            this.state.selectedSectionArrToShow[index] = event.value

            await this.setState({
                selectedSection: event.value,
                selectedSectionArr: this.state.selectedSectionArr,
                selectedSectionArrToShow: this.state.selectedSectionArrToShow
            });
        }
    }

    handleSelectPart = async (event) => {
        if (event === null) {
            this.state.partObj["part_type"] = undefined
        } else if (event !== null) {
            this.state.partObj["part_type"] = event.value
        }

        console.log(this.state);
    }

    handleEditPartObj = (event) => {
        this.state.partObj[event.target.name] = event.target.value;

        /*if (event.target.name === "version") {
            if (this.state.partObj["part_type"] !== undefined){
                this.setState({
                    isExistVersion: this.state[`${this.state.partObj["part_type"].toLowerCase()}Toshow`].includes(this.state.partObj["version"])
                });
            } else {
                this.setState({
                    isExistVersion: false
                });
            }
        }*/
    }

    //==================================== QUESTION TEXT FORMAT ====================================
    handleConvertQuestionType = (question) => {
        if (question === "trueFalseNG") {
            return "True/False/Not given"
        } if (question === "yesNoNG") {
            return "Yes/No/Not given"
        } if (question === "choose2Letters") {
            return "Choose 2 Letters"
        } if (question === undefined) {
            return "-"
        }

        const result = question.replace(/([A-Z])/g, " $1");
        const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
        return finalResult
    }
    //==================================== QUESTION TEXT FORMAT ====================================

    render() {
        const { classes } = this.props;

        return (
            <Grid item xs={12}>
                <Paper>
                    <Grid container spacing={2} style={{ padding: '20px' }}>
                        <Grid item align='left' xs={12}>
                            <Typography variant="h4" style={{ fontWeight: 'bold' }} gutterBottom>
                                Create new section
                            </Typography>
                        </Grid>
                        <Grid item align='left' xs={12}>
                            <Typography variant="h5" style={{ fontWeight: 'bold' }} gutterBottom>
                                Select test type
                            </Typography>
                        </Grid>
                        <Grid item align='left' xs={6}>
                            <Select
                                name="testType"
                                isSearchable={true}
                                isClearable={true}
                                options={[
                                    { value: 'IELTS', label: 'IELTS' },
                                    //{ value: 'BMAT', label: 'BMAT' }
                                ]}
                            />
                        </Grid>
                        <Grid item align='left' xs={12}>
                            <Typography variant="h5" style={{ fontWeight: 'bold' }} gutterBottom>
                                Select section type
                            </Typography>
                        </Grid>
                        <Grid item align='left' xs={12} sm={6}>
                            <Select
                                name="part_type"
                                isSearchable={true}
                                isClearable={true}
                                options={[
                                    { value: 'SPEAKING', label: 'Speaking' },
                                    { value: 'READING', label: 'Reading' },
                                    { value: 'LISTENING', label: 'Listening' }
                                ]}
                                onChange={this.handleSelectPart}
                            />
                        </Grid>
                        <Grid item align='left' xs={12}>
                            <Typography variant="h5" style={{ fontWeight: 'bold' }} gutterBottom>
                                Section number
                            </Typography>
                            <TextField
                                name="part"
                                id="outlined-basic-title"
                                variant="outlined"
                                size="small"
                                onChange={this.handleEditPartObj}
                            />
                        </Grid>
                        <Grid item align='left' xs={12}>
                            <Typography variant="h5" style={{ fontWeight: 'bold' }} gutterBottom>
                                Version
                            </Typography>
                            <TextField
                                error={this.state.isExistVersion}
                                helperText={this.state.isExistVersion ? "Section version is already exist." : ""}
                                name="version"
                                id="outlined-basic-title"
                                variant="outlined"
                                size="small"
                                onChange={this.handleEditPartObj}
                            />
                        </Grid>
                        <Grid item align='left' xs={12}>
                            <Typography variant="h5" style={{ fontWeight: 'bold' }} gutterBottom>
                                Title
                            </Typography>
                            <TextField
                                name="title"
                                id="outlined-basic-description"
                                variant="outlined"
                                size="small"
                                style={{ width: '50%' }}
                                onChange={this.handleEditPartObj}
                            />
                        </Grid>
                        <Grid item align='left' xs={12}>
                            <Typography variant="h5" style={{ fontWeight: 'bold' }} gutterBottom>
                                Description
                            </Typography>
                            <TextField
                                name="description"
                                id="outlined-basic-description"
                                variant="outlined"
                                size="small"
                                style={{ width: '50%' }}
                                onChange={this.handleEditPartObj}
                            />
                        </Grid>
                        <Grid item align='left' xs={12}>
                            <Typography variant="h5" style={{ fontWeight: 'bold' }} gutterBottom>
                                Add questions
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Grid container spacing={2}>
                                {this.state.numSection.map((section, secIndex) =>
                                    <Grid item key={`section-${secIndex}`} align='left' xs={12}>
                                        <Select
                                            name="sections"
                                            isLoading={!this.state.sectionsToShow}
                                            isSearchable={true}
                                            isClearable={true}
                                            options={this.state.sectionsToShow === undefined ? [] : this.state.sectionsToShow}
                                            onChange={(event) => { this.handleSelectSection(event, secIndex) }}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>

                        {this.state.numSection.map((section, index) =>
                            <Grid key={`section-description-${index}`} item xs={12}>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography className={classes.heading}>{this.state.selectedSectionArrToShow[index]?.section_id === undefined ? "-" : `${this.state.selectedSectionArrToShow[index]?.section_id} - ${this.handleConvertQuestionType(this.state.selectedSectionArrToShow[index]?.question_type)}`}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Grid container spacing={2}>
                                                    <Grid item align="left" xs>
                                                        <Typography style={{ fontWeight: 'bold' }}>
                                                            Type:
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item align="left" xs={11}>
                                                        <Typography>
                                                            {this.handleConvertQuestionType(this.state.selectedSectionArrToShow[index]?.question_type)}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={2}>
                                                    <Grid item align="left" xs>
                                                        <Typography style={{ fontWeight: 'bold' }}>
                                                            Description:
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item align="left" xs={11}>
                                                        <Typography>
                                                            {this.state.selectedSectionArr[index] === undefined ? "-" : this.state.selectedSectionArrToShow[index]?.section}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        )}
                        <Grid item align='left' xs={12}>
                            <IconButton aria-label="add" onClick={this.handleAddNewSection}>
                                <AddBoxRoundedIcon fontSize="small" />
                            </IconButton>
                        </Grid>
                        <Grid item align='left' xs={12} style={{ marginTop: '50px' }}>
                            <Button
                                variant="contained"
                                className={classes.buttonColor}
                                onClick={this.handleCreateNewSection}
                            >
                                Create
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        )
    }
}
export default compose(
    withStyles(useStyles)
)(CreateNewPart);