import React, { useEffect, useState } from 'react';

//React
import { compose } from 'recompose';

//Material UI
import Box from "@material-ui/core/Box";
import Container from '@material-ui/core/Container';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';

import { withStyles } from '@material-ui/core/styles';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import styled, { keyframes } from 'styled-components';

//react-router-dom
import {
    Link
} from "react-router-dom";

//Components
import AboutUsSection from './components/AboutUsSection';
import AdvertisementSection from './components/AdvertisementSection';
import ContactUsSection from './components/ContactUsSection';
import OurTestsSection from './components/OurTestsSection';
import PodcastSection from './components/PodcastSection';
import PinnedBlogsSection from './components/PinnedBlogsSection';
import ReviewsSection from './components/ReviewsSection';
import ContentSection from './components/ContentSection';

//Animations
import Slide from 'react-reveal/Slide';
import Pulse from 'react-reveal/Pulse';
import VisibilitySensor from 'react-visibility-sensor';

//Others
const axios = require('axios').default;

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%'
    },
    rootContainer: {
        maxWidth: '75%'

    },
    rootCard: {
        maxWidth: "75%",
        width: '75%',
        margin: 10,
    },
    gridContainer: {
        maxWidth: '90%'
    },
    margin: {
        margin: 100
    },
    marginTop: {
        marginTop: 100
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginTop: theme.spacing(5)
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    header: {
        maxWidth: "80%"
    },
    body: {
        maxWidth: "80%",
        marginTop: 30
    },
    divider: {
        maxWidth: "100%"
    },
    image: {
        margin: 'auto',
        display: 'block',
        margin: 10
    },
    commentTextField: {
        width: '100%',
        maxWidth: '100%'
    },
    commentGridItem: {
        maxWidth: "80%",
        marginTop: 30,
        marginBottom: 100
    }
});

const HomePage = () => {
    const [count, setCount] = useState(0);
    const [showObj, setShowObj] = React.useState({
        show1: false,
        show2: false,
        show3: false,
        show4: false,
        show5: false,
        show6: false,
    });
    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        localStorage.setItem('readingSession', null);
        localStorage.setItem('listeningSession', null);
        localStorage.setItem('writingSession', null);
        localStorage.setItem('testSession', null);
        localStorage.setItem('bmatSessionObj', null);
    }, []);

    const handleShow = (isVisible, no) => {
        showObj[no] = isVisible;
        setShowObj(showObj);
    }

    const breatheAnimation = keyframes`
    0% { transform: scale(1); }
    50% { transform: scale(1.05); }
    100% { transform: scale(1); }
`;

    const StyledCardMedia = styled(CardMedia)`
    animation: ${breatheAnimation} 4s ease-in-out infinite;
`;

    const CloudCardMedia = () => {
        return (
            <StyledCardMedia
                component="img"
                alt="Contemplative Reptile"
                src="/Permanentbackground(transparent).png"
            />
        );
    };

    const DoFullScreen = () => {
        document.getElementById('root').requestFullscreen().then(() => {
            console.log("full screen")
        }, () => { console.alert("no fullscreen") });
    }

    return (
        <Container maxWidth={false} disableGutters style={{ backgroundColor: "#efefef", overflow: "hidden" }}>
            <Grid container>
                {/* <a href="#" onClick={DoFullScreen}>Test Full Screen Mode</a> */}
                <Grid item xs={12} style={{
                    backgroundColor: "#efefef"
                }}>
                    <VisibilitySensor onChange={(isVisible) => {
                        handleShow(isVisible, "show1");
                    }}>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={6}>
                                    <Box
                                        position="absolute"
                                        top={`${scrollPosition + (window.innerWidth <= 768 ? 30 : 0)}px`}
                                        left='-20%'
                                        transition="top 0.3s ease-in-out"
                                        style={{ pointerEvents: "none", zIndex: 0 }}
                                    >
                                        <Slide left>
                                            <StyledCardMedia
                                                component="img"
                                                alt="Contemplative Reptile"
                                                src="/Permanentbackground(transparent).png"
                                            />
                                        </Slide>
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box
                                        position="absolute"
                                        top={`${scrollPosition + 200 + (window.innerWidth <= 768 ? 20 : 0)}px`}
                                        left='20%'
                                        transition="top 0.3s ease-in-out"
                                        style={{ pointerEvents: "none", transform: "scaleX(-1)", zIndex: 0 }}
                                    >
                                        <Slide right>
                                            <StyledCardMedia
                                                component="img"
                                                alt="Contemplative Reptile"
                                                src="/Permanentbackground(transparent).png"
                                            />
                                        </Slide>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </VisibilitySensor>
                </Grid>
                <Grid item xs={12} style={{
                    marginTop: '5%',
                    backgroundColor: "#efefef",
                    height: "600px"
                }}>
                    <AdvertisementSection />
                </Grid>
                <Grid item xs={12} style={{
                    marginTop: '5%',
                    marginBottom: '5%',
                    backgroundColor: "#efefef"
                }}>
                    <AboutUsSection />
                </Grid>
                <Grid item xs={12} style={{
                    marginTop: '5%',
                    marginBottom: '5%',
                    backgroundColor: "#efefef"
                }}>
                    <OurTestsSection />
                </Grid>
                <Grid item xs={12} style={{
                    marginTop: '5%',
                    marginBottom: '5%',
                    backgroundColor: "#efefef"
                }}>
                    <ReviewsSection />
                </Grid>
                <Grid item xs={12} style={{
                    marginTop: '5%',
                    marginBottom: '5%',
                    backgroundColor: "#efefef"
                }}>
                    <ContentSection />
                </Grid>
                <Grid item xs={12} style={{
                    marginTop: '5%',
                    marginBottom: '5%',
                    backgroundColor: "#efefef"
                }}>
                    {/* <VisibilitySensor onChange={(isVisible) => {
                        handleShow(isVisible, "show6");
                    }}>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={6}>
                                    <Slide left>
                                        <Pulse spy={count}>
                                            <CardMedia
                                                component="img"
                                                alt="Contemplative Reptile"
                                                src="/Permanentbackground(transparent).png"
                                                title="Contemplative Reptile"
                                                style={{ transform: "scale(2)" }}
                                            />
                                        </Pulse>
                                    </Slide>
                                </Grid>
                                <Grid item xs={6}>
                                    <Slide right>
                                        <Pulse spy={count}>
                                            <CardMedia
                                                component="img"
                                                alt="Contemplative Reptile"
                                                src="/Permanentbackground(transparent).png"
                                                title="Contemplative Reptile"
                                                style={{ transform: "scale(2)" }}
                                            />
                                        </Pulse>
                                    </Slide>
                                </Grid>
                            </Grid>
                        </Grid>
                    </VisibilitySensor> */}
                    <ContactUsSection />
                </Grid>
            </Grid>
        </Container>
    )
};

export default compose(
    withStyles(useStyles)
)(HomePage);
