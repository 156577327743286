import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Container from '@material-ui/core/Container';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';
import React, { Component } from 'react';
import { compose } from 'recompose';

//import other library
import 'react-calendar/dist/Calendar.css';

//react-router-dom
import {
    Link
} from "react-router-dom";

//import components
import SelectTestType from '../testRegistration/SelectTestType';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = theme => ({
    root: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    }
});


class FreeCourse extends Component {
    constructor() {
        super();
        this.state = {
            selectedTest: '',
            isNewDesign: true,
            avalibleCoursesArr: [
                {
                    name: 'IELTS',
                    data: [
                        {
                            name: "Listening",
                            type: "IELTS listening test",
                            description: "IELTS free test.",
                            amount: 0,
                            duration: 20,
                            teacher: 'Jirayu Promsongwong',
                            available: true
                        },
                        {
                            name: "Reading",
                            type: "IELTS reading test",
                            description: "IELTS free test.",
                            amount: 0,
                            duration: 20,
                            teacher: 'Jirayu Promsongwong',
                            available: true
                        },
                        {
                            name: "Writing",
                            type: "IELTS writing test",
                            description: "IELTS free test.",
                            amount: 0,
                            duration: 20,
                            teacher: 'Jirayu Promsongwong',
                            available: true
                        }
                    ]
                },
                {
                    name: 'BMAT',
                    data: [
                        {
                            name: "BMAT",
                            type: "BMAT test",
                            description: "BMAT free test.",
                            amount: 0,
                            duration: 20,
                            teacher: 'Jirayu Promsongwong',
                            available: true
                        }
                    ]
                }
            ],
            checkout: false,
            charge: undefined,
            isConfirm: false,
            isPurchase: false,
            isSelectPlan: false,
            isChooseFreeTest: false,
            isVerified: true,
            window: {
                height: window.innerHeight,
                width: window.innerWidth
            }
        }
    }

    componentDidMount() {
        this.props.handleChangeMenu('trynow');
        document.querySelector("body").scrollTo(0, 0);
    }

    componentWillUnmount = () => {
        window.removeEventListener("resize", this.handleResize);
    }

    handleResize = (event) => {
        this.setState({
            window: {
                height: window.innerHeight,
                width: window.innerWidth
            }
        });
    };

    handleOpenDialog = () => {
        this.setState({
            isConfirm: true
        });
    }

    handleCloseDialog = () => {
        this.setState({
            isConfirm: false
        });
    }

    handleCloseSnackBar = () => {
        this.setState({
            isVerified: false
        });
    }

    handleSelecteCourse = (obj) => {
        if (JSON.parse(localStorage.getItem('user')) === null) {
            this.setState({
                isVerified: false
            });

            return;
        }

        else if (JSON.parse(localStorage.getItem('user')) !== null) {
            this.setState({
                selectedCourse: obj,
                isVerified: true,
                isConfirm: true
            });
        }
    }

    render() {
        const { classes } = this.props;

        return (
            <Container>
                <Snackbar open={!this.state.isVerified} autoHideDuration={6000} onClose={this.handleCloseSnackBar}>
                    <Alert onClose={this.handleCloseSnackBar} severity="error">
                        Signin required for this process. Please signin/signup before trying free test.
                    </Alert>
                </Snackbar>
                <Dialog
                    open={this.state.isConfirm}
                    onClose={this.handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Try this test?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Please click confirm if you want to try {this.state.selectedCourse !== undefined ? this.state.selectedCourse.type : ''}.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseDialog} style={{ color: 'grey' }}>
                            Cancel
                        </Button>
                        <Link
                            //to={"/test/user/verification?redirectPath=/test/" + this.state.selectedTest.toLowerCase()}
                            to={"/test/" + (this.state.selectedCourse !== undefined ? this.state.selectedCourse.name.toLowerCase() : '')}
                            color="inherit"
                            underline="none"
                            style={{ textDecoration: 'none', color: '#565656' }}
                        >
                            <Button color="primary" autoFocus style={{ color: "#76323F", fontWeight: 'bold' }}>
                                Confirm
                            </Button>
                        </Link>
                    </DialogActions>
                </Dialog>
                <Grid container justify='center' style={{ marginTop: '100px', marginBottom: '200px', padding: '20px', maxWidth: this.state.window.width, flexDirection: 'row' }}>
                    {this.state.isSelectPlan &&
                        <SelectTestType
                            handlePlanChosen={this.handlePlanChosen}
                            handleBacktoAllTests={this.handleBacktoAllTests}
                            handleOpenConfirmPayment={this.handleOpenConfirmPayment}
                        />
                    }
                    {!this.state.isSelectPlan && !this.state.isPurchase &&
                        <Grid item xs={8}>
                            <Typography variant="h2" gutterBottom>
                                <Box fontWeight="fontWeightBold" display='inline' style={{ color: '#76323F' }}>Try Now!</Box>
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom>
                                You can take your test at one of our official test centres on paper or on a computer.
                                All you need is a valid identification document and a bank card if you want to pay online.
                            </Typography>
                            <Typography variant="h5" gutterBottom style={{ marginTop: '30px' }}>
                                <Box fontWeight="fontWeightBold" display='inline' style={{ color: '#76323F' }}>Start a test today</Box>
                            </Typography>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        {this.state.avalibleCoursesArr.map((course, index) =>
                            <Grid container>
                                <Grid item align='left' xs={12} >
                                    <Typography variant="h5" style={{ color: '#76323F' }} gutterBottom>
                                        <Box fontWeight="fontWeightBold" display='inline' style={{ color: '#76323F' }}>{course.name}</Box>
                                    </Typography>
                                </Grid>
                                {course.data.map((type, index) =>
                                    <Grid item xs={2} style={{ margin: '10px' }}>
                                        <Card>
                                            <CardMedia
                                                component="img"
                                                height="140"
                                                image="https://images.unsplash.com/photo-1515378791036-0648a3ef77b2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
                                                alt="green iguana"
                                            />
                                            <CardContent style={{ height: 120, maxHeight: 120 }}>
                                                <Typography style={{ fontSize: '1rem' }} color="text.secondary" gutterBottom>
                                                    Type
                                                </Typography>
                                                <Typography variant="h5" component="div" style={{ fontSize: '1.5rem' }}>
                                                    <Box fontWeight="fontWeightBold" display='inline' style={{ color: '#76323F' }}>{type.name}</Box>
                                                </Typography>
                                                <Typography style={{ fontSize: '0.7rem' }} color="text.secondary">
                                                    {type.type}
                                                </Typography>
                                                <Typography variant="caption" display="block" color="text.secondary">
                                                    {type.duration} mins
                                                </Typography>
                                            </CardContent>
                                            <CardActions>
                                                <Grid container>
                                                    <Grid item align='center' xs={12} sm={8}>
                                                        {type.available && type.amount === 0 &&
                                                            type.name === 'BMAT' ? (
                                                            <Link
                                                                to={"/test/bmat"}
                                                                color="inherit"
                                                                underline="none"
                                                                style={{ textDecoration: 'none', color: '#565656' }}
                                                            >
                                                                <Button
                                                                    size="small"
                                                                    disabled={type.name === 'Writing'}
                                                                    variant="contained"
                                                                    style={{
                                                                        backgroundColor: type.name !== 'Writing' ? '#76323F' : '',
                                                                        color: type.name !== 'Writing' ? 'white' : '',
                                                                        padding: '5px',
                                                                        width: '100%'
                                                                    }}
                                                                >
                                                                    {type.name !== 'Writing' ? 'Try now' : 'Coming soon'}
                                                                </Button>
                                                            </Link>
                                                        ) : (
                                                            <Button
                                                                size="small"
                                                                disabled={type.name === 'Writing'}
                                                                variant="contained"
                                                                style={{
                                                                    backgroundColor: type.name !== 'Writing' ? '#76323F' : '',
                                                                    color: type.name !== 'Writing' ? 'white' : '',
                                                                    padding: '5px',
                                                                    width: '100%'
                                                                }}
                                                                onClick={() => { this.handleSelecteCourse(type) }}
                                                            >
                                                                {type.name !== 'Writing' ? 'Try now' : 'Coming soon'}
                                                            </Button>
                                                        )
                                                        }
                                                        {type.available && type.amount !== 0 &&
                                                            <Link
                                                                to={"/test/payment?course_name=" + type.name + '&description=' + type.description + '&amount=' + type.amount}
                                                                color="inherit"
                                                                underline="none"
                                                                style={{ textDecoration: 'none', color: '#565656' }}
                                                            >
                                                                <Button
                                                                    size="small"
                                                                    style={{
                                                                        backgroundColor: '#76323F',
                                                                        color: 'white',
                                                                        padding: '5px',
                                                                        width: '100%'
                                                                    }}
                                                                >
                                                                    Buy Now
                                                                </Button>
                                                            </Link>
                                                        }
                                                        {!type.available &&
                                                            <Button
                                                                size="small"
                                                                variant="contained"
                                                                onClick={this.handleSelectPlan}
                                                                style={{
                                                                    padding: '5px',
                                                                    width: '100%'
                                                                }}
                                                                disabled
                                                            >
                                                                Coming Soon
                                                            </Button>
                                                        }
                                                    </Grid>
                                                    <Grid item align='center' xs={4}>
                                                        <Typography variant="body1" component="div" style={{ color: '#76323F', padding: '5px' }}>
                                                            {type.amount === 0 ? 'FREE' : type.amount / 100 + '฿'}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </CardActions>
                                        </Card>
                                    </Grid>
                                )}
                                {this.state.avalibleCoursesArr.length - 1 !== index &&
                                    <Grid item align='center' xs={12} style={{ marginTop: '50px', marginBottom: '50px' }}>
                                        <Divider style={{ height: '5px', backgroundColor: '#76323F' }} />
                                    </Grid>
                                }
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Container >
        )
    }
}
export default compose(
    withStyles(useStyles)
)(FreeCourse);