import * as apiConstants from '../constants/apiConstants';

const axios = require('axios').default;

export async function GetPurchasedTests(token, username) {
    try {
        const res = await axios({
            method: 'get',
            url: `${apiConstants.E_LEARNING_API_BASE_URL}/api/test/test-purchase-status?user_name=${username}`,
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });

        return {
            success: true,
            res: res.data
        }

    } catch (err) {
        console.log(err);
        return {
            success: false,
            res: err
        }
    }
}

export async function GetMeetingById(meetingId) {
    try {
        const res = await axios({
            method: 'get',
            url: `${apiConstants.SPEAKING_API_BASE_URL}/meetings/${meetingId}`
        });

        return {
            success: true,
            res: res.data
        }

    } catch (err) {
        console.log(err);
        return {
            success: false,
            res: err
        }
    }
}

export async function GetSpeakingQuestion() {
    try {
        const res = await axios({
            method: 'get',
            url: `${apiConstants.SPEAKING_API_BASE_URL}/speaking/questions/random/1`
        });

        return {
            success: true,
            res: res.data
        }

    } catch (err) {
        console.log(err);
        return {
            success: false,
            res: err
        }
    }
}

export async function GetAllSpeakingLog() {
    try {
        const res = await axios({
            method: 'get',
            url: `${apiConstants.SPEAKING_API_BASE_URL}/speaking/log/`
        });

        return {
            success: true,
            res: res.data
        }

    } catch (err) {
        console.log(err);
        return {
            success: false,
            res: err
        }
    }
}

export async function GetSpeakingLogById(id) {
    try {
        const res = await axios({
            method: 'get',
            url: `${apiConstants.SPEAKING_API_BASE_URL}/speaking/log/${id}`
        });

        return {
            success: true,
            res: res.data
        }

    } catch (err) {
        console.log(err);
        return {
            success: false,
            res: err
        }
    }
}

export async function GetSpeakingLogByTeacher(username) {
    try {
        const res = await axios({
            method: 'get',
            url: `${apiConstants.SPEAKING_API_BASE_URL}/speaking/log/teacher/${username}`
        });

        return {
            success: true,
            res: res.data
        }

    } catch (err) {
        console.log(err);
        return {
            success: false,
            res: err
        }
    }
}

export async function GetSpeakingInformation(id, password) {
    try {
        const res = await axios({
            method: 'get',
            url: `${apiConstants.SPEAKING_API_BASE_URL}/meetings/${id}/${password}`
        });

        return {
            success: true,
            res: res.data
        }

    } catch (err) {
        console.log(err);
        return {
            success: false,
            res: err
        }
    }
}

export async function CreateSpeakingLog(object) {
    try {
        const res = await axios({
            method: 'post',
            url: `${apiConstants.SPEAKING_API_BASE_URL}/speaking/log/create`,
            data: object
        });

        return {
            success: true,
            res: res.data
        }

    } catch (err) {
        console.log(err);
        return {
            success: false,
            res: err
        }
    }
}

export async function GetTestsByTeacher(username) {
    try {
        const res = await axios({
            method: 'get',
            url: `${apiConstants.TEST_MANAGEMENT_API_BASE_URL}/tests?teacherUsername=${username}`
        });

        return {
            success: true,
            res: res.data
        }

    } catch (err) {
        console.log(err);
        return {
            success: false,
            res: err
        }
    }
}

export async function GetSpeakingTestsByTeacher(username) {
    try {
        const res = await axios({
            method: 'get',
            url: `${apiConstants.SPEAKING_API_BASE_URL}/meetings?assignTo=${username}`
        });

        return {
            success: true,
            res: res.data
        }

    } catch (err) {
        console.log(err);
        return {
            success: false,
            res: err
        }
    }
}

export async function GetAllTestSurveyLog() {
    try {
        const res = await axios({
            method: 'get',
            url: `${apiConstants.SPEAKING_API_BASE_URL}/test-survey`,
        });

        return {
            success: true,
            res: res.data
        }

    } catch (err) {
        console.log(err);
        return {
            success: false,
            res: err
        }
    }
}

export async function CreateTestSurveyLog(object) {
    try {
        const res = await axios({
            method: 'post',
            url: `${apiConstants.SPEAKING_API_BASE_URL}/test-survey`,
            data: object
        });

        return {
            success: true,
            res: res.data
        }

    } catch (err) {
        console.log(err);
        return {
            success: false,
            res: err
        }
    }
}

export async function GetBMATWriting() {
    try {
        const res = await axios({
            method: 'get',
            url: `${apiConstants.UTEST_AVENUE_PRO_API}/test/bmat/writing`,
            headers: {
                "Authorization": `Bearer ${JSON.parse(localStorage.getItem('user')).token}`
            }
        });

        return {
            success: true,
            res: res.data
        }

    } catch (err) {
        console.log(err);
        return {
            success: false,
            res: err
        }
    }
}


