import React, { useEffect } from 'react';

//MUI
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CakeRoundedIcon from '@material-ui/icons/CakeRounded';
import EmailRoundedIcon from '@material-ui/icons/EmailRounded';
import LocalPhoneRoundedIcon from '@material-ui/icons/LocalPhoneRounded';
import SchoolRoundedIcon from '@material-ui/icons/SchoolRounded';
import Skeleton from '@material-ui/lab/Skeleton';

import { ThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';

//Icons
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import NavigateBeforeRoundedIcon from '@material-ui/icons/NavigateBeforeRounded';
import NavigateNextRoundedIcon from '@material-ui/icons/NavigateNextRounded';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';

//Font
import 'typeface-glacial-indifference';

//CSS
import './css/profilePage.css';

//Services
import { GetTestReport } from '../../../services/testReportServices';
import { GetAdminToken } from '../../../services/token';
import { GetAllTestHistory, GetAllUsers } from '../../../services/userServices';

//Others
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";

const axios = require('axios').default;
const FormData = require('form-data');
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

const useStyles = theme => ({
    root: {
        padding: theme.spacing(12, 4),
    }
});

const theme = createMuiTheme({
    typography: {
        fontFamily: [
            'Roboto'
        ].join(','),
    },
});

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1500 },
        items: 5
    },
    tablet: {
        breakpoint: { max: 1500, min: 464 },
        items: 3
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1
    }
};

const UsersPage = () => {
    //User
    const [userObj, setUserObj] = React.useState({});
    const [allUserArr, setAllUserArr] = React.useState([]);
    const [testHistoryArr, setTestHistoryArr] = React.useState([]);
    const [testReportArr, setTestReportArr] = React.useState([]);
    const [pageCountArr, setPageCountArr] = React.useState([]);
    const [tempUsersArr, setTempUsersArr] = React.useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);
    const [initial, setInitial] = React.useState("");
    const [searchText, setSearchText] = React.useState("");
    const [currentPage, setCurrentPage] = React.useState(0);

    const [isLoading, setIsLoading] = React.useState(false);

    useEffect(async () => {
        document.body.style.overflow = "auto";
        document.body.style.backgroundColor = "#efefef";
        document.querySelector("body").scrollTo(0, 0);

        const user = await JSON.parse(localStorage.getItem('user'));
        setUserObj(user);
        setInitial(`${user.firstname[0]}${user.lastname[0]}`);

        await getTestHistory(user.username);
        await getTestReports(user.token);
        await getAllUsers();
    }, []);

    const getTestHistory = async (username) => {
        setIsLoading(true);

        const testHistory = await GetAllTestHistory(username);

        if (testHistory.success) {
            setTestHistoryArr(testHistory.res);
        }

        setIsLoading(false);
    }

    const getTestReports = async (token) => {
        setIsLoading(true);

        const testReports = await GetTestReport(token);

        if (testReports.success) {
            setTestReportArr(testReports.res);
        }

        setIsLoading(false);
    }

    const getAllUsers = async () => {
        const tokenObj = await GetAdminToken();
        const res = await GetAllUsers(tokenObj.res.token.token);

        setAllUserArr(res.res);
        const pageArr = await preparePageCount(Math.floor(res.res.length / 10), res.res);
        setPageCountArr(pageArr);
    }

    const preparePageCount = async (pageCount, userArr) => {
        let tempPageCount = [];
        for (let count = 0; count <= pageCount; count++) {
            let start = (count) * 10;
            let end = (count * 10) + 9

            await tempPageCount.push({
                page: count + 1,
                arr: userArr.slice(start, end)
            });
        }

        return tempPageCount
    }

    const handleNextPage = async () => {
        if (currentPage < (pageCountArr.length - 1)) {
            setCurrentPage(currentPage + 1);
        }
    }

    const handlePreviousPage = async () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    }

    const handleChangeSearchText = (event) => {
        setSearchText(event.target.value);
    }

    const handleClearSearchText = () => {
        setSearchText("");
    }

    const mobileUI = (<Grid container style={{ marginTop: "5%", marginBottom: "20%", maxWidth: window.innerWidth }}>
        <Grid item align="left" xs={12} style={{ padding: "5%" }}>
            <Typography variant="h3" style={{ fontWeight: "bold", color: "#880000" }}>
                Profile
            </Typography>
        </Grid>
        <Grid item align="center" xs={12} style={{ padding: "5%" }}>
            <Paper elevation={0} style={{ backgroundColor: "#a6a6a6", borderRadius: "25px", padding: "10%" }}>
                <Grid container>
                    <Grid item xs={12}>
                        <Avatar style={{
                            width: "200px",
                            height: "200px",
                            fontSize: "80px",
                            fontWeight: "bold",
                            backgroundColor: "#880000"
                        }}
                        >
                            {initial}
                        </Avatar>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item align="left" xs={12} style={{ marginTop: "10px" }}>
                                <Typography variant="h4" style={{ color: "white", fontWeight: "bold" }}>
                                    {`${userObj?.firstname} ${userObj?.lastname}`}
                                </Typography>
                            </Grid>
                            <Grid item align="left" xs={12}>
                                <Typography variant="subtitle1" style={{ color: "white" }}>
                                    {userObj?.role}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
        <Grid item xs={12} style={{ padding: "5%" }}>
            <Paper elevation={0} style={{ backgroundColor: "#a6a6a6", borderRadius: "25px", padding: "10%" }}>
                <Grid container>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item align="left">
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'wrap',
                                }}>
                                    <EmailRoundedIcon style={{ color: "white", marginRight: '5px' }} />
                                    <Typography variant="subtitle1" style={{ color: "white" }}>
                                        {userObj?.email}
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item align="left" xs={12}>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'wrap',
                                }}>
                                    <LocalPhoneRoundedIcon style={{ color: "white", marginRight: '5px' }} />
                                    <Typography variant="subtitle1" style={{ color: "white" }}>
                                        {userObj?.phone?.length === 0 ? "-" : userObj.phone}
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item align="left" xs={12}>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'wrap',
                                }}>
                                    <SchoolRoundedIcon style={{ color: "white", marginRight: '5px' }} />
                                    <Typography variant="subtitle1" style={{ color: "white" }}>
                                        {userObj?.school?.length === 0 ? "-" : userObj.school}
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item align="left" xs={12}>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'wrap',
                                }}>
                                    <CakeRoundedIcon style={{ color: "white", marginRight: '5px' }} />
                                    <Typography variant="subtitle1" style={{ color: "white" }}>
                                        {userObj?.birthdate}
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
        <Grid item align="left" xs={12} style={{ padding: "5%" }}>
            <Typography variant="h4" style={{ fontWeight: "bold", color: "#880000" }}>
                History
            </Typography>
        </Grid>
        {testHistoryArr && testHistoryArr?.length > 0 && <Grid align="center" xs={12}>
            <Carousel
                responsive={responsive}
                swipeable={true}
                showDots={true}
                autoPlay={false}
                autoPlaySpeed={100000}
            >
                {testHistoryArr.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()).map((history, index) =>
                    <Grid item xs={12} style={{ padding: "5%" }}>
                        <Card style={{ backgroundColor: "#a6a6a6", borderRadius: "25px", padding: "5%" }}>
                            <CardContent>
                                <Typography variant="h6" style={{ color: "white" }} gutterBottom>
                                    Test
                                </Typography>
                                <Typography variant="h2" style={{ color: "white", fontWeight: "bold" }}>
                                    {history.test_type}
                                </Typography>
                                <Typography variant="body1" style={{ color: "white" }} gutterBottom>
                                    {history.test_part}
                                </Typography>
                                <Typography variant="subtitle1" style={{ color: "white" }}>
                                    {new Date(history.createdAt).toDateString()}
                                </Typography>
                                <Typography variant="subtitle1" style={{ color: "white" }}>
                                    {new Date(history.createdAt).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Grid item xs={12}>
                                    {history.isDone ? <Button
                                        disabled
                                        variant="contained"
                                        size="large"
                                        style={{ backgroundColor: "#636363", color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1.5rem" }}
                                        disableElevation
                                        fullWidth
                                    >
                                        Done
                                    </Button> : <Button
                                        disabled
                                        variant="contained"
                                        size="large"
                                        style={{ backgroundColor: "#636363", color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1.5rem" }}
                                        disableElevation
                                        fullWidth
                                    >
                                        Unavailable in mobile
                                    </Button>
                                    }
                                </Grid>
                            </CardActions>
                        </Card>
                    </Grid>
                )}
            </Carousel>
        </Grid>
        }

        {isLoading && <Grid align="left" xs={12} style={{ padding: "5%" }}>
            <Grid item align="center" xs={12}>
                <Card style={{ backgroundColor: "#a6a6a6", borderRadius: "25px", padding: "10%" }}>
                    <CardContent>
                        <Typography variant="h6" style={{ color: "white" }} gutterBottom>
                            <Skeleton />
                        </Typography>
                        <Typography variant="h2" style={{ color: "white", fontWeight: "bold" }}>
                            <Skeleton />
                        </Typography>
                        <Typography variant="subtitle1" style={{ color: "white" }} gutterBottom>
                            <Skeleton />
                        </Typography>
                        <Typography variant="subtitle1" style={{ color: "white" }}>
                            <Skeleton />
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Grid item xs={12}>
                            <Button
                                disabled
                                variant="contained"
                                size="large"
                                style={{ backgroundColor: "#636363", color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1rem" }}
                                disableElevation
                                fullWidth
                            >
                                Unavailable in mobile
                            </Button>
                        </Grid>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
        }

        {testHistoryArr?.length === 0 && !isLoading && <Grid align="left" xs={12} style={{ padding: "5%" }}>
            <Grid item align="center" xs={12}>
                <Card style={{ backgroundColor: "#a6a6a6", borderRadius: "25px", padding: "10%" }}>
                    <CardContent>
                        <Typography variant="h6" style={{ color: "white" }} gutterBottom>
                            Test
                        </Typography>
                        <Typography variant="h2" style={{ color: "white", fontWeight: "bold" }}>
                            Empty
                        </Typography>
                        <Typography variant="subtitle1" style={{ color: "white" }} gutterBottom>
                            -
                        </Typography>
                        <Typography variant="subtitle1" style={{ color: "white" }}>
                            -
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Grid item xs={12}>
                            <Button
                                disabled
                                variant="contained"
                                size="large"
                                style={{ backgroundColor: "#636363", color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1rem" }}
                                disableElevation
                                fullWidth
                            >
                                Unavailable in mobile
                            </Button>
                        </Grid>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
        }

        <Grid item align="left" xs={12} style={{ padding: "5%" }}>
            <Typography variant="h4" style={{ fontWeight: "bold", color: "#880000" }}>
                My Report
            </Typography>
        </Grid>
        <Grid align="center" xs={12}>
            <Carousel
                responsive={responsive}
                swipeable={true}
                showDots={true}
                autoPlay={false}
                partialVisible={false}
                autoPlaySpeed={100000}
            >
                {testReportArr.sort((a, b) => new Date(b.test_result.test_started ? b.test_result.test_started : b.date_created).getTime() - new Date(a.test_result.test_started ? a.test_result.test_started : a.date_created).getTime()).map((report, index) =>
                    <Grid item xs={12} style={{ padding: "5%" }}>
                        <Card style={{ backgroundColor: "#a6a6a6", borderRadius: "25px", padding: "5%" }}>
                            <CardContent>
                                <Typography variant="h6" style={{ color: "white" }} gutterBottom>
                                    Mini Report
                                </Typography>
                                <Typography variant="h2" style={{ color: "white", fontWeight: "bold" }}>
                                    IELTS
                                </Typography>
                                <Typography variant="body1" style={{ color: "white" }} gutterBottom>
                                    READING
                                </Typography>
                                <Typography variant="subtitle1" style={{ color: "white" }}>
                                    {report.test_result.test_started ? new Date(report.test_result.test_started).toDateString() : new Date(report.date_created).toDateString()}
                                </Typography>
                                <Typography variant="subtitle1" style={{ color: "white" }}>
                                    {report.test_result.test_started ? new Date(report.test_result.test_started).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : new Date(report.date_created).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Grid item xs={12}>
                                    <Link
                                        to={`/report?result_id=${report.result_id}`}
                                        style={{ textDecoration: 'none', color: '#565656' }}
                                    >
                                        <Button
                                            variant="contained"
                                            size="large"
                                            style={{ backgroundColor: "#880000", color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1.5rem" }}
                                            disableElevation
                                            fullWidth
                                        >
                                            View
                                        </Button>
                                    </Link>
                                </Grid>
                            </CardActions>
                        </Card>
                    </Grid>
                )}
            </Carousel>
        </Grid>

        {isLoading && <Grid align="left" xs={12} style={{ padding: "5%" }}>
            <Grid item align="center" xs={12}>
                <Card style={{ backgroundColor: "#a6a6a6", borderRadius: "25px", padding: "10%" }}>
                    <CardContent>
                        <Typography variant="h6" style={{ color: "white" }} gutterBottom>
                            <Skeleton />
                        </Typography>
                        <Typography variant="h2" style={{ color: "white", fontWeight: "bold" }}>
                            <Skeleton />
                        </Typography>
                        <Typography variant="subtitle1" style={{ color: "white" }} gutterBottom>
                            <Skeleton />
                        </Typography>
                        <Typography variant="subtitle1" style={{ color: "white" }}>
                            <Skeleton />
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Grid item xs={12}>
                            <Button
                                disabled
                                variant="contained"
                                size="large"
                                style={{ backgroundColor: "#636363", color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1rem" }}
                                disableElevation
                                fullWidth
                            >
                                Unavailable in mobile
                            </Button>
                        </Grid>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
        }

        {testReportArr?.length === 0 && !isLoading && <Grid align="left" xs={12} style={{ padding: "5%" }}>
            <Grid item align="center" xs={12}>
                <Card style={{ backgroundColor: "#a6a6a6", borderRadius: "25px", padding: "10%" }}>
                    <CardContent>
                        <Typography variant="h6" style={{ color: "white" }} gutterBottom>
                            Mini Report
                        </Typography>
                        <Typography variant="h2" style={{ color: "white", fontWeight: "bold" }}>
                            Empty
                        </Typography>
                        <Typography variant="subtitle1" style={{ color: "white" }} gutterBottom>
                            -
                        </Typography>
                        <Typography variant="subtitle1" style={{ color: "white" }}>
                            -
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Grid item xs={12}>
                            <Button
                                disabled
                                variant="contained"
                                size="large"
                                style={{ backgroundColor: "#636363", color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1rem" }}
                                disableElevation
                                fullWidth
                            >
                                Unavailable in mobile
                            </Button>
                        </Grid>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
        }
    </Grid>)

    // ============================================================================================================================================= 

    const webUI = (<Grid container spacing={5} style={{ marginTop: "5%", marginBottom: "20%", maxWidth: window.innerWidth }}>
        <Grid item align="left" xs={12} style={{ paddingLeft: "10%" }}>
            <Typography variant="h3" style={{ fontWeight: "bold", color: "#880000" }}>
                Profile
            </Typography>
        </Grid>
        <Grid item xs={12} sm={4} style={{ paddingLeft: "10%" }}>
            <Paper elevation={0} style={{ backgroundColor: "#a6a6a6", borderRadius: "25px", padding: "50px" }}>
                <Grid container>
                    <Grid item xs={12}>
                        <Avatar style={{
                            width: "200px",
                            height: "200px",
                            fontSize: "80px",
                            fontWeight: "bold",
                            backgroundColor: "#880000"
                        }}
                        >
                            {initial}
                        </Avatar>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item align="left" xs={12} style={{ marginTop: "10px" }}>
                                <Typography variant="h4" style={{ color: "white", fontWeight: "bold" }}>
                                    {`${userObj?.firstname} ${userObj?.lastname}`}
                                </Typography>
                            </Grid>
                            <Grid item align="left" xs={12}>
                                <Typography variant="subtitle1" style={{ color: "white" }}>
                                    {userObj?.role}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
        <Grid item xs={12} sm={8} style={{ paddingRight: "10%" }}>
            <Paper elevation={0} style={{ backgroundColor: "#f4f3f3", borderRadius: "25px", padding: "10%" }}>
                <Grid container>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <TextField
                                    size="small"
                                    id="search-bar"
                                    type="input"
                                    variant="outlined"
                                    onChange={handleChangeSearchText}
                                    value={searchText}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchRoundedIcon />
                                            </InputAdornment>
                                        ), endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton aria-label="clear" onClick={handleClearSearchText}>
                                                    <ClearRoundedIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ), background: "white"
                                    }}
                                    style={{ width: "80%" }}
                                />
                            </Grid>
                            <Grid item xs={12} align="center">
                                <Grid container>
                                    <Grid item xs={3}>
                                        <Typography align="left" variant="h6" style={{ color: "#595959", fontWeight: "bold" }}>
                                            Name
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography align="left" variant="h6" style={{ color: "#595959", fontWeight: "bold" }}>
                                            Email
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography variant="h6" style={{ color: "#595959", fontWeight: "bold" }}>
                                            School
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography variant="h6" style={{ color: "#595959", fontWeight: "bold" }}>
                                            Gender
                                        </Typography>
                                    </Grid>
                                    <Grid item xs>
                                        <Typography variant="h6" style={{ color: "#595959", fontWeight: "bold" }}>
                                            Edit
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            {(searchText.length == 0 && pageCountArr[currentPage] !== undefined) && pageCountArr[currentPage].arr.map((user) =>
                                <Grid item xs={12} align="left" style={{ marginTop: "10px" }}>
                                    <Grid container>
                                        <Grid item xs={3}>
                                            <Typography variant="subtitle2" style={{ color: "#595959" }}>
                                                {`${user.firstname} ${user.lastname}`}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant="subtitle2" style={{ color: "#595959" }}>
                                                {user.email}
                                            </Typography>
                                        </Grid>
                                        <Grid item align="center" xs={2}>
                                            <Typography variant="subtitle2" style={{ color: "#595959" }}>
                                                {user.school ? user.school : "-"}
                                            </Typography>
                                        </Grid>
                                        <Grid item align="center" xs={2}>
                                            <Typography variant="subtitle2" style={{ color: "#595959" }}>
                                                {user.gender}
                                            </Typography>
                                        </Grid>
                                        <Grid align="center" item xs>
                                            <IconButton aria-label="edit" size="small">
                                                <EditRoundedIcon fontSize="inherit" />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}

                            {(searchText.length > 0) && allUserArr.filter(item => (item.username.includes(searchText) ||
                                `${item.firstname.toLowerCase()} ${item.lastname.toLowerCase()}`.includes(searchText.toLowerCase()) ||
                                item.school.toLowerCase().includes(searchText.toLowerCase())
                            )).map((user) =>
                                <Grid item xs={12} align="left" style={{ marginTop: "10px" }}>
                                    <Grid container>
                                        <Grid item xs={3}>
                                            <Typography variant="subtitle2" style={{ color: "#595959" }}>
                                                {`${user.firstname} ${user.lastname}`}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant="subtitle2" style={{ color: "#595959" }}>
                                                {user.email}
                                            </Typography>
                                        </Grid>
                                        <Grid item align="center" xs={2}>
                                            <Typography variant="subtitle2" style={{ color: "#595959" }}>
                                                {user.school ? user.school : "-"}
                                            </Typography>
                                        </Grid>
                                        <Grid item align="center" xs={2}>
                                            <Typography variant="subtitle2" style={{ color: "#595959" }}>
                                                {user.gender}
                                            </Typography>
                                        </Grid>
                                        <Grid align="center" item xs>
                                            <IconButton aria-label="edit" size="small">
                                                <EditRoundedIcon fontSize="inherit" />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}

                            {isLoading && tempUsersArr.map((num, index) => <Grid item xs={12} align="left" style={{ marginTop: "10px" }}>
                                <Grid container>
                                    <Grid item xs={3}>
                                        <Typography variant="subtitle2" style={{ color: "#595959" }}>
                                            <Skeleton />
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="subtitle2" style={{ color: "#595959" }}>
                                            <Skeleton />
                                        </Typography>
                                    </Grid>
                                    <Grid item align="center" xs={2}>
                                        <Typography variant="subtitle2" style={{ color: "#595959" }}>
                                            <Skeleton />
                                        </Typography>
                                    </Grid>
                                    <Grid item align="center" xs={2}>
                                        <Typography variant="subtitle2" style={{ color: "#595959" }}>
                                            <Skeleton />
                                        </Typography>
                                    </Grid>
                                    <Grid align="center" item xs>
                                        <IconButton aria-label="edit" size="small">
                                            <Skeleton />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                            )}

                            {(searchText === null || searchText === "" || searchText === undefined) &&
                                <Grid item xs={12} align="center" style={{ marginTop: "50px" }}>
                                    <Grid container spacing={1} justify="center">
                                        <Grid item>
                                            <IconButton disabled={(currentPage === 0)} aria-label="edit" size="large" onClick={handlePreviousPage}>
                                                <NavigateBeforeRoundedIcon fontSize="inherit" />
                                            </IconButton>
                                        </Grid>
                                        {pageCountArr.map((page, index) =>
                                            <Grid item style={{ marginTop: "15px" }}>
                                                <Link component="button" underline="none" onClick={() => { setCurrentPage(index) }}>
                                                    <Typography variant="subtitle2" style={{
                                                        color: "#595959",
                                                        fontWeight: currentPage === index ? "bold" : "",
                                                        color: currentPage === index ? "#880000" : "",
                                                        textDecoration: currentPage === index ? "underline" : "",
                                                    }}
                                                    >
                                                        {page.page}
                                                    </Typography>
                                                </Link>
                                            </Grid>
                                        )}
                                        <Grid item>
                                            <IconButton disabled={(currentPage === pageCountArr.length - 1)} aria-label="edit" size="large" onClick={handleNextPage}>
                                                <NavigateNextRoundedIcon fontSize="inherit" />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    </Grid>)

    return <ThemeProvider theme={theme}>
        <Hidden mdUp>
            {mobileUI}
        </Hidden>
        <Hidden smDown>
            {webUI}
        </Hidden>
    </ThemeProvider>
};

export default compose(
    withStyles(useStyles)
)(UsersPage);