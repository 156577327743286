import React, { useEffect } from 'react';

import { ZoomMtg } from '@zoomus/websdk';
import './ZoomSDK.css';

ZoomMtg.setZoomJSLib('https://source.zoom.us/2.9.7/lib', '/av');

ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();
// loads language files, also passes any error messages to the ui
ZoomMtg.i18n.load('en-US');
ZoomMtg.i18n.reload('en-US');

const crypto = require('crypto'); // crypto comes with Node.js
const KJUR = require('jsrsasign')

/*function generateSignature(apiKey, apiSecret, meetingNumber, role) {
    return new Promise((res, rej) => {
        // Prevent time sync issue between client signature generation and zoom 
        const timestamp = new Date().getTime() - 30000
        const msg = Buffer
            .from(apiKey + meetingNumber + timestamp + role)
            .toString('base64')
        const hash = crypto
            .createHmac('sha256', apiSecret)
            .update(msg)
            .digest('base64')
        const signature = Buffer
            .from(`${apiKey}.${meetingNumber}.${timestamp}.${role}.${hash}`).toString('base64')

        res(signature);
    })
}*/

function generateSignature(sdkKey, sdkSecret, meetingNumber, role) {
    return new Promise((res, rej) => {
        const iat = Math.round(new Date().getTime() / 1000) - 30;
        const exp = iat + 60 * 60 * 2
        const oHeader = { alg: 'HS256', typ: 'JWT' }

        const oPayload = {
            sdkKey: sdkKey,
            mn: meetingNumber,
            role: role,
            iat: iat,
            exp: exp,
            appKey: sdkKey,
            tokenExp: iat + 60 * 60 * 2
        }

        const sHeader = JSON.stringify(oHeader)
        const sPayload = JSON.stringify(oPayload)
        const sdkJWT = KJUR.jws.JWS.sign('HS256', sHeader, sPayload, sdkSecret)
        res(sdkJWT)
    })
}

var signature = '';

function ZoomSDK(props) {
    // setup your signature endpoint here: https://github.com/zoom/meetingsdk-sample-signature-node.js
    var signatureEndpoint = 'https://steep-legend-midnight.glitch.me/zoom/signature'
    // This Sample App has been updated to use SDK App type credentials https://marketplace.zoom.us/docs/guides/build/sdk-app
    var sdkKey = '_1Sp5dqpS6wBJxoipJHFQ'
    var sdkSecret = 'b4falcpOM3a5lwdETX1saN7q5mywvCHX'
    var apiKey = 'AlVbIy8ERyilV1Snq_BMrA';
    var apiSecret = 'UsmljQDQQRDhSY4YWgKZjfLWa0Z0WfRGTdct';
    var meetingNumber = props.meetingNumber
    var role = props.role
    var leaveUrl = 'http://localhost:3000'
    var userName = props.userName
    var userEmail = ''
    var passWord = props.password
    // pass in the registrant's token if your meeting or webinar requires registration. More info here:
    // Meetings: https://marketplace.zoom.us/docs/sdk/native-sdks/web/client-view/meetings#join-registered
    // Webinars: https://marketplace.zoom.us/docs/sdk/native-sdks/web/client-view/webinars#join-registered
    var registrantToken = ''

    useEffect(async () => {
        //await getSignature();
        await generateSignature(sdkKey, sdkSecret, meetingNumber, role).then((res) => {
            signature = res;
        });

        console.log(props.meetingObj);

        startMeeting(signature);
    }, []);

    function getSignature() {
        //e.preventDefault();

        fetch(signatureEndpoint, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                meetingNumber: meetingNumber,
                role: role
            })
        }).then(res => res.json())
            .then(response => {
                startMeeting(response.signature)
            }).catch(error => {
                console.error(error)
            })
    }

    function startMeeting(signature) {
        document.getElementById('zmmtg-root').style.display = 'block'

        ZoomMtg.init({
            leaveUrl: role === '1' ? `https://utestavenue.com/test/speaking/scoring?testid=${props.meetingObj.purchaseId ? props.meetingObj.purchaseId : ""}&tester=${props.userName}&id=${props.meetingNumber}&password=${props.password}` : 'https://utestavenue.com/profile',
            isSupportAV: true,
            disableCORP: !window.crossOriginIsolated,
            success: (success) => {
                console.log(success)

                ZoomMtg.join({
                    sdkKey: sdkKey,
                    signature: signature,
                    meetingNumber: meetingNumber,
                    userName: userName,
                    userEmail: userEmail,
                    passWord: passWord,
                    //tk: registrantToken,
                    success: (success) => {
                        console.log('success');
                        console.log(success)
                    },
                    error: (error) => {
                        console.log('error');
                        console.log(error)
                    }
                })

            },
            error: (error) => {
                console.log(error)
            }
        })
    }

    return (
        <div className="App" style={{ paddingTop: '300px', paddingBottom: '300px' }}>
            <main>
                <h1>Preparing session...</h1>
            </main>
        </div>
    );
}

export default ZoomSDK;