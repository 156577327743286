//Material UI
import { Button, Grid, Paper, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';
import { compose } from 'recompose';

//React
import { Component } from 'react';

//react-router-dom
import {
    Link
} from "react-router-dom";

//Scheduler
import '@fullcalendar/react/dist/vdom';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react'; // must go before plugins
import timeGridPlugin from '@fullcalendar/timegrid';

//others
import moment from 'moment';
import { CreateNewNotification } from '../../services/notificationServices';
import PageBackdrop from '../main/PageBackdrop';

const axios = require('axios').default;
const crypto = require('crypto');
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

const useStyles = theme => ({
    root: {
        flexGrow: 1,
    },
});

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class TestSchedulerPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentDate: '2018-11-01',
            schedulerData: [
                { startDate: '2021-07-26T11:00', endDate: '2021-07-26T11:30', title: 'Click here' },
                { startDate: '2021-07-26T12:00', endDate: '2021-07-26T12:30', title: 'Click here' },
                { startDate: '2021-07-26T13:00', endDate: '2021-07-26T13:30', title: 'Click here' },
            ],
            selectedAppointment: null,
            zoomMeetingInfo: null,
            events: [],
            window: {
                height: window.innerHeight,
                width: window.innerWidth
            },
            isLoading: false,
            isConfirm: false,
        };
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    handleResize = (event) => {
        this.setState({
            window: {
                height: window.innerHeight,
                width: window.innerWidth
            }
        });
    };

    componentDidMount = async () => {
        document.querySelector("body").scrollTo(0, 0);
        await this.handleGetPurchasedCourse();
        await this.handleCheckCourseId();

        if (this.state.isChecked) {
            let draggableEl = document.getElementById("external-events");
            new Draggable(draggableEl, {
                itemSelector: ".fc-event",
                eventData: function (eventEl) {
                    let title = eventEl.getAttribute("title");
                    let id = eventEl.getAttribute("data");
                    let duration = eventEl.getAttribute("duration");
                    return {
                        title: title,
                        id: id,
                        duration: duration,
                        constraint: 'availableForMeeting'
                    };
                }
            });
            this.handleGetAllAvailableSchedule();
        }
    }

    handleGetPurchasedCourse = async () => {
        try {
            const purchasedCourse = await axios({
                method: 'get',
                url: api_base_url + "/api/test/test-purchase-status?user_name=" + JSON.parse(localStorage.getItem('user')).username,
                headers: {
                    Authorization: "bearer " + JSON.parse(localStorage.getItem('user')).token
                }
            })

            this.setState({
                purchasedCourse: purchasedCourse.data
            });

        } catch (error) {
            console.error(error);
            this.setState({
                purchasedCourse: []
            });
        }
    }

    handleCheckCourseId = async () => {
        let courseId = '';
        try {
            if (this.props.purchasedId === undefined) {
                const query = new URLSearchParams(this.props.location.search);
                courseId = query.get('id');
            } if (this.props.purchasedId !== undefined) {
                courseId = this.props.purchasedId;
            }

            //const courseId = query.get('id');
            const availableCourse = await this.state.purchasedCourse.filter(courses => courses._id === courseId);
            this.setState({
                isChecked: Boolean(availableCourse.length),
                courseId: courseId
            })
        } catch {
            this.setState({
                isChecked: false
            })
        }
    }

    handleJoinMeeting = async (meetingUrl) => {
        window.open(meetingUrl);
    }

    //=================================== BEGIN SCHEDULE ZOOM MEETING =================================
    handleConfirmDate = async () => {
        this.handleCloseConfirm();
        this.handleScheduleZoomMeeting();
    }

    handleScheduleZoomMeeting = async () => {
        try {
            console.log({
                submitter: this.props.user.username,
                firstname: this.props.user.name,
                start_time: this.state.selectedAppointment.start.replace('Z', ''),
                _id: this.state.courseId
            });
            this.handleOpenLoading();
            const { history } = this.props;
            const res = await axios({
                method: 'post',
                url: 'https://steep-legend-midnight.glitch.me/meetings/schedule',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: {
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    submitter: this.props.user.username,
                    firstname: this.props.user.name,
                    start_time: this.state.selectedAppointment.start.replace('Z', ''),
                    _id: this.state.courseId
                }
            });

            const resData = res.data;
            this.setState({
                zoomMeetingInfo: resData
            });

            if (this.props.purchasedId === undefined) {
                history.push('/profile');
            } if (this.props.purchasedId !== undefined) {
                this.props.handleEndScheduleSpeaking();
            }

        } catch (err) {
            const { history } = this.props;
            console.log(err);
            this.handleCloseLoading();
        }
    }
    //=================================== END SCHEDULE ZOOM MEETING =================================

    handleSkipSchedule = () => {
        CreateNewNotification(JSON.parse(localStorage.getItem('user')).username, "test_schedule", {
            purchasedId: this.props.purchasedId,
            message: "Look like you forgot to schedule a speaking test. Please visit profile page to check it out!"
        })
    }

    handleGetAllAvailableSchedule = async () => {
        try {
            this.handleOpenLoading();
            const resAvailableSlot = await axios({
                method: 'get',
                url: 'https://steep-legend-midnight.glitch.me/admin/schedule'
            });
            let scheduleArr = [];
            console.log("GET: resAvailableSlotData");
            const resAvailableSlotData = await resAvailableSlot.data.filter((schedule) => moment(moment(schedule.schedule.start).toString()).isBetween(moment().toString(), moment().add(3, 'weeks').toString()));

            await resAvailableSlotData.map(schedule => {
                scheduleArr.push({
                    id: schedule._id,
                    start: schedule.schedule.start,
                    end: schedule.schedule.end,
                    display: 'background',
                    groupId: 'availableForMeeting',
                    color: "#008214"
                }, {
                    start: schedule.schedule.start.split("T")[0],
                    end: schedule.schedule.end.split("T")[0],
                    display: 'background',
                    
                })
            })

            const resReservedSchedule = await axios({
                method: 'get',
                url: 'https://steep-legend-midnight.glitch.me/meetings',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            console.log("GET: resReservedScheduleData");
            console.log(resReservedSchedule.data);

            const resReservedScheduleData = await resReservedSchedule.data.data.filter((schedule) => moment(moment(schedule.schedule.start_time).toString()).isSameOrAfter(moment().toString()));
            await resReservedScheduleData.map(meeting => {
                moment.locale('th')
                const startTime = moment(new Date(meeting.schedule.start_time)).format()
                const endTime = moment(new Date(meeting.schedule.start_time)).add(30, 'm').format()
                scheduleArr.push({
                    id: meeting._id,
                    start: startTime,
                    end: endTime,
                    title: 'Scheduled',
                    overlap: false,
                    editable: false,
                    color: "#a6a6a6"
                })
            })

            console.log(scheduleArr);

            this.setState({
                events: scheduleArr
            });
            this.handleCloseLoading();

        } catch (err) {
            console.log(err);
            this.handleCloseLoading();
        }
    }

    handleChange = (event) => {
        const name = event.target.name;
        this.setState({
            ...this.state,
            [name]: event.target.value,
        });
    };

    handleCloseConfirm = () => {
        this.setState({
            isConfirm: false
        });
    }

    handleOpenConfirm = () => {
        this.setState({
            isConfirm: true
        });
    }

    handleCloseLoading = () => {
        this.setState({
            isLoading: false
        });
    }

    handleOpenLoading = () => {
        this.setState({
            isLoading: true
        });
    }

    handleDateClick = (arg) => { // bind with an arrow function
        alert(arg.dateStr)
    }

    render() {
        const { classes } = this.props;

        return (
            <Grid container style={{ width: '100%' }}>

                <PageBackdrop isLoading={this.state.isLoading} />

                <Dialog
                    open={this.state.isConfirm}
                    onClose={this.handleCloseConfirm}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Schedule a test?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Please check appointment date and time before click on confirm button to schedule a test.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseConfirm} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.handleConfirmDate} color="primary" autoFocus>
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>

                {this.state.isChecked === undefined &&
                    <Grid item xs={12}>
                        <CircularProgress style={{ marginTop: '25%', marginBottom: '25%' }} />
                    </Grid>
                }

                {this.state.isChecked !== undefined && !this.state.isChecked &&
                    <Grid item xs={12} style={{ marginTop: '80px', marginBottom: '80%' }}>
                        <Alert severity="error">Invalid course. Please make sure that you pick an availabke course.</Alert>
                    </Grid>
                }

                {this.state.isChecked !== undefined && this.state.isChecked &&
                    <Grid item align='center' xs={12} style={{ marginBottom: '120px', marginTop: '50px' }}>
                        <Grid container justify='center' spacing={0}>
                            <Grid item xs={12}>
                                <Typography variant="h2" gutterBottom>
                                    <Box fontWeight="fontWeightBold" display='inline' style={{ color: '#76323F' }}>Book a Speaking Session</Box>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={8} style={{ padding: '50px' }}>
                                <Paper style={{ padding: '10px' }}>
                                    <FullCalendar
                                        headerToolbar={{
                                            left: 'prev,next today',
                                            center: 'title',
                                            right: 'dayGridMonth,timeGridWeek,timeGridDay'
                                        }}
                                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                        defaultView="timeGridDay"
                                        timeZone="Asia/Bangkok"
                                        //hiddenDays={[0, 7]}
                                        header={{
                                            left: "prev,next today",
                                            center: "title"
                                        }}
                                        rerenderDelay={10}
                                        eventDurationEditable={false}
                                        //businessHours={true}
                                        editable={true}
                                        droppable={true}
                                        navLinks={true}
                                        events={this.state.events}

                                        eventDrop={(event) => {
                                            this.setState({
                                                selectedAppointment: {
                                                    id: event.event._instance.defId,
                                                    start: event.event._instance.range.start.toJSON(),
                                                    end: event.event._instance.range.end.toJSON(),
                                                }
                                            })
                                        }}
                                        eventReceive={(event) => {
                                            const temp = this.state.events.filter((schedule) => schedule.id === event.event._instance.defId);
                                            console.log(event.event._instance.range.start.toJSON());

                                            this.setState({
                                                selectedAppointment: {
                                                    id: event.event._instance.defId,
                                                    start: event.event._instance.range.start.toJSON(),
                                                    end: event.event._instance.range.end.toJSON()
                                                }
                                            })
                                        }}
                                    />
                                </Paper>
                            </Grid>
                            <Grid item align='center' xs={12} sm={4} style={{ padding: '50px' }}>
                                <Paper style={{ padding: '50px', borderRadius: '15px' }}>
                                    <Typography variant="h5">
                                        <Box fontWeight="fontWeightBold" display='inline'>Your Appointment</Box>
                                    </Typography>
                                    <Typography variant="h5" style={{ marginTop: '20px' }}>
                                        <Box fontWeight="fontWeightBold" display='inline'>Date</Box>
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        {this.state.selectedAppointment === null ? 'Please select appointment' : new Date(this.state.selectedAppointment.start).toDateString()}
                                    </Typography>
                                    <Typography variant="h5" style={{ marginTop: '20px' }}>
                                        <Box fontWeight="fontWeightBold" display='inline'>Time</Box>
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        {this.state.selectedAppointment === null ? 'Please select appointment' :
                                            new Date(this.state.selectedAppointment.start).getUTCHours() + '.' + (new Date(this.state.selectedAppointment.start).getUTCMinutes() === 0 ? '00' : new Date(this.state.selectedAppointment.start).getUTCMinutes()) + ' - ' +
                                            new Date(this.state.selectedAppointment.end).getUTCHours() + '.' + (new Date(this.state.selectedAppointment.end).getUTCMinutes() === 0 ? '00' : new Date(this.state.selectedAppointment.end).getUTCMinutes())
                                        }
                                    </Typography>
                                </Paper>
                                {this.state.selectedAppointment === null &&
                                    <div
                                        id="external-events"
                                        style={{
                                            padding: "10px",
                                            width: "100%",
                                            height: "auto",
                                            maxHeight: "-webkit-fill-available",
                                            marginTop: '50px'
                                        }}
                                    >
                                        <Typography align='center' variant="h5" gutterBottom>
                                            <strong>Drag and drop available schedule</strong>
                                        </Typography>
                                        <Grid justify='center' container>
                                            <Grid xs={12} sm={6}>
                                                <Button
                                                    className="fc-event"
                                                    title={"Schedule"}
                                                    data={"1"}
                                                    key={"1"}
                                                    duration={'00:20'}
                                                    style={{ backgroundColor: "#76323F", color: 'white', padding: '20px' }}
                                                >
                                                    <Box fontWeight="fontWeightBold" display='inline'>Your schedule</Box>
                                                </Button>

                                            </Grid>
                                        </Grid>
                                    </div>
                                }
                            </Grid>
                            <Grid item xs={12} sm={7}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    style={{ height: '40px', width: '100%', marginTop: '20px', backgroundColor: this.state.selectedAppointment === null ? '' : '#76323F' }}
                                    onClick={this.handleOpenConfirm}
                                    disabled={this.state.selectedAppointment === null}
                                >
                                    <Box fontWeight="fontWeightBold" display='inline'>{this.state.selectedAppointment === null ? "Please select your appointment" : "Confirm Date"}</Box>
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={7}>
                                <Link to="/profile" color="inherit" underline="none" style={{ textDecoration: 'none', color: '#565656' }}>
                                    <Button
                                        onClick={this.handleSkipSchedule}
                                        style={{ height: '40px', width: '100%' }}
                                        fullWidth
                                    >
                                        <Box fontWeight="fontWeightBold" display='inline'>Skip, I'll schedule date later</Box>
                                    </Button>
                                </Link>
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </Grid>
        )
    }
}
export default compose(
    withStyles(useStyles)
)(TestSchedulerPage);