//React
import { Component } from 'react';
import { compose } from 'recompose';

//Material UI
import Avatar from '@material-ui/core/Avatar';
import Backdrop from '@material-ui/core/Backdrop';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

//Icon
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';
import ArrowDropUpRoundedIcon from '@material-ui/icons/ArrowDropUpRounded';

//Others libraries
import Chart from "react-google-charts";

//react-router-dom
import {
    Link
} from "react-router-dom";

const axios = require('axios').default;
const FormData = require('form-data');
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%'
    }
});

class MyReportPage extends Component {
    constructor() {
        super();
        this.state = {
            isSeeMore: false,
            isLoading: false,
            window: {
                height: window.innerHeight,
                width: window.innerWidth
            }
        };
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    handleResize = (event) => {
        this.setState({
            window: {
                height: window.innerHeight,
                width: window.innerWidth
            }
        });
    };

    componentDidMount = async () => {
        document.querySelector("body").scrollTo(0,0);
        const user = JSON.parse(localStorage.getItem('user'));
        const query = new URLSearchParams(this.props.location.search);

        if (user !== null) {
            await this.setState({
                userToken: user.token,
                test_id: query.get('test_id')
            })
        }
        this.getUserTestReport();
    }

    getUserTestReport = async () => {
        this.handleOpenBackdrop();
        const query = new URLSearchParams(this.props.location.search);
        try {
            const res = await axios({
                method: 'get',
                url: api_base_url + '/api/test/result',
                headers: {
                    "Authorization": "Bearer " + this.props.user.token
                }
            });

            console.log(res)

            const resData = res.data.filter(history => history.test_id === this.state.test_id);
            console.log(resData[0]);
            this.setState({
                report: resData[0].test_result,
                date: resData[0].date_created
            });
            this.handleCloseBackdrop();

        } catch (err) {
            console.log(err);
            this.handleCloseBackdrop();
        }
    }

    handleConvertQuestionType = (question) => {
        if(question === "completeTheNotes") {
            return "Complete the notes";
        }

        else if (question === "matching") {
            return "Matching"
        }

        else if (question === "choose2Letters") {
            return "Choose 2 letters"
        }

        else if (question === "multipleChoice") {
            return "Multiple choice"
        }

        else if (question === "multipleChoice") {
            return "Multiple choice"
        }

        else if (question === "completeTheTables") {
            return "Complete the tables"
        }

        else if (question === "map") {
            return "Map"
        }
    }

    handleSeeMore = (category) => {
        this.setState({
            isSeeMore: true,
            isSeeMoreType: category
        });
    }

    handleSeeLess = () => {
        this.setState({
            isSeeMore: false,
            isSeeMoreType: ''
        });
    }

    handleCloseBackdrop = () => {
        this.setState({
            isLoading: false
        });
    }

    handleOpenBackdrop = () => {
        this.setState({
            isLoading: true
        });
    }

    render() {
        const { classes } = this.props;
        const search = window.location.search;

        return (
            <Container>
                <Backdrop open={this.state.isLoading} onClick={this.handleCloseBackdrop} style={{ color: '#76323F', zIndex: 1 }}>
                    <CircularProgress color="inherit" />
                </Backdrop>

                <Grid container spacing={5} style={{ marginTop: '100px', marginBottom: '100px', maxWidth: this.state.window.width }}>
                    <Grid item align='left' xs={12}>
                        <Typography variant="h4" gutterBottom>
                            <Box fontWeight="fontWeightBold" display='inline'>My Report</Box>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Paper style={{ padding: '20px' }}>
                            <Grid container>
                                <Grid item align='center' xs={12}>
                                    <Avatar
                                        alt={this.props.user.name}
                                        src={this.props.user.picture}
                                        style={{
                                            width: '150px',
                                            height: '150px'
                                        }}
                                    />
                                </Grid>
                                <Grid item align='center' xs={12} style={{ marginTop: '10px' }}>
                                    <Typography variant="h6" gutterBottom>
                                        <Box fontWeight="fontWeightBold" display='inline'>{this.props.user.name}</Box>
                                    </Typography>
                                </Grid>
                                <Grid item align='center' xs={12}>
                                    <Divider style={{ marginBottom: '10px' }} />
                                </Grid>
                                <Grid item align='left' xs={12}>
                                    <Typography variant="body2" gutterBottom>
                                        <Box fontWeight="fontWeightBold" display='inline'>Age: </Box> {this.state.report === undefined ? '' : this.state.report.profile.age}
                                    </Typography>
                                </Grid>
                                <Grid item align='left' xs={12}>
                                    <Typography variant="body2" gutterBottom>
                                        <Box fontWeight="fontWeightBold" display='inline'>Expected score: </Box> {this.state.report === undefined ? '' : this.state.report.profile.expected_score}
                                    </Typography>
                                </Grid>
                                <Grid item align='left' xs={12}>
                                    <Typography variant="body2" gutterBottom>
                                        <Box fontWeight="fontWeightBold" display='inline'>Test date: </Box> {this.state.date === undefined ? '' : new Date(this.state.date).toDateString()}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                        <Grid item xs={12}>
                            <Grid container spacing={5}>
                                <Grid item xs>
                                    <Paper style={{ padding: '20px' }}>
                                        <Typography variant="subtitle2" gutterBottom>
                                            Type
                                        </Typography>
                                        <Divider style={{ marginBottom: '10px' }} />
                                        <Typography variant="h4">
                                            <Box fontWeight="fontWeightBold" display='inline'>{this.state.report === undefined ? '' : this.state.report.test_type}</Box>
                                        </Typography>
                                    </Paper>
                                </Grid>
                                <Grid item xs>
                                    <Paper style={{ padding: '20px' }}>
                                        <Typography variant="subtitle2" gutterBottom>
                                            Expected Score
                                        </Typography>
                                        <Divider style={{ marginBottom: '10px' }} />
                                        <Typography variant="h4" >
                                            <Box fontWeight="fontWeightBold" display='inline'>{this.state.report === undefined ? '' : this.state.report.profile.expected_score}</Box>
                                        </Typography>
                                    </Paper>
                                </Grid>
                                <Grid item xs>
                                    <Paper style={{ padding: '20px' }}>
                                        <Typography variant="subtitle2" gutterBottom>
                                            Overall Score
                                        </Typography>
                                        <Divider style={{ marginBottom: '10px' }} />
                                        <Typography variant="h4">
                                            <Box
                                                fontWeight="fontWeightBold"
                                                display='inline'
                                            >
                                                {this.state.report === undefined ? '' : this.state.report.score.overall_score}
                                            </Box> {this.state.report !== undefined && this.state.report.score.overall_score > this.state.report.profile.expected_score ?
                                                <ArrowDropUpRoundedIcon style={{ color: '#00A300' }} /> : <ArrowDropDownRoundedIcon style={{ color: '#D10000' }} />
                                            }
                                        </Typography>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: '20px' }}>
                            <Paper style={{ padding: '20px' }}>
                                <Grid item align='left' xs={12}>
                                    <Typography variant="subtitle2" gutterBottom>
                                        <Box fontWeight="fontWeightBold" display='inline'>Overall Band Score Description</Box>
                                    </Typography>
                                    <Divider style={{ marginBottom: '10px' }} />
                                    <Typography variant="subtitle2" gutterBottom>
                                        {this.state.report === undefined ? '' : this.state.report.score.description}
                                    </Typography>
                                </Grid>
                            </Paper>
                        </Grid>

                        <Grid item xs={12} style={{ marginTop: '20px' }}>
                            <Grid container spacing={2}>
                                <Grid item align='center' xs={12} sm={6}>
                                    <Paper style={{ padding: '10px' }}>
                                        <Grid item align='center' xs={12}>
                                            <Chart
                                                width={'400px'}
                                                height={'400px'}
                                                chartType="ColumnChart"
                                                loader={<div>Loading Chart</div>}
                                                data={[
                                                    ['Category', 'Avg Users', 'Avg Test Takers', 'You'],
                                                    ['Listening',
                                                        this.state.report === undefined ? '' : this.state.report.score.listening.avg_user_score,
                                                        this.state.report === undefined ? '' : this.state.report.score.listening.avg_tester_score,
                                                        this.state.report === undefined ? '' : this.state.report.score.listening.score],
                                                    ['Reading',
                                                        this.state.report === undefined ? '' : this.state.report.score.reading.avg_user_score,
                                                        this.state.report === undefined ? '' : this.state.report.score.reading.avg_tester_score,
                                                        this.state.report === undefined ? '' : this.state.report.score.reading.score],
                                                    ['Writing',
                                                        this.state.report === undefined ? '' : this.state.report.score.writing.avg_user_score,
                                                        this.state.report === undefined ? '' : this.state.report.score.writing.avg_tester_score,
                                                        this.state.report === undefined ? '' : this.state.report.score.writing.avg_tester_score],
                                                    ['Speaking',
                                                        this.state.report === undefined ? '' : this.state.report.score.speaking.avg_user_score,
                                                        this.state.report === undefined ? '' : this.state.report.score.speaking.avg_tester_score,
                                                        this.state.report === undefined ? '' : this.state.report.score.speaking.score]
                                                ]}
                                                options={{
                                                    // Material design options
                                                    chartArea: { width: '50%' },
                                                    colors: ['#808080', '#C5C5C5', '#76323F'],
                                                    title: 'Overall Band Score Comparison',
                                                    height: 400
                                                }}
                                                // For tests
                                                rootProps={{ 'data-testid': '2' }}
                                            />
                                        </Grid>
                                    </Paper>
                                </Grid>
                                <Grid item align='center' xs={12} sm={6}>
                                    <Paper style={{ padding: '10px' }}>
                                        <Grid item align='center' xs={12}>
                                            <Chart
                                                width={'400px'}
                                                height={'400px'}
                                                chartType="PieChart"
                                                loader={<div>Loading Chart</div>}
                                                data={[
                                                    ['Category', 'Band Score'],
                                                    ['Listening', this.state.report === undefined ? '' : this.state.report.score.listening.score],
                                                    ['Reading', this.state.report === undefined ? '' : this.state.report.score.reading.score],
                                                    ['Writing', this.state.report === undefined ? '' : this.state.report.score.writing.avg_tester_score],
                                                    ['Speaking', this.state.report === undefined ? '' : this.state.report.score.speaking.score]
                                                ]}
                                                options={{
                                                    pieSliceTextStyle: {
                                                        fontSize: 30
                                                    },
                                                    pieSliceText: 'value',
                                                    title: 'Skill Analysis',
                                                    legend: { position: 'bottom', textStyle: { fontSize: 9 } }
                                                }}
                                                rootProps={{ 'data-testid': '1' }}
                                            />
                                        </Grid>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/*================================================= BAND SCORE ===========================================================*/}
                        <Grid item xs={12} style={{ marginTop: '20px' }}>
                            <Paper style={{ padding: '20px' }}>
                                <Grid container>
                                    <Grid item align='left' xs={12}>
                                        <Typography variant="subtitle2" gutterBottom>
                                            <Box fontWeight="fontWeightBold" display='inline'>Band Score</Box>
                                        </Typography>
                                        <Divider style={{ marginBottom: '10px' }} />
                                    </Grid>
                                    <Grid item xs style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        borderRadius: 5,
                                        backgroundColor: this.state.isSeeMoreType === 'LISTENING' ? '#76323F' : 'white',
                                        color: this.state.isSeeMoreType === 'LISTENING' ? 'white' : 'black'
                                    }}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Typography variant="subtitle2" gutterBottom>
                                                    Listening
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="h4" gutterBottom>
                                                    <Box fontWeight="fontWeightBold" display='inline'>{this.state.report === undefined ? '' : this.state.report.score.listening.score}</Box>
                                                </Typography>
                                            </Grid>
                                            {this.state.isSeeMoreType !== 'LISTENING' &&
                                                <Grid item xs={12}>
                                                    <Button
                                                        size="small"
                                                        variant="contained"
                                                        onClick={() => { this.handleSeeMore('LISTENING') }}
                                                        style={{ width: '100%', backgroundColor: '#76323F', color: 'white' }}
                                                    >
                                                        <Box fontWeight="fontWeightBold" display='inline'>{this.state.isSeeMoreType === 'LISTENING' ? "See less" : "See more"}</Box>
                                                    </Button>
                                                </Grid>
                                            }

                                        </Grid>
                                    </Grid>
                                    <Divider variant="middle" orientation="vertical" flexItem />
                                    <Grid item xs style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        borderRadius: 5,
                                        backgroundColor: this.state.isSeeMoreType === 'READING' ? '#76323F' : 'white',
                                        color: this.state.isSeeMoreType === 'READING' ? 'white' : 'black'
                                    }}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Typography variant="subtitle2" gutterBottom>
                                                    Reading
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="h4" gutterBottom>
                                                    <Box fontWeight="fontWeightBold" display='inline'>{this.state.report === undefined ? '' : this.state.report.score.reading.score}</Box>
                                                </Typography>
                                            </Grid>
                                            {this.state.isSeeMoreType !== 'READING' &&
                                                <Grid item xs={12}>
                                                    <Button
                                                        size="small"
                                                        variant="contained"
                                                        onClick={() => { this.handleSeeMore('READING') }}
                                                        style={{ width: '100%', backgroundColor: '#76323F', color: 'white' }}
                                                    >
                                                        <Box fontWeight="fontWeightBold" display='inline'>{this.state.isSeeMoreType === 'READING' ? "See less" : "See more"}</Box>
                                                    </Button>
                                                </Grid>
                                            }

                                        </Grid>
                                    </Grid>
                                    <Divider variant="middle" orientation="vertical" flexItem />
                                    <Grid item xs style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        borderRadius: 5,
                                        backgroundColor: this.state.isSeeMoreType === 'WRITING' ? '#76323F' : 'white',
                                        color: this.state.isSeeMoreType === 'WRITING' ? 'white' : 'black'
                                    }}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Typography variant="subtitle2" gutterBottom>
                                                    Writing
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="h4" gutterBottom>
                                                    <Box fontWeight="fontWeightBold" display='inline'>{this.state.report === undefined ? '' : this.state.report.score.writing.score}</Box>
                                                </Typography>
                                            </Grid>
                                            {this.state.isSeeMoreType !== 'WRITING' &&
                                                <Grid item xs={12}>
                                                    <Button
                                                        size="small"
                                                        variant="contained"
                                                        onClick={() => { this.handleSeeMore('WRITING') }}
                                                        style={{ width: '100%', backgroundColor: '#76323F', color: 'white' }}
                                                    >
                                                        <Box fontWeight="fontWeightBold" display='inline'>{this.state.isSeeMoreType === 'WRITING' ? "See less" : "See more"}</Box>
                                                    </Button>
                                                </Grid>
                                            }

                                        </Grid>
                                    </Grid>
                                    <Divider variant="middle" orientation="vertical" flexItem />
                                    <Grid item xs style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        borderRadius: 5,
                                        backgroundColor: this.state.isSeeMoreType === 'SPEAKING' ? '#76323F' : 'white',
                                        color: this.state.isSeeMoreType === 'SPEAKING' ? 'white' : 'black'
                                    }}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Typography variant="subtitle2" gutterBottom>
                                                    Speaking
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="h4" gutterBottom>
                                                    <Box fontWeight="fontWeightBold" display='inline'>{this.state.report === undefined ? '' : this.state.report.score.speaking.score}</Box>
                                                </Typography>
                                            </Grid>
                                            {this.state.isSeeMoreType !== 'SPEAKING' &&
                                                <Grid item xs={12}>
                                                    <Button
                                                        size="small"
                                                        variant="contained"
                                                        onClick={() => { this.handleSeeMore('SPEAKING') }}
                                                        style={{ width: '100%', backgroundColor: '#76323F', color: 'white' }}
                                                    >
                                                        <Box fontWeight="fontWeightBold" display='inline'>{this.state.isSeeMoreType === 'SPEAKING' ? "See less" : "See more"}</Box>
                                                    </Button>
                                                </Grid>
                                            }

                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        {/*================================================= BAND SCORE ===========================================================*/}
                        {/*======================================================= SEE MORE COMPONENTS ======================================*/}
                        {this.state.isSeeMore && this.state.isSeeMoreType === 'LISTENING' &&
                            <Grid item xs={12} style={{ marginTop: '20px' }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Paper style={{ padding: '20px' }}>
                                            <Grid container>
                                                <Grid item align='left' xs={12} sm={2}>
                                                    <Typography variant="subtitle2" gutterBottom>
                                                        <Box fontWeight="fontWeightBold" display='inline'>Band Score</Box>
                                                    </Typography>
                                                    <Divider style={{ marginBottom: '10px' }} />
                                                    <Typography variant="subtitle2" gutterBottom>
                                                        {this.state.report === undefined ? '' : this.state.report.score.listening.score}
                                                    </Typography>
                                                </Grid>
                                                <Grid item align='left' xs={12} sm={10}>
                                                    <Typography variant="subtitle2" gutterBottom>
                                                        <Box fontWeight="fontWeightBold" display='inline'>Band Score Description</Box>
                                                    </Typography>
                                                    <Divider style={{ marginBottom: '10px' }} />
                                                    <Typography variant="subtitle2" gutterBottom>
                                                        {this.state.report === undefined ? '' : this.state.report.score.listening.score_introduction}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                        {this.state.report !== undefined && this.state.isSeeMore && this.state.isSeeMoreType === 'LISTENING' && this.state.report.score.listening.section.map((section, index) =>
                            <Grid item xs={12} style={{ marginTop: '20px' }}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={3}>
                                        <Paper style={{ padding: '20px' }}>
                                            <Typography variant="subtitle2" gutterBottom>
                                                Section
                                            </Typography>
                                            <Divider style={{ marginBottom: '10px' }} />
                                            <Typography variant="h4">
                                                <Box fontWeight="fontWeightBold" display='inline'>{section.no}</Box>
                                            </Typography>
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={12} sm={9}>
                                        <Paper style={{ padding: '20px' }}>
                                            <Grid container>
                                                <Grid item align='left' xs={12} sm={2}>
                                                    <Typography variant="subtitle2" gutterBottom>
                                                        <Box fontWeight="fontWeightBold" display='inline'>Question type</Box>
                                                    </Typography>
                                                    <Divider style={{ marginBottom: '10px' }} />
                                                    {section.question.map((question) =>
                                                        <Typography variant="subtitle2" gutterBottom>
                                                            {this.handleConvertQuestionType(question)}
                                                        </Typography>
                                                    )}
                                                </Grid>
                                                <Grid item align='center' xs={12} sm={2}>
                                                    <Typography variant="subtitle2" gutterBottom>
                                                        <Box fontWeight="fontWeightBold" display='inline'>Your Score</Box>
                                                    </Typography>
                                                    <Divider style={{ marginBottom: '10px' }} />
                                                    <Typography variant="subtitle2" gutterBottom>
                                                        {section.raw_score}
                                                    </Typography>
                                                </Grid>
                                                <Grid item align='left' xs={12} sm={8}>
                                                    <Typography variant="subtitle2" gutterBottom>
                                                        <Box fontWeight="fontWeightBold" display='inline'>Score Description</Box>
                                                    </Typography>
                                                    <Divider style={{ marginBottom: '10px' }} />
                                                    <Typography variant="subtitle2" gutterBottom>
                                                        {section.description}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}

                        {this.state.isSeeMore && this.state.isSeeMoreType === 'READING' &&
                            <Grid item xs={12} style={{ marginTop: '20px' }}>
                                <Grid item xs={12} style={{ marginTop: '20px' }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Paper style={{ padding: '20px' }}>
                                                <Grid container>
                                                    <Grid item align='left' xs={12} sm={2}>
                                                        <Typography variant="subtitle2" gutterBottom>
                                                            <Box fontWeight="fontWeightBold" display='inline'>Band Score</Box>
                                                        </Typography>
                                                        <Divider style={{ marginBottom: '10px' }} />
                                                        <Typography variant="subtitle2" gutterBottom>
                                                            {this.state.report === undefined ? '' : this.state.report.score.reading.score}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item align='left' xs={12} sm={10}>
                                                        <Typography variant="subtitle2" gutterBottom>
                                                            <Box fontWeight="fontWeightBold" display='inline'>Band Score Description</Box>
                                                        </Typography>
                                                        <Divider style={{ marginBottom: '10px' }} />
                                                        <Typography variant="subtitle2" gutterBottom>
                                                            {this.state.report === undefined ? '' : this.state.report.score.reading.score_introduction}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                        {this.state.report !== undefined && this.state.isSeeMore && this.state.isSeeMoreType === 'READING' && this.state.report.score.reading.section.map((section, index) =>
                            <Grid item xs={12} style={{ marginTop: '20px' }}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={3}>
                                        <Paper style={{ padding: '20px' }}>
                                            <Typography variant="subtitle2" gutterBottom>
                                                Section
                                            </Typography>
                                            <Divider style={{ marginBottom: '10px' }} />
                                            <Typography variant="h4">
                                                <Box fontWeight="fontWeightBold" display='inline'>{Number(index) + 1}</Box>
                                            </Typography>
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={12} sm={9}>
                                        <Paper style={{ padding: '20px' }}>
                                            <Grid container>
                                                <Grid item align='left' xs={12} sm={2}>
                                                    <Typography variant="subtitle2" gutterBottom>
                                                        <Box fontWeight="fontWeightBold" display='inline'>Question type</Box>
                                                    </Typography>
                                                    <Divider style={{ marginBottom: '10px' }} />
                                                    {section.question.map((question) =>
                                                        <Typography variant="subtitle2" gutterBottom>
                                                            {question}
                                                        </Typography>
                                                    )}
                                                </Grid>
                                                <Grid item align='center' xs={12} sm={2}>
                                                    <Typography variant="subtitle2" gutterBottom>
                                                        <Box fontWeight="fontWeightBold" display='inline'>Your Score</Box>
                                                    </Typography>
                                                    <Divider style={{ marginBottom: '10px' }} />
                                                    <Typography variant="subtitle2" gutterBottom>
                                                        {section.raw_score}
                                                    </Typography>
                                                </Grid>
                                                <Grid item align='left' xs={12} sm={8}>
                                                    <Typography variant="subtitle2" gutterBottom>
                                                        <Box fontWeight="fontWeightBold" display='inline'>Score Description</Box>
                                                    </Typography>
                                                    <Divider style={{ marginBottom: '10px' }} />
                                                    <Typography variant="subtitle2" gutterBottom>
                                                        {section.description}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}

                        {this.state.isSeeMore && this.state.isSeeMoreType === 'WRITING' &&
                            <Grid item xs={12} style={{ marginTop: '20px' }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Paper style={{ padding: '20px' }}>
                                            <Grid container>
                                                <Grid item align='left' xs={12} sm={2}>
                                                    <Typography variant="subtitle2" gutterBottom>
                                                        <Box fontWeight="fontWeightBold" display='inline'>Band Score</Box>
                                                    </Typography>
                                                    <Divider style={{ marginBottom: '10px' }} />
                                                    <Typography variant="subtitle2" gutterBottom>
                                                        {this.state.report === undefined ? '' : this.state.report.score.writing.score}
                                                    </Typography>
                                                </Grid>
                                                <Grid item align='left' xs={12} sm={10}>
                                                    <Typography variant="subtitle2" gutterBottom>
                                                        <Box fontWeight="fontWeightBold" display='inline'>Band Score Description</Box>
                                                    </Typography>
                                                    <Divider style={{ marginBottom: '10px' }} />
                                                    <Typography variant="subtitle2" gutterBottom>
                                                        {this.state.report === undefined ? '' : this.state.report.score.writing.score_introduction}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                        {this.state.report !== undefined && this.state.isSeeMore && this.state.isSeeMoreType === 'WRITING' &&
                            <Grid item xs={12} style={{ marginTop: '20px' }}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={3}>
                                        <Paper style={{ padding: '20px' }}>
                                            <Typography variant="subtitle2" gutterBottom>
                                                Section
                                            </Typography>
                                            <Divider style={{ marginBottom: '10px' }} />
                                            <Typography variant="h4">
                                                <Box fontWeight="fontWeightBold" display='inline'>1</Box>
                                            </Typography>
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={12} sm={9}>
                                        <Paper style={{ padding: '20px' }}>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Grid container>
                                                        <Grid item align='left' xs={12} sm={2}>
                                                            <Typography variant="subtitle2" gutterBottom>
                                                                <Box fontWeight="fontWeightBold" display='inline'>Criteria</Box>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item align='left' xs={12} sm={10}>
                                                            <Typography variant="subtitle2" gutterBottom>
                                                                <Box fontWeight="fontWeightBold" display='inline'>Comments</Box>
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item align='left' xs={12}>
                                                    <Divider style={{ marginBottom: '10px' }} />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid container>
                                                        <Grid item align='left' xs={12} sm={2}>
                                                            <Typography variant="subtitle2" gutterBottom>
                                                                Task Achievement
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item align='left' xs={12} sm={10}>
                                                            <Typography variant="subtitle2" gutterBottom>
                                                                {this.state.report.score.writing.task_1.task_achievement}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid container>
                                                        <Grid item align='left' xs={12} sm={2}>
                                                            <Typography variant="subtitle2" gutterBottom>
                                                                Cohesion and Coherence
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item align='left' xs={12} sm={10}>
                                                            <Typography variant="subtitle2" gutterBottom>
                                                                {this.state.report.score.writing.task_1.cohesion_and_coherence}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid container>
                                                        <Grid item align='left' xs={12} sm={2}>
                                                            <Typography variant="subtitle2" gutterBottom>
                                                                Lexical Resource
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item align='left' xs={12} sm={10}>
                                                            <Typography variant="subtitle2" gutterBottom>
                                                                {this.state.report.score.writing.task_1.lexical_resource}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid container>
                                                        <Grid item align='left' xs={12} sm={2}>
                                                            <Typography variant="subtitle2" gutterBottom>
                                                                Grammatical Range and Accuracy
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item align='left' xs={12} sm={10}>
                                                            <Typography variant="subtitle2" gutterBottom>
                                                                {this.state.report.score.writing.task_1.grammatical_range_and_accuracy}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                        {this.state.report !== undefined && this.state.isSeeMore && this.state.isSeeMoreType === 'WRITING' &&
                            <Grid item xs={12} style={{ marginTop: '20px' }}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={3}>
                                        <Paper style={{ padding: '20px' }}>
                                            <Typography variant="subtitle2" gutterBottom>
                                                Section
                                            </Typography>
                                            <Divider style={{ marginBottom: '10px' }} />
                                            <Typography variant="h4">
                                                <Box fontWeight="fontWeightBold" display='inline'>2</Box>
                                            </Typography>
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={12} sm={9}>
                                        <Paper style={{ padding: '20px' }}>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Grid container>
                                                        <Grid item align='left' xs={12} sm={2}>
                                                            <Typography variant="subtitle2" gutterBottom>
                                                                <Box fontWeight="fontWeightBold" display='inline'>Criteria</Box>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item align='left' xs={12} sm={10}>
                                                            <Typography variant="subtitle2" gutterBottom>
                                                                <Box fontWeight="fontWeightBold" display='inline'>Comments</Box>
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item align='left' xs={12}>
                                                    <Divider style={{ marginBottom: '10px' }} />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid container>
                                                        <Grid item align='left' xs={12} sm={2}>
                                                            <Typography variant="subtitle2" gutterBottom>
                                                                Task Achievement
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item align='left' xs={12} sm={10}>
                                                            <Typography variant="subtitle2" gutterBottom>
                                                                {this.state.report.score.writing.task_2.task_achievement}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid container>
                                                        <Grid item align='left' xs={12} sm={2}>
                                                            <Typography variant="subtitle2" gutterBottom>
                                                                Cohesion and Coherence
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item align='left' xs={12} sm={10}>
                                                            <Typography variant="subtitle2" gutterBottom>
                                                                {this.state.report.score.writing.task_2.cohesion_and_coherence}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid container>
                                                        <Grid item align='left' xs={12} sm={2}>
                                                            <Typography variant="subtitle2" gutterBottom>
                                                                Lexical Resource
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item align='left' xs={12} sm={10}>
                                                            <Typography variant="subtitle2" gutterBottom>
                                                                {this.state.report.score.writing.task_2.lexical_resource}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid container>
                                                        <Grid item align='left' xs={12} sm={2}>
                                                            <Typography variant="subtitle2" gutterBottom>
                                                                Grammatical Range and Accuracy
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item align='left' xs={12} sm={10}>
                                                            <Typography variant="subtitle2" gutterBottom>
                                                                {this.state.report.score.writing.task_2.grammatical_range_and_accuracy}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }

                        {this.state.isSeeMore && this.state.isSeeMoreType === 'SPEAKING' &&
                            <Grid item xs={12} style={{ marginTop: '20px' }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Paper style={{ padding: '20px' }}>
                                            <Grid container>
                                                <Grid item align='left' xs={12} sm={2}>
                                                    <Typography variant="subtitle2" gutterBottom>
                                                        <Box fontWeight="fontWeightBold" display='inline'>Band Score</Box>
                                                    </Typography>
                                                    <Divider style={{ marginBottom: '10px' }} />
                                                    <Typography variant="subtitle2" gutterBottom>
                                                        {this.state.report === undefined ? '' : this.state.report.score.speaking.score}
                                                    </Typography>
                                                </Grid>
                                                <Grid item align='left' xs={12} sm={10}>
                                                    <Typography variant="subtitle2" gutterBottom>
                                                        <Box fontWeight="fontWeightBold" display='inline'>Band Score Description</Box>
                                                    </Typography>
                                                    <Divider style={{ marginBottom: '10px' }} />
                                                    <Typography variant="subtitle2" gutterBottom>
                                                        {this.state.report === undefined ? '' : this.state.report.score.speaking.score_introduction}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                        {this.state.report !== undefined && this.state.isSeeMore && this.state.isSeeMoreType === 'SPEAKING' &&
                            <Grid item xs={12} style={{ marginTop: '20px' }}>
                                <Paper style={{ padding: '20px' }}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Grid container>
                                                <Grid item align='left' xs={12} sm={2}>
                                                    <Typography variant="subtitle2" gutterBottom>
                                                        <Box fontWeight="fontWeightBold" display='inline'>Criteria</Box>
                                                    </Typography>
                                                </Grid>
                                                <Grid item align='left' xs={12} sm={10}>
                                                    <Typography variant="subtitle2" gutterBottom>
                                                        <Box fontWeight="fontWeightBold" display='inline'>Comments</Box>
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item align='left' xs={12}>
                                            <Divider style={{ marginBottom: '10px' }} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container>
                                                <Grid item align='left' xs={12} sm={2}>
                                                    <Typography variant="subtitle2" gutterBottom>
                                                        Fluency and Coherence
                                                    </Typography>
                                                </Grid>
                                                <Grid item align='left' xs={12} sm={10}>
                                                    <Typography variant="subtitle2" gutterBottom>
                                                        {this.state.report.score.speaking.fluency_and_coherence}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container>
                                                <Grid item align='left' xs={12} sm={2}>
                                                    <Typography variant="subtitle2" gutterBottom>
                                                        Gramatical Range and Accuracy
                                                    </Typography>
                                                </Grid>
                                                <Grid item align='left' xs={12} sm={10}>
                                                    <Typography variant="subtitle2" gutterBottom>
                                                        {this.state.report.score.speaking.gramatical_range_and_accuracy}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container>
                                                <Grid item align='left' xs={12} sm={2}>
                                                    <Typography variant="subtitle2" gutterBottom>
                                                        Pronunciation
                                                    </Typography>
                                                </Grid>
                                                <Grid item align='left' xs={12} sm={10}>
                                                    <Typography variant="subtitle2" gutterBottom>
                                                        {this.state.report.score.speaking.pronunciation}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        }

                        {this.state.isSeeMore &&
                            <Grid item xs={12} style={{ marginTop: '20px' }}>
                                <Button variant="contained" onClick={this.handleSeeLess} style={{ width: '100%', backgroundColor: '#76323F', color: 'white' }}>
                                    <Box fontWeight="fontWeightBold" display='inline'>See less</Box>
                                </Button>
                            </Grid>
                        }
                        <Grid item xs={12} style={{ marginTop: '20px' }}>
                            <Link to="/myprofile" style={{ textDecoration: 'none', color: '#0f0f0f' }}>
                                <Button variant="outlined">
                                    Back
                                </Button>
                            </Link>
                        </Grid>
                    </Grid>
                </Grid>
            </Container >
        )
    }
}

export default compose(
    withStyles(useStyles)
)(MyReportPage);