import React, { useEffect } from 'react';

//MUI
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';

//Components
import ReadingRetina from './ReadingRetina';
import TestReading from './TestReading';
import ReadingTablet from './ReadingTablet'

//Others
import MediaQuery from 'react-responsive';

const useStyles = theme => ({
    root: {
        padding: theme.spacing(12, 4),
    }
});

const ReadingRender = (props) => {
    useEffect(() => {
        document.querySelector("body").scrollTo(0, 0);
    }, []);

    return <MediaQuery minResolution={"2dppx"}>
        {(matches) =>
            matches ? <ReadingRetina
                DoFullScreen={props.DoFullScreen}
                ExitFullScreen={props.ExitFullScreen}
                session={JSON.parse(localStorage.getItem("readingSession"))}
                user={props.user}
                userSignup={props.userSignup}
                userSignedin={props.userSignedin}
                handleEndTest={props.handleEndTest}
                keepCurrentSession={props.keepCurrentSession}
                handleOpenLoading={props.handleOpenLoading}
                handleCloseLoading={props.handleCloseLoading}
                handleStartTest={props.handleStartTest}
                handleStartTestType={props.handleStartTestType}
                handleUpdateAnswerObj={props.handleUpdateAnswerObj}
                isFreeTest={props.isFreeTest}
                isDummyTest={props.isDummyTest}
                history={props.history}
                handleCreateStudentReport={props.handleCreateStudentReport}
                isLoading={props.isLoading}
                centralTestId={props.centralTestId}
            /> : <MediaQuery maxWidth={"1224px"}>
                {(matches) =>
                    matches ? <ReadingTablet
                        DoFullScreen={props.DoFullScreen}
                        ExitFullScreen={props.ExitFullScreen}
                        session={JSON.parse(localStorage.getItem("readingSession"))}
                        user={props.user}
                        userSignup={props.userSignup}
                        userSignedin={props.userSignedin}
                        handleEndTest={props.handleEndTest}
                        keepCurrentSession={props.keepCurrentSession}
                        handleOpenLoading={props.handleOpenLoading}
                        handleCloseLoading={props.handleCloseLoading}
                        handleStartTest={props.handleStartTest}
                        handleStartTestType={props.handleStartTestType}
                        handleUpdateAnswerObj={props.handleUpdateAnswerObj}
                        isFreeTest={props.isFreeTest}
                        isDummyTest={props.isDummyTest}
                        history={props.history}
                        handleCreateStudentReport={props.handleCreateStudentReport}
                        isLoading={props.isLoading}
                        centralTestId={props.centralTestId}
                    /> : <TestReading
                        DoFullScreen={props.DoFullScreen}
                        ExitFullScreen={props.ExitFullScreen}
                        session={JSON.parse(localStorage.getItem("readingSession"))}
                        user={props.user}
                        userSignup={props.userSignup}
                        userSignedin={props.userSignedin}
                        handleEndTest={props.handleEndTest}
                        keepCurrentSession={props.keepCurrentSession}
                        handleOpenLoading={props.handleOpenLoading}
                        handleCloseLoading={props.handleCloseLoading}
                        handleStartTest={props.handleStartTest}
                        handleStartTestType={props.handleStartTestType}
                        handleUpdateAnswerObj={props.handleUpdateAnswerObj}
                        isFreeTest={props.isFreeTest}
                        isDummyTest={props.isDummyTest}
                        history={props.history}
                        handleCreateStudentReport={props.handleCreateStudentReport}
                        isLoading={props.isLoading}
                        centralTestId={props.centralTestId}
                    />
                }
            </MediaQuery>
        }
    </MediaQuery>
};

export default compose(
    withStyles(useStyles)
)(ReadingRender);
