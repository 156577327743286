const axios = require('axios').default;
const OMISE_HELPER_API = "https://chatter-funky-busby.glitch.me"

export async function GetCharge(id) {
    try {
        const res = await axios({
            method: 'get',
            url: `${OMISE_HELPER_API}/omise/charges/${id}`
        });

        return {
            success: true,
            res: res.data
        }

    } catch (err) {
        console.log(err);

        return {
            success: false,
            res: err
        }
    }
}

export async function GetSource(sourceId) {
    try {
        const res = await axios({
            method: 'get',
            url: `${OMISE_HELPER_API}/omise/sources/${sourceId}`
        });

        return {
            success: true,
            res: res.data
        }

    } catch (err) {
        console.log(err);

        return {
            success: false,
            res: err
        }
    }
}

export async function GetQRCode(amount) {
    try {
        const res = await axios({
            method: 'get',
            url: `${OMISE_HELPER_API}/omise/promptpay/qr/amount/${amount}`
        });

        return {
            success: true,
            res: res.data
        }

    } catch (err) {
        console.log(err);

        return {
            success: false,
            res: err
        }
    }
}