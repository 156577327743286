//Contants
import * as apiConstants from '../constants/apiConstants';

const axios = require('axios').default;

//=========================== CREATE NEW SECTION ======================================
export async function CreateNewSection(secObj) {
    try {
        await axios({
            method: 'post',
            url: `${apiConstants.E_LEARNING_API_BASE_URL}/api/test/section?type=${secObj.question_type}`,
            data: secObj,
            headers: {
                "Authorization": `Bearer ${JSON.parse(localStorage.getItem('user')).token}`
            }
        });

        return {
            message: 'CREATED',
            success: true
        }
    } catch (err) {
        console.log(err);
        return {
            message: err,
            success: false
        }
    }
}
//=========================== CREATE NEW SECTION ======================================