import MaterialTable from 'material-table';

import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { compose } from 'recompose';

//import others
import 'react-calendar/dist/Calendar.css';

//react-router-dom

//Others
const axios = require('axios').default;

const useStyles = theme => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    root: {
        minWidth: 275,
    }
});


class DiscountCodeTable extends Component {
    constructor() {
        super();
        this.state = {
            isOpenDialog: false,
            isLoading: false
        }
    }

    componentDidMount = () => {
        this.handleGetAllDiscountCode();
    }

    handleGetAllDiscountCode = async () => {
        try {
            const discountsToShow = [];

            const res = await axios({
                method: 'get',
                url: 'https://erratic-noiseless-wasabi.glitch.me/utestavenue-system-api/api/v1/discounts',
            });

            const resData = res.data;

            console.log(resData.discounts);

            await resData.discounts.map((discount) => {
                discountsToShow.push({
                    _id: discount._id,
                    discountCode: discount.discountCode,
                    discount: discount.discount,
                    useFor: discount.useFor,
                    useCount: discount.useCount !== undefined ? discount.useCount : "N/A",
                    isForTesting: discount.isForTesting ? "Yes" : "No",
                    createdBy: discount.createdBy,
                    createdAt: new Date(discount.createdAt).toDateString(),
                    expiredAt: new Date(discount.expiredAt).toDateString()
                })
            });


            this.setState({
                discounts: discountsToShow
            });

            console.log(this.state.discounts);

        } catch (err) {
            console.log(err);
        }
    }

    handleDeleteCode = async () => {
        try {
            this.handleCloseDialog();
            this.handleOpenBackdrop();

            const res = await axios({
                method: 'delete',
                url: 'https://erratic-noiseless-wasabi.glitch.me/utestavenue-system-api/api/v1/discounts/' + this.state.selectedDiscountId,
            });

            const resData = res.data;
            console.log(resData);

            await this.handleGetAllDiscountCode();
            this.handleCloseBackdrop();

        } catch (err) {
            console.log(err);
        }
    }

    handleOpenDialog = (discountId) => {
        this.setState({
            isOpenDialog: true,
            selectedDiscountId: discountId
        });
    }

    handleCloseDialog = () => {
        this.setState({
            isOpenDialog: false
        });
    }

    handleOpenBackdrop = () => {
        this.setState({
            isLoading: true
        });
    }

    handleCloseBackdrop = () => {
        this.setState({
            isLoading: false
        });
    }

    render() {
        const { classes } = this.props;

        return (
            <Grid item xs={12}>
                <Backdrop className={classes.backdrop} open={this.state.isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>

                <Dialog
                    open={this.state.isOpenDialog}
                    onClose={this.handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Delete discount code?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to delete "{this.state.selectedDiscountId}"?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseDialog} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.handleDeleteCode} color="primary" autoFocus>
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>

                <MaterialTable
                    isLoading={!this.state.discounts}
                    data={this.state.discounts}
                    columns={[
                        { title: 'Discount code', field: 'discountCode', editable: 'never' },
                        { title: 'Amount', field: 'discount', editable: 'never' },
                        { title: 'Use for', field: 'useFor', editable: 'never' },
                        { title: 'For testing', field: 'isForTesting', editable: 'never' },
                        { title: 'Times left', field: 'useCount', editable: 'never' },
                        { title: 'Created by', field: 'createdBy', editable: 'never' },
                        { title: 'Created at', field: 'createdAt', editable: 'never', defaultSort: 'desc', customSort: (a, b) => new Date(a.createdAt) - new Date(b.createdAt) },
                        { title: 'Expired at', field: 'expiredAt', editable: 'never', customSort: (a, b) => new Date(a.expiredAt) - new Date(b.expiredAt) },
                    ]}
                    options={{
                        actionsColumnIndex: -1,
                        exportAllData: true,
                        exportButton: true,
                        pageSize: 10
                    }}
                    actions={[
                        {
                            icon: 'delete',
                            tooltip: 'Delete code',
                            onClick: (event, rowData) => this.handleOpenDialog(rowData._id)
                        }
                    ]}
                    title='Discounts'
                />
            </Grid>
        )
    }
}
export default compose(
    withStyles(useStyles)
)(DiscountCodeTable);
