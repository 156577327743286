import React from 'react';

//Material UI
import Grid from '@material-ui/core/Grid';
import MuiAlert from '@material-ui/lab/Alert';
import TextField from '@material-ui/core/TextField';

//Others
import { withStyles } from '@material-ui/core/styles';
import { Component } from 'react';
import { compose } from 'recompose';
import Select from 'react-select'

const useStyles = theme => ({
    root: {
        margin: 15,
        marginTop: 50
    },
    margin: {
        margin: theme.spacing(1),
    },
    buttonColor: {
        backgroundColor: '#76323F',
        color: 'white'
    }
});

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class TrueFalseNG extends Component {
    constructor() {
        super()
        this.state = {
            answer: undefined
        };
    }

    componentDidMount = async () => {

    }

    handleSelectAnswer = async (event) => {
        if (event === null) {
            this.props.questionObj.answer = "";
        } else if (event !== null) {
            this.props.questionObj.answer = event.value;
        }
    }

    handleEditQuestion = async (event) => {
        this.props.questionObj.question = event.target.value;
    }

    handleEditQuestionNumber = async (event) => {
        this.props.questionObj.no = event.target.value;

        console.log(this.props.questionObj)
    }

    render() {
        const { classes } = this.props;

        return (
            <Grid item xs={12}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={2}>
                        <TextField
                            label="No."
                            id="outlined-size-small"
                            variant="outlined"
                            size="small"
                            onChange={this.handleEditQuestionNumber}
                        />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <TextField
                            label="Question"
                            id="outlined-size-small"
                            variant="outlined"
                            size="small"
                            onChange={this.handleEditQuestion}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Select
                            name="answer"
                            isSearchable={false}
                            isClearable={true}
                            options={[
                                { value: 'TRUE', label: 'True' },
                                { value: 'FALSE', label: 'False' },
                                { value: 'NOT GIVEN', label: 'Not given' }
                            ]}
                            onChange={this.handleSelectAnswer}
                        />
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}
export default compose(
    withStyles(useStyles)
)(TrueFalseNG);