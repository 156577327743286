//React
import { Component } from 'react';
import { compose } from 'recompose';

//Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';

//Icon
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';

//react-router-dom
import {
    Link
} from "react-router-dom";

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%'
    }
});

class UserInfoPage extends Component {
    constructor() {
        super();
    }

    render() {
        return (
            <Grid container justify="center" style={{ marginTop: '10px' }}>
                <Paper variant="outlined" style={{
                    padding: '50px',
                    backgroundImage: `url("/U-Test-Logo-new-bw.png")`,
                    backgroundSize: '120px',
                    backgroundRepeat: 'space'
                }}>
                    <Grid item align='left' xs={12}>
                        <Typography variant="h6" gutterBottom>
                            <Box fontWeight="fontWeightBold" display='inline'> IELTS Academic Listening Simulation Test </Box>
                        </Typography>
                    </Grid>
                    <Grid item align='left' xs={12}>
                        <Grid container spacing={1}>
                            <Grid item align='left' xs={12}>
                                <Typography variant="subtitle2">
                                    Name: {this.props.user.name}
                                </Typography>
                            </Grid>
                            <Grid item align='left' xs={12}>
                                <Typography variant="subtitle2">
                                    Email: {this.props.user.username}
                                </Typography>
                            </Grid>
                            <Grid item align='left' xs={12}>
                                <Typography variant="subtitle2">
                                    School: {JSON.parse(localStorage.getItem('user')).school?.length === 0 ? "-" : JSON.parse(localStorage.getItem('user')).school}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item align='left' xs={12} style={{ marginTop: '20px' }}>
                        <Typography variant="subtitle2" gutterBottom>
                            <InfoRoundedIcon fontSize="small" /> If your details are not correct, please contact us.
                        </Typography>
                    </Grid>
                    <Grid item align='center' xs={12} style={{ marginTop: '20px' }}>
                        <Button size="small" variant="contained" onClick={this.props.handleUserInfoChecked} style={{ color: 'white', backgroundColor: '#25333e' }}>
                            <Box fontWeight="fontWeightBold" display='inline'> My details are correct </Box>
                        </Button>
                    </Grid>
                    <Grid item align='center' xs={12} style={{ marginTop: '2px' }}>
                        <Link to="/" style={{ textDecoration: 'none', color: '#0f0f0f' }}>
                            <Button size="small" startIcon={<HomeRoundedIcon style={{ color: 'grey' }} />} onClick={this.props.handleEndTest}>
                                <Box fontWeight="fontWeightBold" display='inline' style={{ color: 'grey' }}> Home </Box>
                            </Button>
                        </Link>
                    </Grid>
                    <Grid item align='right' xs={12}>
                        <Typography
                            variant="subtitle2"
                            style={{ color: "#c8c8c8", fontWeight: "bold" }}
                        >
                            {btoa(this.props.user.username)}
                        </Typography>
                    </Grid>
                </Paper>
            </Grid>
        )
    }
}

export default compose(
    withStyles(useStyles)
)(UserInfoPage);