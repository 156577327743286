//React
import React, { Component } from "react";
import { compose } from "recompose";

//Material UI
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import { withStyles } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core/styles";

import ArrowForwardRoundedIcon from "@material-ui/icons/ArrowForwardRounded";

//Animations
import Fade from "react-reveal/Fade";

//react-router-dom
import { Link } from "react-router-dom";

//Constants
import * as styleConstants from "../../../../constants/styleConstants";

//Others
import { Player, ControlBar, PlayToggle, VolumeMenuButton } from "video-react";
import "video-react/dist/video-react.css";

import MediaQuery from 'react-responsive';

const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  rootContainer: {
    maxWidth: "75%",
  },
  rootCard: {
    maxWidth: "75%",
    width: "75%",
    margin: 10,
  },
  gridContainer: {
    maxWidth: "90%",
  },
  margin: {
    margin: 100,
  },
  marginTop: {
    marginTop: 100,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(5),
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  header: {
    maxWidth: "80%",
  },
  body: {
    maxWidth: "80%",
    marginTop: 30,
  },
  divider: {
    maxWidth: "100%",
  },
  image: {
    margin: "auto",
    display: "block",
    margin: 10,
  },
  commentTextField: {
    width: "100%",
    maxWidth: "100%",
  },
  commentGridItem: {
    maxWidth: "80%",
    marginTop: 30,
    marginBottom: 100,
  },
});

const theme = createMuiTheme({
  typography: {
    fontFamily: [`${styleConstants.fontStyle}`].join(","),
  },
});

class AboutUsSectionMobile extends Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() { }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  render() {
    const { classes } = this.props;

    return (
      <MediaQuery minWidth={"768px"}>
        {(matches) =>
          matches ? <Grid item xs={12} style={{ maxWidth: window.innerWidth, zIndex: 1 }}>
            <Grid container style={{ paddingRight: "30px" }}>
              <Grid item align="center" xs={12} sm={6} style={{ padding: "10%" }}>
                <Grid container justify="center">
                  <Fade top>
                    <Grid item xs={12}>
                      <Typography
                        variant="h3"
                        style={{
                          fontWeight: styleConstants.fontWeight.bold,
                          fontFamily: styleConstants.fontStyle,
                          color: "#991514",
                        }}
                      >
                        ABOUT US
                      </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: "10%" }}>
                      <Typography variant="h6" style={{ fontFamily: styleConstants.fontStyle, fontWeight: styleConstants.fontWeight.thin, }}>
                        We corporate our years of teaching experience with innovative
                        machine learning for your personalised test preparations.
                      </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: "10%" }}>
                      <Link
                        to="/aboutus"
                        style={{ textDecoration: "none", color: "#0f0f0f" }}
                      >
                        <Button
                          variant="contained"
                          endIcon={<ArrowForwardRoundedIcon />}
                          style={{ backgroundColor: "white" }}
                        >
                          <Typography
                            variant="h6"
                            style={{ fontWeight: styleConstants.fontWeight.bold, fontFamily: styleConstants.fontStyle }}
                          >
                            Learn more
                          </Typography>
                        </Button>
                      </Link>
                    </Grid>
                  </Fade>
                </Grid>
              </Grid>
              <Grid
                item
                align="center"
                xs={12}
                sm={6}
                style={{ padding: "10%", zIndex: 1, paddingLeft: "0px" }}
              >
                <Player
                  autoPlay={false}
                  preload="auto"
                  poster="/video-thumbnail.png"
                  src="/UTEST AVENUE (MAIN PAGE VID).mp4"
                  fluid={false}
                  width={500}
                >
                  <ControlBar autoHide={false} disableDefaultControls={true}>
                    <PlayToggle />
                    <VolumeMenuButton />
                  </ControlBar>
                </Player>
              </Grid>
            </Grid>
          </Grid> : <Grid item xs={12} style={{ maxWidth: window.innerWidth, zIndex: 1, marginTop: "100px" }}>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  variant="h4"
                  style={{
                    fontWeight: styleConstants.fontWeight.bold,
                    fontFamily: styleConstants.fontStyle,
                    color: "#991514",
                  }}
                >
                  ABOUT US
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ marginTop: "3%", padding: "10px" }}>
                <Typography variant="body1" style={{ fontFamily: styleConstants.fontStyle, fontWeight: styleConstants.fontWeight.thin, }}>
                  We corporate our years of teaching experience with innovative
                  machine learning for your personalised test preparations.
                </Typography>
              </Grid>
              <Grid
                item
                align="center"
                xs={12}
                style={{ padding: "5%", zIndex: 1, paddingBottom: "0px" }}
              >
                <Player
                  autoPlay={false}
                  preload="auto"
                  poster="/video-thumbnail.png"
                  src="/UTEST AVENUE (MAIN PAGE VID).mp4"
                  fluid={false}
                  width={300}
                >
                  <ControlBar autoHide={false} disableDefaultControls={true}>
                    <PlayToggle />
                    <VolumeMenuButton />
                  </ControlBar>
                </Player>
              </Grid>
            </Grid>
          </Grid>
        }
      </MediaQuery>
    );
  }
}

export default compose(withStyles(useStyles))(AboutUsSectionMobile);
