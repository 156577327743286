import React, { useEffect } from 'react';

//MUI
import Container from '@material-ui/core/Container';

import { ThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';
import { DataGrid } from '@mui/x-data-grid';

//Components

//Font
import 'typeface-glacial-indifference';
import { GetAllTestSurveyLog } from '../../../../services/testServices';

//Others

const useStyles = theme => ({
    root: {
        padding: theme.spacing(12, 4),
    }
});

const theme = createMuiTheme({
    typography: {
        fontFamily: [
            'Roboto'
        ].join(','),
    },
});

const UserTestSurveyTable = () => {
    const columns = [
        { field: 'id', headerName: 'ID', width: 90, hide: true },
        {
            field: 'test_id',
            headerName: 'Test ID',
            width: 150,
        },
        {
            field: 'userEmail',
            headerName: 'Email',
            width: 150,
            hide: true
        },
        {
            field: 'userName',
            headerName: 'Name',
            width: 150,
        },
        {
            field: 'ieltsExp',
            headerName: 'Experience(months)',
            width: 210,
        },
        {
            field: 'concern',
            headerName: 'Concerns',
            width: 220,
        },
        {
            field: 'selectedChoice',
            headerName: 'Experienced test',
            width: 200,
        },
        {
            field: 'selectedExpectedScore',
            headerName: 'Expected score',
            width: 200,
        },
    ];

    const [dataRows, setDataRows] = React.useState([]);

    useEffect(() => {
        document.body.style.overflow = "auto";
        document.body.style.backgroundColor = "#efefef";
        document.querySelector("body").scrollTo(0, 0);

        getAllTestSurvey();
    }, []);

    const getAllTestSurvey = async () => {
        let tempArr = [];
        const res = await GetAllTestSurveyLog();
        if (res.success) {
            res.res.data.map((survey) => {
                tempArr.push({
                    id: survey._id,
                    userEmail: survey.user_email,
                    userName: survey.user_name,
                    ieltsExp: survey.survey_result.ieltsExp,
                    concern: survey.survey_result.concern,
                    selectedChoice: survey.survey_result.selectedChoice,
                    selectedExpectedScore: survey.survey_result.selectedExpectedScore,
                    test_id: survey.test_id
                });
            });

            setDataRows(tempArr);
        }

        console.log(res);
    }

    return <ThemeProvider theme={theme}>
        <div style={{ height: 400, width: '100%', backgroundColor: 'white' }}>
            <DataGrid
                rows={dataRows}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[100]}
                //checkboxSelection
                disableSelectionOnClick
            />
        </div>
    </ThemeProvider>
};

export default compose(
    withStyles(useStyles)
)(UserTestSurveyTable);