//React
import { Component } from 'react';
import { compose } from 'recompose';

//Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

//Others libraries
import Image from 'material-ui-image';
import Webcam from "react-webcam";

//comonents
import PageBackdrop from '../../main/PageBackdrop';
import Instructions from './components/Instructions';
import VerifyFailedDialog from './components/VerifyFailedDialog';

const axios = require('axios').default;
const FormData = require('form-data');

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%'
    }
});

class VerificationPage extends Component {
    constructor() {
        super();
        this.state = {
            verifyResult: '',
            verifyCount: 3,
            isCaptured: false,
            videoConstraints: {
                width: 1280,
                height: 720,
                facingMode: "user"
            },
            isLoading: false,
            isConsent: false,
            isOpenDialog: false,
            isVerifyError: false
        };
    }

    componentDidMount = () => {
        document.querySelector("body").scrollTo(0, 0);
        document.body.style.backgroundColor = "#efefef";
        this.prepareRedirectURL();
        this.handleGetPurchasedCourse();
    }

    prepareRedirectURL = () => {
        const { history } = this.props;
        const searchParams = new URLSearchParams(window.location.search);

        if (searchParams.get("redirectTo") === null) {
            history.push("/");
        } else {
            this.setState({
                redirectPath: searchParams.get("redirectTo"),
                purchaseId: searchParams.get("purchaseId")
            });
        }

        return;
    }

    handleGetPurchasedCourse = async () => {
        try {
            const purchasedCourse = await axios({
                method: 'get',
                url: "https://e-learning-be-ybcs6wa7da-as.a.run.app/api/test/test-purchase-status?user_name=" + JSON.parse(localStorage.getItem('user')).username,
                headers: {
                    Authorization: "bearer " + JSON.parse(localStorage.getItem('user')).token
                }
            })

            if (purchasedCourse.data.filter((course) => { return course._id === this.state.purchaseId})[0].tested) {
                this.props.history.push("/profile");
            }

        } catch (error) {
            console.error(error);
            this.setState({
                purchasedCourse: []
            });
        }
    }

    setRef = webcam => {
        this.setState({
            webcamRef: webcam
        })
    }

    handleLoading = () => {
        this.setState({
            isLoading: true
        });
    }

    handleStopLoading = () => {
        this.setState({
            isLoading: false
        });
    }

    b64toBlob = (imageSrc) => {
        const byteCharacters = atob(imageSrc.split(",")[1]);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: "image/jpeg" });
        return blob;
    }

    handleCaptureImg = () => {
        const imageSrc = this.state.webcamRef.getScreenshot();
        const imgBlob = this.b64toBlob(imageSrc);

        this.setState({
            userFaceImg: URL.createObjectURL(imgBlob),
            isCaptured: true,
            userFaceObj: imgBlob
        });
    }

    handleRetakeImage = () => {
        this.setState({
            userFaceImg: null,
            isCaptured: false
        });
    }

    handleUploadFile = async (event, type) => {
        if (type === 'idCard') {
            this.setState({
                idCardImg: URL.createObjectURL(event.target.files[0]),
                idCardObj: event.target.files[0]
            });
        }

        else if (type === 'userFace') {
            this.setState({
                userFaceImg: URL.createObjectURL(event.target.files[0]),
                userFaceObj: event.target.files[0],
                isCaptured: true,
            });
        }
    }

    handleVerifyUser = async () => {
        const { history } = this.props;
        this.handleLoading()
        try {
            console.log(this.state.idCardObj)
            let formData = new FormData();
            formData.append('photo1', this.state.idCardObj, this.state.idCardObj.name);
            formData.append('photo2', this.state.userFaceObj, 'userFace.jpeg');

            const res = await axios({
                method: 'post',
                url: 'https://face-verification2.p.rapidapi.com/FaceVerification',
                headers: {
                    'X-RapidAPI-Key': '52e6ee5c1emsh0b5a86ff7d1a95fp121e86jsn965f31c27f03',
                    'X-RapidAPI-Host': 'face-verification2.p.rapidapi.com',
                    'Content-Type': "multipart/form-data"
                },
                data: formData
            });

            const resData = res.data;
            console.log(resData);

            if (resData.data.similarPercent >= 0.7) {
                this.onOpenDialog();
            }
            else if (resData.data.similarPercent < 0.7) {
                if (this.state.verifyCount === 1) {
                    this.setState({
                        isVerifyError: true
                    });
                }
                this.setState({
                    verifyCount: this.state.verifyCount - 1
                });
            }

            this.setState({
                verifyResult: resData.data.resultMessage
            })

            this.handleStopLoading();

        } catch (err) {
            if (this.state.verifyCount === 1) {
                this.setState({
                    isVerifyError: true
                });
            }
            this.setState({
                verifyCount: this.state.verifyCount - 1
            })
            console.log(err);
            this.handleStopLoading();
        }

    }

    handleSkipVerification = () => {
        const { history } = this.props;

        history.push(this.state.redirectPath);
    }

    onClickConsent = () => {
        this.setState({
            isConsent: true
        });
    }

    onCloseDialog = () => {
        this.setState({
            isOpenDialog: false
        });
    }

    onOpenDialog = () => {
        this.setState({
            isOpenDialog: true
        });
    }

    onClickContinue = () => {
        const { history } = this.props;

        history.push(`${this.state.redirectPath}&purchaseId=${this.state.purchaseId}&is_free=false`);
    }


    render() {
        const { classes } = this.props;

        return (
            <Container>
                <PageBackdrop isLoading={this.state.isLoading} />

                <VerifyFailedDialog isOpenDialog={this.state.isVerifyError} />

                <Dialog
                    open={this.state.isOpenDialog}
                    onClose={this.onCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        <Typography variant="h6">
                            You have successfully verified!
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <Typography variant="body1" gutterBottom>
                                Your trust is the most importance to us.
                            </Typography>
                            <Typography variant="body2" style={{ marginTop: "20px" }}>
                                Thank you for your cooperation in enhancing the security of our platform,
                            </Typography>
                            <Typography variant="body2">
                                UTest Avenue Team
                            </Typography>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.onClickContinue} autoFocus style={{ color: "#8f1b15" }}>
                            Continue
                        </Button>
                    </DialogActions>
                </Dialog>

                <Grid container spacing={2} style={{ marginTop: '50px', marginBottom: '200px' }}>

                    {!this.state.isConsent && <Instructions onClickConsent={this.onClickConsent} />}

                    {this.state.isConsent && <Grid item xs={12}>
                        <Typography align='center' gutterBottom variant="h4" component="h2" style={{ color: '#76323F' }}>
                            <Box fontWeight="fontWeightBold" display='inline'>User Verification</Box>
                        </Typography>
                    </Grid>}

                    {this.state.isConsent && <Grid item xs={12} sm={6}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography align='center' gutterBottom variant="h6" style={{ color: '#76323F' }}>
                                    1. Upload your government issued ids
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper style={{ height: "350px" }}>
                                    {this.state.idCardImg
                                        ? <img src={this.state.idCardImg} style={{ height: "350px" }} />
                                        : <CircularProgress style={{ marginTop: "25%" }} />
                                    }
                                </Paper>
                            </Grid>
                            <Grid item xs={12} style={{ marginTop: '20px' }}>
                                <input
                                    accept="image/png, image/gif, image/jpeg" //image/heic, image/heif
                                    id="contained-button-file"
                                    type="file"
                                    onChange={(event) => { this.handleUploadFile(event, "idCard") }}
                                    style={{ display: 'none' }}
                                />
                                <label htmlFor="contained-button-file">
                                    <Button
                                        disableElevation
                                        variant="contained"
                                        component="span"
                                    >
                                        Upload
                                    </Button>
                                </label>
                            </Grid>
                        </Grid>
                    </Grid>}

                    {this.state.isConsent && <Grid item xs={12} sm={6}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography align='center' gutterBottom variant="h6" style={{ color: '#76323F' }}>
                                    2. Please take your photo
                                </Typography>
                            </Grid>
                            <Grid item align='center' xs={12}>
                                <Paper style={{ height: "350px" }}>
                                    {this.state.isCaptured ? <Image
                                        src={this.state.userFaceImg}
                                        aspectRatio={(16 / 9)}
                                    /> : <Webcam
                                        audio={false}
                                        screenshotFormat="image/jpeg"
                                        ref={this.setRef}
                                        videoConstraints={this.state.videoConstraints}
                                        height={"100%"}
                                        width={"100%"}
                                    />}
                                </Paper>
                            </Grid>
                            <Grid item xs={12} style={{ marginTop: '20px' }}>
                                {this.state.isCaptured ? <Button
                                    disableElevation
                                    variant="contained"
                                    component="span"
                                    onClick={this.handleRetakeImage}
                                >
                                    Re-Capture
                                </Button> : <Button
                                    disableElevation
                                    variant="contained"
                                    component="span"
                                    onClick={this.handleCaptureImg}
                                >
                                    Capture
                                </Button>
                                }
                            </Grid>
                            <Grid item xs={12} style={{ marginTop: '20px' }}>
                                <input
                                    accept="image/png, image/gif, image/jpeg, image/heic, image/heif"
                                    id="contained-button-file-user"
                                    type="file"
                                    onChange={(event) => { this.handleUploadFile(event, "userFace") }}
                                    style={{ display: 'none' }}
                                />
                                {/* <label htmlFor="contained-button-file-user">
                                    <Button
                                        disableElevation
                                        variant="contained"
                                        component="span"
                                    >
                                        Test Upload
                                    </Button>
                                </label> */}
                            </Grid>
                        </Grid>
                    </Grid>}
                    {/*<Grid item xs={12} sm={6}>
                        <Grid container>
                            <Grid item xs={12} style={{ marginTop: '20px' }}>
                                <Paper>
                                    <Image
                                        src={this.state.userFaceImg}
                                    />
                                </Paper>
                            </Grid>
                            <Grid item xs={12} style={{ marginTop: '20px' }}>
                                <input
                                    accept="image/png, image/gif, image/jpeg"
                                    id="contained-button-file-user"
                                    type="file"
                                    onChange={(event) => { this.handleUploadFile(event, "userFace") }}
                                    style={{ display: 'none' }}
                                />
                                <label htmlFor="contained-button-file-user">
                                    <Button
                                        variant="contained"
                                        component="span"
                                    >
                                        Test Upload
                                    </Button>
                                </label>
                            </Grid>
                        </Grid>
                                    </Grid>*/}
                    {this.state.isConsent && <Grid item xs={12}>
                        {/* <Button
                            disableElevation
                            variant="contained"
                            color="primary"
                            style={{ marginTop: '10px', height: '45px', width: '150px', backgroundColor: '#76323F', color: 'white', borderRadius: 0 }}
                            onClick={this.handleSkipVerification}
                        >
                            <Box fontWeight="fontWeightBold" display='inline'>Skip</Box>
                        </Button> */}
                        <Typography align='center' gutterBottom variant="h6" style={{ color: '#76323F' }}>
                            3. Press "VERIFY" button
                        </Typography>
                        <Button
                            disableElevation
                            variant="contained"
                            color="primary"
                            style={{ marginTop: '10px', height: '45px', width: '150px', backgroundColor: '#76323F', color: 'white' }}
                            onClick={this.handleVerifyUser}
                        >
                            <Box fontWeight="fontWeightBold" display='inline'>Verify</Box>
                        </Button>
                        <Typography align='center' gutterBottom variant="body2" style={{ color: '#76323F', marginTop: "20px" }}>
                            {this.state.verifyResult}
                        </Typography>
                        {!(this.state.verifyResult === "The two faces belong to the same person. " || this.state.verifyCount === 0) &&
                            <Typography align='center' gutterBottom variant="body2" style={{ color: '#b2acac', fontWeight: "bold" }}>
                                You have {this.state.verifyCount} chances left for verification
                            </Typography>
                        }
                    </Grid>}
                </Grid>
            </Container >
        )
    }
}

export default compose(
    withStyles(useStyles)
)(VerificationPage);
