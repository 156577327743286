import React, { useEffect } from 'react';

//MUI
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';

import { ThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';

//Components
import AllBlogs from './components/blogsPage/AllBlogs';
import PinnedBlog from './components/blogsPage/PinnedBlog';

//Font
import 'typeface-glacial-indifference';

//Services
import { GetAllBlogs } from '../../../services/blogServices';
import { Button, CardActionArea, CardContent, IconButton, Paper } from '@material-ui/core';

//Others
import { useHistory } from "react-router-dom";
import LoadingAnimation from './components/blogsPage/Pulse-1s-200px.svg';
const axios = require('axios').default;
const FormData = require('form-data');
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

const useStyles = theme => ({
    root: {
        padding: theme.spacing(12, 4),
    }
});

const theme = createMuiTheme({
    typography: {
        fontFamily: [
            'Roboto'
        ].join(','),
    },
});

const BlogsPage = () => {
    const history = useHistory();
    const [blogObj, setBlogObj] = React.useState(undefined);
    const [seeMore, setSeeMore] = React.useState(false);

    useEffect(() => {
        document.body.style.overflow = "auto";
        document.body.style.backgroundColor = "#efefef";
        document.querySelector("body").scrollTo(0, 0);

        getAllBlogs();
    }, []);

    const getAllBlogs = async () => {
        const blogObj = await GetAllBlogs();

        if (blogObj.success) {
            blogObj.res.blogs.sort((a, b) => a.type === "Blogcast" ? -1 : 1)
            setBlogObj(blogObj.res);
        }
    }

    const onClickReadMoreBlog = (id) => {
        history.push(`/blogs/${id}`);
    }

    const onClickSeeMore = () => {
        setSeeMore(true);
        document.querySelector("body").scrollTo(0, 0);
    }

    const onClickSeeLess = () => {
        setSeeMore(false);
        document.querySelector("body").scrollTo(0, 0);
    }

    // const mobileUI = (<Grid item xs={12} style={{ maxWidth: window.innerWidth }}>
    //     <Grid container style={{ marginTop: "50px", marginBottom: "20%" }}>
    //         <Grid item xs={12} style={{ marginBottom: "2%" }}>
    //             <Container>
    //                 {blogObj.pinnedBlogs === undefined ?
    //                     <Skeleton variant="rect" width={"100%"} height={500} style={{ borderRadius: "10px" }} /> :
    //                     <PinnedBlog pinnedBlogs={blogObj.pinnedBlogs !== undefined ? blogObj?.pinnedBlogs[0] : undefined} />
    //                 }
    //             </Container>
    //         </Grid>
    //         <Grid item xs={12}>
    //             <Container>
    //                 {blogObj.blogs === undefined ?
    //                     <Grid container spacing={5}>
    //                         <Grid item xs={12}>
    //                             <Skeleton variant="rect" width={"100%"} height={300} style={{ borderRadius: "25px" }} />
    //                         </Grid>
    //                         <Grid item xs={12}>
    //                             <Skeleton variant="rect" width={"100%"} height={300} style={{ borderRadius: "25px" }} />
    //                         </Grid>
    //                         <Grid item xs={12}>
    //                             <Skeleton variant="rect" width={"100%"} height={300} style={{ borderRadius: "25px" }} />
    //                         </Grid>
    //                     </Grid> :
    //                     <AllBlogs blogsArr={blogObj?.blogs} />
    //                 }
    //             </Container>
    //         </Grid>
    //     </Grid>
    // </Grid>)

    // const webUI = (<Grid container style={{ marginTop: "50px", marginBottom: "20%", maxWidth: window.innerWidth }}>
    //     <Grid item xs={12} style={{ marginBottom: "2%" }}>
    //         <Container>
    //             {blogObj.pinnedBlogs === undefined ?
    //                 <Skeleton variant="rect" width={"100%"} height={500} style={{ borderRadius: "10px" }} /> :
    //                 <PinnedBlog pinnedBlogs={blogObj.pinnedBlogs !== undefined ? blogObj?.pinnedBlogs[0] : undefined} />
    //             }
    //         </Container>
    //     </Grid>
    //     <Grid item xs={12}>
    //         <Container>
    //             {blogObj.blogs === undefined ?
    //                 <Grid container spacing={5}>
    //                     <Grid item xs={12}>
    //                         <Skeleton variant="rect" width={"100%"} height={300} style={{ borderRadius: "25px" }} />
    //                     </Grid>
    //                     <Grid item xs={12}>
    //                         <Skeleton variant="rect" width={"100%"} height={300} style={{ borderRadius: "25px" }} />
    //                     </Grid>
    //                     <Grid item xs={12}>
    //                         <Skeleton variant="rect" width={"100%"} height={300} style={{ borderRadius: "25px" }} />
    //                     </Grid>
    //                 </Grid> :
    //                 <AllBlogs blogsArr={blogObj?.blogs} />
    //             }
    //         </Container>
    //     </Grid>
    // </Grid>)

    const mobileUI = (
        <Grid item xs={12} style={{ maxWidth: window.innerWidth, marginTop: "50px", marginBottom: "30%" }}>
            <Typography variant='h3' style={{ fontWeight: "bold", color: "#991514" }}> Coming Soon! </Typography>
        </Grid>
    )

    const webUI = (
        <Container align='center' style={{ marginTop: "50px", marginBottom: "100px" }}>
            <Grid container spacing={5}>
                <Grid item align="left" xs={12}>
                    <Typography variant="h3" style={{ fontWeight: "bold", color: "#991514" }}>
                        BLOGCASTS
                    </Typography>
                </Grid>
                {!seeMore && <Grid item xs={6}>
                    {blogObj !== undefined ? <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Card>
                                <CardMedia
                                    image={blogObj.pinnedBlogs !== undefined ? blogObj?.pinnedBlogs[0].bigPic : undefined}
                                    title="Image"
                                    style={{ height: 0, paddingTop: '100%', }}
                                />
                            </Card>
                        </Grid>
                        <Grid item align="left" xs={12}>
                            <Typography variant="h4" style={{ fontWeight: "bold", paddingLeft: "20px" }}>
                                {blogObj.pinnedBlogs !== undefined ? blogObj?.pinnedBlogs[0].heading : undefined}
                            </Typography>
                        </Grid>
                        <Grid item align="left" xs={12}>
                            {(blogObj.pinnedBlogs !== undefined ? blogObj?.pinnedBlogs[0].body.slice(0, 600).split("\\n").map((i, index, row) => {
                                if (index + 1 === row.length) {
                                    return <Typography variant="body1" style={{ paddingLeft: "20px", marginBottom: "20px", textAlign: "justify" }}>
                                        <div dangerouslySetInnerHTML={{ __html: `${i}...` }} style={{ lineHeight: 2 }} />
                                    </Typography>
                                } else {
                                    return <Typography variant="body1" style={{ paddingLeft: "20px", marginBottom: "20px", textAlign: "justify" }}>
                                        <div dangerouslySetInnerHTML={{ __html: i }} style={{ lineHeight: 2 }} />
                                    </Typography>
                                }
                            }) : undefined)}
                        </Grid>
                        <Grid item align="right" xs={12}>
                            <Button disableElevation variant="contained" onClick={() => { onClickReadMoreBlog(blogObj.pinnedBlogs !== undefined ? blogObj?.pinnedBlogs[0]._id : undefined) }} style={{ background: "#991514", color: "white", textTransform: "none" }}>
                                Read more
                            </Button>
                        </Grid>
                    </Grid> : <img src={LoadingAnimation} alt="Loading..." style={{ width: "100px" }} />}
                </Grid>}
                {!seeMore && <Grid item xs={6}>
                    {blogObj !== undefined ? <Grid container spacing={5}>
                        {blogObj?.blogs.slice(0, 4).map((blog) => {
                            return (
                                <Grid item xs={12}>
                                    <Card elevation={0} onClick={() => { onClickReadMoreBlog(blog._id) }} style={{ backgroundColor: "#efefef" }}>
                                        <CardActionArea>
                                            <CardContent>
                                                <Grid container spacing={2}>
                                                    <Grid item xs>
                                                        <Card square >
                                                            <CardMedia
                                                                image={blog.smallPic}
                                                                title="Image"
                                                                style={{ paddingTop: '100%', }}
                                                            />
                                                        </Card>
                                                    </Grid>
                                                    <Grid item align="left" xs style={{ paddingTop: "5px" }}>
                                                        <Typography variant="h6" style={{ fontWeight: 600, marginBottom: "10px", lineHeight: 1.2 }}>
                                                            {blog.heading}
                                                        </Typography>
                                                        {blog.type === 'Blogcast' ? (blog.body.slice(0, 330).split("\\n").map((i, index, row) => {
                                                            if (index + 1 === row.length) {
                                                                return <Typography variant="body2" style={{ textAlign: "justify" }}>
                                                                    <div dangerouslySetInnerHTML={{ __html: `${i}...` }} style={{ lineHeight: 1.4 }} />
                                                                </Typography>
                                                            } else {
                                                                return <Typography variant="body2" style={{ marginBottom: "10px", textAlign: "justify" }}>
                                                                    <div dangerouslySetInnerHTML={{ __html: i }} style={{ lineHeight: 1.4 }} />
                                                                </Typography>
                                                            }
                                                        })) : (blog.body.slice(0, 200).split("\\n").map((i, index, row) => {
                                                            if (index + 1 === row.length) {
                                                                return <Typography variant="body2" style={{ textAlign: "justify" }}>
                                                                    {i}...
                                                                </Typography>
                                                            } else {
                                                                return <Typography variant="body2" style={{ marginBottom: "10px", textAlign: "justify" }}>
                                                                    {i}
                                                                </Typography>
                                                            }
                                                        }))}
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Grid>
                            )
                        })}
                        <Grid item align="center" xs={12}>
                            <Button disableElevation variant="outlined" onClick={onClickSeeMore} style={{ textTransform: "none", width: "95%" }}>
                                <Typography variant="h6" style={{ color: "grey" }}>
                                    See more
                                </Typography>
                            </Button>
                        </Grid>
                    </Grid> : <img src={LoadingAnimation} alt="Loading..." style={{ width: "100px" }} />}
                </Grid>}


                <Grid item xs={12}>
                    {(seeMore && blogObj !== undefined) && <Grid container spacing={3}>
                        <Grid item xs>
                            <Card square >
                                <CardMedia
                                    image={blogObj.pinnedBlogs !== undefined ? blogObj?.pinnedBlogs[0].bigPic : undefined}
                                    title="Image"
                                    style={{ height: 0, paddingTop: '100%', }}
                                />
                            </Card>
                        </Grid>
                        <Grid item align="left" xs>
                            <Typography variant="h4" style={{ fontWeight: "bold", paddingLeft: "20px" }}>
                                {blogObj?.pinnedBlogs !== undefined ? blogObj?.pinnedBlogs[0].heading : undefined}
                            </Typography>
                            {(blogObj.pinnedBlogs !== undefined ? blogObj?.pinnedBlogs[0].body.slice(0, 600).split("\\n").map((i, index, row) => {
                                if (index + 1 === row.length) {
                                    return <Typography variant="body1" style={{ paddingLeft: "20px", marginBottom: "20px", textAlign: "justify" }}>
                                        <div dangerouslySetInnerHTML={{ __html: `${i}...` }} style={{ lineHeight: 2 }} />
                                    </Typography>
                                } else {
                                    return <Typography variant="body1" style={{ paddingLeft: "20px", marginBottom: "20px", textAlign: "justify" }}>
                                        <div dangerouslySetInnerHTML={{ __html: i }} style={{ lineHeight: 2 }} />
                                    </Typography>
                                }
                            }) : undefined)}
                        </Grid>
                        <Grid item align="right" xs={12}>
                            <Button disableElevation variant="contained" onClick={() => { onClickReadMoreBlog(blogObj?.pinnedBlogs !== undefined ? blogObj?.pinnedBlogs[0]._id : undefined) }} style={{ background: "#991514", color: "white", textTransform: "none" }}>
                                Read more
                            </Button>
                        </Grid>
                    </Grid>}
                </Grid>
                {(seeMore && blogObj !== undefined) && blogObj?.blogs.map((blog) => {
                    return (
                        <Grid item align="left" xs={12}>
                            <Card elevation={0} onClick={() => { onClickReadMoreBlog(blog._id) }} style={{ backgroundColor: "#efefef" }}>
                                <CardActionArea>
                                    <CardContent>
                                        <Grid container spacing={2} style={{ width: "75%", padding: "10px" }}>
                                            <Grid item xs={5}>
                                                <Card square >
                                                    <CardMedia
                                                        image={blog.smallPic}
                                                        title="Image"
                                                        style={{ paddingTop: '100%', }}
                                                    />
                                                </Card>
                                            </Grid>
                                            <Grid item align="left" xs style={{ paddingTop: "5px" }}>
                                                <Typography variant="h6" style={{ fontWeight: 600, marginBottom: "10px", lineHeight: 1.2 }}>
                                                    {blog.heading}
                                                </Typography>
                                                {blog.type === 'Blogcast' ? (blog.body.slice(0, 700).split("\\n").map((i, index, row) => {
                                                    if (index + 1 === row.length) {
                                                        return <Typography variant="body2" style={{ textAlign: "justify" }}>
                                                            <div dangerouslySetInnerHTML={{ __html: `${i}...` }} style={{ lineHeight: 1.4 }} />
                                                        </Typography>
                                                    } else {
                                                        return <Typography variant="body2" style={{ marginBottom: "10px", textAlign: "justify" }}>
                                                            <div dangerouslySetInnerHTML={{ __html: i }} style={{ lineHeight: 1.4 }} />
                                                        </Typography>
                                                    }
                                                })) : (blog.body.slice(0, 200).split("\\n").map((i, index, row) => {
                                                    if (index + 1 === row.length) {
                                                        return <Typography variant="body2" style={{ textAlign: "justify" }}>
                                                            {i}...
                                                        </Typography>
                                                    } else {
                                                        return <Typography variant="body2" style={{ marginBottom: "10px", textAlign: "justify" }}>
                                                            {i}
                                                        </Typography>
                                                    }
                                                }))}
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    )
                })}
                {seeMore && <Grid item align="center" xs={12}>
                    <Button disableElevation variant="outlined" onClick={onClickSeeLess} style={{ textTransform: "none", width: "95%" }}>
                        <Typography variant="h6" style={{ color: "grey" }}>
                            See less
                        </Typography>
                    </Button>
                </Grid>}
            </Grid>
        </Container>
    )

    return <ThemeProvider theme={theme}>
        <Hidden mdUp>
            {mobileUI}
        </Hidden>
        <Hidden smDown>
            {webUI}
        </Hidden>
    </ThemeProvider>
};

export default compose(
    withStyles(useStyles)
)(BlogsPage);
