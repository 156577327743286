// src/JsonDisplay.js
import React from "react";
import {
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
} from "@material-ui/core";
import { SplitPascalCaseToString } from "../../../../functions/testReportFuntions";

const Answers = ({ data }) => {
  return (
    <TableContainer align="left" component={Paper} style={{ marginTop: 20 }}>
      <Typography
        variant="h6"
        gutterBottom
        style={{ padding: "16px", paddingBottom: 0 }}
      >
        {`Test ID: ${data.test_id}`}
      </Typography>
      <Typography
        variant="h6"
        gutterBottom
        style={{ padding: "16px", paddingTop: 0 }}
      >
        {`Username: ${data.username} (${data.test_type})`}
      </Typography>
      {data.answerObj?.parts?.map((part, partIndex) => (
        <div key={partIndex}>
          <Typography
            variant="h6"
            gutterBottom
            style={{ paddingLeft: "16px", paddingTop: 50 }}
          >
            {`${part.part_type} - Part ${part.part}`}
          </Typography>
          {part.sections?.map((section, sectionIndex) => (
            <div key={sectionIndex}>
              <Typography
                variant="subtitle1"
                style={{ paddingLeft: "16px", paddingTop: 30 }}
              >
                {`Section ID: ${section.section_id}`}
              </Typography>
              <Typography variant="subtitle1" style={{ paddingLeft: "16px" }}>
                {`Question type: ${SplitPascalCaseToString(
                  section.question_type
                )}`}
              </Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Question Number</TableCell>
                    <TableCell>Answer</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {section.answers &&
                    Object.entries(section.answers).map(
                      ([key, value], index) => (
                        <TableRow key={index}>
                          <TableCell>{key}</TableCell>
                          <TableCell>
                            {Array.isArray(value) ? value.join(", ") : value}
                          </TableCell>
                        </TableRow>
                      )
                    )}
                </TableBody>
              </Table>
            </div>
          ))}
        </div>
      ))}
      <div>
        <Typography
          variant="h6"
          gutterBottom
          style={{ paddingLeft: "16px", paddingTop: 50 }}
        >
          {`WRITING`}
        </Typography>
        <div>
          <Typography
            variant="subtitle1"
            style={{ paddingLeft: "16px", paddingTop: 30 }}
          >
            {`Section ID: 5b70cba4-bbc5-462c-bbce-5619f8a6cfe2`}
          </Typography>
          <Typography variant="subtitle1" style={{ paddingLeft: "16px" }}>
            {`Question type: ${SplitPascalCaseToString("writingBaseQuestion")}`}
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Question Number</TableCell>
                <TableCell>Answer</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.stateObj?.answers ? (
                data.stateObj?.answers?.map((answer, ansIndex) => (
                  <TableRow key={ansIndex}>
                    <TableCell>{ansIndex + 1}</TableCell>
                    <TableCell>{answer}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell>-</TableCell>
                  <TableCell>-</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </div>
    </TableContainer>
  );
};

export default Answers;
