import React from 'react';

//MUI
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
//import Slider from '@mui/material/Slider';
import Slider from '@material-ui/core/Slider';

import { ThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';

//Icons
import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecordRounded';

//Components

//Font
import 'typeface-glacial-indifference';

//Others
import Chart from "react-google-charts";
const axios = require('axios').default;
const FormData = require('form-data');
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

const useStyles = theme => ({
    root: {
        padding: theme.spacing(12, 4),
    }
});

const theme = createMuiTheme({
    typography: {
        fontFamily: [
            'Roboto'
        ].join(','),
    },
});

const scale = [
    {
        value: 0,
        label: '0%'
    },
    {
        value: 40,
        label: '40%',
    },
    {
        value: 50,
        label: '50%'
    },
    {
        value: 60,
        label: '60%',
    },
    {
        value: 80,
        label: '80%'
    },
];

function valuetext(value) {
    return `${value}%`;
}

const SpectrumChart = (props) => {
    const mobileUI = (<Grid container>
        <Grid item>
            <Button style={{ borderBottom: "5px solid #43637c", borderRadius: "0px", fontSize: "1rem" }}>
                Overall Band Score
            </Button>
        </Grid>
        <Grid item>
            <Button disabled style={{ fontSize: "1rem" }}>
                Skill Analysis Section
            </Button>
        </Grid>
        <Grid item xs={12}>
            <Divider />
        </Grid>
        <Grid item align='center' xs={12}>
            <Grid container>
                <Grid item xs={12} style={{ paddingTop: '50px' }}>
                    <Grid container spacing={2} justify="center">
                        <Grid item>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexWrap: 'wrap'
                            }}>
                                <FiberManualRecordRoundedIcon style={{ color: "#636363", marginRight: '5px' }} />
                                <Typography variant="subtitle1" style={{ color: "black" }}>
                                    AVG Users
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexWrap: 'wrap'
                            }}>
                                <FiberManualRecordRoundedIcon style={{ color: "#a6a6a6", marginRight: '5px' }} />
                                <Typography variant="subtitle1" style={{ color: "black" }}>
                                    AVG Test takers
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexWrap: 'wrap'
                            }}>
                                <FiberManualRecordRoundedIcon style={{ color: "#880000", marginRight: '5px' }} />
                                <Typography variant="subtitle1" style={{ color: "black" }}>
                                    You
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Chart
                    chartType="ColumnChart"
                    width="100%"
                    height="400px"
                    data={[
                        ['Category', 'Avg Users', 'Avg Test Takers', 'You'],
                        ['Listening',
                            6.5,
                            7,
                            props.reportObj.test_type === "IELTS LISTENING FREE" ? props.bandScoreObj?.score : 0],
                        ['Reading',
                            6,
                            6.5,
                            props.reportObj.test_type === "IELTS LISTENING FREE" ? 0 : props.bandScoreObj?.score],
                        ['Writing',
                            5,
                            6,
                            0],
                        ['Speaking',
                            5.5,
                            6.5,
                            0]
                    ]}
                    options={{
                        // Material design options
                        chartArea: { width: '90%' },
                        colors: ['#636363', '#a6a6a6', '#880000'],
                        title: 'Overall Band Score Comparison',
                        height: 400,
                        backgroundColor: "#efefef"
                    }}
                />
            </Grid>
        </Grid>
    </Grid>)

    const webUI = (<Grid container style={{ paddingBottom: '200px' }}>
        <Grid item>
            <Button style={{ borderBottom: "5px solid #43637c", borderRadius: "0px", fontSize: "1rem" }}>
                Spectrum chart
            </Button>
        </Grid>
        <Grid item>
            <Button disabled style={{ fontSize: "1rem" }}>
                Skill Analysis Section
            </Button>
        </Grid>
        <Grid item xs={12}>
            <Divider />
        </Grid>
        <Grid item align='center' xs={12}>
            <Grid container>
                <Grid item xs={12} style={{ paddingTop: '50px' }}>
                    <Typography variant="h6" style={{ color: "black", fontWeight: 'bold' }}>
                        Overall Performance
                    </Typography>
                </Grid>
                <Grid item xs={12} style={{ paddingTop: '30px' }}>
                    <Grid container spacing={4} justify='center'>
                        <Grid item>
                            <Typography variant="subtitle1" style={{ color: "black", fontWeight: 'bold' }}>
                                Section 1
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Slider
                                disabled
                                aria-label="Custom marks"
                                defaultValue={Math.round((props.reportObj?.test_result?.parts[0]?.score / props.reportObj?.test_result?.parts[0]?.question) * 100,)}
                                getAriaValueText={valuetext}
                                step={1}
                                valueLabelDisplay="auto"
                                marks={scale}
                                style={{ width: "500px", color: "#e1e1e1", background: "linear-gradient(90deg, rgba(255,0,0,1) 0%, rgba(254,253,0,1) 50%, rgba(0,191,46,1) 100%)" }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{ paddingTop: '10px' }}>
                    <Grid container spacing={4} justify='center'>
                        <Grid item>
                            <Typography variant="subtitle1" style={{ color: "black", fontWeight: 'bold' }}>
                                Section 2
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Slider
                                disabled
                                aria-label="Custom marks"
                                defaultValue={Math.round((props.reportObj?.test_result?.parts[1]?.score / props.reportObj?.test_result?.parts[1]?.question) * 100,)}
                                getAriaValueText={valuetext}
                                step={1}
                                valueLabelDisplay="auto"
                                marks={scale}
                                style={{ width: "500px", color: "#e1e1e1", background: "linear-gradient(90deg, rgba(255,0,0,1) 0%, rgba(254,253,0,1) 50%, rgba(0,191,46,1) 100%)" }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{ paddingTop: '10px' }}>
                    <Grid container spacing={4} justify='center'>
                        <Grid item>
                            <Typography variant="subtitle1" style={{ color: "black", fontWeight: 'bold' }}>
                                Section 3
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Slider
                                disabled
                                aria-label="Custom marks"
                                defaultValue={0}
                                getAriaValueText={valuetext}
                                step={1}
                                valueLabelDisplay="auto"
                                marks={scale}
                                style={{ width: "500px", color: "#e1e1e1", background: "linear-gradient(90deg, rgba(255,0,0,1) 0%, rgba(254,253,0,1) 50%, rgba(0,191,46,1) 100%)" }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </Grid>)

    return <ThemeProvider theme={theme}>
        <Hidden mdUp>
            {mobileUI}
        </Hidden>
        <Hidden smDown>
            {webUI}
        </Hidden>
    </ThemeProvider>
};

export default compose(
    withStyles(useStyles)
)(SpectrumChart);