import React from 'react';

//Material UI
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import MuiAlert from '@material-ui/lab/Alert';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

//Question type
import YesNoNG from './AddSections/YesNoNG'
import TrueFalseNG from './AddSections/TrueFalseNG';
import MultipleChoice from './AddSections/MultipleChoice';
import NoteCompletion from './AddSections/NoteCompletion';
import SentenceCompletion from './AddSections/SentenceCompletion';
import ListOfHeading from './AddSections/ListOfHeading';
import MatchingSentenceEndings from './AddSections/MatchingSentenceEndings';
import CompleteTheNotes from './AddSections/CompleteTheNotes';
import CompleteTheTables from './AddSections/CompleteTheTables'
import Matching from './AddSections/Matching';
import Map from './AddSections/Map';
import Choose2Letters from './AddSections/Choose2Letters';
import WritingBaseQuestion from './AddSections/WritingBaseQuestion';

//Icons
import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';

//Models
import { CreateNewSection } from '../../../../models/addSection';

//Others
import { withStyles } from '@material-ui/core/styles';
import { Component } from 'react';
import { compose } from 'recompose';
import Select from 'react-select'

const axios = require('axios').default;
const FormData = require('form-data');
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

const useStyles = theme => ({
    root: {
        margin: 15,
        marginTop: 50
    },
    margin: {
        margin: theme.spacing(1),
    },
    buttonColor: {
        backgroundColor: '#76323F',
        color: 'white'
    }
});

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class AddSections extends Component {
    constructor() {
        super()
        this.state = {
            addedQuestionArr: [],
            underConstructionQuestions: ['completeSummary', 'matchingFeatures', 'completeTheTables'],
            mapChoiceToShow: [],
            isCreated: false,
            isLoading: false,
            isLoadingQuestionType: false,
            isHideAddButton: false
        };
    }

    componentDidMount = async () => {
        document.querySelector("body").scrollTo(0, 0);
    }

    //==================================== PREPARATION =======================================
    handleSelectTestType = async (event) => {
        if (event === null) {
            this.setState({
                testType: undefined
            });
        } else if (event !== null) {
            await this.setState({
                testType: event.value.toUpperCase()
            });

            this.handleGetQuestionTypes();
        }
    }

    handleSelectPartType = async (event) => {
        if (event === null) {
            this.setState({
                partType: undefined,

            });
        } else if (event !== null) {
            await this.setState({
                partType: event.value.toUpperCase()
            });

            this.handleGetQuestionTypes();
        }
    }

    handleGetQuestionTypes = async () => {
        const questionOption = []

        if (this.state.testType !== undefined && this.state.partType !== undefined) {
            try {
                this.handleLoadingQuestionType();

                const res = await axios({
                    method: 'get',
                    url: api_base_url + '/api/test/section/' + this.state.partType,
                    headers: {
                        "Authorization": "Bearer " + JSON.parse(localStorage.getItem('user')).token
                    }
                });

                await res.data.questionType.map((type) => {
                    questionOption.push({
                        value: type,
                        label: this.handleConvertQuestionType(type)
                    })
                })

                this.setState({
                    questionDetails: res.data,
                    questionOption: questionOption
                });

                this.handleEndLoadingQuestionType();
            } catch (err) {
                console.log(err);
                this.handleEndLoadingQuestionType();
            }
        }
    }

    handleLoadingQuestionType = () => {
        this.setState({
            isLoadingQuestionType: true
        });
    }

    handleEndLoadingQuestionType = () => {
        this.setState({
            isLoadingQuestionType: false
        });
    }
    //==================================== PREPARATION =======================================

    //==================================== ADD QUESTION ======================================
    handleEditSectionDescription = (event) => {
        this.setState({
            sectionDescription: event.target.value
        });
    }

    handleEditVersion = (event) => {
        this.setState({
            version: event.target.value
        });
    }

    handleSelectQuestionType = async (event) => {
        if (event === null) {
            await this.setState({
                questionType: undefined,
                addedQuestionArr: [],
                isHideAddButton: false
            });
        } else if (event !== null) {
            await this.setState({
                questionType: event.value,
                isHideAddButton: false
            });
        }
    }

    handleAddQuestionUI = (question, index) => {
        if (this.state.questionType === 'yesNoNG') {
            return (<YesNoNG key={`yesnong-${index}`} questionObj={question} />)
        } if (this.state.questionType === 'trueFalseNG') {
            return (<TrueFalseNG key={`truefalseng-${index}`} questionObj={question} />)
        } if (this.state.questionType === 'multipleChoice') {
            return (<MultipleChoice key={`multiplechoice-${index}`} questionObj={question} />)
        } if (this.state.questionType === 'noteCompletion') {
            return (<NoteCompletion key={`notecompletion-${index}`} questionObj={question} />)
        } if (this.state.questionType === 'sentenceCompletion') {
            return (<SentenceCompletion key={`sentencecompletion-${index}`} questionObj={question} />)
        } if (this.state.questionType === 'listOfHeading') {
            return (<ListOfHeading key={`listofheading-${index}`} questionObj={question} qIdx={index} />)
        } if (this.state.questionType === 'matchingSentenceEndings') {
            return (<MatchingSentenceEndings key={`matchsentenceendings-${index}`} questionObj={question} />)
        } if (this.state.questionType === 'completeTheNotes') {
            return (<CompleteTheNotes key={`completethenotes-${index}`} questionObj={question} />)
        } if (this.state.questionType === 'completeTheTables') {
            return (<CompleteTheTables key={`completethetables-${index}`} questionObj={question} />)
        } if (this.state.questionType === 'matching') {
            return (<Matching key={`matching-${index}`} questionObj={question} />)
        } if (this.state.questionType === 'map') {
            return (<Map key={`map-${index}`} questionObj={question} qIdx={index} mapChoiceToShow={this.state.mapChoiceToShow} handleEditChoiceForMap={this.handleEditChoiceForMap} />)
        } if (this.state.questionType === 'choose2Letters') {
            return (<Choose2Letters key={`choose2letters-${index}`} questionObj={question} />)
        } if (this.state.questionType === 'writingBaseQuestion') {
            return (<WritingBaseQuestion key={`writingbasequestion-${index}`} questionObj={question} />)
        }
    }

    handleAddNewQuestion = async () => {
        let questionObj = await this.state.questionDetails.initial.find((question) => question[this.state.questionType])
        const tempQuestionOption = await JSON.parse(JSON.stringify(questionObj[this.state.questionType]));
        
        console.log(this.state.addedQuestionArr);

        if (this.state.questionType === 'listOfHeading' && this.state.addedQuestionArr.length !== 0) {
            tempQuestionOption.choice = this.state.addedQuestionArr[0].choice;
            this.state.addedQuestionArr.push(tempQuestionOption);
        } else if (this.state.questionType === 'map' && this.state.addedQuestionArr.length !== 0) {
            tempQuestionOption.voice = this.state.addedQuestionArr[0].voice;
            tempQuestionOption.question = this.state.addedQuestionArr[0].question;
            tempQuestionOption.choice = this.state.addedQuestionArr[0].choice;
            tempQuestionOption.image = this.state.addedQuestionArr[0].image;
            
            this.state.addedQuestionArr.push(tempQuestionOption);
        } else if (this.state.questionType === 'matchingSentenceEndings') {
            this.setState({
                isHideAddButton: true
            });
            this.state.addedQuestionArr.push(tempQuestionOption);
        } else {
            this.state.addedQuestionArr.push(tempQuestionOption);
        }

        this.setState({
            addedQuestionArr: this.state.addedQuestionArr
        });
    }

    handleDeleteSection = async (index) => {
        await this.state.addedQuestionArr.splice(index, 1);

        this.setState({
            addedQuestionArr: this.state.addedQuestionArr
        })
    }
    //==================================== ADD QUESTION ======================================

    handleCreateNewSection = async () => {
        console.log(this.state.addedQuestionArr);
        const result = await CreateNewSection({
            section: this.state.sectionDescription === undefined ? "" : this.state.sectionDescription,
            question_type: this.state.questionType,
            questions: this.state.addedQuestionArr
        });

        console.log(result);

        if (result.success) {
            this.setState({
                isCreated: true,
                questionType: undefined,
                addedQuestionArr: []
            });

            this.props.handleSelectMenu('allQuestions');
        }
    }

    //==================================== QUESTION TEXT FORMAT ====================================
    handleConvertQuestionType = (question) => {
        if (question === "trueFalseNG") {
            return "True/False/Not given"
        } if (question === "yesNoNG") {
            return "Yes/No/Not given"
        } if (question === "choose2Letters") {
            return "Choose 2 Letters"
        }

        const result = question.replace(/([A-Z])/g, " $1");
        const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
        return finalResult
    }
    //==================================== QUESTION TEXT FORMAT ====================================

    //==================================== HELPER ==================================================
    handleEditChoiceForMap = async (choiceArr) => {
        let tempArr = [];
        await choiceArr.map((choice, index) => {
            tempArr.push({
                label: choice,
                value: choice
            });
        });

        this.setState({
            mapChoiceToShow: tempArr
        });
    }
    //==================================== HELPER ==================================================

    render() {
        const { classes } = this.props;

        return (
            <Grid item xs={12}>
                <Paper>
                    <Grid container spacing={2} style={{ padding: '20px' }}>
                        <Grid item align='left' xs={12}>
                            <Typography variant="h4" style={{ fontWeight: 'bold' }} gutterBottom>
                                Add new question
                            </Typography>
                        </Grid>

                        <Grid item align='left' xs={12}>
                            <Typography variant="h5" style={{ fontWeight: 'bold' }} gutterBottom>
                                Question description
                            </Typography>
                            <TextField
                                id="outlined-basic-title"
                                variant="outlined"
                                size="small"
                                onChange={this.handleEditSectionDescription}
                                fullWidth
                            />
                        </Grid>
                        <Grid item align='left' xs={12}>
                            <Typography variant="h5" style={{ fontWeight: 'bold' }} gutterBottom>
                                Version
                            </Typography>
                            <TextField
                                id="outlined-basic-title"
                                variant="outlined"
                                size="small"
                                onChange={this.handleEditVersion}
                                fullWidth
                            />
                        </Grid>

                        <Grid item align='left' xs={12}>
                            <Typography variant="h5" style={{ fontWeight: 'bold' }} gutterBottom>
                                Test type
                            </Typography>
                        </Grid>
                        <Grid item align='left' xs={6}>
                            <Select
                                name="testType"
                                isSearchable={true}
                                isClearable={true}
                                options={[
                                    { value: 'IELTS', label: 'IELTS' }
                                ]}
                                onChange={this.handleSelectTestType}
                            />
                        </Grid>

                        <Grid item align='left' xs={12}>
                            <Typography variant="h5" style={{ fontWeight: 'bold' }} gutterBottom>
                                Section type
                            </Typography>
                        </Grid>
                        <Grid item align='left' xs={6}>
                            <Select
                                name="partType"
                                isSearchable={true}
                                isClearable={true}
                                options={[
                                    { value: 'reading', label: 'Reading' },
                                    { value: 'listening', label: 'Listening' },
                                    { value: 'writing', label: 'Writing' }
                                ]}
                                onChange={this.handleSelectPartType}
                            />
                        </Grid>

                        <Grid item align='left' xs={12}>
                            <Typography variant="h5" style={{ fontWeight: 'bold' }} gutterBottom>
                                Question type
                            </Typography>
                        </Grid>
                        <Grid item align='left' xs={6}>
                            <Select
                                name="questionType"
                                isSearchable={true}
                                isClearable={true}
                                isLoading={this.state.isLoadingQuestionType}
                                options={this.state.questionDetails === undefined ? [] : this.state.questionOption}
                                onChange={this.handleSelectQuestionType}
                            />
                        </Grid>

                        <Grid item align='left' xs={12}>
                            <Typography variant="h5" style={{ fontWeight: 'bold' }} gutterBottom>
                                Add questions
                            </Typography>
                        </Grid>
                        <Grid item align='left' xs={12}>
                            {this.state.addedQuestionArr.map((question, index) =>
                                <Grid key={`added-question-${index}`} container spacing={1}>
                                    <Grid item align='left' xs={11}>
                                        {this.handleAddQuestionUI(question, index)}
                                    </Grid>
                                    <Grid item xs={1}>
                                        <IconButton aria-label="delete" onClick={() => { this.handleDeleteSection(index) }} >
                                            <DeleteForeverRoundedIcon fontSize="small" />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                        {!this.state.isHideAddButton && !(this.state.underConstructionQuestions.includes(this.state.questionType)) && 
                            <Grid item align='left' xs={12}>
                                <IconButton disabled={this.state.questionDetails === undefined} aria-label="add" onClick={this.handleAddNewQuestion}>
                                    <AddBoxRoundedIcon fontSize="small" />
                                </IconButton>
                            </Grid>
                        }

                        {!this.state.isHideAddButton && this.state.underConstructionQuestions.includes(this.state.questionType) && 
                            <Grid item align='left' xs={12}>
                                <Button
                                    disabled
                                    variant="contained"
                                    style={{ color: 'black', backgroundColor: '#fece00' }}
                                >
                                    This question type is <span style={{ fontWeight: 'bold', marginLeft: '4px', textDecoration: 'underline' }}>under construction!</span>
                                </Button>
                            </Grid>
                        }

                        <Grid item align='right' xs={12} style={{ marginTop: '50px' }}>
                            <Button
                                variant="contained"
                                onClick={this.handleCreateNewSection}
                                style={{ color: 'white', backgroundColor: '#76323F' }}
                            >
                                Create
                            </Button>
                            {this.state.isCreated &&
                                <Typography variant="h5" style={{ color: 'green', marginTop: '10px' }}>
                                    Question created!
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        )
    }
}
export default compose(
    withStyles(useStyles)
)(AddSections);