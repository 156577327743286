//React
import { Component } from 'react';
import { compose } from 'recompose';

//Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

//react-router-dom

//Others
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import Select from 'react-select';
import { GetAllTeachers } from '../../../../services/teacherServices';
import PageBackdrop from '../../../main/PageBackdrop';

const axios = require('axios').default;

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    }
});

class ScheduleManagement extends Component {
    constructor() {
        super();
        this.state = {
            events: [],
            availableScheduleArr: [],
            displaySchedule: [],
            isEventLoaded: false,
            isLoading: false,
            isTeacherEmpty: false
        };
    }

    componentDidMount() {
        let draggableEl = document.getElementById("external-events");
        new Draggable(draggableEl, {
            itemSelector: ".fc-event",
            eventData: function (eventEl) {
                let title = eventEl.getAttribute("title");
                let id = eventEl.getAttribute("data");
                let duration = eventEl.getAttribute("duration");
                return {
                    title: title,
                    id: id,
                    duration: duration
                };
            }
        });
        this.handleGetAllTeachers();
        this.handleGetAllAvailableSchedule();
    }

    handleGetAllAvailableSchedule = async () => {
        try {
            const res = await axios({
                method: 'get',
                url: 'https://steep-legend-midnight.glitch.me/admin/schedule'
            });
            let scheduleArr = [];


            const resData = res.data;
            await resData.map(schedule => {
                if (schedule.submitter.username) {
                    scheduleArr.push({
                        id: schedule._id,
                        title: `Scheduled (${schedule.submitter.name.split(' ')[0]} ${schedule.submitter.name.split(' ')[1][0]})`,
                        start: schedule.schedule.start,
                        end: schedule.schedule.end,
                        overlap: false,
                        editable: false,
                        submitter: schedule.submitter
                    });
                }
            });

            if (this.state.selectedTeacher !== undefined) {

                this.setState({
                    events: scheduleArr,
                    displaySchedule: scheduleArr.filter((event) => event.submitter.username && (event.submitter.username === this.state.selectedTeacher.username)),
                    isEventLoaded: true
                });
            } else {
                this.setState({
                    events: scheduleArr,
                    displaySchedule: scheduleArr,
                    isEventLoaded: true
                });
            }
        } catch (err) {
            console.log(err);
        }
    }

    handleAddAvailableSchedule = async () => {
        if (this.state.selectedTeacher === undefined) {
            this.setState({
                isTeacherEmpty: true
            });

            return;
        }

        try {
            this.handleOpenLoading();
            let bodyArr = []
            await this.state.availableScheduleArr.map(schedule => {
                bodyArr.push({
                    schedule: {
                        start: schedule.start.toJSON(),
                        end: schedule.end.toJSON()
                    },
                    submitter: this.state.selectedTeacher
                })
            })

            const res = await axios({
                method: 'post',
                url: 'https://steep-legend-midnight.glitch.me/admin/schedule',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: {
                    availableScheduleArr: bodyArr
                }
            });

            const resData = res.data.data;
            this.setState({
                schedules: resData,
                availableScheduleArr: []
            });
            await this.handleGetAllAvailableSchedule();
            this.state.droppedEvent.event.remove();
            this.handleCloseLoading();

        } catch (err) {
            console.log(err);
            this.handleCloseLoading();
        }
    }

    handleFilterScheduleByTeacher = async (username) => {
        console.log(this.state.events);
        console.log(username);
        this.setState({
            displaySchedule: this.state.events.filter((event) => event.submitter.username && (event.submitter.username === username))
        })
    }

    handleGetAllTeachers = async () => {
        const res = await GetAllTeachers();

        if (res.success) {
            let tempArr = [];
            await res.res.teachers.map((teacher) => {
                tempArr.push({
                    value: {
                        name: `${teacher.firstname} ${teacher.lastname}`,
                        username: teacher.username
                    },
                    label: `${teacher.firstname} ${teacher.lastname}`
                })
            })
            this.setState({
                teachersArr: tempArr
            });
        }
    }

    handleSelectTeacher = (event) => {
        if (event === null) {
            this.setState({
                selectedTeacher: undefined,
                displaySchedule: this.state.events
            });
        } if (event !== null) {
            this.setState({
                selectedTeacher: event.value
            });

            this.handleFilterScheduleByTeacher(event.value.username);
        }
    };

    handleChange = (event) => {
        const name = event.target.name;

        this.setState({
            ...this.state,
            [name]: event.target.value
        });
    };

    handleCloseLoading = () => {
        this.setState({
            isLoading: false
        });
    }

    handleOpenLoading = () => {
        this.setState({
            isLoading: true
        });
    }

    render() {
        const { classes } = this.props;

        return (
            <Grid item xs={12} sm={9}>
                <PageBackdrop isLoading={this.state.isLoading} />
                <Grid container spacing={2}>
                    <Grid item xs={12} style={{ zIndex: 1 }}>
                        <Grid container>
                            <Grid item xs={6}>
                                <Typography align='left' variant="h5" gutterBottom>
                                    <Box fontWeight="fontWeightBold" display='inline'>Schedule Management</Box>
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Select
                                    isClearable
                                    isSearchable
                                    options={this.state.teachersArr}
                                    isLoading={this.state.teachersArr === undefined}
                                    onChange={this.handleSelectTeacher}
                                    placeholder="Please Select Teacher..."
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item align='center' xs={12} style={{ zIndex: 0 }}>
                        <Grid container>
                            <Grid item xs={10}>
                                <Paper variant="outlined">
                                    {this.state.isEventLoaded ? (<Grid item xs={12} style={{ padding: '20px' }}>
                                        <FullCalendar
                                            plugins={[timeGridPlugin, interactionPlugin]}
                                            defaultView="timeGridWeek"
                                            timeZone="Asia/Bangkok"
                                            //hiddenDays={[0, 6]}
                                            header={{
                                                left: "prev,next today",
                                                center: "title"
                                            }}
                                            rerenderDelay={10}
                                            eventDurationEditable={false}
                                            //businessHours={true}
                                            editable={true}
                                            droppable={true}
                                            events={this.state.displaySchedule}
                                            eventDrop={(event) => {
                                                let targetIndex = this.state.availableScheduleArr.findIndex((obj => obj.id === event.event._instance.defId));
                                                this.state.availableScheduleArr[targetIndex].start = event.event._instance.range.start;
                                                this.state.availableScheduleArr[targetIndex].end = event.event._instance.range.end;
                                                this.setState({
                                                    droppedEvent: event
                                                });

                                                console.log(this.state.availableScheduleArr);

                                            }}
                                            eventReceive={(event) => {
                                                this.state.availableScheduleArr.push({
                                                    id: event.event._instance.defId,
                                                    start: event.event._instance.range.start,
                                                    end: event.event._instance.range.end
                                                });

                                                console.log(this.state.availableScheduleArr);

                                                this.setState({
                                                    droppedEvent: event
                                                });
                                            }}
                                        />
                                    </Grid>) : (<Grid item xs={12} style={{ padding: '20px', marginTop: '40%', marginBottom: '40%' }}>
                                        <CircularProgress />
                                    </Grid>)}
                                </Paper>
                            </Grid>

                            <Grid item xs={2}>
                                <Grid item align='center' xs={12} style={{ padding: '20px' }}>
                                    <div
                                        id="external-events"
                                        style={{
                                            padding: "10px",
                                            width: "80%",
                                            height: "auto",
                                            maxHeight: "-webkit-fill-available"
                                        }}
                                    >
                                        {!this.state.selectedTeacher && <Typography align='center' variant="body1" gutterBottom>
                                            <strong>Please select teacher..</strong>
                                        </Typography>}
                                        {this.state.selectedTeacher && <Typography align='center' variant="body1" gutterBottom>
                                            <strong>Drag and drop available schedule</strong>
                                        </Typography>}
                                        {this.state.selectedTeacher && <Grid container>
                                            <Grid xs={12}>
                                                <Button
                                                    className="fc-event"
                                                    title={"New"}
                                                    data={"1"}
                                                    key={"1"}
                                                    duration={'00:30'}
                                                    style={{ backgroundColor: "#76323F", color: 'white', width: '90%', padding: '20px', margin: '10px' }}
                                                >
                                                    {"30 Minutes"}
                                                </Button>
                                            </Grid>
                                            <Grid xs={12}>
                                                <Button
                                                    className="fc-event"
                                                    title={"New"}
                                                    data={"1"}
                                                    key={"1"}
                                                    duration={'01:00'}
                                                    style={{ backgroundColor: "#76323F", color: 'white', width: '90%', padding: '20px', margin: '10px' }}
                                                >
                                                    {"1 Hour"}
                                                </Button>
                                            </Grid>
                                            <Grid xs={12}>
                                                <Button
                                                    className="fc-event"
                                                    title={"New"}
                                                    data={"1"}
                                                    key={"1"}
                                                    duration={'02:00'}
                                                    style={{ backgroundColor: "#76323F", color: 'white', width: '90%', padding: '20px', margin: '10px' }}
                                                >
                                                    {"2 Hours"}
                                                </Button>
                                            </Grid>
                                            <Grid xs={12}>
                                                <Button
                                                    className="fc-event"
                                                    title={"New"}
                                                    data={"1"}
                                                    key={"1"}
                                                    duration={'04:00'}
                                                    style={{ backgroundColor: "#76323F", color: 'white', width: '90%', padding: '20px', margin: '10px' }}
                                                >
                                                    {"Half Day"}
                                                </Button>
                                            </Grid>
                                        </Grid>}
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item align='center' xs={12} style={{ marginTop: '20px', visibility: this.state.selectedTeacher === undefined ? 'hidden' : '' }}>
                        <Grid container justify='center' spacing={5}>
                            <Grid item align='center'>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={this.handleAddAvailableSchedule}
                                    style={{ backgroundColor: "#76323F", color: 'white' }}
                                >
                                    Add Schedule
                                </Button>
                            </Grid>
                            <Grid item align='center'>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={this.handleGetAllAvailableSchedule}
                                    style={{ backgroundColor: "#76323F", color: 'white' }}
                                >
                                    Refresh
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

export default compose(
    withStyles(useStyles)
)(ScheduleManagement);