import React from 'react';

//Material UI
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import MuiAlert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

//Others
import Select from 'react-select';
import { withStyles } from '@material-ui/core/styles';
import { Component } from 'react';
import { compose } from 'recompose';
import Image from 'material-ui-image';

const useStyles = theme => ({
    root: {
        margin: 15,
        marginTop: 50
    },
    margin: {
        margin: theme.spacing(1),
    },
    buttonColor: {
        backgroundColor: '#76323F',
        color: 'white'
    }
});

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class Map extends Component {
    constructor() {
        super()
        this.state = {
            tempQuestionCount: [1, 2, 3, 4, 5],
            tempChoice: [1],
            choiceCount: 1
        };
    }

    componentDidMount = async () => {

    }

    handleEditImageUrl = async (event) => {
        this.props.questionObj.image = event.target.value;
    }

    handleEditVoiceUrl = async (event) => {
        this.props.questionObj.voice = event.target.value;
    }

    handleEditQuestion = async (event) => {
        this.props.questionObj.question = event.target.value;
    }

    handleEditSubQuestion = async (event) => {
        this.props.questionObj.sub_question[0] = event.target.value;
    }

    handleEditQuestionNumber = (event) => {
        this.props.questionObj.no = event.target.value;
    }

    handleEditAnswer = (event) => {
        if (event === null) {
            this.props.questionObj.answer = '';
        } else if (event !== null) {
            this.props.questionObj.answer = event.value;
        }
    }

    handleEditChoice = async (event, index) => {
        this.props.questionObj.choice[index] = event.target.value;

        this.props.handleEditChoiceForMap(this.props.questionObj.choice);
    }

    handleAddChoice = () => {
        this.state.tempChoice.push(this.state.choiceCount + 1);

        this.setState({
            tempChoice: this.state.tempChoice,
            choiceCount: this.state.choiceCount + 1
        });
    }

    render() {
        const { classes } = this.props;

        return (
            <Grid item xs={12}>
                <Grid container spacing={1}>
                    {this.props.qIdx === 0 &&
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12} style={{ maxWidth: '30%' }}>
                                    <Image
                                        src={this.props.questionObj.image}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                                        Image url
                                    </Typography>
                                    <TextField
                                        key="outlined-size-small-table-title-image"
                                        id="outlined-size-small-table-title-image"
                                        variant="outlined"
                                        size="small"
                                        onChange={this.handleEditImageUrl}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                    {this.props.qIdx === 0 &&
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                                        Voice Url
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="outlined-size-small"
                                        variant="outlined"
                                        size="small"
                                        onChange={this.handleEditVoiceUrl}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                    {this.props.qIdx === 0 &&
                        <Grid item xs={12}>
                            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                                Question
                            </Typography>
                            <TextField
                                key="outlined-size-small-table-title-quesiton"
                                id="outlined-size-small-table-title-quesiton"
                                variant="outlined"
                                size="small"
                                onChange={this.handleEditQuestion}
                                fullWidth
                            />
                        </Grid>
                    }
                    {this.props.qIdx === 0 &&
                        <Grid item xs={12} sm={6}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                                        Choice
                                    </Typography>
                                </Grid>
                                {this.state.tempChoice.map((choice, choiceIdx) =>
                                    <Grid key={`choicegrid-${choiceIdx}`} item xs={12}>
                                        <TextField
                                            key={`choice-${choiceIdx}`}
                                            variant="outlined"
                                            size="small"
                                            onChange={(event) => { this.handleEditChoice(event, choiceIdx) }}
                                            fullWidth
                                        />
                                    </Grid>
                                )}
                                <Grid item align="center" xs={12}>
                                    <IconButton aria-label="add" onClick={this.handleAddChoice} style={{ margin: 1 }}>
                                        <AddCircleRoundedIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                                            Question Number
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            variant="outlined"
                                            size="small"
                                            onChange={this.handleEditQuestionNumber}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                                            Sub-question
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            variant="outlined"
                                            size="small"
                                            onChange={this.handleEditSubQuestion}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                                            Answer
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            isClearable={true}
                                            isSearchable={true}
                                            name="choice"
                                            options={this.props.mapChoiceToShow}
                                            onChange={this.handleEditAnswer}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider style={{ backgroundColor: "#76323F", height: '5px', marginTop: '10px', marginBottom: '10px' }} />
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}
export default compose(
    withStyles(useStyles)
)(Map);