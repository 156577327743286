import React, { useEffect } from 'react';

//MUI
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';

import { ThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';

//Functions
import { capitalize } from '../.../../../../../../models/publicFuntions';

//Services
import { LoginWithGoogle } from '../.../../../../../../services/userServices';

//Font
import 'typeface-glacial-indifference';

//Others
import { Link } from "react-router-dom";
const axios = require('axios').default;
const FormData = require('form-data');
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

const useStyles = theme => ({
    root: {
        padding: theme.spacing(12, 4),
    }
});

const theme = createMuiTheme({
    typography: {
        fontFamily: [
            'Roboto'
        ].join(','),
    },
});

const SocialSuccessfulLogin = () => {
    useEffect(() => {
        document.body.style.backgroundColor = "#efefef";
        document.querySelector("body").scrollTo(0, 0);
    }, []);

    const getProfile = async () => {
        const params = await new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });

        const res = await LoginWithGoogle(params.token);

        if (res.success) {
            if (res.res.gender === "None") {
                window.location.href = `/social-signup?token=${params.token}`;

                return;
            } else {
                const capFirstName = await capitalize(res.res.firstname);
                const capLastName = await capitalize(res.res.lastname);
                const data = {
                    userSignedin: true,
                    firstname_th: "",
                    lastname_th: "",
                    firstname: capFirstName,
                    lastname: capLastName,
                    name: `${capFirstName} ${capLastName}`,
                    name_th: "",
                    email: res.res.email,
                    birthdate: res.res.birthDate,
                    gender: res.res.gender,
                    phone: res.res.phoneNumber,
                    school: res.res.school,
                    firstNameChar: res.res.firstname.charAt(0).toUpperCase(),
                    token: res.token,
                    role: res.res.role,
                    picture: res.res.picture,
                    username: res.res.username
                };

                await localStorage.setItem('user', JSON.stringify(data));
                await localStorage.setItem('userSignedIn', true);
            }

            window.location.href = "/profile"
        } if (!res.success) {
            alert("Something went wrong. Website will redirect you to sign-in page.");
            window.location.href = "/newlogin"
        }
    }

    const mobileUI = (<Grid container style={{ marginTop: "5%", marginBottom: "10%", maxWidth: window.innerWidth }}>
        <Grid item xs={12}>
            <Typography variant="h3" style={{ fontWeight: "bold" }} gutterBottom>
                <span style={{ color: "#991514" }}>U</span>TEST AVENUE
            </Typography>
        </Grid>
        <Grid item xs={12}>
            <CheckCircleRoundedIcon style={{ fontSize: '200px', color: "#419b45" }} />
        </Grid>
        <Grid item xs={12}>
            <Typography variant="h6">
                An account was created successfully!
            </Typography>
            <Typography variant="h6">
                Please login to the website
            </Typography>
        </Grid>
        <Grid item xs={12} style={{ marginTop: "30px" }}>
            <Button
                variant="contained"
                size="large"
                onClick={getProfile}
                style={{ backgroundColor: "#880000", color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1rem" }}
                disableElevation
            >
                Login
            </Button>
        </Grid>
    </Grid>)

    const webUI = (<Grid container spacing={3} style={{ marginTop: "18%", marginBottom: "25%", maxWidth: window.innerWidth }}>
        <Grid item xs={12}>
            <Typography variant="h2" style={{ fontWeight: "bold" }} gutterBottom>
                <span style={{ color: "#991514" }}>U</span>TEST AVENUE
            </Typography>
        </Grid>
        <Grid item xs={12}>
            <CheckCircleRoundedIcon style={{ fontSize: '200px', color: "#419b45" }} />
        </Grid>
        <Grid item xs={12}>
            <Typography variant="h6">
                An account was created successfully!
            </Typography>
            <Typography variant="h6">
                Please login to the website
            </Typography>
        </Grid>
        <Grid item xs={12}>
            <Button
                variant="contained"
                size="large"
                onClick={getProfile}
                style={{ backgroundColor: "#880000", color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1rem" }}
                disableElevation
            >
                Login
            </Button>
        </Grid>
    </Grid>)

    return <ThemeProvider theme={theme}>
        <Hidden mdUp>
            {mobileUI}
        </Hidden>
        <Hidden smDown>
            {webUI}
        </Hidden>
    </ThemeProvider>
};

export default compose(
    withStyles(useStyles)
)(SocialSuccessfulLogin);
