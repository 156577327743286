//React
import { Component } from 'react';
import { compose } from 'recompose';

//Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';

//Others libraries
import Carousel from "react-multi-carousel";

//react-router-dom
import {
    Link
} from "react-router-dom";

const axios = require('axios').default;
const FormData = require('form-data');
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%'
    }
});

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 5
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 4
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 3
    }
};

class MyReportHistory extends Component {
    constructor() {
        super();
        this.state = {
        };
    }

    componentDidMount = () => {

    }


    render() {
        const { classes } = this.props;

        return (
            <Grid container style={{ padding: '20px', marginTop: '5%' }}>
                <Grid item align="left" xs={12}>
                    <Typography variant="h4" gutterBottom>
                        <Box fontWeight="fontWeightBold" display='inline' style={{ color: '#76323F' }}>My test reports</Box>
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Carousel
                        responsive={responsive}
                        swipeable={true}
                        showDots={true}
                        containerClass="carousel-container"
                        itemClass="carousel-item"
                    >
                        {this.props.testReportHistory !== undefined &&
                            this.props.testReportHistory.length !== 0 &&
                            this.props.testReportHistory.sort((a, b) => new Date(b.date_created).getTime() - new Date(a.date_created).getTime()).map((test, index) =>
                                <Grid item style={{ margin: '10px' }}>
                                    <Card style={{ width: '200px', maxWidth: '200px', height: '300px' }}>
                                        <CardContent>
                                            <Grid container style={{ padding: '10%', paddingBottom: '0%' }}>
                                                <Grid item xs={12}>
                                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                                        Type
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="h5" component="div">
                                                        <Box fontWeight="fontWeightBold" display='inline' style={{ color: '#76323F' }}>{test.test_type}</Box>
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="caption" display="block" color="text.secondary">
                                                        {new Date(test.date_created).toDateString()}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                        <CardActions>
                                            <Grid container style={{ padding: '10%', paddingTop: '0%' }}>
                                                <Grid item align='center' xs={12}>
                                                    {(test.test_type === 'IELTS READING FREE' || test.test_type === 'IELTS LISTENING FREE' || test.test_type === 'BMAT FREE') &&
                                                        <Link to={"/test/free/report?test_id=" + test.test_id} color="inherit" underline="none" style={{ textDecoration: 'none', color: '#565656' }}>
                                                            <Button
                                                                size="large"
                                                                onClick={this.handleChooseFreeTest}
                                                                style={{
                                                                    backgroundColor: '#76323F',
                                                                    color: 'white',
                                                                    padding: '5px',
                                                                    width: '100%',
                                                                    marginTop: test.test_type === 'BMAT FREE' ? '44%' : '0%'
                                                                }}
                                                            >
                                                                See more
                                                            </Button>
                                                        </Link>
                                                    }

                                                    {(test.test_type === 'IELTS READING' || test.test_type === 'IELTS LISTENING' || test.test_type === 'IELST' || test.test_type === 'IELTS') &&
                                                        <Link to={"/myprofile/report?test_id=" + test.test_id} color="inherit" underline="none" style={{ textDecoration: 'none', color: '#565656' }}>
                                                            <Button
                                                                size="large"
                                                                onClick={this.handleChooseFreeTest}
                                                                style={{
                                                                    backgroundColor: '#76323F',
                                                                    color: 'white',
                                                                    padding: '5px',
                                                                    width: '100%',
                                                                    marginTop: '44%'
                                                                }}
                                                            >
                                                                See more
                                                            </Button>
                                                        </Link>
                                                    }

                                                    {(test.test_type === 'BMAT') &&
                                                        <Link to={"/myprofile/report/bmat?test_id=" + test.test_id} color="inherit" underline="none" style={{ textDecoration: 'none', color: '#565656' }}>
                                                            <Button
                                                                size="large"
                                                                onClick={this.handleChooseFreeTest}
                                                                style={{
                                                                    backgroundColor: '#76323F',
                                                                    color: 'white',
                                                                    padding: '5px',
                                                                    width: '100%',
                                                                    marginTop: '44%'
                                                                }}
                                                            >
                                                                See more
                                                            </Button>
                                                        </Link>
                                                    }
                                                </Grid>
                                                <Grid item align='center' xs={12}>
                                                    <Button
                                                        size="small"
                                                        onClick={() => { this.props.handleSelectReportProblem(test._id, 'reportHistory') }}
                                                        style={{
                                                            padding: '5px',
                                                            width: '100%'
                                                        }}
                                                    >
                                                        <Typography variant="caption" display="block" color="text.secondary">
                                                            Report a problem
                                                        </Typography>
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            )}
                    </Carousel>
                </Grid>

                {this.props.testReportHistory !== undefined && this.props.testReportHistory.length === 0 &&
                    <Grid item style={{ margin: '10px', maxWidth: '200px' }}>
                        <Card style={{ width: '200px', maxWidth: '200px', height: '250px', maxHeight: '250px' }}>
                            <CardContent>
                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom style={{ color: 'grey', paddingTop: '45%' }}>
                                    You have no report
                                </Typography>
                                <Link to={"/courses"} color="inherit" underline="none" style={{ textDecoration: 'none', color: '#565656' }}>
                                    <Button
                                        size="large"
                                        onClick={this.handleChooseFreeTest}
                                        style={{
                                            backgroundColor: '#76323F',
                                            color: 'white',
                                            padding: '5px',
                                            width: '100%'
                                        }}
                                    >
                                        Take a Test
                                    </Button>
                                </Link>
                            </CardContent>
                        </Card>
                    </Grid>
                }

                {this.props.testReportHistory === undefined &&
                    <Grid item style={{ width: '200px', maxWidth: '200px', height: '200px', maxHeight: '200px', marginRight: '10px' }}>
                        <Card style={{ width: '200px', maxWidth: '200px', height: '250px', maxHeight: '250px' }}>
                            <CardContent>
                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom style={{ marginTop: '10%' }}>
                                    <Skeleton />
                                </Typography>
                                <Typography variant="h5" component="div">
                                    <Skeleton />
                                </Typography>
                                <Typography variant="caption" display="block" color="text.secondary">
                                    <Skeleton />
                                </Typography>
                                <Skeleton variant="rect" height={40} style={{ marginTop: '15%' }} />
                                <Skeleton variant="rect" height={20} style={{ marginTop: '5%' }} />
                            </CardContent>
                        </Card>
                    </Grid>
                }
            </Grid>
        )
    }
}

export default compose(
    withStyles(useStyles)
)(MyReportHistory);