import React, { useEffect } from 'react';

//MUI
import Avatar from '@material-ui/core/Avatar';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';

import { ThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';

//Constats
import * as styleConstants from '../../../../../constants/styleConstants';

//Font
import 'typeface-glacial-indifference';

import Fade from 'react-reveal/Fade';
import VisibilitySensor from 'react-visibility-sensor';
import { useState } from 'react';

//Others
const axios = require('axios').default;
const FormData = require('form-data');
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

const useStyles = theme => ({
    root: {
        padding: theme.spacing(12, 4),
    }
});

const theme = createMuiTheme({
    typography: {
        fontFamily: [
            styleConstants.fontStyle
        ].join(','),
    },
});

const AboutUs = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        document.body.style.overflow = "auto";
        document.body.style.backgroundColor = "#efefef";
        document.querySelector("body").scrollTo(0, 0);
    }, []);

    const mobileUI = (
        <Grid id="aboutus" container alignContent='center' alignItems='center' style={{ marginTop: "100px", maxWidth: window.innerWidth, padding: "20px", zIndex: 1 }}>
            <Grid item align='left' xs={12} style={{ marginBottom: '50px', zIndex: 1 }}>
                <Typography variant="h4" style={{ fontWeight: styleConstants.fontWeight.bold, color: '#991514' }}>
                    ABOUT US
                </Typography>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: "2%", zIndex: 1 }}>
                <Grid container spacing={6}>
                    <Grid item align='center' xs={12}>
                        <Typography variant="h6" style={{ fontWeight: styleConstants.fontWeight.bold }}>
                            DESIGN
                        </Typography>
                    </Grid>
                    <Grid item align='center' xs={12}>
                        <Avatar
                            alt="Remy Sharp"
                            src="/AB-1.png"
                            style={{ backgroundColor: '#991514', padding: '30px', height: '100px', width: '100px' }}
                        />
                    </Grid>
                    <Grid item align='center' xs={12}>
                        <Typography variant="body2" style={{ fontWeight: styleConstants.fontWeight.thin }} gutterBottom>
                            Well designed tests and system
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: "2%", zIndex: 1 }}>
                <Grid container spacing={6}>
                    <Grid item align='center' xs={12}>
                        <Typography variant="h6" style={{ fontWeight: styleConstants.fontWeight.bold }}>
                            SIMULATE
                        </Typography>
                    </Grid>
                    <Grid item align='center' xs={12}>
                        <Avatar
                            alt="Remy Sharp"
                            src="/AB-1.png"
                            style={{ backgroundColor: '#2a2a2a', padding: '30px', height: '100px', width: '100px' }}
                        />
                    </Grid>
                    <Grid item align='center' xs={12}>
                        <Typography variant="body2" style={{ fontWeight: styleConstants.fontWeight.thin }} gutterBottom>
                            Full computer-based simulation tests
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: "2%", zIndex: 1 }}>
                <Grid container spacing={6}>
                    <Grid item align='center' xs={12}>
                        <Typography variant="h6" style={{ fontWeight: styleConstants.fontWeight.bold }}>
                            ANALYSE
                        </Typography>
                    </Grid>
                    <Grid item align='center' xs={12}>
                        <Avatar
                            alt="Remy Sharp"
                            src="/AB-3.png"
                            style={{ backgroundColor: '#636363', padding: '30px', height: '100px', width: '100px' }}
                        />
                    </Grid>
                    <Grid item align='center' xs={12}>
                        <Typography variant="body2" style={{ fontWeight: styleConstants.fontWeight.thin }} gutterBottom>
                            Skill analysis  using machine learning
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: "2%", zIndex: 1 }}>
                <Grid container spacing={6}>
                    <Grid item align='center' xs={12}>
                        <Typography variant="h6" style={{ fontWeight: styleConstants.fontWeight.bold }}>
                            REPORT
                        </Typography>
                    </Grid>
                    <Grid item align='center' xs={12}>
                        <Avatar
                            alt="Remy Sharp"
                            src="/AB-4.png"
                            style={{ backgroundColor: '#a6a6a6', padding: '30px', height: '100px', width: '100px' }}
                        />
                    </Grid>
                    <Grid item align='center' xs={12}>
                        <Typography variant="body2" style={{ fontWeight: styleConstants.fontWeight.thin }} gutterBottom>
                            Full skill analysis report
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>)

    const webUI = (<Grid id="aboutus" container alignContent='center' alignItems='center' style={{ marginTop: "100px", maxWidth: window.innerWidth, paddingLeft: "5%", paddingRight: "10%" }}>
        <Grid item align='left' xs={12} style={{ marginBottom: '100px', zIndex: 1 }}>
            <VisibilitySensor onChange={(isVisible) => { if (isVisible) { setIsVisible(isVisible) } }}>
                <Fade top when={isVisible}>
                    <Typography variant="h3" style={{ fontWeight: styleConstants.fontWeight.bold, color: '#991514' }}>
                        ABOUT US
                    </Typography>
                </Fade>
            </VisibilitySensor>
        </Grid>
        <Grid item xs style={{ marginBottom: "2%", paddingLeft: '50px', zIndex: 1 }}>
            <Grid container spacing={6}>
                <Grid item align='center' xs={12}>
                    <VisibilitySensor onChange={(isVisible) => { setIsVisible(isVisible) }}>
                        <Fade when={isVisible}>
                            <Typography variant="h3" style={{ fontWeight: styleConstants.fontWeight.bold }}>
                                DESIGN
                            </Typography>
                        </Fade>
                    </VisibilitySensor>
                </Grid>
                <Grid item align='center' xs={12}>
                    <Fade when={isVisible}>
                        <Avatar
                            alt="Remy Sharp"
                            src="/AB-1.png"
                            style={{ backgroundColor: '#991514', padding: '30px', height: '100px', width: '100px' }}
                        />
                    </Fade>
                </Grid>
                <Grid item align='center' xs={12}>
                    <Fade when={isVisible}>
                        <Typography variant="h6" style={{ fontWeight: styleConstants.fontWeight.thin }} gutterBottom>
                            Well designed tests and system
                        </Typography>
                    </Fade>
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs style={{ zIndex: 1 }}>
            <Fade delay={500} when={isVisible}>
                <Divider style={{ height: '10px', backgroundColor: '#a00505' }} />
            </Fade>
        </Grid>
        <Grid item xs style={{ marginBottom: "2%", zIndex: 1 }}>
            <Grid container spacing={6}>
                <Grid item align='center' xs={12}>
                    <Fade delay={1000} when={isVisible}>
                        <Typography variant="h3" style={{ fontWeight: styleConstants.fontWeight.bold }}>
                            SIMULATE
                        </Typography>
                    </Fade>
                </Grid>
                <Grid item align='center' xs={12}>
                    <Fade delay={1000} when={isVisible}>
                        <Avatar
                            alt="Remy Sharp"
                            src="/AB-1.png"
                            style={{ backgroundColor: '#2a2a2a', padding: '30px', height: '100px', width: '100px' }}
                        />
                    </Fade>
                </Grid>
                <Grid item align='center' xs={12}>
                    <Fade delay={1000} when={isVisible}>
                        <Typography variant="h6" style={{ fontWeight: styleConstants.fontWeight.thin }} gutterBottom>
                            Full computer-based simulation tests
                        </Typography>
                    </Fade>
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs style={{ zIndex: 1 }}>
            <Fade delay={1500} when={isVisible}>
                <Divider style={{ height: '10px', backgroundColor: '#a00505' }} />
            </Fade>
        </Grid>
        <Grid item xs style={{ marginBottom: "2%", zIndex: 1 }}>
            <Grid container spacing={6}>
                <Grid item align='center' xs={12}>
                    <Fade delay={2000} when={isVisible}>
                        <Typography variant="h3" style={{ fontWeight: styleConstants.fontWeight.bold }}>
                            ANALYSE
                        </Typography>
                    </Fade>
                </Grid>
                <Grid item align='center' xs={12}>
                    <Fade delay={2000} when={isVisible}>
                        <Avatar
                            alt="Remy Sharp"
                            src="/AB-3.png"
                            style={{ backgroundColor: '#636363', padding: '30px', height: '100px', width: '100px' }}
                        />
                    </Fade>
                </Grid>
                <Grid item align='center' xs={12}>
                    <Fade delay={2000} when={isVisible}>
                        <Typography variant="h6" style={{ fontWeight: styleConstants.fontWeight.thin }} gutterBottom>
                            Skill analysis  using machine learning
                        </Typography>
                    </Fade>
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs style={{ zIndex: 1 }}>
            <Fade delay={2500} when={isVisible}>
                <Divider style={{ height: '10px', backgroundColor: '#a00505' }} />
            </Fade>
        </Grid>
        <Grid item xs style={{ marginBottom: "2%", paddingRight: '50px', zIndex: 1 }}>
            <Grid container spacing={6}>
                <Grid item align='center' xs={12}>
                    <Fade delay={3000} when={isVisible}>
                        <Typography variant="h3" style={{ fontWeight: styleConstants.fontWeight.bold }}>
                            REPORT
                        </Typography>
                    </Fade>
                </Grid>
                <Grid item align='center' xs={12}>
                    <Fade delay={3000} when={isVisible}>
                        <Avatar
                            alt="Remy Sharp"
                            src="/AB-4.png"
                            style={{ backgroundColor: '#a6a6a6', padding: '30px', height: '100px', width: '100px' }}
                        />
                    </Fade>
                </Grid>
                <Grid item align='center' xs={12}>
                    <Fade delay={3000} when={isVisible}>
                        <Typography variant="h6" style={{ fontWeight: styleConstants.fontWeight.thin }} gutterBottom>
                            Full skill analysis report
                        </Typography>
                    </Fade>
                </Grid>
            </Grid>
        </Grid>
    </Grid>)

    return <ThemeProvider theme={theme}>
        <Hidden mdUp>
            {mobileUI}
        </Hidden>
        <Hidden smDown>
            {webUI}
        </Hidden>
    </ThemeProvider>
};

export default compose(
    withStyles(useStyles)
)(AboutUs);
