import * as apiConstants from '../constants/apiConstants';
import { GetAdminToken } from './token';

const axios = require('axios').default;
const _ = require("lodash");

export async function GetAllSchools() {
    try {
        let tempArr = [];

        const res = await axios({
            method: 'get',
            url: `${apiConstants.UTESTAVENUE_SYSTEM_API_BASE_URL}/schools`
        });

        await res.data.schools.map((school) => {
            tempArr.push({
                label: school.name,
                value: school.name
            })
        });

        return {
            success: true,
            res: tempArr
        }
    } catch (err) {
        console.log(err);
        return {
            success: false,
            res: err
        }
    }
}

export async function AddNewSchool(school) {
    try {
        const res = await axios({
            method: 'post',
            url: `${apiConstants.UTESTAVENUE_SYSTEM_API_BASE_URL}/schools/add`,
            data: {
                schoolName: school
            }
        });

        return {
            success: true,
            res: res.data
        }
    } catch (err) {
        console.log(err);
        return {
            success: false,
            res: err
        }
    }
}