import MaterialTable from 'material-table';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { compose } from 'recompose';

//import others
import 'react-calendar/dist/Calendar.css';

//react-router-dom

//Others
const axios = require('axios').default;

const useStyles = theme => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    root: {
        minWidth: 275,
    }
});


class DiscountCodeUsageTable extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false
        }
    }

    componentDidMount = () => {
        this.handleGetAllDiscountCodeUsage();
    }

    handleGetAllDiscountCodeUsage = async () => {
        try {
            const discountUsageToShow = [];

            const res = await axios({
                method: 'get',
                url: 'https://erratic-noiseless-wasabi.glitch.me/utestavenue-system-api/api/v1/discountsUsage/used',
            });

            const resData = res.data;

            await resData.discountsUsage.map((discount) => {
                discountUsageToShow.push({
                    _id: discount._id,
                    discountCode: discount.discountCode,
                    usedBy: discount.usedBy,
                    createdAt: new Date(discount.createdAt).toDateString()
                })
            });


            this.setState({
                discountUsage: discountUsageToShow
            });
        } catch (err) {
            console.log(err);
        }
    }

    handleOpenBackdrop = () => {
        this.setState({
            isLoading: true
        });
    }

    handleCloseBackdrop = () => {
        this.setState({
            isLoading: false
        });
    }

    render() {
        const { classes } = this.props;

        return (
            <Grid item xs={12}>
                <Backdrop className={classes.backdrop} open={this.state.isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>

                <MaterialTable
                    isLoading={!this.state.discountUsage}
                    data={this.state.discountUsage}
                    columns={[
                        { title: 'Discount code', field: 'discountCode', editable: 'never' },
                        { title: 'Used By', field: 'usedBy', editable: 'never' },
                        { title: 'Used at', field: 'createdAt', editable: 'never', defaultSort: 'desc', customSort: (a, b) => new Date(a.createdAt) - new Date(b.createdAt) },
                    ]}
                    options={{
                        actionsColumnIndex: -1,
                        exportAllData: true,
                        exportButton: true,
                        pageSize: 10
                    }}
                    title='Discount Usage'
                />
            </Grid>
        )
    }
}
export default compose(
    withStyles(useStyles)
)(DiscountCodeUsageTable);