import * as apiConstants from '../constants/apiConstants';
import { GetAdminToken } from './token';

const axios = require('axios').default;
const _ = require("lodash");

export async function GetAllProblemReports() {
    try {
        const res = await axios({
            method: 'get',
            url: `${apiConstants.TEST_MANAGEMENT_API_BASE_URL}/test/problems`
        });

        return {
            success: true,
            res: res.data
        }
    } catch (err) {
        console.log(err);
        return {
            success: false,
            res: err
        }
    }
}

export async function GetAllTestsTaken(start, end) {
    try {
        const res = await axios({
            method: 'get',
            url: `${apiConstants.TEST_MANAGEMENT_API_BASE_URL}/test/status/logsbyyear?startDate=${start}&endDate=${end}`
        });

        return {
            success: true,
            res: res.data
        }
    } catch (err) {
        console.log(err);
        return {
            success: false,
            res: err
        }
    }
}

export async function GetAllTestsResult(token) {
    try {
        const res = await axios({
            method: 'get',
            // url: `${apiConstants.E_LEARNING_API_BASE_URL}/api/test/result/all`,
            url: "https://chatter-funky-busby.glitch.me/test-reports",
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });

        console.log(res.data);

        let userArr = []
        await res.data.map((obj) => {
            if (!(res.data.includes(obj.username))) {
                userArr.push(obj.username);
            }
        });

        const userDetailArr = await GetUserDetail([...new Set(userArr)]);
        const orderedByUsername = await _.groupBy(res.data, function (element) {
            if (element.test_result.test_started !== undefined) {
                return element.username;
            }
        });

        const orderedByMonths = await _.groupBy(res.data, function (element) {
            if (element.test_result.test_started !== undefined) {
                return element.test_result.test_started.substring(0, 7);
            }
        });

        const finalArr = await Join2Object(userDetailArr, orderedByUsername);

        return {
            success: true,
            res: {
                results: finalArr,
                resultsOrderedByMonths: orderedByMonths
            }
        }
    } catch (err) {
        console.log(err);
        return {
            success: false,
            res: err
        }
    }
}

export async function GetUserDetail(userArr) {
    try {
        let tempUserArr = []

        const adminToken = await GetAdminToken();
        const res = await axios({
            method: 'get',
            url: `${apiConstants.E_LEARNING_API_BASE_URL}/api/user/all`,
            headers: {
                "Authorization": `Bearer ${adminToken.res.token.token}`
            }
        });

        userArr.map((user, index) => {
            const result = res.data.find(obj => {
                return obj.username === user
            })

            tempUserArr.push(result);
        });

        return tempUserArr
    } catch (err) {
        console.log(err);
        return err
    }
}

export async function Join2Object(userArr, logArr) {
    try {
        let tempLogArr = [];

        console.log(userArr, logArr);

        userArr.map((user, index) => {
            if (user !== undefined) {
                if (logArr[user.username] !== undefined) {
                    logArr[user.username].map((obj, index) => {
                        tempLogArr.push({
                            test_result: obj,
                            user_obj: user
                        });
                    });

                    return tempLogArr;
                }
            }
        });

        console.log(tempLogArr);

        return tempLogArr
    } catch (err) {
        console.log(err);
        return err
    }
}
