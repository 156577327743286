//React
import { Component } from 'react';
import { compose } from 'recompose';

//Material UI
import { withStyles } from '@material-ui/core/styles';

//import components
import { StyleSheet } from '@react-pdf/renderer';

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%'
    }
});

// Create styles for PDF
const styles = StyleSheet.create({
    body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35
    },
    title: {
        fontSize: 24,
        textAlign: 'center',
        marginBottom: 12
    },
    author: {
        fontSize: 12,
        textAlign: 'center',
        marginBottom: 40,
    },
    subtitle: {
        fontSize: 12,
        margin: 12,
        marginBottom: 50,
        textAlign: 'center',
    },
    grammarErrCount: {
        margin: 12,
        fontSize: 14,
        textAlign: 'right',
        fontFamily: 'Times-Roman'
    },
    text: {
        margin: 12,
        fontSize: 14,
        textAlign: 'justify',
        fontFamily: 'Times-Roman'
    },
    image: {
        marginVertical: 15,
        marginHorizontal: 100,
    },
    header: {
        fontSize: 12,
        marginBottom: 20,
        textAlign: 'center',
        color: 'grey',
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    },
});

class MiniReportPDF extends Component {
    constructor() {
        super();
        this.state = {

        };
    }

    render() {
        const { classes } = this.props;

        return (
            <div id="divToPrint">
                <h2>{this.props.report === undefined ? '' : 'Test report(free version)'}</h2>

                <h4 style={{ marginTop: '20px' }}>Tester information</h4>
                {this.props.report?.test_type !== 'BMAT FREE' && <p>
                    <b>Name:</b> {this.props.user === undefined ? '' : this.props.user.name}
                    <br /> <b>Test type:</b> {this.props.report === undefined ? '' : this.props.report.test_type}
                    <br /> <b>Test date:</b> {this.props.report === undefined ? '' : new Date(this.props.report.date_created).toDateString()}
                    <br /> <b>You are likely to get band score of:</b> {this.props.report !== undefined && (this.props.report.test_type === 'IELTS READING FREE' ? this.props.readingBandScore : this.props.listeningBandScore)}
                </p>}

                {this.props.report?.test_type === 'BMAT FREE' && <p>
                    <b>Name:</b> {this.props.user === undefined ? '' : this.props.user.name}
                    <br /> <b>Test type:</b> {this.props.report === undefined ? '' : this.props.report.test_type}
                    <br /> <b>Test date:</b> {this.props.report === undefined ? '' : new Date(this.props.report.date_created).toDateString()}
                </p>}

                <h4 style={{ marginTop: '20px' }}>Test result</h4>
                <h6 style={{ marginTop: '20px' }}>Overall</h6>
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th>Full score</th>
                            <th>Your score</th>
                            <th>Percentage</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{this.props.report === undefined ? '' : this.props.report.test_result.total_score}</td>
                            <td>{this.props.report === undefined ? '' : this.props.report.test_result.total_question}</td>
                            <td>{this.props.report === undefined ? '' : Math.trunc((this.props.report.test_result.total_score / this.props.report.test_result.total_question) * 100)}%</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}

export default compose(
    withStyles(useStyles)
)(MiniReportPDF);