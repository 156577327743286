//React
import { Component } from 'react';
import { compose } from 'recompose';

//Material UI
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccountBoxRoundedIcon from '@material-ui/icons/AccountBoxRounded';
import AccessAlarmRoundedIcon from '@material-ui/icons/AccessAlarmRounded';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Input from '@material-ui/core/Input';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

//import components

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%'
    },
    paperRoot: {
        padding: theme.spacing(2),
        textAlign: 'left'
    },
    paperItem: {
        padding: theme.spacing(2),
        textAlign: 'left',
        height: '810px',
        maxHeight: '810px',
        overflow: 'auto',
    },
    testAlignLeft: {
        padding: theme.spacing(2),
    },
    gridContainerRoot: {
        marginTop: 80
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    marginLeft: {
        marginLeft: 20
    },
    marginTop: {
        marginTop: 20
    },
    margin: {
        margin: 5
    }
});

class TrueFalseNG extends Component {
    constructor() {
        super();

        this.state = {
            answerObj: {
                answers: {}
            }
        };
    }

    render() {
        const { classes } = this.props;

        return (
            <Grid container style={{ marginBottom: '20px' }}>
                <Grid item align='left' xs={12}>
                    <Typography variant="h5" gutterBottom style={{ fontSize: '1rem' }}>
                        <Box fontWeight="fontWeightBold" display='inline'>{this.props.sectionInfo.section}</Box>
                    </Typography>
                </Grid>
                <Grid item align='left' xs={12}>
                    {this.props.sectionInfo.questions.map(q =>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography className={classes.heading} style={{ fontSize: '1rem' }}>
                                    <Box fontWeight="fontWeightBold" display='inline' m={1}>{q.no}</Box> {q.question}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails className={classes.marginLeft}>
                                <RadioGroup
                                    aria-label="q1"
                                    name={this.props.sectionInfo.section_id + '_' + q.no}
                                >
                                    <FormControlLabel
                                        value="true"
                                        control={<Radio color="default" />}
                                        label={<Typography variant="subtitle2" gutterBottom>TRUE</Typography>}
                                    />
                                    <FormControlLabel
                                        value="false"
                                        control={<Radio color="default" />}
                                        label={<Typography variant="subtitle2" gutterBottom>FALSE</Typography>}
                                    />
                                    <FormControlLabel
                                        value="not given"
                                        control={<Radio color="default" />}
                                        label={<Typography variant="subtitle2" gutterBottom>NOT GIVEN</Typography>}
                                    />
                                </RadioGroup>
                            </AccordionDetails>
                        </Accordion>
                    )}
                </Grid>
            </Grid>
        )
    }
}

export default compose(
    withStyles(useStyles)
)(TrueFalseNG);