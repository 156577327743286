import React, { useEffect } from 'react';

//MUI

import { ThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';

//Components
import AboutUs from './components/contactUsPage/AboutUs';
import HowItWorks from './components/contactUsPage/HowItWorks';
import Objectives from './components/contactUsPage/Objectives';
import OurStory from './components/contactUsPage/OurStory';

//Font
import 'typeface-glacial-indifference';

//Others
import Slide from 'react-reveal/Slide';
import { Box, CardMedia, Grid } from '@material-ui/core';
import styled, { keyframes } from 'styled-components';

const useStyles = theme => ({
    root: {
        padding: theme.spacing(12, 4),
    }
});

const theme = createMuiTheme({
    typography: {
        fontFamily: [
            'Roboto'
        ].join(','),
    },
});

const AboutUsPage = () => {
    useEffect(() => {
        document.body.style.overflow = "auto";
        document.body.style.backgroundColor = "#efefef";
        document.querySelector("body").scrollTo(0, 0);
    }, []);

    const breatheAnimation = keyframes`
    0% { transform: scale(1); }
    50% { transform: scale(1.05); }
    100% { transform: scale(1); }
    `;
    const StyledCardMedia = styled(CardMedia)`animation: ${breatheAnimation} 4s ease-in-out infinite;`;

    return <ThemeProvider theme={theme}>
        <Grid item xs={12} style={{
            backgroundColor: "#efefef"
        }}>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={6}>
                        <Box
                            position="absolute"
                            top={`${(window.innerWidth <= 768 ? 30 : 100)}px`}
                            
                            left='-10%'
                            transition="top 0.3s ease-in-out"
                            style={{ pointerEvents: "none", zIndex: 0 }}
                        >
                            <Slide left>
                                <StyledCardMedia
                                    component="img"
                                    alt="Contemplative Reptile"
                                    src="/Permanentbackground(transparent).png"
                                />
                            </Slide>
                        </Box>
                    </Grid>

                    <Grid item xs={6}>
                        <Box
                            position="absolute"
                            top={`${(window.innerWidth <= 768 ? 20 : 80)}px`}
                            left='10%'
                            transition="top 0.3s ease-in-out"
                            style={{ pointerEvents: "none", transform: "scaleX(-1)", zIndex: 0 }}
                        >
                            <Slide right>
                                <StyledCardMedia
                                    component="img"
                                    alt="Contemplative Reptile"
                                    src="/Permanentbackground(transparent).png"
                                />
                            </Slide>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        <OurStory />
        <AboutUs />
        <Objectives />
        <HowItWorks />
    </ThemeProvider>
};

export default compose(
    withStyles(useStyles)
)(AboutUsPage);
