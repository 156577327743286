//React
import React from "react";

//Material UI

//import components

//CSS
import './css/WritingReport.css';

class WritingReport extends React.Component {
    constructor() {
        super();
    }

    componentDidMount = () => {
        console.log(this.props);
    }

    render() {
        const { classes } = this.props;

        return (
            <div id="divToPrint">
                {/* <img src="https://i.ibb.co/sKFnZXp/U-Test-Logo-new-square.png" alt="Italian Trulli" style={{ width: '40px', textAlign: 'right' }} />
                <img src="https://i.ibb.co/8jV816X/Band-Score-Table.png" alt="Italian Trulli" style={{ marginTop: '10px', width: '100px', textAlign: 'right' }} />
                <h2 style={{ fontWeight: 'lighter', color: '#1b0b7a' }}>IELTS Writing</h2>
                <p style={{ marginTop: '20px', lineHeight: '0em' }}><span style={{ fontWeight: 'bold' }}>Test date:</span> {this.props.currentDate}</p>
                <p ><span style={{ fontWeight: 'bold' }}>Student name:</span> {this.props.userObj.name}</p>

                <p style={{ marginTop: '20px', fontWeight: 'bold', lineHeight: '0em' }}>Task 1</p>
                {
                    !(this.props.fullTestObj?.sections[0]?.questions[0]?.passage.includes("https://")) && (this.props.fullTestObj?.sections[0]?.questions[0]?.passage.split('\\n').map((text, index) =>
                        <p style={{ marginTop: '10px', fontWeight: 'bold', lineHeight: '0em' }}>{index === 0 && 'Question:'} {text}</p>
                    ))
                }

                {
                    this.props.fullTestObj?.sections[0]?.questions[0]?.passage.includes("https://") && (this.props.fullTestObj?.sections[0]?.questions[0]?.question.split('\\n').map((text, index) =>
                        <p style={{ marginTop: '10px', fontWeight: 'bold' }}>{index === 0 && 'Question:'} {text}</p>
                    ))
                }
                {
                    this.props.fullTestObj?.sections[0]?.questions[0]?.passage.includes("https://") &&
                    <img src={this.props.fullTestObj?.sections[0]?.questions[0]?.passage} alt="Italian Trulli" style={{ width: '300px', textAlign: 'center' }} />
                } */}

                <p style={{ marginTop: '20px', fontWeight: 'bold' }}>Answer</p>
                {this.props.answerQ1Obj?.length > 0 ? this.props.lastEditQ1 : <p></p>}
                <hr class="solid" style={{ marginTop: '50px', marginBottom: '50px' }} />

                {/* <table style={{ borderRadius: "1em" }}>
                    <tr style={{ textAlign: 'center' }}>
                        <th>
                            <p style={{ fontWeight: 'bold' }}>Possible Improvements</p>
                        </th>
                        <th>
                            <p style={{ fontWeight: 'bold' }}>Metrics</p>
                        </th>
                    </tr>
                    <tr style={{ textAlign: 'left', backgroundColor: 'white' }}>
                        <td>
                            <p>Grammar: {this.props.grammarErrorCountQ1 !== undefined ? this.props.grammarErrorCountQ1?.grammar : 0}</p>
                            <p>Spelling: {this.props.grammarErrorCountQ1 !== undefined ? this.props.grammarErrorCountQ1?.spelling : 0}</p>
                            <p>Stylistics: 0</p>
                            <p>Punctuation: 0</p>
                            <p>Structure: 0</p>
                        </td>
                        <td>
                            <p>Readability(Flesch–Kincaid index): {this.props.readabilityObjQ1 !== undefined ? this.props.readabilityObjQ1?.stats?.fleschKincaid?.readingEase : 0}
                            </p>
                            <p>Words: {this.props.readabilityObjQ1 !== undefined ? this.props.readabilityObjQ1?.stats?.counters?.words : 0}
                            </p>
                            <p>Text length: {this.props.readabilityObjQ1 !== undefined ? this.props.readabilityObjQ1?.stats?.counters?.length : 0}
                            </p>
                            <p>Without sapces: {this.props.readabilityObjQ1 !== undefined ? this.props.readabilityObjQ1?.stats?.counters?.clearLength : 0}
                            </p>
                        </td>
                    </tr>
                </table> */}

                {/*=================================== Q2 ===================================*/}
                {/* <h2 class="pdf-pagebreak-before" style={{ fontWeight: 'lighter', color: '#1b0b7a' }}>IELTS Writing</h2>
                <p style={{ marginTop: '20px', lineHeight: '0em' }}><span style={{ fontWeight: 'bold' }}>Test date:</span> {this.props.currentDate}</p>
                <p ><span style={{ fontWeight: 'bold' }}>Student name:</span> {this.props.userObj.name}</p>

                <p style={{ marginTop: '20px', fontWeight: 'bold' }}>Task 2</p>

                {
                    !(this.props.fullTestObj?.sections[0]?.questions[1]?.passage.includes("https://")) && (this.props.fullTestObj?.sections[0]?.questions[1]?.passage.split('\\n').map((text, index) =>
                        <p style={{ marginTop: '10px', fontWeight: 'bold' }}>{index === 0 && 'Question:'} {text}</p>
                    ))
                }

                {
                    this.props.fullTestObj?.sections[0]?.questions[1]?.passage.includes("https://") && (this.props.fullTestObj?.sections[0]?.questions[1]?.question.split('\\n').map((text, index) =>
                        <p style={{ marginTop: '10px', fontWeight: 'bold' }}>{index === 0 && 'Question:'} {text}</p>
                    ))
                }
                { this.props.fullTestObj?.sections[0]?.questions[1]?.passage.includes("https://") && <p style={{ marginTop: '10px', fontWeight: 'bold' }}>{this.props.fullTestObj?.sections[0]?.questions[0]?.passage}</p>} */}

                <p style={{ marginTop: '20px', fontWeight: 'bold' }}>Answer</p>
                {this.props.answerQ2Obj?.length > 0 ? this.props.lastEditQ2 : <p></p>}
                <hr class="solid" style={{ marginTop: '50px', marginBottom: '50px' }} />

                {/* <table>
                    <tr style={{ textAlign: 'center' }}>
                        <th>
                            <p style={{ fontWeight: 'bold' }}>Possible</p>
                            <p style={{ fontWeight: 'bold' }}>Improvements</p>
                        </th>
                        <th>
                            <p style={{ fontWeight: 'bold' }}>Metrics</p>
                        </th>
                    </tr>
                    <tr style={{ textAlign: 'left', backgroundColor: 'white' }}>
                        <td>
                            <p>Grammar: {this.props.grammarErrorCountQ2 !== undefined ? this.props.grammarErrorCountQ2?.grammar : 0}</p>
                            <p>Spelling: {this.props.grammarErrorCountQ2 !== undefined ? this.props.grammarErrorCountQ2?.spelling : 0}</p>
                            <p>Stylistics: 0</p>
                            <p>Punctuation: 0</p>
                            <p>Structure: 0</p>
                        </td>
                        <td>
                            <p>Readability(Flesch–Kincaid index): {this.props.readabilityObjQ2 !== undefined ? this.props.readabilityObjQ2?.stats?.fleschKincaid?.readingEase : 0}
                            </p>
                            <p>Words: {this.props.readabilityObjQ2 !== undefined ? this.props.readabilityObjQ2?.stats?.counters?.words : 0}
                            </p>
                            <p>Text length: {this.props.readabilityObjQ2 !== undefined ? this.props.readabilityObjQ2?.stats?.counters?.length : 0}
                            </p>
                            <p>Without spaces: {this.props.readabilityObjQ2 !== undefined ? this.props.readabilityObjQ2?.stats?.counters?.clearLength : 0}
                            </p>
                        </td>
                    </tr>
                </table> */}
            </div >
        )
    }
}

export default WritingReport;
