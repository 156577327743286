//React
import { Component } from 'react';
import { compose } from 'recompose';

//Material UI
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import AddToPhotosRoundedIcon from '@material-ui/icons/AddToPhotosRounded';

//react-router-dom
import {
    Link
} from "react-router-dom";

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%'
    },
    gridContainer: {
        marginTop: 100
    },
    addTestButton: {
        width: '100%',
        height: '100px'
    },
    margin: {
        margin: 10
    },
    padding: {
        padding: 50
    },
    FormControl: {
        width: '100%',
        height: '100px'
    }
});

class TestManagement extends Component {
    constructor() {
        super();
        this.state = {
            addNewTest: false,
            tests: [
                {
                    name: "Dummy Test #1",
                    category: "IELTS",
                    type: "Listening"
                }
            ]
        };
        this.handleAddNewTest = this.handleAddNewTest.bind(this);
        this.handleCloseAddNewTest = this.handleCloseAddNewTest.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        })
        console.log(this.state)
    }

    //======================================= BEGIN ADD NEW TEST ==============================================

    handleCloseAddNewTest() {
        this.setState({
            addNewTest: false
        })
    }

    handleAddNewTest() {
        this.setState({
            addNewTest: true
        })
    }

    addNewTest(name, category, type) {
        let addedObject = {
            name: name,
            category: category, type
        }
        this.setState({
            addNewTest: false
        })
        this.state.tests.push(addedObject);
    }

    //======================================= END ADD NEW TEST ==============================================


    render() {
        const { classes } = this.props;

        const addNewTest = (
            <Dialog open={this.state.addNewTest} onClose={this.handleCloseAddNewTest} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title" >
                    Add new test
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        To add test to this website, please enter your test name here. We will updates
                        occasionally.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Name"
                        variant="outlined"
                        name="newTestName"
                        onChange={this.handleChange}
                        fullWidth
                    />
                    <FormControl margin="dense" variant="outlined" fullWidth>
                        <InputLabel htmlFor="outlined-age-native-simple">Category</InputLabel>
                        <Select
                            native
                            value={this.state.testCategory}
                            onChange={this.handleChange}
                            label="testCategory"
                            name="testCategory"
                            inputProps={{
                                id: 'outlined-age-native-simple',
                            }}
                        >
                            <option aria-label="None" value="" />
                            <option value={10}>IELTS</option>
                            <option value={20}>TOFEL</option>
                            <option value={30}>TOEIC</option>
                        </Select>
                    </FormControl>
                    <FormControl margin="dense" variant="outlined" fullWidth>
                        <InputLabel htmlFor="outlined-age-native-simple">Type</InputLabel>
                        <Select
                            native
                            value={this.state.testType}
                            onChange={this.handleChange}
                            label="testType"
                            name="testType"
                            inputProps={{
                                id: 'outlined-age-native-simple',
                            }}
                        >
                            <option aria-label="None" value="" />
                            <option value={10}>Reading</option>
                            <option value={20}>Listening</option>
                            <option value={30}>Speaking</option>
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCloseAddNewTest} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => { this.addNewTest(this.state.newTestName, this.state.testCategory, this.state.testType) }} color="primary">
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
        )

        return (
            <div className={classes.root}>
                <Container maxWidth="lg">
                    <Grid container alignItems='center' className={classes.gridContainer}>
                        <Grid item xs={12}>
                            <Typography align='left' variant="h4" gutterBottom>
                                My tests
                            </Typography>
                        </Grid>
                        {this.state.tests.map((test) => (
                            <Grid item xs={12} className={classes.margin}>
                                <Link to={"/test/management/" + test.name} color="inherit" underline="none" style={{ textDecoration: 'none', color: '#0f0f0f' }}>
                                    <Button variant="outlined" className={classes.addTestButton}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Typography align='left' variant="button" gutterBottom>
                                                    {test.name}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Button>
                                </Link>
                            </Grid>
                        ))}
                        <Grid item xs={12} className={classes.margin}>
                            <Button variant="contained" onClick={this.handleAddNewTest} className={classes.addTestButton}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <AddToPhotosRoundedIcon />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="button" gutterBottom>
                                            ADD
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Button>
                        </Grid>
                    </Grid>
                    {addNewTest}
                </Container>
            </div>
        )
    }
}

export default compose(
    withStyles(useStyles)
)(TestManagement);