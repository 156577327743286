import React from 'react';

import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import MuiAlert from '@material-ui/lab/Alert';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { Container } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Component } from 'react';
import { compose } from 'recompose';

//Icon
import InboxIcon from '@material-ui/icons/Inbox';
import DraftsIcon from '@material-ui/icons/Drafts';

//Others
const axios = require('axios').default;
const FormData = require('form-data');
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

const useStyles = theme => ({
    root: {
        margin: 15,
        marginTop: 50
    }
});

class Menus extends Component {
    constructor() {
        super()
        this.state = {
            breakPoints: [
                { width: 1, itemsToShow: 1 },
                { width: 600, itemsToShow: 2 },
                { width: 900, itemsToShow: 3 },
                { width: 1200, itemsToShow: 4 },
            ],
            window: {
                height: window.innerHeight,
                width: window.innerWidth
            }
        };
    }

    componentDidMount = () => {

    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    handleResize = (event) => {
        this.setState({
            window: {
                height: window.innerHeight,
                width: window.innerWidth
            }
        });
    };

    render() {
        const { classes } = this.props;

        return (
            <Paper style={{ padding: '40px' }}>
                <Grid container>
                    <Grid item xs={12}>
                        <Avatar alt={this.props.user.firstNameChar} src={this.props.user.picture} style={{ width: '100%', height: '100%' }} />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '20px' }}>
                        <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold' }}>
                            {this.props.user.name}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '20px' }}>
                        <Divider variant="middle" />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '20px' }}>
                        <List component="nav" aria-label="main mailbox folders">
                            <ListItem selected button>
                                <ListItemText primary="Performance" />
                            </ListItem>
                            <ListItem disabled button>
                                <ListItemText primary="History" />
                            </ListItem>
                            <ListItem disabled button>
                                <ListItemText primary="Tasks" />
                            </ListItem>
                            <ListItem disabled button>
                                <ListItemText primary="Profit" />
                            </ListItem>
                            <ListItem disabled button>
                                <ListItemText primary="Test status" />
                            </ListItem>
                        </List>
                    </Grid>
                </Grid>
            </Paper>
        )
    }
}
export default compose(
    withStyles(useStyles)
)(Menus);