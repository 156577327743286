import React from 'react';

import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';
import { Component } from 'react';
import { compose } from 'recompose';

//Icon
import AssessmentRoundedIcon from '@material-ui/icons/AssessmentRounded';
import BallotRoundedIcon from '@material-ui/icons/BallotRounded';
import CardMembershipRoundedIcon from '@material-ui/icons/CardMembershipRounded';
import EventRoundedIcon from '@material-ui/icons/EventRounded';
import ImportContactsRoundedIcon from '@material-ui/icons/ImportContactsRounded';

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%'
    },
    img: {
        width: theme.spacing(20),
        height: theme.spacing(20),
    },
});

class UserMenuTab extends Component {
    handleChangeRoleName = (role) => {
        if (role === 'SUPER_ADMIN') {
            return 'Super admin'
        }
        else if (role === 'ADMIN') {
            return 'Admin'
        }
        else if (role === 'HEAD_TEACHER') {
            return 'Head teacher'
        }
        else if (role === 'TEACHER') {
            return 'Teacher'
        }
        else if (role === 'STUDENT') {
            return 'Student'
        }
    }

    render() {
        const { classes } = this.props;

        return (
            <Paper>
                <Grid container style={{ padding: '50px' }}>
                    <Grid xs={12} align='center'>
                        {(this.props.userSignedin && this.props.userInfo.picture.length !== 0) &&
                            <Avatar alt="Remy Sharp" src={this.props.userInfo.picture} className={classes.img} />
                        }
                        {(this.props.userSignedin && this.props.userInfo.picture.length === 0) &&
                            <Avatar className={classes.img}>
                                <Typography component="h1" variant="h1">
                                    {this.props.userInfo.firstNameChar}
                                </Typography>
                            </Avatar>
                        }
                        <Typography component="h5" variant="h5" style={{ marginTop: '10px' }}>
                            {this.props.userInfo.name}
                        </Typography>
                        <Typography component="h7" variant="h7">
                            {this.handleChangeRoleName(this.props.userInfo.role)}
                        </Typography>
                    </Grid>
                    <Grid xs={12} align='center' style={{ padding: '20px' }}>
                        <Divider variant="middle" />
                    </Grid>
                    <Grid xs={12} align='center' style={{ padding: '20px', paddingTop: '0px' }}>
                        <List component="nav" aria-label="mailbox folders" >
                            <ListItem button disabled>
                                <ListItemIcon>
                                    <BallotRoundedIcon />
                                </ListItemIcon>
                                <ListItemText primary="Posts" />
                                <Chip size="small" label={this.props.numPosts} />
                            </ListItem>
                            <ListItem button>
                                <ListItemIcon>
                                    <EventRoundedIcon />
                                </ListItemIcon>
                                <ListItemText primary="Schedules" />
                                <Chip size="small" label={this.props.numSchedules} />
                            </ListItem>
                            <ListItem button>
                                <ListItemIcon>
                                    <ImportContactsRoundedIcon />
                                </ListItemIcon>
                                <ListItemText primary="Courses" />
                                <Chip size="small" label={this.props.purchasedCourse === undefined ? 0 : this.props.purchasedCourse.length} />
                            </ListItem>
                            <ListItem button>
                                <ListItemIcon>
                                    <AssessmentRoundedIcon />
                                </ListItemIcon>
                                <ListItemText primary="Test reports" />
                                <Chip size="small" label={this.props.testReportHistory === undefined ? 0 : this.props.testReportHistory.length} />
                            </ListItem>
                            <ListItem button>
                                <ListItemIcon>
                                    <CardMembershipRoundedIcon />
                                </ListItemIcon>
                                <ListItemText primary="Test History" />
                                <Chip size="small" label={this.props.testHistory === undefined ? 0 : this.props.testHistory.length} />
                            </ListItem>
                        </List>
                    </Grid>
                </Grid>
            </Paper>
        )
    }
}

export default compose(
    withStyles(useStyles)
)(UserMenuTab);