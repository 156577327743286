import React, { useEffect } from 'react';

//MUI
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';

import { ThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';

//Font
import 'typeface-glacial-indifference';

//Others
import { Link } from "react-router-dom";
const axios = require('axios').default;
const FormData = require('form-data');
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

const useStyles = theme => ({
    root: {
        padding: theme.spacing(12, 4),
    }
});

const theme = createMuiTheme({
    typography: {
        fontFamily: [
            'Roboto'
        ].join(','),
    },
});

const SuccessfulLogin = () => {
    useEffect(() => {
        document.body.style.backgroundColor = "#efefef";
        document.querySelector("body").scrollTo(0, 0);
    }, []);

    const mobileUI = (<Grid container style={{ marginTop: "5%", marginBottom: "10%", maxWidth: window.innerWidth }}>
        <Grid item xs={12}>
            <Typography variant="h3" style={{ fontWeight: "bold" }} gutterBottom>
                <span style={{ color: "#991514" }}>U</span>TEST AVENUE
            </Typography>
        </Grid>
        <Grid item xs={12}>
            <CheckCircleRoundedIcon style={{ fontSize: '200px', color: "#419b45" }} />
        </Grid>
        <Grid item xs={12}>
            <Typography variant="h6">
                An account was created successfully!
            </Typography>
            <Typography variant="h6">
                Please login to the website
            </Typography>
        </Grid>
        <Grid item xs={12} style={{ marginTop: "30px" }}>
            <Link
                to="/newlogin"
                style={{ textDecoration: 'none', color: '#565656' }}
            >
                <Button
                    variant="contained"
                    size="large"
                    style={{ backgroundColor: "#880000", color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1rem" }}
                    disableElevation
                >
                    Login
                </Button>
            </Link>
        </Grid>
    </Grid>)

    const webUI = (<Grid container spacing={3} style={{ marginTop: "18%", marginBottom: "25%", maxWidth: window.innerWidth }}>
        <Grid item xs={12}>
            <Typography variant="h2" style={{ fontWeight: "bold" }} gutterBottom>
                <span style={{ color: "#991514" }}>U</span>TEST AVENUE
            </Typography>
        </Grid>
        <Grid item xs={12}>
            <CheckCircleRoundedIcon style={{ fontSize: '200px', color: "#419b45" }} />
        </Grid>
        <Grid item xs={12}>
            <Typography variant="h6">
                An account was created successfully!
            </Typography>
            <Typography variant="h6">
                Please login to the website
            </Typography>
        </Grid>
        <Grid item xs={12}>
            <Link
                to="/newlogin"
                style={{ textDecoration: 'none', color: '#565656' }}
            >
                <Button
                    variant="contained"
                    size="large"
                    style={{ backgroundColor: "#880000", color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1rem" }}
                    disableElevation
                >
                    Login
                </Button>
            </Link>
        </Grid>
    </Grid>)

    return <ThemeProvider theme={theme}>
        <Hidden mdUp>
            {mobileUI}
        </Hidden>
        <Hidden smDown>
            {webUI}
        </Hidden>
    </ThemeProvider>
};

export default compose(
    withStyles(useStyles)
)(SuccessfulLogin);