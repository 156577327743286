//Material UI
import { TextField, Grid, Typography, Button, Avatar, Paper } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';
import Box from '@material-ui/core/Box';
import CardMedia from '@material-ui/core/CardMedia';
import Container from '@material-ui/core/Container';

//React
import { Component } from 'react';

const useStyles = theme => ({
    root: {
        borderTop: '1px solid #ddd',
        height: '60px',
        lineHeight: '60px',
        backgroundColor: 'white'
    },
});

class PageNotFound extends Component {
    componentDidMount = () => {
        document.body.style.backgroundColor = "#efefef";
        document.querySelector("body").scrollTo(0, 0);
    };

    render() {
        const { classes } = this.props;

        return (
            <Grid align="center" item xs={12} style={{ marginTop: "-100px" }}>
                <CardMedia
                    component="img"
                    style={{
                        width: "1000px",
                        backgroundSize: "cover"
                    }}
                    src="graphics/404 not found.png"
                />
            </Grid>
        )
    }
}
export default compose(
    withStyles(useStyles)
)(PageNotFound);
