//React
import { Component } from 'react';
import { compose } from 'recompose';

//Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

//Icon

//react-router-dom

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%'
    }
});

class FullSectionThreeInstructions extends Component {
    constructor() {
        super();
    }

    render() {
        return (
            <Grid container justify="center" style={{ padding: "20%", paddingTop: '20px', paddingBottom: '0px' }}>
                <Paper variant="outlined" style={{
                    padding: '50px',
                    backgroundImage: `url("/U-Test-Logo-new-bw.png")`,
                    backgroundSize: '120px',
                    backgroundRepeat: 'space'
                }}>
                    <Grid container>
                        <Grid item align='right' xs={12}>
                            <CardMedia
                                component="img"
                                alt="Contemplative Reptile"
                                src="/U Test Logo (new).png"
                                title="Contemplative Reptile"
                                style={{ width: '70px' }}
                            />
                            <Typography variant="h6">
                                <Box fontWeight="fontWeightBold" display='inline'> 30 minutes </Box>
                            </Typography>
                            <Typography variant="h6" gutterBottom>
                                <Box fontWeight="fontWeightBold" display='inline'> Writing Task </Box>
                            </Typography>
                        </Grid>
                        <Grid item align='left' xs={12} style={{ marginTop: "-100px" }}>
                            <Typography variant="h5" gutterBottom>
                                <Box fontWeight="fontWeightBold" display='inline'> BioMedical Admissions Simulation Test </Box>
                            </Typography>
                            <Typography variant="body1" style={{ fontWeight: 'bold' }} gutterBottom>
                                SECTION 3
                            </Typography>
                            <Typography variant="body1" style={{ fontWeight: 'bold' }} gutterBottom>
                                Writing Task
                            </Typography>
                            <Typography variant="body1" style={{ fontWeight: 'bold' }} gutterBottom>
                                Please read this page carefully; once you click ‘start’, the timer will begin.
                            </Typography>
                        </Grid>
                        <Grid item align='left' xs={12} style={{ marginTop: '20px' }}>
                            <Typography variant="body1" gutterBottom>
                                This question paper contains a choice of three tasks, of which you must complete only <span style={{ fontWeight: 'bold' }}>one</span>.
                                There is space on the question paper for preliminary notes. <span style={{ fontWeight: 'bold' }}>Your answer must be written in
                                English on the answer sheet provided</span>. No additional answer sheets may be used.
                                <span style={{ fontWeight: 'bold' }}>Candidates with permission to use a word processor must not exceed 550 words.</span>
                            </Typography>
                        </Grid>
                        <Grid item align='left' xs={12} style={{ marginTop: '20px' }}>
                            <Typography variant="body1" gutterBottom>
                                The tasks each provide an opportunity for you to show how well you can select, develop and
                                organise ideas and communicate them effectively in writing.
                            </Typography>
                        </Grid>
                        <Grid item align='left' xs={12} style={{ marginTop: '20px' }}>
                            <Typography variant="body1" gutterBottom>
                                Before you begin writing, take time to think carefully about what you need to say and the ways in
                                which the organisation and layout of your response might help convey your message. Diagrams
                                may be used if they enhance communication.
                            </Typography>
                        </Grid>
                        <Grid item align='left' xs={12} style={{ marginTop: '20px' }}>
                            <Typography variant="body1" gutterBottom>
                                You <span style={{ fontWeight: 'bold' }}>must</span> complete the section within the time limit.
                            </Typography>
                        </Grid>
                        <Grid item align='left' xs={12} style={{ marginTop: '20px' }}>
                            <Typography variant="body1" gutterBottom>
                                You can use the question paper for rough working or notes, but Calculators are NOT permitted.
                            </Typography>
                        </Grid>
                        <Grid item align='left' xs={12} style={{ marginTop: '20px' }}>
                            <Typography variant="body1" gutterBottom>
                                Copyright &reg; UTEST AVENUE 2020
                            </Typography>
                        </Grid>
                        <Grid item align='right' xs={12} style={{ marginTop: '20px' }}>
                            <Button size="small" variant="contained" onClick={this.props.handleCloseSectionThreeInfoPage} style={{ color: 'white', backgroundColor: '#214b4b' }}>
                                <Box fontWeight="fontWeightBold" display='inline'> Start </Box>
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        )
    }
}

export default compose(
    withStyles(useStyles)
)(FullSectionThreeInstructions);
