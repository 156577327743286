import React from 'react';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import MuiAlert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';

//Others
import { withStyles } from '@material-ui/core/styles';
import { Component } from 'react';
import { compose } from 'recompose';
import MaterialTable from 'material-table'

//Icons
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

//Models
import { GetAllStrategy } from '../../../../models/strategy';

//Others
const axios = require('axios').default;
const FormData = require('form-data');
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

const useStyles = theme => ({
    root: {
        margin: 15,
        marginTop: 50
    }
});

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class AllStrategy extends Component {
    constructor() {
        super()
        this.state = {
            selectedPartType: 'reading',
            testsToShow: [],
            isLoadingParts: false
        };
    }

    componentDidMount = () => {
        this.handleGetAllStrategy();
        document.querySelector("body").scrollTo(0, 0);
    }

    handleGetAllStrategy = async () => {
        let strategyToShow = []
        const res = await GetAllStrategy(JSON.parse(localStorage.getItem('user')).token);
        console.log(res);
        await res.data.map((strategy) => {
            strategyToShow.push({
                id: strategy._id,
                test_type: strategy.test_type,
                part_type: strategy.part_type,
                is_free: strategy.is_free,
                strategy_color: strategy.strategy_color
            });
        })

        this.setState({
            strategyToShow: strategyToShow
        });
    }

    render() {
        const { classes } = this.props;

        return (
            <Grid item xs={12} style={{ paddingLeft: '8px' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <MaterialTable
                            data={this.state.strategyToShow}
                            columns={[
                                {
                                    title: 'Strategy id',
                                    field: 'id',
                                    editable: 'never'
                                },
                                {
                                    title: 'Test type',
                                    field: 'test_type',
                                    editable: 'never'
                                },
                                {
                                    title: 'Part Type',
                                    field: 'part_type',
                                    editable: 'never',
                                    defaultSort: 'asc'
                                },
                                {
                                    title: 'Strategy color',
                                    field: 'strategy_color',
                                    editable: 'never'
                                },
                                {
                                    title: 'Is free',
                                    field: 'is_free',
                                    editable: 'never'
                                }
                            ]}
                            options={{
                                actionsColumnIndex: -1,
                                exportAllData: true,
                                exportButton: true,
                                grouping: true,
                                sorting: true,
                                pageSize: 10
                            }}
                            actions={[
                                {
                                    icon: 'edit',
                                    tooltip: 'Edit Test',
                                    onClick: (event, rowData) => this.props.handleSelectStrategy(rowData.id)
                                }
                            ]}
                            isLoading={!this.state.strategyToShow}
                            title='Strategy'
                        />
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}
export default compose(
    withStyles(useStyles)
)(AllStrategy);