//Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import { compose } from 'recompose';

//React
import { Component } from 'react';

//react-router-dom
import {
    Link
} from "react-router-dom";

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        padding: '50px'
    },
});

class SelectTestType extends Component {
    handleSelectPlan = (name, type, description, amount) => {
        this.props.handlePlanChosen(name, type, description, amount)
    }

    render() {
        const { classes } = this.props;

        return (
            <Grid container>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item align='left' xs={6}>
                            <Typography variant="h2" className={classes.pos} style={{ color: "#76323F" }}>
                                <Box fontWeight="fontWeightBold" display='inline' > Select your plan </Box>
                            </Typography>
                        </Grid>
                        <Grid item align='right' xs={6} style={{ marginTop: '35px' }}>
                            <Button onClick={this.props.handleBacktoAllTests}>
                                Back to all tests
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{ padding: '50px', marginTop: '50px', backgroundColor: '#D7CEC7' }}>
                    <Grid container spacing={2}>
                        <Grid item xs>
                            <Card className={classes.root}>
                                <CardContent>
                                    <Typography className={classes.title} gutterBottom>
                                        <Box fontWeight="fontWeightBold" display='inline' style={{ color: "#565656" }}>DEMO TEST</Box>
                                    </Typography>
                                    <Typography variant="h2" className={classes.pos} style={{ color: "#76323F" }}>
                                        FREE
                                    </Typography>
                                    <Typography align='left' className={classes.title} style={{ color: "#565656", marginTop: '20px', width: '70%' }} gutterBottom>
                                        <CheckCircleRoundedIcon style={{ margin: '5px', color: '#8FEB10' }} /> Reading
                                    </Typography>
                                    <Typography align='left' className={classes.title} style={{ color: "#565656", width: '70%' }} gutterBottom>
                                        <CheckCircleRoundedIcon style={{ margin: '5px', color: '#8FEB10' }} /> Listening
                                    </Typography>
                                    <Typography align='left' className={classes.title} style={{ color: "#565656", width: '70%' }} gutterBottom>
                                        <CancelRoundedIcon style={{ margin: '5px', color: '#DB2020' }} /> Writing
                                    </Typography>
                                    <Typography align='left' className={classes.title} style={{ color: "#565656", width: '70%' }} gutterBottom>
                                        <CancelRoundedIcon style={{ margin: '5px', color: '#DB2020' }} /> Speaking
                                    </Typography>
                                    <Typography align='left' className={classes.title} style={{ color: "#565656", width: '70%' }} gutterBottom>
                                        <CancelRoundedIcon style={{ margin: '5px', color: '#DB2020' }} /> Score Analysis
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button variant="contained" style={{ height: '50px', width: '100%', backgroundColor: '#76323F', color: 'white', marginTop: '20px' }}>
                                        <Link to="/test/reading" style={{ textDecoration: 'none', color: 'white' }}>
                                            <Box fontWeight="fontWeightBold" display='inline' >Choose Plan</Box>
                                        </Link>
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                        <Grid item xs>
                            <Card className={classes.root}>
                                <CardContent>
                                    <Typography className={classes.title} gutterBottom>
                                        <Box fontWeight="fontWeightBold" display='inline' style={{ color: "#565656" }}>3 TESTS</Box>
                                    </Typography>
                                    <Typography variant="h2" className={classes.pos} style={{ color: "#76323F" }}>
                                        ฿1,500
                                    </Typography>
                                    <Typography align='left' className={classes.title} style={{ color: "#565656", marginTop: '20px', width: '70%' }} gutterBottom>
                                        <CheckCircleRoundedIcon style={{ margin: '5px', color: '#8FEB10' }} /> Reading
                                    </Typography>
                                    <Typography align='left' className={classes.title} style={{ color: "#565656", width: '70%' }} gutterBottom>
                                        <CheckCircleRoundedIcon style={{ margin: '5px', color: '#8FEB10' }} /> Listening
                                    </Typography>
                                    <Typography align='left' className={classes.title} style={{ color: "#565656", width: '70%' }} gutterBottom>
                                        <CheckCircleRoundedIcon style={{ margin: '5px', color: '#8FEB10' }} /> Writing
                                    </Typography>
                                    <Typography align='left' className={classes.title} style={{ color: "#565656", width: '70%' }} gutterBottom>
                                        <CancelRoundedIcon style={{ margin: '5px', color: '#DB2020' }} /> Speaking
                                    </Typography>
                                    <Typography align='left' className={classes.title} style={{ color: "#565656", width: '70%' }} gutterBottom>
                                        <CheckCircleRoundedIcon style={{ margin: '5px', color: '#8FEB10' }} /> Score Analysis
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button
                                        variant="contained"
                                        style={{ height: '50px', width: '100%', backgroundColor: '#76323F', color: 'white', marginTop: '20px' }}
                                        onClick={() => { this.handleSelectPlan('IELTS®', '3 Tests', 'This test include Reading, Writing and Listening test', 1500 * 100) }}
                                    >
                                        <Box
                                            fontWeight="fontWeightBold"
                                            display='inline'
                                        >
                                            Choose Plan
                                        </Box>
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                        <Grid item xs>
                            <Card className={classes.root}>
                                <CardContent align='center'>
                                    <Box style={{ color: 'white', backgroundColor: '#FFC300', width: '70%' }}>
                                        <Typography className={classes.title} gutterBottom>
                                            <Box fontWeight="fontWeightBold" display='inline'>BEST PRICE</Box>
                                        </Typography>
                                    </Box>
                                    <Typography className={classes.title} gutterBottom>
                                        <Box fontWeight="fontWeightBold" display='inline' style={{ color: "#565656" }}>FULL TEST</Box>
                                    </Typography>
                                    <Typography variant="h2" className={classes.pos} style={{ color: "#76323F" }}>
                                        ฿2,000
                                    </Typography>
                                    <Typography align='left' className={classes.title} style={{ color: "#565656", marginTop: '20px', width: '70%' }} gutterBottom>
                                        <CheckCircleRoundedIcon style={{ margin: '5px', color: '#8FEB10' }} /> Reading
                                    </Typography>
                                    <Typography align='left' className={classes.title} style={{ color: "#565656", width: '70%' }} gutterBottom>
                                        <CheckCircleRoundedIcon style={{ margin: '5px', color: '#8FEB10' }} /> Listening
                                    </Typography>
                                    <Typography align='left' className={classes.title} style={{ color: "#565656", width: '70%' }} gutterBottom>
                                        <CheckCircleRoundedIcon style={{ margin: '5px', color: '#8FEB10' }} /> Writing
                                    </Typography>
                                    <Typography align='left' className={classes.title} style={{ color: "#565656", width: '70%' }} gutterBottom>
                                        <CheckCircleRoundedIcon style={{ margin: '5px', color: '#8FEB10' }} /> Speaking
                                    </Typography>
                                    <Typography align='left' className={classes.title} style={{ color: "#565656", width: '70%' }} gutterBottom>
                                        <CheckCircleRoundedIcon style={{ margin: '5px', color: '#8FEB10' }} /> Score Analysis
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button
                                        variant="contained"
                                        style={{ height: '50px', width: '100%', backgroundColor: '#76323F', color: 'white', marginTop: '20px' }}
                                        onClick={() => { this.handleSelectPlan('IELTS®', 'Full test', 'This test include Reading, Writing, Listening and Speaking test with score analysis', 2000 * 100) }}
                                    >
                                        <Box fontWeight="fontWeightBold" display='inline'>Choose Plan</Box>
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}
export default compose(
    withStyles(useStyles)
)(SelectTestType);