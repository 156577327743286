import React from 'react';

//Material UI
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import MuiAlert from '@material-ui/lab/Alert';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

//Question type
import YesNoNG from './AddSections/YesNoNG'
import TrueFalseNG from './AddSections/TrueFalseNG';
import MultipleChoice from './AddSections/MultipleChoice';
import NoteCompletion from './AddSections/NoteCompletion';
import ListOfHeading from './AddSections/ListOfHeading';
import MatchingSentenceEndings from './AddSections/MatchingSentenceEndings';
import CompleteTheNotes from './AddSections/CompleteTheNotes';
import CompleteTheTables from './AddSections/CompleteTheTables'
import Matching from './AddSections/Matching';
import Map from './AddSections/Map';
import Choose2Letters from './AddSections/Choose2Letters';
import WritingBaseQuestion from './AddSections/WritingBaseQuestion';

//Icons
import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';

//Models
import { GetAllStrategy, UpdateStrategy } from '../../../../models/strategy';
import { GetAllParts } from '../../../../models/addPart';

//Others
import { withStyles } from '@material-ui/core/styles';
import { Component } from 'react';
import { compose } from 'recompose';
import { Link } from "react-router-dom";
import Select from 'react-select'

const axios = require('axios').default;
const FormData = require('form-data');
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

const useStyles = theme => ({
    root: {
        margin: 15,
        marginTop: 50
    },
    margin: {
        margin: theme.spacing(1),
    },
    buttonColor: {
        backgroundColor: '#76323F',
        color: 'white'
    }
});

const MainCheckbox = withStyles({
    root: {
        color: "#76323F",
        '&$checked': {
            color: "#76323F",
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class EditStrategy extends Component {
    constructor() {
        super()
        this.state = {
            currentStrategyArr: [],
            addedStrategyArr: [],
            addedPossibleVersion: [],
            tempVersionArr: [],
            isLoadingParts: true,
            isCreated: false,
            isLoading: false,
            is_free: false,
            isLoadingQuestionType: false
        };
    }

    componentDidMount = async () => {
        document.querySelector("body").scrollTo(0, 0);
        await this.handleSetupObj();
        await this.handleGetAllStrategy();
        this.handleGetAllSubmittedTests();
    }

    //==================================== PREPARATION =======================================
    handleSetupObj = async () => {
        await this.setState({
            strategyObj: {
                test_type: "",
                part_type: "",
                is_free: false,
                strategy_color: "",
                strategy: this.state.addedStrategyArr
            }
        })
    }

    handleGetAllStrategy = async () => {
        const res = await GetAllStrategy(JSON.parse(localStorage.getItem('user')).token);
        const strategyObj = await res.data.filter((strategy) => strategy._id === this.props.match.params.strategy_id);
        let tempStrategyArr = [];
        strategyObj[0].strategy.map((strategy, index) => {
            tempStrategyArr.push({
                part: strategy.part,
                possible_version: strategy.possible_version
            });

            strategy.possible_version.map((version) => {
                if (version && this.state.tempVersionArr[index] === undefined) {
                    this.state.tempVersionArr[index] = [{
                        value: version,
                        label: version
                    }];
                } if (version && this.state.tempVersionArr[index] !== undefined) {
                    const i = this.state.tempVersionArr[index].findIndex(_item => _item.value === version);
                    if (i > -1) {
                        console.log(version);
                    } else {
                        this.state.tempVersionArr[index].push({
                            value: version,
                            label: version
                        });
                    }
                }
            })
        })

        strategyObj[0].strategy = tempStrategyArr;
        this.setState({
            strategyObj: strategyObj[0],
            tempVersionArr: this.state.tempVersionArr,
            currentStrategyArr: this.state.tempVersionArr
        });
    }

    handleSelectStrategyVersion = async (event, index) => {
        if (event === null) {
            this.state.addedPossibleVersion[index] = event;
            this.setState({
                addedPossibleVersion: this.state.addedPossibleVersion
            })
        } else if (event !== null) {
            this.state.addedPossibleVersion[index] = event;
            this.setState({
                addedPossibleVersion: this.state.addedPossibleVersion
            });
        }

        console.log(this.state.addedPossibleVersion)
    }

    handleSelectStrategyVersionExistingPart = async (event, index) => {
        if (event === null) {
            this.state.currentStrategyArr[index] = event;
            this.setState({
                currentStrategyArr: this.state.currentStrategyArr
            })
        } else if (event !== null) {
            this.state.currentStrategyArr[index] = event;
            this.setState({
                currentStrategyArr: this.state.currentStrategyArr
            });
        }

        console.log(this.state.currentStrategyArr)
    }

    handleCheckIsFreeTest = async (event) => {
        this.state.strategyObj.is_free = !this.state.is_free;

        await this.setState({
            strategyObj: this.state.strategyObj,
            is_free: !this.state.is_free
        });
    }

    handleLoadingQuestionType = () => {
        this.setState({
            isLoadingQuestionType: true
        });
    }

    handleEndLoadingQuestionType = () => {
        this.setState({
            isLoadingQuestionType: false
        });
    }
    //==================================== PREPARATION =======================================

    handleAddStrategy = async () => {
        await this.state.addedStrategyArr.push({
            part: undefined,
            possible_version: []
        });

        this.setState({
            addedStrategyArr: this.state.addedStrategyArr
        });
    }

    handleAddPossibleVersion = async (event, index) => {
        this.state.addedStrategyArr[index].possible_version = event.target.value.split(",");
        this.setState({
            addedStrategyArr: this.state.addedStrategyArr
        });
    }

    handleRemovePossibleVersion = async (event, index) => {
        this.state.addedStrategyArr.splice(index, 1);
        this.setState({
            addedStrategyArr: this.state.addedStrategyArr
        });
    }

    handleEditPart = async (event, index) => {
        this.state.addedStrategyArr[index].part = Number(event.target.value);
        this.setState({
            addedStrategyArr: this.state.addedStrategyArr
        });
    }

    handleGetAllSubmittedTests = async () => {
        try {
            const readingToshow = [];
            const listeningToshow = [];
            const writingToshow = [];
            const bmatToshow = [];

            this.setState({
                isLoadingParts: true
            });

            const res = await GetAllParts(JSON.parse(localStorage.getItem('user')).token);

            const resData = res.res;

            const readingArr = await resData.data.filter((part) => part.part_type === "READING");
            const listeningArr = await resData.data.filter((part) => part.part_type === "LISTENING");
            const writingArr = await resData.data.filter((part) => part.part_type === "WRITING");
            const bmatArr = await resData.data.filter((part) => part.part_type !== "READING" && part.part_type !== "LISTENING" && part.part_type !== "WRITING");

            await readingArr.map((part) => {
                readingToshow.push({
                    value: part.version,
                    label: part.version
                });
            });

            await listeningArr.map((part) => {
                listeningToshow.push({
                    value: part.version,
                    label: part.version
                });
            });

            await writingArr.map((part) => {
                writingToshow.push({
                    value: part.version,
                    label: part.version
                });
            });

            await bmatArr.map((part) => {
                bmatToshow.push({
                    value: part.version,
                    label: part.version
                });
            });

            await this.setState({
                readingToshow: readingToshow,
                listeningToshow: listeningToshow,
                writingToshow: writingToshow,
                bmatToshow: bmatToshow,
                partInfo: resData,
                isLoadingParts: false
            });
        } catch (err) {
            console.log(err);
            this.setState({
                isLoadingParts: false
            });
        }
    }

    //================================ EDIT STRATEGY =================================
    handleEditStrategy = async () => {
        if (this.state.addedStrategyArr.length !== 0) {
            this.state.addedPossibleVersion.map((version, index) => {
                let tempVersionArr = [];
                version.map((obj) => {
                    tempVersionArr.push(obj.value);
                });

                this.state.addedStrategyArr[index].possible_version = tempVersionArr;
                this.state.strategyObj.strategy[this.state.currentStrategyArr.length + index] = this.state.addedStrategyArr[index];
            });
        }

        await this.state.currentStrategyArr.map((version, index) => {
            let tempCurrentStrategyArr = [];

            version.map((obj) => {
                tempCurrentStrategyArr.push(obj.value);
            })

            this.state.strategyObj.strategy[index].possible_version = tempCurrentStrategyArr;
        });

        const response = await UpdateStrategy(JSON.parse(localStorage.getItem('user')).token, this.state.strategyObj);
        this.setState({
            currentStrategyArr: [],
            addedStrategyArr: [],
            addedPossibleVersion: [],
            tempVersionArr: [],
            isLoadingParts: true,
            isCreated: false,
            isLoading: false,
            is_free: false,
            isLoadingQuestionType: false
        });

        await this.handleSetupObj();
        await this.handleGetAllStrategy();
        this.handleGetAllSubmittedTests();
    }

    render() {
        const { classes } = this.props;

        return (
            <Grid item xs={12} style={{ padding: '100px' }}>
                <Grid container spacing={2} style={{ padding: '20px' }}>
                    <Grid item align='left' xs={12}>
                        <Typography variant="h4" style={{ fontWeight: 'bold' }} gutterBottom>
                            Edit Strategy
                        </Typography>
                    </Grid>

                    <Grid item align='left' xs={12}>
                        <Typography variant="h5" style={{ fontWeight: 'bold' }} gutterBottom>
                            Test id
                        </Typography>
                    </Grid>
                    <Grid item align='left' xs={6}>
                        <TextField
                            disabled
                            id="outlined-basic-title"
                            variant="outlined"
                            size="small"
                            value={this.state.strategyObj?._id}
                            fullWidth
                        />
                    </Grid>

                    <Grid item align='left' xs={12}>
                        <Typography variant="h5" style={{ fontWeight: 'bold' }} gutterBottom>
                            Test type
                        </Typography>
                    </Grid>
                    <Grid item align='left' xs={6}>
                        <TextField
                            disabled
                            id="outlined-basic-title"
                            variant="outlined"
                            size="small"
                            value={this.state.strategyObj?.test_type}
                            fullWidth
                        />
                    </Grid>
                    <Grid item align='left' xs={12}>
                        <Typography variant="h5" style={{ fontWeight: 'bold' }} gutterBottom>
                            Section type
                        </Typography>
                    </Grid>
                    <Grid item align='left' xs={6}>
                        <TextField
                            disabled
                            id="outlined-basic-title"
                            variant="outlined"
                            size="small"
                            value={this.state.strategyObj?.part_type}
                            fullWidth
                        />
                    </Grid>
                    <Grid item align='left' xs={12}>
                        <Typography variant="h5" style={{ fontWeight: 'bold' }} gutterBottom>
                            Strategy type
                        </Typography>
                    </Grid>
                    <Grid item align='left' xs={6}>
                        <TextField
                            disabled
                            id="outlined-basic-title"
                            variant="outlined"
                            size="small"
                            value={this.state.strategyObj?.strategy_color}
                            fullWidth
                        />
                    </Grid>

                    <Grid item align='left' xs={12}>
                        <Typography variant="h5" style={{ fontWeight: 'bold' }} gutterBottom>
                            Strategy
                        </Typography>
                    </Grid>
                    <Grid item align='left' xs={12}>
                        {/*============================= EXISTING PART =============================*/}
                        {this.state.strategyObj?.strategy.map((question, index) =>
                            <Grid key={`added-question-${index}`} container spacing={1}>
                                <Grid item align='left' xs={11}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={2}>
                                            <TextField
                                                disabled
                                                label="Section"
                                                name="part"
                                                id="outlined-basic-title"
                                                variant="outlined"
                                                size="small"
                                                value={question.part}
                                                onChange={(event) => { this.handleEditPart(event, index) }}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={10}>
                                            <Select
                                                isMulti
                                                isLoading={!this.state.strategyObj.part_type}
                                                name="colors"
                                                defaultValue={this.state.tempVersionArr[index]}
                                                options={this.state.strategyObj.part_type ?
                                                    this.state[`${this.state.strategyObj.part_type.toLowerCase()}Toshow`] : []}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                onChange={(event) => { this.handleSelectStrategyVersionExistingPart(event, index) }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}

                        {/*============================= NEW PART =============================*/}
                        {this.state.addedStrategyArr.map((question, index) =>
                            <Grid key={`added-question-${index}`} container spacing={1}>
                                <Grid item align='left' xs={11}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={2}>
                                            <TextField
                                                label="Section"
                                                name="part"
                                                id="outlined-basic-title"
                                                variant="outlined"
                                                size="small"
                                                onChange={(event) => { this.handleEditPart(event, index) }}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={10}>
                                            <Select
                                                isMulti
                                                isLoading={!this.state.strategyObj.part_type}
                                                name="colors"
                                                options={this.state.strategyObj.part_type ? this.state[`${this.state.strategyObj.part_type.toLowerCase()}Toshow`] : []}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                onChange={(event) => { this.handleSelectStrategyVersion(event, index) }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={1}>
                                    <IconButton aria-label="delete" onClick={(event) => { this.handleRemovePossibleVersion(event, index) }} >
                                        <DeleteForeverRoundedIcon fontSize="small" />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                    <Grid item align='left' xs={12}>
                        <IconButton aria-label="add" onClick={this.handleAddStrategy}>
                            <AddBoxRoundedIcon fontSize="small" />
                        </IconButton>
                    </Grid>

                    <Grid item align='left' xs={12} style={{ marginTop: "50px" }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    disabled
                                    checked={!this.state.strategyObj?.is_free}
                                    name="isFree"
                                    color="primary"
                                />
                            }
                            label="This strategy is for free tests."
                        />
                    </Grid>

                    <Grid item align='center' xs={12} style={{ marginTop: '50px' }}>
                        <Link to={`/dashboard/test-admin?from=strategy`} style={{ textDecoration: 'none', color: '#0f0f0f' }}>
                            <Button
                                variant="outlined"
                                onClick={this.handleCreateNewStrategy}
                                style={{ color: '#76323F', borderColor: '#76323F', marginRight: "10px" }}
                            >
                                Back
                            </Button>
                        </Link>
                        <Button
                            variant="contained"
                            onClick={this.handleEditStrategy}
                            style={{ color: 'white', backgroundColor: '#76323F' }}
                        >
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}
export default compose(
    withStyles(useStyles)
)(EditStrategy);