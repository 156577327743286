//React
import React, { Component } from "react";
import { compose } from "recompose";

import { withStyles } from "@material-ui/core/styles";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";

//Constants
import * as styleConstants from "../../../constants/styleConstants";

//Others
import AboutUsSectionWeb from "./web/AboutUsSectionWeb";
import AboutUsSectionMobile from "./mobile/AboutUsSectionMobile";
import { Hidden } from "@material-ui/core";

const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  rootContainer: {
    maxWidth: "75%",
  },
  rootCard: {
    maxWidth: "75%",
    width: "75%",
    margin: 10,
  },
  gridContainer: {
    maxWidth: "90%",
  },
  margin: {
    margin: 100,
  },
  marginTop: {
    marginTop: 100,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(5),
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  header: {
    maxWidth: "80%",
  },
  body: {
    maxWidth: "80%",
    marginTop: 30,
  },
  divider: {
    maxWidth: "100%",
  },
  image: {
    margin: "auto",
    display: "block",
    margin: 10,
  },
  commentTextField: {
    width: "100%",
    maxWidth: "100%",
  },
  commentGridItem: {
    maxWidth: "80%",
    marginTop: 30,
    marginBottom: 100,
  },
});

const theme = createMuiTheme({
  typography: {
    fontFamily: [`${styleConstants.fontStyle}`].join(","),
  },
});

class AboutUsSection extends Component {
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <Hidden lgUp>
          <AboutUsSectionMobile />
        </Hidden>
        <Hidden mdDown>
          <AboutUsSectionWeb />
        </Hidden>
      </ThemeProvider>
    );
  }
}

export default compose(withStyles(useStyles))(AboutUsSection);
