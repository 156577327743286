import React from 'react';

//Material UI
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import MuiAlert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

//Models
import { CreateSectionHistory } from '../../../../../../models/sectionHistory.js';

//Others
import { withStyles } from '@material-ui/core/styles';
import { Component } from 'react';
import { compose } from 'recompose';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Select from 'react-select'

const axios = require('axios').default;
const FormData = require('form-data');
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

const useStyles = theme => ({
    root: {
        margin: 15,
        marginTop: 50
    },
    margin: {
        margin: theme.spacing(1),
    },
    buttonColor: {
        backgroundColor: '#76323F',
        color: 'white'
    }
});

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class NoteCompletion extends Component {
    constructor() {
        super()
        this.state = {
        };
    }

    componentDidMount = async () => {
        if (this.props.sectionId !== undefined) {
            await this.handleGetSectionObj();
        }
    }

    handleGetSectionObj = async () => {
        if (this.props.sectionId !== undefined && this.props.sectionObj === undefined) {
            try {
                const res = await axios({
                    method: 'get',
                    url: api_base_url + '/api/test/section/id/' + this.props.sectionId,
                    headers: {
                        "Authorization": "Bearer " + JSON.parse(localStorage.getItem('user')).token
                    }
                });
                console.log(res);

                const resData = res.data.section;
                let resDataTemp = JSON.parse(JSON.stringify(resData));

                console.log(resData);

                await this.setState({
                    sectionObj: resData,
                    tempSectionObj: resDataTemp
                });
            } catch (err) {
                console.log(err);
            }
        } else if (this.props.sectionObj !== undefined) {
            await this.setState({
                sectionObj: this.props.sectionObj,
                tempSectionObj: this.props.sectionObj,
                tempAnsArr: []
            });
        }
    }

    // =============================== EDIT SECTION ==================================
    handleEditText = (data) => {
        this.state.sectionObj.questions[0].text = data;
        this.setState({
            sectionObj: this.state.sectionObj
        });

        console.log(this.state.sectionObj)
    }

    handleEditQuestion = async (event) => {
        this.state.sectionObj.questions[0].question = event.target.value;
        this.setState({
            sectionObj: this.state.sectionObj
        });
    }

    handleEditAnswer = async (event, index) => {
        this.state.sectionObj.questions[0].answer[index] = event.target.value.split(',');
        this.setState({
            sectionObj: this.state.sectionObj
        });
    }

    handleEditAnswerNoteCompletion = async (event, index) => {
        this.state.sectionObj.questions[index].answer = event.target.value.split(',');
        this.setState({
            sectionObj: this.state.sectionObj
        });
    }

    handlePutEditSection = async () => {
        if (this.props.sectionId !== undefined) {
            try {
                const res = await axios({
                    method: 'put',
                    url: api_base_url + '/api/test/section',
                    headers: {
                        "Authorization": "Bearer " + JSON.parse(localStorage.getItem('user')).token
                    },
                    data: this.state.sectionObj
                });

                await CreateSectionHistory(this.state.tempSectionObj, this.state.sectionObj, this.props.sectionId, this.props.test_id, this.props.selectedPart);
                await this.handleGetSectionObj();
                this.props.handleEndEditSection();
            } catch (err) {
                console.log(err);
            }
        }
    }
    // =============================== EDIT SECTION ==================================

    render() {
        const { classes } = this.props;

        return (
            <Grid item xs={12}>
                {this.state.sectionObj !== undefined && <Grid container spacing={1}>
                    {this.state.sectionObj.questions.map((question, index) =>
                        <Grid item key={'question' + index} xs={12}>
                            <Grid container spacing={1}>
                                {this.state.sectionObj.question_type === "noteCompletion" &&
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                                                    Question number
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    disabled
                                                    id="outlined-size-small"
                                                    variant="outlined"
                                                    size="small"
                                                    value={question.no}
                                                    fullWidth
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }
                                {this.state.sectionObj.question_type === "completeTheNotes" ?
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                                                    Question
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    disabled={!(this.props.selectedSectionId === this.state.sectionObj.section_id)}
                                                    id="outlined-size-small"
                                                    variant="outlined"
                                                    size="small"
                                                    value={question.question}
                                                    onChange={(event) => { this.handleEditQuestion(event) }}
                                                    fullWidth
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid> : <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                                                    Title
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    disabled={!(this.props.selectedSectionId === this.state.sectionObj.section_id)}
                                                    id="outlined-size-small"
                                                    variant="outlined"
                                                    size="small"
                                                    value={question.title}
                                                    onChange={(event) => { this.handleEditQuestion(event) }}
                                                    fullWidth
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                                                Context
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CKEditor
                                                disabled={!(this.props.selectedSectionId === this.state.sectionObj.section_id)}
                                                editor={ClassicEditor}
                                                data={question.text}
                                                onReady={editor => {
                                                    // You can store the "editor" and use when it is needed.
                                                    console.log('Editor is ready to use!', editor);
                                                }}
                                                onChange={(event, editor) => {
                                                    const data = editor.getData();
                                                    this.handleEditText(data)
                                                    console.log({ event, editor, data });
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {question.type === "sentence" && <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                                                Choices
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={1}>
                                                {question.choice.map((choice, choiceIdx) => {
                                                    return (
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                // disabled={!(this.props.selectedSectionId === this.state.sectionObj.section_id)}
                                                                disabled={true}
                                                                id="outlined-size-small"
                                                                variant="outlined"
                                                                size="small"
                                                                value={choice}
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                    )
                                                })}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>}
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                                                Answer
                                            </Typography>
                                        </Grid>
                                        {this.state.sectionObj.question_type === "completeTheNotes" && question.answer.map((text, ansIdx) =>
                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item align='center' xs={1}>
                                                        <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                                                            {Number(question.no.split('-')[0]) + ansIdx}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={11}>
                                                        <TextField
                                                            disabled={!(this.props.selectedSectionId === this.state.sectionObj.section_id)}
                                                            id="outlined-size-small"
                                                            variant="outlined"
                                                            size="small"
                                                            value={text}
                                                            onChange={(event) => { this.handleEditAnswer(event, ansIdx) }}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )}
                                        {this.state.sectionObj.question_type === "noteCompletion" &&
                                            <Grid item xs={12}>
                                                <TextField
                                                    disabled={!(this.props.selectedSectionId === this.state.sectionObj.section_id)}
                                                    id="outlined-size-small"
                                                    variant="outlined"
                                                    size="small"
                                                    value={question.answer}
                                                    onChange={(event) => { this.handleEditAnswerNoteCompletion(event, index) }}
                                                    fullWidth
                                                />
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>

                                {this.state.sectionObj.question_type === "noteCompletion" && this.state.sectionObj.questions.length !== (index + 1) &&
                                    <Grid item xs={12} style={{ marginTop: '10px' }}>
                                        <Divider style={{ backgroundColor: "#76323F", height: '5px' }} />
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    )}

                    {this.state.sectionObj !== undefined && (this.props.selectedSectionId === this.state.sectionObj.section_id) &&
                        <Grid item align='right' xs={12}>
                            <Button variant="contained" size="medium" color="primary" onClick={this.handlePutEditSection} style={{ marginRight: '5px', backgroundColor: '#76323F' }}>
                                Confirm
                            </Button>
                            <Button variant="contained" size="medium" color="default" onClick={this.props.handleEndEditSection} style={{ marginLeft: '5px', color: 'grey' }}>
                                Cancel
                            </Button>
                        </Grid>
                    }
                </Grid>
                }
            </Grid>
        )
    }
}
export default compose(
    withStyles(useStyles)
)(NoteCompletion);