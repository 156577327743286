import React, { useEffect } from 'react';

//MUI
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Paper from '@material-ui/core/Paper';
//import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { ThemeProvider, createMuiTheme, makeStyles, withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';

//Font
import 'typeface-glacial-indifference';

//Components
import SocialSuccessfulLogin from './components/loginPage/SocialSuccessfulLogin';

//Services
import { AddNewSchool, GetAllSchools } from '../../../services/registrationServices';
import { GetAdminToken } from '../../../services/token';
import { CreateNewUser, LoginWithGoogle, UpdateUserInfo } from '../../../services/userServices';

//Others
import 'react-phone-input-2/lib/style.css';
import { Link, useHistory } from "react-router-dom";
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import ReactFlagsSelect from "react-flags-select";

const useStyles = makeStyles((theme) => ({
    input: {
        background: "white",
        fontSize: "1rem"
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    }
}));

const theme = createMuiTheme({
    typography: {
        fontFamily: [
            'Roboto'
        ].join(','),
    },
});

const SocialRegistrationPage = () => {
    //Components
    const [schools, setSchools] = React.useState([]);
    const [newSchool, setNewSchool] = React.useState("");
    const [isAddSchool, setIsAddSchool] = React.useState(false);
    const genderChoice = [
        { value: 1, label: 'Male' },
        { value: 0, label: 'Female' },
        { value: 3, label: 'Others' }
    ];
    const [country, setCountry] = React.useState("");
    const [referralCode, setReferralCode] = React.useState("");

    //Token
    const [adminToken, setAdminToken] = React.useState("");

    //Validation
    const [isEmailError, setIsEmailError] = React.useState(false);
    const [isFirstNameError, setIsFirstNameError] = React.useState(false);
    const [isLastNameError, setIsLastNameError] = React.useState(false);
    const [isSchoolError, setIsSchoolError] = React.useState(false);
    const [values, setValues] = React.useState({
        showPassword: false,
        showCPassword: false,
    });

    //User
    const [username, setUsername] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [cPassword, setCPassword] = React.useState("");
    const [firstname, setFirstname] = React.useState("");
    const [lastname, setLastname] = React.useState("");
    const [school, setSchool] = React.useState("");
    const [gender, setGender] = React.useState(3);
    const [phoneNumber, setPhoneNumber] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [selectedDate, setSelectedDate] = React.useState(new Date());

    //Flow
    const [isDoneSignUp, setIsDoneSignUp] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [isLoadingSchools, setIsLoadingSchools] = React.useState(false);

    const history = useHistory();

    useEffect(async () => {
        //Get query paramns
        const params = await new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });

        document.body.style.overflow = "auto";
        document.body.style.backgroundColor = "#efefef";
        document.querySelector("body").scrollTo(0, 0);
        await getUserInformation(params.token);
        const tokenObj = await GetAdminToken();
        setupAdminToken(tokenObj);
        getAllSchools();
    }, []);

    const getUserInformation = async (userToken) => {
        setIsLoading(true);
        const res = await LoginWithGoogle(userToken);
        console.log(res.res);

        if (res.success) {
            setUsername(res.res.username);
            setEmail(res.res.email);
            setFirstname(res.res.firstname);
            setLastname(res.res.lastname);
        }

        setIsLoading(false);
        return;
    }

    const getAllSchools = async () => {
        setIsLoadingSchools(true);

        const schoolObj = await GetAllSchools();
        console.log(schoolObj);
        setSchools(schoolObj.res);

        setIsLoadingSchools(false);
    }

    //Selection
    const handleChangeSchool = async (event) => {
        if (event === null) {
            setIsAddSchool(false);
            setNewSchool("");
            setSchool("");
        } if (event?.__isNew__ !== undefined) {
            if (event.__isNew__ === true) {
                setIsAddSchool(true);
                setNewSchool(event.value);
                setSchool(event.value);
            }
        } if (event !== null && event.__isNew__ !== true) {
            setSchool(event.value);
        }

        console.log(isAddSchool, newSchool);
    }


    const handleChangeGender = async (event) => {
        if (event === null) {
            setGender("");
        } if (event !== null) {
            setGender(event.value);
        }
    }

    const setupAdminToken = (resObj) => {
        if (resObj.success) {
            setAdminToken(resObj.res.token.token);
        }
    }

    const registerNewUser = async () => {
        setIsLoading(true);

        const isValidEmail = await validateEmail(email);
        await setIsEmailError(!isValidEmail);
        await setIsFirstNameError(firstname.length === 0);
        await setIsLastNameError(lastname.length === 0);
        await setIsSchoolError(school.length === 0);

        if (!isFirstNameError && !isLastNameError && !isSchoolError && isValidEmail) {
            const res = await UpdateUserInfo({
                username: username,
                firstname: firstname,
                lastname: lastname,
                firstname_th: "",
                lastname_th: "",
                gender: Number(gender),
                phoneNumber: phoneNumber,
                email: email,
                school: school,
                birthDate: new Date(selectedDate).toDateString(),
                token: "MzcEvOEct6smAZL4O3YXUOS83V2O6bzN",
                referralCode: referralCode,
                country: country
            });

            if (isAddSchool) {
                await AddNewSchool(newSchool);
            } if (res.success) {
                setIsDoneSignUp(true);
            }
        }

        setIsLoading(false);
        return;
    }

    const handleChangePhoneNumber = (input_str) => {
        const re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
        setPhoneNumber(input_str);
        return re.test(input_str);
    }

    const handleChangeFirstName = (input_str) => {
        let value = input_str;
        value = value.replace(/[^ก-๙]+$/gi, "");

        setFirstname(value);
    }

    const handleChangeLastname = (input_str) => {
        let value = input_str;
        value = value.replace(/[^ก-๙]+$/gi, "");

        setLastname(value);
    }

    const validateEmail = (email) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleClickShowCPassword = () => {
        setValues({ ...values, showCPassword: !values.showCPassword });
    };

    const handleMouseDownCPassword = (event) => {
        event.preventDefault();
    };

    const classes = useStyles();

    const mobileUI = (<Grid container style={{ marginTop: "10%", marginBottom: "10%", maxWidth: window.innerWidth }}>
        <Grid item xs={12}>
            <Typography variant="h3" style={{ fontWeight: "bold" }} gutterBottom>
                <span style={{ color: "#991514" }}>U</span>TEST AVENUE
            </Typography>
        </Grid>
        <Grid item xs={12} style={{ marginBottom: "2%", paddingLeft: "10%", paddingRight: "10%" }}>
            <Button
                disabled
                variant="contained"
                size="large"
                style={{ backgroundColor: "#991514", color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1.3rem" }}
                disableElevation
            >
                Sign up
            </Button>
        </Grid>
        <Grid item xs={12} style={{ paddingLeft: "5%", paddingRight: "5%", marginTop: "-7%" }}>
            <Paper elevation={0} style={{ backgroundColor: "#a6a6a6", borderRadius: "25px", padding: "10%" }}>
                <Grid item align="center" xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                error={isFirstNameError}
                                value={firstname}
                                id="outlined-basic-firstname"
                                label="First name*"
                                variant="outlined"
                                size="small"
                                type="input"
                                onChange={(event) => { setFirstname(event.target.value.replace(/[^A-Za-z]/gi, "")) }}
                                InputProps={{ className: classes.input }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                error={isLastNameError}
                                value={lastname}
                                id="outlined-basic-lastname"
                                label="Last name*"
                                variant="outlined"
                                size="small"
                                type="input"
                                onChange={(event) => { setLastname(event.target.value.replace(/[^A-Za-z]/gi, "")) }}
                                InputProps={{ className: classes.input }}
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                error={isEmailError}
                                id="outlined-basic-email"
                                label="Email*"
                                variant="outlined"
                                size="small"
                                type="input"
                                onChange={(event) => { setEmail(event.target.value) }}
                                InputProps={{ className: classes.input }}
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <FormControl variant="outlined" size="small" fullWidth>
                                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    type={values.showPassword ? 'text' : 'password'}
                                    value={values.password}
                                    style={{ backgroundColor: "white" }}
                                    onChange={(event) => { setPassword(event.target.value) }}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    labelWidth={70}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl variant="outlined" size="small" fullWidth>
                                <InputLabel htmlFor="outlined-adornment-cpassword">Confirm password</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-cpassword"
                                    error={password !== cPassword}
                                    type={values.showCPassword ? 'text' : 'password'}
                                    value={values.password}
                                    style={{ backgroundColor: "white" }}
                                    onChange={(event) => { setCPassword(event.target.value) }}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowCPassword}
                                                onMouseDown={handleMouseDownCPassword}
                                                edge="end"
                                            >
                                                {values.showCPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    labelWidth={70}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item align="left" xs={12} sm={6}>
                            <TextField
                                defaultValue={"2022-01-01"}
                                type="date"
                                id="outlined-basic-phone"
                                label="Birth date"
                                variant="outlined"
                                size="small"
                                onChange={(event) => { setSelectedDate(event.target.value) }}
                                InputProps={{ className: classes.input }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                isClearable={false}
                                isSearchable={false}
                                name="School"
                                options={genderChoice}
                                style={{ maxWidth: "200px" }}
                                onChange={handleChangeGender}
                            />
                        </Grid>
                        <Grid item align="left" xs={12}>
                            <TextField
                                type="tel"
                                id="outlined-basic-phone"
                                label="Phone"
                                variant="outlined"
                                size="small"
                                onChange={(event) => { handleChangePhoneNumber(event.target.value) }}
                                InputProps={{ className: classes.input }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item align="left" xs={12}>
                            <CreatableSelect
                                isClearable
                                onChange={handleChangeSchool}
                                options={schools}
                                style={{ maxWidth: "200px" }}
                                name="School"
                            />
                        </Grid>
                        {isEmailError &&
                            <Grid item xs={12}>
                                <Button
                                    disabled
                                    variant="contained"
                                    size="large"
                                    style={{ backgroundColor: "#fedce0", color: "#851a21", textTransform: "none", fontSize: "1rem", fontWeight: "bold" }}
                                    fullWidth
                                    disableElevation
                                >
                                    Email is invalid or already taken
                                </Button>
                            </Grid>
                        }
                        {(isFirstNameError || isLastNameError || isSchoolError) &&
                            <Grid item xs={12}>
                                <Button
                                    disabled
                                    variant="contained"
                                    size="large"
                                    style={{ backgroundColor: "#fedce0", color: "#851a21", textTransform: "none", fontSize: "1rem", fontWeight: "bold" }}
                                    fullWidth
                                    disableElevation
                                >
                                    Please fill in all required fields.
                                </Button>
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
        <Grid item xs={12} style={{ marginTop: "-5%" }}>
            <Button
                variant="contained"
                size="large"
                style={{ backgroundColor: "#991514", color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1.3rem" }}
                disableElevation
                onClick={() => { registerNewUser() }}
            >
                Sign up
            </Button>
        </Grid>
        <Grid item xs={12} style={{ marginTop: "2%" }}>
            <Link
                to={`/profile`}
                style={{ textDecoration: 'none' }}
            >
                <Button
                    variant="contained"
                    size="large"
                    style={{ backgroundColor: "#a6a6a6", color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1.3rem" }}
                    disableElevation
                >
                    Skip
                </Button>
            </Link>
        </Grid>
    </Grid>)

    const webUI = (<Grid container style={{ marginTop: "4%", marginBottom: "20%", maxWidth: window.innerWidth }}>
        <Grid item xs={12}>
            <Typography variant="h3" style={{ fontWeight: "bold" }} gutterBottom>
                <span style={{ color: "#991514" }}>U</span>TEST AVENUE
            </Typography>
        </Grid>
        <Grid item xs={12} style={{ marginBottom: "2%", paddingLeft: "40%", paddingRight: "40%" }}>
            <Button
                disabled
                variant="contained"
                size="large"
                style={{ backgroundColor: "#991514", color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1rem" }}
                disableElevation
            >
                Sign up
            </Button>
        </Grid>
        <Grid align="center" item xs={12} style={{ marginTop: "-50px" }}>
            <Paper elevation={0} style={{ maxWidth: "450px", backgroundColor: "#a6a6a6", borderRadius: "25px" }}>
                <Grid item align="center" xs={12} style={{ padding: "30px" }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                error={isFirstNameError}
                                value={firstname}
                                id="outlined-basic-firstname"
                                label="First name*"
                                variant="outlined"
                                size="small"
                                type="input"
                                onChange={(event) => { setFirstname(event.target.value.replace(/[^A-Za-z]/gi, "")) }}
                                InputProps={{ className: classes.input }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                error={isLastNameError}
                                value={lastname}
                                id="outlined-basic-lastname"
                                label="Last name*"
                                variant="outlined"
                                size="small"
                                type="input"
                                onChange={(event) => { setLastname(event.target.value.replace(/[^A-Za-z]/gi, "")) }}
                                InputProps={{ className: classes.input }}
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                disabled
                                error={isEmailError}
                                id="outlined-basic-email"
                                label="Email*"
                                variant="outlined"
                                size="small"
                                type="input"
                                value={email}
                                onChange={(event) => { setEmail(event.target.value) }}
                                InputProps={{ className: classes.input }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item align="left" xs={12} sm={6}>
                            <TextField
                                defaultValue={"2022-01-01"}
                                type="date"
                                id="outlined-basic-phone"
                                label="Birth date*"
                                variant="outlined"
                                size="small"
                                onChange={(event) => { setSelectedDate(event.target.value) }}
                                InputProps={{ className: classes.input }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                isClearable={false}
                                isSearchable={false}
                                name="School*"
                                options={genderChoice}
                                style={{ maxWidth: "200px" }}
                                onChange={handleChangeGender}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <div style={{ backgroundColor: 'white', borderRadius: "5px", height: "89%" }}>
                                <ReactFlagsSelect
                                    selected={country}
                                    onSelect={(code) => setCountry(code)}
                                    style={{ backgroundColor: "white" }}
                                    searchable={true}
                                />
                            </div>
                        </Grid>
                        <Grid item align="left" xs={12}>
                            <TextField
                                type="input"
                                id="outlined-basic-referral"
                                label="Referral code"
                                variant="outlined"
                                size="small"
                                onChange={(event) => { setReferralCode(event.target.value) }}
                                InputProps={{ className: classes.input }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item align="left" xs={12}>
                            <TextField
                                type="tel"
                                id="outlined-basic-phone"
                                label="Phone*"
                                variant="outlined"
                                size="small"
                                onChange={(event) => { handleChangePhoneNumber(event.target.value) }}
                                InputProps={{ className: classes.input }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item align="left" xs={12}>
                            <CreatableSelect
                                isClearable
                                onChange={handleChangeSchool}
                                options={schools}
                                style={{ maxWidth: "200px" }}
                                name="School*"
                            />
                        </Grid>
                        {isEmailError &&
                            <Grid item xs={12}>
                                <Button
                                    disabled
                                    variant="contained"
                                    size="large"
                                    style={{ backgroundColor: "#fedce0", color: "#851a21", textTransform: "none", fontSize: "1rem", fontWeight: "bold" }}
                                    fullWidth
                                    disableElevation
                                >
                                    Email is invalid or already taken
                                </Button>
                            </Grid>
                        }
                        {(isFirstNameError || isLastNameError || isSchoolError) &&
                            <Grid item xs={12}>
                                <Button
                                    disabled
                                    variant="contained"
                                    size="large"
                                    style={{ backgroundColor: "#fedce0", color: "#851a21", textTransform: "none", fontSize: "1rem", fontWeight: "bold" }}
                                    fullWidth
                                    disableElevation
                                >
                                    Please fill in all required fields.
                                </Button>
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
        <Grid item xs={12} style={{ marginTop: "-20px" }}>
            <Button
                variant="contained"
                size="large"
                style={{ backgroundColor: "#991514", color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1rem" }}
                disableElevation
                onClick={() => { registerNewUser() }}
            >
                Sign up
            </Button>
        </Grid>
        <Grid item xs={12} style={{ marginTop: "1%" }}>
            <Link
                to={`/newlogin`}
                style={{ textDecoration: 'none' }}
            >
                <Button
                    variant="contained"
                    size="large"
                    style={{ backgroundColor: "#a6a6a6", color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1rem" }}
                    disableElevation
                >
                    Skip
                </Button>
            </Link>
        </Grid>
    </Grid>)

    return <ThemeProvider theme={theme}>
        <Backdrop className={classes.backdrop} open={isLoading}>
            <CircularProgress color="inherit" style={{ color: "#880000" }} />
        </Backdrop>

        {isDoneSignUp &&
            <SocialSuccessfulLogin />
        }
        {!isDoneSignUp &&
            <Hidden mdUp>
                {mobileUI}
            </Hidden>
        }
        {!isDoneSignUp &&
            <Hidden smDown>
                {webUI}
            </Hidden>
        }
    </ThemeProvider>
};

export default compose(
    withStyles(useStyles)
)(SocialRegistrationPage);
