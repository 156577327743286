//React
import React, { Component } from "react";
import { compose } from "recompose";

import { withStyles } from "@material-ui/core/styles";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";

//Constants
import * as styleConstants from "../../../constants/styleConstants";

//Others
import ContactUsSectionWeb from "./web/ContactUsSectionWeb";
import ContactUsSectionMobile from "./mobile/ContactUsSectionMobile";
import { Hidden } from "@material-ui/core";
import { SubmitEmailContact } from "../../../services/userServices";

const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  rootContainer: {
    maxWidth: "75%",
  },
  rootCard: {
    maxWidth: "75%",
    width: "75%",
    margin: 10,
  },
  gridContainer: {
    maxWidth: "90%",
  },
  margin: {
    margin: 100,
  },
  marginTop: {
    marginTop: 100,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(5),
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  header: {
    maxWidth: "80%",
  },
  body: {
    maxWidth: "80%",
    marginTop: 30,
  },
  divider: {
    maxWidth: "100%",
  },
  image: {
    margin: "auto",
    display: "block",
    margin: 10,
  },
  commentTextField: {
    width: "100%",
    maxWidth: "100%",
  },
  commentGridItem: {
    maxWidth: "80%",
    marginTop: 30,
    marginBottom: 100,
  },
});

const theme = createMuiTheme({
  typography: {
    fontFamily: [`${styleConstants.fontStyle}`].join(","),
  },
});

class ContactUsSection extends Component {
  constructor() {
    super();
    this.state = {
        isLoading: false
    };
}

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = async () => {
    this.setState({
      isLoading: true
    });

    if (this.state.email && this.state.message && this.state.firstname && this.state.lastname) {
      const res = await SubmitEmailContact({
        name: `${this.state.firstname} ${this.state.lastname}`,
        email: this.state.email,
        message: this.state.message
      });

      if (res.success) {
        alert("Submit contact successfully!");
      } else {
        alert("Something went wrong. Please try again.");
      }
    } else {
      alert("Please fill in all required fields.")
    }

    this.setState({
      isLoading: false
    });
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <Hidden lgUp>
          <ContactUsSectionMobile handleChange={this.handleChange} handleSubmit={this.handleSubmit} isLoading={this.state.isLoading} />
        </Hidden>
        <Hidden mdDown>
          <ContactUsSectionWeb handleChange={this.handleChange} handleSubmit={this.handleSubmit} isLoading={this.state.isLoading} />
        </Hidden>
      </ThemeProvider>
    );
  }
}

export default compose(withStyles(useStyles))(ContactUsSection);
