import * as apiConstants from '../constants/apiConstants';

const axios = require('axios').default;

//=========================== GET ADMIN TOKEN ======================================
export async function GetAdminToken() {
    try {
        const token = `${apiConstants.ADMIN_USER.username}:${apiConstants.ADMIN_USER.password}`;
        const encodedToken = Buffer.from(token).toString('base64');

        const res = await axios({
            method: 'post',
            url: `${apiConstants.E_LEARNING_API_BASE_URL}/api/oauth/login`,
            headers: { 'Authorization': `Basic ${encodedToken}` }
        });

        return {
            success: true,
            res: res.data
        }
    } catch (err) {
        console.log(err);
        return {
            success: false,
            res: err
        }
    }
}
//=========================== GET ADMIN TOKEN ======================================