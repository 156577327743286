import React, { useEffect } from 'react';

//MUI
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';

import { ThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';

//Functions
import { AgeCalculation } from '../../../../../functions/userFunctions';

//Services
import {
    GetUserInfoByUsername,
} from "../../../../../services/testReportServices";

//Font
import 'typeface-glacial-indifference';

//Others
import moment from 'moment';
const axios = require('axios').default;
const FormData = require('form-data');
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

const useStyles = theme => ({
    root: {
        padding: theme.spacing(12, 4),
    }
});

const theme = createMuiTheme({
    typography: {
        fontFamily: [
            'Roboto'
        ].join(','),
    },
});

const UserProfile = (props) => {
    const [userObj, setUserObj] = React.useState({});
    const [userAge, setUserAge] = React.useState(0);
    const [initialName, setInitialName] = React.useState("");

    useEffect(() => {
        getUserInfo();
    }, []);

    const getUserInfo = async () => {
        const res = await GetUserInfoByUsername(JSON.parse(localStorage.getItem('user')).token, props.reportObj.username);
        setUserObj(res.res);

        setInitialName(`${JSON.parse(localStorage.getItem('user')).firstname[0]}${JSON.parse(localStorage.getItem('user')).lastname[0]}`);
        const age = await AgeCalculation(JSON.parse(localStorage.getItem('user')).birthdate)
        setUserAge(age);
    }

    const mobileUI = (<Grid container>
        <Grid item xs={12} style={{
            backgroundColor: "#25333e",
            borderTopLeftRadius: "15px",
            borderTopRightRadius: "15px",
            padding: '5px'
        }}>
            <Typography variant="h4" style={{ color: "white" }}>
                User profile
            </Typography>
        </Grid>
        <Grid item xs={12} style={{
            padding: "5%",
            border: "1px solid #25333e",
            borderBottomLeftRadius: "15px",
            borderBottomRightRadius: "15px"
        }}>
            <Grid container spacing={3}>
                <Grid item align="center" xs={12}>
                    <Avatar variant="square" style={{
                        width: "200px",
                        height: "200px",
                        fontSize: "80px",
                        fontWeight: "bold",
                        backgroundColor: "#a00505"
                    }}
                    >
                        {props.isLoading ? <Skeleton /> : props.userObj?.initial?.toUpperCase()}
                    </Avatar>
                </Grid>
                <Grid item align="center" xs={12}>
                    <Grid container spacing={1} style={{ padding: "10px 0px 10px" }}>
                        <Grid item align="left" xs={12}>
                            <Typography variant="body1" style={{ color: "#25333e" }} gutterBottom>
                                <span style={{ fontWeight: "bold" }}>Name:</span> {props.isLoading ? <Skeleton /> : props.userObj?.name}
                            </Typography>
                        </Grid>
                        <Grid item align="left" xs={12}>
                            <Typography variant="body1" style={{ color: "#25333e" }} gutterBottom>
                                <span style={{ fontWeight: "bold" }}>Email:</span> {props.isLoading ? <Skeleton /> : props.userObj?.email}
                            </Typography>
                        </Grid>
                        <Grid item align="left" xs={12}>
                            <Typography variant="body1" style={{ color: "#25333e" }} gutterBottom>
                                <span style={{ fontWeight: "bold" }}>School:</span> {props.isLoading ? <Skeleton /> : props.userObj?.school ? props.userObj?.school : "-"}
                            </Typography>
                        </Grid>
                        <Grid item align="left" xs={12}>
                            <Typography variant="body1" style={{ color: "#25333e" }} gutterBottom>
                                <span style={{ fontWeight: "bold" }}>Test Date:</span> {props.isLoading ? <Skeleton /> : props.reportObj?.test_result?.test_started ? moment(props.reportObj.test_result.test_started).format('dddd Do MMM YYYY') : moment(props.reportObj.test_result.date_created).format('dddd Do MMM YYYY')}
                            </Typography>
                        </Grid>
                        <Grid item align="left" xs={12}>
                            <Typography variant="body1" style={{ color: "#25333e" }} gutterBottom>
                                <span style={{ fontWeight: "bold" }}>Expected Band Score:</span> {props.isLoading ? <Skeleton /> : props.reportObj?.test_result?.test_survey ? props.reportObj.test_result.test_survey.selectedExpectedScore : "-"}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </Grid>)

    const webUI = (<Grid container>
        <Grid item xs={12} style={{
            backgroundColor: "#25333e",
            borderTopLeftRadius: "15px",
            borderTopRightRadius: "15px",
            padding: '5px'
        }}>
            <Typography variant="h5" style={{ color: "white" }}>
                User profile
            </Typography>
        </Grid>
        <Grid item xs={12} style={{
            padding: "5%",
            border: "1px solid #25333e",
            borderBottomLeftRadius: "15px",
            borderBottomRightRadius: "15px"
        }}>
            <Grid container spacing={3}>
                <Grid item align="center" xs={4}>
                    <Avatar variant="square" style={{
                        width: "150px",
                        height: "150px",
                        fontSize: "70px",
                        fontWeight: "bold",
                        backgroundColor: "#a00505"
                    }}
                    >
                        {props.isLoading ? <Skeleton /> : props.userObj?.initial?.toUpperCase()}
                    </Avatar>
                </Grid>
                <Grid item xs={8}>
                    <Grid container spacing={1} style={{ padding: "10px 0px 10px" }}>
                        <Grid item align="left" xs={12}>
                            <Typography variant="body1" style={{ color: "#25333e" }} gutterBottom>
                                <span style={{ fontWeight: "bold" }}>Name:</span> {props.isLoading ? <Skeleton /> : props.userObj?.name}
                            </Typography>
                        </Grid>
                        <Grid item align="left" xs={12}>
                            <Typography variant="body1" style={{ color: "#25333e" }} gutterBottom>
                                <span style={{ fontWeight: "bold" }}>Email:</span> {props.isLoading ? <Skeleton /> : props.userObj?.email}
                            </Typography>
                        </Grid>
                        <Grid item align="left" xs={12}>
                            <Typography variant="body1" style={{ color: "#25333e" }} gutterBottom>
                                <span style={{ fontWeight: "bold" }}>School:</span> {props.isLoading ? <Skeleton /> : props.userObj?.school ? props.userObj?.school : "-"}
                            </Typography>
                        </Grid>
                        <Grid item align="left" xs={12}>
                            <Typography variant="body1" style={{ color: "#25333e" }} gutterBottom>
                                <span style={{ fontWeight: "bold" }}>Test Date:</span> {props.isLoading ? <Skeleton /> : moment.utc(props.reportObj.date_created).format('dddd Do MMM YYYY')}
                            </Typography>
                        </Grid>
                        <Grid item align="left" xs={12}>
                            <Typography variant="body1" style={{ color: "#25333e" }} gutterBottom>
                                <span style={{ fontWeight: "bold" }}>Expected Band Score:</span> {props.isLoading ? <Skeleton /> : props.reportObj?.test_result?.test_survey ? props.reportObj.test_result.test_survey.selectedExpectedScore : "-"}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </Grid>)

    return <ThemeProvider theme={theme}>
        <Hidden mdUp>
            {mobileUI}
        </Hidden>
        <Hidden smDown>
            {webUI}
        </Hidden>
    </ThemeProvider>
};

export default compose(
    withStyles(useStyles)
)(UserProfile);
