//React
import { Component } from 'react';
import { compose } from 'recompose';

//Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import AssignmentLateRoundedIcon from '@material-ui/icons/AssignmentLateRounded';
import AssignmentRoundedIcon from '@material-ui/icons/AssignmentRounded';
import AssignmentTurnedInRoundedIcon from '@material-ui/icons/AssignmentTurnedInRounded';
import BugReportRoundedIcon from '@material-ui/icons/BugReportRounded';
import DoneAllRoundedIcon from '@material-ui/icons/DoneAllRounded';
import EditAttributesRoundedIcon from '@material-ui/icons/EditAttributesRounded';
import EventRoundedIcon from '@material-ui/icons/EventRounded';
import ScheduleRoundedIcon from '@material-ui/icons/ScheduleRounded';

//Icoons

//import components
import AllTests from './components/AllTests';
import AssignedTests from './components/AssignedTests';
import AvailabilityStatus from './components/AvailabilityStatus';
import AvailableSchedules from './components/AvailableSchedules';
import MyAssignedTests from './components/MyAssignedTests';
import MySchedule from './components/MySchedule';
import ReportedProblems from './components/ReportedProblems';
import ScheduleManagement from './components/ScheduleManagement';
import UnassignedTests from './components/UnassignedTests';
import UserFeedbackTable from './components/UserFeedbackTable';
import TeacherSchedules from './components/head-teacher/TeacherSchedules';

import AllSpeakingScoring from './components/AllSpeakingScoring';
import AssignedTestsHT from './components/head-teacher/AssignedTestsHT';

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%'
    }
});

class TeacherDashboard extends Component {
    constructor() {
        super();
        this.state = {
            selectedMenu: '',
            today: new Date(Date.now()),
            currentTab: ''
        };
    }

    componentDidMount = () => {
        document.querySelector("body").scrollTo(0, 0);
        document.body.style.backgroundColor = "#efefef";

        if ((this.props.user.role === 'HEAD_TEACHER' || this.props.user.role === 'SUPER_ADMIN')) {
            this.handleSelectMenu('allTests');
            this.handleSelectTab('overall');
        } else {
            this.handleSelectMenu('myAssignedTest');
            this.handleSelectTab('my');
        }
        this.props.handleChangeMenu('teacher');
    }

    handleChange = (event) => {
        const name = event.target.name;

        this.setState({
            ...this.state,
            [name]: event.target.value
        });
    };

    handleSelectMenu = (menu) => {
        this.setState({
            selectedMenu: menu
        });
    }

    handleSelectTab = (tab) => {
        this.setState({
            currentTab: tab
        });
    }

    render() {
        const { classes } = this.props;

        return (
            <Container>
                <Grid container spacing={3} style={{ maxWidth: window.innerWidth, marginTop: '50px', marginBottom: '30%' }}>
                    <Grid align='left' item xs={12}>
                        <Typography variant="h4">
                            <Box fontWeight="fontWeightBold" display='inline'>{(this.props.user.role === 'HEAD_TEACHER' || this.props.user.role === 'SUPER_ADMIN') ? 'Head teacher dashboard' : 'Teacher dashboard'}</Box>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={3} style={{ paddingTop: "65px" }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Paper variant="outlined">
                                    <ButtonGroup fullWidth color="primary" aria-label="outlined primary button group">
                                        <Button
                                            disabled={!(this.props.user.role === 'HEAD_TEACHER' || this.props.user.role === 'SUPER_ADMIN')}
                                            style={{
                                                textTransform: 'none',
                                                backgroundColor: !(this.props.user.role === 'HEAD_TEACHER' || this.props.user.role === 'SUPER_ADMIN') ? '#d4d4d4' : (this.state.currentTab === 'overall' ? '#76323f' : ''),
                                                borderColor: !(this.props.user.role === 'HEAD_TEACHER' || this.props.user.role === 'SUPER_ADMIN') ? 'grey' : '#76323f',
                                                color: !(this.props.user.role === 'HEAD_TEACHER' || this.props.user.role === 'SUPER_ADMIN') ? 'grey' : (this.state.currentTab === 'overall' ? 'white' : '#76323f'),
                                            }}
                                            onClick={() => { this.handleSelectTab('overall') }}
                                        >
                                            Overall
                                        </Button>
                                        <Button
                                            style={{
                                                textTransform: 'none',
                                                backgroundColor: this.state.currentTab === 'my' ? '#76323f' : '',
                                                borderColor: '#76323f',
                                                color: this.state.currentTab === 'my' ? 'white' : '#76323f',
                                            }}
                                            onClick={() => { this.handleSelectTab('my') }}
                                        >
                                            My
                                        </Button>
                                    </ButtonGroup>
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper variant="outlined">
                                    <List component="nav" aria-label="main mailbox folders">
                                        {(this.props.user.role === 'HEAD_TEACHER' || this.props.user.role === 'SUPER_ADMIN') &&
                                            <ListItem
                                                button
                                                selected={this.state.selectedMenu === 'allTests'}
                                                onClick={() => { this.handleSelectMenu('allTests') }}
                                            >
                                                <ListItemIcon>
                                                    <AssignmentRoundedIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="All tests" />
                                            </ListItem>
                                        }
                                        {/* {(this.props.user.role === 'HEAD_TEACHER' || this.props.user.role === 'SUPER_ADMIN') && this.state.currentTab === 'overall' && <Divider />}
                                        {(this.props.user.role === 'HEAD_TEACHER' || this.props.user.role === 'SUPER_ADMIN') && this.state.currentTab === 'overall' && <ListItem
                                            button
                                            selected={this.state.selectedMenu === 'unassignedTest'}
                                            onClick={() => { this.handleSelectMenu('unassignedTest') }}
                                        >
                                            <ListItemIcon>
                                                <AssignmentLateRoundedIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Unassigned tests" />
                                        </ListItem>
                                        } */}
                                        {(this.props.user.role === 'HEAD_TEACHER' || this.props.user.role === 'SUPER_ADMIN') && this.state.currentTab === 'overall' && <Divider />}
                                        {(this.props.user.role === 'HEAD_TEACHER' || this.props.user.role === 'SUPER_ADMIN') && this.state.currentTab === 'overall' &&
                                            <ListItem
                                                button
                                                selected={this.state.selectedMenu === 'assignedTest'}
                                                onClick={() => { this.handleSelectMenu('assignedTest') }}
                                            >
                                                <ListItemIcon>
                                                    <AssignmentTurnedInRoundedIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Assigned tests" />
                                            </ListItem>
                                        }
                                        {(this.props.user.role === 'TEACHER' || this.props.user.role === 'SUPER_ADMIN') && this.state.currentTab === 'my' && <Divider />}
                                        {(this.props.user.role === 'TEACHER' || this.props.user.role === 'SUPER_ADMIN') && this.state.currentTab === 'my' &&
                                            <ListItem
                                                button
                                                selected={this.state.selectedMenu === 'myAssignedTest'}
                                                onClick={() => { this.handleSelectMenu('myAssignedTest') }}
                                            >
                                                <ListItemIcon>
                                                    <AssignmentTurnedInRoundedIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="My assigned tests" />
                                            </ListItem>
                                        }

                                        {(this.props.user.role === 'TEACHER' || this.props.user.role === 'SUPER_ADMIN') && this.state.currentTab === 'my' && <Divider />}
                                        {(this.props.user.role === 'TEACHER' || this.props.user.role === 'SUPER_ADMIN') && this.state.currentTab === 'my' &&
                                            <ListItem
                                                button
                                                selected={this.state.selectedMenu === 'availableSchedules'}
                                                onClick={() => { this.handleSelectMenu('availableSchedules') }}
                                            >
                                                <ListItemIcon>
                                                    <AssignmentTurnedInRoundedIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Available schedules" />
                                            </ListItem>
                                        }
                                        {/*(this.props.user.role === 'TEACHER' || this.props.user.role === 'SUPER_ADMIN') && <Divider />*/}
                                        {/*(this.props.user.role === 'TEACHER' || this.props.user.role === 'SUPER_ADMIN') &&
                                    <ListItem
                                        button
                                        selected={this.state.selectedMenu === 'myAssignedSpeakingTest'}
                                        onClick={() => { this.handleSelectMenu('myAssignedSpeakingTest') }}
                                    >
                                        <ListItemIcon>
                                            <RecordVoiceOverRoundedIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="My assigned speaking tests" />
                                    </ListItem>
                            */}
                                        {(this.props.user.role === 'HEAD_TEACHER' || this.props.user.role === 'SUPER_ADMIN') && this.state.currentTab === 'my' && <Divider />}
                                        {(this.props.user.role === 'HEAD_TEACHER' || this.props.user.role === 'SUPER_ADMIN') && this.state.currentTab === 'my' &&
                                            <ListItem
                                                button
                                                selected={this.state.selectedMenu === 'headTeacherApproval'}
                                                onClick={() => { this.handleSelectMenu('headTeacherApproval') }}
                                            >
                                                <ListItemIcon>
                                                    <DoneAllRoundedIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Head teacher approval" />
                                            </ListItem>
                                        }
                                        {(this.props.user.role === 'HEAD_TEACHER' || this.props.user.role === 'SUPER_ADMIN') && this.state.currentTab === 'overall' && <Divider />}
                                        {(this.props.user.role === 'HEAD_TEACHER' || this.props.user.role === 'SUPER_ADMIN') && this.state.currentTab === 'overall' &&
                                            <ListItem
                                                button
                                                selected={this.state.selectedMenu === 'availabilityStatus'}
                                                onClick={() => { this.handleSelectMenu('availabilityStatus') }}
                                            >
                                                <ListItemIcon>
                                                    <EditAttributesRoundedIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Teacher availability" />
                                            </ListItem>
                                        }
                                        {(this.props.user.role === 'HEAD_TEACHER' || this.props.user.role === 'SUPER_ADMIN') && this.state.currentTab === 'overall' && <Divider />}
                                        {(this.props.user.role === 'HEAD_TEACHER' || this.props.user.role === 'SUPER_ADMIN') && this.state.currentTab === 'overall' &&
                                            <ListItem
                                                button
                                                selected={this.state.selectedMenu === 'teacherSchedules'}
                                                onClick={() => { this.handleSelectMenu('teacherSchedules') }}
                                            >
                                                <ListItemIcon>
                                                    <EventRoundedIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Teacher schedules" />
                                            </ListItem>
                                        }
                                        {(this.props.user.role === 'TEACHER' || this.props.user.role === 'SUPER_ADMIN') && this.state.currentTab === 'my' && <Divider />}
                                        {(this.props.user.role === 'TEACHER' || this.props.user.role === 'SUPER_ADMIN') && this.state.currentTab === 'my' &&
                                            <ListItem
                                                button
                                                selected={this.state.selectedMenu === 'mySchedule'}
                                                onClick={() => { this.handleSelectMenu('mySchedule') }}
                                            >
                                                <ListItemIcon>
                                                    <EventRoundedIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="My schedule" />
                                            </ListItem>
                                        }
                                        {(this.props.user.role === 'HEAD_TEACHER' || this.props.user.role === 'SUPER_ADMIN') && this.state.currentTab === 'overall' && <Divider />}
                                        {(this.props.user.role === 'HEAD_TEACHER' || this.props.user.role === 'SUPER_ADMIN') && this.state.currentTab === 'overall' &&
                                            <ListItem
                                                button
                                                selected={this.state.selectedMenu === 'scheduleManagement'}
                                                onClick={() => { this.handleSelectMenu('scheduleManagement') }}
                                            >
                                                <ListItemIcon>
                                                    <ScheduleRoundedIcon />
                                                </ListItemIcon>
                                                <ListItemText primary={"Schedule management"} />
                                            </ListItem>
                                        }
                                        {(this.props.user.role === 'HEAD_TEACHER' || this.props.user.role === 'SUPER_ADMIN') && this.state.currentTab === 'overall' && <Divider />}
                                        {(this.props.user.role === 'HEAD_TEACHER' || this.props.user.role === 'SUPER_ADMIN') && this.state.currentTab === 'overall' &&
                                            <ListItem
                                                button
                                                selected={this.state.selectedMenu === 'reportedProblem'}
                                                onClick={() => { this.handleSelectMenu('reportedProblem') }}
                                            >
                                                <ListItemIcon>
                                                    <BugReportRoundedIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Reported problems" />
                                            </ListItem>
                                        }
                                    </List>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>

                    {this.state.selectedMenu === 'allTests' && <AllTests />}
                    {this.state.selectedMenu === 'assignedTest' && <AssignedTests userInfo={this.props.user} />}
                    {this.state.selectedMenu === 'myAssignedTest' && <MyAssignedTests userInfo={this.props.user} />}
                    {this.state.selectedMenu === 'myAssignedSpeakingTest' && <AllSpeakingScoring userInfo={this.props.user} />}
                    {this.state.selectedMenu === 'availabilityStatus' && <AvailabilityStatus userInfo={this.props.user} />}
                    {this.state.selectedMenu === 'availableSchedules' && <AvailableSchedules userInfo={this.props.user} />}
                    {this.state.selectedMenu === 'unassignedTest' && <UnassignedTests />}
                    {this.state.selectedMenu === 'teacherSchedules' && <TeacherSchedules userInfo={this.props.user} />}
                    {this.state.selectedMenu === 'mySchedule' && <MySchedule userInfo={this.props.user} />}
                    {this.state.selectedMenu === 'scheduleManagement' && <ScheduleManagement userInfo={this.props.user} />}
                    {this.state.selectedMenu === 'reportedProblem' && <ReportedProblems userInfo={this.props.user} />}
                    {this.state.selectedMenu === 'userFeedback' && <UserFeedbackTable userInfo={this.props.user} />}
                    {this.state.selectedMenu === 'headTeacherApproval' && <AssignedTestsHT userInfo={this.props.user} />}

                </Grid>
            </Container>
        )
    }
}

export default compose(
    withStyles(useStyles)
)(TeacherDashboard);
