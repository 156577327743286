import React, { Component } from 'react';
import { Button, Grid, Paper, TextField, Typography, Box } from '@material-ui/core';
import DraftsRoundedIcon from '@material-ui/icons/DraftsRounded';
import { compose } from 'recompose';
import { ThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import { Link, withRouter } from 'react-router-dom';
import axios from 'axios';

const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

const styles = (theme) => ({
    input: {
        background: "white",
        fontSize: "1rem"
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    }
});

const theme = createMuiTheme({
    root: {
        flexGrow: 1,
    },
    typography: {
        fontFamily: ['Roboto'].join(','),
    },
});

class CreateNewPasswordPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            isPasswordDifferent: false,
            password: '',
            confirmPassword: '',
            username: '',
            token: ''
        };
    }

    componentDidMount() {
        this.props.handleSignout();
        document.body.style.backgroundColor = "#efefef";
        document.querySelector("body").scrollTo(0, 0);
    }

    //====================== BEGIN HANDLE RESET PASSWORD======================
    getUserInfo = async () => {
        const userDetail_url = `${api_base_url}/api/user`;
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let userToken = params.get('token');

        try {
            this.handleLoading();
            const response = await axios.get(userDetail_url, {
                headers: {
                    "Authorization": `bearer ${userToken}`
                }
            });

            this.setState({
                username: response.data.username,
                token: userToken
            });

        } catch (error) {
            this.handleCloseLoading();
            console.error(error);
        }
    }

    handleChangePassword = async (password) => {
        this.handleLoading();
        const changePassword_url = `${api_base_url}/api/user`;
        await this.getUserInfo();
        try {
            const response = await axios.put(changePassword_url, {
                "username": this.state.username,
                "password": password
            }, {
                headers: {
                    "Authorization": `bearer ${this.state.token}`
                }
            });

            console.log(response);

            this.props.history.push('/newlogin');

        } catch (error) {
            this.handleCloseLoading();
            console.error(error);
        }
    }

    handleCheckConfirmPassword = () => {
        if (this.state.password === this.state.confirmPassword) {
            this.handleChangePassword(this.state.password);
            return;
        }
        this.setState({
            isPasswordDifferent: true
        });
    }
    //====================== END HANDLE RESET PASSWORD======================

    //======================== BEGIN BACKDROP CONTROL ==============================
    handleCloseLoading = () => {
        this.setState({
            isLoading: false
        });
    }

    handleLoading = () => {
        this.setState({
            isLoading: true
        });
    }
    //======================== END BACKDROP CONTROL ===============================

    //======================== BEGIN HANDLE CHANGE ================================
    handleChange = (event) => {
        const name = event.target.name;
        this.setState({
            [name]: event.target.value,
        });
    };
    //======================== END HANDLE CHANGE ================================

    render() {
        const { classes } = this.props;

        return (
            <ThemeProvider theme={theme}>
                <Grid container style={{ marginTop: "4%", marginBottom: "20%", maxWidth: window.innerWidth }}>
                    <Grid item align='center' xs={12}>
                        <Grid container justify='center' spacing={0}>
                            <Grid item>
                                <Grid container style={{ maxWidth: '100%' }}>
                                    <Paper style={{ backgroundColor: "#a6a6a6", borderRadius: "25px", padding: '50px' }}>
                                        <Grid item xs={12}>
                                            <Paper elevation={0} style={{ width: '20%', padding: '10px', backgroundColor: '#f7c9c8' }}>
                                                <DraftsRoundedIcon color="primary" fontSize="large" style={{ color: "#991514" }} />
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={12} style={{ marginTop: '50px' }}>
                                            <Typography variant="h4" style={{ color: "white" }} gutterBottom>
                                                <Box fontWeight="fontWeightBold" display='inline'>Create new password</Box>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography color='textSecondary' variant="subtitle1" gutterBottom>
                                                Your new password must be different from previous used passwords.
                                            </Typography>
                                        </Grid>
                                        <Grid item align='center' xs={12}>
                                            <Typography variant="body1" align="left" style={{ fontWeight: 'bold', marginTop: '20px', color: "white" }} gutterBottom>
                                                Password:
                                            </Typography>
                                            <TextField
                                                name="password"
                                                type="password"
                                                id="outlined-basic"
                                                variant="outlined"
                                                style={{ width: '100%', background: "white", fontSize: "1rem" }}
                                                onChange={this.handleChange}
                                            />
                                        </Grid>
                                        <Grid item align='center' xs={12}>
                                            <Typography variant="body1" align="left" style={{ fontWeight: 'bold', marginTop: '20px', color: "white" }} gutterBottom>
                                                Confirm Password:
                                            </Typography>
                                            <TextField
                                                error={this.state.isPasswordDifferent}
                                                helperText={this.state.isPasswordDifferent && "Password mismatch."}
                                                name="confirmPassword"
                                                type="password"
                                                id="outlined-basic"
                                                variant="outlined"
                                                style={{ width: '100%', background: "white", fontSize: "1rem" }}
                                                onChange={this.handleChange}
                                            />
                                        </Grid>
                                        <Grid item align='center' xs={12}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                style={{ height: '40px', marginTop: '20px', backgroundColor: "#991514", color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1rem" }}
                                                onClick={this.handleCheckConfirmPassword}
                                            >
                                                <Box fontWeight="fontWeightBold" display='inline'>Reset password</Box>
                                            </Button>
                                        </Grid>
                                        <Grid item align='center' xs={12}>
                                            <Link
                                                to={`/newlogin`}
                                                style={{ textDecoration: 'none' }}
                                            >
                                                <Button style={{ color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1rem" }}>
                                                    Back to signin
                                                </Button>
                                            </Link>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </ThemeProvider>
        )
    }
}

export default compose(
    withStyles(styles),
    withRouter
)(CreateNewPasswordPage);
