//React
import React, { Component } from 'react';
import { compose } from 'recompose';

//Material UI
import Button from '@material-ui/core/Button';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';

//Icons
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';

//Constants
import * as styleConstants from "../../../../constants/styleConstants";

//react-router-dom
import {
    Link
} from "react-router-dom";

//Animations
import Fade from 'react-reveal/Fade';

//Others
import MediaQuery from 'react-responsive';
import VisibilitySensor from 'react-visibility-sensor';
const axios = require('axios').default;

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%'
    },
    rootContainer: {
        maxWidth: '75%'

    },
    rootCard: {
        maxWidth: "75%",
        width: '75%',
        margin: 10,
    },
    gridContainer: {
        maxWidth: '90%'
    },
    margin: {
        margin: 100
    },
    marginTop: {
        marginTop: 100
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginTop: theme.spacing(5)
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    header: {
        maxWidth: "80%"
    },
    body: {
        maxWidth: "80%",
        marginTop: 30
    },
    divider: {
        maxwidth: "100%"
    },
    image: {
        margin: 'auto',
        display: 'block',
        margin: 10
    },
    commentTextField: {
        width: '100%',
        maxWidth: '100%'
    },
    commentGridItem: {
        maxWidth: "80%",
        marginTop: 30,
        marginBottom: 100
    }
});

const theme = createMuiTheme({
    typography: {
        fontFamily: [
            'Prompt'
        ].join(','),
    },
});


class OurTestsSectionMobile extends Component {
    constructor() {
        super();
        this.state = {
            isPaperAppear: true,
            isImageAppear: true,
            isTextAppear: true
        };
    }

    componentDidMount() {
    }

    timeout = (delay) => {
        return new Promise(res => setTimeout(res, delay));
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    handlehandleShowAnimation = async (isVisible) => {
        if (isVisible) {
            this.setState({
                isPaperAppear: true
            });
            await this.timeout(2000);
            this.setState({
                isImageAppear: true
            });
            await this.timeout(2000);
            this.setState({
                isTextAppear: true
            });
        }
    }

    render() {
        const { classes } = this.props;

        return (
            <MediaQuery minWidth={"768px"}>
                {(matches) =>
                    matches ? <Grid item xs={12} style={{ maxWidth: window.innerWidth, zIndex: 1 }}>
                        <VisibilitySensor onChange={(isVisible) => {
                            this.handlehandleShowAnimation(isVisible);
                        }}>
                            <Grid container style={{ paddingRight: "7%" }}>
                                <Grid item align="center" xs={12} sm={6} style={{ padding: '10%' }}>
                                    <Grid container>
                                        <Fade top cascade>
                                            <Grid item align="left" xs={12}>
                                                <Typography variant="h3" style={{ fontFamily: styleConstants.fontStyle, fontWeight: styleConstants.fontWeight.bold, color: "#991514" }} >
                                                    OUR TESTS
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} align="left" style={{ marginTop: '5%' }}>
                                                <Typography variant="h5" style={{ fontFamily: styleConstants.fontStyle, fontWeight: styleConstants.fontWeight.thin, }}>
                                                    <CheckBoxOutlinedIcon /> Computer-based tests.
                                                </Typography>
                                                <Typography variant="h5" style={{ ffontFamily: styleConstants.fontStyle, fontWeight: styleConstants.fontWeight.thin, }}>
                                                    <CheckBoxOutlinedIcon /> Original materials
                                                </Typography>
                                                <Typography variant="h5" style={{ fontFamily: styleConstants.fontStyle, fontWeight: styleConstants.fontWeight.thin, }}>
                                                    <CheckBoxOutlinedIcon /> Intelligent skill analysis
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} align="left" style={{ marginTop: '40px' }}>
                                                <Link to="/ourtests" style={{ textDecoration: 'none', color: '#0f0f0f' }}>
                                                    <Button variant="contained" style={{ backgroundColor: "#3f5e75", color: "white" }}>
                                                        <Typography variant="h6" style={{ fontWeight: styleConstants.fontWeight.semiBold, fontFamily: styleConstants.fontStyle }}>
                                                            Our tests
                                                        </Typography>
                                                    </Button>
                                                </Link>
                                            </Grid>
                                        </Fade>
                                    </Grid>
                                </Grid>
                                <Grid item align="center" xs={12} sm={6} style={{ zIndex: 1, paddingTop: "30px" }}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Grid container spacing={5}>
                                                <Grid item align="center" xs={6} style={{ height: "270px" }}>
                                                    {this.state.isPaperAppear && <Paper style={{
                                                        padding: "16px",
                                                        paddingBottom: "0px",
                                                        height: "160px",
                                                        animation: "fadeIn 1s",
                                                        borderRadius: "25px",
                                                        background: "#97a7b2",
                                                        position: "relative",
                                                    }} >
                                                        {this.state.isImageAppear && <Link to="/ourtests" style={{ textDecoration: 'none', color: '#0f0f0f' }}>
                                                            <CardMedia
                                                                component="img"
                                                                style={{
                                                                    position: "absolute",
                                                                    bottom: 0,
                                                                    left: 0,
                                                                    right: 0,
                                                                    top: -25,
                                                                    height: "260px",
                                                                    width: "100%",
                                                                    backgroundSize: "cover",
                                                                }}
                                                                src="graphics/IELTS (Academic).png"
                                                            />
                                                        </Link>}
                                                    </Paper>}
                                                    {this.state.isTextAppear &&
                                                        <Link to="/ourtests" style={{ textDecoration: 'none', color: '#0f0f0f' }}>
                                                            <Typography variant="h5" style={{ animation: "fadeIn 1s", fontWeight: styleConstants.fontWeight.semiBold, fontFamily: styleConstants.fontStyle, marginTop: "10px" }}>
                                                                IELTS (ACADEMIC)
                                                            </Typography>
                                                        </Link>
                                                    }
                                                </Grid>
                                                <Grid item align="center" xs={6} style={{ height: "270px" }}>
                                                    {this.state.isPaperAppear && <Paper style={{
                                                        padding: "16px",
                                                        paddingBottom: "0px",
                                                        height: "160px",
                                                        animation: "fadeIn 1s",
                                                        borderRadius: "25px",
                                                        background: "#97a7b2",
                                                        position: "relative",
                                                    }} >
                                                        {this.state.isImageAppear && <Link to="/ourtests" style={{ textDecoration: 'none', color: '#0f0f0f' }}>
                                                            <CardMedia
                                                                component="img"
                                                                style={{
                                                                    position: "absolute",
                                                                    bottom: 0,
                                                                    left: 0,
                                                                    right: 0,
                                                                    top: -24,
                                                                    height: "260px",
                                                                    width: "100%",
                                                                    backgroundSize: "cover",
                                                                }}
                                                                src="graphics/BMAT.png"
                                                            />
                                                        </Link>}
                                                    </Paper>}
                                                    {this.state.isTextAppear &&
                                                        <Link to="/ourtests" style={{ textDecoration: 'none', color: '#0f0f0f' }}>
                                                            <Typography variant="h5" style={{ animation: "fadeIn 1s", fontWeight: styleConstants.fontWeight.semiBold, fontFamily: styleConstants.fontStyle, marginTop: "10px" }}>
                                                            TBAT
                                                            </Typography>
                                                        </Link>
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={5}>
                                                <Grid item align="center" xs={6} style={{ height: "270px" }}>
                                                    {this.state.isPaperAppear && <Paper style={{
                                                        padding: "16px",
                                                        paddingBottom: "0px",
                                                        height: "160px",
                                                        animation: "fadeIn 1s",
                                                        borderRadius: "25px",
                                                        background: "#97a7b2",
                                                        position: "relative",
                                                    }} >
                                                        {this.state.isImageAppear && <Link to="/ourtests" style={{ textDecoration: 'none', color: '#0f0f0f' }}>
                                                            <CardMedia
                                                                component="img"
                                                                style={{
                                                                    position: "absolute",
                                                                    bottom: 0,
                                                                    left: 0,
                                                                    right: 0,
                                                                    top: -26,
                                                                    height: "260px",
                                                                    width: "100%",
                                                                    backgroundSize: "cover",
                                                                }}
                                                                src="graphics/CU-TEP.png"
                                                            />
                                                        </Link>}
                                                    </Paper>}
                                                    {this.state.isTextAppear &&
                                                        <Link to="/ourtests" style={{ textDecoration: 'none', color: '#0f0f0f' }}>
                                                            <Typography variant="h5" style={{ animation: "fadeIn 1s", fontWeight: styleConstants.fontWeight.semiBold, fontFamily: styleConstants.fontStyle, marginTop: "10px" }}>
                                                                CU-TEP
                                                            </Typography>
                                                        </Link>
                                                    }
                                                </Grid>
                                                <Grid item align="center" xs={6} style={{ height: "270px" }}>
                                                    {this.state.isPaperAppear && <Paper style={{
                                                        padding: "16px",
                                                        paddingBottom: "0px",
                                                        height: "160px",
                                                        animation: "fadeIn 1s",
                                                        borderRadius: "25px",
                                                        background: "#97a7b2",
                                                        position: "relative",
                                                    }} >
                                                        {this.state.isImageAppear && <Link to="/ourtests" style={{ textDecoration: 'none', color: '#0f0f0f' }}>
                                                            <CardMedia
                                                                component="img"
                                                                style={{
                                                                    position: "absolute",
                                                                    bottom: 0,
                                                                    left: 0,
                                                                    right: 0,
                                                                    top: -27,
                                                                    height: "260px",
                                                                    width: "100%",
                                                                    backgroundSize: "cover",
                                                                }}
                                                                src="graphics/TOEIC.png"
                                                            />
                                                        </Link>}
                                                    </Paper>}
                                                    {this.state.isTextAppear &&
                                                        <Link to="/ourtests" style={{ textDecoration: 'none', color: '#0f0f0f' }}>
                                                            <Typography variant="h5" style={{ animation: "fadeIn 1s", fontWeight: styleConstants.fontWeight.semiBold, fontFamily: styleConstants.fontStyle, marginTop: "10px" }}>
                                                                TOEIC
                                                            </Typography>
                                                        </Link>
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </VisibilitySensor>
                    </Grid> : <Grid item xs={12} style={{ maxWidth: window.innerWidth, zIndex: 1 }}>
                        <VisibilitySensor onChange={(isVisible) => {
                            this.handlehandleShowAnimation(isVisible);
                        }}>
                            <Grid container>
                                <Grid item align="center" xs={12}>
                                    <Fade top cascade>
                                        <Grid container>
                                            <Grid item align="center" xs={12}>
                                                <Typography variant="h4" style={{ fontFamily: styleConstants.fontStyle, fontWeight: styleConstants.fontWeight.bold, color: "#991514" }} >
                                                    OUR TESTS
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} align="center" style={{ marginTop: '2%', paddingLeft: "25%" }}>
                                                <Typography inline align="left" variant="body1" style={{ fontFamily: styleConstants.fontStyle, fontWeight: styleConstants.fontWeight.thin }}>
                                                    <CheckBoxOutlinedIcon /> Computer-based tests.
                                                </Typography>
                                                <Typography align="left" variant="body1" style={{ ffontFamily: styleConstants.fontStyle, fontWeight: styleConstants.fontWeight.thin, }}>
                                                    <CheckBoxOutlinedIcon /> Original materials
                                                </Typography>
                                                <Typography align="left" variant="body1" style={{ fontFamily: styleConstants.fontStyle, fontWeight: styleConstants.fontWeight.thin, }}>
                                                    <CheckBoxOutlinedIcon /> Intelligent skill analysis
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Fade>
                                </Grid>
                                <Grid item align="center" xs={12} style={{ zIndex: 1, paddingTop: "10%", paddingBottom: "0px" }}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Grid container spacing={2} style={{ paddingLeft: "5%", paddingRight: "5%" }}>
                                                <Grid item align="center" xs={6} style={{ height: "200px" }}>
                                                    {this.state.isPaperAppear && <Paper style={{
                                                        padding: "16px",
                                                        paddingBottom: "0px",
                                                        height: "100px",
                                                        width: "100%",
                                                        animation: "fadeIn 1s",
                                                        borderRadius: "15px",
                                                        background: "#97a7b2",
                                                        position: "relative",
                                                    }} >
                                                        {this.state.isImageAppear && <Link to="/ourtests" style={{ textDecoration: 'none', color: '#0f0f0f' }}>
                                                            <CardMedia
                                                                component="img"
                                                                style={{
                                                                    position: "absolute",
                                                                    bottom: 0,
                                                                    left: 0,
                                                                    right: 0,
                                                                    top: 0,
                                                                    marginTop: "-27px",
                                                                    height: "180px",
                                                                    width: "100%",
                                                                    backgroundSize: "cover",
                                                                }}
                                                                src="graphics/IELTS (Academic).png"
                                                            />
                                                        </Link>}
                                                    </Paper>}
                                                    {this.state.isTextAppear &&
                                                        <Link to="/ourtests" style={{ textDecoration: 'none', color: '#0f0f0f' }}>
                                                            <Typography variant="body1" style={{ animation: "fadeIn 1s", fontWeight: styleConstants.fontWeight.semiBold, fontFamily: styleConstants.fontStyle, marginTop: "10px" }}>
                                                                IELTS (ACADEMIC)
                                                            </Typography>
                                                        </Link>
                                                    }
                                                </Grid>
                                                <Grid item align="center" xs={6} style={{ height: "200px" }}>
                                                    {this.state.isPaperAppear && <Paper style={{
                                                        padding: "16px",
                                                        paddingBottom: "0px",
                                                        height: "100px",
                                                        width: "100%",
                                                        animation: "fadeIn 1s",
                                                        borderRadius: "15px",
                                                        background: "#97a7b2",
                                                        position: "relative",
                                                    }} >
                                                        {this.state.isImageAppear && <Link to="/ourtests" style={{ textDecoration: 'none', color: '#0f0f0f' }}>
                                                            <CardMedia
                                                                component="img"
                                                                style={{
                                                                    position: "absolute",
                                                                    bottom: 0,
                                                                    left: 0,
                                                                    right: 0,
                                                                    top: 0,
                                                                    marginTop: "-26.8px",
                                                                    height: "180px",
                                                                    width: "100%",
                                                                    backgroundSize: "cover",
                                                                }}
                                                                src="graphics/BMAT.png"
                                                            />
                                                        </Link>}
                                                    </Paper>}
                                                    {this.state.isTextAppear &&
                                                        <Link to="/ourtests" style={{ textDecoration: 'none', color: '#0f0f0f' }}>
                                                            <Typography variant="body1" style={{ animation: "fadeIn 1s", fontWeight: styleConstants.fontWeight.semiBold, fontFamily: styleConstants.fontStyle, marginTop: "10px" }}>
                                                            TBAT
                                                            </Typography>
                                                        </Link>
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={2} style={{ paddingLeft: "5%", paddingRight: "5%" }}>
                                                <Grid item align="center" xs={6} style={{ height: "150px" }}>
                                                    {this.state.isPaperAppear && <Paper style={{
                                                        padding: "16px",
                                                        paddingBottom: "0px",
                                                        height: "100px",
                                                        width: "100%",
                                                        animation: "fadeIn 1s",
                                                        borderRadius: "15px",
                                                        background: "#97a7b2",
                                                        position: "relative",
                                                    }} >
                                                        {this.state.isImageAppear && <Link to="/ourtests" style={{ textDecoration: 'none', color: '#0f0f0f' }}>
                                                            <CardMedia
                                                                component="img"
                                                                style={{
                                                                    position: "absolute",
                                                                    bottom: 0,
                                                                    left: 0,
                                                                    right: 0,
                                                                    top: 0,
                                                                    marginTop: "-27px",
                                                                    height: "180px",
                                                                    width: "100%",
                                                                    backgroundSize: "cover",
                                                                }}
                                                                src="graphics/CU-TEP.png"
                                                            />
                                                        </Link>}
                                                    </Paper>}
                                                    {this.state.isTextAppear &&
                                                        <Link to="/ourtests" style={{ textDecoration: 'none', color: '#0f0f0f' }}>
                                                            <Typography variant="body1" style={{ animation: "fadeIn 1s", fontWeight: styleConstants.fontWeight.semiBold, fontFamily: styleConstants.fontStyle, marginTop: "10px" }}>
                                                                CU-TEP
                                                            </Typography>
                                                        </Link>
                                                    }
                                                </Grid>
                                                <Grid item align="center" xs={6} style={{ height: "150px" }}>
                                                    {this.state.isPaperAppear && <Paper style={{
                                                        padding: "16px",
                                                        paddingBottom: "0px",
                                                        height: "100px",
                                                        width: "100%",
                                                        animation: "fadeIn 1s",
                                                        borderRadius: "15px",
                                                        background: "#97a7b2",
                                                        position: "relative",
                                                    }} >
                                                        {this.state.isImageAppear && <Link to="/ourtests" style={{ textDecoration: 'none', color: '#0f0f0f' }}>
                                                            <CardMedia
                                                                component="img"
                                                                style={{
                                                                    position: "absolute",
                                                                    bottom: 0,
                                                                    left: 0,
                                                                    right: 0,
                                                                    top: 0,
                                                                    marginTop: "-27.5px",
                                                                    height: "180px",
                                                                    width: "100%",
                                                                    backgroundSize: "cover",
                                                                }}
                                                                src="graphics/TOEIC.png"
                                                            />
                                                        </Link>}
                                                    </Paper>}
                                                    {this.state.isTextAppear &&
                                                        <Link to="/ourtests" style={{ textDecoration: 'none', color: '#0f0f0f' }}>
                                                            <Typography variant="body1" style={{ animation: "fadeIn 1s", fontWeight: styleConstants.fontWeight.semiBold, fontFamily: styleConstants.fontStyle, marginTop: "10px" }}>
                                                                TOEIC
                                                            </Typography>
                                                        </Link>
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} align="center" style={{ marginTop: '40px' }}>
                                            <Link to="/ourtests" style={{ textDecoration: 'none', color: '#0f0f0f' }}>
                                                <Button variant="contained" style={{ backgroundColor: "#3f5e75", color: "white" }}>
                                                    <Typography variant="body1" style={{ fontWeight: styleConstants.fontWeight.semiBold, fontFamily: styleConstants.fontStyle }}>
                                                        Our tests
                                                    </Typography>
                                                </Button>
                                            </Link>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </VisibilitySensor>
                    </Grid>
                }
            </MediaQuery>
        )
    }
}

export default compose(
    withStyles(useStyles)
)(OurTestsSectionMobile);
