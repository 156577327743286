import React from 'react';

//Material UI
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import MuiAlert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

//Others
import { withStyles } from '@material-ui/core/styles';
import { Component } from 'react';
import { compose } from 'recompose';

const axios = require('axios').default;
const FormData = require('form-data');
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

const useStyles = theme => ({
    root: {
        margin: 15,
        marginTop: 50
    },
    margin: {
        margin: theme.spacing(1),
    },
    buttonColor: {
        backgroundColor: '#76323F',
        color: 'white'
    }
});

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class ListOfHeading extends Component {
    constructor() {
        super()
        this.state = {
            tempChoiceCount: [1],
            choiceCount: 1,
            tempQuestionCount: [1],
            questionArr: []
        };
    }

    componentDidMount = async () => {

    }

    handleEditChoice = (event, choiceIdx) => {
        this.props.questionObj.choice[choiceIdx] = event.target.value;
    }

    handleAddChoice = () => {
        this.state.tempChoiceCount.push(this.state.choiceCount);

        this.setState({
            tempChoiceCount: this.state.tempChoiceCount,
            choiceCount: this.state.choiceCount + 1
        });
    }

    handleEditQuestion = async (event, questionIdx) => {
        this.props.questionObj.question = event.target.value;
    }

    handleEditParagraph = async (event, questionIdx) => {
        this.props.questionObj.paragraph = event.target.value;
    }

    handleEditAnswer = async (event, questionIdx) => {
        this.props.questionObj.answer = event.target.value;
    }

    handleEditQuestionNumber = async (event) => {
        this.props.questionObj.no = event.target.value;
    }

    render() {
        const { classes } = this.props;

        return (
            <Grid item xs={12}>
                <Grid container spacing={1}>
                    {this.props.qIdx === 0 &&
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                                        Choices
                                    </Typography>
                                </Grid>
                                {this.state.tempChoiceCount.map((choice, choiceIdx) =>
                                    <Grid key={`choice-${choiceIdx}`} item xs={12}>
                                        <TextField
                                            id="outlined-size-small"
                                            variant="outlined"
                                            size="small"
                                            onChange={(event) => { this.handleEditChoice(event, choiceIdx) }}
                                            fullWidth
                                        />
                                    </Grid>
                                )}
                                <Grid item align="center" xs={12}>
                                    <IconButton aria-label="add" onClick={this.handleAddChoice} style={{ margin: 1 }}>
                                        <AddCircleRoundedIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                            Questions
                        </Typography>
                    </Grid>
                    {this.state.tempQuestionCount.map((question, index) =>
                        <Grid item key={`question-${index}`} xs={12}>
                            <Grid container spacing={1} style={{ marginTop: '10px' }}>
                                <Grid item xs={12}>
                                    <TextField
                                        id="outlined-size-small"
                                        variant="outlined"
                                        size="small"
                                        label="Question"
                                        onChange={(event) => { this.handleEditQuestion(event, index) }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="outlined-size-small"
                                        variant="outlined"
                                        size="small"
                                        label="Paragraph"
                                        onChange={(event) => { this.handleEditParagraph(event, index) }}
                                        fullWidth
                                        multiline
                                        maxRows={4}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="outlined-size-small"
                                        variant="outlined"
                                        size="small"
                                        label="Answer"
                                        onChange={(event) => { this.handleEditAnswer(event, index) }}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider style={{ backgroundColor: "#76323F", height: '5px', marginTop: '10px', marginBottom: '10px' }} />
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        )
    }
}
export default compose(
    withStyles(useStyles)
)(ListOfHeading);