//React
import { Component } from "react";
import { compose } from "recompose";

//Material UI
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Checkbox from "@material-ui/core/Checkbox";
import Container from "@material-ui/core/Container";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Slider from "@material-ui/core/Slider";
import Typography from "@material-ui/core/Typography";
import {
  ThemeProvider,
  createMuiTheme,
  withStyles,
} from "@material-ui/core/styles";
import AccessAlarmRoundedIcon from "@material-ui/icons/AccessAlarmRounded";
import AccountBoxRoundedIcon from "@material-ui/icons/AccountBoxRounded";
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded";
import ArrowForwardRoundedIcon from "@material-ui/icons/ArrowForwardRounded";
import VolumeDown from "@material-ui/icons/VolumeDown";
import VolumeUp from "@material-ui/icons/VolumeUp";

//import components
import ReactAudioPlayer from "react-audio-player";
import PageBackdrop from "../../../../main/PageBackdrop";
import TestSoundPage from "../../listening/TestSoundPage";
import UserInfoPage from "../../listening/UserInfoPage";
import ChooseTwoLetters from "../../listening/questions/ChooseTwoLetters";
import CompleteTheNotes from "../../listening/questions/CompleteTheNotes";
import CompleteTheTables from "../../listening/questions/CompleteTheTables";
import Map from "../../listening/questions/Map";
import Matching from "../../listening/questions/Matching";
import MultipleChoice from "../../listening/questions/MultipleChoice";
import InstructionsPage from "../instructionPage/listening/InstructionsPage";

//models
import { PrepareAnsObj } from "../../../../../models/testSubmittion";

//Icons
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import RemoveRoundedIcon from '@material-ui/icons/RemoveRounded';

//others
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
import MediaQuery from 'react-responsive';

//Setup
const axios = require("axios").default;
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  rootContainer: {
    margin: 100,
  },
  margin: {
    margin: 100,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(5),
  },
  questionNumber: {
    maxWidth: "20px",
    maxHeight: "20px",
    minWidth: "20px",
    minHeight: "20px",
    margin: "0.5%",
  },
});

//FONT RELATED
const theme = createMuiTheme({
  typography: {
    fontFamily: ["Noto Sans"].join(","),
  },
});

class TestListening extends Component {
  constructor() {
    super();
    this.state = {
      volume: 0.5,
      isPlayTestSound: false,
      currentPage: 0,
      timer: 2100,
      sumTime: [],
      questionCount: 40,
      freeQuestionCount: 20,
      timerSeconds: 0,
      focusedQuestion: 0,
      progressSound: 1,
      progressSection: 1,
      answers: [],
      answeredQuestion: [],
      reviewedQuestion: [],
      questionNumArr: [],
      currentPart: 1,
      currentSection: 1,
      currentQuestion: 1,
      fontSize: 0.8,
      fontSizeMap: "lg",
      sizeMapHeader: {
        'sm': 'body1',
        'md': 'body1',
        'lg': 'body1',
      },
      sizeMapBody: {
        'sm': 'body1',
        'md': 'body1',
        'lg': 'body1',
      },
      sizeMapCaption: {
        'sm': 'body1',
        'md': 'body1',
        'lg': 'body1',
      },
      window: {
        height: window.innerHeight,
        width: window.innerWidth,
      },
      isEndLastSound: false,
      isStartTest: false,
      isCheckUserInfo: true,
      isReadInstruction: false,
      isTestSound: false,
      isConfirmDone: false,
      questionSeparation: [],
    };
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  componentDidMount = async () => {
    this.checkSession();
    window.addEventListener("resize", this.handleResize);
  };

  checkSession = async () => {
    if (this.props.isFreeTest) {
      await this.createQuestionArr();
      this.setState({
        timer: 1200
      });
      this.props.handleCloseLoading();
    } if (this.props.isDummyTest) {
      await this.createQuestionArr();
      this.props.keepCurrentSession(this.state);
      this.props.handleCloseLoading();
    } else {
      if (this.props.session !== null) {
        await this.setState(this.props.session);
        await this.props.keepCurrentSession(this.state);
        await this.createQuestionArr();
        this.handleStartTimer();
        this.props.handleCloseLoading();
      } else {
        await this.createQuestionArr();
        this.props.keepCurrentSession(this.state);
        //this.props.handleStartTest();
        this.props.handleCloseLoading();
      }
    }
  }

  //================ PREPARATION ===================
  createQuestionArr = async () => {
    for (let i = 1; i < (this.props.isFreeTest ? 21 : 41); i++) {
      this.state.questionNumArr[i] = i;
    }

    this.setState({
      questionNumArr: this.state.questionNumArr,
    });

  };

  onClickFontSize = (size) => {
    if (size === "sm") {
      this.setState({
        fontSize: 0.7,
        fontSizeMap: "sm"
      });
    } if (size === "md") {
      this.setState({
        fontSize: 0.8,
        fontSizeMap: "lg"
      });
    } if (size === "lg") {
      this.setState({
        fontSize: 1,
        fontSizeMap: "lg"
      });
    }

    return;
  }

  handleFontSize = (type) => {
    if (type === 'plus') {
      this.setState({
        fontSize: this.state.fontSize + 0.1
      })
    } else if (type === 'minus') {
      this.setState({
        fontSize: this.state.fontSize - 0.1
      })
    }
  }

  handleRestTextSize = () => {
    this.setState({
      fontSize: 0.8
    });
  }

  handleAnswerObj = (answerObj) => {
    const selectedPart = this.state.answerObj.parts.find(
      (part) => part.part === this.state.currentPart
    );
    const selectedSection = selectedPart.sections.find(
      (section) => section.section_id === answerObj.section_id
    );

    this.handleCheckQuestion(answerObj);

    if (selectedSection) {
      selectedSection.answers = answerObj.answers;
      return;
    }

    selectedPart.sections.push(answerObj);

    /*const selectedPart = this.state.answerObj.parts.find(part => part.part === this.state.currentPart)
        const selectedSection = selectedPart.sections.find(section => section.section_id === answerObj.section_id)
        if (selectedSection) {
            console.log(this.state.answerObj);
            return;
        }
        selectedPart.sections.push(answerObj);*/
  };

  handleCheckQuestion = (answerObj) => {
    if (["matchingSentenceEndings"].includes(answerObj.question_type)) {
      const questionCount = Object.keys(answerObj.answers)[0];
      const firstQuestion = questionCount.split("-")[0];
      answerObj.answers[questionCount].map((question, index) => {
        this.state.answeredQuestion[Number(firstQuestion) + index] = true;
      });
    } else {
      const qNumArr = Object.keys(answerObj.answers);

      qNumArr.map((num, index) => {
        this.state.answeredQuestion[num] = true;
      });
    }
  };

  handleAnsweredQuestionNumber = (questionNo) => {
    this.state.answeredQuestion[questionNo] = true;
    this.setState({
      answeredQuestion: this.state.answeredQuestion,
    });
  };

  handleReviewQuestion = (event) => {
    if (Array.isArray(this.state.focusedQuestion)) {
      this.state.focusedQuestion.map((num) => {
        this.state.reviewedQuestion[Number(num)] =
          event.target.checked;
        this.setState({
          reviewedQuestion: this.state.reviewedQuestion,
        });
      });
    } else {
      this.state.reviewedQuestion[Number(this.state.focusedQuestion)] =
        event.target.checked;
      this.setState({
        reviewedQuestion: this.state.reviewedQuestion,
      });
    }

  };

  onFocusQuestion = (questionNumber) => {
    this.setState({
      focusedQuestion: questionNumber,
    });

  };

  handleCancelQuestionNumber = (questionNumber) => {
    this.state.answeredQuestion[questionNumber] = null;

    this.setState({
      answeredQuestion: this.state.answeredQuestion,
    });

  };

  handleGetResult = async () => {
    try {
      this.handleLoading();
      this.props.handleUpdateAnswerObj(this.state.answerObj);
      if (this.props.isFreeTest) {
        await this.props.handleCreateStudentReport();
      } else {
        this.props.handleStartTestType("READING");
      }
      this.handleCloseLoading();
    } catch (error) {
      this.handleCloseLoading();
      console.error(error);
    }
  };

  handleConfirmDoneTestDialog = async () => {
    const answerCountArr = await this.state.answeredQuestion.filter(
      (answer) => answer
    );

    if (answerCountArr.length != this.state.questionCount) {
      this.setState({
        isErrorConfirmDone: true,
        isNotAllQuestion: false,
      });
    } else if (answerCountArr.length == this.state.questionCount) {
      this.setState({
        isConfirmDone: true,
        isNotAllQuestion: true,
      });
    }
  };

  handleCloseConfirmDoneTestDialog = () => {
    this.setState({
      isConfirmDone: false,
      isErrorConfirmDone: false,
    });
  };

  handleDoneTestDialog = async () => {
    this.setState({
      isErrorConfirmDone: false,
      isConfirmDone: false,
    });

    await this.handleGetResult();

    this.setState({
      isDone: true,
      isStartTest: false,
    });
  };

  handleResize = (event) => {
    this.setState({
      window: {
        height: window.innerHeight,
        width: window.innerWidth,
      },
    });
  };

  //======================== BEGIN BACKDROP CONTROL ==============================
  handleCloseLoading = () => {
    this.setState({
      isLoading: false,
    });
  };

  handleLoading = () => {
    this.setState({
      isLoading: true,
    });
  };
  //======================== END BACKDROP CONTROL ===============================

  //========================= TIMER =========================
  handleStartTimer = () => {
    clearInterval(this.myInterval);

    this.myInterval = setInterval(() => {
      if (this.state.timer > 0) {
        if (this.state.timerSeconds === 0) {
          this.setState({
            timerSeconds: 60,
          });
        }

        this.setState((prevState) => ({
          timer: prevState.timer - 1,
          timerSeconds: this.state.timerSeconds - 1,
        }));
      }
    }, 1000);
  };

  handleAddLeadingZero = (num) => {
    if (num < 10) {
      return "0" + num;
    } else if (num >= 10) {
      return "" + num;
    }
  };
  //========================= TIMER =========================

  getTest = async () => {
    let getTest_url;
    if (this.props.isDummyTest) {
      getTest_url = `https://steep-legend-midnight.glitch.me/test/dummy`;
    } else {
      getTest_url = `${api_base_url}/api/test/random?test_type=IELTS&is_free=${this.props.isFreeTest}`;
    }

    const signinSession_url = api_base_url + "/api/oauth/login";

    try {
      this.handleLoading();

      const testObj = await axios.get(getTest_url, {
        headers: {
          Authorization:
            "bearer " + JSON.parse(localStorage.getItem("user")).token,
        },
      });

      if (this.props.isDummyTest) {
        testObj.data["test"] = testObj.data;
      }

      testObj.data.test.parts.forEach(part => {
        part = part.sections.sort((a, b) => {
          return Number(a.section.split(a.section.includes("Questions") ? "Questions " : "Question ")[1].split(" ")[0]) - Number(b.section.split(b.section.includes("Questions") ? "Questions " : "Question ")[1].split(" ")[0]);
        });
      })

      let filteredTest = await testObj.data.test.parts.filter(
        (parts) => parts.part_type === "LISTENING"
      );

      const answerObj = await PrepareAnsObj(
        filteredTest,
        testObj.data.test.test_id
      );

      this.prepareTestQuestion(filteredTest);

      await this.setState({
        answerObj: answerObj,
        test_id: testObj.data.test.test_id,
        currentTestObject: filteredTest,
        currentSection: 1,
        currentPart: 1,
        playingSound: filteredTest[0].sections[0].questions[0].voice,
        sectionLength: filteredTest[0].sections.length,
        numPart: filteredTest.length,
      });

      if (this.props.isFreeTest || this.props.isDummyTest) {
        //this.handleCloseLoading();
        return;
      } else {
        this.props.handleSetCentralTestId(testObj.data.test.test_id);
        await this.props.handleEndTestSession();
      }

      //this.handleCloseLoading();
    } catch (error) {
      this.handleCloseLoading();
      console.error(error);
    }
  };

  prepareTestQuestion = (testObj) => {
    let tempArr = [];
    let count = 0;

    testObj.map((part) => {
      tempArr.push({
        part: part.part,
        total_question: part.total_question + count
      });

      count += part.total_question;
      return tempArr;
    });

    this.setState({
      questionSeparation: tempArr
    })
  }

  handleClickQuestionBox = (num) => {
    this.handleLoading();

    const filteredParts = this.state.questionSeparation.filter((part) => { return part.total_question >= num });
    const sectionCount = this.state.currentTestObject[filteredParts[0].part - 1].sections.length;

    if (filteredParts[0].part <= this.state.progressSound) {
      if (sectionCount === 1) {
        this.setState({
          currentPart: filteredParts[0].part,
          currentSection: 1,
          sectionLength: sectionCount
        });
      } if (sectionCount > 1) {
        let tempSection = [];
        let sectionCountdown = filteredParts[0].total_question;
        const allSections = this.state.currentTestObject[filteredParts[0].part - 1].sections;
        this.state.currentTestObject[filteredParts[0].part - 1].sections.map((section, index) => {
          tempSection.push({
            section: sectionCount - index,
            total_question: sectionCountdown
          });

          sectionCountdown -= allSections[sectionCount - (index + 1)].total_question;

          return tempSection;
        });

        const filteredSection = tempSection.sort((a, b) => { return a.section - b.section }).filter((section) => { return section.total_question >= num });

        this.setState({
          currentPart: filteredParts[0].part,
          currentSection: filteredSection[0].section,
          sectionLength: sectionCount
        });
      }

      this.setState({
        focusedQuestion: num
      });
    }

    this.props.keepCurrentSession(this.state);
    this.handleCloseLoading();
  }

  handleSubQuestion = (testObj) => {
    if (testObj.question_type === "multipleChoice") {
      return (
        <Grid
          item
          align="left"
          xs={12}
          style={{
            backgroundColor: "#25333e",
            color: "white",
            padding: "10px",
            borderRadius: "5px",
          }}
        >
          <Typography
            variant={this.state.sizeMapBody[`${this.state.fontSizeMap}`]}
            gutterBottom
            align="left"
            style={{ fontWeight: "bold" }}
          >
            {testObj.section}
          </Typography>
          <Typography variant={this.state.sizeMapBody[`${this.state.fontSizeMap}`]} align="left">
            Choose the correct answer. <span style={{ fontSize: 6, color: "#DDDDDD" }}>{btoa(this.props.user.username)}</span>
          </Typography>
        </Grid>
      );
    } else if (testObj.question_type === "matching") {
      return (
        <Grid
          align="left"
          item
          xs={12}
          style={{
            backgroundColor: "#25333e",
            color: "white",
            padding: "10px",
            borderRadius: "5px",
          }}
        >
          <Typography
            variant={this.state.sizeMapBody[`${this.state.fontSizeMap}`]}
            gutterBottom
            style={{ fontWeight: "bold" }}
          >
            Question {testObj.questions[0].no.split("-")[0]} - {testObj.questions[0].no.split("-")[1]}
          </Typography>
          <Typography variant={this.state.sizeMapBody[`${this.state.fontSizeMap}`]} w>
            {testObj.questions[0].question} <span style={{ fontSize: 6, color: "#DDDDDD" }}>{btoa(this.props.user.username)}</span>
          </Typography>
        </Grid>
      );
    } else if (testObj.question_type === "completeTheTables") {
      return (
        <Grid
          item
          align="left"
          xs={12}
          style={{
            backgroundColor: "#25333e",
            color: "white",
            padding: "10px",
            borderRadius: "5px",
          }}
        >
          <Typography
            variant={this.state.sizeMapBody[`${this.state.fontSizeMap}`]}
            gutterBottom
            style={{ fontWeight: "bold" }}
          >
            Question {testObj.questions[0].no.split("-")[0]} - {testObj.questions[0].no.split("-")[1]}
          </Typography>
          <Typography variant={this.state.sizeMapBody[`${this.state.fontSizeMap}`]} >
            <span style={{ fontWeight: "bold" }}>Complete the notes below. </span>{testObj.questions[0].question}
            {/*Write{" "}
            <span style={{ fontWeight: "bold" }}>NO MORE THAN TWO WORDS</span>{" "}
            for each answer. */}<span style={{ fontSize: 6, color: "#DDDDDD" }}>{btoa(this.props.user.username)}</span>
          </Typography>
        </Grid>
      );
    } else if (testObj.question_type === "completeTheNotes") {
      return (
        <Grid
          item
          align="left"
          xs={12}
          style={{
            backgroundColor: "#25333e",
            color: "white",
            padding: "10px",
            borderRadius: "5px",
          }}
        >
          <Typography
            variant={this.state.sizeMapBody[`${this.state.fontSizeMap}`]}
            gutterBottom
            style={{ fontWeight: "bold" }}
          >
            Question {testObj.questions[0].no.split("-")[0]} - {testObj.questions[0].no.split("-")[1]}
          </Typography>
          <Typography variant={this.state.sizeMapCaption[`${this.state.fontSizeMap}`]}>
            {testObj.questions[0].question} <span style={{ fontSize: 6, color: "#DDDDDD" }}>{btoa(this.props.user.username)}</span>
          </Typography>
        </Grid>
      );
    } else if (testObj.question_type === "choose2Letters") {
      return (
        <Grid
          item
          align="left"
          xs={12}
          style={{
            backgroundColor: "#25333e",
            color: "white",
            padding: "10px",
            borderRadius: "5px",
          }}
        >
          <Grid container justify="flex-start">
            <Grid item xs={12}>
              <Typography variant={this.state.sizeMapBody[`${this.state.fontSizeMap}`]} gutterBottom align="left">
                <Box fontWeight="fontWeightBold" display="inline">
                  {testObj.section}
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={this.state.sizeMapBody[`${this.state.fontSizeMap}`]} align="left">
                Choose{" "}
                <Box fontWeight="fontWeightBold" display="inline">
                  TWO
                </Box>{" "}
                correct answers. <span style={{ fontSize: 6, color: "#DDDDDD" }}>{btoa(this.props.user.username)}</span>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      );
    } else if (testObj.question_type === "map") {
      return (
        <Grid
          align="left"
          item
          xs={12}
          style={{
            backgroundColor: "#25333e",
            color: "white",
            padding: "10px",
            borderRadius: "5px",
          }}
        >
          <Typography variant={this.state.sizeMapBody[`${this.state.fontSizeMap}`]} gutterBottom>
            <Box fontWeight="fontWeightBold" display="inline">
              {" "}
              {testObj?.section}{" "}
            </Box>
          </Typography>
          <Typography variant={this.state.sizeMapBody[`${this.state.fontSizeMap}`]}>
            {testObj.questions[0].question} <span style={{ fontSize: 6, color: "#DDDDDD" }}>{btoa(this.props.user.username)}</span>
          </Typography>
        </Grid>
      );
    }
  };

  handleQuestionType = (section) => {
    if (section.question_type === "multipleChoice") {
      return (
        <MultipleChoice
          key={section.section_id}
          currentTestObject={section}
          fontSizeMap={this.state.fontSizeMap}
          fontSize={this.state.fontSize}
          answer={this.state.answer}
          handleChange={this.handleChange}
          playingSound={this.state.playingSound}
          volume={this.state.volume}
          currentSection={this.state.currentSection - 1}
          currentPart={this.state.currentPart - 1}
          handleAnswerObj={this.handleAnswerObj}
          answerObj={this.state.answerObj}
          handleAnsweredQuestionNumber={this.handleAnsweredQuestionNumber}
          onFocusQuestion={this.onFocusQuestion}
        />
      );
    } else if (section.question_type === "matching") {
      return (
        <Matching
          key={section.section_id}
          currentTestObject={section}
          fontSizeMap={this.state.fontSizeMap}
          fontSize={this.state.fontSize}
          answer={this.state.answer}
          handleAnswer={this.handleAnswer}
          playingSound={this.state.playingSound}
          volume={this.state.volume}
          currentSection={this.state.currentSection - 1}
          currentPart={this.state.currentPart - 1}
          handleAnswerObj={this.handleAnswerObj}
          answerObj={this.state.answerObj}
          handleAnsweredQuestionNumber={this.handleAnsweredQuestionNumber}
          onFocusQuestion={this.onFocusQuestion}
          handleCancelQuestionNumber={this.handleCancelQuestionNumber}
        />
      );
    } else if (section.question_type === "completeTheTables") {
      return (
        <CompleteTheTables
          key={section.section_id}
          currentTestObject={section}
          fontSizeMap={this.state.fontSizeMap}
          fontSize={this.state.fontSize}
          answer={this.state.answer}
          handleAnswer={this.handleAnswer}
          playingSound={this.state.playingSound}
          volume={this.state.volume}
          currentSection={this.state.currentSection - 1}
          currentPart={this.state.currentPart - 1}
          handleAnswerObj={this.handleAnswerObj}
          answerObj={this.state.answerObj}
          handleAnsweredQuestionNumber={this.handleAnsweredQuestionNumber}
          onFocusQuestion={this.onFocusQuestion}
        />
      );
    } else if (section.question_type === "completeTheNotes") {
      return (
        <CompleteTheNotes
          key={section.section_id}
          currentTestObject={section}
          fontSizeMap={this.state.fontSizeMap}
          fontSize={this.state.fontSize}
          answer={this.state.answer}
          handleAnswer={this.handleAnswer}
          playingSound={this.state.playingSound}
          volume={this.state.volume}
          currentSection={this.state.currentSection - 1}
          currentPart={this.state.currentPart - 1}
          handleAnswerObj={this.handleAnswerObj}
          answerObj={this.state.answerObj}
          handleAnsweredQuestionNumber={this.handleAnsweredQuestionNumber}
          onFocusQuestion={this.onFocusQuestion}
        />
      );
    } else if (section.question_type === "choose2Letters") {
      return (
        <ChooseTwoLetters
          key={section.section_id}
          currentTestObject={section}
          fontSizeMap={this.state.fontSizeMap}
          fontSize={this.state.fontSize}
          answer={this.state.answer}
          handleAnswer={this.handleAnswer}
          playingSound={this.state.playingSound}
          volume={this.state.volume}
          currentSection={this.state.currentSection - 1}
          currentPart={this.state.currentPart - 1}
          handleAnswerObj={this.handleAnswerObj}
          answerObj={this.state.answerObj}
          handleAnsweredQuestionNumber={this.handleAnsweredQuestionNumber}
          onFocusQuestion={this.onFocusQuestion}
        />
      );
    } else if (section.question_type === "map") {
      return (
        <Map
          key={section.section_id}
          currentTestObject={section}
          fontSize={this.state.fontSize}
          answer={this.state.answer}
          handleAnswer={this.handleAnswer}
          playingSound={this.state.playingSound}
          volume={this.state.volume}
          currentSection={this.state.currentSection - 1}
          currentPart={this.state.currentPart - 1}
          handleAnswerObj={this.handleAnswerObj}
          answerObj={this.state.answerObj}
          handleAnsweredQuestionNumber={this.handleAnsweredQuestionNumber}
          onFocusQuestion={this.onFocusQuestion}
        />
      );
    }
  };

  handleChangeQuestion = async (action) => {
    if (action === "Next") {
      const sectionLength =
        this.state.currentTestObject[this.state.currentPart - 1].sections
          .length;

      if (this.state.currentPart === 4) {
        this.props.keepCurrentSession(this.state);
        return;
      } else if (this.state.currentSection >= sectionLength) {
        await this.setState({
          currentPart: this.state.currentPart + 1,
          currentSection: 1,
          sectionLength: this.state.currentTestObject[this.state.currentPart].sections
            .length
        });

        this.props.keepCurrentSession(this.state);


        /*if (this.state.currentPart > this.state.progressSound) {
          this.setState({
            progressSound: this.state.currentPart,
            progressSection: 1
          });
        }*/

        return;
      } else if (this.state.currentSection < sectionLength) {
        this.setState({
          currentSection: this.state.currentSection + 1
          //progressSection: this.state.currentSection + 1
        });

        this.props.keepCurrentSession(this.state);
        return;
      } else if (this.state.currentPart >= this.state.numPart) {
        this.props.keepCurrentSession(this.state);
        return;
      }
    } else if (action === "Back") {
      if (this.state.currentPart === 1) {
        this.props.keepCurrentSession(this.state);
        return;
      } else if (this.state.currentSection === 1) {
        this.setState({
          currentPart: this.state.currentPart - 1,
          currentSection:
            this.state.currentTestObject[this.state.currentPart - 2].sections
              .length,
        });

        this.props.keepCurrentSession(this.state);
        return;
      } else if (this.state.currentSection > 1) {
        this.setState({
          currentSection: this.state.currentSection - 1,
        });
        this.props.keepCurrentSession(this.state);
        return;
      }
    }
  };

  handleLoadMetadata = (meta) => {
    this.handleLoading()
    const { duration } = meta.target;
    this.state.sumTime.push(duration);
    this.setState({
      sumTime: this.state.sumTime,
    });

    if (this.state.sumTime.length === this.state.numPart) {
      this.setState({
        timer: Math.floor([...new Set(this.state.sumTime)].reduce((a, b) => a + b, 0))
      });

      this.handleCloseLoading();
    }
  }

  onSoundEnd = async () => {
    if (this.state.progressSound >= this.state.numPart) {
      this.setState({
        isEndLastSound: true,
      });

      return;
    }

    await this.setState({
      progressSound: this.state.progressSound + 1,
    });

    /*this.setState({
      currentPart: this.state.currentPart + 1,
    });*/

    return;
  };

  handleChangeVolume = (event, currentVolume) => {
    let volumeValue = currentVolume / 100;
    this.setState({
      volume: volumeValue,
    });
  };

  handleSoundControl = (action) => {
    if (action === "play") {
      this.setState({
        isPlayTestSound: true,
      });
    }
    if (action === "pause") {
      this.setState({
        isPlayTestSound: false,
      });
    }
    if (action === "stop") {
      this.audioGetReady.currentTime = 0;
      this.setState({
        isPlayTestSound: false,
      });
    }
  };

  handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const isAnswered = "q" + name.split("_")[1];
    let ansArr = this.state.answers;

    if (this.state[isAnswered] === undefined) {
      let ansObj = {
        question: name.split("_")[1],
        answer: value,
        section: name.split("_")[0],
      };
      ansArr.push(ansObj);
    } else if (this.state[isAnswered] !== undefined) {
      let index = ansArr.findIndex(
        (obj) => obj.question === name.split("_")[1]
      );

      ansArr[index].answer = value;
    }

    this.setState({
      ...this.state,
      answers: ansArr,
      [isAnswered]: true,
    });
  };

  handleAnswer = (question) => {
    const isAnswered = "q" + question.split("_")[1];
    this.setState({
      ...this.state,
      [isAnswered]: true,
    });
  };

  //================================= BEGIN PRE-TEST ================================
  handleUserInfoPage = () => {
    this.setState({
      isStartTest: false,
      isCheckUserInfo: true,
      isReadInstruction: false,
      isTestSound: false,
    });
  };

  handleUserInfoChecked = () => {
    this.setState({
      isStartTest: false,
      isCheckUserInfo: false,
      isReadInstruction: false,
      isTestSound: true,
    });
  };

  handleDoneSoundTest = () => {
    this.setState({
      isStartTest: false,
      isCheckUserInfo: false,
      isReadInstruction: true,
      isTestSound: false,
    });
  };

  handleDoneReadInstruction = async () => {
    await this.getTest();

    this.setState({
      isStartTest: true,
      isCheckUserInfo: false,
      isReadInstruction: false,
      isTestSound: false,
    });

    this.handleStartTimer();
  };
  //================================= END PRE-TEST =================================

  render() {
    const { classes } = this.props;
    const timesUp = (
      <Dialog
        open={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Time's up!"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please click on{" "}
            <Box fontWeight="fontWeightBold" display="inline">
              Confirm
            </Box>
            , to continue on reading test.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.handleDoneTestDialog}
            variant="contained"
            style={{
              backgroundColor: "#880000",
              color: "white",
              textTransform: "none",
            }}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    );

    return (
      <ThemeProvider theme={theme}>
        <Container
          maxWidth={false}
          style={{ flex: 1, width: "100%", zoom: "100%" }}
          onPaste={(e) => {
            e.preventDefault();
            return false;
          }}
          onCopy={(e) => {
            e.preventDefault();
            return false;
          }}
        >
          <PageBackdrop isLoading={this.state.isLoading} />

          <Dialog
            open={this.state.isConfirmDone}
            onClose={this.handleCloseConfirmDoneTestDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {this.state.isNotAllQuestion
                ? "Kindly take a moment to carefully answer all the questions before submitting your response."
                : "Kindly take a moment to carefully answer all the questions before submitting your response."}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
              Please take a moment to ensure you've answered all the questions before submitting. Are you sure you want to submit the test? If so, please click{" "}
                <Box fontWeight="fontWeightBold" display="inline">
                  Confirm
                </Box>
                .
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={this.handleCloseConfirmDoneTestDialog}
                variant="contained"
                style={{
                  backgroundColor: "#a6a6a6",
                  color: "white",
                  textTransform: "none",
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={this.handleDoneTestDialog}
                variant="contained"
                style={{
                  backgroundColor: "#25333e",
                  color: "white",
                  textTransform: "none",
                }}
                autoFocus
              >
                Confirm
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={this.state.isErrorConfirmDone}
            onClose={this.handleCloseConfirmDoneTestDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"There's still some time remaining."}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Please take a moment to ensure you've answered all the questions before submitting. Are you sure you want to submit the test? If so, please click <Box fontWeight="fontWeightBold" display='inline'>Confirm</Box>.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={this.handleCloseConfirmDoneTestDialog}
                color="primary"
                style={{
                  backgroundColor: "#a6a6a6",
                  color: "white",
                  textTransform: "none",
                }}
              >
                Close
              </Button>
              <Button
                onClick={this.handleDoneTestDialog}
                variant="contained"
                style={{
                  backgroundColor: "#25333e",
                  color: "white",
                  textTransform: "none",
                }}
                autoFocus
              >
                Confirm
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={this.state.isExpiredTest}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Please confirm that Kindly take a moment to carefully answer all the questions before submitting your response."}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                This test session is already{" "}
                <Box fontWeight="fontWeightBold" display="inline">
                  expired
                </Box>{" "}
                please start a new one.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Link to="/" style={{ textDecoration: "none", color: "#0f0f0f" }}>
                <Button onClick={this.handleExpiredTest} color="primary">
                  back
                </Button>
              </Link>
            </DialogActions>
          </Dialog>

          <Grid
            container
            justify="center"
            style={{ maxHeight: this.state.window.height, padding: "5px" }}
          >
            {!this.state.isDone && (
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid align="center" item xs={3}>
                    <Grid
                      container
                      spacing={1}
                      justify="center"
                      alignItems="center"
                    >
                      <Grid item>
                        <AccountBoxRoundedIcon />
                      </Grid>
                      <Grid item>
                        <Typography variant="body2" align="center">
                          {this.props.user.name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid align="center" item xs={6}>
                    <Grid
                      container
                      spacing={1}
                      justify="center"
                      alignItems="center"
                    >
                      <Grid item>
                        <AccessAlarmRoundedIcon
                          style={{
                            color:
                              (this.state.timer >= 590 &&
                                this.state.timer <= 600) ||
                                (this.state.timer >= 290 &&
                                  this.state.timer <= 300)
                                ? "#c91e24"
                                : "",
                          }}
                        />
                      </Grid>
                      <Grid item>
                        {(this.state.isStartTest && this.state.sumTime.length === this.state.numPart) ?
                          <Typography
                            variant="body1"
                            gutterBottom
                            style={{
                              fontWeight: "bold",
                            /*fontSize: '1rem',*/ color:
                                (this.state.timer >= 590 &&
                                  this.state.timer <= 600) ||
                                  (this.state.timer >= 290 &&
                                    this.state.timer <= 300)
                                  ? "#c91e24"
                                  : "",
                            }}
                          >
                            {this.handleAddLeadingZero(
                              Math.floor(this.state.timer / 3600)
                            )}{" "}
                            :{" "}
                            {this.state.timer === 3600
                              ? "00"
                              : this.handleAddLeadingZero(
                                Math.floor(this.state.timer / 60)
                              )}{" "}
                            : {this.handleAddLeadingZero(this.state.timer % 60)}{" "}
                            minutes left
                          </Typography> : <Typography
                            variant="body1"
                            gutterBottom
                            style={{
                              fontWeight: "bold",
                            /*fontSize: '1rem',*/ color:
                                (this.state.timer >= 590 &&
                                  this.state.timer <= 600) ||
                                  (this.state.timer >= 290 &&
                                    this.state.timer <= 300)
                                  ? "#c91e24"
                                  : "",
                            }}
                          >
                            00 : {this.props.isFreeTest ? "2" : "3"}X : XX minutes left
                          </Typography>
                        }
                      </Grid>
                    </Grid>
                    {(this.state.timer === 0 && this.state.isEndLastSound) && timesUp}

                  </Grid>
                  <Grid align="center" item xs={3} style={{ zoom: "100%" }}>
                    <Grid container justify="center" alignItems="center">
                      <Grid item xs={12}>
                        <Paper variant="outlined">
                          <Grid
                            container
                            justify="center"
                            alignItems="center"
                            spacing={1}
                            style={{ padding: "5px", paddingBottom: "0px" }}
                          >
                            <Grid item>
                              <VolumeDown />
                            </Grid>
                            <Grid item xs>
                              <Slider
                                value={this.state.volume * 100}
                                onChange={this.handleChangeVolume}
                                style={{ color: "#25333e" }}
                              />
                            </Grid>
                            <Grid item>
                              <VolumeUp />
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {this.state.isCheckUserInfo && (
              <Grid item align="center" xs={12}>
                <UserInfoPage
                  user={this.props.user}
                  isFreeTest={this.props.isFreeTest}
                  handleUserInfoChecked={this.handleUserInfoChecked}
                  handleEndTest={this.props.handleEndTest}
                />
              </Grid>
            )}
            {this.state.isTestSound && (
              <Grid item align="center" xs={12}>
                <TestSoundPage
                  user={this.props.user}
                  isFreeTest={this.props.isFreeTest}
                  handleUserInfoPage={this.handleUserInfoPage}
                  handleSoundControl={this.handleSoundControl}
                  handleDoneSoundTest={this.handleDoneSoundTest}
                  isPlayTestSound={this.state.isPlayTestSound}
                />
              </Grid>
            )}
            {this.state.isReadInstruction && (
              <Grid item align="center" xs={12}>
                <InstructionsPage
                  user={this.props.user}
                  isFreeTest={this.props.isFreeTest}
                  handleUserInfoChecked={this.handleUserInfoChecked}
                  handleDoneReadInstruction={this.handleDoneReadInstruction}
                />
              </Grid>
            )}
            {this.state.isStartTest && !this.state.isDone && (
              <Grid item align="center" xs={12}>
                <Paper
                  variant="outlined"
                  style={{
                    marginTop: "5px",
                    height: this.state.window.height - 150,
                    width: "100%",
                    backgroundImage: `url("/U-Test-Logo-new-bw.png")`,
                    backgroundSize: "120px",
                    backgroundRepeat: "space",
                  }}
                >
                  <Grid container justify="center">
                    {this.handleSubQuestion(
                      this.state.currentTestObject[this.state.currentPart - 1]
                        .sections[this.state.currentSection - 1]
                    )}
                    <Grid item align='right' xs={12} style={{ padding: "5px" }}>
                      <ButtonGroup size="small" variant="contained" aria-label="contained primary button group">
                        <Button size="small" onClick={() => { this.handleFontSize('plus') }} style={{ color: "white", backgroundColor: "#42647d" }}>
                          <AddRoundedIcon />
                        </Button>
                        <Button size="small" onClick={() => { this.handleFontSize('minus') }} style={{ color: "white", backgroundColor: "#42647d" }}>
                          <RemoveRoundedIcon />
                        </Button>
                        <Button size="small" onClick={this.handleRestTextSize} style={{ color: "white", backgroundColor: "#42647d" }}>
                          Reset
                        </Button>
                      </ButtonGroup>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      style={{
                        overflow: "auto",
                        height: this.state.window.height - 300,
                        maxHeight: this.state.window.height - 300,
                        marginTop: "0px"
                      }}
                    >
                      <Grid container>
                        <Grid item xs={12}>
                          {this.handleQuestionType(
                            this.state.currentTestObject[this.state.currentPart - 1]
                              .sections[this.state.currentSection - 1]
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <ReactAudioPlayer
                      src={
                        this.state.currentTestObject[
                          this.state.progressSound - 1
                        ].sections[this.state.progressSection - 1].questions[0]
                          .voice
                      }
                      volume={this.state.volume}
                      onEnded={this.onSoundEnd}
                      autoPlay
                    />

                    {this.state.currentTestObject[0].sections.map((thisSection) => {
                      return (
                        <ReactAudioPlayer
                          src={
                            thisSection.questions[0]
                              .voice
                          }
                          autoPlay={false}
                        />
                      )
                    })}

                    {this.state.currentTestObject.map((p) => {
                      return (
                        <ReactAudioPlayer
                          src={p.sections[0].questions[0].voice}
                          onLoadedMetadata={this.handleLoadMetadata}
                          autoPlay={false}
                        />
                      )
                    })}

                  </Grid>
                </Paper>
              </Grid>
            )}
            {this.state.isStartTest && !this.state.isDone && (
              <Grid item align={this.state.isStartTest ? "right" : "center"} xs={12}>
                <Typography
                  variant={this.state.sizeMapBody[`${this.state.fontSizeMap}`]}
                  style={{ color: "#c8c8c8", fontWeight: "bold" }}
                >
                  {btoa(this.props.user.username)}
                </Typography>
              </Grid>
            )}
            {this.state.isTestSound && (
              <Grid item align="center" xs={12}>
                <ReactPlayer
                  volume={this.state.volume}
                  url="/TESTING SOUND (IELTS LISTENING).wav"
                  playing={this.state.isPlayTestSound}
                  loop={true}
                />
              </Grid>
            )}
            {this.state.isStartTest && !this.state.isDone && (
              <Grid
                item
                align="center"
                xs={12}
                style={{ position: "absolute", bottom: 0, width: "95%" }}
              >
                <Grid container>
                  <Grid item xs={1} style={{ padding: "1%", paddingTop: "0%" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            Array.isArray(this.state.focusedQuestion) ? this.state.reviewedQuestion[this.state.focusedQuestion[0]] :
                              (this.state.reviewedQuestion[
                                this.state.focusedQuestion
                              ]
                                ? this.state.reviewedQuestion[
                                this.state.focusedQuestion
                                ]
                                : false)
                          }
                          onChange={this.handleReviewQuestion}
                          name="reviewed"
                          color="primary"
                        />
                      }
                      label="Review"
                    />
                  </Grid>
                  <Grid item xs={10} align="left">
                    <Paper variant="outlined">
                      {this.state.questionNumArr.map((num, index) => {
                        return index !== 0 && (
                          <Button
                            key={num}
                            size="small"
                            style={{
                              borderRadius: this.state.reviewedQuestion[num]
                                ? 75
                                : 3,
                              color:
                                this.state.answeredQuestion[num] ===
                                  undefined ||
                                  this.state.answeredQuestion[num] === null
                                  ? "#25333e"
                                  : "",
                              backgroundColor:
                                this.state.answeredQuestion[num] ===
                                  undefined ||
                                  this.state.answeredQuestion[num] === null
                                  ? Array.isArray(this.state.focusedQuestion)
                                    ? this.state.focusedQuestion.includes(String(num))
                                      ? "#dbecf6"
                                      : ""
                                    : this.state.focusedQuestion === num
                                      ? "#dbecf6"
                                      : ""
                                  : "#25333e",
                              borderColor:
                                this.state.answeredQuestion[num] ===
                                  undefined ||
                                  this.state.answeredQuestion[num] === null
                                  ? "#25333e"
                                  : "",
                            }}
                            variant={
                              this.state.answeredQuestion[num] === undefined ||
                                this.state.answeredQuestion[num] === null
                                ? "outlined"
                                : "contained"
                            }
                            onClick={() => { this.handleClickQuestionBox(num) }}
                            color="primary"
                            className={classes.questionNumber}
                          >
                            {num}
                          </Button>
                        );
                      })}
                    </Paper>
                  </Grid>
                  <Grid item xs={1} align="right">
                    <Grid container>
                      <Grid item xs={6}>
                        {(
                          <IconButton
                            disabled={this.state.currentPart === 1 && this.state.currentSection === 1}
                            aria-label="add to favorites"
                            onClick={() => {
                              this.handleChangeQuestion("Back");
                            }}
                            style={{ display: this.state.progressSound > 1 && (!["SUPER_ADMIN", "ADMIN", "HEAD_TEACHER", "TEACHER"].includes(JSON.parse(localStorage.getItem("user"))?.role)) ? "" : "none" }}
                          >
                            <ArrowBackRoundedIcon />
                          </IconButton>
                        )}
                        {(
                          <IconButton
                            disabled={this.state.currentPart === 1 && this.state.currentSection === 1}
                            aria-label="add to favorites"
                            onClick={() => {
                              this.handleChangeQuestion("Back");
                            }}
                            style={{ display: (["SUPER_ADMIN", "ADMIN", "HEAD_TEACHER", "TEACHER"].includes(JSON.parse(localStorage.getItem("user"))?.role) && this.state.currentPart > 1) ? "" : "none" }}
                          >
                            <ArrowBackRoundedIcon />
                          </IconButton>
                        )}
                      </Grid>
                      {(
                        <Grid
                          item
                          xs={6}
                          style={{
                            marginTop:
                              this.state.currentPart < this.state.numPart
                                ? "0%"
                                : "5%",
                            display: (this.state.currentPart < this.state.numPart && (this.state.currentPart < this.state.progressSound || this.state.currentSection < this.state.sectionLength)) && (!["SUPER_ADMIN", "ADMIN", "HEAD_TEACHER", "TEACHER"].includes(JSON.parse(localStorage.getItem("user"))?.role)) ? "" : "none"
                          }}
                        >
                          <IconButton
                            aria-label="add to favorites"
                            onClick={() => {
                              this.handleChangeQuestion("Next");
                            }}
                          >
                            <ArrowForwardRoundedIcon />
                          </IconButton>
                        </Grid>
                      )}

                      {(
                        <Grid
                          item
                          xs={6}
                          style={{
                            marginTop:
                              this.state.currentPart < this.state.numPart
                                ? "0%"
                                : "5%",
                            display: (this.state.currentPart < this.state.numPart) && (["SUPER_ADMIN", "ADMIN", "HEAD_TEACHER", "TEACHER"].includes(
                              JSON.parse(localStorage.getItem("user"))?.role
                            )) ? "" : "none"
                          }}
                        >
                          <IconButton
                            aria-label="add to favorites"
                            onClick={() => {
                              this.handleChangeQuestion("Next");
                            }}
                          >
                            <ArrowForwardRoundedIcon />
                          </IconButton>
                        </Grid>
                      )}

                      {/* {(window.location?.hostname === "localhost" || window.location?.hostname === "127.0.0.1") && <Grid
                        item
                        xs={6}
                        style={{
                          marginTop:
                            this.state.currentPart < this.state.numPart
                              ? "0%"
                              : "5%",
                        }}
                      >
                        <IconButton
                          aria-label="add to favorites"
                          onClick={() => {
                            this.handleChangeQuestion("Next");
                          }}
                        >
                          <ArrowForwardRoundedIcon />
                        </IconButton>
                      </Grid>
                      } */}

                      <Grid
                        item
                        xs={6}
                        style={{
                          marginTop:
                            this.state.currentPart < this.state.numPart
                              ? "0%"
                              : "5%",
                        }}
                      >
                        {(this.state.currentPart >= this.state.numPart) && (
                          <Button
                            size="small"
                            variant="contained"
                            onClick={this.handleConfirmDoneTestDialog}
                            style={{
                              backgroundColor: "#25333e",
                              color: "white",
                            }}
                          >
                            Done
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Container>
      </ThemeProvider>
    );
  }
}

export default compose(withStyles(useStyles))(TestListening);
