//React
import { useEffect, useState } from 'react';
import { compose } from 'recompose';
import { isSafari } from 'react-device-detect';

//CSS
import './Paragraph.css';

//Material UI
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
//Components

//Other libraries
import { ControlledMenu, useMenuState } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/core.css";
import Draggable from 'react-draggable';
import { Button } from '@material-ui/core';

const useStyles = theme => ({
    // root: {
    //     flexGrow: 1,
    //     width: '100%'
    // },
    // paperRoot: {
    //     padding: theme.spacing(2),
    //     textAlign: 'left'
    // },
    // paperItem: {
    //     padding: theme.spacing(2),
    //     textAlign: 'left',
    //     height: '810px',
    //     maxHeight: '810px',
    //     overflow: 'auto',
    // },
    // testAlignLeft: {
    //     padding: theme.spacing(2),
    // },
    // gridContainerRoot: {
    //     marginTop: 80
    // },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    // marginLeft: {
    //     marginLeft: 20
    // },
    // marginTop: {
    //     marginTop: 20
    // },
    // margin: {
    //     margin: '0'
    // }
});

function DisplayParagraph(props) {
    const [menuProps, toggleMenu] = useMenuState();
    const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });
    const [currentHighlightId, setCurrentHighlightId] = useState(null);
    const [showNote, setShowNote] = useState(false);
    const [noteText, setNoteText] = useState('');
    const [existingHighlights, setExistingHighlights] = useState({});
    const [noteAnchor, setNoteAnchor] = useState(null);
    const [highlightObj, setHighlightObj] = useState({});
    const [spanArr, setSpanArr] = useState([]);
    const [isErrorHighlight, setIsErrorHighlight] = useState(false);
    const [isErrorHighlightDup, setIsErrorHighlightDup] = useState(false);
    // const [openMenu, setOpenMenu] = useState(false);

    useEffect(() => {
        if (window.localStorage.getItem('existingHighlights') !== null) {
            setExistingHighlights(window.localStorage.getItem('existingHighlights'))
        };

        // Function to handle click events anywhere on the screen
        const handleClickOutside = (event) => {
            toggleMenu(false);
        };

        // Add event listener when the component mounts
        document.addEventListener('click', handleClickOutside);

        // Remove event listener when the component unmounts
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [])

    const maybeOpenMenu = (e) => {
        e.preventDefault();

        setCurrentHighlightId(null);

        if (window.getSelection().getRangeAt(0).cloneContents().textContent) {
            showContextMenu(e);
        }
    }

    const onClearHighlight = () => {
        setShowNote(false)
        const span = document.getElementById(currentHighlightId);
        span.parentNode.insertBefore(document.createTextNode(span.innerHTML), span);
        span.parentNode.removeChild(span);
        toggleMenu(false);
    };

    const showContextMenu = (mouseClickEvent) => {
        setAnchorPoint({ x: mouseClickEvent.clientX, y: mouseClickEvent.clientY });
        toggleMenu(true);
    }

    const showContextMenuWithOutToggle = (mouseClickEvent) => {
        setAnchorPoint({ x: mouseClickEvent.clientX, y: mouseClickEvent.clientY });
    }

    const onClickHighlight = (type) => {
        if (window.getSelection().toString().trim() === '') {
            return false;
        }

        const selection = window.getSelection().getRangeAt(0);
        const containsSpan = Array.from(selection.cloneContents().querySelectorAll('span')).length > 0;

        if (containsSpan) {
            setIsErrorHighlightDup(true);
            return false;
        } else {
            if (selection.cloneContents().querySelector('p') === null) {
                const selectedText = selection.extractContents();
                const spans = selectedText.querySelectorAll('span');
                const uuid = Math.random().toString();
                const span = document.createElement("span");

                if (selectedText.querySelector('span') || spans.length > 0) {
                    return false;
                }

                if (type !== "note") {
                    span.style.backgroundColor = "yellow";
                }

                span.id = uuid;
                span.appendChild(selectedText);
                span.innerHTML = span.innerHTML.replace("<p>", "").replace("<p>", "").replaceAll("</p>", "");
                selection.insertNode(span);

                span.onclick = function (e) {
                    e.stopImmediatePropagation();
                    e.preventDefault();
                    setCurrentHighlightId(span.id);
                    showContextMenu(e);
                }

                span.oncontextmenu = function (e) {
                    e.stopImmediatePropagation();
                    e.preventDefault();
                    setCurrentHighlightId(span.id);
                    showContextMenu(e);
                }

                const newHighlight = { [uuid]: "" }
                setExistingHighlights(currentValue => ({
                    ...currentValue,
                    ...newHighlight
                }))

                //updateHighlightObj(selection, span, selectedText);

                if (window.getSelection) {
                    if (window.getSelection().empty) {  // Chrome
                        window.getSelection().empty();
                    } else if (window.getSelection().removeAllRanges) {  // Firefox
                        window.getSelection().removeAllRanges();
                    }
                } else if (document.selection) {  // IE?
                    document.selection.empty();
                }

                toggleMenu(false)
                return span;
            } else {
                toggleMenu(false)
                setIsErrorHighlight(true);
                return false;
            }
        }
    };

    const onCloseDialog = () => {
        setIsErrorHighlight(false);
        setIsErrorHighlightDup(false);
    }



    const onClickFunction = (e) => {
        e.stopImmediatePropagation();
        e.preventDefault();
        showContextMenu(e);
    }

    const onClickHighlightIpad = (type) => {
        var sel, range, node;
        if (window.getSelection) {
            sel = window.getSelection();
            if (sel.getRangeAt && sel.rangeCount) {
                range = window.getSelection().getRangeAt(0);

                var html = '<mark>' + range + '</mark>'
                range.deleteContents();

                var el = document.createElement("div");
                el.innerHTML = html;
                var frag = document.createDocumentFragment(), node, lastNode;
                while ((node = el.firstChild)) {
                    lastNode = frag.appendChild(node);
                }
                range.insertNode(frag);
            }
        } else if (document.selection && document.selection.createRange) {
            range = document.selection.createRange();
            range.collapse(false);
            range.pasteHTML(html);
        }

        toggleMenu(false);
    };

    const updateHighlightObj = async (selection, span, selectedText) => {
        await spanArr.push({
            selection: selection,
            selectedText: selectedText,
            span: span
        });
        highlightObj[props.currentTestObject.id] = await spanArr;
        console.log(highlightObj);
        props.updateHighlightObj(highlightObj);
    };

    const onClickAddNote = () => {
        const span = onClickHighlight("note");
        if (span) {
            setCurrentHighlightId(span.id);

            setNoteText("")
            setShowNote(true);
            setNoteAnchor(span);
        }

        toggleMenu(false);
    };

    const onClickAddNoteToHighlight = () => {
        if (currentHighlightId) {
            setCurrentHighlightId(currentHighlightId);

            document.getElementById(currentHighlightId).className = "badge1";
            setNoteText("")
            setShowNote(true);
            setNoteAnchor(document.getElementById(currentHighlightId));
        }

        toggleMenu(false);
    };

    const onClickOpenNote = () => {
        setNoteText(existingHighlights[currentHighlightId]);
        setShowNote(true);
        setNoteAnchor(document.getElementById(currentHighlightId));
        toggleMenu(false);
    };

    const noteTextUpdate = (value) => {
        setNoteText(value)
        const update = { [currentHighlightId]: value }
        setExistingHighlights(currentValue => ({
            ...currentValue,
            ...update
        }))
        window.localStorage.setItem("existingHighlights", existingHighlights);

        if (value) {
            noteAnchor.style.backgroundColor = "yellow";
            noteAnchor.className = "badge1";
        } else {
            noteAnchor.style.backgroundColor = "";
            noteAnchor.className = "";
        }


    }

    return (
        <Grid container style={{ zoom: props.isRetina ? 1.2 : 1 }} className="your-component-class">
            <Dialog
                open={isErrorHighlight}
                onClose={onCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Please highlight/add note with one paragraph at a time"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Highlight and add note feature is currently not allow to highlight multiple paragraph at a time. Please highlight/add note one paragraph at a time.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button disableElevation onClick={onCloseDialog} style={{ backgroundColor: "#25333e", color: "white" }} autoFocus>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={isErrorHighlightDup}
                onClose={onCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Please clear all highlighted/added note before add new"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Please clear all highlighted or added note before creating new highlight or note to avoid confliction.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button disableElevation onClick={onCloseDialog} style={{ backgroundColor: "#25333e", color: "white" }} autoFocus>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <ControlledMenu
                {...menuProps}
                anchorPoint={anchorPoint}
                onClose={() => toggleMenu(false)}
                captureFocus={false}
            >
                {isSafari &&
                    !currentHighlightId && <>
                        <Grid item xs={12}>
                            <Button disableElevation variant='text' onClick={onClickHighlight} style={{ textTransform: "none", width: "100%", textAlign: "left", justifyContent: "flex-start" }}>
                                Highlight
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Button disableElevation variant='text' onClick={onClickAddNote} style={{ textTransform: "none", width: "100%", textAlign: "left", justifyContent: "flex-start" }}>
                                Add Note
                            </Button>
                        </Grid>
                    </>
                }

                {isSafari &&
                    currentHighlightId && <>
                        <Grid item xs={12}>
                            <Button disableElevation variant='text' onClick={existingHighlights[currentHighlightId]?.length == 0 ? onClickAddNoteToHighlight : onClickOpenNote} style={{ textTransform: "none", width: "100%", textAlign: "left", justifyContent: "flex-start" }}>
                                {existingHighlights[currentHighlightId]?.length == 0 ? "Add Note" : "Open Note"}
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Button disableElevation variant='text' onClick={onClearHighlight} style={{ textTransform: "none", width: "100%", textAlign: "left", justifyContent: "flex-start" }}>
                                Clear
                            </Button>
                        </Grid>
                    </>
                }


                {!currentHighlightId && !isSafari && <MenuItem onClick={onClickHighlight}>Highlight</MenuItem>}
                {!currentHighlightId && !isSafari && <MenuItem onClick={onClickAddNote}>Add Note</MenuItem>}

                {currentHighlightId && !isSafari && <MenuItem onClick={existingHighlights[currentHighlightId]?.length == 0 ? onClickAddNoteToHighlight : onClickOpenNote}>{existingHighlights[currentHighlightId]?.length == 0 ? "Add Note" : "Open Note"}</MenuItem>}
                {currentHighlightId && !isSafari && <MenuItem onClick={onClearHighlight}>Clear</MenuItem>}
            </ControlledMenu>

            <Popper id={"popper"} open={showNote} anchorEl={noteAnchor} transition placement="right">
                {({ TransitionProps }) => (
                    <Draggable>
                        <Fade {...TransitionProps} timeout={350}>
                            <Paper>
                                <Grid container style={{ backgroundColor: "#ffee46" }}>
                                    <Grid item align="middle" xs={2}></Grid>
                                    <Grid item align="middle" xs={8}>
                                        Note
                                    </Grid>
                                    <Grid item align="right" xs={2}>
                                        <IconButton aria-label="delete" size="small" onClick={() => setShowNote(false)}>
                                            <CancelRoundedIcon fontSize="inherit" style={{ color: "red" }} />
                                        </IconButton>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="outlined-multiline-static"
                                            data-gramm="false"
                                            data-gramm_editor="false"
                                            data-enable-grammarly="false"
                                            spellCheck="false"
                                            multiline
                                            fullWidth
                                            rows={4}
                                            variant="outlined"
                                            value={noteText}
                                            onChange={(event) => noteTextUpdate(event.target.value)}
                                            style={{ backgroundColor: "#ffee46" }}
                                        />
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Fade>
                    </Draggable>
                )}
            </Popper>

            <Grid item xs={12}>
                <Grid container spacing={2}>
                    {/* {props.isRetina && !(props.testIndex !== undefined) &&
                        <Grid item align="right" xs={12}>
                            <Button disableElevation size="small" variant='contained' onClick={onClickHighlightIpad} style={{ textTransform: "none", marginRight: "5px" }}>
                                Highlight
                            </Button>
                            <Button disabled disableElevation size="small" variant='contained' onClick={onClickAddNote} style={{ textTransform: "none" }}>
                                Add note
                            </Button>
                        </Grid>
                    } */}
                    <Grid item align='left'>
                        <div
                            key={props.currentTestObject?.id}
                            onContextMenu={maybeOpenMenu}
                        >
                            <Typography style={{ fontSize: props.fontSize + 'rem', textAlign: 'justify' }}>
                                <div dangerouslySetInnerHTML={{ __html: props.currentTestObject?.description }} />
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default compose(
    withStyles(useStyles)
)(DisplayParagraph);
