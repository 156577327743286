//React
import { Component } from 'react';
import { compose } from 'recompose';

//Material UI
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

//import components

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%'
    },
    paperRoot: {
        padding: theme.spacing(2),
        textAlign: 'left'
    },
    paperItem: {
        padding: theme.spacing(2),
        textAlign: 'left',
        height: '810px',
        maxHeight: '810px',
        overflow: 'auto',
    },
    testAlignLeft: {
        padding: theme.spacing(2),
    },
    gridContainerRoot: {
        marginTop: 80
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    marginLeft: {
        marginLeft: 20
    },
    marginTop: {
        marginTop: 20
    },
    margin: {
        margin: 5
    }
});

class SentenceCompletion extends Component {
    constructor() {
        super();

        this.state = {
            answerArr: [],
            sizeMapHeader: {
                'sm': 'subtitle2',
                'md': 'body2',
                'lg': 'body1',
                'xl': 'h6',
            },
            sizeMapBody: {
                'sm': 'body2',
                'md': 'body1',
                'lg': 'h6',
                'xl': 'h5',
            },
            sizeMapCaption: {
                'sm': 'subtitle2',
                'md': 'body2',
                'lg': 'body1',
                'xl': 'h6',
            }
        };
    }

    componentDidMount = async () => {
        const filteredAnsObj = await this.props.answerObj?.parts[this.props.currentPart - 1].sections.find((element) => {
            return element.section_id === this.props.sectionInfo.section_id;
        })

        this.setState({
            answerObj: {
                section_id: this.props.sectionInfo.section_id,
                question_type: this.props.sectionInfo.question_type,
                answers: filteredAnsObj.answers.length === 0 ? {} : filteredAnsObj.answers
            }
        })

        if (filteredAnsObj !== undefined) {
            const objectArray = Object.entries(filteredAnsObj.answers);
            await objectArray.forEach(([key, value]) => {

                this.state.answerArr[key] = value
            });

            this.setState({
                answerArr: this.state.answerArr
            });
        }
    }

    handleOnFocusTextField = (event, qNum) => {
        const questionNo = event.target.value;

        if (this.state.answerArr[questionNo] === undefined) {
            this.state.answerArr[questionNo] = ''
            this.setState({
                answerArr: this.state.answerArr
            });
        }

        this.props.onFocusQuestion(Number(qNum))
    }

    handleAnswerQuestion = (event, questionNo) => {
        const questionKey = '' + questionNo //Convert integer to string
        const value = event.target.value;

        this.state.answerArr[questionNo] = value;
        this.state.answerObj.answers[questionKey] = value;
        
        this.setState({
            answerArr: this.state.answerArr
        });

        this.props.handleAnswerObj(this.state.answerObj)
        this.props.handleAnsweredQuestionNumber(questionNo);
    }

    render() {
        const { classes } = this.props;

        return (
            <Grid container>
                {/* =================================== SECTION 2 =================================== */}
                <Grid item align='left' xs={12}>
                    <Typography variant={this.state.sizeMapHeader[`${this.props.fontSizeMap}`]} gutterBottom className={classes.marginTop}>
                        <Box fontWeight="fontWeightBold" display='inline'>{this.props.sectionInfo.section}</Box>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    {this.props.sectionInfo.questions.map((q, index) =>
                        <Typography variant={this.state.sizeMapBody[`${this.props.fontSizeMap}`]} align='left' className={classes.testAlignLeft}>
                            {q.text.split("_")[0]}
                            <TextField
                                size="small"
                                id="outlined-basic-sc"
                                value={(this.state.answerArr === undefined || this.state.answerArr[Number(q.no)] === undefined )?
                                    Number(q.no) : this.state.answerArr[Number(q.no)]}
                                variant="outlined"
                                autoComplete="new-password"
                                onChange={(event) => { this.handleAnswerQuestion(event, q.no) }}
                                onFocus={(event) => {this.handleOnFocusTextField(event, q.no)}}
                                inputProps={{ style: { textAlign: 'center', height: '5px' } }}
                            />
                            {q.text.split("_")[1]}
                        </Typography>
                    )}
                </Grid>
            </Grid>
        )
    }
}

export default compose(
    withStyles(useStyles)
)(SentenceCompletion);