//React
import { Component } from 'react';
import { compose } from 'recompose';

//Material UI
import Avatar from '@material-ui/core/Avatar';
import Backdrop from '@material-ui/core/Backdrop';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

//Icon
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';
import ArrowDropUpRoundedIcon from '@material-ui/icons/ArrowDropUpRounded';

//Others libraries

//react-router-dom
import {
    Link
} from "react-router-dom";

const axios = require('axios').default;
const FormData = require('form-data');
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%'
    },
    colorPrimary: {
        backgroundColor: '#eeeeee',
    },
    barColorPrimaryRed: {
        backgroundColor: '#ff0000',
    },
    barColorPrimaryLightRed: {
        backgroundColor: '#ff7b7b',
    },
    barColorPrimaryOrange: {
        backgroundColor: '#f97300',
    },
    barColorPrimaryLightOrange: {
        backgroundColor: '#ffb21b',
    },
    barColorPrimaryYellow: {
        backgroundColor: '#ffca16',
    },
    barColorPrimaryGreen: {
        backgroundColor: '#00e200',
    }
});

class FullReport extends Component {
    constructor() {
        super();
        this.state = {
            isSeeMore: false,
            isLoading: false,
            window: {
                height: window.innerHeight,
                width: window.innerWidth
            }
        };
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    handleResize = (event) => {
        this.setState({
            window: {
                height: window.innerHeight,
                width: window.innerWidth
            }
        });
    };

    componentDidMount = async () => {
        document.querySelector("body").scrollTo(0, 0);
        const user = JSON.parse(localStorage.getItem('user'));
        const query = new URLSearchParams(this.props.location.search);

        if (user !== null) {
            await this.setState({
                userToken: user.token,
                test_id: query.get('test_id')
            })
        }
        this.getUserTestReport();
    }

    getUserTestReport = async () => {
        this.handleOpenBackdrop();

        try {
            const res = await axios({
                method: 'get',
                url: api_base_url + '/api/test/result',
                headers: {
                    "Authorization": "Bearer " + JSON.parse(localStorage.getItem('user')).token
                }
            });

            const resData = res.data.filter(history => history.test_id === this.state.test_id);
            this.setState({
                report: resData[0].test_result,
                date: resData[0].date_created,
                resultObj: resData[0]
            });
            this.handleCloseBackdrop();

        } catch (err) {
            console.log(err);
            this.handleCloseBackdrop();
        }
    }

    handleBarColor = (value) => {
        if (value <= 40) {
            return "barColorPrimaryRed"
        } else if (value > 40 && value <= 50) {
            return "barColorPrimaryLightRed"
        } else if (value > 50 && value <= 60) {
            return "barColorPrimaryOrange"
        } else if (value > 60 && value <= 70) {
            return "barColorPrimaryLightOrange"
        } else if (value > 70 && value <= 79) {
            return "barColorPrimaryYellow"
        } else if (value >= 80 && value <= 100) {
            return "barColorPrimaryGreen"
        }
    }

    handleSeeMore = (category) => {
        this.setState({
            isSeeMore: true,
            isSeeMoreType: category
        });
    }

    handleSeeLess = () => {
        this.setState({
            isSeeMore: false,
            isSeeMoreType: ''
        });
    }

    handleCloseBackdrop = () => {
        this.setState({
            isLoading: false
        });
    }

    handleOpenBackdrop = () => {
        this.setState({
            isLoading: true
        });
    }

    render() {
        const { classes } = this.props;
        const search = window.location.search;

        return (
            <Container>
                <Backdrop open={this.state.isLoading} onClick={this.handleCloseBackdrop} style={{ color: '#76323F', zIndex: 1 }}>
                    <CircularProgress color="inherit" />
                </Backdrop>

                <Grid container spacing={5} style={{ marginTop: '100px', marginBottom: '100px', maxWidth: this.state.window.width }}>
                    <Grid item align='left' xs={12}>
                        <Typography variant="h4" gutterBottom>
                            <Box fontWeight="fontWeightBold" display='inline'>My Report</Box>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Paper style={{ padding: '20px' }}>
                            <Grid container>
                                <Grid item align='center' xs={12}>
                                    <Avatar
                                        alt={this.props.user.name}
                                        src={this.props.user.picture}
                                        style={{
                                            width: '150px',
                                            height: '150px'
                                        }}
                                    />
                                </Grid>
                                <Grid item align='center' xs={12} style={{ marginTop: '10px' }}>
                                    <Typography variant="h6" gutterBottom>
                                        <Box fontWeight="fontWeightBold" display='inline'>{this.props.user.name}</Box>
                                    </Typography>
                                </Grid>
                                <Grid item align='center' xs={12}>
                                    <Divider style={{ marginBottom: '10px' }} />
                                </Grid>
                                <Grid item align='left' xs={12}>
                                    <Typography variant="body2" gutterBottom>
                                        <Box fontWeight="fontWeightBold" display='inline'>Test date: </Box> {this.state.date === undefined ? '' : new Date(this.state.date).toDateString()}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                        <Grid item xs={12}>
                            <Grid container spacing={5}>
                                <Grid item xs>
                                    <Paper style={{ padding: '20px' }}>
                                        <Typography variant="subtitle2" gutterBottom>
                                            Type
                                        </Typography>
                                        <Divider style={{ marginBottom: '10px' }} />
                                        <Typography variant="h4">
                                            <Box fontWeight="fontWeightBold" display='inline'>{this.state.report === undefined ? '' : this.state.resultObj.test_type}</Box>
                                        </Typography>
                                    </Paper>
                                </Grid>
                                <Grid item xs>
                                    <Paper style={{ padding: '20px' }}>
                                        <Typography variant="subtitle2" gutterBottom>
                                            Total Question
                                        </Typography>
                                        <Divider style={{ marginBottom: '10px' }} />
                                        <Typography variant="h4" >
                                            <Box fontWeight="fontWeightBold" display='inline'>{this.state.report === undefined ? '' : this.state.report.total_question}</Box>
                                        </Typography>
                                    </Paper>
                                </Grid>
                                <Grid item xs>
                                    <Paper style={{ padding: '20px' }}>
                                        <Typography variant="subtitle2" gutterBottom>
                                            Your Score
                                        </Typography>
                                        <Divider style={{ marginBottom: '10px' }} />
                                        <Typography variant="h4">
                                            <Box
                                                fontWeight="fontWeightBold"
                                                display='inline'
                                            >
                                                {this.state.report === undefined ? '' : this.state.report.total_score}
                                            </Box> {this.state.report !== undefined && this.state.report.total_score / 2 >= this.state.report.total_question ?
                                                <ArrowDropUpRoundedIcon style={{ color: '#00A300' }} /> : <ArrowDropDownRoundedIcon style={{ color: '#D10000' }} />
                                            }
                                        </Typography>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} style={{ marginTop: '20px' }}>
                            <Paper style={{ padding: '20px' }}>
                                {this.state.report?.parts.map((part, partIndex) =>
                                    <Grid item align='left' xs={12}>
                                        <Box display="flex" alignItems="center">
                                            <Box minWidth={120}>
                                                <Typography variant="body2">
                                                    Section {part.part}
                                                </Typography>
                                                <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                                                    {part.part_type}
                                                </Typography>
                                            </Box>
                                            <Box width="100%" mr={1}>
                                                <LinearProgress
                                                    variant="determinate"
                                                    value={(part.score / part.question) * 100}
                                                    classes={{
                                                        colorPrimary: classes.colorPrimary,
                                                        barColorPrimary: classes[this.handleBarColor((part.score / part.question) * 100)]
                                                    }}
                                                />
                                            </Box>
                                            <Box minWidth={35}>
                                                <Typography variant="body2" color="textSecondary">{`${Math.round(
                                                    (part.score / part.question) * 100,
                                                )}%`}</Typography>
                                            </Box>
                                        </Box>

                                        {(partIndex + 1) !== this.state.report?.parts.length && <Divider style={{ marginBottom: '10px', marginTop: '10px' }} />}
                                    </Grid>
                                )}
                            </Paper>
                        </Grid>

                        <Grid item xs={12} style={{ marginTop: '20px' }}>
                            <Paper style={{ padding: '20px' }}>
                                <Grid container>
                                    <Grid item align='left' xs={12}>
                                        <Typography variant="subtitle2" gutterBottom>
                                            <Box fontWeight="fontWeightBold" display='inline'>Parts</Box>
                                        </Typography>
                                        <Divider style={{ marginBottom: '10px' }} />
                                    </Grid>
                                    {this.state.report?.parts.map((part, partIndex) =>
                                        <Grid item xs style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            borderRadius: 5,
                                            backgroundColor: this.state.isSeeMoreType === part.part_type ? '#76323F' : 'white',
                                            color: this.state.isSeeMoreType === part.part_type ? 'white' : 'black'
                                        }}>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Typography variant="subtitle2" gutterBottom>
                                                        Part {part.part}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="subtitle1" style={{ fontWeight: 'bold' }} gutterBottom>
                                                        {part.part_type}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="h4" gutterBottom>
                                                        <Box fontWeight="fontWeightBold" display='inline'>{part.score}</Box>
                                                    </Typography>
                                                </Grid>
                                                {this.state.isSeeMoreType !== part.part_type &&
                                                    <Grid item xs={12}>
                                                        <Button
                                                            disabled
                                                            size="small"
                                                            variant="contained"
                                                            onClick={() => { this.handleSeeMore(part.part_type) }}
                                                            style={{ width: '100%' }}
                                                        >
                                                            <Box fontWeight="fontWeightBold" display='inline'>{this.state.isSeeMoreType === part.part_type ? "See less" : "See more"}</Box>
                                                        </Button>
                                                    </Grid>
                                                }

                                            </Grid>
                                            {(partIndex + 1) !== this.state.report?.parts.length && <Divider variant="middle" orientation="vertical" flexItem />}
                                        </Grid>
                                    )}
                                </Grid>
                            </Paper>
                        </Grid>

                        {this.state.isSeeMore &&
                            <Grid item xs={12} style={{ marginTop: '20px' }}>
                                <Button variant="contained" onClick={this.handleSeeLess} style={{ width: '100%', backgroundColor: '#76323F', color: 'white' }}>
                                    <Box fontWeight="fontWeightBold" display='inline'>See less</Box>
                                </Button>
                            </Grid>
                        }
                        <Grid item xs={12} style={{ marginTop: '20px' }}>
                            <Link to="/myprofile" style={{ textDecoration: 'none', color: '#0f0f0f' }}>
                                <Button variant="outlined">
                                    Back
                                </Button>
                            </Link>
                        </Grid>
                    </Grid>
                </Grid>
            </Container >
        )
    }
}

export default compose(
    withStyles(useStyles)
)(FullReport);