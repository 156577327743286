//React
import { Component } from 'react';
import { compose } from 'recompose';

//Material UI
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CircularProgress from '@material-ui/core/CircularProgress';
import CardMedia from '@material-ui/core/CardMedia';
import Divider from '@material-ui/core/Divider';
import FavoriteRoundedIcon from '@material-ui/icons/FavoriteRounded';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import ShareRoundedIcon from '@material-ui/icons/ShareRounded';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

//Icon
import AssignmentTurnedInRoundedIcon from '@material-ui/icons/AssignmentTurnedInRounded';

//Others
import { green } from '@material-ui/core/colors';

//react-router-dom
import {
    Link
} from "react-router-dom";

//import axios
const axios = require('axios').default;

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%'
    },
});

class DoneCreateBlog extends Component {
    constructor() {
        super();
        this.state = {

        };
    }

    componentDidMount = () => {
        document.querySelector("body").scrollTo(0,0);
    }

    handleResize = (event) => {
        this.setState({
            window: {
                height: window.innerHeight,
                width: window.innerWidth
            }
        });
    };

    render() {
        const { classes } = this.props;

        return (
            <Container maxWidth="lg" align="center">
                <Paper>
                    <Grid container style={{ marginTop: '200px', marginBottom: '320px', padding: '100px' }}>
                        <Grid item xs={12}>
                            <AssignmentTurnedInRoundedIcon style={{ fontSize: 200, color: green[500] }} />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6" gutterBottom>
                                <Box fontWeight="fontWeightBold" display='inline'>Your blog has been created!</Box>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: '100px' }}>
                            <Link to="/blogs" style={{ textDecoration: 'none', color: '#0f0f0f' }}>
                                <Button
                                    variant="contained"
                                    component="span"
                                    style={{ backgroundColor: '#76323F', color: 'white' }}
                                >
                                    See all blogs
                                </Button>
                            </Link>
                        </Grid>
                        <Grid item xs={12}>
                            <Link to="/" style={{ textDecoration: 'none', color: '#0f0f0f' }}>
                                <Button
                                    variant="text"
                                    component="span"
                                    onClick={this.props.handleCancelPreviewBlog}
                                >
                                    Back to home
                                </Button>
                            </Link>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        )
    }
}

export default compose(
    withStyles(useStyles)
)(DoneCreateBlog);