import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';
import { Component } from 'react';
import { compose } from 'recompose';

//Icon & animation
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';

//Others
import moment from 'moment';

const axios = require('axios').default;
const FormData = require('form-data');

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%'
    },
    img: {
        width: theme.spacing(20),
        height: theme.spacing(20),
    },
});

class ReportProblemDialog extends Component {
    constructor() {
        super();
        this.state = {
            isError: false,
            isReported: false,
            isLoading: false
        };
    }

    handleChangeInProblemReport = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleChangeReportProblemType = (event) => {
        let value = event.target.value;
        let reportProblemTypeDescription = '';

        if (value === 'report') {
            reportProblemTypeDescription = 'Report a problem that you found while doing a test.'
        } else if (value === 'rescore') {
            reportProblemTypeDescription = 'Request to re-score your test result.'
        }

        this.setState({
            reportProblemType: event.target.value,
            reportProblemTypeDescription: reportProblemTypeDescription
        });
    }

    handleReportProblem = async () => {
        if (this.state.reportProblemType === undefined) {
            this.setState({
                isError: true,
                reportProblemTypeDescription: 'Please select report type.'
            })

            return;
        }

        this.setState({
            isLoading: true
        });

        try {
            await axios({
                method: 'post',
                url: "https://chatter-funky-busby.glitch.me/test/problems/create",
                data: {
                    submittedTestId: this.props.reportProblemTestId,
                    submitter: JSON.parse(localStorage.getItem('user')).name,
                    topic: this.state.topic,
                    description: this.state.description,
                    problemType: this.state.reportProblemType,
                    reportProblemFor: this.props.reportProblemTypeFor,
                    status: 'Unassigned',
                    createdAt: moment().format("DD/MM/YYYY HH:mm:ss")
                }
            })

            this.setState({
                isReported: true,
                isLoading: false
            });

        } catch (error) {
            console.error(error);

            this.setState({
                isLoading: false
            });
        }
    }

    render() {
        const { classes } = this.props;

        return (
            <Dialog open={this.props.isOpenDialog} onClose={this.props.handleCloseDialog} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Report a problem</DialogTitle>
                <DialogContent>
                    {this.state.isLoading &&
                        <Grid item align='center' xs={12} style={{ padding: '100px' }}>
                            <CircularProgress style={{ color: '#76323F' }} />
                        </Grid>
                    }

                    {this.state.isReported && !this.state.isLoading &&
                        <Grid container>
                            <Grid item>
                                <CheckCircleOutlineRoundedIcon style={{ fontSize: '100px', color: 'green' }} />
                            </Grid>
                            <Grid item>
                                <Typography variant="h2" style={{ marginTop: '20px' }}>
                                    Thanks!
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1">
                                    Your report has been submitted.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} style={{ marginTop: '50px' }}>
                                <Button variant="contained" onClick={this.props.handleCloseDialog} style={{ backgroundColor: '#76323F', color: 'white' }}>
                                    Close
                                </Button>
                            </Grid>
                        </Grid>
                    }
                    {!this.state.isReported && !this.state.isLoading &&
                        <Grid container>
                            <Grid item xs={12}>
                                <FormControl component="fieldset" error={this.state.isError}>
                                    <FormLabel component="legend">
                                        <Typography variant="h6" gutterBottom>
                                            Type
                                        </Typography>
                                    </FormLabel>
                                    <RadioGroup value={this.state.reportProblemType} onChange={this.handleChangeReportProblemType}>
                                        <FormControlLabel value="rescore" control={<Radio />} label="Re-score" />
                                        <FormControlLabel value="report" control={<Radio />} label="Report a problem" />
                                    </RadioGroup>
                                    <FormHelperText>{this.state.reportProblemTypeDescription}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
                                    Submitter
                                </Typography>
                                <TextField
                                    disabled
                                    variant="outlined"
                                    margin="dense"
                                    id="submitter"
                                    defaultValue={JSON.parse(localStorage.getItem('user')).name}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6" gutterBottom>
                                    Topic
                                </Typography>
                                <TextField
                                    autoFocus
                                    name="topic"
                                    variant="outlined"
                                    margin="dense"
                                    id="topic"
                                    fullWidth
                                    onChange={this.handleChangeInProblemReport}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6" gutterBottom>
                                    Description
                                </Typography>
                                <TextField
                                    id="outlined-multiline-static"
                                    name="description"
                                    multiline
                                    rows={4}
                                    autoFocus
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    onChange={this.handleChangeInProblemReport}
                                />
                            </Grid>
                        </Grid>
                    }
                    <DialogContentText>
                        {!this.state.isReported && !this.state.isLoading && 'It may be helpful to provide more detail of the issue that you found in your request.'}
                    </DialogContentText>
                </DialogContent>
                {!this.state.isReported && !this.state.isLoading &&
                    <DialogActions>
                        <Button onClick={this.props.handleCloseDialog} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.handleReportProblem} color="primary">
                            Send
                        </Button>
                    </DialogActions>
                }
            </Dialog>
        )
    }
}

export default compose(
    withStyles(useStyles)
)(ReportProblemDialog);