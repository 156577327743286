import React from 'react';

//Material UI
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import MuiAlert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

//Components
import LoadingBackdrop from '../../public/components/LoadingBackdrop';

//Question type reading
import YesNoNG from './questionTypes/reading/YesNoNG';
import TrueFalseNG from './questionTypes/reading/TrueFalseNG';
import ListOfHeading from './questionTypes/reading/ListOfHeading';
import MultipleChoice from './questionTypes/shared/MultipleChoice';

//Question type listening
import Map from './questionTypes/listening/Map';
import Choose2Letters from './questionTypes/listening/Choose2Letters';
import CompleteTheTables from './questionTypes/listening/CompleteTheTables';

//Question type writing
import WritingBaseQuestion from './questionTypes/writing/WritingBaseQuestion';

//Question type shared
import NoteCompletion from './questionTypes/shared/NoteCompletion';
import MatchingSentenceEndings from './questionTypes/shared/MatchingSentenceEndings';

//Icons
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

//Contants
import * as apiConstants from '../../../../constants/apiConstants';

//Models
import { GetSectionHistory } from '../../../../models/sectionHistory.js';

//Others
import { withStyles } from '@material-ui/core/styles';
import { Component } from 'react';
import { compose } from 'recompose';
import { Link } from "react-router-dom";

const axios = require('axios').default;
const FormData = require('form-data');
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

const useStyles = theme => ({
    root: {
        margin: 15,
        marginTop: 50
    },
    margin: {
        margin: theme.spacing(1),
    },
    buttonColor: {
        backgroundColor: '#76323F',
        color: 'white'
    },
    heading: {
        fontWeight: 'bold'
    },
});

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class EditSectionHistory extends Component {
    constructor() {
        super()
        this.state = {
            addedQuestionArr: [],
            isEdit: false,
            isLoading: false,
            isLoadingSection: false,
            isFree: false,
            selectedParagraph: -1
        };
        this.onChange = editorState => this.setState({ editorState });
    }

    componentDidMount = async () => {
        await this.handleSectionTests();
        document.querySelector("body").scrollTo(0, 0);
    }

    //==================================== PREPARATION =======================================
    handleSectionTests = async () => {
        try {
            this.handleLoadingSection();


            const resData = await GetSectionHistory(this.props.match.params.section_id);

            this.setState({
                sectionHistoryArr: resData.data.history,
                selectedHistory: "default"
            });
            
            this.handleEndLoadingSection();
        } catch (err) {
            console.log(err);
            this.setState({
                sectionHistoryArr: [],
                selectedHistory: "default"
            });
            this.handleEndLoadingSection();
        }
    }

    handleLoadingSection = () => {
        this.setState({
            isLoadingSection: true
        });
    }

    handleEndLoadingSection = () => {
        this.setState({
            isLoadingSection: false
        });
    }

    handleSectionObj = (history, obj, index) => {
        this.setState({
            selectedHistory: history,
            selectedObj: obj
        });
    }
    //==================================== PREPARATION =======================================

    handleAddQuestionUI = (sectionObj) => {
        //================================== READING ==================================
        if (sectionObj.question_type === 'yesNoNG') {
            return (<YesNoNG key={sectionObj.section_id} sectionObj={sectionObj} selectedSectionId={this.state.selectedSectionId} sectionId={sectionObj.section_id} handleEndEditSection={this.handleEndEditSection} handleCreateEditTestHistory={this.handleCreateEditTestHistory} />)
        }

        else if (sectionObj.question_type === 'trueFalseNG') {
            return (<TrueFalseNG key={sectionObj.section_id} sectionObj={sectionObj} selectedSectionId={this.state.selectedSectionId} sectionId={sectionObj.section_id} handleEndEditSection={this.handleEndEditSection} handleCreateEditTestHistory={this.handleCreateEditTestHistory} />)
        }

        else if (sectionObj.question_type === 'multipleChoice') {
            return (<MultipleChoice key={sectionObj.section_id} sectionObj={sectionObj} selectedSectionId={this.state.selectedSectionId} sectionId={sectionObj.section_id} handleEndEditSection={this.handleEndEditSection} handleCreateEditTestHistory={this.handleCreateEditTestHistory} />)
        }

        else if (sectionObj.question_type === 'matchingSentenceEndings' || sectionObj.question_type === 'matching' || sectionObj.question_type === 'matchingFeatures') {
            return (<MatchingSentenceEndings key={sectionObj.section_id} sectionObj={sectionObj} selectedSectionId={this.state.selectedSectionId} sectionId={sectionObj.section_id} handleEndEditSection={this.handleEndEditSection} handleCreateEditTestHistory={this.handleCreateEditTestHistory} />)
        }

        else if (sectionObj.question_type === 'listOfHeading') {
            return (<ListOfHeading key={sectionObj.section_id} sectionObj={sectionObj} selectedSectionId={this.state.selectedSectionId} sectionId={sectionObj.section_id} handleEndEditSection={this.handleEndEditSection} handleCreateEditTestHistory={this.handleCreateEditTestHistory} />)
        }

        else if (sectionObj.question_type === 'completeTheNotes' || sectionObj.question_type === 'noteCompletion') {
            return (<NoteCompletion key={sectionObj.section_id} sectionObj={sectionObj} selectedSectionId={this.state.selectedSectionId} sectionId={sectionObj.section_id} handleEndEditSection={this.handleEndEditSection} handleCreateEditTestHistory={this.handleCreateEditTestHistory} />)
        }
        //================================== READING ==================================

        //================================== LISTENING ==================================
        else if (sectionObj.question_type === 'completeTheTables') {
            return (<CompleteTheTables key={sectionObj.section_id} sectionObj={sectionObj} selectedSectionId={this.state.selectedSectionId} sectionId={sectionObj.section_id} handleEndEditSection={this.handleEndEditSection} handleCreateEditTestHistory={this.handleCreateEditTestHistory} />)
        }

        else if (sectionObj.question_type === 'choose2Letters') {
            return (<Choose2Letters key={sectionObj.section_id} sectionObj={sectionObj} selectedSectionId={this.state.selectedSectionId} sectionId={sectionObj.section_id} handleEndEditSection={this.handleEndEditSection} handleCreateEditTestHistory={this.handleCreateEditTestHistory} />)
        }

        else if (sectionObj.question_type === 'map') {
            return (<Map key={sectionObj.section_id} sectionObj={sectionObj} selectedSectionId={this.state.selectedSectionId} sectionId={sectionObj.section_id} handleEndEditSection={this.handleEndEditSection} handleCreateEditTestHistory={this.handleCreateEditTestHistory} />)
        }
        //================================== LISTENING ==================================

        //================================== WRITING ==================================
        else if (sectionObj.question_type === 'writingBaseQuestion') {
            return (<WritingBaseQuestion key={sectionObj.section_id} sectionObj={sectionObj} selectedSectionId={this.state.selectedSectionId} sectionId={sectionObj.section_id} handleEndEditSection={this.handleEndEditSection} handleCreateEditTestHistory={this.handleCreateEditTestHistory} />)
        }
        //================================== WRITING ==================================

    }

    render() {
        const { classes } = this.props;

        return (
            <Grid item xs={12} style={{ marginTop: '100px', marginBottom: '300px' }}>
                <LoadingBackdrop isLoading={this.state.isLoadingSection} />
                <Grid container spacing={2} style={{ padding: '20px' }}>
                    {/* =========================================== READING =========================================== */}
                    <Grid item align='left' xs={12} >
                        <Grid container>
                            <Grid item xs={12} style={{ marginBottom: '20px' }}>
                                <Typography variant="h4" style={{ fontWeight: 'bold' }} gutterBottom>
                                    Section history
                                </Typography>
                            </Grid>
                            <Grid item xs={12} style={{ marginBottom: '20px' }}>
                                <Typography paragraph className={classes.heading}>Section id</Typography>
                                <TextField
                                    disabled
                                    id="outlined-basic-title"
                                    variant="outlined"
                                    size="small"
                                    label="Section id"
                                    value={this.props.match.params.section_id}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography paragraph className={classes.heading}>Section history</Typography>
                            </Grid>
                            {this.state.sectionHistoryArr?.length === 0 &&
                                <Grid item xs={12}>
                                    <Typography variant="caption" display="block">No history found.</Typography>
                                </Grid>
                            }
                            <Grid item align='left' xs={12}>
                                <Grid containern>
                                    {this.state.sectionHistoryArr && this.state.sectionHistoryArr.map((section, index) =>
                                        <Grid item align='left' xs={12}>
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id={"panel1a-header-" + section.id}
                                                    style={{ borderTop: '5px solid rgb(241,234,235)' }}
                                                >
                                                    <Grid item xs={12}>
                                                        <Grid container>
                                                            <Grid item xs={12}>
                                                                <Typography paragraph className={classes.heading}>History {section._id}</Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Typography variant="caption" display="block">Created at {new Date(section.createdAt).toDateString()}({new Date(section.createdAt).toLocaleTimeString('en-US', { timeZone: "Asia/Bangkok", hour: '2-digit', minute: '2-digit' })}) by {section.updateBy.name}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12}>
                                                            {this.handleAddQuestionUI(section.updateTo)}
                                                        </Grid>
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* =========================================== READING =========================================== */}
                    {!this.state.isEdit &&
                        <Grid item align='center' xs={12} style={{ marginTop: '50px' }}>
                            {this.props.match.params.test_id === 'editPart' ?
                                <Link to={`/dashboard/test-admin/parts/${this.props.match.params.part_id}/edit`} style={{ textDecoration: 'none', color: '#0f0f0f' }}>
                                    <Button variant="contained" size="medium" color="default" style={{ marginLeft: '5px', color: 'grey' }}>
                                        Back
                                    </Button>
                                </Link> :
                                <Link to={`/dashboard/test-admin/tests/${this.props.match.params.test_id}/edit`} style={{ textDecoration: 'none', color: '#0f0f0f' }}>
                                    <Button variant="contained" size="medium" color="default" style={{ marginLeft: '5px', color: 'grey' }}>
                                        Back
                                    </Button>
                                </Link>
                            }
                        </Grid>
                    }
                </Grid>
            </Grid >
        )
    }
}
export default compose(
    withStyles(useStyles)
)(EditSectionHistory);