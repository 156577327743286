import React, { useEffect } from 'react';

//MUI
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CakeRoundedIcon from '@material-ui/icons/CakeRounded';
import EmailRoundedIcon from '@material-ui/icons/EmailRounded';
import LocalPhoneRoundedIcon from '@material-ui/icons/LocalPhoneRounded';
import SchoolRoundedIcon from '@material-ui/icons/SchoolRounded';
import Skeleton from '@material-ui/lab/Skeleton';

import { ThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';

//Components
import MyReport from './components/profilePage/MyReport';
import MyTest from './components/profilePage/MyTest';

//Font
import 'typeface-glacial-indifference';

//CSS
import './css/profilePage.css';

//Constants
import * as styleConstants from '../../../constants/styleConstants';

//Services
import { GetTestReport } from '../../../services/testReportServices';
import { GetPurchasedTests } from '../../../services/testServices';
import { GetAllTestHistory } from '../../../services/userServices';

//Others
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";

const axios = require('axios').default;
const FormData = require('form-data');
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

const useStyles = theme => ({
    root: {
        padding: theme.spacing(12, 4),
    }
});

const theme = createMuiTheme({
    typography: {
        fontFamily: [
            styleConstants.fontStyle
        ].join(','),
    },
});

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1500 },
        items: 5
    },
    tablet: {
        breakpoint: { max: 1500, min: 464 },
        items: 1
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1
    }
};

const ProfilePage = (props) => {
    //User
    const [userObj, setUserObj] = React.useState({});
    const [testHistoryArr, setTestHistoryArr] = React.useState([]);
    const [testReportArr, setTestReportArr] = React.useState([]);
    const [purchasedTestArr, setPurchasedTestArr] = React.useState([]);
    const [initial, setInitial] = React.useState("");

    const [isLoading, setIsLoading] = React.useState(false);

    useEffect(async () => {
        props.handleEndTest();
        document.body.style.overflow = "auto";
        document.body.style.backgroundColor = "#efefef";
        document.querySelector("body").scrollTo(0, 0);
        localStorage.setItem('readingSession', null);
        localStorage.setItem('listeningSession', null);
        localStorage.setItem('writingSession', null);
        localStorage.setItem('testSession', null);

        const user = await JSON.parse(localStorage.getItem('user'));
        setUserObj(user);
        setInitial(`${user.firstname[0]}${user.lastname[0]}`);

        await getTestHistory(user.username);
        await getTestReports(user.token);
        await getTestPurchasedTests(user.token, user.username)
    }, []);

    const getTestHistory = async (username) => {
        setIsLoading(true);

        const testHistory = await GetAllTestHistory(username);

        if (testHistory.success) {
            setTestHistoryArr(testHistory.res);
        }

        setIsLoading(false);
    }

    const getTestReports = async (token) => {
        setIsLoading(true);

        const testReports = await GetTestReport(token);

        if (testReports.success) {
            setTestReportArr(testReports.res);
        }

        setIsLoading(false);
    }

    const getTestPurchasedTests = async (token, username) => {
        setIsLoading(true);

        const purchaseddTests = await GetPurchasedTests(token, username);
        console.log(purchaseddTests);

        if (purchaseddTests.success) {
            setPurchasedTestArr(purchaseddTests.res);
        }

        setIsLoading(false);
    }

    const mobileUI = (
        <Grid item xs={12} style={{ maxWidth: window.innerWidth, zIndex: 1 }}>
            <Grid container style={{ marginTop: "5%", marginBottom: "20%" }}>
                <Grid item align="left" xs={12} style={{ padding: "5%" }}>
                    <Typography variant="h3" style={{ fontWeight: styleConstants.fontWeight.bold, color: "#880000" }}>
                        Profile
                    </Typography>
                </Grid>
                <Grid item align="center" xs={12} style={{ padding: "5%" }}>
                    <Paper elevation={0} style={{ backgroundColor: "#a6a6a6", borderRadius: "25px", padding: "10%" }}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Avatar style={{
                                    width: "130px",
                                    height: "130px",
                                    fontSize: "50px",
                                    fontWeight: styleConstants.fontWeight.bold,
                                    backgroundColor: "#880000"
                                }}
                                >
                                    {initial}
                                </Avatar>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item align="left" xs={12} style={{ marginTop: "10px" }}>
                                        <Typography variant="h4" style={{ color: "white", fontWeight: styleConstants.fontWeight.bold }}>
                                            {`${userObj?.firstname} ${userObj?.lastname}`}
                                        </Typography>
                                    </Grid>
                                    <Grid item align="left" xs={12}>
                                        <Typography variant="subtitle1" style={{ color: "white" }}>
                                            {userObj?.role}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} style={{ padding: "5%" }}>
                    <Paper elevation={0} style={{ backgroundColor: "#a6a6a6", borderRadius: "25px", padding: "10%" }}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item align="left">
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            flexWrap: 'wrap',
                                        }}>
                                            <EmailRoundedIcon style={{ color: "white", marginRight: '5px' }} />
                                            <Typography variant="subtitle1" style={{ color: "white" }}>
                                                {userObj?.email}
                                            </Typography>
                                        </div>
                                    </Grid>
                                    <Grid item align="left" xs={12}>
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            flexWrap: 'wrap',
                                        }}>
                                            <LocalPhoneRoundedIcon style={{ color: "white", marginRight: '5px' }} />
                                            <Typography variant="subtitle1" style={{ color: "white" }}>
                                                {userObj?.phone?.length === 0 ? "-" : userObj.phone}
                                            </Typography>
                                        </div>
                                    </Grid>
                                    <Grid item align="left" xs={12}>
                                        <Grid container>
                                            <Grid item>
                                                <SchoolRoundedIcon style={{ color: "white", marginRight: '5px' }} />
                                            </Grid>
                                            <Grid item xs>
                                                <Typography variant="subtitle1" style={{ color: "white" }}>
                                                    {userObj?.school?.length === 0 ? "-" : userObj.school}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item align="left" xs={12}>
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            flexWrap: 'wrap',
                                        }}>
                                            <CakeRoundedIcon style={{ color: "white", marginRight: '5px' }} />
                                            <Typography variant="subtitle1" style={{ color: "white" }}>
                                                {userObj?.birthdate}
                                            </Typography>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

                <Grid id='myreport' item align="left" xs={12}>
                    <MyReport />
                </Grid>

                <Grid item id='mytest' align="left" xs={12}>
                    <MyTest />
                </Grid>

            </Grid>
        </Grid>)

    // ==================================================================== DESKTOP ==========================================================================
    const webUI = (<Grid container spacing={5} style={{ marginTop: "5%", marginBottom: "20%", maxWidth: window.innerWidth }}>
        <Grid item align="left" xs={12} style={{ paddingLeft: "10%" }}>
            <Typography variant="h4" style={{ fontWeight: styleConstants.fontWeight.bold, color: "#880000" }}>
                Profile
            </Typography>
        </Grid>
        <Grid item xs={12} sm={5} style={{ paddingLeft: "10%" }}>
            <Paper elevation={0} style={{ backgroundColor: "#a6a6a6", borderRadius: "25px", padding: "50px" }}>
                <Grid container alignItems='center'>
                    <Grid item xs={6}>
                        <Avatar style={{
                            width: "130px",
                            height: "130px",
                            fontSize: "50px",
                            fontWeight: styleConstants.fontWeight.bold,
                            backgroundColor: "#880000"
                        }}
                        >
                            {initial}
                        </Avatar>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container spacing={1}>
                            <Grid item align="left" xs={12} style={{ marginTop: "10px" }}>
                                <Typography variant="h5" style={{ color: "white", fontWeight: styleConstants.fontWeight.bold }}>
                                    {`${userObj?.firstname} ${userObj?.lastname}`}
                                </Typography>
                            </Grid>
                            <Grid item align="left" xs={12}>
                                <Typography variant="subtitle2" style={{ color: "white" }}>
                                    {userObj?.role}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
        <Grid item xs={12} sm={5}>
            <Paper elevation={0} style={{ backgroundColor: "#a6a6a6", borderRadius: "25px", padding: "10%", height: "100%" }}>
                <Grid container>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item align="left">
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'wrap',
                                }}>
                                    <EmailRoundedIcon style={{ color: "white", marginRight: '5px' }} />
                                    <Typography variant="body1" style={{ color: "white" }}>
                                        {userObj?.email}
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item align="left" xs={12}>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'wrap',
                                }}>
                                    <LocalPhoneRoundedIcon style={{ color: "white", marginRight: '5px' }} />
                                    <Typography variant="body1" style={{ color: "white" }}>
                                        {userObj?.phone?.length === 0 ? "-" : userObj.phone}
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item align="left" xs={12}>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'wrap',
                                }}>
                                    <SchoolRoundedIcon style={{ color: "white", marginRight: '5px' }} />
                                    <Typography variant="body1" style={{ color: "white" }}>
                                        {userObj?.school?.length === 0 ? "-" : userObj.school}
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item align="left" xs={12}>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'wrap',
                                }}>
                                    <CakeRoundedIcon style={{ color: "white", marginRight: '5px' }} />
                                    <Typography variant="body1" style={{ color: "white" }}>
                                        {userObj?.birthdate}
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
        <Grid id='myreport' item align="left" xs={12}>
            <MyReport />
        </Grid>

        {/*======================================== PURCHASED TESTS ========================================*/}
        <Grid item id='mytest' align="left" xs={12}>
            <MyTest />
        </Grid>
    </Grid>)

    return <ThemeProvider theme={theme}>
        <Hidden lgUp>
            {mobileUI}
        </Hidden>
        <Hidden mdDown>
            {webUI}
        </Hidden>
    </ThemeProvider>
};

export default compose(
    withStyles(useStyles)
)(ProfilePage);
