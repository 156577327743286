export const TEST_MANAGEMENT_API_BASE_URL = "https://chatter-funky-busby.glitch.me"
export const SPEAKING_API_BASE_URL = "https://steep-legend-midnight.glitch.me"
export const UTESTAVENUE_SYSTEM_API_BASE_URL = "https://erratic-noiseless-wasabi.glitch.me/utestavenue-system-api/api/v1"
export const PAYMENT_API_BASE_URL = "https://almond-magnetic-sale.glitch.me";

export const E_LEARNING_API_BASE_URL = "https://e-learning-be-ybcs6wa7da-as.a.run.app"

export const UTEST_AVENUE_PRO_API = "https://soapy-west-helium.glitch.me/api"

export const ADMIN_USER = {
    username: "phisonkha",
    password: "1234"
}
