//React
import { Component } from 'react';
import { compose } from 'recompose';

//Material UI
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccountBoxRoundedIcon from '@material-ui/icons/AccountBoxRounded';
import AccessAlarmRoundedIcon from '@material-ui/icons/AccessAlarmRounded';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Input from '@material-ui/core/Input';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';

//import other library
import Image from 'material-ui-image'

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%'
    }
});

class Map extends Component {
    constructor() {
        super();

        this.state = {
            answerObj: {
                answers: {}
            },
            answerArr: {}
        };
    }

    componentDidMount = async () => {

    }

    handleSelectAnswer = (event, index, no) => {
        const questionKey = '' + no //Convert integer to string
        this.state.answerObj.answers[questionKey] = event.target.value;
        this.state.answerArr[no] = event.target.value;
        console.log(no);
    }

    render() {
        const { classes } = this.props;

        return (
            <Grid container spacing={5}>
                <Grid align='left' item xs={12}>
                    <Typography variant="h4" gutterBottom>
                        <Box fontWeight="fontWeightBold" display='inline'> {this.props.currentTestObject?.section} </Box>
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        {this.props.currentTestObject?.questions[0].question}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Image
                        src={this.props.currentTestObject?.questions[0].image}
                    />
                </Grid>
                <Grid item xs={12} sm={8}>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table" style={{ minWidth: 650 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    {this.props.currentTestObject?.questions[0].choice.map((choice) => (
                                        <TableCell align="center">{choice}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.props.currentTestObject?.questions.map((row, index) => (
                                    <TableRow key={row.name}>
                                        <TableCell component="th" scope="row">
                                            {row.sub_question[0]}
                                        </TableCell>
                                        {row.choice.map((choice) => (
                                            <TableCell align="center" style={{ padding: '0px' }}>
                                                <Button
                                                    variant={this.state.answerObj.answers[row.no] === choice ? "contained" : "outlined"}
                                                    value={choice}
                                                    onClick={(event) => { this.handleSelectAnswer(event, index, row.no) }}
                                                    style={{ width: '100%', height: '50px', backgroundColor: this.state.answerObj.answers[row.no] === choice ? "#76323F" : "white" }}
                                                />
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        )
    }
}

export default compose(
    withStyles(useStyles)
)(Map);