//React
import React, { Component } from "react";
import { compose } from "recompose";

//Material UI
import Button from "@material-ui/core/Button";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import { withStyles } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core/styles";

//react-router-dom
import { Link } from "react-router-dom";

//Constants
import * as styleConstants from "../../../../constants/styleConstants";

//Others
import MediaQuery from 'react-responsive';

const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  rootContainer: {
    maxWidth: "75%",
  },
  rootCard: {
    maxWidth: "75%",
    width: "75%",
    margin: 10,
  },
  gridContainer: {
    maxWidth: "90%",
  },
  margin: {
    margin: 100,
  },
  marginTop: {
    marginTop: 100,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(5),
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  header: {
    maxWidth: "80%",
  },
  body: {
    maxWidth: "80%",
    marginTop: 30,
  },
  divider: {
    maxWidth: "100%",
  },
  image: {
    margin: "auto",
    display: "block",
    margin: 10,
  },
  commentTextField: {
    width: "100%",
    maxWidth: "100%",
  },
  commentGridItem: {
    maxWidth: "80%",
    marginTop: 30,
    marginBottom: 100,
  },
});

const theme = createMuiTheme({
  typography: {
    fontFamily: ["Roboto"].join(","),
  },
});

class AdvertisementSectionMobile extends Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() { }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  render() {
    const { classes } = this.props;

    return (
      <MediaQuery minWidth={"768px"}>
        {(matches) =>
          matches ? <Grid item xs={12} style={{ maxWidth: window.innerWidth, zIndex: 1, marginTop: "3%" }}>
            <Grid container spacing={2} style={{ paddingLeft: "5%", paddingRight: "5%" }}>
              <Grid
                item
                align="center"
                xs={6}
                style={{ paddingTop: "10%", paddingBottom: "5%" }}
              >
                <Grid container justify="center">
                  <Grid item xs={12} className="w3-animate-top">
                    <Typography
                      variant="h2"
                      style={{ fontFamily: styleConstants.fontStyle, fontWeight: styleConstants.fontWeight.bold, }}
                    >
                      <span style={{ color: "#991514" }}>U</span>TEST AVENUE
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ marginTop: "5%" }}
                    className="w3-animate-top"
                  >
                    <Typography variant="h6" style={{ fontFamily: styleConstants.fontStyle, fontWeight: styleConstants.fontWeight.thin, }}>
                      A smartly-designed platform for simulation tests & intelligent
                      skill analysis
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ marginTop: "10%" }}
                    className="w3-animate-top"
                  >
                    <Link
                      to="/freetests"
                      style={{ textDecoration: "none", color: "#0f0f0f" }}
                    >
                      <Button
                        variant="contained"
                        style={{ backgroundColor: "#3f5e75", color: "white" }}
                      >
                        <Typography
                          variant="h6"
                          style={{ fontWeight: styleConstants.fontWeight.bold, fontFamily: styleConstants.fontStyle }}
                        >
                          Free test
                        </Typography>
                      </Button>
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item align="center" xs={6}>
                <CardMedia
                  component="img"
                  alt="Image"
                  src="graphics/HomeImg.png"
                  style={{ width: "100%" }}
                  className="w3-animate-top"
                />
              </Grid>
            </Grid>
          </Grid> : <Grid item xs={12} style={{ maxWidth: window.innerWidth, zIndex: 1 }}>
            <Grid container spacing={2}>
              <Grid item align="center" xs={12}>
                <Typography
                  variant="h3"
                  style={{ fontFamily: styleConstants.fontStyle, fontWeight: styleConstants.fontWeight.bold, }}
                >
                  <span style={{ color: "#991514" }}>U</span>TEST AVENUE
                </Typography>
              </Grid>
              <Grid item align="center" xs={12} style={{ padding: "10px" }}>
                <Typography variant="body1" style={{ fontFamily: styleConstants.fontStyle, fontWeight: styleConstants.fontWeight.thin, }}>
                  A smartly-designed platform for simulation tests & intelligent
                  skill analysis
                </Typography>
              </Grid>
              <Grid item align="center" xs={12} style={{ padding: "10px" }}>
                <CardMedia
                  component="img"
                  alt="Contemplative Reptile"
                  src="graphics/HomeImg.png"
                  title="Contemplative Reptile"
                  style={{ width: "70%" }}
                  className="w3-animate-top"
                />
              </Grid>
              <Grid item align="center" xs={12} style={{ padding: "10px" }}>
                <Link
                  to="/freetests"
                  style={{ textDecoration: "none", color: "#0f0f0f" }}
                >
                  <Button
                    variant="contained"
                    style={{ backgroundColor: "#3f5e75", color: "white" }}
                  >
                    <Typography
                      variant="body1"
                      style={{ fontWeight: styleConstants.fontWeight.bold, fontFamily: styleConstants.fontStyle }}
                    >
                      Free test
                    </Typography>
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </Grid>
        }
      </MediaQuery>
    );
  }
}

export default compose(withStyles(useStyles))(AdvertisementSectionMobile);
