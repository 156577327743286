import React, { useEffect } from 'react';

//MUI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';

import { ThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';

//Font
import 'typeface-glacial-indifference';

//Icons
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';

//Others
import { Link } from "react-router-dom";
const axios = require('axios').default;
const FormData = require('form-data');
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

const useStyles = theme => ({
    root: {
        padding: theme.spacing(12, 4),
    }
});

const theme = createMuiTheme({
    typography: {
        fontFamily: [
            'Roboto'
        ].join(','),
    },
});

const PinnedBlog = (props) => {
    useEffect(() => {
        document.body.style.backgroundColor = "#efefef";
        document.querySelector("body").scrollTo(0, 0);
        console.log(props);
    }, []);

    const mobileUI = (
        <Grid container alignItems='center' spacing={5}>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item align='center' xs={4}>
                        <Typography variant="h4" style={{ fontWeight: "bold", color: "#980c0a" }}>
                            BLOGS
                    </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item align='center' xs={12}>
                <Box
                    component="img"
                    style={{ width: '100%', height: '250px' }}
                    alt="The house from the offer."
                    src={props.pinnedBlogs?.smallPic}
                />
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item align='left' xs={12}>
                        <Typography variant="h5" gutterBottom style={{ fontWeight: "bold", color: "#636363" }}>
                            {props.pinnedBlogs?.heading}
                        </Typography>
                        <Typography variant="body1" style={{ color: "#636363" }}>
                            {`${props.pinnedBlogs?.body?.substring(0, 350)}...`}
                        </Typography>
                    </Grid>
                    <Grid item align='right' xs={12}>
                        <Typography variant="body1" style={{ color: "#c7c7c7" }}>
                            {`Article by ${props.pinnedBlogs?.writerName}`}
                        </Typography>
                    </Grid>
                    <Grid item align='center' xs={12}>
                        <Link to={"/blogs/" + props.pinnedBlogs?._id} style={{ textDecoration: 'none', color: '#0f0f0f' }}>
                            <Button
                                variant="contained"
                                style={{ backgroundColor: "#f9f9f9", color: "#636363", borderRadius: "10px", textTransform: "none" }}
                                endIcon={<ArrowForwardRoundedIcon />}
                                disableElevation
                            >
                                <Typography variant="h6" style={{ padding: "5px" }}>
                                    See more
                                </Typography>
                            </Button>
                        </Link>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )

    const webUI = (
        <Grid container alignItems='center' spacing={5}>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item align='center' xs={4}>
                        <Typography variant="h3" style={{ fontWeight: "bold", color: "#980c0a" }}>
                            BLOGS
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item align='center' xs={4}>
                <Box
                    component="img"
                    style={{ width: '100%', height: '300px' }}
                    alt="The house from the offer."
                    src={props.pinnedBlogs?.smallPic}
                />
            </Grid>
            <Grid item xs={8}>
                <Grid container>
                    <Grid item align='left' xs={12}>
                        <Typography variant="h4" gutterBottom style={{ fontWeight: "bold", color: "#636363" }}>
                            {props.pinnedBlogs?.heading}
                        </Typography>
                        <Typography variant="h6" style={{ color: "#636363" }}>
                            {`${props.pinnedBlogs?.body?.substring(0, 350)}...`}
                        </Typography>
                    </Grid>
                    <Grid item align='right' xs={12}>
                        <Typography variant="h6" style={{ color: "#c7c7c7" }}>
                            {`Article by ${props.pinnedBlogs?.writerName}`}
                        </Typography>
                    </Grid>
                    <Grid item align='center' xs={12}>
                        <Link to={"/blogs/" + props.pinnedBlogs?._id} style={{ textDecoration: 'none', color: '#0f0f0f' }}>
                            <Button
                                variant="contained"
                                style={{ backgroundColor: "#f9f9f9", color: "#636363", borderRadius: "10px", textTransform: "none" }}
                                endIcon={<ArrowForwardRoundedIcon />}
                                disableElevation
                            >
                                <Typography variant="h5" style={{ padding: "5px" }}>
                                    See more
                                </Typography>
                            </Button>
                        </Link>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )

    return <ThemeProvider theme={theme}>
        <Hidden mdUp>
            {mobileUI}
        </Hidden>
        <Hidden smDown>
            {webUI}
        </Hidden>
    </ThemeProvider>
};

export default compose(
    withStyles(useStyles)
)(PinnedBlog);
