//React
import { Component } from 'react';
import { compose } from 'recompose';

//Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';

//import components
import RefundDialog from './RefundDialog';

//Others libraries
import Carousel from "react-multi-carousel";

//react-router-dom
import {
    Link
} from "react-router-dom";

const axios = require('axios').default;
const FormData = require('form-data');
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%'
    }
});

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 5
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 4
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 3
    }
};

class PurchasedCourses extends Component {
    constructor() {
        super();
        this.state = {
            testType: '',
            testId: ''
        };
    }

    handleStartPurchasedTest = async () => {
        try {
            const res = await axios({
                method: 'put',
                url: api_base_url + '/api/test/test-purchase-status?test_id=' + this.state.testId + '&test_status=true',
                headers: {
                    Authorization: "bearer " + this.props.userInfo.token
                }
            });

            const resData = res.data;
        } catch (err) {
            console.log(err);
        }
    }

    handleOpenIsStartCourse = (id, type) => {
        this.setState({
            isConfirmStart: true,
            testId: id,
            testType: type
        });
    }

    handleCloseIsStartCourse = () => {
        this.setState({
            isConfirmStart: false
        });
    }

    //=================== REFUND DIALOG ========================
    handleCloseRefundDialog = () => {
        this.setState({
            isOpenRefundDialog: false
        });
    }

    handleOpenRefundDialog = (id) => {
        this.setState({
            isOpenRefundDialog: true,
            selectedPurchasedId: id
        });
    }
    //=================== REFUND DIALOG ========================

    render() {
        const { classes } = this.props;

        return (
            <Grid container style={{ padding: '20px', marginTop: '5%' }}>
                <RefundDialog
                    isOpenRefundDialog={this.state.isOpenRefundDialog}
                    selectedPurchasedId={this.state.selectedPurchasedId}
                    handleCloseRefundDialog={this.handleCloseRefundDialog}
                    handleOpenRefundDialog={this.handleOpenRefundDialog}
                />
                <Grid item align="left" xs={12}>
                    <Typography variant="h4" gutterBottom>
                        <Box fontWeight="fontWeightBold" display='inline' style={{ color: '#76323F' }}>My purchased tests</Box>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    {this.props.purchasedCourse !== undefined && <Carousel
                        responsive={responsive}
                        swipeable={true}
                        showDots={true}
                        containerClass="carousel-container"
                        itemClass="carousel-item"
                    >
                        {this.props.purchasedCourse !== undefined &&
                            this.props.purchasedCourse.length !== 0 &&
                            this.props.purchasedCourse.sort((a, b) => new Date(b.time_stamp).getTime() - new Date(a.time_stamp).getTime()).map((course, index) =>
                                <Grid item style={{ padding: '20px' }}>
                                    <Card style={{ width: '230px', maxWidth: '230px', height: '350px', maxHeight: '350px' }}>
                                        <CardContent>
                                            <Grid container style={{ padding: '10px', paddingBottom: '0%' }}>
                                                <Grid item xs={12}>
                                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                                        Type
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="h5" component="div">
                                                        <Box fontWeight="fontWeightBold" display='inline' style={{ color: '#76323F' }}>{course.test_type}</Box>
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="caption" display="block" color="text.secondary">
                                                        {new Date(course.time_stamp).toDateString()}
                                                    </Typography>
                                                </Grid>
                                                <Grid item align='center' xs={12} style={{ marginTop: '5px' }}>
                                                    <Paper elevation={0} style={{ backgroundColor: course.tested ? 'red' : 'green', padding: '5%', width: '50%' }}>
                                                        <Typography variant="caption" display="block" style={{ color: 'white' }}>
                                                            {course.tested ? 'Tested' : 'Available'}
                                                        </Typography>
                                                    </Paper>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                        <CardActions>
                                            <Grid container style={{ padding: '10px', paddingTop: '0%', maxWidth: '230px', maxHeight: '350px' }}>
                                                {!(course.test_type === 'IELTS(3 1) Speaking') &&
                                                    <Grid item align='center' xs={12}>
                                                        <Button
                                                            size="large"
                                                            variant="contained"
                                                            onClick={() => { this.handleOpenIsStartCourse(course._id, course.test_type) }}
                                                            style={{
                                                                backgroundColor: course.tested ? '' : '#76323F',
                                                                color: course.tested ? '' : 'white',
                                                                padding: '5px',
                                                                width: '100%',
                                                                fontSize: '1rem'
                                                            }}
                                                            disabled={course.tested}
                                                        >
                                                            Start
                                                        </Button>
                                                    </Grid>
                                                }
                                                {!(course.test_type === 'IELTS(3 1)') && !(course.test_type === 'BMAT') &&
                                                    <Grid item align='center' xs={12} style={{ marginTop: '2%' }}>
                                                        <Link to={"/test/scheduler?id=" + course._id} color="inherit" underline="none" style={{ textDecoration: 'none', color: '#565656' }}>
                                                            <Button
                                                                size="small"
                                                                variant="contained"
                                                                style={{
                                                                    backgroundColor: '#76323F',
                                                                    color: 'white',
                                                                    padding: '5px',
                                                                    width: '90%',
                                                                    fontSize: '0.8rem'
                                                                }}
                                                            >
                                                                Book a Speaking
                                                            </Button>
                                                        </Link>
                                                    </Grid>
                                                }
                                                {(course.test_type === 'IELTS(3 1)') && !(course.test_type === 'BMAT') &&
                                                    <Grid item align='center' xs={12} style={{ marginTop: '2%' }}>
                                                        <Link to={`/test/payment?course_name=IELTS(3+1) Speaking&description=Additional speaking test from IELTS(3+1)&amount=10000&test_id=${course._id}`} color="inherit" underline="none" style={{ textDecoration: 'none', color: '#565656' }}>
                                                            <Button
                                                                size="small"
                                                                variant="contained"
                                                                style={{
                                                                    backgroundColor: '#3f7632',
                                                                    color: 'white',
                                                                    padding: '5px',
                                                                    width: '90%',
                                                                    fontSize: '0.7rem'
                                                                }}
                                                            >
                                                                Purchase Speaking
                                                            </Button>
                                                        </Link>
                                                    </Grid>
                                                }
                                                <Grid item align='center' xs={12}>
                                                    <Button
                                                        size="small"
                                                        onClick={() => { this.props.handleSelectReportProblem(course._id, "purchasedTest") }}
                                                        style={{
                                                            padding: '5px',
                                                            width: '80%'
                                                        }}
                                                    >
                                                        <Typography variant="caption" display="block" color="text.secondary" style={{ fontSize: '0.7rem' }}>
                                                            Report a problem
                                                        </Typography>
                                                    </Button>
                                                </Grid>
                                                <Grid item align='center' xs={12} style={{ marginTop: '10px' }}>
                                                    <Button
                                                        size="small"
                                                        onClick={() => { this.handleOpenRefundDialog(course._id) }}
                                                        style={{
                                                            padding: '5px',
                                                            width: '50%',
                                                            borderColor: 'red',
                                                            color: 'red'
                                                        }}
                                                        variant="outlined"
                                                    >
                                                        <Typography variant="caption" display="block" color="text.secondary" style={{ fontSize: '0.6rem' }}>
                                                            Refund
                                                        </Typography>
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            )}
                    </Carousel>}
                </Grid>
                {this.props.purchasedCourse !== undefined && this.props.purchasedCourse.length === 0 &&
                    <Grid item style={{ margin: '10px', maxWidth: '200px' }}>
                        <Card style={{ width: '200px', maxWidth: '200px', height: '250px', maxHeight: '250px' }}>
                            <CardContent>
                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom style={{ color: 'grey', paddingTop: '45%' }}>
                                    You do not purchased any course
                                </Typography>
                                <Link to={"/courses"} color="inherit" underline="none" style={{ textDecoration: 'none', color: '#565656' }}>
                                    <Button
                                        size="large"
                                        onClick={this.handleChooseFreeTest}
                                        style={{
                                            backgroundColor: '#76323F',
                                            color: 'white',
                                            padding: '5px',
                                            width: '100%'
                                        }}
                                    >
                                        Purchase course
                                    </Button>
                                </Link>
                            </CardContent>
                        </Card>
                    </Grid>
                }
                {this.props.purchasedCourse === undefined &&
                    <Grid item style={{ width: '200px', maxWidth: '200px', height: '200px', maxHeight: '200px', marginRight: '10px' }}>
                        <Card style={{ width: '200px', maxWidth: '200px', height: '250px', maxHeight: '250px' }}>
                            <CardContent>
                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom style={{ marginTop: '10%' }}>
                                    <Skeleton />
                                </Typography>
                                <Typography variant="h5" component="div">
                                    <Skeleton />
                                </Typography>
                                <Typography variant="caption" display="block" color="text.secondary">
                                    <Skeleton />
                                </Typography>
                                <Skeleton variant="rect" height={40} style={{ marginTop: '15%' }} />
                                <Skeleton variant="rect" height={20} style={{ marginTop: '5%' }} />
                            </CardContent>
                        </Card>
                    </Grid>
                }
                <Dialog
                    open={this.state.isConfirmStart}
                    onClose={this.handleCloseIsStartCourse}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Start a test?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Please confirm to start {this.state.testType} test.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseIsStartCourse} color="primary">
                            Cancel
                        </Button>
                        {this.state.testType !== 'BMAT' &&
                            <Link to={"/test/full-test?id=" + this.state.testId} color="inherit" underline="none" style={{ textDecoration: 'none', color: '#565656' }}>
                                <Button color="primary" autoFocus>
                                    Confirm
                                </Button>
                            </Link>
                        }
                        {this.state.testType === 'BMAT' &&
                            <Link to={`/test/bmat?is_free=false&id=${this.state.testId}`} color="inherit" underline="none" style={{ textDecoration: 'none', color: '#565656' }}>
                                <Button color="primary" autoFocus>
                                    Confirm
                                </Button>
                            </Link>
                        }
                    </DialogActions>
                </Dialog>
            </Grid>
        )
    }
}

export default compose(
    withStyles(useStyles)
)(PurchasedCourses);