//React
import { Component } from 'react';
import { compose } from 'recompose';

//Material UI
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccountBoxRoundedIcon from '@material-ui/icons/AccountBoxRounded';
import AccessAlarmRoundedIcon from '@material-ui/icons/AccessAlarmRounded';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Input from '@material-ui/core/Input';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

//Models
import { CreateSectionHistory } from '../../../../../../models/sectionHistory.js';

//Initial
const axios = require('axios').default;
const FormData = require('form-data');
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%'
    }
});

class Choose2Letters extends Component {
    constructor() {
        super();

        this.state = {
            alphabetArr: [
                'A',
                'B',
                'C',
                'D',
                'E',
                'F',
                'G'
            ],
            tempAnsArr: []
        };
    }

    componentDidMount = async () => {
        if (this.props.sectionId !== undefined) {
            await this.handleGetSectionObj();
        }
    }

    handleGetSectionObj = async () => {
        if (this.props.sectionId !== undefined && this.props.sectionObj === undefined) {
            try {
                const res = await axios({
                    method: 'get',
                    url: api_base_url + '/api/test/section/id/' + this.props.sectionId,
                    headers: {
                        "Authorization": "Bearer " + JSON.parse(localStorage.getItem('user')).token
                    }
                });
                console.log(res);

                const resData = res.data.section;
                let resDataTemp = JSON.parse(JSON.stringify(resData));

                await this.setState({
                    sectionObj: resData,
                    tempSectionObj: resDataTemp
                });

                console.log(this.state.sectionObj !== undefined);
            } catch (err) {
                console.log(err);
            }
        } else if (this.props.sectionObj !== undefined){
            await this.setState({
                sectionObj: this.props.sectionObj,
                tempSectionObj: this.props.sectionObj,
                tempAnsArr: []
            });
        }
    }

    // =============================== EDIT SECTION ==================================
    handleEditQuestion = (event, index) => {
        this.state.sectionObj.questions[index].question = event.target.value;
        this.setState({
            sectionObj: this.state.sectionObj
        });
    }

    handleEditChoice = (event, index, choiceIdx) => {
        console.log(this.state.alphabetArr[choiceIdx] + event.target.value);
        this.state.sectionObj.questions[index].choice[choiceIdx] = this.state.alphabetArr[choiceIdx] + '. ' + event.target.value;
        this.setState({
            sectionObj: this.state.sectionObj
        });

        console.log(this.state.sectionObj)
    }

    handleEditAnswer = async (event, index) => {
        const value = event.target.value;
        console.log(event);
        if (this.state.sectionObj.questions[index].answer.includes(value) || this.state.sectionObj.questions[index].answer.length === 2) {
            return;
        }

        this.state.sectionObj.questions[index].answer.push(value);
        console.log(this.state.sectionObj.questions[index].answer);

        await this.setState({
            sectionObj: this.state.sectionObj
        });
    }

    handleClearAnswer = async (event, index) => {
        this.state.sectionObj.questions[index].answer = [];

        await this.setState({
            sectionObj: this.state.sectionObj
        });
    }

    handlePutEditSection = async () => {
        if (this.props.sectionId !== undefined) {
            try {
                const res = await axios({
                    method: 'put',
                    url: api_base_url + '/api/test/section',
                    headers: {
                        "Authorization": "Bearer " + JSON.parse(localStorage.getItem('user')).token
                    },
                    data: this.state.sectionObj
                });
                console.log(res);

                await CreateSectionHistory(this.state.tempSectionObj, this.state.sectionObj, this.props.sectionId, this.props.test_id, this.props.selectedPart);
                await this.handleGetSectionObj();
                this.props.handleEndEditSection();
            } catch (err) {
                console.log(err);
            }
        }
    }
    // =============================== EDIT SECTION ==================================

    render() {
        const { classes } = this.props;

        return (
            <Grid item xs={12}>
                <Grid container spacing={1}>
                    {this.state.sectionObj !== undefined ? this.state.sectionObj.questions.map((question, index) =>
                        <Grid key={question.no} item align='left' xs={12} style={{ marginTop: '10px' }}>
                            <Grid container spacing={1}>
                                <Grid item xs={1}>
                                    <TextField
                                        disabled
                                        label="No."
                                        id="outlined-size-small"
                                        variant="outlined"
                                        size="small"
                                        value={question.no}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={11}>
                                    <TextField
                                        disabled={!(this.props.selectedSectionId === this.state.sectionObj.section_id)}
                                        label="Question"
                                        id="outlined-size-small"
                                        variant="outlined"
                                        size="small"
                                        value={question.question}
                                        onChange={(event) => { this.handleEditQuestion(event, index) }}
                                        fullWidth
                                    />
                                </Grid>
                                {(this.state.sectionObj.questions[index].answer.length === 2) && (this.props.selectedSectionId === this.state.sectionObj.section_id) &&
                                    <Grid item align='right' xs={12}>
                                        <Button variant="outlined" size="medium" color="primary" onClick={(event) => { this.handleClearAnswer(event, index) }} style={{ marginRight: '5px', backgroundColor: 'white', color: '#76323F', borderColor: '#76323F' }}>
                                            Clear
                                        </Button>
                                    </Grid>
                                }
                                <Grid item xs={12}>
                                    <RadioGroup
                                        key={question.no}
                                        name={question.no}
                                        value={question.answer}
                                        style={{ marginLeft: '20px', marginTop: '10px' }}
                                    //onChange={(event) => { this.handleEditAnswer(event, index) }}
                                    >
                                        {question.choice.map((choice, choiceIdx) =>
                                            <FormControlLabel
                                                disabled={!(this.props.selectedSectionId === this.state.sectionObj.section_id) || this.state.sectionObj.questions[index].answer.length === 2}
                                                value={choice.split(' ')[0]}
                                                control={<Radio checked={question.answer.includes(choice.split(' ')[0])} onChange={(event) => { this.handleEditAnswer(event, index) }} color="default" />}
                                                label={
                                                    <Grid container spacing={2}>
                                                        <Grid item align='right' xs style={{ paddingTop: '1.2%' }}>
                                                            <Typography variant="h6" align='left'>
                                                                {this.state.alphabetArr[choiceIdx]}.
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs>
                                                            <TextField
                                                                disabled={!(this.props.selectedSectionId === this.state.sectionObj.section_id)}
                                                                id="outlined-size-small"
                                                                variant="outlined"
                                                                size="small"
                                                                value={choice.split('. ')[1]}
                                                                onChange={(event) => { this.handleEditChoice(event, index, choiceIdx) }}
                                                                style={{ width: '800px' }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                }
                                            />
                                        )}
                                    </RadioGroup>
                                </Grid>
                            </Grid>
                        </Grid>
                    ) : <Grid item align='left' xs={12}>
                        <Typography variant="subtitle2" gutterBottom align='left'>
                            <Box fontWeight="fontWeightBold" display='inline'>No question</Box>
                        </Typography>
                    </Grid>
                    }

                    {this.state.sectionObj !== undefined && (this.props.selectedSectionId === this.state.sectionObj.section_id) &&
                        <Grid item align='right' xs={12}>
                            <Button variant="contained" size="medium" color="primary" onClick={this.handlePutEditSection} style={{ marginRight: '5px', backgroundColor: '#76323F' }}>
                                Confirm
                            </Button>
                            <Button variant="contained" size="medium" color="default" onClick={this.props.handleEndEditSection} style={{ marginLeft: '5px', color: 'grey' }}>
                                Cancel
                            </Button>
                        </Grid>
                    }
                </Grid>
            </Grid>
        )
    }
}

export default compose(
    withStyles(useStyles)
)(Choose2Letters);