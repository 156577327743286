import React, { useEffect } from 'react';

//MUI
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { ThemeProvider, createMuiTheme, makeStyles, withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';

//Functions
import { capitalize } from '../../../models/publicFuntions';

//Services
import { Login, LoginWithGoogle } from '../../../services/userServices';

//Social login
import { GoogleOAuthProvider, GoogleLogin, useGoogleLogin } from '@react-oauth/google';
//import GoogleLogin from 'react-google-login';

//Font
import 'typeface-glacial-indifference';

//Others
import {
    Link
} from "react-router-dom";
const axios = require('axios').default;
const FormData = require('form-data');
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

const useStyles = makeStyles((theme) => ({
    input: {
        background: "white",
        fontSize: "1rem"
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    }
}));

const theme = createMuiTheme({
    typography: {
        fontFamily: [
            'Roboto'
        ].join(','),
    },
});

const NewLoginPage = (props) => {
    const [password, setPassword] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [isLoginError, setIsLoginError] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [values, setValues] = React.useState({
        showPassword: false
    });
    const [googleUser, setGoogleUser] = React.useState({});

    useEffect(() => {
        document.body.style.overflow = "auto";
        document.body.style.backgroundColor = "#efefef";
        document.querySelector("body").scrollTo(0, 0);
    }, []);

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleLogin = async (username, password) => {
        const { history } = props;

        setIsLoading(true);

        const res = await Login({ username: username, password: password });

        if (res.success) {
            const capFirstName = await capitalize(res.res.firstname);
            const capLastName = await capitalize(res.res.lastname);
            console.log(res);

            const data = {
                userSignedin: true,
                firstname_th: "",
                lastname_th: "",
                firstname: capFirstName,
                lastname: capLastName,
                name: `${capFirstName} ${capLastName}`,
                name_th: "",
                email: res.res.email,
                birthdate: res.res.birthDate,
                gender: res.res.gender,
                phone: res.res.phoneNumber,
                school: res.res.school,
                firstNameChar: res.res.firstname.charAt(0).toUpperCase(),
                token: res.token,
                role: res.res.role,
                picture: res.res.picture,
                username: res.res.username
            };

            await localStorage.setItem('user', JSON.stringify(data));
            await localStorage.setItem('userSignedIn', true);

            // history.push("/profile");
            if (["TEACHER", "HEAD_TEACHER"].includes(res.res.role)) {
                // window.location.href = "/teacher/dashboard"
                window.location.href = "/"
            } else {
                // window.location.href = "/profile"
                window.location.href = "/"
            }
        } if (!(res.success)) {
            setIsLoginError(true);
            setIsLoading(false);
        }
    }

    const getProfile = async (response) => {
        const { history } = props;

        setIsLoading(true);
        const res = await LoginWithGoogle(response.credential);

        if (res.success) {
            if (res.res.gender === "None") {

                // history.push(`/social-signup?token=${response.credential}`)
                window.location.href = `/social-signup?token=${response.credential}`;

                return;
            } else {
                const capFirstName = await capitalize(res.res.firstname);
                const capLastName = await capitalize(res.res.lastname);
                const data = {
                    userSignedin: true,
                    firstname_th: "",
                    lastname_th: "",
                    firstname: capFirstName,
                    lastname: capLastName,
                    name: `${capFirstName} ${capLastName}`,
                    name_th: "",
                    email: res.res.email,
                    birthdate: res.res.birthDate,
                    gender: res.res.gender,
                    phone: res.res.phoneNumber,
                    school: res.res.school,
                    firstNameChar: res.res.firstname.charAt(0).toUpperCase(),
                    token: res.token,
                    role: res.res.role,
                    picture: res.res.picture,
                    username: res.res.username
                };

                await localStorage.setItem('user', JSON.stringify(data));
                await localStorage.setItem('userSignedIn', true);
            }

            //history.push("/profile");
            if (["TEACHER", "HEAD_TEACHER"].includes(res.res.role)) {
                // window.location.href = "/teacher/dashboard"
                window.location.href = "/"
            } else {
                // window.location.href = "/profile"
                window.location.href = "/"
            }
        } if (!res.success) {
            setIsLoginError(true);
            setIsLoading(false);
        }
    }

    const classes = useStyles();

    const mobileUI = (<Grid container style={{ marginTop: "5%", marginBottom: "10%", maxWidth: window.innerWidth }}>
        <Grid item xs={12}>
            <Typography variant="h3" style={{ fontWeight: "bold" }} gutterBottom>
                <span style={{ color: "#991514" }}>U</span>TEST AVENUE
            </Typography>
        </Grid>
        <Grid item xs={12} style={{ paddingLeft: "10%", paddingRight: "10%" }}>
            <Paper elevation={0} style={{ backgroundColor: "#a6a6a6", borderRadius: "25px" }}>
                <Grid container spacing={2} style={{ padding: "15%" }}>
                    {isLoginError &&
                        <Grid item xs={12}>
                            <Button
                                disabled
                                variant="contained"
                                size="large"
                                style={{ backgroundColor: "#fedce0", color: "#851a21", textTransform: "none", fontSize: "1rem", fontWeight: "bold" }}
                                fullWidth
                                disableElevation
                            >
                                Email or password is incorrect.
                            </Button>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <TextField
                            error={isLoginError}
                            id="outlined-basic-email"
                            label={<span style={{ fontSize: "1rem" }}>Email</span>}
                            variant="outlined"
                            type="input"
                            onChange={(event) => { setEmail(event.target.value) }}
                            InputProps={{ className: classes.input }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel htmlFor="outlined-adornment-password" style={{ fontSize: "1rem" }}>Password</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password"
                                error={isLoginError}
                                type={values.showPassword ? 'text' : 'password'}
                                value={values.password}
                                style={{ backgroundColor: "white", fontSize: "1rem" }}
                                onChange={(event) => { setPassword(event.target.value) }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                labelWidth={70}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            size="small"
                            style={{ backgroundColor: "#991514", color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1rem" }}
                            onClick={() => { handleLogin(email, password) }}
                            fullWidth
                            disableElevation
                        >
                            Sign in
                        </Button>
                    </Grid>
                    <Grid item align="left" xs={12}>
                        <GoogleOAuthProvider clientId="381958762379-v3cvipu3ncagsn76o2rfl3mv13gso2m5.apps.googleusercontent.com">
                            <GoogleLogin
                                onSuccess={credentialResponse => {
                                    getProfile(credentialResponse);
                                }}
                                onError={() => {
                                    console.log('Login Failed');
                                }}
                                
                                width="220px"
                            />
                        </GoogleOAuthProvider>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider variant="middle" style={{ backgroundColor: "white" }} />
                    </Grid>
                    <Grid item xs={12}>
                        <Link
                            to="/register"
                            style={{ textDecoration: 'none', color: '#565656' }}
                        >
                            <Button
                                variant="contained"
                                size="small"
                                style={{ backgroundColor: "#991514", color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1rem" }}
                                fullWidth
                                disableElevation
                            >
                                Sign up
                            </Button>
                        </Link>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    </Grid>)

    const webUI = (<Grid container style={{ marginTop: "5%", marginBottom: "26%", maxWidth: window.innerWidth }}>
        <Grid item xs={12} style={{ marginBottom: "2%" }}>
            <Typography variant="h3" style={{ fontWeight: "bold" }} gutterBottom>
                <span style={{ color: "#991514" }}>U</span>TEST AVENUE
            </Typography>
        </Grid>
        <Grid item align="center" xs={12}>
            <Paper elevation={0} style={{ maxWidth: "350px", backgroundColor: "#a6a6a6", borderRadius: "25px" }}>
                <Grid container spacing={3} style={{ padding: "30px" }}>
                    {isLoginError &&
                        <Grid item xs={12}>
                            <Button
                                disabled
                                variant="contained"
                                size="large"
                                style={{ backgroundColor: "#fedce0", color: "#851a21", textTransform: "none", fontSize: "1rem", fontWeight: "bold" }}
                                fullWidth
                                disableElevation
                            >
                                Email or password is incorrect.
                            </Button>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <Typography variant="body1" align="left" style={{ fontWeight: 'bold', marginTop: '20px', color: "white" }} gutterBottom>
                            Email:
                        </Typography>
                        <TextField
                            error={isLoginError}
                            id="outlined-basic-email"
                            variant="outlined"
                            type="input"
                            onChange={(event) => { setEmail(event.target.value) }}
                            InputProps={{ className: classes.input }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1" align="left" style={{ fontWeight: 'bold', color: "white" }} gutterBottom>
                            Password:
                        </Typography>
                        <FormControl variant="outlined" fullWidth>
                            <OutlinedInput
                                id="outlined-adornment-password"
                                error={isLoginError}
                                type={values.showPassword ? 'text' : 'password'}
                                value={values.password}
                                style={{ backgroundColor: "white", fontSize: "1rem" }}
                                onChange={(event) => { setPassword(event.target.value) }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            size="small"
                            style={{ backgroundColor: "#991514", color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1rem" }}
                            onClick={() => { handleLogin(email, password) }}
                            fullWidth
                            disableElevation
                        >
                            Sign in
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <GoogleOAuthProvider clientId="381958762379-v3cvipu3ncagsn76o2rfl3mv13gso2m5.apps.googleusercontent.com">
                            <GoogleLogin
                                onSuccess={credentialResponse => {
                                    getProfile(credentialResponse);
                                }}
                                onError={() => {
                                    console.log('Login Failed');
                                }}
                                width="285px"
                            />
                        </GoogleOAuthProvider>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider variant="middle" style={{ backgroundColor: "white" }} />
                    </Grid>
                    <Grid item xs={12}>
                        <Link
                            to="/register"
                            style={{ textDecoration: 'none', color: '#565656' }}
                        >
                            <Button
                                variant="contained"
                                size="small"
                                style={{ backgroundColor: "#991514", color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1rem" }}
                                fullWidth
                                disableElevation
                            >
                                Sign up
                            </Button>
                        </Link>
                    </Grid>
                    <Grid item xs={12}>
                        <Link
                            to="/user/forgotpassword"
                            style={{ textDecoration: 'none', color: '#565656' }}
                        >
                            <Button
                                variant="text"
                                size="small"
                                style={{ color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1rem" }}
                                fullWidth
                                disableElevation
                            >
                                Forgot password
                            </Button>
                        </Link>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    </Grid>)

    return <ThemeProvider theme={theme}>
        <Backdrop className={classes.backdrop} open={isLoading}>
            <CircularProgress color="inherit" style={{ color: "#880000" }} />
        </Backdrop>

        <Hidden mdUp>
            {mobileUI}
        </Hidden>
        <Hidden smDown>
            {webUI}
        </Hidden>
    </ThemeProvider>
};

export default compose(
    withStyles(useStyles)
)(NewLoginPage);
