import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';
import { Component } from 'react';
import { compose } from 'recompose';

//Icon & animation
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';

//Others

const axios = require('axios').default;
const FormData = require('form-data');

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%'
    },
    img: {
        width: theme.spacing(20),
        height: theme.spacing(20),
    },
});

class RefundDialog extends Component {
    constructor() {
        super();
        this.state = {
            isError: false,
            isRequested: false,
            isLoading: false
        };
    }

    handleReset = () => {
        this.setState({
            isError: false,
            isRequested: false,
            isLoading: false
        })
    }

    handleChangeInRefundReason = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleRefund = async () => {
        if (this.state.reason === undefined || this.state.reason.length === 0) {
            this.setState({
                isError: true,
                errorMessageEmptyReason: 'Please provide reason to request a refund.'
            })

            return;
        }

        this.setState({
            isLoading: true,
            isError: false,
            errorMessageEmptyReason: ''
        });

        try {
            const res = await axios({
                method: 'get',
                url: "https://almond-magnetic-sale.glitch.me/charges/history/purchased-id/" + this.props.selectedPurchasedId
            })

            const resData = res.data;

            await axios({
                method: 'post',
                url: "https://almond-magnetic-sale.glitch.me/charges/refunds/create",
                data: {
                    purchasedId: this.props.selectedPurchasedId,
                    purchasedUsername: JSON.parse(localStorage.getItem('user')).name,
                    purchasedChargeAmount: resData.purchasedChargeAmount,
                    purchasedChargeId: resData.purchasedChargeId,
                    reason: this.state.reason
                }
            })

            this.setState({
                isRequested: true,
                isLoading: false
            });

        } catch (error) {
            console.error(error);

            this.setState({
                isLoading: false
            });
        }
    }

    render() {
        const { classes } = this.props;

        return (
            <Dialog open={this.props.isOpenRefundDialog} onClose={this.props.handleCloseRefundDialog} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Request for refund</DialogTitle>
                <DialogContent>
                    {this.state.isLoading &&
                        <Grid item align='center' xs={12} style={{ padding: '100px' }}>
                            <CircularProgress style={{ color: '#76323F' }} />
                        </Grid>
                    }

                    {this.state.isRequested && !this.state.isLoading &&
                        <Grid container>
                            <Grid item>
                                <CheckCircleOutlineRoundedIcon style={{ fontSize: '100px', color: 'green' }} />
                            </Grid>
                            <Grid item>
                                <Typography variant="h2" style={{ marginTop: '20px' }}>
                                    Thanks!
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1">
                                    Your refund has been requested.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} style={{ marginTop: '50px' }}>
                                <Button variant="contained" onClick={() => {
                                    this.props.handleCloseRefundDialog();
                                    this.handleReset();
                                }}
                                    style={{ backgroundColor: '#76323F', color: 'white' }}
                                >
                                    Close
                                </Button>
                            </Grid>
                        </Grid>
                    }
                    {!this.state.isRequested && !this.state.isLoading &&
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
                                    Submitter
                                </Typography>
                                <TextField
                                    disabled
                                    variant="outlined"
                                    margin="dense"
                                    id="submitter"
                                    defaultValue={JSON.parse(localStorage.getItem('user')).name}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
                                    Purchase id
                                </Typography>
                                <TextField
                                    disabled
                                    variant="outlined"
                                    margin="dense"
                                    id="purchase-id"
                                    defaultValue={this.props.selectedPurchasedId}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6" gutterBottom>
                                    Reason
                                </Typography>
                                <TextField
                                    id="outlined-multiline-static"
                                    name="reason"
                                    multiline
                                    rows={4}
                                    autoFocus
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    error={this.state.isError}
                                    helperText={this.state.errorMessageEmptyReason}
                                    onChange={this.handleChangeInRefundReason}
                                />
                            </Grid>
                        </Grid>
                    }
                    <DialogContentText>
                        {!this.state.isRequested && !this.state.isLoading && 'Please provide reason that made you request for refund.'}
                    </DialogContentText>
                </DialogContent>
                {!this.state.isRequested && !this.state.isLoading &&
                    <DialogActions>
                        <Button onClick={this.props.handleCloseRefundDialog} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.handleRefund} color="primary">
                            Send
                        </Button>
                    </DialogActions>
                }
            </Dialog>
        )
    }
}

export default compose(
    withStyles(useStyles)
)(RefundDialog);