import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';
import { Component } from 'react';
import { compose } from 'recompose';

//Icon
import EditRoundedIcon from '@material-ui/icons/EditRounded';

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%'
    },
    img: {
        width: theme.spacing(20),
        height: theme.spacing(20),
    },
});

class AccountInfo extends Component {
    handleChangeRoleName = (role) => {
        if (role === 'SUPER_ADMIN') {
            return 'Super admin'
        }
        else if (role === 'ADMIN') {
            return 'Admin'
        }
        else if (role === 'HEAD_TEACHER') {
            return 'Head teacher'
        }
        else if (role === 'TEACHER') {
            return 'Teacher'
        }
        else if (role === 'STUDENT') {
            return 'Student'
        }
    }

    render() {
        const { classes } = this.props;

        return (
            <Grid container style={{ padding: '20px' }}>
                <Grid item xs={12} style={{ marginBottom: '20px' }}>
                    <Grid container>
                        <Grid item align='left'>
                            <Typography component="h4" variant="h4">
                                Account
                            </Typography>
                        </Grid>
                        <Grid item align='left'>
                            <IconButton edge="end" aria-label="delete" disabled>
                                <EditRoundedIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Paper >
                        <Grid container justify='center' spacing={2} style={{ padding: '50px' }}>
                            <Grid item xs={12} sm={6} align='left'>
                                <Typography variant="subtitle1">
                                    <Box fontWeight="fontWeightBold" display='inline'> Username:</Box>
                                </Typography>
                                <TextField
                                    id="outlined-read-only-input"
                                    defaultValue={this.props.userInfo.username}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    variant="outlined"
                                    style={{ width: '90%' }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} align='left'>
                                <Typography variant="subtitle1">
                                    <Box fontWeight="fontWeightBold" display='inline'>Role:</Box>
                                </Typography>
                                <TextField
                                    id="outlined-read-only-input"
                                    defaultValue={this.handleChangeRoleName(this.props.userInfo.role)}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    variant="outlined"
                                    style={{ width: '90%' }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} align='left'>
                                <Typography variant="subtitle1">
                                    <Box fontWeight="fontWeightBold" display='inline'>First name:</Box>
                                </Typography>
                                <TextField
                                    id="outlined-read-only-input"
                                    defaultValue={this.props.userInfo.firstname}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    variant="outlined"
                                    style={{ width: '90%' }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} align='left'>
                                <Typography variant="subtitle1">
                                    <Box fontWeight="fontWeightBold" display='inline'>Last name:</Box>
                                </Typography>
                                <TextField
                                    id="outlined-read-only-input"
                                    defaultValue={this.props.userInfo.lastname}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    variant="outlined"
                                    style={{ width: '90%' }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} align='left'>
                                <Typography variant="subtitle1">
                                    <Box fontWeight="fontWeightBold" display='inline'>Email:</Box>
                                </Typography>
                                <TextField
                                    id="outlined-read-only-input"
                                    defaultValue={this.props.userInfo.email}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    variant="outlined"
                                    style={{ width: '90%' }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} align='left'>
                                <Typography variant="subtitle1">
                                    <Box fontWeight="fontWeightBold" display='inline'>Gender:</Box>
                                </Typography>
                                <TextField
                                    id="outlined-read-only-input"
                                    defaultValue={this.props.userInfo.gender}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    variant="outlined"
                                    style={{ width: '90%' }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} align='left'>
                                <Typography variant="subtitle1">
                                    <Box fontWeight="fontWeightBold" display='inline'>Phone:</Box>
                                </Typography>
                                <TextField
                                    id="outlined-read-only-input"
                                    defaultValue={'+' + this.props.userInfo.phone}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    variant="outlined"
                                    style={{ width: '90%' }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} align='left'>
                                <Typography variant="subtitle1">
                                    <Box fontWeight="fontWeightBold" display='inline'>Birthday:</Box>
                                </Typography>
                                <TextField
                                    id="outlined-read-only-input"
                                    defaultValue={this.props.userInfo.birthdate}
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    style={{ width: '90%' }}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        )
    }
}

export default compose(
    withStyles(useStyles)
)(AccountInfo);