import * as apiConstants from '../constants/apiConstants';

const axios = require('axios').default;

export async function GetAllBlogs() {
    try {
        const res = await axios({
            method: 'get',
            url: `${apiConstants.UTESTAVENUE_SYSTEM_API_BASE_URL}/blogs`,
        });
        const pinnedBlogs = await res.data.blogs.filter(blog => blog.pinned);

        return {
            success: true,
            res: {
                blogs: res.data.blogs,
                pinnedBlogs: pinnedBlogs
            }
        }

    } catch (err) {
        console.log(err);

        return {
            success: false,
            res: err
        }
    }
}