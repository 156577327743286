//React
import { Component } from 'react';
import { compose } from 'recompose';

//Material UI
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Box from '@material-ui/core/Box';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%'
    },
    paperRoot: {
        padding: theme.spacing(2),
        textAlign: 'left'
    },
    paperItem: {
        padding: theme.spacing(2),
        textAlign: 'left',
        height: '810px',
        maxHeight: '810px',
        overflow: 'auto',
    },
    testAlignLeft: {
        padding: theme.spacing(2),
    },
    gridContainerRoot: {
        marginTop: 80
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    marginLeft: {
        marginLeft: 20
    },
    marginTop: {
        marginTop: 20
    },
    margin: {
        margin: 5
    }
});

class YesNoNG extends Component {
    constructor() {
        super();

        this.state = {
            answerObj: {
                answers: {}
            }
        };
    }

    componentDidMount = async () => {

    }

    render() {
        const { classes } = this.props;

        return (
            <Grid container style={{ marginBottom: '20px' }}>
                <Grid item align='left' xs={12}>
                    <Typography variant="h5" gutterBottom style={{ fontSize: '1rem' }}>
                        <Box fontWeight="fontWeightBold" display='inline'>{this.props.sectionInfo.section}</Box>
                    </Typography>
                </Grid>
                <Grid item align='left' xs={12} style={{ marginTop: '20px' }}>
                    {this.props.sectionInfo.questions.map(q =>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography className={classes.heading} style={{ fontSize: '1rem' }}>
                                    <Box fontWeight="fontWeightBold" display='inline' m={1}>{q.no}</Box> {q.question}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails className={classes.marginLeft}>
                                <RadioGroup
                                    aria-label="q1"
                                    placeholder={this.props.sectionInfo.section_id}
                                >
                                    <FormControlLabel
                                        value="yes"
                                        control={<Radio color="default" />}
                                        label={<Typography variant="subtitle2" gutterBottom>YES</Typography>}
                                    />
                                    <FormControlLabel
                                        value="no"
                                        control={<Radio color="default" />}
                                        label={<Typography variant="subtitle2" gutterBottom>NO</Typography>}
                                    />
                                    <FormControlLabel
                                        value="not given"
                                        control={<Radio color="default" />}
                                        label={<Typography variant="subtitle2" gutterBottom>NOT GIVEN</Typography>}
                                    />
                                </RadioGroup>
                            </AccordionDetails>
                        </Accordion>
                    )}
                </Grid>
            </Grid>
        )
    }
}

export default compose(
    withStyles(useStyles)
)(YesNoNG);