import React from 'react';

import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import LinearProgress from '@material-ui/core/LinearProgress';
import MuiAlert from '@material-ui/lab/Alert';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import { Container } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Component } from 'react';
import { compose } from 'recompose';

//Icon
import BallotRoundedIcon from '@material-ui/icons/BallotRounded';
import ImportContactsRoundedIcon from '@material-ui/icons/ImportContactsRounded';
import EventRoundedIcon from '@material-ui/icons/EventRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import CardMembershipRoundedIcon from '@material-ui/icons/CardMembershipRounded';

//Components
import AssignedWorks from './components/AssignedWorks';
import Menus from './components/Menus';
import Income from './components/Income';
import IncomeTable from './components/IncomTable';
import ProblemReportsTable from './components/ProblemReportsTable';
import RescoreReportsTable from './components/RescoreReportsTable';

import Carousel from 'react-elastic-carousel';
import "react-multi-carousel/lib/styles.css";
import UserEngagement from './components/UserEngagement';

//Others
const axios = require('axios').default;
const FormData = require('form-data');
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

const useStyles = theme => ({
    root: {
        margin: 15,
        marginTop: 50
    }
});

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class SuperAdminDashboardPage extends Component {
    constructor() {
        super()
        this.state = {
            breakPoints: [
                { width: 1, itemsToShow: 1 },
                { width: 600, itemsToShow: 2 },
                { width: 900, itemsToShow: 3 },
                { width: 1200, itemsToShow: 4 },
            ],
            window: {
                height: window.innerHeight,
                width: window.innerWidth
            },
            selectedMenu: 'userEngagement'
        };
    }

    componentDidMount = () => {
        this.props.handleChangeMenu('superAdminDashboard');
        document.body.style.backgroundColor = "#efefef";
        document.querySelector("body").scrollTo(0, 0);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    handleResize = (event) => {
        this.setState({
            window: {
                height: window.innerHeight,
                width: window.innerWidth
            }
        });
    };

    handleChangeMenu = (menu) => {
        this.setState({
            selectedMenu: menu
        });
    }

    render() {
        const { classes } = this.props;

        return (
            <Container maxWidth={false} style={{ marginTop: '100px', marginBottom: '300px' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={2}>
                        <Menus handleChangeMenu={this.handleChangeMenu} selectedMenu={this.state.selectedMenu} user={this.props.user} />
                    </Grid>

                    {this.state.selectedMenu === 'userEngagement' &&
                        <Grid item xs={12} sm={10}>
                            <UserEngagement user={this.props.user} />
                        </Grid>
                    }

                    {/*================= INCOME ====================*/}
                    {this.state.selectedMenu === 'income' &&
                        <Grid item xs={12} sm={10}>
                            <Income user={this.props.user} />
                        </Grid>
                    }
                    {this.state.selectedMenu === 'income' &&
                        <Grid item xs={12} sm={12}>
                            <IncomeTable user={this.props.user} />
                        </Grid>
                    }
                    {/*================= INCOME ====================*/}

                    {/*================= TEST STATUS ====================*/}
                    {this.state.selectedMenu === 'testStatus' &&
                        <Grid item xs={12} sm={10}>
                            <AssignedWorks user={this.props.user} />
                        </Grid>
                    }
                    {/*================= TEST STATUS ====================*/}

                    {/*================= PROBLEM REPORTS ====================*/}
                    {this.state.selectedMenu === 'problemReports' &&
                        <Grid item xs={12} sm={10}>
                            <ProblemReportsTable user={this.props.user} />
                        </Grid>
                    }
                    {/*================= PROBLEM REPORTS ====================*/}

                    {/*================= RESCORE REPORTS ====================*/}
                    {this.state.selectedMenu === 'rescoreReports' &&
                        <Grid item xs={12} sm={10}>
                            <RescoreReportsTable user={this.props.user} />
                        </Grid>
                    }
                    {/*================= RESCORE REPORTS ====================*/}

                </Grid>
            </Container>
        )
    }
}
export default compose(
    withStyles(useStyles)
)(SuperAdminDashboardPage);