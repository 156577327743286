//React
import { Component } from 'react';
import { compose } from 'recompose';

//Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Container from '@material-ui/core/Container';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { ThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';

//import components
import PageBackdrop from '../../main/PageBackdrop';
import UserInfoPage from './components/UserInfoPage';
import SectionOneInstructions from './components/free/SectionOneInstructions';
import SectionTwoInstructions from './components/free/SectionTwoInstructions';
import FullSectionOneInstructions from './components/full/FullSectionOneInstructions';
import FullSectionTwoInstructions from './components/full/FullSectionTwoInstructions';

//import question types
import MultipleChoices from './questions/MultipleChoices';

//Icons
import AccessAlarmsRoundedIcon from '@material-ui/icons/AccessAlarmsRounded';

//react-router-dom
import {
    Link
} from "react-router-dom";
import FullWritingSelection from './components/full/FullWritingSelection';

//Others
import moment from 'moment';
import pdfMake from "pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import FullSectionThreeInstructions from './components/full/FullSectionThreeInstructions';
const randomstring = require("randomstring");
const axios = require('axios').default;
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

//FONT RELATED
const theme = createMuiTheme({
    typography: {
        fontFamily: [
            'Noto Sans'
        ].join(','),
    },
});

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%'
    },
    paperRoot: {
        padding: theme.spacing(2),
        textAlign: 'left'
    },
    paperItem: {
        padding: theme.spacing(2),
        textAlign: 'left',
        height: '810px',
        maxHeight: '810px',
        overflow: 'auto',
    },
    testAlignLeft: {
        padding: theme.spacing(2),
    },
    gridContainerRoot: {
        marginTop: 80
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    marginLeft: {
        marginLeft: 20
    },
    marginTop: {
        marginTop: 20
    },
    margin: {
        margin: '0'
    }
});

class MainBMATTestPage extends Component {
    constructor() {
        super();
        this.state = {
            window: {
                height: window.innerHeight,
                width: window.innerWidth
            },
            answeredQuestion: [],
            totalQuestionArr: [],
            resultObj: {},
            isInfoPage: true,
            isInfoSectionOnePage: false,
            isInfoSectionTwoPage: false,
            isInfoSectionThreePage: false,
            isWriting: false,
            isLoading: false,
            isConfirmBackHome: false,
            isConfirmSubmitTest: false,
            isConfirmSubmitSection: false,
            isSetSectionTwoTimer: false,
            isSetSectionThreeTimer: false,
            isSetSectionThreeTimer: false,
            timer: 1500,
            timerSeconds: 0
        };
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    handleResize = (event) => {
        this.setState({
            window: {
                height: window.innerHeight,
                width: window.innerWidth
            }
        });
    };

    componentDidMount = async () => {
        // document.getElementById('root').requestFullscreen().then(() => {
        //     console.log("full screen")
        // }, () => { console.log("no fullscreen") });

        // Prevent right-click
        document.addEventListener('contextmenu', (e) => {
            e.preventDefault();
        });

        document.body.style.backgroundColor = "#efefef";
        window.addEventListener("resize", this.handleResize);

        await this.clearAllInterval();

        const query = new URLSearchParams(this.props.location.search);
        const logQuery = query.get('log_id');
        const isFreeQuery = query.get('is_free');

        this.props.handleStartTest();

        if (JSON.parse(localStorage.getItem('bmatSessionObj')) !== null) {
            this.setState(JSON.parse(localStorage.getItem('bmatSessionObj')));
            this.startTimer();
        } else {
            await this.checkIsFreeTest(isFreeQuery);

            if (logQuery !== null) {
                if (logQuery.length !== 0) {
                    await this.setState({
                        log_id: logQuery
                    });

                    await this.handleResumeTest();
                }
            }
        }
    }

    checkIsFreeTest = async (query) => {
        if (query === 'true') {
            this.setState({
                isFree: true
            });

        } else if (query === null) {
            this.setState({
                isFree: true
            });

        } else if (query !== null && query === 'false') {
            this.setState({
                isFree: false,
                timer: 3600
            });

            await this.handleValidateFullTest();
        }
    }

    //======================================================= VALIDATE FULL TEST =======================================================
    handleValidateFullTest = async () => {
        this.handleLoading();

        await this.handleGetPurchasedCourse();
        await this.handleCheckCourseId();

        this.handleCloseLoading();
    }

    updateSession = () => {
        localStorage.setItem('bmatSessionObj', JSON.stringify(this.state));
    }

    handleGetPurchasedCourse = async () => {
        try {
            const purchasedCourse = await axios({
                method: 'get',
                url: `${api_base_url}/api/test/test-purchase-status?user_name=${JSON.parse(localStorage.getItem('user')).username}`,
                headers: {
                    Authorization: "bearer " + JSON.parse(localStorage.getItem('user')).token
                }
            })

            this.setState({
                purchasedCourse: purchasedCourse.data
            });

        } catch (error) {
            console.error(error);
            this.setState({
                purchasedCourse: []
            });
        }
    }

    handleCheckCourseId = async () => {
        const query = new URLSearchParams(this.props.location.search);
        const courseId = query.get('id');

        if (courseId === null) {
            this.setState({
                isValidated: false
            })

            return false
        } else if (courseId !== null) {
            const availableCourse = await this.state.purchasedCourse?.filter(test => test._id === courseId && !test.tested);

            if (Boolean(availableCourse?.length)) {
                this.setState({
                    purchasedTestId: availableCourse[0]._id
                })
            }

            this.setState({
                isValidated: Boolean(availableCourse?.length)
            })

            return Boolean(availableCourse?.length);
        }
    }

    handlDonePurchasedTest = async () => {
        try {
            await axios({
                method: 'put',
                url: api_base_url + '/api/test/test-purchase-status?test_id=' + this.state.purchasedTestId + '&test_status=true',
                headers: {
                    Authorization: "bearer " + JSON.parse(localStorage.getItem('user')).token
                }

            });
        } catch (err) {
            console.log(err);
        }
    }
    //======================================================= VALIDATE FULL TEST =======================================================

    handleGetBMATTest = async () => {
        try {
            this.handleLoading();

            const testObj = await axios({
                method: 'get',
                url: `${api_base_url}/api/test/random?test_type=BMAT&is_free=${this.state.isFree ? 'true' : 'false'}`,
                // url: `${api_base_url}/api/test/random?test_type=BMAT&is_free=false`,
                headers: {
                    "Authorization": "bearer " + JSON.parse(localStorage.getItem('user')).token
                }
            });

            const part1Obj = await testObj.data.test.parts.filter(part => part.part === 1)[0]

            let tempSectionArr = [];
            let tempPartArr = [];
            await testObj.data.test.parts.map((part, index) => {
                tempSectionArr = [];

                part.sections.map((section) => {
                    tempSectionArr.push({
                        section_id: section.section_id,
                        section: section.section,
                        question_type: section.question_type,
                        answers: {},
                        total_question: section.total_question,
                    });
                });

                tempPartArr.push({
                    part: part.part,
                    part_type: part.part_type,
                    version: part.version,
                    title: part.title,
                    description: part.description,
                    sections: tempSectionArr,
                    total_question: part.total_question,
                })
            })

            await this.setState({
                test_id: testObj.data.test.test_id,
                log_id: testObj.data.log_id,
                testObj: testObj.data.test,
                currentPart: 1,
                currentSection: 1,
                currentQuestion: 1,
                totalSection: part1Obj.sections.length,
                totalPart: testObj.data.test.parts.length,
                totalQuestion: part1Obj.total_question,
                totalQuestionArr: this.state.totalQuestionArr,
                answerObj: {
                    test_id: testObj.data.test.test_id,
                    parts: tempPartArr
                }
            });

            await this.handlePrepareQuestionArr();
            await this.handleCreateTestLog();
            
            this.handlDonePurchasedTest();
            this.updateSession();
            this.handleUpdateTestLog();

            this.handleCloseSectionOneInfoPage();
            this.handleCloseLoading();

        } catch (error) {
            this.handleCloseLoading();
            console.error(error);
        }
    }

    handlePrepareQuestionArr = () => {
        this.setState({
            totalQuestionArr: []
        });

        for (let i = 0; i < this.state.totalQuestion; i++) {
            this.state.totalQuestionArr[i] = i;
        }

        this.setState({
            totalQuestionArr: this.state.totalQuestionArr
        });
    }

    //====================================================== TEST RESUME ============================================================
    handleResumeTest = async () => {
        const logObj = await this.handleGetLogObjById();

        try {
            this.handleLoading();

            const resObj = await axios({
                method: 'get',
                url: `${api_base_url}/api/test?test_id=${logObj.test_id}`,
                headers: {
                    "Authorization": "bearer " + JSON.parse(localStorage.getItem('user')).token
                }
            });

            const testObj = resObj.data[0];
            const part1Obj = await testObj.parts.filter(part => part.part === 1)[0]

            await logObj.answerObj.parts.map((part, partIdx) => {
                part.sections.map((section, secIdx) => {
                    if (section.answers === undefined) {
                        logObj.answerObj.parts[partIdx].sections[secIdx].answers = {};
                    }
                });
            })

            await this.setState({
                test_id: logObj.test_id,
                log_id: logObj.log_id,
                testObj: testObj,
                currentPart: 1,
                currentSection: 1,
                currentQuestion: 1,
                totalSection: part1Obj.sections.length,
                totalPart: testObj.parts.length,
                totalQuestion: part1Obj.total_question,
                totalQuestionArr: this.state.totalQuestionArr,
                answerObj: logObj.answerObj,
                timer: logObj.timer,
                timerSeconds: logObj.timerSeconds
            });

            await this.handlePrepareQuestionArr();
            await this.handleUpdateTestLog();

            this.handleCloseInfoPage();
            this.handleCloseSectionOneInfoPage();
            this.handleCloseLoading();

        } catch (error) {
            console.log(error);
            this.handleCloseLoading();
        }
    }

    handleGetLogObjById = async () => {
        const oneHour = 60 * 60 * 1000;

        try {
            const responseObj = await axios({
                method: 'get',
                url: 'https://chatter-funky-busby.glitch.me/test/status/logs/' + this.state.log_id
            });

            if (((new Date()) - new Date(responseObj.data.createdAt)) > oneHour) {
                this.setState({
                    isExpiredTest: true
                });

                return;
            }

            else if (((new Date()) - new Date(responseObj.data.createdAt)) < oneHour) {
                return responseObj.data;
            }

        } catch (error) {
            console.error(error);
            return error;
        }
    }
    //====================================================== TEST RESUME ============================================================

    //====================================================== ANSWER/CHECK TEST ======================================================
    handleTrackingAnswer = (qNum, answer) => {
        this.state.answeredQuestion[qNum] = answer;
        this.state.answerObj.parts[this.state.currentPart - 1].sections[this.state.currentSection - 1].answers[qNum] = answer;

        this.setState({
            answeredQuestion: this.state.answeredQuestion,
            answerObj: this.state.answerObj
        });

        console.log(this.state.answerObj.parts, Object.keys(this.state.answerObj?.parts[this.state.currentPart - 1]?.sections[0].answers).length);
    }

    handleSubmitTest = async () => {
        const { history } = this.props;
        this.handleLoading();
        this.handleCloseDialog();

        try {
            await this.state.answerObj.parts.map((part, partIdx) => {
                part.sections.map((section, secIdx) => {
                    for (let i = 0; i < section.total_question; i++) {
                        if (this.state.answerObj.parts[partIdx].sections[secIdx].answers[i + 1] === undefined) {
                            this.state.answerObj.parts[partIdx].sections[secIdx].answers[i + 1] = "temp";
                        }
                    }
                });
            });

            const res = await axios({
                method: 'post',
                url: "https://soapy-west-helium.glitch.me/api/test/bmat/report",
                headers: {
                    "Authorization": `Bearer ${JSON.parse(localStorage.getItem('user')).token}`
                },
                data: this.state.answerObj
            });

            const resultObj = await axios({
                method: 'post',
                url: api_base_url + "/api/test/result",
                headers: {
                    Authorization: "bearer " + JSON.parse(localStorage.getItem('user')).token
                },
                data: {
                    test_type: "BMAT",
                    test_result: res.data,
                    test_id: res.data.test_id,
                    username: JSON.parse(localStorage.getItem('user')).username
                }
            });

            this.setState({
                resultObj: resultObj
            });

            clearInterval(this.state.myInterval);
            await this.handleEndTest();
            await this.props.handleEndTest();
            await this.clearAllInterval();
            localStorage.setItem('bmatSessionObj', null);
            localStorage.setItem('bmatWritingSessionObj', null);
            history.push(`/test/evaluation?test-type=BMAT&test-id=${this.state.testObj.test_id}&report-id=${resultObj.data.result_id}`);
            this.handleCloseLoading();
        } catch (err) {
            console.error(err);
            this.props.handleEndTest();
        }

    }

    handleSubmitTestWithWriting = async () => {
        try {
            this.handleLoading();
            this.handleCloseDialog();

            await this.state.answerObj.parts.map((part, partIdx) => {
                part.sections.map((section, secIdx) => {
                    for (let i = 0; i < section.total_question; i++) {
                        if (this.state.answerObj.parts[partIdx].sections[secIdx].answers[i + 1] === undefined) {
                            this.state.answerObj.parts[partIdx].sections[secIdx].answers[i + 1] = "temp";
                        }
                    }
                });
            });

            const res = await axios({
                method: 'post',
                url: "https://soapy-west-helium.glitch.me/api/test/bmat/report",
                headers: {
                    "Authorization": `Bearer ${JSON.parse(localStorage.getItem('user')).token}`
                },
                data: this.state.answerObj
            });

            if (this.state.isFree) {
                const resultObj = await axios({
                    method: 'post',
                    url: api_base_url + "/api/test/result",
                    headers: {
                        Authorization: "bearer " + JSON.parse(localStorage.getItem('user')).token
                    },
                    data: {
                        test_type: "BMAT",
                        test_result: res.data,
                        test_id: res.data.test_id,
                        username: JSON.parse(localStorage.getItem('user')).username
                    }
                });

                return resultObj;
            } else if (!this.state.isFree) {
                const resultObj = await axios({
                    method: 'post',
                    url: api_base_url + "/api/test/result",
                    headers: {
                        Authorization: "bearer " + JSON.parse(localStorage.getItem('user')).token
                    },
                    data: {
                        test_type: "BMAT",
                        test_result: res.data,
                        test_id: res.data.test_id,
                        username: JSON.parse(localStorage.getItem('user')).username
                    }
                });

                return resultObj;
            }
        } catch (err) {
            console.error(err);
            this.props.handleEndTest();
        }

    }

    handleSubmitWritingTest = async (testObj, currentTestIndex, answer, resultObj) => {
        try {
            this.handleLoading();
            this.handleCloseDialog();
            const { history } = this.props;

            //get table html
            const documentDefinition = {
                content: [
                    {
                        columns: [
                            [
                                {
                                    text: 'BMAT Writing',
                                    fontSize: 18,
                                    color: "#1b0b7a",
                                },
                                {
                                    text: `Test date: ${moment.utc().format('dddd Do MMM YYYY')}`, margin: [0, 20, 0, 0]
                                }
                                ,
                                {
                                    text: `Student name: ${JSON.parse(localStorage.getItem("user")).name}`
                                }
                            ],
                            [
                                {
                                    image: 'image1',
                                    width: 30,
                                    height: 30,
                                    style: 'image',
                                }
                            ],
                        ]
                    },
                    {
                        text: "Task",
                        bold: true
                    },
                    {
                        text: testObj[currentTestIndex]?.versions?.context,
                        margin: [0, 10, 0, 0],
                        bold: true
                    },
                    {
                        text: testObj[currentTestIndex]?.versions?.question,
                        margin: [0, 10, 0, 0]
                    },
                    {
                        text: 'Answer',
                        margin: [0, 10, 0, 0],
                        bold: true
                    },
                    {
                        text: answer,
                        margin: [0, 5, 0, 0]
                    },
                ],
                images: {
                    image1: "https://i.ibb.co/sKFnZXp/U-Test-Logo-new-square.png"
                },
                styles: {
                    image: {
                        alignment: 'right'
                    }
                }
            };

            pdfMake.vfs = pdfFonts.pdfMake.vfs;
            const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
            pdfDocGenerator.getBlob(async (blob) => {
                let formData = new FormData();
                await formData.append("test_id", this.state.testObj.test_id);
                await formData.append("result_id", resultObj.result_id);
                await formData.append("submitter", JSON.parse(localStorage.getItem("user")).name);
                await formData.append("submitterUsername", JSON.parse(localStorage.getItem("user")).username);
                await formData.append("testCategory", "BMAT");
                await formData.append("testType", "Writing");
                await formData.append("assignTo", "Unassigned");
                await formData.append("tags", "BMAT");
                await formData.append("tags", "Writing");
                await formData.append("tags", "Full");
                await formData.append("isUpdate", "false");
                await formData.append("createdAt", Date.now());
                await formData.append(
                    "submittedFile",
                    blob,
                    `${JSON.parse(localStorage.getItem("user")).name}_${Date.now()}.pdf`
                );

                this.setState({
                    filePrepared: true,
                });

                await axios({
                    method: "post",
                    url: "https://chatter-funky-busby.glitch.me/tests/submit",
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                    data: formData,
                });
            });

            clearInterval(this.state.myInterval);
            await this.handleEndTest();
            await this.props.handleEndTest();
            await this.clearAllInterval();
            localStorage.setItem('bmatSessionObj', null);
            localStorage.setItem('bmatWritingSessionObj', null);
            history.push(`/test/evaluation?test-type=BMAT&test-id=${this.state.testObj.test_id}&report-id=${resultObj.result_id}`);
            this.handleCloseLoading();
        } catch (err) {
            console.log(err);
        }
    };

    handleOpenConfirmSubmitTest = () => {
        this.setState({
            isConfirmSubmitTest: true
        });
    }

    handleOpenConfirmSubmitSection = () => {
        this.setState({
            isConfirmSubmitSection: true
        });
    }

    handleSubmitSection = () => {
        this.handleNextQuestion();
        this.handleCloseDialog();
    }
    //====================================================== ANSWER/CHECK TEST ======================================================

    //====================================================== TEST SESSION MANAGEMENT ================================================
    handleCreateTestLog = async () => {
        try {
            const responseObj = await axios({
                method: 'post',
                url: 'https://chatter-funky-busby.glitch.me/test/status/log',
                data: {
                    username: JSON.parse(localStorage.getItem('user')).username,
                    test_id: this.state.test_id,
                    log_id: this.state.log_id,
                    test_type: 'BMAT',
                    test_part: 'BMAT',
                    answerObj: this.state.answerObj,
                    answeredQuestion: this.state.answeredQuestion,
                    timer: this.state.timer,
                    timerSeconds: this.state.timerSeconds
                }
            })
        } catch (error) {
            console.error(error);
        }
    }

    handleUpdateTestLog = () => {
        const myInterval = setInterval(() => {
            if (this.props.isTest) {
                axios({
                    method: 'put',
                    url: 'https://chatter-funky-busby.glitch.me/test/status/log',
                    data: {
                        log_id: this.state.log_id,
                        answerObj: this.state.answerObj,
                        answeredQuestion: this.state.answeredQuestion,
                        timer: this.state.timer,
                        timerSeconds: this.state.timerSeconds
                    }
                });
            }
        }, 60000);

        this.setState({
            myInterval: myInterval
        });
    }

    handleEndTest = () => {
        try {
            axios({
                method: 'get',
                url: "https://chatter-funky-busby.glitch.me/test/status/logs/" + this.state.log_id + "/done"
            });
        } catch (error) {
            console.error(error);
        }
    }
    //====================================================== TEST SESSION MANAGEMENT ================================================
    
    handleGotoQuestion = (qNum) => {
        this.setState({
            currentQuestion: qNum
        });
    }

    handleNextQuestion = async () => {
        if (this.state.currentQuestion === this.state.totalQuestion &&
            this.state.currentSection === this.state.totalSection &&
            this.state.currentPart < this.state.totalPart) {

            if (this.state.currentPart === 2) {
                this.handleOpenSectionThreeInfoPage();
            } else {
                this.handleOpenSectionTwoInfoPage();
            }

            this.updateSession();
            return;
        }

        else if (this.state.currentQuestion === this.state.totalQuestion && this.state.currentSection < this.state.totalSection) {
            this.setState({
                isInfoSectionTwoPage: false,
                currentQuestion: 1,
                currentSection: this.state.currentSection + 1,
            });

            this.updateSession();
            return;
        }

        else if (this.state.currentQuestion === this.state.totalQuestion && this.state.currentSection === this.state.totalSection) {
            return;
        }

        this.setState({
            currentQuestion: this.state.currentQuestion + 1
        });
    }

    handleBackQuestion = async () => {
        if (this.state.currentQuestion === 1 && this.state.currentSection === 1 && this.state.currentPart === 1) {
            return;
        }

        else if (this.state.currentQuestion === 1 && this.state.currentSection !== 1) {
            this.setState({
                totalQuestion: this.state.testObj.parts[this.state.currentPart - 1].total_question,
                currentQuestion: this.state.testObj.parts[this.state.currentPart - 1].sections[this.state.currentSection - 2].total_question,
                currentSection: this.state.currentSection - 1
            });

            this.updateSession();
            return;
        }

        else if (this.state.currentQuestion === 1 && this.state.currentSection === 1 && this.state.currentPart !== 1) {
            this.handleLoading();
            await this.setState({
                totalQuestion: this.state.testObj.parts[this.state.currentPart - 2].total_question,
                currentQuestion: this.state.testObj.parts[this.state.currentPart - 2].total_question,
                currentSection: this.state.testObj.parts[this.state.currentPart - 2].sections.length,
                currentPart: this.state.currentPart - 1,
            });

            await this.handlePrepareQuestionArr();

            this.handleCloseLoading();
            return;
        }

        this.updateSession();
        this.setState({
            currentQuestion: this.state.currentQuestion - 1
        });
    }

    handleOpenDialog = () => {
        this.setState({
            isConfirmBackHome: true
        });
    }

    handleCloseDialog = () => {
        this.setState({
            isConfirmBackHome: false,
            isConfirmSubmitTest: false,
            isConfirmSubmitSection: false
        });
    }

    handleOpenInfoPage = () => {
        this.setState({
            isInfoPage: true
        });
    }

    handleOpenWriting = () => {
        this.setState({
            isWriting: true,
            isInfoSectionOnePage: false,
            isInfoSectionTwoPage: false
        });
    }

    handleOpenSectionOneInfoPage = () => {
        this.setState({
            isInfoSectionOnePage: true
        });
    }

    handleOpenSectionTwoInfoPage = () => {
        this.setState({
            isInfoSectionTwoPage: true
        });
    }

    handleOpenSectionThreeInfoPage = () => {
        this.setState({
            isInfoSectionThreePage: true
        });
    }

    handleCloseSectionThreeInfoPage = () => {
        this.setState({
            isInfoSectionThreePage: false
        });

        this.handleOpenWriting();

        if (!this.state.isSetSectionThreeTimer) {
            this.setState({
                timer: 1800,
                isSetSectionThreeTimer: true
            });
        }
    }

    handleCloseSectionTwoInfoPage = async () => {
        this.handleLoading();
        await this.setState({
            totalQuestion: this.state.testObj.parts[this.state.currentPart].total_question,
            currentQuestion: 1,
            currentSection: 1,
            currentPart: this.state.currentPart + 1,
            isInfoSectionTwoPage: false,
        });

        if (!this.state.isSetSectionTwoTimer) {
            this.setState({
                timer: 1800,
                isSetSectionTwoTimer: true
            });
        }

        await this.handlePrepareQuestionArr();

        this.handleCloseLoading();
    }

    handleStartWriting = async () => {
        if (!this.state.isSetSectionThreeTimer) {
            this.setState({
                timer: 1800,
                isSetSectionThreeTimer: true
            });
        }
    }

    handleCloseInfoPage = () => {
        this.setState({
            isInfoPage: false
        });

        this.handleOpenSectionOneInfoPage();
    }

    //========================================= TIME MANAGEMENT ========================================
    handleCloseSectionOneInfoPage = () => {
        this.setState({
            isInfoSectionOnePage: false
        });

        this.startTimer();
    }

    startTimer = () => {
        this.myInterval = setInterval(() => {
            if (this.state.timer > 0) {
                if (this.state.timerSeconds === 0) {
                    this.setState({
                        timerSeconds: 60
                    })
                };

                this.setState(prevState => ({
                    timer: prevState.timer - 1,
                    timerSeconds: this.state.timerSeconds - 1
                }))
            }

            this.updateSession();
        }, 1000);
    }

    handleAddLeadingZero = (num) => {
        if (num < 10) {
            return '0' + num
        } else if (num >= 10) {
            return '' + num
        }
    }
    //========================================= TIME MANAGEMENT ========================================

    //======================== BACKDROP CONTROL ==============================
    handleCloseLoading = () => {
        this.setState({
            isLoading: false
        });
    }

    handleLoading = () => {
        this.setState({
            isLoading: true
        });
    }
    //======================== BACKDROP CONTROL ===============================

    onClickBackHome = () => {
        this.clearAllInterval();

        localStorage.setItem('bmatSessionObj', null);
        localStorage.setItem('bmatWritingSessionObj', null);

        this.props.handleEndTest();
    }

    clearAllInterval = () => {
        const interval_id = window.setInterval(function () { }, Number.MAX_SAFE_INTEGER);

        // Clear any timeout/interval up to that id
        for (let i = 1; i < interval_id; i++) {
            window.clearInterval(i);
        }
    }

    render() {
        const { classes } = this.props;

        return (
            <ThemeProvider theme={theme}>
                <Container maxWidth={false} style={{ flex: 1, width: '100%', alignItems: "stretch", paddingLeft: '0px', paddingRight: '0px', zoom: '80%', overflow: "hidden" }}>
                    {this.state.isInfoPage &&
                        <Grid container>
                            <UserInfoPage handleCloseInfoPage={this.handleCloseInfoPage} handleGetBMATTest={this.handleGetBMATTest} handleEndTest={this.props.handleEndTest} />
                        </Grid>
                    }

                    {this.state.isInfoSectionOnePage &&
                        <Grid container>
                            {this.state.isFree ? <SectionOneInstructions handleGetBMATTest={this.handleGetBMATTest} /> : <FullSectionOneInstructions handleGetBMATTest={this.handleGetBMATTest} />}
                        </Grid>
                    }

                    {this.state.isInfoSectionTwoPage &&
                        <Grid container>
                            {this.state.isFree ? <SectionTwoInstructions handleCloseSectionTwoInfoPage={this.handleCloseSectionTwoInfoPage} /> : <FullSectionTwoInstructions handleCloseSectionTwoInfoPage={this.handleCloseSectionTwoInfoPage} />}
                        </Grid>
                    }

                    {this.state.isInfoSectionThreePage &&
                        <Grid container>
                            {!this.state.isFree && <FullSectionThreeInstructions handleCloseSectionThreeInfoPage={this.handleCloseSectionThreeInfoPage} />}
                        </Grid>
                    }

                    {!this.state.isInfoPage && !this.state.isInfoSectionOnePage && !this.state.isInfoSectionTwoPage && !this.state.isInfoSectionThreePage &&
                        <Grid container>
                            <Grid item xs={12} style={{ backgroundColor: '#214b4b' }}>
                                <Grid container style={{ padding: '20px' }}>
                                    <Grid item align='left' xs>
                                        {this.state.testObj !== undefined &&
                                            this.state.isWriting ? <Typography variant="h4" style={{ color: 'white' }}>
                                                TBAT Section 3 - WRITING <span style={{ fontSize: 8, color: "#DDDDDD" }}> {btoa(this.props.user.username)}</span>
                                            </Typography> : <Typography variant="h4" style={{ color: 'white' }}>
                                            TBAT Section {this.state.testObj.parts[this.state.currentPart - 1].part} - {this.state.testObj.parts[this.state.currentPart - 1].part_type} <span style={{ fontSize: 8, color: "#DDDDDD" }}> {btoa(this.props.user.username)}</span>
                                            </Typography>
                                        }
                                    </Grid>
                                    <Grid item align='center' style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center"
                                    }}>
                                        <Typography variant="subtitle1" style={{ color: 'white', paddingRight: "5px" }}>
                                            {this.handleAddLeadingZero(Math.floor(this.state.timer / 3600))} : {this.state.timer === 3600 ? '00' : this.handleAddLeadingZero(Math.floor(this.state.timer / 60) >= 60 ? Math.floor(this.state.timer / 60) - 60 : Math.floor(this.state.timer / 60))} : {this.handleAddLeadingZero(this.state.timerSeconds)}
                                        </Typography>
                                    </Grid>
                                    <Grid item align='center' style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center"
                                    }}>
                                        <AccessAlarmsRoundedIcon style={{ color: 'white' }} />
                                    </Grid>
                                    <Grid item align='center'>
                                        <IconButton aria-label="home-button" onClick={this.handleOpenDialog}>
                                            <HomeRoundedIcon style={{ color: 'white' }} />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} style={{
                                paddingTop: '30px',
                                paddingBottom: '100%',
                                backgroundImage: `url("/U-Test-Logo-new-bw.png")`,
                                backgroundSize: '200px',
                                backgroundRepeat: 'space'
                            }}>
                                {this.state.testObj !== undefined && !this.state.isWriting &&
                                    <Container>
                                        <Grid item align='left' xs={12}>
                                            <MultipleChoices
                                                key={this.state.currentPart + '-' + this.state.currentSection + '-' + this.state.currentQuestion}
                                                questionObj={this.state.testObj.parts[this.state.currentPart - 1].sections[this.state.currentSection - 1].questions[this.state.currentQuestion - 1]}
                                                answeredQuestion={this.state.answeredQuestion}
                                                handleTrackingAnswer={this.handleTrackingAnswer}
                                            />
                                        </Grid>
                                    </Container>
                                }
                                {this.state.isWriting &&
                                    <Grid container>
                                        <FullWritingSelection handleStartWriting={this.handleStartWriting} testObj={this.state.testObj} handleSubmitTestWithWriting={this.handleSubmitTestWithWriting} handleSubmitWritingTest={this.handleSubmitWritingTest} />
                                    </Grid>
                                }
                            </Grid>
                            {!this.state.isWriting &&
                                <Grid item xs={12} style={{ position: 'absolute', bottom: 0, width: '100%' }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={10} align='center' style={{ paddingLeft: '20px' }}>
                                            <Paper variant="outlined">
                                                {this.state.totalQuestionArr.map((question, index) =>
                                                    <Button
                                                        size="small"
                                                        variant={this.state.answerObj?.parts[this.state.currentPart - 1]?.sections[this.state.currentSection - 1]?.answers[index + 1] === undefined ? "outlined" : "contained"}
                                                        onClick={() => { this.handleGotoQuestion(index + 1) }}
                                                        style={{
                                                            backgroundColor: this.state.answerObj?.parts[this.state.currentPart - 1]?.sections[this.state.currentSection - 1]?.answers[index + 1] === undefined ? "" : "#214b4b",
                                                            color: this.state.answerObj?.parts[this.state.currentPart - 1]?.sections[this.state.currentSection - 1]?.answers[index + 1] === undefined ? "#214b4b" : "white",
                                                            maxWidth: '25px', maxHeight: '25px', minWidth: '25px', minHeight: '25px', margin: '0.3%',
                                                            borderColor: '#214b4b'
                                                        }}
                                                    >
                                                        {index + 1}
                                                    </Button>
                                                )}
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={2} align='left' style={{ width: '100%' }}>
                                            <Grid container>
                                                <Paper variant="outlined">
                                                    <Grid item xs={12} style={{ padding: '1px' }}>
                                                        <ButtonGroup size="large" variant="text" aria-label="outlined primary button group" >
                                                            {!(this.state.currentQuestion === 1 && this.state.currentSection === 1)
                                                                && <Button onClick={this.handleBackQuestion} style={{ color: '#214b4b' }}>Back</Button>}
                                                            {!(this.state.currentQuestion === this.state.totalQuestion && this.state.currentSection === this.state.totalSection)
                                                                && <Button onClick={this.handleNextQuestion} style={{ color: '#214b4b' }}>Next</Button>}
                                                            {(this.state.currentQuestion === this.state.totalQuestion && this.state.currentSection === this.state.totalSection && this.state.currentPart < this.state.totalPart)
                                                                && <Button onClick={this.handleOpenConfirmSubmitSection} style={{ color: 'white', backgroundColor: '#214b4b', borderColor: '#214b4b' }}>Submit</Button>}
                                                            {(this.state.currentQuestion === this.state.totalQuestion && this.state.currentSection === this.state.totalSection && this.state.currentPart === this.state.totalPart)
                                                                && <Button onClick={this.handleOpenConfirmSubmitTest} style={{ color: 'white', backgroundColor: '#214b4b', borderColor: '#214b4b' }}>Submit</Button>}
                                                        </ButtonGroup>
                                                    </Grid>
                                                </Paper>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>}
                        </Grid>
                    }

                    <PageBackdrop isLoading={this.state.isLoading} />

                    <Dialog
                        open={this.state.isValidated !== undefined ? !this.state.isValidated : false}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"This test session is invalid"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Something went wrong with your purchased test.
                                Please check that this purchased test still valid.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Link
                                to={'/'}
                                color="inherit"
                                underline="none"
                                style={{ textDecoration: 'none', color: '#565656' }}
                            >
                                <Button onClick={this.props.handleEndTest} color="primary" autoFocus>
                                    Back Home
                                </Button>
                            </Link>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        open={this.state.isConfirmSubmitTest}
                        onClose={this.handleCloseDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{(this.state.answerObj?.parts[this.state.currentPart - 1]?.sections[0]?.answers && (this.state.answerObj?.parts[this.state.currentPart - 1]?.total_question > Object.keys(this.state.answerObj?.parts[this.state.currentPart - 1]?.sections[0]?.answers).length))
                            ? "You have not done all questions. Are you sure to submit the test?"
                            : "Are you sure to submit the test?"}</DialogTitle>
                        <DialogContent>
                            {(this.state.answerObj?.parts[this.state.currentPart - 1]?.sections[0]?.answers && (this.state.answerObj?.parts[this.state.currentPart - 1]?.total_question > Object.keys(this.state.answerObj?.parts[this.state.currentPart - 1]?.sections[0].answers).length)) ? <DialogContentText id="alert-dialog-description">
                                Some of the questions has not been answered. Are you sure to submit a test?
                                Please click <Box fontWeight="fontWeightBold" display='inline'>Submit</Box> to submit a test.
                            </DialogContentText> : <DialogContentText id="alert-dialog-description">
                                    Are you sure to submit a test?
                                Please click <Box fontWeight="fontWeightBold" display='inline'>Submit</Box> to submit a test.
                            </DialogContentText>}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleCloseDialog} color="primary" style={{ backgroundColor: "#a6a6a6", color: "white", textTransform: "none" }}>
                                Cancel
                            </Button>
                            <Link
                                color="inherit"
                                underline="none"
                                style={{ textDecoration: 'none', color: '#565656' }}
                            >
                                <Button onClick={this.handleSubmitTest} color="primary" style={{ backgroundColor: "#214b4b", color: "white", textTransform: "none" }} autoFocus>
                                    Submit
                                </Button>
                            </Link>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        open={this.state.isConfirmSubmitSection}
                        onClose={this.handleCloseDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{(this.state.answerObj?.parts[this.state.currentPart - 1]?.sections[0]?.answers && (this.state.answerObj?.parts[this.state.currentPart - 1]?.total_question > Object.keys(this.state.answerObj?.parts[this.state.currentPart - 1]?.sections[0].answers).length))
                            ? `You have not done all questions. Are you sure to submit TBAT Section ${this.state.currentPart}?`
                            : `Are you sure to submit TBAT Section ${this.state.currentPart}?`}</DialogTitle>
                        <DialogContent>
                            {(this.state.answerObj?.parts[this.state.currentPart - 1]?.sections[0]?.answers && (this.state.answerObj?.parts[this.state.currentPart - 1]?.total_question > Object.keys(this.state.answerObj?.parts[this.state.currentPart - 1]?.sections[0].answers).length)) ? <DialogContentText id="alert-dialog-description">
                                Some of the questions has not been answered. Are you sure to submit this TBAT Section {this.state.currentPart}?
                                Please click <Box fontWeight="fontWeightBold" display='inline'>Submit</Box> to submit and move to TBAT Section {this.state.currentPart + 1}.
                            </DialogContentText> : <DialogContentText id="alert-dialog-description">
                                    Are you sure to submit this TBAT Section {this.state.currentPart}?
                                Please click <Box fontWeight="fontWeightBold" display='inline'>Submit</Box> to submit and move to TBAT Section {this.state.currentPart + 1}.
                            </DialogContentText>}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleCloseDialog} color="primary" style={{ backgroundColor: "#a6a6a6", color: "white", textTransform: "none" }}>
                                Cancel
                            </Button>
                            <Link
                                color="inherit"
                                underline="none"
                                style={{ textDecoration: 'none', color: '#565656' }}
                            >
                                <Button onClick={this.handleSubmitSection} color="primary" style={{ backgroundColor: "#214b4b", color: "white", textTransform: "none" }} autoFocus>
                                    Submit
                                </Button>
                            </Link>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        open={this.state.isConfirmBackHome}
                        onClose={this.handleCloseDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"Are you sure to back to home page?"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Your test is not completed yet. Are you sure to go back to home page?
                                Please click <Box fontWeight="fontWeightBold" display='inline'>Confirm</Box> to get back to home page.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleCloseDialog} color="primary" style={{ backgroundColor: "#a6a6a6", color: "white", textTransform: "none" }}>
                                Cancel
                            </Button>
                            <Link
                                to={'/'}
                                color="inherit"
                                underline="none"
                                style={{ textDecoration: 'none', color: '#565656' }}
                            >
                                <Button onClick={this.onClickBackHome} color="primary" style={{ backgroundColor: "#214b4b", color: "white", textTransform: "none" }} autoFocus>
                                    Confirm
                                </Button>
                            </Link>
                        </DialogActions>
                    </Dialog>
                </Container>
            </ThemeProvider>
        )
    }
}

export default compose(
    withStyles(useStyles)
)(MainBMATTestPage);
