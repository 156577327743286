import React, { useEffect } from 'react';

//MUI
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';

import { ThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';

//Functions
import { RoundBand } from '../../../../../functions/testReportFuntions';

//Font
import 'typeface-glacial-indifference';

//Others
const axios = require('axios').default;
const FormData = require('form-data');
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

const useStyles = theme => ({
    root: {
        padding: theme.spacing(12, 4),
    }
});

const theme = createMuiTheme({
    typography: {
        fontFamily: [
            'Roboto'
        ].join(','),
    },
});

const OverallScore = (props) => {
    useEffect(async () => {
        console.log(props.speakingNotesObj);
        console.log(props.isGotWritingResult, props.writingNotesObj)
    }, []);

    const calculateOverallBand = () => {
        const listeningScore = props.reportObj?.test_result?.score?.listening?.score;
        const readingScore = props.reportObj?.test_result?.score?.reading?.score;
        const speakingScore = props.isGotSpeakingResult ? props.speakingNotesObj?.note?.score : 0;
        const writingScore = props.isGotWritingResult ? props.writingNotesObj?.note?.score : 0;
        const overallBand = RoundBand((Number(listeningScore) + Number(readingScore) + Number(writingScore) + Number(speakingScore)) / (2 + (props.isGotSpeakingResult ? 1 : 0) + (props.isGotWritingResult ? 1 : 0)));

        return overallBand;
    }

    const mobileUI = (<Grid container>
        <Grid item xs={12} style={{
            backgroundColor: "#42647d",
            borderTopLeftRadius: "15px",
            borderTopRightRadius: "15px",
            padding: '5px'
        }}>
            <Typography variant="h4" style={{ color: "white" }}>
                Overall band score
            </Typography>
        </Grid>
        <Grid item xs={12} style={{
            padding: "5%",
            border: "1px solid #42647d"
        }}>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={12} style={{
                            backgroundColor: "#42647d",
                            borderTopLeftRadius: "5px",
                            borderTopRightRadius: "5px"
                        }}>
                            <Typography variant="subtitle1" style={{ color: "white" }}>
                                Listening
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{
                            padding: "5%",
                            border: "1px solid #42647d",
                            borderBottomLeftRadius: "5px",
                            borderBottomRightRadius: "5px"
                        }}>
                            <Typography variant="subtitle1" style={{ color: "#25333e", fontWeight: "bold" }}>
                                {props.isLoading ? <Skeleton /> : props.reportObj?.test_result?.score?.listening?.score}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={12} style={{
                            backgroundColor: "#42647d",
                            borderTopLeftRadius: "5px",
                            borderTopRightRadius: "5px"
                        }}>
                            <Typography variant="subtitle1" style={{ color: "white" }}>
                                Reading
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{
                            padding: "5%",
                            border: "1px solid #42647d",
                            borderBottomLeftRadius: "5px",
                            borderBottomRightRadius: "5px"
                        }}>
                            <Typography variant="subtitle1" style={{ color: "#25333e", fontWeight: "bold" }}>
                                {props.isLoading ? <Skeleton /> : props.reportObj?.test_result?.score?.reading?.score}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={12} style={{
                            backgroundColor: "#42647d",
                            borderTopLeftRadius: "5px",
                            borderTopRightRadius: "5px"
                        }}>
                            <Typography variant="subtitle1" style={{ color: "white" }}>
                                Writing
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{
                            padding: "5%",
                            border: "1px solid #42647d",
                            borderBottomLeftRadius: "5px",
                            borderBottomRightRadius: "5px"
                        }}>
                            <Typography variant="subtitle1" style={{ color: "#25333e", fontWeight: "bold" }}>
                                {props.isLoading ? <Skeleton /> : props.isGotWritingResult ? props.writingNotesObj?.note?.score : "-"}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={12} style={{
                            backgroundColor: "#42647d",
                            borderTopLeftRadius: "5px",
                            borderTopRightRadius: "5px"
                        }}>
                            <Typography variant="subtitle1" style={{ color: "white" }}>
                                Speaking
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{
                            padding: "5%",
                            border: "1px solid #42647d",
                            borderBottomLeftRadius: "5px",
                            borderBottomRightRadius: "5px"
                        }}>
                            <Typography variant="subtitle1" style={{ color: "#25333e", fontWeight: "bold" }}>
                                {props.isLoading ? <Skeleton /> : props.isGotSpeakingResult ? props.speakingNotesObj?.note?.score : "-"}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs={12}>
            <Grid container>
                <Grid item xs={4} style={{
                    backgroundColor: "#42647d",
                    borderBottomLeftRadius: "15px",
                    padding: "15px 0 15px"
                }}>
                    <Typography variant="subtitle1" style={{ color: "white" }}>
                        Overall
                    </Typography>
                </Grid>
                <Grid item xs={8} style={{
                    borderBottom: "1px solid #42647d",
                    borderRight: "1px solid #42647d",
                    borderBottomRightRadius: "15px"
                }}>
                    <Typography variant="h2" style={{
                        color: "#25333e"
                    }}>
                        {props.isLoading ? <Skeleton /> : calculateOverallBand()}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    </Grid>)

    const webUI = (<Grid container>
        <Grid item xs={12} style={{
            backgroundColor: "#42647d",
            borderTopLeftRadius: "15px",
            borderTopRightRadius: "15px",
            padding: '5px'
        }}>
            <Typography variant="h5" style={{ color: "white" }}>
                Overall band score
            </Typography>
        </Grid>
        <Grid item xs={12} style={{
            padding: "5%",
            border: "1px solid #42647d"
        }}>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={12} style={{
                            backgroundColor: "#42647d",
                            borderTopLeftRadius: "5px",
                            borderTopRightRadius: "5px"
                        }}>
                            <Typography variant="subtitle2" style={{ color: "white" }}>
                                Listening
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{
                            padding: "5%",
                            border: "1px solid #42647d",
                            borderBottomLeftRadius: "5px",
                            borderBottomRightRadius: "5px"
                        }}>
                            <Typography variant="subtitle2" style={{ color: "#25333e", fontWeight: "bold" }}>
                                {props.isLoading ? <Skeleton /> : props.reportObj?.test_result?.score?.listening?.score}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={12} style={{
                            backgroundColor: "#42647d",
                            borderTopLeftRadius: "5px",
                            borderTopRightRadius: "5px"
                        }}>
                            <Typography variant="subtitle2" style={{ color: "white" }}>
                                Reading
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{
                            padding: "5%",
                            border: "1px solid #42647d",
                            borderBottomLeftRadius: "5px",
                            borderBottomRightRadius: "5px"
                        }}>
                            <Typography variant="subtitle2" style={{ color: "#25333e", fontWeight: "bold" }}>
                                {props.isLoading ? <Skeleton /> : props.reportObj?.test_result?.score?.reading?.score}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={12} style={{
                            backgroundColor: "#42647d",
                            borderTopLeftRadius: "5px",
                            borderTopRightRadius: "5px"
                        }}>
                            <Typography variant="subtitle2" style={{ color: "white" }}>
                                Writing
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{
                            padding: "5%",
                            border: "1px solid #42647d",
                            borderBottomLeftRadius: "5px",
                            borderBottomRightRadius: "5px"
                        }}>
                            <Typography variant="subtitle2" style={{ color: "#25333e", fontWeight: "bold" }}>
                                {props.isLoading ? <Skeleton /> : props.isGotWritingResult ? props.writingNotesObj?.note?.score : "-"}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={12} style={{
                            backgroundColor: "#42647d",
                            borderTopLeftRadius: "5px",
                            borderTopRightRadius: "5px"
                        }}>
                            <Typography variant="subtitle2" style={{ color: "white" }}>
                                Speaking
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{
                            padding: "5%",
                            border: "1px solid #42647d",
                            borderBottomLeftRadius: "5px",
                            borderBottomRightRadius: "5px"
                        }}>
                            <Typography variant="subtitle2" style={{ color: "#25333e", fontWeight: "bold" }}>
                                {props.isLoading ? <Skeleton /> : props.isGotSpeakingResult ? props.speakingNotesObj?.note?.score : "-"}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs={12}>
            <Grid container>
                <Grid item xs={4} style={{
                    backgroundColor: "#42647d",
                    borderBottomLeftRadius: "15px",
                    padding: "15px 0 15px"
                }}>
                    <Typography variant="subtitle1" style={{ color: "white" }}>
                        Overall
                    </Typography>
                </Grid>
                <Grid item xs={8} style={{
                    borderBottom: "1px solid #42647d",
                    borderRight: "1px solid #42647d",
                    borderBottomRightRadius: "15px"
                }}>
                    <Typography variant="h3" style={{
                        color: "#25333e"
                    }}>
                        {props.isLoading ? <Skeleton /> : calculateOverallBand()}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    </Grid>)

    return <ThemeProvider theme={theme}>
        <Hidden mdUp>
            {mobileUI}
        </Hidden>
        <Hidden smDown>
            {webUI}
        </Hidden>
    </ThemeProvider>
};

export default compose(
    withStyles(useStyles)
)(OverallScore);
