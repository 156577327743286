import React from 'react';

//Material UI
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import MuiAlert from '@material-ui/lab/Alert';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

//Question type
import YesNoNG from './AddSections/YesNoNG'
import TrueFalseNG from './AddSections/TrueFalseNG';
import MultipleChoice from './AddSections/MultipleChoice';
import NoteCompletion from './AddSections/NoteCompletion';
import ListOfHeading from './AddSections/ListOfHeading';
import MatchingSentenceEndings from './AddSections/MatchingSentenceEndings';
import CompleteTheNotes from './AddSections/CompleteTheNotes';
import CompleteTheTables from './AddSections/CompleteTheTables'
import Matching from './AddSections/Matching';
import Map from './AddSections/Map';
import Choose2Letters from './AddSections/Choose2Letters';
import WritingBaseQuestion from './AddSections/WritingBaseQuestion';

//Icons
import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';

//Models
import { CreateStrategy } from '../../../../models/strategy';
import { GetAllParts } from '../../../../models/addPart';

//Others
import { withStyles } from '@material-ui/core/styles';
import { Component } from 'react';
import { compose } from 'recompose';
import Select from 'react-select'

const axios = require('axios').default;
const FormData = require('form-data');
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

const useStyles = theme => ({
    root: {
        margin: 15,
        marginTop: 50
    },
    margin: {
        margin: theme.spacing(1),
    },
    buttonColor: {
        backgroundColor: '#76323F',
        color: 'white'
    }
});

const MainCheckbox = withStyles({
    root: {
        color: "#76323F",
        '&$checked': {
            color: "#76323F",
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class CreateNewStrategy extends Component {
    constructor() {
        super()
        this.state = {
            addedStrategyArr: [],
            addedPossibleVersion: [],
            isLoadingParts: true,
            isCreated: false,
            isLoading: false,
            is_free: false,
            isLoadingQuestionType: false
        };
    }

    componentDidMount = async () => {
        document.querySelector("body").scrollTo(0, 0);
        await this.handleSetupObj();
        this.handleGetAllSubmittedTests();
    }

    //==================================== PREPARATION =======================================
    handleSetupObj = async () => {
        await this.setState({
            strategyObj: {
                test_type: "",
                part_type: "",
                is_free: false,
                strategy_color: "",
                strategy: this.state.addedStrategyArr
            }
        })
    }

    handleSelectTestType = async (event) => {
        if (event === null) {
            this.setState({
                testType: undefined
            });
        } else if (event !== null) {
            await this.setState({
                testType: event.value.toUpperCase()
            });

            this.state.strategyObj.test_type = event.value.toUpperCase();
            this.setState({
                strategyObj: this.state.strategyObj
            });
        }
    }

    handleSelectPartType = async (event) => {
        if (event === null) {
            this.setState({
                partType: undefined,

            });
        } else if (event !== null) {
            await this.setState({
                partType: event.value.toUpperCase()
            });

            this.state.strategyObj.part_type = event.value.toUpperCase();
            this.setState({
                strategyObj: this.state.strategyObj
            });
        }
    }

    handleSelectStrategyColor = async (event) => {
        if (event === null) {
            this.setState({
                strategyColor: undefined,
            });
        } else if (event !== null) {
            await this.setState({
                strategyColor: event.value.toUpperCase()
            });

            this.state.strategyObj.strategy_color = event.value.toUpperCase();
            this.setState({
                strategyObj: this.state.strategyObj
            });
        }
    }

    handleSelectStrategyVersion = async (event, index) => {
        if (event === null) {
            this.state.addedPossibleVersion[index] = event;
            this.setState({
                addedPossibleVersion: this.state.addedPossibleVersion
            })
        } else if (event !== null) {
            this.state.addedPossibleVersion[index] = event;
            this.setState({
                addedPossibleVersion: this.state.addedPossibleVersion
            })
        }

        console.log(this.state.addedPossibleVersion)
    }

    handleCheckIsFreeTest = async (event) => {
        this.state.strategyObj.is_free = !this.state.is_free;

        await this.setState({
            strategyObj: this.state.strategyObj,
            is_free: !this.state.is_free
        });
    }

    handleLoadingQuestionType = () => {
        this.setState({
            isLoadingQuestionType: true
        });
    }

    handleEndLoadingQuestionType = () => {
        this.setState({
            isLoadingQuestionType: false
        });
    }
    //==================================== PREPARATION =======================================

    handleAddStrategy = async () => {
        await this.state.addedStrategyArr.push({
            part: undefined,
            possible_version: []
        });

        this.setState({
            addedStrategyArr: this.state.addedStrategyArr
        });
    }

    handleAddPossibleVersion = async (event, index) => {
        this.state.addedStrategyArr[index].possible_version = event.target.value.split(",");
        this.setState({
            addedStrategyArr: this.state.addedStrategyArr
        });
    }

    handleRemovePossibleVersion = async (event, index) => {
        this.state.addedStrategyArr.splice(index, 1);
        this.setState({
            addedStrategyArr: this.state.addedStrategyArr
        });
    }

    handleEditPart = async (event, index) => {
        this.state.addedStrategyArr[index].part = Number(event.target.value);
        this.setState({
            addedStrategyArr: this.state.addedStrategyArr
        });
    }

    handleGetAllSubmittedTests = async () => {
        try {
            const readingToshow = [];
            const listeningToshow = [];
            const writingToshow = [];
            const bmatToshow = [];

            this.setState({
                isLoadingParts: true
            });

            const res = await GetAllParts(JSON.parse(localStorage.getItem('user')).token);

            const resData = res.res;

            const readingArr = await resData.data.filter((part) => part.part_type === "READING");
            const listeningArr = await resData.data.filter((part) => part.part_type === "LISTENING");
            const writingArr = await resData.data.filter((part) => part.part_type === "WRITING");
            const bmatArr = await resData.data.filter((part) => part.part_type !== "READING" && part.part_type !== "LISTENING" && part.part_type !== "WRITING");

            await readingArr.map((part) => {
                readingToshow.push({
                    value: part.version,
                    label: part.version
                });
            });

            await listeningArr.map((part) => {
                listeningToshow.push({
                    value: part.version,
                    label: part.version
                });
            });

            await writingArr.map((part) => {
                writingToshow.push({
                    value: part.version,
                    label: part.version
                });
            });

            await bmatArr.map((part) => {
                bmatToshow.push({
                    value: part.version,
                    label: part.version
                });
            });

            await this.setState({
                readingToshow: readingToshow,
                listeningToshow: listeningToshow,
                writingToshow: writingToshow,
                bmatToshow: bmatToshow,
                partInfo: resData,
                isLoadingParts: false
            });
        } catch (err) {
            console.log(err);
            this.setState({
                isLoadingParts: false
            });
        }
    }

    handleCreateNewStrategy = async () => {

        if (!this.state.strategyObj.strategy_color || !this.state.strategyObj.test_type || !this.state.strategyObj.part_type) {
            alert("Please fill in all required fields");
        }

        if (this.state.addedStrategyArr.strategy_color || this.state.strategyObj.test_type || this.state.strategyObj.part_type) {
            await this.state.addedPossibleVersion.map((version, index) => {
                version.map((valueObj, vIdx) => {
                    this.state.addedStrategyArr[index].possible_version.push(valueObj.value);
                });
            })

            await CreateStrategy(JSON.parse(localStorage.getItem('user')).token, this.state.strategyObj);
            this.setState({
                addedStrategyArr: [],
                addedPossibleVersion: [],
                isLoadingParts: true,
                isCreated: false,
                isLoading: false,
                is_free: false,
                isLoadingQuestionType: false
            });

            this.props.handleSelectMenu('allStrategy');
        }
    }

    render() {
        const { classes } = this.props;

        return (
            <Grid item xs={12}>
                <Paper>
                    <Grid container spacing={2} style={{ padding: '20px' }}>
                        <Grid item align='left' xs={12}>
                            <Typography variant="h4" style={{ fontWeight: 'bold' }} gutterBottom>
                                Create New Strategy
                            </Typography>
                        </Grid>

                        <Grid item align='left' xs={12}>
                            <Typography variant="h5" style={{ fontWeight: 'bold' }} gutterBottom>
                                Test type
                            </Typography>
                        </Grid>
                        <Grid item align='left' xs={6}>
                            <Select
                                name="testType"
                                isSearchable={true}
                                isClearable={true}
                                options={[
                                    { value: 'IELTS', label: 'IELTS' }
                                ]}
                                onChange={this.handleSelectTestType}
                            />
                        </Grid>

                        <Grid item align='left' xs={12}>
                            <Typography variant="h5" style={{ fontWeight: 'bold' }} gutterBottom>
                                Section type
                            </Typography>
                        </Grid>
                        <Grid item align='left' xs={6}>
                            <Select
                                name="partType"
                                isSearchable={true}
                                isClearable={true}
                                options={[
                                    { value: 'reading', label: 'Reading' },
                                    { value: 'listening', label: 'Listening' },
                                    { value: 'writing', label: 'Writing' }
                                ]}
                                onChange={this.handleSelectPartType}
                            />
                        </Grid>

                        <Grid item align='left' xs={12}>
                            <Typography variant="h5" style={{ fontWeight: 'bold' }} gutterBottom>
                                Strategy color
                            </Typography>
                        </Grid>
                        <Grid item align='left' xs={6}>
                            <Select
                                isClearable={true}
                                options={[
                                    { value: 'blue', label: 'Blue' },
                                    { value: 'red', label: 'Red' },
                                    { value: 'green', label: 'Green' }
                                ]}
                                onChange={this.handleSelectStrategyColor}
                            />
                        </Grid>

                        <Grid item align='left' xs={12}>
                            <Typography variant="h5" style={{ fontWeight: 'bold' }} gutterBottom>
                                Add strategy
                            </Typography>
                        </Grid>
                        <Grid item align='left' xs={12}>
                            {this.state.addedStrategyArr.map((question, index) =>
                                <Grid key={`added-question-${index}`} container spacing={1}>
                                    <Grid item align='left' xs={11}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={2}>
                                                <TextField
                                                    label="Section"
                                                    name="part"
                                                    id="outlined-basic-title"
                                                    variant="outlined"
                                                    size="small"
                                                    onChange={(event) => { this.handleEditPart(event, index) }}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={10}>
                                                <Select
                                                    isMulti
                                                    isLoading={!this.state.partType}
                                                    name="colors"
                                                    options={this.state.partType ? this.state[`${this.state.partType.toLowerCase()}Toshow`] : []}
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    onChange={(event) => { this.handleSelectStrategyVersion(event, index) }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <IconButton aria-label="delete" onClick={(event) => { this.handleRemovePossibleVersion(event, index) }} >
                                            <DeleteForeverRoundedIcon fontSize="small" />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                        <Grid item align='left' xs={12}>
                            <IconButton aria-label="add" onClick={this.handleAddStrategy}>
                                <AddBoxRoundedIcon fontSize="small" />
                            </IconButton>
                        </Grid>

                        <Grid item align='left' xs={12} style={{ marginTop: "50px" }}>
                            <FormControlLabel
                                control={
                                    <MainCheckbox
                                        checked={!this.state.is_free}
                                        onChange={this.handleCheckIsFreeTest}
                                        name="isFree"
                                        color="primary"
                                    />
                                }
                                label="This strategy is for free tests."
                            />
                        </Grid>

                        <Grid item align='right' xs={12} style={{ marginTop: '50px' }}>
                            <Button
                                variant="contained"
                                onClick={this.handleCreateNewStrategy}
                                style={{ color: 'white', backgroundColor: '#76323F' }}
                            >
                                Create
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        )
    }
}
export default compose(
    withStyles(useStyles)
)(CreateNewStrategy);