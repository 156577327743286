//React
import { Component } from 'react';
import { compose } from 'recompose';

//Material UI
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccountBoxRoundedIcon from '@material-ui/icons/AccountBoxRounded';
import AccessAlarmRoundedIcon from '@material-ui/icons/AccessAlarmRounded';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Input from '@material-ui/core/Input';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

//import components

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%'
    }
});

class MultipleChoice extends Component {
    constructor() {
        super();

        this.state = {
            answerObj: {
                answers: {}
            }
        };
    }

    componentDidMount = async () => {

    }

    render() {
        const { classes } = this.props;

        return (
            <Grid container style={{ marginBottom: '50px' }}>
                <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom align='left' style={{ fontSize: '1rem' }}>
                        <Box fontWeight="fontWeightBold" display='inline'>{this.props.sectionInfo?.section}</Box>
                    </Typography>
                </Grid>
                {this.props.sectionInfo.questions.map(q =>
                    <Grid item align='left' xs={12} style={{ marginTop: '20px' }}>
                        <Typography variant="subtitle2" gutterBottom align='left' style={{ fontSize: '1rem', fontWeight: 'bold' }}>
                            <Box fontWeight="fontWeightBold" display='inline' m={1.5}>{q.no}</Box> {q.question}
                        </Typography>
                        <RadioGroup
                            aria-label="q1"
                            name={q.no}
                            style={{ marginLeft: '20px' }}
                        >
                            {q.choice.map(choice =>
                                <FormControlLabel
                                    value={choice}
                                    control={<Radio color="default" />}
                                    label={<Typography variant="subtitle2" gutterBottom>{choice}</Typography>}
                                />
                            )}
                        </RadioGroup>
                    </Grid>
                )}
            </Grid>
        )
    }
}

export default compose(
    withStyles(useStyles)
)(MultipleChoice);