//React
import { Component } from 'react';
import { compose } from 'recompose';

//Material UI
import Avatar from '@material-ui/core/Avatar';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

//react-router-dom

//Others

const axios = require('axios').default;
const FileDownload = require('js-file-download');

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
});

class AvailabilityStatus extends Component {
    constructor() {
        super();
        this.state = {
            teachers: [],
            isLoading: true,
            isUpdating: false,
            currentTestType: 'WRITING'
        };
    }

    componentDidMount() {
        this.handleGetAllTeachers();
    }

    capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    handleGetAllTeachers = async () => {
        try {
            const res = await axios({
                method: 'get',
                url: 'https://chatter-funky-busby.glitch.me/teachers',
            });

            const resData = res.data;
            this.setState({
                teachers: resData.teachers,
                isLoading: false
            });

        } catch (err) {
            console.log(err);
        }
    }

    handleChangeTeacherStatus = async (id, username, availability, type) => {
        try {
            this.handleOpenUpdate();
            let updatedStatus = {};
            console.log(availability);

            if (type === 'writing') {
                if (availability === undefined) {
                    updatedStatus = {
                        writing: 'active',
                        speaking: 'inactive',
                    }
                } else {
                    updatedStatus = {
                        writing: availability.writing === 'active' ? 'inactive' : 'active',
                        speaking: availability.speaking
                    }
                }
            } else if (type === 'speaking') {
                if (availability === undefined) {
                    updatedStatus = {
                        writing: 'inactive',
                        speaking: 'active',
                    }
                } else {
                    updatedStatus = {
                        writing: availability.writing,
                        speaking: availability.speaking === 'active' ? 'inactive' : 'active',
                    }
                }
            }

            const res = await axios({
                method: 'put',
                url: 'https://chatter-funky-busby.glitch.me/teachers/status',
                data: {
                    username: username,
                    id: id,
                    status: updatedStatus,
                }
            });

            await this.handleGetAllTeachers();
            this.handleCloseUpdate();

        } catch (err) {
            console.log(err);
            this.handleCloseUpdate();
        }
    }

    handleOpenUpdate = () => {
        this.setState({
            isUpdating: true
        });
    }

    handleCloseUpdate = () => {
        this.setState({
            isUpdating: false
        });
    }

    handleChangeTestType = (name) => {
        this.setState({
            currentTestType: name
        });
    }

    render() {
        const { classes } = this.props;

        return (
            <Grid item xs={12} sm={9}>
                <Grid container>
                    <Grid item align='left' xs={12}>
                        <Grid container spacing={2}>
                            <Grid item>
                                <Typography variant="subtitle1" gutterBottom>
                                    Type:
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant={this.state.currentTestType === 'WRITING' ? 'contained' : 'outlined'}
                                    style={{
                                        backgroundColor: this.state.currentTestType === 'WRITING' ? '#76323F' : '',
                                        borderColor: this.state.currentTestType === 'WRITING' ? '' : '#76323F',
                                        color: this.state.currentTestType === 'WRITING' ? 'white' : '#76323F'
                                    }}
                                    onClick={() => { this.handleChangeTestType('WRITING') }}
                                >
                                    Writing
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant={this.state.currentTestType === 'SPEAKING' ? 'contained' : 'outlined'}
                                    style={{
                                        backgroundColor: this.state.currentTestType === 'SPEAKING' ? '#76323F' : '',
                                        borderColor: this.state.currentTestType === 'SPEAKING' ? '' : '#76323F',
                                        color: this.state.currentTestType === 'SPEAKING' ? 'white' : '#76323F'
                                    }}
                                    onClick={() => { this.handleChangeTestType('SPEAKING') }}
                                >
                                    Speaking
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '20px', backgroundColor: '#76323F' }}>
                        <Divider />
                    </Grid>
                    {this.state.isLoading &&
                        <Grid item xs={12} style={{ marginTop: '20px' }}>
                            <CircularProgress />
                        </Grid>
                    }
                    {!this.state.isLoading &&
                        <Grid item align='left' xs={12}>
                            <List style={{ width: '100%' }}>
                                {this.state.teachers.map((teacher, index) =>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar alt={this.capitalize(teacher.firstname)} src={teacher.picture} />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={this.capitalize(teacher.firstname) + ' ' + this.capitalize(teacher.lastname) + '(' + teacher.email + ')'}
                                            secondary={teacher.availability === undefined ? `Inactive (${teacher.role})` : `${this.capitalize(teacher.availability[this.state.currentTestType.toLowerCase()])} (${teacher.role})`}
                                        />
                                        <ListItemSecondaryAction>
                                            <FormControlLabel
                                                control={<Switch
                                                    checked={teacher.availability === undefined ? false : teacher.availability[this.state.currentTestType.toLowerCase()] === 'active'}
                                                    onChange={() => { this.handleChangeTeacherStatus(teacher._id, teacher.username, teacher.availability, this.state.currentTestType.toLowerCase()) }}
                                                    name="availability"
                                                    color="primary"
                                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                                />}
                                                label={teacher.availability !== undefined && teacher.availability[this.state.currentTestType.toLowerCase()] === 'active' ? "Available" : "Unavailable"}
                                            />
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                )}
                            </List>
                        </Grid>
                    }
                </Grid>

                <Backdrop className={classes.backdrop} open={this.state.isUpdating}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Grid>
        )
    }
}

export default compose(
    withStyles(useStyles)
)(AvailabilityStatus);