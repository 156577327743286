import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing(1),
    },
}));

export default function VerifyFailedDialog(props) {
    return (
        <React.Fragment>
            <Dialog
                fullWidth={false}
                maxWidth={"md"}
                open={props.isOpenDialog}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogTitle id="max-width-dialog-title">
                    <Typography variant="h5" style={{ fontWeight: "bold" }}>
                        Your verification attempts have been unsuccessful 3 times
                    </Typography>
                </DialogTitle>
                <DialogContent divißers>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="body1" style={{ fontWeight: "bold", marginBottom: "30px" }}>
                                🛑 Verification Failed 3 Times 🛑
                            </Typography>
                            <Typography variant="body1" style={{ marginBottom: "10px" }}>
                                We're sorry, but it seems that your verification attempts have been unsuccessful three times. For security reasons, we require you to get in touch with our support team to resolve this issue.
                            </Typography>
                            <Typography variant="body1" style={{ marginBottom: "30px" }}>
                                Please contact our administrator at <span style={{ fontWeight: "bold", textDecoration: "underline" }}>contact@utestavenue.com</span>. Our team will be more than happy to assist you in completing the verification process.
                            </Typography>
                            <Typography variant="body1" >
                                Thank you for your understanding and cooperation,
                            </Typography>
                            <Typography variant="body1" style={{ fontWeight: "bold" }}>
                                UTest Avenue Team
                            </Typography>
                        </Grid>
                        <Grid item align="center" xs={12} style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                            <Button disableElevation variant="contained" component={Link} to="/" style={{ textTransform: "none", color: 'white', backgroundColor: '#991514', fontWeight: "bold" }}>Back to home</Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}