import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { FormControl, FormGroup, FormLabel, FormControlLabel, FormHelperText, Checkbox } from '@material-ui/core';
import { G } from '@react-pdf/renderer';
import { set } from 'lodash';

import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing(1),
    },
}));

export default function RegistrationFormDialog(props) {
    const ieltsScores = [2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9]
    const choices = ["Yes", "No"]
    const ieltsExpMap = {
        "lessthan1month": "Less than 1 month",
        "1to3months": "1 to 3 months",
        "morethan3months": "Longer than 3 months"
    }

    const [selectedExpectedScore, setSelectedExpectedScore] = React.useState("");
    const handleChangeExpectedScore = (event) => {
        console.log(selectedExpectedScore);
        setSelectedExpectedScore(event.target.value);
    };

    const [selectedChoice, setSelectedChoice] = React.useState("");
    const handleChangeChoice = (event) => {
        setSelectedChoice(event.target.value);
    };

    const [ieltsExp, setIELTSExp] = React.useState("");
    const onChangeIELTSExp = (event) => {
        setIELTSExp(event.target.name);
    }

    const [concern, setConcern] = React.useState([]);
    const onChangeConcern = (event) => {
        if (event.target.name === "none") {
            setConcern(["none"]);
            return;
        }
        if (concern.includes(event.target.name)) {
            setConcern(concern.filter((item) => (item !== event.target.name && item !== "none")));
            return;
        }

        setConcern([...concern.filter((item) => item !== "none"), event.target.name]);
    }

    const onClickSubmit = () => {
        if (ieltsExp.length > 0 && concern.length > 0 && selectedChoice.length > 0 && selectedExpectedScore.length > 0) {
            props.onSubmitRegitrationFormResult({
                ieltsExp: ieltsExpMap[`${ieltsExp}`],
                concern: concern.toString(),
                selectedChoice: selectedChoice,
                selectedExpectedScore: selectedExpectedScore
            });

            return;
        } else {
            alert("Please fill in all required fields.");
            return;
        }
    }

    const devices = [{ name: "Laptop or PC" }, { name: "Tablet" }]
    const pages = { 0: "Device selection", 1: "Agreement form", 2: "Registration Form" }
    const [currentPage, setCurrentPage] = React.useState(0);
    const [selectedDevice, setSelectedDevice] = React.useState('');
    const history = useHistory();
    const handleChangePage = (action) => {
        if (action === "next") {
            setCurrentPage(currentPage + 1);
            return;
        } if (action === "back") {
            setCurrentPage(currentPage - 1);
            return;
        } if (action === "home") {
            history.push("/");
        }
        else {
            return;
        }
    }

    const handleChangeDevice = (event) => {
        setSelectedDevice(event.target.value);
    }

    const [boxes, setBoxes] = React.useState({ "acknowledge1": false, "acknowledge2": false });
    const handleCheckboxChange = (event) => {
        setBoxes({ ...boxes, [event.target.name]: event.target.checked });
    }

    return (
        <React.Fragment>
            <Dialog
                fullWidth={true}
                maxWidth={currentPage === 0 ? 'sm' : (currentPage < 2 ? 'md' : 'lg')}
                open={props.isOpenDialog}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogTitle id="max-width-dialog-title">
                    <Typography variant="h5" style={{ fontWeight: "bold" }}>
                        {pages[currentPage]}
                    </Typography>
                </DialogTitle>
                <DialogContent dividers>
                    {currentPage === 0 && <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h6" style={{ fontWeight: "bold" }}>
                                Select your device:
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container style={{ paddingLeft: "20px" }}>
                                {devices.map((d) => {
                                    return (
                                        <Grid item xs={12}>
                                            <FormControlLabel
                                                value={d.name}
                                                control={<Radio
                                                    checked={selectedDevice === d.name}
                                                    onChange={handleChangeDevice}
                                                    value={d.name}
                                                    color="default"
                                                    inputProps={{ 'aria-label': d.name }}
                                                    size="small"
                                                />}
                                                label={d.name}
                                                labelPlacement="end"
                                            />
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </Grid>
                    </Grid>}

                    {currentPage === 1 && <Grid container spacing={1}>
                        {selectedDevice === "Laptop or PC" && <Grid item xs={12}>
                            <Typography variant="body1">
                                If Laptop or PC is selected
                                We recommend using full window feature for effective testing with minimal distraction.
                            </Typography>
                        </Grid>}

                        {selectedDevice === "Tablet" && <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={boxes.acknowledge1}
                                        onChange={handleCheckboxChange}
                                        name="acknowledge1"
                                        color="primary"
                                    />
                                }
                                label="I acknowledge that complete testing features require tablet with keyboard & trackpad extension."
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={boxes.acknowledge2}
                                        onChange={handleCheckboxChange}
                                        name="acknowledge2"
                                        color="primary"
                                    />
                                }
                                label="Without keyboard with trackpad extension, I acknowledge the limitations of highlighting and note taking features."
                            />
                        </Grid>}
                    </Grid>}


                    {(currentPage < 2) && <Grid item xs={12} style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                        <Grid container spacing={2}>

                            <Grid item align="right" xs={6}>
                                {currentPage === 0 ? <Button disableElevation onClick={() => { handleChangePage('home') }} variant="contained" style={{ textTransform: 'none', fontWeight: "bold" }}>Home</Button> : <Button disableElevation onClick={() => { handleChangePage('back') }} variant="contained" style={{ textTransform: 'none' }}>Back</Button>}
                            </Grid>
                            <Grid item align="left" xs={6}>
                                <Button disabled={selectedDevice === '' || (currentPage === 1 && selectedDevice === "Tablet") && (boxes.acknowledge1 === false || boxes.acknowledge2 === false)} disableElevation onClick={() => { handleChangePage('next') }} variant="contained" style={{ textTransform: 'none', color: 'white', backgroundColor: selectedDevice === '' || (currentPage === 1 && selectedDevice === "Tablet") && (boxes.acknowledge1 === false || boxes.acknowledge2 === false) ? 'silver' : '#991514' }}>{currentPage < 1 ? 'Next' : 'Agree'}</Button>
                            </Grid>
                        </Grid>
                    </Grid>}

                    {currentPage === 2 && <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="body1" style={{ fontWeight: "bold" }} gutterBottom>
                                What is your expected overall band score?*
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            {ieltsScores.map((value) => {
                                return <FormControlLabel
                                    value={value}
                                    control={<Radio
                                        checked={selectedExpectedScore === value.toString()}
                                        onChange={handleChangeExpectedScore}
                                        value={value}
                                        color="default"
                                        name="radio-button-demo"
                                        inputProps={{ 'aria-label': value }}
                                        size="small"
                                    />}
                                    label={value.toString()}
                                    labelPlacement="bottom"
                                />
                            })}
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1" style={{ fontWeight: "bold" }} gutterBottom>
                                Have you taken the real IELTS test?*
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{ paddingLeft: "35px" }}>
                            {choices.map((value) => {
                                return <FormControlLabel
                                    value={value}
                                    control={<Radio
                                        checked={selectedChoice === value}
                                        onChange={handleChangeChoice}
                                        value={value}
                                        color="default"
                                        name="radio-button-demo"
                                        inputProps={{ 'aria-label': value }}
                                        size="small"
                                    />}
                                    label={value.toString()}
                                    labelPlacement="end"
                                />
                            })}
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1" style={{ fontWeight: "bold" }} gutterBottom>
                                How long have you been preparing for IELTS?*
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{ paddingLeft: "35px" }}>
                            <FormControl component="fieldset">
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Checkbox color="default" checked={ieltsExp === "lessthan1month"} size="small" onChange={onChangeIELTSExp} name="lessthan1month" />}
                                        label="Less than 1 month"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox color="default" checked={ieltsExp === "1to3months"} size="small" onChange={onChangeIELTSExp} name="1to3months" />}
                                        label="1 to 3 months"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox color="default" checked={ieltsExp === "morethan3months"} size="small" onChange={onChangeIELTSExp} name="morethan3months" />}
                                        label="Longer than 3 months"
                                    />
                                </FormGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1" style={{ fontWeight: "bold" }} gutterBottom>
                                Areas of concern (select all that apply)*:
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{ paddingLeft: "35px" }}>
                            <FormControl component="fieldset">
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Checkbox color="default" checked={concern.includes("listening")} size="small" onChange={onChangeConcern} name="listening" />}
                                        label="Listening"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox color="default" checked={concern.includes("reading")} size="small" onChange={onChangeConcern} name="reading" />}
                                        label="Reading"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox color="default" checked={concern.includes("writing")} size="small" onChange={onChangeConcern} name="writing" />}
                                        label="Writing"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox color="default" checked={concern.includes("speaking")} size="small" onChange={onChangeConcern} name="speaking" />}
                                        label="Speaking"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox color="default" checked={concern.includes("none")} size="small" onChange={onChangeConcern} name="none" />}
                                        label="None of the above"
                                    />
                                </FormGroup>
                            </FormControl>
                        </Grid>
                        <Grid item align="center" xs={12} style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                            <Button disableElevation variant="contained" onClick={onClickSubmit} style={{ textTransform: "none", color: 'white', backgroundColor: '#991514', fontWeight: "bold" }}>Submit</Button>
                        </Grid>
                    </Grid>}

                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}
