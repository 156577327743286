import React from 'react';

//Material UI
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import MuiAlert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

//Others
import Select from 'react-select';
import { withStyles } from '@material-ui/core/styles';
import { Component } from 'react';
import { compose } from 'recompose';

const useStyles = theme => ({
    root: {
        margin: 15,
        marginTop: 50
    },
    margin: {
        margin: theme.spacing(1),
    },
    buttonColor: {
        backgroundColor: '#76323F',
        color: 'white'
    }
});

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class MatchingSentenceEndings extends Component {
    constructor() {
        super()
        this.state = {
            tempQuestion: [1],
            tempChoice: [1],
            questionCount: 1,
            choiceCount: 1,
            choiceToShow: [],
            alphabet: ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k"]
        };
    }

    componentDidMount = async () => {

    }

    handleEditQuestionNumber = async (event, index) => {
        this.props.questionObj.no = `${event.target.value}-${Number(event.target.value) + this.state.questionCount}`;

        this.setState({
            startQuestionNum: Number(event.target.value)
        });
    }

    handleEditQuestionL = async (event, index) => {
        this.props.questionObj.match_question_l[index] = `${this.state.startQuestionNum ? this.state.startQuestionNum : 'x'}. ${event.target.value}`;

        console.log(this.props.questionObj)
    }

    handleAddQuestion = () => {
        this.state.tempQuestion.push(this.state.questionCount + 1);

        this.setState({
            tempQuestion: this.state.tempQuestion,
            questionCount: this.state.questionCount + 1
        });
    }

    handleEditQuestionR = async (event, index) => {
        this.props.questionObj.match_question_r[index] = `${this.state.alphabet[index].toUpperCase()}. ${event.target.value}`;

        this.state.choiceToShow[index] = {
            label: event.target.value,
            value: `${this.state.alphabet[index].toUpperCase()}.`
        }
    }

    handleAddChoice = () => {
        this.state.tempChoice.push(this.state.choiceCount + 1);

        this.setState({
            tempChoice: this.state.tempChoice,
            choiceCount: this.state.choiceCount + 1
        });
    }

    handleEditAnswer = async (event, index) => {
        if (event === null) {
            this.props.questionObj.answer[index] = '';
        } else if (event !== null) {
            this.props.questionObj.answer[index] = event.value;
        }
    }

    render() {
        const { classes } = this.props;

        return (
            <Grid item xs={12}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item align='left' xs={12}>
                                <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                                    First Question Number
                                </Typography>
                            </Grid>
                            <Grid item align='left' xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item>
                                        <TextField
                                            id="outlined-size-small"
                                            variant="outlined"
                                            size="small"
                                            label="From"
                                            onChange={this.handleEditQuestionNumber}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            disabled
                                            id="outlined-size-small"
                                            variant="outlined"
                                            size="small"
                                            label="To"
                                            value={this.state.startQuestionNum ? this.state.startQuestionNum + (this.state.questionCount - 1) : ''}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                                            Question
                                        </Typography>
                                    </Grid>
                                    {this.state.tempQuestion.map((question, questionLIndex) =>
                                        <Grid key={`question-${questionLIndex}`} item xs={12}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={11}>
                                                    <TextField
                                                        id="outlined-size-small"
                                                        variant="outlined"
                                                        size="small"
                                                        onChange={(event) => { this.handleEditQuestionL(event, questionLIndex) }}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item align='center' xs={1} style={{paddingTop: 10 }}>
                                                    <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                                                        -
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )}
                                    <Grid item align="center" xs={12}>
                                        <IconButton aria-label="add" onClick={this.handleAddQuestion} style={{ margin: 1 }}>
                                            <AddCircleRoundedIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                                            Answer
                                        </Typography>
                                    </Grid>
                                    {this.state.tempQuestion.map((answer, ansIndex) =>
                                        <Grid key={`answer-${ansIndex}`} item xs={12} style={{padding: 5 }}>
                                            <Select
                                                className="basic-single"
                                                classNamePrefix="select"
                                                isClearable={true}
                                                isSearchable={true}
                                                name="choice"
                                                options={this.state.choiceToShow}
                                                onChange={(event) => { this.handleEditAnswer(event, ansIndex) }}
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                                            Choice
                                        </Typography>
                                    </Grid>
                                    {this.state.tempChoice.map((choice, choiceIndex) =>
                                        <Grid key={`choice-${choiceIndex}`} item xs={12}>
                                            <TextField
                                                id="outlined-size-small"
                                                variant="outlined"
                                                size="small"
                                                onChange={(event) => { this.handleEditQuestionR(event, choiceIndex) }}
                                                fullWidth
                                            />
                                        </Grid>
                                    )}
                                    <Grid item align="center" xs={12}>
                                        <IconButton aria-label="add" onClick={this.handleAddChoice} style={{ margin: 1 }}>
                                            <AddCircleRoundedIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}
export default compose(
    withStyles(useStyles)
)(MatchingSentenceEndings);