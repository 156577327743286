import React, { Component } from "react";

//Material UI
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

//react-load-script
import Script from "react-load-script";
import { GetQRCode, GetSource } from "../services/omise";

let OmiseCard;

export class CheckoutCreditCard extends Component {
  constructor() {
    super();
    this.state = {};
  }
  //==================================== BEGIN OMISE PAYMENT =================================

  handleScriptLoad = () => {
    OmiseCard = window.OmiseCard;
    OmiseCard.configure({
      publicKey: "pkey_5ygf21hj1kid6vismka",
      currency: "thb",
      frameLabel: "UTESTAVENUE",
      submitLabel: "Pay Now",
      buttonLabel: "Pay with Omise",
    });
  };

  creditCardConfigure = () => {
    OmiseCard.configure({
      defaultPaymentMethod: "credit_card",
      otherPaymentMethods: ["promptpay"],
    });

    OmiseCard.configureButton("#credit-card");
    OmiseCard.attach();
  };

  omiseTokenRequester = () => {
    const { user, amount, createCreditCardCharge, promptPayCharge, handleOpenLoading } = this.props;

    OmiseCard.open({
      amount: amount,
      defaultPaymentMethod: "credit_card",
      otherPaymentMethods: ["promptpay"],
      onCreateTokenSuccess: async (token) => {
        if (token.startsWith("src_")) {
          handleOpenLoading();
          const source = await GetSource(token)
          if (source.success) {
            const qr = await GetQRCode(amount)
            promptPayCharge(qr.res, user.email, user.name, amount, token)
          }
        } else {
          createCreditCardCharge(user.email, user.name, amount, token);
        }
      },
      onFormClosed: () => {
        /* Handler on form closure. */
      },
    });
  };

  handlePayClick = (event) => {
    event.preventDefault();
    this.creditCardConfigure();
    this.omiseTokenRequester();
  };

  //==================================== END OMISE PAYMENT =================================

  render() {
    return (
      <div className="own-form">
        <Script
          url="https://cdn.omise.co/omise.js"
          onLoad={this.handleScriptLoad}
        />
        <form>
          <Button
            disableElevation
            variant="contained"
            color="primary"
            className="btn"
            type="button"
            id="credit-card"
            onClick={this.handlePayClick}
            style={{
              width: "100%",
              backgroundColor: "#76323F",
              padding: "10px",
            }}
          >
            <Typography variant="button" style={{ textTransform: "none" }}>
              Pay with Cards or PromptPay
            </Typography>
          </Button>
        </form>
      </div>
    );
  }
}

export default CheckoutCreditCard;
