import React, { useEffect, useRef } from 'react';

//MUI
import Button from '@material-ui/core/Button';
import { Container } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

//Table
import { DataGrid } from '@mui/x-data-grid';

import { ThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';

//Font
import 'typeface-glacial-indifference';

//Services
import { GetAllTeachers } from '../../../services/userServices';
import { GetTestsByTeacher, GetSpeakingTestsByTeacher } from '../../../services/testServices';

//Others
import moment from 'moment';
import jsPDF from 'jspdf';
import { Link } from "react-router-dom";

const useStyles = theme => ({
    root: {
        padding: theme.spacing(12, 4),
    }
});

const theme = createMuiTheme({
    typography: {
        fontFamily: [
            'Roboto'
        ].join(','),
    },
});

const FinancialPage = () => {
    const [teachers, setTeachers] = React.useState([]);
    const [selectedTeacher, setSelectedTeacher] = React.useState({});
    const [writingArr, setWritingArr] = React.useState(undefined);
    const [speakingArr, setSpeakingArr] = React.useState(undefined);
    const [salary, setSalary] = React.useState(undefined);

    const reportTemplateRef = useRef(null);

    const columns = [
        { field: 'id', headerName: 'Username', width: 150 },
        {
            field: 'fullName',
            headerName: 'Full name',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 200,
            valueGetter: (params) =>
                `${params.getValue(params.id, 'firstname') || ''} ${params.getValue(params.id, 'lastname') || ''
                }`,
        },
        {
            field: 'email',
            headerName: 'Email',
            width: 200,
            editable: true,
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 150,
            editable: true,
        },
    ];

    useEffect(() => {
        document.body.style.overflow = "auto";
        document.body.style.backgroundColor = "#efefef";
        document.querySelector("body").scrollTo(0, 0);

        getTeachers();
    }, []);

    const getTeachers = async () => {
        const res = await GetAllTeachers();

        if (res.success) {
            let tempArr = [];
            res.res.teachers.map((teacher) => {
                tempArr.push({
                    id: teacher.username,
                    firstname: teacher.firstname,
                    lastname: teacher.lastname,
                    status: teacher.status,
                    email: teacher.email,
                });
                return;
            })

            setTeachers(tempArr);
        } if (!res.success) {
            alert("Something went wrong. Please try again later.")
        }
    }

    const handleSelectTeacher = (username) => {
        const teacherObj = teachers.find(obj => {
            return obj.id === username[0]
        });
        setSelectedTeacher(teacherObj);
        getTestByTeacher(username);
        getSpeakingTestByTeacher(username);
        getTeacherSalary(username);
    }

    const getTestByTeacher = async (username) => {
        setWritingArr(undefined);
        const res = await GetTestsByTeacher(username);

        if (res.success) {
            setWritingArr(res.res.tests.filter((obj) => { return !["bellebelle", "117337903114766421177"].includes(obj.submitterUsername); }));
        } if (!res.success) {
            setWritingArr([]);
        }
    }

    const getSpeakingTestByTeacher = async (username) => {
        setSpeakingArr(undefined);
        const res = await GetSpeakingTestsByTeacher(username);

        if (res.success) {
            setSpeakingArr(res.res.data);
        } if (!res.success) {
            setSpeakingArr([]);
        }
    }

    const getTeacherSalary = async (username) => {
        setSalary(Math.floor(Math.random() * 50000) + 10000);
    }

    const createHeaders = (headers) => {
        let result = [];
        headers.map((header, index) => {
            result.push({
                id: header.id,
                name: header.id,
                prompt: header.name,
                width: 65,
                align: "center",
                padding: 0
            });

            return;
        })

        return result;
    }

    const generateData = (amount) => {
        var result = [];
        var data = {
            service: "100",
            description: "GameGroup",
            quantity: "XPTO2",
            unit_price: "25",
            disc_percent: "20485861",
            line_total: "0"
        };
        for (var i = 0; i < amount; i += 1) {
            data.id = (i + 1).toString();
            result.push(Object.assign({}, data));
        }

        return result;
    };

    //PDF
    const handleGeneratePdf = (type, testCount, testArr) => {
        const amount = type === 'speaking' ? (testCount * 150).toLocaleString() : (testCount * 100).toLocaleString();
        const service = `11278_P1_${type === 'speaking' ? "SPK" : "WRT"}_Aj ${selectedTeacher.firstname}_${`${moment().format('MMM DD YYYY')}`}_Poom UK_2Hrs`
        const headers = createHeaders([
            {
                id: "service",
                name: "List"
            }, {
                id: "description",
                name: "Description"
            }, {
                id: "quantity",
                name: "Quantity"
            }, {
                id: "unit_price",
                name: "Unit Price"
            }, {
                id: "disc_percent",
                name: "Disc %"
            }, {
                id: "line_total",
                name: "Total"
            },
        ]);

        const data = []

        testArr.map((obj) => {
            data.push({
                "service": `${type === 'speaking' ? "SPK" : "WRT"}_Aj ${selectedTeacher.firstname}_${`${moment().format('MMM DD YYYY')}`}_${type === 'speaking' ? obj.schedule.topic.replace("'s Speaking Test", "") : obj.submitter}`,
                "description": " ",
                "quantity": '1',
                "unit_price": type === 'speaking' ? "150" : "100",
                "disc_percent": "0.0",
                "line_total": type === 'speaking' ? "150" : "100"
            });
            return obj;
        })

        const doc = new jsPDF({
            format: 'a4',
        });

        doc.setFontSize(10)
        doc.text(10, 20, 'Inside out English Coach');
        doc.text(10, 25, '35 Wannasorn Tower, 12th floor, room 1207 Thanonphayathai');
        doc.text(10, 30, 'Rajchatawee');
        doc.text(10, 35, 'Bangkok 10400');
        doc.text(10, 40, 'Thailand');
        doc.text(10, 70, `${selectedTeacher.firstname.toUpperCase()} ${selectedTeacher.lastname.toUpperCase()}`);
        doc.text(10, 75, `Performance report (IELTS ${type === 'speaking' ? 'speaking' : 'writing'})`);

        doc.addImage("U Test Logo (new).png", "PNG", 180, 15, 18, 30);
        doc.text('Invoice Number:', 170, 70, null, null, "right");
        doc.text(`INV-${Math.floor(1000 + Math.random() * 9000)}`, 200, 70, null, null, "right");

        doc.text('Date:', 170, 75, null, null, "right");
        doc.text(`${moment().format('DD/MM/YYYY')}`, 200, 75, null, null, "right");

        doc.text('Due Date:', 170, 80, null, null, "right");
        doc.text(`${moment().add(30, 'days').format('DD/MM/YYYY')}`, 200, 80, null, null, "right");

        doc.text('Amount Due:', 170, 85, null, null, "right");
        doc.text(`${amount - (amount * 0.03)}`, 200, 85, null, null, "right");

        doc.table(20, 95, data, headers, { autoSize: true, fontSize: 9 });

        doc.text('Subtotal', 170, 250, null, null, "right");
        doc.text(`${amount}`, 200, 250, null, null, "right");

        doc.text('Sales Tax (3.0%)', 170, 255, null, null, "right");
        doc.text(`-${amount * 0.03}`, 200, 255, null, null, "right");

        doc.text('Total', 170, 260, null, null, "right");
        doc.text(`${amount}`, 200, 260, null, null, "right");

        doc.line(135, 265, 200, 265);

        doc.text('Amount Due', 170, 270, null, null, "right");
        doc.text(`${amount - (amount * 0.03)}`, 200, 270, null, null, "right");

        // doc.save(service);
        window.open(doc.output('bloburl'), '_blank');
    };

    const testGenPDF = () => {
        const tempObj = {
            "userObj": {
                "userSignedin": true,
                "firstname_th": "",
                "lastname_th": "",
                "firstname": "Jirayu",
                "lastname": "Promsongwong",
                "name": "Jirayu Promsongwong",
                "name_th": "",
                "email": "jirayu.promsongwong@hotmail.com",
                "birthdate": "Thu Jan 01 1970",
                "gender": "None",
                "phone": "",
                "school": "",
                "firstNameChar": "J",
                "token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJ1c2VybmFtZSI6ImppcmF5dS5wcm9tc29uZ3dvbmdAaG90bWFpbC5jb20iLCJpc3N1ZWQiOjE2NzczNTc3MzkxNDMsImV4cGlyZXMiOjE2NzczNTg2MzkxNDN9.DwgGrD6-jQ7Xc-sLLJS4s0wFu8K-cm6fANN_jssl5qWD5J_GdVu3pww-l7vaFmAJyWbU70PCzfxmOgOL8-w4sg",
                "role": "SUPER_ADMIN",
                "picture": "",
                "username": "jirayu.promsongwong@hotmail.com"
            },
            "currentDate": "Mon Mar 06 2023",
            "fullTestObj": {
                "id": "6146a97ba2ac6b06f008a6ff",
                "part": 1,
                "part_type": "WRITING",
                "version": "1W",
                "title": "WRITING Test",
                "description_title": "",
                "description": "Questions 1 - 2",
                "sections": [
                    {
                        "section_id": "5b70cba4-bbc5-462c-bbce-5619f8a6cfe2",
                        "version": "1W",
                        "section": "Question 1-2",
                        "question_type": "writingBaseQuestion",
                        "questions": [
                            {
                                "no": "1",
                                "passage": "https://i.ibb.co/Cs6wZWJ/bmat-BMAT-BAR-CHART-1-W.png",
                                "question": "Summarise the information by selecting and reporting the main features, and make comparisons where relevant."
                            },
                            {
                                "no": "2",
                                "passage": "High school students should be allowed to study only the subjects they find important for their future. So there should not be any more compulsory subjects in school.",
                                "question": "Do you agree or disagree with this statement?"
                            }
                        ],
                        "total_question": 2
                    }
                ],
                "total_question": 2
            },
            "answerQ1Obj": "Nunc leo proin posuere tristique nibh sociosqu sed varius nulla cubilia. Magnis justo aenean a vel, mi quisque faucibus donec hac netus condimentum Tellus in praesent nonummy blandit viverra, class massa class quis aenean dis neque nisi ad pharetra venenatis imperdiet interdum conubia eros sagittis odio sit neque adipiscing sapien rhoncus, tempus senectus taciti curae;, scelerisque id litora sodales nisl dictumst donec dictum. Dictum aptent non laoreet et vehicula dis. Metus mauris ligula venenatis sociosqu malesuada eget fringilla. Netus elementum phasellus. Enim at dui curabitur nisl libero netus ullamcorper morbi nisl nullam pellentesque dignissim tempus. Felis cras lectus. Eget sem. Primis. Curae; praesent laoreet phasellus curae; nisi eros pretium, luctus ultricies quis ad pulvinar. Mi congue lobortis metus faucibus aptent euismod aliquet fames litora cras odio dolor magna potenti id felis libero tellus lorem ultrices nunc justo viverra aliquet aenean metus id non. Maecenas facilisi porta Interdum tristique vel libero. Blandit ipsum suscipit ornare nascetur luctus turpis pede auctor dis nisl sed nam nonummy arcu ridiculus fusce semper pulvinar dolor nisl congue ac laoreet sem porta, per urna inceptos, phasellus dictum. Ultricies aptent. Habitasse bibendum. Condimentum. Elementum a commodo proin fusce sapien ipsum habitasse habitasse dis. Vehicula elit ipsum id.",
            "answerQ2Obj": "Ridiculus fames. Condimentum mattis hac sed enim condimentum lorem augue lobortis amet bibendum dignissim hac Mauris eros curabitur. Curabitur hac. Nullam accumsan nec nonummy vel. Sapien Commodo tempor vehicula. Proin ornare sem justo laoreet vel dictumst rhoncus Tristique lectus facilisis rhoncus odio aliquet. Parturient praesent laoreet dolor parturient inceptos consectetuer. Metus inceptos est. /p Dictum hymenaeos sapien dictum varius non. Enim in inceptos pulvinar porta dui porttitor elit curabitur donec vestibulum lacus. Aliquam rhoncus vel et ultrices proin sem feugiat dictum quam, sapien urna vivamus ridiculus. /p Interdum vivamus fames congue magna. Vitae aliquam malesuada a dolor vitae. Lorem, dis mattis duis habitasse. Urna tempor eu dui fusce pede. Mauris nam sed, adipiscing habitasse mollis lacinia commodo dignissim accumsan sed ultrices imperdiet in id feugiat fusce eros. Pharetra pharetra vivamus non a mauris quam feugiat. /p Suscipit tortor fames a ligula. Lorem pellentesque. Leo class quis ut sem aenean a magnis accumsan consectetuer nam nullam pharetra lectus primis auctor quam tempor. Laoreet nonummy pede ante vestibulum. Vehicula ultrices conubia arcu aenean ante accumsan purus dui a pharetra penatibus vulputate aenean integer malesuada pharetra dictum ultrices dignissim a morbi curae; curae; arcu morbi etiam interdum hymenaeos sociosqu phasellus volutpat mauris dis. At elit elementum.",
            "grammarCheckObj": {},
            "grammarErrorCountQ1": {
                "grammar": 1,
                "spelling": 0
            },
            "grammarErrorCountQ2": {
                "grammar": 0,
                "spelling": 1
            },
            "highlightedTextQ1": [
                {
                    "start": 8,
                    "length": 4,
                    "bad": "test",
                    "type": "grammar"
                }
            ],
            "highlightedTextQ2": [
                {
                    "start": 0,
                    "length": 4,
                    "bad": "Thas",
                    "type": "spelling"
                }
            ],
            "lastEditQ1": {
                "type": "div",
                "key": null,
                "ref": null,
                "props": {
                    "children": [
                        "This is ",
                        {
                            "type": "span",
                            "key": null,
                            "ref": null,
                            "props": {
                                "style": {
                                    "backgroundColor": "pink"
                                },
                                "children": "test"
                            },
                            "_owner": null,
                            "_store": {}
                        },
                        "",
                        {
                            "type": "span",
                            "key": null,
                            "ref": null,
                            "props": {
                                "style": {
                                    "backgroundColor": "pink"
                                },
                                "children": "test"
                            },
                            "_owner": null,
                            "_store": {}
                        },
                        ""
                    ]
                },
                "_owner": null,
                "_store": {}
            },
            "lastEditQ2": {
                "type": "div",
                "key": null,
                "ref": null,
                "props": {
                    "children": [
                        "",
                        {
                            "type": "span",
                            "key": null,
                            "ref": null,
                            "props": {
                                "style": {
                                    "backgroundColor": "yellow"
                                },
                                "children": "Thas"
                            },
                            "_owner": null,
                            "_store": {}
                        },
                        "",
                        {
                            "type": "span",
                            "key": null,
                            "ref": null,
                            "props": {
                                "style": {
                                    "backgroundColor": "yellow"
                                },
                                "children": "Thas"
                            },
                            "_owner": null,
                            "_store": {}
                        },
                        " test"
                    ]
                },
                "_owner": null,
                "_store": {}
            },
            "grammarCheckObjQ1": {
                "result": true,
                "errors": [
                    {
                        "id": "e1247340366",
                        "offset": 8,
                        "length": 4,
                        "description": {
                            "en": "Consider using either the past participle “tested” or the present participle “testing” here."
                        },
                        "bad": "test",
                        "better": [
                            "tested",
                            "testing"
                        ],
                        "type": "grammar"
                    }
                ]
            },
            "grammarCheckObjQ2": {
                "result": true,
                "errors": [
                    {
                        "id": "e1334324413",
                        "offset": 0,
                        "length": 4,
                        "description": {
                            "en": "Possible spelling mistake found."
                        },
                        "bad": "Thas",
                        "better": [
                            "This",
                            "That",
                            "Than",
                            "Thus",
                            "Thai",
                            "Teas",
                            "Thaw"
                        ],
                        "type": "spelling"
                    }
                ]
            },
            "readabilityObjQ1": {
                "stats": {
                    "fleschKincaid": {
                        "readingEase": 100,
                        "grade": "5th grade",
                        "interpretation": "Very easy to read. Easily understood by an average 11-year-old student"
                    },
                    "gunningFog": 1.2,
                    "colemanLiau": 3.7,
                    "SMOG": 3.3,
                    "vocabularySize": {
                        "active": null,
                        "passive": null
                    },
                    "emotion": {
                        "negative": 0.502871878470683,
                        "positive": 0.49712812152931696
                    },
                    "counters": {
                        "length": 12,
                        "clearLength": 10,
                        "words": 3,
                        "sentences": 1
                    }
                }
            },
            "readabilityObjQ2": {
                "stats": {
                    "fleschKincaid": {
                        "readingEase": 100,
                        "grade": "5th grade",
                        "interpretation": "Very easy to read. Easily understood by an average 11-year-old student"
                    },
                    "gunningFog": 0.8,
                    "colemanLiau": 7.6,
                    "SMOG": 3.3,
                    "vocabularySize": {
                        "active": null,
                        "passive": null
                    },
                    "emotion": {
                        "negative": 0.5037451883303109,
                        "positive": 0.4962548116696891
                    },
                    "counters": {
                        "length": 9,
                        "clearLength": 8,
                        "words": 2,
                        "sentences": 1
                    }
                }
            }
        }
        const headers = createHeaders([
            {
                id: "possibleImprovements",
                name: "Possible Improvements"
            },
            {
                id: "metrics",
                name: "Metrics"
            },
        ]);

        const doc = new jsPDF({
            format: 'a4',
        });

        //Q1
        doc.setFontSize(20);
        doc.text(10, 20, 'IELTS Writing');
        doc.addImage("U Test Logo (new) square.png", "PNG", 180, 10, 10, 10);
        doc.table(174, 25, [{ bandScore: " " }], createHeaders([{ id: "bandScore", name: "Bandscore" }]), { autoSize: true, fontSize: 9 });

        doc.setFontSize(10);
        doc.text(10, 30, `Test date: ${tempObj.currentDate}`);
        doc.text(10, 35, `Student name: ${tempObj.userObj.firstname} ${tempObj.userObj.lastname}`);

        doc.text(10, 50, 'Task 1');
        doc.text(10, 55, `Question: ${tempObj.fullTestObj.sections[0].questions[0].question}`);
        if (tempObj.fullTestObj.sections[0].questions[0].passage.includes('https://')) {
            doc.addImage(tempObj.fullTestObj.sections[0].questions[0].passage, "PNG", 60, 60, 95, 70);
        } else {
            doc.text(10, 50, tempObj.fullTestObj.sections[0].questions[0].passage);
        }

        doc.text(10, 140, 'Answer');
        doc.text(10, 145, tempObj.answerQ1Obj, { maxWidth: 190 });
        doc.line(10, 250, 195, 250);

        const dataQ1 = [
            {
                possibleImprovements: `Grammar: ${tempObj.grammarErrorCountQ1.grammar}`,
                metrics: `Words: ${tempObj.readabilityObjQ1.stats.counters.words}`
            },
            {
                possibleImprovements: `Spelling: ${tempObj.grammarErrorCountQ1.spelling}`,
                metrics: " "
            },
        ]
        doc.table(50, 255, dataQ1, headers, { autoSize: false, fontSize: 9 });

        //Q2
        doc.addPage("a4");
        doc.setFontSize(20);
        doc.text(10, 20, 'IELTS Writing');
        doc.addImage("U Test Logo (new) square.png", "PNG", 180, 10, 10, 10);
        doc.table(174, 25, [{ bandScore: " " }], createHeaders([{ id: "bandScore", name: "Bandscore" }]), { autoSize: true, fontSize: 9 });

        doc.setFontSize(10);
        doc.text(10, 30, `Test date: ${tempObj.currentDate}`);
        doc.text(10, 35, `Student name: ${tempObj.userObj.firstname} ${tempObj.userObj.lastname}`);

        doc.text(10, 50, 'Task 1');
        doc.text(10, 55, `Question: ${tempObj.fullTestObj.sections[0].questions[1].question}`);
        if (tempObj.fullTestObj.sections[0].questions[1].passage.includes('https://')) {
            doc.addImage(tempObj.fullTestObj.sections[0].questions[1].passage, "PNG", 60, 60, 95, 70);
        } else {
            doc.text(10, 65, tempObj.fullTestObj.sections[0].questions[1].passage, { maxWidth: 190 });
        }

        doc.text(10, 140, 'Answer');
        doc.text(10, 145, tempObj.answerQ1Obj, { maxWidth: 190 });
        doc.line(10, 250, 195, 250);

        const dataQ2 = [
            {
                possibleImprovements: `Grammar: ${tempObj.grammarErrorCountQ2.grammar}`,
                metrics: `Words: ${tempObj.readabilityObjQ2.stats.counters.words}`
            },
            {
                possibleImprovements: `Spelling: ${tempObj.grammarErrorCountQ2.spelling}`,
                metrics: " "
            },
        ]
        doc.table(50, 255, dataQ2, headers, { autoSize: false, fontSize: 9 });

        doc.save('test');

        doc.html(tempObj.lastEditQ1, {
            callback: function (doc) {
                doc.save();
            },
            x: 10,
            y: 10
        });
    }

    const mobileUI = (<Grid container style={{ marginTop: "18%", marginBottom: "20%", maxWidth: window.innerWidth }}>
        <Grid item xs={12} style={{ marginBottom: "2%" }}>
            <Typography variant="h3" style={{ fontWeight: "bold" }} gutterBottom>
                <span style={{ color: "#991514" }}>U</span>TEST AVENUE
            </Typography>
            <Typography variant="body1">
                A smartly-designed platform for
            </Typography>
            <Typography variant="body1">
                simulation tests & intelligent skill analysis
            </Typography>
        </Grid>
        <Grid item xs={12} style={{ marginTop: "5%" }}>
            <Link
                to="/freetests"
                style={{ textDecoration: 'none', color: '#565656' }}
            >
                <Button
                    variant="contained"
                    size="large"
                    style={{ backgroundColor: "#3f5e75", color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1rem" }}
                    disableElevation
                >
                    Free tests
                </Button>
            </Link>
        </Grid>
    </Grid>)

    const webUI = (<Container>
        <Grid container spacing={2} style={{ marginTop: "5%", marginBottom: "30%", maxWidth: window.innerWidth }}>
            <Grid item align="left" xs={12}>
                <Typography variant="h4" style={{ fontWeight: "bold" }}>
                    Finances
                </Typography>
            </Grid>
            <Grid item xs={12} style={{ height: 400, width: '100%' }}>
                <DataGrid
                    rows={teachers}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    disableSelectionOnClick={false}
                    onSelectionModelChange={(newSelectionModel) => {
                        handleSelectTeacher(newSelectionModel);
                    }}
                />
            </Grid>
            <Grid item align="center" xs={12}>
                <Grid container spacing={3}>
                    <Grid item xs>
                        <Paper elevation={0} style={{ padding: "70px", height: "320px", maxHeight: "320px" }}>
                            <Typography variant="h5" style={{ fontWeight: "bold" }}>
                                Writing
                            </Typography>

                            {writingArr
                                ? <Typography variant="h1" style={{ fontWeight: "bold" }}>
                                    {writingArr.length}
                                </Typography>
                                : <CircularProgress size={100} disableShrink style={{ color: "#991514", fontSize: "100px", marginTop: "20px" }} />
                            }
                            {writingArr && <Typography variant="h6">
                                {`(Total ${(writingArr.length * 100).toLocaleString()} Baht)`}
                            </Typography>
                            }

                            {(writingArr && writingArr.length > 0) &&
                                <Button disableElevation className="button" variant="contained" onClick={() => { handleGeneratePdf('writing', writingArr.length, writingArr) }} style={{ marginTop: '20px' }}>
                                    Generate Invoice
                                </Button>
                            }
                        </Paper>
                    </Grid>
                    <Grid item xs>
                        <Paper elevation={0} style={{ padding: "70px", height: "320px", maxHeight: "320px" }}>
                            <Typography variant="h5" style={{ fontWeight: "bold" }}>
                                Speaking
                            </Typography>

                            {speakingArr
                                ? <Typography variant="h1" style={{ fontWeight: "bold" }}>
                                    {speakingArr.length}
                                </Typography>
                                : <CircularProgress size={100} disableShrink style={{ color: "#991514", fontSize: "100px", marginTop: "20px" }} />
                            }
                            {speakingArr && <Typography variant="h6">
                                {`(Total ${(speakingArr.length * 150).toLocaleString()} Baht)`}
                            </Typography>
                            }

                            {(speakingArr && speakingArr.length > 0) &&
                                <Button disableElevation className="button" variant="contained" onClick={() => { handleGeneratePdf('speaking', speakingArr.length, speakingArr) }} style={{ marginTop: '20px' }}>
                                    Generate Invoice
                                </Button>
                            }
                        </Paper>
                    </Grid>
                    <Grid item xs>
                        <Paper elevation={0} style={{ padding: "70px", height: "320px", maxHeight: "320px" }}>
                            <Typography variant="h5" style={{ fontWeight: "bold" }}>
                                Earning
                            </Typography>

                            {(speakingArr && writingArr)
                                ? <Typography variant="h1" style={{ fontWeight: "bold" }}>
                                    {((speakingArr?.length * 150) + (writingArr?.length * 100)).toLocaleString()}
                                </Typography>
                                : <CircularProgress size={100} disableShrink style={{ color: "#991514", fontSize: "100px", marginTop: "20px" }} />
                            }
                            {salary && <Typography variant="h6">
                                In total (Baht)
                            </Typography>
                            }
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </Container>)

    return <ThemeProvider theme={theme}>
        <Hidden mdUp>
            {mobileUI}
        </Hidden>
        <Hidden smDown>
            {webUI}
        </Hidden>
    </ThemeProvider>
};

export default compose(
    withStyles(useStyles)
)(FinancialPage);
