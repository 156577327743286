//React
import { Component } from 'react';
import { compose } from 'recompose';

//Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import AccessAlarmRoundedIcon from '@material-ui/icons/AccessAlarmRounded';
import AccountBoxRoundedIcon from '@material-ui/icons/AccountBoxRounded';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';
import VolumeDown from '@material-ui/icons/VolumeDown';
import VolumeUp from '@material-ui/icons/VolumeUp';

import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';

//import components
import ReactAudioPlayer from 'react-audio-player';
import PageBackdrop from '../main/PageBackdrop';
import InstructionsPage from './IELTS/listening/InstructionsPage';
import TestReportPage from './IELTS/listening/TestReportPage';
import TestSoundPage from './IELTS/listening/TestSoundPage';
import UserInfoPage from './IELTS/listening/UserInfoPage';
import ChooseTwoLetters from './IELTS/listening/questions/ChooseTwoLetters';
import CompleteTheNotes from './IELTS/listening/questions/CompleteTheNotes';
import CompleteTheTables from './IELTS/listening/questions/CompleteTheTables';
import Map from './IELTS/listening/questions/Map';
import Matching from './IELTS/listening/questions/Matching';
import MultipleChoice from './IELTS/listening/questions/MultipleChoice';

//Models
import { PrepareAnsObj } from '../../models/testSubmittion';

//other libraries
import ReactPlayer from 'react-player';
import {
    Link
} from "react-router-dom";

//Setup
const axios = require('axios').default;
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%'
    },
    rootContainer: {
        margin: 100
    },
    margin: {
        margin: 100
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginTop: theme.spacing(5)
    },
    questionNumber: {
        maxWidth: '30px',
        maxHeight: '30px',
        minWidth: '30px',
        minHeight: '30px',
        margin: '0.5%'
    }
});

//FONT RELATED
const theme = createMuiTheme({
    typography: {
        fontFamily: [
            'Noto Sans'
        ].join(','),
    },
});

class TestListening extends Component {
    constructor() {
        super();
        this.state = {
            volume: 0.5,
            isPlayTestSound: false,
            currentPage: 0,
            timer: 1200,
            timerSeconds: 0,
            questionCount: 20,
            answers: [],
            answeredQuestion: [],
            reviewedQuestion: [],
            questionNumArr: [],
            currentPart: 1,
            currentSection: 1,
            currentQuestion: 1,
            focusedQuestion: 0,
            progressSound: 0,
            window: {
                height: window.innerHeight,
                width: window.innerWidth
            },
            isResume: false,
            isStartTest: false,
            isCheckUserInfo: true,
            isReadInstruction: false,
            isTestSound: false,
            isConfirmDone: false
        };
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    componentDidMount = async () => {
        await this.createQuestionArr();

        const query = new URLSearchParams(this.props.location.search);
        const logQuery = query.get('log_id');
        console.log(logQuery);
        if (logQuery !== null) {
            if (logQuery.length !== 0) {
                await this.setState({
                    log_id: logQuery,
                    isResume: true
                });

                this.handleDoneReadInstruction();
            }
        }

        window.addEventListener("resize", this.handleResize);
        document.addEventListener('contextmenu', (e) => {
            e.preventDefault();
        });
        this.props.handleStartTest();
    }

    createQuestionArr = async () => {
        for (let i = 1; i < this.state.questionCount + 1; i++) {
            this.state.questionNumArr[i] = i;
        }

        this.setState({
            questionNumArr: this.state.questionNumArr
        });

        console.log(this.state.questionNumArr);
    }

    handleAnswerObj = (answerObj) => {
        const selectedPart = this.state.answerObj.parts.find(part => part.part === this.state.currentPart)
        const selectedSection = selectedPart.sections.find(section => section.section_id === answerObj.section_id)
        if (selectedSection) {
            console.log(this.state.answerObj);
            return;
        }
        selectedPart.sections.push(answerObj);
    }

    handleAnsweredQuestionNumber = (questionNo) => {
        this.state.answeredQuestion[questionNo] = true;
        this.setState({
            answeredQuestion: this.state.answeredQuestion
        })
    }

    handleCancelQuestionNumber = (questionNo) => {
        this.state.answeredQuestion[questionNo] = null;
        this.setState({
            answeredQuestion: this.state.answeredQuestion
        })
    }

    handleGetResult = async () => {
        try {
            this.handleLoading();

            const { history } = this.props;

            const testResult = await axios({
                method: 'post',
                url: api_base_url + "/api/test/report?part=LISTENING",
                headers: {
                    Authorization: "bearer " + JSON.parse(localStorage.getItem('user')).token
                },
                data: this.state.answerObj
            });

            await axios({
                method: 'post',
                url: api_base_url + "/api/test/result",
                headers: {
                    Authorization: "bearer " + JSON.parse(localStorage.getItem('user')).token
                },
                data: {
                    test_type: "IELTS LISTENING FREE",
                    test_result: testResult.data,
                    test_id: testResult.data.test_id,
                    username: JSON.parse(localStorage.getItem('user')).username
                }
            });

            this.setState({
                testReportObj: testResult.data
            });

            clearInterval(this.state.myInterval);

            await this.handleEndTest();
            await this.props.handleEndTest();
            history.push('/test/evaluation?test-type=IELTS LISTENING FREE&test-id=' + testResult.data.test_id);
            this.handleCloseLoading();

        } catch (error) {
            await this.props.handleEndTest();
            this.handleCloseLoading();
            console.error(error);
        }
    }

    handleConfirmDoneTestDialog = async () => {
        const answerCountArr = await this.state.answeredQuestion.filter(answer => answer);

        if (answerCountArr.length != this.state.questionCount) {
            this.setState({
                isErrorConfirmDone: true
            })
        } else if (answerCountArr.length == this.state.questionCount) {
            this.setState({
                isConfirmDone: true
            })
        }
    }

    handleCloseConfirmDoneTestDialog = () => {
        this.setState({
            isConfirmDone: false,
            isErrorConfirmDone: false
        })
    }

    handleDoneTestDialog = async () => {
        await this.handleGetResult();
        await this.props.handleEndTest();
        this.setState({
            isDone: true,
            isStartTest: false,
            isConfirmDone: false
        });
    }

    handleResize = (event) => {
        this.setState({
            window: {
                height: window.innerHeight,
                width: window.innerWidth
            }
        });
    };

    //======================== BEGIN BACKDROP CONTROL ==============================
    handleCloseLoading = () => {
        this.setState({
            isLoading: false
        });
    }

    handleLoading = () => {
        this.setState({
            isLoading: true
        });
    }
    //======================== END BACKDROP CONTROL ===============================

    //========================= TIMER =========================
    handleStartTimer = () => {
        console.log(this.state);
        this.myInterval = setInterval(() => {
            if (this.state.timer > 0) {
                if (this.state.timerSeconds === 0) {
                    this.setState({
                        timerSeconds: 60
                    })
                };

                this.setState(prevState => ({
                    timer: prevState.timer - 1,
                    timerSeconds: this.state.timerSeconds - 1
                }))
            }
        }, 1000);
    }

    handleAddLeadingZero = (num) => {
        if (num < 10) {
            return '0' + num
        } else if (num >= 10) {
            return '' + num
        }
    }
    //========================= TIMER =========================

    getTest = async () => {
        const getTest_url = api_base_url + "/api/test/random?test_type=IELTS&is_free=true";

        try {
            this.handleLoading();

            const testObj = await axios.get(getTest_url, {
                headers: {
                    "Authorization": "bearer " + JSON.parse(localStorage.getItem('user')).token
                }
            });

            let filteredTest = await testObj.data.test.parts.filter(parts => parts.part_type === 'LISTENING');
            
            const answerObj = await PrepareAnsObj(filteredTest, testObj.data.test.test_id);

            await this.setState({
                answerObj: answerObj,
                test_id: testObj.data.test.test_id,
                log_id: testObj.data.log_id,
                currentTestObject: filteredTest,
                currentSection: 1,
                currentPart: 1,
                playingSound: filteredTest[0].sections[0].questions[0].voice,
                numPart: filteredTest.length
            });

            await this.handleCreateTestLog();
            this.handleUpdateTestLog();

            this.handleCloseLoading();

        } catch (error) {
            this.handleCloseLoading();
            console.error(error);
        }
    }

    //=============================== TEST LOG & RESUME TEST ============================================
    handleResumeTest = async () => {
        const logObj = await this.handleGetLogObjById();

        if (!logObj) {
            return false;
        }

        const getTest_url = api_base_url + "/api/test?test_id=" + logObj.test_id;

        try {
            this.handleLoading();

            const testObj = await axios.get(
                getTest_url,
                {
                    headers: {
                        "Authorization": "bearer " + JSON.parse(localStorage.getItem('user')).token
                    }
                });
            let filteredTest = testObj.data[0].parts.filter(part => part.part_type === "LISTENING");

            this.state.answerObj.test_id = testObj.data[0].test_id;

            await this.setState({
                test_id: testObj.data[0].test_id,
                log_id: this.state.log_id,
                currentTestObject: filteredTest,
                currentSection: 1,
                currentPart: 1,
                playingSound: filteredTest[0].sections[0].questions[0].voice,
                numPart: filteredTest.length,
                answerObj: logObj.answerObj,
                answeredQuestion: logObj.answeredQuestion,
                timer: logObj.timer,
                timerSeconds: logObj.timerSeconds
            });

            this.handleUpdateTestLog();
            this.handleCloseLoading();

            return true;

        } catch (error) {
            this.handleCloseLoading();
            console.error(error);
        }
    }

    handleGetLogObjById = async () => {
        const oneHour = 60 * 60 * 1000;

        try {
            const responseObj = await axios({
                method: 'get',
                url: 'https://chatter-funky-busby.glitch.me/test/status/logs/' + this.state.log_id
            });

            if (((new Date()) - new Date(responseObj.data.createdAt)) > oneHour) {
                this.setState({
                    isExpiredTest: true
                });

                return false;
            }

            else if (((new Date()) - new Date(responseObj.data.createdAt)) < oneHour) {
                return responseObj.data;
            }

        } catch (error) {
            console.error(error);
            return error;
        }
    }

    handleExpiredTest = () => {
        this.setState({
            isExpiredTest: false
        });

        this.handleEndTest();
        this.props.handleEndTest();
        clearInterval(this.state.myInterval);
    }

    handleCreateTestLog = async () => {
        try {
            const responseObj = await axios({
                method: 'post',
                url: 'https://chatter-funky-busby.glitch.me/test/status/log',
                data: {
                    username: JSON.parse(localStorage.getItem('user')).username,
                    test_id: this.state.test_id,
                    log_id: this.state.log_id,
                    test_type: 'IELTS',
                    test_part: 'LISTENING',
                    answerObj: this.state.answerObj,
                    answeredQuestion: this.state.answeredQuestion,
                    timer: this.state.timer,
                    timerSeconds: this.state.timerSeconds
                }
            })

            console.log(responseObj);
        } catch (error) {
            console.error(error);
        }
    }

    handleUpdateTestLog = () => {
        const myInterval = setInterval(() => {
            if (this.props.isTest) {
                axios({
                    method: 'put',
                    url: 'https://chatter-funky-busby.glitch.me/test/status/log',
                    data: {
                        log_id: this.state.log_id,
                        answerObj: this.state.answerObj,
                        answeredQuestion: this.state.answeredQuestion,
                        timer: this.state.timer,
                        timerSeconds: this.state.timerSeconds
                    }
                })
            }
        }, 60000);

        this.setState({
            myInterval: myInterval
        });
    }

    handleEndTest = () => {
        try {
            axios({
                method: 'get',
                url: "https://chatter-funky-busby.glitch.me/test/status/logs/" + this.state.log_id + "/done"
            });
        } catch (error) {
            console.error(error);
        }
    }
    //=============================== TEST LOG & RESUME TEST ============================================

    handleQuestionType = (section) => {
        if (section.question_type === "multipleChoice") {
            return <MultipleChoice
                key={section.section_id}
                currentTestObject={section}
                answer={this.state.answer}
                handleChange={this.handleChange}
                playingSound={this.state.playingSound}
                volume={this.state.volume}
                currentSection={this.state.currentSection - 1}
                currentPart={this.state.currentPart - 1}
                handleAnswerObj={this.handleAnswerObj}
                answerObj={this.state.answerObj}
                handleAnsweredQuestionNumber={this.handleAnsweredQuestionNumber}
                onFocusQuestion={this.onFocusQuestion}
            />
        }

        else if (section.question_type === "matching") {
            return <Matching
                key={section.section_id}
                currentTestObject={section}
                answer={this.state.answer}
                handleAnswer={this.handleAnswer}
                playingSound={this.state.playingSound}
                volume={this.state.volume}
                currentSection={this.state.currentSection - 1}
                currentPart={this.state.currentPart - 1}
                handleAnswerObj={this.handleAnswerObj}
                answerObj={this.state.answerObj}
                handleCancelQuestionNumber={this.handleCancelQuestionNumber}
                handleAnsweredQuestionNumber={this.handleAnsweredQuestionNumber}
                onFocusQuestion={this.onFocusQuestion}
            />
        }

        else if (section.question_type === "completeTheTables") {
            return <CompleteTheTables
                key={section.section_id}
                currentTestObject={section}
                answer={this.state.answer}
                handleAnswer={this.handleAnswer}
                playingSound={this.state.playingSound}
                volume={this.state.volume}
                currentSection={this.state.currentSection - 1}
                currentPart={this.state.currentPart - 1}
                handleAnswerObj={this.handleAnswerObj}
                answerObj={this.state.answerObj}
                handleAnsweredQuestionNumber={this.handleAnsweredQuestionNumber}
                onFocusQuestion={this.onFocusQuestion}
            />
        }

        else if (section.question_type === "completeTheNotes") {
            return <CompleteTheNotes
                key={section.section_id}
                currentTestObject={section}
                answer={this.state.answer}
                handleAnswer={this.handleAnswer}
                playingSound={this.state.playingSound}
                volume={this.state.volume}
                currentSection={this.state.currentSection - 1}
                currentPart={this.state.currentPart - 1}
                handleAnswerObj={this.handleAnswerObj}
                answerObj={this.state.answerObj}
                handleAnsweredQuestionNumber={this.handleAnsweredQuestionNumber}
                onFocusQuestion={this.onFocusQuestion}
            />
        }

        else if (section.question_type === "choose2Letters") {
            return <ChooseTwoLetters
                key={section.section_id}
                currentTestObject={section}
                answer={this.state.answer}
                handleAnswer={this.handleAnswer}
                playingSound={this.state.playingSound}
                volume={this.state.volume}
                currentSection={this.state.currentSection - 1}
                currentPart={this.state.currentPart - 1}
                handleAnswerObj={this.handleAnswerObj}
                answerObj={this.state.answerObj}
                handleAnsweredQuestionNumber={this.handleAnsweredQuestionNumber}
                onFocusQuestion={this.onFocusQuestion}
            />
        }

        else if (section.question_type === "map") {
            return <Map
                key={section.section_id}
                currentTestObject={section}
                answer={this.state.answer}
                handleAnswer={this.handleAnswer}
                playingSound={this.state.playingSound}
                volume={this.state.volume}
                currentSection={this.state.currentSection - 1}
                currentPart={this.state.currentPart - 1}
                handleAnswerObj={this.handleAnswerObj}
                answerObj={this.state.answerObj}
                handleAnsweredQuestionNumber={this.handleAnsweredQuestionNumber}
                onFocusQuestion={this.onFocusQuestion}
            />
        }
    }

    onFocusQuestion = (questionNumber) => {
        this.setState({
            focusedQuestion: questionNumber
        });
        console.log(questionNumber);
    }

    handleReviewQuestion = (event) => {
        this.state.reviewedQuestion[Number(this.state.focusedQuestion)] = event.target.checked;
        this.setState({
            reviewedQuestion: this.state.reviewedQuestion
        });

        console.log(this.state.reviewedQuestion);
    }

    handleChangeQuestion = async (action) => {
        if (action === 'Next') {
            const sectionLength = this.state.currentTestObject[this.state.currentPart - 1].sections.length

            if (this.state.currentPart === 4) {
                return;
            }

            else if (this.state.currentSection >= sectionLength) {
                await this.setState({
                    currentPart: this.state.currentPart + 1,
                    currentSection: 1
                });
                if (this.state.currentPart > this.state.progressSound) {
                    this.setState({
                        progressSound: this.state.currentPart
                    });
                }

                console.log(this.state.progressSound);
                return;
            }

            else if (this.state.currentSection < sectionLength) {
                this.setState({
                    currentSection: this.state.currentSection + 1
                });
                return;
            }

            else if (this.state.currentPart >= this.state.numPart) {
                return;
            }
        }

        else if (action === 'Back') {
            if (this.state.currentPart === 1) {
                return;
            }

            else if (this.state.currentSection === 1) {
                this.setState({
                    currentPart: this.state.currentPart - 1,
                    currentSection: this.state.currentTestObject[this.state.currentPart - 2].sections.length
                });
                console.log(this.state);
                return;
            }

            else if (this.state.currentSection > 1) {
                this.setState({
                    currentSection: this.state.currentSection - 1,
                });
                console.log(this.state);
                return;
            }
        }
    }

    handleChangeVolume = (event, currentVolume) => {
        let volumeValue = currentVolume / 100
        this.setState({
            volume: volumeValue
        });
    };

    handleSoundControl = (action) => {
        if (action === 'play') {
            this.setState({
                isPlayTestSound: true
            });
        } if (action === 'pause') {
            this.setState({
                isPlayTestSound: false
            });
        } if (action === 'stop') {
            this.setState({
                isPlayTestSound: false
            });
        }
    }

    handleContinue = async () => {
        await this.setState({
            currentPage: this.state.currentPage + 1
        });

        if (this.state.currentPage === 2) {
            this.handleStartTimer();
            //this.handleSoundControl('play')
        }
    }

    handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        const isAnswered = 'q' + name.split('_')[1];
        let ansArr = this.state.answers;

        if (this.state[isAnswered] === undefined) {
            let ansObj = {
                question: name.split('_')[1],
                answer: value,
                section: name.split('_')[0]
            };
            ansArr.push(ansObj);
        }

        else if (this.state[isAnswered] !== undefined) {
            let index = ansArr.findIndex(obj => obj.question === name.split('_')[1]);

            ansArr[index].answer = value;
        }

        this.setState({
            ...this.state,
            answers: ansArr,
            [isAnswered]: true
        });

    };

    handleAnswer = (question) => {
        const isAnswered = 'q' + question.split('_')[1];
        console.log(question);
        this.setState({
            ...this.state,
            [isAnswered]: true
        });

    };

    //================================= BEGIN PRE-TEST ================================
    handleUserInfoPage = () => {
        this.setState({
            isStartTest: false,
            isCheckUserInfo: true,
            isReadInstruction: false,
            isTestSound: false
        })
    }

    handleUserInfoChecked = () => {
        this.setState({
            isStartTest: false,
            isCheckUserInfo: false,
            isReadInstruction: false,
            isTestSound: true
        })
    }

    handleDoneSoundTest = () => {
        this.setState({
            isStartTest: false,
            isCheckUserInfo: false,
            isReadInstruction: true,
            isTestSound: false
        })
    }

    handleDoneReadInstruction = async () => {
        if (this.state.isResume) {
            const isNotExpired = await this.handleResumeTest();
            if (!isNotExpired) {
                this.setState({
                    isStartTest: false,
                    isCheckUserInfo: false,
                    isReadInstruction: false,
                    isTestSound: false
                });
            }

            else {
                this.setState({
                    isStartTest: true,
                    isCheckUserInfo: false,
                    isReadInstruction: false,
                    isTestSound: false
                })

                this.handleStartTimer();
            }

            return;

        } else if (!this.state.isResume) {
            await this.getTest();
        }

        this.setState({
            isStartTest: true,
            isCheckUserInfo: false,
            isReadInstruction: false,
            isTestSound: false
        })

        this.handleStartTimer();
    }
    //================================= END PRE-TEST =================================

    render() {
        const { classes } = this.props;

        return (
            <ThemeProvider theme={theme}>
                <div
                    className={classes.root}
                    onPaste={(e) => {
                        e.preventDefault()
                        return false;
                    }}
                    onCopy={(e) => {
                        e.preventDefault()
                        return false;
                    }}
                >
                    <PageBackdrop isLoading={this.state.isLoading} />

                    <Dialog
                        open={this.state.isConfirmDone}
                        onClose={this.handleCloseConfirmDoneTestDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"There's still some time remaining."}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Please take a moment to ensure you've answered all the questions before submitting. Are you sure you want to submit the test? If so, please click. <Box fontWeight="fontWeightBold" display='inline'>Confirm</Box>.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleCloseConfirmDoneTestDialog} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={this.handleDoneTestDialog} color="primary" autoFocus>
                                Confirm
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        open={this.state.isErrorConfirmDone}
                        onClose={this.handleCloseConfirmDoneTestDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"You have not answer all questions"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Are you sure that you still want to submit the test?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleCloseConfirmDoneTestDialog} color="primary">
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        open={this.state.isExpiredTest}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"Please confirm that There's still some time remaining."}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                This test session is already <Box fontWeight="fontWeightBold" display='inline'>expired</Box> please start a new one.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Link to="/" style={{ textDecoration: 'none', color: '#0f0f0f' }}>
                                <Button onClick={this.handleExpiredTest} color="primary">
                                    back
                                </Button>
                            </Link>
                        </DialogActions>
                    </Dialog>

                    <Grid container justify='center' style={{ maxHeight: this.state.window.height, padding: '20px' }}>
                        {!this.state.isDone &&
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid align='center' item xs={3}>
                                        <Typography variant="subtitle1" align='center'>
                                            <AccountBoxRoundedIcon /> {this.props.user.name}
                                        </Typography>
                                    </Grid>
                                    <Grid align='center' item xs={6}>
                                        <Typography variant="h5">
                                            <AccessAlarmRoundedIcon /> {this.handleAddLeadingZero(Math.floor(this.state.timer / 3600))} : {this.state.timer === 3600 ? '00' : this.handleAddLeadingZero(Math.floor(this.state.timer / 60))} : {this.handleAddLeadingZero(this.state.timerSeconds)} minutes left
                                        </Typography>
                                    </Grid>
                                    <Grid align='center' item xs={3}>
                                        <Grid container>
                                            <Grid item xs={10} style={{ padding: '3%' }}>
                                                <Paper>
                                                    <Grid container spacing={1} align='right'>
                                                        <Grid item>
                                                            <VolumeDown />
                                                        </Grid>
                                                        <Grid item xs>
                                                            <Slider value={this.state.volume * 100} onChange={this.handleChangeVolume} aria-labelledby="continuous-slider" />
                                                        </Grid>
                                                        <Grid item>
                                                            <VolumeUp />
                                                        </Grid>
                                                    </Grid>
                                                </Paper>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Link to="/" style={{ textDecoration: 'none', color: '#0f0f0f' }}>
                                                    <IconButton color="primary" component="span" onClick={this.props.handleEndTest}>
                                                        <HomeRoundedIcon style={{ color: 'black' }} />
                                                    </IconButton>
                                                </Link>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                        {this.state.isCheckUserInfo &&
                            <Grid item align='center' xs={12}>
                                <UserInfoPage
                                    user={this.props.user}
                                    handleUserInfoChecked={this.handleUserInfoChecked}
                                    handleEndTest={this.props.handleEndTest}
                                />
                            </Grid>
                        }
                        {this.state.isTestSound &&
                            <Grid item align='center' xs={12}>
                                <TestSoundPage
                                    handleUserInfoPage={this.handleUserInfoPage}
                                    handleSoundControl={this.handleSoundControl}
                                    handleDoneSoundTest={this.handleDoneSoundTest}
                                    isPlayTestSound={this.state.isPlayTestSound}
                                />
                                <ReactPlayer
                                    volume={this.state.volume}
                                    url='/TESTING SOUND (IELTS LISTENING).wav'
                                    playing={this.state.isPlayTestSound}
                                    loop={true}
                                />
                            </Grid>
                        }
                        {this.state.isReadInstruction &&
                            <Grid item align='center' xs={12}>
                                <InstructionsPage
                                    handleUserInfoChecked={this.handleUserInfoChecked}
                                    handleDoneReadInstruction={this.handleDoneReadInstruction}
                                />
                            </Grid>
                        }
                        {this.state.isStartTest && !this.state.isDone &&
                            <Grid item align='center' xs={12}>
                                <Paper
                                    variant="outlined"
                                    style={{
                                        marginTop: '20px',
                                        height: this.state.window.height - 180,
                                        width: '100%',
                                        backgroundImage: `url("/U-Test-Logo-new-bw.png")`,
                                        backgroundSize: '120px',
                                        backgroundRepeat: 'space'
                                    }}>
                                    <Grid container justify='center' >
                                        <Grid item xs={12} sm={12} style={{
                                            overflow: 'auto',
                                            height: this.state.window.height - 180,
                                            maxHeight: this.state.window.height - 180,
                                            marginTop: '0px',
                                            padding: '20px'
                                        }}>
                                            {this.handleQuestionType(this.state.currentTestObject[this.state.currentPart - 1].sections[this.state.currentSection - 1])}
                                        </Grid>
                                        {this.state.progressSound <= this.state.currentPart &&
                                            <ReactAudioPlayer
                                                src={this.state.currentTestObject[this.state.currentPart - 1].sections[this.state.currentSection - 1].questions[0].voice}
                                                volume={this.state.volume}
                                                autoPlay
                                            />
                                        }
                                    </Grid>
                                </Paper>
                            </Grid>
                        }
                        {!this.state.isStartTest && this.state.isDone &&
                            <Grid item align='center' xs={12}>
                                <Grid container justify='center' >
                                    <Grid item xs={12} sm={12} style={{
                                        overflow: 'auto',
                                        marginTop: '50px'
                                    }}>
                                        <Paper variant="outlined" style={{ padding: '100px' }}>
                                            <TestReportPage
                                                testReportObj={this.state.testReportObj}
                                                handleEndTest={this.props.handleEndTest}
                                            />
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                        {this.state.isStartTest && !this.state.isDone &&
                            <Grid item align='center' xs={12} style={{ position: 'absolute', bottom: 0, width: '95%' }}>
                                <Grid container>
                                    <Grid item xs={1} style={{ padding: '1%', paddingTop: '0%' }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={this.state.reviewedQuestion[this.state.focusedQuestion] ? this.state.reviewedQuestion[this.state.focusedQuestion] : false}
                                                    onChange={this.handleReviewQuestion}
                                                    name="reviewed"
                                                    color="primary"
                                                />
                                            }
                                            label="Review"
                                        />
                                    </Grid>
                                    <Grid item xs={10} align='left'>
                                        <Paper variant="outlined">
                                            {this.state.questionNumArr.map((num) => {
                                                return <Button
                                                    key={num}
                                                    size="small"
                                                    style={{
                                                        borderRadius: this.state.reviewedQuestion[num] ? 75 : 5,
                                                        color: this.state.answeredQuestion[num] === undefined || this.state.answeredQuestion[num] === null ? "#76323F" : "",
                                                        backgroundColor: this.state.answeredQuestion[num] === undefined || this.state.answeredQuestion[num] === null ? "" : "#76323F",
                                                        borderColor: this.state.answeredQuestion[num] === undefined || this.state.answeredQuestion[num] === null ? "#76323F" : ""
                                                    }}
                                                    variant={this.state.answeredQuestion[num] === undefined || this.state.answeredQuestion[num] === null ? "outlined" : "contained"}
                                                    color="primary"
                                                    className={classes.questionNumber}>
                                                    {num}
                                                </Button>
                                            })}
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={1} align='right'>
                                        <Grid container>
                                            <Grid item xs={6}>
                                                {!(this.state.currentSection === 1 && this.state.currentPart === 1) &&
                                                    <IconButton aria-label="add to favorites" onClick={() => { this.handleChangeQuestion('Back') }}>
                                                        <ArrowBackRoundedIcon />
                                                    </IconButton>
                                                }
                                            </Grid>
                                            <Grid item xs={6} style={{ marginTop: (this.state.currentPart < this.state.numPart) ? '0%' : '5%' }}>
                                                {(this.state.currentPart < this.state.numPart) ?
                                                    <IconButton aria-label="add to favorites" onClick={() => { this.handleChangeQuestion('Next') }}>
                                                        <ArrowForwardRoundedIcon />
                                                    </IconButton> : <Button size="small" variant="contained" onClick={this.handleConfirmDoneTestDialog} style={{ backgroundColor: '#76323F', color: 'white' }}>
                                                        Done
                                                    </Button>
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                </div >
            </ThemeProvider>
        )
    }
}

export default compose(
    withStyles(useStyles)
)(TestListening);