import * as apiConstants from '../constants/apiConstants';

const axios = require('axios').default;

export async function ValidateDuplicateTest(username, type) {
    try {
        const res = await axios({
            method: 'get',
            url: `${apiConstants.UTESTAVENUE_SYSTEM_API_BASE_URL}/users/${username}/purchase-history/type/${type}/duplication-check`,
        });

        return {
            success: true,
            res: res.data
        }

    } catch (err) {
        console.log(err);

        return {
            success: false,
            res: err
        }
    }
}