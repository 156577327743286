//React
import React, { useEffect, useState } from 'react';
import { compose } from "recompose";

//Material UI
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
//import Fade from '@material-ui/core/Fade';
import Typography from "@material-ui/core/Typography";

import { withStyles } from "@material-ui/core/styles";

//Icons
import ArrowForwardRoundedIcon from "@material-ui/icons/ArrowForwardRounded";

//Animations
import Fade from "react-reveal/Fade";

//react-router-dom
import { Link } from "react-router-dom";

//Constants
import * as styleConstants from "../../../../constants/styleConstants";

//Others
import { Player, ControlBar, PlayToggle, VolumeMenuButton } from "video-react";
import VisibilitySensor from 'react-visibility-sensor';
import "video-react/dist/video-react.css";

const AboutUsSectionWeb = () => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <Grid container style={{ paddingLeft: "30px", paddingRight: "30px" }}>
      <Grid item align="center" xs={12} sm={6} style={{ padding: "10%", zIndex: 1 }}>
        <Grid container justify="center">
          <VisibilitySensor onChange={(isVisible) => {
            setIsVisible(isVisible);
          }}>
            <Fade top when={isVisible}>
              <Grid item xs={12}>
                <Typography
                  variant="h3"
                  style={{
                    fontWeight: styleConstants.fontWeight.bold,
                    fontFamily: styleConstants.fontStyle,
                    color: "#991514",
                  }}
                >
                  ABOUT US
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ marginTop: "10%" }}>
                <Typography variant="h6" style={{ fontFamily: styleConstants.fontStyle, fontWeight: styleConstants.fontWeight.thin, }}>
                  We corporate our years of teaching experience with innovative
                  machine learning for your personalised test preparations.
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ marginTop: "10%" }}>
                <Link
                  to="/aboutus"
                  style={{ textDecoration: "none", color: "#0f0f0f" }}
                >
                  <Button
                    variant="contained"
                    size="large"
                    endIcon={<ArrowForwardRoundedIcon />}
                    style={{ backgroundColor: "white" }}
                  >
                    <Typography
                      variant="h6"
                      style={{ fontWeight: styleConstants.fontWeight.bold, fontFamily: styleConstants.fontStyle }}
                    >
                      Learn more
                    </Typography>
                  </Button>
                </Link>
              </Grid>
            </Fade>
          </VisibilitySensor>
        </Grid>
      </Grid>
      <Grid
        item
        align="center"
        xs={12}
        sm={6}
        style={{ padding: "10%", zIndex: 1, paddingLeft: "0px" }}
      >
        <Fade when={isVisible}>
          <Player
            autoPlay={false}
            preload="auto"
            poster="/video-thumbnail.png"
            src="/UTEST AVENUE (MAIN PAGE VID).mp4"
            fluid={false}
            width={600}
          >
            <ControlBar autoHide={false} disableDefaultControls={true}>
              <PlayToggle />
              <VolumeMenuButton />
            </ControlBar>
          </Player>
        </Fade>
      </Grid>
    </Grid>
  )
};

export default AboutUsSectionWeb;

