import React from 'react';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

import { withStyles } from '@material-ui/core/styles';
import { Component } from 'react';
import { compose } from 'recompose';

//Components
import LoadingBackdrop from '../../public/components/LoadingBackdrop';

//Others libraries
import moment from 'moment';
import Chart from "react-google-charts";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import PageBackdrop from '../../../main/PageBackdrop';

//Others
const axios = require('axios').default;

const useStyles = theme => ({
    root: {
        margin: 15,
        marginTop: 50
    }
});

class Income extends Component {
    constructor() {
        super()
        this.state = {
            testGroup: [],
            isBarChartView: false,
            isLoading: false,
            year: 2024,
            discountCodeUsage: [],
        };
    }

    componentDidMount = async () => {
        this.setState({
            isLoading: true
        });

        await this.handleGetAllDiscountCodeUsage();
        await this.handleGetAllCharges();

        this.setState({
            isLoading: false
        });
    }

    handleChange = async (event) => {
        this.setState({
            isLoading: true
        });

        const name = event.target.name;
        this.setState({
            ...this.state,
            [name]: event.target.value,
        });

        await this.handleGetAllCharges();

        this.setState({
            isLoading: false
        });
    };

    handleGetAllCharges = async () => {
        try {
            const res = await axios({
                method: 'get',
                url: 'https://almond-magnetic-sale.glitch.me/charges',
            });

            const chargeObj = res.data.list.data.filter(charge => charge.paid);

            await this.handleRemoveTime(chargeObj);
            this.setState({
                charges: chargeObj
            });

        } catch (err) {
            console.log(err);
        }
    }

    handleGetAllDiscountCodeUsage = async () => {
        try {
            const resDC = await axios({
                method: 'get',
                url: 'https://erratic-noiseless-wasabi.glitch.me/utestavenue-system-api/api/v1/discounts',
            });

            const resDCData = resDC.data.discounts.filter((dc) => !["117337903114766421177", "phisonkha"].includes(dc.createdBy) && dc.discountCode !== "JPROMSO");

            const resDCU = await axios({
                method: 'get',
                url: 'https://erratic-noiseless-wasabi.glitch.me/utestavenue-system-api/api/v1/discountsUsage/used',
            });

            const resDCUData = resDCU.data.discountsUsage.filter((dcu) => !["bellebelle", "117337903114766421177", "phisonkha", "jirayu.promsongwong@hotmail.com"].includes(dcu.usedBy) && dcu.discountCode !== "JPROMSO");

            resDCUData.map((dcu) => {
                if (resDCData.find(dc => dc.discountCode === dcu.discountCode)) {
                    dcu.amount = resDCData.find(dc => dc.discountCode === dcu.discountCode).discount;
                }
            });

            this.setState({
                discountCodeUsage: resDCUData
            });

        } catch (err) {
            console.log(err);
        }
    }

    handleRemoveTime = async (chargesArr) => {
        let groups = [];
        let chargeGroupToShowLine = [
            { month: '01', amount: 0 },
            { month: '02', amount: 0 },
            { month: '03', amount: 0 },
            { month: '04', amount: 0 },
            { month: '05', amount: 0 },
            { month: '06', amount: 0 },
            { month: '07', amount: 0 },
            { month: '08', amount: 0 },
            { month: '09', amount: 0 },
            { month: '10', amount: 0 },
            { month: '11', amount: 0 },
            { month: '12', amount: 0 },
        ];
        let chargeGroupToShowBar = [
            ['Date', 'Income']
        ];

        console.log(this.state.discountCodeUsage, this.state.year);

        const filtered = chargesArr.filter((charge) => { return moment(charge.paid_at).format("YYYY") === this.state.year.toString(); });
        const filtered2 = this.state.discountCodeUsage.filter((dc) => moment(dc.createdAt).format("YYYY") === this.state.year.toString());
        await filtered.map(async (charge, index) => {
            const month = new Date(charge.paid_at).getMonth() + 1;
            const monthSrt = month >= 10 ? month + '' : '0' + month
            if (!groups[monthSrt]) {
                groups[monthSrt] = [];
            }

            let foundIndex = await chargeGroupToShowLine.findIndex(x => x.month === monthSrt);
            groups[monthSrt].push(charge);
            chargeGroupToShowLine[foundIndex].amount = chargeGroupToShowLine[foundIndex].amount + (charge.amount / 100)
        })

        await filtered2.map(async (dc, index) => {
            if (dc.amount) {
                const month = new Date(dc.createdAt).getMonth() + 1;
                const monthSrt = month >= 10 ? month + '' : '0' + month
                if (!groups[monthSrt]) {
                    groups[monthSrt] = [];
                }

                let foundIndex = await chargeGroupToShowLine.findIndex(x => x.month === monthSrt);
                groups[monthSrt].push(dc);
                chargeGroupToShowLine[foundIndex].amount = chargeGroupToShowLine[foundIndex].amount + Number(dc.amount)
            }
        })

        await chargeGroupToShowLine.map((chargeGroup) => {
            chargeGroupToShowBar.push([
                chargeGroup.month,
                chargeGroup.amount
            ]);
        })

        this.setState({
            chargeGroup: groups,
            chargeGroupToShowLine: chargeGroupToShowLine,
            chargeGroupToShowBar: chargeGroupToShowBar
        });
    }

    handleToggleBarChartView = () => {
        this.setState({
            isBarChartView: !this.state.isBarChartView
        })
    }

    render() {
        const { classes } = this.props;

        return (
            <Paper style={{ padding: '35px' }} >
                <PageBackdrop isLoading={this.state.isLoading} />
                <Grid container>
                    <Grid item align='left' xs={12}>
                        <Typography variant="h5" gutterBottom>
                            Monthly income
                        </Typography>
                    </Grid>
                    <Grid item align='right' xs={12}>
                        <FormControlLabel
                            control={<Switch color="primary" checked={this.state.isBarChartView} onChange={this.handleToggleBarChartView} />}
                            label="Bar chart view"
                        />
                        <FormControl variant="outlined" style={{ margin: "10px", marginTop: "0px" }}>
                            <InputLabel htmlFor="outlined-age-native-simple">Year</InputLabel>
                            <Select
                                native
                                value={this.state.year}
                                onChange={this.handleChange}
                                label="Year"
                                inputProps={{
                                    name: 'year',
                                    id: 'outlined-year-native-simple',
                                }}
                            >
                                <option aria-label="None" value="" />
                                <option value={2022}>2022</option>
                                <option value={2023}>2023</option>
                                <option value={2024}>2024</option>
                            </Select>
                        </FormControl>
                    </Grid>
                    {!this.state.isBarChartView &&
                        <Grid item align='left' xs={12}>
                            <AreaChart
                                width={1000}
                                height={500}
                                data={this.state.chargeGroupToShowLine}
                                margin={{
                                    top: 10,
                                    right: 30,
                                    left: 0,
                                    bottom: 0,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="month" />
                                <YAxis />
                                <Tooltip />
                                <Area type="monotone" dataKey="amount" stroke="#8884d8" fill="#8884d8" />
                            </AreaChart>
                        </Grid>
                    }
                    {this.state.isBarChartView &&
                        <Grid item align='left' xs={12}>
                            <Chart
                                width={1000}
                                height={500}
                                chartType="Bar"
                                loader={<div>Loading Chart</div>}
                                data={this.state.chargeGroupToShowBar}
                                options={{
                                    // Material design options
                                    chart: {
                                        title: 'Income',
                                        subtitle: 'Overall profit based on number of test assigned.',
                                    },
                                }}
                                margin={{
                                    top: 10,
                                    right: 30,
                                    left: 0,
                                    bottom: 0,
                                }}
                                // For tests
                                rootProps={{ 'data-testid': '2' }}
                            />
                        </Grid>
                    }
                </Grid>
            </Paper >
        )
    }
}
export default compose(
    withStyles(useStyles)
)(Income);
