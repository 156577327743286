import React from 'react';

//Material UI
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import MuiAlert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

//Others
import Select from 'react-select';
import { withStyles } from '@material-ui/core/styles';
import { Component } from 'react';
import { compose } from 'recompose';

const axios = require('axios').default;
const FormData = require('form-data');
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

const useStyles = theme => ({
    root: {
        margin: 15,
        marginTop: 50
    },
    margin: {
        margin: theme.spacing(1),
    },
    buttonColor: {
        backgroundColor: '#76323F',
        color: 'white'
    }
});

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class CompleteTheTables extends Component {
    constructor() {
        super()
        this.state = {
            tempQuestionAnswer: [1],
            tempAnswerCount: [],
            questionAnsCount: 1
        };
    }

    componentDidMount = async () => {

    }

    handleEditVoiceUrl = async (event) => {
        this.props.questionObj.voice = event.target.value;
    }

    handleEditTableTitle = (event) => {
        this.props.questionObj.table_title = event.target.value;
    }

    handleEditQuestion = async (event) => {
        this.props.questionObj.question = event.target.value;
    }

    handleEditQuestionNumber = async (event) => {
        this.props.questionObj.no = `${event.target.value}-${Number(event.target.value) + 4}`;
        this.setState({
            beginQuestion: Number(event.target.value)
        });

        console.log(this.props.questionObj)
    }

    handleAddQuestion = () => {
        this.state.tempQuestionAnswer.push(this.state.questionAnsCount + 1);

        this.setState({
            tempQuestionAnswer: this.state.tempQuestionAnswer,
            questionAnsCount: this.state.questionAnsCount + 1
        });
    }

    handleEditColumnL = async (event, index) => {
        this.props.questionObj.columnL[index] = event.target.value;

        console.log(this.props.questionObj)
    }

    handleEditColumnR = async (event, index) => {
        this.props.questionObj.columnR[index] = event.target.value;
    }

    handleEditAnswer = async (event, index) => {
        this.props.questionObj.answer[index] = event.target.value;
    }

    calculateQuestionCount = (index) => {
        const count = this.props.questionObj.columnR[index].split("_").length - 1;

        return count
    }

    render() {
        const { classes } = this.props;

        return (
            <Grid item xs={12}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                                            Voice Url
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="outlined-size-small"
                                            variant="outlined"
                                            size="small"
                                            onChange={this.handleEditVoiceUrl}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                                            Table title
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            key="outlined-size-small-table-title"
                                            id="outlined-size-small-table-title"
                                            variant="outlined"
                                            size="small"
                                            onChange={this.handleEditTableTitle}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                                            Question
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            key="outlined-size-small-question"
                                            id="outlined-size-small-question"
                                            variant="outlined"
                                            size="small"
                                            onChange={this.handleEditQuestion}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Grid container spacing={1}>
                                    {this.state.tempQuestionAnswer.map((number, numIdx) =>
                                        <Grid item xs={12}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                                                        No.
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <TextField
                                                        id="outlined-size-small"
                                                        variant="outlined"
                                                        size="small"
                                                        value={(this.state.beginQuestion === undefined || this.state.beginQuestion === 0) ? "" : this.state.beginQuestion + numIdx}
                                                        onChange={this.handleEditQuestionNumber}
                                                        disabled={numIdx !== 0}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item style={{ marginTop: '4px' }}>
                                                    <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                                                        -
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <TextField
                                                        disabled
                                                        id="outlined-size-small"
                                                        variant="outlined"
                                                        size="small"
                                                        value={(this.state.beginQuestion === undefined || this.state.beginQuestion === 0) ? "" : this.state.beginQuestion + numIdx}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                                                                Context
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                id="outlined-size-small"
                                                                variant="outlined"
                                                                size="small"
                                                                onChange={(event) => { this.handleEditColumnL(event, numIdx) }}
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                                                                Question
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                id="outlined-size-small"
                                                                variant="outlined"
                                                                size="small"
                                                                onChange={(event) => { this.handleEditColumnR(event, numIdx) }}
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                                                                Answer
                                                            </Typography>
                                                        </Grid>
                                                        {
                                                        <Grid item xs={2}>
                                                            <TextField
                                                                id="outlined-size-small"
                                                                variant="outlined"
                                                                size="small"
                                                                onChange={(event) => { this.handleEditAnswer(event, numIdx) }}
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        }
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Divider style={{ backgroundColor: "#76323F", height: '5px', marginTop: '10px', marginBottom: '10px' }} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid item align="center" xs={12}>
                                <IconButton aria-label="add" onClick={this.handleAddQuestion} style={{ margin: 1 }}>
                                    <AddCircleRoundedIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}
export default compose(
    withStyles(useStyles)
)(CompleteTheTables);