//React
import React, { Component } from 'react';
import { compose } from 'recompose';

//Material UI
import Button from '@material-ui/core/Button';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';

//Icons
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';

//Constants
import * as styleConstants from "../../../../constants/styleConstants";

//react-router-dom
import {
    Link
} from "react-router-dom";

//Animations
import Fade from 'react-reveal/Fade';
import VisibilitySensor from 'react-visibility-sensor';

//Others
const axios = require('axios').default;

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%'
    },
    rootContainer: {
        maxWidth: '75%'

    },
    rootCard: {
        maxWidth: "75%",
        width: '75%',
        margin: 10,
    },
    gridContainer: {
        maxWidth: '90%'
    },
    margin: {
        margin: 100
    },
    marginTop: {
        marginTop: 100
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginTop: theme.spacing(5)
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    header: {
        maxWidth: "80%"
    },
    body: {
        maxWidth: "80%",
        marginTop: 30
    },
    divider: {
        maxWidth: "100%"
    },
    image: {
        margin: 'auto',
        display: 'block',
        margin: 10
    },
    commentTextField: {
        width: '100%',
        maxWidth: '100%'
    },
    commentGridItem: {
        maxWidth: "80%",
        marginTop: 30,
        marginBottom: 100
    }
});

const theme = createMuiTheme({
    typography: {
        fontFamily: [
            'Prompt'
        ].join(','),
    },
});


class ReviewsSectionWeb extends Component {
    constructor() {
        super();
        this.state = {
            isVisible: false
        };
    }

    componentDidMount() {
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    render() {
        const { classes } = this.props;

        return (
            <Grid container style={{ paddingLeft: "7%", paddingRight: "7%" }}>
                <VisibilitySensor onChange={(isVisible) => {
                    this.setState({ isVisible: isVisible })
                }}>
                    <Grid item align="center" xs={12} sm={6}>
                        <Fade when={this.state.isVisible}>
                            <Grid container>
                                <Grid item align="center" xs={12}>
                                    <Typography variant="h3" style={{ fontFamily: styleConstants.fontStyle, fontWeight: styleConstants.fontWeight.bold, color: "#991514" }} >
                                        TRUSTED BY MANY SUCCESSFUL USERS
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Fade>
                    </Grid>
                </VisibilitySensor>
                <Grid item align="center" xs={12} sm={6}>
                    <Fade top cascade when={this.state.isVisible}>
                        <Grid container spacing={5}>
                            <Grid item align="center" xs={12}>
                                <Typography variant="h6" style={{ fontFamily: styleConstants.fontStyle, fontWeight: styleConstants.fontWeight.thin, color: "#0f0f0f" }} >
                                    “The test is accurate with the actual online version of the IELTS exam :)”, Ratapakorn L.
                                </Typography>
                            </Grid>
                            <Grid item align="center" xs={12}>
                                <Typography variant="h6" style={{ fontFamily: styleConstants.fontStyle, fontWeight: styleConstants.fontWeight.thin, color: "#0f0f0f" }} >
                                    “I am about to get 100% on this 30mins easy peasy test!!!!!”, Watcharavith T.
                                </Typography>
                            </Grid>
                            <Grid item align="center" xs={12}>
                                <Typography variant="h6" style={{ fontFamily: styleConstants.fontStyle, fontWeight: styleConstants.fontWeight.thin, color: "#0f0f0f" }} >
                                    “Best website ever!”, Nichapon P.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Fade>
                </Grid>
            </Grid>
        )
    }
}

export default compose(
    withStyles(useStyles)
)(ReviewsSectionWeb);
