//React
import { Component } from 'react';
import { compose } from 'recompose';

//Material UI
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

//import components
import { DragDropContainer } from 'react-drag-drop-container';

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%'
    }
});

class ListOfHeadings extends Component {
    constructor() {
        super();

        this.state = {
            sizeMapHeader: {
                'sm': 'subtitle2',
                'md': 'body2',
                'lg': 'body1',
                'xl': 'h6',
            },
            sizeMapBody: {
                'sm': 'subtitle2',
                'md': 'body2',
                'lg': 'body1',
                'xl': 'h6',
            },
            sizeMapCaption: {
                'sm': 'caption',
                'md': 'subtitle2',
                'lg': 'body2',
                'xl': 'body1',
            },
            sizeMapCustom: {
                'sm': 0.7,
                'md': 0.8,
                'lg': 1,
                'xl': 1.2,
            }
        };
    }

    componentDidMount = () => {
        this.setState({
            answerObj: {
                section_id: this.props.sectionInfo.section_id,
                question_type: this.props.sectionInfo.question_type,
                answers: {}
            }
        });
    }

    handleAnswerQuestion = async () => {
        this.state.answerObj.answers = this.props.dropItems;
        this.props.handleAnswerObj(this.state.answerObj);
    }


    render() {
        const { classes } = this.props;

        return (
            <Grid container style={{ marginBottom: '50px' }}>
                <Grid item xs={12} style={{ marginBottom: '20px' }}>
                    <Typography variant={this.state.sizeMapHeader[`${this.props.fontSizeMap}`]} gutterBottom align='left' style={{ fontSize: this.props.fontSize }}>
                        <Box fontWeight="fontWeightBold" display='inline'>{this.props.sectionInfo.section}</Box>
                    </Typography>
                    <Typography variant={this.state.sizeMapBody[`${this.props.fontSizeMap}`]} gutterBottom align='left'>
                        The text has seven sections. Choose the correct heading for each section and move it into the gap
                    </Typography>
                </Grid>
                <Grid item xs={12} style={{ marginBottom: '20px' }}>
                    <Typography variant={this.state.sizeMapBody[`${this.props.fontSizeMap}`]} align='left'>
                        <Box fontWeight="fontWeightBold" display='inline'>List of Headings</Box>
                    </Typography>
                </Grid>
                <Grid item xs={12} style={{ zoom: this.props.isRetina ? 1.3 : 1 }}>
                    <Grid container spacing={1}>
                        {this.props.sectionInfo.questions[0].choice.map((q, index) =>
                            !(JSON.stringify(this.props.dropItems).indexOf(q) > -1) && <Grid item align='left' xs={12}>
                                <DragDropContainer
                                    targetKey="foo"
                                    onDragStart={(event) => {
                                        this.props.onDragStart(q);
                                        this.handleAnswerQuestion();
                                    }}
                                >
                                    <Paper style={{ padding: '5px' }}>
                                        <Typography style={{ fontWeight: 'bold', fontSize: `${this.state.sizeMapCustom[`${this.props.fontSizeMap}`] - (this.props.isRetina ? 0.2 : 0)}rem` }}>
                                            {q}
                                        </Typography>
                                    </Paper>
                                </DragDropContainer>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

export default compose(
    withStyles(useStyles)
)(ListOfHeadings);
