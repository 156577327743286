import React, { Component } from 'react';
import { compose } from 'recompose';

//Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Container from '@material-ui/core/Container';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';

//Components
import CreateDiscountCode from './components/CreateDiscountCode';
import DiscountCodeTable from './components/DiscountCodeTable';
import DiscountCodeUsageTable from './components/DiscountCodeUsageTable';
import Refunds from './components/Refunds';
import UserFeedbackTable from './components/UserFeedbackTable';
import UserTable from './components/UserTable';
import UserTestSurveyTable from './components/UserTestSurveyTable';
import UserInfoTable from './components/UserInfoTable';

//Icon

//Innitial setup
const drawerWidth = 240;
const axios = require('axios').default;
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";
const useStyles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        height: 500
    },
    container: {
        margin: 100,
        alignItem: "center"
    },
    gridItem: {
        margin: theme.spacing(1),
        width: "800px",
        maxWidth: "800px"
    },
    formControl: {
        maxWidth: 80,
    },
    inline: {
        display: 'inline',
    },
});

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class Admin extends Component {
    constructor() {
        super();
        this.state = {
            value: 0,
            index: '',
            selectedIndex: 1,
            isEdit: false,
            isConfirmDeletion: false,
            users: [],
            selectedMenu: 'users',
            window: {
                height: window.innerHeight,
                width: window.innerWidth
            }
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeTab = this.handleChangeTab.bind(this);
        this.handleListItemClick = this.handleListItemClick.bind(this);
        this.handleCancelEdit = this.handleCancelEdit.bind(this);
        this.handleDeleteUser = this.handleDeleteUser.bind(this);
        this.handleCloseConfirmDeletion = this.handleCloseConfirmDeletion.bind(this);
        this.handleConfirmDeletion = this.handleConfirmDeletion.bind(this);
        this.handleChangeUserRole = this.handleChangeUserRole.bind(this);
    }

    componentDidMount() {
        this.props.handleChangeMenu('administrator')
        document.querySelector("body").scrollTo(0, 0);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    handleResize = (event) => {
        this.setState({
            window: {
                height: window.innerHeight,
                width: window.innerWidth
            }
        });
    };

    handleSelectMenu = (menu) => {
        console.log(menu)
        this.setState({
            selectedMenu: menu
        });
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        })
        console.log(this.state)
    }

    a11yProps(index) {
        console.log(index);
        return {
            id: 'vertical-tab-' + index,
            'aria-controls': 'vertical-tabpanel-' + index
        };
    }

    async handleChangeTab(event, tab) {
        this.setState({
            value: tab,
        });

        if (tab === 0) {
            await this.handleGetallUsers();
        };
    };

    handleListItemClick(action, index) {
        if (action === "EDIT") {
            this.setState({
                isEdit: true,
                editIndex: index
            });
        }
    }

    handleCancelEdit() {
        this.setState({
            isEdit: false
        });
    }

    //============================== BEGIN CHANGE USER ROLE =============================
    async handleChangeUserRole(username, role) {
        const changeUserRole = api_base_url + "/api/user/role";
        try {
            console.log(role);
            if (role === undefined) {
                return;
            }

            let authentication = {
                headers: {
                    "Authorization": "Bearer " + this.props.user.token
                },
            };
            let body = {
                username: username,
                role: role
            }
            const response = await axios.post(changeUserRole, body, authentication);
            console.log(response);
            this.setState({
                isEdit: false,
                isConfirmDeletion: false
            });

            await this.handleGetallUsers();

        } catch (error) {
            console.error(error);
        }
    }
    //============================== END CHANGE USER ROLE =============================

    //============================== TEXT STYLE =============================
    capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    //============================== TEXT STYLE =============================

    //============================== BEGIN DELETE USER =============================
    async handleDeleteUser(username) {
        const deleteUsers = api_base_url + "/api/user/delete/" + username;
        try {
            let authentication = {
                headers: {
                    "Authorization": "Bearer " + this.props.user.token
                }
            };
            const response = await axios.delete(deleteUsers, authentication)
            console.log(response);
            this.handleGetallUsers();
        } catch (error) {
            console.error(error);
        }
    }

    handleCloseConfirmDeletion() {
        this.setState({
            isConfirmDeletion: false
        })
    }

    handleConfirmDeletion(name) {
        this.setState({
            isConfirmDeletion: true,
            deleteUsername: name
        })
    }

    //============================== END DELETE USER =============================

    render() {
        const { classes } = this.props;

        const dialogConfirmDeletion = (
            <Dialog
                open={this.state.isConfirmDeletion}
                onClose={this.handleCloseConfirmDeletion}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirm deletion"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this username "{this.state.deleteUsername}"?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCloseConfirmDeletion} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => { this.handleDeleteUser(this.state.deleteUsername) }} color="primary" autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        )

        return (
            <Container maxWidth="lg" style={{ marginTop: '100px', marginBottom: '500px' }}>
                <Grid container spacing={5}>
                    <Grid align='left' item xs={12}>
                        <Typography variant="h4" gutterBottom>
                            <Box fontWeight="fontWeightBold" display='inline'>Administrator</Box>
                        </Typography>
                    </Grid>
                    <Grid item align="left" xs={12}>
                        <Paper elevation={0} >
                            <ButtonGroup color="primary" aria-label="outlined primary button group" fullWidth>
                                <Button
                                    disableElevation
                                    onClick={() => { this.handleSelectMenu('users') }}
                                    variant={this.state.selectedMenu === 'users' ? 'contained' : 'outlined'}
                                    style={{
                                        backgroundColor: this.state.selectedMenu === 'users' ? '#880000' : '',
                                        color: this.state.selectedMenu === 'users' ? 'white' : '#880000',
                                        borderColor: '#880000',
                                        textTransform: 'none'
                                    }}>
                                    Users
                                </Button>
                                <Button
                                    disableElevation
                                    onClick={() => { this.handleSelectMenu('userFeedbackTable') }}
                                    variant={this.state.selectedMenu === 'userFeedbackTable' ? 'contained' : 'outlined'}
                                    style={{
                                        backgroundColor: this.state.selectedMenu === 'userFeedbackTable' ? '#880000' : '',
                                        color: this.state.selectedMenu === 'userFeedbackTable' ? 'white' : '#880000',
                                        borderColor: '#880000',
                                        textTransform: 'none'
                                    }}>
                                    Feedback
                                </Button>
                                <Button
                                    disableElevation
                                    onClick={() => { this.handleSelectMenu('userTestSurvey') }}
                                    variant={this.state.selectedMenu === 'userTestSurvey' ? 'contained' : 'outlined'}
                                    style={{
                                        backgroundColor: this.state.selectedMenu === 'userTestSurvey' ? '#880000' : '',
                                        color: this.state.selectedMenu === 'userTestSurvey' ? 'white' : '#880000',
                                        borderColor: '#880000',
                                        textTransform: 'none'
                                    }}>
                                    Test survey
                                </Button>
                                <Button
                                    disableElevation
                                    onClick={() => { this.handleSelectMenu('refunds') }}
                                    variant={this.state.selectedMenu === 'refunds' ? 'contained' : 'outlined'}
                                    style={{
                                        backgroundColor: this.state.selectedMenu === 'refunds' ? '#880000' : '',
                                        color: this.state.selectedMenu === 'refunds' ? 'white' : '#880000',
                                        borderColor: '#880000',
                                        textTransform: 'none'
                                    }}>
                                    Refund & Re-test
                                </Button>
                                <Button
                                    disableElevation
                                    onClick={() => { this.handleSelectMenu('discountCode') }}
                                    variant={this.state.selectedMenu === 'discountCode' ? 'contained' : 'outlined'}
                                    style={{
                                        backgroundColor: this.state.selectedMenu === 'discountCode' ? '#880000' : '',
                                        color: this.state.selectedMenu === 'discountCode' ? 'white' : '#880000',
                                        borderColor: '#880000',
                                        textTransform: 'none'
                                    }}>
                                    Discount code
                                </Button>
                                <Button
                                    disableElevation
                                    onClick={() => { this.handleSelectMenu('discountCodeUsage') }}
                                    variant={this.state.selectedMenu === 'discountCodeUsage' ? 'contained' : 'outlined'}
                                    style={{
                                        backgroundColor: this.state.selectedMenu === 'discountCodeUsage' ? '#880000' : '',
                                        color: this.state.selectedMenu === 'discountCodeUsage' ? 'white' : '#880000',
                                        borderColor: '#880000',
                                        textTransform: 'none'
                                    }}>
                                    Discount usage
                                </Button>
                                <Button
                                    disableElevation
                                    onClick={() => { this.handleSelectMenu('createDiscountCode') }}
                                    variant={this.state.selectedMenu === 'createDiscountCode' ? 'contained' : 'outlined'}
                                    style={{
                                        backgroundColor: this.state.selectedMenu === 'createDiscountCode' ? '#880000' : '',
                                        color: this.state.selectedMenu === 'createDiscountCode' ? 'white' : '#880000',
                                        borderColor: '#880000',
                                        textTransform: 'none'
                                    }}>
                                    Create discount code
                                </Button>
                            </ButtonGroup>
                        </Paper>
                    </Grid>
                    {/*(this.props.user.role === "SUPER_ADMIN" || this.props.user.role === "ADMIN") &&
                        <Grid item xs={12} sm={3}>
                            <Paper variant="outlined">
                                <List component="nav" aria-label="main mailbox folders">
                                    <ListItem
                                        button
                                        selected={this.state.selectedMenu === 'users'}
                                        onClick={() => { this.handleSelectMenu('users') }}
                                    >
                                        <ListItemIcon>
                                            <PeopleAltRoundedIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Users" />
                                    </ListItem>
                                    <Divider />
                                    <ListItem
                                        button
                                        selected={this.state.selectedMenu === 'userFeedbackTable'}
                                        onClick={() => { this.handleSelectMenu('userFeedbackTable') }}
                                    >
                                        <ListItemIcon>
                                            <SmsRoundedIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="User Feedbacks" />
                                    </ListItem>
                                    <Divider />
                                    <ListItem
                                        button
                                        selected={this.state.selectedMenu === 'refunds'}
                                        onClick={() => { this.handleSelectMenu('refunds') }}
                                    >
                                        <ListItemIcon>
                                            <MonetizationOnRoundedIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Refunds" />
                                    </ListItem>
                                    <Divider />
                                    <ListItem
                                        button
                                        selected={this.state.selectedMenu === 'discountCode'}
                                        onClick={() => { this.handleSelectMenu('discountCode') }}
                                    >
                                        <ListItemIcon>
                                            <MoneyOffRoundedIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Discount code" />
                                    </ListItem>
                                    <Divider />
                                    <ListItem
                                        button
                                        selected={this.state.selectedMenu === 'discountCodeUsage'}
                                        onClick={() => { this.handleSelectMenu('discountCodeUsage') }}
                                    >
                                        <ListItemIcon>
                                            <HistoryRoundedIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Discount code usage" />
                                    </ListItem>
                                    <Divider />
                                    <ListItem
                                        button
                                        selected={this.state.selectedMenu === 'createDiscountCode'}
                                        onClick={() => { this.handleSelectMenu('createDiscountCode') }}
                                    >
                                        <ListItemIcon>
                                            <AddCircleOutlineRoundedIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Create discount code" />
                                    </ListItem>
                                </List>
                            </Paper>
                        </Grid>
                                */}

                    {this.state.selectedMenu === 'users' &&
                        <Grid item xs={12}>
                            <UserInfoTable
                                teachers={this.state.teachers}
                                handleChangeUserRole={this.handleChangeUserRole}
                                handleConfirmDeletion={this.handleConfirmDeletion}
                            />
                        </Grid>
                    }

                    {this.state.selectedMenu === 'refunds' &&
                        <Grid item xs={12}>
                            <Refunds />
                        </Grid>
                    }

                    {this.state.selectedMenu === 'discountCode' &&
                        <Grid item xs={12}>
                            <DiscountCodeTable />
                        </Grid>
                    }

                    {this.state.selectedMenu === 'discountCodeUsage' &&
                        <Grid item xs={12}>
                            <DiscountCodeUsageTable />
                        </Grid>
                    }

                    {this.state.selectedMenu === 'createDiscountCode' &&
                        <Grid item xs={12}>
                            <CreateDiscountCode handleSelectMenu={this.handleSelectMenu} />
                        </Grid>
                    }

                    {this.state.selectedMenu === 'userFeedbackTable' &&
                        <Grid item xs={12}>
                            <UserFeedbackTable handleSelectMenu={this.handleSelectMenu} />
                        </Grid>
                    }

                    {this.state.selectedMenu === 'userTestSurvey' &&
                        <Grid item xs={12}>
                            <UserTestSurveyTable handleSelectMenu={this.handleSelectMenu} />
                        </Grid>
                    }

                    {!(this.props.user.role === "SUPER_ADMIN" || this.props.user.role === "ADMIN") &&
                        <Alert severity="info">
                            Sorry, you do not have permission to view administrator page.
                        </Alert>
                    }
                    {dialogConfirmDeletion}
                </Grid>
            </Container>
        )
    }
}

export default compose(
    withStyles(useStyles)
)(Admin);
