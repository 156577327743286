//React
import { Component } from 'react';
import { compose } from 'recompose';

//Material UI
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Skeleton from '@material-ui/lab/Skeleton';

//react-router-dom
import {
    Link
} from "react-router-dom";

//Others

const axios = require('axios').default;
const FileDownload = require('js-file-download');

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%'
    }
});

class MySchedule extends Component {
    constructor() {
        super();
        this.state = {
            clickedAssign: false
        };
    }

    componentDidMount() {
        this.handleGetMyMeeting();
    }

    capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    handleGetMyMeeting = async () => {
        try {
            const res = await axios({
                method: 'get',
                url: 'https://steep-legend-midnight.glitch.me/meetings',
                params: {
                    submitter: JSON.parse(localStorage.getItem('user')).username
                }
            });

            const resData = res.data.data;
            this.setState({
                schedules: resData,
            });
            this.props.handleNumChange('numSchedules', resData.length)

        } catch (err) {
            if (err.message === "Request failed with status code 404") {
                this.setState({
                    schedules: [],
                });
            };
            console.log(err);
        }
    }

    render() {
        const { classes } = this.props;

        return (
            <Grid container style={{ marginTop: '50px' }}>
                <Grid item align='center' xs={12}>
                    <Typography align='left' variant="h4" gutterBottom style={{ color: '#76323F' }}>
                        <Box fontWeight="fontWeightBold" display='inline'>My Schedules</Box>
                    </Typography>
                </Grid>
                <Grid item align='center' xs={12} style={{ overflowY: 'auto', maxHeight: '500px' }}>
                    <Paper variant="outlined">
                        {this.state.schedules !== undefined ? (this.state.schedules.length === 0 ?
                            (<Grid container>
                                <Paper style={{ height: 80, maxHeight: 80, width: '100%' }}>
                                    <Grid item xs={12}>
                                        <Typography align='center' variant="subtitle1" gutterBottom style={{ color: 'grey' }}>
                                            You have no schedule
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Link to={"/test/scheduler"} color="inherit" underline="none" style={{ textDecoration: 'none', color: '#565656' }}>
                                            <Button
                                                size="large"
                                                onClick={this.handleChooseFreeTest}
                                                style={{
                                                    backgroundColor: '#76323F',
                                                    color: 'white',
                                                    padding: '5px',
                                                    width: '30%'
                                                }}
                                            >
                                                Book a Test
                                            </Button>
                                        </Link>
                                    </Grid>
                                </Paper>
                            </Grid>) :
                            (this.state.schedules.map((schedule, index) =>
                                <Accordion style={{ width: '100%' }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Grid container spacing={0}>
                                            <Grid align='left' item xs={12} sm>
                                                <Typography>
                                                    {schedule.schedule.topic}
                                                </Typography>
                                            </Grid>
                                            <Grid align='right' item xs={12} sm>
                                                <Chip
                                                    label={new Date(schedule.schedule.start_time).toDateString()}
                                                    style={{ margin: '5px' }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container spacing={2}>
                                            <Grid align='right' item xs={6} sm={3}>
                                                <Typography>
                                                    <Box fontWeight="fontWeightBold" display='inline'>Tester:</Box>
                                                </Typography>
                                                <Typography>
                                                    <Box fontWeight="fontWeightBold" display='inline'>Schedule at:</Box>
                                                </Typography>
                                                <Typography>
                                                    <Box fontWeight="fontWeightBold" display='inline'>Meeting password:</Box>
                                                </Typography>
                                                <Typography>
                                                    <Box fontWeight="fontWeightBold" display='inline'>Duration:</Box>
                                                </Typography>
                                                <Typography>
                                                    <Box fontWeight="fontWeightBold" display='inline'>Test category:</Box>
                                                </Typography>
                                                <Typography>
                                                    <Box fontWeight="fontWeightBold" display='inline'>Type:</Box>
                                                </Typography>
                                                <Typography>
                                                    <Box fontWeight="fontWeightBold" display='inline'>Teacher:</Box>
                                                </Typography>
                                            </Grid>
                                            <Grid align='left' item xs={6} sm={9}>
                                                <Typography>
                                                    {schedule.submitter}
                                                </Typography>
                                                <Typography>
                                                    {new Date(schedule.schedule.start_time).toString()}
                                                </Typography>
                                                <Typography>
                                                    {schedule.schedule.password}
                                                </Typography>
                                                <Typography>
                                                    {schedule.schedule.duration} minutes
                                                </Typography>
                                                <Typography>
                                                    {schedule.testCategory}
                                                </Typography>
                                                <Typography>
                                                    {schedule.testType}
                                                </Typography>
                                                <Typography>
                                                    {this.props.userInfo.name}
                                                </Typography>
                                            </Grid>
                                            <Grid align='right' item xs={12}>
                                                <Grid container spacing={2} justify='right'>
                                                    <Grid item xs={12}>
                                                        <Link
                                                            to={"/test/speaking/meeting-id/" + schedule.schedule.id + "/password/" + schedule.schedule.password + "/role/0"}
                                                            underline="none"
                                                            style={{ textDecoration: 'none', color: '#565656' }}
                                                        >
                                                            <Button
                                                                color="primary"
                                                                variant="contained"
                                                            >
                                                                Join Meeting
                                                            </Button>
                                                        </Link>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            ))) : <Grid>
                            <Skeleton variant="rect" width="100%" height={80} />
                        </Grid>
                        }
                    </Paper>
                </Grid>
            </Grid>
        )
    }
}

export default compose(
    withStyles(useStyles)
)(MySchedule);