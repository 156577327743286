//React
import { Component } from 'react';
import { compose } from 'recompose';

//Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

//import components
import { DragDropContainer, DropTarget } from 'react-drag-drop-container';
import { indexOf } from 'lodash';

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%'
    }
});

class MatchingSentenceEndings extends Component {
    constructor() {
        super();
        this.state = {
            draggedItem: '',
            draggedItemToShow: '',
            dropItems: [],
            dropItemsToShow: [],
            sizeMapHeader: {
                'sm': 'subtitle2',
                'md': 'body2',
                'lg': 'body1',
                'xl': 'h6',
            },
            sizeMapBody: {
                'sm': 0.7,
                'md': 0.8,
                'lg': 1,
                'xl': 1.2,
            },
            sizeMapCaption: {
                'sm': 'caption',
                'md': 'caption',
                'lg': 'body2',
                'xl': 'body1',
            }
        };
    }

    componentDidMount = async () => {
        const filteredAnsObj = await this.props.answerObj?.parts[this.props.currentPart - 1].sections.find((element) => {
            return element.section_id === this.props.sectionInfo.section_id;
        })

        await this.setState({
            answerObj: {
                section_id: this.props.sectionInfo.section_id,
                question_type: this.props.sectionInfo.question_type,
                answers: (filteredAnsObj.answers === undefined || Object.keys(filteredAnsObj.answers).length === 0) ? {} : filteredAnsObj.answers
            },
        });

        if (filteredAnsObj.answers !== undefined && Object.keys(filteredAnsObj.answers).length !== 0) {
            console.log(filteredAnsObj);
            const objectArray = await Object.entries(filteredAnsObj.answers[this.props.sectionInfo.questions[0].no]);
            await objectArray.forEach(([key, value]) => {
                let foundIndex = this.props.sectionInfo.questions[0].match_question_r.findIndex(item => item.includes(value));
                this.state.dropItemsToShow[key] = this.props.sectionInfo.questions[0].match_question_r[foundIndex]
                this.state.dropItems[key] = value
            });
        }

        this.setState({
            dropItemsToShow: this.state.dropItemsToShow,
            dropItems: this.state.dropItems
        })
    }

    onDragged = (index) => {
        if (this.state.draggedItem !== undefined) {
            this.state.dropItems[index] = this.state.draggedItem
            this.state.dropItemsToShow[index] = this.state.draggedItemToShow
            this.state.answerObj.answers[this.props.sectionInfo.questions[0].no] = this.state.dropItems
            console.log(this.state.answerObj);
            this.props.handleAnswerObj(this.state.answerObj);
            this.props.handleAnsweredQuestionNumber(Number(this.props.sectionInfo.questions[0].no.split('-')[0]) + index);
            this.setState({
                dropItemsToShow: this.state.dropItemsToShow,
                draggedItem: undefined
            });
        }
    }

    onDragStart = (item) => {
        this.setState({
            draggedItem: item,
            draggedItemToShow: item
        });
    }

    onClickAnswer = (index, q) => {
        this.state.dropItemsToShow[index] = undefined;
        this.props.handleCancelQuestionNumber(Number(q.split('.')[0]));
        this.setState({
            dropItemsToShow: this.state.dropItemsToShow
        });

        this.props.onFocusQuestion(Number(q.split('.')[0]));
    }

    render() {
        const { classes } = this.props;

        return (
            <Grid container style={{ marginBottom: '50px' }}>
                <Grid item xs={12} style={{ marginBottom: '20px' }}>
                    <Typography variant={this.state.sizeMapHeader[`${this.props.fontSizeMap}`]} gutterBottom align='left'>
                        <Box fontWeight="fontWeightBold" display='inline'>{this.props.sectionInfo.section}</Box>
                    </Typography>
                    <Typography style={{ fontSize: `${this.state.sizeMapBody[`${this.props.fontSizeMap}`]}rem` }} gutterBottom align='left'>
                        Complete each sentence with the correct ending. Choose the correct ending and move it into the gap.
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2} align='left'>
                        {this.props.sectionInfo.questions[0].match_question_l.map((q, index) =>
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item>
                                        <Typography style={{ fontSize: `${this.state.sizeMapBody[`${this.props.fontSizeMap}`]}rem` }}>
                                            {q}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs>
                                        <DropTarget
                                            targetKey="MatchingSentenceEnding"
                                            onChange={this.props.handleChange}
                                            onHit={() => {
                                                this.onDragged(index);
                                            }}
                                        >
                                            <Button
                                                size="small"
                                                id="outlined-basic"
                                                variant="outlined"
                                                style={{ width: '100%', fontWeight: 'bold', backgroundColor: this.state.dropItemsToShow[index] === undefined ? 'white' : '#dbe9ee' }}
                                                onClick={() => { this.onClickAnswer(index, q) }}
                                            >
                                                <Typography style={{ fontSize: `${this.state.sizeMapBody[`${this.props.fontSizeMap}`]}rem`, fontWeight: "bold" }}>
                                                    {this.state.dropItemsToShow[index] === undefined ? q.split('.')[0] : this.state.dropItemsToShow[index].split(". ").length <= 1 ? this.state.dropItemsToShow[index] : (this.state.dropItemsToShow[index].split(". ")[1])}
                                                </Typography>
                                            </Button>
                                        </DropTarget>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{ marginTop: '10px', zoom: this.props.isRetina ? 1.2 : 1 }}>
                    <Grid container align='left' spacing={1}>
                        {this.props.sectionInfo.questions[0].match_question_r.map((q, index) =>
                            !this.state.dropItemsToShow.includes(q) && <Grid item xs={12}>
                                <DragDropContainer targetKey="MatchingSentenceEnding" onDragStart={() => { this.onDragStart(q) }} >
                                    <Paper style={{ padding: '5px' }}>
                                        <Typography style={{ fontWeight: 'bold', fontSize: `${this.state.sizeMapBody[`${this.props.fontSizeMap}`] - (this.props.isRetina ? 0.2 : 0)}rem` }}>
                                            {q.split(". ").length <= 1 ? q : q.split(". ")[1]}
                                        </Typography>
                                    </Paper>
                                </DragDropContainer>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

export default compose(
    withStyles(useStyles)
)(MatchingSentenceEndings);
