import React, { useEffect } from 'react';

//MUI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { ThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';
import TextEditor from './TextEditor';

//Font
import 'typeface-glacial-indifference';

//Others
import { Link } from "react-router-dom";
const axios = require('axios').default;
const FormData = require('form-data');
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

const useStyles = theme => ({
    root: {
        padding: theme.spacing(12, 4),
    }
});

const theme = createMuiTheme({
    typography: {
        fontFamily: [
            'Roboto'
        ].join(','),
    },
});

const Notes = (props) => {
    useEffect(() => {

    }, []);

    const mobileUI = (<Grid container style={{ marginTop: "18%", marginBottom: "20%", maxWidth: window.innerWidth }}>
        <Grid item xs={12} style={{ marginBottom: "2%" }}>
            <Typography variant="h3" style={{ fontWeight: "bold" }} gutterBottom>
                <span style={{ color: "#991514" }}>U</span>TEST AVENUE
            </Typography>
            <Typography variant="body1">
                A smartly-designed platform for
            </Typography>
            <Typography variant="body1">
                simulation tests & intelligent skill analysis
            </Typography>
        </Grid>
        <Grid item xs={12} style={{ marginTop: "5%" }}>
            <Link
                to="/freetests"
                style={{ textDecoration: 'none', color: '#565656' }}
            >
                <Button
                    variant="contained"
                    size="large"
                    style={{ backgroundColor: "#3f5e75", color: "white", borderRadius: "10px", textTransform: "none", fontSize: "1rem" }}
                    disableElevation
                >
                    Free tests
                </Button>
            </Link>
        </Grid>
    </Grid>)

    const webUI = (<Drawer
        variant="permanent"
        anchor="right"
    >
        <Grid container style={{ width: '500px' }}>
            <Grid item align='center' xs={6} style={{ marginTop: '50px' }}>
                <Typography variant="h5">
                    <Box fontWeight="fontWeightBold" display='inline'>Speaking dialog</Box>
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Grid container style={{ marginTop: '20px' }}>
                    <Grid item align="center" xs={12}>
                        <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                            Timer
                        </Typography>
                    </Grid>
                    <Grid item align="center" xs={12}>
                        <Typography variant="h6">
                            {props.handleAddLeadingZero(Math.floor(props.timer / 3600))} : {props.timer === 3600 ? '00' : props.handleAddLeadingZero(Math.floor(props.timer / 60))} : {props.handleAddLeadingZero(props.timer % 60)} minutes left
                        </Typography>
                    </Grid>
                    <Grid item align="center" xs={12}>
                        {props.timerState === "STOP" &&
                            <Button
                                size="small"
                                color="primary"
                                variant="contained"
                                align='center'
                                onClick={props.handleStartTimer}
                                style={{ width: '50%', textTransform: 'none', backgroundColor: '#991514' }}
                            >
                                Start
                            </Button>
                        }
                        {props.timerState === "PAUSE" &&
                            <Button
                                size="small"
                                color="primary"
                                variant="contained"
                                align='center'
                                onClick={() => { props.handleTimerControl("STARTING") }}
                                style={{ width: '50%', textTransform: 'none', backgroundColor: '#991514' }}
                            >
                                Resume
                            </Button>
                        }
                    </Grid>
                    <Grid item align="center" xs={12}>
                        {!(props.timerState === "STOP") && !(props.timerState === "PAUSE") &&
                            <Button
                                size="small"
                                color="primary"
                                variant="contained"
                                align='center'
                                onClick={() => { props.handleTimerControl("PAUSE") }}
                                style={{ width: '50%', textTransform: 'none', backgroundColor: '#991514' }}
                            >
                                Pause
                            </Button>
                        }
                        {/*!(props.timerState === "STOP") && props.timerState === "PAUSE" &&
                        <Button
                            size="small"
                            color="primary"
                            variant="contained"
                            align='center'
                            onClick={() => { props.handleTimerControl("PAUSE") }}
                            style={{ width: '50%', textTransform: 'none' }}
                        >
                            STOP
                        </Button>
                */}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ paddingTop: '30px' }}>
                <TextEditor notes={props.notes} />
            </Grid>
            <Grid item xs={12} style={{
                position: 'fixed', position: 'absolute', left: '50%', top: '75%',
                transform: 'translate(-50%, -50%)'
            }}>
                <Typography variant="h6">
                    <Box fontWeight="fontWeightBold" display='inline'>Notes & Comments</Box>
                </Typography>
                <TextField
                    name="comment"
                    autoComplete='off'
                    id="outlined-multiline-static"
                    label="Notes & Comments"
                    multiline
                    value={props.scoreObj.comment === undefined ? "" : props.scoreObj.comment}
                    rows={10}
                    variant="outlined"
                    style={{
                        width: '500px'
                    }}
                    onChange={props.handleChangeTextField}
                />
            </Grid>
            <Grid item xs={12} style={{
                position: 'fixed', position: 'absolute', left: '50%', top: '88%',
                transform: 'translate(-50%, -50%)'
            }}>
                <Grid container justify='center' alignItems='center' spacing={1}>
                    <Grid item>
                        <Button
                            disabled={props.isSubmitting}
                            color="primary"
                            variant="contained"
                            style={{ textTransform: 'none', backgroundColor: props.isSubmitting ? "#d8d6d8" : '#991514' }}
                            onClick={() => { props.createSpeakingLog() }}
                        >
                            Save
                        </Button>
                    </Grid>
                    <Grid item>
                        {props.isSubmitting && <CircularProgress size={30} style={{ color: "#991514" }} />}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} style={{
                position: 'fixed', position: 'absolute', left: '50%', top: '97%',
                transform: 'translate(-50%, -50%)'
            }}>
                <Button
                    disableElevation
                    color="primary"
                    variant="contained"
                    align='center'
                    onClick={props.handleToggleDrawer}
                    style={{ textTransform: 'none', backgroundColor: '#d8d6d8' }}
                >
                    Hide notes
                </Button>
            </Grid>
        </Grid>
    </Drawer>)

    return <ThemeProvider theme={theme}>
        <Hidden mdUp>
            {mobileUI}
        </Hidden>
        <Hidden smDown>
            {webUI}
        </Hidden>
    </ThemeProvider>
};

export default compose(
    withStyles(useStyles)
)(Notes);