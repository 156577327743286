//Material UI
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { compose } from 'recompose';
import Container from '@material-ui/core/Container';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import MuiAlert from '@material-ui/lab/Alert';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

//Icon
import InsertEmoticonRoundedIcon from '@material-ui/icons/InsertEmoticonRounded';
import PhotoCamera from '@material-ui/icons/PhotoCamera';

//Components
import DoneEditBlog from './components/DoneEditBlog';
import PreviewPage from './components/PreviewPage';

//Others
import { Component } from 'react';
import { green } from '@material-ui/core/colors';
import axios from 'axios';

//react-router-dom
import {
    Link
} from "react-router-dom";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        padding: '50px'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    input: {
        display: 'none',
    },
});

class EditBlogPage extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
            isSubmitted: false,
            isPreview: false,
            isCreated: false,
            isEdit: true,
            body: "",
            bodyHeading: "",
            heading: "",
            subHeading: "",
            isPreview: "",
            image: ""
        }
    }

    componentDidMount = () => {
        this.handleGetBlog();
        document.querySelector("body").scrollTo(0,0);
    }

    handleGetBlog = async () => {
        try {
            const res = await axios({
                method: 'get',
                url: `https://erratic-noiseless-wasabi.glitch.me/utestavenue-system-api/api/v1/blogs/id/${this.props.match.params.id}`,
            });

            const resData = res.data;

            this.setState({
                body: resData.body,
                bodyHeading: resData.bodyHeading,
                heading: resData.heading,
                subHeading: resData.subheading,
                type: resData.type,
                bigPic: resData.bigPic,
                smallPic: resData.smallPic,
                pinned: resData.pinned
            })
        } catch (err) {
            console.log(err);
        }
    }

    handleUpdateBlog = async () => {
        try {
            this.setState({
                isLoading: true
            });

            await axios({
                method: 'put',
                url: `https://erratic-noiseless-wasabi.glitch.me/utestavenue-system-api/api/v1/blogs/id/${this.props.match.params.id}`,
                data: {
                    heading: this.state.heading,
                    smallPic: this.state.smallPic,
                    bigPic: this.state.bigPic,
                    writerName: JSON.parse(localStorage.getItem('user')).name,
                    writerUsername: JSON.parse(localStorage.getItem('user')).username,
                    subheading: this.state.subHeading,
                    bodyHeading: this.state.bodyHeading,
                    body: this.state.body,
                    type: this.state.type,
                    pinned: this.state.pinned
                }
            });

            this.setState({
                isLoading: false,
                isCreated: true
            });
        } catch (err) {
            console.log(err);
            this.setState({
                isLoading: false,
                isErrorRequiredFields: true
            });
        }
    }

    handleChangeInput = (event) => {
        if (event.target.name === 'body') {
            this.setState({
                [event.target.name]: event.target.value.replace('\n', '\\n')
            });
        } else {
            this.setState({
                [event.target.name]: event.target.value
            });
        }
    }

    handleCloseSnackbar = () => {
        this.setState({
            isErrorRequiredFields: false
        });
    }

    handlePreviewBlog = () => {
        this.setState({
            isPreview: true
        });

        document.querySelector("body").scrollTo(0,0);
    }

    handleCancelPreviewBlog = () => {
        this.setState({
            isPreview: false
        });

        document.querySelector("body").scrollTo(0,0);
    }

    render() {
        const { classes } = this.props;

        return (
            <Container maxWidth="lg">
                {this.state.isCreated && <DoneEditBlog />}

                <Backdrop className={classes.backdrop} open={this.state.isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Snackbar open={this.state.isErrorRequiredFields} autoHideDuration={6000} onClose={this.handleCloseSnackbar}>
                    <Alert onClose={this.handleCloseSnackbar} severity="error">
                        Please fill in all required fields.
                    </Alert>
                </Snackbar>



                {this.state.isPreview &&
                    <Grid item xs={12}>
                        <PreviewPage
                            body={this.state.body}
                            bodyHeading={this.state.bodyHeading}
                            heading={this.state.heading}
                            subHeading={this.state.subHeading}
                            image={this.state.bigPic}
                            isPreview={this.state.isPreview}
                            isEdit={this.state.isEdit}
                            handleCreateBlog={this.handleCreateBlog}
                            handleCancelPreviewBlog={this.handleCancelPreviewBlog}
                            handleUpdateBlog={this.handleUpdateBlog}
                        />
                    </Grid>
                }

                {!this.state.isPreview && !this.state.isCreated &&
                    <Grid container>
                        <Grid item xs={12}>
                            <Paper>
                                <Grid container justify='center' spacing={5} style={{ marginTop: '200px', marginBottom: '315px', padding: '20px' }}>
                                    <Grid item xs={12}>
                                        <Typography variant="h4" style={{ fontWeight: 'bold' }} gutterBottom>
                                            Edit blog
                                        </Typography>
                                    </Grid>
                                    <Grid item align='left' xs={12}>
                                        <Typography variant="h6" gutterBottom>
                                            Heading
                                        </Typography>
                                        <TextField name="heading" id="outlined-basic" variant="outlined" autoComplete="new-password" value={this.state.heading} fullWidth onChange={this.handleChangeInput} />
                                    </Grid>
                                    <Grid item align='left' xs={12}>
                                        <Typography variant="h6" gutterBottom>
                                            Subheading
                                        </Typography>
                                        <TextField name="subHeading" id="outlined-basic" variant="outlined" autoComplete="new-password" value={this.state.subHeading} fullWidth onChange={this.handleChangeInput} />
                                    </Grid>
                                    <Grid item align='left' xs={12}>
                                        <Typography variant="h6" gutterBottom>
                                            Body heading
                                        </Typography>
                                        <TextField name="bodyHeading" id="outlined-basic" variant="outlined" autoComplete="new-password" value={this.state.bodyHeading} fullWidth onChange={this.handleChangeInput} />
                                    </Grid>
                                    <Grid item align='left' xs={12}>
                                        <Typography variant="h6" gutterBottom>
                                            Body
                                        </Typography>
                                        <TextField
                                            name="body"
                                            id="outlined-multiline-static"
                                            multiline
                                            fullWidth
                                            rows={10}
                                            variant="outlined"
                                            autoComplete="new-password"
                                            value={this.state.body}
                                            onChange={this.handleChangeInput}
                                        />
                                    </Grid>
                                    <Grid item align='left' xs={12}>
                                        <Typography variant="h6" gutterBottom>
                                            Type
                                        </Typography>
                                        <TextField
                                            name="type"
                                            id="outlined-basic"
                                            variant="outlined"
                                            autoComplete="new-password"
                                            value={this.state.type}
                                            fullWidth
                                            onChange={this.handleChangeInput} />
                                    </Grid>
                                    <Grid item align='left' xs={12}>
                                        <Typography variant="h6" gutterBottom>
                                            Writer name
                                        </Typography>
                                        <TextField
                                            name="writerName"
                                            defaultValue={JSON.parse(localStorage.getItem('user')).name}
                                            id="outlined-basic"
                                            variant="outlined"
                                            autoComplete="new-password"
                                            disabled fullWidth
                                        />
                                    </Grid>
                                    <Grid item align='left' xs={12}>
                                        <Typography variant="h6" gutterBottom>
                                            Small image url
                                        </Typography>
                                        <TextField
                                            name="smallPic"
                                            id="outlined-basic"
                                            variant="outlined"
                                            autoComplete="new-password"
                                            value={this.state.smallPic}
                                            fullWidth
                                            onChange={this.handleChangeInput} />
                                        {/*<Typography variant="h6" gutterBottom>
                                Upload image
                            </Typography>
                            <input
                                accept="image/*"
                                className={classes.input}
                                id="contained-button-file"
                                multiple
                                type="file"
                            />
                            <label htmlFor="contained-button-file">
                                <Button
                                    variant="contained"
                                    component="span"
                                    startIcon={<PhotoCamera />}
                                    style={{ backgroundColor: '#76323F', color: 'white' }}
                                >
                                    Upload
                                </Button>
                            </label>
                            <Typography variant="subtitle2">
                                Upload image
                            </Typography>*/}
                                    </Grid>
                                    <Grid item align='left' xs={12}>
                                        <Typography variant="h6" gutterBottom>
                                            Big image url
                                        </Typography>
                                        <TextField
                                            name="bigPic"
                                            id="outlined-basic"
                                            variant="outlined"
                                            autoComplete="new-password"
                                            value={this.state.bigPic}
                                            fullWidth
                                            onChange={this.handleChangeInput} />
                                    </Grid>
                                    {!this.state.isPreview &&
                                        <Grid item xs={12}>
                                            <Grid container justify='flex-end' spacing={2}>
                                                <Grid item>
                                                    <Button
                                                        variant="contained"
                                                        component="span"
                                                        style={{ backgroundColor: '#76323F', color: 'white' }}
                                                        onClick={this.handleUpdateBlog}
                                                    >
                                                        Update
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button
                                                        variant="outlined"
                                                        component="span"
                                                        style={{ borderColor: '#76323F', color: '#76323F' }}
                                                        onClick={this.handlePreviewBlog}
                                                    >
                                                        Preview
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Link to="/blogs" style={{ textDecoration: 'none', color: '#0f0f0f' }}>
                                                        <Button
                                                            variant="contained"
                                                            component="span"
                                                            style={{ backgroundColor: '#aaaaaa', color: 'white' }}
                                                        >
                                                            Cancel
                                                        </Button>
                                                    </Link>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    }
                                </Grid>
                            </Paper >
                        </Grid>
                    </Grid>
                }
            </Container>

        )
    }
}
export default compose(
    withStyles(useStyles)
)(EditBlogPage);