import React, { useEffect } from 'react';

//MUI
import Container from '@material-ui/core/Container';

import { ThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';


//Font
import 'typeface-glacial-indifference';
import { Grid, Hidden, Typography } from '@material-ui/core';

const useStyles = theme => ({
    root: {
        padding: theme.spacing(12, 4),
    }
});

const theme = createMuiTheme({
    typography: {
        fontFamily: [
            'Roboto'
        ].join(','),
    },
});

const Copyrights = () => {
    useEffect(() => {
        document.body.style.overflow = "auto";
        document.body.style.backgroundColor = "#efefef";
        document.querySelector("body").scrollTo(0, 0);
    }, []);

    const mobileUI = (
        <Grid item xs={12} style={{ maxWidth: window.innerWidth, zIndex: 1, padding: "20px" }}>
            <Grid container spacing={2} style={{ marginTop: "10%", marginBottom: "10%" }}>
                {/* ==================================== Refund Policy ==================================== */}
                <Grid item xs={12}>
                    <Typography variant="h4" style={{ fontWeight: "bold" }}>
                        INTELLECTUAL PROPERTY AGREEMENT & COPYRIGHT ACKNOWLEDGEMENT
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="body1" style={{ fontWeight: "bold", textDecoration: "underline" }}>
                        UTest Avenue Intellectual Property Agreement and Copyright Acknowledgement
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="body1">
                        This agreement sets out the understanding regarding the Intellectual Property and Copyright positions of the UTest Avenue. It is applicable to all UTest Avenue  resource material, free practices, purchased simulation tests, presentations, manuals and certificates provided as resources for the platform whether online or printed hard copy equivalents.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="body1" style={{ paddingLeft: "30px" }}>
                        <span style={{ fontWeight: "bold" }}>1. Copyright acknowledgement: </span> It is agreed that the Intellectual Property [IP] for all the above-mentioned resources, including but not limited to UTest Avenue resource material, free practices, purchased simulation tests, presentations, manuals, graphics, and test reports (“Subject Materials”) remains the property of the UTest Avenue and may not be reproduced in any form, in full or in part, without the written consent of the UTest Avenue. This IP extends to translated versions of all official documentation.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="body1" style={{ fontWeight: "bold", paddingLeft: "30px" }}>
                        2. Obligations of the UTest Avenue Users:
                    </Typography>
                    <Typography variant="body1" style={{ paddingLeft: "60px", paddingTop: "20px" }}>
                        a. I shall not knowingly share subject material with any third parties unless agreed with the UTest Avenue in writing in advance.
                    </Typography>
                    <Typography variant="body1" style={{ paddingLeft: "60px", paddingTop: "20px" }}>
                        b. During the whole duration of my association with the UTest Avenue and afterwards, without any time limitation, I agree that I will not copy nor reproduce any part of any Subject Materials, in any form or fashion, even if using alternative wording to that used in the platform.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="body1" style={{ fontWeight: "bold", paddingLeft: "30px" }}>
                        3. Sanction under this Agreement.
                    </Typography>
                    <Typography variant="body1" style={{ paddingLeft: "60px", paddingTop: "20px" }}>
                        a. The undersigned acknowledges and accepts that any breach of this Agreement constitutes a grave and serious damage to the UTest Avenue and may be subject to financial penalties.
                    </Typography>
                    <Typography variant="body1" style={{ paddingLeft: "60px", paddingTop: "20px" }}>
                        b. he undersigned acknowledges and accepts that my UTest Avenue may take disciplinary action against me if I am deemed to have breached this IP agreement.
                    </Typography>
                    <Typography variant="body1" style={{ paddingLeft: "60px", paddingTop: "20px" }}>
                        c. The undersigned acknowledges and accepts that disciplinary action may include suspension of my UTest Avenue access, de-registration from the program, and/or other legal actions.
                    </Typography>
                </Grid>
                {/* ==================================== Refund Policy ==================================== */}
            </Grid>
        </Grid>
    )

    const webUI = (
        <Container>
            <Grid container spacing={2} style={{ marginTop: "10%", marginBottom: "10%" }}>
                {/* ==================================== Refund Policy ==================================== */}
                <Grid item xs={12}>
                    <Typography variant="h4" style={{ fontWeight: "bold" }}>
                        INTELLECTUAL PROPERTY AGREEMENT & COPYRIGHT ACKNOWLEDGEMENT
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="body1" style={{ fontWeight: "bold", textDecoration: "underline" }}>
                        UTest Avenue Intellectual Property Agreement and Copyright Acknowledgement
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="body1">
                        This agreement sets out the understanding regarding the Intellectual Property and Copyright positions of the UTest Avenue. It is applicable to all UTest Avenue  resource material, free practices, purchased simulation tests, presentations, manuals and certificates provided as resources for the platform whether online or printed hard copy equivalents.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="body1" style={{ paddingLeft: "30px" }}>
                        <span style={{ fontWeight: "bold" }}>1. Copyright acknowledgement: </span> It is agreed that the Intellectual Property [IP] for all the above-mentioned resources, including but not limited to UTest Avenue resource material, free practices, purchased simulation tests, presentations, manuals, graphics, and test reports (“Subject Materials”) remains the property of the UTest Avenue and may not be reproduced in any form, in full or in part, without the written consent of the UTest Avenue. This IP extends to translated versions of all official documentation.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="body1" style={{ fontWeight: "bold", paddingLeft: "30px" }}>
                        2. Obligations of the UTest Avenue Users:
                    </Typography>
                    <Typography variant="body1" style={{ paddingLeft: "60px", paddingTop: "20px" }}>
                        a. I shall not knowingly share subject material with any third parties unless agreed with the UTest Avenue in writing in advance.
                    </Typography>
                    <Typography variant="body1" style={{ paddingLeft: "60px", paddingTop: "20px" }}>
                        b. During the whole duration of my association with the UTest Avenue and afterwards, without any time limitation, I agree that I will not copy nor reproduce any part of any Subject Materials, in any form or fashion, even if using alternative wording to that used in the platform.
                    </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                    <Typography variant="body1" style={{ fontWeight: "bold", paddingLeft: "30px" }}>
                        3. Sanction under this Agreement.
                    </Typography>
                    <Typography variant="body1" style={{ paddingLeft: "60px", paddingTop: "20px" }}>
                        a. The undersigned acknowledges and accepts that any breach of this Agreement constitutes a grave and serious damage to the UTest Avenue and may be subject to financial penalties.
                    </Typography>
                    <Typography variant="body1" style={{ paddingLeft: "60px", paddingTop: "20px" }}>
                        b. he undersigned acknowledges and accepts that my UTest Avenue may take disciplinary action against me if I am deemed to have breached this IP agreement.
                    </Typography>
                    <Typography variant="body1" style={{ paddingLeft: "60px", paddingTop: "20px" }}>
                        c. The undersigned acknowledges and accepts that disciplinary action may include suspension of my UTest Avenue access, de-registration from the program, and/or other legal actions.
                    </Typography>
                </Grid>
                {/* ==================================== Refund Policy ==================================== */}
            </Grid>
        </Container>
    )

    return (
        <ThemeProvider theme={theme}>
            <Hidden mdUp>{mobileUI}</Hidden>
            <Hidden smDown>{webUI}</Hidden>
        </ThemeProvider>
    )
};

export default compose(
    withStyles(useStyles)
)(Copyrights);
