//React
import { Component } from 'react';
import { compose } from 'recompose';

//Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import AccessAlarmRoundedIcon from '@material-ui/icons/AccessAlarmRounded';
import AccountBoxRoundedIcon from '@material-ui/icons/AccountBoxRounded';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';
import RemoveRoundedIcon from '@material-ui/icons/RemoveRounded';

import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';

//import components
import { DropTarget } from 'react-drag-drop-container';
import PageBackdrop from '../main/PageBackdrop';
import InstructionsPage from './IELTS/reading/InstructionsPage';
import UserInfoPage from './IELTS/reading/UserInfoPage';
import DisplayParagraph from './IELTS/reading/paragraph/DisplayParagraph';

//import question type
import ListOfHeadings from './IELTS/reading/questions/ListOfHeadings';
import MatchingSentenceEndings from './IELTS/reading/questions/MatchingSentenceEndings';
import MultipleChoice from './IELTS/reading/questions/MultipleChoice';
import NoteCompletion from './IELTS/reading/questions/NoteCompletion';
import SentenceCompletion from './IELTS/reading/questions/SentenceCompletion';
import TrueFalseNG from './IELTS/reading/questions/TrueFalseNG';
import YesNoNG from './IELTS/reading/questions/YesNoNG';

//Models
import { FillEmptyAnswers, PrepareAnsObj } from '../../models/testSubmittion';

//other libraries
import Highlighter from "react-highlight-words";
import {
    Link
} from "react-router-dom";

const axios = require('axios').default;
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

//FONT RELATED
const theme = createMuiTheme({
    typography: {
        fontFamily: [
            'Noto Sans'
        ].join(','),
    },
});

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%'
    },
    paperRoot: {
        padding: theme.spacing(2),
        textAlign: 'left'
    },
    paperItem: {
        padding: theme.spacing(2),
        textAlign: 'left',
        height: '810px',
        maxHeight: '810px',
        overflow: 'auto',
    },
    testAlignLeft: {
        padding: theme.spacing(2),
    },
    gridContainerRoot: {
        marginTop: 80
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    marginLeft: {
        marginLeft: 20
    },
    marginTop: {
        marginTop: 20
    },
    margin: {
        margin: '0'
    },
    questionNumber: {
        maxWidth: '30px',
        maxHeight: '30px',
        minWidth: '30px',
        minHeight: '30px',
        margin: '0.5%'
    }
});

class TestReading extends Component {
    constructor() {
        super();
        this.state = {
            questionNo: '',
            answers: [],
            highlightArr: [],
            highlightDisplayTextArr: [],
            NotetArr: [],
            NotetDisplayArr: [],
            answeredQuestion: [],
            reviewedQuestion: [],
            questionNumArr: [],
            reviewed: false,
            isLoading: false,
            currentTestObject: null,
            currentTotalQuestion: null,
            totalScore: {},
            isDone: false,
            isConfirmDone: false,
            isAnswerPrepared: false,
            currentQuestion: 1,
            currentPart: 1,
            timer: 1200,
            timerSeconds: 0,
            questionCount: 20,
            window: {
                height: window.innerHeight,
                width: window.innerWidth
            },
            isResume: false,
            isStartTest: false,
            isCheckUserInfo: true,
            isReadInstruction: false,
            dropItems: {},
            testText: 'Read the text below and answer question 1 - 13.',
            fontSize: 1
        };
        this.handleChangeReviewed = this.handleChangeReviewed.bind(this);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    handleResize = (event) => {
        this.setState({
            window: {
                height: window.innerHeight,
                width: window.innerWidth
            }
        });
    };

    componentDidMount = async () => {
        await this.createQuestionArr();

        const query = new URLSearchParams(this.props.location.search);
        const logQuery = query.get('log_id');
        if (logQuery !== null) {
            if (logQuery.length !== 0) {
                await this.setState({
                    log_id: logQuery,
                    isResume: true
                });

                this.handleDoneReadInstruction();
            }
        }

        window.addEventListener("resize", this.handleResize);
        document.addEventListener('contextmenu', (e) => {
            e.preventDefault();
        });

        this.props.handleStartTest();
    }

    createQuestionArr = async () => {
        for (let i = 1; i < this.state.questionCount + 1; i++) {
            this.state.questionNumArr[i] = i;
        }

        this.setState({
            questionNumArr: this.state.questionNumArr
        });

        console.log(this.state.questionNumArr);
    }

    handleAnswerObj = (answerObj) => {
        const selectedPart = this.state.answerObj.parts.find(part => part.part === this.state.currentPart);
        const selectedSection = selectedPart.sections.find(section => section.section_id === answerObj.section_id);
        if (selectedSection) {
            console.log(this.state.answerObj);
            return;
        }
        selectedPart.sections.push(answerObj);
    }

    getTest = async () => {
        const getTest_url = api_base_url + "/api/test/random?test_type=IELTS&is_free=true";

        try {
            this.handleLoading();

            const testObj = await axios.get(getTest_url, {
                headers: {
                    "Authorization": "bearer " + JSON.parse(localStorage.getItem('user')).token
                }
            });
            let filteredTest = await testObj.data.test.parts.filter(part => part.part_type === "READING");

            const answerObj = await PrepareAnsObj(filteredTest, testObj.data.test.test_id);

            await this.setState({
                answerObj: answerObj,
                test_id: testObj.data.test.test_id,
                log_id: testObj.data.log_id,
                testObj: filteredTest,
                currentTestObject: filteredTest.filter(part => part.part === 1)[0],
                currentTotalQuestion: filteredTest.filter(part => part.part === 1)[0].total_question,
                testObject_p1: filteredTest.filter(part => part.part === 1)[0],
                testObject_p2: filteredTest.filter(part => part.part === 2)[0],
                testObject_p3: filteredTest.filter(part => part.part === 3)[0]
            });

            await this.handleCreateTestLog();
            this.handleUpdateTestLog();

            this.handleCloseLoading();

        } catch (error) {
            this.handleCloseLoading();
            console.error(error);
        }
    }

    //=============================== TEST LOG & RESUME TEST ============================================
    handleResumeTest = async () => {
        const logObj = await this.handleGetLogObjById();
        console.log(logObj);

        try {
            this.handleLoading();

            const testObj = await axios({
                method: 'get',
                url: api_base_url + "/api/test?test_id=" + logObj.test_id,
                headers: {
                    "Authorization": "bearer " + JSON.parse(localStorage.getItem('user')).token
                }
            });

            let filteredTest = testObj.data[0].parts.filter(part => part.part_type === "READING");

            this.state.answerObj.test_id = testObj.data[0].test_id;

            await this.setState({
                test_id: testObj.data[0].test_id,
                log_id: this.state.log_id,
                currentTestObject: filteredTest.filter(part => part.part === 1)[0],
                currentTotalQuestion: filteredTest.filter(part => part.part === 1)[0].total_question,
                testObject_p1: filteredTest.filter(part => part.part === 1)[0],
                testObject_p2: filteredTest.filter(part => part.part === 2)[0],
                testObject_p3: filteredTest.filter(part => part.part === 3)[0],
                answerObj: logObj.answerObj,
                answeredQuestion: logObj.answeredQuestion,
                timer: logObj.timer,
                timerSeconds: logObj.timerSeconds
            });

            this.handleUpdateTestLog();
            this.handleCloseLoading();

        } catch (error) {
            console.log(error);
            this.handleCloseLoading();
        }
    }

    handleGetLogObjById = async () => {
        const oneHour = 60 * 60 * 1000;

        try {
            const responseObj = await axios({
                method: 'get',
                url: 'https://chatter-funky-busby.glitch.me/test/status/logs/' + this.state.log_id
            });

            if (((new Date()) - new Date(responseObj.data.createdAt)) > oneHour) {
                this.setState({
                    isExpiredTest: true
                });

                return;
            }

            else if (((new Date()) - new Date(responseObj.data.createdAt)) < oneHour) {
                return responseObj.data;
            }

        } catch (error) {
            console.error(error);
            return error;
        }
    }

    handleExpiredTest = () => {
        this.setState({
            isExpiredTest: false
        });

        this.handleEndTest();
        this.props.handleEndTest();
        clearInterval(this.state.myInterval);
    }

    handleCreateTestLog = async () => {
        try {
            const responseObj = await axios({
                method: 'post',
                url: 'https://chatter-funky-busby.glitch.me/test/status/log',
                data: {
                    username: JSON.parse(localStorage.getItem('user')).username,
                    test_id: this.state.test_id,
                    log_id: this.state.log_id,
                    test_type: 'IELTS',
                    test_part: 'READING',
                    answerObj: this.state.answerObj,
                    answeredQuestion: this.state.answeredQuestion,
                    timer: this.state.timer,
                    timerSeconds: this.state.timerSeconds
                }
            })

            console.log(responseObj.data);
        } catch (error) {
            console.error(error);
        }
    }

    handleUpdateTestLog = () => {
        const myInterval = setInterval(() => {
            if (this.props.isTest) {
                axios({
                    method: 'put',
                    url: 'https://chatter-funky-busby.glitch.me/test/status/log',
                    data: {
                        log_id: this.state.log_id,
                        answerObj: this.state.answerObj,
                        answeredQuestion: this.state.answeredQuestion,
                        timer: this.state.timer,
                        timerSeconds: this.state.timerSeconds
                    }
                })
            }
        }, 60000);

        this.setState({
            myInterval: myInterval
        });
    }

    handleEndTest = () => {
        try {
            axios({
                method: 'get',
                url: "https://chatter-funky-busby.glitch.me/test/status/logs/" + this.state.log_id + "/done"
            });
        } catch (error) {
            console.error(error);
        }
    }
    //=============================== TEST LOG & RESUME TEST ============================================

    handleGetResult = async () => {
        try {
            this.handleLoading();

            const { history } = this.props;

            const testResult = await axios({
                method: 'post',
                url: api_base_url + "/api/test/report?part=READING",
                headers: {
                    Authorization: "bearer " + JSON.parse(localStorage.getItem('user')).token
                },
                data: this.state.answerObj
            })

            await axios({
                method: 'post',
                url: api_base_url + "/api/test/result",
                headers: {
                    Authorization: "bearer " + JSON.parse(localStorage.getItem('user')).token
                },
                data: {
                    test_type: "IELTS READING FREE",
                    test_result: testResult.data,
                    test_id: testResult.data.test_id,
                    username: JSON.parse(localStorage.getItem('user')).username
                }
            });

            this.setState({
                testReportObj: testResult.data
            });

            clearInterval(this.state.myInterval);

            await this.handleEndTest();
            await this.props.handleEndTest();
            history.push('/test/evaluation?test-type=IELTS READING FREE&test-id=' + testResult.data.test_id);
            this.handleCloseLoading();
        } catch (error) {
            this.handleCloseLoading();
            await this.props.handleEndTest();
            console.error(error);
        }
    }

    handleCheckScore = async () => {
        let ansArr = this.state.answers;
        let partIndex = this.state.testObject.parts.findIndex(obj => obj.part === 'READING');
        let index;
        let scoreSection1 = 0;
        let scoreSection2 = 0;

        for (index = 0; index < ansArr.length; index++) {
            let answerObj = ansArr[index]

            let sectionIndex = await this.state.testObject.parts[partIndex].sections.findIndex(obj => obj.section_id === answerObj.section);
            let questionIndex = await this.state.testObject.parts[partIndex].sections[sectionIndex].questions.findIndex(obj => obj.no === answerObj.question);
            let key = await this.state.testObject.parts[partIndex].sections[sectionIndex].questions[questionIndex].answer

            if (key === answerObj.answer && answerObj.section === '1') {
                scoreSection1 = scoreSection1 + 1
            }

            else if (key === answerObj.answer && answerObj.section === '2') {
                scoreSection2 = scoreSection2 + 1
            }
        }

        this.setState({
            totalScore: {
                section1: scoreSection1,
                section2: scoreSection2,
                duration: 60 - this.state.timer
            }
        })
    }

    handleSelectQuestionType = (section) => {
        if (section.question_type === 'trueFalseNG') {
            return <TrueFalseNG
                sectionInfo={section}
                answer={this.state.answer}
                answerObj={this.state.answerObj}
                handleChange={this.handleChange}
                currentPart={this.state.currentPart}
                handleAnswerObj={this.handleAnswerObj}
                onFocusQuestion={this.onFocusQuestion}
                handleAnsweredQuestionNumber={this.handleAnsweredQuestionNumber}
            />
        }

        else if (section.question_type === 'yesNoNG') {
            return <YesNoNG
                sectionInfo={section}
                answer={this.state.answer}
                answerObj={this.state.answerObj}
                handleChange={this.handleChange}
                currentPart={this.state.currentPart}
                handleAnswerObj={this.handleAnswerObj}
                onFocusQuestion={this.onFocusQuestion}
                handleAnsweredQuestionNumber={this.handleAnsweredQuestionNumber}
            />
        }

        else if (section.question_type === 'noteCompletion') {
            return <NoteCompletion
                sectionInfo={section}
                answer={this.state.answer}
                answerObj={this.state.answerObj}
                currentPart={this.state.currentPart}
                handleChange={this.handleChange}
                handleAnswerObj={this.handleAnswerObj}
                onFocusQuestion={this.onFocusQuestion}
                handleAnsweredQuestionNumber={this.handleAnsweredQuestionNumber}
            />
        }

        else if (section.question_type === 'listOfHeading') {
            return <ListOfHeadings
                sectionInfo={section}
                answer={this.state.answer}
                answerObj={this.state.answerObj}
                handleChange={this.handleChange}
                currentQuestion={this.state.currentQuestion}
                currentPart={this.state.currentPart}
                dropItems={this.state.dropItems}
                onDragStart={this.onDragStart}
                handleAnswerObj={this.handleAnswerObj}
                onFocusQuestion={this.onFocusQuestion}
                handleAnsweredQuestionNumber={this.handleAnsweredQuestionNumber}
            />
        }

        else if (section.question_type === 'matchingSentenceEndings') {
            return <MatchingSentenceEndings
                sectionInfo={section}
                answer={this.state.answer}
                answerObj={this.state.answerObj}
                handleChange={this.handleChange}
                currentQuestion={this.state.currentQuestion}
                currentPart={this.state.currentPart}
                onDragStart={this.onDragStart}
                handleAnswer={this.handleAnswer}
                handleAnswerObj={this.handleAnswerObj}
                onFocusQuestion={this.onFocusQuestion}
                handleCancelQuestionNumber={this.handleCancelQuestionNumber}
                handleAnsweredQuestionNumber={this.handleAnsweredQuestionNumber}
            />
        }

        else if (section.question_type === 'sentenceCompletion') {
            return <SentenceCompletion
                sectionInfo={section}
                answer={this.state.answer}
                answerObj={this.state.answerObj}
                handleChange={this.handleChange}
                currentQuestion={this.state.currentQuestion}
                currentPart={this.state.currentPart}
                handleAnswerObj={this.handleAnswerObj}
                onFocusQuestion={this.onFocusQuestion}
                handleAnsweredQuestionNumber={this.handleAnsweredQuestionNumber}
            />
        }

        else if (section.question_type === 'multipleChoice') {
            return <MultipleChoice
                sectionInfo={section}
                answer={this.state.answer}
                answerObj={this.state.answerObj}
                handleChange={this.handleChange}
                currentQuestion={this.state.currentQuestion}
                currentPart={this.state.currentPart}
                handleAnswerObj={this.handleAnswerObj}
                onFocusQuestion={this.onFocusQuestion}
                handleAnsweredQuestionNumber={this.handleAnsweredQuestionNumber}
            />
        }
    }

    onFocusQuestion = (questionNumber) => {
        this.setState({
            focusedQuestion: questionNumber
        });
    }

    handleReviewQuestion = (event) => {
        this.state.reviewedQuestion[Number(this.state.focusedQuestion)] = event.target.checked;
        this.setState({
            reviewedQuestion: this.state.reviewedQuestion
        });
    }

    handleChangePart = async (type) => {
        this.handleLoading();
        if (type === "next") {
            if (this.state.currentPart === 2) {
                await this.setState({
                    currentPart: this.state.currentPart
                });
            }

            else {
                await this.setState({
                    currentPart: this.state.currentPart + 1
                });
            }
        }

        else if (type === "back") {
            if (this.state.currentPart === 1) {
                return;
            }

            await this.setState({
                currentPart: this.state.currentPart - 1
            });
        }

        if (this.state.currentPart === 1) {
            this.setState({
                currentTestObject: this.state.testObject_p1
            })
        }

        else if (this.state.currentPart === 2) {
            this.setState({
                currentTestObject: this.state.testObject_p2
            })
        }

        this.handleCloseLoading();
    }

    //======================== BEGIN BACKDROP CONTROL ==============================
    handleCloseLoading = () => {
        this.setState({
            isLoading: false
        });
    }

    handleLoading = () => {
        this.setState({
            isLoading: true
        });
    }
    //======================== END BACKDROP CONTROL ===============================

    handleAnsweredQuestionNumber = (questionNo) => {
        if (this.state.draggedItem !== undefined) {
            this.state.answeredQuestion[questionNo] = true;
            this.setState({
                answeredQuestion: this.state.answeredQuestion
            })
        }
    }

    handleCancelQuestionNumber = (questionNo) => {
        this.state.answeredQuestion[questionNo] = null;
        this.setState({
            answeredQuestion: this.state.answeredQuestion
        })
    }

    handleAnswer = (question) => {
        const isAnswered = 'q' + question.split('_')[1];
        this.setState({
            ...this.state,
            [isAnswered]: true
        });

    };

    //======================= LIST OF HEADING ====================================
    onDropped = async (questionNo) => {
        if (this.state.draggedItem !== undefined) {
            this.state.dropItems['' + questionNo] = this.state.draggedItem

            this.setState({
                draggedItem: undefined
            });
        }
    }

    onDragStart = (item) => {
        this.setState({
            draggedItem: item
        });
    }

    onClickAnswer = async (question) => {
        this.state.dropItems['' + question] = undefined;
        this.setState({
            dropItems: this.state.dropItems
        });

        this.onFocusQuestion(question);
    }
    //======================= LIST OF HEADING ====================================

    handleChangeReviewed = () => {
        this.setState({
            reviewed: !this.state.reviewed
        })
    }

    handleConfirmDoneTestDialog = async () => {
        const answerCountArr = await this.state.answeredQuestion.filter(answer => answer);

        if (answerCountArr.length != this.state.questionCount) {
            this.setState({
                isErrorConfirmDone: true
            })
        } else if (answerCountArr.length == this.state.questionCount) {
            this.setState({
                isConfirmDone: true
            })
        }
    }

    handleCloseConfirmDoneTestDialog = () => {
        this.setState({
            isConfirmDone: false,
            isErrorConfirmDone: false
        });
    }

    handleDoneTestDialog = async () => {
        await this.handleGetResult();
        this.setState({
            isStartTest: false,
            isConfirmDone: false
        });
    }

    //================================= BEGIN PRE-TEST ================================
    handleUserInfoPage = () => {
        this.setState({
            isStartTest: false,
            isCheckUserInfo: true,
            isReadInstruction: false
        })
    }

    handleUserInfoChecked = () => {
        this.setState({
            isStartTest: false,
            isCheckUserInfo: false,
            isReadInstruction: true
        })
    }

    handleDoneReadInstruction = async () => {
        this.setState({
            isStartTest: true,
            isCheckUserInfo: false,
            isReadInstruction: false
        })

        if (this.state.isResume) {
            await this.handleResumeTest();
        } else if (!this.state.isResume) {
            await this.getTest();
        }

        this.myInterval = setInterval(() => {
            if (this.state.timer > 0) {
                if (this.state.timerSeconds === 0) {
                    this.setState({
                        timerSeconds: 60
                    })
                };

                this.setState(prevState => ({
                    timer: prevState.timer - 1,
                    timerSeconds: this.state.timerSeconds - 1
                }))
            }
        }, 1000);
    }

    handleAddLeadingZero = (num) => {
        if (num < 10) {
            return '0' + num
        } else if (num >= 10) {
            return '' + num
        }
    }
    //================================= END PRE-TEST =================================

    handleFullScreen = () => {
        this.setState({
            fullScreenMode: !this.state.fullScreenMode
        })
    }

    handleFontSize = (type) => {
        if (type === 'plus') {
            this.setState({
                fontSize: this.state.fontSize + 0.1
            })
        } else if (type === 'minus') {
            this.setState({
                fontSize: this.state.fontSize - 0.1
            })
        }
    }

    //========================= HIGHLIGHT TEXT ====================================
    handleGetSelectedText = async () => {
        try {
            const text = window.getSelection().toString();

            if (this.state.highlightArr.includes(text)) {
                const foundIndex = this.state.highlightArr.indexOf(text);
                this.state.highlightArr.splice(foundIndex, 1);
                return;
            }

            this.state.highlightArr.push(text);
            this.setState({
                highlightArr: this.state.highlightArr
            });
        } catch (err) {
            console.log(err);
        }
    }

    handleGetSelectedDisplayText = async () => {
        try {
            const text = window.getSelection().toString();

            if (this.state.highlightDisplayTextArr.includes(text)) {
                const foundIndex = this.state.highlightDisplayTextArr.indexOf(text);
                this.state.highlightDisplayTextArr.splice(foundIndex, 1);
                return;
            }

            this.state.highlightDisplayTextArr.push(text);
            this.setState({
                highlightDisplayTextArr: this.state.highlightDisplayTextArr
            });
        } catch (err) {
            console.log(err);
        }
    }

    handleNoteSelectedText = (event, text) => {
        try {
            this.state.NotetArr[text] = event.target.value;
            console.log(this.state.NotetArr);
            this.setState({
                NotetArr: this.state.NotetArr
            });
        } catch (err) {
            console.log(err);
        }
    }
    //========================= HIGHLIGHT TEXT ====================================

    handleAlarm = () => {
        console.log("ALERT!!!")
    }

    handleRestTextSize = () => {
        this.setState({
            fontSize: 1.5
        });
    }

    render() {
        const { classes } = this.props;

        const timesUp = (
            <Dialog
                open={true}
                onClose={this.handleCloseConfirmDoneTestDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Time's up!"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Please click on <Box fontWeight="fontWeightBold" display='inline'>Confirm</Box>, to check your test score.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleDoneTestDialog} color="primary" autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        )

        return (
            <ThemeProvider theme={theme}>
                <Container
                    maxWidth={false}
                    style={{ flex: 1, width: '100%' }}
                    onPaste={(e) => {
                        e.preventDefault()
                        return false;
                    }}
                    onCopy={(e) => {
                        e.preventDefault()
                        return false;
                    }}
                >
                    <PageBackdrop isLoading={this.state.isLoading} />

                    <Dialog
                        open={this.state.isConfirmDone}
                        onClose={this.handleCloseConfirmDoneTestDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"There's still some time remaining."}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Are you sure that you want to submit the test? 
                                Please click <Box fontWeight="fontWeightBold" display='inline'>Confirm</Box>.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleCloseConfirmDoneTestDialog} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={this.handleDoneTestDialog} color="primary" autoFocus>
                                Confirm
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        open={this.state.isErrorConfirmDone}
                        onClose={this.handleCloseConfirmDoneTestDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"You have not answer all questions"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Are you sure that you still want to submit the test?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleCloseConfirmDoneTestDialog} color="primary">
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        open={this.state.isExpiredTest}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"Please confirm that There's still some time remaining."}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                This test session is already <Box fontWeight="fontWeightBold" display='inline'>expired</Box> please start a new one.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Link to="/myprofile" style={{ textDecoration: 'none', color: '#0f0f0f' }}>
                                <Button onClick={this.handleExpiredTest} color="primary">
                                    back
                                </Button>
                            </Link>
                        </DialogActions>
                    </Dialog>

                    <Grid container justify='center' style={{ maxHeight: this.state.window.height, padding: '20px' }}>
                        {this.state.isCheckUserInfo &&
                            <Grid item align='center' xs={12}>
                                <UserInfoPage
                                    user={this.props.user}
                                    handleUserInfoChecked={this.handleUserInfoChecked}
                                    handleEndTest={this.props.handleEndTest}
                                />
                            </Grid>
                        }
                        {this.state.isReadInstruction &&
                            <Grid item align='center' xs={12}>
                                <InstructionsPage
                                    handleDoneReadInstruction={this.handleDoneReadInstruction}
                                    handleEndTest={this.props.handleEndTest}
                                    handleUserInfoPage={this.handleUserInfoPage}
                                />
                            </Grid>
                        }
                        {!this.state.isDone && this.state.isStartTest && this.state.currentTestObject !== null &&
                            <Grid item xs={12} style={{ maxHeight: this.state.window.height }}>
                                <Grid container justify='center'>
                                    <Grid item xs={12} >
                                        <Grid container>
                                            <Grid item xs={4}>
                                                <Typography variant="subtitle1" align='center' style={{ fontSize: '1rem' }}>
                                                    <AccountBoxRoundedIcon /> {this.props.user.name}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography variant="h5" gutterBottom style={{ fontSize: '1rem' }}>
                                                    <AccessAlarmRoundedIcon /> {this.handleAddLeadingZero(Math.floor(this.state.timer / 3600))} : {this.state.timer === 3600 ? '00' : this.handleAddLeadingZero(Math.floor(this.state.timer / 60))} : {this.handleAddLeadingZero(this.state.timerSeconds)} minutes left
                                                </Typography>
                                                {this.state.timer === 0 && timesUp}
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Link to="/" style={{ textDecoration: 'none', color: '#0f0f0f' }}>
                                                    <IconButton color="primary" component="span" onClick={() => {
                                                        this.props.handleEndTest();
                                                        clearInterval(this.state.myInterval);
                                                    }}>
                                                        <HomeRoundedIcon style={{ color: 'black' }} />
                                                    </IconButton>
                                                </Link>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Paper variant="outlined" style={{
                                            padding: '10px',
                                            backgroundColor: '#76323F',
                                            color: 'white'
                                        }}>
                                            <Typography variant="h4" gutterBottom align='left' style={{ fontSize: '1rem' }}>
                                                <Box fontWeight="fontWeightBold" display='inline'>Section {this.state.currentPart}</Box>
                                            </Typography>
                                            <Typography variant="subtitle1" gutterBottom align='left' style={{ fontSize: '1rem' }}>
                                                {this.state.currentTestObject !== null && this.state.currentTestObject.title}
                                            </Typography>
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Paper variant="outlined" style={{
                                            backgroundImage: `url("/U-Test-Logo-new-bw.png")`,
                                            backgroundSize: '120px',
                                            backgroundRepeat: 'space'
                                        }}>
                                            <Grid container>
                                                <Grid item xs={6} align='left' style={{
                                                    maxHeight: this.state.window.height - 300,
                                                    padding: '20px'
                                                }}>
                                                    <Grid container>
                                                        {this.state.currentTestObject !== null && this.state.currentTestObject.sections[0].question_type === 'listOfHeading' && <Grid item align='right' xs={12} style={{ marginBottom: '10px' }}>
                                                            <ButtonGroup size="small" variant="contained" aria-label="contained primary button group">
                                                                <Button onClick={() => { this.handleFontSize('plus') }}>
                                                                    <AddRoundedIcon />
                                                                </Button>
                                                                <Button onClick={() => { this.handleFontSize('minus') }}>
                                                                    <RemoveRoundedIcon />
                                                                </Button>
                                                                <Button onClick={this.handleRestTextSize}>
                                                                    Reset
                                                                </Button>
                                                            </ButtonGroup>
                                                        </Grid>
                                                        }
                                                        {this.state.currentTestObject !== null && this.state.currentTestObject.sections[0].question_type === 'listOfHeading' &&
                                                            <Grid item align='left' xs={12} style={{ marginBottom: '10px' }}>
                                                                <Typography variant="h5" gutterBottom style={{ fontSize: '1rem' }}>
                                                                    <Box fontWeight="fontWeightBold" display='inline'>{this.state.currentTestObject?.description_title}</Box>
                                                                </Typography>
                                                            </Grid>
                                                        }
                                                        <Grid item xs={12}>
                                                            <Grid container style={{ marginBottom: '20px', paddingRight: '15px', maxHeight: this.state.window.height - 420, overflow: 'auto' }}>
                                                                {this.state.currentTestObject !== null && this.state.currentTestObject.sections[0].question_type === 'listOfHeading' &&
                                                                    this.state.currentTestObject !== null && this.state.currentTestObject.sections.filter(section => section.question_type === 'listOfHeading')[0].questions.map((question, index) =>
                                                                        <Grid item>
                                                                            <Typography
                                                                                paragraph
                                                                                style={{ fontSize: this.state.fontSize + 'rem', textAlign: 'justify' }}
                                                                            >
                                                                                <Highlighter
                                                                                    highlightClassName="YourHighlightClass"
                                                                                    searchWords={this.state.highlightArr}
                                                                                    autoEscape={true}
                                                                                    textToHighlight={question.paragraph}
                                                                                />
                                                                            </Typography>
                                                                            <DropTarget
                                                                                targetKey="foo"
                                                                                onHit={() => {
                                                                                    this.onDropped(question.no);
                                                                                    this.handleAnsweredQuestionNumber(question.no);
                                                                                }}
                                                                                onFocus={() => { this.onFocusQuestion(Number(question.no)) }}
                                                                            >
                                                                                <List component="nav" aria-label="main mailbox folders">
                                                                                    <Paper variant="outlined">
                                                                                        <ListItem
                                                                                            button
                                                                                            //selected={selectedIndex === 0}
                                                                                            onClick={() => { this.onClickAnswer(question.no) }}
                                                                                        >
                                                                                            <ListItemText align='center' primary={
                                                                                                <Typography variant="body1">
                                                                                                    {this.state.dropItems['' + question.no] === undefined ? '' + question.no : this.state.dropItems['' + question.no]}
                                                                                                </Typography>
                                                                                            }
                                                                                            />
                                                                                        </ListItem>
                                                                                    </Paper>
                                                                                </List>
                                                                            </DropTarget>
                                                                        </Grid>
                                                                    )}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    {this.state.currentTestObject !== null && this.state.currentTestObject.sections[0].question_type !== 'listOfHeading' &&
                                                        <Grid container>
                                                            <Grid item align='right' xs={12} style={{ marginBottom: '10px' }}>
                                                                <ButtonGroup size="small" variant="contained" aria-label="contained primary button group">
                                                                    <Button onClick={() => { this.handleFontSize('plus') }}>
                                                                        <AddRoundedIcon />
                                                                    </Button>
                                                                    <Button onClick={() => { this.handleFontSize('minus') }}>
                                                                        <RemoveRoundedIcon />
                                                                    </Button>
                                                                    <Button onClick={this.handleRestTextSize}>
                                                                        Reset
                                                                    </Button>
                                                                </ButtonGroup>
                                                            </Grid>
                                                            <Grid item align='left' xs={12} style={{ marginBottom: '10px' }}>
                                                                <Typography variant="h5" gutterBottom style={{ fontSize: '1rem' }}>
                                                                    <Box fontWeight="fontWeightBold" display='inline'>{this.state.currentTestObject?.description_title}</Box>
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12} align='left' style={{ marginBottom: '20px', paddingRight: '15px', maxHeight: this.state.window.height - 420, overflow: 'auto' }}>
                                                                <DisplayParagraph
                                                                    handleGetSelectedDisplayText={this.handleGetSelectedDisplayText}
                                                                    handleNoteSelectedText={this.handleNoteSelectedText}
                                                                    fontSize={this.state.fontSize}
                                                                    currentTestObject={this.state.currentTestObject}
                                                                    highlightDisplayTextArr={this.state.highlightDisplayTextArr}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    style={{
                                                        overflow: 'auto',
                                                        maxHeight: this.state.window.height - 300,
                                                        padding: '20px'

                                                    }}>
                                                    {this.state.currentTestObject !== null && this.state.currentTestObject.sections.map((section) =>
                                                        this.handleSelectQuestionType(section)
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                        {!this.state.isDone && this.state.isStartTest && this.state.currentTestObject !== null &&
                            <Grid item xs={12} style={{ position: 'absolute', bottom: 0, width: '95%' }}>
                                <Grid container justify='center'>
                                    <Grid item xs={1}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={this.state.reviewedQuestion[this.state.focusedQuestion] ? this.state.reviewedQuestion[this.state.focusedQuestion] : false}
                                                    onChange={this.handleReviewQuestion}
                                                    name="reviewed"
                                                    color="primary"
                                                />
                                            }
                                            label="Review"
                                        />
                                    </Grid>
                                    <Grid item xs={10} align='left'>
                                        <Paper variant="outlined">
                                            {this.state.questionNumArr.map((num) => {
                                                return <Button
                                                    key={num}
                                                    size="small"
                                                    style={{
                                                        borderRadius: this.state.reviewedQuestion[num] ? 75 : 5,
                                                        color: this.state.answeredQuestion[num] === undefined || this.state.answeredQuestion[num] === null ? "#76323F" : "",
                                                        backgroundColor: this.state.answeredQuestion[num] === undefined || this.state.answeredQuestion[num] === null ? "" : "#76323F",
                                                        borderColor: this.state.answeredQuestion[num] === undefined || this.state.answeredQuestion[num] === null ? "#76323F" : ""
                                                    }}
                                                    variant={this.state.answeredQuestion[num] === undefined || this.state.answeredQuestion[num] === null ? "outlined" : "contained"}
                                                    color="primary"
                                                    className={classes.questionNumber}>
                                                    {num}
                                                </Button>
                                            })}
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={1} align='right'>
                                        <Grid container>
                                            <Grid item xs={6}>
                                                {!(this.state.currentPart === 1) &&
                                                    <IconButton
                                                        aria-label="add to favorites"
                                                        onClick={() => { this.handleChangePart('back') }}
                                                    >
                                                        <ArrowBackRoundedIcon />
                                                    </IconButton>
                                                }
                                            </Grid>
                                            <Grid item xs={6} style={{ marginTop: !(this.state.currentPart === 2) ? '0%' : '5%' }}>
                                                {!(this.state.currentPart === 2) ?
                                                    (<IconButton
                                                        aria-label="add to favorites"
                                                        onClick={() => { this.handleChangePart('next') }}
                                                    >
                                                        <ArrowForwardRoundedIcon />
                                                    </IconButton>) : <Button size="small" variant="contained" onClick={() => { FillEmptyAnswers(this.state.answerObj, this.state.testObj) }/*this.handleConfirmDoneTestDialog*/} style={{ backgroundColor: '#76323F', color: 'white' }}>Done</Button>
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                    {/*this.state.isDone &&
                        <Grid container justify='center' >
                            <Grid item xs={12} sm={12} style={{
                                overflow: 'auto',
                                marginTop: '50px'
                            }}>
                                <Paper variant="outlined" style={{ padding: '100px' }}>
                                    <TestReportPage
                                        testReportObj={this.state.testReportObj}
                                        handleEndTest={this.props.handleEndTest}
                                    />
                                </Paper>
                            </Grid>
                        </Grid>
                        */}
                </Container>
            </ThemeProvider>
        )
    }
}

export default compose(
    withStyles(useStyles)
)(TestReading);