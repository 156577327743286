import React, { useEffect, useState } from 'react';

//MUI
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { ThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';

//Components

//Icons
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';

//Font
import 'typeface-glacial-indifference';

//Services
import { ValidateDuplicateTest } from '../../../services/productPageServices';

//Others
import { Link } from "react-router-dom";
import PageBackdrop from '../PageBackdrop';

const useStyles = theme => ({
    root: {
        padding: theme.spacing(12, 4),
    }
});

const theme = createMuiTheme({
    typography: {
        fontFamily: [
            'Roboto'
        ].join(','),
    },
});

const TryNowPage = (props) => {
    const [isOpenDialog, setIsOpenDialog] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [selectedTestUrl, setSelectedTestUrl] = React.useState("");

    useEffect(() => {
        document.body.style.overflow = "auto";
        document.body.style.backgroundColor = "#efefef";
        document.querySelector("body").scrollTo(0, 0);
    }, []);

    const validateDuplicateTest = async (type, url) => {
        setIsLoading(true);
        setSelectedTestUrl(url);

        if (type == "BMAT") {
            goToPurchasePage(url);
        } else {
            const res = await ValidateDuplicateTest(JSON.parse(localStorage.getItem('user')).username, type);
            if (res.success) {
                if (res.res.listening || res.res.reading) {
                    onOpenDialog();
                } if (type === "BMAT") {
                    goToPurchasePage(url);
                } else {
                    goToPurchasePage(url);
                }
            } else {
                if (res.res.response.status === 404) {
                    goToPurchasePage(url);
                } else {
                    alert("Something went wrong! Please try again later.");
                }
            }
        }

        setIsLoading(false);
    }

    const goToPurchasePage = (url) => {
        props.history.push(url);
    }

    const onCloseDialog = () => {
        setIsOpenDialog(false);
    }

    const onOpenDialog = () => {
        setIsOpenDialog(true);
    }

    const testDuplicatedDialog = (
        <Dialog
            open={isOpenDialog}
            onClose={onCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"We are working on adding more set of tests!"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    You might found same questions from your previous tests.
                    Are you sure to purchase the test?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCloseDialog} color="primary">
                    Cancel
                </Button>
                <Button onClick={() => { goToPurchasePage(selectedTestUrl) }} color="primary" autoFocus>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    )

    const mobileUI = (<Grid item xs={12} style={{ maxWidth: window.innerWidth }}>
        {testDuplicatedDialog}
        <Grid container spacing={4} style={{
            marginTop: "5%",
            marginBottom: "20%",
            padding: "20px",
        }}>
            <Grid item align='left' xs={12}>
                <Typography variant="h4" style={{ color: "#970606", fontWeight: 'bold' }} gutterBottom>
                    Try now!
                </Typography>
                <Typography variant="h5" style={{ color: "#970606", fontWeight: 'bold', paddingLeft: '10px' }} gutterBottom>
                    Book a simulation test
                    </Typography>
                <Typography variant="body1" style={{ color: "#696969", fontWeight: 500, paddingLeft: '30px' }} gutterBottom>
                    - You can book and start taking the compute-based simulation test now from anywhere
                    </Typography>
            </Grid>
            <Grid id="productielts" item xs={12} style={{ marginTop: '10px' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} align='left'>
                        <Button
                            disabled
                            variant="contained"
                            style={{ backgroundColor: "#25333e", color: "white", borderRadius: "10px", textTransform: "none" }}
                            disableElevation
                        >
                            <Typography variant="h5" style={{ fontWeight: 'bold' }}>
                                IELTS
                            </Typography>
                        </Button>
                    </Grid>
                    <Grid item xs={12} align='left'>
                        <Typography variant="body1">
                            IELTS is an International English Language Testing System primarily for higher education.
                            The test evaluates the listening, reading, writing and speaking skills ranking from a band score of 2.0 to 9.0
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={1}>
                    <Grid item align='center'>
                        <Grid container>
                            <Grid item xs={12}>
                                <Paper square elevation={0} style={{ height: '500px', width: '350px', padding: "30px", borderRight: '10px solid #25333e', borderBottom: '10px solid #25333e', borderRadius: '20px' }}>
                                    <Grid item align='left' xs={12}>
                                        <Typography variant="h4" style={{ fontWeight: "bold", color: "#25333e" }} gutterBottom>
                                            FULL IELTS
                                            </Typography>
                                        <Typography variant="body1" style={{ color: "#666666" }} gutterBottom>
                                            IELTS test includes speaking test
                                            </Typography>
                                        <Typography variant="h3" style={{ color: "#25333e", fontWeight: 'bold', marginTop: '50px' }} >
                                            ฿1,699
                                        </Typography>

                                        <Grid item xs={12} style={{ marginTop: '30px' }}>
                                            <Grid container spacing={2} alignItems='flex-end'>
                                                <Grid item>
                                                    <CheckRoundedIcon fontSize='small' style={{ color: "#008000" }} />
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="body1" style={{ color: "black" }}>
                                                        Listening
                                                        </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={2} alignItems='flex-end'>
                                                <Grid item>
                                                    <CheckRoundedIcon fontSize='small' style={{ color: "#008000" }} />
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="body1" style={{ color: "black" }}>
                                                        Reading
                                                        </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={2} alignItems='flex-end'>
                                                <Grid item>
                                                    <CheckRoundedIcon fontSize='small' style={{ color: "#008000" }} />
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="body1" style={{ color: "black" }}>
                                                        Writing
                                                        </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={2} alignItems='flex-end'>
                                                <Grid item>
                                                    <CheckRoundedIcon fontSize='small' style={{ color: "#008000" }} />
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="body1" style={{ color: "black" }}>
                                                        Speaking
                                                        </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            {JSON.parse(localStorage.getItem('userSignedIn')) ?
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        style={{ backgroundColor: "#25333e", color: "white", textTransform: "none", borderRadius: '0px', width: '100px', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px' }}
                                        onClick={() => { validateDuplicateTest('IELTS', '/test/payment?course_name=FULL IELTS&description=Include Reading, Listening, Writing and Speaking&amount=169900') }}
                                        disableElevation
                                    >
                                        <Typography variant="h6">
                                            Buy
                                            </Typography>
                                    </Button>
                                </Grid> : <Grid item xs={12}>
                                    <Link
                                        to="/newlogin"
                                        style={{ textDecoration: 'none', color: '#565656' }}
                                    >
                                        <Button
                                            variant="contained"
                                            style={{ backgroundColor: "#25333e", color: "white", textTransform: "none", borderRadius: '0px', width: '100px', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px' }}
                                            disableElevation
                                        >
                                            <Typography variant="h6">
                                                Log in
                                                </Typography>
                                        </Button>
                                    </Link>
                                </Grid>
                            }
                        </Grid>
                    </Grid>

                    <Grid item align='center'>
                        <Grid container>
                            <Grid item xs={12}>
                                <Paper square elevation={0} style={{ height: '500px', width: '350px', padding: "30px", borderRight: '10px solid #25333e', borderBottom: '10px solid #25333e', borderRadius: '25px' }}>
                                    <Grid item align='left' xs={12}>
                                        <Typography variant="h4" style={{ fontWeight: "bold", color: "#25333e" }} gutterBottom>
                                            IELTS NO SPK
                                            </Typography>
                                        <Typography variant="body1" style={{ color: "#666666" }} gutterBottom>
                                            IELTS test without speaking test
                                            </Typography>
                                        <Typography variant="h3" style={{ color: "#25333e", fontWeight: 'bold', marginTop: '50px' }} >
                                            ฿1,399
                                            </Typography>

                                        <Grid item xs={12} style={{ marginTop: '30px' }}>
                                            <Grid container spacing={2} alignItems='flex-end'>
                                                <Grid item>
                                                    <CheckRoundedIcon fontSize='small' style={{ color: "#008000" }} />
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="body1" style={{ color: "black" }}>
                                                        Listening
                                                        </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={2} alignItems='flex-end'>
                                                <Grid item>
                                                    <CheckRoundedIcon fontSize='small' style={{ color: "#008000" }} />
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="body1" style={{ color: "black" }}>
                                                        Reading
                                                        </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={2} alignItems='flex-end'>
                                                <Grid item>
                                                    <CheckRoundedIcon fontSize='small' style={{ color: "#008000" }} />
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="body1" style={{ color: "black" }}>
                                                        Writing
                                                        </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={2} alignItems='flex-end'>
                                                <Grid item>
                                                    <ClearRoundedIcon fontSize='small' style={{ color: "#970606" }} />
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="body1" style={{ color: "black" }}>
                                                        Speaking
                                                        </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            {JSON.parse(localStorage.getItem('userSignedIn')) ?
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        style={{ backgroundColor: "#25333e", color: "white", textTransform: "none", borderRadius: '0px', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px', width: '100px' }}
                                        onClick={() => { validateDuplicateTest('IELTS', '/test/payment?course_name=IELTS NO SPK&description=Include Reading, Listening and Writing&amount=139900') }}
                                        disableElevation
                                    >
                                        <Typography variant="h6">
                                            Buy
                                                </Typography>
                                    </Button>
                                </Grid> : <Grid item xs={12}>
                                    <Link
                                        to="/newlogin"
                                        style={{ textDecoration: 'none', color: '#565656' }}
                                    >
                                        <Button
                                            variant="contained"
                                            style={{ backgroundColor: "#25333e", color: "white", textTransform: "none", borderRadius: '0px', width: '100px', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px' }}
                                            disableElevation
                                        >
                                            <Typography variant="h6">
                                                Log in
                                                </Typography>
                                        </Button>
                                    </Link>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>


            {/* ========================= BMAT ======================= */}
            <Grid id="productbmat" item xs={12} style={{ marginTop: '30px' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} align='left'>
                        <Button
                            disabled
                            variant="contained"
                            style={{ backgroundColor: "#214b4b", color: "white", borderRadius: "10px", textTransform: "none" }}
                            disableElevation
                        >
                            <Typography variant="h5" style={{ fontWeight: 'bold' }}>
                            TBAT
                            </Typography>
                        </Button>
                    </Grid>
                    <Grid item xs={12} align='left'>
                        <Typography variant="body1">
                        Thai Biomedical Admissions Test (TBAT) is a medical institution admissions test that
                            evaluates reasoning ability, scientific knowledge, and communication skills.
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={1}>
                    <Grid item align='center'>
                        <Grid container>
                            <Grid item xs={12}>
                                <Paper square elevation={0} style={{ height: '500px', width: '350px', padding: "30px", borderRight: '10px solid #214b4b', borderBottom: '10px solid #214b4b', borderRadius: '20px' }}>
                                    <Grid item align='left' xs={12}>
                                        <Typography variant="h4" style={{ fontWeight: "bold", color: "#214b4b" }} gutterBottom>
                                            FULL TBAT
                                        </Typography>
                                        <Typography variant="body1" style={{ color: "#666666" }} gutterBottom>
                                        TBAT test includes writing test
                                        </Typography>
                                        <Typography variant="h3" style={{ color: "#214b4b", fontWeight: 'bold', marginTop: '50px' }} >
                                            ฿1,699
                                        </Typography>
                                        <Grid item xs={12} style={{ marginTop: '30px' }}>
                                            <Grid container spacing={2} alignItems='flex-end'>
                                                <Grid item>
                                                    <CheckRoundedIcon fontSize='small' style={{ color: "#008000" }} />
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="body1" style={{ color: "black" }}>
                                                        Aptitude and Skills
                                                        </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={2} alignItems='flex-start'>
                                                <Grid item>
                                                    <CheckRoundedIcon fontSize='small' style={{ color: "#008000" }} />
                                                </Grid>
                                                <Grid item xs>
                                                    <Typography variant="body1" style={{ color: "black" }}>
                                                        Scientific Knowledge and Applications
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={2} alignItems='flex-end'>
                                                <Grid item>
                                                    <CheckRoundedIcon fontSize='small' style={{ color: "#008000" }} />
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="body1" style={{ color: "black" }}>
                                                        Writing Task
                                                        </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            {JSON.parse(localStorage.getItem('userSignedIn')) ?
                                <Grid item xs={12}>
                                    <Button
                                        disabled
                                        variant="contained"
                                        style={{ textTransform: "none", borderRadius: '0px', width: '200px', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px' }}
                                        // style={{ backgroundColor: "#214b4b", color: "white", textTransform: "none", borderRadius: '0px', width: '100px', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px' }}
                                        // onClick={() => { validateDuplicateTest('BMAT', '/test/payment?course_name=BMAT&description=Include Aptitude and Skills, Scientific Knowledge and Applications and Writing Task&amount=169900') }}
                                        disableElevation
                                    >
                                        <Typography variant="h6">
                                        Coming soon
                                        </Typography>
                                    </Button>
                                </Grid> : <Grid item xs={12}>
                                    <Link
                                        to="/newlogin"
                                        style={{ textDecoration: 'none', color: '#565656' }}
                                    >
                                        <Button
                                            variant="contained"
                                            style={{ backgroundColor: "#214b4b", color: "white", textTransform: "none", borderRadius: '0px', width: '100px', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px' }}
                                            disableElevation
                                        >
                                            <Typography variant="h6">
                                                Log in
                                                </Typography>
                                        </Button>
                                    </Link>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item align='center' xs={12} style={{ marginTop: '50px' }}>
                <Typography variant="h4" style={{ fontWeight: 'bold', color: '#970606' }}>
                    START YOUR <span><img src="/U Test Logo (new) square.png" alt="Girl in a jacket" width='40' /></span>EST TODAY
                </Typography>
            </Grid>
        </Grid>
    </Grid>)

    const webUI = (
        <Container>
            {testDuplicatedDialog}

            <Grid container spacing={4} style={{
                marginTop: "5%",
                marginBottom: "20%",
                maxWidth: window.innerWidth,
                zoom: "0.8"
            }}>
                <Grid item align='left' xs={12}>
                    <Typography variant="h3" style={{ color: "#970606", fontWeight: 'bold' }} gutterBottom>
                        Try now!
                    </Typography>
                    <Typography variant="h4" style={{ color: "#970606", fontWeight: 'bold', paddingLeft: '50px' }} gutterBottom>
                        Book a simulation test
                    </Typography>
                    <Typography variant="h5" style={{ color: "#696969", fontWeight: 500, paddingLeft: '100px' }} gutterBottom>
                        - You can book and start taking the compute-based simulation test now from anywhere
                    </Typography>
                </Grid>
                <Grid id="productielts" item xs={12} style={{ marginTop: '50px' }}>
                    <Grid container>
                        <Grid item xs={2} align='center'>
                            <Button
                                disabled
                                variant="contained"
                                style={{ backgroundColor: "#25333e", color: "white", borderRadius: "10px", textTransform: "none" }}
                                disableElevation
                            >
                                <Typography variant="h4" style={{ fontWeight: 'bold' }}>
                                    IELTS
                                </Typography>
                            </Button>
                        </Grid>
                        <Grid item xs={10} align='left'>
                            <Typography variant="h6">
                                IELTS is an International English Language Testing System primarily for higher education.
                                The test evaluates the listening, reading, writing and speaking skills ranking from a band score of 2.0 to 9.0
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item align='center'>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Paper square elevation={0} style={{ height: '500px', width: '350px', padding: "30px", borderRight: '10px solid #25333e', borderBottom: '10px solid #25333e', borderRadius: '20px' }}>
                                        <Grid item align='left' xs={12}>
                                            <Typography variant="h4" style={{ fontWeight: "bold", color: "#25333e" }} gutterBottom>
                                                FULL IELTS
                                            </Typography>
                                            <Typography variant="body1" style={{ color: "#666666" }} gutterBottom>
                                                IELTS test includes speaking test
                                            </Typography>
                                            <Typography variant="h3" style={{ color: "#25333e", fontWeight: 'bold', marginTop: '50px' }} >
                                                ฿1,699
                                            </Typography>

                                            <Grid item xs={12} style={{ marginTop: '30px' }}>
                                                <Grid container spacing={2} alignItems='flex-end'>
                                                    <Grid item>
                                                        <CheckRoundedIcon fontSize='small' style={{ color: "#008000" }} />
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography variant="body1" style={{ color: "black" }}>
                                                            Listening
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={2} alignItems='flex-end'>
                                                    <Grid item>
                                                        <CheckRoundedIcon fontSize='small' style={{ color: "#008000" }} />
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography variant="body1" style={{ color: "black" }}>
                                                            Reading
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={2} alignItems='flex-end'>
                                                    <Grid item>
                                                        <CheckRoundedIcon fontSize='small' style={{ color: "#008000" }} />
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography variant="body1" style={{ color: "black" }}>
                                                            Writing
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={2} alignItems='flex-end'>
                                                    <Grid item>
                                                        <CheckRoundedIcon fontSize='small' style={{ color: "#008000" }} />
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography variant="body1" style={{ color: "black" }}>
                                                            Speaking
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                                {JSON.parse(localStorage.getItem('userSignedIn')) ?
                                    <Grid item xs={12}>
                                        <Button
                                            variant="contained"
                                            style={{ backgroundColor: "#25333e", color: "white", textTransform: "none", borderRadius: '0px', width: '100px', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px' }}
                                            onClick={() => { validateDuplicateTest('IELTS', '/test/payment?course_name=FULL IELTS&description=Include Reading, Listening, Writing and Speaking&amount=169900') }}
                                            disableElevation
                                        >
                                            <Typography variant="h6">
                                                Buy
                                            </Typography>
                                        </Button>
                                    </Grid> : <Grid item xs={12}>
                                        <Link
                                            to="/newlogin"
                                            style={{ textDecoration: 'none', color: '#565656' }}
                                        >
                                            <Button
                                                variant="contained"
                                                style={{ backgroundColor: "#25333e", color: "white", textTransform: "none", borderRadius: '0px', width: '100px', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px' }}
                                                disableElevation
                                            >
                                                <Typography variant="h6">
                                                    Log in
                                                </Typography>
                                            </Button>
                                        </Link>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>

                        <Grid item align='center'>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Paper square elevation={0} style={{ height: '500px', width: '350px', padding: "30px", borderRight: '10px solid #25333e', borderBottom: '10px solid #25333e', borderRadius: '25px' }}>
                                        <Grid item align='left' xs={12}>
                                            <Typography variant="h4" style={{ fontWeight: "bold", color: "#25333e" }} gutterBottom>
                                                IELTS NO SPK
                                            </Typography>
                                            <Typography variant="body1" style={{ color: "#666666" }} gutterBottom>
                                                IELTS test without speaking test
                                            </Typography>
                                            <Typography variant="h3" style={{ color: "#25333e", fontWeight: 'bold', marginTop: '50px' }} >
                                                ฿1,399
                                            </Typography>

                                            <Grid item xs={12} style={{ marginTop: '30px' }}>
                                                <Grid container spacing={2} alignItems='flex-end'>
                                                    <Grid item>
                                                        <CheckRoundedIcon fontSize='small' style={{ color: "#008000" }} />
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography variant="body1" style={{ color: "black" }}>
                                                            Listening
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={2} alignItems='flex-end'>
                                                    <Grid item>
                                                        <CheckRoundedIcon fontSize='small' style={{ color: "#008000" }} />
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography variant="body1" style={{ color: "black" }}>
                                                            Reading
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={2} alignItems='flex-end'>
                                                    <Grid item>
                                                        <CheckRoundedIcon fontSize='small' style={{ color: "#008000" }} />
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography variant="body1" style={{ color: "black" }}>
                                                            Writing
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={2} alignItems='flex-end'>
                                                    <Grid item>
                                                        <ClearRoundedIcon fontSize='small' style={{ color: "#970606" }} />
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography variant="body1" style={{ color: "black" }}>
                                                            Speaking
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                                {JSON.parse(localStorage.getItem('userSignedIn')) ?
                                    <Grid item xs={12}>
                                        <Button
                                            variant="contained"
                                            style={{ backgroundColor: "#25333e", color: "white", textTransform: "none", borderRadius: '0px', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px', width: '100px' }}
                                            onClick={() => { validateDuplicateTest('IELTS', '/test/payment?course_name=IELTS NO SPK&description=Include Reading, Listening and Writing&amount=139900') }}
                                            disableElevation
                                        >
                                            <Typography variant="h6">
                                                Buy
                                                </Typography>
                                        </Button>
                                    </Grid> : <Grid item xs={12}>
                                        <Link
                                            to="/newlogin"
                                            style={{ textDecoration: 'none', color: '#565656' }}
                                        >
                                            <Button
                                                variant="contained"
                                                style={{ backgroundColor: "#25333e", color: "white", textTransform: "none", borderRadius: '0px', width: '100px', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px' }}
                                                disableElevation
                                            >
                                                <Typography variant="h6">
                                                    Log in
                                                </Typography>
                                            </Button>
                                        </Link>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                {/* ========================= BMAT ======================= */}
                <Grid id="productbmat" item xs={12} style={{ marginTop: '50px' }}>
                    <Grid container>
                        <Grid item xs={2} align='center'>
                            <Button
                                disabled
                                variant="contained"
                                style={{ backgroundColor: "#214b4b", color: "white", borderRadius: "10px", textTransform: "none" }}
                                disableElevation
                            >
                                <Typography variant="h4" style={{ fontWeight: 'bold' }}>
                                TBAT
                                </Typography>
                            </Button>
                        </Grid>
                        <Grid item xs={10} align='left'>
                            <Typography variant="h6">
                            Thai Biomedical Admissions Test (TBAT) is a medical institution admissions test that
                                evaluates reasoning ability, scientific knowledge, and communication skills.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item align='center'>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Paper square elevation={0} style={{ height: '500px', width: '350px', padding: "30px", borderRight: '10px solid #214b4b', borderBottom: '10px solid #214b4b', borderRadius: '20px' }}>
                                        <Grid item align='left' xs={12}>
                                            <Typography variant="h4" style={{ fontWeight: "bold", color: "#214b4b" }} gutterBottom>
                                                FULL TBAT
                                            </Typography>
                                            <Typography variant="body1" style={{ color: "#666666" }} gutterBottom>
                                            TBAT test includes writing test
                                            </Typography>
                                            <Typography variant="h3" style={{ color: "#214b4b", fontWeight: 'bold', marginTop: '50px' }} >
                                                ฿1,699
                                            </Typography>
                                            <Grid item xs={12} style={{ marginTop: '30px' }}>
                                                <Grid container spacing={2} alignItems='flex-end'>
                                                    <Grid item>
                                                        <CheckRoundedIcon fontSize='small' style={{ color: "#008000" }} />
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography variant="body1" style={{ color: "black" }}>
                                                            Aptitude and Skills
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={2} alignItems='flex-start'>
                                                    <Grid item>
                                                        <CheckRoundedIcon fontSize='small' style={{ color: "#008000" }} />
                                                    </Grid>
                                                    <Grid item xs>
                                                        <Typography variant="body1" style={{ color: "black" }}>
                                                            Scientific Knowledge and Applications
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={2} alignItems='flex-end'>
                                                    <Grid item>
                                                        <CheckRoundedIcon fontSize='small' style={{ color: "#008000" }} />
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography variant="body1" style={{ color: "black" }}>
                                                            Writing Task
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                                {JSON.parse(localStorage.getItem('userSignedIn')) ?
                                    <Grid item xs={12}>
                                        <Button
                                            disabled
                                            variant="contained"
                                            style={{ textTransform: "none", borderRadius: '0px', width: '200px', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px' }}
                                            // style={{ backgroundColor: "#214b4b", color: "white", textTransform: "none", borderRadius: '0px', width: '100px', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px' }}
                                            // onClick={() => { validateDuplicateTest('BMAT', '/test/payment?course_name=BMAT&description=Include Aptitude and Skills, Scientific Knowledge and Applications and Writing Task&amount=169900') }}
                                            disableElevation
                                        >
                                            <Typography variant="h6">
                                                Coming soon
                                            </Typography>
                                        </Button>
                                    </Grid> : <Grid item xs={12}>
                                        <Link
                                            to="/newlogin"
                                            style={{ textDecoration: 'none', color: '#565656' }}
                                        >
                                            <Button
                                                variant="contained"
                                                style={{ backgroundColor: "#214b4b", color: "white", textTransform: "none", borderRadius: '0px', width: '100px', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px' }}
                                                disableElevation
                                            >
                                                <Typography variant="h6">
                                                    Log in
                                                </Typography>
                                            </Button>
                                        </Link>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item align='center' xs={12} style={{ marginTop: '50px' }}>
                    <Typography variant="h2" style={{ fontWeight: 'bold', color: '#970606' }}>
                        START YOUR <span><img src="/U Test Logo (new) square.png" alt="Girl in a jacket" width='60' /></span>EST TODAY
                    </Typography>
                </Grid>
            </Grid>
        </Container>)

    return <ThemeProvider theme={theme}>
        <PageBackdrop isLoading={isLoading} />
        <Hidden mdUp>
            {mobileUI}
        </Hidden>
        <Hidden smDown>
            <Grid item xs={12} style={{
                backgroundImage: `url("/Permanentbackground(transparent).png")`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
            }}>
                <Container>
                    {webUI}
                </Container>
            </Grid>
        </Hidden>
    </ThemeProvider>
};

export default compose(
    withStyles(useStyles)
)(TryNowPage);
