import React, { useEffect } from "react";

//MUI
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";

import {
  ThemeProvider,
  createMuiTheme,
  withStyles,
} from "@material-ui/core/styles";
import { compose } from "recompose";
import { DataGrid } from "@mui/x-data-grid";

//Font
import "typeface-glacial-indifference";

//Services
import {
  DeleteUser,
  GetAllTestLogByLogId,
  GetAllUsers,
  UpdateUserInfoSecure,
} from "../../../../services/userServices";
import {
  GetAllTestReportByUsername,
  GetSpeakingNotes,
  GetSpeakingNotesByUsername,
  GetWritingNotes,
  GetWritingNotesByUsername,
} from "../../../../services/testReportServices";
import {
  AddNewSchool,
  GetAllSchools,
} from "../../../../services/registrationServices";

//Others
import moment from "moment";
import Select from "react-select";
import { Typography, TextField } from "@material-ui/core";
import {
  BmatScoreMappingSectionOne,
  BmatScoreMappingSectionTwo,
  RoundBand,
} from "../../../../functions/testReportFuntions";
import Answers from "./Answers";

const useStyles = (theme) => ({
  root: {
    padding: theme.spacing(12, 4),
  },
});

const theme = createMuiTheme({
  typography: {
    fontFamily: ["Roboto"].join(","),
  },
});

const UserInfoTable = () => {
  const genderChoiceMap = {
    Male: { value: 1, label: "Male" },
    Female: { value: 0, label: "Female" },
    Others: { value: 3, label: "Others" },
  };

  const roleOptionsMap = {
    SUPER_ADMIN: { value: "SUPER_ADMIN", label: "Super admin" },
    TEST_ADMIN: { value: "TEST_ADMIN", label: "Test admin" },
    ADMIN: { value: "ADMIN", label: "Admin" },
    HEAD_TEACHER: { value: "HEAD_TEACHER", label: "Head teacher" },
    TEACHER: { value: "TEACHER", label: "Teacher" },
    STUDENT: { value: "STUDENT", label: "Student" },
  };

  const roles = {
    SUPER_ADMIN: "Super admin",
    TEST_ADMIN: "Test admin",
    ADMIN: "Admin",
    HEAD_TEACHER: "Head teacher",
    TEACHER: "Teacher",
    STUDENT: "Student",
  };

  const columns = [
    {
      field: "username",
      headerName: "Username",
      width: 250,
    },
    {
      field: "email",
      headerName: "Email",
      width: 250,
    },
    {
      field: "name",
      headerName: "Name",
      width: 250,
      valueGetter: (params) =>
        `${capitalize(params.row.firstname) || ""} ${
          capitalize(params.row.lastname) || ""
        }`,
    },
    {
      field: "school",
      headerName: "School",
      width: 250,
    },
    {
      field: "gender",
      headerName: "Gender",
      width: 150,
    },
    {
      field: "role",
      headerName: "Role",
      width: 150,
      valueGetter: (params) => roleMapping(params.row.role),
    },
    {
      field: "birthDate",
      headerName: "Birthday",
      type: "date",
      width: 220,
    },
  ];

  const reportColumn = [
    {
      field: "_id",
      headerName: "ID",
      width: 250,
      hide: true,
    },
    {
      field: "test_type",
      headerName: "Type",
      width: 130,
      valueGetter: (params) =>
        params.row.test_type.includes("IELTS") ? "IELTS" : params.row.test_type,
    },
    {
      field: "reading",
      headerName: "R",
      width: 90,
    },
    {
      field: "listening",
      headerName: "L",
      width: 90,
    },
    {
      field: "writing",
      headerName: "W",
      width: 90,
    },
    {
      field: "speaking",
      headerName: "S",
      width: 90,
    },
    {
      field: "overall",
      headerName: "O",
      width: 90,
    },
    {
      field: "test_date",
      headerName: "Date (MM/DD/YYYY)",
      type: "date",
      width: 200,
      valueGetter: (params) => new Date(params.row.test_date),
    },
    {
      field: "username",
      headerName: "Username",
      width: 250,
    },
  ];

  const reportBMATColumn = [
    {
      field: "_id",
      headerName: "ID",
      width: 250,
      hide: true,
    },
    {
      field: "test_type",
      headerName: "Type",
      width: 130,
      valueGetter: (params) =>
        params.row.test_type.includes("IELTS") ? "IELTS" : params.row.test_type,
    },
    {
      field: "section_1",
      headerName: "S1",
      width: 100,
    },
    {
      field: "section_2",
      headerName: "S2",
      width: 100,
    },
    {
      field: "writing",
      headerName: "W",
      width: 90,
    },
    {
      field: "overall",
      headerName: "O",
      width: 90,
    },
    {
      field: "test_date",
      headerName: "Date (MM/DD/YYYY)",
      type: "date",
      width: 200,
      valueGetter: (params) =>
        moment.utc(params.row.test_date).format("MM/DD/YYYY"),
    },
    {
      field: "username",
      headerName: "Username",
      width: 250,
    },
  ];

  const [dataRows, setDataRows] = React.useState([]);
  const [selectionModel, setSelectionModel] = React.useState([]);
  const [selectionRows, setSelectionRows] = React.useState([]);
  const [selectedReport, setSelectedReport] = React.useState([]);
  const [reportData, setReportData] = React.useState([]);
  const [reportBMATData, setReportBMATData] = React.useState([]);
  const [editRowsModel, setEditRowsModel] = React.useState({});
  const [isEditInfo, setIsEditInfo] = React.useState(false);
  const [isConfirmDelete, setIsConfirmDelete] = React.useState(false);
  const [currentTab, setCurrentTab] = React.useState("IELTS");
  const [filteredUsers, setFilteredUsers] = React.useState([]);
  const [searchString, setSearchString] = React.useState("");

  const [schools, setSchools] = React.useState([]);
  const [isLoadingSchools, setIsLoadingSchools] = React.useState(false);
  const [selectedReportObj, setSelectedReportObj] = React.useState({});
  const [isLoadingReport, setIsLoadingReport] = React.useState(false);
  const [testLogs, setTestLogs] = React.useState({});

  useEffect(() => {
    document.body.style.overflow = "auto";
    document.body.style.backgroundColor = "#efefef";
    document.querySelector("body").scrollTo(0, 0);

    getAllUsers();
  }, []);

  const getAllSchools = async () => {
    setIsLoadingSchools(true);

    const schoolObj = await GetAllSchools();
    await setSchools(schoolObj.res);

    setIsLoadingSchools(false);
  };

  const getAllUsers = async () => {
    const res = await GetAllUsers(
      JSON.parse(localStorage.getItem("user")).token
    );
    if (res.success) {
      setDataRows(res.res);

      return;
    }

    alert("Something went wrong. Please try again later.");
  };

  const getLogByLogId = async () => {
    const res = await GetAllTestLogByLogId(selectedReportObj.purchase_id);

    res.res["test_id"] = selectedReportObj.test_id;
    setTestLogs(res.res);
    return;
  };

  const onClickHideAnswer = () => {
    setTestLogs({});
    return;
  }

  const onSelectionModelChange = (ids) => {
    const selectedIDs = new Set(ids);
    const selectedRowData = dataRows.filter((row) =>
      selectedIDs.has(row.email.toString())
    );

    selectedRowData.map(async (user) => {
      const res = await GetAllTestReportByUsername(
        JSON.parse(localStorage.getItem("user")).token,
        user.username
      );

      if (res.success) {
        let tempArr = [];
        let tempBMATArr = [];
        const reportsArr = res.res.reports;

        reportsArr.map((report) => {
          if (
            report.test_type.includes("IELTS") &&
            report.result_id !== undefined &&
            report.test_result.score?.reading?.score !== undefined &&
            report.test_result.score?.listening?.score !== undefined
          ) {
            tempArr.push({
              _id: report._id,
              result_id: report.result_id,
              test_type: report.test_type,
              username: report.username,
              test_date: report.date_created,
              reading: report.test_result.score?.reading?.score,
              listening: report.test_result.score?.listening?.score,
              writing: report.test_result.score?.writing?.score,
              speaking: report.test_result.score?.speaking?.score,
              overall: report.test_result.score?.overall_score,
            });
          }

          if (report.test_type.includes("BMAT")) {
            tempBMATArr.push({
              _id: report._id,
              result_id: report.result_id,
              test_type: report.test_type,
              username: report.username,
              test_date: report.date_created,
              section_1: report.test_result?.parts[0]?.total_score,
              section_2: report.test_result?.parts[1]?.total_score,
              writing: "-",
              overall: report.test_result?.total_score,
            });
          }

          return report;
        });

        /*const ielts = reportsArr.filter((log) => { return log.test_type.includes("IELTS") });
                const bmat = reportsArr.filter((log) => { return log.test_type.includes("BMAT") });

                tempLogs["IELTS"] = ielts;
                tempLogs["BMAT"] = bmat;*/

        setReportData(tempArr);
        setReportBMATData(tempBMATArr);
      }
    });
    setSelectionModel(ids);
  };

  const onClickSeeReport = () => {
    if (selectedReport[0].test_type === "BMAT") {
      window.open(
        `/full-bmat-report?result_id=${selectedReport[0].result_id}`,
        "_blank"
      );
    } else if (selectedReport[0].test_type === "IELTS") {
      window.open(
        `/full-report?result_id=${selectedReport[0].result_id}`,
        "_blank"
      );
    }
  };

  const onSelectionReportChange = (ids) => {
    const selectedIDs = new Set(ids);
    const selectedRowData = reportData.filter((row) =>
      selectedIDs.has(row._id)
    );
    const selectedBMATRowData = reportBMATData.filter((row) =>
      selectedIDs.has(row._id)
    );

    if (selectedBMATRowData.length === 1) {
      setSelectedReport(selectedBMATRowData);
    } else {
      setSelectedReportObj(selectedRowData[0]);
      setSelectedReport(selectedRowData);
    }
  };

  const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const roleMapping = (role) => {
    return roles[`${role}`];
  };

  const onClickSeeMore = async () => {
    setIsLoadingReport(true);
    setReportData([]);
    setReportBMATData([]);

    const result = await dataRows.filter((s) =>
      selectionModel.includes(s.email)
    );
    setSelectionRows(result);
    getAllSchools();

    const res = await GetAllTestReportByUsername(
      JSON.parse(localStorage.getItem("user")).token,
      result[0].username
    );

    if (res.success) {
      const writing = await GetWritingNotesByUsername(result[0].username);
      const speaking = await GetSpeakingNotesByUsername(result[0].username);

      let tempArr = [];
      let tempBMATArr = [];
      const reportsArr = res.res.reports;

      reportsArr.map(async (report) => {
        if (
          report.test_type.includes("IELTS") &&
          report.result_id !== undefined &&
          report.test_result.score?.reading?.score !== undefined &&
          report.test_result.score?.listening?.score !== undefined
        ) {
          const speakScore = speaking.success
            ? speaking.res.filter((s) => {
                return s.purchaseId === report.test_result.test_purchase_id;
              })[0]?.score
            : report.test_result.score?.speaking?.score;
          const writScore = writing.success
            ? writing.res.filter((w) => {
                return w.test_id === report.result_id;
              })[0]?.note?.score
            : report.test_result.score?.writing?.score;

          tempArr.push({
            _id: report._id,
            purchase_id: report.test_result.test_purchase_id,
            test_id: report.test_id,
            result_id: report.result_id,
            test_type: report.test_type,
            username: report.username,
            test_date: report.date_created,
            reading: report.test_result.score?.reading?.score,
            listening: report.test_result.score?.listening?.score,
            writing: writScore ? writScore : "-",
            speaking: speakScore ? speakScore : "-",
            overall: RoundBand(
              (Number(report.test_result.score?.listening?.score) +
                Number(report.test_result.score?.reading?.score) +
                Number(writScore ? writScore : 0) +
                Number(speakScore ? speakScore : 0)) /
                (2 + (speakScore ? 1 : 0) + (writScore ? 1 : 0))
            ),
          });
        }

        if (
          report.test_type.includes("BMAT") &&
          report.test_result?.parts[0] !== undefined &&
          report.test_result?.parts[1]?.total_score !== undefined
        ) {
          tempBMATArr.push({
            _id: report._id,
            result_id: report.result_id,
            test_type: report.test_type,
            username: report.username,
            test_date: report.date_created,
            section_1: BmatScoreMappingSectionOne(
              report.test_result?.parts[0]?.total_score
            ),
            section_2: BmatScoreMappingSectionTwo(
              report.test_result?.parts[1]?.total_score
            ),
            writing: "-",
            overall:
              BmatScoreMappingSectionOne(
                report.test_result?.parts[0]?.total_score
              ) +
              BmatScoreMappingSectionTwo(
                report.test_result?.parts[1]?.total_score
              ),
          });
        }

        return report;
      });

      /*const ielts = reportsArr.filter((log) => { return log.test_type.includes("IELTS") });
            const bmat = reportsArr.filter((log) => { return log.test_type.includes("BMAT") });

            tempLogs["IELTS"] = ielts;
            tempLogs["BMAT"] = bmat;*/

      setReportData(tempArr);
      setReportBMATData(tempBMATArr);
      setIsLoadingReport(false);
    } else {
      setReportData([]);
      setReportBMATData([]);
      setIsLoadingReport(false);
    }
  };

  const onClickDelete = () => {
    setIsConfirmDelete(true);
  };

  const onCancelDelete = () => {
    setIsConfirmDelete(false);
  };

  const handleDeleteUser = async () => {
    const user = await dataRows.filter((row) => {
      return row.email === selectionModel[0];
    });

    const res = await DeleteUser(
      JSON.parse(localStorage.getItem("user")).token,
      user[0].username
    );
    if (res.success) {
      getAllUsers();
      setIsConfirmDelete(false);

      return;
    }
  };

  const handleSelectRow = (rows) => {
    setSelectionModel(rows);
  };

  const onClickEditInfo = () => {
    setIsEditInfo(true);
  };

  const onCloseEditInfo = () => {
    setIsEditInfo(false);
  };

  const handleChangeSelect = (event, type) => {
    if (event === null) {
      if (type === "gender") {
        selectionRows[0][`${type}`] = 3;
      } else {
        selectionRows[0][`${type}`] = "";
      }
    }
    if (event !== null) {
      selectionRows[0][`${type}`] = event.value;
    }
  };

  const updateUserInfo = async () => {
    const res = await UpdateUserInfoSecure(
      JSON.parse(localStorage.getItem("user")).token,
      {
        username: selectionRows[0].username,
        school: selectionRows[0].school,
        gender:
          typeof selectionRows[0].gender === "string" ||
          selectionRows[0].gender instanceof String
            ? genderChoiceMap[`${selectionRows[0].gender}`].value
            : selectionRows[0].gender,
        role: selectionRows[0].role,
      }
    );

    if (res.success) {
      setIsEditInfo(false);
      getAllUsers();
    }
  };

  const dateComparator = (v1, v2) => {
    // Parse the dates using Moment.js
    const d1 = moment(v1, "DD/MM/YYYY");
    const d2 = moment(v2, "DD/MM/YYYY");

    // Compare the dates
    if (d1.isBefore(d2)) {
      return 1;
    }
    if (d1.isAfter(d2)) {
      return -1;
    }
    return 0;
  };

  const onClickSelectTab = (tab) => {
    setCurrentTab(tab);
  };

  const onChangeSearch = (event) => {
    const searchString = event.target.value.toLowerCase();
    let filtered = dataRows.filter((obj) => {
      return (
        obj.username.toLowerCase().includes(searchString) ||
        obj.email.toLowerCase().includes(searchString) ||
        `${obj.firstname} ${obj.lastname}`
          .toLowerCase()
          .includes(searchString) ||
        obj.school.toLowerCase().includes(searchString) ||
        obj.gender.toLowerCase().includes(searchString) ||
        obj.role.toLowerCase().includes(searchString) ||
        obj.birthDate.toLowerCase().includes(searchString)
      );
    });

    setFilteredUsers(filtered);
    setSearchString(searchString);
  };

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={isConfirmDelete}
        onClose={onCancelDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete Account"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete account {selectionModel[0]}?
            <p>
              Click <span style={{ fontWeight: "bold" }}>"Delete"</span> to
              confirm.
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onCancelDelete}
            color="primary"
            style={{ color: "grey" }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDeleteUser}
            color="primary"
            autoFocus
            style={{ color: "red" }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Grid item xs={12} style={{ padding: "10px" }}>
        <TextField
          fullWidth
          placeholder="Search.."
          variant="outlined"
          type="search"
          onChange={onChangeSearch}
          style={{ backgroundColor: "white" }}
        />
      </Grid>

      <Grid align="right" item xs={12} style={{ padding: "10px" }}>
        {selectionModel.length === 1 && (
          <Button
            disableElevation
            variant="contained"
            onClick={onClickDelete}
            style={{
              color: "white",
              backgroundColor: "#880000",
              marginRight: "10px",
            }}
          >
            Delete
          </Button>
        )}
        {selectionModel.length === 1 && (
          <Button
            disableElevation
            variant="contained"
            onClick={onClickSeeMore}
            style={{ color: "white", backgroundColor: "#880000" }}
          >
            See more
          </Button>
        )}
      </Grid>
      <div style={{ height: 650, width: "100%", backgroundColor: "white" }}>
        <DataGrid
          disa
          SelectionMode="Single"
          checkboxSelection
          disableSelectionOnClick
          rows={searchString !== "" ? filteredUsers : dataRows}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[100]}
          getRowId={(row) => row.email}
          onSelectionModelChange={handleSelectRow}
          selectionModel={selectionModel}
        />
      </div>
      {selectionRows.length === 1 && (
        <Grid container spacing={2} style={{ marginTop: "30px" }}>
          <Grid item align="left" xs={12}>
            <Grid item xs align="left">
              <Typography variant="h5" style={{ fontWeight: "bold" }}>
                User Information
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            align="left"
            xs={4}
            style={{
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "15px",
            }}
          >
            <Grid container>
              <Grid item align="right" xs={12}>
                {!isEditInfo && (
                  <Button
                    disableElevation
                    size="small"
                    variant="contained"
                    onClick={onClickEditInfo}
                    style={{
                      color: "white",
                      backgroundColor: "#880000",
                      margin: "5px",
                    }}
                  >
                    Edit
                  </Button>
                )}
                {isEditInfo && (
                  <Button
                    disableElevation
                    size="small"
                    variant="contained"
                    onClick={updateUserInfo}
                    style={{
                      color: "white",
                      backgroundColor: "#880000",
                      margin: "5px",
                    }}
                  >
                    Save
                  </Button>
                )}
                {isEditInfo && (
                  <Button
                    disableElevation
                    size="small"
                    variant="contained"
                    onClick={onCloseEditInfo}
                    style={{
                      color: "white",
                      backgroundColor: "Grey",
                      margin: "5px",
                    }}
                  >
                    Cancel
                  </Button>
                )}
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={3}>
                    <Typography variant="body1">
                      <span style={{ fontWeight: "bold" }}>Name: </span>
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <TextField
                      disabled
                      value={`${capitalize(
                        selectionRows[0].firstname
                      )} ${capitalize(selectionRows[0].lastname)}`}
                      type="input"
                      size="small"
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={3}>
                    <Typography variant="body1">
                      <span style={{ fontWeight: "bold" }}>Gender: </span>
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Select
                      name="gender"
                      isDisabled={!isEditInfo}
                      className="gender"
                      classNamePrefix="select-gender"
                      defaultValue={
                        genderChoiceMap[`${selectionRows[0].gender}`]
                      }
                      onChange={(event) => {
                        handleChangeSelect(event, "gender");
                      }}
                      options={[
                        { value: 1, label: "Male" },
                        { value: 0, label: "Female" },
                        { value: 3, label: "Others" },
                      ]}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={3}>
                    <Typography variant="body1">
                      <span style={{ fontWeight: "bold" }}>Birth date: </span>
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <TextField
                      disabled
                      value={selectionRows[0].birthDate}
                      type="input"
                      size="small"
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={3}>
                    <Typography variant="body1">
                      <span style={{ fontWeight: "bold" }}>Phone: </span>
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <TextField
                      disabled
                      value={selectionRows[0].phoneNumber}
                      type="input"
                      size="small"
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={3}>
                    <Typography variant="body1">
                      <span style={{ fontWeight: "bold" }}>Email: </span>
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <TextField
                      disabled
                      value={selectionRows[0].email}
                      type="input"
                      size="small"
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={3}>
                    <Typography variant="body1">
                      <span style={{ fontWeight: "bold" }}>School: </span>
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    {isEditInfo ? (
                      <Select
                        name="school"
                        className="school"
                        classNamePrefix="select-school"
                        isLoading={isLoadingSchools}
                        isDisabled={!isEditInfo}
                        onChange={(event) => {
                          handleChangeSelect(event, "school");
                        }}
                        options={schools}
                      />
                    ) : (
                      <TextField
                        disabled={!isEditInfo}
                        value={
                          selectionRows[0].school.length === 0
                            ? "-"
                            : selectionRows[0].school
                        }
                        type="input"
                        size="small"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={3}>
                    <Typography variant="body1">
                      <span style={{ fontWeight: "bold" }}>Role: </span>
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Select
                      name="role"
                      className="role"
                      classNamePrefix="select-role"
                      defaultValue={roleOptionsMap[`${selectionRows[0].role}`]}
                      isDisabled={!isEditInfo}
                      onChange={(event) => {
                        handleChangeSelect(event, "role");
                      }}
                      options={[
                        { value: "SUPER_ADMIN", label: "Super admin" },
                        { value: "TEST_ADMIN", label: "Test admin" },
                        { value: "ADMIN", label: "Admin" },
                        { value: "HEAD_TEACHER", label: "Head teacher" },
                        { value: "TEACHER", label: "Teacher" },
                        { value: "STUDENT", label: "Student" },
                      ]}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={8}>
            <Grid item xs={12} align="right" style={{ padding: "10px" }}>
              <Grid container spacing={1}>
                <Grid item align="left" xs={6}>
                  <>
                    <Button
                      onClick={() => onClickSelectTab("IELTS")}
                      variant={
                        currentTab === "IELTS" ? "contained" : "outlined"
                      }
                      disableElevation
                      style={{
                        borderColor: currentTab === "IELTS" ? "" : "#880000",
                        backgroundColor:
                          currentTab === "IELTS" ? "#880000" : "",
                        color: currentTab === "IELTS" ? "white" : "#880000",
                      }}
                    >
                      IELTS
                    </Button>
                    <Button
                      onClick={() => onClickSelectTab("BMAT")}
                      variant={currentTab === "BMAT" ? "contained" : "outlined"}
                      disableElevation
                      style={{
                        borderColor: currentTab === "BMAT" ? "" : "#880000",
                        backgroundColor: currentTab === "BMAT" ? "#880000" : "",
                        color: currentTab === "BMAT" ? "white" : "#880000",
                        marginLeft: 10,
                      }}
                    >
                      BMAT
                    </Button>
                  </>
                </Grid>
                {selectedReport.length === 1 && (
                  <Grid item align="right" xs={6}>
                    <>
                      <Button
                        disableElevation
                        variant="contained"
                        onClick={onClickSeeReport}
                        style={{ color: "white", backgroundColor: "#880000" }}
                      >
                        See selected report
                      </Button>
                      {Object.keys(testLogs).length > 0 ? (
                        <Button
                          disableElevation
                          variant="contained"
                          onClick={onClickHideAnswer}
                          style={{
                            color: "white",
                            backgroundColor: "#880000",
                            marginLeft: 10,
                          }}
                        >
                          Hide answer
                        </Button>
                      ) : (
                        <Button
                          disableElevation
                          variant="contained"
                          onClick={getLogByLogId}
                          style={{
                            color: "white",
                            backgroundColor: "#880000",
                            marginLeft: 10,
                          }}
                        >
                          See answer
                        </Button>
                      )}
                    </>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {currentTab === "IELTS" && (
                <div
                  style={{
                    height: 400,
                    width: "100%",
                    backgroundColor: "white",
                  }}
                >
                  <DataGrid
                    loading={isLoadingReport}
                    sortingOrder={["desc", "asc"]}
                    sortModel={[{ field: "test_date", sort: "desc" }]}
                    sortComparator={dateComparator}
                    rows={reportData}
                    columns={reportColumn}
                    pageSize={5}
                    rowsPerPageOptions={[100]}
                    getRowId={(row) => row._id}
                    onSelectionModelChange={onSelectionReportChange}
                    disableSelectionOnClick={false}
                  />
                </div>
              )}

              {currentTab === "BMAT" && (
                <div
                  style={{
                    height: 400,
                    width: "100%",
                    backgroundColor: "white",
                  }}
                >
                  <DataGrid
                    loading={isLoadingReport}
                    sortingOrder={["desc", "asc"]}
                    sortModel={[{ field: "test_date", sort: "desc" }]}
                    sortComparator={dateComparator}
                    rows={reportBMATData}
                    columns={reportBMATColumn}
                    pageSize={5}
                    rowsPerPageOptions={[100]}
                    getRowId={(row) => row._id}
                    onSelectionModelChange={onSelectionReportChange}
                    disableSelectionOnClick={false}
                  />
                </div>
              )}
            </Grid>
          </Grid>
          {Object.keys(testLogs).length > 0 && <Answers data={testLogs} />}
        </Grid>
      )}
    </ThemeProvider>
  );
};

export default compose(withStyles(useStyles))(UserInfoTable);
